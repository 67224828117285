import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { EMPLOYEE_STAFF_POLICY } from "app_config";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Card, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { createEmployee, getPositionGroups, updateEmployee } from 'redux/organization/employeeSlice';
import AsyncSelect from 'react-select/async';
import {debounce} from "../../../helpers/debounce"
import MaskedInput from "react-text-mask";
import NewSiteModal from "./NewSiteModal";
import NewDepartmentModal from "./NewDepartmentModal";
import { useForm, Controller } from "react-hook-form";
import { activeEmployeesDebounce } from "helpers/employeeOptionHelper";
import apiAxios from "helpers/apiAxios";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

const EmployeeForm = ({title, employee}) => {
  const { control, register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { positionGroups } = useSelector((state) => state.organization_employee);
  const [showNewSiteModal, setShowNewSiteModal] = useState(false);
  const [showNewDepartmentModal, setShowNewDepartmentModal] = useState(false);
  const [currentSite, setCurrentSite] = useState(employee && employee.site_id);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (employee) {
      Object.entries(employee).forEach(([key, value]) => {
        switch (key) {
          case "staff_policy":
            setValue(key, value?.toLowerCase());
            break;
          case "start_date":
            setValue(key, value ? new Date(value) : null);
            break;
          default:
            setValue(key, value);
            break;
        }
      });
    }
  }, [employee, setValue]);

  const staffPolicyOptions = useMemo(() => EMPLOYEE_STAFF_POLICY.map((option) => ({ value: option.toLocaleLowerCase(), label: option })), []);
  const defaultSiteOption = useMemo(() => (employee && employee.site ? { value: employee.site.id, label: employee.site.name } : null), [employee]);
  const defaultDepartmentOption = useMemo(() => (employee && employee.department ? { value: employee.department.id, label: employee.department.name } : null), [employee]);
  const defaultManagerOption = useMemo(() => (employee && employee.parent ? { value: employee.parent.id, label: employee.parent.name } : null), [employee]);

  const [departmentOption, setDepartmentOption] = useState(defaultDepartmentOption);

  useEffect(() => {
    dispatch(getPositionGroups());
  }, [dispatch]);

  const onSubmit = async (data) => {
    try {
      if (employee) {
        const employeeId = employee.id;
        await dispatch(updateEmployee({ employeeId, data }));
        redirectEmployeeDetail(employeeId);
      } else {
        const response = await dispatch(createEmployee(data));
        const payload = response.payload;
        if (payload !== undefined) {
          redirectEmployeeDetail(payload.data.id);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const debouncedFilterSites = debounce((inputValue, callback) => {
    filterIndexOptions("/organization/sites", inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);

  const loadSiteOptions = (inputValue, callback) => {
    debouncedFilterSites(inputValue, callback);
  };

  const debouncedFilterDepartments = debounce((inputValue, callback) => {
    let url = "/organization/departments";
    if (currentSite !== null) {
      url = `/organization/sites/${currentSite}/departments`;
    }
    filterIndexOptions(url, inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);

  const loadDepartmentOptions = (inputValue, callback) => {
    debouncedFilterDepartments(inputValue, callback);
  };

  const loadManagerOptions = (inputValue, callback) => {
    activeEmployeesDebounce(inputValue, callback);
  };

  const redirectEmployeeDetail = (employeeId) => {
    navigate(`/organization/employees/${employeeId}`);
  };

  const handleNewSite = () => {
    setShowNewSiteModal(true);
  };

  const handleNewDepartment = () => {
    setShowNewDepartmentModal(true);
  };

  const handleSiteChange = (selectOptions) => {
    setValue("site_id", selectOptions.value);
    setDepartmentOption(null);
    setCurrentSite(selectOptions.value);
    loadDepartmentOptions('', (filteredOptions) => filteredOptions);
  };

  const filterIndexOptions = async (url, inputValue) => {
    try {
      const response = await apiAxios.get(`${url}?page=1&per_page=30&name_cont=${inputValue}`);
      const { rows } = response.data;
      return rows.map((item) => item.attributes ? { value: item.attributes.id, label: item.attributes.name } : { value: item.id, label: item.name });
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const positionGroupOptions = positionGroups.map((item) => ({ value: item.id, label: item.name }));

  return (
    <>
      <Card style={{padding: '20px'}} className="fs--1">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Header className="d-flex flex-between-center border-bottom">
            <Card.Title>{title}</Card.Title>
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
            />
          </Card.Header>
          <Card.Body className="mt-3">

            <Form.Group as={Row} className='mb-2' controlId='name'>
              <Form.Label column sm={3} className='text-lg-end'>
                Name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type='text'
                  name='name'
                  placeholder='Name'
                  className='fs--1'
                  {...register('name', {
                    required: 'Name must be required.'
                  })}
                />
                <span className='text-danger'>
                  {errors.name && errors.name.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='full_name'>
              <Form.Label column sm={3} className='text-lg-end'>
                Full of name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type='text'
                  name='full_name'
                  placeholder='Full of name'
                  className='fs--1'
                  {...register('full_name', {
                    required: 'Full of name must be required.'
                  })}
                />
                <span className='text-danger'>
                  {errors.full_name && errors.full_name.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='title'>
              <Form.Label column sm={3} className='text-lg-end'>
                Title
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type='text'
                  name='title'
                  placeholder='Title'
                  className='fs--1'
                  {...register('title', {
                    required: 'Title must be required.'
                  })}
                />
                <span className='text-danger'>
                  {errors.title && errors.title.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='email'>
              <Form.Label column sm={3} className='text-lg-end'>
                Email
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type='email'
                  name='email'
                  placeholder='Email'
                  className='fs--1'
                  {...register('email', {
                    required: 'Email must be required.'
                  })}
                />
                <span className='text-danger'>
                  {errors.email && errors.email.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='phone_number'>
              <Form.Label column sm={3} className='text-lg-end'>
                Phone number
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type='text'
                  name='phone_number'
                  placeholder='Phone number'
                  className='fs--1'
                  {...register('phone_number')}
                />

              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='parent_id'>
              <Form.Label column sm={3} className='text-lg-end'>
                Manager
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="parent_id"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultManagerOption}
                      loadOptions={loadManagerOptions}
                      closeMenuOnSelect={true}
                      placeholder='<Select manager>'
                      classNamePrefix="react-select"
                      className="w-100"
                      onChange={selectedOption => setValue('parent_id', selectedOption.value)}
                    />
                  )}
                />
                <span className='text-danger'>
                  {errors.parent_id && errors.parent_id.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2 mt-3' controlId='gender'>
              <Col sm={{ span: 10, offset: 3 }}>
                <Controller
                  control={control}
                  name='gender'
                  defaultValue={false}
                  render={({ field }) => (
                    <Form.Check
                      type='switch'
                      label='Female'
                      checked={field.value}
                      onChange={(e) => setValue('gender', e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-1' controlId='is_sales'>
              <Col sm={{ span: 10, offset: 3 }}>
                <Controller
                  control={control}
                  name='is_sales'
                  defaultValue={false}
                  render={({ field }) => (
                    <Form.Check
                      type='switch'
                      label='Sales team'
                      checked={field.value}
                      onChange={(e) => setValue('is_sales', e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-1' controlId='active'>
              <Col sm={{ span: 10, offset: 3 }}>
                <Controller
                  control={control}
                  name='active'
                  defaultValue={true}
                  render={({ field }) => (
                    <Form.Check
                      type='switch'
                      label='Active'
                      checked={field.value}
                      onChange={(e) => setValue('active', e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2 mt-4' controlId='start_date'>
              <Form.Label column sm={3} className='text-lg-end'>
                Start date
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      className="form-control fs--1"
                      placeholderText="DD-MM-YYYY"
                      dateFormat="dd-MM-yyyy"
                      fixedHeight
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='hr_code'>
              <Form.Label column sm={3} className='text-lg-end'>
                HR Code
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type='text'
                  name='hr_code'
                  placeholder='HR Code'
                  className='fs--1'
                  {...register('hr_code')}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='birthday'>
              <Form.Label column sm={3} className='text-lg-end'>
                Birthday
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name='birthday'
                  render={({field}) => (
                    <MaskedInput
                      mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      className="form-control fs--1"
                      guide={false}
                      placeholder="DD-MM-YYYY"
                      {...field}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='position_group_id'>
              <Form.Label column sm={3} className='text-lg-end'>
                Position group
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name='position_group_id'
                  render={({field}) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      options={positionGroupOptions}
                      value={positionGroupOptions.find(option => option.value === field.value) || null}
                      placeholder='Select...'
                      classNamePrefix='react-select'
                      onChange={selectedOption => setValue('position_group_id', selectedOption.value)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='staff_policy'>
              <Form.Label column sm={3} className='text-lg-end'>
                Staff policy
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name='staff_policy'
                  render={({field}) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      options={staffPolicyOptions}
                      value={staffPolicyOptions.find(option => option.value === field.value) || null}
                      placeholder='Select...'
                      classNamePrefix='react-select'
                      onChange={selectedOption => setValue('staff_policy', selectedOption.value)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2 mt-4' controlId='site_id'>
              <Form.Label column sm={3} className='text-lg-end'>
                Site
              </Form.Label>
              <Col sm={9} md={7}>
                <Flex>
                  <Controller
                    control={control}
                    name='site_id'
                    render={() => (
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        defaultValue={defaultSiteOption}
                        loadOptions={loadSiteOptions}
                        closeMenuOnSelect={true}
                        placeholder='Select...'
                        classNamePrefix='react-select'
                        className='w-100'
                        onChange={handleSiteChange}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Site must be required'
                      }
                    }}
                  />
                  <IconButton
                    icon="plus"
                    size="sm"
                    variant="primary"
                    className="ms-2 flex-shrink-1"
                    onClick={handleNewSite}
                  />
                </Flex>
                <span className='text-danger'>
                  {errors.site_id && errors.site_id.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='department_id'>
              <Form.Label column sm={3} className='text-lg-end'>
                Deparment
              </Form.Label>
              <Col sm={9} md={7}>
                <Flex>
                  <Controller
                    control={control}
                    name='department_id'
                    render={() => (
                      <AsyncSelect
                        key={currentSite}
                        cacheOptions
                        defaultOptions
                        value={departmentOption}
                        loadOptions={loadDepartmentOptions}
                        closeMenuOnSelect={true}
                        placeholder='Select...'
                        classNamePrefix='react-select'
                        className='w-100'
                        onChange={(selectOptions) => { 
                          setValue("department_id", selectOptions.value)
                          setDepartmentOption(selectOptions)
                        }}
                      />
                    )}
                  />
                  <IconButton
                    icon="plus"
                    size="sm"
                    variant="primary"
                    className="ms-2 flex-shrink-1"
                    onClick={handleNewDepartment}
                  />
                </Flex>
                <span className='text-danger'>
                  {errors.department_id && errors.department_id.message}
                </span>
              </Col>
            </Form.Group>

          </Card.Body>
          <Card.Footer className="bg-light d-flex flex-center my-3">
            <Button type="submit" variant="primary" className="mx-2">Save</Button>
            <Button variant="danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Card.Footer>
        </Form>
      </Card>

      <NewSiteModal showModal={showNewSiteModal} setshowModal={setShowNewSiteModal}/>
      <NewDepartmentModal showModal={showNewDepartmentModal} setshowModal={setShowNewDepartmentModal}/>
    </>
  )
};

EmployeeForm.propTypes = {
  title: PropTypes.string.isRequired,
  employee: PropTypes.shape({
    id: PropTypes.number,
    site_id: PropTypes.number,
    site: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    department: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    parent: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  })
};

export default EmployeeForm;
