import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDrivers } from 'redux/booking/car/driverSlice';
import DriverHeader from './driver/DriverHeader';
import DriverList from './driver/DriverList';

const Drivers = () => {
  const [driverSelected, setDriverSelected] = useState([])
  const dispatch = useDispatch()
  const { loading, currentPage, pageSize, activeType } = useSelector((state) => state.booking_setting_car)

  useEffect(() => {
    dispatch(getAllDrivers({active: activeType, page: 1, per_page: pageSize}))
  }, [currentPage, pageSize, activeType]);

  return (
    <>
      <DriverHeader driverSelected={driverSelected} />
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="DriverList">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="DriverList"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Driver list</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="DriverList">
                    <DriverList setDriverSelected={setDriverSelected} /> 
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Drivers
