import { formatDate } from 'helpers/utils';
import React from 'react';
import PropTypes from 'prop-types';

const formatDateInfo = (shops, dateType) => (
  shops.map((shop) => (
    shop[dateType] ? (
      <div key={shop.name}><strong>{shop.name}</strong>: {formatDate(shop[dateType], "dd/MM/yy")}</div>
    ) : null
  ))
);

const ShopFormatRenderer = (props) => {
  const {shops} = props.data

  switch (props.column.colId) {
    case "shops.shop_request":
      return formatDateInfo(shops, "shop_schedule");

    case "shops.shop_schedule":
      return formatDateInfo(shops, "shop_schedule");

    case "shops.shop_release":
      return formatDateInfo(shops, "shop_release");

    case "shops.erc_request":
      return formatDateInfo(shops, "erc_schedule");

    case "shops.erc_schedule":
      return formatDateInfo(shops, "erc_schedule");

    case "shops.erc_release":
      return formatDateInfo(shops, "erc_release");

    default:
      return null;
  }
}

ShopFormatRenderer.propTypes = {
  shops: PropTypes.object.isRequired
}

export default ShopFormatRenderer;