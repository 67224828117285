import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button } from "react-bootstrap"
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportToExcel } from "helpers/utils";
import { toast } from "react-toastify";
import { removeRolePermission } from "redux/authorization/roleSlice";
import { useDispatch } from "react-redux";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";

const RoleDetailInfoPermissionList = () => {
  const {recordData: role} = useSelector((state) => state.authorization_role);
  const [data, setData] = useState(role.permissions ? role.permissions : []);
  const dispatch = useDispatch();

  const columns = useMemo(() => [
    {
      accessor: 'subject_class',
      Header: 'Subject class'
    },
    {
      accessor: 'action',
      Header: 'Action'
    },
    {
      accessor: 'description',
      Header: 'Description'
    }
  ], []);

  useEffect(() => {
    setData(role.permissions)
  }, [role])

  const handleRemoveUser = (selectedRowIds) => {
    let selectedRowsIndex = Object.keys(selectedRowIds)
    if (selectedRowsIndex.length > 0) {
      try {
        selectedRowsIndex = selectedRowsIndex.map(Number)
        const permissions = data.filter((_, index) => selectedRowsIndex.includes(index))
        const permission_ids = permissions.map(permission => permission.id)

        dispatch(removeRolePermission({role, data: {permission_ids}}))
        toast.success(`Permissions was deleted successful.`)
      }
      catch(error) {
        console.log(error.message)
        toast.error(error.message)
      }
    }
    else {
      toast.error("No users selected");
    }
  }

  const handleExportUser = () => {
    ExportToExcel(data, "role_permissions")
  }

  const BulAction = ({selectedRowIds}) => {
    const selectedRowSize = Object.keys(selectedRowIds).length

    return (
      <>
        <Row className="flex-between-center mb-3">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div className="fs--1 mb-0 text-nowrap py-2 py-xl-0">
              {
                selectedRowSize > 0 ?
                'You have selected ' + selectedRowSize + ' rows' 
                : ''
              }
            </div>
          </Col>
          <Col sm="auto">

            <Button size="sm" variant="danger" className="me-1" title="Remove" onClick={() => handleRemoveUser(selectedRowIds)}>
              <FontAwesomeIcon icon="trash-alt" />
            </Button>

            <Button size="sm" title="Export" onClick={() => handleRemoveUser(handleExportUser)}>
              <FontAwesomeIcon icon="download" />
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={10}
        selection
        selectionColumnWidth={25}
      >
        <BulAction table/>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={8} lg={4}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </>
  )
}

export default RoleDetailInfoPermissionList