import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import { addLeaveIhrmBooking, deleteBooking, resetBooking, rollbackBooking, sendBackToHRAdmin, sendBackToAccountant, finalTripToRequesterBooking } from 'redux/booking/travel/travelBookingSlice';
import BookingCancelConfirmation from './BookingCancelConfirmation';
import BookingRemindConfirmation from './BookingRemindConfirmation';
import { FaEdit, FaEllipsisV, FaEnvelope, FaFilePowerpoint, FaSync, FaTrashAlt, FaUndo, FaBan } from 'react-icons/fa';
import { MdNotificationsActive } from "react-icons/md";
import { LuAlarmClock } from "react-icons/lu";

const BookingDetailHeader = ({booking}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  //const [showApproveForm, setShowApproveForm] = useState(false)
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [confirmType, setConfirmType] = useState("delete");
  const [showRemindConfirm, setShowRemindConfirm] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookingUrl = "/booking/travel";
  const bookingId = booking.id;

  const {
    allow_edit,
    allow_delete,
    allow_cancel,
    allow_reset,
    allow_rollback,
    allow_send_to_accountant,
    allow_send_to_admin,
    allow_add_leave_to_ihrm,
    allow_final_trip_to_requestor,
    allow_remind
  } = booking?.permissions || {};

  const handleNewBooking = () => {
    navigate(`/booking/travel/new-travel-request`)
  }

  const handleEditBooking = () => {
    navigate(`${bookingUrl}/${bookingId}/edit`, {state: booking})
  }

  const confirmMsg = (type, message) => {
    setConfirmType(type)
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleDeleteBooking = () => {
    const message = `Are you sure to delete booking ID#<strong class='text-danger'>${bookingId}</strong>?`
    confirmMsg("delete", <span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      switch(confirmType) {
        case "delete":
          dispatch(deleteBooking(booking))
          navigate(`${bookingUrl}/list`)
          break;
        case "reset":
          dispatch(resetBooking(bookingId))
          
          closeConfirmModal()
          break;
      }
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleBookingList = () => {
    navigate(`${bookingUrl}/list`)
  }

  const handleSendBackToHRAdmin = () => {
    dispatch(sendBackToHRAdmin(bookingId))
  }

  const handleSendBackToAccountant = () => {
    dispatch(sendBackToAccountant(bookingId))
  }

  const handleResetBooking = () => {
    const message = `Are you sure to reset booking ID#<strong class='text-primary'>${bookingId}</strong>?`
    confirmMsg("reset", <span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleCancelBooking = () => {
    setShowCancelForm(true)
  }

  const handleRollbackBooking = () => {
    dispatch(rollbackBooking(bookingId))
  }

  const handleAddLeaveToiHRM = () => {
    dispatch(addLeaveIhrmBooking(bookingId))
  }
  
  const handlePrintBooking = () => {
    //dispatch(printPDFBooking(bookingId))
    navigate(`${bookingUrl}/${bookingId}/print`, {state: booking} )
  }

  const handleUpdateToRequester = () => {
    dispatch(finalTripToRequesterBooking(bookingId))
  }

  const handleRemindBooking = () => {
    setShowRemindConfirm(true)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewBooking}
            >
              <span className="d-none d-xl-inline-block ms-1">New travel request</span>
            </IconButton>
  
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="print"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handlePrintBooking}
            >
              <span className="d-none d-xl-inline-block ms-1">
                BTA Form
              </span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBookingList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_edit && (
                    <Dropdown.Item className="mb-2" onClick={handleEditBooking}>
                      <FaEdit /> Edit
                    </Dropdown.Item>
                  )}
                  
                  {allow_send_to_accountant && (
                    <>
                      <Dropdown.Item className="mb-2" onClick={handleSendBackToAccountant}>
                        <FaEnvelope /> Send {booking.status.id === 1 ? '' : 'back'} to accountant
                      </Dropdown.Item>
                      <Dropdown.Divider />
                     </>
                  )}

                  {allow_send_to_admin && (
                    <>
                      <Dropdown.Item className="mb-2" onClick={handleSendBackToHRAdmin}>
                        <FaEnvelope /> Send {booking.status.id === 1 ? '' : 'back'} to HR admin
                      </Dropdown.Item>
                      <Dropdown.Divider />
                     </>
                  )}

                  {allow_add_leave_to_ihrm && (
                    <>
                      <Dropdown.Item className="mb-2 text-primary" onClick={handleAddLeaveToiHRM}>
                        <FaSync /> Sync leave to iHRM
                      </Dropdown.Item>
                      <Dropdown.Divider />
                     </>
                  )}

                  {allow_final_trip_to_requestor && (
                    <>
                      <Dropdown.Item className="mb-2 text-warning" onClick={handleUpdateToRequester}>
                        <MdNotificationsActive /> Final trip to requester
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {allow_reset && (
                    <Dropdown.Item className="mb-2" onClick={handleResetBooking}>
                      <FaFilePowerpoint /> Reset
                    </Dropdown.Item>
                  )}
                  
                  {allow_cancel && (
                    <Dropdown.Item className="mb-2" onClick={handleCancelBooking}>
                      <FaBan /> Cancel
                    </Dropdown.Item>
                  )}

                  {allow_remind && (
                    <Dropdown.Item className="mb-2 text-primary" onClick={handleRemindBooking}>
                      <LuAlarmClock /> Remind approval
                    </Dropdown.Item>
                  )}

                  {allow_rollback && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleRollbackBooking}>
                        <FaUndo /> Rollback
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger" onClick={handleDeleteBooking}>
                        <FaTrashAlt className="text-danger" /> Delete
                      </Dropdown.Item>
                    </>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message={messageConfirm}
      />

      {showCancelForm && (
        <BookingCancelConfirmation 
          showModal={showCancelForm}
          setshowModal={setShowCancelForm}
          booking={booking}
        />
      )}
     
      {showRemindConfirm && (
        <BookingRemindConfirmation
          showModal={showRemindConfirm}
          setshowModal={setShowRemindConfirm}
          booking={booking}
        />
      )}
      
    </>
  );
};

BookingDetailHeader.propTypes = {
  booking: PropTypes.object
}

export default BookingDetailHeader;