import React from 'react';
import logo from "./../../../../assets/img/pebsteel.png"
import { Card, Image, Form, Row, Col, Spinner } from "react-bootstrap";
import { formatDate, formatNumber } from "helpers/utils";
import generatePDF from 'react-to-pdf';
import WaiverHeaderPrint from "./WaiverHeaderPrint";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { CRM_WAIVER_FORM_ISO } from "app_config";
import "assets/scss/print-form.scss"
import { useState } from "react";
import { toast } from 'react-toastify';

const WaiverPrint = () => {
  const location = useLocation()
  const { waiver } = location.state
  const { project, buildings } = waiver

  const [showExporting, setExporting] = useState(false)

  const options = {
    filename: `waiver-${waiver.id}.pdf`,
    // default is `save`
    //method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    //resolution: Resolution.HIGH,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: 20,
       // default is 'A4'
       format: 'A4',
       // default is 'portrait'
       orientation: 'portrait',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/png',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
  }

  const getTargetElement = () => document.getElementById('waiver-form');

  const handleDownload = () => {
    setExporting(true)
    generatePDF(getTargetElement, options)
      .then(() => {
        setExporting(false);
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF:', error)
        setExporting(false)
      })
  }

  return (
    <Card className="m-2">
      <WaiverHeaderPrint download={handleDownload}/>

      {showExporting && (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" role="status" variant="primary" className="me-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner> Downloading...
        </div>
      )}


      <div id="waiver-form" className="m-4 print-form text-1000">
        <table className="fs--1">
          <tr>
            <td colSpan="4" className="text-center">
              <div className="fs-2 fw-bold mb-2">WAIVER FORM</div>
            </td>
            <td className="text-center py-4">
              <Image
                fluid
                className="fit-cover rounded"
                src={logo}
                alt="Logo Pebsteel"
                width="120"
                height="120"
              />
            </td>
          </tr>
          <tr className="fw-semi-bold">
            <td colSpan="2" className="text-end">No.:</td>
            <td colSpan="3">{waiver.waiver_number}</td>
          </tr>
          <tr>
            <td className="fw-semi-bold">1. Project name:</td>
            <td colSpan="2" className="fw-semi-bold">
              <Link to={`/crm/projects/${project?.id}`}>{project?.name}</Link>
            </td>
            <td>Customer:</td>
            <td className="fw-semi-bold text-primary">{project?.customer.name}</td>
          </tr>
          <tr>
            <td>
              <span className="mx-2">Quotation / Job No:</span>
            </td>
            <td colSpan="4" className="text-primary">{project.j_number ? project.j_number : project.q_number}</td>
          </tr>
          <tr>
            <td><span className="mx-2">Main contract value:</span></td>
            <td className="text-primary">${formatNumber(waiver.main_contract_value)}</td>
            <td>1st Addendum value:</td>
            <td className="text-primary">${formatNumber(waiver.addendum_value_1st)}</td>
            <td>
              <Row className="gx-0">
                <Col>2nd Addendum value:</Col>
                <Col sm="3" className="text-primary">${formatNumber(waiver.addendum_value_2st)}</Col>
              </Row>
            </td>
          </tr>
          <tr>
            <td className="mx-2">
              <span className="mx-2">Main contract weight:</span>
            </td>
            <td className="text-primary">{formatNumber(waiver.main_contract_weight)} M.T</td>
            <td>1st Addendum weight:</td>
            <td className="text-primary">{formatNumber(waiver.addendum_weight_1st)} M.T</td>
            <td>
              <Row className="gx-0">
                <Col>2nd Addendum weight:</Col>
                <Col sm="3" className="text-primary">{formatNumber(waiver.addendum_weight_2st)} M.T</Col>
              </Row>
            </td>
          </tr>

          <tr className="fw-semi-bold">
            <td colSpan="5">
              2. Payment history (for repeat customer):
            </td>
          </tr>

          <tr className="fw-semi-bold">
            <td colSpan="3">
              3. Job Status:
            </td>
            <td colSpan="2">
              4. Payment term:
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Row className="mb-2 mx-1">
                <Col>
                  Contract
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    checked={waiver.job_status_contract}
                  />
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  LOI
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    checked={waiver.job_status_loi}
                  />
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  JAF
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    checked={waiver.job_status_jaf}
                  />
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  BOM & JAF comparison ( For releasing shipment waiver)
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    checked={waiver.job_status_bom_jaf_comparison}
                  />
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>Others</Col>
                <Col sm={4}></Col>
              </Row>
              <Row>
                <Col>
                  <span className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.job_status_other }}></span>  
                </Col>
              </Row>
            </td>
            <td colSpan="2">
              <Row className="mb-2 mx-1">
                <Col>
                  1. Down payment
                </Col>
                <Col sm={4}>
                  <span className="text-primary">{waiver.payment_term_dp}%</span>
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  2. Second payment after delivery of M/F
                </Col>
                <Col sm={4}>
                  <span className="text-primary">{waiver.payment_term_second}%</span>
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  3. Third payment after deilivery of sheeting
                </Col>
                <Col sm={4}>
                  <span className="text-primary">{waiver.payment_term_third}%</span>
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  4. Four payment after completion of erection
                </Col>
                <Col sm={4}>
                  <span className="text-primary">{waiver.payment_term_four}%</span>
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  5. Final payment
                </Col>
                <Col sm={4}>
                  <span className="text-primary">{waiver.payment_term_final}%</span>
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col>
                  Percentage of receiving:
                </Col>
                <Col sm={4}>
                  <span className="text-primary">{waiver.percentage_of_receiving || 0.0}%</span>
                </Col>
              </Row>
              <Row className="mb-2 mx-1">
                <Col> Others</Col>
                <Col sm={4}></Col>
              </Row>
              <Row>
                <Col>
                  <span className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.payment_term_other }}></span>
                </Col>
              </Row>
            </td>
          </tr>

          <tr className="fw-semi-bold">
            <td colSpan="5">
              5. Release for:
            </td>
          </tr>
          <tr>
            <td colSpan="5">
             <Row className="fs--1 mx-1">
              <Col>
                <Form.Check
                  type="checkbox"
                  checked={waiver.released_for_anchor_bolt}
                  label="Anchor bolt delivery"
                />
                <Form.Check
                  type="checkbox"
                  checked={waiver.released_for_fabrication}
                  label="Fabrication"
                />
                <Form.Check
                  type="checkbox"
                  checked={waiver.released_for_shipment}
                  label="Shipment"
                />
                <Form.Check
                  type="checkbox"
                  checked={waiver.released_for_loading}
                  label="Loading"
                />
                <Form.Check
                  type="checkbox"
                  checked={waiver.released_for_order_material}
                  label="Order material"
                />
                <div className="text-primary">{waiver.released_for_order_material}</div>
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  checked={waiver.released_for_other}
                  label="Others (specify)"
                />

                <span className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.released_for_other_specify }}></span> 
              </Col>
            </Row>
            </td>
          </tr>

          <tr className="fw-semi-bold">
            <td colSpan="5">
              6. Reason for waiver:
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <table>
                <tr className="bg-light my-2">
                  <td width="5%">No.</td>
                  <td width="10%">Buildings</td>
                  <td width="85%">Reason</td>
                </tr>

                {buildings && buildings.map((building, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="fw-semi-bold text-primary">{building.name}</td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: building.reason}}></span> 
                    </td>
                  </tr>
                ))}
              </table>
            </td>
          </tr>

          <tr className="fw-semi-bold">
            <td colSpan="2">
              7. Commitment date to clear waiver:
            </td>
            <td colSpan="3" className="text-primary">
              {formatDate(waiver.commitment_date)}
            </td>
          </tr>
          <tr className="text-center">
            <td className="border" width="20%">Requestor</td>
            <td className="border" width="20%">
              <div className="p-1">
                General Manager/ Chief Rep
                <div className="text-primary m-0 p-0">(Approver)</div>
              </div>
            </td>
            <td className="border" width="20%">
              <div className="p-1">
                GM - Operation
                <div className="text-primary m-0 p-0">(Approver)</div>
              </div>
            </td>
            <td className="border" width="20%">
              <div className="p-1">
                Credit Controller
                <div className="text-primary m-0 p-0">(Verifier)</div>
              </div>
            </td>
            <td className="border" width="20%">
              <div className="p-1">
                Finance Controller
                <div className="text-primary m-0 p-0">(Approver)</div>
              </div>
            </td>
          </tr>
          <tr className="text-center">
            <td className="border">
              <div className="text-primary my-3">{waiver.request?.by}</div>
              <div>Date: <span className="text-primary">{waiver.request?.date}</span></div>
            </td>
            <td className="border">
              <div className="text-primary my-3">{waiver.general_sales_manager?.by}</div>
              {waiver.general_sales_manager?.date && (
                <div>Date: <span className="text-primary">{waiver.general_sales_manager.date}</span></div>
              )}
            </td>
            <td className="border">
              <div className="text-primary my-3">{waiver.general_manager_operation?.by}</div>
              {waiver.general_manager_operation?.date && (
                <div>Date: <span className="text-primary">{waiver.general_manager_operation.date}</span></div>
              )}
            </td>
            <td className="border">
              <div className="text-primary my-3">{waiver.credit_controller?.by}</div>
              {waiver.credit_controller?.date && (
                <div>Date: <span className="text-primary">{waiver.credit_controller.date}</span></div>
              )}
            </td>
            <td className="border">
              <div className="text-primary my-3">{waiver.finance_controller?.by}</div>
              {waiver.finance_controller?.date && (
                <div>Date: <span className="text-primary">{waiver.finance_controller.date}</span></div>
              )}
            </td>
          </tr>
          
        </table>

        <div className="my-2 fs--1">{CRM_WAIVER_FORM_ISO}</div>
      </div>

    </Card>
  )
}

export default WaiverPrint