import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AgPagination from "components/ag-grid/AgPagination";
import Avatar from "components/common/Avatar";
import createMarkup from "helpers/createMarkup";
import DateTimeToText from "helpers/dateTimetoText";
import { calPaginatorRecord } from "helpers/paginator";
import { formatBookingDate, joinWithArray, objectSerializer } from "helpers/utils";
import React, { useState, useEffect, useCallback } from "react";
import { Card, Row, Col, Form, InputGroup, Button, Collapse, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getActivityLog } from "redux/crm/project/pifSlice";
import { useParams } from "react-router-dom";

const PIFActivities = () => {
  const params        = useParams();
  const projectId     = params?.project_id;
  const buildingId    = params?.building_id;
  const adaptationId  = params?.adaptation_id;
  const pifId         = params?.id;

  const [activities, setActivities] = useState([]);
  const [paginate, setPaginate] = useState({
    totalRows: 0,
    totalPagesCount: 1,
    pageIndex: 1,
    pageSize: 20,
    from: 1,
    to: 20
  });
  const [filterValue, setFilterValue] = useState('');
  const [openStates, setOpenStates] = useState(Array(activities.length).fill(false));

  const dispatch = useDispatch();

  const emoji = '🕒';

  useEffect(() => {
    fetchActivityLog();
  }, [params]);

  const fetchActivityLog = useCallback(async () => {
    try {
      const params = {q: filterValue, page: paginate.pageIndex, per_page: paginate.pageSize}

      const response = await dispatch(getActivityLog({
        projectId,
        buildingId,
        adaptationId,
        pifId,
        params
      }))

      const data          = response.payload
      const activeLogData = data.rows
      const total_rows    = data.total_rows

      setActivities(objectSerializer(activeLogData))

      const calRecords         = calPaginatorRecord(paginate.pageIndex, paginate.pageSize, total_rows)
      paginate.totalRows       = total_rows
      paginate.totalPagesCount = calRecords.totalPagesCount
      paginate.fromRecord      = calRecords.beginRecords
      paginate.toRecord        = calRecords.lastRecords
    }
    catch (error) {
      toast.error(error.message)
    }
  }, [dispatch, params, filterValue, paginate.pageIndex, paginate.pageSize]);
  
  const handlePageChange = ((page, pageSize) => {
    setPaginate({...paginate, pageIndex: page, pageSize: pageSize})
  })

  const handleSubmitSearch = (e) => {
    e.preventDefault()
    fetchActivityLog()
  }

  const toggleOpenState = (index) => {
    setOpenStates((prevOpenStates) => {
      const newOpenStates = [...prevOpenStates];
      newOpenStates[index] = !newOpenStates[index];
      return newOpenStates;
    });
  };

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row>
          <Col>
            <h5 className="mb-1 mb-md-0">Activities history</h5>
          </Col>
          <Col lg={4}>
            <Form onSubmit={handleSubmitSearch}>
              <InputGroup>
                <Form.Control placeholder="Search..." size="sm" onChange={(e) => setFilterValue(e.target.value) } />
                <Button size="sm" type="submit">
                  <FontAwesomeIcon icon="search" className="me-1" />
                  Search
                </Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {activities && activities.map((activity, index) => {
          return (
            <div
              key={index}
              className="border-x-0 border-bottom-0 border-300"
            >
              <div className="notification rounded-0 border-x-0 border-300 border-bottom-0">
                {activity.avatar && (
                  <div className="notification-avatar">
                    <Avatar {...activity.avatar} className="me-3" />
                  </div>
                )}
                <div className="notification-body">
                  <h6 className="text-primary text-capitalize">{activity.action.replace(/_/g, ' ')}</h6>
                  <p className="mb-1" dangerouslySetInnerHTML={createMarkup(activity.comment)} />
                  <span className="notification-time">
                    {emoji && (
                      <span className="me-2" role="img" aria-label="Emoji">
                        {emoji}
                      </span>
                    )} 
                    <DateTimeToText datetime={activity.created_at} /> | by {activity.created_by}
                  </span>
                  {activity.audited_changes && Object.keys(activity.audited_changes).length !== 0 && (
                    <div className="mt-2">
                      <Link onClick={() => toggleOpenState(index)}>
                        View Detail
                      </Link>
                      <Collapse in={openStates[index]}>
                        <div className="border rounded">
                          <div className='p-x1 table-responsive'>
                            <Table striped>
                              <thead className="bg-soft-primary text-center">
                                <th scope="col" className="text-start">Attributes changed</th>
                                <th scope="col" className="text-start">From</th>
                                <th scope="col" className="text-start">To</th>
                              </thead>
                              <tbody>
                                {Object.entries(activity.audited_changes).map(([fieldName, fieldChanges]) => {
                                  const isDateField = fieldName === "Date of travel" || fieldName === "Date of return";
                                  const formatField = isDateField ? formatBookingDate : joinWithArray;

                                  const formatValue = (value) => {
                                    if (typeof value === 'object' && value !== null) {
                                      return JSON.stringify(value);
                                    }
                                    return formatField(value);
                                  };

                                  return (
                                    <tr key={fieldName}>
                                      <td className="text-start">{fieldName}</td>
                                      <td className="text-start text-primary">
                                        {formatValue(fieldChanges[0]) === true ? "true" : formatValue(fieldChanges[0]) === false ? "false" : formatValue(fieldChanges[0])}
                                      </td>
                                      <td className="text-start text-danger">
                                        {formatValue(fieldChanges[1]) === true ? "true" : formatValue(fieldChanges[1]) === false ? "false" : formatValue(fieldChanges[1])}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}

        <Row className="m-3">
          <Col>
            <AgPagination
              totalRows={paginate.totalRows}
              totalPagesCount={paginate.totalPagesCount}
              pageChange={handlePageChange}
              pageSize={paginate.pageSize}
              from={paginate.fromRecord}
              to={paginate.toRecord}
              itemsPages={["10", "20", "50"]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default PIFActivities;