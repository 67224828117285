import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { assignUserRole } from "redux/authorization/userSlice";
import { useDispatch } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import Flex from "components/common/Flex";
import RoleForm from "components/authorization/role/RoleForm";
import { FaUserShield } from "react-icons/fa";
import { getRolesbyUser } from "redux/authorization/userSlice";
import { useSelector } from "react-redux";
import Select from 'react-select';
import PropTypes from 'prop-types';

const AssignRole = ({userId, data=[]}) => {
  const { handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [showNewRoleForm, setShowNewRoleForm] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const {rolesByUserData} = useSelector(state => state.authorization_user);

  useEffect(() => {
    dispatch(getRolesbyUser({user_id: userId}));
  }, [userId, data]);

  useEffect(() => {
    const selected = data.length > 0 && data.map(item => ({value: item.id, label: item.name}));
    setSelectedRoles(selected);
  }, [userId, data])

  const onSubmit = (data) => {
    console.log(data)

    try {
      if (userId) {
        dispatch(assignUserRole({userId, data}))
      }
    }
    catch (error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const rolesOptions = rolesByUserData && rolesByUserData.map(item => ({value: item.id, label: item.name}));

  const defaultRolesOption = useMemo(() => {
    if (data) {
      return data.map((item) => ({value: item.id, label: item.name}));
    }
    return null;
  }, [data]);

  const handleChangeRole = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('role_ids', selectedOptionsValues, { shouldValidate: true })
    setSelectedRoles(selectedOptions)
  }

  const handleNewRole = (e) => {
    e.preventDefault();
    setShowNewRoleForm(true)
  }

  return (
    <>
      <div className="mb-2">Assigning roles access to the system</div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2 fs--1">
          <Select
            closeMenuOnSelect={false}
            options={rolesOptions}
            defaultValue={defaultRolesOption}
            value={selectedRoles}
            placeholder='Select...'
            classNamePrefix='react-select'
            isMulti
            onChange={handleChangeRole}
          />
        </div>

        <div className="d-flex justify-content-between">
          <div>Total {data?.length} roles assigned.</div>
          <Button
            type="submit"
            size="sm"
            variant="falcon-default"
          >
            <span><FaUserShield /> {data.length > 0 ? "Update" : "Add"} Role</span>
          </Button>
        </div>
      </Form>

      <Flex className="mt-4" justifyContent="end">
        <Link onClick={(e) => handleNewRole(e)}>Create new Role</Link>
      </Flex>

      {showNewRoleForm && (
        <RoleForm
          showModal={showNewRoleForm}
          setshowModal={setShowNewRoleForm}
          title="Create a new Role"
        />
      )}
    </>
  )
};

AssignRole.propTypes = {
  userId: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
}

export default AssignRole;