import { Collapse, Accordion, Card, Col, Row, Form, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react";
import { formatDate, formatNumber } from "helpers/utils";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { MdLock, MdOutlineTransferWithinAStation, MdVerified } from "react-icons/md";
import UploadDocumentDirect from "components/upload/UploadDocumentDirect";
import { useDispatch } from "react-redux";
import { removeDocument, uploadDocument } from "redux/crm/project/waiverSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CRM_WAIVER_FORM_ISO } from "app_config";
import PropTypes from "prop-types";

const WaiverDetailForm = ({waiver}) => {
  const [activeKey, setActiveKey] = useState(["0", "2", "3", "4", "5", "6", "7", "8"])
  const {project, buildings, documents, permissions} = waiver
  const {
    allow_view_document,
    allow_upload_document,
    allow_download_document,
    allow_remove_document
  } = permissions || {}

  const dispatch = useDispatch()

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const handleUploadDocument = (data) => {
    try {
      dispatch(uploadDocument({
        projectId: project.id,
        waiverId: waiver.id,
        data
      }))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleRemoveDocument = (documentId) => {
    dispatch(removeDocument({
      projectId: project.id,
      waiverId: waiver.id,
      documentId }))
  }

  return (
    <>
      <Collapse in={true}>
        <Accordion
          id="waiver-detail"
          className="border rounded overflow-hidden fs--1"
          alwaysOpen
          flush
          activeKey={activeKey}
          onSelect={handleAccordionSelect}
        >
          <Card className="rounded-0">

              {/* 1. Project Information */}
              <Accordion.Item eventKey="0" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        1. Project Information
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Row className="gx-2 mb-2">
                      <Col>
                        <Row className="gx-2">
                          <Col sm={4}>Project name:</Col>
                          <Col>
                            <Link to={`/crm/projects/${project?.id}`}>{project?.name}</Link>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="gx-2">
                          <Col sm="auto">Customer name:</Col>
                          <Col>
                            <Link to={`/crm/customers/${project?.customer?.id}`}>{project?.customer?.name}</Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="gx-2 mb-2">
                      <Col>
                        <Row className="gx-2">
                          <Col sm={2}>Quotation / Job No:</Col>
                          <Col>
                            <Link to={`/crm/projects/${project?.id}`}>{project?.j_number ? project?.j_number : project?.q_number}</Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="gx-2 mb-2">
                      <Col>
                        <Row className="gx-2">
                          <Col sm={6}>Main contract value:</Col>
                          <Col>
                            <span className="text-primary">${formatNumber(waiver.main_contract_value)}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="gx-2">
                          <Col sm={6}>1st Addendum value:</Col>
                          <Col>
                            <span className="text-primary">${formatNumber(waiver.addendum_value_1st)}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="gx-2">
                          <Col sm={5}>2st Addendum value:</Col>
                          <Col>
                            <span className="text-primary">${formatNumber(waiver.addendum_value_2st)}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="gx-2 mb-2">
                      <Col>
                        <Row className="gx-2">
                          <Col sm={6}>Main contract weight:</Col>
                          <Col>
                            <span className="text-primary">{formatNumber(waiver.main_contract_weight)} M.T</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="gx-2">
                          <Col sm={6}>1st Addendum weight:</Col>
                          <Col>
                            <span className="text-primary">${formatNumber(waiver.addendum_weight_1st)} M.T</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="gx-2">
                          <Col sm={5}>2st Addendum weight:</Col>
                          <Col>
                            <span className="text-primary">${formatNumber(waiver.addendum_weight_2st)} M.T</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* 2. Payment history (for repeat customer) */}
              <Accordion.Item eventKey="1" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        2. Payment history (for repeat customer)
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <div className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.payment_history }}></div> 
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* 3. Job Status: */}
              <Accordion.Item eventKey="2" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        3. Job Status:
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Row className="mb-2">
                      <Col>
                        Contract
                      </Col>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          checked={waiver.job_status_contract}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        LOI
                      </Col>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          checked={waiver.job_status_loi}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        JAF
                      </Col>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          checked={waiver.job_status_jaf}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        BOM & JAF comparison ( For releasing shipment waiver)
                      </Col>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          checked={waiver.job_status_bom_jaf_comparison}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        Others
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.job_status_other }}></span>  
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* 4. Payment term: */}
              <Accordion.Item eventKey="3" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        4. Payment term:
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Row className="mb-2">
                      <Col>
                        1. Down payment
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary">{waiver.payment_term_dp}%</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        2. Second payment after delivery of M/F
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary">{waiver.payment_term_second}%</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        3. Third payment after deilivery of sheeting
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary">{waiver.payment_term_third}%</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        4. Four payment after completion of erection
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary">{waiver.payment_term_four}%</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        5. Final payment
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary">{waiver.payment_term_final}%</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        Percentage of receiving:
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary">{waiver.percentage_of_receiving || 0.0}%</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        Others
                      </Col>
                      <Col sm={8}>
                        <span className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.payment_term_other }}></span>  
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* 5. Release for : */}
              <Accordion.Item eventKey="4" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        5. Release for :
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={waiver.released_for_anchor_bolt}
                          label="Anchor bolt delivery"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={waiver.released_for_fabrication}
                          label="Fabrication"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={waiver.released_for_shipment}
                          label="Shipment"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={waiver.released_for_loading}
                          label="Loading"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={waiver.released_for_order_material}
                          label="Order material"
                        />
                        <div className="text-primary">{waiver.released_for_order_material}</div>
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={waiver.released_for_other}
                          label="Others (specify)"
                        />

                        <span className="text-primary" dangerouslySetInnerHTML={{ __html: waiver.released_for_other_specify }}></span> 
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* 6. Reason for waiver:*/}
              <Accordion.Item eventKey="5" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        6. Reason for waiver:
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Table bordered striped hover responsive>
                      <thead>
                        <tr>
                          <th className="border" width="5%">No.</th>
                          <th className="border" width="10%">Building</th>
                          <th className="border" width="85%">Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buildings && buildings.map((building, index) => (
                          <tr key={index}>
                            <td className="border">{index + 1}</td>
                            <td className="border">{building.name}</td>
                            <td className="border">
                              <span dangerouslySetInnerHTML={{ __html: building.reason}}></span> 
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3" className="border">
                            <div className="fw-semi-bold text-primary">GM Operation comment:</div>
                            <div className="ms-3 mt-1">
                              {waiver.general_manager_operation_remark ? (
                                <div className="bg-soft-warning p-3 rounded" dangerouslySetInnerHTML={{ __html: waiver.general_manager_operation_remark }}></div> 
                              ) : "N/A"}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="border">
                            <div className="fw-semi-bold text-primary">CSD comment:</div>
                            <div className="ms-3 mt-1">
                              {waiver.csd_remark ? (
                                <div className="bg-soft-warning p-3 rounded" dangerouslySetInnerHTML={{ __html: waiver.csd_remark }}></div> 
                              ) : "N/A"}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <Card className="p-4 mt-2 border">
                      <Row className="mb-2">
                        <Col>
                          <Row>
                            <Col className="fw-semi-bold" sm={4}>Waiver value</Col>
                            <Col className="text-primary">${formatNumber(waiver.waiver_value)}</Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="fw-semi-bold" sm={4}>Waiver weight</Col>
                            <Col className="text-primary">{formatNumber(waiver.waiver_weight)} M.T</Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col sm={2}>CSE incharges</Col>
                        <Col className="text-primary">{waiver.cse_incharge}</Col>
                      </Row>

                      <div className="d-flex justify-content-center">
                        <div className="border border-dashed border-bottom my-3 w-100"></div>
                      </div>

                      <Row>
                        <Col className="border-end">
                          <h6 className="fw-semi-bold mb-2 text-info"><MdVerified /> Verified</h6>
                          <div className="ms-3">
                            <Row className="mb-2">
                              <Col>By</Col>
                              <Col className="text-primary">{waiver.verify?.by}</Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>Date</Col>
                              <Col className="text-primary">{formatDate(waiver.verify?.date)}</Col>
                            </Row>
                            <Row className="mb-2 text-danger">
                              <Col>Needed to reverify by Sales</Col>
                              <Col>
                                <Form.Check checked={waiver.verify?.need_reverify_by_sales} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col className="border-end">
                          <h6 className="ffw-semi-bold mb-2 text-warning"><MdOutlineTransferWithinAStation /> Transfered</h6>
                          <div className="ms-3">
                            <Row className="mb-2">
                              <Col>To CSE</Col>
                              <Col>
                                <Form.Check checked={waiver.transfer?.transfered_to_cse_incharge} />
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>By</Col>
                              <Col className="text-primary">{waiver.transfer?.by}</Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>Date</Col>
                              <Col className="text-primary">{formatDate(waiver.transfer?.date)}</Col>
                            </Row>
                          </div>
                        </Col>
                        <Col>
                          <h6 className="ffw-semi-bold mb-2 text-success"><MdLock /> Closed</h6>
                          <div className="ms-3">
                            <Row className="mb-2">
                              <Col>By</Col>
                              <Col className="text-primary">{waiver?.close?.by}</Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>Date</Col>
                              <Col className="text-primary">{formatDate(waiver?.close?.date)}</Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* 7. Commitment date to clear waiver: */}
              <Accordion.Item eventKey="6" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        <span>7. Commitment date to clear waiver:</span>
                        <span className="text-primary ms-3">{formatDate(waiver.commitment_date)}</span>
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Table bordered responsive>
                      <tr className="text-center">
                        <td className="border" width="20%">Requestor</td>
                        <td className="border" width="20%">
                          <div className="p-1">
                            General Manager/ Chief Rep
                            <div className="text-primary m-0 p-0">(Approver)</div>
                          </div>
                        </td>
                        <td className="border" width="20%">
                          <div className="p-1">
                            GM - Operation
                            <div className="text-primary m-0 p-0">(Approver)</div>
                          </div>
                        </td>
                        <td className="border" width="20%">
                          <div className="p-1">
                            Credit Controller
                            <div className="text-primary m-0 p-0">(Verifier)</div>
                          </div>
                        </td>
                        <td className="border" width="20%">
                          <div className="p-1">
                            Finance Controller
                            <div className="text-primary m-0 p-0">(Approver)</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td className="border">
                          <div className="text-primary mt-1">{waiver.request?.by}</div>
                          <div>Date: <span className="text-primary">{waiver.request?.date}</span></div>
                        </td>
                        <td className="border">
                          <div className="text-primary mt-1">{waiver.general_sales_manager?.by}</div>
                          {waiver.general_sales_manager?.date && (
                            <div>Date: <span className="text-primary">{waiver.general_sales_manager.date}</span></div>
                          )}
                        </td>
                        <td className="border">
                          <div className="text-primary mt-1">{waiver.general_manager_operation?.by}</div>
                          {waiver.general_manager_operation?.date && (
                            <div>Date: <span className="text-primary">{waiver.general_manager_operation.date}</span></div>
                          )}
                        </td>
                        <td className="border">
                          <div className="text-primary mt-1">{waiver.credit_controller?.by}</div>
                          {waiver.credit_controller?.date && (
                            <div>Date: <span className="text-primary">{waiver.credit_controller.date}</span></div>
                          )}
                        </td>
                        <td className="border">
                          <div className="text-primary mt-1">{waiver.finance_controller?.by}</div>
                          {waiver.finance_controller?.date && (
                            <div>Date: <span className="text-primary">{waiver.finance_controller.date}</span></div>
                          )}
                        </td>
                      </tr>
                    </Table>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              {/* Attachemnts */}
              <Accordion.Item eventKey="7" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900 text-primary">
                        Attachemnts
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Row>
                      <Col>
                        {allow_upload_document && (
                          <UploadDocumentDirect uploadDocument={(data) => handleUploadDocument(data)} />
                        )}
                      </Col>
                      <Col>
                        <div className="mt-4">
                          {documents && documents.map(attachment => (
                            <DocumentAttachment
                              {...attachment}
                              key={attachment.id}
                              isTable
                              allowView={allow_view_document}
                              allowRemove={allow_remove_document}
                              allowDownload={allow_download_document}
                              handleRemoveAttachment={(documentId) => handleRemoveDocument(documentId)}
                            />
                            ))
                          }
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
          </Card>
        </Accordion>
      </Collapse>
      <div className="my-2 fs--1">{CRM_WAIVER_FORM_ISO}</div>
    </>
  )
};

WaiverDetailForm.propTypes = {
  waiver: PropTypes.shape({
    id: PropTypes.number.isRequired,
    main_contract_value: PropTypes.number,
    addendum_value_1st: PropTypes.number,
    addendum_value_2st: PropTypes.number,
    main_contract_weight: PropTypes.number,
    addendum_weight_1st: PropTypes.number,
    addendum_weight_2st: PropTypes.number,
    payment_history: PropTypes.string,
    job_status_contract: PropTypes.string,
    job_status_loi: PropTypes.string,
    job_status_jaf: PropTypes.string,
    job_status_bom_jaf_comparison: PropTypes.string,
    job_status_other: PropTypes.string,
    payment_term_dp: PropTypes.string,
    payment_term_second: PropTypes.string,
    payment_term_third: PropTypes.string,
    payment_term_four: PropTypes.string,
    payment_term_final: PropTypes.string,
    percentage_of_receiving: PropTypes.string,
    payment_term_other: PropTypes.string, 
    released_for_anchor_bolt: PropTypes.string,
    released_for_fabrication: PropTypes.string,
    released_for_shipment: PropTypes.string,
    released_for_loading: PropTypes.string,
    released_for_order_material: PropTypes.string,
    released_for_other: PropTypes.string,
    released_for_other_specify: PropTypes.string,
    general_manager_operation_remark: PropTypes.string,
    csd_remark: PropTypes.string,
    waiver_value: PropTypes.number,
    waiver_weight: PropTypes.number,
    cse_incharge: PropTypes.string,
    verify: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      need_reverify_by_sales: PropTypes.bool
    }),
    transfer: PropTypes.shape({
      transfered_to_cse_incharge: PropTypes.bool,
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)
    }),
    close: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)
    }),
    commitment_date: PropTypes.instanceOf(Date),
    request: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)

    }),
    general_sales_manager: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)
    }),
    general_manager_operation: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)
    }),
    credit_controller: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)
    }),
    finance_controller: PropTypes.shape({
      by: PropTypes.string,
      date: PropTypes.instanceOf(Date)
    }),

    project: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      q_number: PropTypes.string,
      j_number: PropTypes.string,
      customer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    }),
    buildings: PropTypes.object.isRequired,
    documents: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired
  })
};

export default WaiverDetailForm;