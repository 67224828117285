import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkRenderer = (props) => {
  return (
    <Link to={`/crm/sales-units/${props.value}`}>{props.value}</Link>
  );
};

LinkRenderer.propTypes = {
  value: PropTypes.number
}

export default LinkRenderer;
