import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import { Card, Dropdown, Row, Col } from "react-bootstrap"
import { formatBookingDate, formatNumber, formatNumberCurrency } from "helpers/utils";
import GoogleMapsLink from "helpers/googleMapsLink";
import BookingAttachment from "../../BookingAttachment";
import HotelUploadAttachmentDirectly from "./HotelUploadAttachmentDirectly";
import PropTypes from 'prop-types';

const HotelItem = ({booking, hotel, editHotelBooking, deleteHotelBooking, handleRemoveAttachment}) => {
  const hotelId = hotel.id

  return (
    <Card className="m-4" key={hotel.id}>
      {/* <Background
        image={corner4}
        className="bg-card d-none d-sm-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      /> */}

      <Card.Header className="mt-2">
        <Flex justifyContent="between">
          <div>
            <div className="h5 fw-semi-bold ls text-uppercase text-primary"><FontAwesomeIcon icon="hotel" /> {hotel.name} (ID#{hotel.id})</div>
            <div className="small mt-2"><FontAwesomeIcon icon="map" /> <GoogleMapsLink address={hotel.address}/></div>
          </div>
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item className="mb-1" onClick={editHotelBooking}>
                  <FontAwesomeIcon icon="edit" /> Edit
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-1" onClick={deleteHotelBooking}>
                  <FontAwesomeIcon icon="trash-alt" /> Delete
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Flex>
      </Card.Header>
      <Card.Body className="m-4">
        <Row className="mb-3">
          <Col lg xxl={6}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Hotel Information
            </h6>
            <Row  className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Hotel name</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{hotel.name}</p>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Check in</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{formatBookingDate(hotel.check_in_time)}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Check out</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{formatBookingDate(hotel.check_out_time)}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Tel</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">
                  <a href={`tel:${hotel.tel}`}>{hotel.tel}</a>
                </p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Address</p>
              </Col>
              <Col>
                <GoogleMapsLink address={hotel.address}/>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Created date</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{hotel.created_date}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Created by</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{hotel.created_by}</p>
              </Col>
            </Row>
          </Col>
          <Col lg className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Expense
            </h6>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold mb-1 fs--1">Amount currency</p>
              </Col>
              <Col>
                <p className="mb-1 text-primary fs--1">{formatNumberCurrency(hotel.cost_currency, hotel.currency)}</p>
              </Col>
            </Row>

            {hotel && hotel.currency === "USD" && (
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Exchange rate</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{formatNumber(hotel.exchange_rate)} VND</p>
                </Col>
              </Row>
            )}
            
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Amount</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{formatNumberCurrency(hotel.cost, "VND")}</p>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Note</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary" dangerouslySetInnerHTML={{ __html: hotel.notes }}></p>
              </Col>
            </Row>

          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Attachments
            </h6>

            <Row>
              <Col>
                <HotelUploadAttachmentDirectly booking={booking} hotel={hotel} />
              </Col>
              <Col>
                {hotel.documents.length > 0 && (
                  <div className="pb-2">
                    <Flex direction="row" wrap="wrap" justifyContent="arround">
                      {hotel.documents.map(attachment => (
                        <BookingAttachment
                          {...attachment}
                          key={attachment.id}
                          handleRemoveAttachment={(documentId) => handleRemoveAttachment(hotelId, documentId)}
                        />
                      ))}
                    </Flex>
                  </div>
                )} 
              </Col>
            </Row>

          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
};

HotelItem.propTypes = {
  booking: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  editHotelBooking: PropTypes.func.isRequired,
  deleteHotelBooking: PropTypes.func.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired
}


export default HotelItem;