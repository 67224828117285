import { Card, Row, Col, Dropdown, Table, Tabs, Tab } from "react-bootstrap"
import PropTypes from 'prop-types'
import Flex from "components/common/Flex"
import { formatNumber } from "helpers/utils"
import BookingUpdateExpenseEmployeeModal from './BookingUpdateExpenseEmployeeModal'
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { recalculateExpenseBooking } from "redux/booking/travel/travelBookingSlice"
import ConfirmationModal from "components/confirmation/ConfirmationModal"
import { FaCalculator, FaDownload, FaEdit, FaEllipsisV, FaInfoCircle, FaMoneyBill, FaRegUser } from "react-icons/fa"

const ExpenseDetail = ({booking}) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [summaryExpenses, setSummaryExpenses] = useState([]);
  const [totalAmountEstimate, setTotalAmountEstimate] = useState(0.00);
  const [totalAmount, setTotalAmount] = useState(0);

  const currency = booking && booking.currency;
  const employees = booking?.employees || [];

  const dispatch = useDispatch();

  const {
    allow_update_employee_expense,
    allow_recalculate_expense
  } = booking?.permissions || {};

  useEffect(() => {
    if (booking) {
      let shuttle = []
      let hotels = []

      if (booking.shuttles) {
        shuttle = booking.shuttles.map((item) => (
          {
            name: item.name,
            description: item.description,
            amount_estimate_currency: item.amount_estimate_currency,
            amount_currency: item.amount_currency
          }
        ))
      }

      if (booking.hotels) {
        hotels = booking.hotels.map((item) => (
          {
            name: "Hotel",
            description: item.name,
            amount_estimate_currency: item.cost_currency,
            amount_currency: item.cost_currency
          }
        ))
      }
      const expenses = [...shuttle, ...hotels]

      setSummaryExpenses(expenses)
      setTotalAmountEstimate(expenses.reduce((total, expense) => total + Number(expense.amount_estimate_currency), 0))
      setTotalAmount(expenses.reduce((total, expense) => total + Number(expense.amount_currency), 0))
    }
  }, [booking])
  
  const handleUpdateExpenseEmployee = () => {
    setShowModal(true)
  }

  const handleRecalculationExpense = () => {
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(recalculateExpenseBooking(booking.id))
    setShowConfirmationDelete(false) 
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0"><FaMoneyBill /> Expense</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-5">
            <Col lg xxl={6}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Expense summary
              </h6>
              <div className="table-responsive fs--1 m-3">
                <Table striped bordered>
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="border-0 fixed-header">Name</th>
                      <th className="border-0 text-end fixed-header">Expense estimate ({currency})</th>
                      <th className="border-0 text-end fixed-header">Expense actual ({currency})</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summaryExpenses.map((item) => (
                      <tr className="border-200" key={item.id}>
                        <td>
                          <h6 className="fw-semi-bold mb-0 text-nowrap">{item.name}</h6>
                          <p className="mb-0 small text-wrap">
                            {item.description}
                          </p>
                        </td>
                        <td className="text-end">{formatNumber(item.amount_estimate_currency, currency)}</td>
                        <td className="text-end">{formatNumber(item.amount_currency, currency)}</td>
                      </tr>
                    ))}
                    <tr className="border-top fw-bold">
                      <td className="text-end">TOTAL</td>
                      <td className="text-end text-primary fs-0">{formatNumber(totalAmountEstimate, currency)}</td>
                      <td className="text-end text-danger fs-0">{formatNumber(totalAmount, currency)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col lg className="mt-4 mt-lg-0">
              <Tabs id="booking-expense-overview">
                <Tab eventKey="booking-expense-detail" title="Detail" className='border-bottom border-x p-3'>
                  <div className="m-2">
                    <Row className="mb-3">
                      <Col xs={3} sm={6}>
                        <p className="fs--1 mb-1">
                          <div className="fw-bold">Expense as policy</div>
                          <div className="small">Expense of employees per day as policy.</div>

                          <div className="mt-2 small">
                            <FaDownload icon="download" className="me-1 text-primary" /> Travel expense policy template.
                          </div>
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">
                          <div>{formatNumber(booking.expense_employee_policy_currency)} USD</div>
                          <div>{formatNumber(booking.expense_employee_policy, "VND")} VND</div>
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={6}>
                        <p className="fs--1 mb-1">
                          <div className="fw-bold">Number of days</div>
                          <div className="small">Actual days travel Business as a time of the ticket (plane, train...)</div>
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{booking.number_of_days} days</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={6}>
                        <p className="fs--1 mb-1">
                          <div className="fw-bold">Expense of employees for trip</div>
                          <div className="small">Meal, Transportation & miscellaneous expense</div>
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{formatNumber(booking.other_cost_currency, currency)} {currency}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={6}>
                        <p className="fs--1 mb-1">
                          <div className="fw-bold">Cash Advance</div>
                          <div className="small">Include air ticket expense</div>
                          <div className="mt-2 small">
                            <FaDownload icon="download" className="me-1 text-primary" /> Advance Payment Request template
                          </div>
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{formatNumber(booking.advance_cost_currency, currency)} {currency}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={6}>
                        <p className="fs--1 mb-1">
                          <div className="fw-bold">Exchange rate</div>
                          <div className="small">USD exchange rate to VND from Vietcombank at the time of booking.</div>
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">1USD = { formatNumber(booking.exchange_rate) } VND</p>
                      </Col>
                    </Row>
                    <hr className="w-75" />
                    <Row className="mb-3">
                      <Col xs={3} sm={6}>
                        <p className="fw-bold mb-1 fs--1">TOTAL EXPENSE</p>
                      </Col>
                      <Col>
                        <p className="mb-1 text-primary fs-0">{formatNumber(booking.total_cost_currency, currency)} {currency}</p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={6}> 
                        <p className="fw-bold mb-1 fs--1">REIMBURSE TO EMPLOYEE:</p>
                      </Col>
                      <Col>
                        <p className="mb-1 text-primary fs--1">{formatNumber(booking.total_reimburse_cost_currency, currency)} {currency}</p>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="booking-expense-employees" title="Employees" className='border-bottom border-x p-3 fs--1'>
                  <Flex justifyContent="between">
                    <div className="mb-2">Expense of employees</div>

                    <Dropdown align="end" className="btn-reveal-trigger d-inline-block mb-2">
                      <Dropdown.Toggle split variant="falcon-default" size="sm">
                        <FaEllipsisV icon="ellipsis-v" className="fs--2" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="border py-0">
                        <div className="py-2">
                          {allow_update_employee_expense && (
                            <Dropdown.Item className="mb-1" onClick={handleUpdateExpenseEmployee}>
                              <FaEdit icon="edit" /> Update employee expenses
                            </Dropdown.Item>
                          )}
                          
                          {allow_recalculate_expense && (
                            <>
                              <Dropdown.Divider />
                              <Dropdown.Item className="mb-1" onClick={handleRecalculationExpense}>
                                <FaCalculator icon="calculator" /> Recalculate expenses
                              </Dropdown.Item>
                            </>
                          )}
                         
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Flex>

                  <Row className="mb-3 mt-2">
                    <Col xs={3} sm={6}>
                      <p className="fs--1 mb-1">
                        <div className="fw-bold">Exchange rate</div>
                        <div className="small">USD exchange rate to VND from Vietcombank at the time of booking.</div>
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-1 fs--1 text-primary text-end">1USD = { formatNumber(booking.exchange_rate) } VND</p>
                    </Col>
                  </Row>
                  
                  <div className="table-responsive">
                    <Table responsive striped bordered>
                      <thead className="bg-200">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col" className="text-end">Amount currency</th>
                          <th scope="col" className="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employees && employees.map((emp) => (
                          <tr key={emp.id}>
                            <td className="text-primary"><FaRegUser /> {emp.employee_name}</td>
                            <td className="text-end">{emp.amount_currency} USD</td>
                            <td className="text-end">{formatNumber(emp.amount)} VND</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {booking.purpose_of_travel === "Customer visit factory" && (
                    <div className="text-danger text-end"><FaInfoCircle /> For trips where the purpose is a <strong>customer visit to the factory</strong>, the allowance of sales staff will follow the customer's actual expense.</div>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <BookingUpdateExpenseEmployeeModal 
        showModal={showModal}
        setShowModal={setShowModal}
        booking={booking}
      />

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to recalculate expenses for the trip?"
      />
    </>
  );
};

ExpenseDetail.propTypes = {
  booking: PropTypes.object
};

export default ExpenseDetail;