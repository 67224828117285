import Flex from "components/common/Flex";
import { useDropzone } from "react-dropzone"
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import React from 'react';

const CustomerUploadLogo = ({logo, setLogo, customer}) => {
  const {getInputProps, getRootProps} = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      setLogo(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  })

  const handleRemove = () => {
    setLogo("");
  };

  return (
    <>
      {!logo ? (
        <div {...getRootProps({ className: 'dropzone-area py-5' })}>
          <input {...getInputProps({multiple:false})} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your logo here</p>
          </Flex>
        </div>
      ) : (
        <div className="mt-1">
          <Flex 
            direction="column"
            alignItems="center"
          >
            <Image rounded width={150} height={100} src={customer && customer.logo_url ? logo : logo[0].preview} alt={logo[0].path} />
            <Link className="mt-2 fs--1 text-danger" title="Remove logo" onClick={handleRemove}>
              <FontAwesomeIcon icon="trash" /> Remove
            </Link>
          </Flex>
        </div>
      )}
    </>
  )
}

CustomerUploadLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  setLogo: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired
}

export default CustomerUploadLogo;