import React, { Suspense, lazy } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { MdList, MdNewLabel, MdOutlineRequestQuote } from 'react-icons/md';
import ContactHeader from './ContactHeader';

const AllList = lazy(() => import('./list/All'));
const CreatedFromLeadList = lazy(() => import('./list/CreatedFromLead'));
const NewThisMonthList = lazy(() => import('./list/NewContactThisMonth'));

const Contacts = () => {
  const { loading } = useSelector((state) => state.crm_contact);

  return (
    <>
      <ContactHeader />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="contact-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contact-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdList className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contact-created-from-lead"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineRequestQuote className="text-primary tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Created from Lead</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contact-new-contact-this-month"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdNewLabel className="text-600 tab-icon"/>
                        <h6 className="text-600 mb-0 ms-1"> New Contacts this month</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={<div>Loading...</div>}>
                  <Tab.Content>
                    <Tab.Pane eventKey="contact-all">
                      <AllList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="contact-created-from-lead">
                      <CreatedFromLeadList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="contact-new-contact-this-month">
                      <NewThisMonthList />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Contacts;