import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AssignCustomerModal from './AssignCustomerModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaEllipsisV } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { deleteCustomer } from 'redux/crm/customerSlice';
import PropTypes from 'prop-types';

const CustomerDetailHeader = ({customer}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showAssignCustomer, setShowAssignCustomer] = useState(false);
  const dispatch = useDispatch();

  const {
    allow_view_list,
    allow_edit,
    allow_delete,
    allow_assign
  } = customer?.permissions || {};
  
  const navigate = useNavigate();
  const customerUrl = "/crm/customers";

  const handleNewCustomer = () => {
    navigate(`${customerUrl}/new`)
  }

  const handleEditCustomer = () => {
    navigate(`${customerUrl}/${customer?.id}/edit`, {state: customer})
  }

  const handleDeleteCustomer = () => {
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deleteCustomer(customer.id));
      handleCustomerList();
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleCustomerList = () => {
    navigate(`${customerUrl}/list`);
  }

  const handleAssignedCustomer = () => {
    setShowAssignCustomer(true);
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewCustomer}
            >
              <span className="d-none d-xl-inline-block ms-1">New Customer</span>
            </IconButton>

            {allow_assign && (
              <IconButton
                variant="primary"
                size="sm"
                icon="user"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleAssignedCustomer}
              >
                <span className="d-none d-xl-inline-block ms-1">Assign to</span>
              </IconButton>
            )}
            
            {allow_view_list && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="list"
                iconAlign="middle"
                className="d-none d-sm-block me-1"
                onClick={handleCustomerList}
              >
                <span className="d-none d-xl-inline-block ms-1">
                  List Customers
                </span>
              </IconButton>
            )}
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_edit && (
                    <Dropdown.Item className="text-primary" onClick={handleEditCustomer}>
                      <MdEdit /> Edit
                    </Dropdown.Item>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger" onClick={handleDeleteCustomer}>
                        <MdDelete className="text-danger" /> Delete
                      </Dropdown.Item>
                    </>
                   )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}pCustomerGeneral
        message="Are you sure to delete?"
      />

      <AssignCustomerModal
        showModal={showAssignCustomer}
        setshowModal={setShowAssignCustomer}
        customerId={customer?.id}
      />
    </>
  );
};

CustomerDetailHeader.propTypes = {
  customer: PropTypes.object.isRequired
}

export default CustomerDetailHeader;
