import Flex from "components/common/Flex";
import { Card, Row, Col } from "react-bootstrap";
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import DateRangePickerApp from "components/date-range-picker/DateRangePickerApp";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select"
import { getActiveSalesUnits } from "redux/crm/salesUnitSlice";
import PropTypes from 'prop-types';

const HeaderReport = ({
  title,
  subtitle,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onDatesChange,
  onChangeSalesUnit
}) => {
  const {activeSalesUnitsData} = useSelector((state) => state.crm_sales_unit);
  const {sales_unit} = useSelector(state => state.profile_user.data);
  const dispatch = useDispatch();
  const hasRecordLevelSalesUnit = sales_unit?.record_level || [];

  useEffect(() => {
    if (onChangeSalesUnit && activeSalesUnitsData.length === 0) {
      dispatch(getActiveSalesUnits());
    }
  }, [onChangeSalesUnit]);

  let salesUnitsData = hasRecordLevelSalesUnit && hasRecordLevelSalesUnit.length > 0 
  ? activeSalesUnitsData.filter((item) => hasRecordLevelSalesUnit.includes(item.id)) 
  : activeSalesUnitsData;

  const salesUnitOptions = salesUnitsData && salesUnitsData.map((item) => ({ value: item.id, label: item.description }))

  return (
    <Card className="bg-100 shadow-none border mb-3">
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          <Col sm="auto">
            <Flex alignItems="center">
              <img src={barChart} width={90} alt="..." className="ms-n4" />
              <div>
                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                <h4 className="text-primary fw-bold mb-0">
                  {title}
                  <span className="text-info fw-medium"> {subtitle}</span>
                </h4>
              </div>
              <img
                src={lineChart}
                width={140}
                alt="..."
                className="ms-n4 d-md-none d-lg-block"
              />
            </Flex>
          </Col>
          <Col md="auto" className="mb-3 mb-md-0">
            <Row className="g-1 gy-md-0 h-100 align-items-center">
              <Col xs="auto">
                <h6 className="text-700 mb-0 text-nowrap">Showing Data For:</h6>
              </Col>
              <Col md="auto">
                <DateRangePickerApp
                  {...{startDate, setStartDate, endDate, setEndDate, onDatesChange}}
                />
              </Col>
              <Col className="fs--1">
                {onChangeSalesUnit && (
                  <Select
                    closeMenuOnSelect={false}
                    options={salesUnitOptions}
                    placeholder='Select Sales unit...'
                    classNamePrefix="react-select"
                    isMulti
                    onChange={onChangeSalesUnit}
                    defaultValue={hasRecordLevelSalesUnit.length > 0 ? salesUnitOptions : []}
                  />
                )}
               </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

HeaderReport.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setEndDate: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onChangeSalesUnit: PropTypes.func.isRequired
}

export default HeaderReport;