import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import {
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from "helpers/reducerHelpers";

const projectUrl = "/crm/projects"

const generalWarrantyUrl = (projectId, warrantyId) => (
  warrantyId ? `${projectUrl}/${projectId}/warranties/${warrantyId}` : `${projectUrl}/${projectId}/warranties`
)

export const getWarranties = createAsyncThunk(
  "Warranty/getWarranties",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/warranties`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getProjectWarranties = createAsyncThunk(
  "Warranty/getProjectWarranties",
  async (projectId) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/warranties`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)


export const createWarranty = createAsyncThunk(
  "Warranty/createWarranty",
  async ({projectId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/warranties/`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateWarranty = createAsyncThunk(
  "Warranty/updateWarranty",
  async ({ projectId, warrantyId, data }, { rejectWithValue }) => {
    try {
      const url = generalWarrantyUrl(projectId, warrantyId)
      const response = await apiAxios.put(url, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteWarranty = createAsyncThunk(
  "Warranty/deleteWarranty",
  async ({projectId, warrantyId}) => {
    try {
      const url = generalWarrantyUrl(projectId, warrantyId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getColumns = createAsyncThunk(
  "Warranty/getColumns",
  async () => {
    try {
      const response = await apiAxios.get(`${projectUrl}/warranties/attributes`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportData = createAsyncThunk(
  "Warranty/exportData",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)
    
    const response = await apiAxios.get(`${projectUrl}/warranties/export`, {params, responseType: 'blob'})

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `warranties.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const removeDocument = createAsyncThunk(
  "Warranty/removeDocument",
  async ( { projectId, warrantyId, documentId }, { rejectWithValue }) => {
    try {
      const url = generalWarrantyUrl(projectId, warrantyId)
      const response = await apiAxios.post(`${url}/remove_document`, { document_id: documentId } );
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const warrantySlice = createSlice({
  name: "crm_warranty",
  initialState: {
    ...initialState,
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarranties.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getProjectWarranties.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))

      .addCase(createWarranty.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteWarranty.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(updateWarranty.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(exportData.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportData.fulfilled, (state) => {
        state.exporting = false
        toast.success("Data has been exported successfully.")
      })
      .addCase(getColumns.fulfilled, (state, action) => {
        state.sortColumns = action.payload.sort_columns
      })
      .addCase(removeDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = warrantySlice.actions

export default warrantySlice.reducer