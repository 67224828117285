//APP
export const APP_VERSION = "v3.0.7.1"
export const EMPLOYEE_STAFF_POLICY = ['SALES', 'CMD']

//CRM
export const CRM_SALES_UNIT_TYPE_OF_SALES = ['Domestic', 'Oversea'];
export const CRM_SALES_UNIT_REGION = [
  "SOUTH", "NORTH", "CAMBODIA", "MYANMAR", "INDONESIA", "THAILAND", "PHILIPPINES", "MALAYSIA", "OTHERS EXPORT"
]
export const CRM_SALUTATION = ["Dr.", "Mr.", "Ms.", "Mrs."]
export const CRM_NUMBER_OF_EMPLOYEES = ["less then 50", "50 to 250", "250 to 500", "over 500"]
export const CRM_TYPE_OF_CUSTOMER = ["Consultant", "Contractor", "Developer", "End-user", "Partner"]
export const CRM_LEGAL_STATUS = ["Foreign", "Gorverment", "JS", "JV", "Private"]
export const CRM_ADDRESS_TYPE = ["Bussiness", "Delivery", "Factory", "Invoice"]
export const CRM_PROJECT_MODE = ["COLD", "HOT", "BUDGET", "POSTPONE"]
export const CRM_STS_DESCRIPTION = ["STS-BU 70% volume is BU (Philippines)", "STS-HR 70% volume is HR (Philippines)", "Design by customer (other offices)"]
//export const CRM_PROJECT_ATTACHMENTS = ["image/*", "application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain", "application/acad"]
export const CRM_PROJECT_ATTACHMENTS = "image/*, .doc, .docx, .xls, .xlsx, .pdf, message/rfc822, application/acad, .dwg, .zip, .7z, .rar, .msg, application/x-dwg, application/x-7z-compressed, application/x-zip-compressed, application/x-rar-compressed, .sdb, .$2k";

export const CRM_CONTRACT_ATTACHENTS_TYPE = ".doc, .docx, .pdf"
export const CRM_CONTRACT_ATTACHENTS_NOTE = "Only PDF, WORD files are allowed. The file size is maximum of 100Mb"

export const CRM_PPD_RAD_DRAWINGS = "ppd_rad_drawings"
export const CRM_ESTIMATE_RELEASED_DRAWINGS = "estimate_released_drawings"

//PIF
export const CRM_PIF_PAINT_TYPE = ["Alkyd primer coat", "Alkyd Top coat", "Epoxy primer coat", "Epoxy Top coat", "See below"]
export const CRM_PIF_ROOF_PANEL_COLOR = ["PE 1000 w/ Blue Tooth color", "PE 1000 w/ Green Tea color", "PE 1000 w/ PEB Blue color", "PE 1000 w/ Plan Zinc Alum", "PE 1000 w/ Silver180 color", "PE 1000 w/ White Cream color"]
export const CRM_PIF_LOADS_RECURRENCE_TIME = ["20 Years", "50 Years", "100 Years", "300 Years", "700 Years", "1700 Years"]
export const CRM_PIF_LOADS_EXPLOSURE = ["A", "B", "C", "D"]
export const CRM_PIF_LOADS_OCCUPANCYCATEGORY = ["I", "II", "III"]

export const CRM_PIF_RV_TYPE = ["RV R7.0", "RV R1.3", "RV R3.0", "RV R5.0", "By Others"]
export const CRM_PIF_GRADE_PRIMARY_MEMBER = ["ASTM A572 Gr.50 or equivalent having Min. yield stress 345N/mm2"]
export const CRM_PIF_GRADE_SECONDARY_MEMBER = ["AS 1397 or equivalent having Min. yield strength Fy=45.0KN/cm2", "NSDC570/NSDH540/K18 or equivalent having Min. yield strength Fy=45.0KN/cm2"]
export const CRM_PIF_BUILDING_FRAME_TYPE = ["BU", "CS", "Lean-To", "MG", "MS1", "MS2", "MS3", "OW", "RS", "See DWG", "See notes", "SS"]
export const CRM_PIF_BUILDING_HEIGHT = ["Clear H", "Eave H", "Peak Height", "Top of Rafter", "See notes"]
export const CRM_PIF_BUILDING_COLUMN_SUPPORT = ["Designed by Engg.", "Fixed end", "Pinned End"]
export const CRM_PIF_WALL_FRAME = ["False rafter", "P&B", "RC endwall", "RF", "See DWG", "See notes"]
export const CRM_PIF_GIRT_TYPE = ["By-Pass", "Flush", "No girt"]
export const CRM_PIF_ROOF_PROFILE = ["None", "Lok-Seam", "Peb-Seam", "PebLok", "Pebroof", "Pebwall", "See notes"]
export const CRM_PIF_COLOR = ["Blue Tooth", "Green Tea", "PEB Blue", "Silver180", "TBE", "White Cream" , 'Z/A', "See notes"]
export const CRM_PIF_ROOF_NAME = ["None", "Fiberglass-one side NFR1", "Fiberglass-double side NFR1", "Air Bubble-Double side Aluminum", "PEB Foam-one side Aluminium", "PEB Foam-double side Aluminium", "Rockwool", "See notes"]
export const CRM_PIF_WALL_PROFILE = ["Pebroof", "Pebwall", "See notes"]
export const CRM_PIF_EAVE_CONDITION = ["Curved Eave", "Eave Trim"]
export const CRM_PIF_PANEL_BY = ["None", "PEB", "Others", "See notes"]
export const CRM_PIF_FLOOR_TYPE = ["Chkrd Pl", "Concrete", "Flat Pl", "Gratinq", "Plywood", "See note"]
export const CRM_PIF_STAIR_TYPE = ["None", "Double", "L-Type", "Single", "U-Type"]
export const CRM_PIF_TREAD_TYPE = ["Chrd Pl", "Grating", "None", "Pan", "See note"]
export const CRM_PIF_HAND_RAIL = ["Indust", 'Office', "None"]
export const CRM_PIF_CRANE_TYPE = ["JIB" ,"MR", "TR", "UH"]
export const CRM_PIF_BRACING_TYPE = ["None", "Diaphragm", "Minor Axis", "Portal", "X-Bracing", "As per design"]
export const CRM_PIF_FASICA_PANEL_DESCRIPTION = ["Pebroof", "Pebwall", "Pebliner", "See notes"]
export const CRM_PIF_BDS3_PURLIN_GIRT_SPACING = "Z-Purlin depth can be Z150, Z200, Z250, Z300"

export const CRM_PIF_ISO = "QF-SLS-02 R16 (09/06/2023)"

//Change Order
export const CRM_CO_TYPE_OF_CO = ['Internally Generated', 'Requested by Customer']
export const CRM_CO_FORM_ISO = "QF-CSD-10/03 (01-Apr-2022)"

//Enter job
export const CRM_ENTER_JOB_ASSIGN_TYPE = [
  {
    id: 1,
    name: "override",
    description: "Override current name"
  },
  {
    id: 2,
    name: "assign",
    description: "Assign more name"
  }
]

//Waiver
export const CRM_WAIVER_FORM_ISO = "QF-CSD-18/08 (13-Oct-2022)"

//Lead
export const CRM_LEAD_RATING = ["Cold", "Warm", "Hot"]
export const CRM_LEAD_STAGE = ["SQL", "MQL"]

//Contract
export const CRM_CONTRACT_NOTE = ["LOI", "LOA", "PO", "WAIVER", "PENDING", "REPEAT CUST"]

//Travel
export const TRAVEL_ARRANGEMENT_BY_HR_ADMIN = ["Car of company", "Car rental", "Plane", "Train", "Taxi", "Motocycle", "Bus", "Visa", "Hotel"]
export const TRAVEL_TYPE_OF_TRAVEL = ["Domestic", "International"]
export const TRAVEL_PURPOSE_OF_TRAVEL = ["Working at job sites", "Visit/meeting with customer", "Customer visit factory", "Stock counting", "Other"]
export const TRAVEL_SETTING_EXPENSE_TYPE = ["Travel", "Hotel"]
export const TRAVEL_SETTING_EXPENSE_UNIT = ["USD/day", "VND/day"]


export default { CRM_SALES_UNIT_TYPE_OF_SALES, CRM_SALES_UNIT_REGION };

//Report
export const BY_NUMBER_WEIGHT_FILTER = ["By weight", "By number of projects"]
export const BY_NUMBER_WEIGHT_REVENUE_FILTER = ["By weight", "By number of projects", "By Revenue"]
export const DEFAULT_COUNTRY_NAME = "Vietnam"

//pagination => per page
export const PERPAGE_DEFAULT_SM = 50;
export const PERPAGE_DEFAULT_LG = 100;

export const PERMISSION_PARENT_GROUPS = ["crm", "car_booking", "travel_booking", "drive", "social", "other"];

// Opla CRM integration
export const OPLA_CRM_URL = "https://app.oplacrm.com"  //"https://app-staging.opla-crm.com
export const OPLA_CRM_CUSTOMER_URL = `${OPLA_CRM_URL}/account`
export const OPLA_CRM_CONTACT_URL = `${OPLA_CRM_URL}/contact`
export const OPLA_CRM_PROJECT_URL = `${OPLA_CRM_URL}/opportunity`

export const PROJECT_STATUS_CLOSED_CLOSE = 17