import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import PropTypes from 'prop-types';

const StarterShuttle = ({setShowModalShuttle}) => {

  const handleCreateShuttleBooking = () => {
    setShowModalShuttle(true)
  }

  return (
    <>
      <Row className="align-items-center justify-content-between m-5">
        <Col lg={6}>
          <img src={editing} className="img-fluid" alt="" width="500px" />
        </Col>
        <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
          <h3 className="text-primary">Shuttle Booking!</h3>
          <p className="lead">Book shuttle transportation for your trip, including company vehicles, car rentals, flights, trains, taxis, and more.</p>
          <Button
            variant="falcon-primary"
            onClick={handleCreateShuttleBooking}
          >
            Book a shuttle now!
          </Button>
        </Col>
      </Row>
    </>
  )
}

StarterShuttle.propTypes = {
  setShowModalShuttle: PropTypes.func.isRequired
}

export default StarterShuttle;