import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Form } from 'react-bootstrap';
import { BY_NUMBER_WEIGHT_REVENUE_FILTER } from 'app_config';
import FalconCardHeader from 'components/common/FalconCardHeader';
import TableItem from './TableItem';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  // title: {
  //   text: title
  // },
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   type: 'scroll',
  //   orient: 'vertical',
  //   left: 'left'
  // },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        formatter: '{b}: {d}%'  //'{b}: {c} ({d}%)' 
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
});

getOption.propTypes = {
  data: PropTypes.array.isRequired
}

const ContractRatioOfSalesChart = ({data, title}) => {
  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_REVENUE_FILTER[0])
  const [dataObject, setDataObject] = useState([])

  useEffect(() => {
    if (!data) return;

    const newData = Object.entries(data).map(([name, values]) => {
      let value;
      switch (numberWeightFilter) {
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[0]:
          value = values.weight;
          break;
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[1]:
          value = values.number_of_projects;
          break;
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[2]:
          value = values.revenue;
          break;
        default:
          value = null;
      }
      return { name, value };
    });

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  return (
    <Card className="mb-3 h-100">
        <FalconCardHeader
          title={title}
          titleTag="h6"
          className="py-2"
          light
          endEl={
            <Form.Select
              size="sm"
              onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
              className="mx-1 fs--1"
            >
              {BY_NUMBER_WEIGHT_REVENUE_FILTER.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Form.Select>
          }
        />
      <Card.Body className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(dataObject)}
          className="w-100"
        />
      </Card.Body>
      <Card.Footer as={Flex} justifyContent="end">
        <TableItem data={data} />
      </Card.Footer>
    </Card>
  )
}

ContractRatioOfSalesChart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};


export default ContractRatioOfSalesChart;