import { Row, Col } from "react-bootstrap";
import ContractRatioOfSalesChart from "./ContractRatioOfSalesChart";
import React from 'react';
import PropTypes from 'prop-types';

const ContractRatioOfSales = ({data}) => {
  const {sales_regions, type_of_sales} = data;

  return (
    <Row className="mb-3 g-3">
      <Col>
        <ContractRatioOfSalesChart data={type_of_sales} title="Signed contracts between Domestic and Overseas" />
      </Col>
      <Col>
        <ContractRatioOfSalesChart data={sales_regions} title="Signed contracts by Sales regions" />
      </Col>
    </Row>
  )
};

ContractRatioOfSales.propTypes = {
  data: PropTypes.shape({
    sales_regions: PropTypes.object,
    type_of_sales: PropTypes.string
  }).isRequired
}

export default ContractRatioOfSales;