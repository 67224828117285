import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

const reportUrl = `/crm/reports/job_entry`;

export const getData = createAsyncThunk(
  "ReportJobEntry/getData",
  async (params) => {
    try {
      const response = await apiAxios.get(reportUrl, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportData = createAsyncThunk(
  "ReportJobEntry/exportData",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${reportUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'project-schedule.xlsx');
    document.body.appendChild(link);
    link.click();
  }
)

export const getColumns = createAsyncThunk(
  "ReportJobEntry/getColumns",
  async () => {
    try {
      const response = await apiAxios.get(`${reportUrl}/attributes`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getJobEntryDashboard = createAsyncThunk(
  "ReportJobEntry/getJobEntryDashboard",
  async (params) => {
    try {
      const response = await apiAxios.get(`${reportUrl}/dashboard`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const jobEntrySlice = createSlice({
  name: "report_job_entry",
  initialState: {
    data: [],
    loading: false,
    exporting: false,
    sortColumns: {},
    filterTags: [],
    summaryData: []
  },
  reducers: {
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getColumns.fulfilled, (state, action) => {
        state.sortColumns = action.payload.sort_columns
      })
      .addCase(exportData.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportData.fulfilled, (state) => {
        state.exporting = false
        toast.success("Data has been exported successfully.")
      })
      .addCase(getJobEntryDashboard.fulfilled, (state, action) => {
        state.summaryData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
});

export const {
  setCurrentParams,
  resetCurrentParams,
} = jobEntrySlice.actions;

export default jobEntrySlice.reducer;