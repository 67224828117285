import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const WaiverHeader = () => {
  const navigate = useNavigate()

  const handleDashboard = () => {
    navigate("/crm/reports/waiver-dashboard")
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="chart-pie"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleDashboard}
            >
              <span className="d-none d-xl-inline-block ms-1">Dashboard</span>
            </IconButton>
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
};

export default WaiverHeader;