import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import IconAlert from "components/common/IconAlert";

const MeetingRoom = () => {
  const [url, setUrl] = useState(null);
  const MEETING_ROOM_URL = process.env.REACT_APP_MEETING_ROOM_URL;

  useEffect(() => {
    //getAuthorizationCode();

    const url = `${MEETING_ROOM_URL}?token=${localStorage.getItem('access_token')}`;
    setUrl(url)
    window.open(url, "_blank");

  }, []);

  // const getAuthorizationCode = async () => {
  //   const token = localStorage.getItem('access_token');

  //   const data = {
  //     client_id: MEETING_ROOM_CLIENT_ID,
  //     redirect_uri: MEETING_ROOM_URL,
  //     response_type: "code",
  //     token: token
  //   };
  
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/oauth/authorize`, data, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });

  //     if (response.data && response.data.redirect_uri) {
  //       setUrl(response.data.redirect_uri);
  //       //window.location.href = response.data.redirect_uri

  //     } else {
  //       toast.error('Invalid response data');
  //     }

  //   } catch (error) {
  //     toast.error(`Error getting authorization code: ${error.message}`);
  //     console.error('Error details:', error);
  //   }
  // };

  return (
    <div>
      <IconAlert variant="info" className="mb-3">
        In case the Meeting Room cannot authorize, please click <Link to={url} target="_blank">here</Link> to access it on new page.
      </IconAlert>
      
      {/* {url ? (
        <iframe
          title="Meeting Room"
          src={url}
          width="100%"
          style={{ height: '100vh' }}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          allow="fullscreen; geolocation"
        />
      ) : (
        <div className="text-center p-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )} */}
    </div>
  )
}

export default MeetingRoom;