import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { Card, Table, Image, Form, Row, Col, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "./../../../assets/img/pebsteel.png"
import { Link } from "react-router-dom";
import { formatDate } from "helpers/utils";
import { CRM_CO_FORM_ISO, CRM_CO_TYPE_OF_CO } from "app_config";
import "../../../assets/scss/print-form.scss"
import generatePDF, {Resolution} from 'react-to-pdf';
import React, { useState } from "react";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

const HeaderPrint = ({changeOrder, download}) => {
  const navigate = useNavigate()
  const {project} = changeOrder || {};

  const handleBackToProject = () => {
    navigate(`/crm/projects/${project?.id}`)
  }
  
  const handleDownloadPDF = () => {
    download()
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="download"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleDownloadPDF}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Download
              </span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBackToProject}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Back to Project
              </span>
            </IconButton>
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
}

HeaderPrint.propTypes = {
  changeOrder: PropTypes.object.isRequired,
  download: PropTypes.func.isRequired
}

const ChangeOrderPrint = () => {

  const location = useLocation()
  const { changeOrder } = location.state
  const { project, building, change_order_form: co_form } = changeOrder;
  const [showExporting, setExporting] = useState(false)

  const descriptionChanges = Array.isArray(co_form?.description_of_changes) ? co_form.description_of_changes : []

  const options = {
    filename: `Change order ${building?.j_number} CO#${changeOrder?.co} ${building.name}.pdf`,
    // default is `save`
    //method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.NORMAL,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: 20,
       // default is 'A4'
       format: 'A4',
       // default is 'portrait'
       orientation: 'portrait',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'application/pdf',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
  }

  const getTargetElement = () => document.getElementById('change-order-form');

  const handleDownload = () => {
    setExporting(true)
    generatePDF(getTargetElement, options)
      .then(() => {
        setExporting(false);
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF:', error)
        setExporting(false)
      })
  }

  return (
    <>
      <Card className="m-2">
        <HeaderPrint changeOrder={changeOrder} download={handleDownload}/>

        {showExporting && (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" variant="primary" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner> Downloading...
          </div>
        )}

        <div id="change-order-form"  className="m-4 print-form responsive-table text-1000">
          <table className="fs--1">
            <tr>
              <td colSpan="5" className="border text-center">
                <div className="fs-2 fw-bold mb-2">CHANGE ORDER INFORMATION SHEET</div>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold" width="15%">Job no.:</td>
              <td className="border" width="25%">
                <Link to={`/crm/projects/${project?.id}`}>{building?.j_number}</Link>
              </td>
              <td className="border text-center" rowSpan="3" width="20%">
                <Image
                  fluid
                  className="fit-cover rounded"
                  src={logo}
                  alt="Logo Pebsteel"
                  width="130"
                  height="120"
                />
              </td>
              <td className="border" width="15%">This Change Order No.:</td>
              <td className="border text-primary" width="25%">{changeOrder.co}</td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Customer</td>
              <td className="border text-primary">{project?.customer_name}</td>
              <td className="border">Date this C.O. Entered:</td>
              <td className="border text-primary">{formatDate(changeOrder.entry_date)}</td>
            </tr>
            <tr>
              <td className="border" colSpan="2"></td>
              <td className="border" colSpan="2">
                <Flex justifyContent="between">
                  <div>
                    <span>Continuation Number Sheet Attached:</span>
                    <Form.Check checked={co_form?.continuation_sheet_attached} />
                  </div>
                  <div>
                    Number of sheet: <span className="text-primary">1</span>
                  </div>
                </Flex>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Type of C.O.</td>
              <td className="border" colSpan="4">
                <Flex direction="row" className="mt-1">
                  {CRM_CO_TYPE_OF_CO.map((option, index) => (
                    <Form.Check
                      key={option}
                      id={`${option}${index}`}
                      type="radio"
                      label={option}
                      value={option}
                      className="me-5"
                      checked={co_form?.type_of_co === option}
                    />
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold" rowSpan="3">Payment for this C.O.</td>
              <td className="border" colSpan="4">
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-2" controlId="payment_of_price_co">
                      <Form.Label column sm={5} className="text-lg-start">
                        Price of this C.O.
                      </Form.Label>
                      <Col className="text-primary">
                        {co_form?.payment_of_price_co} 
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row className="gx-2">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.to_be_established}
                          label="To be established"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.already_included_contract}
                          label="Already included contract"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </td>  
            </tr>
            <tr>
              <td className="border" colSpan="5">
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-2" controlId="payment_of_mode">
                      <Form.Label column sm={5} className="text-lg-start">
                        Mode of Payment for this C.O.
                      </Form.Label>
                      <Col className="text-primary">
                        {co_form?.payment_of_mode}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row className="gx-2">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.amend_lc}
                          label="Amend L/C"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.revise_contract}
                          label="Revise contract value"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.payment_others}
                          label="Other (specify)"
                        />
                        {co_form?.payment_others_specify && (
                          <div className="mt-0 ms-3 text-primary">{co_form?.payment_others_specify}</div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </td>  
            </tr>
            <tr>
              <td className="border" colSpan="5">
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-2" controlId="customer_will_pay">
                      <Form.Label column sm={5} className="text-lg-start">
                        Customer will Pay
                      </Form.Label>
                      <Col className="text-primary">
                        {co_form?.customer_will_pay}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row className="gx-2">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.fully}
                          label="Fully"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.partially}
                          label="Partially"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={co_form?.no_charge}
                          label="No charge"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </td>  
            </tr>
            <tr>
              <td className="border fw-semi-bold">Engg. Documents Required after this C.O</td>
              <td className="border" colSpan="4">
                <Row className="mb-2">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.desgin_calculation}
                      label="Desgin calculation"
                    />
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.approval_dwgs}
                      label="Approval dwgs"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.re_approval_dwgs}
                      label="Re approval dwgs"
                    />
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.erection_dwgs}
                      label="Erection dwgs"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.effected_sheets_of_approval_dwgs}
                      label="Effected sheets of approval dwgs"
                    />
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.effected_sheets_of_erection_dwgs}
                      label="Effected sheets of erection dwgs"
                    />
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.record_dwgs}
                      label="Record dwgs"
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">This C.O. affects.</td>
              <td className="border" colSpan="4">
                <Row className="mb-2">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.co_affects_design}
                      label="Desgin"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.co_affects_drafting}
                      label="Drafting"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={co_form?.co_affects_fabrication}
                      label="Fabrication"
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Sales Discount</td>
              <td className="border" colSpan="4">
                <Form.Group as={Row} className="mb-2" controlId="sales_discount_before">
                  <Form.Label column sm={3} className="text-lg-start">
                    Before this C.O.
                  </Form.Label>
                  <Col className="text-primary">
                    {co_form?.sales_discount_before}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="sales_discount_after">
                  <Form.Label column sm={3} className="text-lg-start">
                    After this C.O
                  </Form.Label>
                  <Col className="text-primary">
                    {co_form?.sales_discount_after}
                  </Col>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Impact of Changes</td>
              <td className="border" colSpan="4">
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in STD Ex-works (US$)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                      <Form.Check
                      label="Increase"
                      disabled
                      />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in CFR (US$)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in S.B.O. (US$)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in Delivery Period (Weeks)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in Freight (US$)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in Erection (US$)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in Total Contract (US$)
                  </Col>
                </Row>
                <Row className="gy-0">
                  <Col>
                    <Form.Check
                      label="Increase"
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="Decrease"
                      disabled
                    />
                  </Col>
                  <Col>
                    in Erection Period (Weeks)
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Contract Update (All Prices are in US$)</td>
              <td className="border" colSpan="4">
                <Table striped>
                  <thead className="bg-200">
                    <tr>
                      <th className="border">C.O#</th>
                      <th className="border">Date</th>
                      <th className="border">Std. Ex-Works</th>
                      <th className="border">Freight</th>
                      <th className="border">CFR/CIF Job Site</th>
                      <th className="border">SBO</th>
                      <th className="border">Erection</th>
                      <th className="border">Others</th>
                      <th className="border">Total Contract</th>
                    </tr>
                  </thead>
                </Table>

                <Flex justifyContent="around">
                  <div className="fw-semi-bold">
                    New Delivery Period (Weeks): <span className="text-primary mx-3">{co_form?.new_delivery_period_weeks}</span>
                  </div>
                  <div className="fw-semi-bold">
                    New Erection Period (Weeks): <span className="text-primary mx-3">{co_form?.new_erection_period_weeks}</span>
                  </div>
                </Flex>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Description of changes</td>
              <td className="border" colSpan="4">
                <Table striped>
                  <thead className="bg-200">
                    <tr>
                      <th className="border" width="10%">Bldg. No.</th>
                      <th className="border" width="10%">Change No.</th>
                      <th className="border">Description of changes</th>
                    </tr>
                  </thead>
                  <tbody className="text-1000">
                    {descriptionChanges && descriptionChanges.map((item) => (
                      <tr key={item.id}>
                        <td className="border">{item.building_name}</td>
                        <td className="border">{item.change_no}</td>
                        <td className="border">
                          <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Area office</td>
              <td className="border" colSpan="4">
                <Row className="gx-2 d-flex justify-content-around">
                  <Col sm={4}>
                    <Form.Group as={Row} className="mb-2">
                      <Form.Label column sm="auto" className="text-lg-end">
                        Customer Service Engineer:
                      </Form.Label>
                      <Col className="text-primary mt-1">
                        {co_form?.cse_area}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group as={Row} className="mb-2">
                      <Form.Label column sm={8} className="text-lg-end">
                        Customer Service Manager:
                      </Form.Label>
                      <Col className="text-primary mt-1">
                        {co_form?.csm_area}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group as={Row} className="mb-2">
                      <Form.Label column sm="auto" className="text-lg-end">
                        Date:
                      </Form.Label>
                      <Col className="text-primary mt-1">
                        {formatDate(co_form?.area_date)}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td className="border fw-semi-bold">Head office</td>
              <td className="border" colSpan="4">
                <Row className="gx-2 mb-2">
                  <Col sm={4}>
                    <Form.Group as={Row} className="mb-2">
                      <Form.Label column sm="auto" className="text-lg-end">
                        Customer Service Engineer:
                      </Form.Label>
                      <Col className="text-primary mt-1">
                        {co_form?.cse_head}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group as={Row} className="mb-2">
                      <Form.Label column sm={8} className="text-lg-end">
                        Supervisor:
                      </Form.Label>
                      <Col className="text-primary mt-1">
                        {co_form?.csm_head}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group as={Row} className="mb-2">
                      <Form.Label column sm="auto" className="text-lg-end">
                        Date:
                      </Form.Label>
                      <Col className="mt-1 text-primary">
                        {formatDate(co_form?.head_date)} 
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </td>
            </tr>
          </table>

          <div className="my-2 fs--1">{CRM_CO_FORM_ISO}</div>
        </div>
      </Card>
    </>
  );
};

export default ChangeOrderPrint;