import { useLocation } from "react-router-dom";
import UserForm from "./UserForm";
import React from "react";

const EditUser = () => {
  const location = useLocation()
  const user = location.state

  return (
    <UserForm title="Edit User" user={user} />
  )
}

export default EditUser