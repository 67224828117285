import { Row, Col, Card } from "react-bootstrap";
import RoleDetailInfoUserList from "./RoleDetailInfoUserList";
import RoleDetailInfoUserAdd from "./RoleDetailInfoUserAdd";
import React from "react";

const RoleDetailInfoUser = () => {
  return (
    <>
      <h6 className="fw-bold text-600">Assigning users to Role</h6>
      <Row className="fs--1 gx-3">
        <Col sm={5}>
          <Card className="h-100">
            <Card.Body>
              <RoleDetailInfoUserAdd />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={7}>
          <Card>
            <Card.Body>
              <RoleDetailInfoUserList />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default RoleDetailInfoUser