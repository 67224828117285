import React, { useEffect, useMemo, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Form } from 'react-bootstrap';
import { BY_NUMBER_WEIGHT_REVENUE_FILTER } from 'app_config';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const formatNumber = (params, numberType) => {
  const value = params?.value|| 0
  let result = null

  switch(numberType) {
    case "number":
      result = value?.toLocaleString()
      break;
    case "weight":
      result = `${value?.toLocaleString()} M.T`
      break;
    case "revenue":
      result = `$${value?.toLocaleString()}`
      break;
  }

  return result
}

formatNumber.propTypes = {
  params: PropTypes.object.isRequired,
  numberType: PropTypes.string.isRequired
}

const getOption = (data, monthName, numberType) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      magicType: { show: true, type: ['line', 'bar'] },
      saveAsImage: { show: true }
    }
  },
  xAxis: {
    type: 'category',
    axisPointer: {
      type: 'shadow'
    },
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'line'
      }
    },
    data: monthName,
  },
  yAxis: 
  {
    type: 'value'
  },
  series: [
    {
      type: 'bar',
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: "top",
        formatter: (params) => formatNumber(params, numberType)
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
});

getOption.propTypes = {
  data: PropTypes.array.isRequired,
  monthName: PropTypes.string.isRequired,
  numberType: PropTypes.string.isRequired,
}

const ContractMonthlyChart = ({data}) => {
  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_REVENUE_FILTER[0])
  const [dataObject, setDataObject] = useState([])
  const [numberType, setNumberType] = useState("weight")

  useEffect(() => {
    if (!data) return;

    const newData = Object.entries(data).map(([name, values]) => {
      let value;
      switch (numberWeightFilter) {
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[0]:
          value = values.weight;
          setNumberType("weight")
          break;
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[1]:
          value = values.number_of_projects;
          setNumberType("number")
          break;
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[2]:
          value = values.revenue;
          setNumberType("revenue")
          break;
        default:
          value = null;
          setNumberType("weight")
          break;
      }
      return { name, value };
    });

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  const title = useMemo(() => {
    let name;

    switch (numberWeightFilter) {
      case BY_NUMBER_WEIGHT_REVENUE_FILTER[0]:
        name = "Weight of projects (M.T)";
        break;
      case BY_NUMBER_WEIGHT_REVENUE_FILTER[1]:
        name = "Counting of projects";
        break;
      default:
        name = "Revenue of projects (USD)";
    }
    return name;
  }, [numberWeightFilter]);

  const monthName = dataObject && dataObject.map(item => item.name)

  return (
    <Card className="mb-3 h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select
            size="sm"
            onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
            className="mx-1 fs--1"
          >
            {BY_NUMBER_WEIGHT_REVENUE_FILTER.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Form.Select>
        }
      />
      <Card.Body className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(dataObject, monthName, numberType)}
          className="w-100"
        />
      </Card.Body>
    </Card>
  )
}

ContractMonthlyChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default ContractMonthlyChart;