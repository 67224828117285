import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import React, { useEffect } from "react";
import { getProjectStatuses } from "redux/crm/settingSlice";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { changeStatusProject } from "redux/crm/projectSlice";

const ChangeStatusProjectForm = ({showModal, setshowModal, project}) => {
  const {control, handleSubmit, formState: {errors}} = useForm();
  const {projectStatusData} = useSelector(state => state.crm_setting);
  
  const dispatch = useDispatch();
  const projectId = project?.id;

  useEffect(() => {
    dispatch(getProjectStatuses());
  }, []);

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    try {
      dispatch(changeStatusProject({projectId, data}));
      closeModal();
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const statusOptions = projectStatusData && projectStatusData.map(item => ({value: item.id, label: item.name}));

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Change status building!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            This function allows you to change the status of a project. Specify the desired status for project <span className="fw-semi-bold">{project.name}</span>.
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="status_id">
            <Form.Label column sm={4} className="text-lg-end">
              Status
            </Form.Label>
            <Col sm={8} md={7}>
              <Controller
                control={control}
                name="status_id"
                rules={{required: "Status must be required."}}
                render={({field}) => (
                  <Select
                   closeMenuOnSelect={true}
                    options={statusOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    {...field}
                    value={statusOptions.find(option => option.value === field.value) || null}
                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                  />
                )}
              />
              <span className="text-danger">
                {errors.status_id && errors.status_id.message}
              </span>
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" variant="primary" type="submit">Change</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

ChangeStatusProjectForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
}

export default ChangeStatusProjectForm;