import React, { lazy, Suspense, useEffect } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { FaRegListAlt } from 'react-icons/fa';
import { MdOutlineSummarize } from 'react-icons/md';
import GreetingCard from './change_order/chart/GreetingCard';
import SummaryStatusChart from './change_order/chart/SummaryStatusChart';
import { getChangeOrderStatusThisMonth } from 'redux/crm/report/changeOrderSlice';
import { useDispatch } from 'react-redux';

const LazyChangeOrderList = lazy(() => import("./change_order/ChangeOrderList"))
const LazyChangeOrderSummary = lazy(() => import("./change_order/ChangeOrderSummary"))

const ChangeOrder = () => {
  const { loading, statusThisMonthData } = useSelector((state) => state.crm_report_project_schedule)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getChangeOrderStatusThisMonth())
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={7}>
          <GreetingCard data={statusThisMonthData}/>
        </Col>
        <Col md={5}>
          <SummaryStatusChart data={statusThisMonthData} />
        </Col>
      </Row>

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="change-order-summary">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="change-order-summary"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineSummarize className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Summary</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="change-order-detail"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="change-order-summary">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyChangeOrderSummary />
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="change-order-detail">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyChangeOrderList/>
                    </Suspense>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ChangeOrder;