import QuotationWeekItem from "./QuotationWeekItem";
import React from 'react';
import PropTypes from "prop-types";

const QuotationLastWeek = ({data}) => {
  return (
    <QuotationWeekItem weekData={data} title="Quotations for last week" />
  );
};

QuotationLastWeek.propTypes = {
  data: PropTypes.object.isRequired
}

export default QuotationLastWeek;