import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import {
  initialStateStatus,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  createInitialState,
  handlePayload
} from "helpers/reducerHelpers";

const expenseUrl = "/booking/travel/setting/expenses";

export const getAllExpenses = createAsyncThunk(
  "Expense/getAllExpenses",
  async (params) => {
    try {
      const response = await apiAxios.get(expenseUrl, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getExpense = createAsyncThunk(
  "Expense/getExpense",
  async (expenseId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${expenseUrl}/${expenseId}`)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createExpense = createAsyncThunk(
  "Expense/createExpense",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(expenseUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateExpense = createAsyncThunk(
  "Expense/updateExpense",
  async ({ expenseId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${expenseUrl}/${expenseId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteExpense = createAsyncThunk(
  "Expense/deleteExpense",
  async (expenseId) => {
    try {
      const response = await apiAxios.delete(`${expenseUrl}/${expenseId}`);
      return response.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const cloneExpense = createAsyncThunk(
  "Expense/cloneExpense",
  async (expenseId) => {
    try {
      const response = await apiAxios.post(`${expenseUrl}/${expenseId}/clone`);
      return response.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getStaffPolicySalesExpenses = createAsyncThunk(
  "Expense/getStaffPolicySalesExpenses",
  async (params) => {
    try {
      const response = await apiAxios.get(`${expenseUrl}/staff_policy_sales_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getStaffPolicyCMDExpenses = createAsyncThunk(
  "Expense/getStaffPolicyCMDExpenses",
  async (params) => {
    try {
      const response = await apiAxios.get(`${expenseUrl}/staff_policy_cmd_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportExpenses = createAsyncThunk(
  "Expense/exportExpenses",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${expenseUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `expenses-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const ExpenseSlice = createSlice({
  name: "booking_setting_expense",
  initialState: {
    ...initialStateStatus,
    staffPolicySalesData: createInitialState(),
    staffPolicyCMDData: createInitialState()
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExpenses.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getStaffPolicySalesExpenses.fulfilled, (state, action) => handleFulfilled(state, action, "staffPolicySalesData"))
      .addCase(getStaffPolicyCMDExpenses.fulfilled, (state, action) => handleFulfilled(state, action, "staffPolicyCMDData"))

      .addCase(getExpense.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(createExpense.fulfilled, (state, action) => handlePayload(state, action, "create"))
      .addCase(updateExpense.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(deleteExpense.fulfilled, (state, action) => handlePayload(state, action, "delete"))
      .addCase(cloneExpense.fulfilled, (state, action) => handlePayload(state, action, "create"))
      .addCase(exportExpenses.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportExpenses.fulfilled, (state) => {
        state.exporting = false
        toast.success("Expense has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = ExpenseSlice.actions;

export default ExpenseSlice.reducer;