import React from 'react';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BookingHeader = () => {
  const navigate = useNavigate();

  const handleNewbooking = () => {
    navigate(`/booking/travel/new-travel-request`)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="primary"
              size="sm"
              icon="plane"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewbooking}
            >
              <span className="d-none d-xl-inline-block ms-1">New Travel booking</span>
            </IconButton>
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
};

export default BookingHeader;
