import React from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import noLogo from 'assets/img/logos/no-logo.png'
import GoogleMapsLink from 'helpers/googleMapsLink';
import CustomerDetailAddress from './CustomerDetailAddress';
import CustomerDetailContact from './CustomerDetailContact';
import { formatPhoneNumberInternational } from 'helpers/phoneNumber';
import Flex from 'components/common/Flex';
import { FaCode, FaFax, FaMailBulk, FaPhoneAlt, FaStreetView } from 'react-icons/fa';
import { MdWeb } from 'react-icons/md';
import OpportunityTable from './opportunity/OpportunityTable';
import LeadTable from './lead/LeadTable';
import Overview from './Overview';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CustomerGeneral = ({ customer={} }) => {
  const {
    allow_create_contact,
    allow_create_address
  } = customer?.permissions || {};

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-2">
                <Flex justifyContent="between">
                  <span className="text-primary">{customer?.name}</span>
                  <span>ID#: {customer?.customer_code}</span>
                </Flex>
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={3}>
              {customer && customer?.logo_url !== null ? 
                <img src={customer?.logo_url} alt="logo company" width={150} /> :
                <img src={noLogo} alt="logo company" width={150} />
              }
            </Col>
            <Col>
              <Row>
                <Col xs={3} sm={3}>
                  <p className="fw-semi-bold fs--1 mb-1"><FaPhoneAlt /> Work phone:</p>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <a href={`tel:${customer?.telephone}`} className="mb-1 fs--1 text-primary">{formatPhoneNumberInternational(customer?.telephone)}</a>
                    </Col>
                    <Col className="mb-1 fs--1">
                      EXT internal: <span className="mb-1 fs--1 text-primary">{customer?.ext_phone}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              <Row>
                <Col xs={3} sm={3}>
                  <p className="fw-semi-bold fs--1 mb-1"><FaMailBulk /> Work email:</p>
                </Col>
                <Col>
                  <a href = {`mailto: ${customer?.email}`} className="mb-1 fs--1 text-primary">{customer?.email}</a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={3}>
                  <p className="fw-semi-bold fs--1 mb-1"><FaFax /> Fax:</p>
                </Col>
                <Col>
                  <a href={`tel:${customer?.fax}`} className="mb-1 fs--1 text-primary">{formatPhoneNumberInternational(customer?.fax)}</a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={3}>
                  <p className="fw-semi-bold fs--1 mb-1"><FaCode /> Tax code:</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{customer?.tax_code}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={3}>
                  <p className="fw-semi-bold fs--1 mb-1"><MdWeb /> Website:</p>
                </Col>
                <Col>
                  <Link to={customer?.website} className="mb-1 fs--1 text-primary" target="_blank">{customer?.website}</Link>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={3}>
                  <p className="fw-semi-bold fs--1 mb-1"><FaStreetView /> Address:</p>
                </Col>
                <Col>
                  <GoogleMapsLink address={customer?.primary_address}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      
      <Tabs defaultActiveKey="customer-overview" id="customer-overview-project" transition={false}>
        <Tab eventKey="customer-overview" title="Overview" className='card border-bottom border-x p-4 mb-3'>
          <Overview customer={customer} />
        </Tab>
        <Tab eventKey="customer-projects" title="Opportunities" className='card border-bottom border-x p-4 mb-3'>
          <OpportunityTable data={customer?.projects} />
        </Tab>
        <Tab eventKey="customer-leads" title="Leads" className='card border-bottom border-x p-4 mb-3'>
          <LeadTable data={customer?.leads} />
        </Tab>
      </Tabs>

      <CustomerDetailContact customer={customer} allow_create={allow_create_contact} />
      <CustomerDetailAddress customer={customer} allow_create={allow_create_address} />
    </>
  );
};

CustomerGeneral.propTypes = {
  customer: PropTypes.object.isRequired
}

export default CustomerGeneral;
