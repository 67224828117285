import Flex from "components/common/Flex";
import { Button, Col, Form, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import { MdUpdate } from "react-icons/md";
import React from 'react';
import { formatDate } from "helpers/utils";

const UserDetailEmployeeMap = ({ employee, setshowModal }) => {
  const handleShowModal = () => {
    setshowModal(true)
  }

  const handleUpdateEmployee = () => {
    handleShowModal()
  }

  return (
    <>
      <Row>
        <Col lg xxl={6}>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Name</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Full of name</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.full_name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Title</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.title}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Email</p>
            </Col>
            <Col>
              <a href = {`mailto: ${employee?.email}`} className="text-primary fs--1 mb-1">{employee?.email}</a>
            </Col>
          </Row>
          <Row className="mt-2 fs--1">
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold">Gender</p>
            </Col>
            <Col>
              <Flex direction={'row'}>
                <Form.Check 
                  type='radio'
                  id='gender-male'
                  label='Male'
                  name='gender'
                  checked={!employee?.gender}
                  className="me-3"
                />
                <Form.Check 
                  type='radio'
                  id='gender-female'
                  label='Female'
                  name='gender'
                  checked={employee?.gender}
                />
              </Flex>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Phone number</p>
            </Col>
            <Col>
              <a href={`tel:${employee?.phone_number}`} className="text-primary fs--1 mb-1">{employee?.phone_number}</a>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Manager</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.parent?.name}</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold  fs--1 mb-1">Active</p>
            </Col>
            <Col>
              <Form.Check 
                type='switch'
                className=""
                id='employee-active'
                checked={employee?.active}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row className="my-4">
        <Col lg xxl={6}>
          <h6 className="fw-semi-bold ls mb-3 text-uppercase">
            Department
          </h6>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Department</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.department?.name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Site office</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.site?.name}</p>
            </Col>
          </Row>
        </Col>
        <Col>
          <h6 className="fw-semi-bold ls mb-3 text-uppercase">
            HR
          </h6>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Start date</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{formatDate(employee?.start_date)}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">HR code</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.hr_code}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Birthday</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.birthday}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Staff policy</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.staff_policy}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={4}>
              <p className="fw-semi-bold fs--1 mb-1">Position group</p>
            </Col>
            <Col>
              <p className="text-primary fs--1 mb-1">{employee?.position_group?.name}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Flex justifyContent="end">
            <Button variant="info" size="sm" className="ms-2" onClick={handleUpdateEmployee}><MdUpdate /> Update Employee</Button>
          </Flex>
        </Col>
      </Row>
    </>
  )
}

UserDetailEmployeeMap.propTypes = {
  employee: PropTypes.object.isRequired,
  setshowModal: PropTypes.bool.isRequired
}


export default UserDetailEmployeeMap;