import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import DateTimeToText from 'helpers/dateTimetoText';
import { useDispatch } from 'react-redux';
import { getAllNotifications, markAllReadNotifications, markReadNotification, setCurrentPage, setPageSize } from 'redux/notificationSlice';
import { useSelector } from 'react-redux';
import Avatar from 'components/common/Avatar';
import AgPagination from 'components/ag-grid/AgPagination';
import classNames from 'classnames';
import { toast } from 'react-toastify';

const NotificationList = () => {
  const {
    data,
    totalRows,
    totalPagesCount,
    currentPage,
    pageSize,
    fromRecord,
    toRecord,
    loading,
    readCount,
    unReadCount
  } = useSelector((state) => state.notification)
  const [markAllRead, setMarkAllRead] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllNotifications({page: currentPage, per_page: pageSize}))
  }, [currentPage, pageSize, markAllRead, readCount])

  const handlePageChange = ((page, pageSize) => {
    dispatch(setCurrentPage(page))
    dispatch(setPageSize(pageSize))
  })

  const handleMarkAllasRead = async () => {
    try {
      await dispatch(markAllReadNotifications())
      setMarkAllRead(true)
      toast.success("Mark all as read notifications was successfully.")
    }
    catch(error) {
      toast.error(error.message)
    }
  }

  const handleRead = async (notification) => {
    if (!notification.unread) return
    await dispatch(markReadNotification(notification.id))
  }

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col sm="auto">
            <h5 className="mb-1 mb-md-0">
              Your Notifications
              <Badge pill bg="primary" className="mx-2">
                {totalRows}
              </Badge>
            </h5>
          </Col>
          <Col sm="auto" className="fs--1 text-right">
            <Link className="font-sans-serif ms-2 ms-sm-3" onClick={handleMarkAllasRead}>
              Mark all as read
            </Link>
          
            <div className="ms-2 ms-sm-3">
              <span className="me-2">Read <Badge pill bg="success">{readCount}</Badge></span>
              <span>Unread <Badge pill bg="warning">{unReadCount}</Badge></span>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {loading && 
          <div className="text-center my-2">
            <Spinner animation="border" />
          </div>
        }
        
        { data && data.map(notification => {
          return (
            <div
              key={notification.id}
              className={classNames(
                'notification',
                { 'notification-unread': notification.unread },
                "rounded-0 border-x-0 border-300 border-bottom-0"
              )}
              onClick={() => handleRead(notification)}
            >
              {notification.avatar && (
                <div className="notification-avatar">
                  <Avatar {...notification.avatar} className="me-3" />
                </div>
              )}
              <div className="notification-body">
                <p className="mb-1" dangerouslySetInnerHTML={createMarkup(notification.message)} />
                <span className="notification-time">
                  {notification.emoji && (
                    <span className="me-2" role="img" aria-label="Emoji">
                      {notification.emoji}
                    </span>
                  )}
                  <DateTimeToText datetime={notification.created_at} />
                </span>
              </div>
            </div>
          )
        })}

        <Row className="m-3">
          <Col>
            <AgPagination
              totalRows={totalRows}
              totalPagesCount={totalPagesCount}
              pageChange={handlePageChange}
              pageSize={pageSize}
              from={fromRecord}
              to={toRecord}
              itemsPages={["10", "20", "50"]}
            />
          </Col>
        </Row>

      </Card.Body>
    </Card>
  )
}

export default NotificationList