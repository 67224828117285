import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import "./DateRangePicker.scss"
import moment from "moment";
import PropTypes from 'prop-types';

const DatePresets = (props) => {
  const { startDate, endDate, showDateFormat, handlePresets } = props;
  const today = moment();
  const presets = [
    {
      text: "Yesterday",
      start: moment().subtract(1, 'day').startOf("day"),
      end: moment().subtract(1, 'day').endOf("day"),
    },
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Last week",
      start: moment().subtract(1, 'week').startOf("week"),
      end: moment().subtract(1, 'week').endOf("week"),
    },
    {
      text: "This week",
      start: moment().startOf("week"),
      end: moment().endOf("week"),
    },
    {
      text: "Last month",
      start: moment().subtract(1, 'month').startOf("month"),
      end: moment().subtract(1, 'month').endOf("month"),
    },
    {
      text: "This month",
      start: moment().startOf("month"),
      end: moment().endOf("month"),
    },
    {
      text: "Last quarter",
      start: moment().subtract(1, 'quarter').startOf("quarter"),
      end: moment().subtract(1, 'quarter').endOf("quarter"),
    },
    {
      text: "This quarter",
      start: moment().startOf("quarter"),
      end: moment().endOf("quarter"),
    },
    {
      text: "Last year",
      start: moment().subtract(1, 'year').startOf("year"),
      end: moment().subtract(1, 'year').endOf("year"),
    },
    {
      text: "This year",
      start: moment().startOf("year"),
      end: moment().endOf("year"),
    }
  ];
  return (
    <div className="p-3 fs--1">
      {presets.map(({ text, start, end }) => {
        const isChosen =
          moment(start).format(showDateFormat) ===
            moment(startDate).format(showDateFormat) &&
          moment(end).format(showDateFormat) ===
            moment(endDate).format(showDateFormat);
        return (
          <div
            key={text}
            className={`me-1 cursor-pointer mb-1 ${
              isChosen ? "text-primary" : ""
            }`}
            onClick={() => handlePresets(start, end)}
          >
            {text}
          </div>
        );
      })}
    </div>
  );
};

DatePresets.propTypes = {
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  showDateFormat: PropTypes.string.isRequired,
  handlePresets: PropTypes.func.isRequired
};

const DateRangePickerApp = ({ startDate, setStartDate, endDate, setEndDate, onDatesChange }) => {
  const dateFormat = "DD-MM-YYYY"
  const currentDate = moment() //new Date()

  // const [startDate, setStartDate] = useState(moment().startOf('year'));
  // const [endDate, setEndDate] = useState(currentDate);

  const [dateInput, setDateInput] = useState(currentDate);

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="s_id"
      endDate={endDate}
      endDateId="e_id"
      onDatesChange={onDatesChange}
      focusedInput={dateInput}
      onFocusChange={(e) => setDateInput(e)}
      displayFormat={() => dateFormat}
      showSelectedDates
      keepOpenOnDateSelect
      //editableDateInputs={false}
      readOnly
      anchorDirection="right"
      isOutsideRange={() => false} //noted here for case don't want to select past date
      calendarInfoPosition="before"
      small
      renderCalendarInfo={() => (
        <DatePresets
          startDate={startDate}
          endDate={endDate}
          dateFormat={dateFormat}
          handlePresets={(start, end) => {
            setStartDate(start)
            setEndDate(end)
          }}
        />
      )}
    />
  )
};

DateRangePickerApp.propTypes = {
  startDate: PropTypes.instanceOf(moment).isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  setEndDate: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired
};

export default DateRangePickerApp;