import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const contractUrl = `/crm/reports/contract`;

export const getContracts = createAsyncThunk(
  "ReportContract/getContracts",
  async (params) => {
    try {
      const response = await apiAxios.get(contractUrl, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportContracts = createAsyncThunk(
  "ReportContract/exportContracts",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${contractUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'contracts.xlsx');
    document.body.appendChild(link);
    link.click();
  }
)

export const getColumnsContract = createAsyncThunk(
  "ReportContract/getColumnsContract",
  async () => {
    try {
      const response = await apiAxios.get(`${contractUrl}/attributes`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getContractDashboard = createAsyncThunk(
  "ReportContract/getContractDashboard",
  async (params) => {
    try {
      const response = await apiAxios.get(`${contractUrl}/dashboard`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getContractThisMonth = createAsyncThunk(
  "ReportContract/getContractThisMonth",
  async () => {
    try {
      const response = await apiAxios.get(`${contractUrl}/this_month`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const contractSlice = createSlice({
  name: "report_contract",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    filterTags: [],
    dashboardData: [],
    thisMonthData: []
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    },
    setFilterTag: (state, action) => {
      state.filterTags = action.payload
    },
    resetFilterTag: (state) => {
      state.filterTags = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate           = state.paginate
        const calRecords         = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(getColumnsContract.fulfilled, (state, action) => {
        state.sortColumns = action.payload.sort_columns
      })
      .addCase(exportContracts.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportContracts.fulfilled, (state) => {
        state.exporting = false
        toast.success("Contract has been exported successfully.")
      })
      .addCase(getContractDashboard.fulfilled, (state, action) => {
        state.dashboardData = action.payload
      })
      .addCase(getContractThisMonth.fulfilled, (state, action) => {
        state.thisMonthData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = contractSlice.actions;

export default contractSlice.reducer;