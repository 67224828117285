import React, { useEffect, useState } from 'react';
import { Card, Col, ProgressBar, Row, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { BY_NUMBER_WEIGHT_FILTER } from 'app_config';
import { BsFunnelFill } from 'react-icons/bs';
import PropTypes from'prop-types';

const SalesFunnel = ({data}) => {
  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_FILTER[0])
  const [dataObject, setDataObject] = useState([])

  const isWeight = () => {
    return numberWeightFilter === BY_NUMBER_WEIGHT_FILTER[0]
  }

  useEffect(() => {
    if (!data) return;

    const newData = Object.entries(data).map(([name, values]) => ({
      name,
      value: isWeight() ? values.weight : values.number_of_projects
    }));

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };
  
  const total = dataObject.reduce((acc, data) => acc + data.value, 0);

  const setVariant = (name) => {
    let color = "primary";

    switch(name) {
      case "Quotation":
        color = "primary";
        break;
      case "Negotiation":
        color = "warning";
        break;
      case "Job":
        color = "success";
        break;
      case "Lost":
        color = "danger";
        break;
    }

    return color;
  }

  const percentValue = (value) => {
    return ((value / total) * 100).toFixed(0);
  }

  const formatWeight = () => {
    return isWeight() ? "M.T" : "";
  }

  return (
    <Card className="h-100 mb-3">
      <FalconCardHeader
        title={<span><BsFunnelFill /> PCV Sales Funnel</span>}
        titleTag="h5"
        className="border-200 border-bottom py-2"
        endEl={
          <Form.Select
            size="sm"
            onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
            className="mx-1 fs--1"
          >
            {BY_NUMBER_WEIGHT_FILTER.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Form.Select>
        }
      />
      <Card.Body as={Flex} alignItems="center">
        <div className="w-100 table-responsive">
          <h3 className="text-700 mb-6">{total?.toLocaleString()} {formatWeight()}</h3>
          <ProgressBar
            className="font-sans-serif fs--1 fw-medium mt-xxl-auto rounded-4 overflow-visible"
            style={{ height: 20 }}
          >
            {dataObject.map((item, index) => (
              <ProgressBar
                variant={setVariant(item.name)}
                now={percentValue(item.value)}
                key={index}
                className={classNames('overflow-visible position-relative', {
                  'rounded-end rounded-pill': index === 0,
                  'rounded-start rounded-pill':
                    index === dataObject.length - 1,
                  'border-end border-white border-2':
                    index !== dataObject.length - 1,
                  'rounded-0':
                    index !== dataObject.length - 1 && index !== 0
                })}
                label={
                  <span
                    className="text-700 position-absolute"
                    style={{ top: -30 }}
                  >
                    {item.value?.toLocaleString()} {formatWeight()}
                  </span>
                }
              />
            ))}
          </ProgressBar>
          <Row className="fs--1 fw-semi-bold text-500 mt-3 g-0">
            {dataObject.map((item, index) => (
              <Col
                xs="auto"
                key={index}
                as={Flex}
                alignItems="center"
                className="pe-3"
              >
                <span className={`dot bg-${setVariant(item.name)}`}></span>
                <span>{item.name}</span>
                <span className="d-none d-md-inline-block d-lg-none d-xxl-inline-block">
                  ({percentValue(item.value)}%)
                </span>
              </Col>
            ))}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

SalesFunnel.propTypes = {
  data: PropTypes.object.isRequired
}

export default SalesFunnel;
