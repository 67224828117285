import React from 'react';
import Flex from 'components/common/Flex';
import LoginFormOversea from '../LoginFormOversea';

const LoginOversea = () => (
  <>
    <Flex justifyContent="center" alignItems="center" className="mb-2">
      <h5>Log in</h5>
    </Flex>

    <LoginFormOversea />
  </>
);

export default LoginOversea;
