import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deletePermission  } from 'redux/authorization/permissionSlice';
import PermissionForm from './PermissionForm';
import { MdFindReplace } from 'react-icons/md';
import PermissionFindAndReplace from './PermissionFindAndReplace';
import { FaEdit, FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

const PermissionHeader = ({ permissionSelected }) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const [title, setTitle] = useState('');
  const [showFindandReplaceForm, setShowFindandReplaceForm] = useState(false);
  const [permission, setPermission] = useState(null);
  const [permissionIds, setPermissionIds] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setPermission(permissionSelected.at(-1));
  }, [permissionSelected])

  const validatePermissionSelected = () => {
    if (permissionSelected.length === 0)
    {
      toast.error('Permission has not selected yet.')
      return false
    }
    return true
  }

  const validatePermissionSelectedMultiple = () => {
    if (permissionSelected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of permission.')
      return false
    }
    return true
  }

  const showForm = () => {
    setShowPermissionForm(true)
  }

  const handleNewPermission = () => {
    setTitle("New Permission")
    showForm()
    setPermission(null)
  }

  const handleEditPermission = () => {
    if (!validatePermissionSelected()) return
    if (!validatePermissionSelectedMultiple()) return

    setTitle("Edit Permission")
    showForm()
    setPermission(permissionSelected.at(-1))
  }

  const handledeletePermission = () => {
    if (!validatePermissionSelected()) return

    setShowConfirmationDelete(true)


    if (Array.isArray(permissionSelected)) {
      const ids = permissionSelected.map(item => item.id)
      setPermissionIds(ids)
    }
    else {
      setPermissionIds([permissionSelected.id]);
    }
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(deletePermission(permissionIds))
    setShowConfirmationDelete(false) 
  }

  const handleFindAndReplace = () => {
    setShowFindandReplaceForm(true);
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="user-plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewPermission}
            >
              <span className="d-none d-xl-inline-block ms-1">New Permission</span>
            </IconButton>

            <Button size="sm" variant="falcon-default" className="me-1" onClick={handleEditPermission}>
              <FaEdit /> Edit
            </Button>

            <Button size="sm" variant="falcon-default" className="me-1" onClick={handleFindAndReplace}>
              <MdFindReplace /> Find and Replace
            </Button>

            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="text-danger" onClick={handledeletePermission}>
                    <FaTrashAlt icon="trash-alt" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleOkConfirm}
          message="Are you sure to delete?"
        />
      )}
      
      {showPermissionForm && (
        <PermissionForm
          showModal={showPermissionForm}
          setshowModal={setShowPermissionForm}
          title={title}
          permission={permission}
        />
      )}
 
      {showFindandReplaceForm && (
        <PermissionFindAndReplace
          showModal={showFindandReplaceForm}
          setshowModal={setShowFindandReplaceForm}
        />
      )}
      
    </>
  );
};

PermissionHeader.propTypes = {
  permissionSelected: PropTypes.array
}

export default PermissionHeader;
