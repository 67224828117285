import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import Hotel from './itinerary/hotel/Hotel';
import Shuttle from './itinerary/shuttle/Shuttle';
import Other from './itinerary/other/Other';
import PropTypes from 'prop-types';

const BookingScheduleItinerary = ({booking}) => {
  const [activeKey, setActiveKey] = useState(["0", "1", "2"])

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <>
      <Accordion activeKey={activeKey} alwaysOpen flush onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon icon={faBusinessTime}className="me-1"/> Shuttle
          </Accordion.Header>
          <Accordion.Body>
            <Shuttle booking={booking}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <FontAwesomeIcon icon="hotel" className="me-1"/> Accommodation
          </Accordion.Header>
          <Accordion.Body>
            <Hotel booking={booking} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FontAwesomeIcon icon="calendar" className="me-1"/> Others
          </Accordion.Header>
          <Accordion.Body>
            <Other booking={booking} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
};

BookingScheduleItinerary.propTypes = {
  booking: PropTypes.object.isRequired
}

export default BookingScheduleItinerary;