import { Row, Col } from "react-bootstrap";
import LeadEntryChart from "./LeadEntryChart";
import React from 'react';
import PropTypes from 'prop-types';

const LeadEntryItem = ({data, color}) => {

  const sumData = data && Object.values(data).reduce((acc, item) => {
    acc.number_of_projects += parseInt(item.number_of_projects) || 0;
    acc.weight += parseFloat(item.weight) || 0;

    return acc;
  }, {number_of_projects: 0, weight: 0})

  return (
    <div className="h-100 mb-3">
      <Row className="g-3">
        <Col md="8" sm="12">
          <LeadEntryChart data={data} color={color} />
        </Col>
        <Col md="4" sm="12">
          <div className="fs--1">
          <h6 className="mb-2">Detail:</h6>
          
          <table className="border">
            <thead className="bg-light">
              <tr className="border">
                <th className="px-2 border">Name</th>
                <th className="px-2 border">Number of leads</th>
              </tr>
            </thead>
            <tbody>
              {data && Object.entries(data).map(([name, values], index) => (
                <tr key={index}>
                  <td className="text-start px-2 border">{name}</td>
                  <td className="text-end px-2 border">{values.number_of_projects}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-light text-primary fw-bold">
              <tr>
                <td className="text-center px-2 border">Total</td>
                <td className="text-end px-2 border">{sumData?.number_of_projects}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        </Col>
      </Row>
    </div>
  );
};

LeadEntryItem.propTypes = {
  data: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired
};


export default LeadEntryItem;