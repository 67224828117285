import React from "react";
import EmployeeForm from "./EmployeeForm";

const NewEmployee = () => {
  return (
    <EmployeeForm title="New Employee" edit={false} />
  )
}

export default NewEmployee;
