import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { Controller, useForm } from "react-hook-form";
import TinymceEditor from "components/common/TinymceEditor";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getProjectBuildings } from "redux/crm/project/quotationSlice";
import { objectSerializer } from "helpers/utils";
import {v4 as uuidv4} from "uuid";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';

const AddChangeBuildingForm = ({showModal, setShowModal, project, building, setWaiverBuildings}) => {
  const {control, handleSubmit, formState: {errors}, setValue} = useForm();
  const [buildings, setBuildings] = useState([]);
  const dispatch = useDispatch();
  const projectId = project.id;

  useEffect(() => {
    fetchBuildings()
  }, [project]);

  useEffect(() => {
    if (building) {
      Object.entries(building).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [project. building, setValue])

  const buildingOptions = (
    buildings && buildings.map((building) => ({value: building.id, label: building.name }) )
  )

  const fetchBuildings = async () => {
    const response = await dispatch(getProjectBuildings({ projectId }))
    setBuildings(objectSerializer(response.payload.rows))
  }

  // const uniqueBuildingId = Array.from(
  //   new Set(waiverBuildings.flatMap(building => building.deal_proj_ids))
  // )

  const closeModal = () => {
    setShowModal(false)
  }

  const handleChangeBuilding = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('deal_proj_ids', selectedOptionsValues, { shouldValidate: true })
  }

  // const validateDuplication = (data) => {
  //   if (waiverBuildings) {
  //     const building_name = data.deal_proj_ids.map((id) => {
  //       const building = waiverBuildings.find((b) => b.id === id);
  //       return building ? building.name : '';
  //     })
  //   }
  // } 

  const onSubmit = async (data) => {
    // Recompute the building_name based on updated deal_proj_ids
    const building_name = data.deal_proj_ids.map((id) => {
      const building = buildings.find((b) => b.id === id);
      return building ? building.name : '';
    });
  
    if (building) {
      setWaiverBuildings((prevBuildings) => {
        const newWaiverBuildings = [...prevBuildings];
        const updateIndex = newWaiverBuildings.findIndex((item) => item.id === building.id);
        
        // Update the building data, including the updated name
        newWaiverBuildings[updateIndex] = {
          ...data,
          name: building_name.sort().join(", "),
        };
        
        return newWaiverBuildings;
      });
  
      toast.success(`${building_name.sort().join(", ")} was successfully updated.`);
      closeModal();
    } else {
      setWaiverBuildings((prevBuildings) =>
        prevBuildings.concat({ ...data, id: uuidv4(), name: building_name.sort().join(", ") })
      );
  
      toast.success(`${building_name.sort().join(", ")} was successfully added.`);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1 p-4"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{building ? "Edit" : "Add"} building for wavier!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Specify buildings and reason for waiver.
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="deal_proj_ids">
            <Form.Label column sm={2} className="text-lg-end">
              Buildings
            </Form.Label>
            <Col>
              <Controller
                control={control}
                name="deal_proj_ids"
                rules={{required: "Building must be required."}}
                render={({field}) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={false}
                    options={buildingOptions}
                    value={field.value?.map((id) => 
                      buildingOptions.find((option) => option.value === id)
                    )}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    isMulti
                    onChange={(selected) => {
                      field.onChange(selected.map((option) => option.value));
                      handleChangeBuilding(selected);
                    }}
                  />
                )}
              />
              <span className="text-danger">
                {errors.deal_proj_ids && errors.deal_proj_ids.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="reason">
            <Form.Label column sm={2} className="text-lg-end">
              Reason
            </Form.Label>
            <Col>
              <Controller
                control={control}
                name="reason"
                rules={{required: "Reason must be required."}}
                render={({ field }) => (
                  <TinymceEditor
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <span className="text-danger">
                {errors.reason && errors.reason.message}
              </span> 
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" variant="primary" type="submit">{building ? "Update" : "Add"} building</Button>
          <Button size="sm" variant="falcon-default" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

AddChangeBuildingForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number,
    building: PropTypes.object
  }),
  building: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    deal_proj_ids: PropTypes.array
  }),
  setWaiverBuildings: PropTypes.func.isRequired
}

export default AddChangeBuildingForm;