import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deactiveEmployee, deleteEmployee } from 'redux/organization/employeeSlice';
import { GrUpdate } from "react-icons/gr";
import { FaEdit, FaEllipsisV, FaTrashAlt } from 'react-icons/fa';
import ChangeManagerForm from "./ChangeManagerForm";
import { MdSync } from 'react-icons/md';
import { MdCloudSync, MdOutlineMultipleStop } from "react-icons/md";
import SyncNewEmployeeConfirm from "./component/SyncNewEmployeeConfirm";
import SyncTitleEmployeeChangedConfirm from "./component/SyncTitleEmployeeChangedConfirm";
import SyncTerminalEmployeeConfirm from "./component/SyncTerminalEmployeeConfirm";

const EmployeeHeader = ({ selected }) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showChangeManager, setShowChangeManager] = useState(false);
  const [showNewEmployeeConfirm, setShowNewEmployeeConfirm] = useState(false);
  const [showTitleEmployeeChangedConfirm, setShowTitleEmployeeChangedConfirm] = useState(false);
  const [showTerminalEmployeeConfirm, setShowTerminalEmployeeConfirm] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employee = selected[0];
  const employeeId = employee?.id;

  const validateselected = () => {
    if (selected.length === 0)
    {
      toast.error('Employee has not selected yet.');
      return false;
    }
    return true;
  }

  const validateselectedMultiple = () => {
    if (selected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of employee.');
      return false;
    }
    return true;
  }

  const handleNewEmployee = () => {
    navigate("/organization/employees/new");
  }

  const handleEditEmployee = () => {
    if (!validateselected()) return
    if (!validateselectedMultiple()) return;
    navigate(`/organization/employees/${employee.id}/edit`, {state: employee});
  }

  const handleDisableEmployee = () => {
    if (!validateselected()) return;

    dispatch(deactiveEmployee(employeeId));
  }

  const handleDeleteEmployee = () => {
    if (!validateselected()) return

    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false);
  }

  const handleOkConfirm = async () => {
    dispatch(deleteEmployee(employeeId));
    setShowConfirmationDelete(false) ;
  }

  const handleChangeManager = () => {
    if (selected && selected.length === 0) {
      toast.error("No employee selected.")
      return;
    }
    setShowChangeManager(true);
  }

  const handleSyncNewEmployee = () => {
    setShowNewEmployeeConfirm(true);
  }

  const handleSyncTitleEmployeeChanged = () => {
    setShowTitleEmployeeChangedConfirm(true);
  }

  const handleSyncTerminalEmployee = () => {
    setShowTerminalEmployeeConfirm(true);
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewEmployee}
            >
              <span className="d-none d-xl-inline-block ms-1">New employee</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="lock"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleDisableEmployee}
            >
              <span className="d-none d-xl-inline-block ms-1">
                {employee && employee.active ? 'Deactivate' : 'Active'} 
              </span>
            </IconButton>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="text-warning" onClick={handleChangeManager}>
                    <GrUpdate /> Change manager
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item className="text-primary mb-2" onClick={handleSyncNewEmployee}>
                    <MdCloudSync /> Sync new employee
                  </Dropdown.Item>

                  <Dropdown.Item className="text-primary mb-2" onClick={handleSyncTitleEmployeeChanged}>
                    <MdSync /> Sync title employee changed
                  </Dropdown.Item>

                  <Dropdown.Item className="text-primary" onClick={handleSyncTerminalEmployee}>
                    <MdOutlineMultipleStop /> Sync terminal employee
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleEditEmployee}>
                    <FaEdit /> Edit
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item className="text-danger" onClick={handleDeleteEmployee}>
                    <FaTrashAlt /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleOkConfirm}
          message="Are you sure to delete?"
        />
      )}

      {showChangeManager && (
        <ChangeManagerForm
          open={showChangeManager}
          setOpen={setShowChangeManager}
          records={selected}
        />
      )}

      {showNewEmployeeConfirm && (
        <SyncNewEmployeeConfirm
          showModal={showNewEmployeeConfirm}
          setshowModal={setShowNewEmployeeConfirm}
        />
      )}

      {showTitleEmployeeChangedConfirm && (
        <SyncTitleEmployeeChangedConfirm
          showModal={showTitleEmployeeChangedConfirm}
          setshowModal={setShowTitleEmployeeChangedConfirm}
        />
      )}

      {showTerminalEmployeeConfirm && (
        <SyncTerminalEmployeeConfirm
          showModal={showTerminalEmployeeConfirm}
          setshowModal={setShowTerminalEmployeeConfirm}
        />
      )}
    </>
  );
};

export default EmployeeHeader;
