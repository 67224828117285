import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { ExportToExcel } from "helpers/utils";
import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const ContractDetail = ({data=[]}) => {
  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'Project ID#',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold'
      }
    },
    {
      accessor: 'project_name',
      Header: 'Project name',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, project_name } = rowData.row.original;
        return <Link to={`/crm/projects/${id}`} target="_blank">{project_name}</Link>;
      }
    },
    {
      accessor: 'q_number',
      Header: 'Quote number',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, q_number } = rowData.row.original;
        return <Link to={`/crm/projects/${id}`} target="_blank">{q_number}</Link>;
      }
    },
    {
      accessor: 'j_number',
      Header: 'P/J number',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, j_number } = rowData.row.original;
        return <Link to={`/crm/projects/${id}`} target="_blank">{j_number}</Link>;
      }
    },
    {
      accessor: 'customer_name',
      Header: 'Customer name'
    },
    {
      accessor: 'contract_date',
      Header: 'Contract date'
    },
    {
      accessor: 'contract_weight',
      Header: 'Weight (M.T)'
    },
    {
      accessor: 'contract_value',
      Header: 'Value (USD)'
    },
    {
      accessor: 'sales_incharge',
      Header: 'Sales incharge'
    },
    {
      accessor: 'sales_unit_region',
      Header: 'Sales region'
    },
    {
      accessor: 'pj_province',
      Header: 'PJ province'
    },
    {
      accessor: 'pj_country',
      Header: 'PJ country'
    }
  ], [data]);

  const handleExport = (data) => {
    ExportToExcel(data, "contract-data")
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      selection
      sortable
      pagination
      perPage={15}
      rowCount={data?.length}
    >
      <Card className="p-2">
        <Card.Header>
          <Flex justifyContent="end" className="mb-0" alignContent="center" alignItems="center">
            <AdvanceTableSearchBox table className="w-md-25 w-sm-50" />
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
              onClick={() => handleExport(data)}
              style={{ whiteSpace: 'nowrap' }}
              className="ms-1"
            >
              <span className="ms-1">Export</span>
            </IconButton>
          </Flex>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="btn-reveal-trigger text-nowrap align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={data?.length} table rowInfo navButtons rowsPerPageSelection />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

ContractDetail.propTypes = {
  data: PropTypes.array.isRequired
} 

export default ContractDetail;