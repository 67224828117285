import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload,
  createInitialState
} from 'helpers/reducerHelpers';

const roleUrl = "/authorization/roles";

export const getAllRoles = createAsyncThunk(
  "Role/getAllRoles",
  async (params) => {
    try {
      const response = await apiAxios.get(`${roleUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getRole = createAsyncThunk(
  "Role/getRole",
  async (roleId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${roleUrl}/${roleId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createRole = createAsyncThunk(
  "Role/createRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(roleUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateRole = createAsyncThunk(
  "Role/updateRole",
  async ({ roleId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${roleUrl}/${roleId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteRole = createAsyncThunk(
  "Role/deleteRole",
  async (role) => {
    try {
      const response = await apiAxios.delete(`${roleUrl}/${role.id}`);
      return response.data

    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportRoles = createAsyncThunk(
  "Role/exportRoles",
  async (params) => {
    const response = await apiAxios.get(`${roleUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Roles.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const assignRoleUser = createAsyncThunk(
  "Role/assignRoleUser",
  async ({ role, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${roleUrl}/${role.id}/assign_user`, data);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const removeRoleUser = createAsyncThunk(
  "Role/removeRoleUser",
  async ({ role, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${roleUrl}/${role.id}/remove_user`, data);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const assignRolePermission = createAsyncThunk(
  "Role/assignRolePermission",
  async ({ role, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${roleUrl}/${role.id}/assign_permission`, data);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const removeRolePermission = createAsyncThunk(
  "Role/removeRolePermission",
  async ({ role, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${roleUrl}/${role.id}/remove_permission`, data);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getPermissionsTreeView = createAsyncThunk(
  "Role/getPermissionsTreeView",
  async (roleId) => {
    try {
      const response = await apiAxios.get(`${roleUrl}/${roleId}/permission_treeview` )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const cloneRole = createAsyncThunk(
  "Role/cloneRole",
  async ({sourceRoleId, data}) => {
    try {
      const response = await apiAxios.post(`${roleUrl}/${sourceRoleId}/clone`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getActivityLog = createAsyncThunk(
  "Role/getActivityLog",
  async ({ roleId, params } ) => {
    try {
      const response = await apiAxios.get(`${roleUrl}/${roleId}/activity_log`, { params })
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getRoleByUsers = createAsyncThunk(
  "Role/getRoleByUsers",
  async (params) => {
    try {
      const response = await apiAxios.get(`/authorization/reports/role_by_users`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getRoleByPermissions = createAsyncThunk(
  "Role/getRoleByPermissions",
  async (params) => {
    try {
      const response = await apiAxios.get(`/authorization/reports/role_by_permissions`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportRoleByUsers = createAsyncThunk(
  "Role/exportRoleByUsers",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const urlApi = "/authorization/reports/role_by_users/export";
    const response = await apiAxios.get(urlApi, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'role-by-users.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const exportRoleByPermissions = createAsyncThunk(
  "Role/exportRoleByPermissions",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const urlApi = "/authorization/reports/role_by_permissions/export";
    const response = await apiAxios.get(urlApi, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'role-by-permissions.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const roleSlice = createSlice({
  name: "authorization_role",
  initialState: {
    ...initialState,
    roleByUserData: createInitialState(),
    roleByPermissionData: createInitialState(), 
    filterValue: "",
    permissionTreeViewData: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper,
    setFilterValue: (state, action) => {
      state.filterValue = action.payload
    },
    resetFilterValue: (state) => {
      state.filterValue = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getRoleByUsers.fulfilled, (state, action) => handleFulfilled(state, action, "roleByUserData"))
      .addCase(getRoleByPermissions.fulfilled, (state, action) => handleFulfilled(state, action, "roleByPermissionData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))

      .addCase(getRole.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createRole.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(exportRoles.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportRoles.fulfilled, (state) => {
        state.exporting = false
        toast.success("Role has been exported successfully.")
      })
      .addCase(assignRoleUser.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeRoleUser.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(assignRolePermission.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeRolePermission.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(getPermissionsTreeView.fulfilled, (state, action) => {
        state.permissionTreeViewData = action.payload
      })
      .addCase(exportRoleByUsers.fulfilled, (state) => {
        state.exporting = false
        toast.success("Role by users has been exported successfully.")
      })
      .addCase(exportRoleByPermissions.fulfilled, (state) => {
        state.exporting = false
        toast.success("Role by permission has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
  setFilterValue,
  resetFilterValue
} = roleSlice.actions;

export default roleSlice.reducer;