import { Row, Col, Dropdown } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { formatPhoneNumberInternational, formatPhoneNumberUrl } from 'helpers/phoneNumber';
import SoftBadge from 'components/common/SoftBadge';
import { ExportToExcel } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch } from 'react-redux';
import IconButton from 'components/common/IconButton';
import { deleteContactCustomer } from 'redux/crm/customerSlice';
import PropTypes from 'prop-types';

const ContactTable = ({customer, contacts}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [contact, setContact] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerId = customer ? customer.id : null;
  const contactUrl = '/crm/contacts';

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID#',
      Cell: rawData => {
        const {id} = rawData.row.original
        return (
          id && <a href={`${contactUrl}/${id}`} target='_blank' rel="noreferrer">{id}</a>
        )
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: rawData => {
        const {id, name} = rawData.row.original
        return (
          id && <a href={`${contactUrl}/${id}`} target='_blank' rel="noreferrer">{name}</a>
        )
      }
    },
    {
      accessor: 'position',
      Header: 'Title'
    },
    {
      accessor: 'department',
      Header: 'Department'
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: rowData => {
        const {email} = rowData.row.original

        return (
          email && <a href={'mailto:' + email}>{email}</a>
        )
      }
    },
    {
      accessor: 'mobile',
      Header: 'Phone number',
      Cell: rowData => {
        const {mobile} = rowData.row.original
        return (
          mobile && <a href={formatPhoneNumberUrl(mobile)}>{formatPhoneNumberInternational(mobile)}</a>
        )
      }
    },
    {
      accessor: 'main_contact',
      Header: 'Main contact'
    },
    {
      accessor: 'secondary_contact',
      Header: 'Secondary contact',
      Cell: rowData => {
        const {secondary_contact} = rowData.row.original
        return (
          secondary_contact && secondary_contact.map((name, index) => (
            <SoftBadge bg='info' key={index} className='me-2'>{name}</SoftBadge>
          ))
        )
      }
    },
    {
      accessor: 'created_by',
      Header: 'Created by'
    },
    {
      accessor: 'created_at',
      Header: 'Created date'
    },
    {
      accessor: 'Action',
      Header: 'Action',
      headerProp: 'text-end',
      cellProp: 'text-end',
      disableSortBy: true,
      Cell: (rowData) => {
        const contact = rowData.row.original;
        const {
          allow_view,
          allow_edit,
          allow_delete,
          allow_assign
        } = contact?.permissions || {};

        return (
          <div>
            <Dropdown align='end' className='btn-reveal-trigger d-inline-block'>
              <Dropdown.Toggle split variant='default-falcon' size='sm'>
                <FontAwesomeIcon icon='ellipsis-h' className='fs--2' />
              </Dropdown.Toggle>

              <Dropdown.Menu className='border py-0'>
                <div className='py-2'>
                  {allow_view && (
                  <Dropdown.Item className='mb-1' onClick={() => handleViewContact(contact)}>
                    <FontAwesomeIcon icon='eye' /> View
                  </Dropdown.Item>
                  )}
                 
                 {allow_edit && (
                  <>
                    <Dropdown.Item className='mb-1' onClick={() => handleEditContact(contact)}>
                      <FontAwesomeIcon icon='edit' /> Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                 )}
                  
                  {allow_delete && (
                    <Dropdown.Item className='text-danger mb-1' onClick={() => handleDeleteContact(contact)}>
                    <FontAwesomeIcon icon='trash-alt' className='text-danger' /> Delete
                  </Dropdown.Item>
                  )}
                  
                </div>
              </Dropdown.Menu>
            </Dropdown>

          </div>
        )
      }
    }
  ], []);

  if (!contacts) {
    return null
  }

  const handleExportContacts = () => {
    ExportToExcel(contacts, 'contacts');
  }

  const handleViewContact = (contact) => {
    navigate(`${contactUrl}/${contact.id}`);
  }

  const handleEditContact = (contact) => {
    navigate(`/crm/customers/${contact.company?.id}/contacts/${contact.id}/edit`, { state: {customer, contact} })
  }

  const handleDeleteContact = (contact) => {
    setContact(contact);
    setShowConfirmationDelete(true);
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false);
  }

  const handleOkConfirm = () => {
    dispatch(deleteContactCustomer({customerId, contactId: contact.id}))
    setShowConfirmationDelete(false);
  }

  const BulAction = ({selectedRowIds}) => {
    let selectedContacts = [];
    if (selectedRowIds && Object.keys(selectedRowIds).length > 0) {
      selectedContacts = Object.keys(selectedRowIds).map(index => contacts[index]);
    }
  
   return (
    <div>
      <div className='fs--1 mb-1 text-nowrap py-2 py-xl-0'>
        {
          Object.keys(selectedRowIds).length > 0 ?
          'You have selected ' + Object.keys(selectedRowIds).length + ' rows' 
          : ''
        }
      </div>
    </div>
   )
  }

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={contacts}
        sortable
        pagination
        perPage={5}
        selection
        selectionColumnWidth={20}
      >
        <Row className='flex-end-center mb-3 g-1'>
          <Col xs='auto' sm={6} lg={4}>
            <AdvanceTableSearchBox table />
          </Col>
          <Col sm='auto'>
            <IconButton
              variant='falcon-default'
              size='sm'
              icon='external-link-alt'
              transform='shrink-3'
              title='Export'
              onClick={handleExportContacts}
            ></IconButton>
          </Col>
        </Row>

        <BulAction table />

        <AdvanceTable
          table
          headerClassName='bg-200 text-900 text-nowrap align-middle'
          rowClassName='align-middle white-space-nowrap'
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className='mt-3'> 
          <AdvanceTableFooter
            rowCount={contacts.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message='Are you sure to delete?'
      />
    </>
  );
};

ContactTable.propTypes = {
  customer: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired
}

export default ContactTable;