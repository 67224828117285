import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const projectUrl = "/crm/projects";

const generateRevisionUrl = (projectId, buildingId, revisionId) => (
  revisionId ? 
  `${projectUrl}/${projectId}/buildings/${buildingId}/revisions/${revisionId}` :
  `${projectUrl}/${projectId}/buildings/${buildingId}/revisions`
)

export const getProjectBuildings = createAsyncThunk(
  "Quotation/getProjectBuildings",
  async ({ projectId, params } ) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/buildings`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBuilding = createAsyncThunk(
  "Quotation/getBuilding",
  async ({projectId, buildingId}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/buildings/${buildingId}`)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createBuilding = createAsyncThunk(
  "Building/createBuilding",
  async ({projectId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/buildings`, data)
      return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateBuilding = createAsyncThunk(
  "Quotation/updateBuilding",
  async ({ projectId, buildingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${projectUrl}/${projectId}/buildings/${buildingId}`, data)
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteBuilding = createAsyncThunk(
  "Quotation/deleteBuilding",
  async ({projectId, buildingId}) => {
    try {
      const response = await apiAxios.delete(`${projectUrl}/${projectId}/buildings/${buildingId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getRevision = createAsyncThunk(
  "Quotation/getRevision",
  async ({projectId, buildingId, revisionId}, { rejectWithValue }) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.get(url)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createRevision = createAsyncThunk(
  "Quotation/createRevision",
  async ({projectId, buildingId, data}, { rejectWithValue }) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId)
      const response = await apiAxios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })

      return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteRevision = createAsyncThunk(
  "Quotation/deleteRevision",
  async ({projectId, buildingId, revisionId}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestEstimate = createAsyncThunk(
  "Quotation/requestEstimate",
  async ({projectId, buildingId, revisionId, params}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/request_estimate`, params);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const bypassRequestEstimate = createAsyncThunk(
  "Quotation/bypassRequestEstimate",
  async ({projectId, buildingId, revisionId}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/bypass_request_estimate`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleEstimate = createAsyncThunk(
  "Quotation/scheduleEstimate",
  async ({projectId, buildingId, revisionId, data}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/schedule_estimate`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseEstimate = createAsyncThunk(
  "Quotation/releaseEstimate",
  async ({projectId, buildingId, revisionId, data}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/release_estimate`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const updateReleaseEstimate = createAsyncThunk(
  "Quotation/updateReleaseEstimate",
  async ({projectId, buildingId, revisionId, data}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.put(`${url}/update_release_estimate`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const updateListOfChangeEstimate = createAsyncThunk(
  "Quotation/updateListOfChangeEstimate",
  async ({projectId, buildingId, revisionId, data}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.put(`${url}/update_list_of_changes`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestUpdatePIF = createAsyncThunk(
  "Quotation/requestUpdatePIF",
  async ({projectId, buildingId, revisionId, params}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/request_update_pif`, params);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const acceptUpdatePIF = createAsyncThunk(
  "Quotation/acceptUpdatePIF",
  async ({projectId, buildingId, revisionId}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/accept_update_pif`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const rejectUpdatePIF = createAsyncThunk(
  "Quotation/rejectUpdatePIF",
  async ({projectId, buildingId, revisionId, params}) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/reject_update_pif`, params);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadRequestESTDocument = createAsyncThunk(
  "Quotation/uploadRequestESTDocument",
  async ({ projectId, buildingId, revisionId, data }, { rejectWithValue }) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/upload_request_est_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const uploadReleaseESTDocument = createAsyncThunk(
  "Quotation/uploadReleaseESTDocument",
  async ({ projectId, buildingId, revisionId, data }, { rejectWithValue }) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/upload_release_est_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeRequestESTDocument = createAsyncThunk(
  "Quotation/removeRequestESTDocument",
  async ( { projectId, buildingId, revisionId, data }, { rejectWithValue }) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/remove_request_est_document`, data);

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeReleaseESTDocument = createAsyncThunk(
  "Quotation/removeReleaseESTDocument",
  async ( { projectId, buildingId, revisionId, data }, { rejectWithValue }) => {
    try {
      const url = generateRevisionUrl(projectId, buildingId, revisionId)
      const response = await apiAxios.post(`${url}/remove_release_est_document`, data);

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const requestEstimateList = createAsyncThunk(
  "Quotation/requestEstimateList",
  async (projectId) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/request_estimate_list` );
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleEstimateList = createAsyncThunk(
  "Quotation/scheduleEstimateList",
  async (projectId) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/schedule_estimate_list` );
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseEstimateList = createAsyncThunk(
  "Quotation/releaseEstimateList",
  async (projectId) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/release_estimate_list` );
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const changeStatusBuilding = createAsyncThunk(
  "Quotation/changeStatusBuilding",
  async ({projectId, buildingId, data}) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/buildings/${buildingId}/change_status`, data );
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayloadBuilding = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const record = data.attributes

    if (record !== null) {
      const building = state.data.find((item) => item.id === record.id)

      switch (type) {
        case "create": 
          state.data.unshift(record)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== building.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex((item) => item.id === building.id)

          if (updatedIndex !== -1) {
            state.data[updatedIndex] = record
          }
          break;
        }
      }
    }
    toast.success(message);
  }
};

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const revision = data.attributes

    if (revision !== null) {
      const building = state.data.find((item) => item.id === revision.building_id)
      if (building) {

        switch (type) {
          case "create": 
            building.revisions.unshift(revision)
            break
          case "delete":
            building.revisions = building.revisions.filter((item) => item.id !== revision.id)
            break;
          default:
          {
            const updatedIndex = building.revisions.findIndex((item) => item.id === revision.id)
            if (updatedIndex !== -1) {
              building.revisions[updatedIndex] = revision
            }

            break;
          }
        }
      }
    }
    toast.success(message);
  }
};

const quotationSlice = createSlice({
  name: "crm_project_quotation",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    buildingData: {}
  },
  reducers: {
    setCurrentPageQuotation: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSizeQuotation: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParamsQuotation: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParamsQuotation: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParamsQuotation: (state, action) => {
      state.sortParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuilding.fulfilled, (state, action) => {
        state.buildingData = action.payload
      })
      .addCase(getProjectBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getProjectBuildings.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate = state.paginate
        const calRecords         = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(createBuilding.fulfilled, (state, action) => handlePayloadBuilding(state, action, "create"))
      .addCase(updateBuilding.fulfilled, (state, action) => handlePayloadBuilding(state, action))
      .addCase(deleteBuilding.fulfilled, (state, action) => handlePayloadBuilding(state, action, "delete"))

      .addCase(getRevision.fulfilled, (state, action) => {
        state.adaptationData = action.payload
      })
      .addCase(createRevision.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteRevision.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(requestEstimate.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(scheduleEstimate.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(releaseEstimate.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateReleaseEstimate.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateListOfChangeEstimate.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(bypassRequestEstimate.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(requestUpdatePIF.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(acceptUpdatePIF.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(rejectUpdatePIF.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(uploadRequestESTDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeRequestESTDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(uploadReleaseESTDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeReleaseESTDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(changeStatusBuilding.fulfilled, (state, action) => handlePayloadBuilding(state, action))
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPageQuotation,
  setPageSizeQuotation,
  setCurrentParamsQuotation,
  resetCurrentParamsQuotation,
  setSortParamsQuotation
} = quotationSlice.actions;

export default quotationSlice.reducer;