import { Accordion, Collapse, Card, Row, Col, Form, Button, Table } from "react-bootstrap"
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
//import UploadDocument from "components/upload/UploadDocument";
import DatePicker from "react-datepicker";
import { BsBuildingAdd } from "react-icons/bs";
import Flex from "components/common/Flex";
import AddBuildingForm from "./AddBuildingForm";
import { useNavigate } from "react-router-dom";
import { createWaiver, updateWaiver } from "redux/crm/project/waiverSlice";
import { MdDelete, MdEdit } from "react-icons/md";
import PropTypes from "prop-types";

const WaiverForm = ({project, waiver, title}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues, setError} = useForm()

  const numberOfKeys = 8;
  const defaultActiveKey = Array.from({ length: numberOfKeys }, (_, index) => index.toString());
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const [showAddBuildingForm, setShowAddBuildingForm] = useState(false)
  const [waiverBuildings, setWaiverBuildings] = useState([])
  const [building, setBuilding] = useState(null)
  //const [files, setFiles] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    
    if (waiver) {
      Object.entries(waiver).forEach(([key, value]) => {
        switch(key) {
          case "released_for_order_material":
            setValue("released_for_order_material_check", value)
            break

          case "commitment_date":
            setValue(key, new Date(value))
            break

          case "buildings":
            setWaiverBuildings(value)
            break

          default:
            setValue(key, value)
            break
        }
      })
    }
  }, [waiver, setValue])

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const handleAddBuilding = () => {
    setBuilding(null)
    setShowAddBuildingForm(true)
  }

  const validateJobStatus = () => {
    if (!getValues("job_status_contract") 
      && !getValues("job_status_loi") 
      && !getValues("job_status_jaf") 
      && !getValues("job_status_bom_jaf_comparison")
      && !getValues("job_status_other"))
    {
      toast.error("Job status must be required.")
      return false
    }

    return true
  }

  const validateReleaseFor = () => {
    if (!getValues("released_for_anchor_bolt") 
      && !getValues("released_for_fabrication") 
      && !getValues("released_for_loading") 
      && !getValues("released_for_shipment")
      && !getValues("released_for_other")
      && !getValues("released_for_order_material"))
    {
      toast.error("Release for must be required.")
      return false
    }

    if (getValues("released_for_order_material_check") && !getValues("released_for_order_material")) {
      setError("released_for_order_material", { message: "Released for order material must be specify" })
      return false
    }

    if (getValues("released_for_other") && !getValues("released_for_other_specify")) {
      setError("released_for_other_specify", { message: "Released for other must be specify" })
      return false
    }

    return true
  }

  const validateWaiverValue = () => {
    if (!getValues("released_for_fabrication") 
      && !getValues("released_for_loading") 
      && !getValues("released_for_shipment"))
    {
      setError("waiver_value", { message: "Waiver value is required due to missing release for fabrication, loading, or shipment." })
      return false
    }

    return true
  }

  const onSubmit = async (data) => {
    validateJobStatus()
    validateReleaseFor()
    validateWaiverValue()

    console.log(data)
    data = {...data, buildings: waiverBuildings}

    try {
      const projectId = project.id
      let waiverId = null

      if (waiver) {
        waiverId = waiver.id
        dispatch(updateWaiver({projectId, waiverId, data }))
      }
      else {
        const response = await dispatch(createWaiver({projectId, data}))
        waiverId = response.payload.data.id
      }

      if (waiverId) {
        navigate(`/crm/projects/${projectId}/waivers/${waiverId}`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const handleEditBuilding = (building) => {
    setBuilding(building)
    setShowAddBuildingForm(true)
  }

  const handleDeleteBuilding = (index) => {
    const newWaiverBuildings = waiverBuildings.filter((_, i) => i !== index)
    setWaiverBuildings(newWaiverBuildings)

    toast.success("Building was successful remove.")
  }

  const validateNumber = () => ({
    pattern: {
      value: /^[0-9]+(\.[0-9]{1,2})?$/,
      message: "Please enter a valid number with up to 2 decimal places"
    }
  });

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Body className="fs--1 justify-content-center">
          <h5 className="text-center fs-3 fw-semi-bold">{title} WAIVER FORM</h5>
        </Card.Body>
      </Card>

      <Card className="p-4">
        <Collapse in={true}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Accordion
              id="revision-table"
              className="border rounded overflow-hidden fs--1"
              alwaysOpen
              flush
              activeKey={activeKey}
              onSelect={handleAccordionSelect}
            >
              <Card className="rounded-0">
                {/* 1. Project Information */}
                <Accordion.Item eventKey="0" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          1. Project Information
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-3">
                          <Col>
                            <Row className="mb-3">
                              <Col sm={4}>Project name</Col>
                              <Col className="text-primary">{project?.name}</Col>
                            </Row>
                            <Row className="mb-3">
                              <Col sm={4}>Quotation / Job No</Col>
                              <Col className="text-primary">{project?.q_number}</Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="mb-3">
                              <Col sm={4}>Customer</Col>
                              <Col className="text-primary">{project?.customer.name}</Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="gx-2">
                          <Col>
                            <Row className="gx-2 mb-2 align-items-center">
                              <Col>Main contract value</Col>
                              <Col sm={9} md={6}>
                                <Form.Control
                                  name="main_contract_value"
                                  placeholder="Main contract value"
                                  className="fs--1"
                                  {...register("main_contract_value", {
                                    required: "Main contract value must be required.",
                                    ...validateNumber()
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.main_contract_value && errors.main_contract_value.message}
                                </span>
                              </Col>
                            </Row>
                            <Row className="gx-2 align-items-center">
                              <Col>Main contract weight</Col>
                              <Col sm={9} md={6}>
                                <Form.Control
                                  name="main_contract_weight"
                                  placeholder="Main contract weight"
                                  className="fs--1"
                                  {...register("main_contract_weight", {
                                    required: "Main contract weight must be required.",
                                    ...validateNumber()
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.main_contract_weight && errors.main_contract_weight.message}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="gx-2 mb-2 align-items-center">
                              <Col>1st Addendum value</Col>
                              <Col sm={9} md={6}>
                                <Form.Control
                                  name="addendum_value_1st"
                                  placeholder="1st Addendum value"
                                  className="fs--1"
                                  {...register("addendum_value_1st", {
                                    ...validateNumber()
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.addendum_value_1st && errors.addendum_value_1st.message}
                                </span>
                              </Col>
                            </Row>
                            <Row className="gx-2 mb-2 align-items-center">
                              <Col>1st Addendum weight</Col>
                              <Col sm={9} md={6}>
                                <Form.Control
                                  name="addendum_weight_1st"
                                  placeholder="1st Addendum weight"
                                  className="fs--1"
                                  {...register("addendum_weight_1st", {
                                    ...validateNumber()
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.addendum_weight_1st && errors.addendum_weight_1st.message}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="gx-2 mb-2 align-items-center">
                              <Col>2st Addendum value</Col>
                              <Col sm={9} md={6}>
                                <Form.Control
                                  name="addendum_value_2st"
                                  placeholder="2st Addendum value"
                                  className="fs--1"
                                  {...register("addendum_value_2st", {...validateNumber()})}
                                />
                                <span className="text-danger">
                                  {errors.addendum_value_2st && errors.addendum_value_2st.message}
                                </span>
                              </Col>
                            </Row>
                            <Row className="gx-2 mb-2 align-items-center">
                              <Col>2st Addendum weight</Col>
                              <Col sm={9} md={6}>
                                <Form.Control
                                  name="addendum_weight_2st"
                                  placeholder="2st Addendum weight"
                                  className="fs--1"
                                  {...register("addendum_weight_2st", {...validateNumber()})}
                                />
                                <span className="text-danger">
                                  {errors.addendum_weight_2st && errors.addendum_weight_2st.message}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 2. Payment history (for repeat customer) */}
                <Accordion.Item eventKey="1" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          2. Payment history (for repeat customer)
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="gx-2">
                          <Col>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              name="payment_history"
                              placeholder="Payment history"
                              className="fs--1"
                              {...register("payment_history")}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 3. Job Status */}
                <Accordion.Item eventKey="2" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          3. Job Status
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-2">
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="job_status_contract">
                              <Form.Label column sm={7} className="text-lg-start">
                                Contract
                              </Form.Label>
                              <Col sm={5}>
                                <Controller
                                  control={control}
                                  name="job_status_contract"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2" controlId="job_status_loi">
                              <Form.Label column sm={7} className="text-lg-start">
                                LOI
                              </Form.Label>
                              <Col sm={5}>
                                <Controller
                                  control={control}
                                  name="job_status_loi"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2" controlId="job_status_jaf">
                              <Form.Label column sm={7} className="text-lg-start">
                                JAF
                              </Form.Label>
                              <Col sm={5}>
                                <Controller
                                  control={control}
                                  name="job_status_jaf"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2" controlId="job_status_bom_jaf_comparison">
                              <Form.Label column sm={7} className="text-lg-start">
                                BOM & JAF comparison ( For releasing shipment waiver)
                              </Form.Label>
                              <Col sm={5}>
                                <Controller
                                  control={control}
                                  name="job_status_bom_jaf_comparison"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="mb-2">Others</div>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              name="job_status_other"
                              placeholder="Others"
                              className="fs--1"
                              {...register("job_status_other")}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 4. Payment term */}
                <Accordion.Item eventKey="3" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          4. Payment term
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Form.Group as={Row} className="mb-2" controlId="payment_term_dp">
                          <Form.Label column sm={5} className="text-lg-start">
                            1. Down payment
                          </Form.Label>
                          <Col sm={7}>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                name="payment_term_dp"
                                placeholder="Down payment"
                                className="fs--1"
                                {...register("payment_term_dp")}
                              /> 
                              <span className="ms-1">%</span>
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="payment_term_second">
                          <Form.Label column sm={5} className="text-lg-start">
                            2. Second payment after delivery of M/F
                          </Form.Label>
                          <Col sm={7}>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                name="payment_term_second"
                                placeholder="Second payment after delivery of M/F"
                                className="fs--1"
                                {...register("payment_term_second")}
                              /> 
                              <span className="ms-1">%</span>
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="payment_term_third">
                          <Form.Label column sm={5} className="text-lg-start">
                            3. Third payment after deilivery of sheeting
                          </Form.Label>
                          <Col sm={7}>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                name="payment_term_third"
                                placeholder="Third payment after deilivery of sheeting"
                                className="fs--1"
                                {...register("payment_term_third")}
                              /> 
                              <span className="ms-1">%</span>
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="payment_term_four">
                          <Form.Label column sm={5} className="text-lg-start">
                            4. Four payment after completion of erection
                          </Form.Label>
                          <Col sm={7}>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                name="payment_term_four"
                                placeholder="Four payment after completion of erection"
                                className="fs--1"
                                {...register("payment_term_four")}
                              /> 
                              <span className="ms-1">%</span>
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="payment_term_final">
                          <Form.Label column sm={5} className="text-lg-start">
                            5. Final payment
                          </Form.Label>
                          <Col sm={7}>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                name="payment_term_final"
                                placeholder="Final payment"
                                className="fs--1"
                                {...register("payment_term_final")}
                              /> 
                              <span className="ms-1">%</span>
                            </div>
                          </Col>
                        </Form.Group>
                        
                        <Row className="mt-4 gx-2">
                          <Col>
                            <div className="mb-2">Others</div>  
                            <Form.Control
                              as="textarea"
                              rows={5}
                              name="payment_term_other"
                              placeholder="Others"
                              className="fs--1"
                              {...register("payment_term_other")}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 5. Release for */}
                <Accordion.Item eventKey="4" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          5. Release for
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row>
                          <Col>
                            <div className="mb-2">
                              <Controller
                                control={control}
                                name="released_for_anchor_bolt"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    checked={field.value}
                                    label="Anchor bolt delivery"
                                    onChange={(e) => field.onChange(e)}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-2">
                              <Controller
                                control={control}
                                name="released_for_fabrication"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    checked={field.value}
                                    label="Fabrication"
                                    onChange={(e) => field.onChange(e)}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-2">
                              <Controller
                                control={control}
                                name="released_for_shipment"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    checked={field.value}
                                    label="Shipment"
                                    onChange={(e) => field.onChange(e)}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-2">
                              <Controller
                                control={control}
                                name="released_for_loading"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    checked={field.value}
                                    label="Loading"
                                    onChange={(e) => field.onChange(e)}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-2">
                              <Controller
                                control={control}
                                name="released_for_order_material_check"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    checked={field.value}
                                    label="Order material"
                                    onChange={(e) => field.onChange(e)}
                                  />
                                )}
                              />

                              <Form.Control
                                as="textarea"
                                name="released_for_order_material"
                                placeholder="Released for order material specify"
                                className="fs--1"
                                {...register("released_for_order_material")}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setValue("released_for_order_material_check", value !== "" && value !== null)
                                }}
                              />
                              <span className="text-danger">
                                {errors.released_for_order_material && errors.released_for_order_material.message}
                              </span> 
                            </div>
                          </Col>
                          <Col>
                            <Controller
                              control={control}
                              name="released_for_other"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Others (specify)"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />

                            <Form.Control
                              as="textarea"
                              rows={10}
                              name="released_for_other_specify"
                              placeholder="Released for other specify"
                              className="fs--1"
                              {...register("released_for_other_specify")}
                              onChange={(e) => {
                                const value = e.target.value;
                                setValue("released_for_other", value !== "" && value !== null)
                              }}
                            />
                            <span className="text-danger">
                              {errors.released_for_other_specify && errors.released_for_other_specify.message}
                            </span>  
                          </Col>

                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 6. Reason for waiver */}
                <Accordion.Item eventKey="5" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          6. Reason for waiver
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Form.Group as={Row} className="mb-2" controlId="waiver_value">
                          <Form.Label column sm={5} className="text-lg-start">
                            Waiver value (USD)
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              name="waiver_value"
                              placeholder="Waiver value"
                              className="fs--1"
                              {...register("waiver_value", {...validateNumber()})}
                            />
                            <span className="text-danger">
                              {errors.waiver_value && errors.waiver_value.message}
                            </span>  
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="waiver_weight">
                          <Form.Label column sm={5} className="text-lg-start">
                            Waiver weight (M.T)
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              name="waiver_weight"
                              placeholder="Waiver weight"
                              className="fs--1"
                              {...register("waiver_weight", {...validateNumber()})}
                            />
                            <span className="text-danger">
                              {errors.waiver_weight && errors.waiver_weight.message}
                            </span>
                          </Col>
                        </Form.Group>

                        <div className="mt-3 mb-1">List out the reasons for the building</div>
                        <Table bordered striped hover responsive>
                          <thead>
                            <tr>
                              <th className="border" width="5%"></th>
                              <th className="border" width="5%">No.</th>
                              <th className="border" width="10%">Building</th>
                              <th className="border" width="80%">Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {waiverBuildings && waiverBuildings.map((building, index) => (
                              <tr key={index}>
                                <td className="border">
                                  <MdEdit className="cursor-pointer me-2" onClick={() => handleEditBuilding(building)} />
                                  <MdDelete className="cursor-pointer" onClick={() => handleDeleteBuilding(index)} />
                                </td>
                                <td className="border">{index + 1}</td>
                                <td className="border">{building.name}</td>
                                <td className="border">
                                  <span dangerouslySetInnerHTML={{ __html: building.reason}}></span> 
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
  
                        <Flex
                          justifyContent="end"
                          alignItems="center"
                          className="text-primary cursor-pointer fs-0"
                          onClick={handleAddBuilding}
                        >
                          <span className="circle-dashed">
                            <BsBuildingAdd />
                          </span>
                          <span className="ms-3 fs--1">Add Building</span>
                        </Flex>

                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 7. Commitment date to clear waiver */}
                <Accordion.Item eventKey="6" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          7. Commitment date to clear waiver
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Form.Group as={Row} className="mb-2" controlId="commitment_date">
                          <Form.Label column sm={5} className="text-lg-start">
                            Commitment date
                          </Form.Label>
                          <Col>
                            <Controller
                              control={control}
                              name="commitment_date"
                              rules={{required: "Commitment date must be required"}}
                              render={({ field }) => (
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  className="form-control fs--1"
                                  placeholderText="dd-MMM-YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  fixedHeight
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors.commitment_date && errors.commitment_date.message}
                            </span>  
                          </Col>
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 8. Attachment */}
                {/* <Accordion.Item eventKey="7" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          8. Attachment
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <UploadDocument files={files} setFiles={setFiles}/>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item> */}

              </Card>
            </Accordion>

            <div className="bg-light fixed-bottom border-0 text-center p-3">
              <Button size="sm" type="submit" className="me-2">{waiver ? "Update" : "Create"} Waiver</Button>
              <Button size="sm" variant="danger" onClick={() => navigate(-1)}>Cancel</Button>
            </div>
          </Form>
        </Collapse>
      </Card>

      {showAddBuildingForm && (
        <AddBuildingForm
          showModal={showAddBuildingForm}
          setShowModal={setShowAddBuildingForm}
          project={project}
          setWaiverBuildings={setWaiverBuildings}
          building={building}
        />
      )}
    </>
  )
};

WaiverForm.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    q_number: PropTypes.string,
    customer: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  waiver: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  title: PropTypes.string.isRequired
};

export default WaiverForm;