import React from 'react';
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog
} from "redux/crm/project/waiverSlice"
import ActivityHistory from "components/peb-grid/ActivityHistory"

const WaiverActivity = () => {
  const {activityLogData} = useSelector(state => state.crm_waiver);

  const params = useParams();
  const {project_id: projectId, id: waiverId} = params;

  return (
    <ActivityHistory
      activityLogData={activityLogData}
      setNestedPage={setNestedPage}
      setNestedPageSize={setNestedPageSize}
      setNestedParams={setNestedParams}
      getActivityLog={getActivityLog}
      projectId={projectId}
      waiverId={waiverId}
    />
  )
}

export default WaiverActivity;