import {Card, Form, Col, Row, Button, Image } from 'react-bootstrap';
import { useForm } from "react-hook-form"
import { MdEdit } from "react-icons/md"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { updateSeting } from "redux/settingsSlice"
import PropTypes from 'prop-types';

const General = ({setting, setUpdateStatus}) => {
  const {register, handleSubmit, formState: {errors}, setValue} = useForm();

  const distpach = useDispatch()

  useEffect(() => {
    setUpdateStatus(true)

    if (setting) {
      Object.entries(setting).forEach(([key, value]) => {
        setValue(key, value)
      });
    }

  }, [setting, setValue])

  const onSubmit = (data) => {
    //console.log(data)

    data = {...data, logo: data.logo[0]}

    try {
      distpach(updateSeting(data))
      setUpdateStatus(false)
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <Card>
        <Card.Body className="p-4 mx-5 fs--1">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} className="mb-2" controlId="name">
              <Form.Label column sm={4} className="text-lg-end">
                Name
              </Form.Label>
              <Col>
                <Form.Control
                  name="name"
                  placeholder="Name"
                  className="fs--1"
                  {...register("name", {
                    required: "Name must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.name && errors.name.message}
                </span>  
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="description">
              <Form.Label column sm={4} className="text-lg-end">
                Description
              </Form.Label>
              <Col>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={6}
                  placeholder="Description"
                  className="fs--1"
                  {...register("description", {
                    required: "Description must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.description && errors.description.message}
                </span>  
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2 mt-4" controlId="logo">
              <Form.Label column sm={4} className="text-lg-end">
                Logo
              </Form.Label>
              <Col>
                <Row>
                  <Col>
                    <Form.Control
                      name="logo"
                      type="file"
                      placeholder="Logo"
                      className="fs--1 mb-3"
                      {...register("logo")}
                    />
                    {/* <span className="text-danger">
                      {errors.logo && errors.logo.message}
                    </span>  */}
                  </Col>
                  <Col>
                    {/* <Avatar
                      size="5xl"
                      src={setting?.logo_url}
                      title="Click here to change Avatar"
                    /> */}
                    
                    <Image src={setting?.logo_url} height='50' width='200' />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <div className="border-top text-center mt-5">
              <Button size="sm" type="submit" className="me-2 mt-3"><MdEdit /> Update</Button>
              {/* <Button size="sm" variant="danger" onClick={handleBack}>Cancel</Button> */}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
};

General.propTypes = {
  setting: PropTypes.object.isRequired,
  setUpdateStatus: PropTypes.func.isRequired
}

export default General;