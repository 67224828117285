import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { lazy, Suspense, useState } from 'react';
import { Badge, Card, Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MdCancel, MdEdit, MdNotificationsActive, MdOutlineLockReset, MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { FaClosedCaptioning, FaShareSquare } from 'react-icons/fa';
import { cancelWaiver, deleteWaiver, remindToSalesWaiver, resetWaiver, rollbackWaiver, sendToApproveWaiver } from 'redux/crm/project/waiverSlice';
import PropTypes from 'prop-types';
import { RiGitRepositoryCommitsLine } from "react-icons/ri";

const LazyWaiverClearWaiverForm = lazy(() => import("./WaiverClearWaiverForm"));
const LazyWaiverReSubmitToCSDForm = lazy(() => import("./WaiverReSubmitToCSDForm"));
const LazyCloneWaiverConfirm = lazy(() => import("./CloneWaiverConfirm"));
const LazyChangeCommitmentForm = lazy(() => import("./ChangeCommitmentForm"));

const WaiverDetailHeader = ({waiver}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showResubmitToCSDForm, setShowResubmitToCSDForm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState(null);
  const [showCommitmentForm, setShowCommitmentForm] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const project = waiver?.project;
  const permissions = waiver?.permissions;

  const {
    allow_edit,
    allow_delete,
    allow_send_to_approve,
    allow_resubmit_approval_to_chief_rep,
    allow_resubmit_to_csd,
    allow_clear,
    allow_send_reminder_to_sales,
    allow_rollback,
    allow_reset,
    allow_cancel,
    allow_print,
    allow_update_commitment
  } = permissions || {};
  
  const projectUrl = "/crm/projects";
  const projectId = project?.id;
  const waiverId = waiver?.id;

  const location = useLocation();
  const [showClearWaiverForm, setShowClearWaiverForm] = useState(false);
  const [showCloneForm, setShowCloneForm] = useState(false);

  const handlePrintWaiverForm = () => {
    navigate(`${projectUrl}/${projectId}/waivers/${waiverId}/print`, {state: {waiver}})
  }

  const handleEditWaiver = () => {
    navigate(`${projectUrl}/${projectId}/waivers/${waiverId}/edit`, {state: {waiver}})
  }

  const handleProject = () => {
    navigate(`${projectUrl}/${projectId}`)
  }

  const confirmMsg = (message) => {
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deleteWaiver({projectId, waiverId}))

      if(location && location.state && location.state.source_waiver) {
        navigate(`${projectUrl}/waivers`)
      }
      else {
        navigate(`${projectUrl}/${projectId}`)
      }
      
      closeConfirmModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleDeleteWaiver = () => {
    const message = `Are you sure to delete project ID#<strong class='text-danger'>${projectId}</strong>?`
    confirmMsg(<span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleRequestApproval = () => {
    dispatch(sendToApproveWaiver({projectId, waiverId}))
  }

  const handleResubmitApprovalToChiefRep = () => {
    dispatch(sendToApproveWaiver({projectId, waiverId})) //need to check
  }

  const handleResubmitToCSD = () => {
    setShowResubmitToCSDForm(true)
  }

  const handleClearWaiver = () => {
    setShowClearWaiverForm(true)
  }

  const handleSendReminderToSales = () => {
    dispatch(remindToSalesWaiver({projectId, waiverId}))
  }

  const handleRollback = () => {
    dispatch(rollbackWaiver({projectId, waiverId}))
  }

  const handleResetWaiver = () => {
    dispatch(resetWaiver({projectId, waiverId}))
  }

  const handleCancelWaiver = () => {
    dispatch(cancelWaiver({projectId, waiverId}))
  }

  const handleWaiverList = () => {
    navigate(`${projectUrl}/waivers`)
  }

  const handleCloneWaiver = () => {
    setShowCloneForm(true)
  }

  const handleChangeCommitment = () => {
    setShowCommitmentForm(true)
  }

  const status_name = waiver?.status?.name;
  const statusBadge = status_name === "Rejected" ? (
  <Badge bg="danger" pill>{status_name}</Badge>
  ) : (
    <Badge bg="primary" pill>{status_name}</Badge>
  )

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            {allow_print && (
              <IconButton
                variant="info"
                size="sm"
                icon="print"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                title="Waiver Form"
                onClick={handlePrintWaiverForm}
              >
              </IconButton>
            )}

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="clone"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              title="Clone"
              onClick={handleCloneWaiver}
            >
            </IconButton>
            
            {allow_edit && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="edit"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                title="Edit"
                onClick={handleEditWaiver}
              >
              </IconButton>
            )}
            
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="arrow-up"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              title="Back to Project"
              onClick={handleProject}
            >
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              title="Waiver List"
              onClick={handleWaiverList}
            >
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="primary" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_send_to_approve && (
                    <Dropdown.Item onClick={handleRequestApproval}>
                      <FaShareSquare /> Request approval
                    </Dropdown.Item>
                  )}
                  
                  {allow_resubmit_approval_to_chief_rep && (
                    <Dropdown.Item className="mb-2" onClick={handleResubmitApprovalToChiefRep}>
                      <FaShareSquare /> Resubmit approval to Chief Rep
                    </Dropdown.Item>
                  )}
                  
                  {allow_resubmit_to_csd && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleResubmitToCSD}>
                        <FaShareSquare /> Resubmit to CSD
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_clear && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleClearWaiver}>
                        <FaClosedCaptioning /> Clear waiver
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_send_reminder_to_sales && (
                    <Dropdown.Item className="mb-2" onClick={handleSendReminderToSales}>
                      <MdNotificationsActive /> Send Reminder to Sales
                    </Dropdown.Item>
                  )}

                  {allow_update_commitment && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleChangeCommitment}>
                        <RiGitRepositoryCommitsLine /> Change Commitment
                      </Dropdown.Item>
                    </>
                  )}

                  {allow_rollback && (
                    <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-2" onClick={handleRollback}>
                      <MdOutlineSettingsBackupRestore /> Rollback
                    </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_reset && (
                    <Dropdown.Item className="mb-2" onClick={handleResetWaiver}>
                      <MdOutlineLockReset /> Reset
                    </Dropdown.Item>
                  )}
                  
                  {allow_cancel && (
                    <Dropdown.Item className="text-danger mb-2" onClick={handleCancelWaiver}>
                      <MdCancel /> Cancel
                    </Dropdown.Item>
                  )}

                  {allow_edit && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleEditWaiver}>
                        <MdEdit /> Edit
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger" onClick={handleDeleteWaiver}>
                        <FontAwesomeIcon icon="trash-alt" className="text-danger" /> Delete Waiver
                      </Dropdown.Item>
                    </>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
        <Card.Body className="fs--1 justify-content-center">
          <h5 className="text-center fs-3 fw-semi-bold">WAIVER FORM</h5>
          <div className="text-center">No.: <span className="text-primary">{waiver?.waiver_number}</span></div>
          <div className="text-center">Status.: {statusBadge}</div>
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message={messageConfirm}
      />


      <Suspense fallback={<div>Loading...</div>}>
        {showClearWaiverForm && (
          <LazyWaiverClearWaiverForm
            showModal={showClearWaiverForm}
            setshowModal={setShowClearWaiverForm}
            waiver={waiver}
          />
        )}

        {showResubmitToCSDForm && (
          <LazyWaiverReSubmitToCSDForm
            showModal={showResubmitToCSDForm}
            setshowModal={setShowResubmitToCSDForm}
            waiver={waiver}
          />
        )}

        {showCloneForm && (
          <LazyCloneWaiverConfirm
            showModal={showCloneForm}
            setshowModal={setShowCloneForm}
            waiver={waiver}
          />
        )}

        {showCommitmentForm && (
          <LazyChangeCommitmentForm
            showModal={showCommitmentForm}
            setshowModal={setShowCommitmentForm}
            waiver={waiver}
          />
        )}
      </Suspense>
    </>
  );
};

WaiverDetailHeader.propTypes = {
  waiver: PropTypes.shape({
    id: PropTypes.string.isRequired,
    waiver_number: PropTypes.string.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string
    }),
    status: PropTypes.shape({
      name: PropTypes.string
    }),
    permissions: PropTypes.shape({
      allow_edit: PropTypes.bool,
      allow_delete: PropTypes.bool,
      allow_send_to_approve: PropTypes.bool,
      allow_resubmit_approval_to_chief_rep: PropTypes.bool,
      allow_resubmit_to_csd: PropTypes.bool,
      allow_clear: PropTypes.bool,
      allow_send_reminder_to_sales: PropTypes.bool,
      allow_rollback: PropTypes.bool,
      allow_reset: PropTypes.bool,
      allow_cancel: PropTypes.bool,
      allow_print: PropTypes.bool
    })
  }).isRequired
};

export default WaiverDetailHeader;