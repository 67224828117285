import { Row, Col, Form } from "react-bootstrap";
import BDSGeneralInformation from "./BDSGeneralInformation";
import Flex from "components/common/Flex";
import TinymceEditor from "components/common/TinymceEditor";
import React, { useEffect } from "react";
import { CRM_PIF_BDS3_PURLIN_GIRT_SPACING } from "app_config";
import PropTypes from 'prop-types';

const BDS3 = ({data}) => {
  const {area} = data;
  const {control, register, Controller, setValue} = data.useHookForm;

  const {bds3} = area;

  useEffect(() => {
    setValue(`bds3_purlin_girt_spacing_${area.id}`, CRM_PIF_BDS3_PURLIN_GIRT_SPACING)
  }, [])

  useEffect(() => {
    if (bds3) {
      Object.entries(bds3).forEach(([key, value]) => {
        setValue(`bds3_${key}_${area.id}`, value)
      })
    }
  }, [bds3, setValue])

  return (
    <div className="fs--1 border p-3">
      <BDSGeneralInformation data={data} />
      <h6 className="text-center my-2">This area only : Page 3 of 3</h6>

      <Row className="mt-5 mb-3">
        <Col className="fw-semi-bold">1. Please design the most economical purlin, girt spacing.</Col>
        <Col sm={7}>
          <Form.Control
            name={`bds3_purlin_girt_spacing_${area.id}`}
            placeholder="Specify design the most economical purlin, girt spacing"
            className="fs--1"
            {...register(`bds3_purlin_girt_spacing_${area.id}`)}
          />
        </Col>
      </Row>

      <div className="mb-3">
        <Row>
          <Col className="fw-semi-bold">2. Deflection criterion:</Col>
          <Col sm={7}>
            <Flex justifyContent="between">
              <Controller
                control={control}
                name={`bds3_follow_building_code_${area.id}`}
                render={({ field }) => (
                  <Form.Check
                    type="checkbox"
                    label="Follow building code"
                    checked={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />

              <Controller
                control={control}
                name={`bds3_follow_peb_standard_${area.id}`}
                render={({ field }) => (
                  <Form.Check
                    type="checkbox"
                    label="Follow peb standard"
                    checked={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />

              <Controller
                control={control}
                name={`bds3_follow_requested_of_client_${area.id}`}
                render={({ field }) => (
                  <Form.Check
                    type="checkbox"
                    label="Follow requested of client"
                    checked={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col>Requested of Client:</Col>
          <Col sm={7}>
            <Form.Control
              as="textarea"
              rows={5}
              name={`bds3_follow_requested_of_client_specify_${area.id}`}
              placeholder="Requested of Client"
              className="fs--1"
              {...register(`bds3_follow_requested_of_client_specify_${area.id}`)}
            />
          </Col>
        </Row>
      </div>

      <Row className="mb-3">
        <Col className="fw-semi-bold">3. Environment conditions:</Col>
        <Col sm={8}>
          <Flex justifyContent="between">
            <Controller
              control={control}
              name={`bds3_fertilizer_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Fertilizer"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />

            <Controller
              control={control}
              name={`bds3_chemical_substance_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Chemical substance"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />

            <Controller
              control={control}
              name={`bds3_animal_feed_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Animal feed"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <Controller
              control={control}
              name={`bds3_animal_shed_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Animal shed"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            
            <Controller
              control={control}
              name={`bds3_from_the_sea_100m_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="From the sea 100m"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            
          </Flex>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="fw-semi-bold">4. How many cranes can operate at the same time in the same bay?</Col>
        <Col sm={7}>
          <Flex justifyContent="between">
            <Controller
              control={control}
              name={`bds3_crane_operate_2_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="2"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />

            <Controller
              control={control}
              name={`bds3_crane_operate_3_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="3"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />

            <Controller
              control={control}
              name={`bds3_crane_operate_4_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="4"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <Controller
              control={control}
              name={`bds3_crane_operate_not_apply_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Not Apply"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Flex>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="fw-semi-bold">5. Is Internal X-Bracing allowed?</Col>
        <Col sm={7}>
          <Flex justifyContent="between">
            <Controller
              control={control}
              name={`bds3_internal_xbracing_yes_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />

            <Controller
              control={control}
              name={`bds3_internal_xbracing_no_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="No"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />

            <Controller
              control={control}
              name={`bds3_internal_xbracing_not_apply_${area.id}`}
              render={({ field }) => (
                <Form.Check
                  type="checkbox"
                  label="Not Apply"
                  checked={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Flex>
        </Col>
      </Row>
      
      <Row className="mb-3">
        <Col>
          <div className="fw-semi-bold">6. The Top Coat Paint shall be Flat or Matte Finish ( Very low on Reflectivity)</div>
          <div className="mt-3">
            <Controller
              control={control}
              name={`bds3_top_coat_paint_${area.id}`}
              render={({ field }) => (
                <TinymceEditor
                  handleChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
};

BDS3.propTypes = {
  data: PropTypes.object.isRequired
}

export default BDS3;