import React from 'react';
import { Card, Row, Col, Form, Badge } from "react-bootstrap";
import Flex from "components/common/Flex";
import { formatBookingDate } from "helpers/utils";
import { Link } from "react-router-dom";
import { FaPlaneDeparture } from "react-icons/fa";
import PropTypes from 'prop-types';

const TransactionDetailBookingInfo = ({transaction, bgStatusFormat}) => {
  const statusId = transaction.status_id

  return (
    <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Booking information</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-5">
            <Col lg xxl={6}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Information
              </h6>
              <Row className="mb-1">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Booking number</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary fw-bold">{transaction.booking_number}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">From date</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{formatBookingDate(transaction.from_date)}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">To date</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{formatBookingDate(transaction.to_date)}</p>
                </Col>
              </Row>
              <Row  className="mb-2">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Address pickup location</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{transaction.pickup_location}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Address drop location</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{transaction.drop_location}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Purpose trip</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary" style={{whiteSpace: "pre-line"}}>{transaction.description}</p>
                </Col>
              </Row>
              <Row className="my-2">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Status</p>
                </Col>
                <Col>
                  <Badge bg={bgStatusFormat(statusId)} className="fs--2">{transaction.status_name}</Badge>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Route</p>
                </Col>
                <Col>
                  <Flex>
                    {['One way', 'Round trip'].map((item) => (
                      <Form.Check 
                        type="radio"
                        name="route"
                        className="me-3"
                        label={item}
                        checked={item === transaction.route}
                      />
                    ))}
                  </Flex>
                </Col>
              </Row>
            </Col>
            <Col lg className="mt-4 mt-lg-0">
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Contact
              </h6>
              <Row className="mb-1">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Name of passengers</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">
                    {transaction.name_of_passengers && transaction.name_of_passengers.map(item => (
                      <Badge variant="primary" className="me-1">{item}</Badge>
                    ))}
                  </p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Number of passengers</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{transaction.number_of_passengers}</p>
                </Col>
              </Row>
              <Row  className="mb-2"> 
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Luggage</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">
                    {transaction.luggage === null ? "None" : transaction.luggage}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Phone contacts</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{transaction.phone_contacts}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Site</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{transaction.site_name}</p>
                </Col>
              </Row>

              {transaction?.travel_booking_id && (
                <Row className="mb-2 mt-4">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold mb-1 fs--1"><FaPlaneDeparture /> Source from Travel booking</p>
                  </Col>
                  <Col>
                    <p className="mb-1 text-primary fs--1">
                      <Link to={`/booking/travel/${transaction?.travel_booking_id}`} target="_blank">{transaction?.travel_booking_number}</Link>
                    </p>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg xxl={6}>
            </Col>
            <Col>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Created
              </h6>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Submited by</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{transaction.created_by}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Submited date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{transaction.created_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Updated date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{transaction.updated_at}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
    </Card>
  )
};

TransactionDetailBookingInfo.propTypes = {
  transaction: PropTypes.object.isRequired,
  bgStatusFormat: PropTypes.func.isRequired
}

export default TransactionDetailBookingInfo