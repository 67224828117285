import {Row, Col, InputGroup, Form, Button, Badge} from "react-bootstrap";
import { debounce } from 'helpers/debounce';
import { useDispatch } from 'react-redux';
import React, { useCallback, useState } from "react";
import Flex from "components/common/Flex";
import { FaDownload, FaFilter, FaSearch, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import PropTypes from "prop-types";

const GridFilter = ({
  entryType,
  entryData,
  loadApi,
  currentParams,
  entryKey,
  setNestedParams,
  resetNestedParams,
  setFilterTag,
  filterTags,
  exportApi,
  filterAttributes,
  FilterAdvanceComponent
}) => {
  const [newCurrentParams, setNewCurrentParams] = useState(currentParams);
  const [showFilterAdvance, setShowFilterAdvance] = useState(false);

  const dispatch = useDispatch();
  const key = entryKey;

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = value ? { [filterAttributes]: value } : {...newCurrentParams};
      setNewCurrentParams(newParams)
      dispatch(loadApi(newParams));
    }, 500),
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target;
    handleChangeDebounced(value);
  }

  const handleExport = () => {
    const newParams = {...newCurrentParams, export_status: entryType}
    dispatch(exportApi(newParams))
  }

  const handleFilterAdvance = () => {
    setShowFilterAdvance(true);
  }

  const handleRefresh = () => {
    dispatch(resetNestedParams({key: entryKey}));
  }

  const handleDeleteTag = (id) => {
    const newTags = filterTags.filter((tag) => tag.id !== id)
    dispatch(setFilterTag({ key, tags: newTags }))

    //update current params
    const newParams = {...currentParams};
    delete newParams[id];
    updateParams(newParams);
  };

  const updateParams = (newParams) => {
    dispatch(setNestedParams({key, params: newParams}));
  }

  return (
    <>
      <Row className="my-2 gx-2 flex-between-center">
        <Col sm="auto">
          <Flex justifyContent="start" alignItems="center" wrap="wrap">
            {filterTags ? (
              <span className="fs--1">
                {filterTags.map((item, index) => (
                  <Badge pill bg="secondary" className="me-1 p-2" key={index}>
                    {item.name}: {Array.isArray(item.value) ? `[${item.value.join(', ')}]` : item.value}
                    <span className="ms-2" style={{cursor: "pointer"}}>
                      <FaTrashAlt onClick={() => handleDeleteTag(item.id)} />
                    </span>
                  </Badge>
                ))}
              </span>
            ) : (
              <span className="text-primary">
                <Badge pill bg="secondary" className="me-1 p-2">No filter</Badge>
              </span>
            )}
          </Flex>
        </Col>
        <Col sm={4}>
          <Row className="gx-2 align-items-center">
            <Col>
              <InputGroup className="fs--1">
                <Form.Control size="sm" placeholder="Search..." onChange={handleChange} />
                <Button size="sm" type="submit" variant="falcon-default">
                  <FaSearch className="me-1" />
                </Button>
                <Button size="sm" title="Filter advance" variant="falcon-default" onClick={handleFilterAdvance}>
                  <FaFilter className="me-1" />
                </Button>
                <Button size="sm" variant="falcon-default" onClick={handleRefresh} title="Refresh">
                  <FaSyncAlt className="me-1" />
                </Button>
                <Button size="sm" variant="falcon-default" onClick={handleExport} title="Export to excel">
                  <FaDownload className="me-1" />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      {showFilterAdvance && (
        <FilterAdvanceComponent
          showModal={showFilterAdvance}
          setshowModal={setShowFilterAdvance}
          data={entryData}
          entryKey={key}
        />
      )}
    </>
  );
};

GridFilter.propTypes = {
  entryType: PropTypes.string.isRequired,
  entryData: PropTypes.object.isRequired,
  loadApi: PropTypes.func.isRequired,
  currentParams: PropTypes.object.isRequired,
  entryKey: PropTypes.string.isRequired,
  setNestedParams: PropTypes.func.isRequired,
  resetNestedParams: PropTypes.func.isRequired,
  setFilterTag: PropTypes.func.isRequired,
  filterTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]).isRequired
  })).isRequired,
  exportApi: PropTypes.func.isRequired,
  filterAttributes: PropTypes.string.isRequired,
  FilterAdvanceComponent: PropTypes.elementType.isRequired
};

export default GridFilter;