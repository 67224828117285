import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  createInitialState,
  handlePayload
} from 'helpers/reducerHelpers';

const leadUrl = "/crm/leads"

export const getAllLeads = createAsyncThunk(
  "Lead/getAllLeads",
  async (params) => {
    try {
      const response = await apiAxios.get(`${leadUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getLead = createAsyncThunk(
  "Lead/getLead",
  async (leadId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/${leadId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createLead = createAsyncThunk(
  "Lead/createLead",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(leadUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateLead = createAsyncThunk(
  "Lead/updateLead",
  async ({ leadId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${leadUrl}/${leadId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteLead = createAsyncThunk(
  "Lead/deleteLead",
  async (leadId) => {
    try {
      const response = await apiAxios.delete(`${leadUrl}/${leadId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportData = createAsyncThunk(
  "Lead/exportData",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${leadUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `leads-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const getActivityLog = createAsyncThunk(
  "Lead/getActivityLog",
  async ({ leadId, params } ) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/${leadId}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const transferLead = createAsyncThunk(
  "Lead/transferLead",
  async ({ leadId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${leadUrl}/${leadId}/transfer`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const acceptLead = createAsyncThunk(
  "Lead/acceptLead",
  async ({ leadId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${leadUrl}/${leadId}/accept`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const recordLead = createAsyncThunk(
  "Lead/recordLead",
  async (leadId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${leadUrl}/${leadId}/record`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const salesActionLead = createAsyncThunk(
  "Lead/salesActionLead",
  async ({leadId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${leadUrl}/${leadId}/sales_action`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const updateProjectLead = createAsyncThunk(
  "Lead/updateProjectLead",
  async ({ leadId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${leadUrl}/${leadId}/update_project`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const convertLead = createAsyncThunk(
  "Lead/convertLead",
  async ({ leadId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${leadUrl}/${leadId}/convert`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const notifyToSalesLead = createAsyncThunk(
  "Lead/notifyToSalesLead",
  async (leadId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${leadUrl}/${leadId}/notify_to_sales`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getDashboard = createAsyncThunk(
  "Lead/getDashboard",
  async (params) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/dashboard`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getMqlLeads = createAsyncThunk(
  "Lead/getMqlLeads",
  async (params) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/mql`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getSqlLeads = createAsyncThunk(
  "Lead/getSqlLeads",
  async (params) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/sql`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCreatedThisMonthLeads = createAsyncThunk(
  "Lead/getCreatedThisMonthLeads",
  async (params) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/created_this_month`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getOpportunityLeads = createAsyncThunk(
  "Lead/getOpportunityLeads",
  async (params) => {
    try {
      const response = await apiAxios.get(`${leadUrl}/opportunity`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const leadSlice = createSlice({
  name: "lead",
  initialState: {
    ...initialState,
    mqlData: createInitialState(),
    sqlData: createInitialState(),
    createdThisMonthData: createInitialState(),
    opportunityData: createInitialState(),
    dashboardData: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeads.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getMqlLeads.fulfilled, (state, action) => handleFulfilled(state, action, "mqlData"))
      .addCase(getSqlLeads.fulfilled, (state, action) => handleFulfilled(state, action, "sqlData"))
      .addCase(getCreatedThisMonthLeads.fulfilled, (state, action) => handleFulfilled(state, action, "createdThisMonthData"))
      .addCase(getOpportunityLeads.fulfilled, (state, action) => handleFulfilled(state, action, "opportunityData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))

      .addCase(getLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createLead.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(exportData.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportData.fulfilled, (state) => {
        state.exporting = false
        toast.success("Lead has been exported successfully.")
      })
      .addCase(transferLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(acceptLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(recordLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(salesActionLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateProjectLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(convertLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(notifyToSalesLead.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboardData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = leadSlice.actions;

export default leadSlice.reducer;