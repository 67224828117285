import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

const settingUrl = "/crm/settings";

export const getBuildingTypeLevel1 = createAsyncThunk(
  "Setting/getBuildingTypeLevel1",
  async () => {
    try {
      const response = await apiAxios.get(`${settingUrl}/building-type-level1`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBuildingTypeLevel2 = createAsyncThunk(
  "Setting/getBuildingTypeLevel2",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/building-type-level2`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getTypeOfCustomer = createAsyncThunk(
  "Setting/getTypeOfCustomer",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/type_of_companies`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getLeadSources = createAsyncThunk(
  "Setting/getLeadSources",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/lead_sources`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getContactModes = createAsyncThunk(
  "Setting/getContactModes",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/contact_modes`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getProjectStatuses = createAsyncThunk(
  "Setting/getProjectStatuses",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/project_statuses`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBuildingStatuses = createAsyncThunk(
  "Setting/getBuildingStatuses",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/building_statuses`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getApprovalStatuses = createAsyncThunk(
  "Setting/getApprovalStatuses",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/approval_statuses`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getLeadStatuses = createAsyncThunk(
  "Setting/getLeadStatuses",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/lead_statuses`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getIndustries = createAsyncThunk(
  "Setting/getIndustries",
  async (params) => {
    try {
      const response = await apiAxios.get(`${settingUrl}/industries`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const settingSlice = createSlice({
  name: "crm_setting",
  initialState: {
    buildingTyleLevel1Data: [],
    buildingTyleLevel2Data: [],
    typeOfCustomerData: [],
    leadSourceData: [],
    contactModeData: [],
    projectStatusData: [],
    buildingStatusData: [],
    approvalStatusData: [],
    industryData: [],
    leadStatusData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuildingTypeLevel1.fulfilled, (state, action) => {
        state.buildingTyleLevel1Data = action.payload
      })
      .addCase(getBuildingTypeLevel2.fulfilled, (state, action) => {
        state.buildingTyleLevel2Data = action.payload
      })
      .addCase(getTypeOfCustomer.fulfilled, (state, action) => {
        state.typeOfCustomerData = action.payload
      })
      .addCase(getLeadSources.fulfilled, (state, action) => {
        state.leadSourceData = action.payload
      })
      .addCase(getContactModes.fulfilled, (state, action) => {
        state.contactModeData = action.payload
      })
      .addCase(getProjectStatuses.fulfilled, (state, action) => {
        state.projectStatusData = action.payload
      })
      .addCase(getBuildingStatuses.fulfilled, (state, action) => {
        state.buildingStatusData = action.payload
      })
      .addCase(getApprovalStatuses.fulfilled, (state, action) => {
        state.approvalStatusData = action.payload
      })
      .addCase(getIndustries.fulfilled, (state, action) => {
        state.industryData = action.payload
      })
      .addCase(getLeadStatuses.fulfilled, (state, action) => {
        state.leadStatusData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

//export const {} = settingSlice.actions

export default settingSlice.reducer;