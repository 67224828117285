import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentPage, setPageSize, setCurrentParams, getTrashList } from "redux/drive/driveSlice"
import DriveHeader from "./DriveHeader";
import AgPagination from "components/ag-grid/AgPagination"
import {Card, InputGroup, Button, Form, Table, Breadcrumb } from "react-bootstrap"
import { FaHome, FaSearch} from "react-icons/fa"
import Flex from "components/common/Flex"
import DriveTrashItem from "./DriveTrashItem"
import DriveRestoreFromTrashConfirm from "./confirmation/DriveRestoreFromTrashConfirm";
import { Link} from "react-router-dom";
import "./table.scss";
import { debounce } from "helpers/debounce";
import DriveDeleteForeverConfirm from "./confirmation/DriveDeleteForeverConfirm";

const DiveTrashList = () => {
  const { 
    data,
    totalRows,
    paginate,
    currentParams
  } = useSelector((state) => state.drive)
  const { pageSize, totalPagesCount, fromRecord, toRecord } = paginate;
  
  const [asset, setAsset] = useState({});
  const [showRestoreFromTrash, setShowRestoreFromTrash] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const driveUrl = "/drives";

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentParams) {
      dispatch(getTrashList(currentParams))
    }
  }, [currentParams, dispatch]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setCurrentPage(page))
    dispatch(setPageSize(pageSize))

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize}
    dispatch(setCurrentParams(newCurrentParams))
    dispatch(getTrashList(newCurrentParams))
  })

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = { ...currentParams, name_cont: value };
      dispatch(setCurrentParams(newParams));
    }, 500),
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target
    handleChangeDebounced(value) //call the debound function
  }

  const handleRestore = (asset) => {
    setAsset(asset);
    setShowRestoreFromTrash(true);
  } 

  const handleDelete = (asset) => {
    setAsset(asset);
    setShowDelete(true);
  }

  return (
    <>
      <DriveHeader />
      <Card>  
        <Card.Header>
          <Flex justifyContent="between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={`${driveUrl}/list`}><FaHome /> My Drive</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div>
              <InputGroup>
                <Form.Control size="sm" placeholder="Search..." onChange={handleChange} />
                <Button size="sm" type="submit" className="me-1">
                  <FaSearch icon="search" className="me-1" />
                </Button>
              </InputGroup>
            </div>
          </Flex>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive scrollbar">
            <Table className="fs--1 mb-0">
              <thead className="bg-200 text-900 text-nowrap align-middle border-bottom">
                <th className="p-2 cursor-pointer">Name</th>
                <th className="p-2 cursor-pointer">By</th>
                <th className="p-2 cursor-pointer">Trash date</th>
                <th className="p-2"></th>
              </thead>
              <tbody className="fs--1">
                {data && data.length > 0 ? (
                  data.map(item => 
                    <DriveTrashItem
                      key={item.id}
                      item={item}
                      handleRestore={() => handleRestore(item)}
                      handleDelete={() => handleDelete(item)}
                  />)
                ) : (
                  <div className="py-4">No data found.</div>
                ) }
              </tbody>
            </Table>
          </div>
        </Card.Body>
        <Card.Footer>
          <AgPagination
            totalRows={totalRows}
            totalPagesCount={totalPagesCount}
            pageChange={handlePageChange}
            pageSize={pageSize}
            from={fromRecord}
            to={toRecord}
            itemsPages={["50", "100", "200", "500"]}
          />
        </Card.Footer>
      </Card>

      {showRestoreFromTrash && (
        <DriveRestoreFromTrashConfirm
          showModal={showRestoreFromTrash}
          setshowModal={setShowRestoreFromTrash}
          asset={asset}
        />
      )}

      {showDelete && (
        <DriveDeleteForeverConfirm
          showModal={showDelete}
          setshowModal={setShowDelete}
          asset={asset}
        />
      )}
    </>
  )
}

export default DiveTrashList