import { useLocation, useParams } from "react-router-dom"
import PIFHeader from "./PIFHeader"
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { getPIF } from "redux/crm/project/pifSlice";
import { Card, Nav, Tab } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import PIFActivities from "./PIFActivities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailGDS from "./detail/DetailGDS";
import DetailAreas from "./detail/DetailAreas";
import { CRM_PIF_ISO } from "app_config";
import { FaChevronCircleUp } from "react-icons/fa";

const PIFDetail = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  //const {pifData: pif} = useSelector(state => state.crm_project_pif);
  const [pif, setPif] = useState(null);
  const {project, building, adaptation} = pif || {};

  const searchParams = new URLSearchParams(location.search);
  const sourceType = searchParams.get('type');

  useEffect(() => {
    dispatch(getPIF({
      projectId: params.project_id,
      buildingId: params.building_id,
      adaptationId: params.adaptation_id,
      pifId: params.id
    }))
    .then((action) => {
      setPif(action.payload.data.attributes)
    });
  }, [params])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <PIFHeader
        pif={pif}
        source={sourceType}
      />

      <Card>
        <Tab.Container defaultActiveKey="PIF-GDS">
          <SimpleBar>
            <Card.Header className="p-0 border-bottom">
              <Nav className="nav-tabs border-0 flex-nowrap tab-pif-form">
                <Nav.Item>
                  <Nav.Link
                    eventKey="PIF-GDS"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FontAwesomeIcon
                      icon="list"
                      className="text-600 tab-icon"
                    />
                    <h6 className="text-600 mb-0 ms-1"> General Data Sheet (GDS)</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="PIF-Area"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FontAwesomeIcon
                      icon="calendar"
                      className="text-600 tab-icon"
                    />
                    <h6 className="text-600 mb-0 ms-1"> Areas</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="PIF-Activities"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FontAwesomeIcon
                      icon="history"
                      className="text-600 tab-icon"
                    />
                    <h6 className="text-600 mb-0 ms-1"> Activities</h6>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
          </SimpleBar>
          <Card.Body className="bg-light">
            <Tab.Content>
                <Tab.Pane eventKey="PIF-GDS">
                  <DetailGDS pif={pif} />
                </Tab.Pane>
                <Tab.Pane eventKey="PIF-Area">
                  <DetailAreas data={pif} />
                </Tab.Pane>
              <Tab.Pane eventKey="PIF-Activities">
                <PIFActivities />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>

        <div className="fs--1 m-2 text-end">{CRM_PIF_ISO}</div>
      </Card>

      <div 
        onClick={scrollToTop} 
        style={styles.backToTopButton}
        title="Back to top"
        size='sm'
      >
        <FaChevronCircleUp />
      </div>
    </>
  )
};

const styles = {
  backToTopButton: {
    position: 'fixed',
    bottom: '20px',
    right: '40px',
    cursor: 'pointer',
    fontSize: '26px',
     zIndex: '1000'
  }
};


export default PIFDetail;