import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const carUrl = "/booking/car/cars"

export const getAllCars = createAsyncThunk(
  "Car/getAllCars",
  async (params) => {
    try {
      const response = await apiAxios.get(carUrl, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCar = createAsyncThunk(
  "Car/getCar",
  async (carId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${carUrl}/${carId}`)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createCar = createAsyncThunk(
  "Car/createCar",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(carUrl, data)
      return response.data.attributes
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCar = createAsyncThunk(
  "Car/updateCar",
  async ({ carId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${carUrl}/${carId}`, data)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteCar = createAsyncThunk(
  "Car/deleteCar",
  async (car) => {
    try {
      await apiAxios.delete(`${carUrl}/${car.id}`);
      return car
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportCars = createAsyncThunk(
  "Car/exportCars",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${carUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'cars.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const deactivateCar = createAsyncThunk(
  "Car/deactivateCar",
  async (car, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${carUrl}/${car.id}/deactivate`);
      return response.data.attributes
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const carSlice = createSlice({
  name: "booking_setting_car",
  initialState: {
    data: [],
    totalRows: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: 100,
    fromRecord: 0,
    toRecord: 1,
    loading: false,
    exporting: false,
    filterValue: "",
    currentParams: {page: 1, per_page: 100},
    activeType: "active"
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = parseInt(action.payload)
    },
    setFilterValue: (state, action) => {
      state.filterValue = action.payload
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {}
      state.filterValue = ""
    },
    setActiveType: (state, action) => {
      state.activeType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCars.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllCars.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const calRecords      = calPaginatorRecord(state.currentPage, state.pageSize, state.totalRows)
        state.totalPagesCount = calRecords.totalPagesCount
        state.fromRecord      = calRecords.beginRecords
        state.toRecord        = calRecords.lastRecords
      })
      .addCase(getCar.fulfilled, (state, action) => {
        state.car = action.payload
      })
      .addCase(createCar.fulfilled, (state, action) => {
        const car = action.payload
        state.data.unshift(car)
        toast.success(`Car ${car.name} have been created successfully.`)
      })
      .addCase(updateCar.fulfilled, (state, action) => {
        const car = action.payload
        const updatedIndex = state.data.findIndex((item) => item.id === car.id)
        if (updatedIndex !== -1) {
          state.data[updatedIndex] = car
          toast.success(`Car ${car.name} have been updated successfully.`)
        }
      })
      .addCase(deleteCar.fulfilled, (state, action) => {
        const car = action.payload
        if (car !== undefined) {
          state.data = state.data.filter((item) => item.id !== car.id)
          toast.success(`Car ${car.name} have been deleted successfully.`)
        }
      })
      .addCase(exportCars.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportCars.fulfilled, (state) => {
        state.exporting = false
        toast.success("Car has been exported successfully.")
      })
      .addCase(deactivateCar.fulfilled, (state, action) => {
        const car = action.payload
        const updatedIndex = state.data.findIndex((item) => item.id === car.id)
        if (updatedIndex !== -1) {
          state.data[updatedIndex] = car
          toast.success(`Car ${car.name} have been ${car.active ? 'dectivated' : 'activated'} successfully.`)
        }
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setFilterValue,
  setActiveType
} = carSlice.actions

export default carSlice.reducer