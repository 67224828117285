import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip, Badge } from 'react-bootstrap';
import { formatNumberWord, rgbaColor } from 'helpers/utils';
import BasicECharts from 'components/common/BasicEChart';

import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getMonthName = (monthNumber) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber];
}

getMonthName.propTypes = {
  monthNumber: PropTypes.array.isRequired
}

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    formatter: function(params) {
      const date = new Date(params[0].axisValue);
      const formattedDate = `${date.getDate()}-${getMonthName(date.getMonth())}`;
      return formattedDate;
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'bar',
      data,
      smooth: true,
      lineStyle: {
        width: 2
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('success'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('success'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

getOptions.propTypes = {
  data: PropTypes.array.isRequired
}

const donutOptions = {
  tooltip: chartJsDefaultTooltip(),
  rotation: -90,
  circumference: '180',
  cutout: '80%',
  plugins: {
    legend: {
      display: false
    },
    tooltip: chartJsDefaultTooltip()
  }
};

const PCVSummary = ({pcvData={}}) => {
  const {current_period, previous_period, summary={}, data=[]} = pcvData;
  const became_job = summary.job;

  const sortedData = data.slice().sort((a, b) => {
    const dateA = new Date(a.closed_date);
    const dateB = new Date(b.closed_date);
    
    if (isNaN(dateA) || isNaN(dateB)) {
        console.error("Invalid contract date:", a.closed_date, b.closed_date);
        return 0;
    }
    
    return dateA - dateB;
  });

  const groupedData = sortedData.reduce((acc, item) => {
    const date = item.closed_date;
    //const projectId = item.project_id;
    const leadId = item.lead_id;
    
    acc[date] = acc[date] || { pcv_count: 0, pcv_count_from_lead: 0};

    acc[date].pcv_count++;

    if (leadId) {
      acc[date].pcv_count_from_lead++;
  }

    return acc;
  }, {});

  const chartDataPCVNumber = Object.entries(groupedData).map(([date, { pcv_count }]) => {
    return [date, pcv_count];
  });

  const donutData = {
    labels: ['Lead', 'PCV'],
    datasets: [
      {
        data: [current_period?.number_of_leads, current_period?.number_of_projects],
        backgroundColor: [getColor('primary'), getColor('gray-300')],
        borderColor: [getColor('primary'), getColor('gray-300')]
      }
    ]
  };

  const jobRatioPercent = current_period?.number_of_projects > 0 ? ((parseFloat(became_job?.number_of_projects) / parseFloat(current_period?.number_of_projects)) * 100)?.toFixed(0) : 0;

  return (
    <Card className="h-100 mb-3">
      <Card.Body className="pb-0">
        <Row>
          <Col md={4} sm={12} className="border-end">
            <h6 className="text-700">New PCVs</h6>
            <Flex justifyContent="between" className="my-3">
              <div>
                <span className="h3 text-primary">{formatNumberWord(current_period?.number_of_projects)}</span>
                <span className="text-500 fs--1 ms-1">
                  vs {formatNumberWord(previous_period?.number_of_projects)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataPCVNumber)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
          <Col md={4} sm={12} className="border-end">
            <h6 className="text-700">PCVs created from Lead</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-primary">{formatNumberWord(current_period?.number_of_leads)}</span>
                <span className="text-500 fs--1 ms-1">
                  / {formatNumberWord(current_period?.number_of_projects)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the total PCV in this period.
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div className="text-center">
                 <Doughnut
                  data={donutData}
                  options={donutOptions}
                  style={{ width: '7.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
          <Col md={4} sm={12}>
            <h6 className="text-700">Became Job</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-success">{formatNumberWord(became_job?.number_of_projects)}</span>
                <span className="text-500 fs--1 ms-1">
                  / {formatNumberWord(current_period?.number_of_projects)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the total PCV in this period.
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div className="text-end h5 text-success">
                {formatNumberWord(became_job?.weight)} M.T
              </div>
              <div className="text-end h5">
                <Badge pill>{jobRatioPercent}%</Badge>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

PCVSummary.propTypes = {
  pcvData: PropTypes.object.isRequired
}

export default PCVSummary;