import React, {useEffect, Suspense, lazy} from "react";
import RoleHeader from "./role/RoleHeader";
import { Spinner, Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from "redux/authorization/roleSlice";
import SimpleBar from 'simplebar-react';
import { FaList, FaUserCog, FaUserShield } from "react-icons/fa";

const AllList = lazy(() => import('./role/list/Summary'));
const RoleByUsersList = lazy(() => import('./role/list/RoleByUsers'));
const RoleByPermissionsList = lazy(() => import('./role/list/RoleByPermissions'));

const Role = () => {
  const dispatch = useDispatch()
  const { loading, currentPage, pageSize } = useSelector((state) => state.authorization_role)

  useEffect(() => {
    dispatch(getAllRoles({page: 1, per_page: pageSize}))
  }, [currentPage, pageSize, dispatch]);

  return (
    <>
      <RoleHeader />
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="role-list">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="role-list"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaList
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">List</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="role-by-user"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaUserShield
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Role by users</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="role-by-permission"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaUserCog
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Role by permissions</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="role-list">
                      <AllList /> 
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="role-by-user">
                      <RoleByUsersList />
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="role-by-permission">
                      <RoleByPermissionsList />
                    </Tab.Pane>
                  </Tab.Content>

                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Role