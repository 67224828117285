import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const notificationUrl = "/notifications"

export const getAllNotifications = createAsyncThunk(
  "Notification/getAllNotifications",
  async (params) => {
    try {
      const response = await apiAxios.get(`${notificationUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getGlobalNotifications = createAsyncThunk(
  "Notification/getGlobalNotifications",
  async () => {
    try {
      const response = await apiAxios.get(`${notificationUrl}/top_5_today_earlier`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const markAllReadNotifications = createAsyncThunk(
  "Notification/markAllReadNotifications",
  async () => {
    try {
      await apiAxios.post(`${notificationUrl}/mark_all_read`)
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const markReadNotification = createAsyncThunk(
  "Notification/markReadNotification",
  async (id) => {
    try {
      const response = await apiAxios.put(`${notificationUrl}/${id}/read`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const updateNotification = (state, type, notification) => {
  const updatedIndex = state[type].findIndex((item) => item.id === notification.id);
  if (updatedIndex !== -1) {
    state[type][updatedIndex] = notification;
    toast.success("Notification has been set to read successfully.");
  }
}

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    data: [],
    todayData: [],
    earlierData: [],
    totalRows: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: 20,
    fromRecord: 1,
    toRecord: 1,
    loading: false,
    readCount: 0,
    unReadCount: 0
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        //if (state.totalRows !== results.total_rows) {
          state.data = objectSerializer(results.rows)
          state.totalRows = results.total_rows
          state.readCount = results.read_count
          state.unReadCount = results.unread_count
  
          const calRecords      = calPaginatorRecord(state.currentPage, state.pageSize, state.totalRows)
          state.totalPagesCount = calRecords.totalPagesCount
          state.fromRecord      = calRecords.beginRecords
          state.toRecord        = calRecords.lastRecords
       // }
      })
      .addCase(getGlobalNotifications.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.todayData = objectSerializer(results.today)
        state.earlierData = objectSerializer(results.earlier)
        state.unReadCount = results.unread_count
      })
      .addCase(markReadNotification.fulfilled, (state, action) => {
        state.loading = false
        state.allowRefresh = true

        const {notification, unread_count} = action.payload
        state.unReadCount = unread_count
        
        updateNotification(state, 'data', notification);
        updateNotification(state, 'todayData', notification);
        updateNotification(state, 'earlierData', notification);
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
} = notificationSlice.actions

export default notificationSlice.reducer
