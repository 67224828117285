import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AppContext from "context/Context"
import React, { useContext, useRef, useState, useEffect, useMemo } from "react"
import { Card, Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import DropdownFilter from 'components/common/DropdownFilter'
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarEventModal from "./CalendarEventModal"
import { useDispatch } from "react-redux"
import { getCarCalendar } from "redux/booking/car/carBookingSlice"

const viewName = [
  'Month View',
  'Week View',
  'Day View',
  'List View',
  'Year View'
]

const bookingName = [
  'Booking view',
  'Driver View',
  'Car View'
]

const CarCalendar = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext)

  const calendarRef = useRef()
  const [calendarApi, setCalendarApi] = useState({})
  const [currentFilter, setCurrentFilter] = useState(viewName[0])
  const [currentFilterBooking, setCurrentFilterBooking] = useState(bookingName[0])
  const [title, setTitle] = useState('')
  const [isOpenEventModal, setIsOpenEventModal] = useState(false)
  const [modalEventContent, setModalEventContent] = useState({})
  const [initialEvents, setInitialEvents] = useState([])

  const dispatch = useDispatch()

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  }

  const handleEventClick = (info) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      setModalEventContent(info);
      setIsOpenEventModal(true);
    }
  }

  const handleFilter = filter => {
    setCurrentFilter(filter);

    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  }

  const handleFilterBooking = (filter) => {
    setCurrentFilterBooking(filter);
  }

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());

    if (calendarApi && calendarApi.view) {
      setTimeout(() => {
        const view = calendarApi.view
        const calendar_type = currentFilterBooking.replace(/ /g, '-').toLocaleLowerCase()

        const params = {
          calendar_type: calendar_type,
          from_date: view.activeStart,
          end_date: view.activeEnd
        }

        fetchDataCalendar(params)
      }, 100)
    }
  }, [calendarApi, title, currentFilterBooking])

  const fetchDataCalendar = async (params) => {
    const response = await dispatch(getCarCalendar(params))
    setInitialEvents(response.payload)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <DropdownFilter
                className="me-2"
                filters={bookingName}
                currentFilter={currentFilterBooking}
                handleFilter={handleFilterBooking}
                icon="sort"
                right
              />
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={2}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            //editable
            selectable
            selectMirror
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={initialEvents}
          />
        </Card.Body>
      </Card>

      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
      />
    </>
  )
}

export default CarCalendar