import { useLocation } from "react-router-dom";
import ChangeOrderForm from "./ChangeOrderForm";
import React from 'react';

const EditChangeOrder = () => {
  const location = useLocation()
  const locationState = location.state
  const {changeOrder} = locationState
  const {project, building} = changeOrder || {};

  return (
    <ChangeOrderForm
      project={project}
      building={building}
      changeOrder={changeOrder}
      title="Edit change order information sheet"
    />
  )
}

export default EditChangeOrder;