import React from 'react';
import DocumentViewer from "components/doc-viewer/DocumentViewer";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { FaDownload } from "react-icons/fa";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { useDispatch } from "react-redux";
import { downloadAsset } from "redux/drive/driveSlice";

const DriveDocViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const asset = location?.state?.asset || {};
  const {allow_download} = asset?.permissions || {};

  const handleDownload = () => {
    dispatch(downloadAsset({assetId: asset.id, fileName: asset.name}))
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          {allow_download && (
            <Flex justifyContent="end">
              <Button size="sm" variant="falcon-default" title="Download">
                <FaDownload onClick={handleDownload} />
              </Button>
          </Flex>
          )}
        </Card.Header>
      </Card>

      <Card className="h-100 v-100">
        <Card.Body className="m-3">
          <DocumentViewer document_url={location?.state?.url} />
        </Card.Body>
      </Card>
    </>
  );
};

export default DriveDocViewer;