import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Notification from 'components/notification/Notification';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllNotifications } from 'redux/notificationSlice';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import { FaHistory } from 'react-icons/fa';

const ActivityLog = () => {
  const {data} = useSelector(state => state.notification)
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllNotifications({page: 1, per_page: 10}))
  }, [])

  return (
    <Card className="mt-3" >
      <Card.Header className="bg-light">
        <h5 className="mb-1 mb-md-0"><FaHistory className="text-primary" /> Activities history</h5>
      </Card.Header>
      <Card.Body className="p-0">
        {data.map((activity, index) => (
          <Notification
            {...activity}
            key={activity.id}
            className={classNames(
              'border-x-0 border-bottom-0 border-300',
              index + 1 === data.length ? 'rounded-top-0' : 'rounded-0'
            )}
          />
        ))}
      </Card.Body>
      <FalconCardFooterLink
        title="All Logs"
        to="/notifications"
        size="sm"
      />
    </Card>
  );
};

export default ActivityLog;
