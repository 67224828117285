import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

const pifUrl = (projectId, buildingId, adaptationId, pifId) => {
  const projectUrl = `/crm/projects/${projectId}/buildings/${buildingId}/adaptations/${adaptationId}`
  return pifId ? `${projectUrl}/pifs/${pifId}` : `${projectUrl}/pifs`
}

const pifVersionUrl = (projectId, buildingId, changeOrderId, pifId) => {
  const projectUrl = `/crm/projects/${projectId}/buildings/${buildingId}/adaptations/${changeOrderId}`
  return pifId ? `${projectUrl}/pif_versions/${pifId}` : `${projectUrl}/pif_versions`
}

export const getPIF = createAsyncThunk(
  "PIF/getPIF",
  async ({projectId, buildingId, adaptationId, pifId}, { rejectWithValue }) => {
    try {
      const url = pifUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.get(url)
      
      return response.data
    }
    catch(error){
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({ message: error.message });
    }
  }
)

export const createPIF = createAsyncThunk(
  "PIF/createPIF",
  async ({projectId, buildingId, adaptationId, data}, { rejectWithValue }) => {
    try {
      const url = pifUrl(projectId, buildingId, adaptationId)
      const response = await apiAxios.post(url, {pif: data})
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updatePIF = createAsyncThunk(
  "PIF/updatePIF",
  async ({ projectId, buildingId, adaptationId, pifId, data }, { rejectWithValue }) => {
    try {
      const url = pifUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.put(url, {pif: data})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deletePIF = createAsyncThunk(
  "PIF/deletePIF",
  async ({projectId, buildingId, adaptationId, pifId}) => {
    try {
      const url = pifUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const downloadProjectProposal = createAsyncThunk(
  "PIF/downloadProjectProposal",
  async ({projectId, buildingId, adaptationId, pifId, proposalType}) => {
    const pifurl = pifUrl(projectId, buildingId, adaptationId, pifId)
    const response = await apiAxios.get(`${pifurl}/project_proposal?type=${proposalType}`, {responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Draft proposal contract ${proposalType}.docx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const downloadPIF = createAsyncThunk(
  "PIF/downloadPIF",
  async ({projectId, buildingId, adaptationId, pifId}) => {
    const pifurl = pifUrl(projectId, buildingId, adaptationId, pifId)
    const response = await apiAxios.get(`${pifurl}/print`, {responseType: 'blob'})
    
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'pif.pdf'; // Fallback filename
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+?)"/);
      if (match && match[1]) {
        filename = decodeURIComponent(match[1]);
      }
    }

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
)

export const getActivityLog = createAsyncThunk(
  "PIF/getActivityLog",
  async ({projectId, buildingId, adaptationId, pifId, params}) => {
    try {
      const pifurl = pifUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.get(`${pifurl}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBuildingsProjectCopyPIF = createAsyncThunk(
  "PIF/getBuildingsProjectCopyPIF",
  async ({projectId, params}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`/crm/projects/${projectId}/load_building_copy_pifs?type=${params}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const copyPIF = createAsyncThunk(
  "PIF/copyPIF",
  async ({ projectId, buildingId, adaptationId, data }, { rejectWithValue }) => {
    try {
      const url = pifUrl(projectId, buildingId, adaptationId)
      const response = await apiAxios.post(`${url}/copy_pif`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getPIFVersion = createAsyncThunk(
  "PIF/getPIFVersion",
  async ({projectId, buildingId, adaptationId, pifId}, { rejectWithValue }) => {
    try {
      const url = pifVersionUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.get(url)
      
      return response.data
    }
    catch(error){
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({ message: error.message });
    }
  }
)

export const updatePIFVersion = createAsyncThunk(
  "PIF/updatePIFVersion",
  async ({ projectId, buildingId, adaptationId, pifId, data }, { rejectWithValue }) => {
    try {
      const url = pifVersionUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.put(url, {pif: data})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deletePIFVersion = createAsyncThunk(
  "PIF/deletePIFVersion",
  async ({projectId, buildingId, adaptationId, pifId}) => {
    try {
      const url = pifVersionUrl(projectId, buildingId, adaptationId, pifId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="") => {
  const { error, data, message } = action.payload;
  
  if (error !== undefined) {
    toast.error(error);
  } else {
    
    if (type === "delete") {
      state.pifData = {}
    }
    else {
      state.pifData = data.attributes
    }

    toast.success(message);
  }
};

const pifSlice = createSlice({
  name: "crm_pif",
  initialState: {
    pifData: {},
    areaData: [{d: 1, name: '1'}],
    collapseTabStatus: false,
    initialKeys: {
      gds: [0],
      bds1: [0],
      bds2: [0]
    }
  },
  reducers: {
    addAreaData: (state, action) => {
      const area = action.payload

      const checkArea = state.areaData.find(item => item.id === area.id)
      if (checkArea) {
        toast.error(`Area ${area.id} was existing.`)
      }
      else
      {
        state.areaData.push(area)
        toast.success(`Area ${area.id} was created.`)
      }
    },
    editAreaData: (state, action) => {
      const area = action.payload

      const updateIndex = state.areaData.findIndex(item => item.id === area.id)

      if (updateIndex !== -1)
      {
        state.areaData[updateIndex] = area
        toast.success(`Area ${area.id} was updated.`)
      } 
    },
    removeAreaData: (state, action) => {
      const area = action.payload
      const newData = state.areaData.filter(item => item.id !== area.id)
      state.areaData = newData 
    },
    resetAreaData: (state) => {
      state.areaData = [{d: 1, name: '1'}]
    },
    setExpandAll: (state) => {
      return {
        ...state,
        initialKeys: {
          ...state.initialKeys,
          gds: Array.from({ length: 19 }, (_, index) => String(index + 1)),
          bds1: Array.from({ length: 12 }, (_, index) => String(index + 1)),
          bds2: Array.from({ length: 10 }, (_, index) => String(index + 1)),
        }
      }
    },
    resetExpandAll: (state) => {
      return {
        ...state,
        initialKeys: {
          ...state.initialKeys,
          gds: [],
          bds1: [],
          bds2: [],
        }
      };
    },
    setCollapseTabStatus: (state, action) => {
      state.collapseTabStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPIF.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createPIF.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updatePIF.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deletePIF.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(downloadProjectProposal.pending, (state) => {
        state.exporting = true
      })
      .addCase(downloadProjectProposal.fulfilled, (state) => {
        state.exporting = false
        toast.success("Draft proposal contract has been downloaded successfully.")
      })
      .addCase(getPIFVersion.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {setExpandAll, resetExpandAll, setCollapseTabStatus} = pifSlice.actions;

export default pifSlice.reducer;