import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { driverConfirm, getBooking } from 'redux/booking/car/carBookingSlice';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {format} from 'date-fns'

const TransactionDriverConfirm = () => {
  const {recordData: transaction} = useSelector((state) => state.booking_car);
  const [data, setData] = useState({type: "", message: ""});

  const params = useParams();
  const dispatch = useDispatch();

  const transactionId = params.id;
  const driverTranId = params.driver_tran_id;
  const {allow_update_departure_time} = transaction?.permissions || {};

  console.log(transaction)

  useEffect(() => {
    console.log("Driver confirmed!")
    handleDriverConfirm() 
  }, [])

  useEffect(() => {
    if (!transaction) {
      dispatch(getBooking(transactionId))
    }
  }, [transaction, transactionId, dispatch])

  const handleDriverConfirm = async () => {
    try {
      const response = await dispatch(driverConfirm({ transactionId, driverTranId }));
      const payload = response.payload

      if (payload.error !== undefined) {
        setData({type: "Error", message: payload.error})
      }
      else {
        const {data, message} = payload
        setData({type: "Success", message: message})
      }

    } catch (error) {
      console.error("Error in driver confirmation:", error);
    }
  }

  const isSuccess = () => {
    return data && data.type === "Success"
  }

  const textName = () => {
    return isSuccess() ? 'text-success' : 'text-warning'
  }

  const formatDate = (date) => {
    if (!date) return
    return format(new Date(date), "dd-MMM-yyyy h:mm aa")
  }

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        {/* <img src={rocket} className="img-fluid" alt="" width={100} height={100} /> */}

        <Flex justifyContent="center">
          <div className="text-center">
            <FontAwesomeIcon icon={`${isSuccess() ? 'check-circle' : 'exclamation-triangle'}`} className={`${textName()} fs-8 my-4`} />
         
            <h3 className={textName()}>{data.message}</h3>
            {isSuccess() && (
              <p>You have confirmed car booking.</p>
            )}

            <div className="my-3 border rounded p-3">
              <p className="text-start text-primary">
                <FontAwesomeIcon icon="info-circle" /> Booking information:
              </p>
              <Row className="mb-1">
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold fs--1 mb-1 text-end">Departure date:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 fs--1 text-primary text-start">{formatDate(transaction.from_date)}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold fs--1 mb-1 text-end">End date:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 fs--1 text-primary text-start">{formatDate(transaction.to_date)}</p>
                </Col>
              </Row>
              <Row  className="mb-1">
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold fs--1 mb-1 text-end">Address pickup location:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 fs--1 text-primary text-start">{transaction.pickup_location}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold fs--1 mb-1 text-end">Address drop location:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 fs--1 text-primary text-start">{transaction.drop_location}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold mb-1 fs--1 text-end">Name of passengers:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 text-primary fs--1 text-start">
                    {transaction.name_of_passengers && transaction.name_of_passengers.map(item => (
                      <Badge variant="primary" className="me-1" key={item.id}>{item}</Badge>
                    ))}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold fs--1 mb-1 text-end">Route:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 fs--1 text-primary text-start">{transaction.route}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                  <p className="fw-semi-bold mb-1 fs--1 text-end">Site office:</p>
                </Col>
                <Col className="d-flex justify-content-sm-start">
                  <p className="mb-1 text-primary fs--1 text-start">{transaction.site_name}</p>
                </Col>
              </Row>
            </div>

            {allow_update_departure_time && (
              <Button
                variant="falcon-primary"
                className="mt-3"
                as={Link}
                to={`/booking/car/${transactionId}/driver-trans/${driverTranId}/update`}
              >
                Departure start now!
              </Button>
            )}
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default TransactionDriverConfirm;
