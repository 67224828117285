import React, { useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { useDispatch } from 'react-redux';
import { assignContact } from 'redux/crm/contactSlice';

import { FaBan, FaUser } from 'react-icons/fa';
import { salesEmployeesByNameDebounce } from 'helpers/employeeOptionHelper';
import PropTypes from 'prop-types';

const AssignContactModal = ({showModal, setshowModal, contactId}) => {
  const [formData, setFormData] = useState([]);
  const dispatch = useDispatch();

  const loadEmpOptions = (inputValue, callback) => {
    salesEmployeesByNameDebounce(inputValue, callback);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = [];
    formData && formData.forEach((item) => data.push(item.label));
 
    try {
      await dispatch(assignContact({contactId, formData: { name: data} }));
      setshowModal(false);
    }
    catch(error) {
      toast.error(error.message);
    }
  }

  const handleCancel = () => {
    setshowModal(false);
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static' keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className='bg-light border-0'>
            <Modal.Title id='contained-modal-title-vcenter'>Assigned contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className='text-primary'>Specify name to assign for this contact</h6>
            <Card className='fs--1'>
              <Card.Body>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={loadEmpOptions}
                  closeMenuOnSelect={false}
                  placeholder='Select...'
                  classNamePrefix='react-select'
                  className='w-100'
                  name='name'
                  isMulti
                  required
                  onChange={setFormData}
                  //components={{ Option: AvatarOption }}
                />

              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className='bg-light border-0'>
            <Button size='sm' type='submit'><FaUser /> Assign</Button>
            <Button size='sm' variant='danger' onClick={handleCancel}><FaBan /> Cancel</Button>
          </Modal.Footer>
          </Form>
      </Modal>
    </>
  ) ;
};

AssignContactModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  contactId: PropTypes.number.isRequired
}

export default AssignContactModal;