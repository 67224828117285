import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import CheckboxRenderer from "components/ag-grid/CheckboxRenderer";
import AgGridTable from "components/ag-grid/AgGridTable";
import AgPagination from "components/ag-grid/AgPagination";
import { useDispatch } from "react-redux";
import CarFilter from "./CarFilter";
import {
  getAllCars,
  setCurrentPage,
  setPageSize,
  setCurrentParams
} from "redux/booking/car/carSlice";
import PropTypes from 'prop-types';

const CarList = ({setCarSelected}) => {
  const { 
    data,
    totalRows,
    pageSize,
    totalPagesCount,
    activeType,
    fromRecord,
    toRecord,
    currentParams 
  } = useSelector((state) => state.booking_setting_car)
  const dispatch = useDispatch()

  const columnDefs = useMemo(() => [
    { 
      headerName: 'ID#',
      field: 'id',
      minWidth: 110,
      headerCheckboxSelection: true,
      checkboxSelection: true
    },
    { 
      headerName: 'Name',
      field: 'name',
      minWidth: 200
    },
    { 
      headerName: 'Model',
      field: 'model',
      minWidth: 200
    },
    { 
      headerName: 'Site',
      field: 'site_name',
      minWidth: 200
    },
    { 
      headerName: 'Active',
      field: 'active',
      minWidth: 100,
      cellRenderer: CheckboxRenderer
    },
    { 
      headerName: 'Note',
      field: 'remark',
      minWidth: 200
    },
    { 
      headerName: 'Created by',
      field: 'created_by',
      minWidth: 150
    },
    { 
      headerName: 'Created date',
      field: 'created_at',
      minWidth: 200
    },
    { 
      headerName: 'Updated date',
      field: 'updated_at',
      minWidth: 200
    },

  ], []);

  useEffect(() => {
    dispatch(getAllCars(currentParams))
  }, [])

  const handlePageChange = ((page, pageSize) => {
    dispatch(setCurrentPage(page))
    dispatch(setPageSize(pageSize))

    const newCurrentParams = {...currentParams, active: activeType, page: page, per_page: pageSize}
    console.log(newCurrentParams)
    dispatch(setCurrentParams(newCurrentParams))
    dispatch(getAllCars(newCurrentParams))
  })

  return (
    <>
      <CarFilter />
      <AgGridTable
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        refData={setCarSelected}
        selectedMultipe={false}
        serverSide={true}
      />
      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200", "500"]}
      />
    </>
  )
};

CarList.propTypes = {
  setCarSelected: PropTypes.func.isRequired
}

export default CarList;