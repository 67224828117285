import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
import { getFlatRoutes } from 'helpers/utils';
import NavbarNavLink from './NavbarNavLink';

const NavbarDropdownApp = ({ items }) => {
  const routes = getFlatRoutes(items);
  console.log("NavbarDropdownApp routes", routes);

  return (
    <Row>
      <Col>
        <Nav className="flex-column">
          {routes.unTitled && (
            <>
              {routes.unTitled.map(route => (
                <NavbarNavLink key={route.name} route={route} />
              ))}
            </>
          )}

          {routes.social && (
            <>
              <NavbarNavLink label="Social" title="Social" />
              {routes.social.map(route => (
                <NavbarNavLink key={route.name} route={route} />
              ))}
            </>
          )}

          {routes.supportDesk && (
            <>
              <NavbarNavLink label="Support Desk" title="Support Desk" />
              {routes.supportDesk.map(route => (
                <NavbarNavLink key={route.name} route={route} />
              ))}
            </>
          )}
        </Nav>
      </Col>
      <Col>

        {routes.eLearning && (
          <>
            <NavbarNavLink label="E Learning" title="E Learning" />
            {routes.eLearning.map(route => (
              <NavbarNavLink key={route.name} route={route} />
            ))}
          </>
        )}

        {routes.events && (
          <>
            <NavbarNavLink label="Events" title="Events" />
            {routes.events.map(route => (
              <NavbarNavLink key={route.name} route={route} />
            ))}
          </>
        )}

        {routes.email && (
          <>
            <NavbarNavLink label="Email" title="Email" />
            {routes.email.map(route => (
              <NavbarNavLink key={route.name} route={route} />
            ))}
          </>
        )}
      </Col>
      <Col>
        {routes.eCommerce && (
          <>
            <NavbarNavLink label="E Commerce" title="E Commerce" />
            {routes.eCommerce.map(route => (
              <NavbarNavLink key={route.name} route={route} />
            ))}
          </>
        )}
      </Col>
    </Row>
  );
};

NavbarDropdownApp.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      children: PropTypes.array
    }).isRequired
  ).isRequired
};

export default NavbarDropdownApp;
