
import React from 'react';
import IconButton from 'components/common/IconButton';
import { Card,  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TransactionHeader = () => {
  const navigate = useNavigate()

  const handleNewtransaction = () => {
    navigate("/booking/car/new-car-booking")
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />

          <IconButton
            variant="primary"
            size="sm"
            icon="car"
            transform="shrink-2"
            iconAlign="middle"
            className="me-1"
            onClick={handleNewtransaction}
          >
            <span className="d-none d-xl-inline-block ms-1">New Car booking</span>
          </IconButton>
        </Card.Header>
      </Card>
    </>
  );
};

export default TransactionHeader;
