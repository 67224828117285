import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { MdEmail, MdPhone, MdSettings } from 'react-icons/md';
import PropTypes from 'prop-types';

const ProfileSetting = ({user}) => {
  const {employee} = user;

  const [formData] = useState({
    firstName: employee?.first_name,
    lastName: employee?.last_name,
    email: user?.email,
    title: employee?.title,
    department: employee?.department,
    site: employee?.site
  })

  // const handleChange = e => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <Card>
      <Card.Header className="h5">
        <MdSettings className="text-primary" /> Profile Settings
      </Card.Header>
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                name="firstName"
                //onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                name="lastName"
                //onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label><MdEmail className="text-warning" /> Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={formData.email}
                name="email"
                //onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label><MdPhone className="text-warning" /> Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                value={formData.phone}
                name="phone"
                //onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              value={formData.title}
              name="title"
              //onChange={handleChange}
            />
          </Form.Group>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="department">
              <Form.Label>Department</Form.Label>
              <Form.Control
                type="text"
                placeholder="Department"
                value={formData.department}
                name="department"
                //onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="site">
              <Form.Label>Site</Form.Label>
              <Form.Control
                type="text"
                placeholder="Site"
                value={formData.site}
                name="site"
                //onChange={handleChange}
              />
            </Form.Group>
          </Row>

          {/* <div className="text-end">
            <Button variant="primary" type="submit">
              Update
            </Button>
          </div> */}
        </Form>
      </Card.Body>
    </Card>
  );
};

ProfileSetting.propTypes = {
  user: PropTypes.object.isRequired
}

export default ProfileSetting;
