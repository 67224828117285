import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  title: {
    text: 'Lead Funnel'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}'
  },
  toolbox: {
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {}
    }
  },
  color: [
    getColor("secondary"),
    getColor("primary"),
    getColor("warning"),
    getColor("success"),
    getColor("danger"),
  ],
  series: [
    {
      name: 'Sales Funnel',
      type: 'funnel',
      left: '10%',
      top: 60,
      bottom: 60,
      //width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'descending',
      //gap: 1,
      // label: {
      //   show: true,
      //   position: 'inside'
      // },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid'
        }
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1
      },
      emphasis: {
        label: {
          fontSize: 20
        }
      },
      data
    }
  ]
});

getOption.propTypes = {
  data: PropTypes.array.isRequired
};

const LeadFunnel = ({data=[]}) => {
  const sqlData = data.filter(item => item.stage === "SQL");
  const mqlData = data.filter(item => item.stage === "MQL");
  const opportunityData = data.filter(item => item.status_id === 4) //converted PCV

  const newData = [
    {name: "Leads", value: data?.length || 0},
    {name: "MQL", value: mqlData?.length || 0},
    {name: "SQL", value: sqlData?.length || 0},
    {name: "Opportunities", value: opportunityData?.length || 0},
  ];

  return (
    <Card className="h-100 border rounded">
      <Card.Body className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(newData)}
          className="w-100"
        />
      </Card.Body>
    </Card>
  );
};

LeadFunnel.propTypes = {
  data: PropTypes.array.isRequired
};

export default LeadFunnel;