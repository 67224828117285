import React, { lazy, Suspense } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { FaRegListAlt } from 'react-icons/fa';

const LazyProjectScheduleList = lazy(() => import("./project_schedule/ProjectScheduleList"))

const ProjectSchedule = () => {
  const { loading } = useSelector((state) => state.crm_report_project_schedule)

  return (
    <>
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="project-schedule">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-schedule"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="project-schedule">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyProjectScheduleList/>
                    </Suspense>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProjectSchedule;