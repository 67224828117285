import Flex from "components/common/Flex"
import { formatNumberWord } from "helpers/utils"
import { Card } from "react-bootstrap"
import React from 'react';
import PropTypes from "prop-types";

const NumberOfQuotationsBecameJobs = ({data, number_of_quotations}) => {
  const {number_of_projects, weight} = data;

  return (
    <Card className="h-100 bg-success text-white">
      <Card.Body>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="mb-3 position-static"
        >
          <h6 className="mb-0 flex-1 text-white"> Quotations became jobs</h6>
        </Flex>
        <Flex justifyContent="between">
          <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">
            <span className="fs-4">{formatNumberWord(parseInt(number_of_projects || 0))}</span>
            <span className="fs--1 ms-1">
              / {formatNumberWord(number_of_quotations)}
            </span>
          </p>
          <p className="fs-1 mb-0 mt-0 text-nowrap font-sans-serif">{formatNumberWord(weight || 0)} M.T</p>
        </Flex>
      </Card.Body>
    </Card>
  )
};

NumberOfQuotationsBecameJobs.propTypes ={
  data: PropTypes.object.isRequired,
  number_of_quotations: PropTypes.number.isRequired
}

export default NumberOfQuotationsBecameJobs;