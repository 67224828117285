import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

const projectUrl = `/crm/reports`

export const getIndividual = createAsyncThunk(
  "ReportPCV/getIndividual",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/individual_productivity`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const pcvSlice = createSlice({
  name: "report_pcv",
  initialState: {
    loading: false,
    exporting: false,
    individualData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIndividual.fulfilled, (state, action) => {
        state.individualData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

//export const {} = pcvSlice.actions

export default pcvSlice.reducer;