import React from 'react';
import PropTypes from 'prop-types';

const JobFormatRenderer = (props) => {
  const value = props.value
  let cssText = "text-warning"

  if (value && value.includes("J"))
  {
    cssText = "text-success"
  }

  return (
    <span className={cssText}>{value}</span>
  )
};

JobFormatRenderer.propTypes = {
  value: PropTypes.string.isRequired
}

export default JobFormatRenderer;
