import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Col, Form, Row, Button, Card, Accordion } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import IconButton from "components/common/IconButton";
import Flex from "components/common/Flex";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import apiAxios from "helpers/apiAxios";
import {isAfter} from "date-fns"
import { createBooking, updateBooking } from "redux/booking/travel/travelBookingSlice";
import { useSelector } from "react-redux";
import Select from 'react-select'
import { WithContext as ReactTags } from 'react-tag-input';
import "../../../../assets/scss/tag-input.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconAlert from "components/common/IconAlert";
import { getCountries, getExchangeRate } from "redux/settingsSlice";
import { getProfileUser } from "redux/profileUserSlice";
import { faGg, faRProject } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";
import BookingMultipleStopTripForm from "./BookingMultipleStopTripForm";
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { TRAVEL_ARRANGEMENT_BY_HR_ADMIN, TRAVEL_TYPE_OF_TRAVEL, TRAVEL_PURPOSE_OF_TRAVEL } from "app_config";
import { FaDownload, FaInfoCircle } from "react-icons/fa";
import { debouncedFilterEmployeesOriginal, debounce, debouncedEmployees, filterOptions } from "helpers/debounce"
import { getActiveSites } from "redux/organization/siteSlice";
import GoogleMapAutoCompletePlaceDebounce from "components/google-map-places/GoogleMapAutoCompletePlaceDebounce";
import PropTypes from 'prop-types';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const BookingForm = ({title, booking}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues, reset, setError, clearErrors, watch } = useForm()
  const {usdExchangeRateData, countryData} = useSelector((state) => state.settings)
  const {data: profileUser} = useSelector((state) => state.profile_user)

  const [customersTravelerTags, setCustomersTravelerTags] = useState([])
  const [currentTag, setCurrentTag] = useState()
  const [activeKey, setActiveKey] = useState(["0", "1", "2", "3", "4", "5"])
  const [selectedArrangements, setSelectedArrangements] = useState(booking?.arrangement_by_hr_admin ?? [])
  const [transitBookings, setTransitBookings] = useState([])
  const [transitsToDelete, setTransitsToDelete] = useState([]);
  const [address, setAddress] = useState(null);
  const [showTripForProjectTab, setShowTripForProjectTab] = useState(booking && booking.purpose_of_travel === TRAVEL_PURPOSE_OF_TRAVEL[2] || false);

  const {activeData: siteData} = useSelector(state => state.organization_site);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const bookingUrl = "/booking/travel"
  const { site: { currency: SITE_DEFAULT_CURRENCY = 'VND', country: SITE_DEFAULT_COUNTRY = 'VN' } = {} } = profileUser || {};

  useEffect(() => {
    if (title === "New travel request") {
      reset()
    }
  }, [title]);

  useEffect(() => {
    if (siteData?.length === 0) {
      dispatch(getActiveSites());
    }

    //get exchange rate
    dispatch(getExchangeRate())

    //get profile user
    if (profileUser === undefined) {
      dispatch(getProfileUser())
    }

    if (countryData === undefined || countryData.length === 0) {
      dispatch(getCountries())
    }
  }, [dispatch, siteData, profileUser, countryData]);

  useEffect(() => {
    setValue('date_of_travel', new Date())
    setValue('country_code', SITE_DEFAULT_COUNTRY)
    setValue('currency', SITE_DEFAULT_CURRENCY)
    setValue('exchange_rate', usdExchangeRateData)
    setValue("site_id", profileUser.site?.id)
    setValue("purpose_of_travel", TRAVEL_PURPOSE_OF_TRAVEL[4]); //set default is other
     
    if(booking) {
      Object.entries(booking).forEach(([key, value]) => {
        if (key === 'date_of_travel' || key === 'date_of_return' || key === 'expected_approval') {
          if (value) {
            const dateValue = new Date(value)
            setValue(key, dateValue);
          }
        }
        else if (key === 'customers_traveler') {
          value && setCustomersTravelerTags(value.map(item => ({id: item, text: item})))
        }
        else if (key === 'employees') {
          const selectedOptionsValues = value && value.map((emp) => emp.employee_id);
          setValue('employee_ids', selectedOptionsValues)
        }
        else {
          setValue(key, value)
        }
      })

      //set transit for booking
      setTransitBookings(booking.transits)
    }
  }, [setValue, booking])

  useEffect(() => {
    const customersTraveler = customersTravelerTags.map(tag => tag.text)
    setValue("customers_traveler", customersTraveler)

  }, [customersTravelerTags]);

  //set country belong change destination
  useEffect(() => {
    //console.log(address);
    if (address !== null) {
      setValue('country_code', address?.country);
    }
  }, [address]); 

  const validateJobNumber = () => {
    const job_number = getValues("job_number")
    if ((job_number === undefined || (job_number !== undefined && job_number?.includes('Q'))) && getValues("follow_project_budget")) {
      setError('job_number', {
        type: 'manual',
        message: 'Job number must be required',
      });
      return false;
    }
    return true;
  }

  const validateTraveller = () => {
    const customers_traveler = getValues("customers_traveler")
    const employees_traveler = getValues("employee_ids")

    if (customers_traveler && customers_traveler.length === 0 && employees_traveler === undefined) {
      setError('employee_ids', {
        type: 'manual',
        message: 'Travelers were empty. You must specify employees or customers travel for the trip.',
      });
      return false;
    }
    return true;
  }

  const validateArrangeByHrAdmin = () => {
    const arrangement = getValues("arrangement_by_hr_admin")

    if (arrangement && arrangement.length === 0) {
      setError('arrangement_by_hr_admin', {
        type: 'manual',
        message: 'Arrangement by HR Admin is empty. You must specify the required arrangements by HR admin.',
      });
      return false;
    }
    return true;
  }

  const onSubmit = async (data) => {
    if(transitBookings) {
      if (transitsToDelete) {
        const deletedTrans = transitsToDelete.map(item => ({ ...item, _destroy: "1" }))
        const transitBookingsData = [...transitBookings, ...deletedTrans]

        data = {...data, transits_attributes: transitBookingsData}
      }
      else
      {
        data = {...data, transits_attributes: transitBookings}
      }
    }

    data = {...data, destination: address?.description || booking?.destination};

    console.log(data)
    
    if (!validateTraveller() || !validateJobNumber() || !validateArrangeByHrAdmin()) {
      return; // Exit submit if validation fails
    }
    
    try {
      let bookingId = ""

      if (booking) {
        bookingId = booking.id
        dispatch(updateBooking({bookingId, data }))
      }
      else {
        const response = await dispatch(createBooking(data))
        bookingId = response.payload.data.id
      }
      
      navigate(`${bookingUrl}/${bookingId}`)
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const siteOptions = useMemo(() => siteData && siteData.map((site) => ({ value: site?.id, label: site?.name })), []) 
  const defaultSiteOption = useMemo(() => (
    booking ? { value: booking?.site_id, label: booking?.site_name } : {value: profileUser.site?.id, label: profileUser.site?.name}
  ), [booking])

  const countryOptions = useMemo(() => countryData && countryData.map((option) => ({ value: option.country, label: option.name })), []) 

  const currencyOptions = useMemo(() => [
    { value: "VND", label: "VND" },
    { value: "USD", label: "USD" }
  ], []);

  const employeeOptions = (inputValue, callback) => {
    debouncedFilterEmployeesOriginal(inputValue, callback)
  }

  const defaultEmployeeOption = useMemo(() => {
    if (booking && booking.employees) {
      return booking.employees.map((emp) => ({  value: emp.employee_id, label: emp.employee_name }))
    }
    return null
  }, [booking])

  const handleChangeEmployee = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('employee_ids', selectedOptionsValues, { shouldValidate: true })
  }

  const ccNameOptions = (inputValue, callback) => {
    debouncedEmployees(inputValue, callback, "all")
  }

  const defaultCCnameOption = useMemo(() => {
    if (booking && booking.cc_emails_optional) {
      return booking.cc_emails_optional.map((emp) => ({  value: emp, label: emp }))
    }
    return null
  }, [booking])

  const handleChangeCCname = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('cc_emails_optional', selectedOptionsValues, { shouldValidate: true })
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleDeleteTag = (i) => {
    setCustomersTravelerTags(customersTravelerTags.filter((tag, index) => index !== i));
  }

  const handleAdditionTag = (tag) => {
    setCustomersTravelerTags([...customersTravelerTags, tag]);
  }

  const handleInputTagChange = (value) => {
    setCurrentTag(value);
  };

  const handleInputTagBlur = () => {
    if (currentTag && currentTag.trim() !== '') {
      const checkExistsTag = customersTravelerTags.map(tag => tag.text).includes(currentTag)

      if (!checkExistsTag) {
        const newTag = { id: (customersTravelerTags.length + 1).toString(), text: currentTag.trim() };
        handleAdditionTag(newTag);
      }
      
      setCurrentTag('');
    }
  };

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const travelBookingUrlBE = "/booking/travel/bookings";
  const filterJobNumberOptions = async (inputValue) => {
    try {
      const url = `${travelBookingUrlBE}/list_quote_jobs?page=1&per_page=30&q=${encodeURIComponent(inputValue)}`;
      const response = await apiAxios.get(url);
      const { data } = response;
      const isFollowProjectBudget = watch("follow_project_budget") || false;
  
      if (!data) return [];
  
      return data.map(item => {
        const number = isFollowProjectBudget ? item.j_number : item.q_number;
        return {
          value: number,
          label: `${number}-${item.name}`
        };
      }).filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const debouncedFilterJobNumbers = debounce((inputValue, callback) => {
    filterJobNumberOptions(inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300)
  
  const jobNumberOptions = (inputValue, callback) => {
    debouncedFilterJobNumbers(inputValue, callback)
  }

  const defaultJobNumberOption = useMemo(() => {
    if (booking && booking.project) {
      const job_number = booking.project.job_number
      return { value: job_number, label: job_number } 
    }
    return null
  }, [booking])

  const debouncedFilterCustomers = debounce((inputValue, callback) => {
    filterOptions(`${travelBookingUrlBE}/list_customers`, inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const customerOptions = (inputValue, callback) => {
    debouncedFilterCustomers(inputValue, callback);
  };

  const defaultCustomerOption = useMemo(() => {
    if (booking && booking.project) {
      const project = booking.project
      return { value: project.customer_id, label: project.customer_name } 
    }
    return null
  }, [booking])

  const debouncedFilterProjects = debounce((inputValue, callback) => {
    filterOptions(`${travelBookingUrlBE}/list_projects`, inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const projectOptions = (inputValue, callback) => {
    debouncedFilterProjects(inputValue, callback);
  };

  const defaultProjectOption = useMemo(() => {
    if (booking && booking.project) {
      const project = booking.project
      return { value: project.project_id, label: project.project_name } 
    }
    return null
  }, [booking])

  useEffect(() => (
    setValue('arrangement_by_hr_admin', selectedArrangements, { shouldValidate: true })
  ), [selectedArrangements])

  const handleCheckboxArrangementChange = (option) => {
    // Check if the option is already selected
    if (selectedArrangements.includes(option)) {
      // If selected, remove it
      setSelectedArrangements((prevSelected) =>
        prevSelected.filter((item) => item !== option)
      );
    } else {
      // If not selected, add it
      setSelectedArrangements((prevSelected) => [...prevSelected, option]);
    }
  };

  const handleAddTransit = useCallback(() => {
    const date_of_travel = getValues("date_of_travel")
    const date_of_return = getValues("date_of_return")

    const newTransit = { 
      from_date: date_of_travel ? new Date(date_of_travel) : new Date(),
      to_date: date_of_return ? new Date(date_of_return) : new Date(),
      from_location: null,
      to_location: null,
      description: null,
      user_id: profileUser.id
    }

    const updatedTransits = [...transitBookings, newTransit];

    setTransitBookings(updatedTransits)
  }, [transitBookings])

  const handleRemoveTransit = (index) => {
    //store after deleted
    const transits = transitBookings.filter((item, i) => i !== index)
    setTransitBookings(transits)

    //store deleted items
    const transitsDeleted = transitBookings.filter((item, i) => i === index)
    setTransitsToDelete(transitsDeleted)
  }

  const handleUpdatedTransit = (index, transit) => {
    const updatedTransits = [...transitBookings]

    updatedTransits[index] = transit;
    setTransitBookings(updatedTransits);
  }

  const validateReturnDate = (value) => {
    const fromDate = getValues('date_of_travel');
    const parsedFromDate = fromDate ? new Date(fromDate) : null;
    const parsedToDate = value ? new Date(value) : null;

    return parsedFromDate && parsedToDate && isAfter(parsedToDate, parsedFromDate) || 'Date of return must be after date of travel.';
  }

  return (
    <Card className="fs--1">
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <Card.Title>{title}</Card.Title>
          <IconButton
            onClick={handleBack}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
        </Card.Header>
        <Card.Body className="mt-3 fs--1">
          <IconAlert variant="warning">
            <p className="mb-0">This request applies only to business trips that last overnight. For employees based in Vietnam, once a trip is approved, it will be automatically synchronized with iHRP, and you can check your leave status on both platforms.</p>
          </IconAlert>

          <Accordion 
            activeKey={activeKey}
            alwaysOpen
            flush
            onSelect={handleAccordionSelect}
            className="border rounded overflow-hidden fs--1 mt-3"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FontAwesomeIcon icon="info-circle" className="me-1"/> Trip information
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Row} className="mb-2" controlId="trip_name">
                  <Form.Label column sm={3} className="text-lg-end">
                    Trip name<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Form.Control
                      name="trip_name"
                      placeholder="Trip name"
                      className="fs--1"
                      {...register("trip_name", {
                        required: "Trip name must be required."
                      })}
                    />
                    <span className="text-danger">
                      {errors.trip_name && errors.trip_name.message}
                    </span>
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} className="mb-2" controlId="destination">
                  <Form.Label column sm={3} className="text-lg-end">
                    Destination
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Form.Control
                      name="destination"
                      placeholder="Destination"
                      className="fs--1"
                      {...register("destination", {
                        required: "Destination must be required."
                      })}
                    />
                    <span className="text-danger">
                      {errors.destination && errors.destination.message}
                    </span>
                  </Col>
                </Form.Group> */}

                <Form.Group as={Row} className="mb-2" controlId="destination">
                  <Form.Label column sm={3} className="text-lg-end">
                    Destination<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <GoogleMapAutoCompletePlaceDebounce
                      id="travel-destination"
                      setAddress={setAddress}
                      defaultAddress={booking?.destination}
                      isRequired={true}
                    />
                    <span className="text-danger">
                      {errors.destination && errors.destination.message}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2" controlId="date_of_travel">
                  <Form.Label column sm={3} className="text-lg-end">
                    Date of travel<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="date_of_travel"
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => { 
                            field.onChange(date)
                            
                            if (!getValues("date_of_return")) {
                              setValue("date_of_return", date)
                            }
                          }}
                          className="form-control fs--1"
                          placeholderText="DD-MM-YYYY h:mm aa"
                          timeIntervals={5}
                          dateFormat="dd-MM-yyyy h:mm aa"
                          showTimeSelect
                          fixedHeight
                        />
                      )}
                      rules={{
                        required: "Date of travel must be required."
                      }}
                    />
                    <span className="text-danger">
                      {errors.date_of_travel && errors.date_of_travel.message}
                    </span>    
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="date_of_return">
                  <Form.Label column sm={3} className="text-lg-end">
                    Date of return<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="date_of_return"
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          className="form-control fs--1"
                          placeholderText="DD-MM-YYYY h:mm aa"
                          timeIntervals={5}
                          dateFormat="dd-MM-yyyy h:mm aa"
                          showTimeSelect
                          fixedHeight
                        />
                      )}
                      rules={{
                        required: "Date of return must be required.",
                        validate: {
                          afterFromDate: value => validateReturnDate(value)
                        }
                      }}
                    />
                    <span className="text-danger">
                      {errors.date_of_return && errors.date_of_return.message}
                    </span>    
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="type_of_travel">
                  <Form.Label column sm={3} className="text-lg-end">
                    Type of trip<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller 
                      control={control}
                      name="type_of_travel"
                      defaultValue={booking && booking.type_of_travel ? booking.type_of_travel : TRAVEL_TYPE_OF_TRAVEL[0]}
                      render={({field}) => (
                        <Flex direction={'row'} className="mt-1">
                          {TRAVEL_TYPE_OF_TRAVEL.map((option, index) => (
                            <Form.Check
                              key={option}
                              id={`${option}${index}`}
                              type="radio"
                              label={option}
                              value={option}
                              className="me-3"
                              {...field}
                              defaultChecked={field.value === option}
                              onChange={() => 
                                {
                                  field.onChange(option)
                                  const currency = option === "International" ? "USD" : SITE_DEFAULT_CURRENCY;
                                  setValue('currency', currency);
                                }
                              }
                            />
                          ))}
                        </Flex>
                      )}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="site_id">
                  <Form.Label column sm={3} className="text-lg-end">
                    From site office<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="site_id"
                      render={() => (
                        <Select
                          closeMenuOnSelect={true}
                          options={siteOptions}
                          defaultValue={defaultSiteOption}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          onChange={selectedOption => setValue('site_id', selectedOption.value, {shouldValidate: true})}
                          //isDisabled={true}
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: 'Site must be required'
                        }
                      }}
                    />
                    <span className="text-danger">
                      {errors.site_id && errors.site_id.message}
                    </span>
                    <div className="my-2">
                      <FaInfoCircle /> Specify the correct site office, as the booking request will be forwarded to the HR Admin of that office for arrangement.
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="my-3" controlId="purpose_of_travel">
                  <Form.Label column sm={3} className="text-lg-end">
                    Purpose of travel<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller 
                      control={control}
                      name="purpose_of_travel"
                      rules={{ required: "Purpose of travel must be required."}}
                      defaultValue={booking && booking.purpose_of_travel ? booking.purpose_of_travel : TRAVEL_PURPOSE_OF_TRAVEL[3]}
                      render={({field}) => (
                        <Row className="mt-1">
                          {TRAVEL_PURPOSE_OF_TRAVEL.map((option, index) => (
                            <Col lg={6} sm={2} key={option}>
                              <Form.Check
                                key={option}
                                id={`${option}${index}`}
                                type="radio"
                                label={option}
                                value={option}
                                className="me-3"
                                {...field}
                                defaultChecked={field.value === option}
                                onChange={() => {
                                  field.onChange(option)
                                  setShowTripForProjectTab(option === TRAVEL_PURPOSE_OF_TRAVEL[2]) //Customer visit factory
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}
                    />
                    <span className="text-danger">
                      {errors.purpose_of_travel && errors.purpose_of_travel.message}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="description_purpose_of_travel">
                  <Form.Label column sm={3} className="text-lg-end">
                    Description<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="description_purpose_of_travel"
                      placeholder="Description purpose of travel"
                      className="fs--1"
                      {...register("description_purpose_of_travel", {
                        required: "Description purpose of travel must be required."
                      })}
                    />
                    <span className="text-danger">
                      {errors.description_purpose_of_travel && errors.description_purpose_of_travel.message}
                    </span>
                  </Col>
                </Form.Group>
                
                <Form.Group as={Row} className="mb-3" controlId="country_code">
                  <Form.Label column sm={3} className="text-lg-end">
                    Trip to country<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="country_code"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={countryOptions}
                          value={countryOptions.find(option => option.value === field.value)}
                          placeholder="Select..."
                          classNamePrefix="react-select"
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            setValue('country_code', selectedOption.value, { shouldValidate: true });
                          }}
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: 'Trip to country is required'
                        }
                      }}
                    />
                    <span className="text-danger">
                      {errors.country_code && errors.country_code.message}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="employee_ids">
                  <Form.Label column sm={3} className="text-lg-end">
                    Employees traveler<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="employee_ids"
                      render={() => (
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={employeeOptions}
                          defaultValue={defaultEmployeeOption}
                          closeMenuOnSelect={false}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          className="w-100"
                          isMulti
                          onChange={handleChangeEmployee}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.employee_ids && errors.employee_ids.message}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="arrangement_by_hr_admin">
                  <Form.Label column sm={3} className="text-lg-end">
                    Arrangement by HR Admin<span className="text-primary">*</span>
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller 
                      control={control}
                      name="arrangement_by_hr_admin"
                      render={({field}) => (
                        <Row className="mt-1 fs--1">
                          {TRAVEL_ARRANGEMENT_BY_HR_ADMIN.map((option, index) => (
                            <Col lg={4} sm={2} key={option}>
                              <Form.Check
                                key={option}
                                id={`${option}${index}`}
                                type="checkbox"
                                label={option}
                                value={option}
                                className="me-3"
                                {...field}
                                checked={selectedArrangements.includes(option)}
                                onChange={() => {
                                  field.onChange([...selectedArrangements, option]);
                                  handleCheckboxArrangementChange(option);
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}
                    />
                    <span className="text-danger">
                      {errors.arrangement_by_hr_admin && errors.arrangement_by_hr_admin.message}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="cc_emails_optional">
                  <Form.Label column sm={3} className="text-lg-end">
                    CC emails (optional)
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="cc_emails_optional"
                      render={() => (
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={ccNameOptions}
                          defaultValue={defaultCCnameOption}
                          closeMenuOnSelect={false}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          className="w-100"
                          isMulti
                          onChange={handleChangeCCname}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.cc_emails_optional && errors.cc_emails_optional.message}
                    </span>
                    <div className="my-2">
                      <FaInfoCircle /> If you wish to cc others for email tracking of the trip upon approval, you can specify their addresses in the field here.
                    </div>
                  </Col>
                </Form.Group>

                {/* <Form.Group as={Row} className="mb-4" controlId="expected_approval">
                  <Form.Label column sm={3} className="text-lg-end">
                    Expected approval date
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="expected_approval"
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          className="form-control fs--1"
                          placeholderText="DD-MM-YYYY h:mm aa"
                          timeIntervals={5}
                          dateFormat="dd-MM-yyyy h:mm aa"
                          showTimeSelect
                          fixedHeight
                        />
                      )}
                    />
                  </Col>
                </Form.Group> */}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <FontAwesomeIcon icon={faMapMarker} className="me-1"/> Multi stop trips
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Row} className="my-3 text-end" controlId="addTransit">
                  <Col sm={{span: 7, offset: 3}}>
                    <IconAlert variant="info">
                      <p className="mb-0">For booking multi-stop trips, please provide details including the starting date, ending date, and locations.</p>
                    </IconAlert>

                    <Flex
                      justifyContent="end"
                      alignItems="center"
                      className="text-primary cursor-pointer fs-0"
                      onClick={handleAddTransit}
                    >
                      <span className="circle-dashed">
                        <FontAwesomeIcon icon="plus" />
                      </span>
                      <span className="ms-3">Add stop trip</span>
                    </Flex>
                  </Col>
                </Form.Group>

                { transitBookings.map((item, index) => (
                  <BookingMultipleStopTripForm 
                    index={index}
                    key={index}
                    booking={booking}
                    transit={item}
                    control={control}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    removeTransit={() => handleRemoveTransit(index)}
                    updatedTransit={handleUpdatedTransit}
                  />
                ))}
              </Accordion.Body>
            </Accordion.Item>
            
            {showTripForProjectTab && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <FontAwesomeIcon icon={faRProject} className="me-1"/>  Trip for project
                </Accordion.Header>
                <Accordion.Body>
                  <Form.Group as={Row} controlId="follow_project_budget">
                    <Col sm={{ span: 10, offset: 3 }}>
                      <Controller
                        control={control}
                        name="follow_project_budget"
                        //defaultValue={false}
                        render={({ field }) => (
                          <Form.Check
                            type="switch"
                            label="Follow project budget"
                            checked={field.value}
                            onChange={(e) => {
                              const checked = e.target.checked
                              setValue("follow_project_budget", checked)

                              if (checked) {
                                validateJobNumber()
                              }
                              else {
                                clearErrors("job_number")
                              }
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="customer_id">
                    <Form.Label column sm={3} className="text-lg-end">
                      Customer
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Controller
                        control={control}
                        name="customer_id"
                        render={() => (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={customerOptions}
                            defaultValue={defaultCustomerOption}
                            closeMenuOnSelect={true}
                            placeholder='Select...'
                            classNamePrefix="react-select"
                            className="w-100"
                            isClearable
                            onChange={(selectedOption) => selectedOption ? setValue("customer_id", selectedOption.value) : null}
                          />
                        )}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="project_id">
                    <Form.Label column sm={3} className="text-lg-end">
                      Project
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Controller
                        control={control}
                        name="project_id"
                        render={() => (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={projectOptions}
                            defaultValue={defaultProjectOption}
                            closeMenuOnSelect={true}
                            placeholder='Select...'
                            classNamePrefix="react-select"
                            className="w-100"
                            isClearable
                            onChange={(selectedOption) => selectedOption ? setValue("project_id", selectedOption.value) : null}
                          />
                        )}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="job_number">
                    <Form.Label column sm={3} className="text-lg-end">
                      Quote/Job number
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Controller
                        control={control}
                        name="job_number"
                        render={() => (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={jobNumberOptions}
                            defaultValue={defaultJobNumberOption}
                            closeMenuOnSelect={true}
                            placeholder='Select...'
                            classNamePrefix="react-select"
                            className="w-100"
                            isClearable
                            onChange={(selectedOption) => {
                              
                              if (selectedOption) {
                                setValue("job_number", selectedOption.value)
                                clearErrors("job_number")
                              }
                              else {
                                null
                              }
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.job_number && errors.job_number.message}
                      </span>
                      <div className="my-2">
                        <FaInfoCircle /> Specify the job number if you are following the project budget.
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="customers_traveler">
                    <Form.Label column sm={3} className="text-lg-end">
                      Customers traveler
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <ReactTags
                        tags={customersTravelerTags}
                        delimiters={delimiters}
                        handleDelete={handleDeleteTag}
                        handleAddition={handleAdditionTag}
                        handleInputChange={handleInputTagChange}
                        handleInputBlur={handleInputTagBlur}
                      />
                      <span className="text-danger">
                        {errors.customers_traveler && errors.customers_traveler.message}
                      </span>
                      <div className="my-2">
                        <FaInfoCircle /> Press the enter key on the keyboard when entering a name of customers
                      </div>
                    </Col>
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            )}

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <FontAwesomeIcon icon="money-bill" className="me-1"/> Cash Advance
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Row} className="mb-3" controlId="advance_cost_currency">
                  <Form.Label column sm={3} className="text-lg-end">
                    Cash Advance
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Form.Control
                      name="advance_cost_currency"
                      placeholder="Cash Advance"
                      className="fs--1"
                      {...register('advance_cost_currency')}
                    />
                    <div className="mt-2">
                      <div>
                        <span><FaInfoCircle /> The currency for the cash advance will match that of the trip.</span>
                        <span> Click <FaDownload icon="download" /> Advance Payment Request template.</span>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <FontAwesomeIcon icon={faGg} className="me-1"/> Currency of trip
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Row} className="mb-3" controlId="currency">
                  <Form.Label column sm={3} className="text-lg-end">
                    Currency
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Controller
                      control={control}
                      name="currency"
                      render={({field}) => (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          options={currencyOptions}
                          value={currencyOptions.find(option => option.value === field.value)}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          onChange={selectedOption => setValue('currency', selectedOption.value, {shouldValidate: true})}
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: 'Currency is required'
                        }
                      }}
                    />
                    <span className="text-danger">
                      {errors.currency && errors.currency.message}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="exchange_rate">
                  <Form.Label column sm={3} className="text-lg-end">
                    Exchange rate
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Form.Control
                      type="text"
                      name="exchange_rate"
                      placeholder="Exchange rate"
                      className="fs--1"
                      {...register('exchange_rate', {
                        required: 'Exchange rate must be required'
                      })}
                    />
                    <span className="text-danger">
                      {errors.exchange_rate && errors.exchange_rate.message}
                    </span>
                    <IconAlert variant="info" className="mt-2">
                      <p className="mb-0">USD exchange rate to VND from <a href="https://www.vietcombank.com.vn/KHCN/Cong-cu-tien-ich/Ty-gia?devicechannel=default" target="_blank">Vietcombank</a>.</p>
                    </IconAlert>
                  </Col>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <FontAwesomeIcon icon="comment" className="me-1"/> Comment
                </Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Row} className="mb-2" controlId="comment_for_booking">
                  <Form.Label column sm={3} className="text-lg-end">
                    Comment for booking
                  </Form.Label>
                  <Col sm={9} md={7}>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      name="comment_for_booking"
                      placeholder="Comment for booking"
                      className="fs--1"
                      {...register("comment_for_booking")}
                    />
                  </Col>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="bg-light fixed-bottom border-0 text-center p-3">
            <Button size="sm" type="submit">{booking ? 'Update' : 'Create and send travel request'}</Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={handleBack}>Cancel</Button>
          </div>
        </Card.Body>
      </Form>
    </Card>
  )
};

BookingForm.propTypes = {
  title: PropTypes.string.isRequired,
  booking: PropTypes.object.isRequired
}

export default BookingForm;