import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const buildingUrl = (projectId) => `/crm/projects/${projectId}/buildings`;

export const getExecutionBuildings = createAsyncThunk(
  "Execution/getExecutionBuildings",
  async ({ projectId, params } ) => {
    try {
      const response = await apiAxios.get(`${buildingUrl(projectId)}/execution`, { params });
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const updateExecutionOverview = createAsyncThunk(
  "Execution/updateExecutionOverview",
  async ({ projectId, buildingId, data } ) => {
    try {
      const response = await apiAxios.put(`${buildingUrl(projectId)}/${buildingId}/update_execution_overview`, data);
      return response.data;
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const updatePaymentTerm = createAsyncThunk(
  "Execution/updatePaymentTerm",
  async ({ projectId, buildingId, data } ) => {
    try {
      const response = await apiAxios.put(`${buildingUrl(projectId)}/${buildingId}/update_payment_term`, data);
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const updateShipmentOverview = createAsyncThunk(
  "Execution/updateShipmentOverview",
  async ({ projectId, buildingId, data } ) => {
    try {
      const response = await apiAxios.put(`${buildingUrl(projectId)}/${buildingId}/update_shipment_overview`, data);
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getCOBuildingsProject = createAsyncThunk(
  "Execution/getCOBuildingsProject",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`/crm/projects/${projectId}/load_building_copy_cos`);
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

//---------------------------------------Shipment-----------------------------------------

const shipmentUrl = (projectId, buildingId, shipmentId) => (
  shipmentId ? `${buildingUrl(projectId, buildingId)}/${buildingId}/shipments/${shipmentId}` : `${buildingUrl(projectId, buildingId)}/${buildingId}/shipments`
)

export const createShipment = createAsyncThunk(
  "Execution/createShipment",
  async ({projectId, buildingId, data}, { rejectWithValue }) => {
    try {
      const url = shipmentUrl(projectId, buildingId)
      const response = await apiAxios.post(url, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateShipment = createAsyncThunk(
  "Execution/updateShipment",
  async ({ projectId, buildingId, shipmentId, data }, { rejectWithValue }) => {
    try {
      const url = shipmentUrl(projectId, buildingId, shipmentId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteShipment = createAsyncThunk(
  "Execution/deleteShipment",
  async ({projectId, buildingId, shipmentId}) => {
    try {
      const url = shipmentUrl(projectId, buildingId, shipmentId)
      const response = await apiAxios.delete(url);
      return { building_id: buildingId, shipmentId, message: response.data.message, error: response.data.error || undefined}
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

//---------------------------------------Shop Summary-----------------------------------------

const phaseUrl = (projectId, buildingId, phaseId) => (
  phaseId ? `${buildingUrl(projectId, buildingId)}/${buildingId}/phase_summaries/${phaseId}` : `${buildingUrl(projectId, buildingId)}/${buildingId}/phase_summaries`
)

export const createShopSummary = createAsyncThunk(
  "Execution/createShopSummary",
  async ({projectId, buildingId, data}, { rejectWithValue }) => {
    try {
      const url = phaseUrl(projectId, buildingId)
      const response = await apiAxios.post(url, data)

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateShopSummary = createAsyncThunk(
  "Execution/updateShopSummary",
  async ({ projectId, buildingId, phaseId, data }, { rejectWithValue }) => {
    try {
      const url = phaseUrl(projectId, buildingId, phaseId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const deleteShopSummary = createAsyncThunk(
  "Execution/deleteShopSummary",
  async ({projectId, buildingId, phaseId}) => {
    try {
      const url = phaseUrl(projectId, buildingId, phaseId)
      const response = await apiAxios.delete(url);
      return { building_id: buildingId, phaseId, message: response.data.message, error: response.data.error || undefined}
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action) => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const building = data.attributes

    if (building) {
      const updatedIndex = state.data.findIndex((item) => item.id === building.id)
      if (updatedIndex !== -1) {
        state.data[updatedIndex] = building
      }
    }
    toast.success(message);
  }
}

const handlePayloadShipment = (state, action, type="") => {
  const { error, data, building_id, shipmentId, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    
    let shipment = null
    

    if (building_id) {
      const building = state.data.find((item) => item.id === building_id)

      if (building) {
        switch (type) {
          case "create":
            shipment = data.attributes
            building.shipments.unshift(shipment)

            break
    
          case "delete":
            if (shipmentId) {
              building.shipments = building.shipments.filter((item) => item.id !== shipmentId)
            }
            break
    
          default:
            shipment = data.attributes
    
            if (shipment) {
              const updatedIndex = building.shipments.findIndex((item) => item.id === shipment.id)
              if (updatedIndex !== -1) {
                building.shipments[updatedIndex] = shipment
              }
            }
            break;
        }
    
        toast.success(message)
      }
    }
    else {
      toast.error("No building return.")
    }

    
  }
}

const handlePayloadPhaseSummary = (state, action, type="") => {
  const { error, data, building_id, phaseId, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    
    let phase = null
    
    if (building_id) {
      const building = state.data.find((item) => item.id === building_id)

      if (building) {
        switch (type) {
          case "create":
            phase = data.attributes
            building.shop_summaries.unshift(phase)
            break
    
          case "delete":
            if (phaseId) {
              building.shop_summaries = building.shop_summaries.filter((item) => item.id !== phaseId)
            }
            break
    
          default:
            phase = data.attributes
    
            if (phase) {
              const updatedIndex = building.shop_summaries.findIndex((item) => item.id === phase.id)
              if (updatedIndex !== -1) {
                building.shop_summaries[updatedIndex] = phase
              }
            }
            break;
        }
    
        toast.success(message)
      }
    }
    else {
      toast.error("No building return.")
    }
  }
}

const executionSlide = createSlice({
  name: "crm_project_execution",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {}
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExecutionBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getExecutionBuildings.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate = state.paginate
        const calRecords      = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(updateExecutionOverview.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updatePaymentTerm.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateShipmentOverview.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      //---------------------Shipment----------------------
      .addCase(createShipment.fulfilled, (state, action) => {
        handlePayloadShipment(state, action, "create")
      })
      .addCase(updateShipment.fulfilled, (state, action) => {
        handlePayloadShipment(state, action)
      })
      .addCase(deleteShipment.fulfilled, (state, action) => {
        handlePayloadShipment(state, action, "delete")
      })
      //---------------------Shop----------------------
      .addCase(createShopSummary.fulfilled, (state, action) => {
        handlePayloadPhaseSummary(state, action, "create")
      })
      .addCase(updateShopSummary.fulfilled, (state, action) => {
        handlePayloadPhaseSummary(state, action)
      })
      .addCase(deleteShopSummary.fulfilled, (state, action) => {
        handlePayloadPhaseSummary(state, action, "delete")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams,
  resetParams,
} = executionSlide.actions

export default executionSlide.reducer