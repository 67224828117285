import React, { useMemo, useRef, useCallback, useLayoutEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Flex from 'components/common/Flex';
import { Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import './AgGrid.scss';

const AgGridTable = ({
  columnDefs,
  rowData,
  gridHeight,
  tooltipComponent,
  exportToCSV,
  selectedMultipe,
  refData,
  serverSide,
  sortable,
  filter
}) => {
  const gridRef = useRef(null);
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      //editable: true,
      sortable: sortable || false,
      filter: filter || false,
      //wrapText: true,
      autoHeight: true,
      tooltipComponent: tooltipComponent
    };
  }, []);

  const getRowId = useMemo(() => {
    return (params) => params.data?.id;
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    refData(selectedRows)
  }, []);

  const onQuickFilterChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('quickFilter').value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      gridRef.current && gridRef.current.api.sizeColumnsToFit();
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const gridOptions = {
    getRowId: (data) => data.id,
  };

  return (
    <>
      {exportToCSV && (
        <Flex justifyContent={'between'} className="my-2">
          <div>
            <Form.Control
              type="text"
              onInput={onQuickFilterChanged}
              id="quickFilter"
              placeholder="Quick filter..."
            />
          </div>
          <div>
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="download"
              onClick={onBtnExport}
            >
              <span className="d-none d-sm-inline-block ms-1">Export to CSV</span>
            </IconButton>
          </div>
        </Flex>
      )}
      <div className="ag-theme-alpine" style={{height: gridHeight, width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressSizeToFit={true}
          multiSortKey={'ctrl'}
          animateRows={true}
          getRowId={getRowId}
          gridOptions={gridOptions}
          ref={gridRef}
          rowSelection={ selectedMultipe ? 'multiple' : 'single' }
          onSelectionChanged={onSelectionChanged}
          suppressExcelExport={exportToCSV}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          pagination={!serverSide}
        />
      </div>
    </>
  )
}

AgGridTable.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  gridHeight: PropTypes.string.isRequired,
  tooltipComponent: PropTypes.elementType,
  exportToCSV: PropTypes.bool,
  selectedMultipe: PropTypes.bool,
  refData: PropTypes.func.isRequired,
  serverSide: PropTypes.bool,
  sortable: PropTypes.bool,
  filter: PropTypes.bool,
};

AgGridTable.defaultProps = {
  serverSide: false,
  exportToCSV: false,
  sortable: false,
  filter: false,
};


export default AgGridTable;
