import React from 'react';
import { Card } from 'react-bootstrap';

const NotAuthorized = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card className="text-center h-100 w-100">
        <Card.Body className="p-5 d-flex flex-column justify-content-center">
          <div className="display-1 text-300 fs-error">403</div>
          <p className="lead mt-4 font-sans-serif fw-semi-bold text-danger">
            Unauthorized access page!
          </p>
          <hr />
          <p>
            You are not authorized or do not have the necessary permissions to access this page,
            <a href="mailto:portal@pebsteel.com.vn" className="ms-1">
              contact IT department
            </a>.
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default NotAuthorized;
