const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaInfoCircle, FaCalendar } from 'react-icons/fa';
import React, { lazy, Suspense } from "react";
import { MdPendingActions } from 'react-icons/md';
import { monthNameMapDecimal } from 'helpers/utils';
import PropTypes from 'prop-types';

const LazyCustomerProcessItem = lazy(() => import("./CustomerProcessItem"));
const LazyCustomerDetail = lazy(() => import("./CustomerDetail"));

const CustomerProcess = ({customerData}) => {
  const {monthly, sales_regions, data} = customerData || {};

  const COLOR = "info";

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMapDecimal[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="crm-customer">
        <Card.Header className="bg-light text-primary h5">
          <MdPendingActions /> Customers entered
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Customers created by monthly and sales region for the period.</p>

          <Tabs defaultActiveKey="customer-sales-region" id="c-customer" transition={false}>
            <Tab eventKey="customer-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyCustomerProcessItem data={sales_regions} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="customer-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyCustomerProcessItem data={convertToMonthNames(monthly)} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="customer-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyCustomerDetail data={data} />
              </Suspense>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

CustomerProcess.propTypes = {
  customerData: PropTypes.shape({
    monthly: PropTypes.object,
    sales_regions: PropTypes.object,
    data: PropTypes.array
  }).isRequired
}

export default CustomerProcess;