import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from "components/common/Flex";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadDocument } from "redux/booking/car/carBookingSlice";
import PropTypes from 'prop-types';

const TransactionDetailUploadAttachmentDirectly = ({transactionId}) => {
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);

    try {
      const data = {
        files: acceptedFiles
      }
      
      dispatch(uploadDocument({transactionId, data}));
    }
    catch(error) {
      console.log(error.message);
      toast.error(error.message);
    }
  }, [transactionId]);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true
  })

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone-area py-6' })}>
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">Drop your files here</p>
        </Flex>
      </div>
      {/* <ul className="my-2">
        {selectedFiles.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul> */}
    </div>
  )
};

TransactionDetailUploadAttachmentDirectly.propTypes = {
  transactionId: PropTypes.number.isRequired
}


export default TransactionDetailUploadAttachmentDirectly;