import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import FeedReactions from './FeedReactions';
import FeedActionButtons from './FeedActionButtons';
import Flex from 'components/common/Flex';
import Comments from './Comments';
import { MeAvatar } from 'components/avatar/MeAvatar';
import { useDispatch } from 'react-redux';
import { addComment } from 'redux/social/feedSlice';
import {toast} from "react-toastify";
import { useSelector } from 'react-redux';

const FeedCardFooter = ({
  id,
  countLCS,
  reactions,
  comments,
  otherComments
}) => {
  const {data: user} = useSelector(state => state.profile_user);

  const [comment, setComment] = useState('');
  const dispatch = useDispatch();

  const submitComment = async (e) => {
    e.preventDefault();

    try {
      const newComment = {
        uuid: uuid(),
        avatar_url: user?.avatar_url,
        name: user?.full_name,
        content: comment,
        post_time: new Date()
      };

      await dispatch(addComment({feedId: id, data: newComment}));
  
      setComment('');
    }
    catch(error) {
      toast.error(error.message)
    } 
  };

  return (
    <Card.Footer className="bg-light pt-0">
      <FeedReactions {...countLCS} />
      <FeedActionButtons id={id} reactions={reactions} />

      <Form onSubmit={submitComment}>
        <Flex alignItems="center" className="border-top border-200 pt-3">
          <MeAvatar />
          <Form.Control
            type="text"
            className="rounded-pill ms-2 fs--1"
            placeholder="Write a comment..."
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </Flex>
      </Form>
      {!!comments && (
        <Comments comments={comments} loadComment={otherComments} />
      )}
    </Card.Footer>
  );
};

export default FeedCardFooter;
