import { Row, Col } from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import Flex from "components/common/Flex";
import { FaPhone, FaUser } from "react-icons/fa";
import { formatDate } from "helpers/utils";
import React from 'react';
import PropTypes from 'prop-types';

const ContactItem = ({contact}) => {
  return (
    <div className='p-2 p-sm-3 light border rounded'>
      <Row className="gx-2">
        <Col md="auto">
          <FaUser size="25" className="text-primary" />
        </Col>
        <Col>
          <Flex justifyContent="between" className="mb-3">
            <h5 className="mb-1 text-primary">{contact?.name}</h5>
            <small>{formatDate(contact?.created_date)}</small>
          </Flex>
          <p className="mb-1">
            <div className="mb-2"><MdEmail /> Email: <a href={`mailto:thv@pebsteel.com.vn`}> {contact?.email}</a></div>
            <div className="mb-2"><FaPhone /> Phone: <a href={`tel:+84 977 073 155`}>{contact?.mobile}</a></div>
          </p>
          {/* <div className="text-end"><FaEdit className="text-primary cursor-pointer" title="Edit Contact" /> | <FaTrashAlt className="text-danger cursor-pointer" title="Delete Contact" /></div> */}
        </Col>
      </Row>
    </div>
  )
};

PropTypes.propTypes = {
  contact: PropTypes.object.isRequired
}

export default ContactItem;