import React from 'react';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SummaryChart from './SummaryChart';
import PropTypes from 'prop-types';

const SummaryWaiver = ({data}) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Summary status of waivers"
        titleTag="h6"
        className="border-200 border-bottom py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body as={Row}>
        <Col md={7} className="mb-xxl-1">
          <SummaryChart data={data}/>
        </Col>
        <Col md={5}>
          <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
          {data && data.map((item) => (
            <Flex
              justifyContent="between"
              alignItems="center"
              className="border-bottom py-3"
              key={item.id}
            >
              <Flex>
                <h6 className="text-700 mb-0">{item.name}</h6>
              </Flex>
              <h6 className="text-700 mb-0">{item.number_of_waivers}</h6>
            </Flex>
          ))}
        </Col>
      </Card.Body>
    </Card>
  );
};

SummaryWaiver.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      number_of_waivers: PropTypes.number.isRequired,
      icon: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
      progress: PropTypes.number,
      progressValue: PropTypes.number
    })
  ).isRequired
};

export default SummaryWaiver;
