import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import { formatNumber } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal, Form, Button, Table } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateEmployeeExpense } from 'redux/booking/travel/travelBookingSlice';

const BookingUpdateExpenseEmployeeModal = ({showModal, setShowModal, booking}) => {
  const { register, handleSubmit, formState: {errors}, setValue, getValues } = useForm();
  const dispatch = useDispatch();
  const bookingId = booking.id;

  const employees = booking.employees ? booking.employees : [];

  useEffect(() => {
    
  }, [booking, booking.employees]);

  const onSubmit = (data) => {
    console.log(data)

    const updatedEmployees = []
    employees.map((emp) => {
      const formData = {
        employee_id: emp.employee_id,
        employee_name: data[`name_${emp.id}`],
        amount_currency: data[`amount_currency_${emp.id}`],
        exchange_rate: booking.exchange_rate,
        amount: data[`amount_${emp.id}`]
      }

      updatedEmployees.push(formData)
    })
    
    console.log(updatedEmployees);

    try {
      dispatch(updateEmployeeExpense({bookingId, data: { employees: updatedEmployees} }))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="border-0 bg-light">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Update employee expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="info">
              <p className="mb-0">Expense of employee follow as policy company.</p>
            </IconAlert>
            <Flex justifyContent="end" className="mb-2">
              Exchange rate of trip: 1 USD = <span className="mx-1 fw-bold text-primary fs--1">{formatNumber(booking.exchange_rate)}</span> VND
            </Flex>

            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Employee Name</th>
                  <th scope="col">Amount currency (USD)</th>
                  <th scope="col">Amount (VND)</th>
                </tr>
              </thead>
              <tbody>
                {employees && employees.map((emp) => (
                  <tr key={emp.id}>
                    <td>{emp.id}</td>
                    <td>
                      <Form.Control
                        type="text"
                        name={`name_${emp.id}`}
                        defaultValue={emp.employee_name}
                        placeholder="Amount currency"
                        className="fs--1"
                        {...register(`name_${emp.id}`, {
                          required: "Name must be required"
                        })}
                      />
                      <span className="text-danger">
                        {errors[`name_${emp.id}`] && errors[`name_${emp.id}`].message}
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        name={`amount_currency${emp.id}`}
                        defaultValue={emp.amount_currency}
                        placeholder="Amount currency"
                        className="fs--1"
                        {...register(`amount_currency_${emp.id}`, {
                          required: "Amount currency must be required"
                        })}
                        onChange={(e) => {
                          const amountCurrency = Number(e.target.value);
                          const convertedAmount = Number((amountCurrency * booking.exchange_rate).toFixed(2));
                          setValue(`amount_${emp.id}`, convertedAmount);
                        }}
                      />
                      <span className="text-danger">
                        {errors[`amount_currency_${emp.id}`] && errors[`amount_currency_${emp.id}`].message}
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        name={`amount_${emp.id}`}
                        defaultValue={emp.amount}
                        placeholder="Amount"
                        className="fs--1"
                        {...register(`amount_${emp.id}`, {
                          required: "Amount must be required"
                        })}
                      />
                      <span className="text-danger">
                        {errors[`amount_${emp.id}`] && errors[`amount_${emp.id}`].message}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit" variant="primary">Save</Button>
            <Button size="sm" variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

BookingUpdateExpenseEmployeeModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  booking: PropTypes.object
};


export default BookingUpdateExpenseEmployeeModal;