import React from 'react';
import { formatNumber } from 'helpers/utils';
import PropTypes from 'prop-types';

const WeightFormatRenderer = (props) => {
  const data = props.data
  const value = props.value
  let note = null

  switch(props.column.colId) {
    case "jaf_weight":
      note = data.jaf_note

      if (value === null || value === "" || value == 0.0)
        return <span className="text-danger">{note}</span>
      else
      {
        return note !== 'NOT YET' ? (
          <span>
            {formatNumber(value).toString()}{' '}
            <span className='text-primary'>({note})</span>
          </span>
        ) : formatNumber(value)
      } 
    case "est_final_weight":
      return value === null || value === "" || value == 0.0 ? (<span>{data.est_final_note}</span>) : formatNumber(value)
    case "bom_weight":
      note = data.bom_note

      if (value === null || value === "" || value == 0.0)
        return <span className="text-danger">{note}</span>
      else
      {
        return note !== 'NOT YET' ?  (
          <span>
            {formatNumber(value).toString()}{' '}
            <span className='text-primary'>({note})</span>
          </span>
        ) : formatNumber(value)
      }

    default:
      return null
  }
};

WeightFormatRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  data: PropTypes.shape({
    jaf_note: PropTypes.string,
    est_final_note: PropTypes.string,
    bom_note: PropTypes.string
  }).isRequired
}

export default WeightFormatRenderer;