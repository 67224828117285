import React from 'react';
import { Card} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ContractDetail from 'components/crm/reports/contract/ContractDetail';
import PropTypes from 'prop-types';

const ContractDataView = ({data=[]}) => {
  return (
    <Card className="h-100 mb-3">
      <FalconCardHeader
        title="Detail"
        titleTag="h5"
        className="py-2"
        light 
      />
      <div>
        <ContractDetail data={data} />
      </div>
    </Card>
  );
};

ContractDataView.propTypes = {
  data: PropTypes.array.isRequired
}

export default ContractDataView;