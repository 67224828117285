import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const driverUrl = "/booking/car/drivers"

export const getAllDrivers = createAsyncThunk(
  "Driver/getAllDrivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${driverUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getDriver = createAsyncThunk(
  "Driver/getDriver",
  async (driverId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${driverUrl}/${driverId}`)
      return objectSerializer(response.data)
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createDriver = createAsyncThunk(
  "Driver/createDriver",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(driverUrl, data)
      return objectSerializer(response.data)
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateDriver = createAsyncThunk(
  "Driver/updateDriver",
  async ({ driverId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${driverUrl}/${driverId}`, data)
      return objectSerializer(response.data)
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteDriver = createAsyncThunk(
  "driver/deleteDriver",
  async (driver) => {
    try {
      await apiAxios.delete(`${driverUrl}/${driver.id}`);
      return driver
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportDrivers = createAsyncThunk(
  "Driver/exportDrivers",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${driverUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'drivers.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const deactivateDriver = createAsyncThunk(
  "Driver/deactivateDriver",
  async (driver, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${driverUrl}/${driver.id}/deactivate`);
      return response.data.attributes
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const driverSlice = createSlice({
  name: "booking_setting_driver",
  initialState: {
    data: [],
    totalRows: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: 100,
    fromRecord: 0,
    toRecord: 1,
    loading: false,
    exporting: false,
    filterValue: "",
    currentParams: {page: 1, per_page: 100},
    activeType: "active"
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = parseInt(action.payload)
    },
    setFilterValue: (state, action) => {
      state.filterValue = action.payload
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {}
      state.filterValue = ""
    },
    setActiveType: (state, action) => {
      state.activeType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDrivers.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllDrivers.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const calRecords      = calPaginatorRecord(state.currentPage, state.pageSize, state.totalRows)
        state.totalPagesCount = calRecords.totalPagesCount
        state.fromRecord      = calRecords.beginRecords
        state.toRecord        = calRecords.lastRecords
      })
      .addCase(getDriver.fulfilled, (state, action) => {
        state.driver = action.payload[0]
      })
      .addCase(createDriver.fulfilled, (state, action) => {
        const driver = action.payload[0]
        state.data.unshift(driver)
        toast.success(`Driver ${driver.name} have been created successfully.`)
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        const driver = action.payload[0]
        const updatedIndex = state.data.findIndex((item) => item.id === driver.id)
        if (updatedIndex !== -1) {
          state.data[updatedIndex] = driver
          toast.success(`Driver ${driver.name} have been updated successfully.`)
        }
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        const driver = action.payload
        if (driver !== undefined) {
          state.data = state.data.filter((item) => item.id !== driver.id)
          toast.success(`Driver ${driver.name} have been deleted successfully.`)
        }
      })
      .addCase(exportDrivers.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportDrivers.fulfilled, (state) => {
        state.exporting = false
        toast.success("Driver has been exported successfully.")
      })
      .addCase(deactivateDriver.fulfilled, (state, action) => {
        const driver = action.payload
        const updatedIndex = state.data.findIndex((item) => item.id === driver.id)
        if (updatedIndex !== -1) {
          state.data[updatedIndex] = driver
          toast.success(`Driver ${driver.name} have been ${driver.active ? 'dectivated' : 'activated'} successfully.`)
        }
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setFilterValue,
  setActiveType
} = driverSlice.actions;

export default driverSlice.reducer;