import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContractThisMonth } from "redux/crm/report/contractSlice";
import { Link } from 'react-router-dom';
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { Badge, Card } from "react-bootstrap";

const ContractThisMonth = () => {
  const { thisMonthData: data } = useSelector((state) => state.crm_report_contract)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContractThisMonth())
  }, [])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'Project ID#',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold'
      }
    },
    {
      accessor: 'project_name',
      Header: 'Project name',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, project_name } = rowData.row.original;
        return <Link to={`/crm/projects/${id}`} target="_blank">{project_name}</Link>;
      }
    },
    {
      accessor: 'q_number',
      Header: 'Quote number',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, q_number } = rowData.row.original;
        return <Link to={`/crm/projects/${id}`} target="_blank">{q_number}</Link>;
      }
    },
    {
      accessor: 'j_number',
      Header: 'P/J number',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, j_number } = rowData.row.original;
        return <Link to={`/crm/projects/${id}`} target="_blank">{j_number}</Link>;
      }
    },
    {
      accessor: 'customer_name',
      Header: 'Customer name'
    },
    {
      accessor: 'contract_type_name',
      Header: 'Contract type',
      Cell: rowData => {
        const { contract_type, contract_type_name } = rowData.row.original;
        return <Badge pill bg={contract_type === 0 ? 'primary' : 'info'} className="text-capitalize">{contract_type_name}</Badge>
      }
    },
    {
      accessor: 'contract_date',
      Header: 'Contract date'
    },
    {
      accessor: 'contract_weight',
      Header: 'Weight (M.T)'
    },
    {
      accessor: 'contract_value',
      Header: 'Value (USD)'
    },
    {
      accessor: 'sales_incharge',
      Header: 'Sales incharge'
    },
    {
      accessor: 'sales_unit_name',
      Header: 'Sales unit'
    },
    {
      accessor: 'pj_province',
      Header: 'PJ province'
    },
    {
      accessor: 'pj_country',
      Header: 'PJ country'
    }
  ], [data]);

  return (
    <>
      <div className="mb-3 fs--1">List of Contracts/Addendums created this month.</div>
    
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        selection
        sortable
        pagination
        perPage={15}
        rowCount={data?.length}
      >
        <Card className="p-2">
          <Card.Header>
            <Flex justifyContent="end" className="mb-0" alignContent="center" alignItems="center">
              <AdvanceTableSearchBox table className="w-md-25 w-sm-50" />
            </Flex>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: 'md',
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter rowCount={data?.length} table rowInfo navButtons rowsPerPageSelection />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default ContractThisMonth;