const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaInfoCircle, FaCalendar } from 'react-icons/fa';
import { lazy, Suspense } from "react";
import { MdPendingActions } from 'react-icons/md';
import { monthNameMap } from 'helpers/utils';
import React from 'react';
import PropTypes from "prop-types";

const LazyQuotationProcessingItem = lazy(() => import("./QuotationProcessingItem"));
const LazyQuotationDetail = lazy(() => import("./QuotationDetail"));

const QuotationProcessing = ({data}) => {
  const COLOR = "info";

  const salesRegionData = data.reduce((summary, project) => {
    const { sales_unit_region, est_weight } = project;

    if (!summary[sales_unit_region]) {
        summary[sales_unit_region] = { number_of_projects: 0, weight: 0 };
    }

    summary[sales_unit_region].number_of_projects++;
    summary[sales_unit_region].weight += parseFloat(est_weight) || 0;

    return summary;
  }, {});

  const monthData = data.reduce((summary, project) => {
    const { quotation_month, est_weight } = project;

    if (!summary[quotation_month]) {
        summary[quotation_month] = { number_of_projects: 0, weight: 0 };
    }

    summary[quotation_month].number_of_projects++;
    summary[quotation_month].weight += parseFloat(est_weight) || 0;

    return summary;
  }, {});

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMap[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="became-job">
        <Card.Header className="bg-light text-primary h5">
          <MdPendingActions /> Quotation processing
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Quotations entered by the sales team and awaiting release from the Engineering department for the period.</p>

          <Tabs defaultActiveKey="q-processing-sales-region" id="q-processing" transition={false}>
            <Tab eventKey="q-processing-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyQuotationProcessingItem data={salesRegionData} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="q-processing-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyQuotationProcessingItem data={convertToMonthNames(monthData)} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="q-processing-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyQuotationDetail data={data} />
              </Suspense>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

QuotationProcessing.propTypes = {
  data: PropTypes.object.isRequired
}

export default QuotationProcessing;