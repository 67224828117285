import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import PropTypes from 'prop-types';

const BookingAttachment = ({id, name, size, url, handleRemoveAttachment}) => {

  const getIcon = type => {
    const icon = ['far'];
    if (type.includes('image')) {
      icon.push('file-image');
    }
    if (type.includes('video')) {
      icon.push('file-video');
    }
    if (type.includes('audio')) {
      icon.push('file-audio');
    }
    if (type.includes('zip')) {
      icon.push('file-archive');
    }
    if (type.includes('pdf')) {
      icon.push('file-pdf');
    }
    if (
      type.includes('html') ||
      type.includes('css') ||
      type.includes('json') ||
      type.includes('javascript')
    ) {
      icon.push('file-code');
    }
    if (icon.length === 1) {
      icon.push('file');
    }
    return icon;
  };

  return (
    <Flex
      alignItems="center"
      className="border px-2 me-1 rounded-3 bg-white dark__bg-1000 my-1 fs--1"
    >
      <FontAwesomeIcon icon="paperclip" className="fs--1" />
      <a href={url} rel="noopener noreferrer" target="_blank">
        <span className="mx-2 flex-1">
          {name} ({(size / 1024).toFixed(2)} kb)
        </span>
      </a>
      <span
        className="text-300 p-1 ml-auto cursor-pointer"
        id={`attachmentTooltip${id}`}
        onClick={() => handleRemoveAttachment(id)}
      >
        <FontAwesomeIcon icon="times" />
      </span>
    </Flex>
  )
}

BookingAttachment.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  content_type: PropTypes.string,
  url: PropTypes.string,
  handleRemoveAttachment: PropTypes.func,
};

export default BookingAttachment;