import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { useMemo } from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import {format} from 'date-fns';
import PropTypes from 'prop-types';
import { formatNumber } from 'helpers/utils';
import {Link} from 'react-router-dom';

const TransactionDetailDriverTrans = ({transaction}) => {
  const data = transaction?.driver_trans;

  const formatDate = (date) => {
    if (!date) return
    return format(new Date(date), "MMM, dd-yyyy h:mm aa")
  }

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID#',
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Link to={`/booking/car/${transaction.id}/driver-trans/${id}/update`} target="_blank">{id}</Link>
        )
      }
    },
    {
      accessor: 'driver.name',
      Header: 'Driver name',
      Cell: rowData => {
        const { driver } = rowData.row.original
        return (
          <span className="text-primary">{driver.name}</span>
        )
      }
    },
    {
      accessor: 'car.name',
      Header: 'Car name'
    },
    {
      accessor: 'confirmed',
      Header: 'Confirmed',
      Cell: rowData => {
        const { confirmed } = rowData.row.original
        return(
          <Form.Check 
            type='checkbox'
            checked={confirmed}
            className="text-center"
          />
        )
      }
    },
    {
      accessor: 'confirmed_date',
      Header: 'Confirmed date'
    },
    {
      accessor: 'confirmed_by',
      Header: 'Confirmed by'
    },
    {
      accessor: 'departure_begin_time',
      Header: 'Departure begin time',
      Cell: rowData => {
        const { departure_begin_time } = rowData.row.original
        return(
          formatDate(departure_begin_time)
        )
      }
    },
    {
      accessor: 'departure_end_time',
      Header: 'Departure end time ',
      Cell: rowData => {
        const { departure_end_time } = rowData.row.original
        return(
          formatDate(departure_end_time)
        )
      }
    },
    {
      accessor: 'total_hours',
      Header: 'Total hours',
      Cell: rowData => {
        const { total_hours } = rowData.row.original
        return (
          <span className="text-primary">{total_hours}</span>
        )
      }
    },
    {
      accessor: 'departure_begin_kilometer',
      Header: 'Departure from (Km)'
    },
    {
      accessor: 'departure_end_kilometer',
      Header: 'Departure end (Km)'
    },
    {
      accessor: 'total_kilometers',
      Header: 'Total (Km)',
      Cell: rowData => {
        const { total_kilometers } = rowData.row.original
        return (
          <span className="text-primary">{total_kilometers}</span>
        )
      }
    },
    {
      accessor: 'parking_fee',
      Header: 'Parking fee',
      Cell: rowData => {
        const { parking_fee } = rowData.row.original
        return (
          <span className="text-primary">{formatNumber(parking_fee, 'VND')}</span>
        )
      }
    },
    {
      accessor: 'notes',
      Header: 'Notes',
      Cell: rowData => {
        const { notes } = rowData.row.original
        return (
          <span className="text-danger">{notes}</span>
        )
      }
    },
    {
      accessor: 'closed',
      Header: 'Closed',
      Cell: rowData => {
        const { closed } = rowData.row.original
        return(
          <Form.Check 
            type='checkbox'
            checked={closed}
            className="text-center"
          />
        )
      }
    },
    {
      accessor: 'attchments',
      Header: 'Attchments',
      Cell: rowData => {
        const { images } = rowData.row.original;
        return (
          <ul>
            {images && images.map(image => (
              <li key={image.id} className="my-1"><Link to={image.url} target="_blank">{image.name}</Link></li>
            ))}
          </ul>
        )
      }
    },
    {
      accessor: 'created_by',
      Header: 'Assigned by'
    },
    {
      accessor: 'created_at',
      Header: 'Assigned date'
    }
  ], [])

  return (
    <div className="mb-4">
      <Row className="mb-3">
        <Col>
          <Card bg="info">
            <Card.Body className="text-white">
              <FontAwesomeIcon icon="business-time" className="me-1 fs-1"/>
              Total times: <span className="fs-1 fw-bold">{transaction.total_hours_booking} hours</span>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg="info">
            <Card.Body className="text-white">
              <FontAwesomeIcon icon="road" className="me-1 fs-1"/>
              Total kilomiters: <span className="fs-1 fw-bold">{transaction.total_hours_booking} km</span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
      
      </AdvanceTableWrapper>
    </div>
  )
};

TransactionDetailDriverTrans.propTypes = {
  transaction: PropTypes.object.isRequired
}

export default TransactionDetailDriverTrans;