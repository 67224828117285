import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from "react-icons/fa";

const TermsOfService = () => (
  <>
    <PageHeader
      title="Terms of Service"
      description="Welcome to Pebsteel Portal, These Terms of Service ('Terms') govern your access to and use of our platform. By using our service, you agree to comply with these Terms."
      className="mb-3"
    />

    <Card className="mb-3">
      <Card.Body>
        <p className="fs-0 mb-2 fw-semi-bold">Content: </p>

        <strong>1. Use of Service</strong>
        <p>
          Pebsteel Portal provides a platform that allows users to access specific services securely 
          using <strong>Google OAuth</strong> for authentication. You agree to use the service solely for its 
          intended purpose and in compliance with applicable laws.
        </p>

        <strong>2. User Accounts</strong>
        <p>
          To access Pebsteel Portal, you must authenticate via Google OAuth. This process verifies your 
          identity without requiring the storage of your personal information (e.g., email or profile details). 
          By authenticating, you agree that your access to the service is based solely on this verification.
        </p>

        <strong>3. No Data Collection</strong>
        <p>
          Pebsteel Portal does not collect, store, or share any of your personal information 
          retrieved during the authentication process. All authentication is handled securely 
          via Google's OAuth service.
        </p>

        <strong>4. User Responsibilities</strong>
        <p>
          You agree not to misuse the service. Misuse includes, but is not limited to:
          <ul>
            <li>Attempting to gain unauthorized access to the platform.</li>
            <li>Interfering with the proper functioning of the service.</li>
            <li>Using the service for any illegal or unauthorized purposes.</li>
          </ul>
        </p>

        <strong>5. Limitation of Liability</strong>
        <p>
          Pebsteel Portal is provided "as is" and "as available." While we strive to maintain uninterrupted 
          access, we make no warranties regarding the service's availability, accuracy, or fitness for a 
          particular purpose.
        </p>

        <strong>6. Changes to Terms</strong>
        <p>
          We reserve the right to update these Terms at any time. Continued use of the service 
          after changes are posted constitutes your acceptance of the updated Terms.
        </p>

        <strong>7. Contact Us</strong>
        <p>
          If you have any questions about this Privacy Policy or how we handle your information, 
          please contact us at: <a href="mailto:portal@pebsteel.com.vn">portal@pebsteel.com.vn</a>
        </p>

        <div className="text-center mt-4 mb-3">
          <Link to="/" className="btn btn-primary"><FaHome /> Back to Home</Link>
        </div>
      </Card.Body>
    </Card>
  </>
);

export default TermsOfService;
