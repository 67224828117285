import { Row, Col } from "react-bootstrap"
import WaiverHeader from "./WaiverHeader"
import GreetingCard from "./greeting/GreetingCard"
import React, { useEffect, useMemo } from "react"
import { getWaivers } from "redux/crm/report/waiverSlice"
import { useSelector, useDispatch } from "react-redux"
import SummaryWaiver from "./SummaryWaiver"
import WaiverSalesRegionV2 from "./WaiverSalesRegionV2"
import WaiverMonthlyV2 from "./WaiverMonthlyV2"

const WaiverDashboard = () => {
  const {data} = useSelector(state => state.crm_report_waiver)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWaivers(
      {
        start_date: '2023-01-01',
        end_date: '2023-12-31'
      }
    ))
  }, [])

  const groupedAndCounted = data.reduce((result, item) => {
    const key = `${item.status_id}_${item.status_name}`;
  
    if (!result[key]) {
      result[key] = {
        id: item.status_id,
        name: item.status_name,
        number_of_waivers: 1,
      };
    } else {
      result[key].number_of_waivers++;
    }
  
    return result;
  }, {});

  const summaryData = Object.values(groupedAndCounted)

  const statusNames = useMemo(() => (
   ['Submitted', 'Waiting for Approval', 'Approved', 'Rejected', 'Canceled']
  ), [])

  const salesUnitRegionNames = Array.from(new Set(data.map(item => item.sales_unit_name))).sort();

  const salesRegionData = data.reduce((result, item) => {
    const { status_name, sales_unit_name } = item;
    if (!result[status_name]) {
      result[status_name] = {};
    }

    if (!result[status_name][sales_unit_name]) {
      result[status_name][sales_unit_name] = 1;
    } else {
      result[status_name][sales_unit_name]++;
    }

    return result;
  }, {});

  
  return (
    <>
      <WaiverHeader />

      <Row className="g-3 mb-3">
        <Col md={7}>
          <GreetingCard data={data} />
        </Col>
        <Col md={5}>
          <SummaryWaiver data={summaryData} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <WaiverSalesRegionV2 data={salesRegionData} statusNames={statusNames} salesUnitRegionNames={salesUnitRegionNames} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col>
          <WaiverMonthlyV2 data={data} statusNames={["All", ...statusNames]} />
        </Col>
      </Row>
    </> 
  )
}

export default WaiverDashboard