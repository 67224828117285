import { useParams } from "react-router-dom";
import TransactionDetailHeader from "./TransactionDetailHeader";
import TransactionDetailInfo from "./TransactionDetailInfo";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBooking } from "redux/booking/car/carBookingSlice";
import { Card, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import BookingActivity from "./BookingActivity";

const TransactionDetail = () => {
  const {recordData: transaction} = useSelector((state) => state.booking_car);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getBooking(params.id))
  }, [params, dispatch]);

  return (
    <>
      <TransactionDetailHeader transaction={transaction} />

      <Card>
        <Tab.Container defaultActiveKey="transaction-detail">
          <Card.Header className="p-0 border-bottom">
            <Nav className="nav-tabs border-0 flex-nowrap tab-transaction-details">
              <Nav.Item>
                <Nav.Link
                  eventKey="transaction-detail"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="stream"
                    className="text-600 tab-icon"
                  />
                  <h6 className="text-600 mb-0 ms-1">Detail</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="transaction-activity-log"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="history"
                    className="text-600"
                  />
                  <h6 className="text-600 mb-0 ms-1">Activities history</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              <Tab.Pane eventKey="transaction-detail">
                <TransactionDetailInfo transaction={transaction} />
              </Tab.Pane>
              <Tab.Pane eventKey="transaction-activity-log">
                <BookingActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export default TransactionDetail;