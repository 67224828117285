import React from 'react';
import { useDispatch } from "react-redux"
import { Form, Modal, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { FaTrash } from "react-icons/fa"
import IconAlert from "components/common/IconAlert"
import { deleteForever } from "redux/drive/driveSlice"
import PropTypes from'prop-types';

const DriveDeleteForeverConfirm = ({showModal, setshowModal, asset }) => {
  const assetId = asset.id;
  const dispatch = useDispatch();

  const closeModal = () => {
    setshowModal(false);
  }

  const handleSubmit = () => {
    try {
      dispatch(deleteForever(assetId));
      closeModal();
    }
    catch(error) {
      toast.error(error.message);
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Delete forever confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Area you sure to delete forever {asset?.asset_type} <strong>{asset?.name}</strong>? 
          </IconAlert>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="warning"><FaTrash /> Delete forever</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

DriveDeleteForeverConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    asset_type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};


export default DriveDeleteForeverConfirm;