import React from 'react';
import Flex from "components/common/Flex";
import { Card, Row, Col, Form, Badge } from "react-bootstrap";
import { formatBookingDate, formatNumber } from "helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookingDetailTripForProject from "./BookingDetailTripForProject";
import ExpenseDetail from "./ExpenseDetail";
import BookingMultipleStopTrip from "./BookingMultipleStopTrip";
import { TRAVEL_ARRANGEMENT_BY_HR_ADMIN, TRAVEL_TYPE_OF_TRAVEL } from "app_config";
import { FaInfoCircle } from "react-icons/fa";
import PropTypes from 'prop-types';

const BookingDetailInfo = ({booking}) => {
  const {status} = booking;

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Flex justifyContent="between">
            <h5 className="mb-0"><FontAwesomeIcon icon="info-circle" /> Booking information</h5>
            <span>ID#: <strong className="text-primary">{booking?.booking_number}</strong></span>
          </Flex>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-3">
            <Col lg xxl={6}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Information
              </h6>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Trip name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{booking.trip_name}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Date of travel</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{formatBookingDate(booking.date_of_travel)}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Date of return</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{formatBookingDate(booking.date_of_return)}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Employees traveler</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">
                    {booking.employees && booking.employees.map(item => (
                      <Badge variant="primary" className="me-1" key={item.id}>{item.employee_name} ({item.title})</Badge>
                    ))}
                  </p>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Number of employees traveler</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{booking.employees && booking.employees.length || 0}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Number of days estimated</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{booking.number_of_days_estimate}</p>
                </Col>
              </Row>
            </Col>
            <Col lg className="mt-4 mt-lg-0">
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Destination
              </h6>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">From site office</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking.site_name}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Destination</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking.destination}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Trip to country</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking.country_name}</p>
                </Col>
              </Row>
              <Row className="mb-0">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Type of trip</p>
                </Col>
                <Col>
                  <Flex>
                    {TRAVEL_TYPE_OF_TRAVEL.map((item) => (
                      <Form.Check 
                        type="radio"
                        key={item}
                        name="type_of_travel"
                        className="me-3"
                        label={item}
                        checked={item === booking.type_of_travel}
                      />
                    ))}
                  </Flex>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Currency of trip</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{booking.currency}</p>
                </Col>
              </Row>
              {booking.currency === "USD" && (
                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Echange rate</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">1USD = {formatNumber(booking.exchange_rate)} VND</p>
                  </Col>
                </Row>
              )}
              {/* <Row className="mb-2 mt-4">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1"> Expected approval date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{formatBookingDate(booking.expected_approval)}</p>
                </Col>
              </Row> */}
              <Row className="mb-3">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Status</p>
                </Col>
                <Col>
                  <Badge bg={status?.css_class} className="fs--2">{status?.name}</Badge>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3 fs--1">
            <Col>
              <Row className="mb-2">
                <Col xs={2} sm={2}>
                  <p className="fw-semi-bold fs--1 mb-1">Arrangement by HR Admin</p>
                </Col>
                <Col>
                  <Row className="mx-4 border rounded p-2">
                    {TRAVEL_ARRANGEMENT_BY_HR_ADMIN.map((option, index) => (
                      <Col lg={4} sm={4} key={option}>
                        <Form.Check 
                          type='checkbox'
                          id={index}
                          label={option}
                          checked={booking.arrangement_by_hr_admin && booking.arrangement_by_hr_admin.includes(option)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col lg xxl={6}>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Purpose of trip</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{booking.purpose_of_travel}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold fs--1 mb-1">Description</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary" style={{whiteSpace: "pre-line"}}>{booking.description_purpose_of_travel}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-3">
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">CC emails</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking?.cc_emails_optional?.join(", ")}</p>
                  <div className="small">
                    <FaInfoCircle /> CC others for email tracking of the trip upon approval.
                  </div>
                </Col>
              </Row>
              
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Created
              </h6>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Submited by</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking.submited_by}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Submited date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking.submited_date}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Updated date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{booking.updated_date}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <BookingMultipleStopTrip booking={booking} />
      <BookingDetailTripForProject booking={booking} />
      <ExpenseDetail booking={booking} />
      
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0"><FontAwesomeIcon icon="comments" /> Notes</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-2">
            <Col xs={2} sm={2}>
              <p className="fw-semi-bold fs--1 mb-1">Comment for booking</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary" style={{whiteSpace: "pre-line"}}>{booking.comment_for_booking}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
};

BookingDetailInfo.propTypes = {
  booking: PropTypes.object.isRequired
}

export default BookingDetailInfo;