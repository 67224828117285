const { Tabs, Tab, Card, Form } = require("react-bootstrap")
import { FaGlobe, FaCalendar, FaInfoCircle } from 'react-icons/fa';
import React, { lazy, Suspense, useEffect, useState } from "react";
import FalconCardHeader from 'components/common/FalconCardHeader';
import TrafficSource from 'components/dashboards/analytics/traffic-source/TrafficSource';
import PropTypes from 'prop-types';

const LazyJobEnteredItem = lazy(() => import("./JobEnteredItem"));
const LazyJobDetail = lazy(() => import("./JobDetail"));

const BecameJob = ({data=[]}) => {
  const STATUS_NAME_FILTER = ["Signed contract (J)", "Prospective projects (P)", "Lost jobs (L)"]

  const [numberWeightFilter, setNumberWeightFilter] = useState(STATUS_NAME_FILTER[0]);
  const [dataObject, setDataObject] = useState([]);
  const [color, setColor] = useState("primary");

  useEffect(() => {
    let newData = [];

    switch(numberWeightFilter) {
      case STATUS_NAME_FILTER[0]:
        newData = filterData(1);
        setColor("success");

        break;
      case STATUS_NAME_FILTER[1]:
        newData = filterData(2);
        setColor("warning");

        break;
      case STATUS_NAME_FILTER[2]:
        newData = filterData(3);
        setColor("danger");

        break;
    }

    setDataObject(newData)
  }, [numberWeightFilter, data]);

  const filterData = (value) => {
    return data && data.filter(item => item.seq === value);
  }

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  //const COLOR = "success";
  const monthlyData = {};
  const salesRegionData = {};

  dataObject.forEach(project => {
    const { sales_unit_region, project_id, est_final_weight } = project;

    if (!salesRegionData[sales_unit_region]) {
      salesRegionData[sales_unit_region] = { number_of_projects: 0, weight: 0, projectIds: new Set() };
    }

    if (!salesRegionData[sales_unit_region].projectIds.has(project_id)) {
      salesRegionData[sales_unit_region].number_of_projects++;
      salesRegionData[sales_unit_region].projectIds.add(project_id);
    }

    salesRegionData[sales_unit_region].weight += parseFloat(est_final_weight) || 0;
  });
  
  dataObject.sort((a, b) => {
    const dateA = new Date(a.job_entry_date);
    const dateB = new Date(b.job_entry_date);
    
    return dateA - dateB;
  });

  dataObject.forEach(project => {
    const { job_entry_month, project_id, est_final_weight } = project;

    if (!monthlyData[job_entry_month]) {
      monthlyData[job_entry_month] = { number_of_projects: 0, weight: 0, projectIds: new Set() };
    }

    if (!monthlyData[job_entry_month].projectIds.has(project_id)) {
      monthlyData[job_entry_month].number_of_projects++;
      monthlyData[job_entry_month].projectIds.add(project_id);
    }

    monthlyData[job_entry_month].weight += parseFloat(est_final_weight) || 0;
  });

  return (
    <>
      <Card className="h-100 mb-3" id="became-job">
        <FalconCardHeader
          title={numberWeightFilter}
          titleTag="h5"
          className="py-2 text-primary"
          light
          endEl={
            <Form.Select
              size="sm"
              onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
              className="mx-1 fs--1"
            >
              {STATUS_NAME_FILTER.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Form.Select>
          }
        />
        <Card.Body>
          <p className="fs--1">Entered became jobs within the period.</p>

          <Tabs defaultActiveKey="sales-region" id="became-job-entry" transition={false}>
            <Tab eventKey="sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyJobEnteredItem data={salesRegionData} color={color} isSalesRegion={TrafficSource}/>
              </Suspense>
            </Tab>
            <Tab eventKey="became-job-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyJobEnteredItem data={monthlyData} color={color} />
              </Suspense>
            </Tab>
            <Tab eventKey="became-job-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyJobDetail data={data} />
              </Suspense>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

BecameJob.propTypes = {
  data: PropTypes.array.isRequired
};

export default BecameJob;