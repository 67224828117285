import { Card, Row, Col, Badge } from "react-bootstrap"
import RoleDetailInfoUser from "./user/RoleDetailInfoUser"
import RoleDetailInfoPermission from "./permission/RoleDetailInfoPermission"
import Flex from "components/common/Flex"
import { FaUser, FaUserShield } from "react-icons/fa";
import PropTypes from 'prop-types';
import React from "react";

const RoleDetailInfo = ({role}) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Flex justifyContent="between">
            <div className="mb-0">Name: <span className="text-primary fs-0">{role.name}</span> ID#{role.id}</div>
            <div className="mb-0 fs-0"><FaUser /> Users <Badge pill>{role.users ? role.users.length : 0}</Badge></div>
          </Flex>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <RoleDetailInfoUser />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <div className="mb-0 fs-0"><FaUserShield /> Permissions <Badge pill>{role.permissions ? role.permissions.length : 0}</Badge></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <RoleDetailInfoPermission />
        </Card.Body>
      </Card>
    </>
  )
};

RoleDetailInfo.propTypes = {
  role: PropTypes.object.isRequired
}


export default RoleDetailInfo;