import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Form } from 'react-bootstrap';
import { BY_NUMBER_WEIGHT_FILTER } from 'app_config';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from "prop-types";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);


const getOption = (data) => ({
  color: [
    rgbaColor(getColor('primary'), 0.3),
    getColor('info'),
    getColor('warning'),
    rgbaColor(getColor('danger'), 0.3),
    getColor('danger')
  ],
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   type: 'scroll',
  //   //orient: 'vertical',
  //   bottom: 'center',
  // },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        formatter: '{b}: {d}%'  //'{b}: {c} ({d}%)' 
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 14,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
});

const QuotationConclusionChart = ({data}) => {
  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_FILTER[0])
  const [dataObject, setDataObject] = useState([])

  const isWeight = () => {
    return numberWeightFilter === BY_NUMBER_WEIGHT_FILTER[0];
  }

  useEffect(() => {
    if (!data) return;

    const newData = Object.entries(data).map(([name, values]) => ({
      name,
      value: isWeight() ? values.weight : values.number_of_projects
    }));

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  return (
    <div className="mb-3 h-100 border">
      <FalconCardHeader
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select
            size="sm"
            onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
            className="mx-1 fs--1"
          >
            {BY_NUMBER_WEIGHT_FILTER.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Form.Select>
        }
      />
      <div className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(dataObject)}
          className="w-100"
        />
      </div>
    </div>
  )
}

QuotationConclusionChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default QuotationConclusionChart;