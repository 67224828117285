import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { getCarsBySite, getDriversBySite } from "redux/booking/car/carBookingSlice";
import Flex from "components/common/Flex";
import PropTypes from 'prop-types';

const TransactionDetailAssignDriverForm = React.memo(({index, driver, handleRemoveDriver, transaction, updateAssignDriver}) => {
  const { control, formState: {errors}, setValue } = useForm();
  const [drivers, setDrivers] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);

  const dispatch = useDispatch();
  const params = {
    from_date: transaction.from_date,
    to_date: transaction.to_date,
    site_id: transaction.site_id
  }

  useEffect(() => {
    fetchCarList();
    fetchDriverList();
  }, []);

  const fetchCarList = async () => {
    try {
      const response = await dispatch(getCarsBySite(params))
      const data = response.payload

      const options = data && data.map((item) => ({ value: item.id, label: item.name }))
      setCarOptions(options)
    }
    catch(error) {
      console.error(error);
      return [];
    }
  }

  const fetchDriverList = async () => {
    try {
      const response = await dispatch(getDriversBySite(params))
      const data = response.payload
      setDrivers(data)

      const options = data && data.map((item) => ({ value: item.id, label: item.name }))
      setDriverOptions(options)
    }
    catch(error) {
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    if (driver && driver.driver_id) {
      setValue('driver_id', driver.driver_id);
      setValue('car_id', driver.car_id);
    }
  }, [driver])

  const handleDriverChange = (value) => {
    const driver = drivers.find((item) => item.id === value);

    if (driver) {
      updateAssignDriver(index, driver)
    }
  }

  return (
    <>
      <Flex justifyContent="end" className="mx-3">
        <IconButton
          onClick={handleRemoveDriver}
          variant="falcon-default"
          size="sm"
          icon="trash"
          transform="shrink-3"
        >
        </IconButton>
      </Flex>
      <Form.Group as={Row} className="mb-3" controlId="driver_id">
        <Form.Label column sm={3} className="text-lg-end">
          Driver name
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name="driver_id"
            render={({field}) => (
              <Select
                closeMenuOnSelect={true}
                options={driverOptions}
                placeholder='Select...'
                classNamePrefix="react-select"
                {...field}
                value={driverOptions?.find(option => option.value === field.value)}
                onChange={selectedOption => handleDriverChange(selectedOption.value)}
              />
            )}
            rules={{
              required: 'Driver must be required'
            }}
          />
          <span className="text-danger">
            {errors.driver_id && errors.driver_id.message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="car_id">
        <Form.Label column sm={3} className="text-lg-end">
          Car name
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name="car_id"
            render={({field}) => (
              <Select
                closeMenuOnSelect={true}
                options={carOptions}
                placeholder='Select...'
                classNamePrefix="react-select"
                {...field}
                value={carOptions?.find(option => option.value === field.value)}
                onChange={selectedOption => { 
                  setValue('car_id', selectedOption.value)
                  console.log(selectedOption.value)
                }}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Car must be required'
              }
            }}
          />
          <span className="text-danger">
            {errors.car_id && errors.car_id.message}
          </span>
        </Col>
      </Form.Group>

      <div className="border-dashed border-bottom my-4"></div>
    </>
  )
});

TransactionDetailAssignDriverForm.propTypes = {
  index: PropTypes.number.isRequired,
  driver: PropTypes.object.isRequired,
  handleRemoveDriver: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  updateAssignDriver: PropTypes.func.isRequired
}

export default TransactionDetailAssignDriverForm;