import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

const competitorUrl = "/crm/competitors";

export const getAllCompetitors = createAsyncThunk(
  "Competitor/getAllCompetitors",
  async (params) => {
    try {
      const response = await apiAxios.get(`${competitorUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCompetitor = createAsyncThunk(
  "Competitor/getCompetitor",
  async (competitorId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${competitorUrl}/${competitorId}`)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createCompetitor = createAsyncThunk(
  "Competitor/createCompetitor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(competitorUrl, {competitor: data})
      const {error, attributes} = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return attributes
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCompetitor = createAsyncThunk(
  "Competitor/updateCompetitor",
  async ({ competitorId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${competitorUrl}/${competitorId}`, {competitor: data})
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteCompetitor = createAsyncThunk(
  "Competitor/deleteCompetitor",
  async (competitor) => {
    try {
      const response = await apiAxios.delete(`${competitorUrl}/${competitor.id}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const competitorSlice = createSlice({
  name: "competitor",
  initialState: {
    data: [],
    totalRows: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompetitors.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllCompetitors.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows
      })
      // .addCase(getCompetitor.fulfilled, (state, action) => {
      //   state.crm_competitorData = action.payload
      // })
      .addCase(createCompetitor.fulfilled, (state, action) => {
        const competitor = action.payload

        if (competitor !== null ) {
          state.data.unshift(competitor)
          toast.success(`Competitor have been created successfully.`)
        }
      })
      .addCase(updateCompetitor.fulfilled, (state, action) => {
        const competitor = action.payload
        state.crm_competitorData = competitor
        toast.success(`Competitor have been updated successfully.`)
      })
      .addCase(deleteCompetitor.fulfilled, (state, action) => {

        const { error, data, message } = action.payload
        if (error !== undefined) {
          toast.error(error)
        }
        else {
          const competitor = data.attributes

          if (competitor !== undefined) {
            state.data = state.data.filter((item) => item.id !== competitor.id)
            toast.success(message)
          }
        }
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

//export const {} = competitorSlice.actions

export default competitorSlice.reducer;