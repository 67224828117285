import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { calPaginatorRecord } from "helpers/paginator";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

const driveUrl = "/drive/assets"

export const getAssets = createAsyncThunk(
  "Drive/getAssets",
  async (params) => {
    try {
      const response = await apiAxios.get(`${driveUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

// export const getAsset = createAsyncThunk(
//   "Drive/getAsset",
//   async (assetId, { rejectWithValue }) => {
//     try {
//       const response = await apiAxios.get(`${driveUrl}/${assetId}`)
//       return response.data
//     }
//     catch(error) {
//       return rejectWithValue(error.response.data)
//     }
//   }
// )

export const createAsset = createAsyncThunk(
  "Drive/createAsset",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(driveUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateAsset = createAsyncThunk(
  "Drive/updateAsset",
  async ({ assetId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${driveUrl}/${assetId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteForever = createAsyncThunk(
  "Drive/deleteForever",
  async (assetId) => {
    try {
      const response = await apiAxios.delete(`${driveUrl}/${assetId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getTrashList = createAsyncThunk(
  "Drive/getTrashList",
  async (params) => {
    try {
      const response = await apiAxios.get(`${driveUrl}/trash`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const moveToTrashAsset = createAsyncThunk(
  "Drive/moveToTrashAsset",
  async (assetId) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/move_to_trash`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const restoreFromTrash = createAsyncThunk(
  "Drive/restoreFromTrash",
  async (assetId) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/restore_from_trash`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const copyAsset = createAsyncThunk(
  "Drive/copyAsset",
  async (assetId) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/copy`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const makePublicAsset = createAsyncThunk(
  "Drive/makePublicAsset",
  async (assetId) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/make_public`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const removePublicAsset = createAsyncThunk(
  "Drive/removePublicAsset",
  async (assetId) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/remove_public`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadAsset = createAsyncThunk(
  "Drive/uploadAsset",
  async ({assetId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const downloadAsset = createAsyncThunk(
  "Drive/downloadAsset",
  async ({assetId, fileName}) => {
    try {
      const response = await apiAxios.get(`${driveUrl}/${assetId}/download`, { responseType: "blob"});

      // Create a blob URL for the downloaded file
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set filename
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);

      return; // Return early to avoid unnecessary processing

    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getAncestors = createAsyncThunk(
  "Drive/getAncestors",
  async (assetId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${driveUrl}/${assetId}/ancestors`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const shareAsset = createAsyncThunk(
  "Drive/shareAsset",
  async ({assetId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/share`, data);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getVersions = createAsyncThunk(
  "Drive/getVersions",
  async (assetId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${driveUrl}/${assetId}/versions`);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getSharingList = createAsyncThunk(
  "Drive/getSharingList",
  async (assetId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${driveUrl}/${assetId}/sharing_list`);
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeOwner = createAsyncThunk(
  "Drive/removeOwner",
  async ({assetId, userId}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${driveUrl}/${assetId}/remove_owner`, {user_id: userId});
      return response.data
      
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const record = data.attributes
    
    if (record !== null) {
      state.assetData = record

      switch (type) {
        case "create": 
          state.data.unshift(record)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== record.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex((item) => item.id === record.id)
          if (updatedIndex !== -1) {
            state.data[updatedIndex] = record
          }

          break;
        }
      }
    }
    toast.success(message);
  }
};

const driveSlice = createSlice({
  name: "drive",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    currentParamsOriginal: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    filterTags: [],
    ancestorData: [],
    downloading: false,
    currentFolder: null
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    },
    setFilterTag: (state, action) => {
      state.filterTags = action.payload
    },
    resetFilterTag: (state) => {
      state.filterTags = []
    },
    setCurrentParamsOriginal: (state, action) => {
      state.currentParamsOriginal = action.payload
    },
    resetCurrentParamsOriginal: (state) => {
      state.currentParamsOriginal = {page: 1, per_page: 100}
    },
    resetAncestors: (state) => {
      state.ancestors = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.pending, (state) => {
        state.loading = true
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data      = objectSerializer(results.rows)
        state.totalRows = results.total_rows
        state.currentFolder = results.current_asset

        const paginate            = state.paginate
        const calRecords          = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount  = calRecords.totalPagesCount
        paginate.fromRecord       = calRecords.beginRecords
        paginate.toRecord         = calRecords.lastRecords
      })
      // .addCase(getAsset.fulfilled, (state, action) => {
      //   state.ancestorData = objectSerializer(action.payload)
      // })
      .addCase(createAsset.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteForever.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(getTrashList.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data      = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate            = state.paginate
        const calRecords          = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount  = calRecords.totalPagesCount
        paginate.fromRecord       = calRecords.beginRecords
        paginate.toRecord         = calRecords.lastRecords
      })
      .addCase(moveToTrashAsset.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(restoreFromTrash.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(copyAsset.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(makePublicAsset.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removePublicAsset.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeOwner.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(downloadAsset.pending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadAsset.fulfilled, (state) => {
        console.log("File downloaded successfully");
        state.downloading = false;
      })
      .addCase(uploadAsset.fulfilled, (state, action) => {
        const { error, data, message } = action.payload;

        if (error !== undefined) {
          toast.error(error);
        } else {
          const records = objectSerializer(data);

          if (records) {
            state.data = state.data.concat(records)
          }

          toast.success(message);
        }
      })
      .addCase(getAncestors.fulfilled, (state, action) => {
        state.ancestorData = objectSerializer(action.payload)
      })
      .addCase(shareAsset.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
  setCurrentParamsOriginal,
  resetCurrentParamsOriginal,
  resetAncestors
} = driveSlice.actions

export default driveSlice.reducer