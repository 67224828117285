import { Card, Tab, Nav, Col, Row } from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import DetailBDS from "./DetailBDS";
import React from 'react';
import PropTypes from 'prop-types';

const DetailAreas = ({data}) => {
  const {project, building, adaptation, areas} = data || {};

  return (
    <>
      <Card>
        <Tab.Container id="areaTabs" defaultActiveKey={`PIF-Area${areas && areas.length > 0 ? areas[0].id : ''}`}>
          <Row className="g-0">
            <Col sm={2}>
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav variant="pills" className="nav-tabs border-0 flex-nowrap tab-pif-form flex-column">
                    {areas && areas.map((area) => (
                      <Nav.Item key={area.id}>
                        <Nav.Link
                          eventKey={`PIF-Area${area.id}`}
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <h6 className="text-600 mb-0 ms-1">Area {area.id}</h6>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Card.Header>
              </SimpleBar>
            </Col>
            <Col sm={10}>
              <Card.Body className="bg-light">
                <Tab.Content>
                  {areas && areas.map((area) => (
                    <Tab.Pane key={area.id} eventKey={`PIF-Area${area.id}`}>
                      <DetailBDS
                        project={project}
                        building={building}
                        adaptation={adaptation}
                        area={area}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Card.Body>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
    </>
  )
};

DetailAreas.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.object,
    building: PropTypes.object,
    adaptation: PropTypes.object,
    areas: PropTypes.array
  }).isRequired
}

export default DetailAreas;