import { Card, Col, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { MdContactPhone, MdContacts, MdEmail, MdPhone } from 'react-icons/md';
import { formatPhoneNumberInternational } from 'helpers/phoneNumber';
import { FaFacebookMessenger } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { OPLA_CRM_CONTACT_URL } from 'app_config';
import { GrIntegration } from "react-icons/gr";

const ContactDetailInfo = ({ contact }) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-2">
                Contact ID#: {contact.id}
              </h5>
            </Col>
            <Col xs="auto">
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="border-top">
          <Row>
            <Col lg xxl={6}>
              <Row className="mb-2">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Contact name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{contact.contact_name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Salutation</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{contact.salutation}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">First name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{contact.first_name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Last name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{contact.last_name}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Position</p>
                </Col>
                <Col>
                  <a href={`tel:${contact.mobile}`} className="mb-1 fs--1 text-primary">{contact.position}</a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1"><MdEmail /> Email</p>
                </Col>
                <Col>
                  <a href = {`mailto: ${contact.email}`} className="mb-1 fs--1 text-primary">{contact.email}</a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1"><MdPhone /> Phone number</p>
                </Col>
                <Col>
                  <a href={`tel:${contact.mobile}`} className="mb-1 fs--1 text-primary">
                    {formatPhoneNumberInternational(contact.mobile) || contact.mobile}
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Department</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{contact.department}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Country</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{contact.country}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Details</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-5">
            <Col lg xxl={5}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Contact
              </h6>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1"><MdContacts /> Main contact</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.main_contact}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1"><MdContactPhone /> Secondary contacts</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">
                    {contact.secondary_contact && contact.secondary_contact.map((name, index) => (
                      <SoftBadge bg='info' key={index} className='me-2'>{name}</SoftBadge>
                    ))}
                  </p>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Other contact</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.other_contact}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Buying roles</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.buying_roles}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Personal page</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.personal_page}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1"><FaFacebookMessenger /> Messenger</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.messenger}</p>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Address name</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.address_name}</p>
                </Col>
              </Row>
            </Col>
            <Col lg className="mt-4 mt-lg-0">
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Company
              </h6>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Company</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">
                    <Link to={`/crm/customers/${contact?.company?.id}`} target="_blank">{contact.company?.name}</Link>
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Lead</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">
                    <Link to={`/crm/leads/${contact?.lead?.id}`} target="_blank">{contact.lead?.name}</Link>
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Sales unit</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.sales_unit?.name} - {contact.sales_unit?.description}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg xxl={5}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                <GrIntegration /> Opla CRM
              </h6>
              <Row className="mb-2">
                <Col xs={2} sm={2}>
                  <p className="fw-semi-bold mb-1 fs--1">Opla ID</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1"><Link to={`${OPLA_CRM_CONTACT_URL}/${contact?.opla_id}`} target="_blank">{contact?.opla_id}</Link></p>
                </Col>
              </Row>  
            </Col>
            <Col lg xxl={5}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                History
              </h6>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Created by</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.created_by}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Created date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.created_at}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Updated date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{contact.updated_at}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card> 
    </>
  );
};

ContactDetailInfo.propTypes = {
  contact: PropTypes.object.isRequired
}

export default ContactDetailInfo;
