import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deactiveEmployee, deleteEmployee } from 'redux/organization/employeeSlice';
import PropTypes from "prop-types";

const EmployeeDetailHeader = ({employee}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const navigate = useNavigate();
  const employeeId = employee?.id;
  const dispatch = useDispatch();

  const handleNewEmployee = () => {
    navigate("/organization/employees/new")
  }

  const handleEditEmployee = () => {
    navigate(`/organization/employees/${employeeId}/edit`, {state: employee})
  }

  const handleDeleteEmployee = () => {
    setShowConfirmationDelete(true)
  }

  const handleActiveEmployee =  async () => {
    dispatch(deactiveEmployee(employeeId))
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    dispatch(deleteEmployee(employeeId))
    handleEmployeeList()
  }

  const handleEmployeeList = () => {
    navigate("/organization/employees")
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewEmployee}
            >
              <span className="d-none d-xl-inline-block ms-1">New</span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="lock"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleActiveEmployee}
            >
              <span className="d-none d-xl-inline-block ms-1">
                {employee?.active ? 'Disable' : 'Active'} 
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleEmployeeList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleEditEmployee}>
                   <MdEdit /> Edit
                  </Dropdown.Item>

                  <Dropdown.Divider/>

                  <Dropdown.Item className="text-danger" onClick={handleDeleteEmployee}>
                    <MdDelete /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message="Are you sure to delete?"
      />
    </>
  );
};

EmployeeDetailHeader.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool
  })
};

export default EmployeeDetailHeader;
