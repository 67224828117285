import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Form } from 'react-bootstrap';
import { BY_NUMBER_WEIGHT_FILTER } from 'app_config';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const formatNumber = (params, isWeight) => {
  const value = params?.value|| 0;
  return isWeight ? `${value?.toLocaleString()} M.T` : value?.toLocaleString();
}

formatNumber.propTypes = {
  params: PropTypes.object.isRequired,
  isWeight: PropTypes.bool.isRequired
}

const getOption = (data, monthName, isWeight, color) => ({
  color: [
    getColor(color)
  ],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      magicType: { show: true, type: ['line', 'bar'] },
      saveAsImage: { show: true }
    }
  },
  xAxis: {
    type: 'category',
    axisPointer: {
      type: 'shadow'
    },
    axisLabel: {
      color: getColor('gray-400'),
      rotate: 30,
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'line'
      }
    },
    data: monthName,
  },
  yAxis: 
  {
    type: 'value'
  },
  series: [
    {
      type: 'bar',
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: "top",
        color: getColor("gray-500"),
        formatter: (params) => formatNumber(params, isWeight)
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 14,
          fontWeight: 'bold',
          color: getColor("primary")
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
});

getOption.propTypes = {
  data: PropTypes.array.isRequired,
  monthName: PropTypes.string.isRequired,
  isWeight: PropTypes.bool.isRequired,
  color: PropTypes.string
}


const EntryChart = ({data, color="primary", isSalesRegion=false, defaultWeight}) => {
  const [numberWeightFilter, setNumberWeightFilter] = useState(defaultWeight ? BY_NUMBER_WEIGHT_FILTER[0] : BY_NUMBER_WEIGHT_FILTER[1])
  const [dataObject, setDataObject] = useState([])

  const isWeight = () => {
    return numberWeightFilter === BY_NUMBER_WEIGHT_FILTER[0]
  }

  useEffect(() => {
    if (!data) return;

    const newData = Object.entries(data).map(([name, values]) => ({
      name,
      value: isWeight() ? values.weight : values.number_of_projects
    }));

    isSalesRegion ? newData.sort((a, b) => b.value - a.value) : newData;

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  const monthName = dataObject && dataObject.map(item => item.name)

  return (
    <div className="h-100 border rounded">
      <FalconCardHeader
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select
            size="sm"
            onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
            className="mx-1 fs--1"
          >
            {BY_NUMBER_WEIGHT_FILTER.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Form.Select>
        }
      />
      <div className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(dataObject, monthName, isWeight(), color)}
          className="w-100"
        />
      </div>
    </div>
  )
}

EntryChart.propTypes = {
  data: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  isSalesRegion: PropTypes.bool.isRequired,
  defaultWeight: PropTypes.number
}

export default EntryChart;