import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import useAuth from 'hooks/useAuth';
import React, { Suspense, lazy, useState } from 'react';
import { Card} from 'react-bootstrap';
import Notification from './Notification';
import PropTypes from 'prop-types';

const LazyWaiverDetailView = lazy(() => import("./WaiverDetailView"));

const GreetingCard = ({data }) => {
  const {userName} = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [title, setTitle] = useState(null);

  const waitingForApprovalData = data && data.filter(item => item.status_id === 2);
  const waiverSalesEntriedData = data.filter((item) => item.sales_entry_date !== null);

  const currentDate = (new Date()).toISOString().split('T')[0];
  const waiverApprovedData = data.filter((item) => item.status_id === 3);
  const upcommingData = waiverApprovedData.filter((item) => item.closed === false && item.commitment_date >= currentDate);
  const overDueData = waiverApprovedData.filter((item) => item.has_overdue === true);

  const notifications = [
    {
      id: 1,
      title: "Waiting for approval",
      type: "warning",
      waiver_type: "waiting",
      number_of_waivers: waitingForApprovalData.length
    },
    {
      id: 2,
      title: "Upcoming clear waiver",
      type: "info",
      waiver_type: "upcomming",
      number_of_waivers: upcommingData.length
    },
    {
      id: 3,
      title: "Overdue clear waiver",
      type: "danger",
      waiver_type: "overdue",
      number_of_waivers: overDueData.length
    },
  ]

  const handleViewDetail = (title, type) => {
    setShowModal(true);
    setTitle(title);

    switch(type) {
      case "waiting":
        setDetailData(waitingForApprovalData);
        break;
      case "upcomming":
        setDetailData(upcommingData);
        break;
      case "overdue":
        setDetailData(overDueData);
        break;
    }
  };

  return (
    <>
      <Card className="bg-transparent-50 overflow-hidden">
        <Card.Header className="position-relative">
          <Background
            image={ecomBg}
            className="d-none d-md-block bg-card z-index-1"
            style={{
              backgroundSize: '230px',
              backgroundPosition: 'right bottom',
              zIndex: '-1'
            }}
          />
          <div className="position-relative z-index-2">
            <div>
              <h3 className="text-primary mb-1">Good Afternoon, {userName}!</h3>
              <p className="text-700">
                Here’s what happening waiver of this month
              </p>
            </div>
            <Flex className="py-3">
              <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">Total of waivers created</p>
                <h4 className="text-800 mb-0">{data.length}</h4>
              </div>
              <div className="ps-3">
                <p className="text-600 fs--1">Total of waivers sales entried</p>
                <h4 className="text-800 mb-0 text-warning">{waiverSalesEntriedData.length}</h4>
              </div>
            </Flex>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <ul className="mb-0 list-unstyled">
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                viewDetail={handleViewDetail}
              />
            ))}
          </ul>
        </Card.Body>
      </Card>

      <Suspense fallback={<div>Loading...</div>}>
        {showModal && (
          <LazyWaiverDetailView
            showModal={showModal}
            setshowModal={setShowModal}
            data={detailData}
            title={title}
          />
        )}
      </Suspense>
    </>
  );
};

GreetingCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status_id: PropTypes.number.isRequired,
      sales_entry_date: PropTypes.string,
      closed: PropTypes.bool,
      commitment_date: PropTypes.string,
      has_overdue: PropTypes.bool
    })
  ).isRequired
};

export default GreetingCard;
