import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/authSlice';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    handleLogout()
  }, [])

  const handleLogout = async () => {
    try{
      const token = localStorage.getItem('access_token');
      if (token !== null)
      {
        dispatch(logout())
      }
    }
    catch(err){
      console.log(err.message)
    }
  }

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={logoutImg}
        alt="shield"
        width={100}
      />
      <TitleTag>See you again!</TitleTag>
      <p>
        Thanks for using <strong>Portal</strong>. You are <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`${process.env.REACT_APP_CLIENT_LOGIN_URL}`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'simple',
  titleTag: 'h4'
};

export default LogoutContent;
