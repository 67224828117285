import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { cloneRole } from 'redux/authorization/roleSlice';
import IconAlert from 'components/common/IconAlert';
import { useNavigate } from 'react-router-dom';
import { FaBan, FaClone } from 'react-icons/fa';
import React from "react";
import PropTypes from 'prop-types';

const RoleDetailClone = ({showModal, setshowModal, role}) => {
  const { register, handleSubmit, formState: {errors} } = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (role) {
        const roleId = role.id
        const response = await dispatch(cloneRole({sourceRoleId: roleId, data}))
        const newRoleId = response.payload.data.id

        toast.success(`Role ${data.name} has been cloned successful.`)
        closeModal()

        //navigate RoleDetail
        navigate(`/authorization/roles/${newRoleId}`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static' keyboard={false}
        className='fs--1'
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className='bg-light border-0'>
            <Modal.Title className='fs-0' id='contained-modal-title-vcenter'><FaClone /> Clone new Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant='info'>
              <p className='mb-0'>All permissions of role <strong>{role.name}</strong> will be copied to destination role !</p>
            </IconAlert>

            <Form.Group as={Row} className='mb-3' controlId='source-role'>
              <Form.Label column sm={3} className='text-lg-end'>
                Role name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as='textarea'
                  name='name'
                  placeholder='Role name'
                  autoComplete='role-name'
                  className='fs--1'
                  {...register('name', {
                    required: 'Name must be required'
                  })}
                />
                <span className='text-danger'>
                  {errors.name && errors.name.message}
                </span>
              </Col>
            </Form.Group>
            
          </Modal.Body>
          <Modal.Footer className='bg-light border-0'>
            <Button size='sm' type='submit'><FaClone /> Clone</Button>
            <Button size='sm' variant='danger' onClick={handleCancel}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

RoleDetailClone.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired
}


export default RoleDetailClone;