
import React, { useCallback, useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {FormControl, ListGroup, Spinner} from "react-bootstrap"
import { MdPlace } from "react-icons/md";
import { debounce } from "helpers/debounce";
import PropTypes from 'prop-types'

const GoogleMapAutoCompletePlaceDebounce = ({ setAddress, defaultAddress, isRequired=false }) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [inputValue, setInputValue] = useState(defaultAddress || '');
  const [selectedItem, setSelectedItem] = useState(null);
  const [shouldFetchPredictions, setShouldFetchPredictions] = useState(false);

  const debouncedGetPlacePredictions = useCallback(
    debounce((input) => {
      getPlacePredictions({ input });
    }, 500),
    []
  );

  useEffect(() => {
    if (defaultAddress) {
      setInputValue(defaultAddress);
      setShouldFetchPredictions(false);
    }
  }, [defaultAddress]);

  useEffect(() => {
    if (shouldFetchPredictions && inputValue && !selectedItem) {
      debouncedGetPlacePredictions(inputValue);
    }
  }, [inputValue, selectedItem, shouldFetchPredictions, debouncedGetPlacePredictions]);

  const handleInputChange = (evt) => {
    setInputValue(evt.target.value);
    setSelectedItem(null);
    setShouldFetchPredictions(true);
  };

  const handleSelected = (item) => {
    console.log(item); 

    setSelectedItem(item);
    setInputValue(item.description);
    setShouldFetchPredictions(false);

    if (placesService) {
      placesService.getDetails(
        {
          placeId: item.place_id,
          fields: ['address_components']
        },
        (placeDetails) => {
          const addressComponents = placeDetails?.address_components;
          if (addressComponents) {
            const address = {};
            addressComponents.forEach(component => {
              const types = component.types;
              console.log(component);
              console.log(types);

              address.description = item.description;//full address

              if (types.includes('street_number')) {
                address.street_number = component.long_name;
              }
              else if (types.includes('route')) {
                address.route = component.long_name;
              }
              else if (types.includes('sublocality_level_1')) { //ward
                address.ward = component.long_name;
              } else if (types.includes('administrative_area_level_2') || types.includes('locality')) { //ward/district
                address.district = component.long_name;
              }
              else if (types.includes('administrative_area_level_1')) { //city/province
                address.city_province = component.long_name;
              } else if (types.includes('country')) {
                //address.country = component.long_name;
                address.country = component.short_name;
              }
            });
            console.log('Additional address details:', address);
            setAddress(address); 
          }
        }
      );
    }
  };

  return (
    <>
      <FormControl
        placeholder="Enter an address"
        className="fs--1"
        autoComplete="new-address"
        value={inputValue}
        onChange={handleInputChange}
        isInvalid={isPlacePredictionsLoading}
        required={isRequired}
      />
      {isPlacePredictionsLoading && <Spinner animation="border" />}
      <ListGroup>
        {!selectedItem && placePredictions.map(item => (
          <ListGroup.Item
            key={item.place_id}
            className="cursor-pointer"
            onClick={() => handleSelected(item)}
          >
            <MdPlace /> {item.description}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

GoogleMapAutoCompletePlaceDebounce.propTypes = {
  setAddress: PropTypes.string,
  defaultAddress: PropTypes.string
};

export default GoogleMapAutoCompletePlaceDebounce;