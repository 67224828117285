import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types'
import { formatDate } from 'helpers/utils';
import { MdOutlineUpdate } from "react-icons/md";

const EmployeeDetailInfo = ({ employee }) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-2">
                Employee ID#: {employee.id}
              </h5>
              <div>
                {
                  employee.active ?
                  (
                    <SoftBadge pill bg="success" className="fs--2">
                      {' '}
                      Activated
                      <FontAwesomeIcon
                        icon="check"
                        className="ms-1"
                        transform="shrink-2"
                      />
                    </SoftBadge>
                  )
                  :
                  (
                    <SoftBadge pill bg="danger" className="fs--2">
                      {' '}
                      Not active
                      <FontAwesomeIcon
                        icon="exclamation"
                        className="ms-1"
                        transform="shrink-2"
                      />
                    </SoftBadge>
                  )
                }
              </div>
            </Col>
            <Col xs="auto">
              <Flex direction={'column'} className="fs--2">
                <div>Created by: <span className="fw-semi-bold text-primary mr-1"> {employee.created_by}</span></div>
                <div> Date: <span className="fw-semi-bold text-primary"> {employee.created_at}</span></div>
              </Flex>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="border-top fs--1">
          <Row>
            <Col lg xxl={6}>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold mb-1">Name</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary">{employee.name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Full of name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{employee.full_name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Title</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{employee.title}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Email</p>
                </Col>
                <Col>
                  <a href = {`mailto: ${employee.email}`} className="mb-1 fs--1 text-primary">{employee.email}</a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Gender</p>
                </Col>
                <Col>
                  <Flex direction={'row'} className="mt-2">
                    <Form.Check 
                      type='radio'
                      id='gender-male'
                      label='Male'
                      name='gender'
                      checked={!employee.gender}
                      className="me-3"
                    />
                    <Form.Check 
                      type='radio'
                      id='gender-female'
                      label='Female'
                      name='gender'
                      checked={employee.gender}
                    />
                  </Flex>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Phone number</p>
                </Col>
                <Col>
                  <a href={`tel:${employee.phone_number}`} className="mb-1 fs--1 text-primary">{employee.phone_number}</a>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Manager</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{employee.parent?.name}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Sales team</p>
                </Col>
                <Col>
                  <Form.Check 
                    type='switch'
                    id='employee-sales-team'
                    checked={employee.is_sales}
                  />
                </Col>
              </Row>

              <Row className="my-2 mt-4">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold">Active</p>
                </Col>
                <Col>
                  <Form.Check 
                    type='switch'
                    id='employee-active'
                    checked={employee.active}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Details</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-5">
            <Col lg xxl={5}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Department
              </h6>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Department</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{employee.department?.name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Site office</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{employee.site?.name}</p>
                </Col>
              </Row>
            </Col>
            <Col lg className="mt-4 mt-lg-0">
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                HR
              </h6>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Start date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{formatDate(employee.start_date)}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">HR code</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{employee.hr_code}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Birthday</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{employee.birthday}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Staff policy</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{employee.staff_policy}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Position group</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{employee.position_group?.name}</p>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col xs={5} sm={6}>
                  <p className="fw-semi-bold mb-1 fs--1">Updated at</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1"><MdOutlineUpdate /> {employee.updated_at}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

EmployeeDetailInfo.propTypes = {
  employee: PropTypes.object
}

export default EmployeeDetailInfo;
