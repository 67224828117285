import Flex from "components/common/Flex";
import { Tab, Nav } from "react-bootstrap";
import DetailBDS3 from "./DetailBDS3";
import DetailBDS2 from "./DetailBDS2";
import DetailBDS1 from "./DetailBDS1";
import React from "react";
import PropTypes from 'prop-types';

const DetailBDS = ({project, building, adaptation, area}) => {

  return (
    <>
      <Flex className="mb-2 fs--1" justifyContent="between" alignItems="center">
        <div className="text-primary fw-semi-bold">Area {area?.id}</div>
      </Flex>

      <Tab.Container defaultActiveKey="PIF-BDS1">
        <div className="p-0 border card-header">
          <Nav className="nav-tabs border-0 flex-nowrap tab-pif-form">
            <Nav.Item>
              <Nav.Link
                eventKey="PIF-BDS1"
                className="d-flex align-items-center py-3 px-x1 mb-0"
              >
                <h6 className="text-600 mb-0 ms-1"> BDS1</h6>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="PIF-BDS2"
                className="d-flex align-items-center py-3 px-x1 mb-0"
              >
                <h6 className="text-600 mb-0 ms-1"> BDS2</h6>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="PIF-BDS3"
                className="d-flex align-items-center py-3 px-x1 mb-0"
              >
                <h6 className="text-600 mb-0 ms-1"> BDS3</h6>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="bg-light fs--1">
          <Tab.Content>
            <Tab.Pane eventKey="PIF-BDS1">
              <DetailBDS1
                project={project}
                building={building}
                adaptation={adaptation}
                area={area}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="PIF-BDS2">
              <DetailBDS2
                project={project}
                building={building}
                adaptation={adaptation}
                area={area}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="PIF-BDS3">
              <DetailBDS3
                project={project}
                building={building}
                adaptation={adaptation}
                area={area}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  )
};

DetailBDS.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired
}

export default DetailBDS;