import IconButton from 'components/common/IconButton';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const ContactHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          {/* <IconButton
            variant="falcon-default"
            size="sm"
            icon="address-card"
            transform="shrink-2"
            iconAlign="middle"
            className="me-2"
            onClick={handleNewContact}
          >
            <span className="d-none d-xl-inline-block ms-1">New Contact</span>
          </IconButton> */}
        </Card.Header>
      </Card>
    </>
  );
};

export default ContactHeader;
