import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiAxios from "helpers/apiAxios"
import { toast } from "react-toastify"
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from 'helpers/reducerHelpers';

const salesUnitUrl = "/crm/sales_units"

export const getAllSalesUnits = createAsyncThunk(
  "SalesUnit/getAllSalesUnits",
  async (params) => {
    try {
      const response = await apiAxios.get(`${salesUnitUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getSalesUnit = createAsyncThunk(
  "SalesUnit/getSalesUnit",
  async (salesUnitId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${salesUnitUrl}/${salesUnitId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createSalesUnit = createAsyncThunk(
  "SalesUnit/createSalesUnit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(salesUnitUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateSalesUnit = createAsyncThunk(
  "SalesUnit/updateSalesUnit",
  async ({ salesUnitId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${salesUnitUrl}/${salesUnitId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteSalesUnit = createAsyncThunk(
  "SalesUnit/deleteSalesUnit",
  async (salesUnitId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.delete(`${salesUnitUrl}/${salesUnitId}`);
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getActiveSalesUnits = createAsyncThunk(
  "SalesUnit/getActiveSalesUnits",
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${salesUnitUrl}/active_list`, { params } )
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deactivateSalesUnit = createAsyncThunk(
  "SalesUnit/deactivateSalesUnit",
  async (salesUnitId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${salesUnitUrl}/${salesUnitId}/deactivate`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)


export const exportSalesUnits = createAsyncThunk(
  "SalesUnit/exportSalesUnits",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${salesUnitUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sales-units.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

export const salesUnitSlice = createSlice({
  name: "crm_sales_unit",
  initialState: {
    ...initialState,
    activeSalesUnitsData: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSalesUnits.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))

      .addCase(getActiveSalesUnits.fulfilled, (state, action) => {
        state.activeSalesUnitsData = action.payload
      })
      .addCase(getSalesUnit.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(createSalesUnit.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateSalesUnit.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deleteSalesUnit.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(deactivateSalesUnit.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(exportSalesUnits.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportSalesUnits.fulfilled, (state) => {
        state.exporting = false
        toast.success("Sales unit has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action.error.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = salesUnitSlice.actions;

export default salesUnitSlice.reducer