import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { remindApprovalBooking } from 'redux/booking/travel/travelBookingSlice';
import IconAlert from 'components/common/IconAlert';
import { LuAlarmClock } from "react-icons/lu";

const BookingRemindConfirmation = ({showModal, setshowModal, booking}) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    try {
      dispatch(remindApprovalBooking(booking.id))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
}

  const closeModal = () => {
    setshowModal(false)
  }

  return (
  <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="small"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="border-0 bg-light">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><LuAlarmClock /> Reminder travel booking approval!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="info">
              <p className="mb-0">Are you sure to remind travel booking <strong>{booking.booking_number}</strong> approval?</p>
            </IconAlert>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit" variant="primary">Yes</Button>
            <Button size="sm" variant="secondary" onClick={closeModal}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

BookingRemindConfirmation.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  booking: PropTypes.object
}


export default BookingRemindConfirmation