import React from 'react';
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from "components/common/Flex";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadHotelDocument } from "redux/booking/travel/travelBookingSlice";
import PropTypes from 'prop-types';

const HotelUploadAttachmentDirectly = ({booking, hotel}) => {
  const dispatch = useDispatch()
  const bookingId = booking.id
  const hotelId = hotel.id

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles)

    try {
      const data = {
        files: acceptedFiles
      }
      
      dispatch(uploadHotelDocument({bookingId, hotelId, data}))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: ["image/*", "application/pdf"],
    multiple: true
  })

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone-area py-6' })}>
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">Drop your files here</p>
        </Flex>
      </div>
    </div>
  )
};

HotelUploadAttachmentDirectly.propTypes = {
  booking: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired
}

export default HotelUploadAttachmentDirectly;