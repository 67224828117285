import { formatNumber } from "helpers/utils";
import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import QuotationConclusionChart from "./QuotationConclusionChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdSummarize } from "react-icons/md";
import React from 'react';
import PropTypes from "prop-types";

const QuotationConclusion = ({data={}}) => {
  const { totalProjects, totalWeight } = Object.keys(data).length > 0 && Object.values(data).reduce((acc, { number_of_projects, weight }) => {
    acc.totalProjects += number_of_projects;
    acc.totalWeight += weight;
    return acc;
  }, { totalProjects: 0, totalWeight: 0 });


  return (
    <Card className="h-100 mb-3">
      <Card.Header className="bg-light h5">
        <MdSummarize /> Quotation Summary
      </Card.Header>
      <Card.Body>
        <Row className="g-3">
          <Col>
            <QuotationConclusionChart data={data} />
          </Col>
          <Col>
            <h6>
              Quotation Summary Conclusion
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ position: 'fixed' }}>
                    Negotiation (P), Won (J), and Lost job (L) are created from quotations of projects in the year. Quotation projects are filtered by the entered date from Sales. However, lost quotations are filtered by the actual date of loss. This means that the total of the quotation summary will not include lost quotations..
                  </Tooltip>
                }
              >
                <span>
                  <FontAwesomeIcon
                    icon="info-circle"
                    transform="shrink-1"
                    className="ms-1 text-400"
                  />
                </span>
              </OverlayTrigger>
            </h6>
            <table className="border fs--1">
              <thead className="bg-light">
                <tr>
                  <th className="border px-3">No.</th>
                  <th className="border px-3">Name</th>
                  <th className="border px-3">Number of projects</th>
                  <th className="border px-3">Weight (M.T)</th>
                  <th className="border px-3">% by weight</th>
                </tr>
              </thead>
              <tbody>
                {data && Object.entries(data).map(([name, values], index) => (
                  <tr key={index}>
                    <td className="text-center border px-3">{index + 1}</td>
                    <td className="text-start border px-3">{name}</td>
                    <td className="text-end border px-3">{values.number_of_projects}</td>
                    <td className="text-end border px-3">{formatNumber(values.weight)}</td>
                    <td className="text-end border px-3">{totalWeight > 0 ? ((values.weight / totalWeight) * 100)?.toFixed(0) : 0}%</td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="bg-light text-primary fw-bold">
                <tr>
                  <td colSpan="2" className="text-center border px-3">Total</td>
                  <td className="text-end border px-3">{totalProjects}</td>
                  <td className="text-end border px-3">{formatNumber(totalWeight)}</td>
                  <td className="text-end border px-3">100%</td>
                </tr>
              </tfoot>
            </table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
};

QuotationConclusion.propTypes ={
  data: PropTypes.object.isRequired
}

export default QuotationConclusion;