import React, { useState } from "react"
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import DepartmentList from "./department/DepartmentList"
import DepartmentHeader from "./department/DepartmentHeader"
import { useSelector } from "react-redux"
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Departments = () => {
  const [departmentsSelected, setDepartmentsSelected] = useState([])
  const { loading} = useSelector((state) => state.authorization_permission)


  return (
    <>
      <DepartmentHeader departmentsSelected={departmentsSelected} />
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="employeelist">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="employeelist"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Deaprment list</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="employeelist">
                    <DepartmentList setDepartmentsSelected={setDepartmentsSelected} /> 
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Departments