import SalesUnitForm from "./SalesUnitForm";
import { useLocation } from "react-router-dom";
import React from 'react';

const EditSalesUnit = () => {
  const location = useLocation();

  return (
    <SalesUnitForm
      title="Edit Sales Unit"
      salesUnit={location.state} />
  )
}

export default EditSalesUnit;