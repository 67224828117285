import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiAxios from 'helpers/apiAxios';
import { toast } from 'react-toastify';

export const getProfileUser = createAsyncThunk(
  "Profile/getProfileUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get("/profile");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Network Error");
    }
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected');
}

const profileUserSlice = createSlice({
  name: "profile_user",
  initialState: {
    data: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileUser.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.data = data.attributes;
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message);
        }
      );
  }
})

export default profileUserSlice.reducer;