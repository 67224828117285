import React from 'react';
import { FaFolder, FaFile, FaUserCircle, FaTrashAlt } from "react-icons/fa";
import { MdRestore } from "react-icons/md";
import {Button} from "react-bootstrap";
import PropTypes from'prop-types';

const DriveTrashItem = ({item, handleRestore, handleDelete}) => {
  const isFolder = item.asset_type === "folder";
  const folderComponent = () => isFolder ? <FaFolder size="20" /> : <FaFile size="20" /> ;

  const { 
    allow_delete,
    allow_restore_from_trash
  } = item?.permissions || {};
  
  return (
    <tr key={item?.id} className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
      <td className="text-wrap w-50">
        <span className="me-3">{folderComponent(item)}</span> {item?.name}
      </td>
      <td><FaUserCircle size="15" /> {item?.trash_by}</td>
      <td>{item?.trash_date}</td>
      <td>
        {allow_restore_from_trash && (
          <Button size="sm" variant="falcon-default" className="fs--1" onClick={handleRestore} title="Restore">
           <MdRestore />
          </Button>
        )}
       
        {allow_delete && (
          <Button size="sm" variant="falcon-default" className="fs--1 ms-1" onClick={handleDelete} title="Delete">
            <FaTrashAlt />
          </Button>
        )}
      </td>
    </tr>
  );
};

DriveTrashItem.propTypes = {
  item: PropTypes.shape({
    asset_type: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
      allow_delete: PropTypes.bool,
      allow_restore_from_trash: PropTypes.bool
    }),
    id: PropTypes.string.isRequired, // or PropTypes.number if item.id is a number
    name: PropTypes.string.isRequired,
    trash_by: PropTypes.string,
    trash_date: PropTypes.string
  }).isRequired,
  handleRestore: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DriveTrashItem;