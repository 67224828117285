import React from 'react';
import { Form, Modal, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { FaClone } from "react-icons/fa"
import PropTypes from'prop-types';

const DriveGetLinkConfirm = ({showModal, setshowModal, asset }) => {
  const currentUrl = window.location.href;

  const closeModal = () => {
    setshowModal(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    //processing copy function
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success("Link copied to clipboard")
    }).catch((error) => {
      toast.error("Failed to copy link: " + error.message);
    });

    closeModal();
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Get link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border fs--1 p-4 text-break text-primary">
            {asset?.asset_type === "file" ? asset?.preview_url : currentUrl}
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="warning"><FaClone /> Copy</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

DriveGetLinkConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    asset_type: PropTypes.string,
    name: PropTypes.string,
    preview_url: PropTypes.string
  }).isRequired,
};

export default DriveGetLinkConfirm;