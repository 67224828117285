import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
//import WorldMap from 'components/dashboards/analytics/users-by-country/WorldMap';
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import TableItem from './TableItem';
import { BY_NUMBER_WEIGHT_REVENUE_FILTER } from 'app_config';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { MapChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import world from 'assets/json/world.json';
import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  MapChart,
  CanvasRenderer,
  ToolboxComponent,
  LegendComponent,
  VisualMapComponent
]);

echarts.registerMap('world', { geoJSON: world });

const formatComsume = (value, numberType) => {
  let result = null;

  switch(numberType) {
    case "number":
      result = value?.toLocaleString();
      break;
    case "weight":
      result = `${value?.toLocaleString()} M.T`;
      break;
    case "revenue":
      result = `$${value?.toLocaleString()}`;
      break;
  }

  return result
}

const getOptions = (sumOfValues, data, numberType, maxZoomLevel, minZoomLevel) => ({
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: params => {
      return (
        params.data ?  `<strong>${params.data?.name} :</strong> ${(
          (params.data?.value / sumOfValues) *
          100
        ).toFixed(2)}% (${formatComsume(params.data?.value, numberType)})` : ''
      )
    }
  },
  toolbox: {
    show: false,
    feature: {
      restore: {}
    }
  },
  visualMap: {
    show: false,
    min: 800,
    max: 50000,
    inRange: {
      color: [
        getColor('primary'),
        rgbaColor(getColor('primary'), 0.9),
        rgbaColor(getColor('primary'), 0.7)
      ].reverse()
    }
  },
  series: [
    {
      type: 'map',
      map: 'world',
      data,
      roam: true,
      scaleLimit: {
        min: minZoomLevel,
        max: maxZoomLevel
      },
      left: 0,
      right: 0,
      label: {
        show: false
      },
      itemStyle: {
        borderColor: getColor('gray-300')
      },
      emphasis: {
        label: {
          show: true
        },
        itemStyle: {
          areaColor: getColor('warning')
        }
      }
    }
  ]
});

getOptions.propTypes = {
  sumOfValues: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  numberType: PropTypes.string.isRequired,
  maxZoomLevel: PropTypes.number.isRequired,
  minZoomLevel: PropTypes.number.isRequired
}

const WorldMap = forwardRef(
  ({ data, numberType, style, minZoomLevel = 1, maxZoomLevel = 5 }, ref) => {
    const sumOfValues = data && data.reduce((total, item) => total + item.value, 0);

    return (
      <ReactEChartsCore
        ref={ref}
        echarts={echarts}
        option={getOptions(sumOfValues, data, numberType, maxZoomLevel, minZoomLevel)}
        style={style}
      />
    );
  }
);

const ContractCountry = ({data}) => {
  const chartRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [maxZoomLevel] = useState(5);
  const [minZoomLevel] = useState(1);

  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_REVENUE_FILTER[0])
  const [dataObject, setDataObject] = useState([])
  const [numberType, setNumberType] = useState("weight")

  useEffect(() => {
    if (!data) return;

    const newData = Object.entries(data).map(([name, values]) => {
      let value;
      switch (numberWeightFilter) {
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[0]:
          value = values.weight;
          setNumberType("weight")
          break;
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[1]:
          value = values.number_of_projects;
          setNumberType("number")
          break;
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[2]:
          value = values.revenue;
          setNumberType("revenue")
          break;
        default:
          value = null;
          setNumberType("weight")
          break;
      }
      return { name: name, value: value };
    });

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleMapReset = () => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'restore'
    });
  };
  const handleZoomIn = () => {
    if (zoomLevel < maxZoomLevel) {
      setZoomLevel(zoomLevel + 1);
    }
    chartRef.current.getEchartsInstance().setOption({
      series: {
        zoom: zoomLevel + 1
      }
    });
  };
  const handleZoomOut = () => {
    if (zoomLevel > minZoomLevel) {
      setZoomLevel(zoomLevel - 1);
    }
    chartRef.current.getEchartsInstance().setOption({
      series: {
        zoom: zoomLevel - 1
      }
    });
  };

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  return (
    <Card className="h-100 mb-3">
      <FalconCardHeader
        title="Projects Revenue by Country"
        titleTag="h5"
        className="py-2"
        light
        endEl={
          <Flex>
            <div className="btn-reveal-trigger">
              <Button
                variant="link"
                size="sm"
                className="btn-reveal"
                type="button"
                onClick={handleMapReset}
              >
                <FontAwesomeIcon icon="sync-alt" />
              </Button>
            </div>

            <Form.Select
              size="sm"
              onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
              className="mx-1 fs--1"
            >
              {BY_NUMBER_WEIGHT_REVENUE_FILTER.map(item => (
                <option key={item}>{item}</option>
              ))}
            </Form.Select>
          </Flex>
        }
      />
      <Card.Body className="position-relative pb-0">
        <WorldMap
          data={dataObject}
          ref={chartRef}
          numberType={numberType}
          style={{ height: '18.875rem' }}
          maxZoomLevel={maxZoomLevel}
          minZoomLevel={minZoomLevel}
        />
        <div className="position-absolute top-0 border mt-3 border-200 rounded-3 bg-light">
          <Button
            variant="link"
            size="sm"
            className="bg-100 rounded-bottom-0 px-2 text-700"
            onClick={handleZoomIn}
          >
            <FontAwesomeIcon icon="plus" className="fs--1" />
          </Button>
          <hr className="m-0 text-200" />
          <Button
            variant="link"
            size="sm"
            className="bg-100 rounded-top-0 px-2 text-700"
            onClick={handleZoomOut}
          >
            <FontAwesomeIcon icon="minus" className="fs--1" />
          </Button>
        </div>

        <div className="mt-3">
          <div className="bar-indicator-gradient mb-1" />
          <div className="d-flex flex-between-center fs--2">
            <p className="mb-0">less than 1k</p>
            <p className="mb-0">more than 100k</p>
          </div>
        </div>

        <Flex justifyContent="end" className="my-4">
          <TableItem data={data} />
        </Flex>
      </Card.Body>
      <Card.Footer className="text-end my-0 fs--1">
        <div className="text-warning"><FaInfoCircle /> Projects Revenue by Country according to the actual signed contract.</div>
      </Card.Footer>
    </Card>
  );
};

ContractCountry.propTypes = {
  data: PropTypes.array.isRequired
}

export default ContractCountry;
