import apiAxios from "helpers/apiAxios";
import { debounce, filterOptions } from "helpers/debounce";
import React, { useState } from "react"
import { Button, Card, FloatingLabel, Form, FormLabel, Modal } from "react-bootstrap"
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const NewDepartmentModal = ({showModal, setshowModal}) => {
  const [formData, setFormData] = useState({
    name: '',
    parent_id: '',
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await apiAxios.post("/organization/departments", formData)
      toast.success(`Departments ${formData.name} has been created sucessfully.`)
      setshowModal(false)
    }
    catch(error)
    {
      toast.error(error.message)
    }
  }

  const handleCancel = () => {
    setshowModal(false)
  }

  const handleChange = ({target}) => {
    let name = target.name;
    setFormData({...formData, [name]: target.value})
  }

  const debouncedFilterDepartments = debounce((inputValue, callback) => {
    filterOptions("/organization/departments", inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const loadDepartmentOptions = (inputValue, callback) => {
    debouncedFilterDepartments(inputValue, callback);
  };

  const debouncedFilterSite = debounce((inputValue, callback) => {
    filterOptions("/organization/sites", inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const loadSiteOptions = (inputValue, callback) => {
    debouncedFilterSite(inputValue, callback);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title id="contained-modal-title-vcenter">New Department</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="text-primary">Create a new Department</h6>
            <Card>
                <Card.Body>
                  <FloatingLabel
                    controlId="name"
                    label="Department name"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Department name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange} 
                    />
                  </FloatingLabel>

                  <FormLabel className="mb-2">Parent</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadDepartmentOptions}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100"
                    style={{ height: '100px' }}
                    name="parent_id"
                    onChange={(selectOptions) => setFormData({...formData, parent_id: selectOptions.value})}
                  />

                  <FormLabel className="mb-2">Site</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadSiteOptions}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100"
                    style={{ height: '100px' }}
                    name="site_id"
                    onChange={(selectOptions) => setFormData({...formData, site_id: selectOptions.value})}
                  />
                </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit">Save</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
};

NewDepartmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired
}

export default NewDepartmentModal;