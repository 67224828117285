import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  title: {
    text: "Source to know Pebsteel"
  },
  toolbox: {
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {}
    }
  },
  legend: {
    orient: 'vertical',
    left: 'right',
    top: 50,
    itemWidth: 12,
    itemHeight: 12,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    inactiveBorderColor: 'transparent',
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 12,
      fontFamily: 'Poppins',
      fontWeight: '500'
    }
  },
  series: [
    {
      type: 'pie',
      name: "Source",
      radius: '70%',
      center: ['45%', '55%'],
      label: {
        show: true,
        position: 'inside',
        formatter: '{d}%',
        color:'black', 
        fontSize: 14
      },
      percentPrecision: 0,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      data: data
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

getOption.propTypes = {
  data: PropTypes.array.isRequired
};

const LeadSource = ({data=[], title}) => {
  const groupedData = data && data.reduce((acc, item) => {
    const source = item.source;
    acc[source] = (acc[source] || 0) + 1;

    return acc;
  }, {});

  const newData = Object.entries(groupedData).map(([name, value]) => ({name, value}));

  return (
    <Card className="h-100 border rounded">
      <Card.Body className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(newData, title)}
          className="w-100"
        />
      </Card.Body>
    </Card>
  )
}

LeadSource.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default LeadSource;