
import React from 'react';
import DateFormatRenderer from "components/ag-grid/DateFormatRenderer";
import ProjectLinkRenderer from "./ProjectLinkRenderer";
import DurationRenderer from './DurationRenderer';

export const columnDefs = [
  {
    headerName: '',
      children: [
        { 
          headerName: 'ID#',
          field: 'id',
          minWidth: 50,
          //pinned: 'left',
        },
        { 
          headerName: 'Project name',
          field: 'project.name',
          minWidth: 220,
          cellRenderer: ProjectLinkRenderer
        },
        { 
          headerName: 'Job number',
          field: 'project.j_number',
          minWidth: 120
        },
        { 
          headerName: 'Contract number',
          field: 'contract_addendum_number',
          minWidth: 140
        },
        { 
          headerName: 'Location',
          field: 'location',
          minWidth: 140
        },
      ]
  },
  {
    headerName: 'DESIGN WARRANTY',
    children: [
      { 
        headerName: "Start",
        field: "design_warranty_start",
        minWidth: 120,
        cellRenderer: DateFormatRenderer
      },
      { 
        headerName: "Finish",
        field: "design_warranty_finish",
        minWidth: 120,
        cellRenderer: DateFormatRenderer
      },
      { 
        headerName: "Duration",
        field: "design_warranty_duration",
        minWidth: 80,
        cellRenderer: DurationRenderer
      }
    ]
  },
  {
    headerName: 'LEAK WARRANTY',
    children: [
      { 
        headerName: "Start",
        field: "leak_warranty_start",
        minWidth: 120,
        cellRenderer: DateFormatRenderer
      },
      { 
        headerName: "Finish",
        field: "leak_warranty_finish",
        minWidth: 120,
        cellRenderer: DateFormatRenderer
      },
      { 
        headerName: "Duration",
        field: "leak_warranty_duration",
        minWidth: 80,
        cellRenderer: DurationRenderer
      }
    ]
  },
  {
    headerName: 'MATERIAL WARRANTY',
    children: [
      { 
        headerName: "Start",
        field: "material_warranty_start",
        minWidth: 120,
        cellRenderer: DateFormatRenderer
      },
      { 
        headerName: "Finish",
        field: "material_warranty_finish",
        minWidth: 120,
        cellRenderer: DateFormatRenderer
      },
      { 
        headerName: "Duration",
        field: "material_warranty_duration",
        minWidth: 80,
        cellRenderer: DurationRenderer
      }
    ]
  },
  {
    headerName: '',
    children: [
      { 
        headerName: 'CSE incharge',
        field: 'cse_incharge',
        minWidth: 100
      },
      { 
        headerName: 'Note',
        field: 'note',
        minWidth: 200
      },
      { 
        headerName: 'Created by',
        field: 'created_by',
        minWidth: 120
      },
      { 
        headerName: 'Created date',
        field: 'created_date',
        minWidth: 150
      }
    ]
  }
];