
import Flex from "components/common/Flex";
import IconAlert from "components/common/IconAlert";
import IconButton from "components/common/IconButton";
import { FaTools } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import General from "./General";
import Booking from "./Booking";
import CRM from "./CRM";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSetting } from "redux/settingsSlice";

const { Card, Tabs, Tab } = require("react-bootstrap");

const ApplicationSettings = () => {
  const [updateStatus, setUpdateStatus] = useState(false);
  const {data} = useSelector(state => state.settings);

  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    if (!data) {
      dispatch(getSetting());
    }
  }, [data]);

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Flex justifyContent="between">
            <h4><FaTools className="text-warning" /> Application Settings</h4>

            <div>
              <IconButton
                onClick={handleBack}
                variant="falcon-default"
                size="sm"
                className="me-1"
                icon="arrow-left"
              />
            </div>
          </Flex>
        </Card.Body>
      </Card>

      {updateStatus && (
        <IconAlert variant="warning">
          You have updated the data but have not yet saved it.
        </IconAlert>
      )}

      <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" transition={false}>
        <Tab eventKey="general" title="General" className='border-bottom border-x'>
          <General setting={data} setUpdateStatus={setUpdateStatus} />
        </Tab>
        <Tab eventKey="booking" title="Booking" className='border-bottom border-x p-3'>
          <Booking />
        </Tab>
        <Tab eventKey="crm" title="CRM" className='border-bottom border-x p-3'>
          <CRM />
        </Tab>
      </Tabs>


    </>
  );
};

export default ApplicationSettings;