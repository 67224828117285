import { Card, Row, Col } from "react-bootstrap"
import ContractMonthlyChart from "./ContractMonthlyChart"
import TableItem from "./TableItem"
import React from 'react';
import PropTypes from 'prop-types';

const ContractMonthly = ({data}) => {
  return (
    <Card className="h-100 mb-3">
      <Card.Header className="bg-light">
        Signed Contracts Monthly
      </Card.Header>
      <Card.Body>
        <Row className="g-3">
          <Col md="7" sm="12">
            <ContractMonthlyChart data={data} />
          </Col>
          <Col md="5" sm="12">
            <TableItem data={data} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
};

ContractMonthly.propTypes = {
  data: PropTypes.array.isRequired
}

export default ContractMonthly;