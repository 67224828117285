import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { getChangeLog } from "redux/documentation/changeLogSlice";
import createMarkup from 'helpers/createMarkup';
import { Button, Card, Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { MdDelete, MdEdit, MdHistory, MdPublish, MdNewReleases } from 'react-icons/md';
import { FaEllipsisV } from "react-icons/fa";
import ChangeLogPublishConfirmation from "./ChangeLogPublishConfirmation";
import ChangeLogForm from "./ChangeLogForm";
import ChangeLogDeleteConfirmation from "./ChangeLogDeleteConfirmation";
import { CiCircleList } from "react-icons/ci";

const ChangeLogDetail = () => {
  const {id} = useParams();
  const [showChangeLogForm, setShowChangeLogForm] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [title, setTitle] = useState(null);
  const [changeLog, setChangeLog] = useState({});

  const {changeLogData: log} = useSelector(state => state.documentation_change_log);
  const {permissions} = useSelector(state => state.profile_user.data);
  const { v306 } = permissions || {};
  
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(getChangeLog(id));
  }, [dispatch, id]);

  const handlePublishLog = () => {
    setShowPublishConfirmation(true)
  }

  const handleNewRelease = () => {
    setShowChangeLogForm(true)
    setTitle("New Change Log")
    setChangeLog(null)
  }

  const handleEditLog = () => {
    setShowChangeLogForm(true)
    setTitle("Edit Change log")
    setChangeLog(log)
  }

  const handleDeleteLog = () => {
    setShowDeleteConfirmation(true)
  }

  const handleBacktoList = () => {
   navigate(-1);
  }

  const {allow_edit, allow_delete, allow_release} = log.permissions || {};
 
  return (
    <>
      <Card className="mb-3">
        <Card.Body className="position-relative">
          <h4 className="mb-0 text-primary"><MdNewReleases className="text-warning" /> Change logs</h4>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <Flex justifyContent="between">
            <div>
              <h5>
                <code className="fw-bold fs-1">{log.version}</code> - {log.title}
              </h5>
              <p className="mb-0 fs--1 text-primary"><MdHistory /> {log.released_date}</p>
            </div>
            <div>
              {allow_release && (
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-1"
                  onClick={handlePublishLog}
                >
                  <MdPublish className="text-warning"/> Publish
                </Button>
              )}

              <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-1"
                  onClick={handleBacktoList}
                >
                  <CiCircleList className="text-warning"/> Back to list
                </Button>
              
              {allow_edit && allow_delete && (
                <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                  <Dropdown.Toggle split variant="falcon-default" size="sm">
                    <FaEllipsisV className="fs--2" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="border py-0">
                    <div className="py-2">
                      {v306 && (
                        <Dropdown.Item onClick={handleNewRelease} className="mb-2">
                          <MdNewReleases className="text-warning" /> New Change log
                        </Dropdown.Item>
                      )}

                      <Dropdown.Divider />

                      <Dropdown.Item onClick={handleEditLog}>
                        <MdEdit className="text-warning" /> Edit
                      </Dropdown.Item>

                      <Dropdown.Divider />

                      <Dropdown.Item className="text-danger" onClick={handleDeleteLog}>
                        <MdDelete className="text-danger" /> Delete
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </Flex>
        </Card.Header>
        <Card.Body className="bg-light p-4">
          <span dangerouslySetInnerHTML={createMarkup(log.body)}></span>
        </Card.Body>
      </Card>

      {showChangeLogForm && (
        <ChangeLogForm
          showModal={showChangeLogForm}
          setshowModal={setShowChangeLogForm}
          title={title}
          changeLog={changeLog}
        />
      )}

      {showDeleteConfirmation && (
        <ChangeLogDeleteConfirmation
          showModal={showDeleteConfirmation}
          setshowModal={setShowDeleteConfirmation}
          changeLog={log}
        />
      )}

      {showPublishConfirmation && (
        <ChangeLogPublishConfirmation
          showModal={showPublishConfirmation}
          setshowModal={setShowPublishConfirmation}
          changeLog={log}
        />
      )}
    </>
  )
}

export default ChangeLogDetail;