import { Form, Button, Row, Col, Offcanvas } from "react-bootstrap";
import { finalRequestScheduleToENG, finalScheduleToCSD, getRequestScheduleMailer } from "redux/crm/project/changeOrderSlice";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useMemo, useState } from "react";
import { debouncedFilterEmployees } from "helpers/debounce";
import { useDispatch } from "react-redux";
import AsyncSelect from 'react-select/async';
import TinymceEditor from "components/common/TinymceEditor";
import DatePicker from "react-datepicker";
import RequestScheduleForm from "./enter_job/final/RequestScheduleForm";
import useAuth from "hooks/useAuth";
import { FaComment, FaInfoCircle, FaShareSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

const ChangeOrderFinalRequestSchedule = ({showModal, setshowModal, changeOrder, title, isScheduled }) => {
  const {control, register, handleSubmit, formState: {errors}, setValue} = useForm();
  const {userName} = useAuth();
 
  const [changeOrderMailer, setChangeOrderMailer] = useState({
    to_names: [],
    cc_names: [],
    subject: ''
  });
  const [showLastestCoDate, setShowLastestCoDate] = useState(false);
  const {to_names, cc_names, subject} = changeOrderMailer || {};

  const [estimateData, setEstimateData] = useState(null);
  const [abErectionData, setAbErectionData] = useState(null);
  const [apdData, setApdData] = useState([]);
  const [shopData, setShopData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedToEmails, setSelectedToEmails] = useState([]);
  const [selectedCCEmails, setSelectedCCEmails] = useState([]);

  const ppd_rad_documents = changeOrder?.ppd_rad_documents || [];
  const [selectedDocumentId, setSelectedDocumentId] = useState(ppd_rad_documents.map((document) => document.id) || []);

  const REQUEST_TYPE = isScheduled ? "schedule" : "request";

  const { project, building } = changeOrder;
  const projectId = project.id;
  const buildingId = building.id;
  const changeOrderId = changeOrder.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRequestScheduleMailer({
      projectId: projectId,
      buildingId: buildingId,
      changeOrderId: changeOrderId,
      params: { type: REQUEST_TYPE, drawing_type: "" },
    })).then((action) => {
      const { to_names, cc_names, subject } = action.payload;
      setChangeOrderMailer({ to_names, cc_names, subject });
    });
  }, [dispatch, changeOrder, REQUEST_TYPE, projectId, buildingId]);

  useEffect(() => {
    const { to_names, cc_names, subject } = changeOrderMailer;
    setValue("to", to_names);
    setValue("cc", cc_names);
    setValue("subject", subject);
    setValue("comment", changeOrder.sales_note);

    setSelectedToEmails(to_names.length > 0 && to_names.map(item => ({value: item, label: item})));
    setSelectedCCEmails(cc_names.length > 0 && cc_names.map(item => ({value: item, label: item})));

  }, [setValue, changeOrderMailer, changeOrder.sales_note]);

  useEffect(() => {
    const {estimate, parts, phases, ab_erection, others} = changeOrder

    if(estimate) {
      setEstimateData(estimate)
    }

    if(parts) {
      setApdData(parts)
    }

    if(phases) {
      setShopData(phases)
    }

    if(ab_erection) {
      setAbErectionData(ab_erection)
    }

    if(others) {
      setOtherData(others)
    }

  }, [changeOrder, setValue])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const employeeOptions = (inputValue, callback) => {
    debouncedFilterEmployees(inputValue, callback)
  }

  const defaultToNameOption = useMemo(() => (
    to_names && to_names.map((name) => ({ value: name, label: name }) || [] )
  ), [changeOrder, to_names, cc_names, subject, REQUEST_TYPE])

  const defaultCCNameOption = useMemo(() => (
    cc_names && cc_names.map((name) => ({ value: name, label: name }) || [] )
  ), [changeOrder, to_names, cc_names, subject, REQUEST_TYPE])

  const handleChangeToNames = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('to', selectedOptionsValues, { shouldValidate: true })
    setSelectedToEmails(selectedOptions)
  }

  const handleChangeCCNames = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('cc', selectedOptionsValues, { shouldValidate: true })
    setSelectedCCEmails(selectedOptions)
  }

  const handleDocumentCheckboxChange = (documentId) => {
    setSelectedDocumentId((prevSelectedIds) => {
      if (prevSelectedIds.includes(documentId)) {
        return prevSelectedIds.filter((id) => id !== documentId);
      } else {
        return [...prevSelectedIds, documentId];
      }
    })
  }

  const onSubmit = (data) => {
    data = {
      ...data,
      estimate: isScheduled ? estimateData : {...estimateData, est_requested_sent: true, est_requested_by: userName},
      parts: apdData,
      phases: shopData,
      others: otherData,
      ab_erection: isScheduled ? abErectionData : {...abErectionData, requested_by: userName},
      document_ids: selectedDocumentId
    }

    console.log(data)

    try {
      const newData = {
        projectId: projectId,
        buildingId: buildingId,
        changeOrderId: changeOrderId,
        data
      }

      if (isScheduled) {
        dispatch(finalScheduleToCSD(newData))
      }
      else 
      {
        dispatch(finalRequestScheduleToENG(newData))
      }

      closeModal();
      navigate(`/crm/projects/${projectId}/buildings/${buildingId}/change-orders/${changeOrderId}`);
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Offcanvas
      show={showModal}
      onHide={closeModal}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '50%'}}
      className="fs--1"
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title className="fs-0" id="contained-modal-title-vcenter">{title}!</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2"><MdEmail className="text-primary" /> Email:</div>

          <Row className="g-0 align-items-center mb-2">
            <Col sm={1} className="mx-2 fs--0">To</Col>
            <Col>
              <Controller
                control={control}
                name="to"
                rules={{required: "To must be required"}}
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={employeeOptions}
                    defaultValue={defaultToNameOption}
                    value={selectedToEmails}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100 border-0 outline-none px-x1 rounded-0"
                    isMulti
                    onChange={handleChangeToNames}
                  />
                )}
              />
              {errors.to && (
                <span className="text-danger mx-4 mt-1">
                  {errors.to.message}
                </span>
              )}
            </Col>
          </Row>
          <Row className="gx-0 align-items-center mb-2">
            <Col sm={1} className="mx-2 fs--0">CC</Col>
            <Col>
              <Controller
                control={control}
                name="cc"
                rules={{required: "CC must be required"}}
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={employeeOptions}
                    defaultValue={defaultCCNameOption}
                    value={selectedCCEmails}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100 border-0 outline-none px-x1 rounded-0"
                    isMulti
                    onChange={handleChangeCCNames}
                  />
                )}
              />
              {errors.cc && (
                <span className="text-danger mx-4 mt-1">
                  {errors.cc.message}
                </span>
              )}
            </Col>
          </Row>
          <Row className="gx-0 align-items-center mb-2">
            <Col sm={1} className="mx-2 fs--0">Subject</Col>
            <Col>
              <div className="mx-3">
                <Form.Control
                  placeholder="Subject"
                  //className="border-0 outline-none px-x1 rounded-0 fs--1 text-primary"
                  {...register('subject', {required: true})}
                />
              </div>
            </Col>
          </Row>

          {!isScheduled && (
            <>
              <div className="my-4">
                <h6>Attachments</h6>
                {
                  ppd_rad_documents && ppd_rad_documents.length > 0 ? (
                    <div className="pb-2 mt-3 rounded border border-dashed">
                      {ppd_rad_documents.map(document => (
                        <Row className="mb-2 gx-2 px-4" key={document.id}>
                          <Col sm="auto">
                            <Form.Check
                              key={document.id}
                              name={document.id}
                              onChange={() => handleDocumentCheckboxChange(document.id)}
                              checked={selectedDocumentId.includes(document.id)}
                            />
                          </Col>
                          <Col className="text-primary">
                            <span className="mx-1 flex-1">
                              {document.name} ({(document.size / 1024).toFixed(2)} kb)
                            </span>
                          </Col>
                        </Row>
                      ))}
                  </div>
                ) : (
                  <div className="p-3 text-primary rounded text-center border border-dashed mb-3">No attachment.</div>
                )}
              </div>

              <div className="mt-2">
                <Form.Group as={Row} className="mb-2">
                  <Form.Label column sm={2} className="text-lg-start">
                  </Form.Label>
                  <Col>
                    <Row className="gx-2 align-items-center">
                      <Col sm="auto">
                        <Controller
                          control={control}
                          name="lastest_co"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              checked={field.value}
                              label="Lastest CO# date"
                              onChange={(e) => { 
                                field.onChange(e)
                                const checked = e.target.checked
                                setShowLastestCoDate(checked)
                                if (checked) {
                                  setValue("lastest_co_date", new Date())
                                }
                                else {
                                  setValue("lastest_co_date", null)
                                }
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col>
                        <Controller
                          control={control}
                          name="lastest_co_date"
                          //rules={{required: "Lastest CO# date must be required."}}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              className="form-control fs--1 w-50"
                              placeholderText="dd-MMM-YYYY"
                              dateFormat="dd-MMM-yyyy"
                              disabled={!showLastestCoDate}
                              fixedHeight
                            />
                          )}
                        />
                        {/* <span className="text-danger">
                          {errors.lastest_co_date && errors.lastest_co_date.message}
                        </span> */}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2">
                  <Form.Label column sm={2} className="text-lg-start">
                  </Form.Label>
                  <Col>
                    <Controller
                      control={control}
                      name="always_include_schedule"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          checked={field.value}
                          label="Always included schedule"
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Col>
                </Form.Group>
              </div>
            </>
          )}

          <h6 className="text-primary mt-4"><FaInfoCircle /> Information:</h6>
          <RequestScheduleForm
            estimateData={estimateData}
            setEstimateData={setEstimateData}
            abErectionData={abErectionData}
            setAbErectionData={setAbErectionData}
            apdData={apdData}
            setApdData={setApdData}
            shopData={shopData}
            setShopData={setShopData}
            otherData={otherData}
            setOtherData={setOtherData}
            isScheduled={isScheduled}
          />

          <div className="border-200 mt-5">
            <label className="ms-2"><FaComment className="text-primary" /> Comment</label>
            <Controller
              control={control}
              name="comment"
              render={({ field }) => (
                <TinymceEditor
                  handleChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>

          <div className="border-0 text-center my-4">
            <Button size="sm" type="submit" className="me-1"> <FaShareSquare className="text-warning fs-0 me-1" /> {title}</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
          </div>

        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

ChangeOrderFinalRequestSchedule.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  changeOrder: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isScheduled: PropTypes.bool.isRequired
};

export default ChangeOrderFinalRequestSchedule;