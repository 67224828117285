import {Image} from "react-bootstrap";
import Co00WorkflowProcess from "../../../assets/img/crm/co-00-process.png";
import COFinalWorkflowProcess from "../../../assets/img/crm/co-final-process.png";
import CoReviseWorkflowProcess from "../../../assets/img/crm/co-revise-process.png";
import React from 'react';

const WorkflowChart = () => {
  return (
    <div className="m-4">
      <Image src={COFinalWorkflowProcess} thumbnail />

      <Image src={Co00WorkflowProcess} thumbnail />

      <Image src={CoReviseWorkflowProcess} thumbnail />
    </div>
  )
}

export default WorkflowChart;