import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { setCurrentParams, setFilterTag, setCurrentParamsOriginal } from "redux/crm/report/masterScheduleSlice";
import { useSelector } from "react-redux";
import IconAlert from "components/common/IconAlert";
import { getActiveSalesUnits } from "redux/crm/salesUnitSlice";
import { formatDate } from "helpers/utils";
//import { startOfMonth } from 'date-fns';
import useDateRange from "hooks/useDateRange";
import { FaSearch } from "react-icons/fa";
import Flex from "components/common/Flex";

const MasterScheduleFilter = ({showModal, setshowModal}) => {
  const { paginate, currentParamsOriginal } = useSelector((state) => state.crm_report_master_schedule);
  const {activeSalesUnitsData} = useSelector((state) => state.crm_sales_unit);

  const { control, register, handleSubmit, setValue, reset } = useForm();
  const dispatch = useDispatch();
  // const currentMonthStart = startOfMonth(new Date())
  // const currentMonthEnd = new Date()
  const {pageSize} = paginate

  const { startDate: jobEntryFromDate, endDate: jobEntryEndDate, onChangeDateRange: onChangeJobEntryDate } = useDateRange(); //currentMonthStart, currentMonthEnd
  const { startDate: contractFromDate, endDate: contractEndDate, onChangeDateRange: onChangeContractDate } = useDateRange();
  const { startDate: downPaymentFromDate, endDate: downPaymentEndDate, onChangeDateRange: onChangeDownPaymentDate } = useDateRange();

  useEffect(() => {
    dispatch(getActiveSalesUnits())
  }, [])

  useEffect(() => {
    if (currentParamsOriginal) {
      if (currentParamsOriginal["jobEntryFromDate"] && currentParamsOriginal["jobEntryEndDate"]) {
        onChangeJobEntryDate([new Date(currentParamsOriginal["jobEntryFromDate"]), new Date(currentParamsOriginal["jobEntryEndDate"])])
      }

      if (currentParamsOriginal["contractFromDate"] && currentParamsOriginal["contractEndDate"]) {
        onChangeContractDate([new Date(currentParamsOriginal["contractFromDate"]), new Date(currentParamsOriginal["contractEndDate"])])
      }

      if (currentParamsOriginal["downPaymentFromDate"] && currentParamsOriginal["downPaymentEndDate"]) {
        onChangeDownPaymentDate([new Date(currentParamsOriginal["downPaymentFromDate"]), new Date(currentParamsOriginal["downPaymentEndDate"])])
      }

      Object.entries(currentParamsOriginal).forEach(([key, value]) => {
        if (value) {
          setValue(key, value)
        }
      })
    }
  }, [currentParamsOriginal])

  const ACTIVE_OPTIONS = [
    {label: "All", value: ""},
    {label: "Active", value: true},
    {label: "Deactive", value: false},
  ]

  const salesUnitOptions = activeSalesUnitsData && activeSalesUnitsData.map((item) => ({ value: item.id, label: item.description }))
  const salesRegionOptions = activeSalesUnitsData
    ? Array.from(new Set(activeSalesUnitsData
      .filter(item => item.region)
      .map(item => item.region)
    ))
    .map(region => ({ value: region, label: region }))
    : [];

  const onSubmit = (data) => {
    const newData = {
      project_q_number_cont: data.q_number,
      project_name_cont: data.project_name,
      j_number_cont: data.j_number,
      name_cont: data.building_name,
      project_sales_incharge_cont: data.sales_inchage,
      company_name_cont: data.customer_name,
      active_true: data.active_type,
      contract_date_null: data.down_payment_without_contract_signed || undefined,
      contract_note_cont: data.has_purchase_order ? "PO" : undefined,

      job_entry_date_gteq: formatDate(jobEntryFromDate),
      job_entry_date_lteq: formatDate(jobEntryEndDate),
      contract_date_gteq: formatDate(contractFromDate),
      contract_date_lteq: formatDate(contractEndDate),
      down_payment_gteq: formatDate(downPaymentFromDate),
      down_payment_lteq: formatDate(downPaymentEndDate),

      sales_unit_id_in: data.sales_unit_ids,
      sales_unit_region_in: data.sales_regions,
      page: 1,
      per_page: pageSize
    }

    //store for filter
    const dataFilter = [
      { id: "project_q_number_cont", name: "Quote number", value: data.q_number },
      { id: "j_number_cont", name: "P/J number", value: data.j_number },
      { id: "project_name_cont", name: "Project name", value: data.project_name },
      { id: "name_cont", name: "Building name", value: data.building_name },
      { id: "company_name_cont", name: "Customer name", value: data.customer_name },
      { id: "project_pj_location_cont", name: "Location", value: data.pj_location },
      { id: "project_sales_incharge_cont", name: "Sales inchage", value: data.sales_incharge },
      { id: "active_true", name: "Active type", value: data.active_type },
      { id: "contract_note_cont", name: "Has purchase order", value: data.has_purchase_order},
      //{ id: "contract_date_null", name: "Down payment without contract signed", value: data.down_payment_without_contract_signed },
    ];

    if (jobEntryFromDate && jobEntryEndDate) {
      dataFilter.push({ id: "job_entry_date_gteq", name: "Job entry date", value: [formatDate(jobEntryFromDate), formatDate(jobEntryEndDate) ] })
    }

    if (contractFromDate && contractEndDate) {
      dataFilter.push({ id: "contract_date_gteq", name: "Contract date", value: [formatDate(contractFromDate), formatDate(contractEndDate) ] })
    }

    if (downPaymentFromDate && downPaymentEndDate) {
      dataFilter.push({ id: "down_payment_gteq", name: "Down payment", value: [formatDate(downPaymentFromDate), formatDate(downPaymentEndDate) ] })
    }

    if (data.sales_unit_ids) {
      dataFilter.push({ id: "sales_unit_id_in", name: "Sales unit", value: data.sales_unit_ids })
    }

    if (data.sales_regions) {
      dataFilter.push({ id: "sales_unit_region_in", name: "Sales region", value: data.sales_regions })
    }

    dispatch(setFilterTag(dataFilter.filter((data) => data.value !== undefined && data.value !== '')))

    let obj = {}
    Object.entries(newData).forEach((item) => {
      const value = item[1]
      const key = item[0]

      if (value !== null && value !== "" && value !== undefined) {
        obj = {...obj, [key]: value}
      }
    })

    try {
      dispatch(setCurrentParams(obj))
      dispatch(setCurrentParamsOriginal({
        ...data,
        jobEntryFromDate,
        jobEntryEndDate,
        contractFromDate,
        contractEndDate,
        downPaymentFromDate,
        downPaymentEndDate
      }))

      closeModal()
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleReset = () => {
    resetForm()
    //closeModal()
  }

  const resetForm = () => {
    reset()

    // Reset date ranges to their initial values
    onChangeJobEntryDate([null, null])
    onChangeContractDate([null, null])
    onChangeDownPaymentDate([null, null])

    setValue('sales_unit_ids', null)
    setValue('sales_regions', null)

    dispatch(setCurrentParams({page: 1, per_page: pageSize}))
  }

  const handleChangeSalesUnit = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value)
    setValue('sales_unit_ids', selectedOptionsValues, { shouldValidate: true })
  }

  const handleChangeSalesRegion = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value)
    setValue('sales_regions', selectedOptionsValues, { shouldValidate: true })
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Apply filters to advances</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="info">
              <p className="mb-0">Establish the contract's filtering criteria</p>
            </IconAlert>

            <Form.Group as={Row} className="mb-2" controlId="q_number">
              <Form.Label column sm={3} className="text-lg-end">
                Quote number
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  rows={6}
                  name="q_number"
                  placeholder="Quote number"
                  className="fs--1"
                  {...register("q_number")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="j_number">
              <Form.Label column sm={3} className="text-lg-end">
                P/J number
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  rows={6}
                  name="j_number"
                  placeholder="P/J number"
                  className="fs--1"
                  {...register("j_number")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="project_name">
              <Form.Label column sm={3} className="text-lg-end">
                Project name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="project_name"
                  placeholder="Project name"
                  className="fs--1"
                  {...register("project_name")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="building_name">
              <Form.Label column sm={3} className="text-lg-end">
                Building name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="building_name"
                  placeholder="Building name"
                  className="fs--1"
                  {...register("building_name")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="customer_name">
              <Form.Label column sm={3} className="text-lg-end">
                Customer name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="customer_name"
                  placeholder="Customer name"
                  className="fs--1"
                  {...register("customer_name")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="sales_incharge">
              <Form.Label column sm={3} className="text-lg-end">
                Sales incharge
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="sales_incharge"
                  placeholder="Sales incharge"
                  className="fs--1"
                  {...register("sales_incharge")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="active_type">
              <Form.Label column sm={3} className="text-lg-end"></Form.Label>
              <Col sm={9} md={7}>
                <Controller 
                  control={control}
                  name="active_type"
                  defaultValue={ACTIVE_OPTIONS[0].value}
                  render={({field}) => (
                    <Flex direction={'row'} className="mt-1">
                      {ACTIVE_OPTIONS.map((option, index) => (
                        <Form.Check
                          key={index}
                          type="radio"
                          label={option.label}
                          value={option.value}
                          className="me-3"
                          {...field}
                          checked={field.value === option.value}
                          onChange={() => field.onChange(option.value)}
                        />
                      ))}
                    </Flex>
                  )}
                />
              </Col>
            </Form.Group>

            <div className="border-dashed border-bottom my-4"></div>

            <Form.Group as={Row} className="mb-2" controlId="job_entry_date">
              <Form.Label column sm={3} className="text-lg-end">
                Job entry date
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="job_entry_date"
                  render={() => (
                    <DatePicker
                      onChange={onChangeJobEntryDate}
                      startDate={jobEntryFromDate}
                      endDate={jobEntryEndDate}
                      selectsRange
                      isClearable={true}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Job entry date"
                      className='form-control fs--1'
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="contract_date">
              <Form.Label column sm={3} className="text-lg-end">
                Contract date
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="contract_date"
                  render={() => (
                    <DatePicker
                      onChange={onChangeContractDate}
                      startDate={contractFromDate}
                      endDate={contractEndDate}
                      selectsRange
                      isClearable={true}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Contract date"
                      className='form-control fs--1'
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="down_payment">
              <Form.Label column sm={3} className="text-lg-end">
                Down payment
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="down_payment"
                  render={() => (
                    <DatePicker
                      onChange={onChangeDownPaymentDate}
                      startDate={downPaymentFromDate}
                      endDate={downPaymentEndDate}
                      selectsRange
                      isClearable={true}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd MMM yyyy"
                      placeholderText="Down payment"
                      className='form-control fs--1'
                    />
                  )}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3} className="text-lg-end"></Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="down_payment_without_contract_signed"
                  //defaultValue={false}
                  render={({ field }) => (
                    <Form.Check
                      type="checkbox"
                      label="Down Payment without contract signed"
                      className="text-primary"
                      checked={field.value}
                      onChange={(e) =>  field.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={3} className="text-lg-end"></Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="has_purchase_order"
                  render={({ field }) => (
                    <Form.Check
                      type="checkbox"
                      label="Has Purchase order"
                      className="text-primary"
                      checked={field.value}
                      onChange={(e) =>  field.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>
              
            <div className="border-dashed border-bottom my-4"></div>

            <Form.Group as={Row} className="mb-2" controlId="sales_unit_ids">
              <Form.Label column sm={3} className="text-lg-end">
                Sales unit
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="sales_unit_ids"
                  render={({field}) => (
                    <Select
                      closeMenuOnSelect={true}
                      options={salesUnitOptions}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      {...field}
                      isMulti
                      value={field.value ? salesUnitOptions.filter((item) => field.value.includes(item.value)) : []}
                      onChange={handleChangeSalesUnit}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="sales_regions">
              <Form.Label column sm={3} className="text-lg-end">
                Sales regions
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="sales_regions"
                  render={({field}) => (
                    <Select
                      closeMenuOnSelect={true}
                      options={salesRegionOptions}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      {...field}
                      isMulti
                      value={field.value ? salesRegionOptions.filter((item) => field.value.includes(item.value)) : []}
                      onChange={handleChangeSalesRegion}
                    />
                  )}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              type="submit"
            >
              <FaSearch /> Search
            </Button>
            <Button size="sm" variant="danger" onClick={handleReset}>Reset</Button>
            <Button size="sm" variant="default-falcon" onClick={closeModal}>Close</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

MasterScheduleFilter.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func
};

export default MasterScheduleFilter;