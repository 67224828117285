import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const BookingHeaderPrint = ({booking, download}) => {

  const navigate = useNavigate()
  //const dispatch = useDispatch()
  const bookingUrl = "/booking/travel"
  //const bookingId = booking.id
 
  const handleBookingList = () => {
    navigate(`${bookingUrl}/list`)
  }
  
  const handlePrintToPDF = () => {
    //dispatch(printPDFBooking(bookingId))
    //navigate(`${bookingUrl}/${bookingId}/print`)

    download()
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="download"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handlePrintToPDF}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Download
              </span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBookingList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
};

BookingHeaderPrint.propTypes = {
  booking: PropTypes.object.isRequired,
  download: PropTypes.func.isRequired
}

export default BookingHeaderPrint;