import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import FeedDeleteConfirmation from './FeedDeleteConfirmation';
import EditPostForm from './EditPostForm';

const FeedDropdown = ({feed}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleEditFeed = () => {
    setShowEditForm(true)
  }

  const handleDeleteFeed = () => {
    setShowDeleteConfirm(true);
  }

  return (
    <>
      <Dropdown className="font-sans-serif btn-reveal-trigger">
        <Dropdown.Toggle
          variant="link"
          size="sm"
          className="px-1 text-600 btn-reveal"
        >
          <FontAwesomeIcon icon="ellipsis-h" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item onClick={handleEditFeed}>Edit</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item className="text-danger" onClick={handleDeleteFeed}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {showDeleteConfirm && (
        <FeedDeleteConfirmation
          showModal={showDeleteConfirm}
          setshowModal={setShowDeleteConfirm}
          feedId={feed.id}
        />
      )}

      {showEditForm && (
        <EditPostForm
          showModal={showEditForm}
          setshowModal={setShowEditForm}
          feed={feed}
        />
      )}
    </>
  );
};

FeedDropdown.propTypes = {};

export default FeedDropdown;
