import apiAxios from "helpers/apiAxios";
import React, { useState } from "react"
import { Button, Card, FloatingLabel, Form, Modal } from "react-bootstrap"
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const NewSiteModal = ({showModal, setshowModal}) => {
  const [formData, setFormData] = useState({
    name: '',
    country: 'VN',
    currency: 'VND'
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await apiAxios.post("/organization/sites", formData)
      toast.success(`Site ${formData.name} has been created sucessfully.`)
      setshowModal(false)
    }
    catch(error)
    {
      toast.error(error.message)
    }
  }

  const handleCancel = () => {
    setshowModal(false)
  }

  const handleChange = ({target}) => {
    let name = target.name;
    setFormData({...formData, [name]: target.value})
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title id="contained-modal-title-vcenter">New Site</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="text-primary">Create a new Site</h6>
            <Card>
                <Card.Body>
                  <FloatingLabel
                    controlId="name"
                    label="Site name"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Site name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange} 
                    />
                  </FloatingLabel>
                  <FloatingLabel className="mb-2" controlId="country" label="Country">
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange} 
                    />
                  </FloatingLabel>
                  <FloatingLabel className="mb-2" controlId="currency" label="Currency">
                    <Form.Select
                      aria-label="Currencye"
                      name="currency"
                      value={formData.currency}
                      onChange={handleChange} 
                    >
                      <option value="VND">VND</option>
                      <option value="USD">USD</option>
                    </Form.Select>
                  </FloatingLabel>
                </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit">Save</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
          </Form>
      </Modal>
  </>
  ) 
};

NewSiteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired
}

export default NewSiteModal;