import { useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog
} from "redux/authorization/userSlice";
import ActivityHistory from "components/peb-grid/ActivityHistory";
import React from 'react';

const UserActivity = () => {
  const {activityLogData} = useSelector(state => state.authorization_user);
  const params = useParams();

  return (
    <ActivityHistory
      activityLogData={activityLogData}
      setNestedPage={setNestedPage}
      setNestedPageSize={setNestedPageSize}
      setNestedParams={setNestedParams}
      getActivityLog={getActivityLog}
      userId={params.id} //dynamic params to activity
    />
  )
}

export default UserActivity;