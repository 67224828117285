import React from 'react';
import { Col, Row } from 'react-bootstrap';
import events from 'data/events/events';
import BirthdayNotice from './BirthdatNotice';
import BirthdayFeed from './BirthdayFeed';
import Events from 'components/pages/user/profile/Events';
import FeedContent from './FeedContent';

const Feed = () => {
  return (
    <Row className="g-0 g-lg-3">
      <Col lg={8}>
        <FeedContent />
      </Col>
      <Col lg={4}>
        <BirthdayNotice
          name="Fiona Lam"
          profileLink="/user/profile"
          className="mb-3"
        />
        <BirthdayFeed />
        <Events cardTitle="You may interested" events={events.slice(2, 5)} />
      </Col>
    </Row>
);
};

export default Feed;
