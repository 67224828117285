import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { getColor, rgbaColor } from 'helpers/utils';
import Flex from 'components/common/Flex';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent
]);

const getOption = (data, statusName) => ({
  color: [
    getColor('primary'),
    getColor('secondary'),
    getColor('warning'),
    getColor('success'),
    getColor('danger'),
    rgbaColor(getColor('danger'), 0.2),
  ],
  dataset: { source: data },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div className="fw-semi-bold">${
        params.seriesName
      }</div><div className="fs--1 text-600"><strong>${params.name}:</strong> ${
        params.value[params.componentIndex + 1]
      }</div>`;
    }
  },
  legend: {
    name: statusName,
    left: 'right',
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    textStyle: { color: getColor('gray-700') }
  },
  xAxis: {
    name: "Month",
    type: 'category',
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'line'
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-200'),
        type: 'line',
        width: 0.5
      }
    },
    axisTick: { show: false },
    boundaryGap: true
  },
  yAxis: {
    name: "Number of waivers",
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-200'),
        type: 'dashed'
      }
    },
    axisLine: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-400'),
    }
  },
  series: 
   {
    name: statusName,
    type: 'bar',
    barWidth: '40%',
    barGap: '10%',
    label: { show: false },
    z: 10,
    emphasis: {
      focus: 'series'
    },
    itemStyle: {
      borderRadius: [2, 2, 0, 0]
    },
    //data: data.slice(1).map(row => row[1])
   },
   grid: {
    containLabel: true,
    right: '8%',
    left: '3%',
    bottom: '5%',
    top: '20%'
  }
});

const WaiverMonthlyV2 = ({ data, statusNames }) => {
  const [newData, setNewData] = useState([])
  const [status, setStatus] = useState("All")

  const uniqueMonths = useMemo(() => (
    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  ), [])

  const transformMonthFilter = (originalData, statusName) => {
    // Create a set of unique month names
    
    const headerRow = ['Month', statusName]
  
    // Create data rows
    const dataRows = uniqueMonths.map(month => {
      const row = [month]
      const filteredByMonth = originalData.filter(item => item.created_month_name === month)

      let count = 0
      if (statusName === "All")
      {
        count = filteredByMonth.length
      }
      else
      {
        count = filteredByMonth.filter(item => item.status_name === statusName).length
      }
      
      row.push(count)

      return row
    });

  return [headerRow, ...dataRows]
  };

  useEffect(() => {
    setNewData(transformMonthFilter(data, status))
  }, [data, status])

  const handleChangeStatus = (event) => {
    const selectedStatus = event.target.value
    setStatus(selectedStatus)
  }

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="By monthly"
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Flex justifyContent="between">
            <Form.Select size="sm" className="me-1" onChange={handleChangeStatus} defaultValue={status}>
              {statusNames &&
                statusNames.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
            </Form.Select>

            <Form.Select size="sm">
              <option>This year</option>
              <option>Last year</option>
            </Form.Select>
          </Flex>
        }
      />
      <Card.Body className="h-100 w-100">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(newData, status)}
          style={{ height: '100%', minHeight: '15.625rem' }}
        />
      </Card.Body>
    </Card>
  );
};

WaiverMonthlyV2.propTypes = {
  data: PropTypes.array.isRequired,
  statusNames: PropTypes.array.isRequired
};

export default WaiverMonthlyV2;
