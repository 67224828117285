import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { createCar, updateCar } from "redux/booking/car/carSlice";
import { debounce, filterOptions } from "helpers/debounce";
import AsyncSelect from 'react-select/async';

const CarForm = ({showModal, setshowModal, title, car}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, reset } = useForm()
  const dispatch = useDispatch()

  const defaultSiteOption = useMemo(() => (
    car ? { value: car.site_id, label: car.site_name } : ""
  ), [car])

  useEffect(() => {
    if (title === "New Car") {
      reset()
    }
  }, [title])

  useEffect(() => {
    if(car) {
      Object.entries(car).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, car])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (car) {
        const carId = car.id
        dispatch(updateCar({carId, data }))
      }
      else {
        dispatch(createCar(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const debouncedFilterSites = debounce((inputValue, callback) => {
    filterOptions("/organization/sites", inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const siteOptions = (inputValue, callback) => {
    debouncedFilterSites(inputValue, callback);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-2" controlId="name">
              <Form.Label column sm={3} className="text-lg-end">
                Car name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Car name"
                  className="fs--1"
                  {...register("name", {
                    required: "Car name must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.name && errors.name.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="model">
              <Form.Label column sm={3} className="text-lg-end">
                Model
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="model"
                  placeholder="Model"
                  className="fs--1"
                  {...register("model", {
                    required: "Model must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.model && errors.model.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="active">
              <Col sm={{ span: 10, offset: 3 }}>
                <Controller
                  control={control}
                  name="active"
                  defaultValue={true}
                  render={({ field }) => (
                    <Form.Check
                      type="switch"
                      label="Active"
                      checked={field.value}
                      onChange={(e) => setValue("active", e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="remark">
              <Form.Label column sm={3} className="text-lg-end">
                Note
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="remark"
                  placeholder="Note"
                  className="fs--1"
                  {...register("remark")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="site_id">
              <Form.Label column sm={3} className="text-lg-end">
                Site
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="site_id"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultSiteOption}
                      loadOptions={siteOptions}
                      closeMenuOnSelect={true}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      className="w-100"
                      onChange={selectedOption => setValue('site_id', selectedOption.value)}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Site must be required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.site_id && errors.site_id.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit">Save</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
}

CarForm.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.bool,
  title: PropTypes.string,
  car: PropTypes.object
}

export default CarForm;