import { Controller } from "react-hook-form";
import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import Flex from "components/common/Flex";
import DatePicker from "react-datepicker";
import {isAfter} from "date-fns";
import PropTypes from 'prop-types';

const BookingMultipleStopTripForm = React.memo(({
  index, transit, control, register, errors, setValue, getValues, removeTransit, updatedTransit
}) => {

  useEffect(() => {
    //for case edit
    if (transit) {

      Object.entries(transit).forEach(([key, value]) => {
        if (key === 'from_date' || key === 'to_date') {
          if (value) {
            const dateValue = new Date(value)
            setValue(`${key}_${index}`, dateValue);
          }
        }
        else if (key === "from_location" || key === "to_location" || key === "description" ) {
          setValue(`${key}_${index}`, value)
        }
      })
    }
  }, [transit])

  const handleFieldChange = (fieldName, value) => {
    const updatedTransit = { ...transit, [fieldName]: value }
    handleUpdateTransitData(updatedTransit)
  };

  const handleUpdateTransitData = (updatedData) => {
    updatedTransit(index, updatedData)
  }

  const from_date_name = `from_date_${index}`
  const to_date_name = `to_date_${index}`
  const from_location_name = `from_location_${index}`
  const to_location_name = `to_location_${index}`
  const description_name = `description_${index}`

  return (
    <>
      <Flex justifyContent="end" className="mx-3">
        <IconButton
          onClick={removeTransit}
          variant="falcon-default"
          size="sm"
          icon="trash"
          transform="shrink-3"
        >
        </IconButton>
      </Flex>

      <Form.Group as={Row} className="mb-2" controlId={from_date_name}>
        <Form.Label column sm={3} className="text-lg-end">
          Trip date
        </Form.Label>
        <Col sm={9} md={7}>
          <Row className="gx-2">
            <Col>
              <Controller
                control={control}
                name={from_date_name}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                    onChange={(date) => {
                      field.onChange(date)
                      handleFieldChange("from_date", date)
                    }}
                  />
                )}
                rules={{
                  required: "From date must be required."
                }}
              />
              <span className="text-danger">
                {errors[from_date_name] && errors[from_date_name].message}
              </span>
            </Col>
            <Col sm="auto" className="align-items-center">
              to
            </Col>
            <Col>
              <Controller
                control={control}
                name={to_date_name}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                    onChange={(date) => {
                      field.onChange(date)
                      handleFieldChange("to_date", date)
                    }}
                  />
                )}
                rules={{
                  required: "To date must be required.",
                  validate: {
                    afterFromDate: value => {
                      const fromDate = getValues(from_date_name);
                      return isAfter(value, fromDate) || 'To date must be after from date.';

                      // if (!isAfter(value, fromDate)) {
                      //   return 'To date must be after from date.';
                      // }
                    
                      // if (!isAfter(value, getValues("date_of_return"))) {
                      //   return 'To date must be the same as the date of return for the trip.';
                      // }
                    },
                  },
                }}
              />
              <span className="text-danger">
                {errors[to_date_name] && errors[to_date_name].message}
              </span>
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={from_location_name}>
        <Form.Label column sm={3} className="text-lg-end">
          Location
        </Form.Label>
        <Col sm={9} md={7}>
          <Row className="gx-2">
            <Col>
              <Form.Control
                name={from_location_name}
                placeholder="From"
                className="fs--1"
                {...register(from_location_name, {
                  required: "From location must be required."
                })}
                onChange={(e) => {
                  const value = e.target.value
                  setValue(from_location_name, value)
                  handleFieldChange("from_location", value)
                }}
              />
              <span className="text-danger">
                {errors[from_location_name] && errors[from_location_name].message}
              </span>
            </Col>
            <Col sm="auto">
              to
            </Col>
            <Col>
              <Form.Control
                name={to_location_name}
                placeholder="To"
                className="fs--1"
                {...register(to_location_name, {
                  required: "To location must be required."
                })}
                onChange={(e) => {
                  const value = e.target.value
                  setValue(to_location_name, value)
                  handleFieldChange("to_location", value)
                }}
              />
              <span className="text-danger">
                {errors[to_location_name] && errors[to_location_name].message}
              </span>
            </Col>
          </Row>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId={description_name}>
        <Form.Label column sm={3} className="text-lg-end">
          Description
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            as="textarea"
            rows={5}
            name={description_name}
            placeholder="Description"
            className="fs--1"
            {...register(description_name, {
              required: "Description must be required."
            })}
            onChange={(e) => {
              const value = e.target.value
              setValue(description_name, value)
              handleFieldChange("description", value)
            }}
          />
          <span className="text-danger">
            {errors[description_name] && errors[description_name].message}
          </span>
        </Col>
      </Form.Group>

      <div className="border-dashed border-bottom my-4"></div>
    </>
  )
});

BookingMultipleStopTripForm.propTypes = {
  index: PropTypes.number.isRequired,
  transit: PropTypes.object.isRequired,
  control: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  removeTransit: PropTypes.func.isRequired,
  updatedTransit: PropTypes.func.isRequired
}

export default BookingMultipleStopTripForm;