import { useDispatch } from "react-redux";
import { Form, Modal, Button } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { deletePIFVersion } from "redux/crm/project/pifSlice";
import { useNavigate } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

const PIFVersionDeleteConfirmation = ({showModal, setshowModal, projectId, buildingId, adaptationId, pif }) => {
  const {handleSubmit } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pifId = pif?.id;

  const changeOrderUrl = `/crm/projects/${projectId}/buildings/${buildingId}/change-orders/${adaptationId}`;

  const closeModal = () => {
    setshowModal(false);
  }

  const onSubmit = async () => {

    try {
      await dispatch(deletePIFVersion({
        projectId,
        buildingId,
        adaptationId,
        pifId
      }));

      toast.success(`Version ${pif?.version} was successful deleted.`);
      
      navigate(changeOrderUrl);
      closeModal();
    }
    catch(error) {
      toast.error(error.message);
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Delete PIF version confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Are you sure to delete PIF <strong>version {pif?.version}</strong>?
          </IconAlert>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit"><FaCheckCircle className="text-success" /> Yes</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

PIFVersionDeleteConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  buildingId: PropTypes.number.isRequired,
  adaptationId: PropTypes.number.isRequired,
  pif: PropTypes.object.isRequired
}

export default PIFVersionDeleteConfirmation;