import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplications } from 'redux/integration/applicationSlice';
import AgGridTable from "components/ag-grid/AgGridTable"
import {Link} from "react-router-dom"
import ApplicationHeader from './ApplicationHeader';

const ApplicationList = () => {
  const { data } = useSelector((state) => state.integration_application);

  const dispatch = useDispatch();
  const applicationUrl = "/integration/applications";

  useEffect(() => {
    dispatch(getApplications())
  }, []);

  const columnDefs = useMemo(() => [
    { 
      headerName: 'ID#',
      field: 'id',
      minWidth: 80,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: params => {
        const applicationId = params.value
        return <Link to={`${applicationUrl}/${applicationId}`}>{applicationId}</Link>
      }
    },
    { 
      headerName: 'Application name',
      field: 'name',
      minWidth: 200
    },
    { 
      headerName: 'Redirect uri',
      field: 'redirect_uri',
      minWidth: 300
    },
    { 
      headerName: 'Scopes',
      field: 'scopes',
      minWidth: 140,
    },
    { 
      headerName: 'Created date',
      field: 'created_date',
      minWidth: 130,
    }
  ], [])

  return (
    <>
      <ApplicationHeader />

      <AgGridTable
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        selectedMultipe={false}
        refData={() => {}}
        serverSide={false}
        filter={true}
      />
    </>
  );
};

export default ApplicationList;