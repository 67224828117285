import axios from "axios";
import authAxios from "./authAxios";

const apiAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to refresh the access token
const refreshToken = async () => {
  try {
    const refresh_token = localStorage.getItem('refresh_token');

    if (!refresh_token) {
      throw new Error("No refresh token available");
    }

    const response = await authAxios.post("/refresh_token", { refresh_token });
    const { access_token, expires_at } = response.data;

    localStorage.setItem('access_token', access_token);
    localStorage.setItem('token_expiration', expires_at);

    return access_token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

// Request interceptor - attach token and handle expiration
apiAxios.interceptors.request.use(
  async (config) => {

    let token = localStorage.getItem('access_token');
    const expirationTime = localStorage.getItem('token_expiration');
    const now = Math.floor(Date.now() / 1000); // Should be in seconds

    if (token && expirationTime && now >= expirationTime) {
      try {
        token = await refreshToken(); // Get new token
      } catch (error) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expiration');

        window.location.href = `${process.env.REACT_APP_CLIENT_URL}/authentication/login`;
        return Promise.reject(error);
      }
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor - handle token expiration and errors
apiAxios.interceptors.response.use(
  response => response,
  async (error) => {
    const status = error?.response?.status;

    switch(status) {
      case "401":
        window.location.href = `${process.env.REACT_APP_CLIENT_URL}/authentication/login`
        break;
      case "403":
        window.location.href = `${process.env.REACT_APP_CLIENT_URL}/errors/403`;
        break;
      case "404":
        window.location.href = `${process.env.REACT_APP_CLIENT_URL}/errors/404`;
        break;
      default:
        // Handle other error statuses as needed
        console.error('Unexpected error status:', status);
        break;
    }

    return Promise.reject(error);
  }
);

export default apiAxios;
