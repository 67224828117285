import coverSrc from 'assets/img/generic/4.jpg';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React, { useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ChangeAvatar from './ChangeAvatar';

const ProfileBanner = () => {
  const {data: user} = useSelector(state => state.profile_user)
  const {employee} = user

  const [showChangeAvatar, setShowChangeAvatar] = useState(false)

  const handleChangeAvatar = () => {
    setShowChangeAvatar(true)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="position-relative min-vh-25 mb-7">
          <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
          {user.avatar_url ? (
            <Avatar
              size="5xl"
              className="avatar-profile cursor-pointer"
              src={user.avatar_url}
              title="Click here to change Avatar"
              mediaClass="img-thumbnail shadow-sm"
              onClick={handleChangeAvatar}
            />
          ) : (
            <Avatar
              size="5xl"
              className="avatar-profile cursor-pointer"
              name={user.name}
              title="Click here to change Avatar"
              mediaClass="img-thumbnail shadow-sm"
              onClick={handleChangeAvatar}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Row className="mx-2">
            <Col lg={8}>
              <h4 className="mb-1">
                {employee?.firs_name} {employee?.last_name} <VerifiedBadge />
              </h4>
              <h5 className="fs-0 fw-normal">
                {employee?.title}
              </h5>
              <p className="text-500">{employee?.site}</p>
              <div className="border-dashed border-bottom my-4 d-lg-none" />
            </Col>
            <Col className="ps-2 ps-lg-3">
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showChangeAvatar && (
        <ChangeAvatar
          showModal={showChangeAvatar}
          setshowModal={setShowChangeAvatar}
          user={user}
        />
      )}
    </>
  );
};

export default ProfileBanner;
