import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { setExpandAll, setCollapseTabStatus, resetExpandAll } from "redux/crm/project/pifSlice";
import PIFVersionDeleteConfirmation from './PIFVersionDeleteConfirmation';
import PropTypes from 'prop-types';

const PIFVersionHeader = ({
  project,
  building,
  adaptation,
  pif,
  title
}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const {collapseTabStatus} = useSelector(state => state.crm_project_pif);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const projectId = project?.id;
  const buildingId = building?.id;
  const adaptationId = adaptation?.id;
  const pifId = pif?.id;

  const projectUrl = `/crm/projects/${projectId}`;
  const changeOrderUrl = `${projectUrl}/buildings/${buildingId}/change-orders/${adaptationId}`;
  const pifUrl = pif ? `${changeOrderUrl}/pifs-versions/${pifId}` : null;

  const {
    allow_edit,
    allow_delete
  } = pif?.permissions || {};

  const handlePrintPIF = () => {
    navigate(`${pifUrl}/print`, { state: { pif } })
  }

  const handleEditPIF = () => {
    navigate(`${pifUrl}/edit`, { state: { pif } })
  }

  const handleBackChangeOrder = () => {
    navigate(changeOrderUrl)
  }

  const handleDeletePIF = () => {
    setShowConfirmationDelete(true);
  }

  const handleExpandAll = () => {
    if (collapseTabStatus) {
      dispatch(setExpandAll())
    }
    else {
      dispatch(resetExpandAll())
    }

    dispatch(setCollapseTabStatus(!collapseTabStatus))
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={collapseTabStatus ? faAngleDoubleDown : faAngleDoubleUp}
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleExpandAll}
            >
              <span className="d-none d-xl-inline-block ms-1">Collapse All</span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="print"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handlePrintPIF}
            >
              <span className="d-none d-xl-inline-block ms-1">Print PIF</span>
            </IconButton>

            {allow_edit && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="edit"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleEditPIF}
              >
              <span className="d-none d-xl-inline-block ms-1">Edit</span>
              </IconButton>
            )}
           
            <IconButton
              variant="falcon-default"
              size="sm"
              //icon="arrow-left"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBackChangeOrder}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Back change order
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="primary" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>
                <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_delete && (
                    <Dropdown.Item className="text-danger" onClick={handleDeletePIF}>
                      <FontAwesomeIcon icon="trash-alt" className="text-danger" /> Delete PIF
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
        <Card.Body className="fs--1">
          <h5 className="text-center mb-3 fw-bold text-primary">{title} PROJECT INFORMATION FORM (PIF)</h5>
          
          <Row className="gx-3">
            <Col className="text-end">Project :</Col>
            <Col className="text-primary fw-bold">{project?.name}</Col>
          </Row>

          <Row className="gx-3">
            <Col className="text-end">Building :</Col>
            <Col className="text-primary fw-bold">{building?.name}</Col>
          </Row>

          <Row className="gx-3">
            <Col className="text-end">Version :</Col>
            <Col className="text-primary fw-bold">{pif?.version}</Col>
          </Row>

          <Row className="gx-3">
            <Col className="text-end">Status :</Col>
            <Col className="text-primary fw-bold text-capitalize">{pif?.co_status}</Col>
          </Row>
        </Card.Body>
      </Card>

      {showConfirmationDelete && (
        <PIFVersionDeleteConfirmation
          showModal={showConfirmationDelete}
          setshowModal={setShowConfirmationDelete}
          projectId={projectId}
          buildingId={buildingId}
          adaptationId={adaptationId}
          pif={pif}
        />
      )}
    </>
  );
};

PIFVersionHeader.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  pif: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default PIFVersionHeader;