import React from 'react';
import { useLocation } from "react-router-dom"
import TransactionForm from "./TransactionForm"

const NewCarBooking = () => {
  const location = useLocation()
  const {travel} = location.state || {} //get travel booking

  return (
    <TransactionForm title="Request car booking" travel={travel} />
  )
}

export default NewCarBooking