import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getCarsBySite, updateDriverTran } from "redux/booking/car/carBookingSlice";
import { FaCar } from "react-icons/fa";
import PropTypes from 'prop-types';
import UploadDocument from "components/upload/UploadDocument";

const TransactionDetailDriverTransFormModal = ({showModal, setshowModal, transaction, driverTran}) => {
  const { control, register, handleSubmit, formState: {errors}, setValue } = useForm();
  const [images, setImages] = useState([]);
  const fileTypes = "image/*";
  const fileNote = "Only image/* files type are allowed. The file size is maximum of 20Mb";

  const transactionId = transaction.id;
  const dispatch = useDispatch();

  const params = {
    from_date: transaction.from_date,
    to_date: transaction.to_date,
    site_id: transaction.site_id
  }

  useEffect(() => {
    fetchCarList()
  }, []);

  const fetchCarList = async () => {
    try {
      const response = await dispatch(getCarsBySite(params))
      const data = response.payload

      const options = data && data.map((item) => ({ value: item.id, label: item.name }))
      setCarOptions(options)
    }
    catch(error) {
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    if(driverTran) {
      if (!driverTran.departure_begin_time) {
        setValue("departure_begin_time", dateConvert(transaction.from_date));
      }
      else {
        setValue("departure_begin_time", dateConvert(driverTran.departure_begin_time))
      }

      if (!driverTran.departure_end_time) {
        setValue("departure_end_time", dateConvert(transaction.to_date));
      }
      else {
        setValue("departure_end_time", dateConvert(driverTran.departure_end_time))
      }

      setValue("departure_begin_kilometer", driverTran.departure_begin_kilometer)
      setValue("departure_end_kilometer", driverTran.departure_end_kilometer)
      setValue("notes", driverTran.notes)

      setValue("parking_fee", driverTran.parking_fee)

      // Object.entries(driverTran).forEach(([key, value]) => {
      //   setValue(key, value)
      // })
    }
    else {
      setValue("departure_begin_time", dateConvert(transaction.from_date));
      setValue("departure_end_time", dateConvert(transaction.to_date));
    }
  }, [setValue, driverTran])

  const dateConvert = (date) => {
    const newDate = new Date(date)
    return newDate
  }

  const onSubmit = (data) => {
    data = {
      ...data, images
    }

    console.log(data)

    try {
      if (driverTran) {
        const driverTranId = driverTran.id;
        dispatch(updateDriverTran({transactionId, driverTranId, data}));
        closeModal();
      }
      else {
        toast.error("Error update driver.")
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-primary border-0">
            <Modal.Title className="fs-0 text-white" id="contained-modal-title-vcenter">Edit transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-3">
            <h6 className="mb-4">
              Driver name: <span className="text-primary">{driverTran?.driver.name}/<FaCar /> {driverTran?.car.name} ({driverTran?.car.model})</span>
            </h6>

            <Form.Group as={Row} className="mb-3" controlId="departure_begin_time">
              <Form.Label column sm={3} className="text-lg-end">
                Departure time
              </Form.Label>
              <Col sm={9} md={7}>
                <Row className="g-2">
                  <Col>
                    <Controller
                      control={control}
                      name="departure_begin_time"
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          className="form-control fs--1"
                          placeholderText="From"
                          timeIntervals={5}
                          //dateFormat="h:mm aa"
                          dateFormat="dd-MM-yyyy h:mm aa"
                          showTimeSelect
                          showTimeSelectOnly
                        />
                      )}
                      rules={{
                        required: 'Departure begin time must be required'
                      }}
                    />
                  </Col>
                  <Col>
                    <Controller
                      control={control}
                      name="departure_end_time"
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          className="form-control fs--1"
                          placeholderText="To"
                          timeIntervals={5}
                          //dateFormat="h:mm aa"
                          dateFormat="dd-MM-yyyy h:mm aa"
                          showTimeSelect
                          showTimeSelectOnly
                        />
                      )}
                      rules={{
                        required: 'Departure end time must be required'
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="departure_begin_kilometer">
              <Form.Label column sm={3} className="text-lg-end">
                Departure (km)
              </Form.Label>
              <Col sm={9} md={7}>
                <Row className="g-2">
                  <Col>
                    <Form.Control
                      type="number"
                      name="departure_begin_kilometer"
                      min={0}
                      placeholder="From"
                      className="fs--1"
                      {...register('departure_begin_kilometer', {
                        required: 'Departure from (km) must be required'
                      })}
                    />
                    <span className="text-danger">
                      {errors.departure_begin_kilometer && errors.departure_begin_kilometer.message}
                    </span>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="departure_end_kilometer"
                      min={0}
                      placeholder="To"
                      className="fs--1"
                      {...register('departure_end_kilometer', {
                        required: 'Departure to (km) must be required'
                      })}
                    />
                    <span className="text-danger">
                      {errors.departure_end_kilometer && errors.departure_end_kilometer.message}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="parking_fee">
              <Form.Label column sm={3} className="text-lg-end">
                Parking fee (VND)
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="number"
                  name="parking_fee"
                  placeholder="Parking fee"
                  className="fs--1"
                  {...register("parking_fee")}
                />
                <span className="text-danger">
                  {errors.parking_fee && errors.parking_fee.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="notes">
              <Form.Label column sm={3} className="text-lg-end">
                Notes
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="notes"
                  placeholder="Notes"
                  className="fs--1"
                  {...register("notes")}
                />
                <span className="text-danger">
                  {errors.notes && errors.notes.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="attachment">    
              <Form.Label column sm={3} className="text-lg-end">
                Attachments
              </Form.Label>
              <Col sm={9} md={7}>
                <UploadDocument
                  files={images}
                  setFiles={setImages}
                  fileTypes={fileTypes}
                  note={fileNote}
                />
              </Col>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit">Update</Button>
            <Button size="sm" variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

TransactionDetailDriverTransFormModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  driverTran: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default TransactionDetailDriverTransFormModal;