import { Row, Col, Spinner, Tab, Card, Nav } from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import { FaRegListAlt } from 'react-icons/fa';
import { MdOutlineSummarize } from 'react-icons/md';
import HeaderReport from "../../HeaderReport";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NumberOfQuotationsBecameJobs from "./NumberOfQuotationsBecameJobs";
import JobRatio from "./JobRatio";
import QuotationDetail from "./QuotationDetail";
import { getQuotationDashboard } from "redux/crm/report/quotationSlice";
import QuotationOverview from "./QuotationOverview";
import NumberWeightSummary from "../../NumberWeightSummary";
import IconAlert from "components/common/IconAlert";
import QuoationThisWeek from "./QuotationThisWeek";
import QuotationLastWeek from "./QuotationLastWeek";
import React from 'react';

const QuotationDashboard = () => {
  const { loading, dashboardData } = useSelector((state) => state.crm_report_quotation);

  const currentDate = moment();
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(currentDate);

  const dispatch = useDispatch();

  const params = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD')
  };

  useEffect(() => {
    dispatch(getQuotationDashboard(params))
  }, [startDate, endDate]);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const {
    current_period,
    previous_period,
    summary= {},
    this_week,
    last_week,
    data=[]
  } = dashboardData || {};

  const totalNumberOfProjects = current_period?.number_of_projects;

  const quotation_has_jobs = summary["Jobs (J)"] || {};
  const ratio_job_percent = totalNumberOfProjects > 0 ? (((quotation_has_jobs?.number_of_projects || 0) / totalNumberOfProjects) * 100)?.toFixed(0) : 0

  return (
    <>
      <HeaderReport
        title="Quotation"
        subtitle="Dashboard"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDatesChange={handleDatesChange}
      />

      {/* Summary quotation total number and weight */}
      <Row className="g-3 mb-3">
        <Col sm={3} xxl={6}>
          <NumberWeightSummary
            title="Number of quotations"
            current_value={totalNumberOfProjects}
            previous_value={previous_period?.number_of_projects}
          />
        </Col>
        <Col sm={3} xxl={6}>
          <NumberWeightSummary
            title="Estimated weight (M.T)"
            current_value={current_period?.weight}
            previous_value={previous_period?.weight}
          />
        </Col>
        <Col sm={3} xxl={6}>
          <NumberOfQuotationsBecameJobs
            data={quotation_has_jobs}
            number_of_quotations={totalNumberOfProjects}
          />
        </Col>
        <Col sm={3} xxl={6}>
          <JobRatio data={ratio_job_percent} />
        </Col>
      </Row>

      {/* This week */}
      <Row className="g-3 mb-3">
        <Col>
          <QuoationThisWeek data={this_week} />
        </Col>
        <Col>
          <QuotationLastWeek data={last_week} />
        </Col>
      </Row>

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="quotation-overview">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="quotation-overview"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineSummarize className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Overview</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="quotation-detail"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="quotation-overview">
                    <QuotationOverview originalData={dashboardData} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="quotation-detail">
                    <QuotationDetail data={data} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>

            <IconAlert variant="warning" dismissible className="m-3">
              <p className="mb-0 fs--1">The case of projects with multiple buildings requiring quotations at different times, <strong className="text-danger">the number of projects recorded in the report is based on the date of the first quotation request, for the normally calculated volume</strong>.</p>
            </IconAlert>
          </Card>
        </Col>
      </Row>

    </>
  )
};

export default QuotationDashboard;