import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { formatNumber, formatNumberWord, rgbaColor } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { Link } from 'react-router-dom';
import BasicECharts from 'components/common/BasicEChart';
import PropTypes from "prop-types";

import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getMonthName = (monthNumber) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber];
}

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    formatter: function(params) {
      const date = new Date(params[0].axisValue);
      const formattedDate = `${date.getDate()}-${getMonthName(date.getMonth())}`;
      return formattedDate;
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

const QuotationWeekItem = ({weekData, title}) => {
  const this_week_data = weekData?.data;
  const summaryData = weekData?.summary;

  const chartDataNumber = summaryData && Object.entries(summaryData).map(([date, { number_of_projects }]) => [date, number_of_projects]);
  const chartDataWeight = summaryData && Object.entries(summaryData).map(([date, { weight }]) => [date, weight]);

  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body className="pb-0">
        <Row>
          <Col md={6} sm={12}>
            <h6 className="text-700">Number of projects</h6>
            <Flex justifyContent="between" className="my-2">
              <span className="h3 text-primary">{formatNumberWord(weekData?.number_of_projects)}</span>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataNumber)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
          <Col md={6} sm={12}>
            <h6 className="text-700">Weight</h6>
            <Flex justifyContent="between" className="my-2">
              <span className="h3 text-primary">{formatNumberWord(weekData?.weight)} M.T</span>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataWeight)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
        </Row>
        <div className="mx-ncard">
          <SimpleBarReact>
            <Table className="fs--1 mb-0 overflow-hidden">
              <thead className="bg-100 text-800">
                <tr>
                  <th className="text-nowrap">Quote number</th>
                  <th className="text-nowrap text-end">Weight</th>
                  <th className="text-nowrap text-end">Sales region</th>
                </tr>
              </thead>
              <tbody>
                {this_week_data && this_week_data.length > 0 ? (
                  this_week_data.map(item => (
                    <tr key={item.id}>
                      <td className="text-truncate">
                        <Link to={`/crm/projects/${item.id}`} target="_blank">{item.q_number}</Link>
                      </td>
                      <td className="text-end text-primary">{formatNumber(item.est_weight)} M.T</td>
                      <td className="text-end">{item.sales_unit_region}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center text-primary">No data found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>
      </Card.Body>
    </Card>
  )
};

QuotationWeekItem.propTypes = {
  weekData: PropTypes.shape({
    data: PropTypes.object,
    summary: PropTypes.object,
    number_of_projects: PropTypes.number,
    weight: PropTypes.number
  }).isRequired,
  title: PropTypes.string.isRequired
}

export default QuotationWeekItem;