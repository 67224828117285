import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { Card, Tab, Nav, Col, Row } from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import BDS from "./BDS";
import React from 'react';
import PropTypes from 'prop-types';

const Areas = ({
  projectData,
  useHookForm,
  areaObject,
  newArea,
  editArea,
  cloneArea,
  deleteArea,
  openWallData,
  newOpenWall,
  editOpenWall,
  deleteOpenWall,
  roofExtensionData,
  newRoofExtension,
  editRoofExtension,
  deleteRoofExtension,
  canopyData,
  newCanopy,
  editCanopy,
  deleteCanopy,
  fasciaData,
  newFascia,
  editFascia,
  deleteFascia,
  frameOpeningData,
  newFrameOpening,
  editFrameOpening,
  deleteFrameOpening,
  mezzanineData,
  newMezzanine,
  editMezzanine,
  deleteMezzanine,
  craneInformationData,
  newCraneInformation,
  editCraneInformation,
  deleteCraneInformation,
}) => {

  const { areaData } = areaObject
  const data = {
    projectData,
    useHookForm
  }

  return (
    <>
      <Card>
        <Flex className="m-3 fs--1" justifyContent="start">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-2"
            iconAlign="middle"
            className="me-1"
            onClick={newArea}
          >
            <span className="d-none d-xl-inline-block ms-1">New Area</span>
          </IconButton>

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="copy"
            transform="shrink-2"
            iconAlign="middle"
            className="me-1"
            onClick={cloneArea}
          >
            <span className="d-none d-xl-inline-block ms-1">Clone Area</span>
          </IconButton>
        </Flex>

        <Tab.Container id="areaTabs" defaultActiveKey={`PIF-Area${areaData?.length > 0 ? areaData[0].id : ''}`}>
          <Row className="g-0">
            <Col sm={2}>
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav variant="pills" className="nav-tabs border-0 flex-nowrap tab-pif-form flex-column">
                    {areaData && areaData.map((area) => (
                      <Nav.Item key={area?.id}>
                        <Nav.Link
                          eventKey={`PIF-Area${area?.id}`}
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <h6 className="text-600 mb-0 ms-1">Area {area?.id}</h6>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Card.Header>
              </SimpleBar>
            </Col>
            <Col sm={10}>
              <Card.Body className="bg-light">
                <Tab.Content>
                  {areaData && areaData.map((area) => (
                    <Tab.Pane key={area.id} eventKey={`PIF-Area${area.id}`}>
                      <BDS
                        data={data}
                        area={area}
                        editArea={() => editArea(area)}
                        deleteArea={() => deleteArea(area)}
                        openWallData={openWallData}
                        newOpenWall={newOpenWall}
                        editOpenWall={editOpenWall}
                        deleteOpenWall={deleteOpenWall}
                        roofExtensionData={roofExtensionData}
                        newRoofExtension={newRoofExtension}
                        editRoofExtension={editRoofExtension}
                        deleteRoofExtension={deleteRoofExtension}
                        canopyData={canopyData}
                        newCanopy={newCanopy}
                        editCanopy={editCanopy}
                        deleteCanopy={deleteCanopy}
                        fasciaData={fasciaData}
                        newFascia={newFascia}
                        editFascia={editFascia}
                        deleteFascia={deleteFascia}
                        frameOpeningData={frameOpeningData}
                        newFrameOpening={newFrameOpening}
                        editFrameOpening={editFrameOpening}
                        deleteFrameOpening={deleteFrameOpening}
                        mezzanineData={mezzanineData}
                        newMezzanine={newMezzanine}
                        editMezzanine={editMezzanine}
                        deleteMezzanine={deleteMezzanine}
                        craneInformationData={craneInformationData}
                        newCraneInformation={newCraneInformation}
                        editCraneInformation={editCraneInformation}
                        deleteCraneInformation={deleteCraneInformation}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Card.Body>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
    </>
  )
};

Areas.propTypes = {
  projectData: PropTypes.object.isRequired,
  useHookForm: PropTypes.object.isRequired,
  areaObject: PropTypes.object.isRequired,
  newArea: PropTypes.func.isRequired,
  editArea: PropTypes.func.isRequired,
  cloneArea: PropTypes.func.isRequired,
  deleteArea: PropTypes.func.isRequired,
  openWallData: PropTypes.func.isRequired,
  newOpenWall: PropTypes.func.isRequired,
  editOpenWall: PropTypes.func.isRequired,
  deleteOpenWall: PropTypes.func.isRequired,
  roofExtensionData: PropTypes.func.isRequired,
  newRoofExtension: PropTypes.func.isRequired,
  editRoofExtension: PropTypes.func.isRequired,
  deleteRoofExtension: PropTypes.func.isRequired,
  canopyData: PropTypes.func.isRequired,
  newCanopy: PropTypes.func.isRequired,
  editCanopy: PropTypes.func.isRequired,
  deleteCanopy: PropTypes.func.isRequired,
  fasciaData: PropTypes.func.isRequired,
  newFascia: PropTypes.func.isRequired,
  editFascia: PropTypes.func.isRequired,
  deleteFascia: PropTypes.func.isRequired,
  frameOpeningData: PropTypes.func.isRequired,
  newFrameOpening: PropTypes.func.isRequired,
  editFrameOpening: PropTypes.func.isRequired,
  deleteFrameOpening: PropTypes.func.isRequired,
  mezzanineData: PropTypes.func.isRequired,
  newMezzanine: PropTypes.func.isRequired,
  editMezzanine: PropTypes.func.isRequired,
  deleteMezzanine: PropTypes.func.isRequired,
  craneInformationData: PropTypes.func.isRequired,
  newCraneInformation: PropTypes.func.isRequired,
  editCraneInformation: PropTypes.func.isRequired,
  deleteCraneInformation: PropTypes.func.isRequired
}

export default Areas;