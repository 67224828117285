import { Accordion, Collapse, Card, Row, Col, Form, Button, Table } from "react-bootstrap"
import { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { CRM_CO_TYPE_OF_CO } from "app_config";
import Flex from "components/common/Flex";
import AsyncSelect from 'react-select/async';
import { debouncedFilterEmployees } from "helpers/debounce";
import AddChangeForm from "./AddChangeForm";
import { MdDelete, MdEdit } from "react-icons/md";
import { createChangeOrder, updateChangeOrder } from "redux/crm/project/changeOrderSlice";
import React from 'react';
import PropTypes from 'prop-types';

const ChangeOrderForm = ({project, building, changeOrder, title}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue} = useForm()
  const [activeKey, setActiveKey] = useState(Array.from({ length: 10 }, (_, index) => index.toString()).filter(key => key !== "5"))
  const [showChangeForm, setShowChangeForm] = useState(false)
  const [descriptionChanges, setDescriptionChanges] = useState([])
  const [descriptionChange, setDescriptionChange] = useState(null)
  //const [files, setFiles] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setValue("co", building?.next_change_order || '01')
    setValue("entry_date", new Date())
    setValue("area_date", new Date())
    setValue("head_date", new Date())
    setValue("cse_head", building?.cse_head)
    
    if (changeOrder) {
      Object.entries(changeOrder.change_order_form).forEach(([key, value]) => {
        switch(key) {
          case "entry_date":
          case "area_date":
          case "head_date":
          case "cse_head":
            setValue(key, new Date(value))
            break
          case "description_of_changes":
            setDescriptionChanges(value)
            break
          default:
            setValue(key, value)
            break
        }
      })
    }
  }, [project, building, setValue])

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const employeeOptions = (inputValue, callback) => {
    debouncedFilterEmployees(inputValue, callback)
  }

  const defaultCSEHeadOption = useMemo(() => {
    const name = changeOrder?.change_order_form.cse_head || building?.cse_head
    return name ? { value: name, label: name } : null
  }, [building, changeOrder])

  const defaultCSMHeadOption = useMemo(() => {
    const name = changeOrder?.change_order_form.csm_head
    return name ? { value: name, label: name } : null
  }, [building, changeOrder])

  const defaultCSEAreaOption = useMemo(() => {
    const name = changeOrder?.change_order_form.cse_area
    return name ? { value: name, label: name } : null
  }, [building, changeOrder])

  const defaultCSMAreaOption = useMemo(() => {
    const name = changeOrder?.change_order_form.csm_area
    return name ? { value: name, label: name } : null
  }, [building, changeOrder])

  const handleAddChange = () => {
    setShowChangeForm(true)
    setDescriptionChange(null)
  }

  const handleEditChange = (editedItem) => {
    setShowChangeForm(true)
    setDescriptionChange(editedItem)
  }

  const handleDeleteChange = (deletedItem) => {
    setDescriptionChanges((prevChanges) =>
      prevChanges.filter((change) => change.change_no !== deletedItem.change_no)
    )
    toast.success(`Change no#${deletedItem.change_no} removed.`)
  }

  const validateDescriptionOfChange = () => {
    if (!descriptionChanges || descriptionChanges.length === 0)
    {
      toast.error("Description of changes must be required.")
      return false
    }
    return true
  }

  const onSubmit = async (formData) => {
    if (!validateDescriptionOfChange()) {
      return
    }

    const data = {
      co: formData.co,
      entry_date: formData.entry_date,
      cse_area: formData.cse_area,
      csm_area: formData.csm_area,
      cse_head: formData.cse_head,
      csm_head: formData.csm_head,
      change_order_form: {
        ...formData,
        description_of_changes: descriptionChanges
      },
     // change_order_documents: files || null
    }

    console.log(data)

    try {
      const projectId = project.id
      const buildingId = building.id
      let changeOrderId = null

      if (changeOrder) {
        changeOrderId = changeOrder.id
        dispatch(updateChangeOrder({projectId, buildingId, changeOrderId, data}))
      }
      else 
      {
        const response = await dispatch(createChangeOrder({projectId, buildingId, data}))
        changeOrderId = response.payload.data.id
      }

      if (changeOrderId) {
        navigate(`/crm/projects/${projectId}/buildings/${buildingId}/change-orders/${changeOrderId}`)
      }
    }
    catch (error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  return (
    <>
     <Card className="mb-3" bg="falcon-default">
        <Card.Body className="fs--1 justify-content-center">
          <h5 className="text-center fs-3 fw-semi-bold text-uppercase">{title}</h5>
        </Card.Body>
      </Card>
      <Card className="p-4">
        <Collapse in={true}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Accordion
              id="revision-table"
              className="border rounded overflow-hidden fs--1"
              alwaysOpen
              flush
              activeKey={activeKey}
              onSelect={handleAccordionSelect}
            >
              <Card className="rounded-0">
                {/* 1. General Information */}
                <Accordion.Item eventKey="0" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          1. General Information
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-2">
                          <Col>
                            <Row className="mb-3">
                              <Col sm={4}>Job No</Col>
                              <Col className="text-primary">{project?.j_number}</Col>
                            </Row>
                            <Row className="mb-3">
                              <Col sm={4}>Customer</Col>
                              <Col className="text-primary">
                                {project?.customer_name ? project.customer_name : project?.customer.name}
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col sm={4}>Type of C.O</Col>
                              <Col>
                                <Controller 
                                  control={control}
                                  name="type_of_co"
                                  defaultValue={CRM_CO_TYPE_OF_CO[0]}
                                  render={({field}) => (
                                    <Flex direction="column" className="mt-1">
                                      {CRM_CO_TYPE_OF_CO.map((option, index) => (
                                        <Form.Check
                                          key={option}
                                          id={`${option}${index}`}
                                          type="radio"
                                          label={option}
                                          value={option}
                                          className="me-3"
                                          {...field}
                                          defaultChecked={field.value === option}
                                          onChange={() => field.onChange(option)}
                                        />
                                      ))}
                                    </Flex>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="co">
                              <Form.Label column sm={6} className="text-lg-start">
                                This Change Order No.
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="co"
                                  placeholder="Change order number"
                                  disabled
                                  className="fs--1"
                                  {...register("co", {
                                    required: "Change order number must be required."
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.co && errors.co.message}
                                </span>  
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2" controlId="entry_date">
                              <Form.Label column sm={6} className="text-lg-start">
                                Date this C.O. Entered
                              </Form.Label>
                              <Col>
                                <Controller
                                  control={control}
                                  name="entry_date"
                                  rules={{required: "Date this C.O. Entered must be required"}}
                                  render={({ field }) => (
                                    <DatePicker
                                      selected={field.value}
                                      onChange={(date) => field.onChange(date)}
                                      className="form-control fs--1"
                                      placeholderText="dd-MMM-YYYY"
                                      dateFormat="dd-MMM-yyyy"
                                      fixedHeight
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {errors.entry_date && errors.entry_date.message}
                                </span>  
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" controlId="continuation_sheet_attached">
                              <Form.Label column sm={6} className="text-lg-start">
                                Continuation Sheet Attached:
                              </Form.Label>
                              <Col>
                                <Flex justifyContent="between">
                                  <Controller
                                    control={control}
                                    name="continuation_sheet_attached"
                                    render={({ field }) => (
                                      <Form.Check
                                        type="checkbox"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e)}
                                      />
                                    )}
                                  />
                                  <div>Number of sheet: <strong>1</strong></div>
                                </Flex>
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 2. Payment for this CO */}
                <Accordion.Item eventKey="1" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          2. Payment for this CO.
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-2">
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="payment_of_price_co">
                              <Form.Label column sm={4} className="text-lg-start">
                                Price of this C.O.
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="co"
                                  placeholder="Price of this C.O."
                                  className="fs--1"
                                  {...register("payment_of_price_co")}
                                />
                                <span className="text-danger">
                                  {errors.payment_of_price_co && errors.payment_of_price_co.message}
                                </span>  
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Row className="gx-2">
                              <Col>
                                <Controller
                                  control={control}
                                  name="to_be_established"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="To be established"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                              <Col>
                                <Controller
                                  control={control}
                                  name="already_included_contract"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="Already included contract"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="payment_of_mode">
                              <Form.Label column sm={4} className="text-lg-start">
                                Mode of Payment for this C.O.
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="payment_of_mode"
                                  placeholder="Mode of Payment for this C.O"
                                  className="fs--1"
                                  {...register("payment_of_mode")}
                                />
                                <span className="text-danger">
                                  {errors.payment_of_mode && errors.payment_of_mode.message}
                                </span>  
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Row className="gx-2">
                              <Col>
                                <Controller
                                  control={control}
                                  name="amend_lc"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="Amend L/C"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                              <Col>
                                <Controller
                                  control={control}
                                  name="revise_contract"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="Revise contract value"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                              <Col>
                                <Controller
                                  control={control}
                                  name="payment_others"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="Other (specify)"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />

                                <Form.Control
                                  as="textarea"
                                  name="payment_others_specify"
                                  placeholder="Others"
                                  className="fs--1"
                                  {...register("payment_others_specify")}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="customer_will_pay">
                              <Form.Label column sm={4} className="text-lg-start">
                                Customer will Pay
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="customer_will_pay"
                                  placeholder="Customer will Pay"
                                  className="fs--1"
                                  {...register("customer_will_pay")}
                                />
                                <span className="text-danger">
                                  {errors.customer_will_pay && errors.customer_will_pay.message}
                                </span>  
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Row className="gx-2">
                              <Col>
                                <Controller
                                  control={control}
                                  name="fully"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="Fully"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                              <Col>
                                <Controller
                                  control={control}
                                  name="partially"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="Partially"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                              <Col>
                                <Controller
                                  control={control}
                                  name="no_charge"
                                  render={({ field }) => (
                                    <Form.Check
                                      type="checkbox"
                                      checked={field.value}
                                      label="No charge"
                                      onChange={(e) => field.onChange(e)}
                                    />
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 3. Engg. Documents Required after this C.O. */}
                <Accordion.Item eventKey="2" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          3. Engg. Documents Required after this C.O.
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-2">
                          <Col>
                            <Controller
                              control={control}
                              name="desgin_calculation"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Desgin calculation"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="approval_dwgs"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Approval dwgs"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                          </Col>
                          <Col>
                            <Controller
                              control={control}
                              name="re_approval_dwgs"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Re approval dwgs"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="erection_dwgs"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Erection dwgs"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                          </Col>
                          <Col>
                            <Controller
                              control={control}
                              name="effected_sheets_of_approval_dwgs"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Effected sheets of approval dwgs"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="effected_sheets_of_erection_dwgs"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Effected sheets of erection dwgs"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="record_dwgs"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Record dwgs"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 4. This C.O. affects. */}
                <Accordion.Item eventKey="3" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          4. This C.O. affects.
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-2">
                          <Col>
                            <Controller
                              control={control}
                              name="co_affects_design"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Desgin"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                          </Col>
                          <Col>
                            <Controller
                              control={control}
                              name="co_affects_drafting"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Drafting"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                          </Col>
                          <Col>
                            <Controller
                              control={control}
                              name="co_affects_fabrication"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  checked={field.value}
                                  label="Fabrication"
                                  onChange={(e) => field.onChange(e)}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 5. Sales Discount */}
                <Accordion.Item eventKey="4" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          5. Sales Discount
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Form.Group as={Row} className="mb-2" controlId="sales_discount_before">
                          <Form.Label column sm={5} className="text-lg-start">
                            Before this C.O.
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              name="sales_discount_before"
                              placeholder="Before this C.O."
                              className="fs--1"
                              {...register("sales_discount_before")}
                            /> 
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="sales_discount_after">
                          <Form.Label column sm={5} className="text-lg-start">
                            After this C.O
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              name="sales_discount_after"
                              placeholder="After this C.O"
                              className="fs--1"
                              {...register("sales_discount_after")}
                            /> 
                          </Col>
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 6. Impact of Changes */}
                <Accordion.Item eventKey="5" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          6. Impact of Changes
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in STD Ex-works (US$)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in CFR (US$)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in S.B.O. (US$)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in Delivery Period (Weeks)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in Freight (US$)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in Erection (US$)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in Total Contract (US$)
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col>
                             <Form.Check
                              label="Increase"
                              disabled
                             />
                          </Col>
                          <Col>
                             <Form.Check
                              label="Decrease"
                              disabled
                             />
                          </Col>
                          <Col>
                            in Erection Period (Weeks)
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 7. Contract Update (All Prices are in US$) */}
                <Accordion.Item eventKey="6" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          7. Contract Update (All Prices are in US$)
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Table striped>
                          <thead className="bg-200 text-900">
                            <tr>
                              <th className="border">C.O#</th>
                              <th className="border">Date</th>
                              <th className="border">Std. Ex-Works</th>
                              <th className="border">Freight</th>
                              <th className="border">CFR/CIF Job Site</th>
                              <th className="border">SBO</th>
                              <th className="border">Erection</th>
                              <th className="border">Others</th>
                              <th className="border">Total Contract</th>
                            </tr>
                          </thead>
                        </Table>

                        <Row className="mt-2 gx-2">
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="new_delivery_period_weeks">
                              <Form.Label column sm={6} className="text-lg-start">
                                New Delivery Period (Weeks)
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  type="number"
                                  name="new_delivery_period_weeks"
                                  placeholder="weeks"
                                  className="fs--1"
                                  {...register("new_delivery_period_weeks")}
                                /> 
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="new_erection_period_weeks">
                              <Form.Label column sm={6} className="text-lg-start">
                                New Erection Period (Weeks)
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  type="number"
                                  name="new_erection_period_weeks"
                                  placeholder="weeks"
                                  className="fs--1"
                                  {...register("new_erection_period_weeks")}
                                />  
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 8. Description of changes */}
                <Accordion.Item eventKey="7" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          8. Description of changes
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Table striped>
                          <thead className="bg-200 text-900">
                            <tr>
                              <th className="border" width="5%"></th>
                              <th className="border" width="5%">Bldg. No.</th>
                              <th className="border" width="5%">Change No.</th>
                              <th className="border">Description of changes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(descriptionChanges) && descriptionChanges.map((item) => (
                              <tr key={item.id}>
                                <td className="border">
                                  <Button size="sm" variant="default-falcon" className="me-2" onClick={() => handleEditChange(item)}>
                                    <MdEdit className="text-primary cursor-pointer" title="Edit"/>
                                  </Button>
                                  <Button size="sm" variant="default-falcon" onClick={() => handleDeleteChange(item)}>
                                    <MdDelete className="text-danger cursor-pointer" title="Remove"/>
                                  </Button>
                                </td>
                                <td className="border">{item.building_name}</td>
                                <td className="border">{item.change_no}</td>
                                <td className="border">
                                  <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <Flex
                          justifyContent="end"
                          alignItems="center"
                          className="text-primary cursor-pointer fs-0"
                          onClick={handleAddChange}
                        >
                          <span className="circle-dashed">
                            <FontAwesomeIcon icon="plus" />
                          </span>
                          <span className="ms-3">Add change</span>
                        </Flex>

                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 9. Customer service engineer */}
                <Accordion.Item eventKey="8" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          9. Customer service engineer
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <Row className="gx-2 mb-2">
                          <Col sm="auto" className="fw-semi-bold">Area office</Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="cse_area">
                              <Form.Label column sm={6} className="text-lg-end">
                                Customer Service Engineer
                              </Form.Label>
                              <Col sm={6}>
                                <Controller
                                  control={control}
                                  name="cse_area"
                                  rules={{required: "Name must be required"}}
                                  render={() => (
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={employeeOptions}
                                      defaultValue={defaultCSEAreaOption}
                                      closeMenuOnSelect={true}
                                      placeholder='Select...'
                                      classNamePrefix="react-select"
                                      className="w-100"
                                      onChange={(selectedOption) => selectedOption ? setValue("cse_area", selectedOption.value) : null}
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {errors.cse_area && errors.cse_area.message}
                                </span> 
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="csm_area">
                              <Form.Label column sm={6} className="text-lg-end">
                                Customer Service Manager
                              </Form.Label>
                              <Col sm={6}>
                                <Controller
                                  control={control}
                                  name="csm_area"
                                  rules={{required: "Name must be required"}}
                                  render={() => (
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={employeeOptions}
                                      defaultValue={defaultCSMAreaOption}
                                      closeMenuOnSelect={true}
                                      placeholder='Select...'
                                      classNamePrefix="react-select"
                                      className="w-100"
                                      isClearable
                                      onChange={(selectedOption) => selectedOption ? setValue("csm_area", selectedOption.value) : null}
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {errors.csm_area && errors.csm_area.message}
                                </span>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="area_date">
                              <Form.Label column sm={3} className="text-lg-end">
                                Date
                              </Form.Label>
                              <Col sm={9}>
                                <Controller
                                  control={control}
                                  name="area_date"
                                  rules={{required: "Date must be required"}}
                                  render={({ field }) => (
                                    <DatePicker
                                      selected={field.value}
                                      onChange={(date) => field.onChange(date)}
                                      className="form-control fs--1"
                                      placeholderText="dd-MMM-YYYY"
                                      dateFormat="dd-MMM-yyyy"
                                      fixedHeight
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {errors.area_date && errors.area_date.message}
                                </span>  
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="gx-2 mb-2">
                          <Col sm="auto" className="fw-semi-bold">Head office</Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="cse_head">
                              <Form.Label column sm={6} className="text-lg-end">
                                Customer Service Engineer
                              </Form.Label>
                              <Col sm={6}>
                                <Controller
                                  control={control}
                                  name="cse_head"
                                  rules={{required: "Name must be required"}}
                                  render={() => (
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={employeeOptions}
                                      defaultValue={defaultCSEHeadOption}
                                      closeMenuOnSelect={true}
                                      placeholder='Select...'
                                      classNamePrefix="react-select"
                                      className="w-100"
                                      onChange={(selectedOption) => selectedOption ? setValue("cse_head", selectedOption.value) : null}
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {errors.cse_head && errors.cse_head.message}
                                </span> 
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="csm_head">
                              <Form.Label column sm={6} className="text-lg-end">
                                Supervisor
                              </Form.Label>
                              <Col sm={6}>
                                <AsyncSelect
                                  cacheOptions
                                  defaultOptions
                                  loadOptions={employeeOptions}
                                  defaultValue={defaultCSMHeadOption}
                                  closeMenuOnSelect={true}
                                  placeholder='Select...'
                                  classNamePrefix="react-select"
                                  className="w-100"
                                  isClearable
                                  onChange={(selectedOption) => selectedOption ? setValue("csm_head", selectedOption.value) : null}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group as={Row} className="mb-2" controlId="head_date">
                              <Form.Label column sm={3} className="text-lg-end">
                                Date
                              </Form.Label>
                              <Col sm={9}>
                                <Controller
                                  control={control}
                                  name="head_date"
                                  rules={{required: "Date must be required"}}
                                  render={({ field }) => (
                                    <DatePicker
                                      selected={field.value}
                                      onChange={(date) => field.onChange(date)}
                                      className="form-control fs--1"
                                      placeholderText="dd-MMM-YYYY"
                                      dateFormat="dd-MMM-yyyy"
                                      fixedHeight
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {errors.head_date && errors.head_date.message}
                                </span>  
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>

                {/* 10. Attachment */}
                {/* <Accordion.Item eventKey="9" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900 text-primary">
                          8. Attachment
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                    <Accordion.Body className="p-0">
                      <Card.Body className="m-2">
                        <div className="small text-danger mb-1">Only OUTLOOK, PDF, EXCEL, WORD, IMAGE or TEXT files are allowed. The file size is maximum of 100Mb.</div>
                        <UploadDocument files={files} setFiles={setFiles} />
                      </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>
                */}
              </Card>
            </Accordion> 

            <div className="bg-light fixed-bottom border-0 text-center p-3">
              <Button size="sm" type="submit" className="me-2">{changeOrder ? "Update" : "Create"}</Button>
              <Button size="sm" variant="danger" onClick={() => navigate(-1)}>Cancel</Button>
            </div>
          </Form>
        </Collapse>
      </Card>

      {showChangeForm && (
        <AddChangeForm
          showModal={showChangeForm}
          setshowModal={setShowChangeForm}
          building={building}
          descriptionChanges={descriptionChanges}
          setDescriptionChanges={setDescriptionChanges}
          descriptionChange={descriptionChange}
        />
      )}
    </>
  )
};

ChangeOrderForm.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  changeOrder: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default ChangeOrderForm;