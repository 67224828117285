import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import calendar from 'assets/img/icons/spot-illustrations/calendar.svg';
import image from 'assets/img/icons/spot-illustrations/image.svg';
import location from 'assets/img/icons/spot-illustrations/location.svg';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, {useRef, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createFeed } from 'redux/social/feedSlice';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getSize } from 'helpers/utils';
import { FaTags, FaTrash } from "react-icons/fa"
import "./hashtag.scss";
import {toast} from "react-toastify";

const CreatePostForm = () => {
  const [privacy, setPrivacy] = useState('public');
  const [content, setContent] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const maxSize = 50 * 1024 * 1024; //50Mb

  // console.log(`UA: ${window.navigator.geolocation}`);

  // navigator.geolocation.getCurrentPosition(
  //   (position) => {
  //     const { latitude, longitude } = position.coords;
  //     console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  //   },
  //   (error) => {
  //     console.error('Error getting geolocation:', error);
  //   }
  // );

  const {getRootProps, getInputProps} = useDropzone({
    accept: "image/*",
    multiple: true,
    onDrop: (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(file => {
        return !file.name.toLowerCase().endsWith('.exe') && file.size <= maxSize;
      });
      setSelectedFiles(filteredFiles)
    }
  })

  const handleRemove = path => {
    setSelectedFiles(selectedFiles.filter(file => file.path !== path));
  };

  const handleInputChange = () => {
    const inputValue = inputRef.current.innerText;
    let newHashtags = inputValue.split(' ').filter(tag => tag.startsWith('#')).map(tag => tag.trim());
    setHashtags(newHashtags);
  };

  const handleFocus = () => {
    if (inputRef.current.innerText === 'Use # followed by your keyword to create a hashtag') {
      inputRef.current.innerText = '';
    }
  };

  const handleBlur = () => {
    if (inputRef.current.innerText === '') {
      inputRef.current.innerText = 'Use # followed by your keyword to create a hashtag';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        content: content,
        location: 'Ho Chi Minh',
        privacy,
        hashtags: hashtags,
        gallery: selectedFiles,
      };
  
      if (!!content || selectedFiles?.length > 0) {
        await dispatch(createFeed(data));
  
        setSelectedFiles([]);
        setContent('');
      };
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Control
        as="textarea"
        rows={4}
        placeholder="What do you want to talk about?"
        className="shadow-none rounded-0 resize-none border-y-0 px-x1 border-200"
        value={content}
        onChange={e => setContent(e.target.value)}
      />
    
      <div className="m-4">
        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
          <input {...getInputProps()} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your images/video here</p>
          </Flex>
        </div>
        <div className="text-muted my-2 text-end small">The maximum file size allowed is 50MB.</div>
      </div>

      <div className="mt-3 p-3">
        {selectedFiles && selectedFiles.length > 0 && (
          <>
            <h6 className="mb-3">Images will upload detail:</h6>
            {selectedFiles.map(file => (
              <Flex
                alignItems="center"
                className="py-1 border-bottom btn-reveal-trigger fs--1 p-3"
                key={file.path} // Use a unique identifier for the key
              >
                 {file.type.startsWith('image/') && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="me-3"
                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px' }}
                  />
                )}
                
                <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                  <div>
                    <h6>{file.path}</h6>
                    <Flex className="position-relative" alignItems="center">
                      <p className="mb-0 fs--1 text-400 line-height-1">
                        <strong>{getSize(file.size)}</strong>
                      </p>
                    </Flex>
                  </div>
                </Flex>
                <FaTrash className="cursor-pointer" onClick={() => handleRemove(file.path)} />
              </Flex>
            ))}
            <div className="m-3 text-primary fw-bold">Total {selectedFiles.length || 0} files.</div>
          </>
        )}
      </div>

      <Form.Group
        as={Flex}
        alignItems="center"
        className="ps-x1 border border-200"
        controlId="hashtag"
      >
        <Form.Label className="text-nowrap mb-0 me-2">
          <FaTags />
          <span className="fw-medium fs--1"> Hashtag</span>
        </Form.Label>
        <div
          ref={inputRef}
          className="editable-input border-0 fs--1 shadow-none"
          contentEditable="true"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={handleInputChange}
        >
          Use # followed by your keyword to create a hashtag
        </div>
      </Form.Group>
      <div className="hashtags-container text-primary">
        {hashtags.map((tag, index) => (
          <div key={index} className="hashtag">
            {tag}
          </div>
        ))}
      </div>
   

      {/* <Form.Group
        as={Flex}
        alignItems="center"
        className="ps-x1 border border-200"
        controlId="hashtag"
      >
        <Form.Label className="text-nowrap mb-0 me-2">
          <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
          <span className="fw-medium fs--1">Add hashtag</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Help the right person to see"
          className="border-0 fs--1  shadow-none"
          value={hashtags?.join(',')}
          onChange={handleHashTagChange}
        />
      </Form.Group> */}

      <Row className="g-0 mt-3 px-x1 pb-3 justify-content-between">
        <Col>
          <PostButton image={image} title="Image" />
          <PostButton image={calendar} title="Event" />
          <PostButton image={location} title="Check In" />
        </Col>
        <Col xs="auto">
          <Dropdown className="d-inline-block me-1">
            <Dropdown.Toggle
              variant="none"
              size="sm"
              className="px-1 shadow-none"
            >
              <FontAwesomeIcon
                icon={classNames({
                  //users: privacy === 'friends',
                  lock: privacy === 'private',
                  'globe-americas': privacy === 'public'
                })}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2" align="end">
              <Dropdown.Item onClick={() => setPrivacy('public')}>
                Public
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setPrivacy('private')}>
                Private
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setPrivacy('friends')}>
                Friends
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          <Button size="sm" className="px-4 px-sm-5" type="submit">
            Post now!
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const PostButton = ({ image, title }) => (
  <Button
    variant="light"
    size="sm"
    className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1"
  >
    <img src={image} alt="" width={17} />
    <span className="ms-2 d-none d-md-inline-block">{title}</span>
  </Button>
);
PostButton.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CreatePostForm;
