import React, { lazy, Suspense } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab, Button } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { FaChartBar, FaRegListAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LazyQuotationList = lazy(() => import("./quotation/QuotationList"));

const Quotation = () => {
  const { loading } = useSelector((state) => state.crm_report_quotation);

  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    navigate("/crm/reports/quotation-dashboard");
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex">
          <Button size="sm" variant="falcon-default" onClick={handleGoToDashboard} className="ms-auto">
            <FaChartBar /> Go to Dashboard
          </Button>
        </Card.Header>
      </Card>

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="project-schedule">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-schedule"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="project-schedule">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyQuotationList />
                    </Suspense>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Quotation;