import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import authAxios from "helpers/authAxios"
import { toast } from "react-toastify";

export const login = createAsyncThunk(
  "Auth/login",
  async (data) => {
    try {
      const response = await authAxios.post('/login', data )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const loginWithGoogle = createAsyncThunk(
  "Auth/loginWithGoogle",
  async (data) => {
    try {
      const token = data.access_token;
      const requestOptions = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'access_token': `${token}`
        },
        body: JSON.stringify(data)
      }

      const url = `${process.env.REACT_APP_API_URL}/auth/google_oauth2/callback`
      const response = await axios.post(url, requestOptions);
      
      return response.data
    }
    catch (error) {
      if (error && error.message) {
        throw new Error(error.message);
      } else {
        throw new Error('An unknown error occurred when sign with Google.');
      }
    }
  }
)

export const logout = createAsyncThunk(
  "Auth/logout",
  async () => {
    try {
      const token = localStorage.getItem('access_token');

      if (token !== null)
      {
        const response = await authAxios.delete('/logout', { headers: {'Authorization': `Bearer ${token}`}})
        return response.data
      }

      return null
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const verifyToken = createAsyncThunk(
  "Auth/verifyToken",
  async (token) => {
    try {
      const response = await authAxios.post('/login', token )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const verifyRecaptcha = createAsyncThunk(
  "Auth/verifyRecaptcha",
  async (token) => {
    try {
      const response = await authAxios.post('/verify_recaptcha', { token } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const handleAuthSuccess = (state, action) => {
  state.loading = false;
  const { data, access_token, token_expiration, refresh_token } = action.payload;

  if (data) {
    state.currentUser = data;
  
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("token_expiration", token_expiration);
    localStorage.setItem("refresh_token", refresh_token);
  
    toast.success(`Logged in as ${data.email}`);
  }
  else {
    toast.error("Sign in was failed.")
  }
};

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentUser: {},
    loading: false
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true
      })
      .addCase(login.fulfilled, handleAuthSuccess)
      .addCase(verifyToken.pending, (state) => {
        state.loading = true
      })
      .addCase(verifyToken.fulfilled, handleAuthSuccess)
      .addCase(logout.fulfilled, (state) => {
        state.loading = false
        state.currentUser = {}
        localStorage.clear()

        console.log('localStorage cleared');
      })
      .addCase(loginWithGoogle.fulfilled, handleAuthSuccess)
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
});

export const {setCurrentUser} = authSlice.actions;

export default authSlice.reducer;