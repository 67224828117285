import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompetitor, getAllCompetitors } from 'redux/crm/competiorSlice';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import PropTypes from 'prop-types';

const CompetiorList = ({showModal, setshowModal}) => {
  const {data} = useSelector((state) => state.crm_competitor)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const dispatch = useDispatch()

  useEffect( () => {
    dispatch(getAllCompetitors())
  },[dispatch])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleClose = () => {
    closeModal()
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
  }

  const handleOkConfirm = async () => {
   // dispatch(deleteCompetitor(competitor.id))
    setShowConfirmation(false) 
  }

  const handleDelete = () => {
    setShowConfirmation(true)
  }

  const handleEdit = () => {

  }

  const columns = [
    {
      accessor: 'id',
      Header: 'ID#',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'code',
      Header: 'Code',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: (params) => {
        const data = params.data;
        const {allow_edit, allow_delete} = data?.permissions || {};

        return (
          <>
            {allow_edit && (
              <>
                <MdEdit
                  title="Edit"
                  className="me-1"
                  style={{cursor: "pointer"}}
                  onClick={handleEdit}
                />
                | {' '}
              </>
            )}
            {allow_delete && (
              <MdDelete
                title="Delete"
                style={{cursor: "pointer"}}
                onClick={handleDelete}
              />
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <Modal
        className="fs--1"
        show={showModal}
        onHide={() => setshowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        centered
      >
        <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Competitor list</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AdvanceTableWrapper
              columns={columns}
              data={data}
              selection
              sortable
              pagination
              perPage={20}
            >
              <Card className="mb-3">
                <Card.Header>
                  {/* <SalesUnitTableHeader table/> */}
                </Card.Header>
                <Card.Body className="p-0">
                  <Row className="flex-end-center mb-3">
                    <Col xs="auto" sm={6} lg={6}>
                      <AdvanceTableSearchBox table/>
                    </Col>
                  </Row>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </Card.Body>
                <Card.Footer>
                  <AdvanceTablePagination table />
                </Card.Footer>
              </Card>
            </AdvanceTableWrapper>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" variant="falcon-default" onClick={handleClose}>Close</Button>
          </Modal.Footer>
      </Modal>

      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to delete?"
      />
    </>
  )
};

CompetiorList.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired
}

export default CompetiorList;