import React from 'react';
import PropTypes from 'prop-types';
import { FaStar } from "react-icons/fa";

const StarGroup = ({number}) => {
  const stars = [];

  for(let i=0; i < number; i++) {
    stars.push(<FaStar className="text-warning" key={i} />)
  }

  return <span>{stars}</span>
}

StarGroup.propTypes = {
  number: PropTypes.number.isRequired
}

export default StarGroup;