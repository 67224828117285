import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog
} from "redux/authorization/roleSlice";
import ActivityHistory from "components/peb-grid/ActivityHistory";
import React from "react";

const RoleActivity = () => {
  const {activityLogData} = useSelector(state => state.authorization_role);
  const params = useParams();

  return (
    <ActivityHistory
      activityLogData={activityLogData}
      setNestedPage={setNestedPage}
      setNestedPageSize={setNestedPageSize}
      setNestedParams={setNestedParams}
      getActivityLog={getActivityLog}
      roleId={params.id} //dynamic params to activity
    />
  )
}

export default RoleActivity;