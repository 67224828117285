import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { ExportToExcel } from "helpers/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { removeRoleUser } from "redux/authorization/roleSlice";

const RoleDetailInfoUserList = () => {
  const {recordData: role} = useSelector((state) => state.authorization_role)
  const [data, setData] = useState(role.users ? role.users : [])
  const dispatch = useDispatch()

  const columns = useMemo(() => [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: params => {
        const {id, name} = params.row.original;

        return (
          <Link to={`/authorization/users/${id}`} target="_blank">{name}</Link>
        )
      }
    },
    {
      accessor: 'full_of_name',
      Header: 'Full of name'
    },
    {
      accessor: 'site_office',
      Header: 'Site office'
    }
  ], [])

  useEffect(() => {
    setData(role.users)
  }, [role])

  const handleRemoveUser = (selectedRowIds) => {
    let selectedRowsIndex = Object.keys(selectedRowIds)
    if (selectedRowsIndex.length > 0) {
      try {
        selectedRowsIndex = selectedRowsIndex.map(Number)
        const users = data.filter((user, index) => selectedRowsIndex.includes(index))
        const user_ids = users.map(user => user.id)
        const user_names = users.map(user => user.name)
        console.log(user_ids)

        dispatch(removeRoleUser({role, data: {user_ids}}))
        toast.success(`Users ${user_names} was deleted successful.`)
      }
      catch(error) {
        console.log(error.message)
        toast.error(error.message)
      }
    }
    else {
      toast.error("No users selected");
    }
  }

  const handleExportUser = () => {
    ExportToExcel(data, "role_to_uses")
  }

  const BulAction = ({selectedRowIds}) => {
    const selectedRowSize = Object.keys(selectedRowIds).length

    return (
      <>
        <Row className="flex-between-center mb-3">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div className="fs--1 mb-0 text-nowrap py-2 py-xl-0">
              {
                selectedRowSize > 0 ?
                'You have selected ' + selectedRowSize + ' rows' 
                : ''
              }
            </div>
          </Col>
          <Col sm="auto">
            <Button size="sm" variant="danger" className="me-1" title="Remove" onClick={() => handleRemoveUser(selectedRowIds)}>
              <FontAwesomeIcon icon="trash-alt" />
            </Button>

            <Button size="sm" title="Export" onClick={() => handleRemoveUser(handleExportUser)}>
              <FontAwesomeIcon icon="download" />
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={10}
        selection
        selectionColumnWidth={25}
      >
        <BulAction table/>
        <Row className="flex-end-center mb-2">
          <Col sm="auto">
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data?.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </>
  )
}

export default RoleDetailInfoUserList