import { useLocation } from "react-router-dom";
import ChangeOrderForm from "./ChangeOrderForm";
import React from 'react';

const NewChangeOrder = () => {
  const location = useLocation()

  const locationState = location.state
  const {project, building} = locationState

  return (
    <ChangeOrderForm
      project={project}
      building={building}
      title="New change order information sheet"
    />
  )
}

export default NewChangeOrder;