import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DepartmentForm from './DepartmentForm';
import { useDispatch } from 'react-redux';
import { deleteDepartment } from 'redux/organization/departmentSlice';
import PropTypes from "prop-types";
import { FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

const DepartmentHeader = ({departmentsSelected}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showDepartmentForm, setshowDepartmentForm] = useState(false);
  const [titleDepartment, setTitleDepartment] = useState('');
 
  const department = departmentsSelected.at(-1)
  const dispatch = useDispatch()

  const showForm = () => {
    setshowDepartmentForm(true)
  }

  const handleNewDepartment = () => {
    setTitleDepartment("New Department")
    showForm()
  }

  const handleEditDepartment = () => {
    if (!validateDepartmentSelected()) return
    if (!validateDepartmentSelectedMultiple()) return

    setTitleDepartment("Edit Department")
    showForm()
  }

  const handleDeleteDepartment = () => {
    if (!validateDepartmentSelected()) return
    if (!validateDepartmentSelectedMultiple()) return

    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(deleteDepartment(department))
    setShowConfirmationDelete(false) 
  }

  const validateDepartmentSelected = () => {
    if (departmentsSelected.length === 0)
    {
      toast.error('Department has not selected yet.')
      return false
    }
    return true
  }

  const validateDepartmentSelectedMultiple = () => {
    if (departmentsSelected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of department.')
      return false
    }
    return true
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Flex justifyContent={'end'}>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewDepartment}
            >
              <span className="d-none d-xl-inline-block ms-1">New department</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="edit"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleEditDepartment}
            >
              <span className="d-none d-xl-inline-block ms-1">Edit</span>
            </IconButton>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="text-danger" onClick={handleDeleteDepartment}>
                    <FaTrashAlt /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleOkConfirm}
          message="Are you sure to delete?"
        />
      )}
      
      {showDepartmentForm && (
        <DepartmentForm
          showModal={showDepartmentForm}
          setshowModal={setshowDepartmentForm}
          title={titleDepartment}
          department={department}
        />
      )}
      
    </>
  );
};

DepartmentHeader.propTypes = {
  departmentsSelected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

export default DepartmentHeader;
