import { Button, Spinner } from "react-bootstrap"
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settingsImg from 'assets/img/icons/spot-illustrations/settings.png';
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { verifyToken } from "redux/authSlice";
import { getProfileUser } from "redux/profileUserSlice";
import { useSelector } from "react-redux";

const VerifyEmail = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const booking_url = searchParams.get('booking_url')
  const {loading} = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  //const navigate = useNavigate()

  useEffect(() => {
    authentication()
  }, [])

  const authentication = async () => {
    try {
      await dispatch(verifyToken({token}))

      // Delay execution by 5 seconds (5000 milliseconds)
      setTimeout(async () => {
        await dispatch(getProfileUser());

        if (booking_url) {
          //navigate(booking_url, { replace: true }); //is not working with full URL
          window.location.href = booking_url;
        }
      }, 500);
    }
    catch(error){
      console.log(error)
    }
  }

  return (
    <div className="text-center">
      <img
        className="d-block mx-auto mb-4"
        src={settingsImg}
        alt="shield"
        width={100}
      />

      {!loading ? (
        <>
          <h5 className="mb-4 text-danger">Verifing the token!</h5>
          <p className="my-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </p>
        </>
      ) : (
        <>
          <h5 className="mb-4 text-danger">Authentication Verification Failed!</h5>
          <p>
            The token has <strong>expired</strong> or is <strong>incorrect</strong>. Please log in to continue accessing the system.
          </p>
          <Button
            as={Link}
            color="primary"
            size="sm"
            className="mt-3"
            to={`${process.env.REACT_APP_CLIENT_LOGIN_URL}`}
          >
            <FontAwesomeIcon
              icon="chevron-left"
              transform="shrink-4 down-1"
              className="me-1"
            />
              Click here to Login
          </Button>
        </>
      )}
    </div>
  )
}

export default VerifyEmail