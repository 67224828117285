import { Image, Form, Row, Col } from "react-bootstrap";
import logo from "./../../../../assets/img/pebsteel.png";
import { formatDate } from "helpers/utils";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';

const BDS3 = ({pif, area}) => {
  const {project, building, adaptation} = pif;
  const bds3 = area?.bds3 || {};

  const projectUrl = `/crm/projects/${project?.id}`;

  return (
    <table className="fs--1 mt-5">
      <tr>
        <td colSpan="2" className="text-start fw-bold">
          Building Data Sheet 3
        </td>
        <td colSpan="6" className="text-center">
          <div className="fs-2 fw-bold mb-2">PROJECT INFORMATION FORM</div>
        </td>
        <td colSpan="2" className="text-end fw-bold">
          This building only: 3 of 3
        </td>
      </tr>
      <tr>
        <td colSpan="2" rowSpan="2" className="text-center">
          <Image
            fluid
            className="fit-cover rounded"
            src={logo}
            alt="Logo Pebsteel"
            width="130"
            height="120"
          />
        </td>
        <td className="fw-bold">Quote No:</td>
        <td className="text-primary">
          <Link to={projectUrl}>{project?.q_number}</Link>
        </td>
        <td className="fw-bold">Rev No:</td>
        <td className="text-primary">{adaptation.rev}</td>
        <td className="fw-bold">Date:</td>
        <td className="text-primary">{formatDate(adaptation.entry_date)}</td>
        <td colSpan="2" className="text-end fw-bold">
          <Flex justifyContent="around">
            <Form.Check
              type="checkbox"
              label="For Order Entry"
              name="for_order_entry"
              checked={adaptation?.type === "change_order"}
            />

            <Form.Check
              type="checkbox"
              label="For Quotation"
              name="for_quotation"
              checked={adaptation?.type === "revision"}
            />
          </Flex>
        </td>
      </tr>
      <tr>
        <td className="fw-bold">P/Job No:</td>
        <td className="text-primary">
          <Link to={projectUrl}>{building?.j_number}</Link>
        </td>
        <td className="fw-bold">CO#:</td>
        <td className="text-primary">{adaptation.co}</td>
        <td className="fw-bold">Date:</td>
        <td className="text-primary">{formatDate(adaptation.entry_date)}</td>
        <td colSpan="2" className="text-end fw-bold">
          <Flex justifyContent="around">
            <div>
              Bldg No: <span className="text-primary">{building?.name}</span>
            </div>
            <div>
              Area No: <span className="text-primary">{area?.id}</span>
            </div>
          </Flex>
          <h6 className="text-center my-2">This area only : Page 3 of 3</h6>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <Row>
            <Col className="fw-semi-bold">1. Please design the most economical purlin, girt spacing.</Col>
            <Col sm={7}>
              <span className="text-primary">{bds3.purlin_girt_spacing}</span>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <Row>
            <Col className="fw-semi-bold">2. Deflection criterion:</Col>
            <Col sm={7}>
              <Flex justifyContent="around">
                <Form.Check
                  type="checkbox"
                  label="Follow building code"
                  checked={bds3.follow_building_code}
                />

                <Form.Check
                  type="checkbox"
                  label="Follow peb standard"
                  checked={bds3.follow_peb_standard}
                />

                <Form.Check
                  type="checkbox"
                  label="Follow requested of client"
                  checked={bds3.follow_requested_of_client}
                />
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col className="mx-3">Requested of Client:</Col>
            <Col sm={7}>
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: bds3.follow_requested_of_client_specify }}></span>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <Row className="mb-3">
            <Col className="fw-semi-bold">3. Environment conditions:</Col>
            <Col sm={8}>
              <Flex justifyContent="around">
                <Form.Check
                  type="checkbox"
                  label="Fertilizer"
                  checked={bds3.fertilizer}
                />

                <Form.Check
                  type="checkbox"
                  label="Chemical substance"
                  checked={bds3.chemical_substance}
                />

                <Form.Check
                  type="checkbox"
                  label="Animal feed"
                  checked={bds3.animal_feed}
                />

                <Form.Check
                  type="checkbox"
                  label="Animal shed"
                  checked={bds3.animal_shed}
                />

                <Form.Check
                  type="checkbox"
                  label="From the sea 100m"
                  checked={bds3.from_the_sea_100m}
                />
              </Flex>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <Row className="mb-3">
            <Col className="fw-semi-bold">4. How many cranes can operate at the same time in the same bay?</Col>
            <Col sm={7}>
              <Flex justifyContent="around">
                <Form.Check
                  type="checkbox"
                  label="2"
                  checked={bds3.crane_operate_2}
                />

                <Form.Check
                  type="checkbox"
                  label="3"
                  checked={bds3.crane_operate_3}
                />

                <Form.Check
                  type="checkbox"
                  label="4"
                  checked={bds3.crane_operate_4}
                />

                <Form.Check
                  type="checkbox"
                  label="Not Apply"
                  checked={bds3.crane_operate_not_apply}
                />
              </Flex>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <Row className="mb-3">
            <Col className="fw-semi-bold">5. Is Internal X-Bracing allowed?</Col>
            <Col sm={7}>
              <Flex justifyContent="around">
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  checked={bds3.internal_xbracing_yes}
                />
                
                <Form.Check
                  type="checkbox"
                  label="No"
                  checked={bds3.internal_xbracing_no}
                />

                <Form.Check
                  type="checkbox"
                  label="Not Apply"
                  checked={bds3.internal_xbracing_not_apply}
                />
              </Flex>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
          <Row className="mb-3">
            <Col>
              <div className="fw-semi-bold">6. The Top Coat Paint shall be Flat or Matte Finish ( Very low on Reflectivity)</div>
              <div className="mt-3">
                <span dangerouslySetInnerHTML={{ __html: bds3.top_coat_paint }}></span>
              </div>
            </Col>
          </Row>
        </td>
      </tr>
    </table>
  )
};

BDS3.propTypes = {
  pif: PropTypes.object.isRequired,
  area: PropTypes.array.isRequired
}

export default BDS3;