import React from 'react';
import {format} from 'date-fns'
import PropTypes from 'prop-types';

const DateFormatRenderer = (props) => {
  const date = props.value ? format(new Date(props.value), "dd/MM/yy") : null
  
  return (
    <>{date}</>
  );
};

DateFormatRenderer.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired
}

export default DateFormatRenderer;
