import React from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FaRegListAlt } from 'react-icons/fa';
import ApplicationList from './ApplicationList';
import { useSelector } from 'react-redux';

const Applications = () => {
  const { loading } = useSelector((state) => state.integration_application)

  return (
    <>
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="lead-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Applications</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="lead-all">
                    {loading && 
                      <div className="text-center my-2">
                        <Spinner animation="border" />
                      </div>
                    }
                    <ApplicationList />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Applications