import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';
import DateTimeToText from 'helpers/dateTimetoText';
import { markReadNotification } from 'redux/notificationSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const Notification = ({
  avatar,
  message,
  created_at,
  unread,
  flush,
  emoji,
  id
}) => {
  const dispatch = useDispatch()

  const handleMarkRead = () => {
    if (!unread) return

    try {
      dispatch(markReadNotification(id))
      toast.success("Mark as read notifications was successfully.")
    }
    catch(error) {
      toast.error(error.message)
    }
  }

  return (
    <Link
      className={classNames(
        'notification',
        { 'notification-unread': unread, 'notification-flush': flush },
        "rounded-0 border-x-0 border-300 border-bottom-0"
      )}
      onClick={handleMarkRead}
    >
      {avatar && (
        <div className="notification-avatar">
          <Avatar {...avatar} className="me-3" />
        </div>
      )}
      <div className="notification-body">
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(message)} />
        <span className="notification-time">
          {emoji && (
            <span className="me-2" role="img" aria-label="Emoji">
              {emoji}
            </span>
          )}
          <DateTimeToText datetime={created_at} />
        </span>
      </div>
    </Link>
  )
}

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  message: PropTypes.string,
  created_at: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  id: PropTypes.number
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
