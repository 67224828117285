export const calPaginatorRecord = (currentPage, pageSize, totalRows) => {
  const totalPagesCount = Math.ceil(totalRows / pageSize)

  let lastRecords = currentPage * pageSize
  let beginRecords = (lastRecords - pageSize) + 1
  
  if (currentPage === totalPagesCount){
    lastRecords = totalRows
  }

  if (totalRows < pageSize) {
    lastRecords = totalRows
  }

  return { totalPagesCount, beginRecords, lastRecords }
}