import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { closeLostProject, lostReasonProject } from "redux/crm/projectSlice";
import Select from "react-select";
import { getProjectBuildings } from "redux/crm/project/quotationSlice";

const CloseLostForm = ({showModal, setshowModal, project}) => {
  const {control, handleSubmit, formState: {errors}, setError, setValue} = useForm();
  const [selectedIds, setSelectedIds] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [lostOptions, setLostOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchBuildings();
    fetchLostReason();
  }, []);

  useEffect(() => {
    setSelectedIds([...buildingData.map(item => item.id)]);
  }, [buildingData]);

  const fetchBuildings = async () => {
    const response = await dispatch(getProjectBuildings({ projectId: project.id }));
    const data = response.payload.rows;

    if (data) {
      // Extract the attributes and map to id and name
      const newData = data.map(item => ({
        id: item.attributes.id,
        name: item.attributes.name
      }));

      setBuildingData(newData);
    }
  }

  const fetchLostReason = async () => {
    const response = await dispatch(lostReasonProject());
    const data = response.payload;
    const options = data && data.map(item => ({value: item.name, label: item.name}));
    setLostOptions(options);
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCheckboxChange = (itemId) => {
    const updatedIds = selectedIds.includes(itemId)
      ? selectedIds.filter((id) => id !== itemId)
      : [...selectedIds, itemId];

    setSelectedIds(updatedIds)
  }

  const handleSelectAll = (selectAll) => {
    const allIds = buildingData.map(item => item.id);
    setSelectedIds(selectAll ? allIds : []);
  }

  const validateSelectBuilding = () => {
    if (selectedIds && selectedIds.length === 0) {
      setError("proj_ids", {
        type: "manual",
        message: "Building must be required."
      })
      //toast.error("Building must be required.")
      return false
    }
    return true
  }

  const onSubmit = async (data) => {
    console.log(data)

    if (validateSelectBuilding()) {
      try {

        data = {
          proj_ids: selectedIds,
          reason: data.reason,
        }

        await dispatch(closeLostProject({projectId: project.id, data}));
        closeModal();
      }
      catch(error) {
        console.log(error.message)
        toast.error(error.message)
      }
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Close lost building!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            pecify the lost buildings for the project <span className="fw-semi-bold">{project.name}</span>.
          </IconAlert>

          <Form.Group as={Row}>
            <Form.Label column sm={4} className="text-lg-end"></Form.Label>
            <Col sm={8} md={7}>
              <Controller
                control={control}
                name="select-all"
                render={({ field }) => (
                  <Form.Check
                    type="checkbox"
                    label="Select for all buildings."
                    checked={field.value || selectedIds.length === buildingData.length}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      handleSelectAll(e.target.checked);
                    }}
                  />
                )}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2 align-items-center" controlId="proj_ids">
            <Form.Label column sm={4} className="text-lg-end">
              Buildings
            </Form.Label>
            <Col sm={8} md={7}>
              <Row className="gx-2 mt-3">
                {buildingData && buildingData.map(item => (
                  <Col key={item.id}>
                    <Controller
                      control={control}
                      name={String(item.id)}
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label={item.name}
                          checked={field.value || selectedIds.includes(item.id)}
                          onChange={(value) => {
                            field.onChange(value)
                            handleCheckboxChange(item.id)
                          }}
                        />
                      )}
                    />
                  </Col>
                ))}
                <span className="text-danger">
                  {errors.proj_ids && errors.proj_ids.message}
                </span>
              </Row>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="reason">
            <Form.Label column sm={4} className="text-lg-end">
              Reason
            </Form.Label>
            <Col>
              <Controller
                control={control}
                name="reason"
                render={({ field }) => (
                  <Select
                    //{...field}
                    closeMenuOnSelect={true}
                    options={lostOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => setValue('reason', selectedOption.value, {shouldValidate: true})}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Reason must be required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.reason && errors.reason.message}
              </span>
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" variant="primary" type="submit">Update to Lost</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

CloseLostForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

export default CloseLostForm;