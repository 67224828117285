import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Accordion, Card, Form, Row, Col, Table, Dropdown } from "react-bootstrap"
import IconButton from "components/common/IconButton";
import TinymceEditor from "components/common/TinymceEditor";
import { CRM_PIF_ROOF_PANEL_COLOR, CRM_PIF_RV_TYPE } from "app_config";
import Select from "react-select"
import BDSGeneralInformation from "./BDSGeneralInformation";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

const BDS2 = ({
  data,
  roofExtensionData,
  newRoofExtension,
  editRoofExtension,
  deleteRoofExtension,
  canopyData,
  newCanopy,
  editCanopy,
  deleteCanopy,
  fasciaData,
  newFascia,
  editFascia,
  deleteFascia,
  frameOpeningData,
  newFrameOpening,
  editFrameOpening,
  deleteFrameOpening,
  mezzanineData,
  newMezzanine,
  editMezzanine,
  deleteMezzanine,
  craneInformationData,
  newCraneInformation,
  editCraneInformation,
  deleteCraneInformation
}) => {
  const {bds2: initialKeys} = useSelector(state => state.crm_project_pif.initialKeys)
  const [activeKey, setActiveKey] = useState(initialKeys);

  const {area} = data
  const {control, register, Controller, setValue} = data.useHookForm

  const areaId = area.id
  const {bds2} = area

  useEffect(() => {
    setActiveKey(initialKeys);
  }, [initialKeys]);

  useEffect(() => {
    if (bds2) {
      Object.entries(bds2).forEach(([key, value]) => {
        setValue(`bds2_${key}_${area.id}`, value)
      })
    }
  }, [bds2, setValue]);

  const roofPaintColorOptions = useMemo(() => (
    CRM_PIF_ROOF_PANEL_COLOR.map((option) => ({value: option, label: option}))
  ))
  const defaultRoofPaintColorOption = bds2 ? { value: bds2.roof_monitor_panel_and_color, label: bds2.roof_monitor_panel_and_color } : null

  const rvTypeOptions = useMemo(() => (
    CRM_PIF_RV_TYPE.map((option) => ({value: option, label: option}))
  ))
  const defaultRvTypeOption = bds2 ? { value: bds2.ridge_vent_rv_type, label: bds2.ridge_vent_rv_type } : null

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <>
      <Collapse in={true}>
        <Accordion
          id="revision-table"
          className="border rounded overflow-hidden fs--1"
          alwaysOpen
          flush
          activeKey={activeKey}
          onSelect={handleAccordionSelect}
        >
          <Card className="rounded-0">
            <Accordion.Item eventKey="0" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      General Information
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <BDSGeneralInformation data={data} />
                  <h6 className="text-center my-2">This area only : Page 2 of 3</h6>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 12. Roof Extension */}
            <Accordion.Item eventKey="1" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      12. Roof Extension
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newRoofExtension(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Roof Extension</span>
                    </IconButton>
                  </Flex>

                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (M)</th>
                          <th className="border" rowSpan={2}>Length (M)</th>
                          <th className="border" colSpan={2}>Soffit panel</th>
                          <th className="border" rowSpan={2}>Insulated</th>
                          <th className="border" rowSpan={2}>Description</th>
                        </tr>
                        <tr className="text-center" style={{height: "10px"}}>
                          <td className="border">By</td>
                          <td className="border">Description</td>
                        </tr>
                      </thead>
                      <tbody>
                        {roofExtensionData && roofExtensionData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editRoofExtension(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteRoofExtension(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.soffit_panel_by}</td>
                            <td className="border">{item.soffit_panel_description}</td>
                            <td className="border">{item.insulated}</td>
                            <td className="border">{item.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_roof_extension_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 13. Canopy */}
            <Accordion.Item eventKey="2" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      13. Canopy
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newCanopy(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Canopy</span>
                    </IconButton>
                  </Flex>

                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (M)</th>
                          <th className="border" rowSpan={2}>Length (M)</th>
                          <th className="border" rowSpan={2}>Clear height (M)</th>
                          <th className="border" rowSpan={2}>Eave condition</th>
                          <th className="border" rowSpan={2}>Slope</th>
                          <th className="border" colSpan={3}>Roof panel</th>
                          <th className="border" colSpan={3}>Endwall panel</th>
                          <th className="border" colSpan={3}>Soffit panel</th>
                        </tr>
                        <tr className="text-center" style={{height: "10px"}}>
                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>
                        </tr>
                      </thead>
                      <tbody>
                        {canopyData && canopyData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editCanopy(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteCanopy(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                          </td>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.clear_height}</td>
                            <td className="border">{item.eave_condition}</td>
                            <td className="border">{item.slope}</td>

                            <td className="border">{item.roof_panel_by}</td>
                            <td className="border">{item.roof_panel_description}</td>
                            <td className="border">{item.roof_panel_color}</td>

                            <td className="border">{item.end_wall_panel_by}</td>
                            <td className="border">{item.end_wall_panel_description}</td>
                            <td className="border">{item.end_wall_panel_color}</td>

                            <td className="border">{item.soffit_panel_by}</td>
                            <td className="border">{item.soffit_panel_description}</td>
                            <td className="border">{item.soffit_panel_color}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
        
                  <Row className="mt-3">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_capony_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 14. Fascia */}
            <Accordion.Item eventKey="3" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      14. Fascia
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newFascia(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Fascia</span>
                    </IconButton>
                  </Flex>
            
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Type</th>
                          <th className="border" rowSpan={2}>Height (M)</th>
                          <th className="border" rowSpan={2}>Length (M)</th>
                          <th className="border" rowSpan={2}>Proj. (M)</th>

                          <th className="border" colSpan={3}>Fascia panel</th>
                          <th className="border" colSpan={3}>Soffit panel</th>
                          <th className="border" colSpan={3}>Backup panel</th>
                        </tr>
                        <tr className="text-center" style={{height: "10px"}}>
                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>
                        </tr>
                      </thead>
                      <tbody>
                        {fasciaData && fasciaData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editFascia(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteFascia(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.fascia_type}</td>
                            <td className="border">{item.height}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.proj}</td>
    
                            <td className="border">{item.fasica_panel_by}</td>
                            <td className="border">{item.fasica_panel_description}</td>
                            <td className="border">{item.fasica_panel_color}</td>

                            <td className="border">{item.soffit_panel_by}</td>
                            <td className="border">{item.soffit_panel_description}</td>
                            <td className="border">{item.soffit_panel_color}</td>

                            <td className="border">{item.backup_panel_by}</td>
                            <td className="border">{item.backup_panel_description}</td>
                            <td className="border">{item.backup_panel_color}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
        
                  <Row className="mt-3">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_fascia_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 15. Frame Opening */}
            <Accordion.Item eventKey="4" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      15. Frame Opening
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newFrameOpening(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Frame Opening</span>
                    </IconButton>
                  </Flex>
               
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (mm)</th>
                          <th className="border" rowSpan={2}>Height (mm)</th>
                          <th className="border" rowSpan={2}>For</th>
                          <th className="border" rowSpan={2}>Doors, window,…By</th>
                          <th className="border" rowSpan={2}>Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        {frameOpeningData && frameOpeningData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editFrameOpening(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteFrameOpening(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="border">{item.height}</td>
                            <td className="border">{item.frame_opening_for}</td>
                            <td className="border">{item.door_window_by}</td>
                            <td className="border">{item.location}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_frame_opening_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 16. Mezzanine */}
            <Accordion.Item eventKey="5" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      16. Mezzanine
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm={7}>
                      <Row className="gx-2">
                        <Col sm="auto">PEB to Supply :</Col>
                        <Col>
                          <Flex justifyContent="between">
                            <Controller
                              control={control}
                              name={`bds2_mezzinine_peb_supply_cols_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Cols"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds2_mezzinine_peb_supply_beams_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Beams"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds2_mezzinine_peb_supply_joists_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Joists"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds2_mezzinine_peb_supply_deck_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Deck"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds2_mezzinine_peb_supply_handrails_mezz_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Handrails Mezz."
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={5}>
                      <Row className="gx-2">
                        <Col sm="auto">
                          <Controller
                            control={control}
                            name={`bds2_mezzinine_apply_load_only_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Apply loads only"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            name={`bds2_mezzinine_apply_load_only_specify_${area.id}`}
                            placeholder="Apply loads only"
                            className="fs--1"
                            {...register(`bds2_mezzinine_apply_load_only_specify_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newMezzanine(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Mezzanine</span>
                    </IconButton>
                  </Flex>

                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>Mz no</th>
                          <th className="border" rowSpan={2}>DL (kN/m2)</th>
                          <th className="border" rowSpan={2}>LL (kN/m2)</th>
                          <th className="border" rowSpan={2}>Coll (kN/m2)</th>
                          <th className="border" rowSpan={2}>Area (m2)</th>
                          <th className="border" rowSpan={2}>Clearance (mm)</th>
                          <th className="border" colSpan={2}>Floor</th>
                          <th className="border" rowSpan={2}>Stair Type</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (mm) (IN/IN ofStringer)</th>
                          <th className="border" colSpan={2}>Landing</th>
                          <th className="border" rowSpan={2}>Tread Type</th>
                          <th className="border" rowSpan={2}>Hand Rail</th>
                        </tr>
                        <tr className="text-center">
                          <th className="border">Type</th>
                          <th className="border">Total thickness (mm)</th>

                          <th className="border">Mid</th>
                          <th className="border">Top</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mezzanineData && mezzanineData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editMezzanine(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteMezzanine(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.mz_no}</td>
                            <td className="border">{item.dl}</td>
                            <td className="border">{item.ll}</td>
                            <td className="border">{item.coll}</td>
                            <td className="border">{item.area}</td>
                            <td className="border">{item.clearance}</td>
                            <td className="border">{item.floor_type}</td>
                            <td className="border">{item.floor_t}</td>
                            <td className="border">{item.stair_type}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="text-center border">
                              <Form.Check
                                name={`landing_mid_${item.id}`}
                                checked={item.landing_mid}
                              />
                            </td>
                            <td className="text-center border">
                              <Form.Check
                                name={`landing_top_${item.id}`}
                                checked={item.landing_top}
                              />
                            </td>
                            <td className="border">{item.tread_type}</td>
                            <td className="border">{item.hand_rail}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3 mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_mezzanine_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_mezzinine_concrete_floor_thickness_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Is Concrete floor thickness included finished floor?"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                     <Row className="gx-2 align-items-center">
                        <Col sm="auto">Finish floor thickness (mm):</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_mezzinine_finish_floor_thickness_${area.id}`}
                            placeholder="Finish floor thickness (mm)"
                            className="fs--1"
                            {...register(`bds2_mezzinine_finish_floor_thickness_${area.id}`)}
                          />
                        </Col>
                     </Row>
                    </Col>
                  </Row>
                  <Row className="gx-2 mb-2 align-items-center">
                    <Col sm="auto">Edge of mezzanine slab is expanded to out of :</Col>
                    <Col>
                      <Form.Control
                        name={`bds2_mezzanine_edge_slab_${area.id}`}
                        placeholder="Edge of mezzanine slab is expanded to out of"
                        className="fs--1"
                        {...register(`bds2_mezzanine_edge_slab_${area.id}`)}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 17. Roof Monitor */}
            <Accordion.Item eventKey="6" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      17. Roof Monitor
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Width at throat (mm):</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_roof_monitor_width_${area.id}`}
                            placeholder="Width at throat (mm)"
                            className="fs--1"
                            {...register(`bds2_roof_monitor_width_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Height (mm):</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_roof_monitor_height_${area.id}`}
                            placeholder="Height (mm)"
                            className="fs--1"
                            {...register(`bds2_roof_monitor_height_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Roof Slope:</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_roof_monitor_slope_${area.id}`}
                            placeholder="Roof Slope"
                            className="fs--1"
                            {...register(`bds2_roof_monitor_slope_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Eave Condition:</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_roof_monitor_eave_condition_${area.id}`}
                            placeholder="Eave Condition"
                            className="fs--1"
                            {...register(`bds2_roof_monitor_eave_condition_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={8}>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Roof panel & color:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds2_roof_monitor_panel_and_color_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={roofPaintColorOptions}
                                defaultValue={defaultRoofPaintColorOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds2_roof_monitor_panel_and_color_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3 gx-2 align-items-center">
                    <Col sm="auto">Wall Condition:</Col>
                    <Col>
                      <Form.Control
                        name={`bds2_roof_monitor_wall_condition_${area.id}`}
                        placeholder="Wall Condition"
                        className="fs--1"
                        {...register(`bds2_roof_monitor_wall_condition_${area.id}`)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_roof_monitor_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />     
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 18. Ridge Vent */}
            <Accordion.Item eventKey="7" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      18. Ridge Vent
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2 gx-2">
                    <Col sm="auto">
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">RV Type:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds2_ridge_vent_rv_type_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={rvTypeOptions}
                                defaultValue={defaultRvTypeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds2_ridge_vent_rv_type_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="align-items-center mt-2 ms-3">
                      <Controller
                        control={control}
                        name={`bds2_ridge_vent_non_standard_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="NON-STANDARD"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 gx-2">
                    <Col sm="auto">
                      <div className="mb-1">Quantity:</div>
                      <Form.Control
                        name={`bds2_ridge_vent_qty_${area.id}`}
                        placeholder="Quantity"
                        className="fs--1"
                        {...register(`bds2_ridge_vent_qty_${area.id}`)}
                      />
                    </Col>
                    <Col>
                      <div className="mb-1">Length:</div>
                      <Form.Control
                        name={`bds2_ridge_vent_length_${area.id}`}
                        placeholder="Length"
                        className="fs--1"
                        {...register(`bds2_ridge_vent_length_${area.id}`)}
                      />
                    </Col>
                    <Col>
                      <div className="mb-1">Roof panel:</div>
                      <Form.Control
                        name={`bds2_ridge_vent_roof_panel_${area.id}`}
                        placeholder="Roof panel"
                        className="fs--1"
                        {...register(`bds2_ridge_vent_roof_panel_${area.id}`)}
                      />
                    </Col>
                    <Col>
                      <div className="mb-1">Color:</div>
                      <Form.Control
                        name={`bds2_ridge_vent_color_${area.id}`}
                        placeholder="Color"
                        className="fs--1"
                        {...register(`bds2_ridge_vent_color_${area.id}`)}
                      />
                    </Col>
                    <Col>
                      <div className="mb-1">Remark:</div>
                      <Form.Control
                        name={`bds2_ridge_vent_remark_${area.id}`}
                        placeholder="Remark"
                        className="fs--1"
                        {...register(`bds2_ridge_vent_remark_${area.id}`)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_ridge_vent_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />     
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 19. Crane Information */}
            <Accordion.Item eventKey="8" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      19. Crane Information
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm="auto">Scope of Supply:</Col>
                    <Col>
                      <Flex justifyContent="between">
                        <Controller
                          control={control}
                          name={`bds2_crane_only_apply_loads_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Only Apply Loads,"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bds2_crane_beams_brackets_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Crane Beams & Brackets,"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bds2_crane_rails_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Rails,"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bds2_crane_system_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Crane system,"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bds2_crane_bracket_only_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Bracket only,"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Flex>
                    </Col>
                  </Row>
                  <Row className="mb-2 gx-2">
                    <Col sm="auto">
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Power Supply. Volts:</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_crane_power_supply_volt_${area.id}`}
                            placeholder="Power Supply. Volts "
                            className="fs--1"
                            {...register(`bds2_crane_power_supply_volt_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Hz:</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_crane_hz_${area.id}`}
                            placeholder="Hz"
                            className="fs--1"
                            {...register(`bds2_crane_hz_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Phases:</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_crane_phasenly_${area.id}`}
                            placeholder="Phases"
                            className="fs--1"
                            {...register(`bds2_crane_phase_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Manufacturer:</Col>
                        <Col>
                          <Form.Control
                            name={`bds2_crane_manufacturer_${area.id}`}
                            placeholder="Manufacturer"
                            className="fs--1"
                            {...register(`bds2_crane_manufacturer_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newCraneInformation(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Crane Information</span>
                    </IconButton>
                  </Flex>
            
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>No.</th>
                          <th className="border" rowSpan={2}>Crane Type</th>
                          <th className="border" colSpan={3}>Span</th>
                          <th className="border" colSpan={3}>Run length</th>
                          <th className="border" rowSpan={2}>Capacity (M.T)</th>
                          <th className="border" rowSpan={2}>Wheel Load (kN)</th>
                          <th className="border" rowSpan={2}>Wheel Base (mm)</th>
                          <th className="border" rowSpan={2}>Bumper (mm)</th>
                          <th className="border" rowSpan={2}>Min. Hook Height</th>
                          <th className="border" rowSpan={2}>Top of Crane Beam</th>
                          <th className="border" rowSpan={2}>Bridge Weight (kg)</th>
                          <th className="border" rowSpan={2}>Trolley Weight (kg)</th>
                          <th className="border" colSpan={2}>Operaion</th>
                        </tr>
                        <tr className="text-center">
                          <th className="border">W1 (m)</th>
                          <th className="border">From (GL)</th>
                          <th className="border">To (GL)</th>

                          <th className="border">(m)</th>
                          <th className="border">From (GL)</th>
                          <th className="border">To (GL)</th>

                          <th className="border">Pendant</th>
                          <th className="border">Cab</th>
                        </tr>
                      </thead>
                      <tbody>
                        {craneInformationData && craneInformationData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editCraneInformation(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteCraneInformation(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.crane_sr}</td>
                            <td className="border">{item.crane_type}</td>
                            <td className="border">{item.span_w1}</td>
                            <td className="border">{item.span_from_gl}</td>
                            <td className="border">{item.span_to_gl}</td>
                            <td className="border">{item.run_length}</td>
                            <td className="border">{item.run_length_from_gl}</td>
                            <td className="border">{item.run_length_to_gl}</td>
                            <td className="border">{item.capacity}</td>
                            <td className="border">{item.wheel_load}</td>
                            <td className="border">{item.wheel_base}</td>
                            <td className="border">{item.bumper}</td>
                            <td className="border">{item.min_hook_height}</td>
                            <td className="border">{item.top_of_crane_beam}</td>
                            <td className="border">{item.bridge_weight}</td>
                            <td className="border">{item.trolley_weight}</td>

                            <td className="border text-center"><Form.Check checked={item.operation_pendant} /></td>
                            <td className="border text-center"><Form.Check checked={item.operation_cab} /></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3 mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds2_crane_information_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      Attached
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="between">
                    <Controller
                      control={control}
                      name={`bds2_attached_for_this_building_${area.id}`}
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="For This Building/ Area Only,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`bds2_attached_bldg_data_sheet3_${area.id}`}
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="BLDG.Data Sheet 3,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`bds2_attached_bldg_data_sheet4_${area.id}`}
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="BLDG.Data Sheet 4,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Flex>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            </Card>
          </Accordion>
        </Collapse>
    </>
  )
};

BDS2.propTypes = {
  data: PropTypes.object.isRequired,
  roofExtensionData: PropTypes.func.isRequired,
  newRoofExtension: PropTypes.func.isRequired,
  editRoofExtension: PropTypes.func.isRequired,
  deleteRoofExtension: PropTypes.func.isRequired,
  canopyData: PropTypes.func.isRequired,
  newCanopy: PropTypes.func.isRequired,
  editCanopy: PropTypes.func.isRequired,
  deleteCanopy: PropTypes.func.isRequired,
  fasciaData: PropTypes.func.isRequired,
  newFascia: PropTypes.func.isRequired,
  editFascia: PropTypes.func.isRequired,
  deleteFascia: PropTypes.func.isRequired,
  frameOpeningData: PropTypes.func.isRequired,
  newFrameOpening: PropTypes.func.isRequired,
  editFrameOpening: PropTypes.func.isRequired,
  deleteFrameOpening: PropTypes.func.isRequired,
  mezzanineData: PropTypes.func.isRequired,
  newMezzanine: PropTypes.func.isRequired,
  editMezzanine: PropTypes.func.isRequired,
  deleteMezzanine: PropTypes.func.isRequired,
  craneInformationData: PropTypes.func.isRequired,
  newCraneInformation: PropTypes.func.isRequired,
  editCraneInformation: PropTypes.func.isRequired,
  deleteCraneInformation: PropTypes.func.isRequired
};

export default BDS2;