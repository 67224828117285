import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deleteSalesUnit, deactivateSalesUnit } from 'redux/crm/salesUnitSlice';
import PropTypes from 'prop-types';
import {FaLock, FaTrashAlt} from "react-icons/fa";

const SalesUnitHeader = ({ selected }) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const salesUnit = selected.at(-1);

  const validateSalesUnitSelected = () => {
    if (selected.length === 0)
    {
      toast.error('Sales unit has not selected yet.')
      return false
    }
    return true
  }

  const handleNewSalesUnit = () => {
    navigate("/crm/sales-units/new");
  }

  const handleDeleteSalesUnit = () => {
    if (!validateSalesUnitSelected()) return

    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(deleteSalesUnit(salesUnit.id))
    setShowConfirmationDelete(false) 
  }

  const handleDeactivate = () => {
    dispatch(deactivateSalesUnit(salesUnit.id))
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewSalesUnit}
            >
              <span className="d-none d-xl-inline-block ms-1">New Sales Unit</span>
            </IconButton>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="text-primary" onClick={handleDeactivate}>
                    <FaLock /> {salesUnit ? "Deactivate" : "Active"}
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="text-danger" onClick={handleDeleteSalesUnit}>
                    <FaTrashAlt /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleOkConfirm}
          message="Are you sure to delete?"
        />
      )}
    </>
  );
};

SalesUnitHeader.propTypes = {
  selected: PropTypes.array
}

export default SalesUnitHeader;
