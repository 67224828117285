import { useDispatch } from "react-redux";
import { Form, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import IconAlert from "components/common/IconAlert";
import { deleteFeed } from "redux/social/feedSlice";
import React from 'react';
import PropTypes from 'prop-types';

const FeedDeleteConfirmation = ({showModal, setshowModal, feedId }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    setshowModal(false);
  }

  const handleSubmit = () => {
    try {
      dispatch(deleteFeed(feedId));
      closeModal();
    }
    catch(error) {
      toast.error(error.message);
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Delete confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Area you sure to delete this post?
          </IconAlert>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="primary">Yes</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

FeedDeleteConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  feedId: PropTypes.number.isRequired
}

export default FeedDeleteConfirmation;