import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState, Suspense, lazy, useEffect } from 'react';
import { Card, Dropdown, DropdownButton, ButtonGroup, Row, Col, Spinner, Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { BsPencilSquare  } from "react-icons/bs";
import { MdDelete, MdEditNotifications, MdOutlineScheduleSend } from "react-icons/md";
import { FaLock, FaPlus, FaShareSquare, FaUndo } from 'react-icons/fa';
import ChangeOrderStatus from './ChangeOrderStatus';
import ChangeOrderDetailMessageAction from './ChangeOrderDetailMessageAction';
import PropTypes from 'prop-types';
import { downloadCOForm, closeChangeOrder } from 'redux/crm/project/changeOrderSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const LazyCopyPIFForm = lazy(() => import('components/crm/pif/CopyPIFForm'));
const LazyChangeOrderDeleteConfirm = lazy(() => import('./ChangeOrderDeleteConfirm'));
const LazyChangeOrderRequestConfirmToCSD = lazy(() => import('./confirmation/ChangeOrderRequestConfirmToCSD'));
const LazyChangeOrderRevoke = lazy(() => import('./confirmation/ChangeOrderRevoke'));
const LazyChangeOrderRequestRevisedToCSD = lazy(() => import('./confirmation/ChangeOrderRequestRevisedToCSD'));
const LazyChangeOrderRequestFullSchedule = lazy(() => import('./ChangeOrderFinalRequestSchedule'));
const LazyChangeOrderSendFullScheduleToSales = lazy(() => import('./confirmation/ChangeOrderSendFullScheduleToSales'));
const LazyChangeOrderLock = lazy(() => import('./confirmation/ChangeOrderLock'));
const LazyChangeOrderUnLock = lazy(() => import('./confirmation/ChangeOrderUnLock'));

const ChangeOrderDetailHeader = ({changeOrder}) => {
  const [showChangeOrderDeleteConfirm, setShowChangeOrderDeleteConfirm] = useState(false);
  const [showCopyPIFForm, setShowCopyPIFForm] = useState(false);
  const [requestConfirmationToCSD, setRequestConfirmationToCSD] = useState(false);
  const [revokeConfirmation, setRevokeConfirmation] = useState(false);
  const [requestReviseToCSD, setRequestReviseToCSD] = useState(false);

  const [requestFullSchedule, setRequestFullSchedule] = useState(false);
  const [titleFullSchedule, setTitleFullSchedule] = useState("");
  const [isSchedule, setIsSchedule] = useState(false);
  const [isClose, setIsClose] = useState(changeOrder.closed);

  const [sendFullScheduleToSales, setSendFullScheduleToSales] = useState(false);
  const [lockCO, setLockCO] = useState(false);
  const [unlockCO, setUnlockCO] = useState(false);
  const [loading, setLoading] = useState(false);

  const {project, building, permissions} = changeOrder || {};
  const navigate = useNavigate();
  
  const projectId = project?.id;
  const buildingId = building?.id;
  const changeOrderId = changeOrder?.id;

  const projectUrl = "/crm/projects";
  const changeOrderUrl = `${projectUrl}/${projectId}/buildings/${buildingId}/change-orders`;
  const pifUrl = `${projectUrl}/${projectId}/buildings/${buildingId}/adaptations/${changeOrderId}/pifs`;

  const pifId = changeOrder?.pif_id;
  const dispatch = useDispatch();

  const {
    allow_create_co,
    allow_edit_co_form,
    allow_delete,
    allow_request_confirm,
    allow_request_revise,
    allow_revoke,
    allow_create_pif,
    allow_view_pif,
    allow_copy_pif,
    allow_final_enter_job_request,
    allow_final_enter_job_schedule,
    allow_final_enter_job_release,
    allow_close_co
  } = permissions || {}

  useEffect(() => {
    console.log("Change order render!");
  }, [changeOrder]);

  // const handlePrintCO = () => {
  //   navigate(`${changeOrderUrl}/${changeOrderId}/print`, {state: {changeOrder}})
  // }

  const handlePrintCO = async () => {
    try {
      setLoading(true)
      await dispatch(downloadCOForm({
        projectId,
        buildingId,
        changeOrderId,
      })).unwrap();
    }
    catch(error) {
      toast.error(error.message);
    }
    finally {
      setLoading(false)
    }
  }

  const handleEditCOForm= () => {
    navigate(`${changeOrderUrl}/${changeOrderId}/edit`, {state: {changeOrder}})
  }

  const handleNewPIF = () => {
    navigate(`${pifUrl}/new-pif`, {state: {project, building, adaptation: changeOrder}})
  }

  const handleViewPIF = () => {
    window.open(`${pifUrl}/${pifId}?type=change-order`, '_blank');
  }

  const handleCopyPIF = () => {
    setShowCopyPIFForm(true)
  }

  const handleProject = () => {
    navigate(`${projectUrl}/${projectId}`)
  }

  const handleDeleteChangeOrder= () => {
    setShowChangeOrderDeleteConfirm(true)
  }

  const handleRequestConfirmToCSD = () => {
    setRequestConfirmationToCSD(true)
  }

  const handleRequestReviseCO = () => {
    setRequestReviseToCSD(true)
  }

  const handleRevokeCO = () => {
    setRevokeConfirmation(true)
  }

  const handleRequestScheduleToENG = () => {
    setRequestFullSchedule(true)
    setTitleFullSchedule("Request schedule to ENG")
    setIsSchedule(false)
  }

  const handleSendScheduleToCSD = () => {
    setRequestFullSchedule(true)
    setTitleFullSchedule("Submit schedule to CSD")
    setIsSchedule(true)
  }

  const handleSendScheduleToSales = () => {
    setSendFullScheduleToSales(true)
  }

  // const handleLockCO = () => {
  //   setLockCO(true)
  // }

  const handleNewChangeOrder = () => {
    navigate(`${changeOrderUrl}/new`, {state: {project, building}});
  }

  const handleChangeClose = (event) => {
    const checked = event.target.checked;
    setIsClose(checked);

    dispatch(closeChangeOrder({
      projectId: projectId,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data: {status: checked}
    }))
  }
 
  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <div>
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              className="me-1"
              icon="arrow-left"
            />
            <IconButton
              variant="falcon-default"
              size="sm"
              icon=""
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleProject}
            >
              <span className="d-none d-xl-inline-block ms-1">Back to Project</span>
            </IconButton>
          </div>
          <Flex>
            {changeOrder?.co !== "00" && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="print"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handlePrintCO}
              >
                <span className="d-none d-xl-inline-block ms-1">Change order form</span>
              </IconButton>
            )}
 
            {pifId === null ? (
              <>
                {allow_create_pif && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-1"
                    onClick={handleNewPIF}
                    >
                    <span className="d-none d-xl-inline-block ms-1">New PIF</span>
                    </IconButton>
                )}
               
                {allow_copy_pif && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="copy"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-1"
                    onClick={handleCopyPIF}
                  >
                    <span className="d-none d-xl-inline-block ms-1">Copy PIF</span>
                  </IconButton>
                )}
              </>
            ) : (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="eye"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleViewPIF}
                disabled={!allow_view_pif}
              >
                <span className="d-none d-xl-inline-block ms-1">View Final PIF</span>
              </IconButton>
            )}
           
            <DropdownButton
                variant="primary"
                size="sm"
                as={ButtonGroup} 
                title="Action"
                id="bg-nested-dropdown"
              >
                <div className="py-1">
                  {allow_create_co && (
                    <>
                      <Dropdown.Item className="mb-2" onClick={handleNewChangeOrder}>
                        <FaPlus className="text-primary fs-0 me-1" /> New Change order
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {allow_request_confirm && (
                    <>
                      <Dropdown.Item className="mb-2" onClick={handleRequestConfirmToCSD}>
                        <FaShareSquare className="text-primary fs-0 me-1" /> Request confirmation to CSD
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                  
                  {allow_request_revise && (
                    <Dropdown.Item className="mb-2" onClick={handleRequestReviseCO}>
                      <MdEditNotifications className="text-primary fs-0 me-1" /> Request Revise CO
                    </Dropdown.Item>
                  )}
                  
                  {allow_revoke && (
                    <Dropdown.Item className="mb-2" onClick={handleRevokeCO}>
                      <FaUndo className="text-primary fs-0 me-1" /> Revoke previous step CO
                    </Dropdown.Item>
                  )}
                  
                  {allow_final_enter_job_request && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleRequestScheduleToENG}>
                        <FaShareSquare className="text-primary fs-0 me-1" /> Request schedule to ENG
                      </Dropdown.Item>
                    </>
                  )}

                  {allow_final_enter_job_schedule && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleSendScheduleToCSD}>
                        <MdOutlineScheduleSend className="text-primary fs-0 me-1" /> Send schedule to CSD
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_final_enter_job_release && (
                    <Dropdown.Item className="mb-2" onClick={handleSendScheduleToSales}>
                      <MdOutlineScheduleSend className="text-primary fs-0 me-1" /> Send schedule to Sales
                    </Dropdown.Item>
                  )}

                  {/* {allow_close_co && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleLockCO}>
                        <FaLock className="text-danger fs-0 me-1" /> Close CO#
                      </Dropdown.Item>        
                    </>
                  )} */}

                  {allow_edit_co_form && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleEditCOForm}>
                        <BsPencilSquare className="text-primary fs-0 me-1" /> Edit change order form
                      </Dropdown.Item>
                    </>
                  )}

                  {allow_delete && (
                    <Dropdown.Item className="mb-2" onClick={handleDeleteChangeOrder}>
                      <MdDelete className="text-danger fs-0  me-1" /> Delete CO
                    </Dropdown.Item>
                  )}
                  
                </div>
              </DropdownButton>
          </Flex>
        </Card.Header>
        
        {loading && (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Spinner animation="border" role="status" variant="primary" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner> Downloading...
          </div>
        )}

        <Card.Body className="fs--1">
          <h5 className="text-center fs-3 fw-semi-bold text-uppercase">Change Order Detail</h5>
          <div className="text-center mb-2">No.: <span className="text-primary fw-bold">{changeOrder?.id}</span></div>
          
          <Row className="gx-3 mb-1">
            <Col className="text-end">Project name :</Col>
            <Col><Link to={`/crm/projects/${projectId}`} target="_blank">{project?.name}</Link></Col>
          </Row>
          <Row className="gx-3 mb-1">
            <Col className="text-end">Building :</Col>
            <Col className="text-primary fw-bold">{building?.name}-CO#{changeOrder?.co} ({project?.j_number})</Col>
          </Row>
          <Row className="gx-3 mb-1">
            <Col className="text-end">Status :</Col>
            <Col>
              <ChangeOrderStatus status={changeOrder?.co_status} />
            </Col>
          </Row>
          <Row className="gx-3 mb-1">
            <Col className="text-end">Closed :</Col>
            <Col>
              <Form.Check 
                type='switch'
                id='co#'
                checked={isClose}
                disabled={!allow_close_co}
                onChange={handleChangeClose}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ChangeOrderDetailMessageAction changeOrder={changeOrder} />

      <div className="my-2 px-4 fs-0 d-flex justify-content-start align-items-center">
        <span className="me-1 fw-bold">Next CO#:</span> {changeOrder?.list_change_orders && changeOrder?.list_change_orders.map(item => (
          <Link
            to={`${changeOrderUrl}/${item.id}`}
            className="ms-1 btn border"
            style={
              changeOrder?.id === item.id
                ? { color: '#2c7be5', fontWeight: 'bold' }
                : {}
            }
            key={item.id}
          >
            {item.co}
          </Link>
        )) } 
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        {showCopyPIFForm && (
          <LazyCopyPIFForm
            showModal={showCopyPIFForm}
            setshowModal={setShowCopyPIFForm}
            project={project}
            building={building}
            adaptation={changeOrder}
            type="enter-job"
          />
        )}

        {showChangeOrderDeleteConfirm && (
          <LazyChangeOrderDeleteConfirm
            showModal={showChangeOrderDeleteConfirm}
            setshowModal={setShowChangeOrderDeleteConfirm}
            changeOrder={changeOrder}
          />
        )}

        {requestConfirmationToCSD && (
          <LazyChangeOrderRequestConfirmToCSD
            showModal={requestConfirmationToCSD}
            setshowModal={setRequestConfirmationToCSD}
            changeOrder={changeOrder}
          />
        )}

        {requestReviseToCSD && (
          <LazyChangeOrderRequestRevisedToCSD
            showModal={requestReviseToCSD}
            setshowModal={setRequestReviseToCSD}
            changeOrder={changeOrder}
          />
        )}

        {revokeConfirmation && (
          <LazyChangeOrderRevoke
            showModal={revokeConfirmation}
            setshowModal={setRevokeConfirmation}
            changeOrder={changeOrder}
          />
        )}

        {requestFullSchedule && (
          <LazyChangeOrderRequestFullSchedule
            showModal={requestFullSchedule}
            setshowModal={setRequestFullSchedule}
            changeOrder={changeOrder}
            title={titleFullSchedule}
            isScheduled={isSchedule}
          />
        )}

        {sendFullScheduleToSales && (
          <LazyChangeOrderSendFullScheduleToSales
            showModal={sendFullScheduleToSales}
            setshowModal={setSendFullScheduleToSales}
            changeOrder={changeOrder}
          />
        )}

        {lockCO && (
          <LazyChangeOrderLock
            showModal={lockCO}
            setshowModal={setLockCO}
            changeOrder={changeOrder}
          />
        )}

        {unlockCO && (
          <LazyChangeOrderUnLock
            showModal={unlockCO}
            setshowModal={setUnlockCO}
            changeOrder={changeOrder}
          />
        )}
      </Suspense>
    </>
  );
};

ChangeOrderDetailHeader.propTypes = {
  changeOrder: PropTypes.object.isRequired
}

export default ChangeOrderDetailHeader;