import useAuth from "hooks/useAuth";
import React, { useMemo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import TinymceEditor from "components/common/TinymceEditor";
import { debouncedFilterEmployees } from "helpers/debounce";
import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { confirmApdToSales, getRequestScheduleMailer } from "redux/crm/project/changeOrderSlice";
import Flex from "components/common/Flex";
import { BsBodyText } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import PropTypes from 'prop-types';
import { formatDate } from "helpers/utils";
import { FaPaperclip } from "react-icons/fa";

const APDDetailReleaseToSales = ({
  showModal,
  setshowModal,
  changeOrder,
  part
}) => {
  const {userName} = useAuth()
  const {control, register, handleSubmit, formState: {errors}, setValue, watch} = useForm();
  const { project, building, parts } = changeOrder;
  const [changeOrderMailer, setChangeOrderMailer] = useState({
    to_names: [],
    cc_names: [],
    subject: ''
  });
  const [selectedToEmails, setSelectedToEmails] = useState([]);
  const [selectedCCEmails, setSelectedCCEmails] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState(part.documents.map((document) => document.id) || [])

  const {to_names, cc_names, subject} = changeOrderMailer || {};

  const REQUEST_TYPE = "confirm" ;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRequestScheduleMailer({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      params: { type: REQUEST_TYPE, drawing_type: "apd", confirm_accepted: true },
    }))
    .then((action) => {
      const { to_names, cc_names, subject } = action.payload;
      setChangeOrderMailer({ to_names, cc_names, subject });
    });
  }, [changeOrder, REQUEST_TYPE]);

  useEffect(() => {
    setValue("to", to_names)
    setValue("cc", cc_names)
    setValue("subject", subject)
    setValue("content", content)
    setValue("apd_drawing", true)
    setValue("ab_for_approval_drawing", true)

    setSelectedToEmails(to_names.length > 0 && to_names.map(item => ({value: item, label: item})));
    setSelectedCCEmails(cc_names.length > 0 && cc_names.map(item => ({value: item, label: item})));

  }, [setValue, changeOrderMailer, REQUEST_TYPE]);

  const url = `${process.env.REACT_APP_CLIENT_URL}/crm/projects/${project?.id}/buildings/${building?.id}/change-orders/${changeOrder?.id}`;

  const content = useMemo(() => (
    `
    Dear ${to_names?.join("/")},<br>
      <p>APD of CO#${changeOrder.co} – <strong>${building.j_number}-${building.name}</strong> have released to you.</p>
      
      <p>
        <table>
          <thead class="bg-200 text-900">
            <tr>
              <th class="border">Type of document</th>
              <th class="border">Required by CSD</th>
              <th class="border">Committed by Eng.Dept</th>
              <th class="border">Actual released by Eng.Dept</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border bg-light">Approval Drawings</td>
              <td class="border text-primary">${formatDate(part.apd_requested)}</td>
              <td class="border text-primary">${formatDate(part.apd_scheduled)}</td>
              <td class="border text-primary">${formatDate(part.apd_released)}</td>
            </tr>
            <tr>
              <td class="border bg-light">A/B for Approval Drawings</td>
              <td class="border text-primary">${formatDate(part.ab_erd_requested)}</td>
              <td class="border text-primary">${formatDate(part.ab_erd_scheduled)}</td>
              <td class="border text-primary">${formatDate(part.ab_erd_released)}</td>
            </tr>
          </tbody>
        </table>
      </p>

      <p>Please access link <a href='${url}'>here</a> for detail information.</p>

      <p>
        Thanks and best regards.<br>
        ${userName}
      </p>
    `
  ), [changeOrder, to_names, setValue])
  
  const closeModal = () => {
    setshowModal(false)
  }

  const employeeOptions = (inputValue, callback) => {
    debouncedFilterEmployees(inputValue, callback)
  }

  const defaultToNameOption = useMemo(() => (
    to_names && to_names.map((name) => ({ value: name, label: name }) || [] )
  ), [changeOrder, to_names, cc_names, subject, REQUEST_TYPE])

  const defaultCCNameOption = useMemo(() => (
    cc_names && cc_names.map((name) => ({ value: name, label: name }) || [] )
  ), [changeOrder, to_names, cc_names, subject, REQUEST_TYPE])

  const handleChangeToNames = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('to', selectedOptionsValues, { shouldValidate: true })
    setSelectedToEmails(selectedOptions)
  }

  const handleChangeCCNames = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('cc', selectedOptionsValues, { shouldValidate: true })
    setSelectedCCEmails(selectedOptions)
  }

  const handleDocumentCheckboxChange = (documentId) => {
    setSelectedDocumentId((prevSelectedIds) => {
      if (prevSelectedIds.includes(documentId)) {
        // If documentId is already in the list, remove it
        return prevSelectedIds.filter((id) => id !== documentId);
      } else {
        // If documentId is not in the list, add it
        return [...prevSelectedIds, documentId];
      }
    })
  }

  const onSubmit = (data) => {
    data = {
      ...data,
      part_id: part.id,
      document_ids: selectedDocumentId
    }

    console.log(data)

    try {
      dispatch(confirmApdToSales({
        projectId: project.id,
        buildingId: building.id,
        changeOrderId: changeOrder.id,
        data
      }))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  console.log(part)

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0 bg-light">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Release APD and A/B for Approval Drawings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="mb-2"><MdEmail className="text-primary" /> New email with informations:</div>

              <div className="border border-200 border-top-0">
                <Row className="g-0 align-items-center">
                  <Col sm={1} className="mx-2 fs--0">To</Col>
                  <Col>
                    <Controller
                      control={control}
                      name="to"
                      rules={{required: "To must be required"}}
                      render={() => (
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={employeeOptions}
                          defaultValue={defaultToNameOption}
                          value={selectedToEmails}
                          closeMenuOnSelect={true}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          className="w-100 border-0 outline-none px-x1 rounded-0"
                          isMulti
                          onChange={handleChangeToNames}
                        />
                      )}
                    />
                    {errors.to && (
                      <span className="text-danger mx-4 mt-1">
                        {errors.to.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="border border-200 border-top-0">
                <Row className="gx-0 align-items-center">
                  <Col sm={1} className="mx-2 fs--0">CC</Col>
                  <Col>
                    <Controller
                      control={control}
                      name="cc"
                      rules={{required: "CC must be required"}}
                      render={() => (
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={employeeOptions}
                          defaultValue={defaultCCNameOption}
                          value={selectedCCEmails}
                          closeMenuOnSelect={true}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          className="w-100 border-0 outline-none px-x1 rounded-0"
                          isMulti
                          onChange={handleChangeCCNames}
                        />
                      )}
                    />
                    {errors.cc && (
                      <span className="text-danger mx-4 mt-1">
                        {errors.cc.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="border border-200 border-top-0">
                <Row className="gx-0 align-items-center">
                  <Col sm={1} className="mx-2 fs--0">Subject</Col>
                  <Col>
                    <Form.Control
                      placeholder="Subject"
                      className="border-0 outline-none px-x1 rounded-0 fs--1 text-primary"
                      {...register('subject', {required: true})}
                    />
                  </Col>
                </Row>
              </div>

              <div className="border border-200 border-top-0">
                <Row className="gx-0 align-items-center">
                  <Col sm={1} className="mx-2 fs--0"></Col>
                  <Col>
                    <Flex justifyContent="around" className="mt-1">
                      <Controller
                        control={control}
                        name="apd_drawing"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="APD drawing"
                            checked={field.value}
                            defaultChecked={true}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="ab_for_approval_drawing"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="A/B for Approval drawing"
                            checked={field.value}
                            defaultChecked={true}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      />
                    </Flex>
                  </Col>
                </Row>
              </div>

              <div className="border-200 m-t">
                <label className="ms-2"><BsBodyText className="text-primary" /> Content</label>
                <Controller
                  control={control}
                  name="content"
                  render={({ field }) => (
                    <TinymceEditor
                      handleChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </div>

              <div className="my-3">
                <h6><FaPaperclip className="fs--1" /> Attachments</h6>
                {part.documents && part.documents.map((document) => (
                  <Row className="mb-2 gx-2 px-4">
                    <Col sm="auto">
                      <Form.Check
                        key={document.id}
                        name={document.id}
                        onChange={() => handleDocumentCheckboxChange(document.id)}
                        checked={selectedDocumentId.includes(document.id)}
                      />
                    </Col>
                    <Col className="text-primary">
                      <span className="mx-1 flex-1">
                        {document.name} ({(document.size / 1024).toFixed(2)} kb)
                      </span>
                    </Col>
                  </Row>
                ))}
              </div>

            </div>
            <div className="border-0 mt-3 text-end">
              <Button size="sm" variant="primary" type="submit" className="me-1">Submit</Button>
              <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
};

APDDetailReleaseToSales.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  changeOrder: PropTypes.object.isRequired,
  apd: PropTypes.object.isRequired
}

export default APDDetailReleaseToSales;