import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RoleForm from './RoleForm';
import PropTypes from 'prop-types';

const RoleHeader = () => {
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [title, setTitle] = useState('');

  const navigate = useNavigate();

  const showForm = () => {
    setShowRoleForm(true);
  }

  const handleNewRole = () => {
    setTitle("New Role");
    showForm();
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="user-plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewRole}
            >
              <span className="d-none d-xl-inline-block ms-1">New Role</span>
            </IconButton>
          </Flex>
        </Card.Header>
      </Card>

      <RoleForm
        showModal={showRoleForm}
        setshowModal={setShowRoleForm}
        title={title}
        role={null}
      />
    </>
  );
};

RoleHeader.propTypes = {
  role: PropTypes.object.isRequired
}


export default RoleHeader;
