import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, {useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import { MdDelete, MdEdit, MdNotificationsActive } from 'react-icons/md';
import { deleteLead, notifyToSalesLead } from 'redux/crm/leadSlice';
import { FaRecordVinyl } from 'react-icons/fa';
import LeadRecordConfirm from './confirmation/LeadRecordConfirm';

const LeadDetailHeader = ({lead}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [messageConfirm, setMessageConfirm] = useState(null)
  const [showRecordConfirm, setShowRecordConfirm] = useState(false);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const permissions = lead?.permissions

  const {
    allow_edit,
    allow_delete,
    allow_record,
    allow_send_notify
  } = permissions || {}
  
  const leadUrl = "/crm/leads"
  const leadId = lead?.id

  const handleNewLead = () => {
    navigate(`${leadUrl}/new`)
  }

  const handleEditLead = () => {
   navigate(`${leadUrl}/${leadId}/edit`, { state: {lead} })
  }

  const handleBackToLeads = () => {
    navigate(`${leadUrl}/list`)
  }

  const confirmMsg = (message) => {
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deleteLead(leadId))
      navigate(`${leadUrl}/list`)
      
      closeConfirmModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleDeleteLead = () => {
    const message = `Are you sure to delete Lead ID#<strong class='text-danger'>${leadId}</strong>?`
    confirmMsg(<span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleNotifyTo = () => {
    dispatch(notifyToSalesLead(leadId))
  }

  const handleRecordLead = () => {
    setShowRecordConfirm(true);
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            iconAlign="middle"
            className="d-none d-sm-block me-1"
            onClick={handleNewLead}
          >
            <span className="d-none d-xl-inline-block ms-1">
              New Lead
            </span>
          </IconButton>

          {allow_edit && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="edit"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleEditLead}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Edit Lead
              </span>
            </IconButton>
          )}
            
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBackToLeads}
            >
              <span className="d-none d-xl-inline-block ms-1">
               List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="primary" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_record && (
                    <Dropdown.Item onClick={handleRecordLead}>
                      <FaRecordVinyl className="text-warning" /> Record
                    </Dropdown.Item>
                  )}

                  {allow_send_notify && (
                    <Dropdown.Item onClick={handleNotifyTo}>
                      <MdNotificationsActive className="text-warning" /> Send notify to Sales
                    </Dropdown.Item>
                  )}

                  {allow_edit && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={handleEditLead}>
                        <MdEdit /> Edit
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger" onClick={handleDeleteLead}>
                        <MdDelete className="text-danger" /> Delete
                      </Dropdown.Item>
                    </>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message={messageConfirm}
        />
      )}
      
      {showRecordConfirm && (
        <LeadRecordConfirm
          showModal={showRecordConfirm}
          setshowModal={setShowRecordConfirm}
          lead={lead}
        />
      )}
    </>
  );
};

LeadDetailHeader.propTypes = {
  lead: PropTypes.object
}

export default LeadDetailHeader;