import { Row, Col } from "react-bootstrap";
import EntryChart from "./EntryChart";
import TableItem from "./TableItem";
import React from 'react';
import PropTypes from 'prop-types';

const EntryItem = ({data, color, isSalesRegion, isWeight}) => {
  return (
    <div className="h-100 mb-3">
      <Row className="g-3">
        <Col md="8" sm="12">
          <EntryChart data={data} color={color} isSalesRegion={isSalesRegion} isWeight={isWeight} />
        </Col>
        <Col md="4" sm="12">
          <TableItem data={data} />
        </Col>
      </Row>
    </div>
  );
};

EntryItem.propTypes = {
  data: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  isSalesRegion: PropTypes.bool.isRequired,
  isWeight: PropTypes.bool.isRequired
}

export default EntryItem;