import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import useAuth from "hooks/useAuth"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCar, FaPlane } from 'react-icons/fa';

const Starter = () => {
  const {userName} = useAuth()

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  let greeting;

  if (currentHour >= 5 && currentHour < 12) {
    greeting = 'Good morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = 'Good afternoon';
  } else {
    greeting = 'Good evening';
  }


  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-4">
        <div className="mb-5 text-lg-end h6"><FontAwesomeIcon icon="crown" className="text-warning" /> Welcome <strong className="text-primary"><FontAwesomeIcon icon="user" className="me-1" />{userName}</strong>, you are now signed in to the <strong className="text-primary">Portal</strong> system.</div>

        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">{greeting}!</h3>
            <p className="lead">Booking something based on your selection.</p>
            <div className="mt-5">
              <Button
                variant="outline-primary"
                as={Link}
                to="/booking/travel/new-travel-request"
                className="me-2"
              >
                <FaPlane /> Make a Travel booking!
              </Button>
              <Button
                variant="outline-primary"
                as={Link}
                to="/booking/car/new-car-booking"
              >
                <FaCar /> Make a Car Booking!
              </Button>
            </div>
            
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Starter;
