import React, { useState } from "react"
import { Spinner, Row, Col, Tab, Card, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApprovalFlowHeader from "./setting/approval-flow/ApprovalFlowHeader";
import ApprovalFlowList from "./setting/approval-flow/ApprovalFlowList";

const ApprovalFlows = () => {
  const [selected, setSelected] = useState([])
  const { loading } = useSelector((state) => state.authorization_permission)

  return (
    <>
      <ApprovalFlowHeader selected={selected} />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="approval-flow">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="approval-flow"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">All</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="approval-flow">
                    <ApprovalFlowList setSelected={setSelected} /> 
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ApprovalFlows;