import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import { BiRefresh } from "react-icons/bi";
import { syncProjectToOplaCrm } from "redux/crm/projectSlice";

const SyncToOplaCrmForm = ({showModal, setshowModal, project }) => {
  const {control, handleSubmit, formState: {errors}, setValue } = useForm();
  const projectId = project?.id;

  const salesProcess = [
    "Sync data",
    "Quotation",
    "Negotiation",
    "Execution",
    "Close won",
    "Close lost"
  ]

  const salesProcessOptions = salesProcess.map(item => ({value: item, label: item}));

  const dispatch = useDispatch();

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(syncProjectToOplaCrm({
        projectId: projectId,
        data
      }))

      toast.success(response.payload?.message);
      closeModal();
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Sync to Opla CRM!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Sync sales process to Opla CRM. Are you sure to sync with Opla ID <strong>{project?.opla_id}</strong>?
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="name"
                rules={{ required: { value: true, message: "Name is required" } }} 
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={salesProcessOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => setValue('name', selectedOption.value, {shouldValidate: true})}
                  />
                )}
              />
              <span className="text-danger">
                {errors.sales_unit_id && errors.sales_unit_id.message}
              </span>
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit"><BiRefresh /> Sync</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

SyncToOplaCrmForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

export default SyncToOplaCrmForm;