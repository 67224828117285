import React, { useState } from 'react';
import ShuttleList from './ShuttleList';
import ShuttleForm from './ShuttleForm';
import StarterShuttle from './StarterShuttle';
import PropTypes from 'prop-types';

const Shuttle = ({booking}) => {
  const [showModalShuttle, setShowModalShuttle] = useState(false)
  const {shuttles} = booking
  const shuttlesWithoutOthers = shuttles && shuttles.filter((shuttle) => shuttle.other === false)

  return (
    <>
      {shuttlesWithoutOthers && shuttlesWithoutOthers.length > 0 ? (
        <ShuttleList booking={booking} setShowModalShuttle={setShowModalShuttle} />
      ) : (
        <StarterShuttle setShowModalShuttle={setShowModalShuttle}/>
      )}
      
      <ShuttleForm 
        showModal={showModalShuttle}
        setshowModal={setShowModalShuttle}
        booking={booking}
      />
    </>
  )
};

Shuttle.propTypes = {
  booking: PropTypes.object.isRequired
}

export default Shuttle;