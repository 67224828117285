import IconAlert from 'components/common/IconAlert';
import React, { useState, Suspense, lazy } from 'react';
import { Button } from 'react-bootstrap';
import { FaBan, FaCheckCircle, FaUserLock, FaShareSquare } from 'react-icons/fa';
import ChangeOrderFinalRequestSchedule from './ChangeOrderFinalRequestSchedule';
import PropTypes from 'prop-types';

const LazyChangeOrderAcceptConfirmation = lazy(() => import('./confirmation/ChangeOrderAcceptConfirmation'));
const LazyChangeOrderRejectConfirmation= lazy(() => import('./confirmation/ChangeOrderRejectConfirmation'));
const LazyChangeOrderRejectAndFreezeConfirmation = lazy(() => import('./confirmation/ChangeOrderRejectAndFreezeConfirmation'));
const LazyChangeOrderRequestConfirmToCSD = lazy(() => import('./confirmation/ChangeOrderRequestConfirmToCSD'));

const ChangeOrderDetailMessageAction = ({changeOrder}) => {
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [showRejectAndFreezeConfirmation, setShowRejectAndFreezeConfirmation] = useState(false);
  const [requestConfirmationToCSD, setRequestConfirmationToCSD] = useState(false);
  const [requestFullScheduleToENG, setRequestFullScheduleToENG] = useState(false);

  const {building, permissions} = changeOrder || {};
  const isRevised = changeOrder?.co_revised;

  const {
    allow_request_confirm,
    allow_confirm
  } = permissions || {};

  const handleAcceptCO = () => {
    setShowAcceptConfirmation(true)
  }

  const handleRejectCO = () => {
    setShowRejectConfirmation(true)
  }

  const handleRejectAndFreezeCO = () => {
    setShowRejectAndFreezeConfirmation(true)
  }

  const handleRequestConfirmToCSD = () => {
    setRequestConfirmationToCSD(true)
  }

  //show form request schedule to ENG directly
  const handleRequestScheduleToENG = () => {
    setRequestFullScheduleToENG(true);
  }

  return (
    <>
    {allow_request_confirm && (
      <IconAlert variant="warning">
        <p className="mb-0">
          <span>Waiting confirmation by CSD !!!</span>
          <span>
            <Button
              variant="primary"
              size="sm"
              className="ms-3 me-1"allow_delete
              onClick={handleRequestConfirmToCSD}
            >
              <FaShareSquare /> Request confirmation to CSD right now!
            </Button>
          </span>
        </p>
      </IconAlert>
    )}

    {allow_confirm && (
      <IconAlert variant="warning">
        <p className="mb-0">
          <span>Waiting confirmation by <strong>{building?.cse_order_entry.join("/")}!</strong></span>
          <span>
            <Button
              variant="success"
              size="sm"
              className="ms-3 me-1"
              onClick={handleAcceptCO}
            >
              <FaCheckCircle /> Accept
            </Button>

            <Button
              variant="danger"
              size="sm"
              className="me-1"
              onClick={handleRejectCO}
            >
              <FaBan /> Reject
            </Button>

            <Button
              variant="warning"
              size="sm"
              className="me-1"
              onClick={handleRejectAndFreezeCO}
            >
              <FaUserLock /> Reject and Freeze
            </Button>
          </span>
        </p>
      </IconAlert>
    )}

    <Suspense fallback={<div>Loading...</div>}>
      {showAcceptConfirmation && (
        <LazyChangeOrderAcceptConfirmation
          showModal={showAcceptConfirmation}
          setshowModal={setShowAcceptConfirmation}
          changeOrder={changeOrder}
          requestScheduleToENG={handleRequestScheduleToENG}
        />
      )}

      {showRejectConfirmation && (
        <LazyChangeOrderRejectConfirmation
          showModal={showRejectConfirmation}
          setshowModal={setShowRejectConfirmation}
          changeOrder={changeOrder}
        />
      )}

      {showRejectAndFreezeConfirmation && (
        <LazyChangeOrderRejectAndFreezeConfirmation
          showModal={showRejectAndFreezeConfirmation}
          setshowModal={setShowRejectAndFreezeConfirmation}
          changeOrder={changeOrder}
        />
      )}

      {requestConfirmationToCSD && (
        <LazyChangeOrderRequestConfirmToCSD
          showModal={requestConfirmationToCSD}
          setshowModal={setRequestConfirmationToCSD}
          changeOrder={changeOrder}
        />
      )}

      {requestFullScheduleToENG && (
        <ChangeOrderFinalRequestSchedule
          showModal={requestFullScheduleToENG}
          setshowModal={setRequestFullScheduleToENG}
          changeOrder={changeOrder}
          title="Request schedule to ENG"
          isScheduled={false}
        />
      )}
    </Suspense>
  </>
  )
};

ChangeOrderDetailMessageAction.propTypes = {
  changeOrder: PropTypes.object.isRequired
};

export default ChangeOrderDetailMessageAction