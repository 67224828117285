import { formatDate } from "helpers/utils";
import { Table } from "react-bootstrap";
import React from 'react';
import PropTypes from 'prop-types';

const ChangeOrderDetailInputReview = ({changeOrder}) => {
  const parts = changeOrder?.parts || []
  const estimate = changeOrder?.estimate || {}
  const shops = changeOrder?.phases || []
  const ab_erection = changeOrder?.ab_erection || {}
  const others = changeOrder?.others || []

  const renderDateInfo = (data, type) => (
    <td className="border text-primary">
      {data && data.map((item) => (
        item[type] ? (
          <div className="mb-1" key={item.name}>
            {item.name}: {formatDate(item[type])}
          </div>
        ) : null
      ))}
    </td>
  )
  
  return (
    <>
      <div className="table-responsive fs--1 mt-2">
        <Table striped>
          <thead className="bg-200 text-900">
            <tr>
              <th className="border fixed-header">Type of document</th>
              <th className="border fixed-header">Required by CSD</th>
              <th className="border fixed-header">Committed by Eng.Dept</th>
              <th className="borde fixed-header">Actual released by Eng.Dept</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border bg-light">Approval Drawings</td>
              {renderDateInfo(parts, 'apd_requested')}
              {renderDateInfo(parts, 'apd_scheduled')}
              {renderDateInfo(parts, 'apd_released')}
            </tr>
            <tr>
              <td className="border bg-light">A/B for Approval Drawings</td>
              {renderDateInfo(parts, 'ab_erd_requested')}
              {renderDateInfo(parts, 'ab_erd_scheduled')}
              {renderDateInfo(parts, 'ab_erd_released')}
            </tr>
            <tr>
              <td className="border bg-light">Estimates</td>
              <td className="border text-primary">{formatDate(estimate.requested_date)}</td>
              <td className="border text-primary">{formatDate(estimate.scheduled_date)}</td>
              <td className="border text-primary">{formatDate(estimate.released_date)}</td>
            </tr>
            <tr>
              <td className="border bg-light">Shop Drawings</td>
              {renderDateInfo(shops, 'shop_requested')}
              {renderDateInfo(shops, 'shop_scheduled')}
              {renderDateInfo(shops, 'shop_released')}
            </tr>
            <tr>
              <td className="border bg-light">Erection Drawings</td>
              {renderDateInfo(shops, 'erc_requested')}
              {renderDateInfo(shops, 'erc_scheduled')}
              {renderDateInfo(shops, 'erc_released')}
            </tr>
            <tr>
              <td className="border bg-light">A/B for Erection Drawings</td>
              <td className="border text-primary">{formatDate(ab_erection.requested_date)}</td>
              <td className="border text-primary">{formatDate(ab_erection.scheduled_date)}</td>
              <td className="border text-primary">{formatDate(ab_erection.released_date)}</td>
            </tr>

            {others && others.map(item => (
              <tr>
                <td className="border bg-light">{item.name}</td>
                <td className="border text-primary">{formatDate(item.requested_date)}</td>
                <td className="border text-primary">{formatDate(item.scheduled_date)}</td>
                <td className="border text-primary">{formatDate(item.released_date)}</td>
              </tr>
            ))}
          
          </tbody>
        </Table>
      </div>
    </>
  )
};

ChangeOrderDetailInputReview.propTypes = {
  changeOrder: PropTypes.object.isRequired
};

export default ChangeOrderDetailInputReview;