import React, { lazy, Suspense, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { FaBuilding, FaFileContract } from 'react-icons/fa';
import { MdApproval, MdNewReleases, MdOutlineSummarize } from 'react-icons/md';
import moment from "moment";
import HeaderReport from './HeaderReport';

const LazyMasterSummary = lazy(() => import("./project_master_schedule/dashboard/MasterSummary/MasterSummary"))
const LazyContractSummary = lazy(() => import("./project_master_schedule/dashboard/contract_summary/ContractSummary"))
const LazyEnterShop = lazy(() => import("./project_master_schedule/dashboard/enter_shop/EnterShop"))
const LazyJobReleased = lazy(() => import("./project_master_schedule/dashboard/job_released/JobReleased"))
const LazyApprovalDrawing = lazy(() => import("./project_master_schedule/dashboard/approval_drawing/ApprovalDrawing"))

const ProjectMasterDashboard = () => {
  const { loading } = useSelector((state) => state.crm_report_master_schedule);

  const currentDate = moment() 
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(currentDate);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const dateRanges = {
    beginDate: startDate.format("ll"),
    endDate: endDate.format("ll")
  };

  return (
    <>
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <HeaderReport
        title="Master"
        subtitle="dashboard"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDatesChange={handleDatesChange}
      />

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="master-summary">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="master-summary"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineSummarize className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Master summary</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contract-summary"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaFileContract className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Contract summary</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="enter-shop"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaBuilding className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Enter shop</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="job-released"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdNewReleases className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Job released</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="approval-drawing"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdApproval className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Approval drawing</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="master-summary">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyMasterSummary dateRanges={dateRanges} />
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="contract-summary">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyContractSummary /> 
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="enter-shop">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyEnterShop dateRanges={dateRanges} /> 
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="job-released">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyJobReleased dateRanges={dateRanges} /> 
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="approval-drawing">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyApprovalDrawing /> 
                    </Suspense>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProjectMasterDashboard;