import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateCar, deleteCar, exportCars, getAllCars, resetCurrentParams } from 'redux/booking/car/carSlice';
import CarForm from './CarForm';

const CarHeader = ({ carSelected }) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [showCarForm, setShowCarForm] = useState(false)
  const [title, setTitle] = useState("")
  const { data, activeType, pageSize, currentParams } = useSelector((state) => state.booking_setting_car) 

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const car = carSelected.at(-1)

  const validateCarSelected = () => {
    if (carSelected.length === 0)
    {
      toast.error('Car has not selected yet.')
      return false
    }
    return true
  }

  const validateCarSelectedMultiple = () => {
    if (carSelected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of car.')
      return false
    }
    return true
  }

  const showForm = () => {
    setShowCarForm(true)
  }

  const handleNewCar = () => {
    setTitle("New Car")
    showForm()
  }

  const handleEditCar = () => {
    if (!validateCarSelected()) return
    if (!validateCarSelectedMultiple()) return

    setTitle("Edit Car")
    showForm()
  }

  const handleRefreshCar = () => {
    dispatch(getAllCars({active: activeType, page: 1, per_page: pageSize}))
    dispatch(resetCurrentParams())
  }

  const handleDeActiveCar = () => {
    if (!validateCarSelected()) return

    try {
      dispatch(deactivateCar(car))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleExportcar = () => {
    dispatch(exportCars(currentParams))
  }

  const handledeleteCar = () => {
    if (!validateCarSelected()) return

    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(deleteCar(car))
    setShowConfirmationDelete(false) 
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewCar}
            >
              <span className="d-none d-xl-inline-block ms-1">New</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="edit"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleEditCar}
            >
              <span className="d-none d-xl-inline-block ms-1">Edit</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="sync-alt"
              iconAlign="middle"
              className="d-none d-sm-block"
              onClick={handleRefreshCar}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Refresh
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="lock"
              iconAlign="middle"
              className="d-none d-sm-block mx-2"
              onClick={handleDeActiveCar}
            >
              <span className="d-none d-xl-inline-block ms-1">
                De-active
              </span>
            </IconButton>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleEditCar} className="mb-1">
                    <FontAwesomeIcon icon="edit" /> Edit
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleExportcar} className="mb-1">
                    <FontAwesomeIcon icon="download" /> Export
                  </Dropdown.Item>
                  <Dropdown.Item className="d-sm-none mb-1" onClick={handleRefreshCar}>
                  <FontAwesomeIcon icon="sync-alt" /> Refresh
                  </Dropdown.Item>
                  <Dropdown.Item className="d-sm-none mb-1" onClick={handleDeActiveCar}>
                    <FontAwesomeIcon icon="lock" /> De-active
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="text-danger mb-1" onClick={handledeleteCar}>
                    <FontAwesomeIcon icon="trash-alt" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>
      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to delete?"
      />

      <CarForm
        showModal={showCarForm}
        setshowModal={setShowCarForm}
        title={title}
        car={car}
      />
    </>
  );
};

CarHeader.propTypes = {
  carSelected: PropTypes.array
}

export default CarHeader;
