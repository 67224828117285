import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatBookingDate } from "helpers/utils";
import { Card, Row, Col, Button } from "react-bootstrap";
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import Flex from "components/common/Flex";
import ShuttleForm from "../itinerary/shuttle/ShuttleForm";
import HotelForm from "../itinerary/hotel/HotelForm";
import GoogleMapsLink from "helpers/googleMapsLink";
import PropTypes from 'prop-types';
import { FaHotel } from 'react-icons/fa';
import { GiShuttlecock } from "react-icons/gi";

const BookingMultipleStopTrip = ({booking}) => {
  const [showModalShuttle, setShowModalShuttle] = useState(false);
  const [showModalHotel, setShowModalHotel] = useState(false);
  const [transit, setTransit] = useState(null);
  const {transits, permissions} = booking;

  const {
    allow_create_shuttle,
    allow_create_hotel
  } = permissions || {};

  const handleCreateShuttleBooking = (transit) => {
    setShowModalShuttle(true)
    setTransit(transit)
  }

  const handleCreateHotelBooking = (transit) => {
    setShowModalHotel(true)
    setTransit(transit)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0"><FontAwesomeIcon icon={faMapMarker} /> Multi-stop trips</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <h6>Details of a multi-stop trip!</h6>

          <Row className="g-4">
            {transits && transits.map((transit) => (
              <Col sm={6} key={transit.id}>
                <Card className="my-3">
                  <Card.Header className="mt-2 text-primary">
                    <Flex justifyContent="between">
                      <div>
                        <FontAwesomeIcon icon={faMapMarker} className="me-2"/>{transit.name} (ID#{transit.id})
                      </div>
                      
                      {/* <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                        <Dropdown.Toggle split variant="falcon-default" size="sm">
                          <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="border py-0">
                          <div className="py-2">
                            <Dropdown.Item className="mb-1" onClick={() => handleDeleteTransit(transit.id)}>
                              <FontAwesomeIcon icon="trash-alt" /> Delete
                            </Dropdown.Item>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </Flex>
                  </Card.Header>
                  <Card.Body className="m-2">
                    <Row className="g-4">
                      <Col>
                        <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                          From
                        </h6>

                        <Row className="mb-2">
                          <Col xs={2} sm={5}>
                            <p className="fw-semi-bold fs--1 mb-1">Date</p>
                          </Col>
                          <Col>
                            <p className="mb-1 fs--1 text-primary">{formatBookingDate(transit.from_date)}</p>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={2} sm={5}>
                            <p className="fw-semi-bold fs--1 mb-1">Location</p>
                          </Col>
                          <Col>
                            <p className="mb-1 fs--1 text-primary">{transit.from_location}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                          To
                        </h6>

                        <Row className="mb-2">
                          <Col xs={2} sm={5}>
                            <p className="fw-semi-bold fs--1 mb-1">Date</p>
                          </Col>
                          <Col>
                            <p className="mb-1 fs--1 text-primary">{formatBookingDate(transit.to_date)}</p>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={2} sm={5}>
                            <p className="fw-semi-bold fs--1 mb-1">Location</p>
                          </Col>
                          <Col>
                            <p className="mb-1 fs--1 text-primary">{transit.to_location}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  
                    <Row className="mb-2 mt-4">
                      <Col sm="auto">
                        <p className="fw-semi-bold fs--1 mb-1">Description</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{transit.description}</p>
                      </Col>
                    </Row>

                    <div className="border-dashed border-bottom my-4"></div>

                    {transit.shuttles && transit.shuttles.map((shuttle) => (
                      <>
                        <h6 className="fw-semi-bold ls mb-3 mt-4 text-uppercase">
                          Shuttle
                        </h6>
                        <div className="mb-2 fs--1 text-primary">
                          <div className="text-primary mb-2 fs-0">By {shuttle.name}</div>
                          <div><FontAwesomeIcon icon="calendar" /> {formatBookingDate(shuttle.departure_date)} ~ {formatBookingDate(shuttle.return_date)} </div>
                        </div>
                      </>
                    ))}

                    <div className="border-dashed border-bottom my-4"></div>

                    {transit.hotels && transit.hotels.map((hotel) => (
                      <>
                        <h6 className="fw-semi-bold ls mb-3 mt-4 text-uppercase">
                          Hotel
                        </h6>
                        <div className="mb-2 fs--1 text-primary">
                          <div className="fs-0 mb-2"><FontAwesomeIcon icon="hotel" /> {hotel.name}</div>
                          <div className="small"><FontAwesomeIcon icon="phone" /> <a href={`tel:${hotel.tel}`}>{hotel.tel}</a></div>
                          <div className="small"><GoogleMapsLink address={hotel.address} /></div>
                        </div>
                      </>
                    ))}
                  </Card.Body>
                  <Card.Footer as={Flex} justifyContent="end" className="fs--1">
                    {allow_create_shuttle && (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-1"
                        onClick={() => handleCreateShuttleBooking(transit)}
                      >
                        <GiShuttlecock /> Book a shuttle now!
                      </Button>
                    )}
                    
                    {allow_create_hotel && (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleCreateHotelBooking(transit)}
                      >
                        <FaHotel /> Book a hotel now!
                      </Button>
                    )}
                      
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>

      <ShuttleForm 
        showModal={showModalShuttle}
        setshowModal={setShowModalShuttle}
        booking={booking}
        transit={transit}
      />

      <HotelForm
        showModal={showModalHotel}
        setshowModal={setShowModalHotel}
        booking={booking}
        transit={transit}
      />
    </>
  )
};

BookingMultipleStopTrip.propTypes = {
  booking: PropTypes.object.isRequired
}

export default BookingMultipleStopTrip;