import IconAlert from "components/common/IconAlert";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BackgroundJob = () => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/sidekiq`;
    setUrl(url)
  })

  return (
    <div>
      <IconAlert variant="info" className="mb-3">
        In case the Sikekid is not accessing, please click <Link to={url} target="_blank">here</Link> to access it on new page.
      </IconAlert>

      <iframe
        title="Sidekiq"
        src={url}
        width="100%"
        style={{ height: '100vh' }}
      />
    </div>
  )
}

export default BackgroundJob