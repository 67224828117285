import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";
import {
  initialStateStatus,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  createInitialState,
  handlePayload
} from "helpers/reducerHelpers";

const bookingUrl = "/booking/travel/bookings"

export const getAllBookings = createAsyncThunk(
  "TravelBooking/getAllBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBooking = createAsyncThunk(
  "TravelBooking/getBooking",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/${bookingId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createBooking = createAsyncThunk(
  "TravelBooking/createBooking",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(bookingUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateBooking = createAsyncThunk(
  "TravelBooking/updateBooking",
  async ({ bookingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${bookingUrl}/${bookingId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteBooking = createAsyncThunk(
  "TravelBooking/deleteBooking",
  async (booking) => {
    try {
      const response = await apiAxios.delete(`${bookingUrl}/${booking.id}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportBookings = createAsyncThunk(
  "TravelBooking/exportBookings",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)
    
    const response = await apiAxios.get(`${bookingUrl}/export`, {params, responseType: 'blob'})

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `travel-bookings-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const approveBooking = createAsyncThunk(
  "TravelBooking/approveBooking",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/approve`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const rejectBooking = createAsyncThunk(
  "TravelBooking/rejectBooking",
  async ( { bookingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/reject`, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const sendBackToAccountant = createAsyncThunk(
  "TravelBooking/sendBackToAccountant",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/send_back_to_accountant`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const sendBackToHRAdmin = createAsyncThunk(
  "TravelBooking/sendBackToHRAdmin",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/send_back_to_hr_admin`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const sendBackUpdateBudgetToHRAdmin = createAsyncThunk(
  "TravelBooking/sendBackUpdateBudgetToHRAdmin",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/send_back_update_budget_to_hr_admin`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const confirmBooking = createAsyncThunk(
  "TravelBooking/confirmBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/confirm`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const verifyBooking = createAsyncThunk(
  "TravelBooking/verifyBooking",
  async ( { bookingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/verify`, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetBooking = createAsyncThunk(
  "TravelBooking/resetBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/reset`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const cancelBooking = createAsyncThunk(
  "TravelBooking/cancelBooking",
  async ( { bookingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/cancel`, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const rollbackBooking = createAsyncThunk(
  "TravelBooking/rollbackBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/rollback`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const recalculateExpenseBooking = createAsyncThunk(
  "TravelBooking/recalculateExpenseBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/recalculate`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const remindApprovalBooking = createAsyncThunk(
  "TravelBooking/remindApprovalBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/remind`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const addLeaveIhrmBooking = createAsyncThunk(
  "TravelBooking/addLeaveIhrmBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/add_leave_to_ihrm`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const finalTripToRequesterBooking = createAsyncThunk(
  "TravelBooking/finalTripToRequesterBooking",
  async ( bookingId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/final_trip_to_requester`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const getActivityLog = createAsyncThunk(
  "TravelBooking/getActivityLog",
  async ({ bookingId, params } ) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/${bookingId}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getAllStatuses = createAsyncThunk(
  "TravelBooking/getAllStatuses",
  async () => {
    try {
      const response = await apiAxios.get("/booking/travel/statuses")
      return objectSerializer(response.data)
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const updateProjectBudget = createAsyncThunk(
  "TravelBooking/updateProjectBudget",
  async ({bookingId, data}) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/update_project_budget`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const updateEmployeeExpense = createAsyncThunk(
  "TravelBooking/updateEmployeeExpense",
  async ({bookingId, data}) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/update_employee_expense`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const printPDFBooking = createAsyncThunk(
  "TravelBooking/printPDFBooking",
  async (bookingId) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/${bookingId}/print_pdf`, {
        responseType: 'blob'
      })

      if (response.headers['content-type'] === 'application/pdf') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error("Invalid Content-Type received. Expected 'application/pdf'.");
      }
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

// Hotel
export const createBookingHotel = createAsyncThunk(
  "TravelBooking/createBookingHotel",
  async ({ bookingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/hotels`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })

      const {error, attributes} = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return attributes
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateBookingHotel = createAsyncThunk(
  "TravelBooking/updateBookingHotel",
  async ({ bookingId, hotelId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${bookingUrl}/${bookingId}/hotels/${hotelId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      
      const {error, attributes} = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return attributes
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteBookingHotel = createAsyncThunk(
  "TravelBooking/deleteBookingHotel",
  async ({bookingId, hotelId}) => {
    try {
      const response = await apiAxios.delete(`${bookingUrl}/${bookingId}/hotels/${hotelId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadHotelDocument = createAsyncThunk(
  "TravelBooking/uploadHotelDocument",
  async ({ bookingId, hotelId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/hotels/${hotelId}/upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeHotelDocument = createAsyncThunk(
  "TravelBooking/removeHotelDocument",
  async ( { bookingId, hotelId, documentId }, { rejectWithValue }) => {
    try {
      await apiAxios.post(`${bookingUrl}/${bookingId}/hotels/${hotelId}/remove_file`, { file_id: documentId } );
      return {hotelId, documentId}
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

////end Hotel

////Transportation 
export const createBookingShuttle = createAsyncThunk(
  "TravelBooking/createBookingShuttle",
  async ({ bookingId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/shuttles`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })

      const {error, attributes} = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return attributes
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateBookingShuttle = createAsyncThunk(
  "TravelBooking/updateBookingShuttle",
  async ({ bookingId, shuttleId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${bookingUrl}/${bookingId}/shuttles/${shuttleId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      
      const {error, attributes} = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return attributes
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteBookingShuttle = createAsyncThunk(
  "TravelBooking/deleteBookingShuttle",
  async ({bookingId, shuttleId}) => {
    try {
      const response = await apiAxios.delete(`${bookingUrl}/${bookingId}/shuttles/${shuttleId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadShuttleDocument = createAsyncThunk(
  "TravelBooking/uploadShuttleDocument",
  async ({ bookingId, shuttleId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${bookingId}/shuttles/${shuttleId}/upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeShuttleDocument = createAsyncThunk(
  "TravelBooking/removeShuttleDocument",
  async ( { bookingId, shuttleId, documentId }, { rejectWithValue }) => {
    try {
      await apiAxios.post(`${bookingUrl}/${bookingId}/shuttles/${shuttleId}/remove_file`, { file_id: documentId } );
      return {shuttleId, documentId}
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)
////end Transportation

export const getSumitedBookings = createAsyncThunk(
  "TravelBooking/getSumitedBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/submited_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getWaitingApprovalBookings = createAsyncThunk(
  "TravelBooking/getWaitingApprovalBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/waiting_approval_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getWaitingConfirmationBookings = createAsyncThunk(
  "TravelBooking/getWaitingConfirmationBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/waiting_confirmation_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getWaitingVerificationBookings = createAsyncThunk(
  "TravelBooking/getWaitingVerificationBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/waiting_verification_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getApprovedBookings = createAsyncThunk(
  "TravelBooking/getApprovedBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/approved_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getRejectedBookings = createAsyncThunk(
  "TravelBooking/getRejectedBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/rejected_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCanceledBookings = createAsyncThunk(
  "TravelBooking/getCanceledBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/canceled_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const travelBookingSlice = createSlice({
  name: "travel_booking",
  initialState: {
    ...initialStateStatus,
    waitingConfirmationData: createInitialState(),
    waitingVerificationData: createInitialState(),
    bookingStatuses: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookings.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getSumitedBookings.fulfilled, (state, action) => handleFulfilled(state, action, "submitedData"))
      .addCase(getWaitingApprovalBookings.fulfilled, (state, action) => handleFulfilled(state, action, "waitingApprovalData"))
      .addCase(getWaitingConfirmationBookings.fulfilled, (state, action) => handleFulfilled(state, action, "waitingConfirmationData"))
      .addCase(getWaitingVerificationBookings.fulfilled, (state, action) => handleFulfilled(state, action, "waitingVerificationData"))
      .addCase(getApprovedBookings.fulfilled, (state, action) => handleFulfilled(state, action, "approvedData"))
      .addCase(getRejectedBookings.fulfilled, (state, action) => handleFulfilled(state, action, "rejectedData"))
      .addCase(getCanceledBookings.fulfilled, (state, action) => handleFulfilled(state, action, "canceledData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))
      
      .addCase(getBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(exportBookings.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportBookings.fulfilled, (state) => {
        state.exporting = false
        toast.success("Booking has been exported successfully.")
      })
      .addCase(approveBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(rejectBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(sendBackToHRAdmin.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(sendBackToAccountant.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(sendBackUpdateBudgetToHRAdmin.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(confirmBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(verifyBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(resetBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(cancelBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(rollbackBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(recalculateExpenseBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(remindApprovalBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(addLeaveIhrmBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(finalTripToRequesterBooking.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(getAllStatuses.fulfilled, (state, action) => {
        state.bookingStatuses = action.payload
      })
      .addCase(updateProjectBudget.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateEmployeeExpense.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createBookingHotel.fulfilled, (state, action) => {
        const hotel = action.payload

        if (hotel !== null ) {
          state.recordData.hotels.unshift(hotel)
          toast.success(`Hotel have been created successfully.`)
        }
      })
      .addCase(updateBookingHotel.fulfilled, (state, action) => {
        const hotel = action.payload;

        const updatedIndex = state.recordData.hotels.findIndex((item) => item.id === hotel.id)
        if (updatedIndex !== -1) {
          state.recordData.hotels[updatedIndex] = hotel
          toast.success(`Hotel ${hotel.name} have been updated successfully.`)
        }
      })
      .addCase(deleteBookingHotel.fulfilled, (state, action) => {
        const { error, data, message } = action.payload

        if (error !== undefined) {
          toast.error(error)
        }
        else {
          const hotel = data.attributes

          if (hotel !== undefined) {
            state.recordData.hotels = state.recordData.hotels.filter((item) => item.id !== hotel.id)
            toast.success(message)
          }
        }
      })
      .addCase(uploadHotelDocument.fulfilled, (state, action) => {
        const { error, data, hotel_id, message } = action.payload

        if (error) {
          toast.error(error)
        }
        else {
          let hotel = state.recordData.hotels.find((hotel) => hotel.id === hotel_id)
          hotel.documents = data
          toast.success(message)
        }
      })
      .addCase(removeHotelDocument.fulfilled, (state, action) => {
        const {hotelId, documentId} = action.payload
        const hotel = state.recordData.hotels.find((hotel) => hotel.id === hotelId)

        hotel.documents = hotel.documents.filter((item) => item.id !== documentId)
        toast.success(`Attachment ID#${documentId} was successfully removed.`)
      })
      .addCase(createBookingShuttle.fulfilled, (state, action) => {
        const shuttle = action.payload

        if (shuttle !== null ) {
          state.recordData.shuttles.unshift(shuttle)
          toast.success(`${shuttle.name} have been created successfully.`)
        }
      })
      .addCase(updateBookingShuttle.fulfilled, (state, action) => {
        const shuttle = action.payload

        const updatedIndex = state.recordData.shuttles.findIndex((item) => item.id === shuttle.id)
        if (updatedIndex !== -1) {
          state.recordData.shuttles[updatedIndex] = shuttle
          toast.success(`${shuttle.name} have been updated successfully.`)
        }
      })
      .addCase(deleteBookingShuttle.fulfilled, (state, action) => {
        const { error, data, message } = action.payload

        if (error !== undefined) {
          toast.error(error)
        }
        else {
          const shuttle = data.attributes

          if (shuttle !== undefined) {
            state.recordData.shuttles = state.recordData.shuttles.filter((item) => item.id !== shuttle.id)
            toast.success(message)
          }
        }
      })
      .addCase(uploadShuttleDocument.fulfilled, (state, action) => {
        const { error, data, shuttle_id, message } = action.payload

        if (error) {
          toast.error(error)
        }
        else {
          let shuttle = state.recordData.shuttles.find((shuttle) => shuttle.id === shuttle_id)
          shuttle.documents = data
          toast.success(message)
        }
      })
      .addCase(removeShuttleDocument.fulfilled, (state, action) => {
        const {shuttleId, documentId} = action.payload
        const shuttle = state.recordData.shuttles.find((shuttle) => shuttle.id === shuttleId)

        shuttle.documents = shuttle.documents.filter((item) => item.id !== documentId)
        toast.success(`Attachment ID#${documentId} was successfully removed.`)
      })
      .addCase(printPDFBooking.fulfilled, () => {
        toast.success("Booking has been generate PDF successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = travelBookingSlice.actions;

export default travelBookingSlice.reducer;