import React, { useMemo } from 'react';
import { useLocation, useParams } from "react-router-dom";
import BookingDetailHeader from "./detail/BookingDetailHeader";
import BookingDetailInfo from "./detail/BookingDetailInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { approveBooking, confirmBooking, getBooking, sendBackToAccountant, sendBackToHRAdmin } from "redux/booking/travel/travelBookingSlice";
import IconAlert from "components/common/IconAlert";
import IconButton from 'components/common/IconButton';
import BookingDetailRejectModal from "./detail/BookingDetailRejectModal";
import BookingDetailVerifyModal from "./detail/BookingDetailVerifyModal";
import BookingScheduleItinerary from "./BookingScheduleItinerary";
import BookingJobTransactions from "./BookingJobTransactions";
import BookingActivity from "./detail/BookingActivity";

const BookingDetail = () => {
  const {recordData: booking} = useSelector((state) => state.booking_travel);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showVerifyForm, setShowVerifyForm] = useState(false);

  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const approvalType = searchParams.get('type');

  const bookingId = useMemo(() => booking?.id, [booking]);
  const dispatch = useDispatch();
  
  const {status, permissions} = booking || {};
  const {
    allow_approve,
    allow_send_to_accountant,
    allow_send_to_admin,
    allow_admin_hr_site_office_confirmation,
    allow_admin_hr_head_office_verification,
  } = permissions || {};

  useEffect(() => {
    if (approvalType !== null) {
      if (approvalType === "approve") {
        approve()
      }
      else {
        reject()
      }
    }
  }, [approvalType]);

  useEffect(() => {
    console.log("Effect is running");
    dispatch(getBooking(params.id))
  }, [params.id, dispatch])
  
  const handleConfirmBooking = () => {
    dispatch(confirmBooking(bookingId))
  }

  const handleVerifyBooking = () => {
    setShowVerifyForm(true)
  }

  const approve = () => {
    dispatch(approveBooking(bookingId))
  }

  const handleApproveBooking = () => {
    approve()
  }

  const reject = () => {
    setShowRejectForm(true)
  }

  const handleRejectBooking = () => {
    reject()
  }

  const handleSendBackToAdminOrAccountant = () => {

    if (allow_send_to_accountant) {
      dispatch(sendBackToAccountant(bookingId))
    }
    else
    {
      dispatch(sendBackToHRAdmin(bookingId))
    }
  }

  return (
    <>
      <BookingDetailHeader booking={booking} />
      
      <IconAlert variant={status?.css_class}>
        <p className="mb-0">
          {booking.workflow_message}

          {(allow_send_to_accountant || allow_send_to_admin) && status.id === 1 && (
            <IconButton
              variant="info"
              size="sm"
              icon="share"
              transform="shrink-2"
              iconAlign="middle"
              className="ms-2"
              onClick={handleSendBackToAdminOrAccountant}
            >
              <span className="d-none d-xl-inline-block ms-1">{allow_send_to_accountant ? "Send back to Accountant now!" : "Send back to HR Admin now!" }</span>
            </IconButton>
          )}

          {allow_approve && (
            <>
              <IconButton
                variant="info"
                size="sm"
                icon="check-circle"
                transform="shrink-2"
                iconAlign="middle"
                className="ms-2"
                onClick={handleApproveBooking}
                title="Approve"
              >
                <span className="d-none d-xl-inline-block ms-1">Approve</span>
              </IconButton>

              <IconButton
                variant="danger"
                size="sm"
                icon="times-circle"
                transform="shrink-2"
                iconAlign="middle"
                className="ms-2"
                onClick={handleRejectBooking}
                title="Reject"
              >
                <span className="d-none d-xl-inline-block ms-1">Reject</span>
              </IconButton>
            </>
          )}

          {allow_admin_hr_site_office_confirmation && (
            <IconButton
              variant="primary"
              size="sm"
              icon="check-circle"
              transform="shrink-2"
              iconAlign="middle"
              className="ms-2"
              onClick={handleConfirmBooking}
              title="Confirm"
            >
              <span className="d-none d-xl-inline-block ms-1">Confirm</span>
            </IconButton>
          )}
          
          {allow_admin_hr_head_office_verification && (
            <IconButton
              variant="primary"
              size="sm"
              icon="check"
              transform="shrink-2"
              iconAlign="middle"
              className="ms-2"
              onClick={handleVerifyBooking}
              title="Verify"
            >
              <span className="d-none d-xl-inline-block ms-1">Verify</span>
            </IconButton>
          )}
          
        </p>
      </IconAlert>
      <Card>
        <Tab.Container defaultActiveKey="booking-detail">
          <Card.Header className="p-0 border-bottom">
            <Nav className="nav-tabs border-0 flex-nowrap tab-booking-details">
              <Nav.Item>
                <Nav.Link
                  eventKey="booking-detail"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="stream"
                    className="text-600 tab-icon"
                  />
                  <h6 className="text-600 mb-0 ms-1">Detail</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="booking-itinerary"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="suitcase-rolling"
                    className="text-600 tab-icon"
                  />
                  <h6 className="text-600 mb-0 ms-1">Schedule itinerary</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="booking-job-transactions"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="stream"
                    className="text-600 tab-icon"
                  />
                  <h6 className="text-600 mb-0 ms-1">Job transactions</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="booking-activity-log"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="history"
                    className="text-600"
                  />
                  <h6 className="text-600 mb-0 ms-1">Activities</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              <Tab.Pane eventKey="booking-detail">
                <BookingDetailInfo booking={booking} />
              </Tab.Pane>
              <Tab.Pane eventKey="booking-itinerary">
                <BookingScheduleItinerary booking={booking} />
              </Tab.Pane>
              <Tab.Pane eventKey="booking-job-transactions">
                <BookingJobTransactions booking={booking} />
              </Tab.Pane>
              <Tab.Pane eventKey="booking-activity-log">
                <BookingActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>

      <BookingDetailRejectModal 
        showModal={showRejectForm}
        setshowModal={setShowRejectForm}
        booking={booking}
      />

      <BookingDetailVerifyModal 
        showModal={showVerifyForm}
        setshowModal={setShowVerifyForm}
        booking={booking}
      />
    </>
  )
};

export default BookingDetail;