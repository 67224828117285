import React from 'react';
import { Button, Form, Modal} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteDriverTran } from "redux/booking/car/carBookingSlice";
import IconAlert from 'components/common/IconAlert';
import PropTypes from 'prop-types';

const DeleteDriverTranConfirm = ({showModal, setshowModal, transaction, driverTran}) => {
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  const driverName = driverTran?.driver.name;
  const transactionId = transaction.id;

  const onSubmit = () => {
    try {
      dispatch(deleteDriverTran({transactionId, driverTranId: driverTran?.id}));
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Cancel driver for car booking!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="warning">
              <p className="mb-0">Booking {transaction.booking_number} was approved. Are you sure to cancel driver {driverName}?</p>
            </IconAlert>
       
            {/* <Form.Group as={Row} className="my-3" controlId="reason">
              <Form.Label column sm={3} className="text-lg-end">
                Reason
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="reason"
                  placeholder="Reason"
                  className="fs--1"
                  {...register("reason", {
                    required: "Reason must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.reason && errors.reason.message}
                </span>
              </Col>
            </Form.Group> */}
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              variant="primary"
              type="submit"
            >
              Yes
            </Button>
            <Button size="sm" variant="secondary" onClick={closeModal}>No</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

DeleteDriverTranConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  driverTran: PropTypes.object
}

export default DeleteDriverTranConfirm;