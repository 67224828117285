import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Form, Modal, Row, Col, Button } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import React, { useState } from "react";
import Select from "react-select";
import Flex from "components/common/Flex";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaClone } from "react-icons/fa";
import { clonePIFVersion } from "redux/crm/project/changeOrderSlice";
import PropTypes from 'prop-types';

const ClonePIFForm = ({showModal, setshowModal, project, building, adaptation }) => {
  const {control, handleSubmit, formState: {errors}, setValue } = useForm()
  const [showVersion, setShowVersion] = useState(false)
  const versions = adaptation?.pif_versions || []
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const projectId = project.id

  const TYPE_OF_CLONE = ["current", "version"]

  const versionOptions = versions && versions.map(item => ({value: item.id, label: `Version ${item.version}`}))

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      await dispatch(clonePIFVersion({
        projectId,
        buildingId: building.id,
        changeOrderId: adaptation.id,
        data
      }))

      navigate(`/crm/projects/${projectId}/buildings/${building.id}/change-orders/${adaptation?.id}`)
      closeModal()
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const formatLabel = (label) => {
    return label.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Clone PIF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Specify to clone a new PIF from the current version accepted by CSD or from versions stored in this <strong>CO#{adaptation?.co}</strong>.
          </IconAlert>

          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3} className="text-lg-end"></Form.Label>
            <Col sm={9} md={7}>
              <Controller 
                control={control}
                name="type_of_clone"
                defaultValue={TYPE_OF_CLONE[0]}
                render={({field}) => (
                  <Flex direction="row" className="mt-1">
                    {TYPE_OF_CLONE.map((option, index) => (
                      <Form.Check
                        key={option}
                        id={`${option}${index}`}
                        type="radio"
                        label={formatLabel(option)}
                        value={option}
                        className="me-3"
                        {...field}
                        checked={field.value === option}
                        onChange={() => { 
                          field.onChange(option)
                          setShowVersion(option === TYPE_OF_CLONE[1])
                          setValue("source_version_id", null)
                        }}
                      />
                    ))}
                  </Flex>
                )}
              />
            </Col>
          </Form.Group>

          {showVersion && (
            <Form.Group as={Row} className="mb-3" controlId="source_version_id">
              <Form.Label column sm={3} className="text-lg-end">
                Version
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="source_version_id"
                  rules={{required: "Version must be required."}}
                  render={() => (
                    <Select
                      closeMenuOnSelect={true}
                      options={versionOptions}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      onChange={selectedOption => setValue('source_version_id', selectedOption.value, {shouldValidate: true})}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.source_version_id && errors.source_version_id.message}
                </span>
              </Col>
            </Form.Group>
          )}

        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit"><FaClone /> Clone</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

ClonePIFForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired
}

export default ClonePIFForm;