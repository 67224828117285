import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { createPositionGroup, updatePositionGroup } from "redux/organization/positionGroupSlice";

const PositionGroupForm = ({showModal, setshowModal, title, record}) => {
  const {register, handleSubmit, formState: {errors}, setValue } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if(record) {
      Object.entries(record).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, record])

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (record) {
        const positionGroupId = record.id
        dispatch(updatePositionGroup({ positionGroupId, data }))
      }
      else {
        dispatch(createPositionGroup(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                className="fs--1"
                {...register("name", {
                  required: "Name must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit">Save</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

PositionGroupForm.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  title: PropTypes.string,
  record: PropTypes.object
}

export default PositionGroupForm