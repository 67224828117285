import IconButton from 'components/common/IconButton';
import React, { useState, useEffect, useMemo } from "react"
import { Accordion, Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getBuildingTypeLevel1, getBuildingTypeLevel2, getContactModes, getLeadSources, getIndustries } from "redux/crm/settingSlice";
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { CRM_LEAD_RATING, CRM_LEGAL_STATUS, CRM_NUMBER_OF_EMPLOYEES, CRM_TYPE_OF_CUSTOMER } from 'app_config';
import { debouncedFilterEmployees } from 'helpers/debounce';
import { getCountries } from 'redux/settingsSlice';
import { FaPlus } from 'react-icons/fa';
import Flex from 'components/common/Flex';
import { createLead, updateLead } from 'redux/crm/leadSlice';
import { toast } from 'react-toastify';
import ContactForm from './ContarctForm';
import GoogleMapAutoCompletePlaceDebounce from 'components/google-map-places/GoogleMapAutoCompletePlaceDebounce';
import PropTypes from 'prop-types';

const LeadForm = ({lead, title}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues } = useForm()
  const [activeKey, setActiveKey] = useState(["0", "1"])
  const {buildingTyleLevel1Data, buildingTyleLevel2Data, leadSourceData, contactModeData, industryData} = useSelector((state) => state.crm_setting)
  const {countryData} = useSelector((state) => state.settings)

  const newContact = { 
    salutation: null,
    first_name: null,
    last_name: null,
    position: null,
    email: null,
    mobile: null
  }

  const [contacts, setContacts] = useState([newContact]);
  const [contactsDeleted, setContactsDeleted] = useState([]);
  const [address, setAddress] = useState(null);
  const [projectLocation, setProjectLocation] = useState(null);

  const {data: user} = useSelector(state => state.profile_user)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getBuildingTypeLevel1())
    dispatch(getLeadSources())
    dispatch(getContactModes())
    dispatch(getIndustries())

    if (countryData.length === 0) {
      dispatch(getCountries())
    }
  }, [])

  useEffect(() => {
    setValue("type_of_company", typeOfCustomerOptions[0]);
    setValue("no_of_employee", numberOfEmployeesOptions[0]);

    if(lead) {
      Object.entries(lead).forEach(([key, value]) => {
        setValue(key, value)
      })

      setContacts(lead?.contacts);
    }
  }, [setValue, lead])

  const onSubmit = async (data) => {
    //console.log(data)

    if(contacts) {
      const updateContacts = contacts.map(item => ({...item, main_contact: user?.name, sales_unit_id: user?.sales_unit.id, user_id: user.id}))

      if (contactsDeleted) {
        const deletedContacts = contactsDeleted.map(item => ({ ...item, _destroy: "1" }))
        const contactData = [...updateContacts, ...deletedContacts]

        data = {...data, contacts_attributes: contactData}
      }
      else
      {
        data = {...data, contacts_attributes: updateContacts}
      }
    }

    try {
      let leadId = null

      if (address) {
        data = {
          ...data,
          address: address.description
        };
      }

      if (projectLocation) {
        data = {
          ...data,
          project_location: projectLocation.description,
          province_project: projectLocation.city_province,
          country_project: projectLocation.country
        };
      }

      if (lead) {
        leadId = lead.id
        dispatch(updateLead({leadId, data }))
      }
      else {
        const response = await dispatch(createLead(data))

        if (response.payload && response.payload?.data?.id) {
          leadId = response.payload.data.id;
        } else {
          toast.error(response.payload.error)
        }
      }

      if (leadId) {
        navigate(`/crm/leads/${leadId}`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const fetchBuildingTypeLevel2 = (buildingTypeL1Id) => {
    const params = {parent_id: buildingTypeL1Id}
    dispatch(getBuildingTypeLevel2(params))
  }

  const buildingTypeL1Options = useMemo(() => (
    buildingTyleLevel1Data
    ? buildingTyleLevel1Data.map((option) => ({ value: option.id, label: option.name}))
    : []
  ), [buildingTyleLevel1Data])

  const defaultBuildingTypeL1Option = useMemo(() => (
    lead && lead.building_type_l1 ? {value: lead.building_type_l1.id, label: lead.building_type_l1.name} : null
  ), [lead])

  const buildingTypeL2Options = useMemo(() => (
    buildingTyleLevel2Data
    ? buildingTyleLevel2Data.map((option) => ({ value: option.id, label: option.name}))
    : []
  ), [buildingTyleLevel2Data])

  const defaultBuildingTypeL2Option = useMemo(() => (
    lead && lead.building_type_l2 ? {value: lead.building_type_l2.id, label: lead.building_type_l2.name} : null
  ), [lead])

  const leadSourceOptions = useMemo(() => leadSourceData.map((option) => ({ value: option.name, label: option.name })), []) 
  const defaultLeadSourceOption = useMemo(() => (
    lead && lead.source ? { value: lead.source, label: lead.source } : leadSourceData[0]?.name
  ), [lead])

  const contactModeOptions = useMemo(() => contactModeData.map((option) => ({ value: option.name, label: option.name })), []) 
  const defaultContactModeOption = useMemo(() => (
    lead && lead.contact_mode ? { value: lead.contact_mode, label: lead.contact_mode } : contactModeData[0]?.name
  ), [lead])

  const industryOptions = useMemo(() => industryData.map((option) => ({ value: option.name, label: option.name })), []) 
  const defaultIndustryOption = useMemo(() => (
    lead && lead.industry ? { value: lead.industry, label: lead.industry } : industryData[0]?.name
  ), [lead])

  const ratingOptions = useMemo(() => (
    CRM_LEAD_RATING.map((option) => ({ value: option, label: option}))
  ))
  const defaultRatingOption = () => (
    lead && lead.rating ? { value: lead.rating, label: lead.rating } : null
  );

  const ownerOptions = (inputValue, callback) => {
    debouncedFilterEmployees(inputValue, callback)
  }

  const defaultOwnerOption = useMemo(() => (
    lead && lead.owner_by ? { value: lead.owner_by, label: lead.owner_by } : null
  ), [lead])

  const typeOfCustomerOptions = useMemo(() => CRM_TYPE_OF_CUSTOMER.map((option) => ({ value: option, label: option })), []) 
  const defaultTypeOfCustomerOption = useMemo(() => (
    lead ? { value: lead.type_of_company, label: lead.type_of_company } : typeOfCustomerOptions[0]
  ), [lead])

  const numberOfEmployeesOptions = useMemo(() => CRM_NUMBER_OF_EMPLOYEES.map((option) => ({ value: option, label: option })), []) 
  const defaultNumberOfEmployeesOption = useMemo(() => (
    lead ? { value: lead.no_of_employee, label: lead.no_of_employee } : numberOfEmployeesOptions[0]
  ), [lead])

  const handleAddContact = () => {
    const updatedContacts = [...contacts, newContact];
    setContacts(updatedContacts)
  }

  const handleRemoveContact = (index) => {
    //store after deleted
    const deleteContacts = contacts.filter((item, i) => i !== index)
    setContacts(deleteContacts)

    //store deleted items
    const contactsDeleted = contacts.filter((item, i) => i === index)
    setContactsDeleted(contactsDeleted)
  }

  const handleUpdatedContact = (index, contact) => {
    const updatedContacts = [...contacts]

    updatedContacts[index] = contact;
    setContacts(updatedContacts);
  }

  return (
    <Card className="fs--1">
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <Card.Title>{title}</Card.Title>
          <IconButton
            onClick={handleBack}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
        </Card.Header>
        <Card.Body className="fs--1">
          <div className="mb-2">All leads must be collected by Markering or Bussiness Development.</div>

          <Accordion
              id="new-lead"
              className="border rounded overflow-hidden fs--1 mt-3"
              alwaysOpen
              flush
              activeKey={activeKey}
              onSelect={handleAccordionSelect}
            >
              <Card className="rounded-0">
                <Accordion.Item eventKey="0" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          1. Lead information (*)
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <Form.Group as={Row} className="mb-2" controlId="name">
                        <Form.Label column sm={3} className="text-lg-end">
                          Lead name
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="name"
                            placeholder="Lead name"
                            className="fs--1"
                            {...register("name", {
                              required: "Lead name must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors.name && errors.name.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="rating">
                        <Form.Label column sm={3} className="text-lg-end">
                          Rating
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="rating"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={ratingOptions}
                                defaultValue={defaultRatingOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('rating', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Rating must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.rating && errors.rating.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="owner_by">
                        <Form.Label column sm={3} className="text-lg-end">
                          Owner
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="owner_by"
                            rules={{required: "Owner must be required"}}
                            render={() => (
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={ownerOptions}
                                defaultValue={defaultOwnerOption}
                                closeMenuOnSelect={true}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                className="w-100"
                                isClearable
                                onChange={(selectedOption) => selectedOption ? setValue("owner_by", selectedOption.value) : null}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors.owner_by && errors.owner_by.message}
                          </span>
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row} className="mb-2 mt-4" controlId="source">
                        <Form.Label column sm={3} className="text-lg-end">
                          Source
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="source"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={leadSourceOptions}
                                defaultValue={defaultLeadSourceOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('source', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Lead source must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.source && errors.source.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="contact_mode">
                        <Form.Label column sm={3} className="text-lg-end">
                          How does customer know Pebsteel?
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="contact_mode"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={contactModeOptions}
                                defaultValue={defaultContactModeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('contact_mode', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'How does customer know Pebsteel must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.contact_mode && errors.contact_mode.message}
                          </span>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          2. Account (*)
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <Form.Group as={Row} className="mb-2" controlId="account_name">
                        <Form.Label column sm={3} className="text-lg-end">
                          Name
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="account_name"
                            placeholder="Account name"
                            className="fs--1"
                            {...register("account_name", {
                              required: "Account name must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors.account_name && errors.account_name.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2 mt-3" controlId="legal_status">
                        <Form.Label column sm={3} className="text-lg-end">
                          Legal status
                        </Form.Label>
                        <Col sm={9} md={7}>
                          {CRM_LEGAL_STATUS.map((status) => (
                            <Form.Check
                              key={status}
                              inline
                              type="radio"
                              name="legal_status"
                              value={status}
                              label={status}
                              defaultChecked={status === 'Private'}
                              className="fs--1"
                              {...register("legal_status")}
                            />
                          ))}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="type_of_company">
                        <Form.Label column sm={3} className="text-lg-end">
                          Type of company
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="type_of_company"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={typeOfCustomerOptions}
                                defaultValue={defaultTypeOfCustomerOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('type_of_company', selectedOption.value)}
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="no_of_employee">
                        <Form.Label column sm={3} className="text-lg-end">
                          No. of employees
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="no_of_employee"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={numberOfEmployeesOptions}
                                defaultValue={defaultNumberOfEmployeesOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('no_of_employee', selectedOption.value)}
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="industry">
                        <Form.Label column sm={3} className="text-lg-end">
                          Industry
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="industry"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={industryOptions}
                                defaultValue={defaultIndustryOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('industry', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Industry must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.industry && errors.industry.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="work_phone">
                        <Form.Label column sm={3} className="text-lg-end">
                          Work phone
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="work_phone"
                            placeholder="Work phone"
                            className="fs--1"
                            {...register("work_phone")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="work_email">
                        <Form.Label column sm={3} className="text-lg-end">
                          Work wmail
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            type="email"
                            name="work_email"
                            placeholder="example@company.com"
                            className="fs--1"
                            {...register('email', {
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address'
                              }
                            })}
                          />
                          <span className="text-danger">
                            {errors.work_email && errors.work_email.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="address">
                        <Form.Label column sm={3} className="text-lg-end">
                          Address
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <GoogleMapAutoCompletePlaceDebounce id="lead-address" setAddress={setAddress} />
                        </Col>
                      </Form.Group>

                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          3. Contacts
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">

                    <Flex
                      justifyContent="end"
                      alignItems="center"
                      className="text-primary cursor-pointer fs-0"
                      onClick={handleAddContact}
                    >
                      <span className="circle-dashed">
                        <FaPlus />
                      </span>
                      <span className="ms-3">Add Contact</span>
                    </Flex>

                    { contacts && contacts.map((item, index) => (
                      <ContactForm
                        index={index}
                        key={index}
                        lead={lead}
                        contact={item}
                        control={control}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        removeContact={() => handleRemoveContact(index)}
                        updatedContact={handleUpdatedContact}
                      />
                    ))}
                      
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          4. Customer message
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <div className="mb-4 text-primary">Customer left a message.</div>

                      <Form.Group as={Row} className="mb-2" controlId="message">
                        <Form.Label column sm={3} className="text-lg-end">
                          Message
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            as="textarea"
                            rows={10}
                            name="message"
                            placeholder="Message"
                            className="fs--1"
                            {...register("message")}
                          />
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="4" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          5. Project information (optional)
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <div className="mb-4 text-primary">Enter the project information if available from the Lead.</div>

                      <Form.Group as={Row} className="mb-2" controlId="project_name">
                        <Form.Label column sm={3} className="text-lg-end">
                          Project name
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="project_name"
                            placeholder="Project name"
                            className="fs--1"
                            {...register("project_name")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="amount">
                        <Form.Label column sm={3} className="text-lg-end">
                          Amount (USD)
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="amount"
                            placeholder="Amount"
                            className="fs--1"
                            min={0}
                            {...register("amount")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="area">
                        <Form.Label column sm={3} className="text-lg-end">
                          Area (m2)
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="area"
                            placeholder="Area"
                            className="fs--1"
                            min={0}
                            {...register("area")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2 mt-4" controlId="building_type_l1_id">
                        <Form.Label column sm={3} className="text-lg-end">
                          Building Type L1
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="building_type_l1_id"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={buildingTypeL1Options}
                                defaultValue={defaultBuildingTypeL1Option}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => { 
                                  const value = selectedOption.value
                                  setValue('building_type_l1_id', value, {shouldValidate: true})
                                  fetchBuildingTypeLevel2(value)

                                  //reset building level2 if current has value selected
                                  setValue('building_type_l2_id', null)
                                }}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors.building_type_l1_id && errors.building_type_l1_id.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="building_type_l2_id">
                        <Form.Label column sm={3} className="text-lg-end">
                          Building Type L2
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="building_type_l2_id"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={buildingTypeL2Options}
                                defaultValue={defaultBuildingTypeL2Option}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('building_type_l2_id', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors.building_type_l2_id && errors.building_type_l2_id.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2 mt-4" controlId="project_location">
                        <Form.Label column sm={3} className="text-lg-end">
                          Location
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <GoogleMapAutoCompletePlaceDebounce id="lead-project-location" setAddress={setProjectLocation} defaultAddress={lead?.project_location} />
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Card>
            </Accordion>

            <div className="bg-light fixed-bottom border-0 text-center p-3">
              <Button size="sm" type="submit" className="me-2">{lead ? "Update" : "Create"} Lead</Button>
              <Button size="sm" variant="danger" onClick={handleBack}>Cancel</Button>
            </div>
        </Card.Body>
      </Form>
    </Card>
  )
};

LeadForm.propTypes = {
  lead: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default LeadForm;