import { CRM_ADDRESS_TYPE } from 'app_config';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select'
import { toast } from 'react-toastify';
import { createAddress, updateAddress } from 'redux/crm/addressSlice';
import { useDispatch } from 'react-redux';
import { FaBan, FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import GoogleMapAutoCompletePlaceDebounce from 'components/google-map-places/GoogleMapAutoCompletePlaceDebounce';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const AddressForm = ({ customerId, address, setCollapsed, closeEdit }) => {
  const {control, handleSubmit, formState: {errors}, setValue, clearErrors, setError } = useForm();
  const [addressFull, setAddressFull] = useState({});
  
  const dispatch = useDispatch();

  useEffect(() => {
    setValue('address_type', addressTypeOptions[0]);
    setValue('name', addressFull.description || address?.name);

    if(address) {
      Object.entries(address).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, address, addressFull])

  const onSubmit = async (data) => {
    if (!data.name) { //&& address_type
      setError('name', { type: 'manual', message: 'Address must be required.' });
      return;
    }

    const street_name = addressFull.route !== undefined ? `${addressFull.street_number} ${addressFull.route}`.trim() : address?.street_name;

    const newData = {
      address_type: data.address_type.value,
      name: addressFull.description,
      street_name: street_name,
      ward: addressFull.ward,
      district: addressFull.district,
      city: addressFull.city_province,
      country: addressFull.country,
      is_primary: data.is_primary,
    };

    try {
      if (!address) {
        await dispatch(createAddress({ customerId, data: newData }));
      } else {
        const addressId = address.id;
        await dispatch(updateAddress({ customerId, addressId, data: newData }));
        closeEdit();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addressTypeOptions = useMemo(() => CRM_ADDRESS_TYPE.map((option) => ({ value: option, label: option })), []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='fs--1'>
      {address && (
        <h5 className='mb-4'>Edit address ID#:{address.id}</h5>
      )}
      <Form.Group as={Row} className='mb-3' controlId='address_type'>
        <Form.Label column sm={3} className='text-lg-end'>
          Address type
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name='address_type'
            render={({ field }) => (
              <Select
                {...field}
                closeMenuOnSelect={true}
                options={addressTypeOptions}
                value={addressTypeOptions.find(option => option.value === field.value)}
                placeholder='Select...'
                classNamePrefix='react-select'
                required={true}
                onChange={(selectedOption) => {
                  clearErrors('address_type');
                  field.onChange(selectedOption);
                }}
              />
            )}
            rules={{ required: 'Address type is required' }}
          />
          <span className='text-danger'>
            {errors.address_type && errors.address_type.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3' controlId='name'>
        <Form.Label column sm={3} className='text-lg-end'>
          Address
        </Form.Label>
        <Col sm={9} md={7}>
          <GoogleMapAutoCompletePlaceDebounce setAddress={setAddressFull} defaultAddress={address?.name} />
          <span className='text-danger'>
            {errors.name && errors.name.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3' controlId='is_primary'>
        <Col sm={{ span: 10, offset: 3 }}>
          <Controller
            control={control}
            name='is_primary'
            render={({ field }) => (
              <Form.Check
                type='switch'
                label='Set primary'
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={{ span: 10, offset: 3 }}>
          <Button size='sm' type='submit'><FaSave /> Save</Button>
          {address ? (
            <Button variant='danger' size='sm' className='ms-2' onClick={closeEdit}><FaBan /> Cancel</Button>
          ) : (
            <Button variant='falcon-default' size='sm' className='ms-2' onClick={() => setCollapsed(true)}><MdClose /> Close</Button>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

AddressForm.propTypes = {
  customerId: PropTypes.number.isRequired,
  address: PropTypes.object.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  closeEdit: PropTypes.func.isRequired
}

export default AddressForm;