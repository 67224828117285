import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload,
  createInitialState
} from 'helpers/reducerHelpers';

const siteUrl = "/organization/sites"

export const getAllSites = createAsyncThunk(
  "Site/getAllSites",
  async (params) => {
    try {
      const response = await apiAxios.get(`${siteUrl}`, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getSite = createAsyncThunk(
  "Site/getSite",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${siteUrl}/${siteId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createSite = createAsyncThunk(
  "Site/createSite",
  async (data) => {
    try {
      const response = await apiAxios.post(siteUrl, data)
      return response.data
    }
    catch(error){
      throw new Error(error.message)
    }
  }
)

export const updateSite = createAsyncThunk(
  "Site/updateSite",
  async ({ siteId, data }) => {
    try {
      const response = await apiAxios.put(`${siteUrl}/${siteId}`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const deleteSite = createAsyncThunk(
  "Site/deleteSite",
  async (siteId) => {
    try {
      const response = await apiAxios.delete(`${siteUrl}/${siteId}`);
      return response.data

    } catch (error) {
      throw new Error(error.message)
    }
  }
);

export const deactivateSite = createAsyncThunk(
  "Site/deactivateSite",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${siteUrl}/${siteId}/deactivate`);
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const exportSites = createAsyncThunk(
  "Site/exportSites",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${siteUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `sites-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
);

export const getCarBookingSites = createAsyncThunk(
  "Site/getCarBookingSites",
  async (params) => {
    try {
      const response = await apiAxios.get(`${siteUrl}/car_booking`, params)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getTravelBookingSites = createAsyncThunk(
  "Site/getTravelBookingSites",
  async (params) => {
    try {
      const response = await apiAxios.get(`${siteUrl}/travel_booking`, params)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getActiveSites = createAsyncThunk(
  "Site/getActiveSites",
  async (params) => {
    try {
      const response = await apiAxios.get(`${siteUrl}/active_list`, params)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const siteSlice = createSlice({
  name: "organization_site",
  initialState: {
    ...initialState,
    carBookingData: createInitialState(),
    travelBookingData: createInitialState(),
    activeData: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSites.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getCarBookingSites.fulfilled, (state, action) => handleFulfilled(state, action, "carBookingData"))
      .addCase(getTravelBookingSites.fulfilled, (state, action) => handleFulfilled(state, action, "travelBookingData"))

      .addCase(getSite.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(createSite.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(deactivateSite.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(exportSites.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportSites.fulfilled, (state) => {
        state.exporting = false
        toast.success("Sites has been exported successfully.")
      })
      .addCase(getActiveSites.fulfilled, (state, action) => {
        state.activeData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = siteSlice.actions;

export default siteSlice.reducer;
