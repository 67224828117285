import React from 'react';
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import PublicLayout from '../layouts/PublicLayout';

// import Accordion from 'components/doc-components/Accordion';
// import Alerts from 'components/doc-components/Alerts';
// import Badges from 'components/doc-components/Badges';
// import Breadcrumbs from 'components/doc-components/Breadcrumb';
// import Buttons from 'components/doc-components/Buttons';
// import CalendarExample from 'components/doc-components/CalendarExample';
// import Cards from 'components/doc-components/Cards';
// import Dropdowns from 'components/doc-components/Dropdowns';
// import ListGroups from 'components/doc-components/ListGroups';
// import Modals from 'components/doc-components/Modals';
// import Offcanvas from 'components/doc-components/Offcanvas';
// import Pagination from 'components/doc-components/Pagination';
// import BasicProgressBar from 'components/doc-components/ProgressBar';
// import Spinners from 'components/doc-components/Spinners';
// import Toasts from 'components/doc-components/Toasts';
// import Avatar from 'components/doc-components/Avatar';
// import Image from 'components/doc-components/Image';
// import Tooltips from 'components/doc-components/Tooltips';
// import Popovers from 'components/doc-components/Popovers';
// import Figures from 'components/doc-components/Figures';
// import Hoverbox from 'components/doc-components/Hoverbox';
// import Tables from 'components/doc-components/Tables';
// import FormControl from 'components/doc-components/FormControl';
// import InputGroup from 'components/doc-components/InputGroup';
// import Select from 'components/doc-components/Select';
// import Checks from 'components/doc-components/Checks';
// import Range from 'components/doc-components/Range';
// import FormLayout from 'components/doc-components/FormLayout';
// import FloatingLabels from 'components/doc-components/FloatingLabels';
// import FormValidation from 'components/doc-components/FormValidation';
// import BootstrapCarousel from 'components/doc-components/BootstrapCarousel';
// import SlickCarousel from 'components/doc-components/SlickCarousel';
// import Navs from 'components/doc-components/Navs';
// import Navbars from 'components/doc-components/Navbars';
// import Tabs from 'components/doc-components/Tabs';
// import Collapse from 'components/doc-components/Collapse';
// import CountUp from 'components/doc-components/CountUp';
// import Embed from 'components/doc-components/Embed';
// import Background from 'components/doc-components/Backgrounds';
// import Search from 'components/doc-components/Search';
// import VerticalNavbar from 'components/doc-components/VerticalNavbar';
// import NavBarTop from 'components/doc-components/NavBarTop';
// import NavbarDoubleTop from 'components/doc-components/NavbarDoubleTop';
// import ComboNavbar from 'components/doc-components/ComboNavbar';
// import TypedText from 'components/doc-components/TypedText';
// import FileUploader from 'components/doc-components/FileUploader';
// import Borders from 'components/utilities/Borders';
// import Colors from 'components/utilities/Colors';
// import ColoredLinks from 'components/utilities/ColoredLinks';
// import Display from 'components/utilities/Display';
// import Visibility from 'components/utilities/Visibility';
// import StretchedLink from 'components/utilities/StretchedLink';
// import Float from 'components/utilities/Float';
// import Position from 'components/utilities/Position';
// import Spacing from 'components/utilities/Spacing';
// import Sizing from 'components/utilities/Sizing';
// import TextTruncation from 'components/utilities/TextTruncation';
// import Typography from 'components/utilities/Typography';
// import VerticalAlign from 'components/utilities/VerticalAlign';
// import Flex from 'components/utilities/Flex';
// import Grid from 'components/utilities/Grid';
// import WizardForms from 'components/doc-components/WizardForms';
import GettingStarted from 'components/documentation/GettingStarted';
import Configuration from 'components/documentation/Configuration';
// import AnimatedIcons from 'components/doc-components/AnimatedIcons';
// import DatePicker from 'components/doc-components/DatePicker';
// import FontAwesome from 'components/doc-components/FontAwesome';
// import Analytics from 'components/dashboards/analytics';
// import Crm from 'components/dashboards/crm';
// import Saas from 'components/dashboards/saas';
// import Associations from 'components/pages/asscociations/Associations';
// import Followers from 'components/app/social/followers/Followers';
// import Notifications from 'components/app/social/notifications/Notifications';
// import ActivityLog from 'components/app/social/activity-log/ActivityLog';
// import Placeholder from 'components/doc-components/Placeholder';
// import Lightbox from 'components/doc-components/Lightbox';
// import AdvanceTableExamples from 'components/doc-components/AdvanceTableExamples';
// import ModalAuth from 'components/authentication/modal/ModalAuth';
// import Calendar from 'components/app/calendar/Calendar';
// import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt';
// import FaqBasic from 'components/pages/faq/faq-basic/FaqBasic';
// import FaqAccordion from 'components/pages/faq/faq-accordion/FaqAccordion';
// import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
// import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
// import PricingAlt from 'components/pages/pricing/pricing-alt/PricingAlt';
// import Invoice from 'components/app/e-commerce/Invoice';
// import Billing from 'components/app/e-commerce/billing/Billing';
// import Checkout from 'components/app/e-commerce/checkout/Checkout';
// import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
// import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
// import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';
// import Products from 'components/app/e-commerce/product/Products';
// import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
// import Orders from 'components/app/e-commerce/orders/order-list/Orders';

// import Courses from 'components/app/e-learning/course/Courses';
// import CourseDetails from 'components/app/e-learning/course/course-details';
// import CreateCourse from 'components/app/e-learning/course/create-a-course';
// import TrainerProfile from 'components/app/e-learning/trainer-profile';
// import StudentOverview from 'components/app/e-learning/student-overview';
// import CreateEvent from 'components/app/events/create-an-event/CreateEvent';
// import EventList from 'components/app/events/event-list/EventList';
// import EventDetail from 'components/app/events/event-detail/EventDetail';
// import EmailDetail from 'components/app/email/email-detail/EmailDetail';
// import Compose from 'components/app/email/compose/Compose';
// import Inbox from 'components/app/email/inbox/Inbox';
// import Rating from 'components/doc-components/Rating';
// import AdvanceSelect from 'components/doc-components/AdvanceSelect';
// import Editor from 'components/doc-components/Editor';
// import Chat from 'components/app/chat/Chat';
// import Kanban from 'components/app/kanban/Kanban';
// import DraggableExample from 'components/doc-components/DraggableExample';
// import LineCharts from 'components/doc-components/charts-example/echarts/line-charts';
// import BarCharts from 'components/doc-components/charts-example/echarts/bar-charts';
// import CandlestickCharts from 'components/doc-components/charts-example/echarts/candlestick-charts';
// import GeoMaps from 'components/doc-components/charts-example/echarts/geo-map';
// import ScatterCharts from 'components/doc-components/charts-example/echarts/scatter-charts';
// import PieCharts from 'components/doc-components/charts-example/echarts/pie-charts';
// import RadarCharts from 'components/doc-components/charts-example/echarts/radar-charts/Index';
// import HeatmapCharts from 'components/doc-components/charts-example/echarts/heatmap-chart';
// import Chartjs from 'components/doc-components/charts-example/chartjs';
// import D3js from 'components/doc-components/charts-example/d3';
//import HowToUse from 'components/doc-components/charts-example/echarts/HowToUse';
// import GoogleMapExample from 'components/doc-components/GoogleMapExample';
// import LeafletMapExample from 'components/doc-components/LeafletMapExample';
// import CookieNoticeExample from 'components/doc-components/CookieNoticeExample';
// import Scrollbar from 'components/doc-components/Scrollbar';
// import Scrollspy from 'components/doc-components/Scrollspy';
// import ReactIcons from 'components/doc-components/ReactIcons';
// import ReactPlayerExample from 'components/doc-components/ReactPlayerExample';
// import EmojiMartExample from 'components/doc-components/EmojiMart';
// import TreeviewExample from 'components/doc-components/TreeviewExample';
// import Timeline from 'components/doc-components/Timeline';
// import Widgets from 'widgets/Widgets';
// import Ecommerce from 'components/dashboards/e-commerce';
// import Lms from 'components/dashboards/lms';
// import ProjectManagement from 'components/dashboards/project-management';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLoginOversea from 'components/authentication/simple/LoginOversea';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
//import Dashboard from 'components/dashboards/default';

//import SupportDesk from 'components/dashboards/support-desk';
import TableView from 'components/app/support-desk/tickets-layout/TableView';
import CardView from 'components/app/support-desk/tickets-layout/CardView';
import Contacts from 'components/app/support-desk/contacts/Contacts';
import ContactDetails from 'components/app/support-desk/contact-details/ContactDetails';
import TicketsPreview from 'components/app/support-desk/tickets-preview/TicketsPreview';
import QuickLinks from 'components/app/support-desk/quick-links/QuickLinks';
import Reports from 'components/app/support-desk/reports/Reports';
// import InputMaskExample from 'components/doc-components/InputMaskExample';
// import RangeSlider from 'components/doc-components/RangeSlider';
import PrivateRoute from './privateRoute';
import {
  NewPCV,
  EditPCV,
  Projects,
  ProjectDetail
} from 'components/crm/project';
import {
  ProjectMasterSchedule,
  EngineeringJobProgress,
  EstimateAssignmentSchedule,
  ICCC,
  Quotation,
  PIF,
  QuotationDashboard,
  IndividualProductivity,
  JobEntry,
  ProjectSchedule,
  TeamProductivity
} from 'components/crm/reports';
import Contracts from 'components/crm/contract/Contracts';
import { SalesUnits } from 'components/crm/settings';
import MeetingRoom from 'components/bookings/meeting_room/MeetingRoom';
import {
  ExpensePolicy,
  TravelBookings,
  NewTravelRequest,
  TravelBookingDetail,
  EditTravelBooking,
  ApprovalFlows
} from 'components/bookings/travel';

import Users from 'components/authorization/Users';
import { Permission, Role } from 'components/authorization';
import SalesUnitDetail from 'components/crm/settings/sales_unit/detail/SalesUnitDetail';
import NewSalesUnit from 'components/crm/settings/sales_unit/NewSalesUnit';
import EditSalesUnit from 'components/crm/settings/sales_unit/EditSalesUnit';

import Employees from 'components/organization/Employees';
import EmployeeDetail from 'components/organization/employee/detail/EmployeeDetail';
import NewEmployee from 'components/organization/employee/NewEmployee';
import EditEmployee from 'components/organization/employee/EditEmployee';
import Departments from 'components/organization/Departments';
import Sites from 'components/organization/Sites';
import NewContact from 'components/crm/contact/NewContact';
import EditContact from 'components/crm/contact/EditContact';
import ContactDetail from 'components/crm/contact/detail/ContactDetail';
import NotificationList from 'components/notification/NotificationList';
import NewCustomer from 'components/crm/customer/NewCustomer';
import EditCustomer from 'components/crm/customer/EditCustomer';
import CustomerDetail from 'components/crm/customer/detail/CustomerDetail';
import UserDetail from 'components/authorization/user/detail/UserDetail';
import NewUser from 'components/authorization/user/NewUser';
import EditUser from 'components/authorization/user/EditUser';
import RoleDetail from 'components/authorization/role//detail/RoleDetail';
import Cars from 'components/bookings/car/Cars';
import Drivers from 'components/bookings/car/Drivers';
import Transactions from 'components/bookings/car/Transactions';
import EditCarBooking from 'components/bookings/car/transaction/EditCarBooking';
import NewCarBooking from 'components/bookings/car/transaction/NewCarBooking';
import TransactionDetail from 'components/bookings/car/transaction/detail/TransactionDetail';
import TransactionDriverConfirm from 'components/bookings/car/transaction/TransactionDriverConfirm';
import CarCalendar from 'components/bookings/car/CarCalendar';
import TransactionDriverUpdateDeparture from 'components/bookings/car/transaction/TransactionDriverUpdateDeparture';
import VerifyEmail from 'components/authentication/VerifyEmail';
import Error403 from 'components/errors/Error403';
import PrintBooking from 'components/bookings/travel/booking/print/PrintBooking';
import NewWaiver from 'components/crm/waiver/NewWaiver';
import EditWaiver from 'components/crm/waiver/EditWaiver';
import NewChangeOrder from 'components/crm/change-order/NewChangeOrder';
import EditChangeOrder from 'components/crm/change-order/EditChangeOrder';
import ChangeOrderPrint from 'components/crm/change-order/ChangeOrderPrint';
import NewPIF from 'components/crm/pif/NewPIF';
import EditPIF from 'components/crm/pif/EditPIF';
import PIFDetail from 'components/crm/pif/PIFDetail';
import PIFPrint from 'components/crm/pif/PIFPrint';
import ChangeOrderDetail from 'components/crm/change-order/ChangeOrderDetail';
import Waivers from 'components/crm/waiver/Waivers';
import WaiverDetail from 'components/crm/waiver/WaiverDetail';
import WaiverPrint from 'components/crm/waiver/print/WaiverPrint';
import WaiverDashboard from 'components/crm/waiver/dashboard/WaiverDashboard';
import ProjectMasterDashboard from 'components/crm/reports/ProjectMasterDashboard';
import ChangeOrder from 'components/crm/reports/ChangeOrder';
import Leads from 'components/crm/lead/Leads';
import LeadDetail from 'components/crm/lead/LeadDetail';
import NewLead from 'components/crm/lead/NewLead';
import EditLead from 'components/crm/lead/EditLead';
import BackgroundJob from 'components/app/portal/background-job/BackgroundJob';
import Starter from 'components/app/portal/Starter';
import Faq from 'components/app/portal/documentation/faq/Faq';
import ChangeLog from 'components/app/portal/documentation/change_log/ChangeLog';
import ChangeLogDetail from 'components/app/portal/documentation/change_log/ChangeLogDetail';
import ProfileUser from 'components/app/portal/user/profile/Profile';
import ApplicationSettings from 'components/app/portal/settings/ApplicationSettings';
import ContractDashboard from 'components/crm/reports/Contract';
import CRMDashboard from 'components/crm/dashboard/CrmDashboard';
import LeadDashboard from 'components/crm/dashboard/LeadDashboard';
import IntegrationApplication from 'components/integration/Applications';
import ApplicationDetail from 'components/integration/ApplicationDetail';
import NewApplication from 'components/integration/NewApplication';
import EditApplication from 'components/integration/EditApplication';
import DriveList from 'components/drive/DiveList';
import DriveDocViewer from 'components/drive/DriveDocViewer';
import DiveTrashList from 'components/drive/DiveTrashList';
import PIFVersionDetail from 'components/crm/pif-version/PIFVersionDetail';
import PIFVersionEdit from 'components/crm/pif-version/PIFVersionEdit';
import Feed from 'components/app/portal/social/feed/Feed';
import CRMContacts from 'components/crm/contact/Contacts';
import Customers from 'components/crm/customer/Customers';
import PositionGroups from 'components/organization/PositionGroups';
import CarTransactions from 'components/bookings/car/report/CarTransactions';
import ProtectedRoute from './ProtectedRoute';
import ProjectBomShop from '../components/crm/reports/ProjectBomShop';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy';
import TermsOfService from 'components/terms-of-service/TermsOfService';
import ProjectWarranty from '../components/crm/warranty/Warranties';

const PortalRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/403" element={<Error403 />} />
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
      </Route>

      {/*- ------------- Authentication ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route path="authentication/login" element={<SimpleLogin />} />
        <Route path="authentication/login-oversea" element={<SimpleLoginOversea />} />
        <Route path="authentication/logout" element={<SimpleLogout />} />
        <Route
          path="authentication/forgot-password"
          element={<SimpleForgetPassword />}
        />
        <Route
          path="authentication/reset-password"
          element={<SimplePasswordReset />}
        />
        <Route
          path="authentication/confirm-mail"
          element={<SimpleConfirmMail />}
        />
        <Route
          path="authentication/lock-screen"
          element={<SimpleLockScreen />}
        />
        <Route path="authentication/verify-email" element={<VerifyEmail />} />
        
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<PrivateRoute />}>
        <Route element={<MainLayout />}>
          {/*Home*/}
          <Route path="/" element={<Starter />} />

          {/*Dashboard*/}
          {/* <Route path="dashboard/home" element={<Dashboard />} />
          <Route path="dashboard/analytics" element={<Analytics />} />
          <Route path="dashboard/crm" element={<Crm />} /> */}

          <Route element={<ProtectedRoute permissionNo={'v501'} />}>
            <Route path="dashboard/crm" element={<CRMDashboard />} />
          </Route>

          {/* <Route path="dashboard/saas" element={<Saas />} />
          <Route path="dashboard/e-commerce" element={<Ecommerce />} />
          <Route path="dashboard/lms" element={<Lms />} />
          <Route
            path="dashboard/project-management"
            element={<ProjectManagement />}
          />
          <Route path="dashboard/support-desk" element={<SupportDesk />} /> */}

          {/* Booking */}
          <Route path="booking/meeting-room" element={<MeetingRoom />} />
          <Route path="booking/car/list" element={<Transactions />} />
          <Route
            path="booking/car/new-car-booking"
            element={<NewCarBooking />}
          />
          <Route path="booking/car/:id/edit" element={<EditCarBooking />} />
          <Route path="booking/car/:id" element={<TransactionDetail />} />
          <Route
            path="booking/car/:id/driver-trans/:driver_tran_id/confirm"
            element={<TransactionDriverConfirm />}
          />
          <Route
            path="booking/car/:id/driver-trans/:driver_tran_id/update"
            element={<TransactionDriverUpdateDeparture />}
          />
          <Route path="booking/car/calendar" element={<CarCalendar />} />
          <Route
            path="booking/car/reports/car-transactions"
            element={<CarTransactions />}
          />
          <Route path="booking/car/settings/cars" element={<Cars />} />
          <Route path="booking/car/settings/drivers" element={<Drivers />} />

          <Route
            path="booking/travel/new-travel-request"
            element={<NewTravelRequest />}
          />
          <Route path="booking/travel/list" element={<TravelBookings />} />
          <Route path="booking/travel/:id" element={<TravelBookingDetail />} />
          <Route
            path="booking/travel/:id/edit"
            element={<EditTravelBooking />}
          />
          <Route path="booking/travel/:id/print" element={<PrintBooking />} />
          <Route
            path="booking/travel/settings/expense-policy"
            element={<ExpensePolicy />}
          />
          <Route
            path="booking/travel/settings/approval-flows"
            element={<ApprovalFlows />}
          />

          {/*Travel original link*/}
          <Route path="travel/bookings/:id" element={<TravelBookingDetail />} />

          {/*Drive */}
          <Route path="drives/list" element={<DriveList />} />
          <Route path="drives/:id" element={<DriveList />} />
          <Route path="drives/:id/viewer" element={<DriveDocViewer />} />
          <Route path="drives/trash/list" element={<DiveTrashList />} />

          {/*Social Feed*/}
          <Route path="social/feeds" element={<Feed />} />

          {/*CRM*/}
          <Route element={<ProtectedRoute permissionNo={'v600'} />}>
            <Route path="crm/leads/list" element={<Leads />} />
            <Route path="crm/leads/:id" element={<LeadDetail />} />
            <Route path="crm/leads/:id/edit" element={<EditLead />} />
          </Route>
          <Route element={<ProtectedRoute permissionNo={'v601'} />}>
            <Route path="crm/leads/new" element={<NewLead />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v602'} />}>
            <Route path="crm/leads/dashboard" element={<LeadDashboard />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v701'} />}>
            <Route path="crm/contacts/list" element={<CRMContacts />} />
            <Route path="crm/contacts/new" element={<NewContact />} />
            <Route path="crm/contacts/:id" element={<ContactDetail />} />
            <Route path="crm/contacts/:id/edit" element={<EditContact />} />
          </Route>
          
          <Route element={<ProtectedRoute permissionNo={'v801'} />}>
            <Route path="crm/customers/list" element={<Customers />} />
            <Route path="crm/customers/new" element={<NewCustomer />} />
            <Route path="crm/customers/:id" element={<CustomerDetail />} />
            <Route path="crm/customers/:id/edit" element={<EditCustomer />} />
            <Route
              path="crm/customers/:customerId/contacts/:id/edit"
              element={<EditContact />}
            />
          </Route>
         
          <Route element={<ProtectedRoute permissionNo={'v900'} />}>
            <Route path="crm/projects/new-pcv" element={<NewPCV />} />
            <Route path="crm/projects/list" element={<Projects />} />
            <Route path="crm/projects/:id" element={<ProjectDetail />} />
            <Route path="crm/projects/:id/edit" element={<EditPCV />} />

            <Route
              path="crm/projects/:project_id/buildings/:building_id/adaptations/:adaptation_id/pifs/new-pif"
              element={<NewPIF />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/adaptations/:adaptation_id/pifs/:id/edit"
              element={<EditPIF />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/adaptations/:adaptation_id/pifs/:id"
              element={<PIFDetail />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/adaptations/:adaptation_id/pifs/:id/print"
              element={<PIFPrint />}
            />

            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/new"
              element={<NewChangeOrder />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/:id/edit"
              element={<EditChangeOrder />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/:id"
              element={<ChangeOrderDetail />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/:id/print"
              element={<ChangeOrderPrint />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/:change_order_id/pifs-versions/:id"
              element={<PIFVersionDetail />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/:change_order_id/pifs-versions/:id/edit"
              element={<PIFVersionEdit />}
            />
            <Route
              path="crm/projects/:project_id/buildings/:building_id/change-orders/:change_order_id/pifs-versions/:id/print"
              element={<PIFPrint />}
            />

            <Route
              path="crm/projects/reports/quotations"
              element={<Quotation />}
            />
            <Route path="crm/reports/iccc" element={<ICCC />} />
            <Route
              path="crm/projects/reports/project-master-schedule"
              element={<ProjectMasterSchedule />}
            />
            <Route
              path="crm/projects/warranties"
              element={<ProjectWarranty />}
            />
            {/* <Route
              path="crm/projects/reports/individual-productivity"
              element={<IndividualProductivity />}
            />
            <Route
              path="crm/projects/reports/team-productivity"
              element={<TeamProductivity />}
            /> */}
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v910'} />}>
            <Route path="crm/projects/waivers" element={<Waivers />} />
            <Route
              path="crm/projects/:project_id/waivers/new"
              element={<NewWaiver />}
            />
            <Route
              path="crm/projects/:project_id/waivers/:id/edit"
              element={<EditWaiver />}
            />
            <Route
              path="crm/projects/:project_id/waivers/:id"
              element={<WaiverDetail />}
            />
            <Route
              path="crm/projects/:project_id/waivers/:id/print"
              element={<WaiverPrint />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v911'} />}>
            <Route path="crm/contracts" element={<Contracts />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v912'} />}>
            <Route path="crm/sales-units/list" element={<SalesUnits />} />
            <Route path="crm/sales-units/new" element={<NewSalesUnit />} />
            <Route path="crm/sales-units/:id/edit" element={<EditSalesUnit />} />
            <Route path="crm/sales-units/:id" element={<SalesUnitDetail />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9131'} />}>
            <Route path="crm/reports/quotation-dashboard" element={<QuotationDashboard />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9132'} />}>
            <Route path="crm/reports/pifs" element={<PIF />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9134'} />}>
            <Route
              path="crm/reports/contract-dashboard"
              element={<ContractDashboard />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9135'} />}>
            <Route
              path="crm/reports/waiver-dashboard"
              element={<WaiverDashboard />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9136'} />}>
            <Route
              path="crm/reports/project-master-dashboard"
              element={<ProjectMasterDashboard />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9137'} />}>
            <Route path="crm/reports/change-order" element={<ChangeOrder />} />
          </Route>


          <Route element={<ProtectedRoute permissionNo={'v9138'} />}>
            <Route
              path="crm/reports/project-schedule"
              element={<ProjectSchedule />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9139'} />}>
            <Route
              path="crm/reports/estimate-assignment-schedule"
              element={<EstimateAssignmentSchedule />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9140'} />}>
            <Route
              path="crm/reports/engineering-job-progress"
              element={<EngineeringJobProgress />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9141'} />}>
            <Route
              path="crm/reports/project-bom-shop"
              element={<ProjectBomShop />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v9142'} />}>
            <Route path="crm/reports/job-entry" element={<JobEntry />} />
          </Route>
          
          <Route element={<ProtectedRoute permissionNo={'v912'} />}>
            <Route
              path="crm/settings/configuration"
              element={<Configuration />}
            />
          </Route>

          {/*Organization */}
          <Route element={<ProtectedRoute permissionNo={'v100'} />}>
            <Route path="organization/employees" element={<Employees />} />
            <Route path="organization/employees/new" element={<NewEmployee />} />
            <Route
              path="organization/employees/:id"
              element={<EmployeeDetail />}
            />
            <Route
              path="organization/employees/:id/edit"
              element={<EditEmployee />}
            />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v101'} />}>
            <Route path="organization/departments" element={<Departments />} />
          </Route>
          
          <Route element={<ProtectedRoute permissionNo={'v102'} />}>
            <Route path="organization/sites" element={<Sites />} />
          </Route>

          <Route element={<ProtectedRoute permissionNo={'v103'} />}>
            <Route
              path="organization/position-groups"
              element={<PositionGroups />}
            />
          </Route>
          
          {/*Authorization */}
          <Route element={<ProtectedRoute permissionNo={'v201'} />}>
            <Route path="authorization/users" element={<Users />} />
            <Route path="authorization/users/new" element={<NewUser />} />
            <Route path="authorization/users/:id" element={<UserDetail />} />
            <Route path="authorization/users/:id/edit" element={<EditUser />} />
          </Route>
          
          <Route element={<ProtectedRoute permissionNo={'v202'} />}>
            <Route path="authorization/roles" element={<Role />} />
            <Route path="authorization/roles/:id" element={<RoleDetail />} />
          </Route>
         
          <Route element={<ProtectedRoute permissionNo={'v203'} />}>
            <Route path="authorization/permissions" element={<Permission />} />
          </Route>

          <Route path="user/profile" element={<ProfileUser />} />
   

          {/*Background job */}
          <Route element={<ProtectedRoute permissionNo={'v301'} />}>
           <Route path="sidekiq" element={<BackgroundJob />} />
          </Route>

          {/*Integration */}
          <Route element={<ProtectedRoute permissionNo={'v302'} />}>
            <Route
              path="integration/applications/list"
              element={<IntegrationApplication />}
            />
            <Route
              path="integration/applications/new"
              element={<NewApplication />}
            />
            <Route
              path="integration/applications/:id"
              element={<ApplicationDetail />}
            />
            <Route
              path="integration/applications/:id/edit"
              element={<EditApplication />}
            />
          </Route>

          {/*Settings */}
          <Route element={<ProtectedRoute permissionNo={'v303'} />}>
            <Route path="settings" element={<ApplicationSettings />} />
          </Route>
          
          {/*Notifications list */}
          <Route path="notifications" element={<NotificationList />} />

          {/*App*/}
          {/* <Route path="app/calendar" element={<Calendar />} />
          <Route path="app/chat" element={<Chat />} />
          <Route path="app/kanban" element={<Kanban />} /> */}
          {/* <Route path="social/feed" element={<Feed />} /> */}
          {/* <Route path="social/activity-log" element={<ActivityLog />} />
          <Route path="social/notifications" element={<Notifications />} />
          <Route path="social/followers" element={<Followers />} />
          <Route path="events/event-detail" element={<EventDetail />} />
          <Route path="events/create-an-event" element={<CreateEvent />} />
          <Route path="events/event-list" element={<EventList />} /> */}

          {/* E Commerce */}
          {/* <Route
            path="e-commerce/orders/order-details"
            element={<OrderDetails />}
          />
          <Route path="e-commerce/orders/order-list" element={<Orders />} />
          <Route path="e-commerce/invoice" element={<Invoice />} />
          <Route path="e-commerce/billing" element={<Billing />} />
          <Route path="e-commerce/checkout" element={<Checkout />} />
          <Route path="e-commerce/shopping-cart" element={<ShoppingCart />} /> */}
          {/* <Route path="e-commerce/customers" element={<Customers />} /> */}
          {/* <Route
            path="e-commerce/customer-details"
            element={<CustomersDetails />}
          />

          <Route
            path="e-commerce/product/product-details"
            element={<ProductDetails />}
          />
          <Route
            path="e-commerce/product/product-details/:productId"
            element={<ProductDetails />}
          />

          <Route
            path="e-commerce/product/:productLayout"
            element={<Products />}
          /> */}

          {/* <Route path="e-commerce/invoice" element={<Invoice />} /> */}

          {/* E Learning */}
          {/* <Route path="e-learning/course/:courseLayout" element={<Courses />} />
          <Route
            path="e-learning/course/course-details"
            element={<CourseDetails />}
          />
          <Route
            path="e-learning/course/course-details/:courseId"
            element={<CourseDetails />}
          />
          <Route
            path="e-learning/course/create-a-course"
            element={<CreateCourse />}
          />
          <Route
            path="e-learning/trainer-profile"
            element={<TrainerProfile />}
          />
          <Route
            path="e-learning/student-overview"
            element={<StudentOverview />}
          /> */}

          {/*icons*/}
          {/* <Route path="icons/font-awesome" element={<FontAwesome />} />
          <Route path="icons/react-icons" element={<ReactIcons />} /> */}

          {/* maps */}
          {/* <Route path="maps/google-map" element={<GoogleMapExample />} />
          <Route path="maps/leaflet-map" element={<LeafletMapExample />} /> */}

          {/* Email */}
          {/* <Route path="email/email-detail" element={<EmailDetail />} />
          <Route path="email/inbox" element={<Inbox />} />
          <Route path="email/compose" element={<Compose />} /> */}

          {/* support desk */}
          {/* <Route path="/support-desk/table-view" element={<TableView />} />
          <Route path="/support-desk/card-view" element={<CardView />} />
          <Route path="/support-desk/contacts" element={<Contacts />} />
          <Route
            path="/support-desk/contact-details"
            element={<ContactDetails />}
          />
          <Route
            path="/support-desk/tickets-preview"
            element={<TicketsPreview />}
          />
          <Route path="/support-desk/quick-links" element={<QuickLinks />} />
          <Route path="/support-desk/reports" element={<Reports />} /> */}

          {/*Pages*/}

          {/* <Route path="user/profile" element={<Profile />} />
          <Route path="user/settings" element={<Settings />} /> */}
          {/* <Route path="miscellaneous/associations" element={<Associations />} /> */}
          {/* <Route path="faq/faq-alt" element={<FaqAlt />} />
          <Route path="faq/faq-basic" element={<FaqBasic />} />
          <Route path="faq/faq-accordion" element={<FaqAccordion />} /> */}
          {/* <Route path="pricing/pricing-default" element={<PricingDefault />} />
          <Route path="pricing/pricing-alt" element={<PricingAlt />} />
          <Route path="miscellaneous/invite-people" element={<InvitePeople />} /> */}
          {/* charts-example */}

          {/* <Route path="charts/chartjs" element={<Chartjs />} />
          <Route path="charts/d3js" element={<D3js />} />
          <Route path="charts/echarts/line-charts" element={<LineCharts />} />
          <Route path="charts/echarts/bar-charts" element={<BarCharts />} />
          <Route
            path="charts/echarts/candlestick-charts"
            element={<CandlestickCharts />}
          />
          <Route path="charts/echarts/geo-map" element={<GeoMaps />} />
          <Route
            path="charts/echarts/scatter-charts"
            element={<ScatterCharts />}
          />
          <Route path="charts/echarts/pie-charts" element={<PieCharts />} />
          <Route path="charts/echarts/radar-charts" element={<RadarCharts />} />
          <Route
            path="charts/echarts/heatmap-charts"
            element={<HeatmapCharts />}
          />
          <Route path="charts/echarts/how-to-use" element={<HowToUse />} /> */}

          {/*Components*/}
          {/* <Route path="components/alerts" element={<Alerts />} />
          <Route path="components/accordion" element={<Accordion />} />
          <Route path="components/animated-icons" element={<AnimatedIcons />} />
          <Route path="components/badges" element={<Badges />} />
          <Route path="components/breadcrumb" element={<Breadcrumbs />} />
          <Route path="components/buttons" element={<Buttons />} />
          <Route path="components/calendar" element={<CalendarExample />} />
          <Route path="components/cards" element={<Cards />} />
          <Route path="components/dropdowns" element={<Dropdowns />} />
          <Route path="components/list-group" element={<ListGroups />} />
          <Route path="components/modals" element={<Modals />} />
          <Route path="components/offcanvas" element={<Offcanvas />} />
          <Route path="components/pagination" element={<Pagination />} />
          <Route path="components/progress-bar" element={<BasicProgressBar />} />
          <Route path="components/placeholder" element={<Placeholder />} />
          <Route path="components/spinners" element={<Spinners />} />
          <Route path="components/toasts" element={<Toasts />} />
          <Route path="components/pictures/avatar" element={<Avatar />} />
          <Route path="components/pictures/images" element={<Image />} />
          <Route path="components/pictures/figures" element={<Figures />} />
          <Route path="components/pictures/hoverbox" element={<Hoverbox />} />
          <Route path="components/pictures/lightbox" element={<Lightbox />} />
          <Route path="components/tooltips" element={<Tooltips />} />
          <Route path="components/popovers" element={<Popovers />} />
          <Route path="components/draggable" element={<DraggableExample />} />
          <Route path="components/scrollspy" element={<Scrollspy />} />
          <Route path="components/timeline" element={<Timeline />} />
          <Route path="components/treeview" element={<TreeviewExample />} />
          <Route
            path="components/carousel/bootstrap"
            element={<BootstrapCarousel />}
          />
          <Route path="components/carousel/slick" element={<SlickCarousel />} />
          <Route path="components/navs-and-tabs/navs" element={<Navs />} />
          <Route path="tables/basic-tables" element={<Tables />} />
          <Route
            path="tables/advance-tables"
            element={<AdvanceTableExamples />}
          />
          <Route path="forms/basic/form-control" element={<FormControl />} />
          <Route path="forms/basic/input-group" element={<InputGroup />} />
          <Route path="forms/basic/select" element={<Select />} />
          <Route path="forms/basic/checks" element={<Checks />} />
          <Route path="forms/basic/range" element={<Range />} />
          <Route path="forms/basic/layout" element={<FormLayout />} />
          <Route path="forms/advance/date-picker" element={<DatePicker />} />
          <Route path="forms/advance/editor" element={<Editor />} />
          <Route
            path="forms/advance/emoji-button"
            element={<EmojiMartExample />}
          />
          <Route
            path="forms/advance/advance-select"
            element={<AdvanceSelect />}
          />
          <Route path="forms/advance/input-mask" element={<InputMaskExample />} />
          <Route path="forms/advance/range-slider" element={<RangeSlider />} />
          <Route path="forms/advance/file-uploader" element={<FileUploader />} />
          <Route path="forms/advance/rating" element={<Rating />} />
          <Route path="forms/floating-labels" element={<FloatingLabels />} />
          <Route path="forms/validation" element={<FormValidation />} />
          <Route path="forms/wizard" element={<WizardForms />} />
          <Route path="components/navs-and-tabs/navbar" element={<Navbars />} />
          <Route path="components/navs-and-tabs/tabs" element={<Tabs />} />
          <Route path="components/collapse" element={<Collapse />} />
          <Route
            path="components/cookie-notice"
            element={<CookieNoticeExample />}
          />
          <Route path="components/countup" element={<CountUp />} />
          <Route path="components/videos/embed" element={<Embed />} />
          <Route
            path="components/videos/react-player"
            element={<ReactPlayerExample />}
          />
          <Route path="components/background" element={<Background />} />
          <Route path="components/search" element={<Search />} />
          <Route path="components/typed-text" element={<TypedText />} />
          <Route
            path="components/navs-and-tabs/vertical-navbar"
            element={<VerticalNavbar />}
          />
          <Route
            path="components/navs-and-tabs/top-navbar"
            element={<NavBarTop />}
          />
          <Route
            path="components/navs-and-tabs/double-top-navbar"
            element={<NavbarDoubleTop />}
          />
          <Route
            path="components/navs-and-tabs/combo-navbar"
            element={<ComboNavbar />}
          /> */}

          {/*Utilities*/}
          {/* <Route path="utilities/borders" element={<Borders />} />
          <Route path="utilities/colors" element={<Colors />} />
          <Route path="utilities/colored-links" element={<ColoredLinks />} />
          <Route path="utilities/display" element={<Display />} />
          <Route path="utilities/visibility" element={<Visibility />} />
          <Route path="utilities/stretched-link" element={<StretchedLink />} />
          <Route path="utilities/stretched-link" element={<StretchedLink />} />
          <Route path="utilities/float" element={<Float />} />
          <Route path="utilities/position" element={<Position />} />
          <Route path="utilities/spacing" element={<Spacing />} />
          <Route path="utilities/sizing" element={<Sizing />} />
          <Route path="utilities/text-truncation" element={<TextTruncation />} />
          <Route path="utilities/typography" element={<Typography />} />
          <Route path="utilities/vertical-align" element={<VerticalAlign />} />
          <Route path="utilities/flex" element={<Flex />} />
          <Route path="utilities/grid" element={<Grid />} />
          <Route path="utilities/scroll-bar" element={<Scrollbar />} />

          <Route path="widgets" element={<Widgets />} /> */}

          {/*Documentation*/}
          <Route path="documentation/faqs" element={<Faq />} />
          <Route path="documentation/change-logs" element={<ChangeLog />} />
          <Route path="documentation/change-logs/:id" element={<ChangeLogDetail />} />
          <Route
            path="documentation/getting-start"
            element={<GettingStarted />}
          />
        </Route>
      </Route>

      {/* //--- MainLayout end  */}

      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default PortalRoutes;
