import React from 'react';
import PropTypes from 'prop-types';

const StatusBuildingFormatRenderer = (props) => {
  const value = props.value;
  const {status} = props.data;
  let textCss = "text-primary";

  switch(status?.id) {
    case 1:
    case 2:
    case 12:
      textCss = "text-danger"
      break
  }
  return <span className={textCss}>{value}</span>
};

StatusBuildingFormatRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  data: PropTypes.shape({
    status: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired
}

export default StatusBuildingFormatRenderer;