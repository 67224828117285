import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAssets, setCurrentPage, setPageSize, setCurrentParams, copyAsset, makePublicAsset, downloadAsset, getAncestors, resetAncestors } from "redux/drive/driveSlice"
import DriveHeader from "./DriveHeader";
import AgPagination from "components/ag-grid/AgPagination"
import {Card, InputGroup, Button, Form, Table, Breadcrumb } from "react-bootstrap"
import { FaHome, FaSearch} from "react-icons/fa"
import Flex from "components/common/Flex"
import DriveItem from "./DriveItem"
import DriveDeleteForeverConfirm from "./confirmation/DriveDeleteForeverConfirm";
import FolderForm from "./FolderForm";
import DriveMoveToTrashConfirm from "./confirmation/DriveMoveToTrashConfirm";
import DriveShareForm from "./DriveShareForm";
import DriveGetLinkConfirm from "./confirmation/DriveGetLinkConfirm";
import { useParams, useNavigate, Link} from "react-router-dom";
import DriveDetail from "./DriveDetail";
import "./table.scss";
import { debounce } from "helpers/debounce";

const DriveList = () => {
  const { 
    data,
    totalRows,
    paginate,
    currentParams,
    currentFolder,
    ancestorData
  } = useSelector((state) => state.drive)
  const { pageSize, totalPagesCount, fromRecord, toRecord } = paginate;
  
  const [asset, setAsset] = useState({});
  const [showDeleteForever, setShowDeleteForever] = useState(false);
  const [showRenameAsset, setShowRenameAsset] = useState(false);
  const [showMoveToTrash, setShowMoveToTrash] = useState(false);
  // const [showMoveToFolder, setShowMoveToFolder] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showGetLink, setShowGetLink] = useState(false);
  const [showDetailInformation, setShowDetailInformation] = useState(false);

  const driveUrl = "/drives";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const assetId = params?.id || null;

  useEffect(() => {
    let newCurrentParams = { ...currentParams }

    if (assetId) {
      newCurrentParams.asset_id = assetId;
      dispatch(getAncestors(assetId));
    } else {
      delete newCurrentParams.asset_id;
      dispatch(resetAncestors());
    }

    dispatch(setCurrentParams(newCurrentParams));
  }, [assetId]);

  useEffect(() => {
    if (currentParams) {
      dispatch(getAssets(currentParams))
    }
  }, [currentParams]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setCurrentPage(page))
    dispatch(setPageSize(pageSize))

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize}
    dispatch(setCurrentParams(newCurrentParams))
  })

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = { ...currentParams, name_cont: value };
      dispatch(setCurrentParams(newParams));
    }, 500),
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target
    handleChangeDebounced(value) //call the debound function
  }

  const handleAction = useCallback((item, actionType) => {
    console.log(`${actionType} action on item`, item.id);
    const assetId = item?.id;

    switch(actionType) {
      case "preview":
        if (item.preview_url) {
          //window.location.href = `https://docs.google.com/viewer?url=${item.preview_url}&embedded=true`;
          //window.location.href = "https://view.officeapps.live.com/op/view.aspx?src=${item.preview_url}";
          
          window.open(item?.preview_url, "_blank");
          //navigate(`/drives/${assetId}/viewer`, { state: {asset: item, url: item?.preview_url} } )
        }

        break;
      case "preview-google":
        if (item.preview_url) {
          window.open(`https://docs.google.com/viewer?url=${item.preview_url}&embedded=true`, '_blank');
        }

        break;
      case "getLink":
        setShowGetLink(true);
        break;
      case "share":
        setShowShare(true);
        break;
      case "makePublic":
        dispatch(makePublicAsset(assetId))
        break;
      case "download":
        {
        //download file
        const fileName = item?.name;
        dispatch(downloadAsset({assetId, fileName: item.asset_type === "folder" ? `${fileName}.zip` : fileName }))
        break;
        }
      case "rename":
        setShowRenameAsset(true);
        break;
      case "copy":
        dispatch(copyAsset(assetId));
        break;
      // case "move":
      //   setShowMoveToFolder(true);
      //   break;
      case "detail":
        setShowDetailInformation(true);
        break;
      case "moveToTrash":
        setShowMoveToTrash(true);
        break;
      case "deleteForever":
        setShowDeleteForever(true);
        break;
    }

    setAsset(item);

  }, [navigate]);

  return (
    <>
      <DriveHeader />
      <Card>  
        <Card.Header>
          <Flex justifyContent="between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={`${driveUrl}/list`}><FaHome /> My Drive</Link>
                </Breadcrumb.Item>

                {ancestorData && ancestorData.map(item => (
                  <Breadcrumb.Item key={item.id}>
                    <Link to={`${driveUrl}/${item?.id}`}>{item?.name}</Link>
                  </Breadcrumb.Item>
                ))}
                  {currentFolder && (
                    <Breadcrumb.Item>
                      {currentFolder}
                    </Breadcrumb.Item>
                  )}
              </Breadcrumb>
            </div>
            <div>
              <InputGroup>
                <Form.Control size="sm" placeholder="Search..." onChange={handleChange} />
                <Button size="sm" type="submit" className="me-1">
                  <FaSearch icon="search" className="me-1" />
                </Button>
              </InputGroup>
            </div>
          </Flex>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive scrollbar">
            <Table className="fs--1 mb-0">
              <thead className="bg-200 text-900 text-nowrap align-middle border-bottom">
                <th className="p-2 cursor-pointer">Name</th>
                <th className="p-2 cursor-pointer">Owner</th>
                <th className="p-2 cursor-pointer">Last modified</th>
                <th className="p-2 cursor-pointer">File size</th>
                <th className="p-2"></th>
              </thead>
              <tbody className="fs--1">
                {data && data.length > 0 ? (
                  data.map(item => 
                    <DriveItem
                      key={item.id}
                      item={item}
                      handleAction={handleAction}
                  />)
                ) : (
                  <div className="py-4">No data found.</div>
                ) }
              </tbody>
            </Table>
          </div>
        </Card.Body>
        <Card.Footer>
          <AgPagination
            totalRows={totalRows}
            totalPagesCount={totalPagesCount}
            pageChange={handlePageChange}
            pageSize={pageSize}
            from={fromRecord}
            to={toRecord}
            itemsPages={["50", "100", "200", "500"]}
          />
        </Card.Footer>
      </Card>

      {showDeleteForever && (
        <DriveDeleteForeverConfirm
          showModal={showDeleteForever}
          setshowModal={setShowDeleteForever}
          asset={asset}
        />
      )}

      {showRenameAsset && (
        <FolderForm
          showModal={showRenameAsset}
          setshowModal={setShowRenameAsset}
          asset={asset}
          title={`Rename ${asset?.asset_type}`}
        />
      )}

      {showMoveToTrash && (
        <DriveMoveToTrashConfirm
          showModal={showMoveToTrash}
          setshowModal={setShowMoveToTrash}
          asset={asset}
        />
      )}

      {/* {showMoveToFolder && (
        <DriveMoveToFolderConfirm
          showModal={showMoveToFolder}
          setshowModal={setShowMoveToFolder}
          asset={asset}
        />
      )} */}

      {showShare && (
        <DriveShareForm
          showModal={showShare}
          setshowModal={setShowShare}
          asset={asset}
        />
      )}

      {showGetLink && (
        <DriveGetLinkConfirm
          showModal={showGetLink}
          setshowModal={setShowGetLink}
          asset={asset}
        />
      )}

      {showDetailInformation && (
        <DriveDetail
          showModal={showDetailInformation}
          setshowModal={setShowDetailInformation}
          asset={asset}
        />
      )}
    </>
  )
}

export default DriveList