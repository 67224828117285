import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { createUser, updateUser } from 'redux/authorization/userSlice';
import Select from 'react-select'
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { debounce, filterOptions } from 'helpers/debounce';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSalesUnits } from 'redux/crm/salesUnitSlice';
import AsyncSelect from 'react-select/async';
import IconButton from 'components/common/IconButton';
import { useNavigate } from 'react-router-dom';
import { FaBan, FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';

const UserForm = ({title, user}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues } = useForm();
  const {activeSalesUnitsData: salesUnits} = useSelector((state) => state.crm_sales_unit);
  const [salesUnitOptions, setSalesUnitOptions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userUrl = '/authorization/users';

  useEffect(() => {
    //if (salesUnits.length === 0) {
      dispatch(getActiveSalesUnits());
    //}
  }, []);

  useEffect(() => {
    setValue('time_zone', getTimeZone());

    if(user) {
      Object.entries(user).forEach(([key, value]) => {
        if (key === "site_id")
        {
          loadSalesUnitBySite(value); //Head Office
        }
        setValue(key, value)
      })
    }
  }, [setValue]);

  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  const debouncedFilterSites = debounce((inputValue, callback) => {
    filterOptions('/organization/sites/active_list', inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const siteOptions = (inputValue, callback) => {
    debouncedFilterSites(inputValue, callback);
  };

  // const defaultSalesUnitOption = useMemo(() => (
  //   user ? { value: user.sales_unit_id, label: user.sales_unit?.name } : (salesUnitOptions?.length > 0 ? salesUnitOptions[0] : undefined)
  // ), [user, salesUnitOptions]);

  const defaultSiteOption = useMemo(() => (
    user ? { value: user.site_id, label: user.site?.name } : ''
  ), [user])

  // const timeZoneOptions = useMemo(() => moment.tz.names().map((option) => ({ value: option, label: option })), [])
  // const defaultTimeZoneOption = useMemo(() => (
  //   user ? { value: user.time_zone, label: user.time_zone } : timeZoneOptions.filter(item => item === getTimeZone())
  // ), [])

  const onSubmit = async (data) => {
    //data = {...data, confirmed_at: data.confirm ? null : new Date()};
    console.log(data)

    try {
      let userId = user ? user.id : null

      if (!user) {
        const response = await dispatch(createUser(data))
        userId = response.payload.data.id 
      }
      else {
        await dispatch(updateUser({userId, data}))
      }

      if (userId) {
        navigate(`${userUrl}/${userId}`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const validatePasswordConfirmation = (value) => {
    const { password } = getValues()
    return password === value || 'Password confirmation does not match.'
  }

  const handleBack = () => {
    navigate(-1)
  }

  const loadSalesUnitBySite = (site_id) => {
    const options = salesUnits && salesUnits
      .filter(item => item.site_id === site_id)
      .map((option) => ({ value: option.id, label: `${option.name} - ${option.description}` }));
    
      setSalesUnitOptions(options);
  }

  const handleSiteChange = (selectedOption) => {
    //reset current sales_unit selected
    setValue("sales_unit_id", null);

    const value = selectedOption?.value;
    loadSalesUnitBySite(value);
    setValue('site_id', value);
  };

  return (
    <Card className='fs--1'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Header className='d-flex flex-between-center border-bottom'>
          <Card.Title>{title}</Card.Title>
          <IconButton
            onClick={handleBack}
            variant='falcon-default'
            size='sm'
            icon='arrow-left'
          />
        </Card.Header>
        <Card.Body className='mt-3'>
          <Form.Group as={Row} className='mb-2' controlId='name'>
            <Form.Label column sm={3} className='text-lg-end'>
              Code
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type='text'
                name='name'
                placeholder='Type 3 characters code of name'
                className='fs--1'
                {...register('name', {
                  required: 'Code must be required.'
                })}
              />
              <span className='text-danger'>
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-2' controlId='full_name'>
            <Form.Label column sm={3} className='text-lg-end'>
              Full of name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type='text'
                name='full_name'
                placeholder='Full of name'
                className='fs--1'
                {...register('full_name', {
                  required: 'Full of name must be required.'
                })}
              />
              <span className='text-danger'>
                {errors.full_name && errors.full_name.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-2' controlId='email'>
            <Form.Label column sm={3} className='text-lg-end'>
              Email
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type='email'
                name='email'
                placeholder='example@pebsteel.com.vn'
                autoComplete='username'
                className='fs--1'
                {...register('email', {
                  required: 'Email must be required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address'
                  }
                })}
              />
              <span className='text-danger'>
                {errors.email && errors.email.message}
              </span>
            </Col>
          </Form.Group>
          
          {!user && (
            <>
              <Form.Group as={Row} className='mb-2 mt-4' controlId='password'>
                <Form.Label column sm={3} className='text-lg-end'>
                  Password
                </Form.Label>
                <Col sm={9} md={7}>
                  <Form.Control
                    type='password'
                    name='password'
                    placeholder='Password'
                    autoComplete='new-password'
                    className='fs--1'
                    {...register('password', {
                      required: 'Password must be required',
                      minLength: {
                        value: 6,
                        message: 'Password must have at least 6 characters'
                      }
                    })}
                  />
                  <span className='text-danger'>
                    {errors.password && errors.password.message}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-2' controlId='password_confirmation'>
                <Form.Label column sm={3} className='text-lg-end'>
                  Password confirmation
                </Form.Label>
                <Col sm={9} md={7}>
                  <Form.Control
                    type='password'
                    name='password_confirmation'
                    placeholder='Password confirmation'
                    autoComplete='new-password'
                    className='fs--1'
                    {...register('password_confirmation', {
                      required: 'Password confirmation must be required',
                      validate: validatePasswordConfirmation
                    })}
                  />
                  <span className='text-danger'>
                    {errors.password_confirmation && errors.password_confirmation.message}
                  </span>
                </Col>
              </Form.Group>
            </>
          )}
          
          <Form.Group as={Row} className='mb-1 mt-4' controlId='active'>
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name='active'
                defaultValue={true}
                render={({ field }) => (
                  <Form.Check
                    type='switch'
                    label='Active'
                    checked={field.value}
                    onChange={(e) => setValue('active', e.target.checked)}
                  />
                )}
              />
            </Col>
          </Form.Group>

          {/* <Form.Group as={Row} className='mb-1' controlId='confirm'>
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name='confirm'
                render={({ field }) => (
                  <Form.Check
                    type='switch'
                    label='Confirm email'
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              <div className="ms-3 form-check p-0 fs--1 text-primary">Just applicable for normal sign-in. The user must confirm before signing in to the system.</div>
            </Col>
          </Form.Group> */}

          <Form.Group as={Row} className='mb-1' controlId='all_sales_units'>
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name='all_sales_units'
                render={({ field }) => (
                  <Form.Check
                    type='switch'
                    label='View all sales unit on dashboard'
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-2 mt-4' controlId='site_id'>
            <Form.Label column sm={3} className='text-lg-end'>
              Site
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name='site_id'
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={defaultSiteOption}
                    loadOptions={siteOptions}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix='react-select'
                    className='w-100'
                    onChange={handleSiteChange}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Site must be required'
                  }
                }}
              />
              <span className='text-danger'>
                {errors.site_id && errors.site_id.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='sales_unit_id'>
            <Form.Label column sm={3} className='text-lg-end'>
              Sales unit
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name='sales_unit_id'
                render={({field}) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    options={salesUnitOptions}
                    value={salesUnitOptions.find(option => option.value === field.value) || null}
                    //defaultValue={defaultSalesUnitOption}
                    placeholder='Select...'
                    classNamePrefix='react-select'
                    isClearable
                    onChange={(selectedOption) => setValue('sales_unit_id', selectedOption ? selectedOption.value : null)}
                  />
                )}
              />
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className='mb-2' controlId='time_zone'>
            <Form.Label column sm={3} className='text-lg-end'>
              Time zone
            </Form.Label>
            <Col sm={9} md={7}>
              {/* <Controller
                control={control}
                name='time_zone'
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={timeZoneOptions}
                    defaultValue={defaultTimeZoneOption}
                    placeholder='Select...'
                    classNamePrefix='react-select'
                    onChange={selectedOption => setValue('time_zone', selectedOption.value)}
                  />
                )}
              /> */}

              <Form.Control
                type='text'
                name='time_zone'
                placeholder='Time zone'
                className='fs--1'
                disabled={true}
                {...register('time_zone', {
                  required: 'Time zone must be required.'
                })}
              />
              <span className='text-danger'>
                {errors.time_zone && errors.time_zone.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col className='my-2'>
              <Flex justifyContent='center'>
                <Button size='sm' type='submit'><FaSave /> Save</Button>
                <Button variant='danger' size='sm' className='ms-2' onClick={handleBack}><FaBan /> Cancel</Button>
              </Flex>
            </Col>
          </Form.Group>
        </Card.Body>
      </Form>
    </Card>
  )
};

UserForm.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
}

export default UserForm;