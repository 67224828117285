import { DropdownButton, ButtonGroup, Dropdown, Row, Col } from "react-bootstrap";
import ChangeOrderUploadDocument from "../ChangeOrderUploadDocument";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { formatDate, formatNumber } from "helpers/utils";
import Flex from "components/common/Flex";
import React, { lazy, useState, Suspense } from "react";
import { FaCalendar, FaCalendarCheck, FaShareSquare, FaUser, FaVoteYea } from "react-icons/fa";
import PropTypes from 'prop-types';

const LazyABErectionRequestToENG = lazy(() => import("./ab_erection/ABErectionRequestToENG"));
const LazyABErectionScheduleToCSD = lazy(() => import("./ab_erection/ABErectionScheduleToCSD"));
const LazyABErectionReleaseToCSD = lazy(() => import("./ab_erection/ABErectionReleaseToCSD"));

const ABErection = ({
  changeOrder,
  handleUploadDocument,
  removeDocument
}) => {
  const [showRequestABErection, setShowRequestABErection] = useState(false);
  const [showScheduleABErection, setShowScheduleABErection] = useState(false);
  const [showReleaseABErection, setShowReleaseABErection] = useState(false);

  const {
    allow_request_ab_erection,
    allow_schedule_ab_erection,
    allow_release_ab_erection,
    allow_view_ab_erection_document,
    allow_upload_ab_erection_document,
    allow_remove_ab_erection_document,
    allow_download_ab_erection_document
  } = changeOrder?.permissions || {}

  const {
    requested_date,
    requested_by,
    scheduled_date,
    scheduled_by,
    released_date,
    released_by,
    released_weight
  } = changeOrder?.ab_erection || {}

  const TYPE_AB_ERECTION = "ab-erection"

  const handleABErectionRequestToENG = () => {
    setShowRequestABErection(true)
  }

  const handleABErectionScheduleToCSD = () => {
    setShowScheduleABErection(true)
  }

  const handleABErectionReleaseToCSD = () => {
    setShowReleaseABErection(true)
  }


  return (
    <>
      <Flex justifyContent="end" className="mb-2">
        <DropdownButton variant="falcon-default" as={ButtonGroup} title="Action" id="bg-nested-dropdown" size="sm">
          <div className="py-1">
            {allow_request_ab_erection && (
              <Dropdown.Item className="mb-1" onClick={handleABErectionRequestToENG}>
                <FaShareSquare /> Request to ENG
              </Dropdown.Item>
            )}
            
            {allow_schedule_ab_erection && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-2" onClick={handleABErectionScheduleToCSD}>
                  <FaCalendarCheck /> Schedule to CSD
                </Dropdown.Item>
              </>
            )}
            
            {allow_release_ab_erection && (
              <Dropdown.Item className="mb-1 text-primary" onClick={handleABErectionReleaseToCSD}>
                <FaVoteYea /> Release to CSD
              </Dropdown.Item>
            )}
            
          </div>
        </DropdownButton>
      </Flex>
      <Row>
        <Col>
          <h6>A/B for Erection Drawings</h6>
          <div className="mx-2">
            <Row className="mb-2 gx-2">
              <Col sm={3}><FaCalendar className="text-primary" /> Requested date</Col>
              <Col className="text-primary">{formatDate(requested_date)}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}><FaUser className="text-primary" /> Requested by</Col>
              <Col className="text-primary">{requested_by}</Col>
            </Row>

            <Row className="mb-2 gx-2 mt-4">
              <Col sm={3}><FaCalendar className="text-primary" /> Scheduled date</Col>
              <Col className="text-primary">{formatDate(scheduled_date)}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}><FaUser className="text-primary" /> Scheduled by</Col>
              <Col className="text-primary">{scheduled_by}</Col>
            </Row>

            <Row className="mb-2 mt-4 gx-2">
              <Col sm={3}><FaCalendar className="text-primary" /> Released date</Col>
              <Col className="text-primary">{formatDate(released_date)}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}><FaUser className="text-primary" /> Released by</Col>
              <Col className="text-primary">{released_by}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}>Weight (M.T)</Col>
              <Col className="text-primary">{formatNumber(released_weight)} M.T</Col>
            </Row>

            <Row className="mb-2 gx-2 mt-4">
              <Col sm={3}>Note</Col>
              <Col className="text-primary"></Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            {allow_upload_ab_erection_document && (
              <ChangeOrderUploadDocument uploadDocument={(data) => handleUploadDocument(data, TYPE_AB_ERECTION)}/>
            )}
          </div>
          <div>
            <h6 className="mt-3">Attachments:</h6>
            {changeOrder?.ab_erection_documents && changeOrder?.ab_erection_documents.map(attachment => (
              <DocumentAttachment
                {...attachment}
                key={attachment.id}
                isTable
                allowView={allow_view_ab_erection_document}
                allowRemove={allow_remove_ab_erection_document}
                allowDownload={allow_download_ab_erection_document}
                handleRemoveAttachment={(documentId) => removeDocument(documentId, TYPE_AB_ERECTION)}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Suspense fallback={<div>Loading....</div>}>
        {showRequestABErection && (
          <LazyABErectionRequestToENG
            showModal={showRequestABErection}
            setshowModal={setShowRequestABErection}
            changeOrder={changeOrder}
          />
        )}

        {showScheduleABErection && (
          <LazyABErectionScheduleToCSD
            showModal={showScheduleABErection}
            setshowModal={setShowScheduleABErection}
            changeOrder={changeOrder}
          />
        )}

        {showReleaseABErection && (
          <LazyABErectionReleaseToCSD
            showModal={showReleaseABErection}
            setshowModal={setShowReleaseABErection}
            changeOrder={changeOrder}
          />
        )}
      </Suspense>
    </>
  )
};

ABErection.propTypes = {
  changeOrder: PropTypes.object.isRequired,
  handleUploadDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired
}

export default ABErection;