import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import PropTypes from'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const formatNumber = (params) => {
  const value = params?.value|| 0;
  return value?.toLocaleString();
}

formatNumber.propTypes = {
  params: PropTypes.object.isRequired
};

const getOption = (data, monthName, color) => ({
  color: [
    getColor(color)
  ],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      magicType: { show: true, type: ['line', 'bar'] },
      saveAsImage: { show: true }
    }
  },
  xAxis: {
    type: 'category',
    axisPointer: {
      type: 'shadow'
    },
    axisLabel: {
      color: getColor('gray-400'),
      rotate: 30,
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'line'
      }
    },
    data: monthName,
  },
  yAxis: 
  {
    type: 'value'
  },
  series: [
    {
      type: 'bar',
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: "top",
        color: getColor("gray-500"),
        formatter: (params) => formatNumber(params)
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 14,
          fontWeight: 'bold',
          color: getColor("primary")
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
});

getOption.propTypes = {
  data: PropTypes.array.isRequired,
  monthName: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired
};


const LeadEntryChart = ({data, color="primary"}) => {

  const newData = Object.entries(data).map(([name, values]) => ({
    name,
    value: values.number_of_projects
  }));

  const monthName = newData && newData.map(item => item.name)

  return (
    <div className="h-100 border rounded">
      <div className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(newData, monthName, color)}
          className="w-100"
        />
      </div>
    </div>
  )
}

LeadEntryChart.propTypes = {
  data: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired
};

export default LeadEntryChart;