import React, { useState, lazy, Suspense } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmployeeHeader from "./employee/EmployeeHeader";
import EmployeeOrganization from './employee/EmployeeOrganization';
import { useSelector } from 'react-redux';

const AllList = lazy(() => import('./employee/list/All'));

const Employees = () => {
  const { loading } = useSelector((state) => state.organization_employee)
  const [selected, setSelected] = useState([]);

  console.log(selected);

  return (
    <>
      <EmployeeHeader selected={selected} />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="employeelist">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="employeelist"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="organization"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="ticket-alt"
                          className="text-600"
                        />
                        <h6 className="text-600 mb-0 ms-1">Organization</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="employeelist">
                      <AllList setSelected={setSelected} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="organization">
                      <EmployeeOrganization />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Employees
