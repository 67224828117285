import { Card, Col, Collapse, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactTable from "./contact/ContactTable";
import { useSelector } from "react-redux";
import { setCustomerContacts } from "redux/crm/contactSlice";
import ContactForm from "components/crm/contact/ContactForm";

const CustomerDetailContact = ({customer, allow_create}) => {
  const [collapsed, setCollapsed] = useState(true);
  const {customerContacts: contacts} = useSelector((state) => state.crm_contact);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customer) {
      dispatch(setCustomerContacts(customer.contacts))
    }
  }, [customer]);

  return (
    <>
     <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Contacts</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          {allow_create && (
            <Flex
              alignItems="center"
              className="mb-3 text-primary fs-0 cursor-pointer"
              onClick={() => setCollapsed(!collapsed)}
            >
              <span className="circle-dashed">
                <FontAwesomeIcon icon="plus" />
              </span>
              <span className="ms-3">Add new contact</span>
            </Flex>
          )}
          
          <Collapse in={!collapsed}>
            <div className="fs--1">
              <ContactForm
                customer={customer}
                handleCancel={() => setCollapsed(true)}
              />
              <div className="border-dashed border-bottom my-3" />
            </div>
          </Collapse>

          <ContactTable customer={customer} contacts={contacts} />
        </Card.Body>
      </Card>
    </>
  )
};

CustomerDetailContact.propTypes = {
  customer: PropTypes.object.isRequired,
  allow_create: PropTypes.bool.isRequired
}

export default CustomerDetailContact;