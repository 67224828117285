import ContractCountry from "components/crm/reports/contract/ContractCountry";
import ContractRatioOfSales from "components/crm/reports/contract/ContractRatioOfSales";
import ContractTopRevenueProject from "components/crm/reports/contract/ContractTopRevenueProject";
import JobEnteredTracking from "components/crm/reports/job_entry/JobEnteredTracking";
import JobEnteredSummary from "components/crm/reports/job_entry/JobEnteredSummary";
import JobEntryTypeOfSales from "components/crm/reports/job_entry/JobEntryTypeOfSales";
import React from 'react';
import PropTypes from 'prop-types';

const Overview = ({overviewData={}}) => {
  const {enter_job, contract} = overviewData;
  const {current_period, previous_period, summary, monthly, sales_regions, data} = enter_job || {};

  const became_job = summary?.general?.length ? summary.general[0]['Signed contract (J)'] : undefined;


  return (
    <>
      <JobEnteredSummary jobEnteredData={{current_period, previous_period, became_job, data}} />
      <JobEntryTypeOfSales data={summary} />
      <JobEnteredTracking originalData={{monthly, sales_regions, data}} />

      <ContractRatioOfSales data={{sales_regions: contract?.sales_regions, type_of_sales: contract?.type_of_sales}} />
      <ContractTopRevenueProject data={contract?.data} />
      <ContractCountry data={contract?.countries} />
    </>
  );
};

Overview.propTypes = {
  overviewData: PropTypes.object.isRequired
}

export default Overview;