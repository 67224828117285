import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

const applicationUrl = "/integration/applications";

export const getApplications = createAsyncThunk(
  "Application/getApplications",
  async (params, {rejectWithValue}) => {
    try {
      const response = await apiAxios.get(`${applicationUrl}`, { params } )
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getApplication = createAsyncThunk(
  "Application/getApplication",
  async (applicationId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${applicationUrl}/${applicationId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createApplication = createAsyncThunk(
  "Application/createApplication",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(applicationUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateApplication = createAsyncThunk(
  "Application/updateApplication",
  async ({ applicationId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${applicationUrl}/${applicationId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteApplication = createAsyncThunk(
  "Application/deleteApplication",
  async (applicationId) => {
    try {
      const response = await apiAxios.delete(`${applicationUrl}/${applicationId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)


const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload || {};

  if (error) {
    toast.error(error);
  } else {
    const record = data && data.attributes ? data.attributes : null;
    
    if (record !== null) {
      state.applicationData = record

      switch (type) {
        case "create": 
          state.data.unshift(record)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== record.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex((item) => item.id === record.id)
          if (updatedIndex !== -1) {
            state.data[updatedIndex] = record
          }

          break;
        }
      }
    }
    toast.success(message);
  }
};

const applicationSlice = createSlice({
  name: "integration_application",
  initialState: {
    data: [],
    totalRows: 0,
    loading: false,
    exporting: false,
    applicationData: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.pending, (state) => {
        state.loading = true
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data      = objectSerializer(results.rows)
        state.totalRows = results.total_rows
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createApplication.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateApplication.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteApplication.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          const error = action.payload?.message || action.payload?.error || action.error.message;
          if (error.includes('Unauthorized')) {
            toast.error('Unauthorized access. Please contact IT to support.');
          } else {
            toast.error(error);
          }
        }
      );
  }
});

//export const {} = applicationSlice.actions;

export default applicationSlice.reducer;