import { Button, Form, Modal, Row, Col } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import React, { useEffect } from "react"
import DatePicker from "react-datepicker";
import Select from "react-select"
import { setNestedParams, resetNestedParams, setFilterTag } from "redux/crm/project/warrantySlice"
import { useSelector } from "react-redux"
import IconAlert from "components/common/IconAlert"
import { getActiveSalesUnits } from "redux/crm/salesUnitSlice"
import { formatDate } from "helpers/utils"
import useDateRange from "hooks/useDateRange"
import { MdSearch, MdOutlineRestartAlt, MdOutlineClose, MdFilter } from "react-icons/md"

const FilterAdvanceModal = ({showModal, setshowModal, data, recordKey}) => {
  const {activeSalesUnitsData} = useSelector((state) => state.crm_sales_unit);
 
  const { control, register, handleSubmit, setValue, reset } = useForm();
  
  const {pageSize} = data || {};
  const key = recordKey;
  const dispatch = useDispatch();

  const { startDate: createdFromDate, endDate: createdEndDate, onChangeDateRange: onChangeCreatedDate } = useDateRange(null, null)

  useEffect(() => {
    if (!activeSalesUnitsData) {
      dispatch(getActiveSalesUnits())
    }
  }, []);

  const salesUnitOptions = activeSalesUnitsData && activeSalesUnitsData.map((item) => ({ value: item.id, label: item.description }))

  const onSubmit = (data) => {
    const newData = {
      id_eq: data.id,
      contract_addendum_number_cont: data.contract_addendum_number,
      project_j_number_cont: data.j_number,
      project_name_cont: data.project_name,
      cse_incharge_cont: data.cse_incharge,
      user_name_cont: data.created_by,
      created_at_gteq: createdFromDate,
      created_at_lteq: createdEndDate,
      sales_unit_id_in: data.sales_unit_ids,
      page: 1,
      per_page: pageSize
    }

    //store for filter
    const dataFilter = [
      { id: "id_eq", name: "ID#", value: data.id },
      { id: "contract_addendum_number_cont", name: "Contract add# number", value: data.contract_addendum_number },
      { id: "project_j_number_cont", name: "Job number", value: data.j_number },
      { id: "project_name_cont", name: "Project name", value: data.project_name },
      { id: "cse_incharge_cont", name: "CSE incharge", value: data.cse_incharge },
      { id: "user_name_cont", name: "Created by", value: data.created_by }
    ];

    if (createdFromDate && createdEndDate) {
      dataFilter.push({ id: "created_at_gteq", name: "Created date", value: [formatDate(createdFromDate), formatDate(createdEndDate)] })
    }

    if (data.sales_unit_ids) {
      dataFilter.push({ id: "sales_unit_id_in", name: "Sales unit", value: data.sales_unit_ids })
    }

    dispatch(setFilterTag({key, tags: dataFilter.filter((item) => item.value !== undefined && item.value !== '')}))

    let obj = {}
    Object.entries(newData).forEach((item) => {
      const value = item[1]
      const key = item[0]

      if (value !== null && value !== "" && value !== undefined) {
        obj = {...obj, [key]: value}
      }
    });

    try {
      dispatch(setNestedParams({key, params: obj}));
      closeModal();
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleReset = () => {
    resetForm()
    //closeModal()
  }

  const resetForm = () => {
    reset();

    onChangeCreatedDate([null, null]);
    setValue('sales_unit_ids', null)
    dispatch(resetNestedParams({key}))
  }

  const handleChangeSalesUnit = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('sales_unit_ids', selectedOptionsValues, { shouldValidate: true })
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><MdFilter /> Filters for advances</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="info">
              <p className="mb-0">Establish the waiver's filtering criteria</p>
            </IconAlert>

            <Form.Group as={Row} className="mb-2" controlId="id">
              <Form.Label column sm={3} className="text-lg-end">
                ID#
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="number"
                  rows={6}
                  name="id"
                  placeholder="ID#"
                  className="fs--1"
                  {...register("id")}
                />
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} className="mb-2" controlId="j_number">
              <Form.Label column sm={3} className="text-lg-end">
                P/J number.
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  rows={6}
                  name="j_number"
                  placeholder="P/J number."
                  className="fs--1"
                  {...register("j_number")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="project_name">
              <Form.Label column sm={3} className="text-lg-end">
                Project name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="project_name"
                  placeholder="Project name"
                  className="fs--1"
                  {...register("project_name")}
                />
              </Col>
            </Form.Group>

            <div className="border-dashed border-bottom my-4"></div>

            <Form.Group as={Row} className="mb-2" controlId="cse_incharge">
              <Form.Label column sm={3} className="text-lg-end">
                CSE incharge
              </Form.Label>
              <Col sm={9} md={7}>
                  <Form.Control
                    type="text"
                    rows={6}
                    name="cse_incharge"
                    placeholder="CSE incharge"
                    className="fs--1"
                    {...register("cse_incharge")}
                  />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="sales_unit_ids">
              <Form.Label column sm={3} className="text-lg-end">
                Sales unit
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="sales_unit_ids"
                  render={({field}) => (
                    <Select
                      closeMenuOnSelect={true}
                      options={salesUnitOptions}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      {...field}
                      isMulti
                      value={field.value ? salesUnitOptions.filter((item) => field.value.includes(item.value)) : []}
                      onChange={handleChangeSalesUnit}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <div className="border-dashed border-bottom my-4"></div>
            
            <Form.Group as={Row} className="mb-2" controlId="created_by">
              <Form.Label column sm={3} className="text-lg-end">
                Created by
              </Form.Label>
              <Col sm={9} md={7}>
                <Row className="g-2">
                  <Col sm={5}>
                    <Form.Control
                      type="text"
                      rows={6}
                      name="created_by"
                      placeholder="Created by"
                      className="fs--1"
                      {...register("created_by")}
                    />
                  </Col>
                  <Col>
                    <Controller
                      control={control}
                      name="created_at"
                      render={() => (
                        <DatePicker
                          onChange={onChangeCreatedDate}
                          startDate={createdFromDate}
                          endDate={createdEndDate}
                          selectsRange
                          dateFormat="dd MMM yyyy"
                          placeholderText="Created date"
                          className='form-control fs--1'
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><MdSearch /> Search</Button>
            <Button size="sm" variant="falcon-default" onClick={handleReset}><MdOutlineRestartAlt /> Reset</Button>
            <Button size="sm" variant="falcon-default" onClick={closeModal}><MdOutlineClose /> Close</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default FilterAdvanceModal;