import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const changeLogUrl = "/documentation/changelogs"

export const getChangeLogs = createAsyncThunk(
  "ChangeLog/getChangeLogs",
  async (params) => {
    try {
      const response = await apiAxios.get(changeLogUrl, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getChangeLog = createAsyncThunk(
  "ChangeLog/getChangeLog",
  async (changeLogId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${changeLogUrl}/${changeLogId}`)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createChangeLog = createAsyncThunk(
  "ChangeLog/createChangeLog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(changeLogUrl, data)
      const {error } = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateChangeLog = createAsyncThunk(
  "ChangeLog/updateChangeLog",
  async ({ changeLogId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${changeLogUrl}/${changeLogId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteChangeLog = createAsyncThunk(
  "ChangeLog/deleteWaiver",
  async (changeLogId) => {
    try {
      const response = await apiAxios.delete(`${changeLogUrl}/${changeLogId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseChangeLog = createAsyncThunk(
  "ChangeLog/releaseChangeLog",
  async ({changeLogId, data}) => {
    try {
      const response = await apiAxios.post(`${changeLogUrl}/${changeLogId}/release`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  if (!action.payload) {
    //toast.error("Invalid response received");
    return;
  }

  const { error, data, message } = action.payload;

  // Handle error case
  if (error) {
    toast.error(error);
    return;
  }

  // Handle empty data case
  if (!data || !data.attributes) {
    toast.error("No data received");
    return;
  }

  const log = data.attributes;
  state.changeLogData = log;

  switch (type) {
    case "create":
      state.data.unshift(log);
      break;
    case "delete":
      state.data = state.data.filter((item) => item.id !== log.id);
      break;
    default: {
      const updatedIndex = state.data.findIndex((item) => item.id === log.id);
      if (updatedIndex !== -1) {
        state.data[updatedIndex] = log;
      }
      break;
    }
  }

  if (message) {
    toast.success(message);
  }
};

const changeLogSlice = createSlice({
  name: "documentation_change_log",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 10,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 10},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    changeLogData: {}
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 10}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChangeLogs.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate = state.paginate
        const calRecords      = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(getChangeLog.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createChangeLog.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteChangeLog.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(updateChangeLog.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(releaseChangeLog.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams
} = changeLogSlice.actions;

export default changeLogSlice.reducer;