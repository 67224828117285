import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import ChangeOrderUploadDocument from "../ChangeOrderUploadDocument";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { formatDate } from "helpers/utils";
import { FaClone, FaCodeBranch, FaInfoCircle} from "react-icons/fa";
import Flex from "components/common/Flex";
import React, { useState } from "react";
import ClonePIFForm from "../ClonePIFForm";
import PIFVersionList from "./PIFVersionList";
import SimpleBar from "simplebar-react";
import PIFVersionWorkflowModal from "../PIFVersionWorkflowModal";
import PropTypes from 'prop-types';

const GeneralInformation = ({
  project,
  building,
  changeOrder,
  handleUploadDocument,
  removeDocument
}) => {
  const {
    allow_view_ppd_rad_document,
    allow_upload_ppd_rad_document,
    allow_remove_ppd_rad_document,
    allow_download_ppd_rad_document,
    allow_clone_pif_version,
    allow_delete_pif_version
  } = changeOrder?.permissions || {};

  const TYPE_PPD_RAD = "ppd-rad";

  const [showPIFForm, setShowPIFForm] = useState(false);
  const pifVersions = changeOrder?.pif_versions || [];
  const [showPIFWorkFlow, setShowPIFWorkFlow] = useState(false);

  const handleClonePIF = () => {
    setShowPIFForm(true);
  }

  const handleShowPIFVersionWorkFlow = () => {
    setShowPIFWorkFlow(true);
  }

  return (
    <>
      <Row className="mb-2 gx-2">
        <Col>
          <h6>Sales information</h6>
          <div className="mx-2">
            <Row className="mb-2 gx-2">
              <Col sm={3}>Note</Col>
              <Col className="text-primary">
                <div dangerouslySetInnerHTML={{ __html: changeOrder?.sales_note }}/>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <h6>CSD information</h6>
          <div className="mx-2">
            <Row className="mb-2 gx-2">
              <Col sm={3}>RAD date</Col>
              <Col className="text-primary">{formatDate(changeOrder?.return_approval_drawing)}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}>Entry date</Col>
              <Col className="text-primary">{formatDate(changeOrder?.entry_date)}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}>Status</Col>
              <Col className="text-primary">{building?.status_name}</Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}>CSE OE</Col>
              <Col className="text-primary">
                {building?.cse_order_entry.map(name => (
                  <Badge className="me-1">{name}</Badge>
                ))}
              </Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}>CSE OM</Col>
              <Col className="text-primary">
                {building?.cse_order_management.map(name => (
                  <Badge className="me-1">{name}</Badge>
                ))}
              </Col>
            </Row>
            <Row className="mb-2 gx-2">
              <Col sm={3}>CSD remark</Col>
              <Col className="text-primary">
                <div dangerouslySetInnerHTML={{ __html: changeOrder?.csd_remark }}/>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <Card>
            <Card.Header className="fs-0 text-primary">
              <Flex justifyContent="between">
                <div>
                  <FaCodeBranch className="text-warning" /> PIF Versions <FaInfoCircle title="Workflow" className="cursor-pointer" onClick={handleShowPIFVersionWorkFlow} />
                </div>
                <div>
                  {allow_clone_pif_version && (
                    <Button
                      size="sm"
                      variant="falcon-default"
                      title="Clone PIF"
                      onClick={handleClonePIF}
                    >
                      <FaClone className="text-warning" />
                    </Button>
                  )}
                </div>
              </Flex>
            </Card.Header>
            <Card.Body>
              <SimpleBar>
                <PIFVersionList 
                  projectId={project?.id}
                  buildingId={building?.id}
                  changeOrderId={changeOrder?.id}
                  allow_delete_pif_version={allow_delete_pif_version}
                  data={pifVersions}
                />
              </SimpleBar>
            </Card.Body>
            <Card.Footer>
              <span className="small"><FaInfoCircle /> PIF versions are only applicable for revised cases.</span>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <div className="border border-dashed border-bottom m-3"></div>

      <Row className="mt-4">
        <Col>
          {allow_upload_ppd_rad_document && (
            <ChangeOrderUploadDocument uploadDocument={(data) => handleUploadDocument(data, TYPE_PPD_RAD)}/>
          )}
        </Col>
        <Col>
          <h6 className="mt-3">Attachments:</h6>
          {changeOrder?.ppd_rad_documents && changeOrder?.ppd_rad_documents.map(attachment => (
            <DocumentAttachment
              {...attachment}
              key={attachment.id}
              isTable
              allowView={allow_view_ppd_rad_document}
              allowRemove={allow_remove_ppd_rad_document}
              allowDownload={allow_download_ppd_rad_document}
              handleRemoveAttachment={(documentId) => removeDocument(documentId, TYPE_PPD_RAD)}
            />
          ))}
        </Col>
      </Row>

      {showPIFForm && (
        <ClonePIFForm
          showModal={showPIFForm}
          setshowModal={setShowPIFForm}
          project={project}
          building={building}
          adaptation={changeOrder}
        />
      )}
      
      {showPIFWorkFlow && (
        <PIFVersionWorkflowModal
          showModal={showPIFWorkFlow}
          setshowModal={setShowPIFWorkFlow}
        />
      )}
    </>
  )
};

GeneralInformation.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  changeOrder: PropTypes.object.isRequired,
  handleUploadDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired
}

export default GeneralInformation;