import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deactivateSite, deleteSite } from 'redux/organization/siteSlice';
import SiteForm from './SiteForm';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const SiteHeader = ({selected}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showSiteForm, setShowSiteForm] = useState(false);
  const [title, setTitle] = useState("");
  const [site, setSite] = useState(selected[0] || {});

  useEffect(() => {
    setSite(selected[0])
  }, [selected]);

  const dispatch = useDispatch()

  const showForm = () => {
    setShowSiteForm(true)
  }

  const handleNewSite = () => {
    setTitle("New Site");
    showForm();
    setSite({});
  }

  const handleEditSite = () => {
    if (!validateSiteSelected()) return
    if (!validateSiteSelectedMultiple()) return

    setTitle("Edit Site");
    showForm()
  }

  const handleDeleteSite = () => {
    if (!validateSiteSelected()) return
    if (!validateSiteSelectedMultiple()) return
    
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    dispatch(deleteSite(site.id))
    setShowConfirmationDelete(false)
  }

  const validateSiteSelected = () => {
    if (selected.length === 0)
    {
      toast.error('Site has not selected yet.')
      return false
    }
    return true
  }

  const validateSiteSelectedMultiple = () => {
    if (selected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of site.')
      return false
    }
    return true
  }

  const handleDeactivateSite = () => {
    dispatch(deactivateSite(site.id));
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Flex justifyContent={'end'}>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewSite}
            >
              <span className="d-none d-xl-inline-block ms-1">New</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="lock"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleDeactivateSite}
            >
              <span className="d-none d-xl-inline-block ms-1">
                {site && site.active ? 'Deactivate' : 'Active'} 
              </span>
            </IconButton>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleEditSite}>
                    <FontAwesomeIcon icon="edit" /> Edit
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item className="text-danger" onClick={handleDeleteSite}>
                    <FontAwesomeIcon icon="trash-alt" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message="Are you sure to delete?"
        />
      )}
      
      {showSiteForm && (
        <SiteForm
          showModal={showSiteForm}
          setshowModal={setShowSiteForm}
          title={title}
          site={site}
        />
      )}
    </>
  )
};

SiteHeader.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired
};


export default SiteHeader;
