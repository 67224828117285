import React, { useState, useEffect, useMemo } from "react"
import { Accordion, Card, Row, Col, Form, Button, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from "react-hook-form";
import IconButton from "components/common/IconButton";
import Flex from "components/common/Flex";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconAlert from "components/common/IconAlert";
import { debounce } from "helpers/debounce";
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { getActiveSalesUnits } from "redux/crm/salesUnitSlice";
import { useSelector } from "react-redux";
import { getBuildingTypeLevel1, getBuildingTypeLevel2, getContactModes, getLeadSources } from "redux/crm/settingSlice";
import DatePicker from "react-datepicker";
import apiAxios from "helpers/apiAxios";
import { getCustomerContacts } from "redux/crm/customerSlice";
import { createProject, updateProject } from "redux/crm/projectSlice";
import { toast } from "react-toastify";
import SimpleBarReact from 'simplebar-react';
import CompetitorForm from "../competitor/CompetitorForm";
import { getAllCompetitors } from "redux/crm/competiorSlice";
import CompetiorList from "../competitor/CompetitorList";
import CustomerFormModal from "./detail/CustomerFormModal";
import GoogleMapAutoCompletePlaceDebounce from "components/google-map-places/GoogleMapAutoCompletePlaceDebounce";
import { salesEmployeesByNameDebounce } from "helpers/employeeOptionHelper";
import { objectSerializer } from "helpers/utils";
import ContactFormModal from "./detail/ContactFormModal";
import PropTypes from 'prop-types';
import { FaInfoCircle } from "react-icons/fa";

const ProjectForm = ({title, project}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues, reset, setError} = useForm();
  const {activeSalesUnitsData} = useSelector((state) => state.crm_sales_unit);
  const {data: competitorData} = useSelector((state) => state.crm_competitor);
  const {buildingTyleLevel1Data, buildingTyleLevel2Data, leadSourceData, contactModeData} = useSelector((state) => state.crm_setting);
  const [activeKey, setActiveKey] = useState(["0", "1", "2", "3", "4", "5"]);
  const {sales_unit} = useSelector(state => state.profile_user?.data);

  const [contactCustomers, setContactCustomers] = useState(null);
  const [contactEndUsers, setContactEndUsers] = useState(null);
  const [contactContractors, setContactContractors] = useState(null);
  const [contactConsultants, setContactConsultants] = useState(null);

  const [contactKey, setContactKey] = useState(0);
  const [contactEnduserKey, setContactEnduserKey] = useState(0);
  const [contactContractorKey, setContactContractorKey] = useState(0);
  const [contactConsultantKey, setContactConsultantKey] = useState(0);

  const [address, setAddress] = useState(project ? project.pj_location : null );
  const [showModalCompetitor, setShowModalCompetitor] = useState(false);
  const [showModalCompetitorList, setShowModalCompetitorList] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const [allowCreateContact, setAllowCreateContact] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disabledSalesUnitOption = sales_unit?.id !== null;

  useEffect(() => {
    if (title === "Create new PCV") {
      reset()
    }
  }, [title])

  useEffect(() => {
    dispatch(getActiveSalesUnits());
    dispatch(getBuildingTypeLevel1());
    dispatch(getLeadSources());
    dispatch(getContactModes());
  }, []);

  useEffect(() => {
    if (project && project.company_id) {
      handleSelectChange(project.company_id, "company_id", setContactCustomers, "contact_id", setContactKey);
    }

    if (project && project.enduser_id) {
      handleSelectChange(project.enduser_id, "enduser_id", setContactEndUsers, "enduser_contact_id", setContactEnduserKey);
    }
  }, [project])

  useEffect(() => {
    if (competitorData.length === 0) {
      dispatch(getAllCompetitors())
    }
  }, [competitorData])

  useEffect(() => {
    setValue("sales_unit_id", sales_unit?.id);
    
    if(project) {
      setCurrentCustomerId(project.customer?.id);

      Object.entries(project).forEach(([key, value]) => {
        switch (key) {
          case "expected_date_of_signing_contract":
            {
              const parsedDate = Date.parse(value);

              if (!isNaN(parsedDate)) {
                setValue(key, new Date(parsedDate));
              } else {
                console.error(`Invalid date value for key ${key}: ${value}`);
              }
            }

            break
          default:
            setValue(key, value)
            break
        }
      })
    }
  }, [setValue, project])

  const fetchBuildingTypeLevel2 = (buildingTypeL1Id) => {
    const params = {parent_id: buildingTypeL1Id}
    dispatch(getBuildingTypeLevel2(params))
  }

  const salesUnitOptions = useMemo(() => (
    activeSalesUnitsData
    ? activeSalesUnitsData.map((option) => ({ value: option.id, label: `${option.name} - ${option.description}`}))
    : []
  ), [activeSalesUnitsData])

  const defaultSalesUnitOption = useMemo(() => (
    project && project.sales_unit_id ? {value: project.sales_unit_id, label: project.sales_unit?.full_name} : {value: sales_unit?.id, label: sales_unit?.full_name}
  ), [])

  const buildingTypeL1Options = useMemo(() => (
    buildingTyleLevel1Data
    ? buildingTyleLevel1Data.map((option) => ({ value: option.id, label: option.name}))
    : []
  ), [buildingTyleLevel1Data])

  const defaultBuildingTypeL1Option = useMemo(() => (
    project && project.building_type_l1 ? {value: project?.building_type_l1.id, label: project?.building_type_l1?.name} : null
  ), [])

  const buildingTypeL2Options = useMemo(() => (
    buildingTyleLevel2Data
    ? buildingTyleLevel2Data.map((option) => ({ value: option.id, label: option.name}))
    : []
  ), [buildingTyleLevel2Data])

  const defaultBuildingTypeL2Option = useMemo(() => (
    project && project.building_type_l2 ? {value: project?.building_type_l2.id, label: project?.building_type_l2?.name} : null
  ), [])

  const leadSourceOptions = useMemo(() => (
    leadSourceData
    ? leadSourceData.map((option) => ({ value: option.name, label: option.name}))
    : []
  ), [leadSourceData])

  const defaultLeadSourceOption = useMemo(() => (
    project && project.lead_source ? {value: project.lead_source, label: project.lead_source} : null
  ), [])

  const contactModeOptions = useMemo(() => (
    contactModeData
    ? contactModeData.map((option) => ({ value: option.name, label: option.name}))
    : []
  ), [contactModeData])

  const defaultContactModeOption = useMemo(() => (
    project && project.contact_mode ? {value: project.contact_mode, label: project.contact_mode} : null
  ), [])

  const filterCustomersOptions = async (inputValue) => {
    const url = `/crm/projects/load_customers?page=1&per_page=30&name_cont=${inputValue}`

    const response = await apiAxios.get(url)
    const {rows} = response.data
    const options = rows && rows.map((item) => (
      { value: item.id, label: item.name }
    ))
  
    return options
  }

  const debouncedFilterCustomers = debounce((inputValue, callback) => {
    filterCustomersOptions(inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300)
  
  const customerOptions = (inputValue, callback) => {
    debouncedFilterCustomers(inputValue, callback)
  }

  // const getDefaultContactOrgOption = (project, contactIdKey, contactKey) => (
  //   project && project[contactIdKey] ? { value: project[contactKey].id, label: project[contactKey].name } : null
  // );

  const mapContactOptions = (contacts) => (
    contacts ? contacts.map(contact => ({ value: contact.id, label: contact.name })) : []
  );
  
  const getDefaultContactOrgOption = (project, contactIdKey, contactKey) => (
    project && project[contactIdKey] ? { value: project[contactKey].id, label: project[contactKey].name } : null
  );

  const getDefaultContactOption = (project, contactIdKey, contactKey) => (
    project && project[contactIdKey] ? { value: project[contactKey].contact_id, label: project[contactKey].contact_name } : null
  );

  const contactOptions = useMemo(() => mapContactOptions(contactCustomers), [contactCustomers]);
  const defaultContactOption = useMemo(() => getDefaultContactOrgOption(project, 'contact_id', 'contact'), [project]);

  const contactEnduserOptions = useMemo(() => mapContactOptions(contactEndUsers), [contactEndUsers]);
  const contactContractorOptions = useMemo(() => mapContactOptions(contactContractors), [contactContractors]);
  const contactConsultantOptions = useMemo(() => mapContactOptions(contactConsultants), [contactConsultants]);

  const defaultContactEnduserOption = useMemo(() => getDefaultContactOption(project, 'enduser_contact_id', 'enduser'), [project]);
  const defaultContactContractorOption = useMemo(() => getDefaultContactOption(project, 'contractor_contact_id', 'contractor'), [project]);
  const defaultContactConsultantOption = useMemo(() => getDefaultContactOption(project, 'consultant_contact_id', 'consultant'), [project]);


  const getDefaultOption = (project, key) => (
    project && project[key] ? { value: project[key].id, label: project[key].name } : null
  );

  const defaultCustomerOption = useMemo(() => getDefaultOption(project, 'customer'), [project]);
  const defaultEnduserOption = useMemo(() => getDefaultOption(project, 'enduser'), [project]);
  const defaultContractorOption = useMemo(() => getDefaultOption(project, 'contractor'), [project]);
  const defaultConsultantOption = useMemo(() => getDefaultOption(project, 'consultant'), [project]);

  const competitorOptions = useMemo(() => (
    competitorData ?
    competitorData.map((com) => ({ value: com.id, label: com.name }))
    : []
  ), [competitorData])

  const defaultCompetitorOption = useMemo(() => {
    if (project && project.competitor_ids && competitorOptions) {
      return competitorOptions.filter(option => project.competitor_ids.includes(option.value));
    }
    return null;
  }, [project, competitorOptions]);
  
  const employeeOptions = (inputValue, callback) => {
    salesEmployeesByNameDebounce(inputValue, callback)
  }

  const getDefaultEmpOption = (project, key) => (
    project && project[key] ? { value: project[key], label: project[key] } : null
  );

  const defaultSalesInchargeOption = useMemo(() => getDefaultEmpOption(project, 'sales_incharge'), [project]);
  const defaultSalesManagerOption = useMemo(() => getDefaultEmpOption(project, 'sales_manager'), [project]);
  const defaultSalesSupportOption = useMemo(() => getDefaultEmpOption(project, 'sales_support'), [project]);
  const defaultSalesSupportSupervisorOption = useMemo(() => getDefaultEmpOption(project, 'sales_support_supervisor'), [project]);
  const defaultSalesSupportManagerOption = useMemo(() => getDefaultEmpOption(project, 'sales_support_manager'), [project]);
  const defaultCSEAreaOption = useMemo(() => getDefaultEmpOption(project, 'cse_area'), [project]);
  const defaultCSMAreaOption = useMemo(() => getDefaultEmpOption(project, 'csm_area'), [project]);

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const validateProjectLocation = () => {
    if (!address) {
      setError('pj_location', {
        type: 'manual',
        message: 'Project location must be required',
      });
      return;
    }
  }

  const validateEnduserContractorConsultant = () => {
    if (!getValues("enduser_id") && !getValues("contractor_id") && getValues("consultant_id")) {
      toast.error("Consultant, Contractor or EndUser must be specify.")
      return
    }
  }

  const onSubmit = async (data) => {
    validateProjectLocation()
    validateEnduserContractorConsultant()

    if (address) {
      data = {
        ...data,
        pj_location: address.description,
        pj_province: address.city_province,
        pj_country: address.country
      };
    }

    console.log(data)

    try {
      let projectId = ""

      if (project) {
        projectId = project.id
        dispatch(updateProject({projectId, data }))
      }
      else {
        const response = await dispatch(createProject(data))
        projectId = response.payload.data.id
      }

      if (projectId) {
        navigate(`/crm/projects/${projectId}`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleNewCustomer = () => {
    setShowCustomerModal(true)
  }

  const handleNewContact = () => {
    setShowContactModal(true)
  }

  const handleRefreshContact = () => {
    handleSelectChange(currentCustomerId, "company_id", setContactCustomers, "contact_id", setContactKey);
  }

  const handleNewCompetitor = () => {
    setShowModalCompetitor(true)
  }

  const handleCompetitorList = () => {
    setShowModalCompetitorList(true)
  }

  const customerChange = async (selectedValue) => {
    handleSelectChange(selectedValue, "company_id", setContactCustomers, "contact_id", setContactKey);
    setAllowCreateContact(true);

    setCurrentCustomerId(selectedValue);
  };
  
  const endUserChange = async (selectedValue) => {
    handleSelectChange(selectedValue, "enduser_id", setContactEndUsers, "enduser_contact_id", setContactEnduserKey);
  };

  const contractorChange = async (selectedValue) => {
    handleSelectChange(selectedValue, "contractor_id", setContactContractors, "contractor_contact_id", setContactContractorKey);
  };

  const consultantChange = async (selectedValue) => {
    handleSelectChange(selectedValue, "consultant_id", setContactConsultants, "consultant_contact_id", setContactConsultantKey);
  };

  const handleSelectChange = async (selectedValue, customerKey, dataSetter, contactKey, setContractKeySetter) => {
    if (!selectedValue) {
      setValue(customerKey, null);
      return;
    }

    setValue(customerKey, selectedValue)
    const response = await dispatch(getCustomerContacts(selectedValue));

    if(response.payload) {
      dataSetter(objectSerializer(response.payload));
    }
  
    // Reset current contact selected
    setValue(contactKey, null);
  
    // Reload contact
    setContractKeySetter(prevKey => prevKey + 1);
  };

  const handleChangeCompetitor = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('competitor_ids', selectedOptionsValues, { shouldValidate: true });
  }

  return (
    <>
      <Card className="fs--1">
        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          <Card.Header className="d-flex flex-between-center border-bottom">
            <Card.Title>{title}</Card.Title>
            <IconButton
              onClick={handleBack}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
            />
          </Card.Header>
          <Card.Body className="mt-3 fs--1">
            <IconAlert variant="warning">
              <p className="mb-0">Note: All PCV must be sumitted to Sales Department.</p>
            </IconAlert>
            
            <Accordion
              id="pcv-project"
              className="border rounded overflow-hidden fs--1 mt-3"
              alwaysOpen
              flush
              activeKey={activeKey}
              onSelect={handleAccordionSelect}
            >
              <Card className="rounded-0">

                {/* Project information */}
                <Accordion.Item eventKey="0" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          1. Project information
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <Form.Group as={Row} className="mb-2" controlId="name">
                        <Form.Label column sm={3} className="text-lg-end">
                          Project name*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="name"
                            placeholder="Project name"
                            className="fs--1"
                            {...register("name", {
                              required: "Project name must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors.name && errors.name.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="amount">
                        <Form.Label column sm={3} className="text-lg-end">
                          Amount (USD)*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="amount"
                            placeholder="Amount"
                            className="fs--1"
                            {...register("amount", {
                              required: "Amount must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors.amount && errors.amount.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="weight">
                        <Form.Label column sm={3} className="text-lg-end">
                          Weight (M.T)*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="weight"
                            placeholder="Weight"
                            className="fs--1"
                            {...register("weight", {
                              required: "Weight must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors.weight && errors.weight.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="area">
                        <Form.Label column sm={3} className="text-lg-end">
                          Area (m2)*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            name="area"
                            placeholder="Area"
                            className="fs--1"
                            {...register("area", {
                              required: "Area must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors.area && errors.area.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2 mt-4" controlId="pj_location">
                        <Form.Label column sm={3} className="text-lg-end">
                          Project location*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <GoogleMapAutoCompletePlaceDebounce
                            id="project-location"
                            setAddress={setAddress}
                            defaultAddress={project?.pj_location}
                          />
                          <span className="text-danger">
                            {errors.pj_location && errors.pj_location.message}
                          </span>
                          <div className="my-2">
                            <FaInfoCircle /> Ensure that at least the correct province/city and country are provided for the location.
                          </div>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2 mt-4" controlId="expected_date_of_signing_contract">
                        <Form.Label column sm={3} className="text-lg-end">
                          Expected month signing contract
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="expected_date_of_signing_contract"
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control fs--1"
                                placeholderText="MM-YYYY"
                                dateFormat="MM-yyyy"
                                showMonthYearPicker
                                fixedHeight
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="sales_unit_id">
                        <Form.Label column sm={3} className="text-lg-end">
                          Sales unit*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="sales_unit_id"
                            rules={{required: "Sales unit must be required"}}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={salesUnitOptions}
                                defaultValue={defaultSalesUnitOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('sales_unit_id', selectedOption.value, {shouldValidate: true})}
                                isDisabled={disabledSalesUnitOption}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors.sales_unit_id && errors.sales_unit_id.message}
                          </span>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                 {/* Customer information */}
                <Accordion.Item eventKey="1" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          2. Customer information
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <div className="my-3">
                        <Form.Group as={Row} className="mb-2" controlId="company_id">
                          <Form.Label column sm={3} className="text-lg-end">
                            Customer name (Signed contract with)*
                          </Form.Label>
                          <Col sm={9} md={7}>
                            <Row className="gx-1">
                              <Col>
                                <Controller
                                  control={control}
                                  name="company_id"
                                  rules={{ required: "Customer must be required" }}
                                  render={() => (
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={customerOptions}
                                      defaultValue={defaultCustomerOption}
                                      closeMenuOnSelect={true}
                                      placeholder='Select...'
                                      classNamePrefix="react-select"
                                      className="w-100"
                                      isClearable
                                      onChange={(selectedOption) => {
                                        customerChange(selectedOption.value)
                                      }}
                                    />
                                  )}
                                />
                              </Col>
                              <Col sm="auto">
                                <IconButton
                                  variant="falcon-default"
                                  title="New customer"
                                  icon="plus"
                                  transform="shrink-2"
                                  iconAlign="middle"
                                  className="me-1"
                                  onClick={handleNewCustomer}
                                />
                              </Col>
                            </Row>
                            
                            <span className="text-danger">
                              {errors.company_id && errors.company_id.message}
                            </span>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2" controlId="contact_id">
                          <Form.Label column sm={3} className="text-lg-end">
                            Contact*
                          </Form.Label>
                          <Col sm={9} md={7}>
                            <Row className="gx-1">
                              <Col>
                                <Controller
                                  control={control}
                                  name="contact_id"
                                  //rules={{ required: "Contact for the customer must be required" }}
                                  render={() => (
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={contactOptions}
                                      defaultValue={defaultContactOption}
                                      placeholder='Select...'
                                      classNamePrefix="react-select"
                                      key={contactKey}
                                      isClearable
                                      onChange={selectedOption => {
                                        setValue('contact_id', selectedOption ? selectedOption.value : null, {shouldValidate: true})
                                      }}
                                    />
                                  )}
                                />
                              </Col>
                              <Col sm="auto">
                                <IconButton
                                  variant="falcon-default"
                                  title="New contact"
                                  icon="plus"
                                  transform="shrink-2"
                                  iconAlign="middle"
                                  className="me-1"
                                  disabled={!allowCreateContact}
                                  onClick={() => handleNewContact(project?.customer)}
                                />
                                <IconButton
                                  variant="falcon-default"
                                  title="Refresh"
                                  icon="sync-alt"
                                  transform="shrink-2"
                                  iconAlign="middle"
                                  className="me-1"
                                  disabled={!allowCreateContact}
                                  onClick={() => handleRefreshContact(project?.customer)}
                                />
                              </Col>
                            </Row>
                            
                            <span className="text-danger">
                              {errors.contact_id && errors.contact_id.message}
                            </span>
                          </Col>
                        </Form.Group>
                      </div>

                      <Card className="mt-5">
                        <Tab.Container defaultActiveKey="enduser">
                          <Card.Header className="p-0 bg-light overflow-hidden">
                            <SimpleBarReact>
                              <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="enduser"
                                    className="d-flex align-items-center py-3 px-x1 mb-0"
                                  >
                                    <h6 className="text-600 mb-0 ms-1">End-user</h6>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="contractor"
                                    className="d-flex align-items-center py-3 px-x1 mb-0"
                                  >
                                    <h6 className="text-600 mb-0 ms-1">Contractor</h6>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="consultant"
                                    className="d-flex align-items-center py-3 px-x1 mb-0"
                                  >
                                    <h6 className="text-600 mb-0 ms-1">Consultant</h6>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="competitor"
                                    className="d-flex align-items-center py-3 px-x1 mb-0"
                                  >
                                    <h6 className="text-600 mb-0 ms-1">Competitor</h6>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </SimpleBarReact>
                          </Card.Header>
                          <Card.Body className="bg-light">
                            <Tab.Content>
                              <Tab.Pane eventKey="enduser">
                                <Form.Group as={Row} className="mb-2" controlId="enduser_id">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    End user
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Row className="gx-1">
                                      <Col>
                                        <Controller
                                          control={control}
                                          name="enduser_id"
                                          //rules={{ required: "Enduser must be required" }}
                                          render={() => (
                                            <AsyncSelect
                                              cacheOptions
                                              defaultOptions
                                              loadOptions={customerOptions}
                                              defaultValue={defaultEnduserOption}
                                              closeMenuOnSelect={true}
                                              placeholder='Select...'
                                              classNamePrefix="react-select"
                                              className="w-100"
                                              isClearable
                                              onChange={(selectedOption) => endUserChange(selectedOption?.value)}
                                            />
                                          )}
                                        />
                                      </Col>
                                      <Col sm="auto">
                                        <IconButton
                                          variant="falcon-default"
                                          title="New customer"
                                          icon="plus"
                                          transform="shrink-2"
                                          iconAlign="middle"
                                          className="me-1"
                                          onClick={handleNewCustomer}
                                        />
                                      </Col>
                                    </Row>
                                    
                                    <span className="text-danger">
                                      {errors.enduser_id && errors.enduser_id.message}
                                    </span>
                                  </Col>
                                </Form.Group>
                              
                                <Form.Group as={Row} className="mb-2" controlId="enduser_contact_id">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    Contact
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Controller
                                      control={control}
                                      name="enduser_contact_id"
                                      //rules={{ required: "Contact for the Enduser must be required" }}
                                      render={() => (
                                        <Select
                                          closeMenuOnSelect={true}
                                          options={contactEnduserOptions}
                                          defaultValue={defaultContactEnduserOption}
                                          placeholder='Select...'
                                          classNamePrefix="react-select"
                                          key={contactEnduserKey}
                                          isClearable
                                          onChange={selectedOption => {
                                            setValue('enduser_contact_id', selectedOption ? selectedOption.value : null, {shouldValidate: true})
                                          }}
                                        />
                                      )}
                                    />
                                     
                                    <span className="text-danger">
                                      {errors.enduser_contact_id && errors.enduser_contact_id.message}
                                    </span>
                                  </Col>
                                </Form.Group>
                              </Tab.Pane>
                              <Tab.Pane eventKey="contractor">
                                {/* Contractor */}
                                <Form.Group as={Row} className="mb-2" controlId="contractor_id">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    Contractor
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Row className="gx-1">
                                      <Col>
                                        <Controller
                                          control={control}
                                          name="contractor_id"
                                          render={() => (
                                            <AsyncSelect
                                              cacheOptions
                                              defaultOptions
                                              loadOptions={customerOptions}
                                              defaultValue={defaultContractorOption}
                                              closeMenuOnSelect={true}
                                              placeholder='Select...'
                                              classNamePrefix="react-select"
                                              className="w-100"
                                              isClearable
                                              onChange={(selectedOption) => contractorChange(selectedOption?.value)}
                                            />
                                          )}
                                        />
                                      </Col>
                                      <Col sm="auto">
                                        <IconButton
                                          variant="falcon-default"
                                          title="New customer"
                                          icon="plus"
                                          transform="shrink-2"
                                          iconAlign="middle"
                                          className="me-1"
                                          onClick={handleNewCustomer}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-2" controlId="contractor_contact_id">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    Contact
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Controller
                                      control={control}
                                      name="contractor_contact_id"
                                      render={() => (
                                        <Select
                                          closeMenuOnSelect={true}
                                          options={contactContractorOptions}
                                          defaultValue={defaultContactContractorOption}
                                          placeholder='Select...'
                                          classNamePrefix="react-select"
                                          key={contactContractorKey}
                                          isClearable
                                          onChange={selectedOption => {
                                            setValue('contractor_contact_id', selectedOption ? selectedOption.value : null, {shouldValidate: true})
                                          }}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Form.Group>
                              </Tab.Pane>
                              <Tab.Pane eventKey="consultant">
                                {/* Consultant */}
                                <Form.Group as={Row} className="mb-2" controlId="consultant_id">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    Consultant
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Row className="gx-1">
                                      <Col>
                                        <Controller
                                          control={control}
                                          name="consultant_id"
                                          render={() => (
                                            <AsyncSelect
                                              cacheOptions
                                              defaultOptions
                                              loadOptions={customerOptions}
                                              defaultValue={defaultConsultantOption}
                                              closeMenuOnSelect={true}
                                              placeholder='Select...'
                                              classNamePrefix="react-select"
                                              className="w-100"
                                              isClearable
                                              onChange={(selectedOption) => consultantChange(selectedOption?.value)}
                                            />
                                          )}
                                        />
                                      </Col>
                                      <Col sm="auto">
                                        <IconButton
                                          variant="falcon-default"
                                          title="New customer"
                                          icon="plus"
                                          transform="shrink-2"
                                          iconAlign="middle"
                                          className="me-1"
                                          onClick={handleNewCustomer}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-2" controlId="consultant_contact_id">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    Contact
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Controller
                                      control={control}
                                      name="consultant_contact_id"
                                      render={() => (
                                        <Select
                                          closeMenuOnSelect={true}
                                          options={contactConsultantOptions}
                                          defaultValue={defaultContactConsultantOption}
                                          placeholder='Select...'
                                          classNamePrefix="react-select"
                                          key={contactConsultantKey}
                                          isClearable
                                          onChange={selectedOption => {
                                            setValue('consultant_contact_id', selectedOption ? selectedOption.value : null, {shouldValidate: true})
                                          }}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Form.Group>
                              </Tab.Pane>
                              <Tab.Pane eventKey="competitor">
                                {/* Competitor */}
                                <Form.Group as={Row} className="mb-2" controlId="competitor_ids">
                                  <Form.Label column sm={3} className="text-lg-end">
                                    Competitor
                                  </Form.Label>
                                  <Col sm={9} md={7}>
                                    <Controller
                                      control={control}
                                      name="competitor_ids"
                                      render={() => (
                                        <Select
                                          closeMenuOnSelect={false}
                                          options={competitorOptions}
                                          defaultValue={defaultCompetitorOption}
                                          placeholder='Select...'
                                          classNamePrefix="react-select"
                                          isClearable
                                          isMulti
                                          onChange={handleChangeCompetitor}
                                        />
                                      )}
                                    />
                                    <Flex justifyContent="end" className="mt-2 fs--1">
                                      <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        title="New Competitor"
                                        icon="plus"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-1"
                                        onClick={handleNewCompetitor}
                                      >
                                        New Competitor
                                      </IconButton>
                                      <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        title="List Competitor"
                                        icon="list"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-1"
                                        onClick={handleCompetitorList}
                                      >
                                        List
                                      </IconButton>
                                    </Flex>
                                  </Col>
                                </Form.Group>
                              </Tab.Pane>
                            </Tab.Content>
                          </Card.Body>
                        </Tab.Container>
                      </Card>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Segmentation */}
                <Accordion.Item eventKey="2" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          3. Segmentation
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <Form.Group as={Row} className="mb-2" controlId="building_type_l1_id">
                        <Form.Label column sm={3} className="text-lg-end">
                          Building Type L1*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="building_type_l1_id"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={buildingTypeL1Options}
                                defaultValue={defaultBuildingTypeL1Option}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                //key={buildingTypeLevel2Key}
                                onChange={selectedOption => { 
                                  const value = selectedOption.value

                                  setValue('building_type_l1_id', value, {shouldValidate: true})
                                  fetchBuildingTypeLevel2(value)

                                  //reset building level2 if current has value selected
                                  setValue('building_type_l2_id', null)

                                  //setBuildingTypeLevel2Key(prevKey => prevKey + 1)
                                }}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Building type level 1 must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.building_type_l1_id && errors.building_type_l1_id.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="building_type_l2_id">
                        <Form.Label column sm={3} className="text-lg-end">
                          Building Type L2*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="building_type_l2_id"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={buildingTypeL2Options}
                                defaultValue={defaultBuildingTypeL2Option}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('building_type_l2_id', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Building type level 2 must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.building_type_l2_id && errors.building_type_l2_id.message}
                          </span>
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row} className="mb-2" controlId="lead_source">
                        <Form.Label column sm={3} className="text-lg-end">
                          Lead source*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="lead_source"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={leadSourceOptions}
                                defaultValue={defaultLeadSourceOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('lead_source', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Lead source must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.lead_source && errors.lead_source.message}
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="contact_mode">
                        <Form.Label column sm={3} className="text-lg-end">
                          How does customer know Pebsteel?*
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name="contact_mode"
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={contactModeOptions}
                                defaultValue={defaultContactModeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue('contact_mode', selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'How does customer know Pebsteel must be required'
                              }
                            }}
                          />
                          <span className="text-danger">
                            {errors.contact_mode && errors.contact_mode.message}
                          </span>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Responsible */}
                <Accordion.Item eventKey="3" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          4. Responsible
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <Row>
                        <Col>
                          <Form.Group as={Row} className="mb-2" controlId="sales_incharge">
                            <Form.Label column sm={5} className="text-lg-end">
                              Sales incharge*
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="sales_incharge"
                                rules={{required: "Sales incharge must be required"}}
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultSalesInchargeOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("sales_incharge", selectedOption.value) : null}
                                  />
                                )}
                              />
                              <span className="text-danger">
                                {errors.sales_incharge && errors.sales_incharge.message}
                              </span>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-2" controlId="sales_manager">
                            <Form.Label column sm={5} className="text-lg-end">
                              Sales manager
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="sales_manager"
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultSalesManagerOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("sales_manager", selectedOption.value) : null}
                                  />
                                )}
                              />
                              <span className="text-danger">
                                {errors.sales_manager && errors.sales_manager.message}
                              </span>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row} className="mb-2" controlId="sales_support">
                            <Form.Label column sm={5} className="text-lg-end">
                              Sales support
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="sales_support"
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultSalesSupportOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("sales_support", selectedOption.value) : null}
                                  />
                                )}
                              />
                              <span className="text-danger">
                                {errors.sales_support && errors.sales_support.message}
                              </span>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-2" controlId="sales_support_supervisor">
                            <Form.Label column sm={5} className="text-lg-end">
                              Sales support supervisor
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="sales_support_supervisor"
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultSalesSupportSupervisorOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("sales_support_supervisor", selectedOption.value) : null}
                                  />
                                )}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-2" controlId="sales_support_manager">
                            <Form.Label column sm={5} className="text-lg-end">
                              Sales support manager
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="sales_support_manager"
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultSalesSupportManagerOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("sales_support_manager", selectedOption.value) : null}
                                  />
                                )}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row} className="mb-2" controlId="cse_area">
                            <Form.Label column sm={5} className="text-lg-end">
                              CSE area incharge
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="cse_area"
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultCSEAreaOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("cse_area", selectedOption.value) : null}
                                  />
                                )}
                              />
                              <span className="text-danger">
                                {errors.cse_area && errors.cse_area.message}
                              </span>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} className="mb-2" controlId="csm_area">
                            <Form.Label column sm={5} className="text-lg-end">
                              CSE area manager
                            </Form.Label>
                            <Col sm={7} md={6}>
                              <Controller
                                control={control}
                                name="csm_area"
                                render={() => (
                                  <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={employeeOptions}
                                    defaultValue={defaultCSMAreaOption}
                                    closeMenuOnSelect={true}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    isClearable
                                    onChange={(selectedOption) => selectedOption ? setValue("csm_area", selectedOption.value) : null}
                                  />
                                )}
                              />
                              <span className="text-danger">
                                {errors.csm_area && errors.csm_area.message}
                              </span>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Notes */}
                <Accordion.Item eventKey="4" className="border-0">
                  <Card.Header className="p-0">
                    <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon="caret-right"
                          className="accordion-icon me-3 mt-1"
                          transform="shrink-2"
                        />
                        <span className="fw-medium font-sans-serif text-900">
                          5. Notes
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className="p-0">
                    <Card.Body className="m-2">
                      <Form.Group as={Row} className="mb-2" controlId="remark">
                        {/* <Form.Label column sm={3} className="text-lg-end">
                          
                        </Form.Label> */}
                        <Col>
                          <Form.Control
                            as="textarea"
                            rows={10}
                            name="remark"
                            placeholder="Remark"
                            className="fs--1"
                            {...register("remark")}
                          />
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            </Accordion>

            <div className="bg-light fixed-bottom border-0 text-center p-3">
              <Button size="sm" type="submit" className="me-2">{project ? "Update" : "Create"} PCV</Button>
              <Button size="sm" variant="danger" onClick={handleBack}>Cancel</Button>
            </div>

          </Card.Body>
        </Form>
      </Card>

      {showModalCompetitor && (
        <CompetitorForm 
          showModal={showModalCompetitor}
          setshowModal={setShowModalCompetitor}
          title="New Competitor"
        />
      )}

      {showModalCompetitorList && (
        <CompetiorList
          showModal={showModalCompetitorList}
          setshowModal={setShowModalCompetitorList}
        />
      )}
      
      {showContactModal && (
        <ContactFormModal
          showModal={showContactModal}
          setshowModal={setShowContactModal}
          customerId={currentCustomerId}
        />
      )}
      
      {showCustomerModal && (
        <CustomerFormModal
          showModal={showCustomerModal}
          setshowModal={setShowCustomerModal}
        />
      )}
      
    </>
  )
};

ProjectForm.propTypes = {
  title: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired
}

export default ProjectForm;