import Avatar from "components/common/Avatar";
import { Card, Col, Collapse, Row, Table, Form, InputGroup, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import createMarkup from "helpers/createMarkup";
import DateTimeToText from "helpers/dateTimetoText";
import { Link } from "react-router-dom";
import AgPagination from "components/ag-grid/AgPagination";
import { formatBookingDate, joinWithArray } from "helpers/utils";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { debounce } from "helpers/debounce";
import { RiRefreshFill } from "react-icons/ri";

const FilterHistory = ({value, onSearchChange, onRefresh}) => {
  const handleSumit = (e) => {
    e.preventDefault();
  }

  return (
    <Row>
      <Col>
        <h5 className="mb-1 mb-md-0">Activities history</h5>
      </Col>
      <Col lg={4}>
        <Form onSubmit={handleSumit}>
          <InputGroup>
            <Form.Control placeholder="Search..." onChange={onSearchChange} value={value} />
            <Button size="xs" type="submit">
              <FaSearch className="me-1"  />
            </Button>
            <Button size="xs" className="bg-info" onClick={onRefresh}>
              <RiRefreshFill className="me-1" title="Refresh" />
            </Button>
          </InputGroup>
        </Form>
      </Col>
    </Row>
  );
};

const ActivityHistory = ({
  activityLogData,
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog,
  onRefresh,
  ...dynamicParams //dynamic params pass from another component
}) => {
  const {
    data = [],
    totalRows,
    pageSize,
    totalPagesCount,
    fromRecord,
    toRecord,
    currentParams
  } = activityLogData || {};

  const key = "activityLogData";

  const [filterValue, setFilterValue] = useState('')
  const [openStates, setOpenStates] = useState(Array(data.length).fill(false));

  const dispatch = useDispatch()

  const emoji = '🕒'

  useEffect(() => {
    fetchActivityLog();
  }, [currentParams])

  const handleRefresh = () => {
    fetchActivityLog();
  }

  const fetchActivityLog = () => {
    if (currentParams) {
      const queryParams = { ...currentParams, q: filterValue };
      dispatch(getActivityLog({ ...dynamicParams, params: queryParams }));
    }
  }

  const handlePageChange = ((page, pageSize) => {
    dispatch(setNestedPage({ key, page }));
    dispatch(setNestedPageSize({ key, pageSize }));

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize}
    console.log(newCurrentParams)
    dispatch(setNestedParams({ key, params: newCurrentParams }));

    dispatch(getActivityLog({ ...dynamicParams, params: newCurrentParams }));
  })

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = value ? { q: value } : {...currentParams};
      dispatch(setNestedParams({ key, params: newParams }));
    }, 500),
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target;
    setFilterValue(value);
    handleChangeDebounced(value);
  }

  const toggleOpenState = (index) => {
    setOpenStates((prevOpenStates) => {
      const newOpenStates = [...prevOpenStates];
      newOpenStates[index] = !newOpenStates[index];
      return newOpenStates;
    });
  };

  return (
    <Card>
      <Card.Header className="bg-light">
       <FilterHistory
        value={filterValue}
        onSearchChange={handleChange}
        onRefresh={handleRefresh}
       />
      </Card.Header>
      <Card.Body className="p-0">
        {data && data.map((activity, index) => {
          return (
            <div
              key={index}
              className="border-x-0 border-bottom-0 border-300"
            >
              <div className="notification rounded-0 border-x-0 border-300 border-bottom-0">
                {activity.avatar && (
                  <div className="notification-avatar">
                    <Avatar {...activity.avatar} className="me-3" />
                  </div>
                )}
                <div className="notification-body">
                  <h6 className="text-primary text-capitalize">{activity.action.replace(/_/g, ' ')}</h6>
                  <p className="mb-1" dangerouslySetInnerHTML={createMarkup(activity.comment)} />
                  <span className="notification-time">
                    {emoji && (
                      <span className="me-2" role="img" aria-label="Emoji">
                        {emoji}
                      </span>
                    )} 
                    <DateTimeToText datetime={activity.created_at} /> | by {activity.created_by}
                  </span>
                  {activity.audited_changes && Object.keys(activity.audited_changes).length !== 0 && (
                    <div className="mt-2">
                      <Link onClick={() => toggleOpenState(index)}>
                        View Detail
                      </Link>
                      <Collapse in={openStates[index]}>
                        <div className="border rounded">
                          <div className='p-x1 table-responsive'>
                            <Table striped>
                              <thead className="bg-soft-primary text-center">
                                <th scope="col" className="text-start">Attributes changed</th>
                                <th scope="col" className="text-start">From</th>
                                <th scope="col" className="text-start">To</th>
                              </thead>
                              <tbody>
                                {Object.entries(activity.audited_changes).map(([fieldName, fieldChanges]) => {
                                  const isDateField = fieldName === "Date of travel" || fieldName === "Date of return";
                                  const formatField = isDateField ? formatBookingDate : joinWithArray;

                                  const formatValue = (value) => {
                                    if (typeof value === 'object' && value !== null) {
                                      return JSON.stringify(value);
                                    }
                                    return formatField(value);
                                  };

                                  return (
                                    <tr key={fieldName}>
                                      <td className="text-start">{fieldName}</td>
                                      <td className="text-start text-primary">
                                        {formatValue(fieldChanges[0]) === true ? "true" : formatValue(fieldChanges[0]) === false ? "false" : formatValue(fieldChanges[0])}
                                      </td>
                                      <td className="text-start text-danger">
                                        {formatValue(fieldChanges[1]) === true ? "true" : formatValue(fieldChanges[1]) === false ? "false" : formatValue(fieldChanges[1])}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}

        <Row className="m-3">
          <Col>
            <AgPagination
              totalRows={totalRows}
              totalPagesCount={totalPagesCount}
              pageChange={handlePageChange}
              pageSize={pageSize}
              from={fromRecord}
              to={toRecord}
              itemsPages={["10", "20", "50"]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActivityHistory;