import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import React, { useRef, useState } from 'react';
import { OverlayTrigger, Tooltip, Card, Row, Col, Button } from 'react-bootstrap';
import DetailViewModal from '../DetailViewModal';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (newData) => ({
  color: [
    getColor('primary'),
    getColor('warning'),
    getColor('success'),
    getColor('info'),
    getColor('danger')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<strong>${params.data.name}:</strong> ${params.percent}%`;
    }
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '67%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: newData
    }
  ]
});

getOptions.propTypes = {
  newData: PropTypes.array.isRequired
}


const SummaryStatusChart = ({ data }) => {
  const chartRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const totalChangeOrders = data?.length || 0;

  const projectObject = data && data?.reduce((acc, item) => {
    acc[item?.co_status] = (acc[item?.co_status] || 0) + 1;
    return acc
  }, {});

  const newData = projectObject && Object.entries(projectObject).map(([status, count]) => ({
    value: count,
    name: status.charAt(0).toUpperCase() + status.slice(1)
  }));

  const handleViewDetail = () => {
    setShowModal(true)
  }

  return (
    <>
      <Card className="h-100">
        <Card.Header className="border-200 border-bottom py-2">
          <Flex justifyContent="between" alignItems="center" alignContent="center">
            <h6>Status of Change Orders in this month</h6>
            <Button size="sm" variant="link" onClick={handleViewDetail}>Detail</Button>
          </Flex>
        </Card.Header>
        <Card.Body as={Row}>
          <Col md={5} xxl={12} className="mb-xxl-1">
            <div className="position-relative py-2">
              <ReactEChartsCore
                ref={chartRef}
                echarts={echarts}
                option={getOptions(newData)}
                style={{ height: '12.5rem' }}
              />
              <div className="position-absolute top-50 start-50 translate-middle text-center">
                <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
                  Total
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Included CO#00
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                        id="changeOrderStatusThisMonth"
                      />
                    </span>
                  </OverlayTrigger>
                </p>
                <p className="fs-3 mb-0 font-sans-serif fw-medium mt-n2">{totalChangeOrders}</p>
              </div>
            </div>
          </Col>
          <Col xxl={12} md={7}>
            <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
            {newData && newData.map((item, index) => (
              <Flex
                justifyContent="between"
                alignItems="center"
                className="border-bottom py-3"
                key={index}
              >
                <Flex>
                  <h6 className="text-700 mb-0">{item.name}</h6>
                </Flex>
                <h6 className="text-700 mb-0 fw-normal">{item.value}</h6>
              </Flex>
            ))}
          </Col>
        </Card.Body>
      </Card>

      {showModal && (
        <DetailViewModal
          showModal={showModal}
          setshowModal={setShowModal}
          data={data}
          title="Summary Status of Change order in this month"
        />
      )}
    </>
  );
};

SummaryStatusChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default SummaryStatusChart;
