import { Row, Col } from "react-bootstrap";
import LostQuotationItem from "./LostQuotationItem";
import LostJobItem from "./LostJobItem";
import React from 'react';
import PropTypes from'prop-types';

const LostSummary = ({data={}}) => {
  const {quotations, jobs} = data;

  return (
    <Row className="g-3 mb-3">
      <Col>
        <LostQuotationItem data={quotations} title="Quotations" />
      </Col>
      <Col>
        <LostJobItem data={jobs} title="Jobs" />
      </Col>
    </Row>
  );
};

LostSummary.propTypes = {
  data: PropTypes.object.isRequired
};

export default LostSummary;