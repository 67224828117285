import React, {lazy, Suspense} from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { MdCheck, MdLeaderboard, MdNewReleases, MdOnlinePrediction, MdListAlt } from 'react-icons/md';
import LeadHeader from './LeadHeader';

const AllList = lazy(() => import('./list/All'));
const MqlList = lazy(() => import('./list/Mql'));
const SqlList = lazy(() => import('./list/Sql'));
const CreatedThisMonthList = lazy(() => import('./list/CreatedThisMonth'));
const OpportunityList = lazy(() => import('./list/Opportunity'));

const Leads = () => {
  const { loading } = useSelector((state) => state.crm_lead)

  return (
    <>
      <LeadHeader />
      
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="lead-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom fs--1">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-created-this-month"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdNewReleases className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Created this month</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-mql"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdCheck className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> MQL</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-sql"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdLeaderboard className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> SQL</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-opportunity"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOnlinePrediction className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Opportunity</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="lead-all">
                      <AllList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="lead-created-this-month">
                      <CreatedThisMonthList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="lead-mql">
                      <MqlList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="lead-sql">
                      <SqlList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="lead-opportunity">
                      <OpportunityList />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Leads