import ChangeOrderDetailHeader from "./detail/ChangeOrderDetailHeader";
import { Card, Nav, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SimpleBarReact from 'simplebar-react';
import { MdRateReview } from "react-icons/md";
import { FaCentercode, FaCodeBranch, FaHistory, FaWpforms } from "react-icons/fa";
import ChangeOrderDetailForm from "./detail/ChangeOrderDetailForm";
import ChangeOrderDetailEnterJob from "./detail/ChangeOrderDetailEnterJob";
import ChangeOrderDetailInputReview from "./detail/ChangeOrderDetailInputReview";
import ChangeOrderActivity from "./detail/ChangeOrderActivity";
import { getChangeOrder } from "redux/crm/project/changeOrderSlice";
import WorkflowChart from "./WorkflowChart";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ChangeOrderDetail = () => {
  const {changeOrderData: changeOrder} = useSelector(state => state.crm_change_order);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChangeOrder({
      projectId: params.project_id,
      buildingId: params.building_id,
      changeOrderId: params.id}
    ))
  }, [params.project_id, params.building_id, params.id, dispatch]);

  const isNotCo00 = changeOrder?.co !== "00";

  return (
    <>
      <ChangeOrderDetailHeader changeOrder={changeOrder}/>

      <Card>
        <Tab.Container defaultActiveKey="co-enterJob">
          <Card.Header className="p-0 bg-light overflow-hidden">
            <SimpleBarReact>
              <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                {isNotCo00 && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey="co-form"
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FaWpforms className="text-600 tab-icon" />
                      <h6 className="text-600 mb-0 ms-1">Change Order Form</h6>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    eventKey="co-enterJob"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaCentercode className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Enter job</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="co-inputReview"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <MdRateReview className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Input review</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="co-workflow"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaCodeBranch className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Workflow</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="co-activity-log"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaHistory className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Activities</h6>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </SimpleBarReact>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              {isNotCo00 && (
                <Tab.Pane eventKey="co-form">
                  <ChangeOrderDetailForm changeOrder={changeOrder} />
                </Tab.Pane>
              )}
              <Tab.Pane eventKey="co-enterJob">
                <ChangeOrderDetailEnterJob changeOrder={changeOrder} />
              </Tab.Pane>
              <Tab.Pane eventKey="co-inputReview">
                <ChangeOrderDetailInputReview changeOrder={changeOrder} />
              </Tab.Pane>
              <Tab.Pane eventKey="co-workflow">
                <WorkflowChart />
              </Tab.Pane>
              <Tab.Pane eventKey="co-activity-log">
                <ChangeOrderActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  )
};

export default ChangeOrderDetail;