import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const faqUrl = "/documentation/faqs"

export const getFaqs = createAsyncThunk(
  "Faq/getFaqs",
  async (params) => {
    try {
      const response = await apiAxios.get(faqUrl, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getFaq = createAsyncThunk(
  "Faq/getFaq",
  async (faqId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${faqUrl}/${faqId}`)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createFaq = createAsyncThunk(
  "Faq/createFaq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(faqUrl, data)
      const {error } = response.data

      if (error) {
        toast.error(error)
        return null
      }
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateFaq = createAsyncThunk(
  "Faq/updateFaq",
  async ({ faqId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${faqUrl}/${faqId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteFaq = createAsyncThunk(
  "Faq/deleteWaiver",
  async (faqId) => {
    try {
      const response = await apiAxios.delete(`${faqUrl}/${faqId}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getCategory = createAsyncThunk(
  "Faq/getCategory",
  async (params) => {
    try {
      const response = await apiAxios.get(`${faqUrl}/category`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const faq = data.attributes
    
    if (faq !== null) {
      state.faqData = faq

      switch (type) {
        case "create": 
          state.data.unshift(faq)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== faq.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex((item) => item.id === faq.id)
          if (updatedIndex !== -1) {
            state.data[updatedIndex] = faq
          }

          break;
        }
      }
    }
    toast.success(message);
  }
};

const faqSlice = createSlice({
  name: "documentation_faq",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 10,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 10},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    faqData: {},
    categoryData: []
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 10}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaq.fulfilled, (state, action) => {
        state.crm_waiverData = action.payload
      })
      .addCase(getFaqs.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate = state.paginate
        const calRecords      = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categoryData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams
} = faqSlice.actions;

export default faqSlice.reducer;