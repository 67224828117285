import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { 
  getAllDrivers,
  setCurrentParams,
  setActiveType,
  setCurrentPage,
  setFilterValue
} from "redux/booking/car/driverSlice";

const DriverFilter = () => {
  const {pageSize, activeType, filterValue} = useSelector((state) => state.booking_setting_driver)
  const [value, setValue] = useState(filterValue)
  const dispatch = useDispatch()
  
  const handleSubmit = (e) => {
    e.preventDefault()

    loadData(value, activeType)
  }

  const handleCheckBoxChange = (event) => {
    const valueChk = event.target.id
    console.log(valueChk)
    dispatch(setCurrentPage(1))
    dispatch(setActiveType(valueChk))

    loadData(filterValue, valueChk)
  }

  const loadData = (filterValue, active) => {
    const params = {q: filterValue, active: active, page: 1, per_page: pageSize}
    
    dispatch(setCurrentParams(params))
    dispatch(getAllDrivers(params))
  }

  const handleInputChange = (newValue) => {
    setValue(newValue)
    setFilterValue(newValue)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="my-2">
        <Col sm="8">
          <span className="me-2 fs--1">Condition: </span>
          <Form.Check
            inline
            type='radio'
            id='all'
            label='All'
            name='inline-radio'
            checked={activeType === 'all'}
            onChange={handleCheckBoxChange}
          />
          <Form.Check
            inline 
            type='radio'
            id='active'
            label='Active'
            name='inline-radio'
            checked={activeType === 'active'}
            onChange={handleCheckBoxChange}
          />
          <Form.Check
            inline 
            type='radio'
            id='disabled'
            label='Disabled'
            name='inline-radio'
            checked={activeType === 'disabled'}
            onChange={handleCheckBoxChange}
          />
        </Col>
        <Col sm="4" className="text-end">
          <InputGroup>
            <Form.Control
              type="text"
              name="q"
              placeholder="Type input and search here..."
              className="fs--1"
              value={value}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <Button
              size="sm"
              type="submit"
              variant="primary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon="search" /> Search
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default DriverFilter;