import React, { useState, useEffect } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import * as d3 from 'd3';
import { getColor, rgbaColor } from 'helpers/utils';
import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

const width = 500;
const height = 420;
const fontSize = 0.7; //rem

const ForceGraph = ({ nodes }) => {
  const [animatedNodes, setAnimatedNodes] = useState([]);

  const radiusScale = value => {
    const fx = d3
      .scaleSqrt()
      .range([15, 55])
      .domain([
        0.95 *
          d3.min(nodes, item => {
            return item.v;
          }),
        1.05 *
          d3.max(nodes, item => {
            return item.v;
          })
      ]);
    return fx(value);
  };

  // Tooltip
  const showTooltip = function (e, item) {
    e.target.classList.add('opacity-75');
    d3.select('.d3-tooltip').transition().duration(200);
    d3.select('.d3-tooltip')
      .style('opacity', 1)
      .style('border', `1px solid ${item.color}`)
      .text(item.name + ': $' + item.v.toLocaleString() + ' (' + item.w.toLocaleString() +  ' M.T)')
      .style('left', e.clientX - 40 + 'px')
      .style('top', e.clientY - 40 + 'px');
  };

  const moveTooltip = function (e) {
    e.target.classList.add('opacity-75');
    d3.select('.d3-tooltip')
      .style('opacity', 1)
      .style('left', e.clientX - 40 + 'px')
      .style('top', e.clientY - 40 + 'px');
  };

  const hideTooltip = function (e) {
    e.target.classList.remove('opacity-75');
    d3.select('.d3-tooltip').transition().duration(200).style('opacity', 0);
  };

  useEffect(() => {
    const simulation = d3
      .forceSimulation()
      .velocityDecay(0.7)
      .force('x', d3.forceX().strength(0.05))
      .force('y', d3.forceY().strength(0.05))
      .force(
        'collide',
        d3.forceCollide(d => radiusScale(d.v) + 4)
      );

    simulation.on('tick', () => {
      setAnimatedNodes([...simulation.nodes()]);
    });

    simulation.nodes([...nodes]);

    simulation.alpha(0.01).restart();

    return () => simulation.stop();
  }, [nodes]);

  return (
    <>
      {animatedNodes.map((node, index) => (
        <g
          key={index}
          textAnchor="middle"
          transform={`translate(${width / 2 + node.x}, ${height / 2 + node.y})`}
          onMouseOver={e => showTooltip(e, node)}
          onMouseMove={e => moveTooltip(e)}
          onMouseLeave={e => hideTooltip(e)}
        >
          <circle r={radiusScale(node.v)} fill={node.color} strokeWidth="0" />
          <text
            dy="4"
            fill="#fff"
            textAnchor="middle"
            fontSize={`${fontSize}rem`}
            fontWeight="normal"
            style={{ pointerEvents: 'none' }}
          >
            {node.name?.substring(0, 5)}
          </text>
        </g>
      ))}
    </>
  );
};

ForceGraph.propTypes = {
  nodes: PropTypes.array
}

const BubbleChart = ({ data }) => {
  return (
    <div className="position-relative w-100" style={{ height: '28rem' }}>
      <div className="d3-tooltip"></div>
      <svg
        className="packed-bubble-svg h-100 w-100"
        viewBox={'0 0 ' + width + ' ' + height}
      >
        <ForceGraph nodes={data} />
      </svg>
    </div>
  );
};

BubbleChart.propTypes = {
  data: PropTypes.array
}

const ContractTopRevenueProject = ({data=[]}) => {
 // let newData = data && Object.entries(data).sort(([,a],[,b]) => b - a);
  const newData = data.map(item => ({
    name: item.project_name,
    v: parseFloat(item.contract_value),
    w: parseFloat(item.contract_weight),
    color: rgbaColor(getColor('primary'), Math.random().toFixed(2))
  }));

  return (
    <Card className="mb-3 h-100">
      <FalconCardHeader
        light
        title="Top revenue-generating projects"
        titleTag="h5"
        className="py-2"
      />
      <Card.Body className="py-2 d-flex flex-center position-relative">
        <BubbleChart data={newData} />
      </Card.Body>
      <Card.Footer className="text-end my-0 fs--1">
        <div className="text-warning"><FaInfoCircle /> Revenue-generating projects as per the actual signed contract.</div>
      </Card.Footer>
    </Card>
  );
};

ContractTopRevenueProject.propTypes = {
  data: PropTypes.array
}


export default ContractTopRevenueProject;
