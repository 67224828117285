import ContractCountry from "components/crm/reports/contract/ContractCountry";
import ContractGeneral from "components/crm/reports/contract/ContractGeneral";
import ContractMonthly from "components/crm/reports/contract/ContractMonthly";
import ContractProvince from "components/crm/reports/contract/ContractProvince";
import ContractRatioOfSales from "components/crm/reports/contract/ContractRatioOfSales";
import ContractDataView from "./ContractDataView";
import React from 'react';
import PropTypes from 'prop-types';

const Contract = ({contractData={}}) => {
  const {contract = {}} = contractData;
  const {current_period, previous_period, monthly, sales_regions, type_of_sales, pj_countries, countries, data=[]} = contract;

  return (
    <>
      <ContractGeneral contractData={{current_period, previous_period, data}} />
      <ContractMonthly data={monthly} />
      <ContractRatioOfSales data={{sales_regions, type_of_sales}} />
      <ContractProvince originalData={{data, pj_countries}} />
      <ContractCountry data={countries} />
      <ContractDataView data={data} />
    </>
  )
};

Contract.propTypes = {
  contractData: PropTypes.object.isRequired
}

export default Contract;