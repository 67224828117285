import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login, verifyRecaptcha } from 'redux/authSlice';
import { getProfileUser } from 'redux/profileUserSlice';
import ReCAPTCHA from 'react-google-recaptcha'
import PrivacyPolicy from './PrivacyPolicy';

const LoginFormOversea = ({ hasLabel }) => {
  const recaptcha = useRef();
  const [recapchaMessage, setRecapchaMessage] = useState("");

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const from = location.state?.from || "/";

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Get the reCAPTCHA value
    const captchaValue = recaptcha.current.getValue();
  
    if (!captchaValue) {
      setRecapchaMessage('Please verify the reCAPTCHA!');
      return;
    }
  
    try {
      // Verify reCAPTCHA with backend
      const recaptchaResponse = await dispatch(verifyRecaptcha(captchaValue));
  
      if (!recaptchaResponse.payload.success) {
        setRecapchaMessage('Token is invalid or expired!');
        toast.error('reCAPTCHA verification failed. Please try again.');
        return;
      }
  
      setRecapchaMessage('');
  
      // Attempt login
      const loginResponse = await dispatch(
        login({
          user: {
            email: formData.email,
            password: formData.password,
          },
        })
      );
  
      // Fetch profile after successful login
      if (loginResponse.meta.requestStatus === 'fulfilled') {
        await dispatch(getProfileUser());
        navigate(from, { replace: true });

      } else {
        toast.error('Login failed. Please check your credentials.')
        //throw new Error();
      }
    } catch (error) {
      // General error handling
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'An error occurred while signing in.');
      } else {
        toast.error('An error occurred while signing in.');
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRecapchaChange = () => {
    setRecapchaMessage("");
  }

  const hasValue = !formData.email || !formData.password;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        {!hasValue && (
          <div className="p-2 justify-content-center flex-column align-items-center">
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecapchaChange}
            />
            <div className="small text-danger">{recapchaMessage}</div>
          </div>
        )}

        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={hasValue}
        >
          Log in
        </Button>
      </Form.Group>

      <PrivacyPolicy />
    </Form>
  );
};

LoginFormOversea.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginFormOversea.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginFormOversea;
