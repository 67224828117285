import { useDispatch } from "react-redux"
import { Form, Modal, Button, Row, Col } from "react-bootstrap"
import { toast } from "react-toastify"
import { updateFeed } from "redux/social/feedSlice"
import TinymceEditor from "components/common/TinymceEditor";
import { useForm, Controller } from "react-hook-form"
import React, { useEffect } from "react";
import PropTypes from 'prop-types';

const EditPostForm = ({showModal, setshowModal, feed }) => {
  const {control, handleSubmit, formState: {errors}, setValue } = useForm()

  const dispatch = useDispatch();

  useEffect(() => {
    if (feed) {
      setValue("content", feed?.content?.status);

      // Object.entries(feed).forEach(([key, value]) => {
      //   setValue(key, value)
      // })
    }
  }, [feed])

  const closeModal = () => {
    setshowModal(false);
  }

  const onSubmit = async (data) => {
    console.log(data.content)

    try {
      const newData = {
        content: data.content,
        // location: 'Ho Chi Minh',
        // privacy,
        // hashtags: hashtags,
        // gallery: selectedFiles, 
      };

      await dispatch(updateFeed({feedId: feed.id, data: newData}));
      closeModal();
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
      size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Edit Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group as={Row} className="mb-2" controlId="content">
            <Form.Label column sm={2} className="text-lg-end">
              Content
            </Form.Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="content"
                rules={{ required: "Content must be required." }}
                render={({ field }) => (
                  <TinymceEditor
                    height="50vh"
                    handleChange={field.onChange}
                    value={field.value}
                    id="content-editor"
                  />
                )}
              />
              <span className="text-danger">
                {errors.content && errors.content.message}
              </span>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="primary">Update</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

EditPostForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired
}

export default EditPostForm;