import React, { useCallback, useState, lazy, Suspense, useEffect } from 'react';
import AgPagination from "components/ag-grid/AgPagination";
import { Card, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { MdNewReleases } from "react-icons/md";
import { useSelector } from "react-redux";
import { getChangeLogs } from "redux/documentation/changeLogSlice";
import { useDispatch } from 'react-redux';
import { debounce } from 'helpers/debounce';
import { FaSearch } from 'react-icons/fa';
import Logs from './Logs';
import {setPageSize, setCurrentPage} from "redux/documentation/changeLogSlice";

const LazyChangeLogForm = lazy(() => import('./ChangeLogForm'));
const LazyChangeLogDeleteConfirmation = lazy(() => import('./ChangeLogDeleteConfirmation'));
const LazyChangeLogPublishConfirmation = lazy(() => import('./ChangeLogPublishConfirmation'));

const ChangeLog = () => {
  const [filterValue, setFilterValue] = useState('');
  const [showChangeLogForm, setShowChangeLogForm] = useState(false);
  const [title, setTitle] = useState(null);
  const [changeLog, setChangeLog] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);

  const {paginate, currentParams, data} = useSelector(state => state.documentation_change_log);
  const {permissions} = useSelector(state => state.profile_user.data);
  
  const { v306 } = permissions || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChangeLogs(currentParams));
  }, []);

  const handleNewRelease = () => {
    setShowChangeLogForm(true)
    setTitle("New Change Log")
    setChangeLog(null)
  }

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = { ...currentParams, title_cont: value };
      dispatch(getChangeLogs(newParams));
    }, 500),
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target
    setFilterValue(value)
    handleChangeDebounced(value)
  }

  const handlePageChange = ((page, pageSize) => {
    setPageSize(pageSize)
    setCurrentPage(page)
  })

  const handleEditLog = (log) => {
    setShowChangeLogForm(true)
    setTitle("Edit Change log")
    setChangeLog(log)
  }

  const handleDeleteLog = (log) => {
    setShowDeleteConfirmation(true)
    setChangeLog(log)
  }

  const handlePublishLog = (log) => {
    setShowPublishConfirmation(true)
    setChangeLog(log)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="position-relative">
          <h4 className="mb-0 text-primary"><MdNewReleases className="text-warning" /> Change logs</h4>
        </Card.Body>
      </Card>

      <div className="my-2">
        <Row>
          <Col md={{span: 4, offset: 8}}>
            <InputGroup>
              <Form.Control placeholder="Search..." onChange={handleChange} />
              <Button size="sm" type="submit" className="me-1">
                <FaSearch className="me-1" />
              </Button>
              {v306 && (
                <Button size="sm" variant="warning" onClick={handleNewRelease}>
                  <MdNewReleases className="me-1" /> New Change log
                </Button>
              )}
            </InputGroup>
          </Col>
        </Row>
      </div>

      {data.map((log, index) => (
        <Logs
          log={log}
          index={index}
          key={index}
          editLog={() => handleEditLog(log)}
          deleteLog={() => handleDeleteLog(log)}
          publishLog={() => handlePublishLog(log)}
        />
      ))}

      <Row className="m-3">
        <Col>
          <AgPagination
            totalRows={paginate.totalRows}
            totalPagesCount={paginate.totalPagesCount}
            pageChange={handlePageChange}
            pageSize={paginate.pageSize}
            from={paginate.fromRecord}
            to={paginate.toRecord}
            //itemsPages={["10", "20", "50"]}
          />
        </Col>
      </Row>

      <Suspense fallback={<div>Loading...</div>}>
        {showChangeLogForm && (
          <LazyChangeLogForm
            showModal={showChangeLogForm}
            setshowModal={setShowChangeLogForm}
            title={title}
            changeLog={changeLog}
          />
        )}

        {showDeleteConfirmation && (
          <LazyChangeLogDeleteConfirmation
            showModal={showDeleteConfirmation}
            setshowModal={setShowDeleteConfirmation}
            changeLog={changeLog}
          />
        )}

        {showPublishConfirmation && (
          <LazyChangeLogPublishConfirmation
            showModal={showPublishConfirmation}
            setshowModal={setShowPublishConfirmation}
            changeLog={changeLog}
          />
        )}
      </Suspense>
    </>
  )
}

export default ChangeLog;