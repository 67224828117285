import { Form, Offcanvas, Row, Col, Button } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IconAlert from "components/common/IconAlert";
import { getExchangeRate } from "redux/settingsSlice";
import { useDispatch } from "react-redux";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";
import BookingUploadAttachment from "../../BookingUploadAttachment";
import { createBookingShuttle, updateBookingShuttle } from "redux/booking/travel/travelBookingSlice";
import PropTypes from 'prop-types';
import TinymceEditor from "components/common/TinymceEditor";

const OtherForm = ({showModal, setshowModal, booking, record}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, reset } = useForm()
  const {usdExchangeRateData} = useSelector((state) => state.settings)
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch()
  const showExchangeRate = booking.currency == "USD"
  const bookingId = booking.id
  const title = record ? "Edit" : "Create"
 
  useEffect(() => {
    if (!record) {
      reset()
    }
  }, [record])

  useEffect(() => {
    //get exchange rate
    if (showExchangeRate) {
      dispatch(getExchangeRate())
    }
  }, [showExchangeRate])

  useEffect(() => {
    setValue('name', "Visa")
    setValue('exchange_rate', usdExchangeRateData)
    setValue('currency', booking.currency)

    if(record) {
      Object.entries(record).forEach(([key, value]) => {
        setValue(key, value)
      })
    }

  }, [[setValue, booking, record]])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleClose = () => {
    closeModal()
  }

  const onSubmit = async (data) => {
    console.log(data)

    if (files) {
      data = {...data, other: true, files}
    }

    try {
      let shuttleId = ""

      if (record) {
        shuttleId = record.id
        dispatch(updateBookingShuttle({bookingId, shuttleId, data }))
      }
      else {
        dispatch(createBookingShuttle({ bookingId, data }))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  return (
    <Offcanvas
      show={showModal}
      onHide={handleClose}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '50%'}}
    >
      <Offcanvas.Header className="bg-light" closeButton>
        <Offcanvas.Title>
          {title} Other booking <FontAwesomeIcon icon={faCcVisa} className="text-primary"/>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        
        <IconAlert variant="warning" className="mt-2">
          <p className="mb-0">Create a booking for other purposes, such as a visa, for the trip</p>
        </IconAlert>

        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="name"
                placeholder="Name"
                className="fs--1"
                {...register("name", {
                  required: "Name must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="description">
            <Form.Label column sm={3} className="text-lg-end">
              Description
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={5}
                name="description"
                placeholder="Description"
                className="fs--1"
                {...register("description", {
                  required: "Description must be required."
                })}
              />
              <span className="text-danger">
                {errors.description && errors.description.message}
              </span>
            </Col>
          </Form.Group>

          <hr />
          <Form.Group as={Row} className="mb-2 mt-4" controlId="amount_estimate_currency">
            <Form.Label column sm={3} className="text-lg-end">
              Amount estimate currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="amount_estimate_currency"
                placeholder="Amount estimate currency"
                className="fs--1"
                {...register("amount_estimate_currency", {
                  required: "Amount estimate currency must be required."
                })}
              />
              <span className="text-danger">
                {errors.amount_estimate_currency && errors.amount_estimate_currency.message}
              </span>
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Expense estimate.</div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="amount_currency">
            <Form.Label column sm={3} className="text-lg-end">
              Amount currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="amount_currency"
                placeholder="Amount currency"
                className="fs--1"
                {...register("amount_currency")}
              />
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Actual expense.</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="currency">
            <Form.Label column sm={3} className="text-lg-end">
              Currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="currency"
                placeholder="Currency"
                className="fs--1"
                disabled
                {...register("currency", {
                  required: "Currency must be required."
                })}
              />
              <span className="text-danger">
                {errors.currency && errors.currency.message}
              </span>
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Currency in accordance with the trip's currency.</div>
            </Col>
          </Form.Group>

          {showExchangeRate && (
            <Form.Group as={Row} className="mb-2" controlId="exchange_rate">
              <Form.Label column sm={3} className="text-lg-end">
                Exchange rate
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="exchange_rate"
                  placeholder="Exchange rate"
                  className="fs--1"
                  {...register('exchange_rate', {
                    required: 'Exchange rate must be required'
                  })}
                />
                <span className="text-danger">
                  {errors.exchange_rate && errors.exchange_rate.message}
                </span>
                <IconAlert variant="info" className="mt-2">
                  <p className="mb-0">USD exchange rate to VND from <a href="https://www.vietcombank.com.vn/KHCN/Cong-cu-tien-ich/Ty-gia?devicechannel=default" target="_blank" rel="noreferrer">Vietcombank</a>.</p>
                </IconAlert>
              </Col>
            </Form.Group>
          )}
    
          <Form.Group as={Row} className="mb-2" controlId="notes">
            <Form.Label column sm={3} className="text-lg-end">
              Note
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <TinymceEditor
                    height="30vh"
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="attachment">    
              <Form.Label column sm={3} className="text-lg-end">
                Attachments
              </Form.Label>
              <Col sm={9} md={7}>
                <BookingUploadAttachment files={files} setFiles={setFiles} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col className="my-4">
                <Flex justifyContent="center">
                  <Button size="sm" type="submit">Save</Button>
                  <Button variant="danger" size="sm" className="ms-2" onClick={handleClose}>Cancel</Button>
                </Flex>
              </Col>
            </Form.Group>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

OtherForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
}

export default OtherForm;