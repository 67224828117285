import React from "react";
import ApplicationForm from "./ApplicationForm";

const NewApplication = () => {

  return (
    <ApplicationForm title="New Application" />
  )
};

export default NewApplication;