import { Card, Nav, Tab } from "react-bootstrap";
import {useParams} from "react-router-dom";
import { CRM_PIF_ISO } from "app_config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleBar from "simplebar-react";
import { useDispatch } from "react-redux";
import { getPIFVersion } from "redux/crm/project/pifSlice";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import DetailGDS from "../pif/detail/DetailGDS";
import DetailAreas from "../pif/detail/DetailAreas";
import PIFVersionHeader from "./PIFVersionHeader";

const PIFVersionDetail = () => {
  const params = useParams();
  const projectId = params?.project_id;
  const buildingId = params?.building_id;
  const adaptationId = params?.change_order_id;
  const pifId = params?.id;

  const dispatch = useDispatch();
  const {pifData: pif} = useSelector(state => state.crm_project_pif);
  const {project, building, adaptation} = pif || {};

  useEffect(() => {
    dispatch(getPIFVersion({projectId, buildingId, adaptationId, pifId}));
  }, [params]);

  return (
    <>
      <PIFVersionHeader
        project={project}
        building={building}
        adaptation={adaptation}
        pif={pif}
      />

      <Card>
        <Tab.Container defaultActiveKey="PIF-GDS">
          <SimpleBar>
            <Card.Header className="p-0 border-bottom">
              <Nav className="nav-tabs border-0 flex-nowrap tab-pif-form">
                <Nav.Item>
                  <Nav.Link
                    eventKey="PIF-GDS"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FontAwesomeIcon
                      icon="list"
                      className="text-600 tab-icon"
                    />
                    <h6 className="text-600 mb-0 ms-1"> General Data Sheet (GDS)</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="PIF-Area"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FontAwesomeIcon
                      icon="calendar"
                      className="text-600 tab-icon"
                    />
                    <h6 className="text-600 mb-0 ms-1"> Areas</h6>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link
                    eventKey="PIF-Activities"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FontAwesomeIcon
                      icon="history"
                      className="text-600 tab-icon"
                    />
                    <h6 className="text-600 mb-0 ms-1"> Activities</h6>
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Card.Header>
          </SimpleBar>
          <Card.Body className="bg-light">
            <Tab.Content>
                <Tab.Pane eventKey="PIF-GDS">
                  <DetailGDS pif={pif} />
                </Tab.Pane>
                <Tab.Pane eventKey="PIF-Area">
                  <DetailAreas data={pif} />
                </Tab.Pane>
              {/* <Tab.Pane eventKey="PIF-Activities">
                <PIFActivities data={pif} />
              </Tab.Pane> */}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>

        <div className="fs--1 m-2 text-end">{CRM_PIF_ISO}</div>
      </Card>
    </>
  )
}

export default PIFVersionDetail;