import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PositionGroupForm from './PositionGroupForm';
import { deletePositionGroup } from 'redux/organization/positionGroupSlice';
import PropTypes from "prop-types";

const PositionGroupHeader = ({selected}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showFormPositionGroup, setShowFormPositionGroup] = useState(false);
  const [title, setTitle] = useState("");
  const [record, setRecord] = useState(null);

  // useEffect(() => {
  //   setRecord(selected[0])
  // }, [selected]);

  const dispatch = useDispatch()

  const showForm = () => {
    setShowFormPositionGroup(true)
  }

  const handleNewPositionGroup = () => {
    setTitle("New Position group");
    showForm();
    setRecord(null);
  }

  const handleEditPositionGroup = () => {
    if (!validateSiteSelected()) return
    if (!validateSiteSelectedMultiple()) return

    setTitle("Edit Position group");
    showForm()
    setRecord(selected[0])
  }

  const handleDeleteSite = () => {
    if (!validateSiteSelected()) return
    if (!validateSiteSelectedMultiple()) return
    
    setShowConfirmationDelete(true)
    setRecord(selected[0])
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    dispatch(deletePositionGroup(record.id))
    setShowConfirmationDelete(false)
  }

  const validateSiteSelected = () => {
    if (selected.length === 0)
    {
      toast.error('Record has not selected yet.')
      return false
    }
    return true
  }

  const validateSiteSelectedMultiple = () => {
    if (selected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of record.')
      return false
    }
    return true
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Flex justifyContent={'end'}>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewPositionGroup}
            >
              <span className="d-none d-xl-inline-block ms-1">New Position group</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="edit"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleEditPositionGroup}
            >
              <span className="d-none d-xl-inline-block ms-1">Edit</span>
            </IconButton>

            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="text-danger" onClick={handleDeleteSite}>
                    <FontAwesomeIcon icon="trash-alt" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message="Are you sure to delete?"
        />
      )}
      
      {showFormPositionGroup && (
        <PositionGroupForm
          showModal={showFormPositionGroup}
          setshowModal={setShowFormPositionGroup}
          title={title}
          record={record}
        />
      )}
      
    </>
  );
};

PositionGroupHeader.propTypes = {
  selected: PropTypes.array.isRequired,
};

export default PositionGroupHeader;
