import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from "react-icons/fa";

const PrivacyPolicy = () => (
  <>
    <PageHeader
      title="Privacy policy"
      description="At PEB Portal, we are committed to protecting your privacy. This Privacy Policy explains how we use authentication services and handle any information you provide when accessing our platform."
      className="mb-3"
    />

    <Card className="mb-3">
      <Card.Body>
        <p className="fs-0 mb-2 fw-semi-bold">Content: </p>

        <strong>1. Authentication via Google</strong>
        <p>
          We use <strong>Google OAuth</strong> for authentication purposes only. When you log in to our service 
          using your Google account, we verify your identity through Google without storing or collecting 
          your account information, such as your email address or profile details.
        </p>

        <strong>2. Purpose of Authentication</strong>
        <p>
          The authentication process is solely used to grant you secure access to the Pebsteel Portal. 
          We do not collect, process, or share your Google account information for any other purpose.
        </p>

        <strong>3. No Data Storage</strong>
        <p>
          Pebsteel Portal does not store any personal information retrieved through Google OAuth. 
          All authentication details are processed securely via Google's OAuth services.
        </p>

        <strong>4. Data Protection</strong>
        <p>
          We prioritize your security and privacy. The authentication process uses encryption 
          and follows industry standards to ensure your information is protected. We do not share 
          your details with third parties.
        </p>

        <strong>5. Contact Us</strong>
        <p>
          If you have any questions about this Privacy Policy or how we handle your information, 
          please contact us at: <a href="mailto:portal@pebsteel.com.vn">portal@pebsteel.com.vn</a>
        </p>

        <div className="text-center mt-4 mb-3">
          <Link to="/" className="btn btn-primary"><FaHome /> Back to Home</Link>
        </div>
      </Card.Body>
    </Card>
  </>
);

export default PrivacyPolicy;
