import React, { useEffect } from 'react';
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { transferSiteOfficeBooking } from "redux/booking/car/carBookingSlice";
import IconAlert from 'components/common/IconAlert';
import { FaClone, FaBan } from "react-icons/fa";
import PropTypes from 'prop-types';
import { MdOutlineTransferWithinAStation } from 'react-icons/md';
import { getActiveSites } from 'redux/organization/siteSlice';
import { useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const TransferSiteOfficeConfirm = ({showModal, setshowModal, transaction}) => {
  const {control, handleSubmit, formState: {errors} } = useForm()
  const {activeData: siteData = []} = useSelector(state => state.organization_site);

  const dispatch = useDispatch();

  const siteOptions = siteData && siteData.filter(item => item.car_booking === true).map(item => ({value: item.id, label: item.name}));

  useEffect(() => {
    dispatch(getActiveSites());
  }, []);

  const onSubmit = (data) => {
    try {
      dispatch(transferSiteOfficeBooking({transactionId: transaction?.id, data}));
      closeModal();
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><FaClone /> Clone new car booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="warning">
              <p className="mb-0">Are you sure to transfer the {transaction.site_name} site for booking number {transaction.booking_number}?</p>
            </IconAlert>

            <Form.Group as={Row} className="mb-2" controlId="site_id">
              <Form.Label column sm={3} className="text-lg-end">
                To Site
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="site_id"
                  render={({field}) => (
                    <Select
                      closeMenuOnSelect={true}
                      options={siteOptions}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      {...field}
                      value={siteOptions.find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption.value)}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Site must be required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.site_id && errors.site_id.message}
                </span>
              </Col>
            </Form.Group>
            
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              variant="primary"
              type="submit"
            >
              <MdOutlineTransferWithinAStation /> Transfer
            </Button>
            <Button size="sm" variant="falcon-default" onClick={closeModal}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

TransferSiteOfficeConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired
}

export default TransferSiteOfficeConfirm;