import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PIFForm from "./PIFForm";
import { toast } from "react-toastify";

const NewPIF = () => {
  const location = useLocation();
  const state = location.state;

  const [project, setProject] = useState(null);
  const [building, setBuilding] = useState(null);
  const [adaptation, setAdaptation] = useState(null);

  useEffect(() => {
    try {
      if (state === null || Object.keys(state).length === 0) {
        throw new Error('The link is incorrect because project is empty. Please correct the source of the link in the system.');
      }

      setProject(state.project)
      setBuilding(state.building)
      setAdaptation(state.adaptation)
    }
    catch(error) {
      toast.error('An error occurred: ' + error.message);
    }

  }, [location])

  return (
    <PIFForm
      project={project}
      building={building}
      adaptation={adaptation}
      pif={null}
      title="New"
    />
  )
}

export default NewPIF;