import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import React from 'react';
import PropTypes from 'prop-types';
import { getActiveSalesUnits } from "redux/crm/salesUnitSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Select from "react-select";
import { changeSalesUnitProject } from "../../../../redux/crm/projectSlice";

const ChangeSalesUnitForm = ({showModal, setshowModal, project }) => {
  const {control, handleSubmit, formState: {errors}, setValue } = useForm();
  const {activeSalesUnitsData} = useSelector((state) => state.crm_sales_unit);
  const projectId = project?.id;

  const dispatch = useDispatch();
  //const navigate = useNavigate();
  const salesUnitOptions = activeSalesUnitsData && activeSalesUnitsData.map((item) => ({ value: item.id, label: item.description }))

  useEffect(() => {
    dispatch(getActiveSalesUnits());
  }, []);

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = (data) => {
    try {
      dispatch(changeSalesUnitProject({
        projectId: projectId,
        data
      }))

      closeModal()
      //navigate(`/crm/projects/${projectId}`)
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Change sales unit!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            All sales units for the project, building, job entry, change orders, and contracts will be updated to the new sales unit. Are you sure to change sales unit <strong>{project.sales_unit?.description}</strong>?
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="sales_unit_id">
            <Form.Label column sm={3} className="text-lg-end">
              To Sales unit
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="sales_unit_id"
                rules={{ required: { value: true, message: "Sales unit is required" } }} 
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={salesUnitOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => setValue('sales_unit_id', selectedOption.value, {shouldValidate: true})}
                  />
                )}
              />
              <span className="text-danger">
                {errors.sales_unit_id && errors.sales_unit_id.message}
              </span>
            </Col>
          </Form.Group>


        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit">Change</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

ChangeSalesUnitForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

export default ChangeSalesUnitForm;