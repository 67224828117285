import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createMarkup from 'helpers/createMarkup';
import { MdDelete, MdEdit } from 'react-icons/md';

const FaqItem = ({ faq, editFaq, deleteFaq, isFirst, isLast }) => {
  const {allow_edit, allow_delete} = faq?.permissions || {};

  return (
    <Card
      className={classNames('shadow-none', {
        'rounded-bottom-0': isFirst,
        'border-bottom': !isLast,
        'rounded-0': !isLast && !isFirst
      })}
    >
      <Accordion.Item eventKey={faq.id} className="border-0">
        <Card.Header className="p-0" id={`faqAccordionHeading${faq.id}`}>
          <Accordion.Button className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
            <div className="d-flex">
              <FontAwesomeIcon
                icon="caret-right"
                className="accordion-icon me-3 mt-1"
                transform="shrink-2"
              />
                <span className="fw-medium font-sans-serif text-900">
                  {faq.title}
                </span>
            </div>
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body className="p-0">
          <Card.Body className="pt-2">
            <div className="text-end">
              {allow_edit && allow_delete && (
                <>
                  <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={editFaq}
                    className="me-1"
                    title="Edit"
                  >
                    <MdEdit className="text-warning" />
                  </Button>
                  <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={deleteFaq}
                    title="Delete"
                  >
                    <MdDelete className="text-danger" />
                  </Button>
                </>
              )}
            </div>

            <div
              className="ps-3"
              dangerouslySetInnerHTML={createMarkup(faq.description)}
            />
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );
};

FaqItem.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  editFaq: PropTypes.func,
  deleteFaq: PropTypes.func,
  faq: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
};

export default FaqItem;
