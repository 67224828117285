import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import { removeABErectionDocument, removeEstimateReleasedDocument, removePPDDocument, setEnterJobActiveKey, uploadABErectionDocument, uploadEstimateReleasedDocument, uploadPPDDocument } from "redux/crm/project/changeOrderSlice";
import { useDispatch } from "react-redux";

import ABErection from "./enter_job/ABErection";
import GeneralInformation from "./enter_job/GeneralInformation";
import Estimate from "./enter_job/Estimate";
import ApprovalDrawing from "./enter_job/ApprovalDrawing";
import OtherDrawing from "./enter_job/OtherDrawing";
import ShopDrawing from "./enter_job/ShopDrawing";
import { useSelector } from "react-redux";
import React from 'react';
import PropTypes from 'prop-types';

const ChangeOrderDetailEnterJob = ({changeOrder}) => {
  const {enterJobActiveKey} = useSelector(state => state.crm_change_order);
  const { project, building } = changeOrder || {};

  const projectId = project?.id;
  const buildingId = building?.id;
  const changeOrderId = changeOrder?.id;
  const { allow_view_shop_erection, allow_view_ab_for_erection } = changeOrder?.permissions || {};
  const dispatch = useDispatch();

  const TYPE_PPD_RAD = "ppd-rad";
  const TYPE_ESTIMATE_RELEASED = "estimate-released";
  const TYPE_AB_ERECTION = "ab-erection";

  const handleAccordionSelect = (newActiveKey) => {
    dispatch(setEnterJobActiveKey(newActiveKey));
  }

  const handleUploadDocument = (data, documentType) => {
    try {
      const params = {projectId, buildingId, changeOrderId, data}

      switch (documentType) {
        case TYPE_PPD_RAD:
          dispatch(uploadPPDDocument(params))
          break
        case TYPE_ESTIMATE_RELEASED:
          dispatch(uploadEstimateReleasedDocument(params))
          break
        case TYPE_AB_ERECTION:
          dispatch(uploadABErectionDocument(params))
          break

        default:
          console.error(`Unhandled document_type: ${documentType}`)
          toast.error("An error occurred while removing the document.")
          break
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const removeDocument = (documentId, documentType) => {
    try {
      const params = {projectId, buildingId, changeOrderId, data: { document_id: documentId }}
      switch (documentType) {
        case TYPE_PPD_RAD:
          dispatch(removePPDDocument(params))
          break
        case TYPE_ESTIMATE_RELEASED:
          dispatch(removeEstimateReleasedDocument(params))
          break
        case TYPE_AB_ERECTION:
          dispatch(removeABErectionDocument(params))
          break

        default:
          console.error(`Unhandled document Type: ${documentType}`)
          toast.error("An error occurred while removing the document.")
          break
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  return (
    <>
      <Accordion
        id="co-form-detail"
        className="border rounded overflow-hidden fs--1"
        alwaysOpen
        flush
        activeKey={enterJobActiveKey}
        onSelect={handleAccordionSelect}
      >
        <Card className="rounded-0">

          {/* 1. General Information */}
          <Accordion.Item eventKey="0" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    1. General Information
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <GeneralInformation
                    project={project}
                    building={building}
                    changeOrder={changeOrder}
                    handleUploadDocument={handleUploadDocument}
                    removeDocument={removeDocument}
                  />
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 2. Re-Estimate and Release final Estimate Drawings */}
          <Accordion.Item eventKey="1" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    2. Re-Estimate and Release final Estimate Drawings
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Estimate
                    building={building}
                    changeOrder={changeOrder}
                    handleUploadDocument={handleUploadDocument}
                    removeDocument={removeDocument}
                  />
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 3. Approval Drawings (APD), A/B for Approval Drawings */}
          <Accordion.Item eventKey="2" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    3. Approval Drawings (APD), A/B for Approval Drawings
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <ApprovalDrawing
                    changeOrder={changeOrder}
                    handleUploadDocument={handleUploadDocument}
                    removeDocument={removeDocument}
                  />
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 4. Shop, Erection Drawings */}
          {allow_view_shop_erection && (
            <Accordion.Item eventKey="3" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      4. Shop, Erection Drawings
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <ShopDrawing
                      changeOrder={changeOrder}
                      handleUploadDocument={handleUploadDocument}
                      removeDocument={removeDocument}
                    />
                  </Card.Body>
                </Accordion.Body>
            </Accordion.Item>
          )}

          {/* 5. A/B for Erection Drawings */}
          {allow_view_ab_for_erection && (
            <Accordion.Item eventKey="4" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      5. A/B for Erection Drawings
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <ABErection
                      changeOrder={changeOrder}
                      handleUploadDocument={handleUploadDocument}
                      removeDocument={removeDocument}
                    />
                  </Card.Body>
                </Accordion.Body>
            </Accordion.Item>
           )}

          {/* 6. Others (Forecast estimate, Design calculation... */}
          <Accordion.Item eventKey="5" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    6. Others (Forecast estimate, Design calculation...)
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <OtherDrawing changeOrder={changeOrder} />
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>
        </Card>
      </Accordion>
    </>
  )
};

ChangeOrderDetailEnterJob.propTypes = {
  changeOrder: PropTypes.object.isRequired
}

export default ChangeOrderDetailEnterJob;