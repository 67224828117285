import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  title: {
    text: 'MQL Status'
  },
  toolbox: {
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {}
    }
  },
  color: [
    getColor('secondary'),
    getColor('primary'),
    getColor('info'),
    rgbaColor(getColor('warning'), 0.7),
    getColor('success'),
    getColor('danger')
  ],
  legend: {
    orient: 'vertical',
    left: 'right',
    top: 50,
    itemWidth: 12,
    itemHeight: 12,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    inactiveBorderColor: 'transparent',
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 12,
      fontFamily: 'Poppins',
      fontWeight: '500'
    }
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: "outside",
        color: getColor("primary"),
        formatter: '{b}: {d}%'  //'{b}: {c} ({d}%)' 
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

getOption.propTypes = {
  data: PropTypes.array.isRequired
};

const LeadMQLStatus = ({data=[]}) => {

  const groupedData = data && data.reduce((acc, item) => {
    const status = item.mql_status;
    acc[status] = (acc[status] || 0) + 1;

    return acc;
  }, {});

  const newData = Object.entries(groupedData).map(([name, value]) => ({name, value}));

  return (
    <Card className="h-100 border rounded">
      <Card.Body className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(newData)}
          className="w-100"
        />
      </Card.Body>
    </Card>
  )
}

LeadMQLStatus.propTypes = {
  data: PropTypes.array.isRequired
};

export default LeadMQLStatus;