import { useSelector } from "react-redux"

const useAuth = () => {
  let accessToken = localStorage.getItem('access_token')
  let expirationTime = localStorage.getItem('token_expiration')

  let {currentUser} = useSelector((state) => state.auth)
  let userName = ''

  if (currentUser && Object.keys(currentUser).length > 0)
    userName = currentUser.name

  // //token expired then call Logout route
  // if (accessToken && (expirationDate < Date.now() / 1000))
  // {
  //   localStorage.clear()
  //   //refirect to logout
  //   history.push("/authentication/login")
  // }
  const now = Math.floor(Date.now() / 1000);

  let isAuthenticated = (accessToken && (expirationTime > now)) || false
  return {isAuthenticated, userName}
}

export default useAuth