import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  title: {
    text: 'PCV Sales Funnel'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}'
  },
  toolbox: {
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {}
    }
  },
  color: [
    getColor("secondary"),
    getColor("primary"),
    getColor("warning"),
    getColor("success"),
    getColor("danger"),
  ],
  series: [
    {
      name: 'Sales Funnel',
      type: 'funnel',
      left: '10%',
      top: 60,
      bottom: 60,
      //width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'descending',
      //gap: 1,
      // label: {
      //   show: true,
      //   position: 'inside'
      // },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid'
        }
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1
      },
      emphasis: {
        label: {
          fontSize: 20
        }
      },
      data
    }
  ]
});

getOption.propTypes = {
  data: PropTypes.array.isRequired
}

const Funnel = ({data={}}) => {
  let newData = Object.entries(data).map(([name, values]) => ({
    name,
    value: values.number_of_projects
  }));

  const total = newData.reduce((acc, data) => acc + data.value, 0);
  newData = [{name: "PCV", value: total}, ...newData];

  return (
    <Card className="h-100 border rounded">
      <Card.Body className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(newData)}
          className="w-100"
        />
      </Card.Body>
    </Card>
  );
};

Funnel.propTypes = {
  data: PropTypes.array.isRequired
}

export default Funnel;