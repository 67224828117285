import { Row, Col } from 'react-bootstrap';
import React, { useMemo } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { ExportToExcel } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';

const LeadTable = ({data}) => {
  const leadUrl = "/crm/leads";

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'ID#',
      Cell: rawData => {
        const {id, lead_code} = rawData.row.original
        return (
          id && <a href={`${leadUrl}/${id}`} target='_blank' rel="noreferrer">{lead_code}</a>
        )
      }
    },
    {
      accessor: 'name',
      Header: 'Lead name',
      Cell: rawData => {
        const {id, name} = rawData.row.original
        return (
          id && <a href={`${leadUrl}/${id}`} target='_blank' rel="noreferrer">{name}</a>
        )
      }
    },
    {
      accessor: 'status.name',
      Header: 'Status'
    },
    {
      accessor: 'stage',
      Header: 'Stage'
    },
    {
      accessor: 'mql_status',
      Header: 'MQL status'
    },
    { 
      accessor: 'owner_by',
      Header: 'Owner'
    },
    { 
      accessor: 'project_name',
      Header: 'Project name'
    },
    { 
      accessor: 'sales_unit.description',
      Header: 'Sales unit'
    },
    {
      accessor: 'created_by',
      Header: 'Created by'
    },
    {
      accessor: 'created_date',
      Header: 'Created date'
    }
  ], []);

  if (!data) {
    return null
  }

  const handleExportLead = () => {
    ExportToExcel(leads, 'leads')
  }

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
        selection
        selectionColumnWidth={20}
      >
        <Row className='flex-end-center mb-3 g-1'>
          <Col xs='auto' sm={6} lg={4}>
            <AdvanceTableSearchBox table />
          </Col>
          <Col sm='auto'>
            <IconButton
              variant='falcon-default'
              size='sm'
              icon='external-link-alt'
              transform='shrink-3'
              title='Export'
              onClick={handleExportLead}
            ></IconButton>
          </Col>
        </Row>

        <AdvanceTable
          table
          headerClassName='bg-200 text-900 text-nowrap align-middle'
          rowClassName='align-middle white-space-nowrap'
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className='mt-3'> 
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </>
  )
};

LeadTable.propTypes = {
  data: PropTypes.array.isRequired
}

export default LeadTable;