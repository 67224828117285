import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useMemo } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { formatDate, formatNumber } from "helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { MdAttachFile } from "react-icons/md";
import PropTypes from 'prop-types';
import { FaVoteYea } from "react-icons/fa";

const ShopPhaseDetailTable = ({
  phase,
  data,
  onReleaseShop,
  editRevision,
  deleteRevision,
  removeShopDetailDocument
}) => {

  const columns = useMemo(() => [
    {
      Header: ' ',
      Cell: rowData => {
        const detail = rowData.row.original

        const {
          allow_edit,
          allow_delete
        } = detail?.permissions || {}

        return (
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                
                {allow_edit && (
                  <>
                    <Dropdown.Item onClick={() => onReleaseShop(phase, detail)} className="mb-1 text-primary">
                      <FaVoteYea /> Release to CSD
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                
                {allow_edit && (
                  <>
                    <Dropdown.Item onClick={() => editRevision(phase, detail)} className="mb-1">
                      <FontAwesomeIcon icon="edit" /> Edit
                    </Dropdown.Item>
                  </>
                )}

                {allow_delete && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-1 text-danger" onClick={() => deleteRevision(phase, detail)}>
                      <FontAwesomeIcon icon="trash-alt" /> Delete
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )
      },
      cellProps: {
        className: "fixed-column"
      }
    },
    {
      accessor: 'rev',
      Header: 'REV#',
      cellProps: {
        className: "text-primary text-center"
      }
    },
    {
      accessor: 'co',
      Header: 'CO#',
      cellProps: {
        className: "text-primary text-center"
      }
    },
    {
      accessor: 'bom_weight',
      Header: 'BOM (M.T)',
      Cell: ({row}) => formatNumber(row.original.bom_weight),
      cellProps: {
        className: "text-end text-primary"
      }
    },
    {
      accessor: 'shop_released',
      Header: 'Shop released',
      Cell: ({row}) => formatDate(row.original.shop_released)
    },
    {
      accessor: 'erc_released',
      Header: 'Erection released',
      Cell: ({row}) => formatDate(row.original.erc_released)
    },
    {
      accessor: 'detailer',
      Header: 'Detailer',
      cellProps: {
        className: "text-center"
      }
    },
    {
      accessor: 'checker',
      Header: 'Checker',
      cellProps: {
        className: "text-center"
      }
    },
    {
      Header: (
        <><MdAttachFile /> Documents</>
      ),
      accessor: "documents",
      Cell: rowData => {
        const detail = rowData.row.original
        const {
          allow_view_document,
          allow_remove_document,
          allow_download_document
        } = detail?.permissions

        return (
          <>
            {detail.documents.length > 0 && (
              <div className="pb-2 mt-3">
                {detail.documents.map(attachment => (
                  <DocumentAttachment
                    {...attachment}
                    key={attachment.id}
                    allowView={allow_view_document}
                    allowDownload={allow_download_document}
                    allowRemove={allow_remove_document}
                    isTable
                    handleRemoveAttachment={(documentId) => removeShopDetailDocument(phase.id, detail.id, documentId)}
                  />
                ))}
              </div>
            )}
          </>
        )
      }
    },
    {
      accessor: 'eng_notes',
      Header: 'ENG notes',
      cellProps: {
        className: "text-danger"
      }
    },
    {
      accessor: 'created_by',
      Header: 'Created by',
      cellProps: {
        className: "text-center"
      }
    },
    {
      accessor: 'created_date',
      Header: 'Created date',
      cellProps: {
        className: "text-center"
      }
    }
  ], []);

  return (
    <div className="my-3">
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-2 gx-2">
          <Col sm="3">
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3 mb-2">
          <AdvanceTablePagination
            table
          />
        </div>
      </AdvanceTableWrapper>
    </div>
  )
};

ShopPhaseDetailTable.propTypes = {
  phase: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  editRevision: PropTypes.func.isRequired,
  deleteRevision: PropTypes.func.isRequired,
  removeShopDetailDocument: PropTypes.func.isRequired
}

export default ShopPhaseDetailTable;