import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Flex from "components/common/Flex";
import AsyncSelect from 'react-select/async';
import { debounce, filterOptions } from "helpers/debounce";
import Select from 'react-select';
import { createEmployee, getPositionGroups, updateEmployee } from "redux/organization/employeeSlice";
import { EMPLOYEE_STAFF_POLICY } from "app_config";
import MaskedInput from "react-text-mask";
import { updateUserEmployee } from "redux/authorization/userSlice";
import { MdUpdate } from "react-icons/md";
import { FaBan } from "react-icons/fa";
import PropTypes from 'prop-types';
import apiAxios from "helpers/apiAxios";
import DatePicker from "react-datepicker";

const UserDetailUpdateEmployee = ({showModal, setShowModal, user}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm();
  const { positionGroups } = useSelector((state) => state.organization_employee);
  const dispatch = useDispatch();
  const {employee} = user || {};

  const defaultManagerOption = useMemo(() => {
    if (employee && employee.parent) {
      return { value: employee.parent.id, label: employee.parent.name }
    }
    return null
  }, [employee])

  const defaultDepartmentOption = useMemo(() => {
    if (employee && employee.department) {
      return { value: employee.department.id, label: employee.department.name }
    }
    return null
  }, [employee])

  const staffPolicyOptions = useMemo(() => 
    EMPLOYEE_STAFF_POLICY.map((option) => ({ value: option.toLocaleLowerCase(), label: option }))
  , [])

  useEffect(() => {
    if(employee) {
      Object.entries(employee).forEach(([key, value]) => {
        switch (key) {
          case "staff_policy":
            setValue(key, value?.toLowerCase());
            break;
          case "start_date":
            setValue(key, value ? new Date(value) : null);
            break;
          default:
            setValue(key, value);
            break;
        }
      });
    }
    else {
      setValue('name', user.name);
      setValue('email', user.email);
      setValue('site_id', user?.site_id);
    }
  }, [setValue, employee])

  useEffect(() => {
    dispatch(getPositionGroups());
  }, [dispatch]);

  const handleCancel = () => {
    setShowModal(false)
  }

  const debouncedFilterManager = debounce((inputValue, callback) => {
    filterOptions("/organization/employees/active_list", inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);

  const loadManagerOptions = (inputValue, callback) => {
    debouncedFilterManager(inputValue, callback);
  };

  const filterDepartmentOptions = async (url, inputValue) => {
    try {
      const response = await apiAxios.get(`${url}?page=1&per_page=30&name_cont=${inputValue}`);
      const { rows: data } = response.data;
  
      const options = data && data.map((item) => {
        return { value: item.attributes.id, label: item.attributes.name };
      });
  
      return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const debouncedFilterDepartments = debounce((inputValue, callback) => {
    let url = "/organization/departments"
    const siteId = employee?.site_id || user?.site_id;
    if (siteId) {
      url = `/organization/sites/${siteId}/departments`;
    }
  
    filterDepartmentOptions(url, inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);

  const loadDepartmentOptions = (inputValue, callback) => {
    debouncedFilterDepartments(inputValue, callback);
  };

  const onSubmit = async (data) => {
    console.log(data)
    try {
      let response;

      if (employee) {
        const employeeId = employee.id;
        response = await dispatch(updateEmployee({employeeId, data }))
      }
      else {
        response = await dispatch(createEmployee(data))
      }
     
      if (response && response.payload && response.payload.data && response.payload.data.attributes) {
        dispatch(updateUserEmployee(response.payload.data.attributes));
        setShowModal(false);
      } 
      else {
        toast.error("Failed to update or create employee: Invalid response structure.");
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const positionGroupOptions = positionGroups.map((item) => ({ value: item.id, label: item.name }));

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Update Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-2" controlId="name">
              <Form.Label column sm={3} className="text-lg-end">
                Name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Type 3 characters code of name"
                  className="fs--1"
                  {...register("name", {
                    required: "Name must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.name && errors.name.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="full_name">
              <Form.Label column sm={3} className="text-lg-end">
                Full of name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="full_name"
                  placeholder="Full of name"
                  className="fs--1"
                  {...register("full_name", {
                    required: "Full of name must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.full_name && errors.full_name.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="title">
              <Form.Label column sm={3} className="text-lg-end">
                Title
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="fs--1"
                  {...register("title", {
                    required: "Title must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.title && errors.title.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="email">
              <Form.Label column sm={3} className="text-lg-end">
                Email
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="fs--1"
                  {...register("email", {
                    required: "Email must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.email && errors.email.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="gender">
              <Form.Label column sm={3} className="text-lg-end">
                Gender
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  name="gender"
                  control={control}
                  defaultValue={employee?.gender}
                  render={({ field }) => (
                    <Flex direction={'row'}>
                      <Form.Check
                        type='radio'
                        id='gender-male'
                        label='Male'
                        name='gender'
                        value={true}
                        checked={field.value === false}
                        className="me-3"
                        onChange={(e) => field.onChange(false)}
                      />
                      <Form.Check
                        type='radio'
                        id='gender-female'
                        label='Female'
                        name='gender'
                        value={false}
                        checked={field.value === true}
                        onChange={(e) => field.onChange(true)}
                      />
                    </Flex>
                  )}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="phone_number">
              <Form.Label column sm={3} className="text-lg-end">
                Phone
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder="Phone number"
                  className="fs--1"
                  {...register("phone_number")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2 mt-4' controlId='start_date'>
              <Form.Label column sm={3} className='text-lg-end'>
                Start date
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      className="form-control fs--1"
                      placeholderText="DD-MM-YYYY"
                      dateFormat="dd-MM-yyyy"
                      fixedHeight
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="hr_code">
              <Form.Label column sm={3} className="text-lg-end">
                HR Code
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="hr_code"
                  placeholder="HR Code"
                  className="fs--1"
                  {...register("hr_code")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="birthday">
              <Form.Label column sm={3} className="text-lg-end">
                Birthday
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="birthday"
                  defaultValue={employee?.birthday}
                  render={({ field }) => (
                    <MaskedInput
                      mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      className="form-control fs--1"
                      guide={false}
                      placeholder="DD-MM-YYYY"
                      name="birthday"
                      {...field}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="is_sales">
              <Form.Label column sm={3} className="text-lg-end">
                Sales team
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="is_sales"
                  defaultValue={true}
                  render={({ field }) => (
                    <Form.Check
                      type="switch"
                      label="Sales team"
                      checked={field.value}
                      onChange={(e) => setValue("is_sales", e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="active">
              <Form.Label column sm={3} className="text-lg-end">
                Active
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="active"
                  defaultValue={true}
                  render={({ field }) => (
                    <Form.Check
                      type="switch"
                      label="Active"
                      checked={field.value}
                      onChange={(e) => setValue("active", e.target.checked)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <hr />

            <Form.Group as={Row} className="mb-2" controlId="parent_id">
              <Form.Label column sm={3} className="text-lg-end">
                Manager
              </Form.Label>
              <Col sm={9} md={7}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  defaultValue={defaultManagerOption}
                  loadOptions={loadManagerOptions}
                  closeMenuOnSelect={true}
                  placeholder='Select...'
                  classNamePrefix="react-select"
                  className="w-100"
                  name="parent_id"
                  onChange={(selectOptions) => setValue('parent_id', selectOptions.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="staff_policy">
              <Form.Label column sm={3} className="text-lg-end">
                Staff policy
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="staff_policy"
                  render={() => (
                    <Select
                      closeMenuOnSelect={true}
                      options={staffPolicyOptions}
                      defaultValue={staffPolicyOptions.find((option) => option.value === employee?.staff_policy?.toLocaleLowerCase())}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      onChange={selectedOption => setValue('staff_policy', selectedOption.value, {shouldValidate: true})}
                    />
                  )}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="position_group_id">
              <Form.Label column sm={3} className="text-lg-end">
                Position group
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="position_group_id"
                  render={({field}) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      options={positionGroupOptions}
                      value={positionGroupOptions.find(option => option.value === field.value) || null}
                      placeholder='Select...'
                      classNamePrefix='react-select'
                      onChange={selectedOption => setValue('position_group_id', selectedOption.value)}
                    />
                  )}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="department_id">
              <Form.Label column sm={3} className="text-lg-end">
                Department
              </Form.Label>
              <Col sm={9} md={7}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  defaultValue={defaultDepartmentOption}
                  loadOptions={loadDepartmentOptions}
                  closeMenuOnSelect={true}
                  placeholder='Select...'
                  classNamePrefix="react-select"
                  className="w-100"
                  name="department_id"
                  onChange={(selectOptions) => setValue('department_id', selectOptions.value)}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><MdUpdate /> Update</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
};

UserDetailUpdateEmployee.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired
};

export default UserDetailUpdateEmployee;