import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PropTypes from'prop-types';

const DocumentViewer = ({document_url}) => {
  const docs = [
    { uri: document_url },
  ];
  
  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      style={{minHeight: 500}}
      config={{
        header: {
          disableHeader: false,
          disableFileName: false,
          retainURLParams: false,
          //retainURLParams: true
        },
        csvDelimiter: ",", // "," as default,
        pdfZoom: {
          defaultZoom: 1.1, // 1 as default,
          zoomJump: 0.2, // 0.1 as default,
        },
        pdfVerticalScrollByDefault: true, // false as default
        requestHeaders: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, HEAD',
          'Access-Control-Allow-Headers': '*'
        }
      }}
    />
  )
};

DocumentViewer.propTypes = {
  document_url: PropTypes.string.isRequired
}

export default DocumentViewer;