import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  createInitialState,
  handlePayload
} from 'helpers/reducerHelpers';

const userUrl = "/authorization/users"

export const getAllUsers = createAsyncThunk(
  "User/getAllUsers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${userUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getUser = createAsyncThunk(
  "User/getUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${userUrl}/${userId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createUser = createAsyncThunk(
  "User/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(userUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateUser = createAsyncThunk(
  "User/updateUser",
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${userUrl}/${userId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteUser = createAsyncThunk(
  "User/deleteUser",
  async (user) => {
    try {
      await apiAxios.delete(`${userUrl}/${user.id}`);
      return user
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const deactivateUser = createAsyncThunk(
  "User/deactivateUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/deactivate`);
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const exportUsers = createAsyncThunk(
  "User/exportUsers",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${userUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `users-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
);

export const getActivityLog = createAsyncThunk(
  "User/getActivityLog",
  async ({ userId, params } ) => {
    try {
      const response = await apiAxios.get(`${userUrl}/${userId}/activity_log`, { params })
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const changeAvatar = createAsyncThunk(
  "User/changeAvatar",
  async ({ userId, formData }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/change_avatar`, formData, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const assignUserRole = createAsyncThunk(
  "User/assignUserRole",
  async ({ userId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/assign_role`, data);
      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const assignUserSalesUnitRecord = createAsyncThunk(
  "User/assignUserSalesUnitRecord",
  async ({ userId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/assign_sales_unit_record`, data);
      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const assignUserCarBookingSiteRecord = createAsyncThunk(
  "User/assignUserCarBookingSiteRecord",
  async ({ userId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/assign_car_booking_site_record`, data);
      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const assignUserTravelBookingSiteRecord = createAsyncThunk(
  "User/assignUserTravelBookingSiteRecord",
  async ({ userId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/assign_travel_booking_site_record`, data);
      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const changePasswordUser = createAsyncThunk(
  "User/changePasswordUser",
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/change_password`, data)
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const changePasswordManageUser = createAsyncThunk(
  "User/changePasswordManageUser",
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/change_password_manage`, data)
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getActiveUsers = createAsyncThunk(
  "User/getActiveUsers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${userUrl}/active_list`, { params } )
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getDeActiveUsers = createAsyncThunk(
  "User/getDeActiveUsers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${userUrl}/deactive_list`, { params } )
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getOnlineUsers = createAsyncThunk(
  "User/getOnlineUsers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${userUrl}/online_list`, { params } )
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getCreatedThisMonthUsers = createAsyncThunk(
  "User/getCreatedThisMonthUsers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${userUrl}/created_this_month_list`, { params } )
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

// export const confirmUser = createAsyncThunk(
//   "User/confirmUser",
//   async (userId, { rejectWithValue }) => {
//     try {
//       const response = await apiAxios.post(`${userUrl}/${userId}/confirm`)
//       return response.data;
//     }
//     catch(error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// )

export const unlockUser = createAsyncThunk(
  "User/unlockUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/${userId}/unlock`)
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getRolesbyUser = createAsyncThunk(
  "User/getRolesbyUser",
  async (params) => {
    try {
      const response = await apiAxios.get(`${userUrl}/roles_by_user`, { params } );
      return response.data;
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const searchUser = createAsyncThunk(
  "User/searchUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${userUrl}/quick_search`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const userSlice = createSlice({
  name: "authorization_user",
  initialState: {
    ...initialState,
    activeData: createInitialState(),
    deactiveData: createInitialState(),
    createdThisMonthData: createInitialState(),
    onlineData: createInitialState(),
    rolesByUserData: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper,
    updateUserEmployee: (state, action) => {
      state.recordData.employee = action.payload
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllUsers.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getActiveUsers.fulfilled, (state, action) => handleFulfilled(state, action, "activeData"))
      .addCase(getDeActiveUsers.fulfilled, (state, action) => handleFulfilled(state, action, "deactiveData"))
      .addCase(getOnlineUsers.fulfilled, (state, action) => handleFulfilled(state, action, "onlineData"))
      .addCase(getCreatedThisMonthUsers.fulfilled, (state, action) => handleFulfilled(state, action, "createdThisMonthData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))

      .addCase(createUser.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(exportUsers.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportUsers.fulfilled, (state) => {
        state.exporting = false
        toast.success("User has been exported successfully.")
      })
      .addCase(getUser.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(assignUserRole.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(assignUserSalesUnitRecord.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(assignUserCarBookingSiteRecord.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(assignUserTravelBookingSiteRecord.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(changePasswordManageUser.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(changeAvatar.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      // .addCase(confirmUser.fulfilled, (state, action) => {
      //   handlePayload(state, action);
      // })
      .addCase(unlockUser.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(getRolesbyUser.fulfilled, (state, action) => {
        state.rolesByUserData = action.payload
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
  updateUserEmployee
} = userSlice.actions;

export default userSlice.reducer;
