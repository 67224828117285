import AgGridTable from "components/ag-grid/AgGridTable"
import AgPagination from "components/ag-grid/AgPagination"
import React, { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams
} from "redux/crm/project/warrantySlice"
import { columnDefs } from "./Columns";
import ShortFilter from "./ShortFilter"

const GridTable = ({recordType, recordData, loadApi, recordKey}) => {
  const {
    data,
    totalRows,
    pageSize,
    totalPagesCount,
    fromRecord,
    toRecord,
    currentParams
  } = recordData || {};
  const key = recordKey;

  const dispatch = useDispatch()
  const columns = useMemo(() => columnDefs, []);

  useEffect(() => {
    fetchData();
  }, [currentParams])

  const fetchData = async () => {
    if (currentParams) {
      await dispatch(loadApi(currentParams))
    }
  }

  const handlePageChange = ((page, pageSize) => {
    dispatch(setNestedPage({ key, page }));
    dispatch(setNestedPageSize({ key, pageSize }));

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize}
    console.log(newCurrentParams)
    dispatch(setNestedParams({ key, params: newCurrentParams }));

    dispatch(loadApi(newCurrentParams))
  })

  return (
    <>
      <ShortFilter
        recordType={recordType}
        recordData={recordData}
        loadApi={loadApi}
        currentParams={currentParams}
        recordKey={recordKey}
      />

      <AgGridTable
        columnDefs={columns}
        rowData={data}
        gridHeight={'60vh'}
        selectedMultipe={false}
        refData={() => {}}
        serverSide={true}
      />
      
      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200"]}
      />
    </>
  )
};

export default GridTable;