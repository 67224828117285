import { Badge } from "react-bootstrap";
import React from 'react';
import PropTypes from 'prop-types';

const ChangeOrderStatus = ({status}) => {
  let bg = "primary"

    switch(status) {
      case "submited":
        bg = "secondary"
        break
      case "sent":
        bg = "warning"
        break
      case "rejected":
        bg = "danger"
        break
      case "accepted":
        bg = "success";
        break;
      default:
        bg = "primary"
        break
    }

  return (
    <Badge pills bg={bg} className="text-capitalize">{status}</Badge>
  )
};

ChangeOrderStatus.propTypes = {
  status: PropTypes.string.isRequired
}

export default ChangeOrderStatus;