import { useDispatch, useSelector } from "react-redux";
import React, {useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Form } from 'react-bootstrap';
import { getApplication } from "redux/integration/applicationSlice";
import ApplicationDetailHeader from "./ApplicationDetailHeader";
import SecretText from "components/secret/SecretText";

const ApplicationDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {applicationData: record} = useSelector(state => state.integration_application);
  const recordId = params.id;

  useEffect(() => {
    dispatch(getApplication(recordId));
  }, [recordId, dispatch]);

  return (
    <>
      <ApplicationDetailHeader record={record} />

      <Card className="mb-3 p-4 fs--1">
        <Row className="mb-2 fs-0">
          <Col className="fw-bold">Application name:</Col>
          <Col sm={9}>
            <span className="text-primary fw-bold">{record.name}</span>
          </Col>
        </Row>

        <div className="border-bottom text-center my-3"></div>

        <Row className="mb-2">
          <Col>Application UID:</Col>
          <Col sm={9}>
            <span className="text-primary">{record.uid}</span>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>Secret:</Col>
          <Col sm={9}>
            <SecretText defaultSecret={record.secret} />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>Callback url:</Col>
          <Col sm={9}>
            <span className="text-primary">{record.redirect_uri}</span>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>Scopes:</Col>
          <Col sm={9}>
            <span className="text-primary">{record.scopes}</span>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>Confidential:</Col>
          <Col sm={9}>
            <Form.Check className="fs--1" checked={record.confidential} />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>Created date:</Col>
          <Col sm={9}>
            <span className="text-primary">{record.created_date}</span>
          </Col>
        </Row>

      </Card>
    </>
  )
}

export default ApplicationDetail;