import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteProject, convertQuoteNumber, getProject } from 'redux/crm/projectSlice';
import { FaEdit, FaEllipsisV, FaPlusCircle } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';
import ChangeSalesUnitForm from './ChangeSalesUnitForm';
import ChangeStatusProjectForm from "./ChangeStatusProjectForm";
import { MdTrackChanges, MdOutlineChangeCircle } from "react-icons/md";
import SyncToOplaCrmForm from './SyncToOplaCrmForm';
import CloseLostForm from './CloseLostForm';
import { FaFaceSadTear } from "react-icons/fa6";
import { useSelector } from 'react-redux';

const ProjectDetailHeader = () => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [showChangeSalesUnitForm, setShowChangeSalesUnitForm] = useState(false);
  const [showChangeStatusForm, setShowChangeStatusForm] = useState(false);
  const [showSyncToOplaForm, setShowSyncToOplaForm] = useState(false);
  const [showCloseLostForm, setShowCloseLostForm] = useState(false);

  const {recordData: project} = useSelector(state => state.crm_project);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const projectUrl = "/crm/projects";
  const projectId = params.id;

  const handleNewPCV = () => {
    navigate(`${projectUrl}/new-pcv`)
  }

  const handleNewWaiver = () => {
    navigate(`${projectUrl}/${projectId}/waivers/new`, {state: { project} });
  }

  const handleEditProject = () => {
    navigate(`${projectUrl}/${projectId}/edit`, {state: { project}});
  }

  const handleProjectList = () => {
    navigate(`${projectUrl}/list`)
  }

  const confirmMsg = (message) => {
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deleteProject(project))
      navigate(`${projectUrl}/list`)

      closeConfirmModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleDeleteProject = () => {
    const message = `Are you sure to delete project ID#<strong class='text-danger'>${projectId}</strong>?`
    confirmMsg(<span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleConvertQuoteNumber = () => {
    dispatch(convertQuoteNumber(projectId));
  }

  const handleChangeSalesUnit = () => {
    setShowChangeSalesUnitForm(true);
  }

  const handleChangeStatus = () => {
    setShowChangeStatusForm(true);
  }

  const handleSyncOplaCRM = () => {
    setShowSyncToOplaForm(true);
  }

  const handleCloseLost = () => {
    setShowCloseLostForm(true);
  }

  const handleRefresh = async () => {
    await dispatch(getProject(projectId))
    toast.success("Project has been refresh.")
  }

  const {
    allow_edit,
    allow_delete,
    allow_create_waiver,
    allow_convert_quotation,
    allow_change_sales_unit,
    allow_change_status,
    allow_sync_sales_process_opla,
    allow_close_lost
  } = project?.permissions || {};

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="sync-alt"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleRefresh}
              title="Refresh"
            >
              <span className="d-none d-xl-inline-block ms-1"></span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              title="New PCV"
              onClick={handleNewPCV}
            >
              <span className="d-none d-xl-inline-block ms-1">New PCV</span>
            </IconButton>

            {allow_convert_quotation && (
                <IconButton
                  variant="info"
                  size="sm"
                  icon="bolt"
                  transform="shrink-2"
                  iconAlign="middle"
                  className="me-1"
                  onClick={handleConvertQuoteNumber}
                >
                  <span className="d-none d-xl-inline-block ms-1">Convert Quote number</span>
                </IconButton>
              )}
            
            {allow_create_waiver && (
              <IconButton
                variant="info"
                size="sm"
                icon="plus"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                title="New Waiver"
                onClick={handleNewWaiver}
              >
                <span className="d-none d-xl-inline-block ms-1">New Waiver</span>
              </IconButton>
            )}

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleProjectList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_create_waiver && (
                    <>
                      <Dropdown.Item className="mb-1" onClick={handleNewWaiver}>
                        <FaPlusCircle /> New Waiver
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {allow_close_lost && (
                    <>
                      <Dropdown.Item className="mb-1" onClick={handleCloseLost}>
                        <FaFaceSadTear className="text-danger" /> Close lost
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {allow_change_sales_unit && (
                    <>
                      <Dropdown.Item className="mb-1" onClick={handleChangeSalesUnit}>
                        <MdTrackChanges className="text-info" /> Change sales unit
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}


                  {allow_change_status && (
                    <>
                      <Dropdown.Item className="mb-1" onClick={handleChangeStatus}>
                        <MdOutlineChangeCircle className="text-warning" /> Change status
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {allow_sync_sales_process_opla && (
                    <>
                      <Dropdown.Item className="mb-1" onClick={handleSyncOplaCRM}>
                        <MdOutlineChangeCircle className="text-warning" /> Sync to Opla CRM
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}

                  {allow_edit && (
                    <Dropdown.Item className="mb-1" onClick={handleEditProject}>
                      <FaEdit /> Edit
                    </Dropdown.Item>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger" onClick={handleDeleteProject}>
                        <MdDelete className="text-danger" /> Delete
                      </Dropdown.Item>
                    </>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message={messageConfirm}
        />
      )}


      {showChangeSalesUnitForm && (
        <ChangeSalesUnitForm
          showModal={showChangeSalesUnitForm}
          setshowModal={setShowChangeSalesUnitForm}
          project={project}
        />
      )}

      {showChangeStatusForm && (
        <ChangeStatusProjectForm
          showModal={showChangeStatusForm}
          setshowModal={setShowChangeStatusForm}
          project={project}
        />
      )}

      {showSyncToOplaForm && (
        <SyncToOplaCrmForm
          showModal={showSyncToOplaForm}
          setshowModal={setShowSyncToOplaForm}
          project={project}
        />
      )}

      {showCloseLostForm && (
        <CloseLostForm
          showModal={showCloseLostForm}
          setshowModal={setShowCloseLostForm}
          project={project}
        />
      )}
    </>
  );
};

ProjectDetailHeader.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectDetailHeader;