import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Row, Col, InputGroup, Form, Button, Badge} from "react-bootstrap";
import { debounce } from 'helpers/debounce';
import { useDispatch } from 'react-redux';
import { exportData, resetNestedParams, setFilterTag, setNestedParams } from "redux/crm/project/warrantySlice";
import { useCallback, useState } from "react";
import FilterAdvanceModal from "./FilterAdvanceModal";
import { useSelector } from "react-redux";
import Flex from "components/common/Flex";

const ShortFilter = ({
  recordType,
  recordData,
  loadApi,
  currentParams,
  recordKey
}) => {
  const {filterTags} = useSelector(state => state.crm_warranty);
  const [newCurrentParams, setNewCurrentParams] = useState(currentParams);
  const [showFilterAdvance, setShowFilterAdvance] = useState(false);

  const dispatch = useDispatch();
  const key = recordKey;

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = value ? { project_name_or_project_j_number_cont: value } : {...newCurrentParams}; // Update the parameter as needed
      setNewCurrentParams(newParams)
      dispatch(loadApi(newParams));
    }, 500), // Set the debounce time (in milliseconds)
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target;
    handleChangeDebounced(value); //call the debound function
  }

  const handleExport = () => {
    const newParams = {...newCurrentParams}
    dispatch(exportData(newParams))
  }

  const handleFilterAdvance = () => {
    setShowFilterAdvance(true);
  }

  const handleRefresh = () => {
    dispatch(resetNestedParams({key: recordKey}));
  }

  const handleDeleteTag = (id) => {
    const newTags = filterTags.filter((tag) => tag.id !== id)
    dispatch(setFilterTag(newTags))

    //update current params
    const newParams = {...currentParams};
    delete newParams[id];
    updateParams(newParams);
  };

  const updateParams = (newParams) => {
    dispatch(setNestedParams({key, params: newParams}));
  }

  return (
    <>
      <Row className="my-2 gx-2 flex-between-center">
        <Col sm="auto">
          <Flex justifyContent="start" alignItems="center" wrap="wrap">
            {filterTags ? (
              <span className="fs--1">
                {filterTags.map((item, index) => (
                  <Badge pill bg="secondary" className="me-1 p-2" key={index}>
                    {item.name}: {Array.isArray(item.value) ? `[${item.value.join(', ')}]` : item.value}
                    <span className="ms-2" style={{cursor: "pointer"}}>
                      <FontAwesomeIcon icon="trash-alt" onClick={() => handleDeleteTag(item.id)} />
                    </span>
                  </Badge>
                ))}
              </span>
            ) : (
              <span className="text-primary">
                <Badge pill bg="secondary" className="me-1 p-2">No filter</Badge>
              </span>
            )}
          </Flex>
        </Col>
        <Col sm={4}>
          <Row className="gx-2 align-items-center">
            <Col>
              <InputGroup className="fs--1">
                <Form.Control size="sm" placeholder="Search..." onChange={handleChange} />
                <Button size="sm" type="submit" variant="falcon-default">
                  <FontAwesomeIcon icon="search" className="me-1" />
                </Button>
                <Button size="sm" title="Filter advance" variant="falcon-default" onClick={handleFilterAdvance}>
                  <FontAwesomeIcon icon="filter" className="me-1" />
                </Button>
                <Button size="sm" variant="falcon-default" onClick={handleRefresh} title="Refresh">
                  <FontAwesomeIcon icon="sync-alt" className="me-1" />
                </Button>
                <Button size="sm" variant="falcon-default" onClick={handleExport} title="Export to excel">
                  <FontAwesomeIcon icon="download" className="me-1" />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      {showFilterAdvance && (
        <FilterAdvanceModal
          showModal={showFilterAdvance}
          setshowModal={setShowFilterAdvance}
          data={recordData}
          recordKey={key}
        />
      )}
    </>
  );
};

export default ShortFilter;