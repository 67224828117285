import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { findAndReplacePermission } from "redux/authorization/permissionSlice";
import { FaBan } from "react-icons/fa";
import { MdFindReplace } from "react-icons/md";
import IconAlert from "components/common/IconAlert";
import PropTypes from 'prop-types';

const PermissionFindAndReplace = ({showModal, setshowModal}) => {
  const {register, handleSubmit, formState: {errors}, setValue, reset } = useForm();
  const dispatch = useDispatch();
 
  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      const response = await dispatch(findAndReplacePermission(data));
      toast.success(response.payload.message);

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Find and replace permission!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <IconAlert variant="warning">
              This function just apply for <b>subject class</b> column!
            </IconAlert>

            <Form.Group as={Row} className="mb-2" controlId="find_subject_class">
              <Form.Label column sm={4} className="text-lg-end">
                Find subject class
              </Form.Label>
              <Col sm={8} md={7}>
                <Form.Control
                  type="text"
                  name="subject_class"
                  placeholder="Find subject class"
                  className="fs--1"
                  {...register("find_subject_class", {
                    required: "Find subject class must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.find_subject_class && errors.find_subject_class.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="replace_subject_class">
              <Form.Label column sm={4} className="text-lg-end">
                Replace
              </Form.Label>
              <Col sm={8} md={7}>
                <Form.Control
                  type="text"
                  name="replace_subject_class"
                  placeholder="Replace subject class"
                  className="fs--1"
                  {...register("replace_subject_class", {
                    required: "Replace subject class must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.replace_subject_class && errors.replace_subject_class.message}
                </span>
              </Col>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><MdFindReplace /> Replace</Button>
            <Button size="sm" variant="danger" onClick={closeModal}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
};

PermissionFindAndReplace.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired
}

export default PermissionFindAndReplace;