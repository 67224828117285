
import { Card, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import AssignRole from "./assign/AssignRole";
import RecordLevelSalesUnit from "./assign/RecordLevelSalesUnit";
import RecordLevelCarBookingSite from "./assign/RecordLevelCarBookingSite";
import RecordLevelTravelBookingSite from "./assign/RecordLevelTravelBookingSite";
import React, { useEffect } from "react";
import { getActiveSites } from "redux/organization/siteSlice";
import { useSelector, useDispatch } from "react-redux";
import { FaUserPlus, FaUserShield } from "react-icons/fa";

const UserDetailAuthorization = ({user}) => {
  const { activeData: sites } = useSelector(state => state.organization_site);
  const { 
    roles,
    record_level_sales_units,
    record_level_car_booking_sites,
    record_level_travel_booking_sites
   } = user || {};
   
  const userId = user.id;
  const dispatch = useDispatch();

  //console.log(user)

  useEffect(() => {
    dispatch(getActiveSites());
  }, [user, dispatch]);

  const travelSites = sites && sites.filter(item => item.travel_booking === true);
  const carSites = sites && sites.filter(item => item.car_booking === true);

  return (
    <>
      <Row className="fs--1 g-4">
        <Col sm={7}>
          <Card className="mb-3 h-100">
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <div className="mb-0 fs-0"><FaUserShield /> Assign Roles</div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
              <AssignRole userId={userId} data={roles} />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-3">
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <div className="mb-0 fs-0"><FaUserPlus /> Record level sales Unit - CRM</div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
              <RecordLevelSalesUnit userId={userId} data={record_level_sales_units} />
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <div className="mb-0 fs-0"><FaUserPlus /> Record level Car Booking Sites</div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
              <RecordLevelCarBookingSite
                userId={userId}
                data={record_level_car_booking_sites}
                sites={carSites}
              />
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <div className="mb-0 fs-0"><FaUserPlus /> Record level Travel Sites</div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
              <RecordLevelTravelBookingSite
                userId={userId}
                data={record_level_travel_booking_sites}
                sites={travelSites}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
    </>
  );
};

UserDetailAuthorization.propTypes = {
  user: PropTypes.object.isRequired
};


export default UserDetailAuthorization;