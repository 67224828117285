import React from 'react';
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetNestedParams, setFilterTag, setNestedParams } from "redux/organization/departmentSlice";
import IconAlert from "components/common/IconAlert";
import { MdSearch, MdOutlineRestartAlt, MdOutlineClose } from "react-icons/md";
import { debounce, filterOptions } from "helpers/debounce";
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";

const FilterAdvanceModal = ({showModal, setshowModal, data, entryKey}) => {
  const { control, register, handleSubmit, setValue, reset } = useForm();
  const dispatch = useDispatch();

  const {pageSize} = data || {};
  const key = entryKey;

  const onSubmit = (data) => {
    const newData = {
      name_cont: data.name,
      site_id_in: data.site_ids,
      page: 1,
      per_page: pageSize
    }

    console.log(newData)

    //store for filter
    const dataFilter = [
      { id: "name_cont", name: "Name", value: data.name }
    ]

    if (data.site_ids) {
      dataFilter.push({ id: "site_id_in", name: "Site", value: data.site_ids })
    }

    dispatch(setFilterTag({key, tags: dataFilter.filter((item) => item.value !== undefined && item.value !== '')}))

    let obj = {}
    Object.entries(newData).forEach((item) => {
      const value = item[1]
      const key = item[0]

      if (value !== null && value !== "" && value !== undefined) {
        obj = {...obj, [key]: value}
      }
    })

    try {
      dispatch(setNestedParams({key, params: obj}));
      closeModal()
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleReset = () => {
    resetForm()
  }

  const resetForm = () => {
    reset()
    setValue('site_ids', null)
    dispatch(resetNestedParams({key}));
  }

  const handleSiteChange = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('site_ids', selectedOptionsValues, { shouldValidate: true })
  }

  const debouncedFilterSites = debounce((inputValue, callback) => {
    filterOptions('/organization/sites/active_list', inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);
  
  const siteOptions = (inputValue, callback) => {
    debouncedFilterSites(inputValue, callback);
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Apply filters to advances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="info">
            <p className="mb-0">Establish the department's filtering criteria</p>
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                rows={6}
                name="name"
                placeholder="Name"
                className="fs--1"
                {...register("name")}
              />
            </Col>
          </Form.Group>
          
   
          <Form.Group as={Row} className="mb-2" controlId="site_ids">
            <Form.Label column sm={3} className="text-lg-end">
              Site
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name='site_ids'
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={siteOptions}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix='react-select'
                    className='w-100'
                    isMulti
                    onChange={handleSiteChange}
                  />
                )}
              />
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit"><MdSearch /> Search</Button>
          <Button size="sm" variant="falcon-default" onClick={handleReset}><MdOutlineRestartAlt /> Reset</Button>
          <Button size="sm" variant="falcon-default" onClick={closeModal}><MdOutlineClose /> Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

FilterAdvanceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    site_ids: PropTypes.arrayOf(PropTypes.number),
    pageSize: PropTypes.number
  }).isRequired,
  entryKey: PropTypes.string.isRequired
};

export default FilterAdvanceModal;