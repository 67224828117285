import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiAxios from 'helpers/apiAxios';
import { toast } from 'react-toastify';
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  createInitialState,
  handlePayload
} from 'helpers/reducerHelpers';
import { objectSerializer } from 'helpers/utils';

const customerUrl = '/crm/companies'

export const getAllCustomers = createAsyncThunk(
  'Customer/getAllCustomers',
  async (params) => {
    try {
      const response = await apiAxios.get(`${customerUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCustomer = createAsyncThunk(
  'Customer/getCustomer',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${customerUrl}/${customerId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createCustomer = createAsyncThunk(
  'Customer/createCustomer',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(customerUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCustomer = createAsyncThunk(
  'Customer/updateCustomer',
  async ({ customerId, formData }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${customerUrl}/${customerId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteCustomer = createAsyncThunk(
  'Customer/deleteCustomer',
  async (customerId) => {
    try {
      const response = await apiAxios.delete(`${customerUrl}/${customerId}`);
      return response.data;

    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportCustomers = createAsyncThunk(
  'Customer/exportCustomers',
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)
    
    const response = await apiAxios.get(`${customerUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `customers-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
);

export const assignContact = createAsyncThunk(
  'Customer/assignContact',
  async ({ customerId, formData }) => {
    try {
      const response = await apiAxios.post(`${customerUrl}/${customerId}/assign_to`, formData);
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getActivityLog = createAsyncThunk(
  'Customer/getActivityLog',
  async ({ customerId, params } ) => {
    try {
      const response = await apiAxios.get(`${customerUrl}/${customerId}/activity_log`, { params });
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getCustomerContacts = createAsyncThunk(
  'Customer/getCustomerContacts',
  async (customerId) => {
    try {
      const response = await apiAxios.get(`${customerUrl}/${customerId}/contacts` )
      return response.data.rows
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCreatedThisMonthCustomers = createAsyncThunk(
  'Customer/getCreatedThisMonthCustomers',
  async (params) => {
    try {
      const response = await apiAxios.get(`${customerUrl}/created_this_month_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const deleteContactCustomer = createAsyncThunk(
  'Customer/deleteContactCustomer',
  async ({ customerId, contactId }) => {
    try {
      const response = await apiAxios.post(`${customerUrl}/${customerId}/delete_contact?contact_id=${contactId}`);
      return response.data;

    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const setPrimaryAddress = createAsyncThunk(
  'Customer/setPrimaryAddress',
  async ({ customerId, addressId }) => {
    try {
      const response = await apiAxios.post(`${customerUrl}/${customerId}/set_primary_address?address_id=${addressId}`);
      return response.data;

    } catch (error) {
      throw new Error(error.message)
    }
  }
)


const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const customerSlice = createSlice({
  name: 'crm_customer',
  initialState: {
    ...initialState,
    createdThisMonthData: createInitialState(),
    contactData: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getCreatedThisMonthCustomers.fulfilled, (state, action) => handleFulfilled(state, action, "createdThisMonthData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))

      .addCase(getCustomer.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(exportCustomers.pending, (state) => {
        state.exporting = true
      })
      .addCase(assignContact.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(exportCustomers.fulfilled, (state) => {
        state.exporting = false
        toast.success('Contacts has been exported successfully.')
      })
      .addCase(getCustomerContacts.fulfilled, (state, action) => {
        state.contactData = objectSerializer(action.payload)
      })
      .addCase(deleteContactCustomer.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(setPrimaryAddress.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error.message)
        }
      );
  }
});

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
  setCustomerContacts
} = customerSlice.actions;

export default customerSlice.reducer;