import React from 'react';
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { useMemo } from "react";
import {format} from 'date-fns';
import {Link} from 'react-router-dom';

const BookingTable = ({data}) => {

  const formatDate = (date) => {
    if (!date) return
    return format(new Date(date), "MMM, dd-yyyy h:mm aa")
  }

  const columns = useMemo(() => [
    {
      accessor: 'booking_number',
      Header: 'Booking ID#',
      Cell: rowData => {
        const { id, booking_number } = rowData.row.original;
        return (
          <Link to={`/booking/car/${id}`} target="_blank">{booking_number}</Link>
        )
      }
    },
    {
      accessor: 'from_date',
      Header: 'From date',
      Cell: rowData => {
        const { from_date } = rowData.row.original
        return(
          formatDate(from_date)
        )
      }
    },
    {
      accessor: 'to_date',
      Header: 'To date',
      Cell: rowData => {
        const { to_date } = rowData.row.original
        return(
          formatDate(to_date)
        )
      }
    },
    {
      accessor: 'pickup_location',
      Header: 'Address pickup location'
    },
    {
      accessor: 'drop_location',
      Header: 'Address drop location'
    },
    {
      accessor: 'driver_trans',
      Header: 'Drivers',
      Cell: rowData => {
        const {driver_trans} = rowData.row.original

        return (
          <ul>
            {driver_trans && driver_trans.map((item, index) => (
              <li key={index} className="pb-0 mb-0">{item.driver?.name} <span className="text-primary">({item.car?.name})</span></li>
            ))}
          </ul>
        );
      }
    },
    {
      accessor: 'notes',
      Header: 'Notes',
      Cell: rowData => {
        const { notes } = rowData.row.original
        return (
          <span className="text-danger">{notes}</span>
        )
      }
    }
  ], [])

  return (
    <div className="m-4">
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        pagination
        perPage={5}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
      
      </AdvanceTableWrapper>
    </div>
  )
};

export default BookingTable;