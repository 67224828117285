import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from "components/common/Flex";
import { CRM_PROJECT_ATTACHMENTS } from "app_config";
import PropTypes from "prop-types";

const UploadDocumentDirect = ({uploadDocument}) => {
 
  const onDrop = useCallback((acceptedFiles) => {
    const data = {
      documents: acceptedFiles
    }

    uploadDocument(data)
  }, [uploadDocument])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: CRM_PROJECT_ATTACHMENTS,
    multiple: true
  })

  return (
    <div>
      <div className="mb-2 small text-primary">Only .EML, DWG, PDF, EXCEL, WORD files are allowed. The file size is maximum of 100Mb</div>
      <div {...getRootProps({ className: 'dropzone-area py-6' })}>
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="Upload Icon" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">Drop your files here</p>
        </Flex>
      </div>
    </div>
  )
}

UploadDocumentDirect.propTypes = {
  uploadDocument: PropTypes.func.isRequired,
};

export default UploadDocumentDirect;