const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaInfoCircle, FaCalendar } from 'react-icons/fa';
import { MdPendingActions } from 'react-icons/md';
import { monthNameMap } from 'helpers/utils';
import LeadTable from './LeadTable';
import LeadEntryItem from './LeadEntryItem';
import React from 'react';
import PropTypes from'prop-types';

const LeadEntry = ({data}) => {
  const COLOR = "secondary";

  const sortedData = data.slice().sort((a, b) => {
    const dateA = new Date(a.created_date);
    const dateB = new Date(b.created_date);
    
    if (isNaN(dateA) || isNaN(dateB)) {
        console.error("Invalid created date:", a.created_date, b.created_date);
        return 0;
    }
    
    return dateA - dateB;
  });

  const salesRegionData = data.reduce((acc, item) => {
    const sales_region = item.sales_unit_region;
    if (!acc[sales_region]) {
      acc[sales_region] = {number_of_projects: 1};
    }
    else {
      acc[sales_region].number_of_projects++;
    }

    return acc;
  }, {});

  const monthData = sortedData.reduce((acc, item) => {
    const date = item.created_month;
    if (!acc[date]) {
      acc[date] = {number_of_projects: 1};
    }
    else {
      acc[date].number_of_projects++;
    }

    return acc;
  }, {});

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMap[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="lead-entry">
        <Card.Header className="bg-light text-primary h5">
          <MdPendingActions /> Lead entry
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Lead created by monthly and sales regions for the period.</p>

          <Tabs defaultActiveKey="lead-monthly" id="lead-tracking" transition={false}>
            <Tab eventKey="lead-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <LeadEntryItem data={convertToMonthNames(monthData)} color={COLOR} />
            </Tab>
            <Tab eventKey="lead-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <LeadEntryItem data={salesRegionData} color={COLOR} />
            </Tab>
            <Tab eventKey="lead-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <LeadTable data={data} />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

LeadEntry.propTypes = {
  data: PropTypes.array.isRequired
};


export default LeadEntry;