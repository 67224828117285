import { Card, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Open from './step/Open';
import Contacted from './step/Contacted';
import ConvertedPCV from './step/ConvertedPCV';
import PropTypes from 'prop-types';

const NavItem = ({ index, step, handleNavs, icon, label, status }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < status,
          active: (step === index && index <= status)
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.string.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
}

const LeadStep = ({lead}) => {
  const navigate = useNavigate();
  const progressBar = true;
  const {status} = lead || {};
  const statusId = status?.id;
  const isContacted = statusId > 1;
  const isOpportunity = statusId === 4;

  const [step, setStep] = useState(statusId || 1)

  let navItems = [
    {
      icon: 'lock',
      label: 'Open',
      anchor: 'open'
    },
    {
      icon: 'user',
      label: 'Contacted',
      anchor: 'contacted'
    }, {
      icon: 'thumbs-up',
      label: 'Opportunity',
      anchor: 'opportunity'
    }
  ];

  const handleNavs = targetStep => {
    setStep(targetStep)

    const newPath = `/crm/leads/${lead?.id}#${navItems[targetStep - 1].anchor}`
    navigate(newPath)
  }

  useEffect(() => {
    const hash = window.location.hash;

    const matchingNavItemIndex = navItems.findIndex(item => `#${item.anchor}` === hash);

    if (matchingNavItemIndex !== -1) {
      setStep(matchingNavItemIndex + 1)
    }
  }, [navigate])

  return (
    <Card className="theme-wizard">
      <Card.Header className="bg-light pb-2">
        <Nav className="justify-content-center">
          {navItems.map((item, index) => (
            <NavItem
              key={item.label}
              index={index + 1}
              step={step}
              handleNavs={handleNavs}
              icon={item.icon}
              label={item.label}
              status={statusId}
            />
          ))}
        </Nav>
      </Card.Header>
      
      {/* {progressBar && <ProgressBar now={step * 16.7} style={{ height: 2 }} />} */}
      <Card.Body className="fw-normal px-md-3 py-4">
        {step === 1 && (
          <Open lead={lead} />
        )}
        {step === 2 && isContacted && (
          <Contacted lead={lead} />
        )}
        {step === 3 && isOpportunity && (
          <ConvertedPCV lead={lead} />
        )}
      </Card.Body>
    </Card>
  )
};

LeadStep.propTypes = {
  lead: PropTypes.object.isRequired
}

export default LeadStep;