const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaCalendar, FaInfoCircle, FaCity } from 'react-icons/fa';
import { lazy, Suspense } from "react";
import { monthNameMapDecimal } from 'helpers/utils';
import React from 'react';
import PropTypes from "prop-types";

const LazyEntryItem = lazy(() => import("../../components/EntryItem"));
const LazyQuotationDetail = lazy(() => import("./QuotationDetail"));
const LazyQuotationProvince = lazy(() => import("./QuotationProvince"));

const QuotationEntry = ({originalData}) => {
  const {monthly, sales_regions, data} = originalData
  const COLOR = "primary";

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMapDecimal[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="became-job">
        <Card.Header className="bg-light text-primary h5">
          Quotation entry
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Quotations entered monthly, sales regions or province of project for the period.</p>

          <Tabs defaultActiveKey="quotation-sales-region" id="quotation-entry" transition={false}>
            <Tab eventKey="quotation-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyEntryItem data={sales_regions} color={COLOR} isSalesRegion={true} />
              </Suspense>
            </Tab>
            <Tab eventKey="quotation-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyEntryItem data={convertToMonthNames(monthly)} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="quotation-province" title={<span><FaCity /> Province of Project</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyQuotationProvince originalData={originalData} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="became-job-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyQuotationDetail data={data} />
              </Suspense>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

QuotationEntry.propTypes ={
  originalData: PropTypes.object.isRequired
}

export default QuotationEntry;