import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { syncTerminalEmployee } from 'redux/organization/employeeSlice';
import { toast } from "react-toastify";
import React from 'react';
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useEffect } from "react";

const SyncTerminalEmployeeConfirm = ({showModal, setshowModal }) => {
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const date = new Date();

    setValue("from_date", date);
    setValue("to_date", date);
  }, []);

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    try {
      await dispatch(syncTerminalEmployee(data));
      closeModal();
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Sync terminal employees confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Are you sure to sync terminal employees from iHRM now?
          </IconAlert>

          <Form.Group as={Row} className='mb-2' controlId='from_date'>
            <Form.Label column sm={3} className='text-lg-end'>
              Start date
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="from_date"
                rules={{required: "Start date must be required."}}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    fixedHeight
                  />
                )}
              />
              <span className='text-danger'>
                {errors.from_date && errors.from_date.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-2' controlId='to_date'>
            <Form.Label column sm={3} className='text-lg-end'>
              End date
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="to_date"
                rules={{required: "End date must be required."}}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    fixedHeight
                  />
                )}
              />
              <span className='text-danger'>
                {errors.to_date && errors.to_date.message}
              </span>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit">Yes</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

export default SyncTerminalEmployeeConfirm;