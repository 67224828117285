import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from "helpers/utils";

const TableItem = ({data}) => {
  const sumData = data && Object.values(data).reduce((acc, item) => {
    acc.number_of_projects += parseInt(item.number_of_projects) || 0;
    acc.weight += parseFloat(item.weight) || 0;

    return acc;
  }, {number_of_projects: 0, weight: 0})

  return (
    <div className="fs--1 table-responsive scrollbar">
      <h6 className="mb-2">Detail:</h6>
      
      <table className="border">
        <thead className="bg-light">
          <tr className="border">
            <th className="px-2 border">Name</th>
            <th className="px-2 border">Number of projects</th>
            <th className="px-2 border">Weight (M.T)</th>
          </tr>
        </thead>
        <tbody>
          {data && Object.entries(data).map(([name, values], index) => (
            <tr key={index}>
              <td className="text-start px-2 border">{name}</td>
              <td className="text-end px-2 border">{values.number_of_projects}</td>
              <td className="text-end px-2 border">{formatNumber(values.weight)} M.T</td>
            </tr>
          ))}
        </tbody>
        <tfoot className="bg-light text-primary fw-bold">
          <tr>
            <td className="text-center px-2 border">Total</td>
            <td className="text-end px-2 border">{sumData?.number_of_projects}</td>
            <td className="text-end px-2 border">{formatNumber(sumData?.weight)} M.T</td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

TableItem.propTypes = {
  data: PropTypes.array.isRequired
}

export default TableItem;