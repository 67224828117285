import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const formatDate = (date) => {
  const now = new Date();
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (days === 1) {
    return "yesterday";
  } else {
    return `${days} day${days === 1 ? "" : "s"} ago`;
  }
};

const DateTimeToText = ({ datetime }) => {
  const [formattedText, setFormattedText] = useState("");

  useEffect(() => {
    const formatted = formatDate(new Date(datetime));
    setFormattedText(formatted);
  }, [datetime]);

  return <span>{formattedText}</span>;
};

DateTimeToText.propTypes = {
  datetime: PropTypes.string.isRequired,
};

export default DateTimeToText;