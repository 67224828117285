import IconButton from 'components/common/IconButton';
import { Button, Form, Card, Row, Col, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { CRM_LEGAL_STATUS, CRM_NUMBER_OF_EMPLOYEES, CRM_TYPE_OF_CUSTOMER } from 'app_config';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { createCustomer, updateCustomer } from 'redux/crm/customerSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { getCountries } from 'redux/settingsSlice';
import PhoneInput from 'react-phone-input-2';
import {isValidPhoneNumber} from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import CustomerUploadLogo from './CustomerUploadLogo';
import GoogleMapAutoCompletePlaceDebounce from 'components/google-map-places/GoogleMapAutoCompletePlaceDebounce';
import { getIndustries } from 'redux/crm/settingSlice';
import { salesEmployeesByNameDebounce } from 'helpers/employeeOptionHelper';
import { FaBan, FaCaretRight, FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';

const CustomerForm = ({
  title,
  customer
}) => {
  const {industryData: industries} = useSelector((state) => state.crm_setting);
  const {data: profileUser} = useSelector((state) => state.profile_user)
  const {countryData} = useSelector((state) => state.settings);
  const [activeKey, setActiveKey] = useState(['0', '1', '2']);

  const {control, register, handleSubmit, formState: { errors}, setValue, getValues } = useForm();
  const customertUrl = useMemo(() => '/crm/customers', []);

  const numberOfEmployeesOptions = useMemo(() => CRM_NUMBER_OF_EMPLOYEES.map((option) => ({ value: option, label: option })), []) 
  const defaultNumberOfEmployeesOption = useMemo(() => (
    customer ? { value: customer.employess, label: customer.employess } : numberOfEmployeesOptions[0]
  ), [customer]);

  const typeOfCustomerOptions = useMemo(() => CRM_TYPE_OF_CUSTOMER.map((option) => ({ value: option, label: option })), []) 
  const defaultTypeOfCustomerOption = useMemo(() => (
    customer ? { value: customer.type_of_company, label: customer.type_of_company } : typeOfCustomerOptions[0]
  ), [customer]);

  const defaultMainContactOption = useMemo(() => {
    if (customer && customer.main_contact) {
      return { value: customer.main_contact, label: customer.main_contact };
    }
    return null;
  }, [customer]);

  const defaultSecondaryContactOption = useMemo(() => {
    if (customer && customer.secondary_contact) {
      return customer.secondary_contact.map((option) => ({value: option, label: option}));
    }
    return null;
  }, [customer]);

  const industryOptions = useMemo(() => industries && industries.map((item) => ({ value: item.name, label: item.name })), []);
  const defaultIndustryOption = useMemo(() => (
    customer ? { value: customer.industry, label: customer.industry } : industryOptions[0]
  ), [customer]);

  const countryOptions = useMemo(() => countryData && countryData.map((option) => ({ value: option.country, label: option.name })), []);
  const defaultcountryOption = useMemo(() => (
    customer ? { value: customer.country, label: customer.country_name } : (countryOptions.filter((country) => country.value === 'VN')[0])
  ), [customer]);

  const [logo, setLogo] = useState('');
  const [countryCode, setCountryCode] = useState('vn');
  const [address, setAddress] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getIndustryList = () => {
      dispatch(getIndustries())
    }

    const getCountryList = () => {
      dispatch(getCountries())
    }

    getCountryList()
    getIndustryList()
  }, []);

  useEffect(() => {
    setValue("country", profileUser?.site.country);
    setValue("main_contact", profileUser?.name);
    setValue('employess', numberOfEmployeesOptions[1]?.value);
    setValue('industry', industryOptions[1]?.value);
    setValue('type_of_company', typeOfCustomerOptions[1]?.value);

    if(customer) {
      Object.entries(customer).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue]);

  const onSubmit = async (data) => {
    if (!validate(data)) return;

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      let newValue = value;

      if (newValue === undefined) {
        delete data[key];
      }

      if (Array.isArray(newValue)) {
        newValue.forEach((item) => {
          formData.append(`${key}[]`, item);
        })
      }
      else {
        formData.append(key, newValue);
      }
    })

    if (logo) {
      formData.append('logo', logo[0]);
    }
    
    if (address) {
      formData.append('address', JSON.stringify(address));
    }

    try {
      let customerId = null;
      if (customer)
      {
        customerId = customer.id;
        await dispatch(updateCustomer({customerId, formData}));
      }
      else
      {
        const response = await dispatch(createCustomer(formData));
        customerId = response.payload.data.id;
      }

      if (customerId) {
        redirectCustomerDetail(customerId);
      }
    }
    catch(error) {
      console.log(error.message);
    }
  }

  const handleCancle = () => {
    navigate(-1);
    return null;
  }

  const redirectCustomerDetail = (customerId) => {
    navigate(`${customertUrl}/${customerId}`);
  }

  const handleChangeSecondaryContact = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('secondary_contact', selectedOptionsValues, { shouldValidate: true });
  }

  const validatePhoneNumber = (number) => {
    if (number === undefined ||
        number === 'undefined' ||
        number.trim() === '') return undefined

    const {country} = getValues();
    const countryCode = country.toUpperCase();

    if (!isValidPhoneNumber(number.toString(), countryCode)) {
      return `Invalid phone number with country code '${countryCode}' `;
    }
    return true;
  }

  const validate = (data) => {
    if ((data.email === '' || data.email === undefined) && (data.telephone === undefined || data.telephone === '')) {
      toast.error('Email or phone must be required.');
      return false;
    }
    return true;
  }

  const loadMainContactOptions = (inputValue, callback) => {
    salesEmployeesByNameDebounce(inputValue, callback);
  }
  
  const loadSecondaryContactOptions = (inputValue, callback) => {
    salesEmployeesByNameDebounce(inputValue, callback);
  }

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  }

  return (
    <>
      <Card className='fs--1'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Header className='d-flex flex-between-center border-bottom'>
            <Card.Title>{title}</Card.Title>
            <IconButton
              onClick={() => navigate(-1)}
              variant='falcon-default'
              size='sm'
              icon='arrow-left'
            />
          </Card.Header>

          <Card.Body className='p-5'>
            <Accordion
              id='new-customer'
              className='border rounded overflow-hidden fs--1 mt-3'
              alwaysOpen
              flush
              activeKey={activeKey}
              onSelect={handleAccordionSelect}
            >
              <Card className='rounded-0'>
                <Accordion.Item eventKey='0' className='border-0'>
                  <Card.Header className='p-0'>
                    <Accordion.Button className='bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none'>
                      <div className='d-flex'>
                        <FaCaretRight className='accordion-icon me-3 mt-1' />
                        <span className='fw-medium font-sans-serif text-900'>
                          1. Information
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className='p-0'>
                    <Card.Body className='m-2'>

                      <Form.Group as={Row} className='mb-2' controlId='name'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Company name
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            type='text'
                            name='name'
                            placeholder='Company name'
                            className='fs--1'
                            {...register('name', {
                              required: 'Company name must be required.'
                            })}
                          />
                          <span className='text-danger'>
                            {errors.name && errors.name.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='country'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Country
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='country'
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={countryOptions}
                                defaultValue={defaultcountryOption}
                                placeholder='Select...'
                                classNamePrefix='react-select'
                                onChange={selectedOption =>  {
                                  setValue('country', selectedOption.value)
                                  setCountryCode(selectedOption.value.toLowerCase())
                                }}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Country is required'
                              }
                            }}
                          />
                          <span className='text-danger'>
                            {errors.country && errors.country.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2 mt-4' controlId='email'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Work Email
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            type='email'
                            name='email'
                            className='fs--1'
                            placeholder='example@pebsteel.com.vn'
                            {...register('email', {
                              //required: 'Email is required',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address'
                              }
                            })}
                          />
                          <span className='text-danger'>
                            {errors.email && errors.email.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='telephone'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Work phone
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='telephone'
                            render={({field: {ref, ...field}}) => (
                              <PhoneInput
                                {...field}
                                inputProps={{ref}}
                                country={customer ? customer.country?.toLowerCase() : countryCode }
                                autoFormat={true}
                                countryCodeEditable={customer ? true : false}
                                enableSearch={true}
                                disableSearchIcon={true}
                                inputStyle={{width: '100%'}}
                                searchStyle={{width: '95%'}}
                              />
                            )}
                            rules={{ validate: validatePhoneNumber }}
                          />
                          <span className='text-danger'>
                            {errors.telephone && errors.telephone.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='fax'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Fax
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='fax'
                            render={({field: {ref, ...field}}) => (
                              <PhoneInput
                                {...field}
                                inputProps={{ref}}
                                country={customer ? customer.country?.toLowerCase() : countryCode }
                                autoFormat={true}
                                countryCodeEditable={customer ? true : false}
                                enableSearch={true}
                                disableSearchIcon={true}
                                inputStyle={{width: '100%'}}
                                searchStyle={{width: '95%'}}
                              />
                            )}
                            rules={{ validate: validatePhoneNumber}}
                          />
                          <span className='text-danger'>
                            {errors.fax && errors.fax.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='ext_phone'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          EXT internal
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            type='text'
                            name='ext_phone'
                            placeholder='123'
                            className='fs--1'
                            {...register('ext_phone')}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2 mt-4' controlId='tax_code'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Tax code
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            type='text'
                            name='tax_code'
                            placeholder='12345678'
                            className='fs--1'
                            {...register('tax_code')}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='website'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Website
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Form.Control
                            type='text'
                            name='website'
                            placeholder='https:\\example.com'
                            className='fs--1'
                            {...register('website')}
                          />
                        </Col>
                      </Form.Group>

                      {!customer && (
                        <Form.Group as={Row} className='mb-2 mt-4' controlId='address'>
                          <Form.Label column sm={3} className='text-lg-end'>
                            Address
                          </Form.Label>
                          <Col sm={9} md={7}>
                            <GoogleMapAutoCompletePlaceDebounce setAddress={setAddress} />
                          </Col>
                        </Form.Group>
                      )}
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='1' className='border-0'>
                  <Card.Header className='p-0'>
                    <Accordion.Button className='bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none'>
                      <div className='d-flex'>
                        <FaCaretRight className='accordion-icon me-3 mt-1' />
                        <span className='fw-medium font-sans-serif text-900'>
                          2. Legal status
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className='p-0'>
                    <Card.Body className='m-2'>

                    <Form.Group as={Row} className='mb-2' controlId='legal_status'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Legal status
                        </Form.Label>
                        <Col sm={9} md={7}>
                          {CRM_LEGAL_STATUS.map((status) => (
                            <Form.Check
                              inline
                              key={status}
                              type='radio'
                              name='legal_status'
                              value={status}
                              label={status}
                              defaultChecked={status === 'Private'}
                              className='fs--1'
                              {...register('legal_status')}
                            />
                          ))}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='type_of_company'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Type of customer
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='type_of_company'
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={typeOfCustomerOptions}
                                defaultValue={defaultTypeOfCustomerOption}
                                placeholder='Select...'
                                classNamePrefix='react-select'
                                onChange={selectedOption => setValue('type_of_company', selectedOption.value)}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Type of customer is required'
                              }
                            }}
                          />
                          <span className='text-danger'>
                            {errors.type_of_company && errors.type_of_company.message}
                          </span>
                        </Col>
                      </Form.Group>
           
                      <Form.Group as={Row} className='mb-2' controlId='employess'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Number of employees
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='employess'
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={numberOfEmployeesOptions}
                                defaultValue={defaultNumberOfEmployeesOption}
                                placeholder='Select...'
                                classNamePrefix='react-select'
                                onChange={selectedOption => setValue('employess', selectedOption.value)}
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='industry'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Industry
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='industry'
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={industryOptions}
                                defaultValue={defaultIndustryOption}
                                placeholder='Select...'
                                classNamePrefix='react-select'
                                onChange={selectedOption => setValue('industry', selectedOption.value)}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Industry is required'
                              }
                            }}
                          />
                          <span className='text-danger'>
                            {errors.industry && errors.industry.message}
                          </span>
                        </Col>
                      </Form.Group>

                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='2' className='border-0'>
                  <Card.Header className='p-0'>
                    <Accordion.Button className='bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none'>
                      <div className='d-flex'>
                        <FaCaretRight className='accordion-icon me-3 mt-1' />
                        <span className='fw-medium font-sans-serif text-900'>
                          3. Responsible
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className='p-0'>
                    <Card.Body className='m-2'>
                      <Form.Group as={Row} className='mb-2' controlId='main_contact'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Main contact
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='main_contact'
                            render={() => (
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                defaultValue={defaultMainContactOption}
                                loadOptions={loadMainContactOptions}
                                closeMenuOnSelect={true}
                                placeholder='Select...'
                                classNamePrefix='react-select'
                                className='w-100'
                                onChange={selectedOption => setValue('main_contact', selectedOption.value)}
                              />
                            )}
                            rules={{
                              required: {
                                value: true,
                                message: 'Main contact is required'
                              }
                            }}
                          />
                          <span className='text-danger'>
                            {errors.main_contact && errors.main_contact.message}
                          </span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className='mb-2' controlId='secondary_contact'>
                        <Form.Label column sm={3} className='text-lg-end'>
                          Secondary contact
                        </Form.Label>
                        <Col sm={9} md={7}>
                          <Controller
                            control={control}
                            name='secondary_contact'
                            render={() => (
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                defaultValue={defaultSecondaryContactOption}
                                loadOptions={loadSecondaryContactOptions}
                                closeMenuOnSelect={true}
                                placeholder='Select...'
                                classNamePrefix='react-select'
                                className='w-100'
                                isMulti
                                onChange={handleChangeSecondaryContact}
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='3' className='border-0'>
                  <Card.Header className='p-0'>
                    <Accordion.Button className='bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none'>
                      <div className='d-flex'>
                        <FaCaretRight className='accordion-icon me-3 mt-1' />
                        <span className='fw-medium font-sans-serif text-900'>
                          4. Upload logo
                        </span>
                      </div>
                    </Accordion.Button>
                  </Card.Header>
                  <Accordion.Body className='p-0'>
                    <Card.Body className='m-2'>
                      <Form.Group as={Row} className='mb-2' controlId='logo'>
                        <Form.Label column sm={3} className='text-lg-end'></Form.Label>
                        <Col sm={9} md={7}>
                          <CustomerUploadLogo {...{logo, setLogo, customer}}/>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            </Accordion>
          </Card.Body>

          <div className='bg-light fixed-bottom border-0 text-center p-3 ms-5'>
            <Button size='sm' type='submit' className='me-2'><FaSave /> Save</Button>
            <Button size='sm' variant='danger' onClick={handleCancle}><FaBan /> Cancel</Button>
          </div>
        </Form>
      </Card>
    </>
  )
};

CustomerForm.propTypes = {
  title: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired
}

export default CustomerForm;
