import IconAlert from "components/common/IconAlert";
import { Button, Modal } from "react-bootstrap";
import { FaBan, FaCheckCircle } from "react-icons/fa";
import React from 'react';
import PropTypes from 'prop-types';

const ConfirmationModal = ({ show, onHide, onConfirm, message }) => {
  
  return (
    <>
      <Modal
        className="fs--1"
        show={show}
        size="sm"
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
      >
        <Modal.Header closeButton className="border-0">
          <div id="contained-modal-title-vcenter fs-0 text-300">Confirmation!</div>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            { message === undefined ? 'Are you sure ?' : message }
          </IconAlert>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" variant="primary" onClick={onConfirm}><FaCheckCircle className="me-2" /> OK</Button>
          <Button size="sm" variant="danger" onClick={onHide}><FaBan /> Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  ) 
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
}

export default ConfirmationModal;