import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useState, useMemo, Suspense, lazy, useCallback, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { FaRegMinusSquare, FaPlusCircle, FaShareSquare, FaCalendarCheck, FaVoteYea } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate, formatNumber } from "helpers/utils";
import { BsPlusSquare } from "react-icons/bs";
import ShopPhaseDetailTable from "./detail/ShopPhaseDetailTable";
import { removeShopRevisionDocument } from "redux/crm/project/changeOrderSlice";
import { useDispatch } from "react-redux";
import DocumentAttachment from "components/upload/DocumentAttachment";
import PropTypes from 'prop-types';

const LazyShopRequestToENG = lazy(() => import("./ShopRequestToENG"));
const LazyShopScheduleToCSD = lazy(() => import("./ShopScheduleToCSD"));
const LazyShopReleaseToCSD = lazy(() => import("./ShopReleaseToCSD"));
const LazyShopPhaseDetailForm= lazy(() => import("./detail/ShopPhaseDetailForm"));
const LazyShopPhaseDetailDeleteConfirm= lazy(() => import("./detail/ShopPhaseDetailDeleteConfirm"));

const ShopDetailTable = ({changeOrder, editPhase, deletePhase, refreshCount}) => {
  const [showRequestShop, setShowRequestShop] = useState(false)
  const [showScheduleShop, setShowScheduleShop] = useState(false)
  const [showReleaseShop, setShowReleaseShop] = useState(false)
  const [phase, setPhase] = useState(null)
  const [detail, setDetail] = useState(null)
  const [title, setTitle] = useState(null)
  const [showShopPhaseDetailForm, setShowShopPhaseDetailForm] = useState(false)
  const [showShopPhaseDetailDelete, setShowShopPhaseDetailDelete] = useState(false)

  const data = changeOrder?.phases || []
  const dispatch = useDispatch()

  useEffect(() => {
    console.log('ShopDetailTable has been refreshed!')
  }, [refreshCount])

  const handleRequestShop = (phase) => {
    setPhase(phase)
    setShowRequestShop(true)
  }

  const handleScheduleShop = (phase) => {
    setPhase(phase)
    setShowScheduleShop(true)
  }

  const handleReleaseShop = (phase, detail) => {
    setPhase(phase)
    setDetail(detail)
    setShowReleaseShop(true)
  }

  const handleNewRevision = (phase) => {
    setPhase(phase)
    setTitle(`New Revision - ${phase.name}`)
    setShowShopPhaseDetailForm(true)
  }

  const handleEditRevision = (phase, detail) => {
    setPhase(phase)
    setDetail(detail)
    setTitle(`Edit Revision - ${phase.name}`)
    setShowShopPhaseDetailForm(true)
  }

  const handleDeleteRevision = (phase, detail) => {
    setPhase(phase)
    setDetail(detail)
    setShowShopPhaseDetailDelete(true)
  }

  const handleRemoveShopDetailDocument = (phaseId, detailId, documentId) => {
    const {project, building} = changeOrder || {};

    dispatch(removeShopRevisionDocument({
      projectId: project?.id,
      buildingId: building?.id,
      changeOrderId: changeOrder?.id,
      phaseId,
      detailId,
      data: { document_id: documentId }
    }));
  }

  const columns = useMemo(() => [
    {
      Header: " ",
      columns: [
        {
          Header: ' ',
          Cell: rowData => {
            const phase = rowData.row.original;
    
            const {
              allow_edit,
              allow_delete,
              allow_request_to_eng,
              allow_schedule_to_csd,
             // allow_release_to_csd,
              allow_create_shop_revision
            } = phase?.permissions || {};
    
            return (
              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                <Dropdown.Toggle split variant="falcon-default" size="sm">
                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                </Dropdown.Toggle>
    
                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
          
                    {allow_create_shop_revision && (
                      <>
                        <Dropdown.Item onClick={() => handleNewRevision(phase)} className="mb-1">
                          <FaPlusCircle /> New Revision
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    )}

                    {allow_request_to_eng && (
                      <>
                        <Dropdown.Item onClick={() => handleRequestShop(phase)} className="mb-1">
                          <FaShareSquare /> Request to ENG
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    )}
    
                    {allow_schedule_to_csd && (
                      <>
                        <Dropdown.Item onClick={() => handleScheduleShop(phase)} className="mb-1 text-primary">
                          <FaCalendarCheck /> Schedule to CSD
                        </Dropdown.Item>
                        <Dropdown.Divider />
                       </>
                    )}
    
                    {/* {allow_release_to_csd && (
                      <>
                        <Dropdown.Item onClick={() => handleReleaseShop(phase)} className="mb-1 text-primary">
                          <FaVoteYea /> Release to CSD
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    )}
                    */}

                    {allow_edit && (
                      <>
                        <Dropdown.Item onClick={() => editPhase(phase)} className="mb-1">
                          <FontAwesomeIcon icon="edit" /> Edit
                        </Dropdown.Item>
                      </>
                    )}
    
                    {allow_delete && (
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item className="mb-1 text-danger" onClick={() => deletePhase(phase)}>
                          <FontAwesomeIcon icon="trash-alt" /> Delete
                        </Dropdown.Item>
                      </>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )
          },
          cellProps: {
            className: "fixed-column"
          }
        },
        {
          id: "expanderShop",
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? <FaRegMinusSquare /> : <BsPlusSquare />}
            </span>
          ),
          Cell: ({ row }) => {
            const details = row.original.details

            return (
              details.length > 0 ? (
                <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <FaRegMinusSquare /> : <BsPlusSquare />}
                </span>
              ) : null
            )
          }
        },
        {
          Header: 'Name',
          accessor: 'name'
        },
        {
          Header: 'Description',
          accessor: 'description',
        },
        {
          Header: 'BOM (M.T)',
          accessor: 'bom_weight',
          Cell: ({row}) => formatNumber(row.original.bom_weight),
          cellProps: {
            className: "text-end text-primary"
          }
        },
      ]
    },
    {
      Header: "Shop Drawing",
      columns: [
        {
          Header: 'Requested',
          accessor: 'shop_requested',
          Cell: ({row}) => formatDate(row.original.shop_requested)
        },
        {
          Header: 'Schedule',
          accessor: 'shop_scheduled',
          Cell: ({row}) => formatDate(row.original.shop_scheduled)
        },
        {
          Header: 'Release',
          accessor: 'shop_released',
          Cell: ({row}) => formatDate(row.original.shop_released)
        },
        {
          accessor: 'detailer',
          Header: 'Detailer',
          cellProps:{
            className:'text-center'
          }
        },
        {
          accessor: 'checker',
          Header: 'Checker',
          cellProps:{
            className:'text-center'
          }
        },
      ],
      cellProps: {
        className: "text-center"
      }
    },
    {
      Header: "Erection Drawing",
      columns: [
        {
          accessor: 'erc_requested',
          Header: 'Requested',
          Cell: ({row}) => formatDate(row.original.erc_requested)
        },
        {
          accessor: 'erc_scheduled',
          Header: 'Schedule',
          Cell: ({row}) => formatDate(row.original.erc_scheduled)
        },
        {
          accessor: 'erc_released',
          Header: 'Release',
          Cell: ({row}) => formatDate(row.original.erc_released)
        }
      ],
      cellProps: {
        className: "text-center"
      }
    },
    {
      Header: " ",
      columns: [
        {
          accessor: 'lastest_revision',
          Header: 'Lastest REV#',
          cellProps: {
            className: "text-center"
          }
        },
        {
          Header: (
            <><MdAttachFile /> Documents</>
          ),
          accessor: "documents",
          Cell: rowData => {
            const phase = rowData.row.original

            const {
              allow_view_document,
              allow_download_document
            } = phase?.permissions
    
            return (
              <>
                {phase.lastest_documents && phase.lastest_documents.length > 0 && (
                  <div className="pb-2 mt-3">
                    {phase.lastest_documents.map(attachment => (
                      <DocumentAttachment
                        {...attachment}
                        key={attachment.id}
                        allowView={allow_view_document}
                        allowDownload={allow_download_document}
                        isTable
                      />
                    ))}
                  </div>
                )}
              </>
            )
          }
        },
        {
          accessor: 'eng_notes',
          Header: 'ENG note',
          cellProps: {
            className: "text-danger"
          }
        },
        {
          accessor: 'created_by',
          Header: 'Created by',
          cellProps: {
            className: "text-center"
          }
        },
        {
          accessor: 'created_date',
          Header: 'Created date',
        },
      ]
    }
  ], [])

  const renderPhaseDetailComponent = useCallback(
    ({ row }) => {
      const dataDetail = row.original?.details || []

      return <ShopPhaseDetailTable
                phase={row.original}
                data={dataDetail}
                onReleaseShop={handleReleaseShop}
                editRevision={handleEditRevision}
                deleteRevision={handleDeleteRevision}
                removeShopDetailDocument={handleRemoveShopDetailDocument}
              />
    }, []
  )

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-2">
          <Col xs="auto" sm={5} lg={3}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
          renderExpandComponent={renderPhaseDetailComponent}
        />
        <div className="mt-3 mb-2">
          <AdvanceTablePagination
            table
          />
        </div>
      </AdvanceTableWrapper>

      <Suspense fallback={<div>Loading....</div>}>
        {showRequestShop && (
          <LazyShopRequestToENG
            showModal={showRequestShop}
            setshowModal={setShowRequestShop}
            changeOrder={changeOrder}
            phase={phase}
          />
        )}

        {showScheduleShop && (
          <LazyShopScheduleToCSD
            showModal={showScheduleShop}
            setshowModal={setShowScheduleShop}
            changeOrder={changeOrder}
            phase={phase}
          />
        )}

        {showReleaseShop && (
          <LazyShopReleaseToCSD
            showModal={showReleaseShop}
            setshowModal={setShowReleaseShop}
            changeOrder={changeOrder}
            phase={phase}
            detail={detail}
          />
        )}

        {showShopPhaseDetailForm && (
          <LazyShopPhaseDetailForm
            showModal={showShopPhaseDetailForm}
            setshowModal={setShowShopPhaseDetailForm}
            changeOrder={changeOrder}
            phase={phase}
            detail={detail}
            title={title}
          />
        )}

        {showShopPhaseDetailDelete && (
          <LazyShopPhaseDetailDeleteConfirm
            showModal={showShopPhaseDetailDelete}
            setshowModal={setShowShopPhaseDetailDelete}
            changeOrder={changeOrder}
            phase={phase}
            detail={detail}
          />
        )}
      </Suspense>
    </>
  )
};

ShopDetailTable.propTypes = {
  changeOrder: PropTypes.object.isRequired,
  editPhase: PropTypes.func.isRequired,
  deletePhase: PropTypes.func.isRequired,
  refreshCount: PropTypes.func.isRequired
}

export default ShopDetailTable;