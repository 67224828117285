import { Row, Col, Form } from "react-bootstrap";
import Flex from "components/common/Flex";
import DetailBDSGeneralInformation from "./DetailBDSGeneralInformation";
import React from 'react';
import PropTypes from 'prop-types';

const DetailBDS3 = ({project, building, adaptation, area}) => {
  const {bds3} = area;

  return (
    <div className="fs--1 border p-3">
      <DetailBDSGeneralInformation
        project={project}
        building={building}
        adaptation={adaptation}
        area={area}
      />
      <h6 className="text-center my-2">This area only : Page 3 of 3</h6>

      <Row className="mt-5 mb-3">
        <Col className="fw-semi-bold">1. Please design the most economical purlin, girt spacing.</Col>
        <Col sm={7}>
          <span className="text-primary">{bds3.purlin_girt_spacing}</span>
        </Col>
      </Row>

      <div className="mb-3">
        <Row>
          <Col className="fw-semi-bold">2. Deflection criterion:</Col>
          <Col sm={7}>
            <Flex justifyContent="between">
              <Form.Check
                type="checkbox"
                label="Follow building code"
                checked={bds3.follow_building_code}
              />

              <Form.Check
                type="checkbox"
                label="Follow peb standard"
                checked={bds3.follow_peb_standard}
              />

              <Form.Check
                type="checkbox"
                label="Follow requested of client"
                checked={bds3.follow_requested_of_client}
              />
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col>Requested of Client:</Col>
          <Col sm={7}>
            <span className="text-primary" dangerouslySetInnerHTML={{ __html: bds3.follow_requested_of_client_specify }}></span>
          </Col>
        </Row>
      </div>

      <Row className="mb-3">
        <Col className="fw-semi-bold">3. Environment conditions:</Col>
        <Col sm={8}>
          <Flex justifyContent="between">
            <Form.Check
              type="checkbox"
              label="Fertilizer"
              checked={bds3.fertilizer}
            />

            <Form.Check
              type="checkbox"
              label="Chemical substance"
              checked={bds3.chemical_substance}
            />

            <Form.Check
              type="checkbox"
              label="Animal feed"
              checked={bds3.animal_feed}
            />

            <Form.Check
              type="checkbox"
              label="Animal shed"
              checked={bds3.animal_shed}
            />

            <Form.Check
              type="checkbox"
              label="From the sea 100m"
              checked={bds3.from_the_sea_100m}
            />
          </Flex>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="fw-semi-bold">4. How many cranes can operate at the same time in the same bay?</Col>
        <Col sm={7}>
          <Flex justifyContent="between">
            <Form.Check
              type="checkbox"
              label="2"
              checked={bds3.crane_operate_2}
            />

            <Form.Check
              type="checkbox"
              label="3"
              checked={bds3.crane_operate_3}
            />

            <Form.Check
              type="checkbox"
              label="4"
              checked={bds3.crane_operate_4}
            />

            <Form.Check
              type="checkbox"
              label="Not Apply"
              checked={bds3.crane_operate_not_apply}
            />
          </Flex>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="fw-semi-bold">5. Is Internal X-Bracing allowed?</Col>
        <Col sm={7}>
          <Flex justifyContent="between">
            <Form.Check
              type="checkbox"
              label="Yes"
              checked={bds3.internal_xbracing_yes}
            />
            
            <Form.Check
              type="checkbox"
              label="No"
              checked={bds3.internal_xbracing_no}
            />

            <Form.Check
              type="checkbox"
              label="Not Apply"
              checked={bds3.internal_xbracing_not_apply}
            />
          </Flex>
        </Col>
      </Row>
      
      <Row className="mb-3">
        <Col>
          <div className="fw-semi-bold">6. The Top Coat Paint shall be Flat or Matte Finish ( Very low on Reflectivity)</div>
          <div className="mt-3">
            <span dangerouslySetInnerHTML={{ __html: bds3.top_coat_paint }}></span>
          </div>
        </Col>
      </Row>
    </div>
  )
};

DetailBDS3.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired
}

export default DetailBDS3;