import React from 'react';
import SoftBadge from "components/common/SoftBadge"
import { formatNumber } from "helpers/utils"
import { Table, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';

const BookingJobTransactions = ({booking}) => {
  const {job_transactions} = booking

  return (
    <div className="table-responsive fs--1 m-3">
      <Row className="mb-4">
        <Col xs={2} sm={4}>
          <p className="fw-semi-bold fs--1 mb-1">Job number:</p>
        </Col>
        <Col>
          <p className="mb-1 fs--1 text-primary">{booking.job_number}</p>
        </Col>
      </Row>

      <Table striped>
        <thead className="bg-200 text-900">
          <tr>
            <th className="border-0">Trip ID#</th>
            <th className="border-0">Trip name</th>
            <th className="border-0">Travelers</th>

            <th className="border-0 text-end">Expense currency</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {job_transactions && job_transactions.map((trip) => (
            <tr className="border-200" key={trip.id}>
              <td className="text-start">
                <Link to={`/booking/travel/${trip.id}`} target="_blank">{trip.id}</Link>
              </td>
              <td className="text-start">{trip.trip_name}</td>
              <td className="text-start">
                {trip.travelers && trip.travelers.map((name) => (
                  <SoftBadge bg="primary" className="me-1">{name}</SoftBadge>
                ))}
              </td>
              <td className="text-end">${trip.expense_currency}</td>
              <td className="text-danger">
                {trip.id === booking.id ? "Current booking" : ""}
              </td>
            </tr>
          ))}
          
          <tr className="border-top fw-bold">
            <td colSpan={3} className="text-start">Total expense</td>
            <td className="text-end text-primary fs-0">${formatNumber(booking.total_expense_by_job)}</td>
            <td></td>
          </tr>
          <tr className="border-top fw-bold">
            <td colSpan={3} className="text-start">Budget remaining</td>
            <td className="text-end text-danger fs-0">${formatNumber(booking.project && booking.project.budget_remaining_currency) ?? 0.00}</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
};

BookingJobTransactions.propTypes = {
  booking: PropTypes.object.isRequired
}

export default BookingJobTransactions;