import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip, Badge } from 'react-bootstrap';
import { formatNumberWord, rgbaColor } from 'helpers/utils';
import BasicECharts from 'components/common/BasicEChart';

import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getMonthName = (monthNumber) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber];
}

getMonthName.propTypes = {
  monthNumber: PropTypes.array.isRequired
};

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    formatter: function(params) {
      const date = new Date(params[0].axisValue);
      const formattedDate = `${date.getDate()}-${getMonthName(date.getMonth())}`;
      return formattedDate;
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'bar',
      data,
      smooth: true,
      lineStyle: {
        width: 2
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('success'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('success'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

getOptions.propTypes = {
  data: PropTypes.array.isRequired
};

const donutOptions = {
  tooltip: chartJsDefaultTooltip(),
  rotation: -90,
  circumference: '180',
  cutout: '80%',
  plugins: {
    legend: {
      display: false
    },
    tooltip: chartJsDefaultTooltip()
  }
};

const LeadSummary = ({data}) => {
  const {current_period_data = [], number_of_leads_previous_period} = data || [];

  const totalNumberLeads = current_period_data?.length || 0;
  const totalNumberLeadsPreviousPeriod = number_of_leads_previous_period || 0;

  const opportunityData = current_period_data.filter(item => item.status_id === 4) //Convert to PCV
  const opportunityNumber = opportunityData?.length || 0;

  const sqlData = current_period_data.filter(item => item.stage === "SQL");
  //const mqlData = current_period_data.filter(item => item.stage === "MQL");

  const sqlNumber = sqlData?.length || 0;
  const mqlNumber = sqlData?.length || 0;

  const sortedData = current_period_data.slice().sort((a, b) => {
    const dateA = new Date(a.created_date);
    const dateB = new Date(b.created_date);
    
    if (isNaN(dateA) || isNaN(dateB)) {
        console.error("Invalid created date:", a.created_date, b.created_date);
        return 0;
    }
    
    return dateA - dateB;
  });

  const groupedData = sortedData.reduce((acc, item) => {
    const date = item.created_date;
    if (!acc[date]) {
      acc[date] = {number_of_projects: 1};
    }
    else {
      acc[date].number_of_projects++;
    }

    return acc;
  }, {});

  const chartDataPCVNumber = Object.entries(groupedData).map(([date, { number_of_projects }]) => {
    return [date, number_of_projects];
  });

  const mqlDonutData = {
    labels: ['MQL', 'Leads'],
    datasets: [
      {
        data: [mqlNumber, totalNumberLeads],
        backgroundColor: [getColor('primary'), getColor('gray-300')],
        borderColor: [getColor('primary'), getColor('gray-300')]
      }
    ]
  };

  const sqlDonutData = {
    labels: ['SQL', 'Leads'],
    datasets: [
      {
        data: [sqlNumber, totalNumberLeads],
        backgroundColor: [getColor('info'), getColor('gray-300')],
        borderColor: [getColor('info'), getColor('gray-300')]
      }
    ]
  };

  const opportunityRatioPercent = totalNumberLeads > 0 ? ((parseFloat(opportunityNumber) / parseFloat(totalNumberLeads)) * 100)?.toFixed(0) : 0;

  const handleViewDetail = (e, key) => {
    e.preventDefault();
    window.location.href = key;
  }
  
  return (
    <Card className="h-100 mb-3">
      <Card.Body className="pb-0">
        <Row>
          <Col md={3} sm={12} className="border-end">
            <h6 className="text-700">New Leads</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-primary">{formatNumberWord(totalNumberLeads)}</span>
                <span className="text-500 fs--1 ms-1">
                  vs {formatNumberWord(totalNumberLeadsPreviousPeriod)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataPCVNumber)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
            <div className="text-end my-2">
              <Link className="fs--1" onClick={(e) => handleViewDetail(e, "#lead-entry")}>View detail</Link>
            </div>
          </Col>
          <Col md={3} sm={12} className="border-end">
            <h6 className="text-700">Marketing Qualify Leads</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-primary">{formatNumberWord(sqlNumber)}</span>
                <span className="text-500 fs--1 ms-1">
                  / {formatNumberWord(totalNumberLeads)}
                </span>
              </div>
              <div className="text-center">
                <Doughnut
                  data={mqlDonutData}
                  options={donutOptions}
                  style={{ height: "30px" }}
                />
              </div>
            </Flex>
            <div className="text-end">
              <Link className="fs--1" onClick={(e) => handleViewDetail(e, "#lead-mql")}>View detail</Link>
            </div>
          </Col>
          <Col md={3} sm={12} className="border-end">
            <h6 className="text-700">Sales Qualify Leads</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-primary">{formatNumberWord(sqlNumber)}</span>
                <span className="text-500 fs--1 ms-1">
                  / {formatNumberWord(totalNumberLeads)}
                </span>
              </div>
              <div className="text-center">
                <Doughnut
                  data={sqlDonutData}
                  options={donutOptions}
                  style={{ height: "30px" }}
                />
              </div>
            </Flex>
            <div className="text-end my-2">
              <Link className="fs--1" onClick={(e) => handleViewDetail(e, "#lead-sql")}>View detail</Link>
            </div>
          </Col>
          <Col md={3} sm={12}>
            <h6 className="text-700">Opportunity</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-success">{formatNumberWord(opportunityNumber)}</span>
                <span className="text-500 fs--1 ms-1">
                  / {formatNumberWord(totalNumberLeads)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the total Leads in this period.
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div className="text-end h5">
                <Badge pill>{opportunityRatioPercent}%</Badge>
              </div>
            </Flex>
            <div className="text-end my-2">
              <Link className="fs--1" onClick={(e) => handleViewDetail(e, "#converted-opportunities")}>View detail</Link>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

LeadSummary.propTypes = {
  data: PropTypes.array.isRequired
};

export default LeadSummary;