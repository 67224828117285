import React, { Suspense, lazy } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { MdCheck, MdLock, MdNewReleases, MdOnlinePrediction, MdListAlt } from 'react-icons/md';
import UserHeader from './user/UserHeader';

const AllList = lazy(() => import('./user/list/All'));
const ActiveList = lazy(() => import('./user/list/Activated'));
const DeactiveList = lazy(() => import('./user/list/Deactivated'));
const OnlineList = lazy(() => import('./user/list/Online'));
const CreatedThisMonthList = lazy(() => import('./user/list/CreatedThisMonth'));

const Users = () => {
  const { loading } = useSelector((state) => state.authorization_user);

  return (
    <>
      <UserHeader />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="user-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom fs--1">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="user-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="user-active"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdCheck className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Active</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="user-deactive"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdLock className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> De-activated</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="user-created-this-month"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdNewReleases className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Created this month</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="user-online"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOnlinePrediction className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Online</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="user-all">
                      <AllList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="user-active">
                      <ActiveList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="user-deactive">
                      <DeactiveList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="user-created-this-month">
                      <CreatedThisMonthList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="user-online">
                      <OnlineList />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Users;
