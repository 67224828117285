import EntryItem from "../components/EntryItem";
import {Card} from "react-bootstrap";
import React from 'react';
import PropTypes from 'prop-types';

const CustomerSignedContract = ({data=[]}) => {
  const capitalize = (str) => {
    return str.toLowerCase().replace(/\b\w/g, c => c.toUpperCase() );
  }

  const customerData = data.reduce((acc, item) => {
    const { contract_weight} = item || {};

    const customerName = capitalize(item.customer_name);

    if (!acc[customerName]) {
      acc[customerName] = {number_of_projects: 1, weight: parseFloat(contract_weight)};
    }
    else {
      acc[customerName].number_of_projects++;
      acc[customerName].weight += parseFloat(contract_weight);
    }

    return acc;
  }, {});

  return (
    <Card className="h-100 mb-3">
      <Card.Header className="bg-light text-primary h5">
        Customer Signed contract
      </Card.Header>
      <Card.Body>
        <EntryItem data={customerData} color="success" />
      </Card.Body>
    </Card>
  );
};

CustomerSignedContract.propTypes = {
  data: PropTypes.array.isRequired
}

export default CustomerSignedContract;