import React, { useCallback, useEffect } from 'react';
import CreatePost from './CreatePost';
import FeedCard from './FeedCard';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getFeeds, setCurrentPage, resetData } from 'redux/social/feedSlice';
import {Spinner} from "react-bootstrap";

const FeedContent = () => {
  const {data: feeds, paginate, loading} = useSelector(state => state.social_feed);
  const {currentPage, pageSize, hasMore} = paginate || {};

  console.log(feeds)

  const dispatch = useDispatch();

  useEffect(() => {
    // Reset to the first page on component mount
    dispatch(resetData());
  }, []);

  useEffect(() => {
    const newParams = { page: currentPage, per_page: pageSize };
    dispatch(getFeeds(newParams));
  }, [currentPage, pageSize]);

  const loadMoreFeeds = useCallback(() => {
    if (hasMore && !loading) {
      const nextPage = currentPage + 1
      dispatch(setCurrentPage(nextPage));
    }
  }, [currentPage, pageSize, hasMore, loading]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight) {
      loadMoreFeeds();
    }
  }, [loadMoreFeeds]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <CreatePost className="mb-3" />
      {feeds.map((feed, index) => (
        <FeedCard
          key={feed.id}
          feed={feed}
          className={classNames({
            'mb-3': index + 1 !== feeds.length,
            'mb-3 mb-lg-0': index + 1 === feeds.length
          })}
        />
      ))}

      {loading && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default FeedContent;
