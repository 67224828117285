import React from 'react';
import StarGroup from "./StarGroup";
import PropTypes from 'prop-types';

const RatingScore = ({rating}) => {
  let score = 0

  switch(rating) {
    case "Cold":
      score = 1;
      break;
    case "Warm":
      score = 2;
      break;
    case "Hot":
      score = 3;
      break;

    default:
      score = 0;
      break;
  }

  return (
    <>
      <StarGroup number={score} /> <span className="text-primary">{rating}</span>
    </>
  )
};

RatingScore.propTypes = {
  rating: PropTypes.string.isRequired
}

export default RatingScore;