import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { assignDriverTran, getCarsBySite, getDriversBySite } from "redux/booking/car/carBookingSlice";
import { MdOutlineAssessment } from "react-icons/md";
import { FaBan } from "react-icons/fa";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

const AssignDriverFormSingle = ({showModal, setshowModal, transaction}) => {
  const { control, formState: {errors}, handleSubmit, setValue } = useForm();
  const [drivers, setDrivers] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);

  const dispatch = useDispatch();

  const params = {
    from_date: transaction.from_date,
    to_date: transaction.to_date,
    site_id: transaction.site_id
  }

  useEffect(() => {
    fetchCarList()
    fetchDriverList()
  }, []);

  const fetchCarList = async () => {
    try {
      const response = await dispatch(getCarsBySite(params))
      const data = response.payload

      const options = data && data.map((item) => ({ value: item.id, label: item.name }))
      setCarOptions(options)
    }
    catch(error) {
      console.error(error);
      return [];
    }
  }

  const fetchDriverList = async () => {
    try {
      const response = await dispatch(getDriversBySite(params))
      const data = response.payload
      setDrivers(data)

      const options = data && data.map((item) => ({ value: item.id, label: item.name }))
      setDriverOptions(options)
    }
    catch(error) {
      console.error(error);
      return [];
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleDriverChange = (value) => {
    setValue("driver_id", value);

    const selectedDriver = drivers.find(driver => driver.id === value);

    if (selectedDriver) {
      setValue("car_id", selectedDriver.car_id);
    }
  }

  const onSubmit = (data) => {
    console.log(data);
    const transactionId = transaction.id

    try {
      dispatch(assignDriverTran({transactionId, data}))
      closeModal();
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><MdOutlineAssessment /> Assign Driver for car booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-2" controlId="driver_id">
            <Form.Label column sm={3} className="text-lg-end">
              Driver name
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="driver_id"
                render={({field}) => (
                  <Select
                    closeMenuOnSelect={true}
                    options={driverOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    {...field}
                    value={driverOptions.find(option => option.value === field.value)}
                    onChange={selectedOption => handleDriverChange(selectedOption.value)}
                  />
                )}
                rules={{
                  required: 'Driver must be required'
                }}
              />
              <span className="text-danger">
                {errors.driver_id && errors.driver_id.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="car_id">
            <Form.Label column sm={3} className="text-lg-end">
              Car name
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="car_id"
                render={({field}) => (
                  <Select
                    closeMenuOnSelect={true}
                    options={carOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    {...field}
                    value={carOptions.find(option => option.value === field.value)}
                    onChange={selectedOption => { 
                      setValue('car_id', selectedOption.value)
                      console.log(selectedOption.value)
                    }}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Car must be required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.car_id && errors.car_id.message}
              </span>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button
            size="sm"
            type="submit"
          >
            <MdOutlineAssessment /> Assign
          </Button>
          <Button size="sm" variant="falcon-default" onClick={closeModal}><FaBan /> Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AssignDriverFormSingle.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired
}

export default AssignDriverFormSingle;