import { useLocation } from "react-router-dom";
import CustomerForm from "./CustomerForm";
import React from 'react';

const EditCustomer = () => {
  const location = useLocation()
  const customer = location.state

  return (
    <CustomerForm
      title="Edit Customer"
      customer={customer}
     />
  )
}

export default EditCustomer;