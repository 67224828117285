import { Form, Offcanvas, Row, Col, Button } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IconAlert from "components/common/IconAlert";
import { getExchangeRate } from "redux/settingsSlice";
import { useDispatch } from "react-redux";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {isAfter} from "date-fns"
import { createBookingHotel, updateBookingHotel } from "redux/booking/travel/travelBookingSlice";
import { toast } from "react-toastify";
import BookingUploadAttachment from "../../BookingUploadAttachment";
import PropTypes from 'prop-types';
import TinymceEditor from "components/common/TinymceEditor";

const HotelForm = ({showModal, setshowModal, booking, hotel, transit}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues, reset } = useForm()
  const {usdExchangeRateData} = useSelector((state) => state.settings)
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch()
  const showExchangeRate = booking.currency == "USD"
  const bookingId = booking.id
  const title = hotel ? "Edit" : "Create"

  useEffect(() => {
    if (!hotel) {
      reset()
    }
  }, [hotel])

  useEffect(() => {
    //get exchange rate
    if (showExchangeRate) {
      dispatch(getExchangeRate())
    }
  }, [showExchangeRate])

  useEffect(() => {
    setValue('check_in_time', new Date())
    setValue('exchange_rate', usdExchangeRateData)
    setValue('currency', booking.currency)

    if(hotel) {
      Object.entries(hotel).forEach(([key, value]) => {
        if (key === 'check_in_time' || key === 'check_out_time') {
          if (value) {
            const dateValue = new Date(value)
            setValue(key, dateValue);
          }
        }
        else {
          setValue(key, value)
        }
      })
    }

  }, [[setValue, booking, hotel]])

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = (data) => {
    console.log(data)

    if (transit) {
      data = {...data, transit_id: transit.id}
    }

    if (files) {
      data = {...data, files}
    }

    try {
      let hotelId = ""

      if (hotel) {
        hotelId = hotel.id
        dispatch(updateBookingHotel({bookingId, hotelId, data }))
      }
      else {
        dispatch(createBookingHotel({ bookingId, data }))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  return (
    <Offcanvas
      show={showModal}
      onHide={closeModal}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '50%'}}
    >
      <Offcanvas.Header className="bg-light" closeButton>
        <Offcanvas.Title>
          {title} Hotel booking <FontAwesomeIcon icon="hotel" className="text-primary" />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        
        <IconAlert variant="warning" className="mt-2">
          <p className="mb-0">Book a hotel for the trip</p>
        </IconAlert>

        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="name"
                placeholder="Name of hotel"
                className="fs--1"
                {...register("name", {
                  required: "Name of hotel must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="check_in_time">
            <Form.Label column sm={3} className="text-lg-end">
              Check in
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="check_in_time"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                )}
                rules={{
                  required: "Check in time must be required."
                }}
              />
              <span className="text-danger">
                {errors.check_in_time && errors.check_in_time.message}
              </span>    
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="check_out_time">
            <Form.Label column sm={3} className="text-lg-end">
              Check out
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="check_out_time"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                )}
                rules={{
                  required: "Check out time must be required.",
                  validate: {
                    afterFromDate: value => {
                      const fromDate = getValues('check_in_time');
                      return isAfter(value, fromDate) || 'Check out must be after check in time.';
                    },
                  },
                }}
              />
              <span className="text-danger">
                {errors.check_out_time && errors.check_out_time.message}
              </span>    
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="tel">
            <Form.Label column sm={3} className="text-lg-end">
              Telephone
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="tel"
                placeholder="Telephone"
                className="fs--1"
                {...register("tel", {
                  required: "Telephone must be required."
                })}
              />
              <span className="text-danger">
                {errors.tel && errors.tel.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="address">
            <Form.Label column sm={3} className="text-lg-end">
              Address
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={5}
                name="address"
                placeholder="Address of hotel"
                className="fs--1"
                {...register("address", {
                  required: "Address must be required."
                })}
              />
              <span className="text-danger">
                {errors.address && errors.address.message}
              </span>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-2 mt-4" controlId="cost_currency">
            <Form.Label column sm={3} className="text-lg-end">
              Amount currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="cost_currency"
                placeholder="Amount currency"
                className="fs--1"
                {...register("cost_currency", {
                  required: "Amount currency must be required."
                })}
              />
              <span className="text-danger">
                {errors.cost_currency && errors.cost_currency.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="currency">
            <Form.Label column sm={3} className="text-lg-end">
              Currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="currency"
                placeholder="Currency"
                className="fs--1"
                disabled
                {...register("currency", {
                  required: "Currency must be required."
                })}
              />
              <span className="text-danger">
                {errors.currency && errors.currency.message}
              </span>
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Currency in accordance with the trip's currency.</div>
            </Col>
          </Form.Group>

          {showExchangeRate && (
            <Form.Group as={Row} className="mb-2" controlId="exchange_rate">
              <Form.Label column sm={3} className="text-lg-end">
                Exchange rate
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="exchange_rate"
                  placeholder="Exchange rate"
                  className="fs--1"
                  {...register('exchange_rate', {
                    required: 'Exchange rate must be required'
                  })}
                />
                <span className="text-danger">
                  {errors.exchange_rate && errors.exchange_rate.message}
                </span>
                <IconAlert variant="info" className="mt-2">
                  <p className="mb-0">USD exchange rate to VND from <a href="https://www.vietcombank.com.vn/KHCN/Cong-cu-tien-ich/Ty-gia?devicechannel=default" target="_blank" rel="noreferrer">Vietcombank</a>.</p>
                </IconAlert>
              </Col>
            </Form.Group>
          )}
    
          <Form.Group as={Row} className="mb-2" controlId="notes">
            <Form.Label column sm={3} className="text-lg-end">
              Note
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <TinymceEditor
                    height="30vh"
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="attachment">    
              <Form.Label column sm={3} className="text-lg-end">
                Attachments
              </Form.Label>
              <Col sm={9} md={7}>
                <BookingUploadAttachment files={files} setFiles={setFiles} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col className="my-4">
                <Flex justifyContent="center">
                  <Button size="sm" type="submit">Save</Button>
                  <Button variant="danger" size="sm" className="ms-2" onClick={closeModal}>Cancel</Button>
                </Flex>
              </Col>
            </Form.Group>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

HotelForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  transit: PropTypes.object.isRequired
}

export default HotelForm;