const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaCalendar, FaInfoCircle, FaBolt } from 'react-icons/fa';
import React, { lazy, Suspense } from "react";
import { monthNameMapDecimal } from 'helpers/utils';
import PropTypes from'prop-types';

const LazyEntryItem = lazy(() => import("../components/EntryItem"));
const LazySignedForecastDetail = lazy(() => import("./SignedForecastDetail"));

const SignedContractForecast = ({originalData}) => {
  const {monthly, sales_regions, data} = originalData?.signed_contract_forecast || {};
  const COLOR = "warning";

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMapDecimal[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="became-job">
        <Card.Header className="bg-light text-primary h5">
          <FaBolt className="text-warning"/> Signed contract forecast
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Forecast PCV signed contracts monthly or by sales regions for the period.</p>

          <Tabs defaultActiveKey="pcv-monthly" id="pcv-entry" transition={false}>
            <Tab eventKey="pcv-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyEntryItem data={convertToMonthNames(monthly)} color={COLOR} defaultWeight={false} />
              </Suspense>
            </Tab>
            <Tab eventKey="pcv-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyEntryItem data={sales_regions} color={COLOR} isSalesRegion={true} defaultWeight={false} />
              </Suspense>
            </Tab>
            <Tab eventKey="pcv-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazySignedForecastDetail data={data} />
              </Suspense>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

SignedContractForecast.propTypes = {
  originalData: PropTypes.shape({
    signed_contract_forecast: PropTypes.object
  }).isRequired
}

export default SignedContractForecast;