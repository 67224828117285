import { useParams } from 'react-router-dom';
import ContactDetailHeader from './ContactDetailHeader';
import ContactDetailInfo from './ContactDetailInfo';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getContact } from 'redux/crm/contactSlice';
import { Card, Nav, Tab } from 'react-bootstrap';
import ContactActivity from './ContactActivity';
import { MdDetails, MdHistory } from 'react-icons/md';
import { useSelector } from 'react-redux';

const ContactDetail = () => {
  const {recordData: contact} = useSelector(state => state.crm_contact);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContact(params.id));
  }, [params, dispatch]);
  
  return (
    <>
      <ContactDetailHeader contact={contact} />
      <Card>
        <Tab.Container defaultActiveKey='contact-detail'>
          <Card.Header className='p-0 border-bottom'>
            <Nav className='nav-tabs border-0 flex-nowrap tab-contact-details'>
              <Nav.Item>
                <Nav.Link
                  eventKey='contact-detail'
                  className='d-flex align-items-center py-3 px-x1 mb-0'
                >
                  <MdDetails className='text-600 tab-icon' />
                  <h6 className='text-600 mb-0 ms-1'>Detail</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='contact-activity-log'
                  className='d-flex align-items-center py-3 px-x1 mb-0'
                >
                  <MdHistory className='text-600' />
                  <h6 className='text-600 mb-0 ms-1'>Activities history</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className='bg-light'>
            <Tab.Content>
              <Tab.Pane eventKey='contact-detail'>
                <ContactDetailInfo contact={contact} />
              </Tab.Pane>
              <Tab.Pane eventKey='contact-activity-log'>
                <ContactActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export default ContactDetail;