import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Accordion, Card, Form, Row, Col, Button } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { MdEdit } from "react-icons/md"

const Booking = () => {
  const {register, handleSubmit, formState: {errors} } = useForm();
  const [activeKey, setActiveKey] = useState(["0", "1"])

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  const onSubmit = async (data) => {
    console.log(data)

    // try {
    //   let leadId = null

    //   if (lead) {
    //     leadId = lead.id
    //     dispatch(updateLead({leadId, data }))
    //   }
    //   else {
    //     const response = await dispatch(createLead(data))

    //     if (response.payload && response.payload?.data?.id) {
    //       leadId = response.payload.data.id;
    //     } else {
    //       toast.error(response.payload.error)
    //     }
    //   }

    //   if (leadId) {
    //     navigate(`/crm/leads/${leadId}`)
    //   }
    // }
    // catch(error) {
    //   console.log(error.message)
    //   toast.error(error.message)

    //   return;
    // }
  }

  return (
    <Accordion
      id="setting-booking"
      className="border rounded overflow-hidden fs--1 mt-3"
      alwaysOpen
      flush
      activeKey={activeKey}
      onSelect={handleAccordionSelect}
    >
      <Card className="rounded-0">
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
            <Accordion.Item eventKey="0" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900">
                      1. Car
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Form.Group as={Row} className="mb-2" controlId="name">
                    <Form.Label column sm={3} className="text-lg-end">
                      Lead name
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Form.Control
                        name="name"
                        placeholder="Lead name"
                        className="fs--1"
                        {...register("name", {
                          required: "Lead name must be required."
                        })}
                      />
                      <span className="text-danger">
                        {errors.name && errors.name.message}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* <Form.Group as={Row} className="mb-2" controlId="owner_by">
                    <Form.Label column sm={3} className="text-lg-end">
                      Owner
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Controller
                        control={control}
                        name="owner_by"
                        rules={{required: "Owner must be required"}}
                        render={() => (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={ownerOptions}
                            defaultValue={defaultOwnerOption}
                            closeMenuOnSelect={true}
                            placeholder='Select...'
                            classNamePrefix="react-select"
                            className="w-100"
                            isClearable
                            onChange={(selectedOption) => selectedOption ? setValue("owner_by", selectedOption.value) : null}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.owner_by && errors.owner_by.message}
                      </span>
                    </Col>
                  </Form.Group> */}
                  
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900">
                      2. Travel
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Form.Group as={Row} className="mb-2" controlId="name">
                    <Form.Label column sm={3} className="text-lg-end">
                      Lead name
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Form.Control
                        name="name"
                        placeholder="Lead name"
                        className="fs--1"
                        {...register("name", {
                          required: "Lead name must be required."
                        })}
                      />
                      <span className="text-danger">
                        {errors.name && errors.name.message}
                      </span>
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            <div className="bg-light fixed-bottom border-0 text-center p-3">
              <Button size="sm" type="submit" className="me-2"><MdEdit /> Update</Button>
              <Button size="sm" variant="danger">Cancel</Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Accordion>
  )
}

export default Booking