import React, { useEffect } from "react"
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createCompetitor, updateCompetitor } from "redux/crm/competiorSlice";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

const CompetitorForm = ({showModal, setshowModal, title, competitor}) => {
  const {register, handleSubmit, formState: {errors}, setValue, reset } = useForm()

  const dispatch = useDispatch();

  useEffect(() => {
    if (title === "New Competior") {
      reset()
    }
  }, [title])

  useEffect(() => {
    if(competitor) {
      Object.entries(competitor).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, competitor])

  const onSubmit = async (data) => {
    console.log(data)

    try {
      let competitorId = ""

      if (competitor) {
        competitorId = competitor.id
        dispatch(updateCompetitor({competitorId, data }))
      }
      else {
        const response = await dispatch(createCompetitor(data))
        competitorId = response.payload.id
      }

      if (competitorId) {
        closeModal()
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Modal
      className="fs--1"
      show={showModal}
      onHide={() => setshowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name*
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Competitor name"
                className="fs--1"
                {...register("name", {
                  required: "Name must be required."
                })}
              />
              <span className="text-danger mt-1">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="code">
            <Form.Label column sm={3} className="text-lg-end">
              Code*
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="code"
                placeholder="Competitor code"
                className="fs--1"
                {...register("code", {
                  required: "Ccode must be required."
                })}
              />
              <span className="text-danger mt-1">
                {errors.code && errors.code.message}
              </span>
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit">Save</Button>
          <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  ) 
};

CompetitorForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  competitor: PropTypes.object.isRequired
}

export default CompetitorForm;