import { formatNumber } from 'helpers/utils';
import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { FaAddressCard, FaChessQueen } from 'react-icons/fa';
import { MdAccountBalance } from 'react-icons/md';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const ConvertPCVComponent = ({lead}) => {
  const contact = lead?.contacts[0] || [];
  const opportunity = lead?.opportunity || {};
  const customer = lead?.customer || {};

  return (
    <Row className="fs--1 mt-3 gx-3">
      <Col>
        <Card className="h-100">
          <Card.Header className="border-bottom text-primary fs-1"><MdAccountBalance /> Customer</Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col className="text-start">Name:</Col>
              <Col sm={9}>
                <span className="text-primary">{lead?.account_name}</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Work phone:</Col>
              <Col sm={9}>
                <span className="text-primary">{lead?.work_phone}</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Address:</Col>
              <Col sm={9}>
                <span className="text-primary">60A Truong Son, Phuong 2, Quan Tan Binh.</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Website:</Col>
              <Col sm={9}>
                <span className="text-primary">{lead?.website}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="h-100">
          <Card.Header className="border-bottom text-primary fs-1"><FaAddressCard /> Contact</Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col className="text-start">Name:</Col>
              <Col sm={9}>
                <span className="text-primary">{contact?.name}</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Title:</Col>
              <Col sm={9}>
                <span className="text-primary">{contact?.position}</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Mobile:</Col>
              <Col sm={9}>
                <span className="text-primary">{contact?.mobile}</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Email:</Col>
              <Col sm={9}>
                <span className="text-primary">{contact?.email}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="h-100">
          <Card.Header className="border-bottom text-success fs-1 fw-bold"><FaChessQueen /> Opportunity</Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col className="text-start">Name:</Col>
              <Col sm={9}>
                <span className="text-primary">
                  <Link to={`/crm/projects/${opportunity?.id}`} target="_blank">{lead?.project_name}</Link>
                </span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Account name:</Col>
              <Col sm={9}>
                <span className="text-primary">
                  <Link to={`/crm/customers/${customer?.id}`} target="_blank">{customer?.name}</Link>
                </span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Amount:</Col>
              <Col sm={9}>
                <span className="text-primary">${formatNumber(lead?.amount)}</span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-start">Sales incharge:</Col>
              <Col sm={9}>
                <span className="text-primary">{lead?.salesperson}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
};

ConvertPCVComponent.propTypes = {
  lead: PropTypes.object.isRequired
}

export default ConvertPCVComponent;