import { useDispatch } from "react-redux"
import { Form, Modal, Button, Row, Col} from "react-bootstrap"
import { toast } from "react-toastify"
import { FaSave } from "react-icons/fa"
import { useForm } from "react-hook-form"
import React, { useEffect } from "react"
import { createAsset, updateAsset } from "redux/drive/driveSlice"
import PropTypes from'prop-types';
import { useParams } from "react-router-dom"

const FolderForm = ({showModal, setshowModal, asset, title }) => {
  const {register, handleSubmit, formState: {errors}, setValue } = useForm()

  const dispatch = useDispatch();
  const {id} = useParams();
 
  useEffect(() => {
    if(asset) {
      setValue("name", asset?.name);
    }
  }, [setValue, asset])

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = (data) => {
    try {
      if (asset) {
        const assetId = asset.id;
        dispatch(updateAsset({assetId, data}))
      }
      else {
        data = id !== undefined ? {...data, parent_id: id} : data;
        dispatch(createAsset(data))
      }
      
      closeModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="name"
                placeholder="Name"
                className="fs--1"
                {...register("name", {
                  required: "Name must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="primary"><FaSave /> Save</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

FolderForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    asset_type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired
};

export default FolderForm;