import Funnel from "components/crm/reports/pcv/Funnel";
import PCVCreatedFromLeadEntry from "components/crm/reports/pcv/PCVCreatedFromLeadEntry";
import PCVEntry from "components/crm/reports/pcv/PCVEntry";
import PCVSummary from "components/crm/reports/pcv/PCVSummary";
import SalesFunnel from "components/crm/reports/pcv/SalesFunnel";
import { Row, Col } from "react-bootstrap";
import React from 'react';
import PropTypes from 'prop-types';

const PCV = ({pcvData}) => {
  const {pcv} = pcvData || {};
  const {current_period, previous_period, summary, monthly, sales_regions, pcv_created_from_lead, data} = pcv || {};
  const originalData = {monthly, sales_regions, data};

  return (
    <>
      <PCVSummary pcvData={{current_period, previous_period, summary, data}} />

      <Row className="g-3 mb-3">
        <Col md={5}>
          <Funnel data={summary} />
        </Col>
        <Col>
          <SalesFunnel data={summary} />
        </Col>
      </Row>

      <PCVEntry originalData={originalData}/>
      <PCVCreatedFromLeadEntry pcvData={pcv_created_from_lead} />
    </>
 
  )
};

PCV.propTypes = {
  pcvData: PropTypes.object.isRequired
}

export default PCV;