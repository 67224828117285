import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import React from 'react';
import PropTypes from "prop-types";

const JobRatio = ({data}) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="mb-3 position-static"
        >
          <h6 className="mb-0 flex-1">
            Job Ratio with Quotation

            <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ position: 'fixed' }}>
                    The rate of winning bids based on the number of quotations in the year.
                  </Tooltip>
                }
              >
                <span>
                  <FontAwesomeIcon
                    icon="info-circle"
                    transform="shrink-1"
                    className="ms-1 text-400"
                  />
                </span>
              </OverlayTrigger>
          </h6>
        </Flex>
        <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-success text-center">{data || 0}%</p>
      </Card.Body>
    </Card>
  )
};

JobRatio.propTypes ={
  data: PropTypes.object.isRequired
}


export default JobRatio;