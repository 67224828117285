import AgGridTable from "components/ag-grid/AgGridTable";
import GridFilter from "components/peb-grid/GridFilter";
import React, {useEffect, useMemo} from "react"
import { useSelector } from "react-redux";
import { 
  getAllDepartments,
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setFilterTag,
  exportDepartments
} from "redux/organization/departmentSlice";
import FilterAdvanceModal from "./FilterAdvanceModal";
import AgPagination from "components/ag-grid/AgPagination";
import { useDispatch } from "react-redux";
import { PERPAGE_DEFAULT_SM } from "app_config";
import PropTypes from "prop-types";

const DepartmentList = ({setDepartmentsSelected}) => {

  const key = "allData";
  const { allData } = useSelector((state) => state.organization_department);
  const dispatch = useDispatch();

  const {
    data,
    totalRows,
    pageSize,
    totalPagesCount,
    fromRecord,
    toRecord,
    currentParams,
    filterTags
  } = allData || {};

  const columnDefs = useMemo(() => [
    { 
      headerName: 'ID#',
      field: 'id',
      minWidth: 110,
      headerCheckboxSelection: true,
      checkboxSelection: true
    },
    { 
      headerName: 'Name',
      field: 'name',
      minWidth: 200
    },
    { 
      headerName: 'Parent',
      field: 'parent_name',
      minWidth: 200
    },
    { 
      headerName: 'Site',
      field: 'site_name',
      minWidth: 200
    }
  ], []);

  useEffect(() => {
    const params = currentParams ?? { page: 1, per_page: PERPAGE_DEFAULT_SM };
    dispatch(getAllDepartments(params));
  }, [currentParams]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setNestedPage({ key, page }));
    dispatch(setNestedPageSize({ key, pageSize }));

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize};
    console.log(newCurrentParams);
    dispatch(setNestedParams({ key, params: newCurrentParams }));

    dispatch(getAllDepartments(newCurrentParams));
  })

  
  return (
    <>
      <GridFilter
        entryType="all"
        entryData={allData}
        loadApi={getAllDepartments}
        currentParams={currentParams}
        entryKey={"allData"}
        setNestedParams={setNestedParams}
        resetNestedParams={resetNestedParams}
        setFilterTag={setFilterTag}
        filterTags={filterTags}
        filterAttributes="name_cont"
        exportApi={exportDepartments}
        FilterAdvanceComponent={FilterAdvanceModal}
      />

      <AgGridTable
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        refData={setDepartmentsSelected}
        selectedMultipe={false}
        serverSide={true}
      />
      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200", "500"]}
      />
    </>
  )
};

DepartmentList.propTypes = {
  setDepartmentsSelected: PropTypes.func.isRequired
}

export default DepartmentList;
