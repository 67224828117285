import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import { Card, Dropdown, Row, Col, Form } from "react-bootstrap"
import { formatBookingDate, formatNumber, formatNumberCurrency } from "helpers/utils";
import BookingAttachment from "../../BookingAttachment";
import ShuttleUploadAttachmentDirectly from "./ShuttleUploadAttachmentDirectly";
import PropTypes from 'prop-types';

const ShuttleItem = ({booking, shuttle, icon, editShuttleBooking, deleteShuttleBooking, handleRemoveAttachment}) => {
  const shuttleId = shuttle.id

  const isCarOfCompany = () => {
    return shuttle && shuttle.name === "Car of company"
  }

  const isUSDCurrency = () => {
    return booking.currency === "USD"
  }

  return (
    <Card className="m-4">
      <Card.Header className="mt-2">
        <Flex justifyContent="between">
          <div>
            <div className="h5 fw-semi-bold ls text-uppercase text-primary"><FontAwesomeIcon icon={icon} /> {shuttle.name} (ID#{shuttle.id})</div>
            <div className="small ms-4 mt-2">Created date: {shuttle.created_date} | by: {shuttle.created_by}</div>
          </div>
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                {!isCarOfCompany() && (
                  <>
                    <Dropdown.Item className="mb-1" onClick={editShuttleBooking}>
                      <FontAwesomeIcon icon="edit" /> Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                
                <Dropdown.Item className="mb-1" onClick={deleteShuttleBooking}>
                  <FontAwesomeIcon icon="trash-alt" /> Delete
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Flex>
      </Card.Header>
      <Card.Body className="m-4">
        <Row className="mb-3">
          <Col lg xxl={6}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Shuttle Information
            </h6>
            <Row  className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Shuttle name</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{shuttle.name}</p>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Departure date</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{formatBookingDate(shuttle.departure_date)}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Return date</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">{formatBookingDate(shuttle.return_date)}</p>
              </Col>
            </Row>


            <Row className="mb-2">
              <Col xs={4} sm={5}>
                <p className="fw-semi-bold fs--1 mb-1">Primary</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary">
                  <Form.Check 
                    type="switch"
                    name="primary"
                    className="me-3"
                    //label="Primary"
                    checked={shuttle.primary}
                  />
                </p>
              </Col>
            </Row>
            
          </Col>
          <Col lg className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Expense
            </h6>
            {isCarOfCompany() ? (
              <>
                <Row className="mb-2">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold mb-1 fs--1">Amount actual currency</p>
                  </Col>
                  <Col>
                    <p className="mb-1 text-primary fs--1">N/A</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold fs--1 mb-1">Amount actual</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">N/A</p>
                  </Col>
                </Row>

                <Row className="mb-2 mt-3">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold fs--1 mb-1">Car booking</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">Link here</p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="mb-2">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold mb-1 fs--1">Amount estimate currency</p>
                  </Col>
                  <Col>
                    <p className="mb-1 text-primary fs--1">{formatNumberCurrency(shuttle.amount_estimate_currency, shuttle.currency)}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold fs--1 mb-1">Amount estimate</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{formatNumberCurrency(shuttle.amount_estimate, "VND")}</p>
                  </Col>
                </Row>

                <hr className="w-75" />

                <Row className="mb-2">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold mb-1 fs--1">Amount actual currency</p>
                  </Col>
                  <Col>
                    <p className="mb-1 text-primary fs--1">{formatNumberCurrency(shuttle.amount_currency, shuttle.currency)}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={6}>
                    <p className="fw-semi-bold fs--1 mb-1">Amount actual</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{formatNumberCurrency(shuttle.amount, "VND")}</p>
                  </Col>
                </Row>

                <hr className="w-75" />

                {isUSDCurrency() && (
                  <Row className="mb-2">
                    <Col xs={4} sm={6}>
                      <p className="fw-semi-bold mb-1 fs--1">Exchange rate</p>
                    </Col>
                    <Col>
                      <p className="mb-1 text-primary fs--1">{formatNumber(shuttle.exchange_rate)} VND</p>
                    </Col>
                  </Row>
                )}
              </>
            )}
            
            <Row className="mb-2 mt-4">
              <Col xs={4} sm={6}>
                <p className="fw-semi-bold fs--1 mb-1">Note</p>
              </Col>
              <Col>
                <p className="mb-1 fs--1 text-primary" dangerouslySetInnerHTML={{ __html: shuttle.notes }}></p>
              </Col>
            </Row>

          </Col>
        </Row>
        {!isCarOfCompany() && (
          <Row className="mb-3">
            <Col>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                Attachments
              </h6>

              <Row>
                <Col>
                  <ShuttleUploadAttachmentDirectly booking={booking} shuttle={shuttle} />
                </Col>
                <Col>
                  {shuttle && shuttle.documents.length > 0 && (
                    <div className="pb-2">
                      <Flex direction="row" wrap="wrap" justifyContent="arround">
                        {shuttle.documents.map(attachment => (
                          <BookingAttachment
                            {...attachment}
                            key={attachment.id}
                            handleRemoveAttachment={(documentId) => handleRemoveAttachment(shuttleId, documentId)}
                          />
                        ))}
                      </Flex>
                    </div>
                  )} 
                </Col>
              </Row>

            </Col>
          </Row>
        )}
        
      </Card.Body>
    </Card>
  )
};

ShuttleItem.propTypes = {
  booking: PropTypes.object.isRequired,
  shuttle: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  editShuttleBooking: PropTypes.func.isRequired,
  deleteShuttleBooking: PropTypes.func.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired
}

export default ShuttleItem;