import React, { Suspense, lazy, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import ProjectHeader from './ProjectHeader';
import { FaFileSignature, FaPeopleArrows, FaSadTear } from 'react-icons/fa';
import { MdNewLabel, MdOutlineRequestQuote, MdList } from 'react-icons/md';

const LazyProjectAllList = lazy(() => import('./list/All'));
const LazyProjectNewPCVList = lazy(() => import('./list/NewPCV'));
const LazyProjectQuotationList = lazy(() => import('./list/Quotation'));
const LazyProjectNegotiationList = lazy(() => import('./list/Negotation'));
const LazyProjectJobList = lazy(() => import('./list/Job'));
const LazyProjectLostList = lazy(() => import('./list/Lost'));

const Projects = () => {
  const [projectSelected, setProjectSelected] = useState([])
  const { loading } = useSelector((state) => state.crm_project)

  return (
    <>
      <ProjectHeader projectSelected={projectSelected} />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="project-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdList className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-new"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdNewLabel className="text-600 tab-icon"/>
                        <h6 className="text-600 mb-0 ms-1"> New PCV</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-quotation"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineRequestQuote className="text-primary tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Quotation</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-negotiation"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaPeopleArrows className="text-info tab-icon"/>
                        <h6 className="text-600 mb-0 ms-1"> Negotiation</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-job"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaFileSignature className="text-success tab-icon"/>
                        <h6 className="text-600 mb-0 ms-1"> Job</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="project-lost"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaSadTear className="text-danger tab-icon"/>
                        <h6 className="text-600 mb-0 ms-1"> Lost</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={<div>Loading...</div>}>
                  <Tab.Content>
                    <Tab.Pane eventKey="project-all">
                      <LazyProjectAllList setProjectSelected={setProjectSelected} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="project-new">
                      <LazyProjectNewPCVList setProjectSelected={setProjectSelected} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="project-quotation">
                      <LazyProjectQuotationList setProjectSelected={setProjectSelected} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="project-negotiation">
                      <LazyProjectNegotiationList setProjectSelected={setProjectSelected}  />
                    </Tab.Pane>
                    <Tab.Pane eventKey="project-job">
                      <LazyProjectJobList setProjectSelected={setProjectSelected}  />
                    </Tab.Pane>
                    <Tab.Pane eventKey="project-lost">
                      <LazyProjectLostList setProjectSelected={setProjectSelected}  />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Projects