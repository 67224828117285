import HeaderReport from "components/crm/reports/HeaderReport"
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { MdOutlineNewReleases, MdOutlineSummarize, MdPendingActions } from 'react-icons/md';
import { FaFileContract, FaSadTear, FaUserFriends } from "react-icons/fa";
import { getCrmDashboardData } from "redux/crm/report/crmDasboardSlice";
import NumberWeightSummary from "components/crm/reports/NumberWeightSummary";
import Overview from "./project/Overview";
import PCV from "./project/PCV";
import Quotation from "./project/Quotation";
import Contract from "./project/Contract";
import Customer from "./project/Customer";
import Lost from "./project/Lost";

const CRMDashboard = () => {
  const {dashboardData} = useSelector(state => state.crm_report_dashboard);

  const currentDate = moment();
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(currentDate);
  const [defaultKeyTab, setDefaultKeyTab] = useState("crm-overview");

  const dispatch = useDispatch()


  const params = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD')
  };

  useEffect(() => {
    dispatch(getCrmDashboardData(params))
  }, [startDate, endDate])

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const { pcv, quotation, contract, enter_job, customer} = dashboardData || {};

  const totalPCVNumberProjectsCurrentPeriod   = pcv?.current_period?.number_of_projects || 0;
  const totalPCVNumberProjectsPreviousPeriod  = pcv?.previous_period?.number_of_projects || 0;

  const totalQNWeightCurrentPeriod            = quotation?.current_period?.weight || 0;
  const totalQWeightPreviousPeriod            = quotation?.previous_period?.weight || 0;

  const totalJNWeightCurrentPeriod            = enter_job?.current_period?.became_jobs?.weight || 0;
  const totalJWeightPreviousPeriod            = enter_job?.previous_period?.became_jobs?.weight || 0;

  const totalContractRevenueCurrentPeriod     = contract?.current_period?.revenue || 0;
  const totalContractRevenuePreviousPeriod    = contract?.previous_period?.revenue || 0;

  const handleClick = (key) => {
    setDefaultKeyTab(key)
  }

  return (
    <>
      <HeaderReport
        title="CRM"
        subtitle="Dasboard"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDatesChange={handleDatesChange}
      />

      <Row className="g-3 mb-3">
        <Col md={3}>
          <NumberWeightSummary
            title="Number of PCVs"
            current_value={totalPCVNumberProjectsCurrentPeriod}
            previous_value={totalPCVNumberProjectsPreviousPeriod}
            handleClick={() => handleClick("crm-pcv")}
            titleHeader="PCV entered in this year"
          />
        </Col>
        <Col>
          <NumberWeightSummary
            title="Weight of Quotations (M.T)"
            current_value={totalQNWeightCurrentPeriod}
            previous_value={totalQWeightPreviousPeriod}
            handleClick={() => handleClick("crm-quotation")}
            titleHeader="All quotations entered in this year"
          />
        </Col>
        <Col>
          <NumberWeightSummary
            title="Weight of Jobs (M.T)"
            current_value={totalJNWeightCurrentPeriod}
            previous_value={totalJWeightPreviousPeriod}
            isRevenue={true}
            handleClick={() => handleClick("crm-overview")}
            titleHeader="Actual jobs entered this year (including quotations from previous years)"
          />
        </Col>
        <Col>
          <NumberWeightSummary
            title="Signed contract (USD)"
            current_value={totalContractRevenueCurrentPeriod}
            previous_value={totalContractRevenuePreviousPeriod}
            isRevenue={true}
            handleClick={() => handleClick("crm-contract")}
             titleHeader="Actual contracts signed in this year"
          />
        </Col>
      </Row>

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey={defaultKeyTab} activeKey={defaultKeyTab}>
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="crm-overview"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                        onClick={() => handleClick("crm-overview")}
                      >
                        <MdOutlineSummarize className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Overview</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="crm-pcv"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                        onClick={() => handleClick("crm-pcv")}
                      >
                        <MdOutlineNewReleases className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> PCV</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="crm-quotation"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                        onClick={() => handleClick("crm-quotation")}
                      >
                        <MdPendingActions className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Quotation</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="crm-contract"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                        onClick={() => handleClick("crm-contract")}
                      >
                        <FaFileContract className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Contract</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="crm-lost"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                        onClick={() => handleClick("crm-lost")}
                      >
                        <FaSadTear className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Lost</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="crm-customer"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                        onClick={() => handleClick("crm-customer")}
                      >
                        <FaUserFriends className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Customer</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="crm-overview">
                    <Overview overviewData={{enter_job, contract}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="crm-pcv">
                    <PCV pcvData={{pcv}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="crm-quotation">
                    <Quotation quotationData={{quotation}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="crm-contract">
                    <Contract contractData={{contract}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="crm-lost">
                    <Lost lostData={{quotation: quotation?.data, job: enter_job?.data}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="crm-customer">
                    <Customer customerData={{customer, contract: contract?.data}} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CRMDashboard