import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { createSite, updateSite } from "redux/organization/siteSlice";
import Select from 'react-select';
import { activeEmployeesByNameDebounce } from "helpers/employeeOptionHelper";
import AsyncSelect from 'react-select/async';

const SiteForm = ({showModal, setshowModal, title, site}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm();
  const {countryData} = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  useEffect(() => {
    if(site) {
      Object.entries(site).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, site])

  const currencyOptions = useMemo(() => ["VND", "USD"].map((option) => ({ value: option, label: option })), []) 

  const defaultCurrencyOption = useMemo(() => (
    site ? { value: site.currency, label: site.currency } : currencyOptions[0]
  ), [site])

  const countryOptions = useMemo(() => countryData && countryData.map((option) => ({ value: option.country, label: option.name })), []) 
  const defaultCountryOption = useMemo(() => (
    site ? { value: site.country, label: site.country_name } : (countryOptions.filter((country) => country.value === 'VN')[0])
  ), [site])

  const defaultHrAdminOption = useMemo(() => {
    if (site && site.hr_admin) {
      return site.hr_admin.map((emp) => ({  value: emp, label: emp }))
    }
    return null
  }, [site])

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (Object.keys(site).length > 0) {
        const siteId = site.id
        dispatch(updateSite({ siteId, data }))
      }
      else {
        dispatch(createSite(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const loadHrAdminOptions = (inputValue, callback) => {
    activeEmployeesByNameDebounce(inputValue, callback);
  };

  const handleChangeHrAdmin = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('hr_admin', selectedOptionsValues, { shouldValidate: true })
  }


  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Site name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Site name"
                className="fs--1"
                {...register("name", {
                  required: "Site name must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="country">
            <Form.Label column sm={3} className="text-lg-end">
              Country
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="country"
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={countryOptions}
                    defaultValue={defaultCountryOption}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => setValue('country', selectedOption.value, {shouldValidate: true})}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Country is required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.country && errors.country.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="currency">
            <Form.Label column sm={3} className="text-lg-end">
              Currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="currency"
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={currencyOptions}
                    defaultValue={defaultCurrencyOption}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => setValue('currency', selectedOption.value, {shouldValidate: true})}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Currency is required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.currency && errors.currency.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='hr_admin'>
            <Form.Label column sm={3} className='text-lg-end'>
              HR admin
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="hr_admin"
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={defaultHrAdminOption}
                    loadOptions={loadHrAdminOptions}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    className="w-100"
                    isMulti
                    onChange={handleChangeHrAdmin}
                  />
                )}
              />
              <span className='text-danger'>
                {errors.hr_admin && errors.hr_admin.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="active">
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name="active"
                defaultValue={true}
                render={({ field }) => (
                  <Form.Check
                    type="switch"
                    label="Active"
                    checked={field.value}
                    onChange={(e) => setValue("active", e.target.checked)}
                  />
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="car_booking">
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name="car_booking"
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    type="switch"
                    label="Car Booking"
                    checked={field.value}
                    onChange={(e) => setValue("car_booking", e.target.checked)}
                  />
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="car_booking_driver_confirm">
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name="car_booking_driver_confirm"
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    type="switch"
                    label="Driver's confirmation for car booking"
                    checked={field.value}
                    onChange={(e) => setValue("car_booking_driver_confirm", e.target.checked)}
                  />
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="travel_booking">
            <Col sm={{ span: 10, offset: 3 }}>
              <Controller
                control={control}
                name="travel_booking"
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    type="switch"
                    label="Travel Booking"
                    checked={field.value}
                    onChange={(e) => setValue("travel_booking", e.target.checked)}
                  />
                )}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit">Save</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

SiteForm.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  title: PropTypes.string,
  site: PropTypes.object
}

export default SiteForm