import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { BsTrash } from "react-icons/bs";
import { FaClock, FaDownload, FaUser } from "react-icons/fa";
import PropTypes from "prop-types";

const DocumentAttachment = ({
  id,
  name,
  size,
  url,
  created_by,
  created_date,
  allowView,
  allowDownload,
  allowRemove,
  handleRemoveAttachment,
  isTable
}) => {

  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      {isTable ? (
        <Flex
          alignItems="center"
          justifyContent="between"
          className="px-2 me-1 dark__bg-1000 my-1 fs--1 mb-2"
        >
          <div>
            <FontAwesomeIcon icon="paperclip" className="fs--1" />
            {allowView ? (
              <a href={url} rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                <span className="mx-2 flex-1">
                  {name} ({(size / 1024).toFixed(2)} kb)
                </span>
                <div className="mx-2 small text-muted text-start"><FaUser /> Uploaded by <strong>{created_by}</strong> | <FaClock/> Date {created_date}</div>
              </a>
            ) : (
              <span>
                <span className="mx-2 flex-1">
                  {name} ({(size / 1024).toFixed(2)} kb)
                </span>
                <div className="mx-2 small text-muted text-start"><FaUser /> Uploaded by <strong>{created_by}</strong> | <FaClock/> Date {created_date}</div>
              </span>
            )}
          </div>
          <div>
            {allowDownload && (
              <span
                className="text-300 p-1 ml-auto cursor-pointer"
                id={`attachmentDETooltip${id}`}
                onClick={() => handleDownload(url)}
              >
                <FaDownload className="text-primary"/>
              </span>
            )}
            
            {allowRemove && (
              <span
                className="text-300 p-1 ml-auto cursor-pointer"
                id={`attachmentTooltip${id}`}
                onClick={() => handleRemoveAttachment(id)}
              >
                <BsTrash className="text-danger"/>
              </span>
            )}
            
          </div>
        </Flex>
        ) : (
          <Flex
            alignItems="center"
            className="border px-2 me-1 rounded-3 bg-white dark__bg-1000 my-1 fs--1 text-decoration-none"
          >
            <FontAwesomeIcon icon="paperclip" className="fs--1" />
            <a href={url} rel="noopener noreferrer" target="_blank">
              <span className="mx-2 flex-1">
                {name} ({(size / 1024).toFixed(2)} kb)
              </span>
            </a>
            <span
              className="text-300 p-1 ml-auto cursor-pointer"
              id={`attachmentTooltip${id}`}
              onClick={() => handleRemoveAttachment(id)}
            >
              <FontAwesomeIcon icon="times" />
            </span>
          </Flex>
        )}
    </>
  )
};


DocumentAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  created_by: PropTypes.string.isRequired,
  created_date: PropTypes.string.isRequired,
  allowView: PropTypes.bool.isRequired,
  allowDownload: PropTypes.bool.isRequired,
  allowRemove: PropTypes.bool.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired,
  isTable: PropTypes.bool
};

export default DocumentAttachment;