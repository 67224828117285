import { Form, Modal, Button, Image } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import COReviseWorkflow from "../../../../assets/img/crm/co-revise-process.png";
import React from 'react';
import PropTypes from 'prop-types';

const PIFVersionWorkflowModal = ({ showModal, setshowModal }) => {

  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
      size="lg"
    >
      <Form>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">PIF version workflow!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
          The PIF version applies in case of a revised change order in the project. Please follow the workflow below to process it.
          </IconAlert>
          <Image src={COReviseWorkflow} thumbnail />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" variant="falcon-default" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

PIFVersionWorkflowModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired
}

export default PIFVersionWorkflowModal;