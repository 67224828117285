import React from 'react';
import createMarkup from 'helpers/createMarkup';
import { Button, Card, Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { MdDelete, MdEdit, MdHistory, MdPublish } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Logs = ({
  log,
  editLog,
  deleteLog,
  publishLog
}) => {
  const {allow_edit, allow_delete, allow_release} = log.permissions || {};

  return (
    <Card className="mb-3">
      <Card.Header>
        <Flex justifyContent="between">
          <div>
            <h5>
              <Link to={`/documentation/change-logs/${log.id}`} className="text-underline"><code className="fw-bold fs-1">{log.version}</code> - {log.title}</Link>
            </h5>
            <p className="mb-0 fs--1 text-primary"><MdHistory /> {log.released_date}</p>
          </div>
          <div>
            {allow_release && (
              <Button
                size="sm"
                variant="falcon-default"
                className="me-1"
                onClick={publishLog}
              >
                <MdPublish className="text-warning"/> Publish
              </Button>
            )}
            
            {allow_edit && allow_delete && (
              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                <Dropdown.Toggle split variant="falcon-default" size="sm">
                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Dropdown.Item onClick={editLog}>
                      <MdEdit className="text-warning" /> Edit
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Item className="text-danger" onClick={deleteLog}>
                      <MdDelete className="text-danger" /> Delete
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </Flex>
      </Card.Header>
      <Card.Body className="bg-light p-4">
        <span dangerouslySetInnerHTML={createMarkup(log.body)}></span>
      </Card.Body>
    </Card>
  )
};

Logs.propTypes = {
  log: PropTypes.object.isRequired,
  editLog: PropTypes.func.isRequired,
  deleteLog: PropTypes.func.isRequired,
  publishLog: PropTypes.func.isRequired
}

export default Logs;
