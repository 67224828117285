import CheckboxRenderer from "components/ag-grid/CheckboxRenderer";
import BadgeRenderer from "./BadgeRenderer";
import LinkRenderer from "./LinkRenderer";

export const columnDefs = [
  { 
    headerName: 'ID#',
    field: 'id',
    minWidth: 110,
    pinned: 'left',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: LinkRenderer
  },
  { 
    headerName: 'Name',
    field: 'name',
    minWidth: 100,
    pinned: 'left'
  },
  { 
    headerName: 'Description',
    field: 'description',
    minWidth: 150
  },
  { 
    headerName: 'Region',
    field: 'region',
    minWidth: 120
  },
  { 
    headerName: 'Parent',
    field: 'parent_id',
    minWidth: 120
  },
  { 
    headerName: 'Sales admin',
    field: 'sales_admin',
    minWidth: 120
  },
  { 
    headerName: 'Sales leader',
    field: 'sales_leader',
    minWidth: 120
  },
  { 
    headerName: 'Sales manager',
    field: 'sales_manager',
    minWidth: 120
  },
  { 
    headerName: 'ENG incharge',
    field: 'eng_incharge',
    minWidth: 120
  },
  { 
    headerName: 'Detailer incharge',
    field: 'detailer_incharge',
    minWidth: 120
  },
  { 
    headerName: 'ENG manager',
    field: 'eng_manager',
    minWidth: 120
  },
  { 
    headerName: 'Credit controller',
    field: 'credit_controller',
    minWidth: 120
  },
  { 
    headerName: 'Finance controller',
    field: 'finance_controller',
    minWidth: 120
  },
  { 
    headerName: 'General manager operation',
    field: 'general_manager_operation',
    minWidth: 120
  },
  { 
    headerName: 'CSD order entry',
    field: 'csd_order_entry',
    minWidth: 180,
    cellRenderer: BadgeRenderer
  },
  { 
    headerName: 'CSD order management',
    field: 'csd_order_management',
    minWidth: 180,
    cellRenderer: BadgeRenderer
  },
  { 
    headerName: 'Contract management',
    field: 'contract_management',
    minWidth: 150
  },
  { 
    headerName: 'Contract handle',
    field: 'contract_handler_optional',
    minWidth: 180,
    cellRenderer: BadgeRenderer
  },
  { 
    headerName: 'Estimate forecast',
    field: 'estimate_forecast_handler',
    minWidth: 200,
    cellRenderer: BadgeRenderer
  },
  { 
    headerName: 'Active',
    field: 'active',
    minWidth: 100,
    cellRenderer: CheckboxRenderer
  },
  { 
    headerName: 'Type of sales',
    field: 'type_of_sales',
    minWidth: 120
  },
  { 
    headerName: 'Created date',
    field: 'created_at',
    minWidth: 170
  },
]


//     Cell: () => {
//       return (
//         <CardDropdown>
//           <div className="py-2">
//             <Dropdown.Item href="#!"><FontAwesomeIcon icon={'edit'} /> Edit</Dropdown.Item>
//             <Dropdown.Divider as="div" />
//             <Dropdown.Item href="#!" className="text-danger">
//             <FontAwesomeIcon icon={'trash'} /> Delete
//             </Dropdown.Item>
//           </div>
//         </CardDropdown>
//       );
//     }
//   }
// ];