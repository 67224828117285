import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReadMe from './job_entry/ReadMe';
import HeaderReport from './HeaderReport';
import moment from "moment";
import JobEntryOverview from './job_entry/JobEntryOverview';
import { getJobEntryDashboard } from 'redux/crm/report/jobEntrySlice';
import { useSelector } from 'react-redux';
import JobEntryDeail from './job_entry/JobEntryDeail';
import SimpleBar from 'simplebar-react';
import { MdDetails, MdOutlineSummarize } from 'react-icons/md';
import { FaRegListAlt } from 'react-icons/fa';
import NumberWeightSummary from './NumberWeightSummary';
import JobEntryWeekly from './job_entry/JobEntryWeekly';
import JodEntryRatio from './job_entry/JodEntryRatio';
import JobEntryLost from './job_entry/JobEntryLost';

const JobEntry = () => {
  const {summaryData} = useSelector(state => state.crm_report_job_entry);

  const currentDate = moment();
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(currentDate);

  const dispatch = useDispatch();
  const {current_period, previous_period, this_week, last_week, summary, data} = summaryData;

  const totalNumberProjectsCurrentPeriod = current_period?.number_of_projects || 0;
  const totalNumberProjectsPreviousPeriod = previous_period?.number_of_projects || 0;

  const number_of_projects_became_job_current_period = current_period?.became_jobs?.number_of_projects || 0;
  const number_of_projects_became_job_previous_period = previous_period?.became_jobs?.number_of_projects || 0;

  const ratio_current_period = number_of_projects_became_job_current_period > 0 ? ((number_of_projects_became_job_current_period / totalNumberProjectsCurrentPeriod) * 100).toFixed(0) : 0;
  const ratio_previous_period = number_of_projects_became_job_previous_period > 0 ? ((number_of_projects_became_job_previous_period / totalNumberProjectsPreviousPeriod) * 100).toFixed(0) : 0;


  const params = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD')
  };

  useEffect(() => {
    dispatch(getJobEntryDashboard(params))
  }, [startDate, endDate])

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      <HeaderReport
        title="Entry Job"
        subtitle="Dashboard"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDatesChange={handleDatesChange}
      />

      <Row className="g-3 mb-3">
        <Col>
          <NumberWeightSummary
            title="Number of projects entered job"
            current_value={totalNumberProjectsCurrentPeriod}
            previous_value={totalNumberProjectsPreviousPeriod}
          />
        </Col>
        <Col>
          <NumberWeightSummary
            title="Weight of enterd jobs (M.T)"
            current_value={current_period?.weight}
            previous_value={previous_period?.weight}
          />
        </Col>
        <Col>
          <NumberWeightSummary
            title="Number of projects became jobs"
            current_value={number_of_projects_became_job_current_period}
            previous_value={number_of_projects_became_job_previous_period}
            isRevenue={true}
          />
        </Col>
        <Col>
          <JodEntryRatio
            title="Job Ratio"
            current_value={ratio_current_period}
            previous_value={ratio_previous_period}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col>
          <JobEntryWeekly data={{this_week, last_week}} />
        </Col>
        <Col>
          <JobEntryLost data={{current_period, previous_period, summary}} />
        </Col>
      </Row>

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="job-entry-overview">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="job-entry-overview"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineSummarize className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Overview</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="job-entry-detail"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdDetails className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="job-entry-readme"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Readme</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="job-entry-overview">
                    <JobEntryOverview originalData={summaryData} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="job-entry-detail">
                    <JobEntryDeail data={data} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="job-entry-readme">
                    <ReadMe />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
};

export default JobEntry;