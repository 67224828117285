import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deleteApprovalFlow  } from 'redux/booking/travel/setting/approvalFlowSlice';
import ApprovalFlowForm from './ApprovalFlowForm';
import PropTypes from 'prop-types';

const ApprovalFlowHeader = ({ selected }) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [showApprovalForm, setShowApprovalForm] = useState(false)
  const [title, setTitle] = useState('')
  const [record, setRecord] = useState(selected.at(-1) || {});

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setRecord(selected.at(-1) || {});
  }, [selected]);

  const validateselected = () => {
    if (selected.length === 0)
    {
      toast.error('Record has not selected yet.')
      return false
    }
    return true
  }

  const validateselectedMultiple = () => {
    if (selected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of record.')
      return false
    }
    return true
  }

  console.log(record)

  const showForm = () => {
    setShowApprovalForm(true)
  }

  const handleNewrecord = () => {
    setTitle("New Approval Flow")
    setRecord({})
    showForm()
  }

  const handleEditrecord = () => {
    if (!validateselected()) return
    if (!validateselectedMultiple()) return

    setTitle("Edit Approval Flow")
    showForm()
  }

  const handledeleterecord = () => {
    if (!validateselected()) return

    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(deleteApprovalFlow(record.id))
    setShowConfirmationDelete(false) 
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewrecord}
            >
              <span className="d-none d-xl-inline-block ms-1">New Approval Flow</span>
            </IconButton>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleEditrecord}>
                    <FontAwesomeIcon icon="edit" /> Edit
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  
                  <Dropdown.Item className="text-danger" onClick={handledeleterecord}>
                    <FontAwesomeIcon icon="trash-alt" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to delete?"
      />

      <ApprovalFlowForm
        showModal={showApprovalForm}
        setshowModal={setShowApprovalForm}
        title={title}
        record={record}
      />
    </>
  );
};

ApprovalFlowHeader.propTypes = {
  selected: PropTypes.array.isRequired
}


export default ApprovalFlowHeader;
