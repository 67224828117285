import React from "react";
import IconItem from "components/common/icon/IconItem";
import {Dropdown} from "react-bootstrap";
import { FaDownload, FaPen, FaTrashAlt, FaClone, FaLink, FaTrash, FaEye, FaEllipsisV, FaUserPlus, FaInfoCircle, FaUsers, FaGoogleDrive } from "react-icons/fa"
import PropTypes from "prop-types";

const ActionComponent = ({item, handleAction}) => {
  const isFile = item?.asset_type === "file";
  const isPublic = item?.public;

  const { 
    allow_view,
    allow_edit,
    allow_delete,
    allow_copy,
    allow_share,
    allow_make_public,
    allow_move_to_trash,
    allow_download
  } = item?.permissions || {};

  return (
    <div className="d-flex align-items-between">
      <div className="hover-actions mx-5" style={{backgroundColor: "white"}}>
        {allow_view && isFile && (
          <IconItem
            tag="button"
            icon="eye"
            size="sm"
            title="Preview"
            onClick={() => handleAction(item, "preview")}
            className="btn rounded-3 me-2 fs--2"
          />
        )}

        {!isPublic && (
          <>
            {allow_share && (
              <IconItem
                tag="button"
                icon="user-plus"
                size="sm"
                title="Share"
                onClick={() => handleAction(item, "share")}
                className="btn rounded-3 me-2 fs--2"
              />
            )}

            {allow_make_public && (
              <IconItem
                tag="button"
                icon="users"
                size="sm"
                title="Make public"
                onClick={() => handleAction(item, "makePublic")}
                className="btn rounded-3 me-2 fs--2"
              />
            )}
          </>
        )}
        
        {allow_download && (
          <IconItem
            tag="button"
            icon="download"
            size="sm"
            title="Download"
            onClick={() => handleAction(item, "download")}
            className="btn rounded-3 me-2 fs--2"
          />
        )}
        
        {allow_move_to_trash && (
          <IconItem
            tag="button"
            icon="trash-alt"
            size="sm"
            title="Move to trash"
            onClick={() => handleAction(item, "moveToTrash")}
            className="btn rounded-3 me-2 fs--2"
          />
        )}
      </div>

      <Dropdown align="end" className="d-inline-block">
        <Dropdown.Toggle split variant="falcon-default" size="sm">
          <FaEllipsisV icon="ellipsis-v" className="fs--2" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="border py-0">
          <div className="py-2">
            {allow_view && isFile && (
              <>
                <Dropdown.Item onClick={() => handleAction(item, "preview")} className="mb-1">
                  <FaEye className="text-primary" /> Preview
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleAction(item, "preview-google")} className="mb-1">
                  <FaGoogleDrive  className="text-warning" /> Preview Google Viewer
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}

            <Dropdown.Item onClick={() => handleAction(item, "getLink")} className="mb-1">
              <FaLink /> Get link
            </Dropdown.Item>

            <Dropdown.Item className="mb-1" onClick={() => handleAction(item, "detail")}>
              <FaInfoCircle /> <span className="text-capitalize">{item?.asset_type}</span> detail
            </Dropdown.Item>

            {!isPublic && (
              <>
              <Dropdown.Divider />
                {allow_share && (
                  <Dropdown.Item onClick={() => handleAction(item, "share")} className="mb-1">
                    <FaUserPlus /> Share
                  </Dropdown.Item>
                )}
                {allow_make_public && (
                  <Dropdown.Item onClick={() => handleAction(item, "makePublic")} className="mb-1">
                    <FaUsers /> Make public
                  </Dropdown.Item>
                )}
              </>
            )}
            
            {allow_download && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleAction(item, "download")} className="mb-1">
                  <FaDownload /> Download
                </Dropdown.Item>
              </>
            )}
          
            {allow_edit && (
              <Dropdown.Item onClick={() => handleAction(item, "rename")} className="mb-1">
                <FaPen/> Rename
              </Dropdown.Item>
            )}

            {isFile && allow_copy && (
              <Dropdown.Item onClick={() => handleAction(item, "copy")} className="mb-1">
                <FaClone/> Make a copy
              </Dropdown.Item>
            )}

            {/* <Dropdown.Item onClick={() => handleAction(item, "move")} className="mb-1">
              <FaExpandArrowsAlt/> Move to
            </Dropdown.Item> */}

            {allow_move_to_trash && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-1" onClick={() => handleAction(item, "moveToTrash")}>
                  <FaTrashAlt /> Move to Trash
                </Dropdown.Item>
              </>
            )}
            
            {allow_delete && (
              <Dropdown.Item className="mb-1" onClick={() => handleAction(item, "deleteForever")}>
                <FaTrash className="text-danger" /> Delete
              </Dropdown.Item>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
};

ActionComponent.propTypes = {
  item: PropTypes.object.isRequired,
  handleAction: PropTypes.func.isRequired
};

export default ActionComponent;