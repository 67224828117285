import React, { Suspense, lazy, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { MdListAlt } from 'react-icons/md';
import PositionGroupHeader from './position_group/PositionGroupHeader';

const AllList = lazy(() => import('./position_group/PostitionGroupList'));

const PositionGroups = () => {
  const { loading } = useSelector((state) => state.organization_position_group);
  const [selected, setSelected] = useState([]);

  return (
    <>
      <PositionGroupHeader selected={selected} />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="site-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom fs--1">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="site-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="site-all">
                      <AllList setSelected={setSelected} />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PositionGroups;
