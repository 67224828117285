import { Controller } from "react-hook-form";
import React, { useEffect, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import Flex from "components/common/Flex";
import { CRM_SALUTATION } from "app_config";
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import {isValidPhoneNumber} from "libphonenumber-js";
import PropTypes from 'prop-types';

const ContactForm = React.memo(({
  index, contact, control, register, errors, setValue, removeContact, updatedContact
}) => {

  const salutationOptions = useMemo(() => CRM_SALUTATION.map((option) => ({ value: option, label: option })), []) 

  useEffect(() => {
    //for case edit
    if (contact) {
      Object.entries(contact).forEach(([key, value]) => {
        setValue(`${key}_${index}`, value)
      })
    }
  }, [contact, index, setValue]);

  const handleInputChange = (fieldName, value) => {
    const fieldId = generateFieldId(fieldName);
    setValue(fieldId, value);

    const updatedContact = { ...contact, [fieldName]: value };
    handleUpdateContactData(updatedContact);
  };

  const handleUpdateContactData = (updatedData) => {
    updatedContact(index, updatedData)
  }

  const handleSelectChange = (fieldName, selectedOption) => {
    setValue(fieldName, selectedOption.value, { shouldValidate: true });
    
    const updatedContact = { ...contact, [fieldName]: selectedOption.value };
    handleUpdateContactData(updatedContact);
  };

  const validatePhoneNumber = (number) => {
    if (number === undefined || number === "" || number === null) return undefined

    let countryCode = 'VN'
    if (!isValidPhoneNumber(number.toString(), countryCode)) {
      return "Invalid phone number";
    }
    return true
  }

  // const validate = (data) => {
  //   if ((data.email === '' || data.email === undefined) && (data.mobile === undefined || data.mobile === '')) {
  //     toast.error("Email or mobile must be required.")
  //     return false
  //   }
  //   return true
  // }

  const generateFieldId = fieldName => `${fieldName}_${index}`;

  const salutation  = `salutation_${index}`;
  const first_name  = `first_name_${index}`;
  const last_name   = `last_name_${index}`;
  const email       = `email_${index}`;
  const mobile      = `mobile_${index}`;
  const position    = `position_${index}`;

  return (
    <>
      <Flex justifyContent="end" className="mx-3">
        <IconButton
          onClick={removeContact}
          variant="falcon-default"
          size="sm"
          icon="trash"
          transform="shrink-3"
        >
        </IconButton>
      </Flex>

      <Form.Group as={Row} className="mb-3" controlId={salutation}>
        <Form.Label column sm={3} className="text-lg-end">
          Salutation
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name={salutation}
            render={() => (
              <Select
                closeMenuOnSelect={true}
                options={salutationOptions}
                //defaultValue={salutationOptions[1]}
                placeholder='Select...'
                classNamePrefix="react-select"
                onChange={selectedOption => handleSelectChange("salutation", selectedOption)}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Salutation is required'
              }
            }}
          />
          <span className="text-danger">
            {errors[salutation] && errors[salutation].message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={first_name}>
        <Form.Label column sm={3} className="text-lg-end">
          Contact name
        </Form.Label>
        <Col sm={9} md={7}>
          <Row className="g-2">
            <Col>
              <Form.Control
                type="text"
                name={first_name}
                placeholder="First name"
                className="fs--1"
                {...register(first_name, {
                  required: "First name must be required."
                })}
                onChange={(e) => handleInputChange("first_name", e.target.value)}
              />
              <span className="text-danger">
                {errors[first_name] && errors[first_name].message}
              </span>
            </Col>
            <Col>
              <Form.Control
                type="text"
                name={last_name}
                placeholder="Last name"
                className="fs--1"
                {...register(last_name)}
                onChange={(e) => handleInputChange("last_name", e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={email}>
        <Form.Label column sm={3} className="text-lg-end">
          Email
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="email"
            name={email}
            placeholder="example@pebsteel.com.vn"
            className="fs--1"
            {...register(email, {
              //required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address'
              }
            })}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          <span className="text-danger">
            {errors[email] && errors[email].message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={mobile}>
        <Form.Label column sm={3} className="text-lg-end">
          Phone number
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name={mobile}
            render={({field: {ref, ...field}}) => (
              <PhoneInput
                {...field}
                inputProps={{ref}}
                country='vn'
                autoFormat={true}
                countryCodeEditable={false}
                enableSearch={true}
                disableSearchIcon={true}
                inputStyle={{width: '100%'}}
                searchStyle={{width: '95%'}}
                onChange={(value) => handleInputChange('mobile', value)}
              />
            )}
            rules={{ validate: validatePhoneNumber }}
          />
          <span className="text-danger">
            {errors[mobile] && errors[mobile].message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={position}>
        <Form.Label column sm={3} className="text-lg-end">
          Title
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="text"
            name={position}
            placeholder="Title"
            className="fs--1"
            {...register(position, {
              required: "Title must be required."
            })}
            onChange={(e) => handleInputChange("position", e.target.value)}
          />
        </Col>
      </Form.Group>
      
      <div className="border-dashed border-bottom my-4"></div>
    </>
  )
});

ContactForm.propTypes = {
  index: PropTypes.number.isRequired,
  contact: PropTypes.object.isRequired,
  control: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  removeContact: PropTypes.func.isRequired,
  updatedContact: PropTypes.func.isRequired
}

export default ContactForm;