import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteBooking, resetBooking, sendBackEmail, sendBackToApprove } from 'redux/booking/car/carBookingSlice';
import TransactionDetailApprovalModal from './TransactionDetailApprovalModal';
import TransactionDetailCancelModal from './TransactionDetailCancelModal';
import TransactionDetailRejectModal from './TransactionDetailRejectModal';
import CloneBookingConfirm from './CloneBookingConfirm';
import TransferSiteOfficeConfirm from './TransferSiteOfficeConfirm';
import { FaBan, FaClone, FaEdit, FaEllipsisV, FaEnvelope, FaPowerOff, FaTrashAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { MdOutlineTransferWithinAStation } from 'react-icons/md';

const TransactionDetailHeader = ({transaction}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showApproveForm, setShowApproveForm] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [confirmType, setConfirmType] = useState("delete");
  const [showCloneConfirm, setShowCloneConfirm] = useState(false);
  const [showTransferSiteOfficeForm, setShowTransferSiteOfficeForm] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionUrl = "/booking/car";
  const transactionId = transaction?.id;

  const {
    allow_create,
    allow_edit,
    allow_delete,
    allow_approve,
    allow_reset,
    allow_cancel,
    allow_send_back_email,
    allow_send_to_approve,
    allow_clone,
    allow_transfer_site_office
  } = transaction.permissions || {};

  const handleNewBooking = () => {
    navigate("/booking/car/new-car-booking");
  }

  const handleEditBooking = () => {
    navigate(`${transactionUrl}/${transactionId}/edit`, {state: transaction});
  }

  const confirmMsg = (type, message) => {
    setConfirmType(type)
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleDeleteBooking = () => {
    const message = `Are you sure to delete booking ID#<strong class='text-danger'>${transactionId}</strong>?`
    confirmMsg("delete", <span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      switch(confirmType) {
        case "delete":
          dispatch(deleteBooking(transaction))
          navigate(`${transactionUrl}/list`)
          
          break;
        case "reset":
          dispatch(resetBooking(transactionId))
          
          closeConfirmModal()
          break;
      }
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleBookingList = () => {
    navigate(`${transactionUrl}/list`)
  }

  const handleApproveBooking = () => {
    setShowApproveForm(true)
  }

  const handleRejectBooking = () => {
    setShowRejectForm(true)
  }

  const handleTransferSiteOffice = () => {
    setShowTransferSiteOfficeForm(true)
  }

  const handleSendBackEmailBooking = () => {
    dispatch(sendBackEmail(transactionId))
  }

  const handleResetBooking = () => {
    const message = `Are you sure to reset booking ID#<strong class='text-primary'>${transactionId}</strong>?`
    confirmMsg("reset", <span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleCancelBooking = () => {
    setShowCancelForm(true)
  }

  const handleSendBackToApprove = () => {
    dispatch(sendBackToApprove(transactionId))
  }

  const handleCloneBooking = () => {
    setShowCloneConfirm(true)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            {allow_create && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="car"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleNewBooking}
              >
                <span className="d-none d-xl-inline-block ms-1">New Car Booking</span>
              </IconButton>
            )}
 
            {allow_approve && (
              <IconButton
                variant="info"
                size="sm"
                icon="check-circle"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleApproveBooking}
              >
                <span className="d-none d-xl-inline-block ms-1">Approve</span>
              </IconButton>
            )}
            
            {allow_approve && (
              <IconButton
                variant="danger"
                size="sm"
                icon="times-circle"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleRejectBooking}
              >
                <span className="d-none d-xl-inline-block ms-1">Reject</span>
              </IconButton>
            )}

            {allow_clone && (
              <Button variant="falcon-default" size="sm" className="me-1" onClick={handleCloneBooking}>
                <FaClone />
                <span className="d-none d-xl-inline-block ms-1">Clone</span>
              </Button>
            )}

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBookingList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_edit && (
                    <Dropdown.Item className="mb-2" onClick={handleEditBooking}>
                      <FaEdit /> Edit
                    </Dropdown.Item>
                  )}
                  
                  {allow_send_to_approve && (
                    <Dropdown.Item className="mb-2" onClick={handleSendBackToApprove}>
                      <FaEnvelope /> Send back to approve
                    </Dropdown.Item>
                  )}

                  {allow_send_back_email && (
                    <Dropdown.Item className="mb-2" onClick={handleSendBackEmailBooking}>
                      <FaEnvelope /> Send back email
                    </Dropdown.Item>
                  )}

                  {allow_transfer_site_office && (
                    <Dropdown.Item className="mb-2 text-warning" onClick={handleTransferSiteOffice}>
                      <MdOutlineTransferWithinAStation /> Transfer site office
                    </Dropdown.Item>
                  )}
                  
                  {allow_reset && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2" onClick={handleResetBooking}>
                        <FaPowerOff icon="power-off" /> Reset
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {allow_cancel && (
                    <Dropdown.Item className="mb-2 text-danger" onClick={handleCancelBooking}>
                      <FaBan /> Cancel
                    </Dropdown.Item>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger" onClick={handleDeleteBooking}>
                        <FaTrashAlt className="text-danger" /> Delete
                      </Dropdown.Item>
                    </>
                  )}
                  
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message={messageConfirm}
        />
      )}
      
      {showApproveForm && (
        <TransactionDetailApprovalModal
          showModal={showApproveForm}
          setshowModal={setShowApproveForm}
          transaction={transaction}
        />
      )}
      
      {showRejectForm && (
        <TransactionDetailRejectModal
          showModal={showRejectForm}
          setshowModal={setShowRejectForm}
          transaction={transaction}
        />
      )}
      
      {showCancelForm && (
        <TransactionDetailCancelModal 
          showModal={showCancelForm}
          setshowModal={setShowCancelForm}
          transaction={transaction}
        />
      )}

      {showCloneConfirm && (
        <CloneBookingConfirm 
          showModal={showCloneConfirm}
          setshowModal={setShowCloneConfirm}
          transaction={transaction}
        />
      )}

      {showTransferSiteOfficeForm && (
        <TransferSiteOfficeConfirm 
          showModal={showTransferSiteOfficeForm}
          setshowModal={setShowTransferSiteOfficeForm}
          transaction={transaction}
        />
      )}
      
    </>
  );
};

TransactionDetailHeader.propTypes = {
  transaction: PropTypes.object.isRequired
}

export default TransactionDetailHeader;