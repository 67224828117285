import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import OtherForm from './OtherForm';
import Flex from "components/common/Flex";
import { useDispatch } from "react-redux";
import { deleteBookingShuttle, removeShuttleDocument } from 'redux/booking/travel/travelBookingSlice';
import OtherItem from './OtherItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import PropTypes from 'prop-types';

const OtherList = ({booking}) => {
  const [showModalOther, setShowModalOther] = useState(false)
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [shuttle, setShuttle] = useState(null)
  const {shuttles} = booking

  const bookingId = booking.id
  const dispatch = useDispatch()

  const handleEditOtherBooking = (shuttle) => {
    setShuttle(shuttle)
    openModal()
  }

  const handleDeleteOtherBooking = (shuttle) => {
    setShuttle(shuttle)
    setShowConfirmationDelete(true)
  }

  const handleRemoveAttachment = (shuttleId, documentId) => {
    dispatch(removeShuttleDocument({bookingId, shuttleId, documentId}))
  }

  const handleCreateRecordBooking = () => {
    setShuttle(null)
    openModal()
  }

  const openModal = () => {
    setShowModalOther(true)
  }

  const closeConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmation()
  }

  const handleOkConfirm = async () => {
    const shuttleId = shuttle.id
    dispatch(deleteBookingShuttle({bookingId, shuttleId}))
    closeConfirmation()
  }

  const shuttleOthers = shuttles && shuttles.filter((shuttle) => shuttle.other === true)

  return (
    <>
      <Flex justifyContent="end" className="mx-4">
        <Button
          variant="falcon-primary"
          className="me-2"
          onClick={handleCreateRecordBooking}
        >
          <FontAwesomeIcon icon="plus" /> New record booking
        </Button>
      </Flex>
      
      {shuttleOthers.map((shuttle) => (
        <OtherItem
          key={shuttle.id}
          booking={booking}
          record={shuttle}
          editShuttleBooking={() => handleEditOtherBooking(shuttle)}
          deleteShuttleBooking={() => handleDeleteOtherBooking(shuttle)}
          handleRemoveAttachment={handleRemoveAttachment}
        />
      ))}

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to delete?"
      />

      <OtherForm 
        showModal={showModalOther}
        setshowModal={setShowModalOther}
        booking={booking}
        record={shuttle}
      />
    </>
  )
};

OtherList.propTypes = {
  booking: PropTypes.object.isRequired
}

export default OtherList;