import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { Controller, useForm } from "react-hook-form";
import TinymceEditor from "components/common/TinymceEditor";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
//import {v4 as uuidv4} from "uuid"
import PropTypes from 'prop-types';

const AddChangeForm = ({
  showModal,
  setshowModal,
  building,
  descriptionChanges,
  setDescriptionChanges,
  descriptionChange
}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue} = useForm();
  const [title, setTitle] = useState("Add some changes for building!");

  useEffect(() => {
    //for case edit
    if (descriptionChange) {
      setTitle(`Edit description change with change No.#${descriptionChange.change_no}`)

      Object.entries(descriptionChange).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [building, descriptionChange, setValue])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const onSubmit = (data) => {
    console.log(data)

    //deal_proj_id: building.id

    //for case edit
    if (descriptionChange) {
      const updatedDescriptionChanges = descriptionChanges.map(item =>
        item.change_no === descriptionChange.change_no ? { ...item, ...data } : item
      )

      setDescriptionChanges(updatedDescriptionChanges)
      toast.success(`Change no#${data.change_no} updated.`)
      closeModal()
    }
    else 
    {
      const newDataItem = { ...data, deal_proj_id: building.id}
      setDescriptionChanges([...descriptionChanges, newDataItem])
      toast.success(`Change no#${data.change_no} created.`)
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Specify information and create a list of buildings slated for modifications.
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="building_name">
            <Form.Label column sm={2} className="text-lg-end">
              Bldg No.
            </Form.Label>
            <Col sm={10} md={9}>
              <Form.Control
                name="building_name"
                placeholder="Building"
                className="fs--1"
                defaultValue={building.name}
                readOnly={true}
                {...register("building_name", {
                  required: "Building must be required."
                })}
              />
              <span className="text-danger">
                {errors.building_name && errors.building_name.message}
              </span>  
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="change_no">
            <Form.Label column sm={2} className="text-lg-end">
              Change No.
            </Form.Label>
            <Col sm={10} md={9}>
              <Form.Control
                type="number"
                name="change_no"
                placeholder="Change No."
                className="fs--1"
                {...register("change_no")}
              />
              <span className="text-danger">
                {errors.change_no && errors.change_no.message}
              </span>  
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="description">
            <Form.Label column sm={2} className="text-lg-end">
              Description of changes
            </Form.Label>
            <Col sm={10} md={9}>
              <Controller
                control={control}
                name="description"
                rules={{required: "Description of changes must be required."}}
                render={({ field }) => (
                  <TinymceEditor
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <span className="text-danger">
                {errors.description && errors.description.message}
              </span> 
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" variant="primary" type="submit">{descriptionChange ? "Update" : "Add"} change</Button>
          <Button size="sm" variant="danger" onClick={handleCancel}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

AddChangeForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  building: PropTypes.object.isRequired,
  descriptionChanges: PropTypes.object.isRequired,
  setDescriptionChanges: PropTypes.func.isRequired,
  descriptionChange: PropTypes.object.isRequired
}

export default AddChangeForm;