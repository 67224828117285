import React from 'react';
import { Card, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { formatNumber, formatNumberWord } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const JobEntryLost = ({data}) => {
  const {current_period, previous_period, summary} = data;
  const summaryData = summary?.data;
  const lostData = summaryData && summaryData.filter(item => item.seq === 3);

  const lostjobsCurrentPeriod = current_period?.lost_jobs || {};
  const lostjobsPreviousPeriod = previous_period?.lost_jobs || {};

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Lost jobs"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body className="pb-0">
        <Card className="bg-danger">
          <Card.Body className="text-white">
            <Row className="mb-3">
              <Col md={6} sm={12}>
                <h6 className="text-white">Number of projects</h6>

                <span className="h3 text-white">{formatNumberWord(lostjobsCurrentPeriod?.number_of_projects || 0)}</span>
                <span className="fs--1 ms-1 mb-3">vs {formatNumberWord(lostjobsPreviousPeriod?.number_of_projects || 0)}</span>
                <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
              </Col>
              <Col md={6} sm={12}>
                <h6 className="text-white">Weight</h6>
                  <span className="h3 text-white">{formatNumberWord(lostjobsCurrentPeriod?.weight || 0)} M.T</span>
                  <span className="fs--1 ms-1 mb-3">vs {formatNumberWord(lostjobsPreviousPeriod?.weight || 0)} M.T</span>
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                        </Tooltip>
                      }
                    >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="mx-ncard">
          <SimpleBarReact>
            <Table className="fs--1 mb-0 overflow-hidden">
              <thead className="bg-100 text-800">
                <tr>
                  <th className="text-nowrap">Job number</th>
                  <th className="text-nowrap text-end">Weight</th>
                  <th className="text-nowrap text-end">Sales region</th>
                </tr>
              </thead>
              <tbody>
                {lostData && lostData.length > 0 ? (
                  lostData.map(item => (
                    <tr key={item.id}>
                      <td className="text-truncate">
                        <Link to={`/crm/projects/${item.id}`} target="_blank">{item.j_number}</Link>
                      </td>
                      <td className="text-end">{formatNumber(item.est_final_weight)} M.T</td>
                      <td className="text-end">{item.sales_unit_region}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center text-primary">No data found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>
      </Card.Body>
    </Card>
  )
};

JobEntryLost.PropTypes = {
  data: PropTypes.array.isRequired
}

export default JobEntryLost;