import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import apiAxios from 'helpers/apiAxios';
import React, { useCallback, useState } from 'react';
import { Card, Dropdown, Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deactivateUser, searchUser, unlockUser } from 'redux/authorization/userSlice';
import UserDetailChangePassword from './UserDetailChangePassword';
import { MdDelete, MdEdit, MdLock } from 'react-icons/md';
import { FaSearch, FaUnlock } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { debounce } from 'helpers/debounce';

const UserDetailHeader = ({user}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [valueSearch, setValueSearch] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userUrl = "/authorization/users";
  const userId = user?.id;

  const handleNewuser = () => {
    navigate(`${userUrl}/new`)
  }

  const handleEditUser = () => {
    navigate(`${userUrl}/${userId}/edit`, {state: user});
  }

  const handleDeleteUser = () => {
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    try {
      await apiAxios.delete(`${userUrl}/${userId}`)
      toast.success(`User ${user.name} has been deleted sucessful.`)
      navigate(userUrl)
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleUserList = () => {
    navigate(userUrl)
  }

  const handleDeactivate = () => {
    dispatch(deactivateUser(user.id))
  }

  const handleChangePassword = () => {
    setShowChangePassword(true)
  }

  // const handleBypassConfirmation = () => {
  //   dispatch(confirmUser(userId))
  // }

  const handleUnlock = () => {
    dispatch(unlockUser(userId))
  }

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const params = {q: value.toUpperCase()}
      console.log(params)
      dispatch(searchUser(params));
    }, 500),
    [dispatch]
  );

  const handleChange = (e) => {
    const {value} = e.target;
    setValueSearch(value)
    //handleChangeDebounced(value);
  }

  const handleSeach = async () => {
    const params = {q: valueSearch.toUpperCase()}
    const response = await dispatch(searchUser(params));
    const {user_id, message} = response.payload;

    if (user_id) {
      navigate(`/authorization/users/${user_id}`);
      toast.success(message)
    }
    else {
      toast.error(message)
    }
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <Flex justifyContent="around">
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
            />
          
            <InputGroup className="fs--1 ms-2">
              <Form.Control size="sm" placeholder="Search name..." onChange={handleChange} />
              <Button size="sm" type="submit" variant="falcon-default" onClick={handleSeach}>
                <FaSearch className="me-1" />
              </Button>
            </InputGroup>
          </Flex>
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="user"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewuser}
            >
              <span className="d-none d-xl-inline-block ms-1">New user</span>
            </IconButton>
            <IconButton
              variant="info"
              size="sm"
              icon="user-lock"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleDeactivate}
            >
              <span className="d-none d-xl-inline-block ms-1">{user?.active ? 'Deactivate' : 'Activate'}</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleUserList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleChangePassword}>
                    <MdLock /> Change password
                  </Dropdown.Item>

                  {/* {!user?.confirmed && (
                    <Dropdown.Item onClick={handleBypassConfirmation} className="my-1">
                    <MdConfirmationNumber /> Bypass confirmation
                    </Dropdown.Item>
                  )} */}

                  {user?.access_locked && (
                    <Dropdown.Item onClick={handleUnlock} className="my-1">
                    <FaUnlock /> Unlock
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />

                  <Dropdown.Item onClick={handleEditUser}>
                    <MdEdit /> Edit
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item className="text-danger" onClick={handleDeleteUser}>
                    <MdDelete className="text-danger" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message="Are you sure to delete?"
      />

      <UserDetailChangePassword
        user={user}
        showModal={showChangePassword}
        setshowModal={setShowChangePassword}
      />
    </>
  );
};

UserDetailHeader.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserDetailHeader;
