import React from "react"
import { Button, Form, Image, Modal } from "react-bootstrap"
import { toast } from "react-toastify";
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import Flex from "components/common/Flex";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeAvatar } from "redux/authorization/userSlice";

const ChangeAvatar = ({showModal, setshowModal, user}) => {
  const [avatar, setAvatar] = React.useState([]);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {getInputProps, getRootProps} = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: acceptedFiles => {
      setAvatar(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  })

  const handleRemove = () => {
    setAvatar([]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('avatar', avatar[0])

    try {
      if (user)
      {
        const userId = user.id
        await dispatch(changeAvatar({userId, formData}))
        setshowModal(false)

        navigate(`/user/profile`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleCancel = () => {
    setshowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Change avatar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {avatar && avatar.length === 0 ? (
              <div {...getRootProps({ className: 'dropzone-area py-5' })}>
                <input {...getInputProps({multiple:false})} />
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">Drop your avatar here</p>
                </Flex>
              </div>
            ) : (
              <div className="mt-1">
                <Flex 
                  direction="column"
                  alignItems="center"
                >
                  <Image width={150} height={100} src={avatar && avatar.length > 0 ? avatar[0]?.preview : null} />
                  <Link className="mt-2 fs--1 text-danger" title="Remove avatar" onClick={handleRemove}>
                    <FontAwesomeIcon icon="trash" /> Remove
                  </Link>
                </Flex>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit">Change</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
          </Form>
      </Modal>
  </>
  ) 
}

ChangeAvatar.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default ChangeAvatar;