import { Button, Form } from "react-bootstrap"
import apiAxios from "helpers/apiAxios";
import { debounce } from "helpers/debounce";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async"
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Flex from "components/common/Flex";
import { assignRoleUser } from "redux/authorization/roleSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const RoleDetailInfoUserAdd = () => {
  const { handleSubmit, setValue } = useForm()
  const dispatch = useDispatch()
  const asyncSelectRef = useRef()
  const [renderTrigger, setRenderTrigger] = useState(0)
  const {recordData: role} = useSelector((state) => state.authorization_role)
   
  const onSubmit = (data) => {
    console.log(data)

    try {
      if (role) {
        dispatch(assignRoleUser({role, data}))
        asyncSelectRef.current.clearValue()
        setRenderTrigger(renderTrigger + 1)
      }
    }
    catch (error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const filterOptions = async (inputValue, justShowName=false) => {
    //console.log(inputValue);
    const url = `/authorization/roles/users_by_role?role_id=${role.id}&page=1&per_page=30&q=${inputValue}`
    const response = await apiAxios.get(url)
    const {data} = response
  
    let options = []
    options = data && data.map((item) => ({ value: justShowName ? item.attributes.name : item.attributes.id, label: item.attributes.name }))
  
    return options.filter((item) => 
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const debouncedFilterPermissions = debounce((inputValue, callback) => {
    filterOptions(inputValue).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);

  const loadPermissionOptions = (inputValue, callback) => {
    debouncedFilterPermissions(inputValue, callback);
  };

  const handleChangeRole = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('user_ids', selectedOptionsValues, { shouldValidate: true })
  }

  return (
    <Flex direction="column" alignContent="center">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Flex justifyContent="center" className="mt-4 mb-3">
          <AsyncSelect
            key={renderTrigger}
            ref={asyncSelectRef}
            cacheOptions
            defaultOptions
            loadOptions={loadPermissionOptions}
            closeMenuOnSelect={false}
            placeholder='Select...'
            classNamePrefix="react-select"
            className="w-100 fs--1"
            name="user_ids"
            required
            isMulti
            onChange={handleChangeRole}
          />
        </Flex>
        <Flex justifyContent="center">
          <Button
            style={{height: "37px"}}
            type="submit"
            variant="falcon-default"
            className="border-300 fs--1"
          >
            <FontAwesomeIcon icon="user-shield" /> Assign User
          </Button>
        </Flex>
      </Form>
    </Flex>
  )
}

export default RoleDetailInfoUserAdd