import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Notification = ({ notification: { title, type, waiver_type, number_of_waivers }, isLast, viewDetail }) => {
  return (
    <li
      className={classNames(`alert mb-0 rounded-0 py-3 px-x1 alert-${type}`, {
        'border-top': !(type === 'warning'),
        'border-0': isLast,
        'border-x-0 border-top-0': !isLast
      })}
    >
      <Row className="flex-between-center">
        <Col>
          <Flex>
            <FontAwesomeIcon
              icon="circle"
              className={classNames('mt-1 fs--2', {
                'text-primary': !type
              })}
            />
            <p className="fs--1 ps-2 mb-0">{number_of_waivers} {title}</p>
          </Flex>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <Link to="#!" className="alert-link fs--1 fw-medium" onClick={() => viewDetail(title, waiver_type)}>
            View detail
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Link>
        </Col>
      </Row>
    </li>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    waiver_type: PropTypes.string,
    number_of_waivers: PropTypes.string
  }),
  isLast: PropTypes.bool,
  viewDetail: PropTypes.func
};

export default Notification;
