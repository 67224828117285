import React from 'react';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getSize } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const BookingUploadAttachment = ({files, setFiles}) => {

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, .doc, .docx, .xls, .xlsx, .pdf',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone-area py-6' })}>
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">Drop your files here</p>
        </Flex>
      </div>

      <div className="mt-3">
        {files && files.map(file => (
          <Flex
            alignItems="center"
            className="py-1 border-bottom btn-reveal-trigger"
            key={file.path}
          >
            <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
              <div>
                <h6>{file.path}</h6>
                <Flex className="position-relative" alignItems="center">
                  <p className="mb-0 fs--1 text-400 line-height-1">
                    <strong>
                      {getSize(file.size)}
                    </strong>
                  </p>
                </Flex>
              </div>
            </Flex>

            <FontAwesomeIcon icon="trash" onClick={() => handleRemove(file.path)} style={{cursor: "pointer"}} />
          </Flex>
        ))}
      </div>
    </div>
  );
};

BookingUploadAttachment.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired
}

export default BookingUploadAttachment;