import DateTimeToText from 'helpers/dateTimetoText';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Form, ListGroup } from 'react-bootstrap';
import React, { useState } from 'react';
import AddressForm from './AddressForm';
import { setPrimaryAddress } from 'redux/crm/customerSlice';
import { useDispatch } from 'react-redux';

const Address = ({customer, address, editAddress, deleteAddress}) => {
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const {allow_set_primary_address} = customer?.permissions || {};

  if (!address) {
    return null;
  }

  const handleSetPrimary = (addressId) => {
    dispatch(setPrimaryAddress({customerId: customer.id, addressId }));
  };

  const handleEditAddress = () => {
    editAddress(address)
    setEdit(true)
  };

  const handleDeleteAddress = () => {
    deleteAddress(address)
  }

  const handleCloseEditAddress = () => {
    setEdit(false)
  }

  return (
    <>
      {edit ? (
        <div className='my-3'>
          <AddressForm customerId={customer.id} address={address} closeEdit={handleCloseEditAddress} />
        </div>
      ) : (
        <ListGroup.Item className='p-3 p-sm-4 light' as={Link} action>
          <Flex justifyContent='between'>
            <h5 className='mb-1'>
              <Form.Check 
                type='switch'
                id='set-primary-address'
                label={`${address.address_type} - ID#:${address.id}`}
                checked={address.is_primary}
                disabled={!allow_set_primary_address}
                onChange={() => handleSetPrimary(address.id)}
              />
            </h5>
            <small><DateTimeToText datetime={address.created_at} /></small>
          </Flex>
          <p className='mb-2'>
            {address.name}
          </p>
          <Flex justifyContent='between' alignContent='center'>
            <small className='font-italic'>Created by {address.created_by}</small>
            <div>
              <IconButton
                variant='primary'
                size='sm'
                icon='edit'
                transform='shrink-2'
                iconAlign='middle'
                className='me-1'
                title='Edit address'
                onClick={handleEditAddress}
              />
              <IconButton
                variant='danger'
                size='sm'
                icon='trash-alt'
                transform='shrink-2'
                iconAlign='middle'
                className='me-1'
                title='Delete address'
                onClick={handleDeleteAddress}
              />
            </div>
          </Flex>
        </ListGroup.Item>
      )}
    </>
  )
}

Address.propTypes = {
  customer: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  edtAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired
}

export default Address;