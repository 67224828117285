import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';

const PrivacyPolicy = () => {
  return (
    <Flex justifyContent="end" className="mt-2">
      <small>&copy; Pebsteel Portal. All Rights Reserved | <Link to="/privacy-policy">Privacy Policy</Link></small>
    </Flex>
  );  
};

export default PrivacyPolicy;
