import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from 'helpers/reducerHelpers';

const permissionUrl = "/authorization/permissions"

export const getAllPermissions = createAsyncThunk(
  "Permission/getAllPermissions",
  async (params) => {
    try {
      const response = await apiAxios.get(`${permissionUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const createPermission = createAsyncThunk(
  "Permission/createPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(permissionUrl, { permission: data})
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updatePermission = createAsyncThunk(
  "Permission/updatePermission",
  async ({ permissionId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${permissionUrl}/${permissionId}`, { permission: data})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deletePermission = createAsyncThunk(
  "Permission/deletePermission",
  async (permissionIds) => {
    try {
      const response = await Promise.all(permissionIds.map(async (id) => {
        await apiAxios.delete(`${permissionUrl}/${id}`);
        return id;
      }));
      return response;

    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportPermissions = createAsyncThunk(
  "Permission/exportPermissions",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${permissionUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Permissions.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const findAndReplacePermission = createAsyncThunk(
  "Permission/findAndReplacePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${permissionUrl}/find_and_replace_subject_class`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const permissionSlice = createSlice({
  name: "authorization_permission",
  initialState: {
    ...initialState
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPermissions.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      
      .addCase(createPermission.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
        //handlePayload(state, action, "delete");
        action.payload.forEach(id => {
          const index = state.allData.data.findIndex(permission => permission.id === id);
          if (index !== -1) {
            state.allData.data.splice(index, 1);
            toast.success(`Permission ${id} was successfully deleted.`)
          }
        });
      })
      .addCase(deletePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(exportPermissions.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportPermissions.fulfilled, (state) => {
        state.exporting = false
        toast.success("Permission has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = permissionSlice.actions;

export default permissionSlice.reducer;