import { Col, Row, Image, Form } from "react-bootstrap"
import logo from "./../../../../assets/img/pebsteel.png"
import React from 'react';
import PropTypes from 'prop-types';

const DetailBDSGeneralInformation = ({ project, building, adaptation, area }) => {

  return (
    <Row className="gx-3 fs--1">
      <Col sm="auto">
        <Image
          fluid
          className="fit-cover rounded"
          src={logo}
          alt="Logo Pebsteel"
          width="90"
          height="90"
        />
      </Col>
      <Col sm={3}>
        <Row className="gx-2 mb-2">
          <Col xs="auto">Quote No:</Col>
          <Col><span className="text-primary">{project.q_number}</span></Col>
        </Row>
        <Row className="gx-2 mb-2">
          <Col xs="auto">P/Job No:</Col>
          <Col><span className="text-primary">{building.j_number}</span></Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row className="gx-2 mb-2">
          <Col xs="auto">CO#:</Col>
          <Col><span className="text-primary">{adaptation?.co || '00'}</span></Col>
        </Row>
        <Row className="gx-2 mb-2">
          <Col xs="auto">REV#:</Col>
          <Col><span className="text-primary">{adaptation?.rev}</span></Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row className="gx-2 mb-2">
          <Col xs="auto">Date:</Col>
          <Col><span className="text-primary">{adaptation?.entry_date}</span></Col>
        </Row>
        <Row className="gx-2 mb-2">
          <Col xs="auto">Date:</Col>
          <Col><span className="text-primary">{adaptation?.entry_date}</span></Col>
        </Row>
      </Col>
      <Col sm={3}>
        <Row>
          <Col xs="auto">
            <Form.Check
              type="checkbox"
              label="For Order Entry"
              name="for_order_entry"
            />
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              label="For Quotation"
              name="for_quotation"
              checked={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <div>Bldg No : <span className="text-primary">{building.name}</span></div>
          </Col>
          <Col>
            <div>Area No: <span className="text-primary">{area.id}</span></div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

DetailBDSGeneralInformation.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired
}

export default DetailBDSGeneralInformation;