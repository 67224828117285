import React, { useState } from 'react';
import { FaCopy, FaEye, FaEyeSlash } from "react-icons/fa";
import PropTypes from "prop-types";

const SecretText = ({ defaultSecret }) => {
  const [showSecret, setShowSecret] = useState(false);

  const handleShowSecret = () => {
    setShowSecret(!showSecret);
  };

  const handleCopySecret = () => {
    navigator.clipboard.writeText(defaultSecret);
  }

  return (
    <div>
      <span className="text-primary">{
        showSecret ? defaultSecret : '***********************************'}
      </span>
      {showSecret ? (
        <FaEyeSlash className="cursor-pointer ms-2 fs-0" onClick={handleShowSecret} />
      ) : (
        <FaEye className="cursor-pointer ms-2 fs-0" onClick={handleShowSecret} />
      )}
      <FaCopy className="cursor-pointer ms-2 fs-0" onClick={handleCopySecret}/>
    </div>
  );
};

SecretText.propTypes = {
  defaultSecret: PropTypes.string.isRequired
};

export default SecretText;