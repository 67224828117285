import ContactForm from "./ContactForm";
import { Card } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import { useNavigate } from "react-router-dom";
import React from 'react';

const NewContact = () => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate(-1)
    return null
  }

  return (
    <Card style={{padding: '20px'}} className="fs--1">
      <Card.Header className="d-flex flex-between-center border-bottom">
        <Card.Title>New Contact</Card.Title>
        <IconButton
          onClick={() => navigate(-1)}
          variant="falcon-default"
          size="sm"
          icon="arrow-left"
        />
      </Card.Header>
      <Card.Body className="mt-3">
        <ContactForm handleCancel={handleCancel}/>
      </Card.Body>
    </Card>
  )
}

export default NewContact