import { Card, Col, ListGroup, Row, Collapse } from "react-bootstrap";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import AddressForm from "./address/AddressForm";
import Address from "./address/Address";
import ConfirmationModal from "components/confirmation/ConfirmationModal";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteAddress, setAddresses } from "redux/crm/addressSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const CustomerDetailAddress = ({customer={}, allow_create}) => {
  const {data: addresses} = useSelector((state) => state.crm_address);
  const [collapsed, setCollapsed] = useState(true);
  const [address, setAddress] = useState({});
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (customer) {
      dispatch(setAddresses(customer?.addresses))
    }
  }, [customer]);

  const handleEditAddress = (address) => {
    setAddress(address)
  }

  const handleDeleteAddress = (address) => {
    setAddress(address)
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    try {
      const customerId = customer?.id
      const addressId = address?.id
      dispatch(deleteAddress({customerId, addressId}))
      handleCloseConfirmation()
    }
    catch(error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Addresses</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          {allow_create && (
            <Flex
              alignItems="center"
              className="mb-3 text-primary fs-0 cursor-pointer"
              onClick={() => setCollapsed(!collapsed)}
            >
              <span className="circle-dashed">
                <FontAwesomeIcon icon="plus" />
              </span>
              <span className="ms-3">Add new address</span>
            </Flex>
          )}
       
          <Collapse in={!collapsed}>
            <div className="fs--1">
              <AddressForm
                customerId={customer?.id}
                setCollapsed={setCollapsed}
              />
              <div className="border-dashed border-bottom my-3" />
            </div>
          </Collapse>

          <Row className="mb-5 fs--1">
            <Col>
              <ListGroup>
                {addresses && addresses.map((address, index) => (
                  <Address
                    customer={customer}
                    address={address}
                    key={index}
                    editAddress={handleEditAddress}
                    deleteAddress={handleDeleteAddress} />
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message={`Are you sure to delete address ${address.name}-${address.id} ?`}
      />
    </>
  )
}

CustomerDetailAddress.propTypes = {
  customer: PropTypes.object.isRequired,
  allow_create: PropTypes.bool.isRequired
}

export default CustomerDetailAddress;