import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatNumberWord } from "helpers/utils";
import { FaCaretDown, FaCaretUp, FaInfoCircle } from "react-icons/fa";
import SoftBadge from "components/common/SoftBadge";
import { Link } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';

const NumberWeightSummary = ({title, current_value, previous_value, isRevenue, handleClick, titleHeader}) => {

  const percent = previous_value > 0 ? ((current_value / previous_value) * 100)?.toFixed(0) : 0;
  const caretUp = current_value > previous_value;
  
  const formartNumberProject = (value) => {
    let newValue = formatNumberWord(parseInt(value || 0))

    return <span>
      <span className="fs-4">{newValue}</span>{caretUp ? (<FaCaretUp className="text-success" />) : (<FaCaretDown className="text-danger"/>)}
    </span>
  }

  return (
    <Card className={`h-100 ${isRevenue ? 'bg-success' : ''}`} onClick={handleClick}>
      <Card.Body>
        <Flex
          justifyContent="center"
          alignItems="center"
          className={`mb-3 position-static`}
        >
          <h6 className={`mb-0 flex-1 ${isRevenue ? 'text-white' : ''}`}>{title} <FaInfoCircle title={titleHeader} /></h6>
        </Flex>
        <Flex justifyContent="between" alignItems="center">
          <div className={`font-sans-serif lh-1 mb-2 pe-2`}>
            <span className={`${isRevenue ? 'text-white' : ''}`}>
              {formartNumberProject(current_value)}
            </span>
            <span className={`text-${isRevenue ? '300' : '500'} fs--1 ms-1`}>
              vs {formatNumberWord(previous_value)}
            
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ position: 'fixed' }}>
                    Compare with the same period in the previous year
                  </Tooltip>
                }
              >
                <span>
                  <FontAwesomeIcon
                    icon="info-circle"
                    transform="shrink-1"
                    className="ms-1 text-400"
                  />
                </span>
              </OverlayTrigger>
            </span>
          </div>
          <div className={`mb-2 text-${caretUp ? 'success' : 'danger'}`}>
            <SoftBadge pill bg={`${caretUp ? 'success' : 'danger'}`} className="fs--1 fw-bold">{percent}%</SoftBadge>
          </div>
        </Flex>

        {handleClick && (
          <div className="fs--1 text-end mt-3">
            <Link to="#" className="">View Detail</Link>
          </div>
        )}
        
      </Card.Body>
    </Card>
  )
};

NumberWeightSummary.propTypes = {
  title: PropTypes.string.isRequired,
  current_value: PropTypes.number.isRequired,
  previous_value: PropTypes.number.isRequired,
  isRevenue: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default NumberWeightSummary;