import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import ShuttleForm from './ShuttleForm';
import ConfirmationModal from "components/confirmation/ConfirmationModal";
import Flex from "components/common/Flex";
import { useDispatch } from "react-redux";
import ShuttleItem from './ShuttletIem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteBookingShuttle, removeShuttleDocument } from 'redux/booking/travel/travelBookingSlice';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaInfoCircle, FaCar } from "react-icons/fa";
import BookingTable from './BookingTable';

const ShuttleList = ({booking}) => {
  const [showModalShuttle, setshowModalShuttle] = useState(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [shuttle, setShuttle] = useState(null);
  const {shuttles} = booking;

  const bookingId = booking.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditShuttleBooking = (shuttle) => {
    setShuttle(shuttle)
    openModal()
  }

  const handleDeleteShuttleBooking = (shuttle) => {
    setShuttle(shuttle)
    setShowConfirmationDelete(true)
  }

  const handleRemoveAttachment = (shuttleId, documentId) => {
    dispatch(removeShuttleDocument({bookingId, shuttleId, documentId}))
  }

  const handleCreateShuttleBooking = () => {
    setShuttle(null)
    openModal()
  }

  const handleMakeCarBooking = () => {
    navigate("/booking/car/new-car-booking", { state: {travel: booking} })
  }

  const openModal = () => {
    setshowModalShuttle(true)
  }

  const closeConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmation()
  }

  const handleOkConfirm = async () => {
    const shuttleId = shuttle.id
    dispatch(deleteBookingShuttle({bookingId, shuttleId}))
    closeConfirmation()
  }

  const shuttleIcon = (name) => {
    let icon = "car"
    switch(name) {
      case "Car of company", "Car rental":
        icon = "car"
        break
      case "Plane":
        icon = "plane"
        break
      case "Train":
        icon = "train"
        break
      case "Taxi":
        icon = "taxi"
        break
      case "Motocycle":
        icon = "motocycle"
        break
    }

    return icon
  } 

  const shuttlesWithoutOthers = shuttles && shuttles.filter((shuttle) => shuttle.other === false)

  return (
    <>
      <Flex justifyContent="end" className="mx-4">
        <Button
          variant="falcon-primary"
          className="me-2"
          onClick={handleCreateShuttleBooking}
        >
          <FontAwesomeIcon icon="plus" /> New Shuttle Booking
        </Button>
        <Button
          variant="falcon-warning"
          onClick={handleMakeCarBooking}
        >
          <FontAwesomeIcon icon="car" /> Make Car Booking
        </Button>
      </Flex>

      <Tabs
        id="travel-car-booking"
        className="mb-3"
      >
        <Tab eventKey="overview" title={<><FaInfoCircle /> Overview</>}>
          {shuttlesWithoutOthers.map((shuttle) => (
            <ShuttleItem
              key={shuttle.id}
              booking={booking}
              shuttle={shuttle}
              icon={shuttleIcon(shuttle.name)}
              editShuttleBooking={() => handleEditShuttleBooking(shuttle)}
              deleteShuttleBooking={() => handleDeleteShuttleBooking(shuttle)}
              handleRemoveAttachment={handleRemoveAttachment}
            />
          ))}
        </Tab>
        <Tab eventKey="car-booking" title={<><FaCar /> Car booking</>}>
          <BookingTable data={booking?.car_bookings} />
        </Tab>
      </Tabs>
      
      <ShuttleForm 
        showModal={showModalShuttle}
        setshowModal={setshowModalShuttle}
        booking={booking}
        shuttle={shuttle}
      />

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to delete?"
      />
    </>
  )
}

ShuttleList.propTypes = {
  booking: PropTypes.object.isRequired
}

export default ShuttleList;