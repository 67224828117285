import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import {
  initialStateStatus,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload,
  createInitialState
} from "helpers/reducerHelpers";

const projectUrl = "/crm/projects"
const waiverUrl = `${projectUrl}/waivers`

const generalWaiverUrl = (projectId, waiverId) => (
  waiverId ? `${projectUrl}/${projectId}/waivers/${waiverId}` : `${projectUrl}/${projectId}/waivers`
)

export const getWaivers = createAsyncThunk(
  "Waiver/getWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/waivers`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getWaiver = createAsyncThunk(
  "Waiver/getWaiver",
  async ({projectId, waiverId}, { rejectWithValue }) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.get(url)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const createWaiver = createAsyncThunk(
  "Waiver/createWaiver",
  async ({projectId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/waivers/`, data)
      return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateWaiver = createAsyncThunk(
  "Waiver/updateWaiver",
  async ({ projectId, waiverId, data }, { rejectWithValue }) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteWaiver = createAsyncThunk(
  "Waiver/deleteWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const sendToApproveWaiver = createAsyncThunk(
  "Waiver/sendToApproveWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/send_to_approve`)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const approveWaiver = createAsyncThunk(
  "Waiver/approveWaiver",
  async ({projectId, waiverId, data}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/approve`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const verifyWaiver = createAsyncThunk(
  "Waiver/verifyWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/verify`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const resendToCSDWaiver = createAsyncThunk(
  "Waiver/resendToCSDWaiver",
  async ({projectId, waiverId, data}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/resend_to_csd`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const closeWaiver = createAsyncThunk(
  "Waiver/closeWaiver",
  async ({projectId, waiverId, data}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/close`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const cancelWaiver = createAsyncThunk(
  "Waiver/cancelWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/cancel`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const rollbackWaiver = createAsyncThunk(
  "Waiver/rollbackWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/rollback`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const resetWaiver = createAsyncThunk(
  "Waiver/resetWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/reset`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const remindToSalesWaiver = createAsyncThunk(
  "Waiver/remindToSalesWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/remind_to_sales`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadDocument = createAsyncThunk(
  "Waiver/uploadDocument",
  async ({ projectId, waiverId, data }, { rejectWithValue }) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/upload_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeDocument = createAsyncThunk(
  "Waiver/removeDocument",
  async ( { projectId, waiverId, documentId }, { rejectWithValue }) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/remove_document`, { document_id: documentId });
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const changeCommitment = createAsyncThunk(
  "Waiver/changeCommitment",
  async ({projectId, waiverId, data}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/update_commitment`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getColumns = createAsyncThunk(
  "Waiver/getColumns",
  async () => {
    try {
      const response = await apiAxios.get(`${projectUrl}/waivers/attributes`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportData = createAsyncThunk(
  "Waiver/exportData",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)
    
    const response = await apiAxios.get(`${projectUrl}/waivers/export`, {params, responseType: 'blob'})

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `waiver-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const getActivityLog = createAsyncThunk(
  "Waiver/getActivityLog",
  async ({ projectId, waiverId, params } ) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.get(`${url}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getVefifiedClosedMailerWaiver = createAsyncThunk(
  "Waiver/getVefifiedClosedMailerWaiver",
  async ({projectId, waiverId, data}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.get(`${url}/verified_closed_mailer`, {params: data} )
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getSubmitedWaivers = createAsyncThunk(
  "Waiver/getSubmitedWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/submited_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getWaitingApprovalWaivers = createAsyncThunk(
  "Waiver/getWaitingApprovalWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/waiting_approval_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getPendingClosedWaivers = createAsyncThunk(
  "Waiver/getPendingClosedWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/pending_closed_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getApprovedWaivers = createAsyncThunk(
  "Waiver/getApprovedWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/approved_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getRejectedWaivers = createAsyncThunk(
  "Waiver/getRejectedWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/rejected_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCanceledWaivers = createAsyncThunk(
  "Waiver/getCanceledWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/canceled_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getClosedWaivers = createAsyncThunk(
  "Waiver/getClosedWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(`${waiverUrl}/closed_list`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const cloneWaiver = createAsyncThunk(
  "Waiver/cloneWaiver",
  async ({projectId, waiverId}) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(`${url}/clone`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const updateCommitmentWaiver = createAsyncThunk(
  "Waiver/updateCommitmentWaiver",
  async ({ projectId, waiverId, data }, { rejectWithValue }) => {
    try {
      const url = generalWaiverUrl(projectId, waiverId)
      const response = await apiAxios.post(url, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)


const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const waiverSlice = createSlice({
  name: "crm_waiver",
  initialState: {
    ...initialStateStatus,
    pendingClosedData: createInitialState(),
    closedData: createInitialState(),
    mailer: {}
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getSubmitedWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "submitedData"))
      .addCase(getWaitingApprovalWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "waitingApprovalData"))
      .addCase(getPendingClosedWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "pendingClosedData"))
      .addCase(getApprovedWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "approvedData"))
      .addCase(getRejectedWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "rejectedData"))
      .addCase(getCanceledWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "canceledData"))
      .addCase(getClosedWaivers.fulfilled, (state, action) => handleFulfilled(state, action, "closedData"))
      
      .addCase(getWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createWaiver.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteWaiver.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(updateWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(sendToApproveWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(approveWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(verifyWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(resendToCSDWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(closeWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(cancelWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(rollbackWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(resetWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(remindToSalesWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(changeCommitment.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(exportData.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportData.fulfilled, (state) => {
        state.exporting = false
        toast.success("Data has been exported successfully.")
      })
      .addCase(getColumns.fulfilled, (state, action) => {
        state.sortColumns = action.payload.sort_columns
      })
      .addCase(getVefifiedClosedMailerWaiver.fulfilled, (state, action) => {
        state.mailer = action.payload
      })
      .addCase(updateCommitmentWaiver.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = waiverSlice.actions

export default waiverSlice.reducer