import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetNestedParams, setFilterTag, setNestedParams } from "redux/crm/salesUnitSlice";
import IconAlert from "components/common/IconAlert";
import { MdSearch, MdOutlineRestartAlt, MdOutlineClose } from "react-icons/md";
import React from 'react';
import PropTypes from "prop-types";

const FilterAdvanceModal = ({showModal, setshowModal, data, entryKey}) => {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();

  const {pageSize} = data || {};
  const key = entryKey;

  const onSubmit = (data) => {
    const newData = {
      id_cont: data.id,
      name_cont: data.name,
      description_cont: data.description,
      parent_id_cont: data.parent,
      page: 1,
      per_page: pageSize
    }

    //store for filter
    const dataFilter = [
      { id: "id_cont", name: "ID#", value: data.id },
      { id: "name_cont", name: "Name", value: data.name },
      { id: "description_cont", name: "Description", value: data.description },
      { id: "parent_id_cont", name: "Parent", value: data.parent }
    ]

    dispatch(setFilterTag({key, tags: dataFilter.filter((data) => data.value !== undefined && data.value !== '')}))

    let obj = {}
    Object.entries(newData).forEach((item) => {
      const value = item[1]
      const key = item[0]

      if (value !== null && value !== "" && value !== undefined) {
        obj = {...obj, [key]: value}
      }
    })

    try {
      dispatch(setNestedParams({key, params: obj}));
      closeModal()
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleReset = () => {
    resetForm()
  }

  const resetForm = () => {
    reset();
    dispatch(resetNestedParams({key}));
  }


  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Apply filters to advances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="info">
            <p className="mb-0">Establish the sales unit's filtering criteria</p>
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="id">
            <Form.Label column sm={3} className="text-lg-end">
              ID#
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="id"
                placeholder="Sales unit ID#"
                className="fs--1"
                {...register("id")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Sales unit name"
                className="fs--1"
                {...register("name")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="description">
            <Form.Label column sm={3} className="text-lg-end">
              Description
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="description"
                placeholder="Description"
                className="fs--1"
                {...register("description")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="parent">
            <Form.Label column sm={3} className="text-lg-end">
              Parent
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="parent"
                placeholder="Parent"
                className="fs--1"
                {...register("parent")}
              />
            </Col>
          </Form.Group>
          
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit"><MdSearch /> Search</Button>
          <Button size="sm" variant="falcon-default" onClick={handleReset}><MdOutlineRestartAlt /> Reset</Button>
          <Button size="sm" variant="falcon-default" onClick={closeModal}><MdOutlineClose /> Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

FilterAdvanceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  entryKey: PropTypes.string.isRequired
};

export default FilterAdvanceModal;