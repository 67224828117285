import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

const customerUrl = "/crm/companies"

export const createAddress = createAsyncThunk(
  "Address/createAddress",
  async ({customerId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${customerUrl}/${customerId}/addresses`, data)
      return objectSerializer(response.data)
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateAddress = createAsyncThunk(
  "Address/updateAddress",
  async ({ customerId, addressId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${customerUrl}/${customerId}/addresses/${addressId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return objectSerializer(response.data)
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteAddress = createAsyncThunk(
  "Address/deleteAddress",
  async ({customerId, addressId}, { rejectWithValue }) => {
    try {
      await apiAxios.delete(`${customerUrl}/${customerId}/addresses/${addressId}`);
      return addressId
    } catch (error) {
      console.log(error.response.data)
      return rejectWithValue(error.response.data);
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const addressSlice = createSlice({
  name: "address",
  initialState: {
    data: []
  },
  reducers: {
    setAddresses: (state, action) => {
      state.data = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAddress.fulfilled, (state, action) => {
        const address = action.payload[0]
        state.data.unshift(address)
        toast.success(`Address ${address.name} have been created successfully.`)
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        const address = action.payload[0]
        const updatedIndex = state.data.findIndex((add) => add.id === address.id)
        if (updatedIndex !== -1) {
          state.data[updatedIndex] = address
          toast.success(`Address ${address.name} have been updated successfully.`)
        }
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        const addressId = action.payload
        if (addressId !== undefined) {
          state.data = state.data.filter((add) => add.id !== addressId)
          toast.success(`Address ${addressId} have been deleted successfully.`)
        }
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
    }

})

export const {setAddresses} = addressSlice.actions

export default addressSlice.reducer