import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from 'helpers/reducerHelpers';

const approvalFlowUrl = "/booking/travel/setting/approval_flows"

export const getAllApprovalFlows = createAsyncThunk(
  "ApprovalFlow/getAllApprovalFlows",
  async (params) => {
    try {
      const response = await apiAxios.get(`${approvalFlowUrl}`, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getApprovalFlow = createAsyncThunk(
  "ApprovalFlow/getApprovalFlow",
  async (approvalId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${approvalFlowUrl}/${approvalId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createApprovalFlow = createAsyncThunk(
  "ApprovalFlow/createApprovalFlow",
  async (data) => {
    try {
      const response = await apiAxios.post(approvalFlowUrl, data)
      return response.data
    }
    catch(error){
      throw new Error(error.message)
    }
  }
)

export const updateApprovalFlow = createAsyncThunk(
  "ApprovalFlow/updateApprovalFlow",
  async ({ approvalId, data }) => {
    try {
      const response = await apiAxios.put(`${approvalFlowUrl}/${approvalId}`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const deleteApprovalFlow = createAsyncThunk(
  "ApprovalFlow/deleteApprovalFlow",
  async (approvalId) => {
    try {
      const response = await apiAxios.delete(`${approvalFlowUrl}/${approvalId}`);
      return response.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
);

export const exportApprovalFlows = createAsyncThunk(
  "ApprovalFlow/exportApprovalFlows",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${approvalFlowUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'approval-flows.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const approvalFlowSlice = createSlice({
  name: "booking_setting_approval_flow",
  initialState: {
    ...initialState
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllApprovalFlows.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))

      .addCase(createApprovalFlow.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateApprovalFlow.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deleteApprovalFlow.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(exportApprovalFlows.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportApprovalFlows.fulfilled, (state) => {
        state.exporting = false
        toast.success("ApprovalFlow has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
});

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = approvalFlowSlice.actions;

export default approvalFlowSlice.reducer
