import React, { useEffect, useMemo, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Select from "react-select"
import { BY_NUMBER_WEIGHT_REVENUE_FILTER, DEFAULT_COUNTRY_NAME } from 'app_config';
import DetailDataModal from '../DetailDataModal';
import ContractDetail from './ContractDetail';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const formatComsume = (value, numberType) => {
  let result = null;

  switch(numberType) {
    case "number":
      result = value?.toLocaleString();
      break;
    case "weight":
      result = `${value?.toLocaleString()} M.T`;
      break;
    case "revenue":
      result = `$${value?.toLocaleString()}`;
      break;
  }

  return result
}

formatComsume.propTypes = {
  value: PropTypes.number.isRequired,
  numberType: PropTypes.string.isRequired
}

const formatNumber = (params, numberType) => {
  const value = params?.value[1] || 0;
  return formatComsume(value, numberType);
}

formatNumber.propTypes = {
  params: PropTypes.object.isRequired,
  numberType: PropTypes.string.isRequired
}

const getOption = (data, numberType) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      magicType: { show: true, type: ['line', 'bar'] },
      saveAsImage: { show: true }
    }
  },
  color: [
    getColor("warning")
  ],
  xAxis: {
    type: 'category',
    axisPointer: {
      type: 'shadow'
    },
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'line'
      }
    }
  },
  yAxis: 
  {
    type: 'value'
  },
  series: [
    {
      type: 'bar',
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: "top",
        color: getColor('gray-600'),
        formatter: (params) => formatNumber(params, numberType)
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: data
    }
  ],
});

getOption.propTypes = {
  data: PropTypes.array.isRequired,
  numberType: PropTypes.string.isRequired
}

const ContractProvince = ({originalData}) => {
  const {data, pj_countries} = originalData;

  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_REVENUE_FILTER[0])
  const [dataObject, setDataObject] = useState([])
  const [numberType, setNumberType] = useState("weight")

  const [countryName, setCountryName] = useState(DEFAULT_COUNTRY_NAME)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [provinceName, setProvinceName] = useState(null)
  const [provinceDetailData, setProvinceDetailData] = useState([])

  useEffect(() => {
    if (!data) return;

    const filteredData = data && data.filter(item => item.pj_country === countryName)
    
    const consumeProvince = filteredData.reduce((acc, item) => {
      const {pj_province, contract_weight, contract_value} = item

      switch (numberWeightFilter) {
        case BY_NUMBER_WEIGHT_REVENUE_FILTER[0]:
          acc[pj_province] = (acc[pj_province] || 0) + parseFloat(contract_weight);
          setNumberType("weight");
          break;

        case BY_NUMBER_WEIGHT_REVENUE_FILTER[1]:
          acc[pj_province] =  (acc[pj_province] || 0) + 1;;
          setNumberType("number");
          break;

        case BY_NUMBER_WEIGHT_REVENUE_FILTER[2]:
          acc[pj_province] = (acc[pj_province] || 0) + parseFloat(contract_value);
          setNumberType("revenue");
          break;

      }

      return acc;
    }, {});

    const consumeProvinceArray = Object.entries(consumeProvince).sort(([,a],[,b]) => b - a);
    setDataObject(consumeProvinceArray);

  }, [countryName, numberWeightFilter, originalData]);

  const title = useMemo(() => {
    let name;

    switch (numberWeightFilter) {
      case BY_NUMBER_WEIGHT_REVENUE_FILTER[0]:
        name = "Province Signed Contracts by Weight (M.T)";
        break;
      case BY_NUMBER_WEIGHT_REVENUE_FILTER[1]:
        name = "Province Signed Contracts counting of projects";
        break;
      default:
        name = "Province Signed Contracts by Revenue (USD)";
    }
    return name;
  }, [numberWeightFilter]);

  const countryDefaultOption = { value: DEFAULT_COUNTRY_NAME, label: DEFAULT_COUNTRY_NAME }
  const countryOptions = useMemo(() => pj_countries && pj_countries.map((item) => ({ value: item, label: item })), [pj_countries]) 
  
  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  const handleChangeCountry = (country) => {
    setCountryName(country)
  };

  const handleChartClick = (params) => {
    const name = params.name

    setShowDetailModal(true)
    setProvinceName(name)

    const filteredData = data && data.filter(item => item.pj_province === name)
    setProvinceDetailData(filteredData)
  }

  const transposedArray = dataObject.reduce((acc, [province, value]) => {
    acc[0].push(province);
    acc[1].push(value);
    return acc;
  }, [[], []]);

  return (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader
          title={title}
          titleTag="h5"
          className="py-2"
          light
          endEl={
            <Flex justifyContent="end" className="fs--1">
              <Select
                closeMenuOnSelect={true}
                options={countryOptions}
                defaultValue={countryDefaultOption}
                placeholder='Select...'
                classNamePrefix="react-select"
                onChange={selectedOption => handleChangeCountry(selectedOption.value)}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '100%',
                  }),
                }}
              />
              
              <Form.Select
                size="sm"
                onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
                className="mx-1 fs--1"
              >
                {BY_NUMBER_WEIGHT_REVENUE_FILTER.map(item => (
                  <option key={item}>{item}</option>
                ))}
              </Form.Select>
            </Flex>
          }
        />
        <Card.Body className="p-3 d-flex aligm-items-center">
          <ReactEChartsCore
            echarts={echarts}
            option={getOption(dataObject, numberType)}
            className="w-100"
            onEvents={{click: handleChartClick}}
          />
        </Card.Body>
        <Card.Footer>
          <Flex className="fs--1" justifyContent="end">
            <h6 className="mb-2 me-3">Details:</h6>
            <div className="table-responsive">
              <table className="border">
                <thead className="bg-light">
                  <tr className="border">
                    {transposedArray[0].map((province, index) => (
                      <th key={index} className="px-2 border">{province}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border">
                    {transposedArray[1].map((value, index) => (
                      <td className="px-2 border text-end text-primary" key={index}>{formatComsume(value, numberType)}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </Flex>
        </Card.Footer>
      </Card>

      {showDetailModal && (
        <DetailDataModal
          showModal={showDetailModal}
          setshowModal={setShowDetailModal}
          title={provinceName}
          dataComponent={<ContractDetail data={provinceDetailData} />}
        />
      )}
    </>
  )
}

ContractProvince.propTypes = {
  originalData: PropTypes.shape({
    data: PropTypes.array,
    pj_countries: PropTypes.array
  }).isRequired
};

export default ContractProvince;