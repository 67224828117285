import React, { useContext } from "react";
import { Outlet } from 'react-router-dom';
import NotAuthorized from "../components/errors/NotAuthorized";
import { PermissionContext } from 'context/Context';

const ProtectedRoute = ({permissionNo}) => {
  const { permissions } = useContext(PermissionContext);

  const hasPermission = (permissionNo) => {
    return permissions[permissionNo] === true;
  };

  return hasPermission(permissionNo) ? <Outlet /> : <NotAuthorized />;
};

export default ProtectedRoute;
