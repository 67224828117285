import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { deletePIF, setExpandAll, setCollapseTabStatus, resetExpandAll, downloadPIF } from "redux/crm/project/pifSlice";
import { Link } from 'react-router-dom';
import { FaBackward } from 'react-icons/fa';
import PropTypes from 'prop-types';

const PIFHeader = ({pif, source, title}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");

  const {collapseTabStatus} = useSelector(state => state.crm_project_pif);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {project, building, adaptation} = pif || {};
  
  const projectId = project?.id;
  const buildingId = building?.id;
  const adaptationId = adaptation?.id;
  const pifId = pif?.id;
  const projectUrl = `/crm/projects/${projectId}`;
  const changeOrderUrl = `${projectUrl}/buildings/${buildingId}/change-orders/${adaptationId}`;
  const pifUrl = pif ? `${projectUrl}/buildings/${buildingId}/adaptations/${adaptationId}/pifs/${pifId}` : null;

  const {
    allow_edit,
    allow_delete,
    allow_export_draft_proposal_contract
  } = pif?.permissions || {};

  console.log(project)
  console.log(pif)

  const handlePreviewPIF = () => {
    navigate(`${pifUrl}/print`, { state: { pif } })
  }

  const handleEditPIF = () => {
    navigate(`${pifUrl}/edit`, { state: { pif } })
  }

  const handleProject = () => {
    navigate(projectUrl)
  }

  const confirmMsg = (message) => {
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deletePIF({projectId, buildingId, adaptationId, pifId}))
      
      source === "change-order" ? navigate(changeOrderUrl) : navigate(projectUrl)

      closeConfirmModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleDeletePIF = () => {
    const message = `Are you sure to delete PIF ID#<strong class='text-danger'>${pifId}</strong>?`
    confirmMsg(<span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  const handleExpandAll = () => {
    if (collapseTabStatus) {
      dispatch(setExpandAll())
    }
    else {
      dispatch(resetExpandAll())
    }

    dispatch(setCollapseTabStatus(!collapseTabStatus))
  }

  const handleDownloadPIF = async () => {
    try {
      setLoading(true)
      await dispatch(downloadPIF({
        projectId,
        buildingId,
        adaptationId,
        pifId
      })).unwrap();
    }
    catch(error) {
      toast.error(error.message);
    }
    finally {
      setLoading(false)
    }
  }

  const handleDownloadProjectProposalPIF = () => {
    dispatch(downloadDraftProposal({
      projectId,
      buildingId,
      adaptationId,
      pifId: pifId
    }))
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={collapseTabStatus ? faAngleDoubleDown : faAngleDoubleUp}
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              title="Collapse All"
              onClick={handleExpandAll}
            />

            {/* {allow_export_draft_proposal_contract && (
              <IconButton
                variant="info"
                size="sm"
                icon="download"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleDownloadProjectProposalPIF}
              >
                <span className="d-none d-xl-inline-block ms-1">Draft proposal contract</span>
              </IconButton>
            )} */}
          
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="eye"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              title="Preview PIF"
              onClick={handlePreviewPIF}
            />

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="download"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              title="Download PIF"
              onClick={handleDownloadPIF}
            />

            {allow_edit && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="edit"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleEditPIF}
              >
                <span className="d-none d-xl-inline-block ms-1">Edit</span>
              </IconButton>
            )}
            
            <IconButton
              variant="falcon-default"
              size="sm"
              //icon="arrow-left"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleProject}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Back to Project
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="primary" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_delete && (
                    <Dropdown.Item className="text-danger" onClick={handleDeletePIF}>
                      <FontAwesomeIcon icon="trash-alt" className="text-danger" /> Delete PIF
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>

        {loading && (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Spinner animation="border" role="status" variant="primary" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner> Downloading...
          </div>
        )}

        <Card.Body className="fs--1">
          <h5 className="text-center mb-3 fw-bold text-primary">{title} PROJECT INFORMATION FORM (PIF)</h5>
          <div className="text-center mb-2">ID#: <span className="fw-bold text-primary">{pif?.pif_number}</span></div>
          
          <Row className="gx-3">
            <Col className="text-end">Project :</Col>
            <Col className="text-primary fw-bold">{project?.name}</Col>
          </Row>

          <Row className="gx-3">
            <Col className="text-end">Building :</Col>
            <Col className="text-primary fw-bold">{building?.name}</Col>
          </Row>

          <Row className="gx-3">
            <Col className="text-end">Quote no :</Col>
            <Col className="text-primary fw-bold">
              {project?.q_number}
              <span> - {building?.j_number}</span>
            </Col>
          </Row>
          
          {adaptation?.type == "change_order" ? (
            <Row className="gx-3">
            <Col className="text-end">CO# :</Col>
            <Col>
              <span className="text-primary fw-bold me-3">{adaptation?.co}</span>
              <span>
                <FaBackward className="me-1" />
                <Link to={`/crm/projects/${project?.id}/buildings/${building?.id}/change-orders/${adaptation?.id}`}>Back to Change order</Link>
              </span>
              
            </Col>
          </Row>
          ) : (
            <Row className="gx-3">
              <Col className="text-end">REV# :</Col>
              <Col className="text-primary fw-bold">{adaptation?.rev}</Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message={messageConfirm}
      />
    </>
  );
};

PIFHeader.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  pif: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default PIFHeader;