import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changePasswordManageUser } from "redux/authorization/userSlice";
import IconAlert from "components/common/IconAlert";
import React from 'react';

const UserDetailChangePassword = ({showModal, setshowModal, user}) => {
  const { register, handleSubmit, formState: {errors}, getValues } = useForm()
  const dispatch = useDispatch()
  
  const onSubmit = (data) => {
    console.log(data)

    try {
      if (user) {
        const userId = user.id
        dispatch(changePasswordManageUser({userId, data}))

        closeModal()
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const validatePasswordConfirmation = (value) => {
    const { password } = getValues()
    return password === value || 'Password confirmation does not match.'
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Change password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="warning">
              Change password for user <strong className="text-primary">{user.name}</strong>
            </IconAlert>

            <Form.Group as={Row} className="mb-3" controlId="password">
              <Form.Label column sm={3} className="text-lg-end">
                Password
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="new-password"
                  className="fs--1"
                  {...register("password", {
                    required: "Password must be required",
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters"
                    }
                  })}
                />
                <span className="text-danger">
                  {errors.password && errors.password.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="password_confirmation">
              <Form.Label column sm={3} className="text-lg-end">
                Password confirmation
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="password"
                  name="password_confirmation"
                  placeholder="Password confirmation"
                  autoComplete="new-password"
                  className="fs--1"
                  {...register("password_confirmation", {
                    required: "Password confirmation must be required",
                    validate: validatePasswordConfirmation
                  })}
                />
                <span className="text-danger">
                  {errors.password_confirmation && errors.password_confirmation.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit">Update</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>

      </Modal>
    </>
  )
}

UserDetailChangePassword.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
}

export default UserDetailChangePassword;