import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { createExpense, updateExpense } from "redux/booking/travel/setting/expenseSlice";
import { FaBan, FaSave } from "react-icons/fa";
import { EMPLOYEE_STAFF_POLICY, TRAVEL_SETTING_EXPENSE_TYPE, TRAVEL_TYPE_OF_TRAVEL, TRAVEL_SETTING_EXPENSE_UNIT } from "app_config";
import { getPositionGroups } from "redux/organization/employeeSlice";
import Flex from "components/common/Flex";
import Select from 'react-select';
import { getCountries } from "redux/settingsSlice";

const ExpenseForm = ({showModal, setshowModal, title, record}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, reset } = useForm();
  const {positionGroups} = useSelector((state) => state.organization_employee);
  const {countryData} = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (positionGroups?.length === 0) {
      dispatch(getPositionGroups());
    }

    if (countryData?.length === 0) {
      dispatch(getCountries());
    }
  }, []);

  useEffect(() => {
    if (title === "New Expense") {
      reset()
    }
  }, [title]);

  useEffect(() => {
    
    setValue("area", TRAVEL_TYPE_OF_TRAVEL[0]);
    setValue("expense_type", TRAVEL_SETTING_EXPENSE_TYPE[0]);
    setValue("staff_policy", EMPLOYEE_STAFF_POLICY[0].toLocaleLowerCase());
    setValue("unit", TRAVEL_SETTING_EXPENSE_UNIT[0]);
    
    if(record) {
      Object.entries(record).forEach(([key, value]) => {
        if (key === 'countries') {
          const selectedOptionsValues = value && value.map((emp) => emp);
          setValue('countries', selectedOptionsValues)
        }
        else {
          setValue(key, value)
        }
      })
    }
  }, [setValue, record]);

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    const newData = {...data, staff_policy: data?.staff_policy.toLocaleLowerCase()};

    try {
      if (record) {
        const expenseId = record.id
        dispatch(updateExpense({expenseId, data: newData }))
      }
      else {
        dispatch(createExpense(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleChangeCountries = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('countries', selectedOptionsValues, { shouldValidate: true })
  }

  const unitOptions = useMemo(() => TRAVEL_SETTING_EXPENSE_UNIT.map((option) => ({ value: option, label: option })));
  const positionGroupOptions = positionGroups && positionGroups.map((item) => ({ value: item.id, label: item.name }));

  const countryOptions = useMemo(() => countryData && countryData.map((option) => ({ value: option.country, label: option.name })), []) ;
  const defaultCountryOption = useMemo(() => {
    if (record && record.countries && countryData) {
      return record.countries.map((countryCode) => {
        const country = countryData.find((data) => data.country === countryCode);
        return country ? { value: country.country_code, label: country.name } : null;
      }).filter(option => option !== null); // Filter out any null values
    }
    return null;
  }, [record, countryData]);

  const defaultStaffPolicyOption = record?.staff_policy?.toLocaleLowerCase() || EMPLOYEE_STAFF_POLICY[0].toLocaleLowerCase();

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-2" controlId="name">
              <Form.Label column sm={3} className="text-lg-end">
                Application scope
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  name="name"
                  placeholder="Application scope"
                  className="fs--1"
                  {...register("name", {
                    required: "Application scope must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.name && errors.name.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="my-3" controlId="area">
              <Form.Label column sm={3} className="text-lg-end">
                Area
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller 
                  control={control}
                  name="area"
                  rules={{required: "Area must be required."}}
                  defaultValue={record && record.area ? record.area : TRAVEL_TYPE_OF_TRAVEL[0]}
                  render={({field}) => (
                    <Flex direction={'row'}>
                      {TRAVEL_TYPE_OF_TRAVEL.map((option, index) => (
                        <Form.Check
                          key={option}
                          id={`${option}${index}`}
                          type="radio"
                          label={option}
                          value={option}
                          className="me-3"
                          {...field}
                          defaultChecked={field.value === option}
                          onChange={() => field.onChange(option)}
                        />
                      ))}
                    </Flex>
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="expense_type">
              <Form.Label column sm={3} className="text-lg-end">
                Expense type
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller 
                  control={control}
                  name="expense_type"
                  rules={{ required: "Expense type must be required."}}
                  defaultValue={record && record.expense_type ? record.expense_type : TRAVEL_SETTING_EXPENSE_TYPE[0]}
                  render={({field}) => (
                    <Flex>
                      {TRAVEL_SETTING_EXPENSE_TYPE.map((option, index) => (
                        <Form.Check
                          key={option}
                          id={`${option}${index}`}
                          type="radio"
                          label={option}
                          value={option}
                          className="me-3"
                          {...field}
                          defaultChecked={field.value === option}
                          onChange={() => field.onChange(option)}
                        />
                      ))}
                    </Flex>
                  )}
                />
                <span className="text-danger">
                  {errors.expense_type && errors.expense_type.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="amount">
              <Form.Label column sm={3} className="text-lg-end">
                Amount
              </Form.Label>
              <Col sm={9} md={7}>
                <Row className="gx-1">
                  <Col>
                    <Form.Control
                      name="amount"
                      placeholder="Amount"
                      className="fs--1"
                      {...register("amount", {
                        required: "Amount must be required."
                      })}
                    />
                    <span className="text-danger">
                      {errors.amount && errors.amount.message}
                    </span>
                  </Col>
                  <Col md="auto">
                    <Controller
                      control={control}
                      name='unit'
                      rules={{required: "Unit must be required."}}
                      render={({field}) => (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          options={unitOptions}
                          value={unitOptions.find(option => option.value === field.value) || null}
                          placeholder='Select...'
                          classNamePrefix='react-select'
                          onChange={selectedOption => setValue('unit', selectedOption.value)}
                        />
                      )}
                    />
                  </Col>
                </Row>
                
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-2' controlId='position_group_id'>
              <Form.Label column sm={3} className='text-lg-end'>
                Position group
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name='position_group_id'
                  render={({field}) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      options={positionGroupOptions}
                      value={positionGroupOptions.find(option => option.value === field.value) || null}
                      placeholder='Select...'
                      classNamePrefix='react-select'
                      onChange={selectedOption => setValue('position_group_id', selectedOption.value)}
                    />
                  )}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="my-3" controlId="staff_policy">
              <Form.Label column sm={3} className="text-lg-end">
              Staff policy
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller 
                  control={control}
                  name="staff_policy"
                  rules={{ required: "Staff policy must be required."}}
                  defaultValue={defaultStaffPolicyOption}
                  render={({field}) => (
                    <Flex>
                      {EMPLOYEE_STAFF_POLICY.map((option, index) => (
                        <Form.Check
                          key={option}
                          id={`${option}${index}`}
                          type="radio"
                          label={option}
                          value={option?.toLocaleLowerCase()}
                          className="me-3"
                          {...field}
                          defaultChecked={field.value === option.toLocaleLowerCase()}
                          onChange={() => field.onChange(option.toLocaleLowerCase())}
                        />
                      ))}
                    </Flex>
                  )}
                />
                <span className="text-danger">
                  {errors.staff_policy && errors.staff_policy.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="countries">
              <Form.Label column sm={3} className="text-lg-end">
                Countries scope
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="countries"
                  render={() => (
                    <Select
                      closeMenuOnSelect={false}
                      options={countryOptions}
                      defaultValue={defaultCountryOption}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      isMulti
                      onChange={handleChangeCountries}
                    />
                  )}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: 'Countries must be required'
                  //   }
                  // }}
                />
                <span className="text-danger">
                  {errors.countries && errors.countries.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="remark">
              <Form.Label column sm={3} className="text-lg-end">
                Remark
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="remark"
                  placeholder="Remark"
                  className="fs--1"
                  {...register("remark")}
                />
              </Col>
            </Form.Group>
        
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><FaSave /> Save</Button>
            <Button size="sm" variant="danger" onClick={closeModal}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
}

ExpenseForm.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  title: PropTypes.string,
  record: PropTypes.object
};

export default ExpenseForm;