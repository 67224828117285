import { useDispatch } from "react-redux"
import { Form, Modal, Button, Row, Col, Tab, Tabs} from "react-bootstrap"
import { toast } from "react-toastify"
import { FaUserPlus } from "react-icons/fa"
import IconAlert from "components/common/IconAlert"
import { useForm, Controller } from "react-hook-form"
import React, { useState } from "react"
import { shareAsset } from "redux/drive/driveSlice"
import AsyncSelect from 'react-select/async';
import { activeEmployeesByNameDebounce, departmentsDebounce } from "helpers/employeeOptionHelper"

const DriveShareForm = ({showModal, setshowModal, asset }) => {
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm()
  const [tabActive, setTabActive] = useState("employees");

  const dispatch = useDispatch()

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = (data) => {
    //console.log(data)

    switch(tabActive) {
      case "employees":
        data = {
          share_type: tabActive,
          employee_names: data.employee_names,
          set_owner: data.set_owner,
          notify_people: data.emp_notify_people,
          optional_message: data.emp_optional_message
        }

        console.log(data);

        break;
      case "departments":
        data = {
          share_type: tabActive,
          department_ids: data.department_ids,
          notify_people: data.dep_notify_people,
          optional_message: data.dep_optional_message
        }
        console.log(data);
        break;
    }

    try {
      if (asset) {
        const assetId = asset.id;
        dispatch(shareAsset({assetId, data}))
      }
      
      closeModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const employeeOptions = (inputValue, callback) => {
    activeEmployeesByNameDebounce(inputValue, callback)
  }

  const handleChangeEmployee = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('employee_names', selectedOptionsValues, { shouldValidate: true })
  }
  
  const departmentOptions = (inputValue, callback) => {
    departmentsDebounce(inputValue, callback);
  };

  const handleChangeDepartment = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('department_ids', selectedOptionsValues, { shouldValidate: true })
  }

  console.log(departmentOptions);

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
      size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><FaUserPlus /> Sharing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Sharing {asset?.asset_type} <strong>{asset?.name}.</strong>
          </IconAlert>

          <Tabs
            id="shareDrive"
            activeKey={tabActive}
            onSelect={(k) => setTabActive(k)}
          >
            <Tab eventKey="employees" title="Employees" className='border-bottom border-x p-3'>
              <Form.Group as={Row} className="mb-2" controlId="employee_names">
                <Form.Label column sm={3} className="text-lg-end">
                  Name
                </Form.Label>
                <Col sm={9}>
                  <Controller
                    control={control}
                    name="employee_names"
                    rules={{required: tabActive === "employees" &&  "Name must be required"}}
                    render={() => (
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={employeeOptions}
                        closeMenuOnSelect={false}
                        placeholder='Select...'
                        classNamePrefix="react-select"
                        className="w-100"
                        isMulti
                        onChange={handleChangeEmployee}
                      />
                    )}
                  />
                  <span className="text-danger">
                    {errors.employee_names && errors.employee_names.message && tabActive === "employees"}
                  </span>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="emp_notify_people" className="mt-3">
                <Col sm={{ span: 10, offset: 3 }}>
                  <Controller
                    control={control}
                    name="emp_notify_people"
                    defaultValue={true}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        label="Notify people"
                        checked={field.value}
                        onChange={(e) => {
                          const checked = e.target.checked
                          setValue("emp_notify_people", checked)
                        }}
                      />
                    )}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="set_owner" className="mb-3">
                <Col sm={{ span: 10, offset: 3 }}>
                  <Controller
                    control={control}
                    name="set_owner"
                    defaultValue={false}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        label="Set owner"
                        checked={field.value}
                        onChange={(e) => {
                          const checked = e.target.checked
                          setValue("set_owner", checked)
                        }}
                      />
                    )}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="emp_optional_message">
                <Form.Label column sm={3} className="text-lg-end">
                  Optional message
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="emp_optional_message"
                    placeholder="Optional message"
                    className="fs--1"
                    {...register("emp_optional_message")}
                  />
                </Col>
              </Form.Group>
            </Tab>
            <Tab eventKey="departments" title="Departments" className='border-bottom border-x p-3'>
              <Form.Group as={Row} className="mb-2" controlId="department_ids">
                <Form.Label column sm={3} className="text-lg-end">
                  Name
                </Form.Label>
                <Col sm={9}>
                  <Controller
                    control={control}
                    name="department_ids"
                    rules={{required: tabActive === "departments" && "Name must be required"}}
                    render={() => (
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={departmentOptions}
                        closeMenuOnSelect={false}
                        placeholder='Select...'
                        classNamePrefix="react-select"
                        className="w-100"
                        isMulti
                        onChange={handleChangeDepartment}
                      />
                    )}
                  />
                  <span className="text-danger">
                    {errors.department_ids && errors.department_ids.message}
                  </span>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="dep_notify_people" className="mt-3">
                <Col sm={{ span: 10, offset: 3 }}>
                  <Controller
                    control={control}
                    name="dep_notify_people"
                    defaultValue={true}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        label="Notify people"
                        checked={field.value}
                        onChange={(e) => {
                          const checked = e.target.checked
                          setValue("dep_notify_people", checked)
                        }}
                      />
                    )}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="dep_optional_message">
                <Form.Label column sm={3} className="text-lg-end">
                  Optional message
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="dep_optional_message"
                    placeholder="Optional message"
                    className="fs--1"
                    {...register("dep_optional_message")}
                  />
                </Col>
              </Form.Group>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="primary"><FaUserPlus /> Share</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

export default DriveShareForm;