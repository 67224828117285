import React, { lazy, Suspense } from 'react';
import { Button, Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { FaChartBar, FaFileContract, FaFileSignature } from 'react-icons/fa';
import ContractThisMonth from './ContractThisMonth';
import { useNavigate } from 'react-router-dom';

const LazyContractList = lazy(() => import("./ContractList"));
const LazyAddendumList = lazy(() => import("../addendum/AddendumList"));

const Contracts = () => {
  const { loading } = useSelector((state) => state.crm_report_contract);
  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    navigate("/crm/reports/contract-dashboard");
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex">
          <Button size="sm" variant="falcon-default" onClick={handleGoToDashboard} className="ms-auto">
            <FaChartBar /> Go to Dashboard
          </Button>
        </Card.Header>
      </Card>

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="contract-list">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contract-list"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaFileSignature className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Contracts</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="addendum-list"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaFileContract className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Addendums</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contract-this-month"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaFileSignature className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Created this month</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>         
                  <Tab.Pane eventKey="contract-list">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyContractList /> 
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="addendum-list">
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyAddendumList /> 
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="contract-this-month">
                    <ContractThisMonth />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Contracts