import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { createBooking, updateBooking } from "redux/booking/car/carBookingSlice";
import AsyncSelect from 'react-select/async';
import IconButton from "components/common/IconButton";
import Flex from "components/common/Flex";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { WithContext as ReactTags } from 'react-tag-input';
import "../../../../assets/scss/tag-input.scss"
import {isAfter} from "date-fns"
import { getProfileUser } from "redux/profileUserSlice";
import { useSelector } from "react-redux";
import { MdCancel, MdCarCrash, MdUpdate } from "react-icons/md";
import { getActiveSites } from "redux/organization/siteSlice";
import Select from "react-select";
import IconAlert from "components/common/IconAlert";
import { employeesEmailDebounce } from "helpers/employeeOptionHelper";
import { FaInfoCircle } from "react-icons/fa";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const TransactionForm = ({title, transaction, travel}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues, reset, setError, clearErrors } = useForm()
  const [luggageCheck, setLuggageCheck] = useState(transaction && transaction.luggage !== null ? true : false)
  const [passengerTags, setPassengerTags] = useState([]);
  const [currentTag, setCurrentTag] = useState();
  const {data: profileUser} = useSelector((state) => state.profile_user);
  const {activeData: siteData = []} = useSelector(state => state.organization_site);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let emails = null;

  let defaultEmailOption = useMemo(() => {
    if (travel) {
      emails = travel.employees.map((item) => item.employee_email)
      return emails.map((option) => ({  value: option, label: option }))
    }
    else {
      if (transaction && transaction.cc_email) {
        return transaction.cc_email.map((option) => ({  value: option, label: option }))
      }
    }
    
    return null
  }, [transaction, travel])

  useEffect(() => {
    if (title === "New Car booking") {
      reset()
    }
  }, [title])

  useEffect(() => {
    //get profile user
    if (profileUser === undefined) {
      dispatch(getProfileUser())
    }

    if (siteData?.length === 0) {
      dispatch(getActiveSites());
    }
  }, []);

  useEffect(() => {
    setValue('from_date', new Date());
    setValue("site_id", profileUser.site?.id);

    if (travel) {
      setValue('from_date', new Date(travel.date_of_travel))
      setValue('to_date', new Date(travel.date_of_return))
      setValue("description", travel.description_purpose_of_travel)
      setValue("pickup_location", travel.site_name)
      setValue("site_id", travel.site_id)

      const phones = travel.employees
        .filter((item) => item.employee_phone !== '' && item.employee_phone !== null)
        .map((item) => item.employee_phone);
  
      if (phones) {
        setValue('phone_contacts', phones.join(", "))
      }
      
      if (emails) {
        setValue('cc_email', emails.join(";"))
      }
      
      //name_of_passengers
      setPassengerTags(travel.employees.map(emp => ({id: emp.employee_name, text: emp.employee_name})))
    }

    if(transaction) {
      Object.entries(transaction).forEach(([key, value]) => {
        if (key === 'from_date' || key === 'to_date') {
          const dateValue = new Date(value)
          setValue(key, dateValue);
        }
        else if (key === 'name_of_passengers') {
          setPassengerTags(value.map(item => ({id: item, text: item})))
        }
        else {
          setValue(key, value)
        }
      })
    }
  }, [setValue, transaction, travel])

  useEffect(() => {
    const passengers = passengerTags.map(tag => tag.text).join(", ")

    setValue("name_of_passengers", passengers)
    setValue("number_of_passengers", passengerTags.length)

    if (passengerTags.length > 0) {
      clearErrors("name_of_passengers")
    }
  }, [passengerTags])

  const onSubmit = async (data) => {
    console.log(data)

    if (passengerTags.length === 0) {
      setError('name_of_passengers', {
        type: 'manual',
        message: 'Name of passengers must be required',
      });
      return;
    }

    //add travel_id if create car booking from traveling
    if (travel) {
      data = {...data, travel_booking_id: travel.id}
    }
  
    try {
      let transactionId = ""

      if (transaction) {
        transactionId = transaction.id
        dispatch(updateBooking({transactionId, data }))
      }
      else {
        const response = await dispatch(createBooking(data))
        transactionId = response.payload.data.id
      }
      
      navigate(`/booking/car/${transactionId}`)
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const siteOptions = siteData && siteData.filter(item => item.car_booking === true).map(item => ({value: item.id, label: item.name}));

  const emailOptions = (inputValue, callback) => {
    employeesEmailDebounce(inputValue, callback)
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleDeleteTag = (i) => {
    setPassengerTags(passengerTags.filter((tag, index) => index !== i));
  }

  const handleAdditionTag = (tag) => {
    setPassengerTags([...passengerTags, tag]);
  }

  const handleInputTagChange = (value) => {
    setCurrentTag(value);
  };

  const handleInputTagBlur = () => {
    if (currentTag && currentTag.trim() !== '') {
      const checkExistsTag = passengerTags.map(tag => tag.text).includes(currentTag)

      if (!checkExistsTag) {
        const newTag = { id: (passengerTags.length + 1).toString(), text: currentTag.trim() };
        handleAdditionTag(newTag);
      }
      
      setCurrentTag('');
    }
  };

  const handleChangeCCemail = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('cc_email', selectedOptionsValues.join(";"), { shouldValidate: true })
  }

  const validateBookingDate = (value) => {
    const fromDate = getValues('from_date');
    const parsedFromDate = fromDate ? new Date(fromDate) : null;
    const parsedToDate = value ? new Date(value) : null;

    return parsedFromDate && parsedToDate && isAfter(parsedToDate, parsedFromDate) || 'To date must be after From date.';
  }

  return (
    <Card className="fs--1">
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <Card.Title>{title}</Card.Title>
          <IconButton
            onClick={handleBack}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
        </Card.Header>
        <Card.Body className="mt-3">
          <IconAlert variant="info" className="mb-4">
            Specify information for car booking. After you 'Request booking' the transaction will be sent directly to Admin for arrangement. <span className="text-primary font-weight">This applies only in Vietnam</span>.
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="from_date">
            <Form.Label column sm={3} className="text-lg-end">
              From date<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="from_date"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                )}
                rules={{
                  required: "From date must be required."
                }}
              />
              <span className="text-danger">
                {errors.from_date && errors.from_date.message}
              </span>    
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="to_date">
            <Form.Label column sm={3} className="text-lg-end">
              To date<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="to_date"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                )}
                rules={{
                  required: "To date must be required.",
                  validate: {
                    afterFromDate: value => validateBookingDate(value),
                  },
                }}
              />
              <span className="text-danger">
                {errors.to_date && errors.to_date.message}
              </span>    
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="pickup_location">
            <Form.Label column sm={3} className="text-lg-end">
              Address pickup location<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={3}
                name="pickup_location"
                placeholder="Address pickup location"
                className="fs--1"
                {...register("pickup_location", {
                  required: "Address pickup location must be required."
                })}
              />
              <span className="text-danger">
                {errors.pickup_location && errors.pickup_location.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="drop_location">
            <Form.Label column sm={3} className="text-lg-end">
              Address drop location<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={3}
                name="drop_location"
                placeholder="Address drop location"
                className="fs--1"
                {...register('drop_location', {
                  required: "Address drop location must be required."
                })}
              />
              <span className="text-danger">
                {errors.drop_location && errors.drop_location.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="description">
            <Form.Label column sm={3} className="text-lg-end">
              Purpose trip<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={6}
                name="description"
                placeholder="Purpose trip"
                className="fs--1"
                {...register('description', {
                  required: "Purpose trip must be required."
                })}
              />
              <span className="text-danger">
                {errors.description && errors.description.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="name_of_passengers">
            <Form.Label column sm={3} className="text-lg-end">
              Name of passengers<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <ReactTags
                tags={passengerTags}
                delimiters={delimiters}
                handleDelete={handleDeleteTag}
                handleAddition={handleAdditionTag}
                handleInputChange={handleInputTagChange}
                handleInputBlur={handleInputTagBlur}
              />
              <span className="text-danger">
                {errors.name_of_passengers && errors.name_of_passengers.message}
              </span>
              <div className="mt-2">
                <FaInfoCircle /> Press enter on the keyboard when input name or multiple of names.
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="number_of_passengers">
            <Form.Label column sm={3} className="text-lg-end">
              Number of passengers<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="number"
                name="number_of_passengers"
                min={0}
                max={20}
                placeholder="Number of passengers"
                className="fs--1"
                disabled
                {...register('number_of_passengers', {
                  required: 'Number of passengers must be required'
                })}
              />
              <span className="text-danger">
                {errors.number_of_passengers && errors.number_of_passengers.message}
              </span>
              <div className="mt-2">
                <FaInfoCircle /> Number of passengers will be automatic fill in when type input name of passengers above.
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="luggage">
            <Form.Label column sm={3} className="text-lg-end">
              Luggage
            </Form.Label>
            <Col sm={9} md={7}>
              <Row className="g-4">
                <Col sm={2}>
                  <Form.Check
                    type="switch"
                    className="mt-2"
                    label={luggageCheck ? "Yes" : "None"}
                    checked={luggageCheck}
                    onChange={(e) => setLuggageCheck(e.target.checked)}
                  />
                </Col>
                {luggageCheck && (
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="luggage"
                      placeholder="Luggage"
                      className="fs--1"
                      {...register('luggage', {
                        required: 'Luggage must be required'
                      })}
                    />
                    <span className="text-danger">
                      {errors.luggage && errors.luggage.message}
                    </span>
                  </Col>
                )}
              </Row>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="phone_contacts">
            <Form.Label column sm={3} className="text-lg-end">
              Phone contacts<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="phone_contacts"
                placeholder="Phone contacts"
                className="fs--1"
                {...register('phone_contacts', {
                  required: 'Phone contacts must be required'
                })}
              />
              <span className="text-danger">
                {errors.phone_contacts && errors.phone_contacts.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="cc_email">
            <Form.Label column sm={3} className="text-lg-end">
              CC emails <span className="text-primary">(optional)</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="cc_email"
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={defaultEmailOption}
                    loadOptions={emailOptions}
                    closeMenuOnSelect={false}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100"
                    isMulti
                    onChange={handleChangeCCemail}
                  />
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="route">
            <Form.Label column sm={3} className="text-lg-end">
              Route<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller 
                control={control}
                name="route"
                defaultValue={transaction && transaction.route ? transaction.route : "One way"}
                render={({field}) => (
                  <Flex direction={'row'} className="mt-1">
                    {['One way', 'Round trip'].map((option, index) => (
                      <Form.Check
                        key={option}
                        id={`${option}${index}`}
                        type="radio"
                        label={option}
                        value={option}
                        className="me-3"
                        {...field}
                        defaultChecked={field.value === option}
                        onChange={() => field.onChange(option)}
                      />
                    ))}
                  </Flex>
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="site_id">
            <Form.Label column sm={3} className="text-lg-end">
              Site<span className="text-primary">*</span>
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="site_id"
                defaultValue={transaction?.site_id || profileUser.site?.id}
                render={({field}) => (
                  <Select
                    closeMenuOnSelect={true}
                    options={siteOptions}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    {...field}
                    value={siteOptions.find(option => option.value === field.value) || null}
                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Site must be required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.site_id && errors.site_id.message}
              </span>
              <div className="mt-2">
                <FaInfoCircle /> Specify the correct site office, as the booking request will be forwarded to the HR Admin of that office for arrangement.
              </div>
            </Col>
          </Form.Group>

          <div className="bg-light fixed-bottom border-0 text-center p-3">
            <Button size="sm" type="submit">
              {transaction ? (<span><MdUpdate /> Update</span>) : (<span><MdCarCrash /> Request Booking</span>)}
            </Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={handleBack}><MdCancel /> Cancel</Button>
          </div>
      </Card.Body>
      </Form>
    </Card>
  ) 
}

TransactionForm.propTypes = {
  title: PropTypes.string,
  transaction: PropTypes.object,
  travel: PropTypes.object
}

export default TransactionForm