import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { MdUpdate } from "react-icons/md";
import { FaBan } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { changeManagerEmployee } from "redux/organization/employeeSlice";
import { activeEmployeesByNameDebounce } from "helpers/employeeOptionHelper";

const ChangeManagerForm = ({open, setOpen, records}) => {
  const { handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();

  const loadManagerOptions = (inputValue, callback) => {
    activeEmployeesByNameDebounce(inputValue, callback);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const onSubmit = async (data) => {
    try {
      const employeeIds = records && records.map(item => item.id);
      await dispatch(changeManagerEmployee({ employee_ids: employeeIds, parent_id: data.parent_id }));

      handleClose();

    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Change manager Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">Choose the manager's name from the list.</div>

          <Form.Group as={Row} className="mb-2" controlId="parent_id">
            <Form.Label column sm={3} className="text-lg-end">
              Manager
            </Form.Label>
            <Col sm={9} md={7}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadManagerOptions}
                closeMenuOnSelect={true}
                placeholder='Select...'
                classNamePrefix="react-select"
                className="w-100"
                name="parent_id"
                onChange={(selectOptions) => setValue('parent_id', selectOptions.value)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit"><MdUpdate /> Change</Button>
          <Button size="sm" variant="danger" onClick={handleClose}><FaBan /> Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ChangeManagerForm;