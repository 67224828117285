import React from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { FaRegListAlt } from 'react-icons/fa';
import ProjectMasterScheduleList from './project_master_schedule/ProjectMasterScheduleList';

const ProjectMasterSchedule = () => {
  const { loading } = useSelector((state) => state.crm_report_master_schedule)

  return (
    <>
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="master-schedule">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="master-schedule"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Overview</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="master-schedule">
                    <ProjectMasterScheduleList />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProjectMasterSchedule;