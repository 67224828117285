import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import PropTypes from 'prop-types';

const StarterOther = ({setShowModalOther}) => {
  const handleCreateOtherBooking = () => {
    setShowModalOther(true)
  }

  return (
    <>
      <Row className="align-items-center justify-content-between m-5">
        <Col lg={6}>
          <img src={editing} className="img-fluid" alt="" width="500px" />
        </Col>
        <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
          <h3 className="text-primary">Other Booking!</h3>
          <p className="lead">Create a booking for other purposes, such as a visa, for the trip.</p>
          <Button
            variant="falcon-primary"
            onClick={handleCreateOtherBooking}
          >
            Create record now!
          </Button>
        </Col>
      </Row>
    </>
  )
};

StarterOther.propTypes = {
  setShowModalOther: PropTypes.func.isRequired
}

export default StarterOther;