import React, { useEffect, useMemo } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch } from "react-redux";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import { createDepartment, updateDepartment } from "redux/organization/departmentSlice";
import PropTypes from 'prop-types'
import { Controller, useForm } from "react-hook-form";
import { departmentsDebounce, sitesDebounce } from "../../../helpers/employeeOptionHelper";

const DepartmentForm = ({showModal, setshowModal, title, department}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, reset } = useForm()
  const dispatch = useDispatch()

  const defaultOption = useMemo(() => (
    department ? { value: department.parent_id, label: department.parent_name } : ""
  ), [department])

  useEffect(() => {
    if (title === "New Department") {
      reset()
    }
  }, [title])

  useEffect(() => {
    if(department) {
      Object.entries(department).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, department])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const onSubmit = (data) => {
    console.log(data)

    try {
      if (department) {
        const departmentId = department.id
        dispatch(updateDepartment({ departmentId, data }))
      }
      else {
        dispatch(createDepartment(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const loadDepartmentOptions = (inputValue, callback) => {
    departmentsDebounce(inputValue, callback);
  };

  const siteOptions = (inputValue, callback) => {
    sitesDebounce(inputValue, callback);
  };

  const defaultSiteOption = useMemo(() => (
    department ? { value: department.site_id, label: department.site_name } : undefined
  ), [department])

  return (
    <Modal
      className="fs--1"
      show={showModal}
      onHide={() => setshowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Department name"
                className="fs--1"
                {...register("name", {
                  required: "Department name must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="parent_id">
            <Form.Label column sm={3} className="text-lg-end">
              Parent
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="parent_id"
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={defaultOption}
                    loadOptions={loadDepartmentOptions}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100"
                    onChange={selectedOption => setValue('parent_id', selectedOption.value)}
                  />
                )}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="site_id">
            <Form.Label column sm={3} className="text-lg-end">
              Site
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="site_id"
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={defaultSiteOption}
                    loadOptions={siteOptions}
                    closeMenuOnSelect={true}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100"
                    onChange={selectedOption => setValue('site_id', selectedOption.value)}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Site must be required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.site_id && errors.site_id.message}
              </span>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit">Save</Button>
          <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  ) 
}

DepartmentForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  department: PropTypes.shape({
    id: PropTypes.number,
    parent_id: PropTypes.number,
    parent_name: PropTypes.string,
    site_id: PropTypes.number,
    site_name: PropTypes.string
  })
};

export default DepartmentForm;