import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { ExportToExcel } from "helpers/utils";
import React, { useMemo } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from'prop-types';

const PCVDetail = ({data=[]}) => {
  const columns = useMemo(() => [
    {
      accessor: 'project_id',
      Header: 'Project ID#',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold'
      }
    },
    {
      accessor: 'project_name',
      Header: 'Project name',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { project_id, project_name } = rowData.row.original;
        return <Link to={`/crm/projects/${project_id}`} target="_blank">{project_name}</Link>;
      }
    },
    {
      accessor: 'q_number',
      Header: 'Quote number',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { project_id, q_number } = rowData.row.original;
        return <Link to={`/crm/projects/${project_id}`} target="_blank">{q_number}</Link>;
      }
    },
    {
      accessor: 'customer_name',
      Header: 'Customer name'
    },
    {
      accessor: 'closed_date',
      Header: 'Closed date'
    },
    {
      accessor: 'quotation_entry_date',
      Header: 'Quote entry date'
    },
    {
      accessor: 'job_entry_date',
      Header: 'Job entry date'
    },
    {
      accessor: 'status_name',
      Header: 'Building status'
    },
    {
      accessor: 'est_weight',
      Header: 'EST weight'
    },
    {
      accessor: 'sales_incharge',
      Header: 'Sales incharge'
    },
    {
      accessor: 'sales_unit_region',
      Header: 'Sales region'
    },
    {
      accessor: 'lead_id',
      Header: 'Lead ID#',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { lead_id } = rowData.row.original;
        return <Link to={`/crm/leads/${lead_id}`} target="_blank">{lead_id}</Link>;
      }
    }
  ], [data]);

  const handleExport = (data) => {
    ExportToExcel(data, "job-entry-data")
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      selection
      sortable
      pagination
      perPage={15}
      rowCount={data?.length}
    >
      <Card className="p-2">
        <Card.Header>
          <Row className="mb-0">
            <Col xs={{span: 12, order: 2}} md={{span: 6, offset: 6, order: 1}}>
              <Row className="gx-1">
                <Col>
                  <AdvanceTableSearchBox table/>
                </Col>
                <Col>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="external-link-alt"
                    transform="shrink-3"
                    onClick={() => handleExport(data)}
                  >
                    <span className="ms-1">Export</span>
                  </IconButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="btn-reveal-trigger text-nowrap align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={data?.length} table rowInfo navButtons rowsPerPageSelection />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

PCVDetail.propTypes = {
  data: PropTypes.array.isRequired
}

export default PCVDetail;