import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, Button, Row, Col, InputGroup, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import Flex from "components/common/Flex";
import { 
  setCurrentParams,
  setSortParams,
  setFilterTag,
  resetCurrentParams,
  resetFilterTag,
  exportData
} from "redux/crm/report/masterScheduleSlice";
import { FaSyncAlt } from "react-icons/fa";
import ProjectMasterScheduleFilter from "./ProjectMasterScheduleFilter";
import { toast } from "react-toastify";

const ProjectMasterScheduleHeader = () => {
  const { sortColumns, sortParams, currentParams, filterTags } = useSelector((state) => state.crm_report_master_schedule);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [direction, setDirection] = useState(true);
  const [defaultSortColumn] = useState(sortParams && sortParams.attribute || "id");

  const dispatch = useDispatch();
  
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const handleFilterModal = () => {
    setShowFilterForm(true)
  }

  const handleSortColumnChange = ({target}) => {
    const newSort = {...sortParams, attribute: target.value}
    dispatch(setSortParams(newSort))

    const newParams = {...currentParams, sort: newSort}
    dispatch(setCurrentParams(newParams))
  }

  const handleSort = () => {
    setDirection(prevDirection => !prevDirection)

    const newDirection = direction ? 'desc' : 'asc'
    const newSort = {...sortParams, direction: newDirection}
    dispatch(setSortParams(newSort))

    const newParams = {...currentParams, sort: newSort}
    dispatch(setCurrentParams(newParams))
  }

  const handleDeleteTag = (id) => {
    const newTags = filterTags.filter((tag) => tag.id !== id)
    dispatch(setFilterTag(newTags))

    //update current params
    const newParams = {...currentParams}
    delete newParams[id]
    dispatch(setCurrentParams(newParams))
  }

  const handleExport = () => {
    dispatch(exportData(currentParams))
  }

  const handleRefresh = () => {
    dispatch(resetCurrentParams())
    dispatch(resetFilterTag())

    toast.success("Data has been refreshed.")
  }

  return (
    <>
      <div className="mb-4">
        <h5 className="text-primary">PEB STEEL MASTER PROJECTS SCHEDULE</h5>
        <div className="fs--1">All projects with details on weight, shipment, payment terms, and production.</div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Row className="my-2 flex-between-center">
          <Col sm="auto">
            <Flex justifyContent="start" alignItems="center" wrap="wrap">
              <span className="me-2 fs--1">Conditions: </span>
              {filterTags ? (
                <span className="fs--1">
                  {filterTags.map((item, index) => (
                    <Badge pill bg="secondary" className="me-1 p-2" key={index}>
                      {item.name}: {Array.isArray(item.value) ? `[${item.value.join(', ')}]` : (typeof item.value === 'boolean' ? item.value.toString() : item.value)}
                      <span className="ms-2" style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon="trash-alt" onClick={() => handleDeleteTag(item.id)} />
                      </span>
                    </Badge>
                  ))}
                </span>
              ) : (
                <span className="text-primary">
                  <Badge pill bg="secondary" className="me-1 p-2">No filter</Badge>
                </span>
              )}
            </Flex>
          </Col>
          <Col sm="auto">
            <Row className="gx-2 align-items-center">
              <Col xs="auto">
                <small>Sort by:</small>
              </Col>
              <Col xs="auto">
                <InputGroup>
                  <Form.Select
                    className="pe-5 fs--1"
                    defaultValue={defaultSortColumn}
                    name="attribute"
                    onChange={handleSortColumnChange}
                  >
                    {sortColumns && Object.entries(sortColumns).map(([value, label]) => (
                      <option key={value} value={value} defaultValue={value === 'id'}>{label}</option>
                    ))}
                  </Form.Select>
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="fs--1" style={{ position: 'fixed' }} id="overlay-trigger-example">
                        Sort {direction ? "Ascending" : "Descending" }
                      </Tooltip>
                    }
                  >
                    <InputGroup.Text
                      as={Button}
                      variant="link"
                      className="border border-300 text-700"
                      onClick={handleSort}
                    >
                      <FontAwesomeIcon
                        icon={direction ? 'sort-amount-up' : 'sort-amount-down'}
                      />
                    </InputGroup.Text>
                  </OverlayTrigger>

                  <OverlayTrigger
                    overlay={
                      <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
                        Filters for advances
                      </Tooltip>
                    }
                  >
                    <InputGroup.Text
                      as={Button}
                      variant="default-falcon"
                      className="border border-300 text-700 fs--1"
                      onClick={handleFilterModal}
                    >
                      <FontAwesomeIcon icon="filter" />
                    </InputGroup.Text>
                  </OverlayTrigger>
                  <InputGroup.Text
                      as={Button}
                      variant="default-falcon"
                      className="border border-300 text-700 fs--1"
                      onClick={handleRefresh}
                    >
                      <FaSyncAlt />
                  </InputGroup.Text>
                  <InputGroup.Text
                      as={Button}
                      variant="default-falcon"
                      className="border border-300 text-700 fs--1"
                      onClick={handleExport}
                    >
                      <FontAwesomeIcon icon="download" />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <ProjectMasterScheduleFilter
        showModal={showFilterForm}
        setshowModal={setShowFilterForm}
      />
    </>
  )
};

export default ProjectMasterScheduleHeader;