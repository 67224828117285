import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatNumberWord } from "helpers/utils";
import React from 'react';
import PropTypes from 'prop-types';

const JodEntryRatio = ({title, current_value, previous_value}) => {
  // const percent = previous_value > 0 ? ((current_value / previous_value) * 100)?.toFixed(0) : 0
  // const caret = current_value > previous_value ? "up" : "down"

  return (
    <Card className="h-100 bg-info">
      <Card.Body>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="mb-3 position-static"
        >
          <h6 className="mb-0 flex-1 text-white">{title}</h6>
        </Flex>
        <Flex justifyContent="between" alignItems="center">
          <p className="font-sans-serif lh-1 mb-1 pe-2 text-white">
            <span className={`fs-4`}>{formatNumberWord(current_value)}%</span>
          </p>
          <span className="fs-1 text-warning ms-1">
            {formatNumberWord(previous_value)}%

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: 'fixed' }}>
                  Compare with the same period in the previous year
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon="info-circle"
                  transform="shrink-1"
                  className="ms-1 text-400"
                />
              </span>
            </OverlayTrigger>
          </span>
        </Flex>
      </Card.Body>
    </Card>
  )
}

JodEntryRatio.propTypes = {
  title: PropTypes.string.isRequired,
  current_value: PropTypes.number.isRequired,
  previous_value: PropTypes.number.isRequired
}

export default JodEntryRatio;