import Flex from "components/common/Flex";
import { formatDate, formatNumber, removeUnderlineString } from "helpers/utils";
import { Tabs, Tab, DropdownButton, ButtonGroup, Dropdown, Row, Col, Form, Badge } from "react-bootstrap";
import ChangeOrderUploadDocument from "../ChangeOrderUploadDocument";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { FaCalendarCheck, FaShareSquare, FaVoteYea, FaCheck, FaBan, FaUser, FaCalendar } from "react-icons/fa";
import React, { useState, lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showOnMaster, updateEstWithoutChange } from "redux/crm/project/changeOrderSlice";
import PropTypes from 'prop-types';
import { FaInfoCircle } from "react-icons/fa";

const LazyEstimateRequestToENG = lazy(() => import("./estimate/EstimateRequestToENG"));
const LazyEstimateScheduleToCSD = lazy(() => import("./estimate/EstimateScheduleToCSD"));
const LazyEstimateConfirmToSales = lazy(() => import("./estimate/EstimateConfirmToSales"));
const LazyEstimateRejectToENG = lazy(() => import("./estimate/EstimateRejectToENG"));
const LazyEstimateReleaseToCSD = lazy(() => import("./estimate/EstimateReleaseToCSD"));

const Estimate = ({
  changeOrder,
  handleUploadDocument,
  removeDocument
}) => {
  const [showRequestEstimate, setShowRequestEstimate] = useState(false);
  const [showScheduleEstimate, setShowScheduleEstimate] = useState(false);
  const [showReleaseEstimate, setShowReleaseEstimate] = useState(false);
  const [showRejectEstimate, setShowRejectEstimate] = useState(false);
  const [showConfirmEstimate, setShowConfirmEstimate] = useState(false);

  const TYPE_ESTIMATE_RELEASED = "estimate-released";

  const {
    allow_estimate_request,
    allow_estimate_schedule,
    allow_estimate_release,
    allow_estimate_confirm,
    allow_estimate_reject,
    allow_show_master,
    allow_view_estimate_release_document,
    allow_upload_estimate_release_document,
    allow_remove_estimate_release_document,
    allow_download_estimate_release_document,
    allow_est_without_change
  } = changeOrder?.permissions || {};

  const {
    requested_date,
    expected_date,
    requested_by,
    scheduled_date,
    released_date,
    released_by,
    released_weight,
    des_incharge,
    dsn_incharge
  } = changeOrder?.estimate || {};

  const {estimate} = changeOrder?.confirmation || {}
  const {project, building} = changeOrder || {};
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedESTNoChnage, setIsCheckedESTNoChnage] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsChecked(building?.est_co_lastest_id === changeOrder?.id);
    setIsCheckedESTNoChnage(changeOrder?.est_without_change);
  }, [building, dispatch]);

  const handleEstimateRequestToENG = () => {
    setShowRequestEstimate(true)
  }

  const handleEstimateScheduleToCSD = () => {
    setShowScheduleEstimate(true)
  }

  const handleEstimateReleaseToCSD = () => {
    setShowReleaseEstimate(true)
  }

  const handleConfirmToSales = () => {
    setShowConfirmEstimate(true)
  }

  const handleRejectToENG = () => {
    setShowRejectEstimate(true)
  }

  const handleShowEstimateToMasterSchedule = (e) => {
    const checked = e.target.checked
    setIsChecked(checked);

    const data = {
      type: "estimate",
      checked: checked,
      weight: changeOrder?.estimate.released_weight,
      latest_co_id: changeOrder.id
    }

    dispatch(showOnMaster({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data
    }))
  }

  const handleUpdateEstimateWithoutChange = (e) => {
    const checked = e.target.checked
    
    dispatch(updateEstWithoutChange({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data: { checked }
    }))
  }

  const bgConfirmStatus = () => {
    const status_name = estimate?.status
    const bg = status_name === "reject_and_waiting_adjust_drawing" ? "danger" : "primary"

    return <Badge pill bg={bg}>{removeUnderlineString(status_name)}</Badge>
  }

  return (
    <>
      <Flex justifyContent="end" className="mb-2">
        <DropdownButton variant="falcon-default" as={ButtonGroup} title="Action" id="bg-nested-dropdown" size="sm">
          <div className="py-1">
            {allow_estimate_request && (
              <Dropdown.Item className="mb-1" onClick={handleEstimateRequestToENG}>
                <FaShareSquare /> Request to ENG
              </Dropdown.Item>
            )}
            
            {allow_estimate_schedule && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-2" onClick={handleEstimateScheduleToCSD}>
                  <FaCalendarCheck/> Schedule to CSD
                </Dropdown.Item>
              </>
            )}
            
            {allow_estimate_release && (
              <Dropdown.Item className="mb-1 text-primary" onClick={handleEstimateReleaseToCSD}>
                <FaVoteYea /> Release to CSD
              </Dropdown.Item>
            )}
            
            {allow_estimate_confirm && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-1" onClick={handleConfirmToSales}>
                  <FaCheck className="text-primary"/> CSD confirm to Sales
                </Dropdown.Item>
              </>
            )}
            
            {allow_estimate_reject && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-2" onClick={handleRejectToENG}>
                  <FaBan className="text-danger" /> CSD reject to ENG
                </Dropdown.Item>
              </>
            )}
          </div>
        </DropdownButton>
      </Flex>
      
      <Tabs defaultActiveKey="estimate-released" id="change-order-detail" transition={false}>
        <Tab eventKey="estimate-released" title="Overview" className='border-bottom border-x p-3'>
          <Row className="p-3">
            <Col>
              <h6>Request estimate</h6>
              <div className="mx-2">
                <Row className="mb-2 gx-2">
                  <Col sm={4}><FaCalendar className="text-primary" /> Requested date</Col>
                  <Col className="text-primary">{formatDate(expected_date)}</Col>
                </Row>
              </div>
              <div className="mx-2">
                <Row className="mb-2 gx-2">
                  <Col sm={4}><FaUser className="text-primary" /> Requested by</Col>
                  <Col className="text-primary">{requested_by}</Col>
                </Row>
              </div>

              <h6 className="mt-4">Schedule estimate</h6>
              <div className="mx-2">
                <Row className="mb-2 gx-2">
                  <Col sm={4}><FaCalendar className="text-primary" /> Scheduled date</Col>
                  <Col className="text-primary">{formatDate(scheduled_date)}</Col>
                </Row>
              </div>

              <h6 className="mt-4">Release estimate</h6>
              <div className="mx-2">
                <Row className="mb-3 gx-2">
                  <Col sm={4}><FaCalendar className="text-primary" /> Released date</Col>
                  <Col className="text-primary">{formatDate(released_date)}</Col>
                </Row>
                <Row className="mb-3 gx-2">
                  <Col sm={4}>Weight (M.T)</Col>
                  <Col>
                    <div className="text-primary">{formatNumber(released_weight)}</div>
                    
                    {allow_show_master && (
                      <div className="mt-2 text-primary">
                        <Form.Check
                          label="Show in Master Schedule report"
                          onChange={(e) => handleShowEstimateToMasterSchedule(e)}
                          checked={isChecked}
                        />
                      </div>
                    )}

                    <div className="mt-1 text-primary">
                      <Form.Check
                        label={
                          <span>
                            Estimate without change <FaInfoCircle className="text-warning" title="If selected, this action will send a notification email to CSD handle." />
                          </span>
                        }
                        onChange={(e) => handleUpdateEstimateWithoutChange(e)}
                        checked={isCheckedESTNoChnage}
                        disabled={!allow_est_without_change}
                      />
                    </div>
                   
                  </Col>
                </Row>

                <Row className="mb-3 gx-2">
                  <Col sm={4}><FaUser className="text-primary" /> Designer</Col>
                  <Col className="text-primary">{dsn_incharge}</Col>
                </Row>

                <Row className="mb-3 gx-2">
                  <Col sm={4}><FaUser className="text-primary" /> Checker</Col>
                  <Col className="text-primary">{des_incharge}</Col>
                </Row>

                <Row className="mb-3 gx-2">
                  <Col sm={4}><FaUser className="text-primary" /> Released by</Col>
                  <Col className="text-primary">{released_by}</Col>
                </Row>

                {/* <Row className="mb-2 gx-2">
                  <Col sm={3}>ENG Note</Col>
                  <Col className="text-primary">
                    <div dangerouslySetInnerHTML={{ __html: eng_note }}/>
                  </Col>
                </Row> */}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                {allow_upload_estimate_release_document && (
                  <ChangeOrderUploadDocument uploadDocument={(data) => handleUploadDocument(data, TYPE_ESTIMATE_RELEASED)}/>
                )}
              </div>
              <div>
                <h6 className="mt-3">Attachments:</h6>
                {changeOrder?.estimate_released_documents && changeOrder?.estimate_released_documents.map(attachment => (
                  <DocumentAttachment
                    {...attachment}
                    key={attachment.id}
                    isTable
                    allowView={allow_view_estimate_release_document}
                    allowRemove={allow_remove_estimate_release_document}
                    allowDownload={allow_download_estimate_release_document}
                    handleRemoveAttachment={(documentId) => removeDocument(documentId, TYPE_ESTIMATE_RELEASED)}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="estimate-confirmation" title="Confirmation" className='border-bottom border-x p-3'>
          <div className="p-3">
            <h6>CSD Confirmation estimate</h6>
            <div className="mx-2">
              <Row className="mb-2 gx-2">
                <Col sm={2}>Status</Col>
                <Col>{bgConfirmStatus()}</Col>
              </Row>

              <Row className="mb-2 gx-2 mt-4">
                <Col sm={2}><FaUser className="text-primary" /> By</Col>
                <Col className="text-primary">{estimate?.by}</Col>
              </Row>
              <Row className="mb-2 gx-2">
                <Col sm={2}><FaCalendar className="text-primary" /> Date</Col>
                <Col className="text-primary">{formatDate(estimate?.date)}</Col>
              </Row>

              <Row className="mb-2 gx-2 mt-4">
                <Col sm={2}>Note</Col>
                <Col className="text-primary">
                  <div dangerouslySetInnerHTML={{ __html: estimate?.note }}/>
                </Col>
              </Row>
            </div>
        </div>
        </Tab>
      </Tabs>

      <Suspense fallback={<div>Loading....</div>}>
        {showRequestEstimate && (
          <LazyEstimateRequestToENG
            showModal={showRequestEstimate}
            setshowModal={setShowRequestEstimate}
            changeOrder={changeOrder}
          />
        )}

        {showScheduleEstimate && (
          <LazyEstimateScheduleToCSD
            showModal={showScheduleEstimate}
            setshowModal={setShowScheduleEstimate}
            changeOrder={changeOrder}
          />
        )}

        {showReleaseEstimate && (
          <LazyEstimateReleaseToCSD
            showModal={showReleaseEstimate}
            setshowModal={setShowReleaseEstimate}
            changeOrder={changeOrder}
          />
        )}

        {showConfirmEstimate && (
          <LazyEstimateConfirmToSales
            showModal={showConfirmEstimate}
            setshowModal={setShowConfirmEstimate}
            changeOrder={changeOrder}
          />
        )}

        {showRejectEstimate && (
          <LazyEstimateRejectToENG
            showModal={showRejectEstimate}
            setshowModal={setShowRejectEstimate}
            changeOrder={changeOrder}
          />
        )}
      </Suspense>
    </>
  )
}

Estimate.propTypes = {
  changeOrder: PropTypes.object.isRequired,
  handleUploadDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired
}

export default Estimate;