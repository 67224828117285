import { useParams } from "react-router-dom";
import CustomerDetailHeader from "./CustomerDetailHeader";
import CustomerGeneral from "./CustomerGeneral";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "redux/crm/customerSlice";
import { Card, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerActivity from "./CustomerActivity";

const CustomerDetail = () => {
  //const {recordData: customer} = useSelector(state => state.crm_customer);
  const [customer, setCustomer] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomer(params.id)).then(action => {
      setCustomer(action.payload.data.attributes)
    })
  }, [params, dispatch]);

  return (
    <>
      <CustomerDetailHeader customer={customer} />

      <Card>
        <Tab.Container defaultActiveKey="customer-detail">
          <Card.Header className="p-0 border-bottom">
            <Nav className="nav-tabs border-0 flex-nowrap tab-customer-details">
              <Nav.Item>
                <Nav.Link
                  eventKey="customer-detail"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="stream"
                    className="text-600 tab-icon"
                  />
                  <h6 className="text-600 mb-0 ms-1">Detail</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="customer-activity-log"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="history"
                    className="text-600"
                  />
                  <h6 className="text-600 mb-0 ms-1">Activities history</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              <Tab.Pane eventKey="customer-detail">
                <CustomerGeneral customer={customer} />
              </Tab.Pane>
              <Tab.Pane eventKey="customer-activity-log">
                <CustomerActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export default CustomerDetail;