import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getAllPermissions,
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setFilterTag,
  exportPermissions
} from "redux/authorization/permissionSlice";

import AgGridTable from "components/ag-grid/AgGridTable";
import AgPagination from "components/ag-grid/AgPagination";
import { useDispatch } from "react-redux";
import GridFilter from "components/peb-grid/GridFilter";
import FilterAdvanceModal from "./FilterAdvanceModal";
import PropTypes from 'prop-types';
import { PERPAGE_DEFAULT_SM } from "app_config";

const PermissionList = ({setPermissionSelected}) => {
  const key = "allData";
  const { allData } = useSelector((state) => state.authorization_permission);

  const {
    data,
    totalRows,
    pageSize,
    totalPagesCount,
    fromRecord,
    toRecord,
    currentParams,
    filterTags
  } = allData || {};
  
  const dispatch = useDispatch();

  const columnDefs = useMemo(() => [
    { 
      headerName: 'ID#',
      field: 'id',
      minWidth: 110,
      headerCheckboxSelection: true,
      checkboxSelection: true
    },
    { 
      headerName: 'Subject class',
      field: 'subject_class',
      minWidth: 100
    },
    { 
      headerName: 'Action',
      field: 'action',
      minWidth: 200
    },
    { 
      headerName: 'Description',
      field: 'description',
      minWidth: 300
    },
    { 
      headerName: 'Parent group',
      field: 'parent_group',
      minWidth: 200
    }
  ], []);

  useEffect(() => {
    const params = currentParams ?? { page: 1, per_page: PERPAGE_DEFAULT_SM };
    dispatch(getAllPermissions(params));
  }, [currentParams]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setNestedPage({ key, page }));
    dispatch(setNestedPageSize({ key, pageSize }));

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize};
    console.log(newCurrentParams);
    dispatch(setNestedParams({ key, params: newCurrentParams }));

    dispatch(getAllPermissions(newCurrentParams));
  })

  return (
    <>
      <GridFilter
        entryType="all"
        entryData={allData}
        loadApi={getAllPermissions}
        currentParams={currentParams}
        entryKey={"allData"}
        setNestedParams={setNestedParams}
        resetNestedParams={resetNestedParams}
        setFilterTag={setFilterTag}
        filterTags={filterTags}
        filterAttributes="subject_class_or_action_or_description_or_parent_group_cont"
        exportApi={exportPermissions}
        FilterAdvanceComponent={FilterAdvanceModal}
      />

      <AgGridTable
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        refData={setPermissionSelected}
        selectedMultipe={true}
        serverSide={true}
      />
      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200", "500"]}
      />
    </>
  )
}

PermissionList.propTypes = {
  setPermissionSelected: PropTypes.func.isRequired
}


export default PermissionList;