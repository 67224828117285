import React from 'react';

const DurationRenderer = (props) => {
  const value = props.value ? `${props.value} years` : null; 
  return (
    <span className="text-primary">{value}</span>
  );
};

export default DurationRenderer;
