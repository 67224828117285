import React, { useState } from 'react';
import OtherForm from './OtherForm';
import OtherList from './OtherList';
import StarterOther from './StarterOther';
import PropTypes from 'prop-types';

const Other = ({booking}) => {
  const [showModalOther, setShowModalOther] = useState(false)
  const {shuttles} = booking
  const shuttleOthers = shuttles && shuttles.filter((shuttle) => shuttle.other === true)

  return (
    <>
      {shuttleOthers && shuttleOthers.length > 0 ? (
        <OtherList booking={booking} setShowModalOther={setShowModalOther} />
      ) : (
        <StarterOther setShowModalOther={setShowModalOther}/>
      )}
      
      <OtherForm 
        showModal={showModalOther}
        setshowModal={setShowModalOther}
        booking={booking}
      />
    </>
  )
};

Other.propTypes = {
  booking: PropTypes.object.isRequired
}

export default Other;