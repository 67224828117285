import UserAvatar from "components/avatar/UserAvatar";
import SoftBadge from "components/common/SoftBadge";
import { Row, Col } from "react-bootstrap";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { OPLA_CRM_CUSTOMER_URL } from "app_config";
import { GrIntegration } from "react-icons/gr";

const Overview = ({customer={}}) => {
  return (
    <>
      <Row className="mb-5">
        <Col lg xxl={5}>
          <h6 className="fw-semi-bold ls mb-3 text-uppercase">
            customer
          </h6>
          <Row className="mb-2">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Customer name</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.name}</p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Parent</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">
                <Link to={`/crm/customers/${customer?.parent_id}`} target="_blank">{customer?.parent?.name}</Link>
              </p>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Type of customer</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.type_of_company}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Legal status</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.legal_status}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Industry</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.industry}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Source</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.source}</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Number of employees</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.employess}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={3} sm={5}>
              <p className="fw-semi-bold fs--1 mb-1">Country</p>
            </Col>
            <Col>
              <p className="mb-1 fs--1 text-primary">{customer?.country_name}</p>
            </Col>
          </Row>
        </Col>
        <Col lg className="mt-4 mt-lg-0">
          <h6 className="fw-semi-bold ls mb-3 text-uppercase">
            Responsible
          </h6>
          <Row className="mb-2">
            <Col xs={4} sm={5}>
              <p className="fw-semi-bold mb-1 fs--1">Main contact</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1">{customer?.main_contact}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4} sm={5}>
              <p className="fw-semi-bold mb-1 fs--1">Secondary contact</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1">
                {customer?.secondary_contact && customer?.secondary_contact.map((name, index) => (
                  <SoftBadge bg='info' key={index} className='me-2'>{name}</SoftBadge>
                ))}
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4} sm={5}>
              <p className="fw-semi-bold mb-1 fs--1">Sales unit</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1">{customer?.sales_unit?.name} - {customer?.sales_unit?.description}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col lg xxl={5}>
          <h6 className="fw-semi-bold ls mb-3 text-uppercase">
            <GrIntegration /> Opla CRM
          </h6>
          <Row className="mb-2">
            <Col xs={2} sm={2}>
              <p className="fw-semi-bold mb-1 fs--1">Opla ID</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1"><Link to={`${OPLA_CRM_CUSTOMER_URL}/${customer?.opla_id}`} target="_blank">{customer?.opla_id}</Link></p>
            </Col>
          </Row>  
        </Col>
        <Col lg xxl={5}>
          <h6 className="fw-semi-bold ls mb-3 text-uppercase">
            History
          </h6>
          <Row className="mb-2">
            <Col xs={4} sm={4}>
              <p className="fw-semi-bold mb-1 fs--1">Created by</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1"><UserAvatar size="2xl" user_avatar={customer?.user_avatar} /></p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4} sm={4}>
              <p className="fw-semi-bold mb-1 fs--1">Created date</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1">{customer?.created_at}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4} sm={4}>
              <p className="fw-semi-bold mb-1 fs--1">Updated date</p>
            </Col>
            <Col>
              <p className="mb-1 text-primary fs--1">{customer?.updated_at}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Overview.propTypes = {
  customer: PropTypes.object.isRequired
}

export default Overview;