import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from 'helpers/reducerHelpers';

const employeeUrl = "/organization/employees"

export const getAllEmployees = createAsyncThunk(
  "employee/getAllEmployees",
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${employeeUrl}`, { params } )
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getEmployee = createAsyncThunk(
  "employee/getEmployee",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${employeeUrl}/${employeeId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createEmployee = createAsyncThunk(
  "employee/CreateEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(employeeUrl, data);
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async ({ employeeId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${employeeUrl}/${employeeId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.delete(`${employeeUrl}/${employeeId}`);
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
);

export const deactiveEmployee = createAsyncThunk(
  "employee/deactiveEmployee",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${employeeUrl}/${employeeId}/deactive`);
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
);


export const getPositionGroups = createAsyncThunk(
  "employee/getPositionGroups",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${employeeUrl}/position_group_list`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getOrganizationEmployeeChart = createAsyncThunk(
  "employee/getOrganizationEmployeeChart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${employeeUrl}/emp_organization_chart`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const changeManagerEmployee = createAsyncThunk(
  "employee/changeManagerEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${employeeUrl}/change_manager`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const exportEmployees = createAsyncThunk(
  "employee/exportEmployees",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${employeeUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'employees.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const syncNewEmployee = createAsyncThunk(
  "employee/syncNewEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${employeeUrl}/sync_new_employee`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const syncTitleEmployeeChanged = createAsyncThunk(
  "employee/syncTitleEmployeeChanged",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${employeeUrl}/sync_title_employee_update`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const syncTerminalEmployee = createAsyncThunk(
  "employee/syncTerminalEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${employeeUrl}/sync_terminal_employee`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)


const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const employeeSlice = createSlice({
  name: "organization_employee",
  initialState: {
    ...initialState,
    positionGroups: [],
    empChartDataSnk: {},
    empChartDataAko: {}
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))

      .addCase(getEmployee.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(deactiveEmployee.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(getPositionGroups.fulfilled, (state, action) => {
        state.loading = false
        state.positionGroups = action.payload
      })
      .addCase(getOrganizationEmployeeChart.fulfilled, (state, action) => {
        state.loading = false
        state.empChartDataSnk = action.payload[0]
        state.empChartDataAko = action.payload[1]
      })
      .addCase(exportEmployees.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportEmployees.fulfilled, (state) => {
        state.exporting = false
        toast.success("Employee has been exported successfully.")
      })
      .addCase(syncNewEmployee.fulfilled, (state, action) => {
        const {message} = action.payload;
        toast.success(message);
      })
      .addCase(syncTitleEmployeeChanged.fulfilled, (state, action) => {
        const {message} = action.payload;
        toast.success(message);
      })
      .addCase(syncTerminalEmployee.fulfilled, (state, action) => {
        const {message} = action.payload;
        toast.success(message);
      })
      .addCase(changeManagerEmployee.fulfilled, (state, action) => {
        const { error, data, message } = action.payload;
        
          if (error !== undefined) {
            toast.error(error);
          } else {
            data?.forEach(employee => {
              const updatedIndex = state.allData.data.findIndex((item) => item.id === Number(employee.id))
              if (updatedIndex !== -1) {
                state.allData.data[updatedIndex] = employee.attributes;
              }
            })

            if (message !== null) {
              toast.success(message);
            }
          }
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = employeeSlice.actions;

export default employeeSlice.reducer
