import ContractCountry from "./ContractCountry";
import ContractMonthly from "./ContractMonthly";
import ContractProvince from "./ContractProvince";
import ContractTopRevenueProject from "./ContractTopRevenueProject";
import ContractRatioOfSales from "./ContractRatioOfSales";
import React from 'react';
import PropTypes from 'prop-types';

const ContractSummary = ({originalData}) => {
  const {type_of_sales, sales_regions, monthly, pj_countries, countries, data} = originalData;
  return (
    <>
      <ContractTopRevenueProject data={data} />
      <ContractRatioOfSales data={{sales_regions, type_of_sales}} />
      <ContractMonthly data={monthly} />
      <ContractProvince originalData={{data, pj_countries}} />
      <ContractCountry data={countries} />
    </>
  )
};

ContractSummary.propTypes = {
  originalData: PropTypes.shape({
    type_of_sales: PropTypes.string,
    sales_regions: PropTypes.array,
    monthly: PropTypes.object,
    pj_countries: PropTypes.array,
    countries: PropTypes.array,
    data: PropTypes.array
  }).isRequired
}

export default ContractSummary;