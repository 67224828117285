import { useParams } from "react-router-dom"
import EmployeeDetailHeader from "./EmployeeDetailHeader"
import EmployeeDetailInfo from "./EmployeeDetailInfo"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getEmployee } from "redux/organization/employeeSlice"

const EmployeeDetail = () => {
  const { recordData: employee } = useSelector((state) => state.organization_employee);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployee(params.id));
  }, [params])
  
  return (
    <>
      <EmployeeDetailHeader employee={employee} />
      <EmployeeDetailInfo employee={employee} />
    </>
    
  )
}

export default EmployeeDetail
