import React from 'react';
import ConfirmMailContent from 'components/authentication/ConfirmMailContent';
import { useSelector } from 'react-redux';

const ConfirmMail = () => {
  const {confirmEmail} = useSelector((state) => state.settings)

  return (
    <div className="text-center">
      <ConfirmMailContent email={confirmEmail} />
    </div>
  )
};

export default ConfirmMail;
