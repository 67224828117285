import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import { useSelector } from 'react-redux';
import { getGlobalNotifications, markAllReadNotifications } from 'redux/notificationSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const NotificationDropdown = () => {

  const {data, todayData, earlierData, unReadCount} = useSelector((state) => state.notification)
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  useEffect(() => {
    dispatch(getGlobalNotifications())
  }, [isAllRead, data])

  const markAsRead = async (e) => {
    e.preventDefault();
    try {
      await dispatch(markAllReadNotifications())
      setIsAllRead(true)
      toast.success("Mark all as read notifications are successfully.")
    }
    catch(error) {
      toast.error(error.message)
    }
  }

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-warning': !isAllRead,
          'notification-indicator-fill': unReadCount > 0
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
        {unReadCount > 0 && (
          <span className="notification-indicator-number">
            {unReadCount}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <div className="list-group-title">NEW</div>{' '}
              {todayData && todayData.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
            <div className="list-group-title">EARLIER</div>
              {earlierData && earlierData.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/notifications">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
