import React from "react";
import { Card, Row, Col, Dropdown, Form, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from 'prop-types';
import Flex from "components/common/Flex";
import BookingUpdateBudgetModal from "./BookingUpdateBudgetModal";
import { useState } from "react";
import { formatNumber } from "helpers/utils";
import { useDispatch } from "react-redux";
import { sendBackUpdateBudgetToHRAdmin } from "redux/booking/travel/travelBookingSlice";
import { FaEdit, FaEllipsisV, FaEnvelope, FaRProject } from "react-icons/fa";

const BookingDetailTripForProject = ({booking}) => {
  const [showModal, setShowModal] = useState(false);
  const {project} = booking;
  const {
    allow_edit_project_budget,
    allow_send_back_update_budget_to_hr_admin
  } = booking?.permissions || {};

  const dispatch = useDispatch();

  const handleUpdateProjectBudget = () => {
    setShowModal(true)
  }

  const handleSendBackUpdateBudgettoHRAdmin = () => {
    dispatch(sendBackUpdateBudgetToHRAdmin(booking.id));
  }

  return (
    <>
      <Card className="mb-3">
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                <h5 className="mb-0"><FaRProject /> Trip for project</h5>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="bg-light border-top">
            <Row className="mb-5">
              <Col lg xxl={6}>
                <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                  Project
                </h6>
                <Row  className="mb-2">
                  <Col xs={3} sm={4}>
                    <p className="fw-semi-bold fs--1 mb-1">Customer name</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project?.customer_name}</p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={3} sm={4}>
                    <p className="fw-semi-bold fs--1 mb-1">Project name</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project?.project_name}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={3} sm={4}>
                    <p className="fw-semi-bold fs--1 mb-1">Job number</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{booking?.job_number}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={4}>
                    <p className="fw-semi-bold mb-1 fs--1">Customers traveler</p>
                  </Col>
                  <Col>
                    <p className="mb-1 text-primary fs--1">
                      {booking.customers_traveler && booking.customers_traveler.map(item => (
                        <Badge variant="primary" className="me-1" key={item.id}>{item}</Badge>
                      ))}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={3} sm={4}>
                    <p className="fw-semi-bold fs--1 mb-1">Number of customers traveler</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{booking.customers_traveler && booking.customers_traveler.length}</p>
                  </Col>
                </Row>
              </Col>
              <Col lg className="mt-4 mt-lg-0">
                <Flex justifyContent="between">
                  <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                    Budget
                  </h6>

                  {booking?.follow_project_budget && (
                    <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                      <Dropdown.Toggle split variant="falcon-default" size="sm">
                        <FaEllipsisV icon="ellipsis-v" className="fs--2" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="border py-0">
                        <div className="py-2">
                          {allow_edit_project_budget && (
                            <>
                             <Dropdown.Item className="mb-1" onClick={handleUpdateProjectBudget}>
                              <FaEdit icon="edit" /> Update project budget
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            </>
                          )}

                          {allow_send_back_update_budget_to_hr_admin && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip placement="bottom" style={{position: "fixed"}}>
                                  This function is used to send an email back to the HR admin
                                </Tooltip>
                              }
                            >
                              <Dropdown.Item className="mb-1" onClick={handleSendBackUpdateBudgettoHRAdmin}>
                                <FaEnvelope icon="envelope" /> Send back to HR admin
                              </Dropdown.Item>
                            </OverlayTrigger>
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Flex>

                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold mb-1 fs--1">Follow project budget</p>
                  </Col>
                  <Col>
                    <p className="mb-1 text-primary fs--1">
                      <Form.Check 
                        type='switch'
                        id='required_hotel'
                        checked={booking.follow_project_budget}
                      />
                    </p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Budget currency</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project && formatNumber(project.budget_amount_currency)} USD</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Exchange rate</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project && formatNumber(project.budget_exchange_rate)} VND</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Budget amount</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project && formatNumber(project.budget_amount, "VND")} VND</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Notes</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project && project.budget_notes}</p>
                  </Col>
                </Row>

                <Row className="mb-2 mt-4">
                  <Col xs={4} sm={5}>
                    <p className="fw-bold fs--1 mb-1">Budget remain</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-danger">{project && formatNumber(project.budget_remaining_currency)} USD</p>
                  </Col>
                </Row>

                <Row className="mb-2 mt-4">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Updated by</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project && project.budget_by}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} sm={5}>
                    <p className="fw-semi-bold fs--1 mb-1">Updated date</p>
                  </Col>
                  <Col>
                    <p className="mb-1 fs--1 text-primary">{project && project.budget_date}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
      </Card>

      <BookingUpdateBudgetModal 
        showModal={showModal}
        setShowModal={setShowModal}
        booking={booking}
      />
    </>
  )
}

BookingDetailTripForProject.propTypes = {
  booking: PropTypes.object
};

export default BookingDetailTripForProject;