import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import PIFForm from "../pif/PIFForm"

const PIFVersionEdit = () => {
  const location = useLocation()
  const {pif} = location.state
  const {project, building, adaptation} = pif
  
  useEffect(() => {
    try {
      if (pif === null) {
        throw new Error('The link is incorrect because the PIF is null. Please correct the source of the link in the system.');
      }

    } catch (error) {
      // Catch the error and show it on a toast
      toast.error('An error occurred: ' + error.message);
    }
  }, [pif])

  return (
    <PIFForm
      project={project}
      building={building}
      adaptation={adaptation}
      pif={pif}
      title="Edit"
      isPIFversion={true}
    />
  )
}

export default PIFVersionEdit;