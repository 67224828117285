
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Tab, Nav, Card, Table, Badge } from "react-bootstrap"
import { FaFile, FaFolder, FaShareAlt, FaUser, FaUserCircle, FaUsers } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify"
import { makePublicAsset, removePublicAsset, getVersions, getSharingList, removeOwner } from "redux/drive/driveSlice";
import { MdOutlinePrivateConnectivity, MdPublic, MdHistoryToggleOff, MdDownload, MdDelete} from "react-icons/md";
import { FcViewDetails } from "react-icons/fc";
import SimpleBarReact from 'simplebar-react';
import { downloadAsset } from 'redux/drive/driveSlice';

const DriveDetail = ({showModal, setshowModal, asset }) => {
  const [versions, setVersions] = useState([]);
  const [sharings, setSharings] = useState([]);
  const assetType = asset?.asset_type;
  const isPublic = asset?.public;
  const assetId = asset?.id;
  const {owners, users, departments} = sharings || {};

  const dispatch = useDispatch();

  const { 
    allow_make_public,
    allow_remove_public,
    allow_download,
    allow_remove_owner
  } = asset?.permissions || {};

  const closeModal = () => {
    setshowModal(false);
  }

  const handleMakePublic = () => {
    try {
      dispatch(makePublicAsset(assetId))
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleRemovePublic = () => {
    try {
      dispatch(removePublicAsset(assetId))
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  useEffect(() => {
    fetchVersions();
    fetchSharings();
  }, [asset]);

  const fetchVersions = async () => {
    try {
      let data = await dispatch(getVersions(assetId)).unwrap();
      if (data) {
        data = data.map(item => item.attributes);
        setVersions(data);
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  const fetchSharings = async () => {
    try {
      let data = await dispatch(getSharingList(assetId)).unwrap();
      if (data) {
        setSharings(data);
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  const downloadFile = async () => {
    const fileName = asset?.name;
    await dispatch(downloadAsset({assetId, fileName }));
  }

  const handleRemoveOnwer = async (userId) => {
    try {
      await dispatch(removeOwner({assetId, userId}));
      closeModal();
    }
    catch(error) {
      toast.error(error.message);
    }
  }

  const ownerUserNames = owners && owners.map(owner => owner.attributes.user_name);
  const employeeNames = users && users.map(owner => owner.attributes.user_name);
  const departmentNames = departments && departments.map(owner => owner.attributes.department_name);

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
    >
      <Modal.Header closeButton className="border-bottom">
        <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><span className="text-capitalize">{assetType}</span> information!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-2">
          {assetType === "file" ? <FaFile size="50"/> : <FaFolder size="50"/>}
        </div>
        <div className="text-center">
          <div className="fw-bold mb-2">Who has access</div>
          <FaUserCircle size={30} className="text-primary" />
          <div className="mb-3 mt-1">
            <span>
              {isPublic ? (
                <>
                  <FaUsers /> Public
                </>
              ) : (
                <>
                  <MdOutlinePrivateConnectivity /> Private to you
                </>
              )}
            </span>
            {(allow_make_public || allow_remove_public) && (
              <>
                {" | "}
                <span
                  className={`ms-1 cursor-pointer ${isPublic ? "text-danger" : "text-primary"}`}
                  onClick={isPublic ? handleRemovePublic : handleMakePublic}
                >
                  {isPublic ? "Remove public" : (
                    <><MdPublic /> Make public</>
                  )}
                </span>
              </>
            )}
          </div>
        </div>

        <Tab.Container defaultActiveKey="drive-overview">
          <Card.Header className="p-0 bg-light overflow-hidden">
            <SimpleBarReact>
              <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                <Nav.Item>
                  <Nav.Link
                    eventKey="drive-overview"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FcViewDetails />
                    <h6 className="text-600 mb-0 ms-1">Overview</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="drive-version"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <MdHistoryToggleOff />
                    <h6 className="text-600 mb-0 ms-1">Versions</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="drive-sharing"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaShareAlt />
                    <h6 className="text-600 mb-0 ms-1">Sharing</h6>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </SimpleBarReact>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              <Tab.Pane eventKey="drive-overview" className="p-3">
                <Row className="mb-2 gx-2">
                  <Col md={3}>Name:</Col>
                  <Col className="text-primary fw-bold">{asset?.name}</Col>
                </Row>
                <Row className="mb-2 gx-2">
                  <Col md={3}>Location:</Col>
                  <Col className="fw-bold">{asset?.parent_id ? asset?.parent_name : <strong>My Drive</strong>}</Col>
                </Row>

                <Row className="mb-2 gx-2">
                  <Col md={3}>Owner:</Col>
                  <Col><FaUser />{asset?.created_by}</Col>
                </Row>

                <Row className="mb-2 gx-2">
                  <Col md={3}>Modified:</Col>
                  <Col>{asset?.updated_date}</Col>
                </Row>

                <Row className="mb-2 gx-2">
                  <Col md={3}>Created:</Col>
                  <Col>{asset?.created_date}</Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="drive-version" className="p-3">
                <div className="mb-2">List of versions</div>

                <Table className="fs--1 mb-0">
                  <thead className="bg-200 text-900 text-nowrap align-middle border-bottom">  
                    <tr>
                      <th className="p-2 cursor-pointer">Version</th>
                      <th className="p-2 cursor-pointer">Modified</th>
                      <th></th>
                      <th></th>
                    </tr> 
                  </thead>
                  <tbody className="fs--1">
                    {versions && versions.length > 0 ? (
                      versions.map((item, index) => 
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{item.created_date}</td>
                          <td>
                            {(index + 1) === versions.length && (
                              <span className="badge bg-primary ms-2">Current version</span>
                            )}
                          </td>
                          <td>
                            {allow_download && (
                              <MdDownload className="cursor-pointer" onClick={downloadFile} />
                            )}
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan="4" className="py-4 text-center">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey="drive-sharing" className="p-3">
                <Row className="mb-2 gx-2">
                  <Col md={3}>Owner:</Col>
                  <Col>
                    {ownerUserNames && ownerUserNames.map((item, index) => (
                      <Badge key={index} bg="primary" className="me-1">
                        {item}
                        {allow_remove_owner && (
                          <span
                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                            onClick={() => handleRemoveOnwer(item)}
                          >
                            ✕
                          </span>
                        )}
                      </Badge>
                    ))}
                  </Col>
                </Row>
                <Row className="mb-2 gx-2">
                  <Col md={3}>Employee:</Col>
                  <Col className="text-primary">{employeeNames?.join(", ") }</Col>
                </Row>
                <Row className="mb-2 gx-2">
                  <Col md={3}>Department:</Col>
                  <Col className="text-primary">{departmentNames?.join(", ") }</Col>
                </Row>
                
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>

      </Modal.Body> 
      <Modal.Footer className="border-0">
        <Button size="sm" variant="primary" onClick={closeModal}>Close</Button>
      </Modal.Footer>

    </Modal>
  )
};

export default DriveDetail;