import { formatDate } from 'helpers/utils';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTrophy } from 'react-icons/fa';
import ConvertPCVComponent from '../ConvertPCVCompenent';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ConvertedPCV = ({lead}) => {
  const navigate = useNavigate();
  const opportunity = lead?.opportunity || {};

 const handleGoToPCV = () => {
  navigate(`/crm/projects/${opportunity.id}`);
 }

  return (
    <div>
      <div className="text-center">
        <div className="my-4">
          <div className="mx-auto">
            <FaTrophy className="text-warning" size="50" />
          </div>
        </div>
        <h4 className="mb-1">Your lead has converted to PCV!</h4>
        <p className="fs-0">Now you can access to your account</p>
        <Button color="primary" className="px-5 my-3" onClick={handleGoToPCV}>
          Go to PCV now!
        </Button>
      </div>

      <ConvertPCVComponent lead={lead} />

      <div className="text-end small my-2">Converted by: <span className="text-primary fw-bold">{lead.convert_by}</span>, Date: <span className="text-primary fw-bold">{formatDate(lead.convert_date)}</span></div>
    </div>
  );
};

ConvertedPCV.propTypes = {
  lead: PropTypes.object.isRequired
}

export default ConvertedPCV;