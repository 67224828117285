import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Collapse, Accordion, Card, Col, Row, Image, Form, Table, Dropdown } from "react-bootstrap";
import logo from "./../../../../assets/img/pebsteel.png";
import Flex from "components/common/Flex";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import TinymceEditor from "components/common/TinymceEditor";

const GDS = ({
  projectData,
  gdsData,
  useHookForm,
  paintSystemData,
  newPaintSystem,
  editPaintSystem,
  deletePaintSystem,
  secondaryMemberData,
  newSecondaryMember,
  editSecondaryMember,
  deleteSecondaryMember
}) => {
  const {gds: initialKeys} = useSelector(state => state.crm_project_pif.initialKeys);
  const [activeKey, setActiveKey] = useState(initialKeys);

  const {project, building, adaptation} = projectData;
  const {control, register, errors, Controller, setValue} = useHookForm || {};

  useEffect(() => {
    setActiveKey(initialKeys);
  }, [initialKeys]);
  
  useEffect(() => {
    setValue("gds.no_of_bldgs", 1)
    setValue("gds.no_of_non_identical_bldgs", 1)
  }, [])

  useEffect(() => {
    if (gdsData) {
      Object.entries(gdsData).forEach(([key, value]) => {
        if (value && value !== null) {
          switch(key) {
            case "contract_loi_on_date":
            case "peb_standard_offer_signed_date":
            case "customer_contract_signed_date":
            case "checked_date":
              setValue(`gds.${key}`, new Date(value))
              break
  
            default:
              setValue(`gds.${key}`, value)
              break
          }
        }
      })
    }
  }, [gdsData, setValue]);
  
  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <>
      <Collapse in={true}>
        <Accordion
          id="revision-table"
          className="border rounded overflow-hidden fs--1"
          alwaysOpen
          flush
          activeKey={activeKey}
          onSelect={handleAccordionSelect}
        >
          <Card className="rounded-0">

            {/* 1. General Information */}
            <Accordion.Item eventKey="0" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      1. General Information
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="gx-0">
                    <Col sm={2}>
                      <Image
                        fluid
                        className="fit-cover rounded"
                        src={logo}
                        alt="Logo Pebsteel"
                        width="120"
                        height="120"
                      />
                    </Col>
                    <Col>
                      <Row className="mb-2">
                        <Col>
                          Quote No:
                        </Col>
                        <Col sm={9} md={7}>
                          <span className="text-primary">{project?.q_number}</span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          P/Job No:
                        </Col>
                        <Col sm={9} md={7}>
                          <span className="text-primary">{building?.j_number}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-2">
                        <Col>
                          CO#: 
                        </Col>
                        <Col sm={10} md={8}>
                          <span className="text-primary">{adaptation?.co || '00'}</span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          REV#:
                        </Col>
                        <Col sm={10} md={8}>
                          <span className="text-primary">{adaptation?.rev}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                    <Row className="mb-2">
                        <Col>
                          Date: 
                        </Col>
                        <Col sm={10} md={8}>
                          <span className="text-primary">{adaptation?.entry_date}</span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          Date:
                        </Col>
                        <Col sm={10} md={8}>
                          <span className="text-primary">{adaptation?.entry_date}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Flex justifyContent="between">
                        <Form.Check
                          type="checkbox"
                          label="For Order Entry"
                          name="for_order_entry"
                        />

                        <Form.Check
                          type="checkbox"
                          label="For Quotation"
                          name="for_quotation"
                          checked={true}
                        />
                      </Flex>
                      <h6 className="text-center my-2">This sheet is applicable to entire project</h6>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Row className="mb-2">
                        <Col>
                          Project name:
                        </Col>
                        <Col sm={10} md={8}>
                          <span className="text-primary">{project?.name}</span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          Location:
                        </Col>
                        <Col sm={10} md={8}>
                          <span className="text-primary">{building?.pj_location}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-2">
                        <Col>
                          No of all Bldgs in this Project:
                        </Col>
                        <Col sm={10} md={6}>
                          <Form.Control
                            type="number"
                            name="gds.no_of_bldgs"
                            placeholder="Number of building"
                            className="fs--1"
                            {...register("gds.no_of_bldgs", {
                              required: "No of all Bldgs in this project must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors["gds.no_of_bldgs"] && errors["gds.no_of_bldgs"].message}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          No. of Non Identical Bldgs in this Project:
                        </Col>
                        <Col sm={10} md={6}>
                          <Form.Control
                            type="number"
                            name="gds.no_of_non_identical_bldgs"
                            placeholder="No. of Non Identical Bldgs"
                            className="fs--1"
                            {...register("gds.no_of_non_identical_bldgs", {
                              required: "No. of Non Identical Bldgs in this project must be required."
                            })}
                          />
                          <span className="text-danger">
                            {errors["gds.no_of_non_identical_bldgs"] && errors["gds.no_of_non_identical_bldgs"].message}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 2. Current Contract status */}
            <Accordion.Item eventKey="1" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      2. Current Contract status
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name="gds.contract_loi"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Letter of intent issued by customer on (Date)"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={10} md={6}>
                      <Controller
                        control={control}
                        name="gds.contract_loi_on_date"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control fs--1"
                            placeholderText="DD-MMM-YYYY"
                            dateFormat="dd-MMM-yyyy"
                            fixedHeight
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name="gds.peb_standard_offer_signed"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="PEB Standard Offer signed by customer on (Date)"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={10} md={6}>
                      <Controller
                        control={control}
                        name="gds.peb_standard_offer_signed_date"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control fs--1"
                            placeholderText="DD-MMM-YYYY"
                            dateFormat="dd-MMM-yyyy"
                            fixedHeight
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name="gds.customer_contract_signed"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Customer contract signed by customer on (Date)"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={10} md={6}>
                      <Controller
                        control={control}
                        name="gds.customer_contract_signed_date"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control fs--1"
                            placeholderText="DD-MMM-YYYY"
                            dateFormat="dd-MMM-yyyy"
                            fixedHeight
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name="gds.contract_status_others"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Others (Explain)"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={10} md={6}>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="gds.contract_status_others_explain"
                        placeholder="Explain"
                        className="fs--1"
                        {...register("gds.contract_status_others_explain")}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 3. Penalty */}
            <Accordion.Item eventKey="2" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      3. Penalty
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      Terms of penalty
                    </Col>
                    <Col md={8}>
                      <Form.Control
                        as="textarea"
                        name="gds.term_of_penalty"
                        placeholder="Terms of penalty"
                        className="fs--1"
                        {...register("gds.term_of_penalty")}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2 mt-3">
                    <Col>
                      Max. Penalty as % of Contract Price:
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="gds.penalty_of_contact_price_5_percent"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="5%"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <Controller
                        control={control}
                        name="gds.penalty_of_contact_price_other"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Others (Specify)"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />

                      <Form.Control
                        as="textarea"
                        name="gds.penalty_of_contact_price_other_specify"
                        placeholder="Others (Specify)"
                        className="fs--1"
                        {...register("gds.penalty_of_contact_price_other_specify")}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 4. Shipment */}
            <Accordion.Item eventKey="3" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      4. Shipment
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      Delivery Commitment (weeks):
                    </Col>
                    <Col md={8}>
                      <Form.Control
                        type="number"
                        name="gds.shipment_delivery_commitment"
                        placeholder="Delivery Commitment (weeks)"
                        className="fs--1"
                        {...register("gds.shipment_delivery_commitment")}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Delivery by:
                    </Col>
                    <Col md={8}>
                      <Flex justifyContent="between">
                        <Controller
                          control={control}
                          name="gds.shipment_delivery_by_pebsteel"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="by PEB Steel"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />

                      <Controller
                        control={control}
                        name="gds.shipment_customer_pickup"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Customer pickup"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="gds.shipment_partial_allow"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Partial shipment allowed"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                      </Flex>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col>
                      <Row className="g-0">
                        <Col md={3}>
                          <Controller
                            control={control}
                            name="gds.overland_to"
                            className="me-3"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Overland to"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            name="gds.shipment_overland_to"
                            placeholder="Overland to"
                            className="fs--1"
                            {...register("gds.shipment_overland_to")}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="g-0">
                        <Col md={3}>
                          <Controller
                            control={control}
                            name="gds.seafreight_to"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Seafreight to"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            name="gds.shipment_seafreight_to"
                            placeholder="Seafreight to"
                            className="fs--1"
                            {...register("gds.shipment_seafreight_to")}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.shipment_seafreight"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Seafreight"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.shipment_container"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Container"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.shipment_truck"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Truck"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col>
                          Export document by:
                        </Col>
                        <Col md={8}>
                          <Row>
                            <Col>
                              <Controller
                                control={control}
                                name="gds.shipment_export_document_by_pebsteel"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    label="PEB Steel"
                                    checked={field.value}
                                    onChange={(value) => field.onChange(value)}
                                  />
                                )}
                              />
                            </Col>
                            <Col md={8}>
                              <Controller
                                control={control}
                                name="gds.shipment_export_document_by_others"
                                render={({ field }) => (
                                  <Form.Check
                                    type="checkbox"
                                    label="by others"
                                    checked={field.value}
                                    onChange={(value) => field.onChange(value)}
                                  />
                                )}
                              />

                              <Form.Control
                                as="textarea"
                                name="gds.shipment_export_document_by_others_specify"
                                placeholder="Export document by others"
                                className="fs--1"
                                {...register("gds.shipment_export_document_by_others_specify")}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 5. Required Initial Engineering Submittals */}
            <Accordion.Item eventKey="4" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      5. Required Initial Engineering Submittals
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col>
                      <Controller
                        control={control}
                        name="gds.eng_design_calculation"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Design calculation"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="gds.eng_approval_drawings"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Approval drawings"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />   

                      <Controller
                        control={control}
                        name="gds.eng_erection_drawings"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Erection drawings"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />       
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="gds.end_preliminary_design_sheet"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Preliminary Design Sheet"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="gds.eng_anchor_bolts_plan"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Anchor Bolts Plan"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />         
                    </Col>
                  </Row>             
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 6. Anchor Bolts */}
            <Accordion.Item eventKey="5" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      6. Anchor Bolts
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      Anchor Bolts by:
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.anchor_bolts_by_pebsteel"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="PEB Steel"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.anchor_bolts_by_other"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="by others"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />

                          <Form.Control
                            as="textarea"
                            name="gds.anchor_bolts_by_other_specify"
                            placeholder="Others"
                            className="fs--1"
                            {...register("gds.anchor_bolts_by_other_specify")}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <Col>Ship Anchor Bolts:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.anchor_bolts_ship_with_job"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="with Job"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.anchor_bolts_priod_to_job"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Priod to Job"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col>Ship by:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.anchor_bolts_ship_by_overland"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Overland"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.anchor_bolts_ship_by_seafreight"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Seafreight"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control
                        as="textarea"
                        name="gds.anchor_bolts_note"
                        placeholder="Note for anchor bolts"
                        className="fs--1"
                        {...register("gds.anchor_bolts_note")}
                      />
                    </Col>
                  </Row>

                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

           {/* 7. Buyer (Invoice to) */}
            <Accordion.Item eventKey="6" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      7. Buyer (Invoice to)
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col>
                      <Row className="mb-2">
                        <Col>Name:</Col>
                        <Col md={9} className="text-primary">
                          <Link to={`/crm/customers/${project?.customer?.id}`} target="_blank">{project?.customer?.name}</Link>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Phone:</Col>
                        <Col md={9} className="text-primary">{project?.contact?.mobile}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Address:</Col>
                        <Col md={9} className="text-primary">{project?.customer?.primary_address}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Contact person:</Col>
                        <Col md={9} className="text-primary">{project?.contact?.name}</Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-2">
                        <Col>Email:</Col>
                        <Col md={9} className="text-primary">{project?.contact?.email}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Fax:</Col>
                        <Col md={9} className="text-primary">{project?.contact?.fax}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Title:</Col>
                        <Col md={9} className="text-primary">{project?.contact?.position}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 8. Consignee (Ship To)  */}
            <Accordion.Item eventKey="7" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      8. Consignee (Ship To) 
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col>
                      <Row className="mb-2">
                        <Col>Name:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.consignee_name"
                            placeholder="Name"
                            className="fs--1"
                            {...register("gds.consignee_name")}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Phone:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.consignee_phone"
                            placeholder="Phone"
                            className="fs--1"
                            {...register("gds.consignee_phone")}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Address:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.consignee_address"
                            placeholder="Address"
                            className="fs--1"
                            {...register("gds.consignee_address")}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-2">
                        <Col>Mobile:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.consignee_mobile"
                            placeholder="Mobile"
                            className="fs--1"
                            {...register("gds.consignee_mobile")}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Fax:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.consignee_fax"
                            placeholder="Fax"
                            className="fs--1"
                            {...register("gds.consignee_fax")}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Email:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.consignee_email"
                            placeholder="Email"
                            className="fs--1"
                            {...register("gds.consignee_email")}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 9. Shipment (Advice To) */}
            <Accordion.Item eventKey="8" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      9. Shipment (Advice To)
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col>
                      <Row className="mb-2">
                        <Col>Name:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.shipment_advice_to_name"
                            placeholder="Name"
                            className="fs--1"
                            {...register("gds.shipment_advice_to_name")}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Fax:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.shipment_advice_to_fax"
                            placeholder="Fax"
                            className="fs--1"
                            {...register("gds.shipment_advice_to_fax")}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-2">
                        <Col>Mobile:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.shipment_advice_to_mobile"
                            placeholder="Mobile"
                            className="fs--1"
                            {...register("gds.shipment_advice_to_mobile")}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>Email:</Col>
                        <Col md={9}>
                          <Form.Control
                            name="gds.shipment_advice_to_email"
                            placeholder="Email"
                            className="fs--1"
                            {...register("gds.shipment_advice_to_email")}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 10. Contractor */}
            <Accordion.Item eventKey="9" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      10. Contractor
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col md={{span: 6}}>
                      <Row className="mb-2">
                        <Col>Name:</Col>
                        <Col md={9} className="text-primary">
                          <Link to={`/crm/customers/${project?.contractor?.id}`} target="_blank">{project?.contractor?.name}</Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      11. Consultant
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col md={{span: 6}}>
                      <Row className="mb-2">
                        <Col>Name:</Col>
                        <Col md={9} className="text-primary">
                          <Link to={`/crm/customers/${project?.consultant?.id}`} target="_blank">{project?.consultant?.name}</Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      12. Enduser
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col md={{span: 6}}>
                      <Row className="mb-2">
                        <Col>Name:</Col>
                        <Col md={9} className="text-primary">
                          <Link to={`/crm/customers/${project?.enduser?.id}`} target="_blank">{project?.enduser?.name}</Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 13. Erection */}
            <Accordion.Item eventKey="12" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      13. Erection
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      Erection by:
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.erection_by_pebsteel"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="PEB Steel"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.erection_by_others"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="by others"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col md={6} sm={8}>
                          <Row className="gx-2">
                            <Col md="auto">
                              Erector Name:
                            </Col>
                            <Col>
                              <Form.Control
                                as="textarea"
                                name="gds.erector_name"
                                placeholder="Erector Name"
                                className="fs--1"
                                {...register("gds.erector_name")}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                   <Col>
                    <Controller
                      control={control}
                      name="gds.erection_supervisor_by_peb"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Erection supervisior by PEB"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                   </Col>
                   <Col>
                      <Row>
                        <Col md="auto">
                          Explain details:
                        </Col>
                        <Col>
                          <Form.Control
                            as="textarea"
                            name="gds.erection_explain_detail"
                            placeholder="Explain details"
                            className="fs--1"
                            {...register("gds.erection_explain_detail")}
                          />
                        </Col>
                      </Row>
                   </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 14. Primary Members */}
            <Accordion.Item eventKey="13" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      14. Primary Members
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col md="auto">
                      Surface treatment:
                    </Col>
                    <Col>
                      <Flex justifyContent="between">
                        <Controller
                          control={control}
                          name="gds.primary_power_clean_st3"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Power clean ST3"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="gds.primary_shotblasting_sa_2_0"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Shotblasting SA 2.0"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="gds.primary_shotblasting_sa_2_5"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Shotblasting SA 2.5"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="gds.primary_hd_galv"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="H.D.Galv"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="gds.primary_dft"
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="DFT"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Flex>
                        <div className="me-3">Purlin Clip to Column / Rafter:</div>
                        <Controller
                            control={control}
                            name="gds.primary_purlin_clip_to_column"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Flex>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="gds.primary_memebers_note"
                        placeholder="Specify notes..."
                        className="fs--1"
                        {...register("gds.primary_memebers_note")}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 15. Paint system */}
            <Accordion.Item eventKey="14" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      15. Paint system
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={newPaintSystem}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Paint System</span>
                    </IconButton>
                  </Flex>
              
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr>
                          <th></th>
                          <th className="border">Coat No.</th>
                          <th className="border">DFT (microns)</th>
                          <th className="border">Paint Type</th>
                          <th className="border">Manufacturer</th>
                          <th className="border">Color</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paintSystemData && paintSystemData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editPaintSystem(item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deletePaintSystem(item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.cost_no}</td>
                            <td className="border">{item.dft}</td>
                            <td className="border">{item.paint_type}</td>
                            <td className="border">{item.manufacturer}</td>
                            <td className="border">{item.color}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        name="gds.paint_system_note"
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col> 
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 16. Secondary Members (Purlins, girts, eave strut) */}
            <Accordion.Item eventKey="15" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      16. Secondary Members (Purlins, girts, eave strut)
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col sm={3}>
                      <Controller
                        control={control}
                        name="gds.second_galv"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Galv."
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />       
                    </Col>
                    <Col>
                      <Row>
                        <Col md={3} className="fw-semi-bold">
                          Surface treatment:
                        </Col>
                        <Col>
                         <Flex justifyContent="between">
                          <Controller
                            control={control}
                            name="gds.second_power_clean_st3"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Power clean ST3"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="gds.second_shotblasting_sa_2_0"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Shotblasting SA 2.0"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          /> 
                          <Controller
                            control={control}
                            name="gds.second_shotblasting_sa_2_5"
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Shotblasting SA 2.5"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          /> 
                         </Flex>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={3} className="fw-semi-bold">
                          Paint system:
                        </Col>
                        <Col>
                          <Flex justifyContent="between">
                            <Controller
                              control={control}
                              name="gds.second_paint_standard"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Standard Alkyd red oxide primer"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="gds.second_paint_speical"
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="Special (see below)"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            /> 
                          </Flex>    
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  
                  <Flex justifyContent="end" className="mt-3">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={newSecondaryMember}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New</span>
                    </IconButton>
                  </Flex>
          
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr>
                          <th></th>
                          <th className="border">Coat No.</th>
                          <th className="border">DFT (microns)</th>
                          <th className="border">Paint Type</th>
                          <th className="border">Manufacturer</th>
                          <th className="border">Color</th>
                        </tr>
                      </thead>
                      <tbody>
                        {secondaryMemberData && secondaryMemberData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editSecondaryMember(item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteSecondaryMember(item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.cost_no}</td>
                            <td className="border">{item.dft}</td>
                            <td className="border">{item.paint_type}</td>
                            <td className="border">{item.manufacturer}</td>
                            <td className="border">{item.color}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        name="gds.secondary_memeber_note"
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 17. Attached */}
            <Accordion.Item eventKey="16" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      17. Attached
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="between" className="mx-4">
                    <Controller
                      control={control}
                      name="gds.attached_gds"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="GDS"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.attached_bds1"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="BDS1"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.attached_bds2"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="BDS2"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.attached_bds3"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="BDS3"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.attached_sketchs"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Sketchs"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Flex>
              </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 18. Checking */}
            <Accordion.Item eventKey="17" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      18. Checking
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col>
                      <Row className="align-items-center">
                        <Col md="auto">Filled by:</Col>
                        <Col>
                          <Form.Control
                            name="gds.filled_by"
                            placeholder="Filled by"
                            className="fs--1"
                            {...register("gds.filled_by")}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="align-items-center">
                        <Col md="auto">Checked by:</Col>
                        <Col>
                          <Form.Control
                            name="gds.checked_by"
                            placeholder="Checked by"
                            className="fs--1"
                            {...register("gds.checked_by")}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="align-items-center">
                        <Col md="auto">Date:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name="gds.checked_date"
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control fs--1"
                                placeholderText="DD-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 19. Double Side Welding - UT Test - Third Part Inspection */}
            <Accordion.Item eventKey="18" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      19. Double Side Welding - UT Test - Third Part Inspection
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col md="auto">
                      <Controller
                        control={control}
                        name="gds.double_side_welding"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Double Side Welding"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="gds.ut_test"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="UT Test"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="gds.third_party_inspection"
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Third Party Inspection"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="gds.double_side_welding_notes"
                        placeholder="Specify notes..."
                        className="fs--1"
                        {...register("gds.double_side_welding_notes")}
                      />
                    </Col>
                  </Row>

                  <Flex justifyContent="between" className="mt-3">
                    <Controller
                      control={control}
                      name="gds.engineering"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Engineering"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.sales_support_dept"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Sales Support Dept,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.sales_dept"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Sales Dept,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.customer_service_dept"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Customer Service Dept,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="gds.production"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Production,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="gds.finance"
                      render={({ field }) => (
                        <Form.Check
                          type="checkbox"
                          label="Finance,"
                          checked={field.value}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Flex>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
         
          </Card>
        </Accordion>
      </Collapse>
    </>
  )
};

GDS.propTypes = {
  projectData: PropTypes.object.isRequired,
  gdsData: PropTypes.object.isRequired,
  useHookForm: PropTypes.object.isRequired,
}

export default GDS;