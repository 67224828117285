import IconAlert from "components/common/IconAlert";
import React, { useEffect, useMemo } from "react";
import { Form, Offcanvas, Row, Col, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Flex from "components/common/Flex";
import TinymceEditor from "components/common/TinymceEditor";
import { createFaq, getCategory, updateFaq } from "redux/documentation/faqSlice";
import Select from "react-select";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

const FaqForm = ({showModal, setshowModal, title, faq}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm();
  const {categoryData} = useSelector(state => state.documentation_faq)
  const dispatch = useDispatch()

  useEffect(() => {
    if (faq) {
      Object.entries(faq).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [faq])

  useEffect(() => {
    if (categoryData && categoryData.length === 0) {
      dispatch(getCategory())
    }
  }, [])

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = (data) => {
    console.log(data)

    try {
      let faqId = ""

      if (faq) {
        faqId = faq.id
        dispatch(updateFaq({faqId, data }))
      }
      else {
        dispatch(createFaq(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const categoryOptions = useMemo(() => (
    categoryData && categoryData.map((item) => ({value: item.id, label: item.name}))
  ))
  const categoryOption = faq ? { value: faq.category_id, label: faq.category_name } : null

  return (
    <Offcanvas
      show={showModal}
      onHide={closeModal}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '50%'}}
    >
      <Offcanvas.Header className="bg-light" closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <IconAlert variant="warning" className="mt-2">
          <p className="mb-0">Frequently asked questions</p>
        </IconAlert>

        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          
          <Form.Group as={Row} className="mb-2" controlId="title">
            <Form.Label column sm={2} className="text-lg-end">
              Title
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name="title"
                placeholder="Title"
                className="fs--1"
                {...register("title", {
                  required: "Title must be required."
                })}
              />
              <span className="text-danger">
                {errors.title && errors.title.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="category_id">
            <Form.Label column sm={2} className="text-lg-end">
              Category
            </Form.Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="category_id"
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={categoryOptions}
                    defaultValue={categoryOption}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => setValue("category_id", selectedOption.value, {shouldValidate: true})}
                  />
                )}
              />
              <span className="text-danger">
                {errors.category_id && errors.category_id.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="description">
            <Form.Label column sm={2} className="text-lg-end">
              Description
            </Form.Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="description"
                rules={{ required: "Description must be required." }}
                render={({ field }) => (
                  <TinymceEditor
                    height="50vh"
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <span className="text-danger">
                {errors.description && errors.description.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col className="my-4">
              <Flex justifyContent="center">
                <Button size="sm" type="submit">Save</Button>
                <Button variant="danger" size="sm" className="ms-2" onClick={closeModal}>Cancel</Button>
              </Flex>
            </Col>
          </Form.Group>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

FaqForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  faq: PropTypes.object.isRequired
}

export default FaqForm;