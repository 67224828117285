import { Accordion, Card, Row, Col, Form, Table, Image } from "react-bootstrap"
import React, { useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CRM_CO_FORM_ISO, CRM_CO_TYPE_OF_CO } from "app_config";
import Flex from "components/common/Flex";
import { formatDate } from "helpers/utils";
import { Link } from "react-router-dom";
import logoCompany from 'assets/img/pebsteel.png';
import DocumentAttachment from "components/upload/DocumentAttachment";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { removeCODocument, uploadCODocument } from "redux/crm/project/changeOrderSlice";
import ChangeOrderUploadDocument from "./ChangeOrderUploadDocument";
import PropTypes from 'prop-types';

const ChangeOrderDetailForm = ({changeOrder}) => {
  const [activeKey, setActiveKey] = useState(Array.from({ length: 10 }, (_, index) => index.toString()).filter(key => key !== "5"))

  const {
    project,
    building
  } = changeOrder || {};

  const co_form = changeOrder?.change_order_form || {};

  const descriptionChanges = Array.isArray(co_form?.description_of_changes) ? co_form?.description_of_changes : [];

  const {
    allow_upload_co_form_document,
    allow_remove_co_form_document,
    allow_download_co_from_document
  } = changeOrder?.permissions || {};

  const projectId = project?.id;
  const buildingId = building?.id;
  const changeOrderId = changeOrder?.id;

  const dispatch = useDispatch();

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  }

  const handleUploadDocument = (data, documentType) => {
    try {
      dispatch(uploadCODocument({projectId, buildingId, changeOrderId, data}))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const removeChangeOrderFormDocument = (documentId) => {
    try {
      dispatch(removeCODocument({projectId, buildingId, changeOrderId, data: { document_id: documentId }}))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  return (
    <>
      <Accordion
        id="co-detail-form"
        className="border rounded overflow-hidden fs--1"
        alwaysOpen
        flush
        activeKey={activeKey}
        onSelect={handleAccordionSelect}
      >
        <Card className="rounded-0">
          {/* 1. General Information */}
          <Accordion.Item eventKey="0" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    1. General Information
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2 gx-2">
                    <Col sm={5}>
                      <Row className="mb-3">
                        <Col sm={4}>Job No</Col>
                        <Col className="text-primary">
                          <Link to={`/crm/projects/${project?.id}`} target="_blank">{project?.j_number}</Link>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col sm={4}>Customer</Col>
                        <Col className="text-primary">
                          <Link to={`/crm/customers/${project?.customer_id}`} target="_blank">{project?.customer_name}</Link>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col sm={4}>Type of C.O</Col>
                        <Col>
                          <Flex direction="column" className="mt-1">
                            {CRM_CO_TYPE_OF_CO.map((option, index) => (
                              <Form.Check
                                key={option}
                                id={`${option}${index}`}
                                type="radio"
                                label={option}
                                value={option}
                                className="me-3"
                                checked={co_form?.type_of_co === option}
                              />
                            ))}
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={2}>
                      <Image
                        fluid
                        className="fit-cover rounded"
                        src={logoCompany}
                        alt="Logo Pebsteel"
                        width="120"
                        height="120"
                      />
                    </Col>
                    <Col sm={5}>
                      <Form.Group as={Row} className="mb-2" controlId="co">
                        <Form.Label column sm={6} className="text-lg-start">
                          This Change Order No.
                        </Form.Label>
                        <Col className="text-primary">
                          {changeOrder?.co}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-2" controlId="entry_date">
                        <Form.Label column sm={6} className="text-lg-start">
                          Date this C.O. Entered
                        </Form.Label>
                        <Col className="text-primary">
                          {formatDate(changeOrder?.entry_date)} 
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2" controlId="continuation_sheet_attached">
                        <Form.Label column sm={6} className="text-lg-start">
                          Continuation Sheet Attached:
                        </Form.Label>
                        <Col>
                          <Flex justifyContent="between">
                            <Form.Check
                              type="checkbox"
                              checked={co_form?.continuation_sheet_attached}
                            />
                            <div>Number of sheet: <strong className="text-primary">1</strong></div>
                          </Flex>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 2. Payment for this CO */}
          <Accordion.Item eventKey="1" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    2. Payment for this CO.
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-2" controlId="payment_of_price_co">
                        <Form.Label column sm={5} className="text-lg-start">
                          Price of this C.O.
                        </Form.Label>
                        <Col className="text-primary">
                          {co_form.payment_of_price_co} 
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row className="gx-2">
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.to_be_established}
                            label="To be established"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.already_included_contract}
                            label="Already included contract"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-2" controlId="payment_of_mode">
                        <Form.Label column sm={5} className="text-lg-start">
                          Mode of Payment for this C.O.
                        </Form.Label>
                        <Col className="text-primary">
                          {co_form.payment_of_mode}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row className="gx-2">
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.amend_lc}
                            label="Amend L/C"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.revise_contract}
                            label="Revise contract value"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.payment_others}
                            label="Other (specify)"
                          />
                          {co_form.payment_others_specify && (
                            <div className="mt-0 ms-3 text-primary">{co_form.payment_others_specify}</div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-2" controlId="customer_will_pay">
                        <Form.Label column sm={5} className="text-lg-start">
                          Customer will Pay
                        </Form.Label>
                        <Col className="text-primary">
                          {co_form.customer_will_pay}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row className="gx-2">
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.fully}
                            label="Fully"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.partially}
                            label="Partially"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={co_form.no_charge}
                            label="No charge"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 3. Engg. Documents Required after this C.O. */}
          <Accordion.Item eventKey="2" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    3. Engg. Documents Required after this C.O.
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Form.Check
                        type="checkbox"
                        checked={co_form.desgin_calculation}
                        label="Desgin calculation"
                      />
                      <Form.Check
                        type="checkbox"
                        checked={co_form.approval_dwgs}
                        label="Approval dwgs"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        checked={co_form.re_approval_dwgs}
                        label="Re approval dwgs"
                      />
                      <Form.Check
                        type="checkbox"
                        checked={co_form.erection_dwgs}
                        label="Erection dwgs"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        checked={co_form.effected_sheets_of_approval_dwgs}
                        label="Effected sheets of approval dwgs"
                      />
                      <Form.Check
                        type="checkbox"
                        checked={co_form.effected_sheets_of_erection_dwgs}
                        label="Effected sheets of erection dwgs"
                      />
                      <Form.Check
                        type="checkbox"
                        checked={co_form.record_dwgs}
                        label="Record dwgs"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 4. This C.O. affects. */}
          <Accordion.Item eventKey="3" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    4. This C.O. affects.
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Form.Check
                        type="checkbox"
                        checked={co_form.co_affects_design}
                        label="Desgin"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        checked={co_form.co_affects_drafting}
                        label="Drafting"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        checked={co_form.co_affects_fabrication}
                        label="Fabrication"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 5. Sales Discount */}
          <Accordion.Item eventKey="4" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    5. Sales Discount
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Form.Group as={Row} className="mb-2" controlId="sales_discount_before">
                    <Form.Label column sm={3} className="text-lg-start">
                      Before this C.O.
                    </Form.Label>
                    <Col className="text-primary">
                      {co_form.sales_discount_before}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="sales_discount_after">
                    <Form.Label column sm={3} className="text-lg-start">
                      After this C.O
                    </Form.Label>
                    <Col className="text-primary">
                      {co_form.sales_discount_after}
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 6. Impact of Changes */}
          <Accordion.Item eventKey="5" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    6. Impact of Changes
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in STD Ex-works (US$)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in CFR (US$)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in S.B.O. (US$)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in Delivery Period (Weeks)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in Freight (US$)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in Erection (US$)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in Total Contract (US$)
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                        <Form.Check
                        label="Increase"
                        disabled
                        />
                    </Col>
                    <Col>
                        <Form.Check
                        label="Decrease"
                        disabled
                        />
                    </Col>
                    <Col>
                      in Erection Period (Weeks)
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 7. Contract Update (All Prices are in US$) */}
          <Accordion.Item eventKey="6" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    7. Contract Update (All Prices are in US$)
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Table striped>
                    <thead className="bg-200 text-900">
                      <tr>
                        <th className="border">C.O#</th>
                        <th className="border">Date</th>
                        <th className="border">Std. Ex-Works</th>
                        <th className="border">Freight</th>
                        <th className="border">CFR/CIF Job Site</th>
                        <th className="border">SBO</th>
                        <th className="border">Erection</th>
                        <th className="border">Others</th>
                        <th className="border">Total Contract</th>
                      </tr>
                    </thead>
                  </Table>

                  <Flex justifyContent="around">
                    <div className="fw-semi-bold">
                      New Delivery Period (Weeks): <span className="text-primary mx-3">{co_form.new_delivery_period_weeks}</span>
                    </div>
                    <div className="fw-semi-bold">
                      New Erection Period (Weeks): <span className="text-primary mx-3">{co_form.new_erection_period_weeks}</span>
                    </div>
                  </Flex>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 8. Description of changes */}
          <Accordion.Item eventKey="7" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    8. Description of changes
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Table striped>
                    <thead className="bg-200 text-900">
                      <tr>
                        <th className="border" width="5%">Bldg. No.</th>
                        <th className="border" width="5%">Change No.</th>
                        <th className="border">Description of changes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {descriptionChanges && descriptionChanges.map((item) => (
                        <tr key={item.id}>
                          <td className="border">{item.building_name}</td>
                          <td className="border">{item.change_no}</td>
                          <td className="border">
                            <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 9. Customer service engineer */}
          <Accordion.Item eventKey="8" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    9. Customer service engineer
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="gx-2 d-flex justify-content-around">
                    <Col sm={3} className="fw-semi-bold mt-2">Area office</Col>
                    <Col sm={3}>
                      <Form.Group as={Row} className="mb-2" controlId="cse_area">
                        <Form.Label column sm="auto" className="text-lg-end">
                          Customer Service Engineer:
                        </Form.Label>
                        <Col className="text-primary mt-1">
                          {co_form.cse_area}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group as={Row} className="mb-2" controlId="csm_area">
                        <Form.Label column sm={8} className="text-lg-end">
                          Customer Service Manager:
                        </Form.Label>
                        <Col className="text-primary mt-1">
                          {co_form.csm_area}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group as={Row} className="mb-2" controlId="area_date">
                        <Form.Label column sm="auto" className="text-lg-end">
                          Date:
                        </Form.Label>
                        <Col className="text-primary mt-1">
                          {formatDate(co_form.area_date)}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 mb-2">
                    <Col sm={3} className="fw-semi-bold mt-2">Head office</Col>
                    <Col sm={3}>
                      <Form.Group as={Row} className="mb-2" controlId="cse_head">
                        <Form.Label column sm="auto" className="text-lg-end">
                          Customer Service Engineer:
                        </Form.Label>
                        <Col className="text-primary mt-1">
                          {co_form.cse_head}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={8} className="text-lg-end">
                          Supervisor:
                        </Form.Label>
                        <Col className="text-primary mt-1">
                          {co_form.csm_head}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group as={Row} className="mb-2" controlId="head_date">
                        <Form.Label column sm="auto" className="text-lg-end">
                          Date:
                        </Form.Label>
                        <Col className="mt-1 text-primary">
                          {formatDate(co_form.head_date)} 
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

          {/* 10. Attachment */}
          <Accordion.Item eventKey="9" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    8. Attachment
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row>
                    <Col>
                      {allow_upload_co_form_document && (
                        <ChangeOrderUploadDocument
                          uploadDocument={(data) => handleUploadDocument(data, "change_order_documents")} />
                      )}
                    </Col>
                    <Col>
                      <h6 className="mt-3">Documents:</h6>
                      {changeOrder?.change_order_documents && changeOrder?.change_order_documents.map(attachment => (
                        <DocumentAttachment
                          {...attachment}
                          key={attachment.id}
                          isTable
                          //allowView={allow_view_co_form_document}
                          allowRemove={allow_remove_co_form_document}
                          allowDownload={allow_download_co_from_document}
                          handleRemoveAttachment={(documentId) => removeChangeOrderFormDocument(documentId)}
                        />
                      ))}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
          </Accordion.Item>

        </Card>
      </Accordion>

      <div className="fs--1 text-primary my-2">{CRM_CO_FORM_ISO}</div>
    </>
  )
};

ChangeOrderDetailForm.propTypes = {
  changeOrder: PropTypes.object.isRequired
}

export default ChangeOrderDetailForm;