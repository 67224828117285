const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaCalendar, FaInfoCircle } from 'react-icons/fa';
import { monthNameMapDecimal } from 'helpers/utils';
import EntryItem from '../components/EntryItem';
import PCVDetail from './PCVDetail';
import React from 'react';
import PropTypes from'prop-types';

const PCVCreatedFromLeadEntry = ({pcvData}) => {
  const {monthly, sales_regions, data} = pcvData || {};
  const COLOR = "info";

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMapDecimal[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="became-job">
        <Card.Header className="bg-light text-primary h5">
          PCV entry from Lead
        </Card.Header>
        <Card.Body>
          <p className="fs--1">PCV created from Lead monthly or sales regions for the period.</p>

          <Tabs defaultActiveKey="pcv-monthly" id="pcv-entry" transition={false}>
            <Tab eventKey="pcv-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <EntryItem data={convertToMonthNames(monthly)} color={COLOR} defaultWeight={false} />
            </Tab>
            <Tab eventKey="pcv-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <EntryItem data={sales_regions} color={COLOR} isSalesRegion={true} defaultWeight={false} />
            </Tab>
            <Tab eventKey="pcv-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <PCVDetail data={data} />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

PCVCreatedFromLeadEntry.propTypes = {
  pcvData: PropTypes.object.isRequired
}

export default PCVCreatedFromLeadEntry;