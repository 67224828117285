import { configureStore, combineReducers } from "@reduxjs/toolkit"
import authReducer from "./authSlice"
import crmSalesUnitReducer from './crm/salesUnitSlice'
import organizationEmployeeReducer from "./organization/employeeSlice"
import organizationDepartmentReducer from "./organization/departmentSlice"
import organizationSiteReducer from "./organization/siteSlice"
import organizationPositionGroupReducer from "./organization/positionGroupSlice"
import crmContactReducer from './crm/contactSlice'
import crmCustomerReducer from './crm/customerSlice'
import crmAddressReducer from './crm/addressSlice'
import crmClassifyCustomerReducer from './crm/classifyCustomerSlice'
import notificationReducer from './notificationSlice'
import authorizationUserReducer from './authorization/userSlice'
import authorizationPermissionReducer from './authorization/permissionSlice'
import authorizationRoleReducer from './authorization/roleSlice'
import bookingSettingCarReducer from './booking/car/carSlice'
import bookingDriverReducer from './booking/car/driverSlice'
import bookingCarReducer from "./booking/car/carBookingSlice"
import bookingSettingExpenseReducer from "./booking/travel/setting/expenseSlice"
import bookingSettingApprovalFlowReducer from "./booking/travel/setting/approvalFlowSlice"
import bookingTravelReducer from './booking/travel/travelBookingSlice'
import profileUserReducer from './profileUserSlice'
import crmProjectReducer from './crm/projectSlice'
import crmCompetitorReducer from './crm/competiorSlice'
import crmSettingReducer from './crm/settingSlice'
import crmProjectQuotationReducer from './crm/project/quotationSlice'
import crmProjectPifReducer from './crm/project/pifSlice'
import crmWaiverReducer from './crm/project/waiverSlice'
import crmProjectEnterJobReducer from './crm/project/enterJobSlice'
import crmProjectJobBuildingReducer from "./crm/project/jobBuildingSlice"
import crmReportContractReducer from "./crm/report/contractSlice"
import crmReportAddendumReducer from "./crm/report/addendumSlice"
import crmProjectExecutionReducer from "./crm/project/executionSlice"
import crmChangeOrderReducer from "./crm/project/changeOrderSlice"
import crmReportIcccReducer from "./crm/report/icccSlice"
import crmReportMasterScheduleReducer from "./crm/report/masterScheduleSlice"
import crmReportProjectScheduleReducer from "./crm/report/projectScheduleSlice"
import crmReportProjectBomShopReducer from "./crm/report/projectBomShopSlice"
import crmReportEngineeringJobProgressReducer from "./crm/report/engineeringJobProgressSlice"
import crmReportEstimateAssignmentScheduleReducer from "./crm/report/estimateAssignmentScheduleSlice"
import crmReportQuotationReducer from "./crm/report/quotationSlice"
import crmReportPifReducer from "./crm/report/pifSlice"
import crmReportWaiverReducer from "./crm/report/waiverSlice"
import crmReportChangeOrderReducer from "./crm/report/changeOrderSlice"
import crmLeadReducer from "./crm/leadSlice"
import documentationFaqReducer from "./documentation/faqSlice"
import documentationChangeLogReducer from "./documentation/changeLogSlice"
import settingsReducer from "./settingsSlice"
import crmReportJobEntryReducer from "./crm/report/jobEntrySlice"
import crmReportPcvReducer from "./crm/report/pcvSlice"
import crmReportDashboardReducer from "./crm/report/crmDasboardSlice"
import integrationApplicationReducer from "./integration/applicationSlice"
import driveReducer from "./drive/driveSlice"
import socialFeedReducer from "./social/feedSlice"
import crmProjectWarrantyReducer from './crm/project/warrantySlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
//import storage from "redux-persist/lib/storage";
import localforage from 'localforage';

const persistConfig = {
  key: "root",
  version: 1,
  storage: localforage,
};

const rootReducer = combineReducers({ 
  auth: authReducer,
  profile_user: profileUserReducer,
  notification: notificationReducer,
  settings: settingsReducer,
  integration_application: integrationApplicationReducer,
  drive: driveReducer,
  social_feed: socialFeedReducer,

  organization_employee: organizationEmployeeReducer,
  organization_department: organizationDepartmentReducer,
  organization_site: organizationSiteReducer,
  organization_position_group: organizationPositionGroupReducer,
  
  booking_setting_car: bookingSettingCarReducer,
  booking_setting_driver: bookingDriverReducer,
  booking_setting_expense: bookingSettingExpenseReducer,
  booking_setting_approval_flow: bookingSettingApprovalFlowReducer,
  booking_car: bookingCarReducer,
  booking_travel: bookingTravelReducer,

  crm_setting: crmSettingReducer,
  crm_competitor: crmCompetitorReducer,
  crm_sales_unit: crmSalesUnitReducer,
  crm_contact: crmContactReducer,
  crm_customer: crmCustomerReducer,
  crm_address: crmAddressReducer,
  crm_classify_customer: crmClassifyCustomerReducer,
  crm_lead: crmLeadReducer,
  crm_project: crmProjectReducer,
  crm_waiver: crmWaiverReducer,
  crm_project_quotation: crmProjectQuotationReducer,
  crm_project_enter_job: crmProjectEnterJobReducer,
  crm_project_job_building: crmProjectJobBuildingReducer,
  crm_project_execution: crmProjectExecutionReducer,
  crm_project_pif: crmProjectPifReducer,
  crm_change_order: crmChangeOrderReducer,
  crm_warranty: crmProjectWarrantyReducer,
 
  crm_report_contract: crmReportContractReducer,
  crm_report_addendum: crmReportAddendumReducer,
  crm_report_iccc: crmReportIcccReducer,
  crm_report_master_schedule: crmReportMasterScheduleReducer,
  crm_report_project_schedule: crmReportProjectScheduleReducer,
  crm_report_project_bom_shop: crmReportProjectBomShopReducer,
  crm_report_engineering_job_progress: crmReportEngineeringJobProgressReducer,
  crm_report_estimate_assignment_schedule: crmReportEstimateAssignmentScheduleReducer,
  crm_report_quotation: crmReportQuotationReducer,
  crm_report_pif: crmReportPifReducer,
  crm_report_waiver: crmReportWaiverReducer,
  crm_report_change_order: crmReportChangeOrderReducer,
  crm_report_job_entry: crmReportJobEntryReducer,
  crm_report_pcv: crmReportPcvReducer,
  crm_report_dashboard: crmReportDashboardReducer,

  authorization_user: authorizationUserReducer,
  authorization_permission: authorizationPermissionReducer,
  authorization_role: authorizationRoleReducer,
  documentation_faq: documentationFaqReducer,
  documentation_change_log: documentationChangeLogReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store)