import IconButton from 'components/common/IconButton';
import React, { useEffect } from "react"
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createApplication, updateApplication } from 'redux/integration/applicationSlice';
import PropTypes from "prop-types";

const ApplicationForm = ({application, title}) => {
  const {register, handleSubmit, formState: {errors}, setValue } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(application) {
      Object.entries(application).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, application]);

  const onSubmit = async (data) => {
    console.log(data);

    try {
      let applicationId = null

      if (application) {
        applicationId = application.id
        dispatch(updateApplication({applicationId, data }))
      }
      else {
        const response = await dispatch(createApplication(data))

        if (response.payload && response.payload?.data?.id) {
          applicationId = response.payload.data.id;
        } else {
          toast.error(response.payload.error)
        }
      }

      if (applicationId) {
        navigate(`/integration/applications/${applicationId}`)
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <Card className="fs--1">
      <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <Card.Title>{title}</Card.Title>
          <IconButton
            onClick={handleBack}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
        </Card.Header>
        <Card.Body className="fs--1">
          <div className="mb-2 text-primary">Application for authorization to integrate with another system.</div>

          <Form.Group as={Row} className="mb-2 mt-4" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Application name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="name"
                placeholder="Application name"
                className="fs--1"
                {...register("name", {
                  required: "Application name must be required."
                })}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="redirect_uri">
            <Form.Label column sm={3} className="text-lg-end">
              Redirect url
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="redirect_uri"
                placeholder="Redirect url"
                className="fs--1"
                {...register("redirect_uri")}
              />
              <span className="text-danger">
                {errors.redirect_uri && errors.redirect_uri.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="scopes">
            <Form.Label column sm={3} className="text-lg-end">
              Scopes
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="scopes"
                placeholder="Scopes"
                className="fs--1"
                {...register("scopes")}
              />
              <div className="text-primary my-1 text-end">
                Optional scopes: write, update, public (default).
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2 mt-3" controlId="confidential">
            <Form.Label column sm={3} className="text-lg-end"></Form.Label>
            <Col sm={9} md={7}>
              <Form.Check
                name="confidential"
                //value={status}
                label="Confidential"
                //defaultChecked={status === 'Private'}
                className="fs--1"
                {...register("confidential")}
              />
            </Col>
          </Form.Group>

          <div className="bg-light fixed-bottom border-0 text-center p-3">
            <Button size="sm" type="submit" className="me-2">{application ? "Update" : "Create"} Application</Button>
            <Button size="sm" variant="danger" onClick={handleBack}>Cancel</Button>
          </div>
      </Card.Body>
    </Form>
  </Card>
  );
};

ApplicationForm.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
};

export default ApplicationForm;


