import { Col, Row } from 'react-bootstrap';
import ProfileBanner from './ProfileBanner';
import ProfileSetting from './ProfileSetting';
import ChangePassword from './ChangePassword';
import ActivityLog from './ActivityLog';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { getProfileUser } from 'redux/profileUserSlice';

const Profile = () => {
  const {data: user} = useSelector(state => state.profile_user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileUser());
  }, [dispatch]);

  return (
    <>
      <ProfileBanner />

      <Row className="g-3">
        <Col lg={8}>
          <ProfileSetting user={user} />
          <ActivityLog />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <ChangePassword user={user} />
            {/* <Events
              className="mb-3"
              cardTitle="Events"
              events={events.slice(2, 5)}
            /> */}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Profile