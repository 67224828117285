import React from 'react';
import { Link } from 'react-router-dom';

const ProjectLinkRenderer = (props) => {
  const {project} = props.data
  return (
    <Link to={`/crm/projects/${project.id}`}>{project.name}</Link>
  );
};

export default ProjectLinkRenderer;
