import React, { useEffect, useRef, useState } from "react"
import OrganizationChart from "@dabeng/react-orgchart"
import { Button, Row, Col } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FaDownload } from "react-icons/fa";
//import { useSelector } from "react-redux";
import apiAxios from "helpers/apiAxios";

const EmployeeOrganization = () => {
  //const {data: siteData} = useSelector(state => state.organization_site.allData);
  //const {empChartDataSnk, empChartDataAko} = useSelector(state => state.organization_employee);
  const orgchart = useRef();
  const [siteId] = useState([]);
  const [fileExtension, setfileExtension] = useState("png");

  // useEffect(() => {
  //   dispatch(getOrganizationEmployeeChart());
  // }, []);

  const [dataSami, setDataSami] = useState({})
  const [dataAdib, setDataAdib] = useState({})

  useEffect(() => {
    getDataChart()
  }, [siteId]);

  const getDataChart = async () => {
    try {
      const response = await apiAxios.get(`/organization/employees/emp_organization_chart?site_ids=${siteId}`)
      setDataSami(response.data[0])
      setDataAdib(response.data[1])
    }
    catch(error) {
      throw new Error(error.message)
    }
  }

  const handleExport = () => {
    orgchart.current.exportTo("employees-organization-chart", fileExtension);
  };
  
  const onExtensionChange = event => {
    setfileExtension(event.target.value);
  };

  // const handleChangeSite = (selectedOptions) => {
  //   const array = selectedOptions && selectedOptions.map(item => item.value)
  //   console.log(array);
  //   setSiteId(array)
  // };

  // const siteOptions = siteData && siteData.map((item) => ({ value: item.id, label: item.name }));

  return (
    <>
      <Row className="gx-0">
        <Col className="text-start">
          {/* <Form.Group as={Row} className="mb-2 fs--1" controlId="site_ids">
            <Form.Label column sm={3} className="text-lg-end">
              Site
            </Form.Label>
            <Col sm={9} md={7}>
              <Select
                closeMenuOnSelect={false}
                options={siteOptions}
                placeholder='Select...'
                classNamePrefix="react-select"
                isMulti
                onChange={handleChangeSite}
              />
            </Col>
          </Form.Group> */}
        </Col>
        <Col className="text-end">
          <Flex>
            <span>File extension: </span>
            <div className="mx-1">
              <input
                id="rd-png"
                type="radio"
                value="png"
                className="mx-1"
                checked={fileExtension === "png"}
                onChange={onExtensionChange}
              />
              <label htmlFor="rd-png">png</label>
            </div>
            <div className="mx-1">
              <input
                id="rd-pdf"
                type="radio"
                value="pdf"
                className="mx-1"
                checked={fileExtension === "pdf"}
                onChange={onExtensionChange}
              />
              <label htmlFor="rd-pdf">pdf</label>
            </div>
            <Button variant="primary" size={"sm"} className="ms-3" onClick={handleExport}>
              <FaDownload />
            </Button>
          </Flex>
        </Col>
      </Row>

      <OrganizationChart
        className="pebChart"
        ref={orgchart}
        datasource={dataSami}
        draggable={true}
        pan={true}
      />

      <OrganizationChart
        className="pebChart"
        ref={orgchart}
        datasource={dataAdib}
        draggable={true}
        pan={true}
      />
    </>
  )
}

export default EmployeeOrganization
