import React, { useState } from 'react';
import HotelForm from './HotelForm';
import StarterHotel from './StarterHotel';
import HotelList from './HotelList';
import PropTypes from 'prop-types';

const Hotel = ({booking}) => {
  const [showModalHotel, setShowModalHotel] = useState(false)
  const {hotels} = booking

  return (
    <>
      {hotels && hotels.length > 0 ? (
        <HotelList booking={booking} setShowModalHotel={setShowModalHotel} />
      ) : (
        <StarterHotel setShowModalHotel={setShowModalHotel}/>
      )}
      
      <HotelForm 
        showModal={showModalHotel}
        setshowModal={setShowModalHotel}
        booking={booking}
      />
    </>
  )
};

Hotel.propTypes = {
  booking: PropTypes.object.isRequired
}

export default Hotel;