import React, { useCallback, useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { createApprovalFlow, updateApprovalFlow } from "redux/booking/travel/setting/approvalFlowSlice";
import { FaBan, FaSave } from "react-icons/fa";
import Select from 'react-select';
import { getActiveSites } from "redux/organization/siteSlice";
import { useSelector } from "react-redux";
import { activeEmployeesByNameDebounce } from "helpers/employeeOptionHelper";
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

const ApprovalFlowForm = ({showModal, setshowModal, title, record}) => {
  const {control, handleSubmit, formState: {errors}, setValue } = useForm();
  const dispatch = useDispatch();
  const {activeData: siteData} = useSelector(state => state.organization_site);

  console.log(record)

  useEffect(() => {
    if (siteData?.length === 0) {
      dispatch(getActiveSites());
    }
  }, []);

  useEffect(() => {
    if(record) {
      Object.entries(record).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, record]);

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (record) {
        const approvalId = record.id
        dispatch(updateApprovalFlow({approvalId, data }))
      }
      else {
        dispatch(createApprovalFlow(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const sitesOptions = siteData && siteData.map((item) => ({ value: item.id, label: item.name }));

  const defaultSiteOption = useMemo(() => (
    record ? { value: record?.site_id, label: record?.site_name } : null
  ), [record]);

  const empOptions = (inputValue, callback) => {
    activeEmployeesByNameDebounce(inputValue, callback);
  };

  const useDefaultOption = (record, key) => {
    return useMemo(() => {
      if (record && record[key]) {
        return { value: record[key], label: record[key] };
      }
      return null;
    }, [record, key]);
  };

  const useDefaultOptions = (record, key) => {
    return useMemo(() => {
      if (record && Array.isArray(record[key])) {
        return record[key].map((option) => ({ value: option, label: option }));
      }
      return null;
    }, [record, key]);
  };

  const useChangeOptions = (fieldName) => {
    return useCallback((selectedOptions) => {
      const selectedOptionsValues = selectedOptions.map((option) => option.value);
      setValue(fieldName, selectedOptionsValues, { shouldValidate: true });
    }, [fieldName]);
  };

  const defaultHRAdminOption = useDefaultOptions(record, 'hr_admins_site_office_confirm');
  const defaultFNCCheckOption = useDefaultOptions(record, 'accountants_confirm');
  const defaultHRheadOfficeCheckOption = useDefaultOptions(record, 'hr_admins_head_office_confirm');

  const defaultChiefRepOption = useDefaultOption(record, 'chief_rep_gm_approval');
  const defaultHRManagerAppovalOption = useDefaultOption(record, 'hr_manager_approval');
  const defaultGDApprovalOption = useDefaultOption(record, 'gd_approval');

  const changeHRAdminOptions = useChangeOptions('hr_admins_site_office_confirm');
  const changeFNCCheckOptions = useChangeOptions('accountants_confirm');
  const changeHRAdminHeadOfficeOptions = useChangeOptions('hr_admins_head_office_confirm');

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-2" controlId="site_id">
              <Form.Label column sm={4} className="text-lg-end">
                Site
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name='site_id'
                  rules={{required: "Site is required"}}
                  render={() => (
                    <Select
                      closeMenuOnSelect={true}
                      options={sitesOptions}
                      defaultValue={defaultSiteOption}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      onChange={selectedOption => setValue('site_id', selectedOption.value, {shouldValidate: true})}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.site_id && errors.site_id.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="hr_admins_site_office_confirm">
              <Form.Label column sm={4} className="text-lg-end">
                HR admin
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name="hr_admins_site_office_confirm"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultHRAdminOption}
                      loadOptions={empOptions}
                      closeMenuOnSelect={true}
                      placeholder='HR admin'
                      classNamePrefix="react-select"
                      className="w-100"
                      isMulti
                      onChange={changeHRAdminOptions}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'HR admin is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.hr_admins_site_office_confirm && errors.hr_admins_site_office_confirm.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="chief_rep_gm_approval">
              <Form.Label column sm={4} className="text-lg-end">
                Chief Rep/GM
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name="chief_rep_gm_approval"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultChiefRepOption}
                      loadOptions={empOptions}
                      closeMenuOnSelect={true}
                      placeholder='Chief Rep/GM'
                      classNamePrefix="react-select"
                      className="w-100"
                      onChange={selectedOption => setValue('chief_rep_gm_approval', selectedOption.value)}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Chief Rep/GM is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.chief_rep_gm_approval && errors.chief_rep_gm_approval.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="accountants_confirm">
              <Form.Label column sm={4} className="text-lg-end">
                FNC Check
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name="accountants_confirm"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultFNCCheckOption}
                      loadOptions={empOptions}
                      closeMenuOnSelect={true}
                      placeholder='FNC Check'
                      classNamePrefix="react-select"
                      className="w-100"
                      isMulti
                      onChange={changeHRAdminHeadOfficeOptions}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'FNC check is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.accountants_confirm && errors.accountants_confirm.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="hr_admins_head_office_confirm">
              <Form.Label column sm={4} className="text-lg-end">
                HR Admin head office
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name="hr_admins_head_office_confirm"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultHRheadOfficeCheckOption}
                      loadOptions={empOptions}
                      closeMenuOnSelect={true}
                      placeholder='HR Admin head office'
                      classNamePrefix="react-select"
                      className="w-100"
                      isMulti
                      onChange={changeFNCCheckOptions}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'HR Admin head office is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.hr_admins_head_office_confirm && errors.hr_admins_head_office_confirm.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="hr_manager_approval">
              <Form.Label column sm={4} className="text-lg-end">
                HR manager head office
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name="hr_manager_approval"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultHRManagerAppovalOption}
                      loadOptions={empOptions}
                      closeMenuOnSelect={true}
                      placeholder='HR manager head office'
                      classNamePrefix="react-select"
                      className="w-100"
                      onChange={selectedOption => setValue('hr_manager_approval', selectedOption.value)}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'HR manager head office is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.hr_manager_approval && errors.hr_manager_approval.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="gd_approval">
              <Form.Label column sm={4} className="text-lg-end">
                General Director approval
              </Form.Label>
              <Col sm={8} md={7}>
                <Controller
                  control={control}
                  name="gd_approval"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultGDApprovalOption}
                      loadOptions={empOptions}
                      closeMenuOnSelect={true}
                      placeholder='General Director approval'
                      classNamePrefix="react-select"
                      className="w-100"
                      onChange={selectedOption => setValue('gd_approval', selectedOption.value)}
                    />
                  )}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: 'General Director approval is required'
                  //   }
                  // }}
                />
                <span className="text-danger">
                  {errors.gd_approval && errors.gd_approval.message}
                </span>
              </Col>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><FaSave /> Save</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
};

ApprovalFlowForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
}

export default ApprovalFlowForm;