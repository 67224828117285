import React from 'react';
import { Button, Card, Form} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdOutlinePassword } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changePasswordUser } from 'redux/authorization/userSlice';
import PropTypes from 'prop-types';

const ChangePassword = ({user}) => {
  const {register, handleSubmit, formState: {errors}, watch } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newPassword = watch("password", "");

  const onSubmit = async (data) => {
    //console.log(data)

    try {
      const response = await dispatch(changePasswordUser({userId: user.id, data}))
      const {status, message} = response.payload

      if (status === 200) {
        toast.success(message)

        //navigate to logout page
        navigate("/authentication/logout")
      }
      else {
        toast.error(message)
      }
    }
    catch(error) {
      toast.error(error.message)
      return;
    }
  }

  const validateConfirmPassword = (value) => {
    return value === newPassword || "Confirm Password does not match New Password";
  }

  return (
    <Card className="mb-3">
      <Card.Header className="h5">
        <MdOutlinePassword className="text-primary" /> Change Password
      </Card.Header>

      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          <Form.Group className="mb-3" controlId="current_password">
            <Form.Label className="text-lg-end">
              Old Password
            </Form.Label>
            <Form.Control
              name="current_password"
              type="password"
              placeholder="Old Password"
              className="fs--1"
              {...register("current_password", {
                required: "Old Password must be required."
              })}
            />
            <span className="text-danger">
              {errors.current_password && errors.current_password.message}
            </span>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label className="text-lg-end">
              New Password
            </Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="New Password"
              className="fs--1"
              {...register("password", {
                required: "New Password must be required."
              })}
            />
            <span className="text-danger">
              {errors.password && errors.password.message}
            </span>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password_confirmation">
            <Form.Label className="text-lg-end">
              Confirm Password
            </Form.Label>
            <Form.Control
              name="password_confirmation"
              type="password"
              placeholder="Confirm Password"
              className="fs--1"
              {...register("password_confirmation", {
                required: "Confirm Password must be required.",
                validate: validateConfirmPassword
              })}
            />
            <span className="text-danger">
              {errors.password_confirmation && errors.password_confirmation.message}
            </span>
          </Form.Group>

          <Button size="sm" type="submit" className="w-100 mt-3">Update Password</Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

ChangePassword.propTypes = {
  user: PropTypes.object.isRequired
}

export default ChangePassword;
