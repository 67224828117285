import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from "moment";
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { MdDetails, MdOutlineSummarize } from 'react-icons/md';
import HeaderReport from '../reports/HeaderReport';
import { getDashboard } from 'redux/crm/leadSlice';
import LeadTable from '../reports/lead/LeadTable';
import LeadSummary from '../reports/lead/LeadSummary';
import LeadOverview from '../reports/lead/LeadOverview';

const LeadDashboard = () => {
  const {dashboardData} = useSelector(state => state.crm_lead);
  
  const currentDate = moment();
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(currentDate);
  const [salesUnit, setSalesUnit] = useState([]);

  const dispatch = useDispatch()

  let params = {
    created_at_gteq: startDate.format('YYYY-MM-DD'),
    created_at_lteq: endDate.format('YYYY-MM-DD')
  };

  useEffect(() => {
    if (salesUnit) {
      params = {...params, sales_unit_id_in: salesUnit};
    }

    dispatch(getDashboard(params))
  }, [startDate, endDate, salesUnit])

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const {current_period_data} = dashboardData || {};

  const handleChangeSalesUnit = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setSalesUnit(selectedOptionsValues);
  }
 
  return (
    <>
      <HeaderReport
        title="Lead"
        subtitle="Dasboard"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDatesChange={handleDatesChange}
        onChangeSalesUnit={handleChangeSalesUnit}
      />

      <LeadSummary data={dashboardData} />

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="pcv-overview">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="pcv-overview"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdOutlineSummarize className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Overview</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="pcv-detail"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdDetails className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="pcv-overview">
                    <LeadOverview data={current_period_data} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="pcv-detail">
                    <LeadTable data={current_period_data} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default LeadDashboard;