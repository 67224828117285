import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { Tabs, Tab, DropdownButton, ButtonGroup, Dropdown, Row, Col, Form, Badge } from "react-bootstrap";
import { FaCheck, FaBan, FaUser, FaCalendar } from "react-icons/fa";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {removeAPDDocument, showOnMaster} from "redux/crm/project/changeOrderSlice";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { FaCalendarCheck, FaShareSquare, FaVoteYea } from "react-icons/fa";
import { formatDate, removeUnderlineString } from "helpers/utils";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import PropTypes from 'prop-types';
import APDDetailReleaseToSales from "./apd/APDDetailReleaseToSales";

const LazyAPDForm = lazy(() => import("./apd/APDForm"));
const LazyAPDRequestToENG = lazy(() => import("./apd/APDRequestToENG"));
const LazyAPDScheduleToCSD = lazy(() => import("./apd/APDScheduleToCSD"));
const LazyAPDReleaseToCSD = lazy(() => import("./apd/APDReleaseToCSD"));
const LazyAPDConfirmToSales = lazy(() => import("./apd/APDConfirmToSales"));
const LazyAPDRejectToENG = lazy(() => import("./apd/APDRejectToENG"));
const LazyAPDDeleteConfirm = lazy(() => import("./apd/APDDeleteConfirm"));

const ApprovalDrawing = ({changeOrder}) => {
  const [showAPDForm, setShowAPDForm] = useState(false)
  const [title, setTitle] = useState(null)
  const [part, setPart] = useState(null)

  const [showRequestAPD, setShowRequestAPD] = useState(false)
  const [showScheduleAPD, setShowScheduleAPD] = useState(false)
  const [showReleaseAPD, setShowReleaseAPD] = useState(false)
  const [showConfirmAPD, setShowConfirmAPD] = useState(false)
  const [showReleaseAPDDetail, setShowReleaseAPDDetail] = useState(false)
  const [showRejectAPD, setShowRejectAPD] = useState(false)
  const [showDeleteAPD, setShowDeleteAPD] = useState(false)
  const [apdRecord, setApdRecord] = useState(null);

  const {
    allow_create_apd,
    allow_show_master,
    allow_apd_confirm,
    allow_apd_reject
  } = changeOrder?.permissions || {};

  const parts = changeOrder?.parts || []
  const dispatch = useDispatch();

  const {apd, ab_apd} = changeOrder?.confirmation || {}
  const {project, building} = changeOrder || {};
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(building?.apd_co_lastest_id === changeOrder?.id)
  }, [building, dispatch])

  const handleNewAPD = () => {
    setTitle("New APD/AB drawings")
    setPart(null)
    setShowAPDForm(true)
  }

  const handleEditAPD = (part) => {
    setPart(part)
    setTitle("Edit APD/AB drawings")
    setShowAPDForm(true)
  }

  const handleDeleteAPD = (part) => {
    setPart(part)
    setShowDeleteAPD(true)
  }

  const handleRequestAPD = (part) => {
    setPart(part)
    setShowRequestAPD(true)
  }

  const handleScheduleAPD = (part) => {
    setPart(part)
    setShowScheduleAPD(true)
  }

  const handleReleaseAPD = (part) => {
    setPart(part)
    setShowReleaseAPD(true)
  }

  const handleConfirmToSales = () => {
    setShowConfirmAPD(true)
  }

  const handleRejectToENG = () => {
    setShowRejectAPD(true)
  }

  const handleReleaseDetailToSales = (record) => {
    setShowReleaseAPDDetail(true)
    setApdRecord(record)
  }

  const columns = useMemo(() => [
    {
      Header: ' ',
      Cell: rowData => {
        const part = rowData.row.original

        const {
          allow_edit,
          allow_delete,
          allow_request_to_eng,
          allow_schedule_to_csd,
          allow_release_to_csd,
        } = part?.permissions || {};

        return (
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
      
                {allow_request_to_eng && (
                  <>
                    <Dropdown.Item onClick={() => handleRequestAPD(part)} className="mb-1">
                      <FaShareSquare /> Request to ENG
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}

                {allow_schedule_to_csd && (
                  <Dropdown.Item onClick={() => handleScheduleAPD(part)} className="mb-1 text-primary">
                    <FaCalendarCheck/> Schedule to CSD
                  </Dropdown.Item>
                )}

                {allow_release_to_csd && (
                  <Dropdown.Item onClick={() => handleReleaseAPD(part)} className="mb-1 text-primary">
                    <FaVoteYea /> Release to CSD
                  </Dropdown.Item>
                )}

                {allow_apd_confirm && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-1" onClick={() => handleReleaseDetailToSales(part)}>
                      <FaCheck className="text-primary"/> Release to Sales
                    </Dropdown.Item>
                  </>
                )}

                {allow_edit && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleEditAPD(part)} className="mb-1">
                      <FontAwesomeIcon icon="edit" /> Edit
                    </Dropdown.Item>
                  </>
                )}

                {allow_delete && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-1 text-danger" onClick={() => handleDeleteAPD(part)}>
                      <FontAwesomeIcon icon="trash-alt" /> Delete
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )
      },
      cellProps: {
        className: "fixed-column"
      }
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: "APD Drawing",
      columns: [
        {
          Header: 'Requested',
          accessor: 'apd_requested',
          Cell: rowData => {
            const { apd_requested } = rowData.row.original
            return (
              formatDate(apd_requested)
            )
          }
        },
        {
          Header: 'Schedule',
          accessor: 'apd_scheduled',
          Cell: rowData => {
            const { apd_scheduled } = rowData.row.original
            return (
              formatDate(apd_scheduled)
            )
          }
        },
        {
          Header: 'Release',
          accessor: 'apd_released',
          Cell: rowData => {
            const { apd_released } = rowData.row.original
            return (
              formatDate(apd_released)
            )
          }
        },
        {
          accessor: 'dsn_incharge',
          Header: 'Detailer',
          cellProps:{
            className:'text-center'
          }
        },
        {
          accessor: 'des_incharge',
          Header: 'Checker',
          cellProps:{
            className:'text-center'
          }
        },
      ],
      cellProps: {
        className: "text-center"
      }
    },
    {
      Header: "A/B for Approval Drawing",
      columns: [
        {
          accessor: 'ab_erd_requested',
          Header: 'Requested',
          Cell: rowData => {
            const { ab_erd_requested } = rowData.row.original
            return (
              formatDate(ab_erd_requested)
            )
          }
        },
        {
          accessor: 'ab_erd_scheduled',
          Header: 'Schedule',
          Cell: rowData => {
            const { ab_erd_scheduled } = rowData.row.original
            return (
              formatDate(ab_erd_scheduled)
            )
          }
        },
        {
          accessor: 'ab_erd_released',
          Header: 'Release',
          Cell: rowData => {
            const { ab_erd_released } = rowData.row.original
            return (
              formatDate(ab_erd_released)
            )
          }
        },
        {
          accessor: 'dss_incharge',
          Header: 'Design Supporter',
          cellProps:{
            className:'text-center'
          }
        }
      ],
      cellProps: {
        className: "text-center"
      }
    },
    {
      Header: " ",
      columns: [
        {
          accessor: '',
          Header: 'Attachments',
          Cell: rowData => {
            const part = rowData.row.original
    
            const {
              allow_view_document,
              allow_remove_document,
              allow_download_document,
            } = part?.permissions || {}
    
            return (
             <>
              {part.documents && (
                <div className="pb-2 mt-3">
                  {part.documents.map(attachment => (
                    <DocumentAttachment
                      {...attachment}
                      key={attachment.id}
                      isTable
                      allowView={allow_view_document}
                      allowDownload={allow_download_document}
                      allowRemove={allow_remove_document}
                      handleRemoveAttachment={() => handleRemoveDocument(part.id, attachment.id)}
                    />
                  ))}
                </div>
              )} 
             </>
            )
          }
        },
        {
          accessor: 'eng_notes',
          Header: 'ENG note',
          cellProps: {
            className: "text-danger"
          }
        },
        {
          accessor: 'created_by',
          Header: 'Created by',
          cellProps: {
            className: "text-center"
          }
        },
        {
          accessor: 'created_date',
          Header: 'Created date',
        },
      ]
    }
  ], [])

  const handleRemoveDocument = (partId, documentId) => {
    try {
      const {project, building} = changeOrder || {};

      dispatch(removeAPDDocument({
        projectId: project?.id,
        buildingId: building?.id, 
        changeOrderId: changeOrder?.id,
        partId,
        data: { document_id: documentId }
      }))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleShowAPDToMasterSchedule = (e) => {
    const checked = e.target.checked
    setIsChecked(checked);

    const data = {
      type: "apd",
      checked: checked,
      weight: null,
      latest_co_id: changeOrder.id
    }

    dispatch(showOnMaster({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data
    }))
  }

  const bgConfirmStatus = (status_name) => {
    const bg = status_name === "reject_and_waiting_adjust_drawing" ? "danger" : "primary"

    return <Badge pill bg={bg}>{removeUnderlineString(status_name)}</Badge>
  }

  return (
    <>
      <Flex justifyContent="between" className="mb-2">
        <div className="text-primary">
          {allow_show_master && (
            <Form.Check
              label="Show in Master Schedule report"
              onChange={(e) => handleShowAPDToMasterSchedule(e)}
              checked={isChecked}
            />
          )}
        </div>
        <div>
          <DropdownButton variant="falcon-default" as={ButtonGroup} title="Action" id="bg-nested-dropdown" size="sm">
            <div className="py-1">
              {allow_apd_confirm && (
                <Dropdown.Item className="mb-1" onClick={handleConfirmToSales}>
                  <FaCheck className="text-primary"/> CSD release to Sales
                </Dropdown.Item>
              )}

              {allow_apd_reject && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item className="mb-2" onClick={handleRejectToENG}>
                    <FaBan className="text-danger" /> CSD reject to ENG
                  </Dropdown.Item>
                </>
              )}
            </div>
          </DropdownButton>
        </div>
      </Flex>
      
      <Tabs defaultActiveKey="apd-overview" id="change-order-detail" transition={false}>
        <Tab eventKey="apd-overview" title="Overview" className='border-bottom border-x p-3'>
          {allow_create_apd && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewAPD}
            >
              <span className="d-none d-xl-inline-block ms-1">New APD</span>
            </IconButton>
          )}

          <AdvanceTableWrapper
            columns={columns}
            data={parts}
            sortable
            pagination
            perPage={15}
          >
            <Row className="flex-end-center mb-2">
              <Col xs="auto" sm={5} lg={3}>
                <AdvanceTableSearchBox table/>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2">
              <AdvanceTablePagination
                table
              />
            </div>
          </AdvanceTableWrapper>

        </Tab>
        <Tab eventKey="estimate-confirmation" title="Confirmation" className='border-bottom border-x p-3'>
          <div className="p-3">
            <Row>
              <Col>
                <h6>Approval Drawings</h6>
                <div className="mx-2">
                  <Row className="mb-2 gx-2">
                    <Col sm={2}>Status:</Col>
                    <Col>{bgConfirmStatus(apd?.status)}</Col>
                  </Row>

                  <Row className="mb-2 gx-2 mt-4">
                    <Col sm={2}><FaUser className="text-primary" /> By:</Col>
                    <Col className="text-primary">{apd?.by}</Col>
                  </Row>
                  <Row className="mb-2 gx-2">
                    <Col sm={2}><FaCalendar className="text-primary" /> Date:</Col>
                    <Col className="text-primary">{formatDate(apd?.date)}</Col>
                  </Row>

                  <Row className="mb-2 gx-2 mt-4">
                    <Col sm={2}>Note:</Col>
                    <Col className="text-primary">
                      <div dangerouslySetInnerHTML={{ __html: apd?.note }}/>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <h6>A/B for Approval Drawings</h6>
                <div className="mx-2">
                  <Row className="mb-2 gx-2">
                    <Col sm={2}>Status:</Col>
                    <Col>{bgConfirmStatus(ab_apd?.status)}</Col>
                  </Row>

                  <Row className="mb-2 gx-2 mt-4">
                    <Col sm={2}><FaUser className="text-primary" /> By:</Col>
                    <Col className="text-primary">{ab_apd?.by}</Col>
                  </Row>
                  <Row className="mb-2 gx-2">
                    <Col sm={2}><FaCalendar className="text-primary" /> Date:</Col>
                    <Col className="text-primary">{formatDate(ab_apd?.date)}</Col>
                  </Row>

                  <Row className="mb-2 gx-2 mt-4">
                    <Col sm={2}>Note:</Col>
                    <Col className="text-primary">
                      <div dangerouslySetInnerHTML={{ __html: ab_apd?.note }}/>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            
        </div>
        </Tab>
      </Tabs>

      <Suspense fallback={<div>Loading....</div>}>
        {showAPDForm && (
          <LazyAPDForm
            showModal={showAPDForm}
            setshowModal={setShowAPDForm}
            changeOrder={changeOrder}
            part={part}
            title={title}
          />
        )}

        {showRequestAPD && (
          <LazyAPDRequestToENG
            showModal={showRequestAPD}
            setshowModal={setShowRequestAPD}
            changeOrder={changeOrder}
            part={part}
          />
        )}

        {showScheduleAPD && (
          <LazyAPDScheduleToCSD
            showModal={showScheduleAPD}
            setshowModal={setShowScheduleAPD}
            changeOrder={changeOrder}
            part={part}
          />
        )}

        {showReleaseAPD && (
          <LazyAPDReleaseToCSD
            showModal={showReleaseAPD}
            setshowModal={setShowReleaseAPD}
            changeOrder={changeOrder}
            part={part}
          />
        )}

        {showDeleteAPD && (
          <LazyAPDDeleteConfirm
            showModal={showDeleteAPD}
            setshowModal={setShowDeleteAPD}
            changeOrder={changeOrder}
            part={part}
          />
        )}

        {showConfirmAPD && (
          <LazyAPDConfirmToSales
            showModal={showConfirmAPD}
            setshowModal={setShowConfirmAPD}
            changeOrder={changeOrder}
          />
        )}

        {showRejectAPD && (
          <LazyAPDRejectToENG
            showModal={showRejectAPD}
            setshowModal={setShowRejectAPD}
            changeOrder={changeOrder}
          />
        )}

        {showReleaseAPDDetail && (
          <APDDetailReleaseToSales
            showModal={showReleaseAPDDetail}
            setshowModal={setShowReleaseAPDDetail}
            changeOrder={changeOrder}
            part={apdRecord}
          />
        )}

      </Suspense>
    </>
  )
};

ApprovalDrawing.propTypes = {
  changeOrder: PropTypes.object.isRequired
}

export default ApprovalDrawing;