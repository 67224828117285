import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { Tab, Nav } from "react-bootstrap";
import BDS1 from "./area/BDS1";
import BDS2 from "./area/BDS2";
import BDS3 from "./area/BDS3";
import React from 'react';
import PropTypes from 'prop-types';

const BDS = ({
  data,
  area,
  editArea,
  deleteArea,
  openWallData,
  newOpenWall,
  editOpenWall,
  deleteOpenWall,
  roofExtensionData,
  newRoofExtension,
  editRoofExtension,
  deleteRoofExtension,
  canopyData,
  newCanopy,
  editCanopy,
  deleteCanopy,
  fasciaData,
  newFascia,
  editFascia,
  deleteFascia,
  frameOpeningData,
  newFrameOpening,
  editFrameOpening,
  deleteFrameOpening,
  mezzanineData,
  newMezzanine,
  editMezzanine,
  deleteMezzanine,
  craneInformationData,
  newCraneInformation,
  editCraneInformation,
  deleteCraneInformation
}) => {
  const newData = {...data, area}

  return (
    <>
      <Flex className="mb-2 fs--1" justifyContent="between" alignItems="center">
        <div className="text-primary fw-semi-bold">Area {area?.id}</div>
        <div>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="edit"
            transform="shrink-2"
            iconAlign="middle"
            className="me-1"
            onClick={editArea}
          >
            <span className="d-none d-xl-inline-block ms-1">Edit Area</span>
          </IconButton>

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="trash-alt"
            transform="shrink-2"
            iconAlign="middle"
            className="me-1"
            onClick={deleteArea}
          >
            <span className="d-none d-xl-inline-block ms-1">Delete Area</span>
          </IconButton>
        </div>
      </Flex>

      <Tab.Container defaultActiveKey="PIF-BDS1">
        <div className="p-0 border card-header">
          <Nav className="nav-tabs border-0 flex-nowrap tab-pif-form">
            <Nav.Item>
              <Nav.Link
                eventKey="PIF-BDS1"
                className="d-flex align-items-center py-3 px-x1 mb-0"
              >
                <h6 className="text-600 mb-0 ms-1"> BDS1</h6>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="PIF-BDS2"
                className="d-flex align-items-center py-3 px-x1 mb-0"
              >
                <h6 className="text-600 mb-0 ms-1"> BDS2</h6>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="PIF-BDS3"
                className="d-flex align-items-center py-3 px-x1 mb-0"
              >
                <h6 className="text-600 mb-0 ms-1"> BDS3</h6>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="bg-light fs--1">
          <Tab.Content>
            <Tab.Pane eventKey="PIF-BDS1">
              <BDS1
                data={newData}
                openWallData={openWallData}
                newOpenWall={newOpenWall}
                editOpenWall={editOpenWall}
                deleteOpenWall={deleteOpenWall}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="PIF-BDS2">
              <BDS2
                data={newData}
                roofExtensionData={roofExtensionData}
                newRoofExtension={newRoofExtension}
                editRoofExtension={editRoofExtension}
                deleteRoofExtension={deleteRoofExtension}
                canopyData={canopyData}
                newCanopy={newCanopy}
                editCanopy={editCanopy}
                deleteCanopy={deleteCanopy}
                fasciaData={fasciaData}
                newFascia={newFascia}
                editFascia={editFascia}
                deleteFascia={deleteFascia}
                frameOpeningData={frameOpeningData}
                newFrameOpening={newFrameOpening}
                editFrameOpening={editFrameOpening}
                deleteFrameOpening={deleteFrameOpening}
                mezzanineData={mezzanineData}
                newMezzanine={newMezzanine}
                editMezzanine={editMezzanine}
                deleteMezzanine={deleteMezzanine}
                craneInformationData={craneInformationData}
                newCraneInformation={newCraneInformation}
                editCraneInformation={editCraneInformation}
                deleteCraneInformation={deleteCraneInformation}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="PIF-BDS3">
              <BDS3 data={newData} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  )
};

BDS.propTypes = {
  data: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editArea: PropTypes.func.isRequired,
  deleteArea: PropTypes.func.isRequired,
  openWallData: PropTypes.func.isRequired,
  newOpenWall: PropTypes.func.isRequired,
  editOpenWall: PropTypes.func.isRequired,
  deleteOpenWall: PropTypes.func.isRequired,
  roofExtensionData: PropTypes.func.isRequired,
  newRoofExtension: PropTypes.func.isRequired,
  editRoofExtension: PropTypes.func.isRequired,
  deleteRoofExtension: PropTypes.func.isRequired,
  canopyData: PropTypes.func.isRequired,
  newCanopy: PropTypes.func.isRequired,
  editCanopy: PropTypes.func.isRequired,
  deleteCanopy: PropTypes.func.isRequired,
  fasciaData: PropTypes.func.isRequired,
  newFascia: PropTypes.func.isRequired,
  editFascia: PropTypes.func.isRequired,
  deleteFascia: PropTypes.func.isRequired,
  frameOpeningData: PropTypes.func.isRequired,
  newFrameOpening: PropTypes.func.isRequired,
  editFrameOpening: PropTypes.func.isRequired,
  deleteFrameOpening: PropTypes.func.isRequired,
  mezzanineData: PropTypes.func.isRequired,
  newMezzanine: PropTypes.func.isRequired,
  editMezzanine: PropTypes.func.isRequired,
  deleteMezzanine: PropTypes.func.isRequired,
  craneInformationData: PropTypes.func.isRequired,
  newCraneInformation: PropTypes.func.isRequired,
  editCraneInformation: PropTypes.func.isRequired,
  deleteCraneInformation: PropTypes.func.isRequired
};

export default BDS;