import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MdDelete, MdEdit } from 'react-icons/md';
import { deleteApplication } from 'redux/integration/applicationSlice';
import PropTypes from "prop-types";

const ApplicationDetailHeader = ({record}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [messageConfirm, setMessageConfirm] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const applicationUrl = "/integration/applications";
  const applicationId = record?.id

  const handleNewApplication = () => {
    navigate(`${applicationUrl}/new`)
  }

  const handleEditApplication = () => {
   navigate(`${applicationUrl}/${applicationId}/edit`, { state: {record} })
  }

  const handleBackToList = () => {
    navigate(`${applicationUrl}/list`)
  }

  const confirmMsg = (message) => {
    setMessageConfirm(message)
    setShowConfirmationDelete(true)
  }

  const closeConfirmModal = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmModal()
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deleteApplication(applicationId))
      navigate(`${applicationUrl}/list`)
      
      closeConfirmModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleDeleteApplication = () => {
    const message = `Are you sure to delete application ID#<strong class='text-danger'>${applicationId}</strong>?`
    confirmMsg(<span dangerouslySetInnerHTML={{ __html: message }} />);
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            iconAlign="middle"
            className="d-none d-sm-block me-1"
            onClick={handleNewApplication}
          >
            <span className="d-none d-xl-inline-block ms-1">
              New Application
            </span>
          </IconButton>

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="edit"
            iconAlign="middle"
            className="d-none d-sm-block me-1"
            onClick={handleEditApplication}
          >
            <span className="d-none d-xl-inline-block ms-1">
              Edit
            </span>
          </IconButton>
    
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBackToList}
            >
              <span className="d-none d-xl-inline-block ms-1">
               List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="primary" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleEditApplication}>
                    <MdEdit /> Edit
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item className="text-danger" onClick={handleDeleteApplication}>
                    <MdDelete className="text-danger" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message={messageConfirm}
        />
      )}
    </>
  );
};

ApplicationDetailHeader.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
}

export default ApplicationDetailHeader;