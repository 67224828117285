import React from "react"
import WaiverForm from "./WaiverForm"
import { useLocation } from "react-router-dom"

const EditWaiver = () => {
  const location = useLocation()
  const {waiver} = location.state

  console.log(waiver)

  return (
    <WaiverForm 
      project={waiver?.project}
      waiver={waiver}
      title="EDIT"
    />
  )
}

export default EditWaiver