import React from 'react';
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetNestedParams, setFilterTag, setNestedParams } from "redux/booking/travel/setting/approvalFlowSlice";
import IconAlert from "components/common/IconAlert";
import { MdSearch, MdOutlineRestartAlt, MdOutlineClose } from "react-icons/md";
import PropTypes from 'prop-types';

const FilterAdvanceModal = ({showModal, setshowModal, data, entryKey}) => {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();

  const {pageSize} = data || {};
  const key = entryKey;

  const onSubmit = (data) => {
    const newData = {
      site_name_cont: data.site_name,
      page: 1,
      per_page: pageSize
    }

    console.log(newData)

    //store for filter
    const dataFilter = [
      { id: "site_name_cont", name: "Site name", value: data.subject_class },
    ]

    dispatch(setFilterTag({key, tags: dataFilter.filter((data) => data.value !== undefined && data.value !== '')}))

    let obj = {}
    Object.entries(newData).forEach((item) => {
      const value = item[1]
      const key = item[0]

      if (value !== null && value !== "" && value !== undefined) {
        obj = {...obj, [key]: value}
      }
    })

    try {
      dispatch(setNestedParams({key, params: obj}));
      closeModal()
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleReset = () => {
    resetForm()
  }

  const resetForm = () => {
    reset()
    dispatch(resetNestedParams({key}));
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Apply filters to advances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="info">
            <p className="mb-0">Establish the approval flow's filtering criteria</p>
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="site_name">
            <Form.Label column sm={3} className="text-lg-end">
              Site name
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                rows={6}
                name="subjectsite_name_class"
                placeholder="Site name"
                className="fs--1"
                {...register("site_name")}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit"><MdSearch /> Search</Button>
          <Button size="sm" variant="falcon-default" onClick={handleReset}><MdOutlineRestartAlt /> Reset</Button>
          <Button size="sm" variant="falcon-default" onClick={closeModal}><MdOutlineClose /> Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

FilterAdvanceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  entryKey: PropTypes.string.isRequired
}

export default FilterAdvanceModal;