import React from 'react';
import { useLocation } from "react-router-dom"
import TransactionForm from "./TransactionForm"

const EditCarBooking = () => {
  const location = useLocation()
  const transaction = location.state

  return (
    <TransactionForm title="Edit car booking" transaction={transaction} />
  )
}

export default EditCarBooking