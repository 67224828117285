import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Breadcrumb } from 'react-bootstrap';
import { useLocation } from "react-router-dom";

const PebBreadcrumb = () => {
  const location = useLocation()
  const locationName = location.pathname.split("/").filter(Boolean)

  return (
    <Breadcrumb className="mb-3 fs--1">
      <Breadcrumb.Item href="/">
        <FontAwesomeIcon icon={'home'} /> Home
      </Breadcrumb.Item>
      {
        locationName.map((name, index) => (
          <Breadcrumb.Item href="#" key={index} className="text-capitalize" active>
            {name}
          </Breadcrumb.Item>
        ))
      }
    </Breadcrumb>
  )
}

export default PebBreadcrumb;