import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from 'helpers/reducerHelpers';

const positionGroupUrl = "/organization/position_groups";

export const getAllPositionGroups = createAsyncThunk(
  "PositionGroup/getAllPositionGroups",
  async (params) => {
    try {
      const response = await apiAxios.get(`${positionGroupUrl}`, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getPositionGroup = createAsyncThunk(
  "PositionGroup/getPositionGroup",
  async (positionGroupId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${positionGroupUrl}/${positionGroupId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createPositionGroup = createAsyncThunk(
  "PositionGroup/createPositionGroup",
  async (data) => {
    try {
      const response = await apiAxios.post(positionGroupUrl, data)
      return response.data
    }
    catch(error){
      throw new Error(error.message)
    }
  }
)

export const updatePositionGroup = createAsyncThunk(
  "PositionGroup/updatePositionGroup",
  async ({ positionGroupId, data }) => {
    try {
      const response = await apiAxios.put(`${positionGroupUrl}/${positionGroupId}`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const deletePositionGroup = createAsyncThunk(
  "PositionGroup/deletePositionGroup",
  async (positionGroupId) => {
    try {
      const response = await apiAxios.delete(`${positionGroupUrl}/${positionGroupId}`);
      return response.data

    } catch (error) {
      throw new Error(error.message)
    }
  }
);

export const exportPositionGroups = createAsyncThunk(
  "PositionGroup/exportPositionGroups",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${positionGroupUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "position-group.xlsx");
    document.body.appendChild(link);
    link.click();
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const positionGroupSlice = createSlice({
  name: "organization_position_group",
  initialState: {
    ...initialState
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPositionGroups.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))

      .addCase(getPositionGroup.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(createPositionGroup.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updatePositionGroup.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deletePositionGroup.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(exportPositionGroups.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportPositionGroups.fulfilled, (state) => {
        state.exporting = false
        toast.success("Position group has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = positionGroupSlice.actions;

export default positionGroupSlice.reducer;
