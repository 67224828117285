import React, { useEffect, lazy, Suspense, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProject } from "redux/crm/projectSlice";
import { Card, Nav, Tab, Row, Col, ProgressBar, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectDetailHeader from "./detail/ProjectDetailHeader";
import SimpleBarReact from 'simplebar-react';
import { formatNumber } from "helpers/utils";
import { MdNewReleases } from "react-icons/md";
import { FaAward, FaBan, FaHistory, FaList, FaMoneyBillWave, FaPlaneDeparture } from "react-icons/fa";
import { IoDocumentAttach } from "react-icons/io5";
import { useSelector } from "react-redux";
import IconAlert from "components/common/IconAlert";

const LazyProjectDetailWaiver = lazy(() => import("./detail/ProjectDetailWaiver"));
const LazyProjectDetailTravelBooking = lazy(() => import("./detail/ProjectDetailTravelBooking"));
const LazyProjectDetailActivities = lazy(() => import("./detail/ProjectActivity"));
const LazyProjectDetailOverview = lazy(() => import("./detail/ProjectDetailOverview"));
const LazyProjectDetailDocument = lazy(() => import("./detail/ProjectDetailDocument"));
const LazyWarrantyService = lazy(() => import("./detail/WarrantyService"));

const ProjectDetail = () => {
  const params = useParams();
  const {recordData: project} = useSelector(state => state.crm_project);

  const status = project?.status;
  const customer = project?.customer;
  const projectId = params.id;
  
  const dispatch = useDispatch();

  const final_estimate_weight = project?.final_weight?.est || 0.00;
  const weight = final_estimate_weight > 0 ? final_estimate_weight : project?.weight;

  useEffect(() => {
    fetchProject();
  }, [projectId, dispatch]);

  const fetchProject = async () => {
    await dispatch(getProject(projectId))
  }

  const { allow_view_warranty } = project?.permissions || {};

  const ProjectStatus = () => {
    return <Badge pill bg={status?.css_class}>{status?.name}</Badge>
  }

  return (
    <>
      <ProjectDetailHeader project={project} />
      <Card className="mb-3 p-4 fs--1">
        <Row>
          <Col sm={6}>
            <div>
              <FontAwesomeIcon icon="building" />
              <span className="fs-1 fw-semi-bold text-primary ms-1">{project?.name}</span>
            </div>
            <div>Status: <ProjectStatus /> / <span className="text-dark text-capitalize">{project?.sales_status}</span></div>
            <div className="small">Created by: <strong>{project?.created_by}</strong>, Date: {project?.created_date}</div>
          </Col>
          <Col>
            Amount:
            <div className="text-success fs-1">${formatNumber(project?.amount)}</div>
          </Col>
          <Col>
            % Success
            <ProgressBar className="my-1" now={project?.percent_success} label={`${project?.percent_success?.toString()}%`} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={4}>
            Customer:
            <div className="mt-1">
              <Link to={`/crm/customers/${customer?.id}`} target="_blank">{customer?.name}</Link>
            </div>
          </Col>
          <Col>
            Quote number:
            <div className="text-primary mt-1">{project?.q_number}</div>
          </Col>
          <Col>
            P/J number:
            <div className="text-primary mt-1">{project?.j_number}</div>
          </Col>
          <Col>
            Estimate weight:
            <div className="text-primary mt-1">{weight} M.T</div>
          </Col>
          <Col>
            Estimate scales:
            <div className="text-primary mt-1">{project?.area} m2</div>
          </Col>
          <Col>
            <FontAwesomeIcon icon="user" /> Sales incharge:
            <div className="text-primary mt-1">{project?.sales_incharge}</div>
          </Col>
        </Row>
      </Card>

      <Card>
        <Tab.Container defaultActiveKey="project-overview">
          <Card.Header className="p-0 bg-light overflow-hidden">
            <SimpleBarReact>
              <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                <Nav.Item>
                  <Nav.Link
                    eventKey="project-overview"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaList className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Project</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="project-waiver"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaMoneyBillWave className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Waiver</h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="project-travel-booking"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaPlaneDeparture className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Travel bookings</h6>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="project-warranty"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaAward className="text-warning tab-icon"/>
                    <h6 className="text-600 mb-0 ms-1">Warranty service</h6>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="project-documents"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <IoDocumentAttach className="text-600 tab-icon" />
                    <h6 className="text-600 mb-0 ms-1">Documents <MdNewReleases className="text-warning" /></h6>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="booking-activity-log"
                    className="d-flex align-items-center py-3 px-x1 mb-0"
                  >
                    <FaHistory className="text-600" />
                    <h6 className="text-600 mb-0 ms-1">Activities</h6>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </SimpleBarReact>
          </Card.Header>
          <Card.Body className="bg-light">
            <Suspense fallback={<div>Loading...</div>}>
              <Tab.Content>
                <Tab.Pane eventKey="project-overview">
                  <LazyProjectDetailOverview />
                </Tab.Pane>
                <Tab.Pane eventKey="project-waiver">
                  <LazyProjectDetailWaiver />
                </Tab.Pane>
                <Tab.Pane eventKey="project-travel-booking">
                  <LazyProjectDetailTravelBooking />
                </Tab.Pane>
                <Tab.Pane eventKey="project-warranty">
                  { allow_view_warranty ? (<LazyWarrantyService />) : (
                    <IconAlert variant="warning" className="text-center fs-0 text-danger border p-5">
                      The project must have a signed contract before you can proceed with the warranty, or you cannot access it due to lack of permission. Warranty not found!
                    </IconAlert>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="project-documents">
                  <LazyProjectDetailDocument />
                </Tab.Pane>
                <Tab.Pane eventKey="booking-activity-log">
                  <LazyProjectDetailActivities />
                </Tab.Pane>
              </Tab.Content>
            </Suspense>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  )
}

export default ProjectDetail