import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { calPaginatorRecord } from "helpers/paginator";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

const feedUrl = "/social/feeds"

export const getFeeds = createAsyncThunk(
  "SocialFeed/getFeeds",
  async (params) => {
    try {
      const response = await apiAxios.get(feedUrl, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const createFeed = createAsyncThunk(
  "SocialFeed/createFeed",
  async (data) => {
    try {
      const response = await apiAxios.post(feedUrl, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const updateFeed = createAsyncThunk(
  "SocialFeed/updateFeed",
  async ({feedId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${feedUrl}/${feedId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteFeed = createAsyncThunk(
  "SocialFeed/deleteFeed",
  async (feedId, { rejectWithValue } ) => {
    try {
      const response = await apiAxios.delete(`${feedUrl}/${feedId}`)

      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addComment = createAsyncThunk(
  "SocialFeed/addComment",
  async ({feedId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${feedUrl}/${feedId}/create_comment`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const likeFeed = createAsyncThunk(
  "SocialFeed/likeFeed",
  async ({feedId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${feedUrl}/${feedId}/like`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const record = data.attributes
    
    if (record !== null) {
      switch (type) {
        case "create": 
          state.data.unshift(record)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== record.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex(item => item.id === record.id)
          if (updatedIndex !== -1) {
            state.data[updatedIndex] = record
          }
          break;
        }
      }
    }
    toast.success(message);
  }
};

const feedSlice = createSlice({
  name: "social_feed",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 5,
      fromRecord: 0,
      toRecord: 1,
      hasMore: true
    },
    loading: false,
    exporting: false
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    resetData: (state) => {
      state.data = [];
      state.paginate.currentPage = 1;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeds.pending, (state) => {
        state.loading = true
      })
      .addCase(getFeeds.fulfilled, (state, action) => {
        state.loading = false;
        const results = action.payload;
        const paginate = state.paginate;

        if (paginate.currentPage === 1) {
          state.data = objectSerializer(results.rows); // Initial load, replace data
        } else {
          state.data = [...state.data, ...objectSerializer(results.rows)]; // Append data
        }

        state.totalRows           = results.total_rows;

        const calRecords          = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows);
        paginate.totalPagesCount  = calRecords.totalPagesCount;
        paginate.fromRecord       = calRecords.beginRecords;
        paginate.toRecord         = calRecords.lastRecords;

        paginate.hasMore          = paginate.currentPage < paginate.totalPagesCount;
      })
      .addCase(getFeeds.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createFeed.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateFeed.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteFeed.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(addComment.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(likeFeed.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  resetData
} = feedSlice.actions

export default feedSlice.reducer