import React from 'react'
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getSize } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";
import { CRM_PROJECT_ATTACHMENTS } from 'app_config';

const UploadDocument = ({files, setFiles, fileTypes, note}) => {

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileTypes ? fileTypes : CRM_PROJECT_ATTACHMENTS,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  const getIcon = type => {
    const icon = ['far'];
    if (type.includes('image')) {
      icon.push('file-image');
    }
    if (type.includes('video')) {
      icon.push('file-video');
    }
    if (type.includes('audio')) {
      icon.push('file-audio');
    }
    if (type.includes('zip')) {
      icon.push('file-archive');
    }
    if (type.includes('pdf')) {
      icon.push('file-pdf');
    }
    if (
      type.includes('html') ||
      type.includes('css') ||
      type.includes('json') ||
      type.includes('javascript')
    ) {
      icon.push('file-code');
    }
    if (icon.length === 1) {
      icon.push('file');
    }
    return icon;
  };

  const label_comment = note ? note : "Only .eml, .doc, .docx, .xls, .xlsx, .pdf, .acad files type are allowed. The file size is maximum of 100Mb"

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone-area py-6' })}>
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">Drop your files here</p>
        </Flex>
      </div>
      <div className="text-primary text-end small mt-1">{label_comment}</div>

      <div className="mt-3">
        {files.length > 0 && (
          <div className="px-x1 py-3">
            <Flex direction="column" inline>
              {files.map((file) => (
                <Flex
                  alignItems="center"
                  className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs--1"
                  key={file.path}
                >
                  <FontAwesomeIcon icon={getIcon(file.type)} className="fs-1" />
                  <span className="mx-2 flex-1">
                    {file.path} ({getSize(file.size)})
                  </span>
                  <span
                    className="text-300 p-1 ml-auto cursor-pointer"
                    onClick={() => handleRemove(file.path)}
                  >
                    <FontAwesomeIcon icon="times" />
                  </span>
                </Flex>
              ))}
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
}

UploadDocument.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFiles: PropTypes.func.isRequired,
  fileTypes: PropTypes.string,
  note: PropTypes.string
};

export default UploadDocument;