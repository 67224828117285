import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCars } from 'redux/booking/car/carSlice';
import CarHeader from './car/CarHeader';
import CarList from './car/CarList';

const Cars = () => {
  const [carSelected, setCarSelected] = useState([])
  const dispatch = useDispatch()
  const { loading, currentPage, pageSize, activeType } = useSelector((state) => state.booking_setting_car)

  useEffect(() => {
    dispatch(getAllCars({active: activeType, page: 1, per_page: pageSize}))
  }, [currentPage, pageSize, activeType]);

  return (
    <>
      <CarHeader carSelected={carSelected} />
      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="CarList">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="CarList"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Car list</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="CarList">
                    <CarList setCarSelected={setCarSelected} /> 
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Cars
