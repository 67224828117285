const { useState } = require("react");

const useDateRange = (initialStartDate = null, initialEndDate = null) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return { startDate, endDate, onChangeDateRange };
}

export default useDateRange