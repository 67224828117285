import { Modal, Button } from "react-bootstrap";
import React from 'react';
import PropTypes from 'prop-types';

const DetailDataModal = ({showModal, setshowModal, title, dataComponent }) => {
  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dataComponent}
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button size="sm" variant="falcon-default" onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

DetailDataModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dataComponent: PropTypes.string.isRequired
};

export default DetailDataModal;