import React from 'react';
import PropTypes from "prop-types";

const GoogleMapsLink = ({ address }) => {
  const handleClick = (e) => {
    e.preventDefault()
    const encodedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <a href="#" onClick={handleClick} className="mb-1 fs--1">
      { address }
    </a>
  );
};

GoogleMapsLink.propTypes = {
  address: PropTypes.string.isRequired,
};

export default GoogleMapsLink;
