import React, { useState, lazy, Suspense } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpenseHeader from './setting/expense/ExpenseHeader';
import { useSelector } from 'react-redux';
import { MdPolicy } from 'react-icons/md';

const AllList = lazy(() => import('./setting/expense/list/All'));
const StaffPolicyBySalesList = lazy(() => import('./setting/expense/list/StaffPolicyBySales'));
const StaffPolicyByCMDList = lazy(() => import('./setting/expense/list/StaffPolicyByCMD'));

const ExpensePolicy = () => {
  const [selected, setSelected] = useState([]);
  const {loading} = useSelector((state) => state.booking_setting_expense);

  return (
    <>
      <ExpenseHeader selected={selected} />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="expense-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="expense-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="expense-sales"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdPolicy className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Staff policy by Sales</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="expense-cmd"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdPolicy className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Staff policy by CMD</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="expense-all">
                      < AllList setSelected={setSelected} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="expense-sales">
                      <StaffPolicyBySalesList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="expense-cmd">
                      <StaffPolicyByCMDList />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ExpensePolicy
