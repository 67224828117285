import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setFilterTag,
  exportApprovalFlows,
  getAllApprovalFlows
} from "redux/booking/travel/setting/approvalFlowSlice";

import AgGridTable from "components/ag-grid/AgGridTable";
import AgPagination from "components/ag-grid/AgPagination";
import { useDispatch } from "react-redux";
import GridFilter from "components/peb-grid/GridFilter";
import FilterAdvanceModal from "./FilterAdvanceModal";
import PropTypes from 'prop-types';
import { PERPAGE_DEFAULT_SM } from "app_config";

const ApprovalFlowList = ({setSelected}) => {
  const key = "allData";
  const { allData } = useSelector((state) => state.booking_setting_approval_flow);

  const {
    data,
    totalRows,
    pageSize,
    totalPagesCount,
    fromRecord,
    toRecord,
    currentParams,
    filterTags
  } = allData || {};
  
  const dispatch = useDispatch();

  const columnDefs = useMemo(() => [
    { 
      headerName: 'ID#',
      field: 'id',
      minWidth: 110,
      headerCheckboxSelection: true,
      checkboxSelection: true
    },
    { 
      headerName: 'Site',
      field: 'site_name',
      minWidth: 200
    },
    { 
      headerName: 'HR admin site office',
      field: 'hr_admins_site_office_confirm',
      minWidth: 120
    },
    { 
      headerName: 'Chief Rep/GM approval',
      field: 'chief_rep_gm_approval',
      minWidth: 120
    },
    { 
      headerName: 'FNC check',
      field: 'accountants_confirm',
      minWidth: 120
    },
    { 
      headerName: 'C&B check',
      field: 'c_b_verify',
      minWidth: 120
    },
    { 
      headerName: 'HR admin head office',
      field: 'hr_admins_head_office_confirm',
      minWidth: 120
    },
    { 
      headerName: 'HR manager approval',
      field: 'hr_manager_approval',
      minWidth: 120
    },
    { 
      headerName: 'GD Approval',
      field: 'gd_approval',
      minWidth: 120
    }
  ], []);

  useEffect(() => {
    const params = currentParams ?? { page: 1, per_page: PERPAGE_DEFAULT_SM };
    dispatch(getAllApprovalFlows(params));
  }, [currentParams]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setNestedPage({ key, page }));
    dispatch(setNestedPageSize({ key, pageSize }));

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize};
    console.log(newCurrentParams);
    dispatch(setNestedParams({ key, params: newCurrentParams }));

    dispatch(getAllApprovalFlows(newCurrentParams));
  })

  return (
    <>
      <div className="fs--1">Comprehensive List of Approval Flows for Travel Booking.</div>

      <GridFilter
        entryType="all"
        entryData={allData}
        loadApi={getAllApprovalFlows}
        currentParams={currentParams}
        entryKey={"allData"}
        setNestedParams={setNestedParams}
        resetNestedParams={resetNestedParams}
        setFilterTag={setFilterTag}
        filterTags={filterTags}
        filterAttributes="site_name_cont"
        exportApi={exportApprovalFlows}
        FilterAdvanceComponent={FilterAdvanceModal}
      />

      <AgGridTable
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        refData={setSelected}
        selectedMultipe={false}
        serverSide={true}
      />

      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200", "500"]}
      />
    </>
  )
}

ApprovalFlowList.propTypes = {
  setSelected: PropTypes.func.isRequired
}

export default ApprovalFlowList;