import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { Collapse, Accordion, Card, Form, Row, Col, Table } from "react-bootstrap";
import DetailBDSGeneralInformation from "./DetailBDSGeneralInformation";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

const DetailBDS2 = ({project, building, adaptation, area}) => {
  const {bds2} = area
  const roofExtensionData = bds2?.roof_extensions || []
  const canopyData = bds2?.canopies || []
  const fasciaData = bds2?.fascias || []
  const frameOpeningData = bds2?.frame_openings || []
  const mezzanineData = bds2?.mezzanines || []
  const craneInformationData = bds2?.crane_informations || []

  const {bds2: initialKeys} = useSelector(state => state.crm_project_pif.initialKeys)
  const [activeKey, setActiveKey] = useState(initialKeys)

  useEffect(() => {
    setActiveKey(initialKeys);
  }, [initialKeys]);

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <>
      <Collapse in={true}>
        <Accordion
          id="revision-table"
          className="border rounded overflow-hidden fs--1"
          alwaysOpen
          flush
          activeKey={activeKey}
          onSelect={handleAccordionSelect}
        >
          <Card className="rounded-0">
            <Accordion.Item eventKey="0" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      General Information
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <DetailBDSGeneralInformation
                    project={project}
                    building={building}
                    adaptation={adaptation}
                    area={area}
                  />
                  <h6 className="text-center my-2">This area only : Page 2 of 3</h6>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 12. Roof Extension */}
            <Accordion.Item eventKey="1" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      12. Roof Extension
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (M)</th>
                          <th className="border" rowSpan={2}>Length (M)</th>
                          <th className="border" colSpan={2}>Soffit panel</th>
                          <th className="border" rowSpan={2}>Insulated</th>
                          <th className="border" rowSpan={2}>Description</th>
                        </tr>
                        <tr className="text-center" style={{height: "10px"}}>
                          <td className="border">By</td>
                          <td className="border">Description</td>
                        </tr>
                      </thead>
                      <tbody>
                        {roofExtensionData && roofExtensionData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.soffit_panel_by}</td>
                            <td className="border">{item.soffit_panel_description}</td>
                            <td className="border">{item.insulated}</td>
                            <td className="border">{item.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3">
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.roof_extension_note }}></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 13. Canopy */}
            <Accordion.Item eventKey="2" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      13. Canopy
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (M)</th>
                          <th className="border" rowSpan={2}>Length (M)</th>
                          <th className="border" rowSpan={2}>Clear height (M)</th>
                          <th className="border" rowSpan={2}>Eave condition</th>
                          <th className="border" rowSpan={2}>Slope</th>
                          <th className="border" colSpan={3}>Roof panel</th>
                          <th className="border" colSpan={3}>Endwall panel</th>
                          <th className="border" colSpan={3}>Soffit panel</th>
                        </tr>
                        <tr className="text-center" style={{height: "10px"}}>
                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>
                        </tr>
                      </thead>
                      <tbody>
                        {canopyData && canopyData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.clear_height}</td>
                            <td className="border">{item.eave_condition}</td>
                            <td className="border">{item.slope}</td>

                            <td className="border">{item.roof_panel_by}</td>
                            <td className="border">{item.roof_panel_description}</td>
                            <td className="border">{item.roof_panel_color}</td>

                            <td className="border">{item.end_wall_panel_by}</td>
                            <td className="border">{item.end_wall_panel_description}</td>
                            <td className="border">{item.end_wall_panel_color}</td>

                            <td className="border">{item.soffit_panel_by}</td>
                            <td className="border">{item.soffit_panel_description}</td>
                            <td className="border">{item.soffit_panel_color}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
         
                  <Row className="mt-3">
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.capony_note }}></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 14. Fascia */}
            <Accordion.Item eventKey="3" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      14. Fascia
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Type</th>
                          <th className="border" rowSpan={2}>Height (M)</th>
                          <th className="border" rowSpan={2}>Length (M)</th>
                          <th className="border" rowSpan={2}>Proj. (M)</th>

                          <th className="border" colSpan={3}>Fascia panel</th>
                          <th className="border" colSpan={3}>Soffit panel</th>
                          <th className="border" colSpan={3}>Backup panel</th>
                        </tr>
                        <tr className="text-center" style={{height: "10px"}}>
                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>

                          <td className="border">By</td>
                          <td className="border">Description</td>
                          <td className="border">Color</td>
                        </tr>
                      </thead>
                      <tbody>
                        {fasciaData && fasciaData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.fascia_type}</td>
                            <td className="border">{item.height}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.proj}</td>
    
                            <td className="border">{item.fasica_panel_by}</td>
                            <td className="border">{item.fasica_panel_description}</td>
                            <td className="border">{item.fasica_panel_color}</td>

                            <td className="border">{item.soffit_panel_by}</td>
                            <td className="border">{item.soffit_panel_description}</td>
                            <td className="border">{item.soffit_panel_color}</td>

                            <td className="border">{item.backup_panel_by}</td>
                            <td className="border">{item.backup_panel_description}</td>
                            <td className="border">{item.backup_panel_color}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
        
                  <Row className="mt-3">
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.fascia_note }}></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 15. Frame Opening */}
            <Accordion.Item eventKey="4" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      15. Frame Opening
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (mm)</th>
                          <th className="border" rowSpan={2}>Height (mm)</th>
                          <th className="border" rowSpan={2}>For</th>
                          <th className="border" rowSpan={2}>Doors, window,…By</th>
                          <th className="border" rowSpan={2}>Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        {frameOpeningData && frameOpeningData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="border">{item.height}</td>
                            <td className="border">{item.frame_opening_for}</td>
                            <td className="border">{item.door_window_by}</td>
                            <td className="border">{item.location}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3">
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.frame_opening_note }}></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 16. Mezzanine */}
            <Accordion.Item eventKey="5" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      16. Mezzanine
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm={7}>
                      <Row className="gx-2">
                        <Col sm="auto">PEB to Supply :</Col>
                        <Col>
                          <Flex justifyContent="between">
                            <Form.Check
                              type="checkbox"
                              label="Cols"
                              checked={bds2.mezzinine_peb_supply_cols}
                            />

                            <Form.Check
                              type="checkbox"
                              label="Beams"
                              checked={bds2.mezzinine_peb_supply_beams}
                            />

                            <Form.Check
                              type="checkbox"
                              label="Joists"
                              checked={bds2.mezzinine_peb_supply_joists}
                            />

                            <Form.Check
                              type="checkbox"
                              label="Deck"
                              checked={bds2.mezzinine_peb_supply_deck}
                            />

                            <Form.Check
                              type="checkbox"
                              label="Handrails Mezz."
                              checked={bds2.mezzinine_peb_supply_handrails_mezz}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={5}>
                      <Row className="gx-2">
                        <Col sm="auto">
                          <Form.Check
                            type="checkbox"
                            label="Apply loads only"
                            checked={bds2.mezzinine_apply_load_only}
                          />
                        </Col>
                        <Col>
                          <span className="text-primary">{bds2.mezzinine_apply_load_only_specify}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th className="border" rowSpan={2}>Mz no</th>
                          <th className="border" rowSpan={2}>DL (kN/m2)</th>
                          <th className="border" rowSpan={2}>LL (kN/m2)</th>
                          <th className="border" rowSpan={2}>Coll (kN/m2)</th>
                          <th className="border" rowSpan={2}>Area (m2)</th>
                          <th className="border" rowSpan={2}>Clearance (mm)</th>
                          <th className="border" colSpan={2}>Floor</th>
                          <th className="border" rowSpan={2}>Stair Type</th>
                          <th className="border" rowSpan={2}>Qty</th>
                          <th className="border" rowSpan={2}>Width (mm) (IN/IN ofStringer)</th>
                          <th className="border" colSpan={2}>Landing</th>
                          <th className="border" rowSpan={2}>Tread Type</th>
                          <th className="border" rowSpan={2}>Hand Rail</th>
                        </tr>
                        <tr className="text-center">
                          <th className="border">Type</th>
                          <th className="border">Total thickness (mm)</th>

                          <th className="border">Mid</th>
                          <th className="border">Top</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mezzanineData && mezzanineData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">{item.mz_no}</td>
                            <td className="border">{item.dl}</td>
                            <td className="border">{item.ll}</td>
                            <td className="border">{item.coll}</td>
                            <td className="border">{item.area}</td>
                            <td className="border">{item.clearance}</td>
                            <td className="border">{item.floor_type}</td>
                            <td className="border">{item.floor_t}</td>
                            <td className="border">{item.stair_type}</td>
                            <td className="border">{item.qty}</td>
                            <td className="border">{item.width}</td>
                            <td className="text-center border">
                              <Form.Check
                                name={`landing_mid_${item.id}`}
                                checked={item.landing_mid}
                              />
                            </td>
                            <td className="text-center border">
                              <Form.Check
                                name={`landing_top_${item.id}`}
                                checked={item.landing_top}
                              />
                            </td>
                            <td className="border">{item.tread_type}</td>
                            <td className="border">{item.hand_rail}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3 mb-2">
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.mezzanine_note }}></span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Is Concrete floor thickness included finished floor?"
                        checked={bds2.mezzinine_concrete_floor_thickness}
                      />
                    </Col>
                    <Col>
                     <Row className="gx-2 align-items-center">
                        <Col sm="auto">Finish floor thickness (mm):</Col>
                        <Col>
                          <span className="text-primary">{bds2.mezzinine_finish_floor_thickness}</span>
                        </Col>
                     </Row>
                    </Col>
                  </Row>
                  <Row className="gx-2 mb-2 align-items-center">
                    <Col sm="auto">Edge of mezzanine slab is expanded to out of :</Col>
                    <Col>
                      <span className="text-primary">{bds2.mezzanine_edge_slab}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 17. Roof Monitor */}
            <Accordion.Item eventKey="6" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      17. Roof Monitor
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Width at throat (mm):</Col>
                        <Col>
                          <span className="text-primary">{bds2.roof_monitor_width}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Height (mm):</Col>
                        <Col>
                          <span className="text-primary">{bds2.roof_monitor_height}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Roof Slope:</Col>
                        <Col>
                          <span className="text-primary">{bds2.roof_monitor_slope}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Eave Condition:</Col>
                        <Col>
                          <span className="text-primary">{bds2.roof_monitor_eave_condition}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={8}>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Roof panel & color:</Col>
                        <Col>
                          <span className="text-primary">{bds2.roof_monitor_panel_and_color}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3 gx-2 align-items-center">
                    <Col sm="auto">Wall Condition:</Col>
                    <Col>
                      <span className="text-primary">{bds2.roof_monitor_wall_condition}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.roof_monitor_note }}></span>    
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 18. Ridge Vent */}
            <Accordion.Item eventKey="7" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      18. Ridge Vent
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2 gx-2">
                    <Col sm="auto">
                      <Row className="gx-2">
                        <Col sm="auto">RV Type:</Col>
                        <Col>
                          <span className="text-primary">{bds2.ridge_vent_rv_type}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="align-items-center mt-2 ms-3">
                      <Form.Check
                        type="checkbox"
                        label="NON-STANDARD"
                        checked={bds2.ridge_vent_non_standard}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 gx-2">
                    <Col sm="auto">
                      <div className="mb-1">Quantity:</div>
                      <span className="text-primary">{bds2.ridge_vent_qty}</span>
                    </Col>
                    <Col>
                      <div className="mb-1">Length:</div>
                      <span className="text-primary">{bds2.ridge_vent_length}</span>
                    </Col>
                    <Col>
                      <div className="mb-1">Roof panel:</div>
                      <span className="text-primary">{bds2.ridge_vent_roof_panel}</span>
                    </Col>
                    <Col>
                      <div className="mb-1">Color:</div>
                      <span className="text-primary">{bds2.ridge_vent_color}</span>
                    </Col>
                    <Col>
                      <div className="mb-1">Remark:</div>
                      <span className="text-primary">{bds2.ridge_vent_remark}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.ridge_vent_note }}></span>        
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            {/* 19. Crane Information */}
            <Accordion.Item eventKey="8" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      19. Crane Information
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm="auto">Scope of Supply:</Col>
                    <Col>
                      <Flex justifyContent="between">
                        <Form.Check
                          type="checkbox"
                          label="Only Apply Loads,"
                          checked={bds2.crane_only_apply_loads}
                        />

                        <Form.Check
                          type="checkbox"
                          label="Crane Beams & Brackets,"
                          checked={bds2.crane_beams_brackets}
                        />

                        <Form.Check
                          type="checkbox"
                          label="Rails,"
                          checked={bds2.crane_rails}
                        />

                        <Form.Check
                          type="checkbox"
                          label="Crane system,"
                          checked={bds2.crane_system}
                        />

                        <Form.Check
                          type="checkbox"
                          label="Bracket only,"
                          checked={bds2.crane_bracket_only}
                        />

                      </Flex>
                    </Col>
                  </Row>
                  <Row className="mb-2 gx-2">
                    <Col sm="auto">
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Power Supply. Volts:</Col>
                        <Col>
                          <span className="text-primary">{bds2.crane_power_supply_volt}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Hz:</Col>
                        <Col>
                          <span className="text-primary">{bds2.crane_hz}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Phases:</Col>
                        <Col>
                          <span className="text-primary">{bds2.crane_phasenly}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3 gx-2 align-items-center">
                        <Col sm="auto">Manufacturer:</Col>
                        <Col>
                          <span className="text-primary">{bds2.crane_manufacture}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th className="border" rowSpan={2}>No.</th>
                          <th className="border" rowSpan={2}>Crane Type</th>
                          <th className="border" colSpan={3}>Span</th>
                          <th className="border" colSpan={3}>Run length</th>
                          <th className="border" rowSpan={2}>Capacity (M.T)</th>
                          <th className="border" rowSpan={2}>Wheel Load (kN)</th>
                          <th className="border" rowSpan={2}>Wheel Base (mm)</th>
                          <th className="border" rowSpan={2}>Bumper (mm)</th>
                          <th className="border" rowSpan={2}>Min. Hook Height</th>
                          <th className="border" rowSpan={2}>Top of Crane Beam</th>
                          <th className="border" rowSpan={2}>Bridge Weight (kg)</th>
                          <th className="border" rowSpan={2}>Trolley Weight (kg)</th>
                          <th className="border" colSpan={2}>Operaion</th>
                        </tr>
                        <tr className="text-center">
                          <th className="border">W1 (m)</th>
                          <th className="border">From (GL)</th>
                          <th className="border">To (GL)</th>

                          <th className="border">(m)</th>
                          <th className="border">From (GL)</th>
                          <th className="border">To (GL)</th>

                          <th className="border">Pendant</th>
                          <th className="border">Cab</th>
                        </tr>
                      </thead>
                      <tbody>
                        {craneInformationData && craneInformationData.map((item, index) => (
                          <tr key={index}>
                            <td className="border">{item.crane_sr}</td>
                            <td className="border">{item.crane_type}</td>
                            <td className="border">{item.span_w1}</td>
                            <td className="border">{item.span_from_gl}</td>
                            <td className="border">{item.span_to_gl}</td>
                            <td className="border">{item.run_length}</td>
                            <td className="border">{item.run_length_from_gl}</td>
                            <td className="border">{item.run_length_to_gl}</td>
                            <td className="border">{item.capacity}</td>
                            <td className="border">{item.wheel_load}</td>
                            <td className="border">{item.wheel_base}</td>
                            <td className="border">{item.bumper}</td>
                            <td className="border">{item.min_hook_height}</td>
                            <td className="border">{item.top_of_crane_beam}</td>
                            <td className="border">{item.bridge_weight}</td>
                            <td className="border">{item.trolley_weight}</td>

                            <td className="border text-center"><Form.Check checked={item.operation_pendant} /></td>
                            <td className="border text-center"><Form.Check checked={item.operation_cab} /></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3 mb-2">
                    <Col>
                      <span dangerouslySetInnerHTML={{ __html: bds2.crane_information_note }}></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      Attached
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="between">
                    <Form.Check
                      type="checkbox"
                      label="For This Building/ Area Only,"
                      checked={bds2.attached_for_this_building}
                    />

                    <Form.Check
                      type="checkbox"
                      label="BLDG.Data Sheet 3,"
                      checked={bds2.attached_bldg_data_sheet3}
                    />

                    <Form.Check
                      type="checkbox"
                      label="BLDG.Data Sheet 4,"
                      checked={bds2.attached_bldg_data_sheet4}
                    />

                  </Flex>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

            </Card>
          </Accordion>
        </Collapse>
    </>
  )
};

DetailBDS2.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired
}

export default DetailBDS2;