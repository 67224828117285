import Avatar from "components/common/Avatar";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
import React from "react";

export const MeAvatar = () => {
  const {data: user} = useSelector(state => state.profile_user);
  
  return (
    <Link to="/user/profile">
      {user.avatar_url ? (
        <Avatar size="m" src={user.avatar_url} />
      ) : (
        <Avatar size="m" name={user.name} />
      )}
    </Link>
  );
};