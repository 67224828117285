import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import TransactionHeader from './transaction/TransactionHeader';
import { FaBan, FaCheck } from 'react-icons/fa';
import { MdCancel, MdPending, MdListAlt } from 'react-icons/md';
import React, { Suspense, lazy } from 'react';

const AllList = lazy(() => import('./transaction/list/All'));
const WaitingApprovalList = lazy(() => import('./transaction/list/WaitingApproval'));
const ApprovedList = lazy(() => import('./transaction/list/Approved'));
const RejectedList = lazy(() => import('./transaction/list/Rejected'));
const CanceledList = lazy(() => import('./transaction/list/Canceled'));

const Transactions = () => {
  const { loading } = useSelector((state) => state.booking_car);

  return (
    <>
      <TransactionHeader />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="car-booking-list">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                  <Nav.Item>
                      <Nav.Link
                        eventKey="car-booking-list"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="car-booking-waiting-for-approval"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdPending className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Waiting for approval</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="car-booking-approved"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaCheck className="text-success tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Approved</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="car-booking-rejected"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaBan className="text-danger tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Rejected</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="car-booking-canceled"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdCancel className="text-danger tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Canceled</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>

                  <Tab.Content>
                    <Tab.Pane eventKey="car-booking-list">
                      <AllList /> 
                    </Tab.Pane>
                    <Tab.Pane eventKey="car-booking-waiting-for-approval">
                      <WaitingApprovalList /> 
                    </Tab.Pane>
                    <Tab.Pane eventKey="car-booking-approved">
                      <ApprovedList /> 
                    </Tab.Pane>
                    <Tab.Pane eventKey="car-booking-rejected">
                      <RejectedList /> 
                    </Tab.Pane>
                    <Tab.Pane eventKey="car-booking-canceled">
                      <CanceledList /> 
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Transactions
