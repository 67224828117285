import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import PropTypes from 'prop-types';

const StarterHotel = ({setShowModalHotel}) => {

  const handleCreateHotelBooking = () => {
    setShowModalHotel(true)
  }

  return (
    <>
      <Row className="align-items-center justify-content-between m-5">
        <Col lg={6}>
          <img src={editing} className="img-fluid" alt="" width="500px" />
        </Col>
        <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
          <h3 className="text-primary">Hotel Booking!</h3>
          <p className="lead">Book a hotel for the trip.</p>
          <Button
            variant="falcon-primary"
            onClick={handleCreateHotelBooking}
          >
            Book a hotel now!
          </Button>
        </Col>
      </Row>
    </>
  )
};

StarterHotel.propTypes = {
  setShowModalHotel: PropTypes.func.isRequired
};

export default StarterHotel;