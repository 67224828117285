import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProjectNameRenderer = ({ data }) => (
  <>
    <Link to={`/crm/projects/${data.project.id}`}>
      {data.project.name}
    </Link>

    <div className="fs--1 my-0 py-0">
      {data.building_description ? `${data.building_name}-${data.building_description}` : data.building_name}
    </div>

    {data.sts_notes && (
      <div className="text-danger fs--2">
        {data.sts_notes}
      </div>
    )}

    {data.qty_of_identical_bldgs > 1 && (
      <div className="fs--2 my-0 py-0">
        Identical Bldgs: <strong>{data.qty_of_identical_bldgs}</strong>
      </div>
    )}
  </>
);

ProjectNameRenderer.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProjectNameRenderer;
