import { Button, Form, Row, Col, Offcanvas } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';
import React, { useMemo, useEffect } from 'react';
import { CRM_SALUTATION } from 'app_config';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import Flex from 'components/common/Flex';
import { FaBan, FaSave } from 'react-icons/fa';
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2';
import AsyncSelect from 'react-select/async';
import {isValidPhoneNumber} from "libphonenumber-js"
import { salesEmployeesByNameDebounce } from 'helpers/employeeOptionHelper';
import { toast } from 'react-toastify';
import { createContact } from 'redux/crm/contactSlice';
import { getCustomer } from 'redux/crm/customerSlice';
import PropTypes from 'prop-types';

const ContactFormModal = ({showModal, setshowModal, customerId}) => {
  const {data: profileUser} = useSelector((state) => state.profile_user);
  const {recordData: customer} = useSelector(state => state.crm_customer);
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm();

  const salutationOptions = useMemo(() => CRM_SALUTATION.map((option) => ({ value: option, label: option })), []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomer(customerId));
    }
  }, [customerId]);

  const defaultSalutationOption = useMemo(() => salutationOptions[1], []);
  const defaultMainContactOption = useMemo(() => ({ value: profileUser.name, label: profileUser.name }), []);

  useEffect(() => {
    setValue('salutation', salutationOptions[1].value);
    setValue('main_contact', profileUser.name);
  }, []);

  const handleChangeSecondaryContact = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('secondary_contact', selectedOptionsValues, { shouldValidate: true });
  }

  const mainContactOptions = (inputValue, callback) => {
    salesEmployeesByNameDebounce(inputValue, callback);
  };

  const onSubmit = async (data) => {
    console.log(data)
    if (!validate(data)) return;

    try {
      let contactId = null;

      const response = await dispatch(createContact({customerId, data}));
      contactId = response.payload.data.id;
      
      if (contactId) {
        closeModal();
      }
    }
    catch(error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const validatePhoneNumber = (number) => {
    if (number === undefined || number === "" || number === null) return undefined

    let countryCode = customer ? customer.country : 'VN'
    if (!isValidPhoneNumber(number.toString(), countryCode)) {
      return `Invalid phone number with country code ${countryCode}.`;
    }
    return true
  }

  const validate = (data) => {
    if ((data.email === '' || data.email === undefined) && (data.mobile === undefined || data.mobile === '')) {
      toast.error("Email or mobile must be required.")
      return false
    }
    return true
  }

  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <Offcanvas
      show={showModal}
      onHide={closeModal}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '50%'}}
      className="fs--1"
    >
        <Offcanvas.Header className="bg-light" closeButton>
          <Offcanvas.Title>
            New Contact
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <IconAlert variant="warning" className="mt-2">
            <p className="mb-0">Create a new contact in {customer?.name} customer.</p>
          </IconAlert>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} className="mb-2" controlId="salutation">
              <Form.Label column sm={3} className="text-lg-end">
                Salutation
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="salutation"
                  render={() => (
                    <Select
                      closeMenuOnSelect={true}
                      options={salutationOptions}
                      defaultValue={defaultSalutationOption}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      onChange={selectedOption => setValue('salutation', selectedOption.value, {shouldValidate: true})}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Salutation is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.salutation && errors.salutation.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="first_name">
              <Form.Label column sm={3} className="text-lg-end">
                Contact name
              </Form.Label>
              <Col sm={9} md={7}>
                <Row className="g-2">
                  <Col>
                    <Form.Control
                      type="text"
                      name="first_name"
                      placeholder="First name"
                      className="fs--1"
                      {...register("first_name", {
                        required: "First name must be required."
                      })}
                    />
                    <span className="text-danger">
                      {errors.first_name && errors.first_name.message}
                    </span>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Last name"
                      className="fs--1"
                      {...register("last_name")}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="position">
              <Form.Label column sm={3} className="text-lg-end">
                Title
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="position"
                  placeholder="Title"
                  className="fs--1"
                  {...register("position", {
                    required: "Title must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.position && errors.position.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="email">
              <Form.Label column sm={3} className="text-lg-end">
                Email
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="example@pebsteel.com.vn"
                  className="fs--1"
                  {...register('email', {
                    //required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Invalid email address'
                    }
                  })}
                />
                <span className="text-danger">
                  {errors.email && errors.email.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="mobile">
              <Form.Label column sm={3} className="text-lg-end">
                Phone number
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="mobile"
                  render={({field: {ref, ...field}}) => (
                    <PhoneInput
                      {...field}
                      inputProps={{ref}}
                      country={customer ? customer.country?.toLowerCase() : 'vn' }
                      autoFormat={true}
                      countryCodeEditable={false}
                      enableSearch={true}
                      disableSearchIcon={true}
                      inputStyle={{width: '100%'}}
                      searchStyle={{width: '95%'}}
                    />
                  )}
                  rules={{ validate: validatePhoneNumber }}
                />
                <span className="text-danger">
                  {errors.mobile && errors.mobile.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="main_contact">
              <Form.Label column sm={3} className="text-lg-end">
                Main contact
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="main_contact"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={defaultMainContactOption}
                      loadOptions={mainContactOptions}
                      closeMenuOnSelect={true}
                      placeholder='Select or input 3 characters of name...'
                      classNamePrefix="react-select"
                      className="w-100"
                      onChange={selectedOption => setValue('main_contact', selectedOption.value)}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Main contact is required'
                    }
                  }}
                />
                <span className="text-danger">
                  {errors.main_contact && errors.main_contact.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="secondary_contact">
              <Form.Label column sm={3} className="text-lg-end">
                Secondary contact
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name="secondary_contact"
                  render={() => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={mainContactOptions}
                      closeMenuOnSelect={false}
                      placeholder='Select...'
                      classNamePrefix="react-select"
                      className="w-100"
                      isMulti
                      onChange={handleChangeSecondaryContact}
                    />
                  )}
                />
              </Col>
            </Form.Group>
            
            <Form.Group as={Row}>
              <Col className="mb-3 mt-5">
                <Flex justifyContent="center">
                  <Button size="sm" type="submit"><FaSave /> Save</Button>
                  <Button variant="danger" size="sm" className="ms-2" onClick={closeModal}><FaBan /> Cancel</Button>
                </Flex>
              </Col>
            </Form.Group>
          </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

ContactFormModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired
};

export default ContactFormModal;