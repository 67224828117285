import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Error403 = () => {
  const location = useLocation();
  console.log(location)

  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">403</div>
        <p className="lead mt-4 font-sans-serif fw-semi-bold text-danger">
          Unauthorized error!
        </p>
        <hr />
        <p>
          You are not authorized or do not have the necessary permissions to access page,
          <a href="mailto:portal@pebsteel.com.vn" className="ms-1">
            contact IT deparment
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Error403;
