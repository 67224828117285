import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import { FaExternalLinkAlt, FaHistory, FaMixer, FaRProject } from "react-icons/fa";
import { formatDate, formatNumber } from "helpers/utils";
import GoogleMapsLink from "helpers/googleMapsLink";
import { MdOutlineTransferWithinAStation, MdTransferWithinAStation } from "react-icons/md";
import IconButton from "components/common/IconButton";
import React, { useState, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const LazySalesAction = lazy(() => import("../confirmation/LeadSalesAction"));
const LazyUpdateProject = lazy(() => import("../UpdateProject"));
const LazyConvertPCVConfirm = lazy(() => import("../confirmation/ConvertPCVConfirm"));

const Contacted = ({lead}) => {
  const [showSalesAction, setShowSalesAction] = useState(false)
  const [showUpdateProject, setShowUpdateProject] = useState(false)
  const [showConvertPCVConfirm, setShowConvertPCVConfirm] = useState(false)

  const handleAddAction = () => {
    setShowSalesAction(true);
  }

  const handleUpdateProject = () => {
    setShowUpdateProject(true);
  }

  const handleConvertPCV = () => {
    setShowConvertPCVConfirm(true);
  }

  const permissions = lead?.permissions || {};
  const contacts = lead?.contacts || [];
  const opportunity = lead?.opportunity || {};
  const customer = lead?.customer || {};

  const {
    allow_convert,
    allow_add_sales_action,
    allow_update_project
  } = permissions || {};

  console.log(opportunity)

  return (
    <>
      <div className="p-3">
        <div className="fs-2 fw-semi-bold">Contacted stage</div>
        <p>Contact and explore more information from customer.</p>

        <Row className="fs--1 mt-4 gx-3">
          <Col sm={8}>
            <Card className="mb-3">
              <Card.Header className="fw-bold border-bottom"><MdTransferWithinAStation className="text-warning" /> Transfer information</Card.Header>
              <Card.Body>
                <div className="mb-4 text-primary">The lead has been transferred to Sales for handling qualified information until it becomes a new opportunity.</div>
                <Row className="mb-2">
                  <Col>Sales person:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead.salesperson}</span>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>Confirmed:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead.sales_confirm}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Confirmed date:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead.sales_confirm_date}</span>
                  </Col>
                </Row>
                <Row className="mb-2 mt-4">
                  <Col>Sales comment:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead.sales_confirm_reason}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Header className="fw-bold border-bottom">
               <FaRProject className="text-warning" /> Project information
              </Card.Header>
              <Card.Body>
                <Row className="mb-4">
                  <Col>Project name:</Col>
                  <Col sm={9}>
                    <span className="text-primary fw-bold">{lead.project_name}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Amount:</Col>
                  <Col sm={9}>
                    <span className="text-primary">${formatNumber(lead.amount)}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Area:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{formatNumber(lead.area)} m2</span>
                  </Col>
                </Row>

                <Row className="mb-2 mt-4">
                  <Col>Building type L1:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead?.building_type_l1?.name}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Building type L2:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead?.building_type_l2?.name}</span>
                  </Col>
                </Row>

                <Row className="mb-2 mt-4">
                  <Col>Location:</Col>
                  <Col sm={9}>
                    <GoogleMapsLink address={lead.project_location} />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                {allow_update_project && (
                  <IconButton
                    icon="edit"
                    size="sm"
                    onClick={handleUpdateProject}
                  >
                    Update
                  </IconButton>
                )}

                {allow_convert && (
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={handleConvertPCV}
                    className="ms-2"
                  >
                    <MdOutlineTransferWithinAStation /> Convert PCV now!
                  </Button>
                )}
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={4}>
            <Card className="mb-3">
              <Card.Header className="fw-bold border-bottom"><FaMixer className="text-info" /> Sales action</Card.Header>
              <Card.Body>
                <span className="text-primary">{lead.sales_action ? lead.sales_action : "No action found."}</span>
              </Card.Body>
              <Card.Footer className="text-end">
                {allow_add_sales_action && (
                  <IconButton
                    icon="plus"
                    size="sm"
                    onClick={handleAddAction}
                  >
                    Add Action
                  </IconButton>
                )}
               
              </Card.Footer>
            </Card>

            <Card className="mb-3">
              <Card.Header className="fw-bold border-bottom"><FaHistory className="text-info" /> Tranfer history</Card.Header>
              <Card.Body>
                <Row className="mb-2">
                  <Col>By:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{lead.transfer_by}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Date:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{formatDate(lead.transfer_date)}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Header className="fw-bold border-bottom"><FaExternalLinkAlt className="text-info" /> Related</Card.Header>
              <Card.Body>
                <div className="mb-2">
                  Opportunity ({opportunity?.id ? 1 : 0}): <Link to={`/crm/projects/${opportunity?.id}`} target="_blank">{opportunity?.name}</Link>
                </div>
                <div className="mb-2">
                  Customer ({customer?.id ? 1 : 0}): <Link to={`/crm/customers/${customer?.id}`} target="_blank">{customer?.name}</Link>
                </div>
                <div className="mb-2">
                  Contacts ({contacts?.length || 0}): {contacts && contacts.map(c => (
                    <Badge pill key={c.id} className="me-2">{c?.name}</Badge>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Suspense fallback={<div>Loading...!</div>}>
        {showSalesAction && (
          <LazySalesAction
            showModal={showSalesAction}
            setshowModal={setShowSalesAction}
            lead={lead}
          />
        )}

        {showUpdateProject && (
          <LazyUpdateProject
            showModal={showUpdateProject}
            setshowModal={setShowUpdateProject}
            lead={lead}
          />
        )}

        {showConvertPCVConfirm && (
          <LazyConvertPCVConfirm
            showModal={showConvertPCVConfirm}
            setshowModal={setShowConvertPCVConfirm}
            lead={lead}
          />
        )}
      </Suspense>
    </>
  )
};

Contacted.propTypes = {
  lead: PropTypes.object.isRequired
}

export default Contacted;