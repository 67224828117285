import IconAlert from "components/common/IconAlert";
import QuotationConclusion from "components/crm/reports/quotation/dashboard/QuotationConclusion";
import QuotationFunnel from "components/crm/reports/quotation/dashboard/QuotationFunnel";
import QuotationPipeline from "components/crm/reports/quotation/dashboard/QuotationPipeline";
import QuotationProcessing from "components/crm/reports/quotation/dashboard/QuotationProcessing";
import React from 'react';
import PropTypes from 'prop-types';

const Quotation = ({quotationData}) => {
  const {quotation} = quotationData || {};
  const {summary, data} = quotation || {}; //, monthly, sales_regions
  //const originalData = {monthly, sales_regions, data};
  const quotationProcessing = Array.isArray(data) ? data.filter(item => item.seq === 1) : [];

  return (
    <>
      <QuotationFunnel data={summary} />
      <QuotationConclusion data={summary} />
      <QuotationPipeline data={data} />
      <QuotationProcessing data={quotationProcessing} />

      <IconAlert variant="warning" dismissible className="my-3">
        <p className="mb-0 fs--1"> The case of projects with multiple buildings requiring quotations at different times, <strong className="text-danger">the number of projects recorded in the report is based on the date of the first quotation request, for the normally calculated volume</strong>.</p>
      </IconAlert>
    </>
 
  )
};

Quotation.propTypes = {
  quotationData: PropTypes.object.isRequired
}

export default Quotation;