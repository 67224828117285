import IconAlert from "components/common/IconAlert";
import React, { useEffect } from "react";
import { Form, Offcanvas, Row, Col, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Flex from "components/common/Flex";
import TinymceEditor from "components/common/TinymceEditor";
import { createChangeLog, updateChangeLog } from "redux/documentation/changeLogSlice";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

const ChangeLogForm = ({showModal, setshowModal, title, changeLog}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    if (changeLog) {
      Object.entries(changeLog).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [changeLog])

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = (data) => {
    console.log(data)

    try {
      let changeLogId = ""

      if (changeLog) {
        changeLogId = changeLog.id
        dispatch(updateChangeLog({changeLogId, data }))
      }
      else {
        dispatch(createChangeLog(data))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  return (
    <Offcanvas
      show={showModal}
      onHide={closeModal}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '60%'}}
    >
      <Offcanvas.Header className="bg-light" closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <IconAlert variant="warning" className="mt-2">
          <p className="mb-0">Make a release for application.</p>
        </IconAlert>

        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          
          <Form.Group as={Row} className="mb-2" controlId="title">
            <Form.Label column sm={2} className="text-lg-end">
              Title
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name="title"
                placeholder="Title"
                className="fs--1"
                {...register("title", {
                  required: "Title must be required."
                })}
              />
              <span className="text-danger">
                {errors.title && errors.title.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="version">
            <Form.Label column sm={2} className="text-lg-end">
              Version
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name="version"
                placeholder="Version"
                className="fs--1"
                {...register("version", {
                  required: "Version must be required."
                })}
              />
              <span className="text-danger">
                {errors.version && errors.version.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="body">
            <Form.Label column sm={2} className="text-lg-end">
              Content
            </Form.Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="body"
                rules={{ required: "Content must be required." }}
                render={({ field }) => (
                  <TinymceEditor
                    height="50vh"
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
              <span className="text-danger">
                {errors.body && errors.body.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col className="my-4">
              <Flex justifyContent="center">
                <Button size="sm" type="submit">Save</Button>
                <Button variant="danger" size="sm" className="ms-2" onClick={closeModal}>Cancel</Button>
              </Flex>
            </Col>
          </Form.Group>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

ChangeLogForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  changeLog: PropTypes.object.isRequired
}

export default ChangeLogForm;