import { useLocation } from "react-router-dom";
import EmployeeForm from "./EmployeeForm";
import React from "react";

const EditEmployee = () => {
  const location = useLocation();

  return (
    <EmployeeForm 
      title="Edit Employee"
      employee={location.state}
     />
  )
}

export default EditEmployee;
