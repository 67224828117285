import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card } from 'react-bootstrap';

const WarrantyHeader = () => {

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
        </Card.Header>
      </Card>
    </>
  );
};

export default WarrantyHeader;