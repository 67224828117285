import Avatar from "components/common/Avatar";
import React from "react";

const UserAvatar = ({user_avatar = {}, size='2xl', ...rest}) => {
  const {name, full_name, avatar_url} = user_avatar;

  return (
    <div className="d-flex align-items-center border rounded w-50 p-1">
      <Avatar
        src={avatar_url || undefined}
        size={size}
        name={name}
        isExact={avatar_url ? undefined : true}
        {...rest}
      /> <span className="ms-2">{full_name}</span>
    </div>
  );
};

export default UserAvatar;