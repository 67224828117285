import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog
} from "redux/crm/project/changeOrderSlice";
import ActivityHistory from "components/peb-grid/ActivityHistory";
import React from 'react';

const ChangeOrderActivity = () => {
  const {activityLogData} = useSelector(state => state.crm_change_order);

  const params = useParams();
  const {project_id, building_id, id} = params;

  return (
    <ActivityHistory
      activityLogData={activityLogData}
      setNestedPage={setNestedPage}
      setNestedPageSize={setNestedPageSize}
      setNestedParams={setNestedParams}
      getActivityLog={getActivityLog}
      projectId={project_id}
      buildingId={building_id}
      changeOrderId={id}
    />
  )
}

export default ChangeOrderActivity;