import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const projectUrl = "/crm/projects"

const generalJobBuildingUrl = (projectId, jobBuildingId) => (
  jobBuildingId ? `${projectUrl}/${projectId}/job_buildings/${jobBuildingId}` : `${projectUrl}/${projectId}/job_buildings`
)

const generalContractUrl = (projectId, jobBuildingId, contractId) => (
  contractId ? `${projectUrl}/${projectId}/job_buildings/${jobBuildingId}/contracts/${contractId}` : `${projectUrl}/${projectId}/job_buildings/${jobBuildingId}/contracts`
)

const generalAddendumtUrl = (projectId, jobBuildingId, contractId, addendumId) => (
  addendumId ? `${generalContractUrl(projectId, jobBuildingId, contractId)}/addendums/${addendumId}` : `${generalContractUrl(projectId, jobBuildingId, contractId)}/addendums`
);

export const getJobBuildings = createAsyncThunk(
  "JobBuilding/getJobBuildings",
  async ({ projectId, params } ) => {
    try {
      const url = generalJobBuildingUrl(projectId)
      const response = await apiAxios.get(url, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getJobBuilding = createAsyncThunk(
  "JobBuilding/getJobBuilding",
  async ({projectId, jobBuildingId}, { rejectWithValue }) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.get(url)
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getBuildingsHasJob = createAsyncThunk(
  "JobBuilding/getBuildingsHasJob",
  async ({projectId, params}, { rejectWithValue }) => {
    try {
      const url = generalJobBuildingUrl(projectId)
      const response = await apiAxios.get(`${url}/load_buildings`, {params})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createJobBuilding = createAsyncThunk(
  "JobBuilding/createJobBuilding",
  async ({projectId, data}, { rejectWithValue }) => {
    try {
      const url = generalJobBuildingUrl(projectId)
      const response = await apiAxios.post(url, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateJobBuilding = createAsyncThunk(
  "JobBuilding/updateJobBuilding",
  async ({ projectId, jobBuildingId, data }, { rejectWithValue }) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteJobBuilding = createAsyncThunk(
  "JobBuilding/deleteJobBuilding",
  async ({projectId, jobBuildingId}) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.delete(url);
      return response.data;
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadDocument = createAsyncThunk(
  "JobBuilding/uploadDocument",
  async ({ projectId, jobBuildingId, data }, { rejectWithValue }) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.post(`${url}/upload_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeDocument = createAsyncThunk(
  "JobBuilding/removeDocument",
  async ( { projectId, jobBuildingId, documentId }, { rejectWithValue }) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.post(`${url}/remove_document`, { document_id: documentId } );
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const sentToContractManagementJobBuilding = createAsyncThunk(
  "JobBuilding/sentToContractManagementJobBuilding",
  async ({projectId, jobBuildingId}) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.post(`${url}/send_to_contract_management`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const revokeToContractManagementJobBuilding = createAsyncThunk(
  "JobBuilding/revokeToContractManagementJobBuilding",
  async ({projectId, jobBuildingId}) => {
    try {
      const url = generalJobBuildingUrl(projectId, jobBuildingId)
      const response = await apiAxios.post(`${url}/revoke`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getContract = createAsyncThunk(
  "JobBuilding/getContract",
  async ({projectId, jobBuildingId, contractId, data}, { rejectWithValue }) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.get(url, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const createContract = createAsyncThunk(
  "JobBuilding/createContract",
  async ({projectId, jobBuildingId, data}, { rejectWithValue }) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId)
      const response = await apiAxios.post(url, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateContract = createAsyncThunk(
  "JobBuilding/updateContract",
  async ({ projectId, jobBuildingId, contractId, data }, { rejectWithValue }) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteContract = createAsyncThunk(
  "JobBuilding/deleteContract",
  async ({projectId, jobBuildingId, contractId}) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseContract = createAsyncThunk(
  "JobBuilding/releaseContract",
  async ({projectId, jobBuildingId, contractId, data}) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.post(`${url}/release`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const unreleaseContract = createAsyncThunk(
  "JobBuilding/unreleaseContract",
  async ({projectId, jobBuildingId, contractId}) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.post(`${url}/unrelease`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadContractDocument = createAsyncThunk(
  "JobBuilding/uploadContractDocument",
  async ({ projectId, jobBuildingId, contractId, documentType, data }, { rejectWithValue }) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)

      const response = await apiAxios.post(`${url}/upload_${documentType}_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeContractDocument = createAsyncThunk(
  "JobBuilding/removeContractDocument",
  async ( { projectId, jobBuildingId, contractId, documentType, documentId }, { rejectWithValue }) => {
    try {
      const url = generalContractUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.post(`${url}/remove_${documentType}_document`, { document_id: documentId } );
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const createAddendum = createAsyncThunk(
  "JobBuilding/createAddendum",
  async ({projectId, jobBuildingId, contractId, data}, { rejectWithValue }) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId)
      const response = await apiAxios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateAddendum = createAsyncThunk(
  "JobBuilding/updateAddendum",
  async ({ projectId, jobBuildingId, contractId, addendumId, data }, { rejectWithValue }) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.put(url, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteAddendum = createAsyncThunk(
  "JobBuilding/deleteAddendum",
  async ({projectId, jobBuildingId, contractId, addendumId}) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const sentAddendumToContractManagement = createAsyncThunk(
  "JobBuilding/sentAddendumToContractManagement",
  async ({projectId, jobBuildingId, contractId, addendumId}) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.post(`${url}/send_to_contract_management`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseAddendum = createAsyncThunk(
  "JobBuilding/releaseAddendum",
  async ({projectId, jobBuildingId, contractId, addendumId, data}) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.post(`${url}/release`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const unreleaseAddendum = createAsyncThunk(
  "JobBuilding/unreleaseAddendum",
  async ({projectId, jobBuildingId, contractId, addendumId}) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.post(`${url}/unrelease`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadAddendumDocument = createAsyncThunk(
  "JobBuilding/uploadSalesAddendumDocument",
  async ({ projectId, jobBuildingId, contractId, addendumId, documentType, data }, { rejectWithValue }) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.post(`${url}/upload_${documentType}_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeAddendumDocument = createAsyncThunk(
  "JobBuilding/removeAddendumDocument",
  async ( { projectId, jobBuildingId, contractId, addendumId, documentId, documentType }, { rejectWithValue }) => {
    try {
      const url = generalAddendumtUrl(projectId, jobBuildingId, contractId, addendumId)
      const response = await apiAxios.post(`${url}/remove_${documentType}_document`, { document_id: documentId } );
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const jobBuilding = data.attributes

    if (jobBuilding !== null) {
      switch (type) {
        case "create": 
          state.data.unshift(jobBuilding)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== jobBuilding.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex((item) => item.id === jobBuilding.id)
          if (updatedIndex !== -1) {
            state.data[updatedIndex] = jobBuilding
          }

          break;
        }
      }
    }
    toast.success(message);
  }
};

const handlePayloadContract = (state, action, type="none") => {
  const { error, job_building_id, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const contract = data.attributes
    let jobBuilding = state.data.find((item) => item.id === job_building_id)

    if (jobBuilding && contract !== null) {
      switch (type) {
        case "delete":
          jobBuilding.contract = null
          break;
        default:
          jobBuilding.contract = contract
          break;
      }
    }
    toast.success(message);
  }
};

const handlePayloadAddendum = (state, action, type="none") => {
  const { error, job_building_id, contract_id, addendum_id, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    let jobBuilding = state.data.find((item) => item.id === job_building_id)

    if (jobBuilding && jobBuilding.contract && contract_id) {
      let contract = jobBuilding.contract 
      let addendum = null

      switch (type) {
        case "create":
          addendum = data.attributes
          contract.addendums.unshift(addendum)
          break;

        case "delete":
          if (addendum_id) {
            contract.addendums = contract.addendums.filter((item) => item.id !== addendum_id)
          }
          break;
        default:
        {
          addendum = data.attributes
          const updatedIndex = contract.addendums.findIndex((item) => item.id === addendum.id)
          if (updatedIndex !== -1) {
            contract.addendums[updatedIndex] = addendum
          }
          break;
        }
      }
    }
    toast.success(message);
  }
};

const jobBuildingSlice = createSlice({
  name: "crm_project_job_building",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    jobBuildingData: {}
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobBuilding.fulfilled, (state, action) => {
        state.jobBuildingData = action.payload
      })
      .addCase(getJobBuildings.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate           = state.paginate
        const calRecords         = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(createJobBuilding.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteJobBuilding.fulfilled, (state, action) => {
        //handlePayload(state, action, "delete")
        const { error, jobBuildingId, message } = action.payload;

        if (error !== undefined) {
          toast.error(error);
        } 
        else
        {
          if (jobBuildingId) {
            state.data = state.data.filter((item) => item.id !== jobBuildingId)
            toast.success(message)
          }
        }
      })
      .addCase(updateJobBuilding.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(sentToContractManagementJobBuilding.fulfilled, (state, action) => {
        const { error, message } = action.payload;

        if (error !== undefined) {
          toast.error(error);
        } else {
          toast.success(message)
        }
      })
      .addCase(revokeToContractManagementJobBuilding.fulfilled, (state, action) => {
        const { error, message } = action.payload;

        if (error !== undefined) {
          toast.error(error);
        } else {
          toast.success(message)
        }
      })
      .addCase(createContract.fulfilled, (state, action) => {
        handlePayloadContract(state, action)
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        handlePayloadContract(state, action)
      })
      .addCase(deleteContract.fulfilled, (state, action) => {
        handlePayloadContract(state, action, "delete")
      })
      .addCase(uploadContractDocument.fulfilled, (state, action) => {
        handlePayloadContract(state, action)
      })
      .addCase(removeContractDocument.fulfilled, (state, action) => {
        handlePayloadContract(state, action)
      })
      .addCase(releaseContract.fulfilled, (state, action) => {
        handlePayloadContract(state, action)
      })
      .addCase(unreleaseContract.fulfilled, (state, action) => {
        handlePayloadContract(state, action)
      })
      .addCase(createAddendum.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action, "create")
      })
      .addCase(updateAddendum.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action)
      })
      .addCase(deleteAddendum.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action, "delete")
      })
      .addCase(uploadAddendumDocument.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action)
      })
      .addCase(removeAddendumDocument.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action)
      })
      .addCase(sentAddendumToContractManagement.fulfilled, (state, action) => {
        const { error, message } = action.payload;

        if (error !== undefined) {
          toast.error(error);
        } else {
          toast.success(message)
        }
      })
      .addCase(releaseAddendum.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action)
      })
      .addCase(unreleaseAddendum.fulfilled, (state, action) => {
        handlePayloadAddendum(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams
} = jobBuildingSlice.actions;

export default jobBuildingSlice.reducer;