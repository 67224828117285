import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Card, Col, Nav, Row, Button, Tab, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from "react-hook-form"
import GDS from './form/GDS';
import Areas from './form/Areas';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPIF, resetExpandAll, setCollapseTabStatus, setExpandAll, updatePIF, updatePIFVersion } from 'redux/crm/project/pifSlice';
import IconButton from 'components/common/IconButton';
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import Flex from 'components/common/Flex';
import { FaWpforms } from 'react-icons/fa';
import { MdCancel, MdDataSaverOff } from 'react-icons/md';
import PropTypes from 'prop-types';
import { FaChevronCircleUp } from "react-icons/fa";

const LazyPaintSystemForm = lazy(() => import('./form/PaintSystemForm'));
const LazySecondaryMemberForm = lazy(() => import('./form/SecondaryMemberForm'));

//Area
const LazyAreaFormModal = lazy(() => import("./form/area/AreaFormModal"))
const LazyCloneAreaModal = lazy(() => import("./form/area/CloneAreaModal"))
const LazyAreaDeleteConfirmation = lazy(() => import("./form/AreaDeleteConfirmation"))

//BDS1
const LazyOpenWallForm = lazy(() => import('./form/area/OpenWallForm.js'))

//BDS2
const LazyRoofExtensionForm = lazy(() => import('./form/area/RoofExtensionForm.js'))
const LazyCanopyForm = lazy(() => import('./form/area/CanopyForm.js'))
const LazyFasciaForm = lazy(() => import('./form/area/FasciaForm.js'))
const LazyFrameOpeningForm = lazy(() => import('./form/area/FrameOpeningForm.js'))
const LazyMezzanineForm = lazy(() => import('./form/area/MezzanineForm.js'))
const LazyCraneInformationForm = lazy(() => import('./form/area/CraneInformationForm.js'))

const PIFForm = ({project, building, adaptation, pif, title, isPIFversion}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues} = useForm();
  const {collapseTabStatus} = useSelector(state => state.crm_project_pif);
  const gds = pif?.gds || {};

  const [gdsData, setGdsData] = useState(
    pif ? gds : {
      paint_systems: [],
      secondary_members: []
    }
  )

  const [areaData, setAreaData] = useState(
    pif ? pif.areas : [
      {
        id: 1,
        name: "Area 1",
        bds1: {},
        bds2: {},
        bds3: {}
      }
    ]
  )

  const createInitialState = () => ({
    data: [],
    item: {},
    title: null,
    edit: false,
    showModal: false
  });
  
  const [paintSystem, setPaintSystem] = useState(createInitialState());
  const [secondaryMember, setSecondaryMember] = useState(createInitialState()); 

  const [openWall, setOpenWall] = useState(createInitialState());
  const [roofExtension, setRoofExtension] = useState(createInitialState());
  const [canopy, setCanopy] = useState(createInitialState());
  const [fascia, setFascia] = useState(createInitialState());
  const [frameOpening, setFrameOpening] = useState(createInitialState());
  const [mezzanine, setMezzanine] = useState(createInitialState());
  const [craneInformation, setCraneInformation] = useState(createInitialState());

  const [showAreaModal, setShowAreaModal] = useState(false)
  const [showCloneModal, setShowCloneModal] = useState(false)
  const [showDeleteAreaConfirmation, setShowDeleteAreaConfirmation] = useState(false)
  const [titleArea, setTitleArea] = useState(null)
  const [area, setArea] = useState(null)
  const [draftAreas, setDraftAreas] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const projectId = project?.id
  const buildingId = building?.id
  const adaptationId = adaptation?.id

  const projectData = {
    project,
    building,
    adaptation,
  }

  const useHookForm = {
    Controller,
    control,
    register,
    setValue,
    errors
  }

  const areaObject = {
    areaData,
    setAreaData
  }

  useEffect(() => {
    if (gds && Object.keys(gds).length > 0) {
      setPaintSystem({ data: gds?.paint_systems || []});
      setSecondaryMember({ data: gds?.secondary_members || [] });
    }
  }, [gds]);

  useEffect(() => {
    setGdsData(prevData => ({
      ...prevData,
      paint_systems: paintSystem.data || [],
      secondary_members: secondaryMember.data || []
    }));
  }, [paintSystem.data, secondaryMember.data]);

  useEffect(() => {
    if (areaData) {
      let openWallsData = [];
      let roofExtensionsData = [];
      let canopiesData = [];
      let fasciasData = [];
      let frameOpeningsData = [];
      let mezzaninesData = [];
      let craneInformationsData = [];

      // Iterate over each area and accumulate data for each property
      areaData.forEach(area => {
        // Accumulate data for open walls
        openWallsData  = accumulateData(openWallsData, area, "bds1", "open_walls")

        // Accumulate data for bds2
        roofExtensionsData  = accumulateData(roofExtensionsData, area, "bds2", "roof_extensions")
        canopiesData        = accumulateData(canopiesData, area, "bds2", "canopies")
        fasciasData         = accumulateData(fasciasData, area, "bds2", "fascias")
        frameOpeningsData   = accumulateData(frameOpeningsData, area, "bds2", "frame_openings")
        mezzaninesData      = accumulateData(mezzaninesData, area, "bds2", "mezzanines")
        craneInformationsData = accumulateData(craneInformationsData, area, "bds2", "crane_informations")
      });

      // Set the accumulated data for each property
      setOpenWall({ data: openWallsData });
      setRoofExtension({ data: roofExtensionsData });
      setCanopy({ data: canopiesData });
      setFascia({ data: fasciasData });
      setFrameOpening({ data: frameOpeningsData });
      setMezzanine({ data: mezzaninesData });
      setCraneInformation({ data: craneInformationsData });
    }
  }, [areaData])

  const accumulateData = (data, area, bds, property) => {
    const newData = area?.[bds]?.[property]?.map(item => ({
      ...item,
      area_id: area.id
    })) || []

    return data.concat(newData);
  }

  const transformDataByPrefix = (data, prefix) => {
    return Object.entries(data).reduce((result, [key, value]) => {
      const match = key.match(new RegExp(`^${prefix}_(.*?)(?:_(\\d+))?$`)); //bds1_${key}_${area.id}
  
      if (match) {
        const [, propertyName, id] = match;
  
        const existingItem = result.find((item) => item.id === id);
  
        if (existingItem) {
          existingItem[prefix][propertyName] = value;
        } else {
          result.push({
            id,
            [prefix]: { [propertyName]: value }
          });
        }
      }
  
      return result;
    }, []);
  };

  const findArea = (data, area_id) => {
    return data.filter(item => String(item.area_id) === String(area_id))
  }
  
  const transformedObject = (data) => {
    const bds1Data = transformDataByPrefix(data, 'bds1');
    const bds2Data = transformDataByPrefix(data, 'bds2');
    const bds3Data = transformDataByPrefix(data, 'bds3');

    const areaIds = areaData.map(area => area.id)?.toString()
    
    const filteredBds1Data = bds1Data.filter(item => areaIds.includes(item.id));
    bds1Data.forEach(item => {
      item.bds1.open_walls = findArea(openWall.data, item.id);
    });
  
    let bds2 = null
    const filteredBds2Data = bds2Data.filter(item => areaIds.includes(item.id));
    bds2Data.filter(item => areaIds.includes(item.id)).forEach(item => {
      bds2 = item.bds2

      bds2.roof_extensions  = findArea(roofExtension.data, item.id);
      bds2.canopies         = findArea(canopy.data, item.id);
      bds2.fascias          = findArea(fascia.data, item.id);
      bds2.frame_openings   = findArea(frameOpening.data, item.id);
      bds2.mezzanines       = findArea(mezzanine.data, item.id);
      bds2.crane_informations = findArea(craneInformation.data, item.id);
    });

    const filteredBds3Data = bds3Data.filter(item => areaIds.includes(item.id))

    return { bds1Data: filteredBds1Data, bds2Data: filteredBds2Data, bds3Data: filteredBds3Data };
  };

  const mergeObjects = (bds1Data, bds2Data, bds3Data) => {
    const mergedArray = [];
  
    // Combine objects based on id
    bds1Data.forEach(({ id, bds1 }) => {
      const mergedObject = { id, bds1, bds2: {}, bds3: {} };
  
      // Find matching bds2Data and bds3Data based on id
      const matchingBds2 = bds2Data.find((item) => item.id === id);
      const matchingBds3 = bds3Data.find((item) => item.id === id);
  
      // Merge matching bds2Data and bds3Data into the mergedObject
      if (matchingBds2) {
        mergedObject.bds2 = matchingBds2.bds2;
      }
      if (matchingBds3) {
        mergedObject.bds3 = matchingBds3.bds3;
      }
  
      mergedArray.push(mergedObject);
    });
  
    return mergedArray;
  };

  const processData = (data) => {
    const { bds1Data, bds2Data, bds3Data } = transformedObject(data)
    const result = {gds: data.gds, areas: mergeObjects(bds1Data, bds2Data, bds3Data)}

    return result;
  }

  const onSubmit = async (data, event) => {
    //console.log(data)
    const result = processData(data);

    const actionType = event.nativeEvent.submitter.value;
    const saveAndClose = actionType === "createOrUpdate";

    try {
      let pifId = null

      if (pif) {
        pifId = pif.id

        if (isPIFversion) {
          await dispatch(updatePIFVersion({projectId, buildingId, adaptationId, pifId, data: result }));
          toast.success("PIF was successful updated.");
        }
        else {
          await dispatch(updatePIF({projectId, buildingId, adaptationId, pifId, data: result }))
        }
      }
      else {
        const response = await dispatch(createPIF({projectId, buildingId, adaptationId, data: result}))

        pifId = response.payload.data?.id;

        // if (pifId)
        // {
        //   const buildingUrl = `/crm/projects/${projectId}/buildings/${buildingId}`;
        //   const pifUrl = isPIFversion ? `${buildingUrl}/change-orders/${adaptationId}/pifs-versions/${pifId}` : `${buildingUrl}/adaptations/${adaptationId}/pifs/${pifId}`;
          
        //   navigate(pifUrl)
        // }
      }

      if (pifId) {
        const buildingUrl = `/crm/projects/${projectId}/buildings/${buildingId}`;
        const pifUrl = isPIFversion ? `${buildingUrl}/change-orders/${adaptationId}/pifs-versions/${pifId}` : `${buildingUrl}/adaptations/${adaptationId}/pifs/${pifId}`;

        if (saveAndClose) {
          navigate(pifUrl)
        }
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  const createMainFormState = (prevState, title, edit, smItem = null, areaId = null) => ({
    ...prevState,
    title,
    edit,
    item: smItem,
    area_id: areaId,
    showModal: true,
  });

  const handleDeleteAction = (stateUpdater, setStateUpdater, item, entityName, areaId, successMessage=`${entityName} ID#${item.id} was removed.`) => {
    let newData = null

    if (areaId) {
      newData = stateUpdater.data.filter(
        (dataItem) => !(dataItem.id === item.id && dataItem.area_id === areaId)
      )
    }
    else {
      newData = stateUpdater.data.filter((sitem) => item.id !== sitem.id)
    }

    setStateUpdater((preState) => 
      ({
        ...preState,
        data: newData
      })
    )
    toast.success(successMessage)
  }

  // GDS
  const handleNewPaintSystem = () => {
    setPaintSystem(createMainFormState(paintSystem, "Create a new Paint system", false, null, null));
  }

  const handleEditPaintSystem = (item) => {
    setPaintSystem(createMainFormState(paintSystem, "Edit Paint system", true, item, null));
  }

  const handleDeletePaintSystem = (item) => {
    handleDeleteAction(paintSystem, setPaintSystem, item, "Paint system", null, `Paint system Cost no #${item.cost_no} was removed.`)
  }

  const handleNewSecondaryMember = () => {
    setSecondaryMember(createMainFormState(secondaryMember, "Create a new Secondary Member", false, null, null));
  }

  const handleEditSecondaryMember = (item) => {
    setSecondaryMember(createMainFormState(secondaryMember, "Edit Secondary Member", true, item, null));
  }

  const handleDeleteSecondaryMember = (item) => {
    handleDeleteAction(secondaryMember, setSecondaryMember, item, "Secondary Member", null, `Secondary Memeber Cost no #${item.cost_no} was removed.`)
  }


  //Area => BDS
  const handleNewOpenWall = (areaId) => {
    setOpenWall(createMainFormState(openWall, "Create a new Open Wall", false, null, areaId));
  }

  const handleEditOpenWall = (areaId, item) => {
    setOpenWall(createMainFormState(openWall, "Edit Open Wall", true, item, areaId));
  }

  const handleDeleteOpenWall = (areaId, item) => {
    handleDeleteAction(openWall, setOpenWall, item, "Open Wall", areaId, `Open Wall location #${item.location} was removed.`)
  }

  const handleNewRoofExtension = (areaId) => {
    setRoofExtension(createMainFormState(roofExtension, "Create a new Roof Entension", false, null, areaId));
  }

  const handleEditRoofExtension = (areaId, item) => {
    setRoofExtension(createMainFormState(roofExtension, "Edit Roof Entension", true, item, areaId));
  }

  const handleDeleteRoofExtension = (areaId, item) => {
    handleDeleteAction(roofExtension, setRoofExtension, item, "Roof Extension", areaId, `Roof Extension location #${item.location} was removed.`)
  }

  const handleNewCanopy = (areaId) => {
    setCanopy(createMainFormState(canopy, "Create a new Canopy", false, null, areaId));
  }

  const handleEditCanopy = (areaId, item) => {
    setCanopy(createMainFormState(canopy, "Edit Canopy", true, item, areaId));
  }

  const handleDeleteCanopy = (areaId, item) => {
    handleDeleteAction(canopy, setCanopy, item, canopy, areaId, `Canopy location #${item.location} was removed.`)
  }

  const handleNewFascia = (areaId) => {
    setFascia(createMainFormState(fascia, "Create a new Fascia", false, null, areaId));
  }

  const handleEditFascia = (areaId, item) => {
    setFascia(createMainFormState(fascia, "Edit Fascia", true, item, areaId));
  }

  const handleDeleteFascia = (areaId, item) => {
    handleDeleteAction(fascia, setFascia, item, "Fascia", areaId, `Fascia location #${item.location} was removed.`)
  }

  const handleNewFrameOpening = (areaId) => {
    setFrameOpening(createMainFormState(frameOpening, "Create a new Frame Opening", false, null, areaId));
  }

  const handleEditFrameOpening = (areaId, item) => {
    setFrameOpening(createMainFormState(frameOpening, "Edit Frame Opening", true, item, areaId));
  }

  const handleDeleteFrameOpening = (areaId, item) => {
    handleDeleteAction(frameOpening, setFrameOpening, item, "Frame Opening", areaId, `Frame Opening location #${item.location} was removed.`)
  }

  const handleNewMezzanine = (areaId) => {
    setMezzanine(createMainFormState(mezzanine, "Create a new Mezzanine", false, null, areaId));
  }

  const handleEditMezzanine = (areaId, item) => {
    setMezzanine(createMainFormState(mezzanine, "Edit Mezzanineg", true, item, areaId));
  }

  const handleDeleteMezzanine = (areaId, item) => {
    handleDeleteAction(mezzanine, setMezzanine, item, "Mezzanineg", areaId, `Mezzanine Mz no.#${item.mz_no} was removed.`, "mz_no")
  }

  const handleNewCraneInformation = (areaId) => {
    setCraneInformation(createMainFormState(craneInformation, "Create a new Crane Information", false, null, areaId));
  }

  const handleEditCraneInformation = (areaId, item) => {
    setCraneInformation(createMainFormState(craneInformation, "Edit Crane Information", true, item, areaId));
  }

  const handleDeleteCraneInformation = (areaId, item) => {
    handleDeleteAction(craneInformation, setCraneInformation, item, "Crane", areaId, `Crane Information no.#${item.no} was removed.`, "crane_sr")
  }

   const handleNewArea = () => {
    setTitleArea("Create new Area")
    setShowAreaModal(true)
    setArea(null)
  }

  const handleEditArea = (area) => {
    setTitleArea("Edit Area")
    setShowAreaModal(true)
    setArea(area)
  }

  const handleCloneArea = () => {
    setShowCloneModal(true)

    const formData = getValues();
    const { bds1Data, bds2Data, bds3Data } = transformedObject(formData)

    setDraftAreas(mergeObjects(bds1Data, bds2Data, bds3Data))
  }

  const handleDeleteArea = (area) => {
    setShowDeleteAreaConfirmation(true)
    setArea(area)
  }

  const handleSubmitAreaDelete = () => {
    const newAreaData = areaData.filter((item) => item.id !== area.id)
    if (newAreaData) {
      setAreaData(newAreaData)
      setShowDeleteAreaConfirmation(false)
      toast.success(`Area ${area.id} was successful deleted.`)
    }
    else {
      toast.error(`Error, something went wrong deleting Area ${area.id}.`)
   }
  }

  const handleExpandAll = () => {
    if (collapseTabStatus) {
      dispatch(setExpandAll())
    }
    else {
      dispatch(resetExpandAll())
    }

    dispatch(setCollapseTabStatus(!collapseTabStatus))
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={collapseTabStatus ? faAngleDoubleDown : faAngleDoubleUp}
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleExpandAll}
            >
              <span className="d-none d-xl-inline-block ms-1">Collapse All</span>
            </IconButton>
          </Flex>
        </Card.Header>
        <Card.Body className="fs--1 text-center">
          <h5>{title} Project Information Form (PIF)</h5>
          <div>Project: <span className="fw-semi-bold text-primary">{project?.name}</span></div>
          <div>Building: <span className="fw-semi-bold text-primary">{building?.name}</span></div>

          {adaptation?.type == "change_order" ? (
            <div>CO#: <span className="fw-semi-bold text-primary">{adaptation?.co}</span></div>
          ) : (
            <div>REV#: <span className="fw-semi-bold text-primary">{adaptation?.rev}</span></div>
          )}
        </Card.Body>
      </Card>

      <Row className="g-3">
        <Col>
          <Card>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Tab.Container defaultActiveKey="PIF-GDS">
                <SimpleBar>
                  <Card.Header className="p-0 border-bottom">
                    <Nav className="nav-tabs border-0 flex-nowrap tab-pif-form">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="PIF-GDS"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon="list"
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1"> General Data Sheet (GDS)</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="PIF-Area"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon="calendar"
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1"> Areas</h6>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                </SimpleBar>
                <Card.Body className="bg-light">
                  <Tab.Content>
                    <Tab.Pane eventKey="PIF-GDS">
                      <GDS
                        projectData={projectData}
                        gdsData={gdsData}
                        useHookForm={useHookForm}

                        paintSystemData={paintSystem.data}
                        newPaintSystem={handleNewPaintSystem}
                        editPaintSystem={handleEditPaintSystem}
                        deletePaintSystem={handleDeletePaintSystem}

                        secondaryMemberData={secondaryMember.data}
                        newSecondaryMember={handleNewSecondaryMember}
                        editSecondaryMember={handleEditSecondaryMember}
                        deleteSecondaryMember={handleDeleteSecondaryMember}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="PIF-Area">
                      <Areas
                        projectData={projectData}
                        useHookForm={useHookForm}
                        areaObject={areaObject}
                        newArea={handleNewArea}
                        editArea={handleEditArea}
                        cloneArea={handleCloneArea}
                        deleteArea={handleDeleteArea}
                        openWallData={openWall.data}
                        newOpenWall={handleNewOpenWall}
                        editOpenWall={handleEditOpenWall}
                        deleteOpenWall={handleDeleteOpenWall}
                        roofExtensionData={roofExtension.data}
                        newRoofExtension={handleNewRoofExtension}
                        editRoofExtension={handleEditRoofExtension}
                        deleteRoofExtension={handleDeleteRoofExtension}
                        canopyData={canopy.data}
                        newCanopy={handleNewCanopy}
                        editCanopy={handleEditCanopy}
                        deleteCanopy={handleDeleteCanopy}
                        fasciaData={fascia.data}
                        newFascia={handleNewFascia}
                        editFascia={handleEditFascia}
                        deleteFascia={handleDeleteFascia}
                        frameOpeningData={frameOpening.data}
                        newFrameOpening={handleNewFrameOpening}
                        editFrameOpening={handleEditFrameOpening}
                        deleteFrameOpening={handleDeleteFrameOpening}
                        mezzanineData={mezzanine.data}
                        newMezzanine={handleNewMezzanine}
                        editMezzanine={handleEditMezzanine}
                        deleteMezzanine={handleDeleteMezzanine}
                        craneInformationData={craneInformation.data}
                        newCraneInformation={handleNewCraneInformation}
                        editCraneInformation={handleEditCraneInformation}
                        deleteCraneInformation={handleDeleteCraneInformation}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>

              <div className="bg-light fixed-bottom border-0 text-center p-3">
                {pif && (
                  <Button size="sm" type="submit" name="action" value="saveAsDraft" className="me-1">
                    <MdDataSaverOff /> Save
                  </Button>
                )}
                <Button size="sm" type="submit" className="me-1" name="action" value="createOrUpdate">
                  <FaWpforms /> {pif ? "Save and close" : "Create PIF"}
                </Button>
                <Button size="sm" variant="danger" onClick={() => navigate(-1)}> <MdCancel />{pif ? 'Close' : 'Cancel'}</Button>
              </div>
            </Form>

            <div className="fs--1 m-2 text-end">{}</div>
          </Card>
        </Col>
      </Row>

      <div 
        onClick={scrollToTop} 
        style={styles.backToTopButton}
        title="Back to top"
        size='sm'
      >
        <FaChevronCircleUp />
      </div>

      <Suspense fallback={<div className='text-center p-3'>Loading....</div>}>
        {paintSystem && paintSystem.showModal && (
          <LazyPaintSystemForm
            dataObject={paintSystem}
            setDataObject={setPaintSystem}
          />
        )}

        {secondaryMember && secondaryMember.showModal && (
          <LazySecondaryMemberForm
            dataObject={secondaryMember}
            setDataObject={setSecondaryMember}
          />
        )}

        {openWall && openWall.showModal && (
          <LazyOpenWallForm
            dataObject={openWall}
            setDataObject={setOpenWall}
          />
        )}

        {roofExtension && roofExtension.showModal && (
          <LazyRoofExtensionForm
            dataObject={roofExtension}
            setDataObject={setRoofExtension}
          />
        )}

        {canopy && canopy.showModal && (
          <LazyCanopyForm 
            dataObject={canopy}
            setDataObject={setCanopy}
          />
        )}

        {fascia && fascia.showModal && (
          <LazyFasciaForm
            dataObject={fascia}
            setDataObject={setFascia}
          />
        )}

        {frameOpening && frameOpening.showModal && (
          <LazyFrameOpeningForm
            dataObject={frameOpening}
            setDataObject={setFrameOpening}
          />
        )}

        {mezzanine && mezzanine.showModal && (
          <LazyMezzanineForm
            dataObject={mezzanine}
            setDataObject={setMezzanine}
          />
        )}

        {craneInformation && craneInformation.showModal && (
          <LazyCraneInformationForm
            dataObject={craneInformation}
            setDataObject={setCraneInformation}
          />
        )}

        {showAreaModal && (
          <LazyAreaFormModal
            showModal={showAreaModal}
            setshowModal={setShowAreaModal}
            title={titleArea}
            areaObject={areaObject}
            area={area}
          />
        )}
        
        {showCloneModal && (
          <LazyCloneAreaModal
            showModal={showCloneModal}
            setshowModal={setShowCloneModal}
            areaObject={areaObject}
            draftAreas={draftAreas}
          />
        )}

        {showDeleteAreaConfirmation && (
          <LazyAreaDeleteConfirmation
            showModal={showDeleteAreaConfirmation}
            setshowModal={setShowDeleteAreaConfirmation}
            area={area}
            onSubmitAreaDelete={handleSubmitAreaDelete}
          />
        )}

      </Suspense>
    </>
  )
};

const styles = {
  backToTopButton: {
    position: 'fixed',
    bottom: '15px',
    right: '35px',
    cursor: 'pointer',
    fontSize: '26px',
    zIndex: '2000'
  }
};

PIFForm.propTypes = {
  project: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  adaptation: PropTypes.object.isRequired,
  pif: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isPIFversion: PropTypes.bool.isRequired,
}

export default PIFForm;