import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createRole, updateRole } from "redux/authorization/roleSlice";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaBan, FaSave } from "react-icons/fa";

const RoleForm = ({showModal, setshowModal, title, role}) => {
  const { register, handleSubmit, formState: {errors}, setValue } = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let roleId = role ? role.id : null

  useEffect(() => {
    if(role && title === 'Edit Role') {
      setValue('name', role.name)
    } 
    else {
      setValue('name', '')
    }
  }, [role, title]);
  
  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (role) {
        dispatch(updateRole({roleId, data})) 
      }
      else {
        const response = await dispatch(createRole(data))
        roleId = response.payload.data.id
      }

      closeModal()
      navigate(`/authorization/roles/${roleId}`)
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column sm={3} className="text-lg-end">
                Name
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  name="name"
                  placeholder="Role name"
                  autoComplete="role-name"
                  className="fs--1"
                  {...register("name", {
                    required: "Name must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.name && errors.name.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><FaSave /> Save</Button>
            <Button size="sm" variant="danger" onClick={handleCancel}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

RoleForm.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.bool,
  title: PropTypes.string,
  role: PropTypes.object
}

export default RoleForm