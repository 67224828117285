import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { ExportToExcel } from "helpers/utils";
import { useMemo } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';

const LeadTable = ({data=[]}) => {
  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: 'Lead ID#',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold'
      }
    },
    {
      accessor: 'name',
      Header: 'Lead name',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { id, name } = rowData.row.original;
        return <Link to={`/crm/leads/${id}`} target="_blank">{name}</Link>;
      }
    },
    {
      accessor: 'account_name',
      Header: 'Account name',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { account_name, company_id } = rowData.row.original;
        return  company_id === null ? account_name : (
<         Link to={`/crm/customers/${company_id}`} target="_blank">{account_name}</Link>
        );
      }
    },
    {
      accessor: 'source',
      Header: 'Source'
    },
    {
      accessor: 'contact_mode',
      Header: 'Contact mode'
    },
    {
      accessor: 'stage',
      Header: 'EST weight'
    },
    {
      accessor: 'status_name',
      Header: 'Status'
    },
    {
      accessor: 'work_phone',
      Header: 'Work phone'
    },
    {
      accessor: 'website',
      Header: 'Website'
    },
    {
      accessor: 'rating',
      Header: 'Rating'
    },
    {
      accessor: 'industry',
      Header: 'Industry'
    },
    {
      accessor: 'owner_by',
      Header: 'Owner'
    },
    {
      accessor: 'sales_unit_region',
      Header: 'Sales region'
    },
    {
      accessor: 'created_date',
      Header: 'Created date'
    }
  ], [data]);

  const handleExport = (data) => {
    ExportToExcel(data, "lead-data")
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      selection
      sortable
      pagination
      perPage={15}
      rowCount={data?.length}
    >
      <Card className="p-2">
        <Card.Header>
          <Flex justifyContent="end" className="mb-0" alignContent="center" alignItems="center">
            <AdvanceTableSearchBox table className="w-md-25 w-sm-50" />
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
              onClick={() => handleExport(data)}
              style={{ whiteSpace: 'nowrap' }}
              className="ms-1"
            >
              <span className="ms-1">Export</span>
            </IconButton>
          </Flex>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="btn-reveal-trigger text-nowrap align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={data?.length} table rowInfo navButtons rowsPerPageSelection />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

LeadTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default LeadTable;