import { ListGroup, Row, Col, Badge, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { driverConfirm } from "redux/booking/car/carBookingSlice";
import TransactionDetailDriverTransFormModal from "./TransactionDetailDriverTransFormModal";
import React, { useState } from "react";
import { FaBan, FaEdit, FaEllipsisV, FaUserCircle } from "react-icons/fa";
import DriverTranDeleteConfirm from "./DriverTranDeleteConfirm";
import PropTypes from 'prop-types';

const TransactionDetailApprovalDriver = ({transaction}) => {
  const [showForm, setShowForm] = useState(false);
  const [driverTran, setDriverTran] = useState(null);
  const transactionId = transaction.id;
  const driver_trans = transaction?.driver_trans || [];
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const dispatch = useDispatch();

  const handleConfirm = (driverTranId) => {
    dispatch(driverConfirm({transactionId, driverTranId}));
  }

  const handleEditDriverTran = (id) => {
    setShowForm(true)
    const record = driver_trans.filter((item) => item.id === id)[0];
    setDriverTran(record);
  }

  const handleDeleteDriverTran = (record) => {
    setShowDeleteConfirm(true);
    setDriverTran(record);
  }

  const DriverItem = ({driver_tran}) => {
    const {
      allow_hr_admin_edit,
      allow_confirm,
      allow_delete
    } = driver_tran?.permissions || {};

    return (
      <ListGroup.Item className='p-3 p-sm-4 light' key={driver_tran.id}>
        <Row className="mb-3">
          <Col className="text-end">
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_hr_admin_edit && (
                    <Dropdown.Item className="mb-2" onClick={() => handleEditDriverTran(driver_tran.id)}>
                      <FaEdit /> Edit transaction
                    </Dropdown.Item>
                  )}
                  
                  {allow_delete && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item className="mb-2 text-danger" onClick={() => handleDeleteDriverTran(driver_tran)}>
                        <FaBan /> Cancel
                      </Dropdown.Item>
                    </>
                  )}
                  
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <Row className="mb-1">
          <Col>Car number</Col>
          <Col className="text-primary">{driver_tran.car?.name} / <strong>{driver_tran.car?.model}</strong></Col>
        </Row>
        <Row className="mb-1">
          <Col>Driver name</Col>
          <Col className="text-primary">{driver_tran.driver?.name}</Col>
        </Row>
        <Row className="mb-1">
          <Col>Email</Col>
          <Col className="text-primary">{driver_tran.driver?.email}</Col>
        </Row>
        <Row className="mb-1">
          <Col>Phone number</Col>
          <Col className="text-primary">{driver_tran.driver?.phone}</Col>
        </Row>

        {driver_tran.confirmed ? (
          <>
            <Row>
              <Col className="text-danger">Confirmed?</Col>
              <Col>
                <FontAwesomeIcon icon="check-circle" className="text-success" size="2x" />
              </Col>
            </Row>
            <Row className="mt-3 mb-2">
              <Col>Confirmed date</Col>
              <Col className="text-primary">{driver_tran.confirmed_date}</Col>
            </Row>
            <Row className="mt-3 mb-2">
              <Col>Confirmed by</Col>
              <Col className="text-primary"><FaUserCircle /> {driver_tran.confirmed_by}</Col>
            </Row>
          </>
        ) : (
          <Row className="mt-3">
            <Col className="text-danger">Confirmed?</Col>
            <Col>
              <Badge bg="warning" className="fs--2">Waiting for confirmation by driver!</Badge>

              {allow_confirm && (
                <div className="my-1 text-end">
                  Click here to <Link className="fw-semi-bold" onClick={() => handleConfirm(driver_tran.id)}>Confirm!</Link>
                </div>
              )}
            </Col>
          </Row>
        )}
      </ListGroup.Item>
    );
  };

  return (
    <>
      <ListGroup className="mx-3">
        {Array.isArray(driver_trans) && driver_trans.map((driver_tran) => <DriverItem driver_tran={driver_tran} />)}
      </ListGroup>

      {showForm && (
        <TransactionDetailDriverTransFormModal
          showModal={showForm}
          setshowModal={setShowForm}
          transaction={transaction}
          driverTran={driverTran}
        />
      )}
     
     {showDeleteConfirm && (
      <DriverTranDeleteConfirm
        showModal={showDeleteConfirm}
        setshowModal={setShowDeleteConfirm}
        transaction={transaction}
        driverTran={driverTran}
        />
     )}
     
    </>
  );
};

TransactionDetailApprovalDriver.propTypes = {
  transaction: PropTypes.object.isRequired
}

export default TransactionDetailApprovalDriver;