import AgGridTable from "components/ag-grid/AgGridTable";
import AgPagination from "components/ag-grid/AgPagination";
import CheckboxRenderer from "components/ag-grid/CheckboxRenderer";
import DateFormatRenderer from "../DateFormatRenderer";
import NumberRenderer from "components/ag-grid/NumberRenderer";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColumns, getMasterSchedules, setCurrentPage, setPageSize, setCurrentParams } from "redux/crm/report/masterScheduleSlice";
import { Link } from 'react-router-dom';
import ProjectMasterScheduleHeader from "./ProjectMasterScheduleHeader";
import { formatDate } from "helpers/utils";
import FormatPercentRenderer from "components/ag-grid/FormatPerentRenderer";
import WeightFormatRenderer from "./WeightFormatRenderer";
import StatusBuildingFormatRenderer from "./StatusFormatRenderer";
import ProjectNameRenderer from "./ProjectNameRenderer";
import APDFormatRenderer from "../APDFormatRenderer";
import ShopFormatRenderer from "../ShopFormatRenderer";
import ShipmentFormatRenderer from "./ShipmentFormatRenderer";
import JobFormatRenderer from "../JobFormatRenderer";

const ProjectMasterScheduleList = () => {
  const { 
    data,
    totalRows,
    paginate,
    currentParams
  } = useSelector((state) => state.crm_report_master_schedule)
  const { pageSize, totalPagesCount, fromRecord, toRecord } = paginate

  const dispatch = useDispatch();

  const columnDefs = useMemo(() => [
    {
      headerName: "PROJECT DETAILS",
      children: [
        { 
          headerName: 'ID#',
          field: 'id',
          hide: true,
        },
        { 
          headerName: 'No.',
          field: 'j_sort',
          minWidth: 100,
          pinned: 'left',
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        { 
          headerName: 'Quote number.',
          field: 'project.q_number',
          minWidth: 120,
          pinned: 'left',
          cellRenderer: (params) => {
            const {project} = params.data
            return (
              <Link to={`/crm/projects/${project.id}`}>{project.q_number}</Link>
            )
          }
        },
        { 
          headerName: 'P/J number',
          field: 'j_number',
          minWidth: 120,
          pinned: 'left',
          cellRenderer: JobFormatRenderer
        },
        {
          headerName: 'Customer name',
          field: 'project.customer_name',
          minWidth: 200,
          cellRenderer: (params) => {
            const {project} = params.data
            return (
              <Link to={`/crm/projects/${project.customer_id}`}>{project.customer_name}</Link>
            )
          }
        },
        { 
          headerName: 'Project name',
          field: 'project.name',
          minWidth: 200,
          cellRenderer: ProjectNameRenderer
        },
        {
          headerName: "SALE AREA",
          children: [
            { 
              headerName: 'Sales incharge',
              field: 'project.sales_incharge',
              minWidth: 120
            },
            { 
              headerName: 'Region',
              field: 'sales_unit.region',
              minWidth: 130
            },
            { 
              headerName: 'Location',
              field: 'project.pj_location',
              minWidth: 220
            },
            { 
              headerName: 'Province',
              field: 'project.pj_province',
              minWidth: 150
            },
            // { 
            //   headerName: 'Country',
            //   field: 'project.pj_country',
            //   minWidth: 110
            // },
          ]
        },
        {
          headerName: "TOTAL WEIGHT (M.T)",
          children: [
            { 
              headerName: 'JAF',
              field: 'jaf_weight',
              minWidth: 140,
              cellRenderer: WeightFormatRenderer
            },
            { 
              headerName: 'Final EST',
              field: 'est_final_weight',
              minWidth: 140,
              cellRenderer: WeightFormatRenderer
            },
            { 
              headerName: 'BOM',
              field: 'bom_weight',
              minWidth: 140,
              cellRenderer: WeightFormatRenderer
            },
          ]
        },
        {
          headerName: " ",
          children: [
            { 
              headerName: 'Area (m2)',
              field: 'area',
              minWidth: 120,
              cellRenderer: NumberRenderer
            },
            { 
              headerName: 'Full released BOM',
              field: 'full_released_bom',
              minWidth: 180,
              cellRenderer: CheckboxRenderer
            },
          ]
        }
      ]
    },
    {
      headerName: "CUSTOMER SERVICE DEPT.",
      children: [
        { 
          headerName: 'Job entry date',
          field: 'job_entry_date',
          minWidth: 150,
          cellRenderer: DateFormatRenderer
        },
        { 
          headerName: 'Lastest CO date',
          field: 'lastest_co_date',
          minWidth: 150,
          cellRenderer: DateFormatRenderer
        },
        { 
          headerName: 'Contract date',
          field: 'contract_date',
          minWidth: 150,
          cellRenderer: (params) => {
            const data = params.data
            const value = params.value
    
            return value === null || value === "" ? (<span className="text-danger">{data.contract_note}</span>) : formatDate(value, "dd/MM/yy")
          }
        },
      ]
    },
    {
      headerName: "FINANCIAL DEPARTMENT",
      children: [
        {
          headerName: "PAYMENT TERMS",
          children: [
            { 
              headerName: 'Payment description',
              field: 'payment_term.description',
              minWidth: 200,
              cellRenderer: (params) => {
                return <span className="text-primary">{params.value}</span>
              }
            },
            { 
              headerName: 'DP',
              field: 'payment_term.down_payment',
              minWidth: 100,
              cellRenderer: DateFormatRenderer
            },
            { 
              headerName: 'Received up to',
              field: 'payment_term.received_up_to',
              minWidth: 120,
              cellRenderer: FormatPercentRenderer
            },
            { 
              headerName: 'BG/LC',
              field: 'payment_term.bank_guarantee_letter_of_credit',
              minWidth: 80,
              cellRenderer: CheckboxRenderer
            },
            { 
              headerName: 'APG',
              field: 'payment_term.advance_payment_guarantee',
              minWidth: 80,
              cellRenderer: CheckboxRenderer
            },
            { 
              headerName: 'PB',
              field: 'payment_term.performance_bond',
              minWidth: 80,
              cellRenderer: CheckboxRenderer
            },
            { 
              headerName: 'Penalty',
              field: 'payment_term.penalty',
              minWidth: 80,
              cellRenderer: CheckboxRenderer
            },
            { 
              headerName: 'CFS',
              field: 'payment_term.clear_for_shipment',
              minWidth: 80,
              cellRenderer: CheckboxRenderer
            },
          ]
        }
      ]
    },
    {
      headerName: "ENGINEERING DEPT.",
      children: [
        {
          headerName: "APD",
          children: [
            { 
              headerName: 'APD Schedule',
              field: 'apds.apd_schedule',
              minWidth: 120,
              cellRenderer: APDFormatRenderer
            },
            { 
              headerName: 'APD Release',
              field: 'apds.apd_release',
              minWidth: 120,
              cellRenderer: APDFormatRenderer
            },
          ]
        },
        { 
          headerName: 'RAD',
          field: 'return_approval_drawing',
          minWidth: 120,
          cellRenderer: DateFormatRenderer
        },
        {
          headerName: "SHOP DWGS",
          children: [
            { 
              headerName: 'Shop DWGS Schedule',
              field: 'shops.shop_schedule',
              minWidth: 120,
              cellRenderer: ShopFormatRenderer
            },
            { 
              headerName: 'Shop DWGS Release',
              field: 'shops.shop_release',
              minWidth: 120,
              cellRenderer: ShopFormatRenderer
            },
            { 
              headerName: 'Shop DWGS released (Y/N)',
              field: 'shop_dwgs_released_status',
              minWidth: 140,
              cellRenderer: CheckboxRenderer
            },
          ]
        },
        {
          headerName: "ERECTION DWGS",
          children: [
            { 
              headerName: 'Erec DWGS Schedule',
              field: 'shops.erc_schedule',
              minWidth: 120,
              cellRenderer: ShopFormatRenderer
            },
            { 
              headerName: 'Erec DWGS Release',
              field: 'shops.erc_release',
              minWidth: 120,
              cellRenderer: ShopFormatRenderer
            },
            { 
              headerName: 'Erec DWGS released (Y/N)',
              field: 'erc_drawings_released_status',
              minWidth: 140,
              cellRenderer: CheckboxRenderer
            },
          ]
        }
      ]
    },
    { 
      headerName: 'Production Released',
      field: 'production_released',
      minWidth: 140,
      cellRenderer: CheckboxRenderer
    },
    {
      headerName: "SHIPMENT DATE",
      children: [
        { 
          headerName: 'Required by customer',
          field: 'shipments.required_by_customer',
          minWidth: 140,
          cellRenderer: ShipmentFormatRenderer
        },
        { 
          headerName: 'CSD committed',
          field: 'shipments.csd_committed',
          minWidth: 140,
          cellRenderer: ShipmentFormatRenderer
        },
        { 
          headerName: 'CSD actual',
          field: 'shipments.csd_actual',
          minWidth: 140,
          cellRenderer: ShipmentFormatRenderer
        },
        { 
          headerName: 'Status',
          field: 'shipment_status',
          minWidth: 120,
          cellRenderer: FormatPercentRenderer
        },
      ]
    },
    {
      headerName: " ",
      children: [
        { 
          headerName: 'Erec by Pebsteel',
          field: 'erc_by_pebsteel',
          minWidth: 110,
          cellRenderer: CheckboxRenderer
        },
        { 
          headerName: 'PEB Scope',
          field: 'peb_scope',
          minWidth: 110
        },
      ]
    },
    {
      headerName: "SCOPE OF WORK",
      children: [
        { 
          headerName: 'Red Steel',
          field: 'red_steel',
          minWidth: 100,
          cellRenderer: CheckboxRenderer
        },
        { 
          headerName: 'Sheeted',
          field: 'sheeted',
          minWidth: 100,
          cellRenderer: CheckboxRenderer
        },
        { 
          headerName: 'Insulation',
          field: 'insulation',
          minWidth: 100,
          cellRenderer: CheckboxRenderer
        },
      ]
    },
    {
      headerName: " ",
      children: [
        { 
          headerName: 'Building status',
          field: 'status.name',
          minWidth: 160,
          cellRenderer: StatusBuildingFormatRenderer
        },
        { 
          headerName: 'Active',
          field: 'active',
          minWidth: 80,
          cellRenderer: CheckboxRenderer
        },
        { 
          headerName: 'CSE inchage',
          field: 'csd_incharges',
          minWidth: 140,
          cellRenderer: params => {
            return params.value?.join(", ")
          }
        },
        { 
          headerName: 'Deeply remark',
          field: 'deeply_remark',
          minWidth: 200,
          cellRenderer: (params) => {
            return <span className="text-danger">{params.value}</span>
          }
        },
        { 
          headerName: 'Current status',
          field: 'current_status',
          minWidth: 180,
          cellRenderer: (params) => {
            return <span className="text-primary">{params.value}</span>
          }
        },
        { 
          headerName: 'Undelivery',
          field: 'undelivery',
          minWidth: 110,
          cellRenderer: NumberRenderer
        },
        { 
          headerName: 'STS notes',
          field: 'sts_notes',
          minWidth: 130,
          cellRenderer: (params) => {
            return <span className="text-danger">{params.value}</span>
          }
        }
      ]
    }
  ], [])

  useEffect(() => {
    dispatch(getColumns())
  }, []);

  useEffect(() => {
    console.log("Render Master schedule!")

    if (currentParams) {
      dispatch(getMasterSchedules(currentParams))
    }
  }, [currentParams]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setCurrentPage(page))
    dispatch(setPageSize(pageSize))

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize}
    console.log(newCurrentParams)
    dispatch(setCurrentParams(newCurrentParams))
    dispatch(getMasterSchedules(newCurrentParams))
  })

  return (
    <>
      <ProjectMasterScheduleHeader />
      <AgGridTable
        id="project-master-scheudle-argrid"
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        selectedMultipe={false}
        refData={() => {}}
        serverSide={true}
        sortable={true}
      />
      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200", "500"]}
      />
    </>
  )
}

export default ProjectMasterScheduleList;