import { useDispatch } from "react-redux"
import { Form, Modal, Button } from "react-bootstrap"
import IconAlert from "components/common/IconAlert"
import { toast } from "react-toastify"
import { deleteFaq } from "redux/documentation/faqSlice"
import React from 'react';
import PropTypes from 'prop-types';

const FaqDeleteConfirmation = ({showModal, setshowModal, faq }) => {
  const dispatch = useDispatch()

  const closeModal = () => {
    setshowModal(false)
  }

  const handleSubmit = () => {
    try {
      dispatch(deleteFaq(faq.id))
      closeModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Delete FAQ confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Are you sure to delete <strong>{faq.id}</strong>?
          </IconAlert>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit">Yes</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

FaqDeleteConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  faq: PropTypes.object.isRequired
}

export default FaqDeleteConfirmation;