import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Suspense, lazy, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getWaiver, sendToApproveWaiver } from "redux/crm/project/waiverSlice";
import WaiverDetailHeader from "./detail/WaiverDetailHeader";
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SimpleBar from "simplebar-react";
import { MdHistory, MdSendAndArchive, MdVerified } from "react-icons/md";
import { FaBan, FaCheck, FaCheckSquare, FaClosedCaptioning, FaRegListAlt } from "react-icons/fa";
import WaiverDetailForm from "./detail/WaiverDetailForm";
import IconAlert from "components/common/IconAlert";
import { Link } from "react-router-dom";
import WaiverActivity from "./detail/WaiverActivity";

const LazyWaiverApproveForm = lazy(() => import("./detail/WaiverApproveForm"));
const LazyWaiverRejectForm = lazy(() => import("./detail/WaiverRejectForm"));
const LazyWaiverCSDAcceptForm = lazy(() => import("./detail/WaiverCSDAcceptForm"));
const LazyWaiverCSDReVerifyForm = lazy(() => import("./detail/WaiverCSDReverifyForm"));
const LazyWaiverReSubmitToCSDForm = lazy(() => import("./detail/WaiverReSubmitToCSDForm"));
const LazyWaiverGMApproveForm = lazy(() => import("./detail/WaiverGMApproveForm"));
const LazyWaiverClearWaiverForm = lazy(() => import("./detail/WaiverClearWaiverForm"));
const LazyWaiverCreditControllerVerifyForm = lazy(() => import("./detail/WaiverCreditControllerVerifyForm"));

const WaiverDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const {project_id: projectId, id: waiverId} = params;
  const {recordData: waiver} = useSelector(state => state.crm_waiver);
  const [showApproveForm, setShowApproveForm] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showCSDAcceptForm, setShowCSDAcceptForm] = useState(false);
  const [showCSDVerifyForm, setShowCSDVerifyForm] = useState(false);
  const [showResubmitToCSDForm, setShowResubmitToCSDForm] = useState(false);
  const [showGMApproveForm, setShowGMApproveForm] = useState(false);
  const [showClearWaiverForm, setShowClearWaiverForm] = useState(false);
  const [showCreditControllerVerifyForm, setShowCreditControllerVerifyForm] = useState(false);

  const {
    allow_sales_manager_approve,
    allow_clear,
    allow_csd_leader_verify,
    allow_resubmit_to_csd,
    allow_general_manager_operation_approve,
    allow_credit_controller_verify,
    allow_finance_controller_approve
  } = waiver?.permissions || {};

  useEffect(() => {
    dispatch(getWaiver({projectId, waiverId}))
  }, [params])

  const handleRequestApproval = () => {
    dispatch(sendToApproveWaiver({projectId, waiverId}))
  }

  const handleApprove = () => {
    setShowApproveForm(true)
  }

  const handleReject = () => {
    setShowRejectForm(true)
  }

  const handleCSDAccept = () => {
    setShowCSDAcceptForm(true)
  }

  const handleCSDVerify = () => {
    setShowCSDVerifyForm(true)
  }

  const handleResubmitToCSD = () => {
    setShowResubmitToCSDForm(true)
  }

  const handleGMApprove = () => {
    setShowGMApproveForm(true)
  }

  const handleGMReject = () => {
    setShowRejectForm(true)
  }

  const handleClearWaiver = () => {
    setShowClearWaiverForm(true)
  }

  const handleCreditControllerVerify = () => {
    setShowCreditControllerVerifyForm(true)
  }

  const closed = waiver?.close?.closed || false;

  const renderMessage = () => {
    if (waiver.action_message) {
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: waiver.action_message }}></span>
          {(allow_sales_manager_approve || allow_finance_controller_approve) && (
            <>
              <Button size="sm" variant="primary ms-2 me-1" onClick={handleApprove}>
                <FaCheck /> Approve
              </Button>
              <Button size="sm" variant="danger" onClick={handleReject}>
                <FaBan /> Reject
              </Button>
            </>
          )}

          {allow_csd_leader_verify && (
            <>
              <Button size="sm" variant="primary ms-2 me-1" onClick={handleCSDAccept}>
                <FaCheck /> Accept
              </Button>
              <Button size="sm" variant="warning" onClick={handleCSDVerify}>
                <MdVerified /> Re-verify
              </Button>
            </>
          )}

          {allow_resubmit_to_csd && (
            <>
              <Button size="sm" variant="primary mx-2" onClick={handleResubmitToCSD}>
                <MdSendAndArchive /> Resubmit to CSD
              </Button>
            </>
          )}

          {allow_general_manager_operation_approve && (
            <>
              <Button size="sm" variant="primary ms-2 me-1" onClick={handleGMApprove}>
                <FaCheckSquare /> Approve
              </Button>
              <Button size="sm" variant="danger" onClick={handleGMReject}>
                <FaBan /> Reject
              </Button>
            </>
          )}

          {allow_credit_controller_verify && (
            <Button size="sm" variant="warning" className="mx-2" onClick={handleCreditControllerVerify}>
              <MdVerified /> Verify
            </Button>
          )}

          {allow_clear && (
            <Button size="sm" variant="warning mx-2" onClick={handleClearWaiver}>
              <FaClosedCaptioning /> Clear waiver now!
            </Button>
          )}
        </>
      )
    } else {
      return (
        <span className="fs--1">
          Waiver has not been sent for approval.
          <Link onClick={handleRequestApproval} className="ms-2 btn btn-sm btn-primary"> Send approval request now!</Link>
        </span>
      )
    }
  }

  return (
    <>
      <WaiverDetailHeader waiver={waiver} />

      {!closed && (
        <IconAlert variant="warning">
          {waiver?.has_overdue ? (
            <>
              <span>Commitment was overdue date!</span>
              {allow_clear && (
                <Button size="sm" variant="warning mx-2" onClick={handleClearWaiver}>
                  <FaClosedCaptioning /> Clear waiver now!
                </Button>
              )}
            </>
          ) : renderMessage()}
        </IconAlert>
      )}
      
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="waiver-detail">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="waiver-detail"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="waiver-activities"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdHistory className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Activities</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="waiver-detail">
                    <WaiverDetailForm waiver={waiver} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="waiver-activities">
                    <WaiverActivity />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>

      <Suspense fallback={<div>Loading...</div>}>
        {showApproveForm && (
          <LazyWaiverApproveForm
            showModal={showApproveForm}
            setshowModal={setShowApproveForm}
            waiver={waiver}
          />
        )}

        {showRejectForm && (
          <LazyWaiverRejectForm
            showModal={showRejectForm}
            setshowModal={setShowRejectForm}
            waiver={waiver}
          />
        )}

        {showCSDAcceptForm && (
          <LazyWaiverCSDAcceptForm
            showModal={showCSDAcceptForm}
            setshowModal={setShowCSDAcceptForm}
            waiver={waiver}
          />
        )}

        {showCSDVerifyForm && (
          <LazyWaiverCSDReVerifyForm
            showModal={showCSDVerifyForm}
            setshowModal={setShowCSDVerifyForm}
            waiver={waiver}
          />
        )}

        {showResubmitToCSDForm && (
          <LazyWaiverReSubmitToCSDForm
            showModal={showResubmitToCSDForm}
            setshowModal={setShowResubmitToCSDForm}
            waiver={waiver}
          />
        )}

        {showGMApproveForm && (
          <LazyWaiverGMApproveForm
            showModal={showGMApproveForm}
            setshowModal={setShowGMApproveForm}
            waiver={waiver}
          />
        )}

        {showClearWaiverForm && (
          <LazyWaiverClearWaiverForm
            showModal={showClearWaiverForm}
            setshowModal={setShowClearWaiverForm}
            waiver={waiver}
          />
        )}

        {showCreditControllerVerifyForm && (
          <LazyWaiverCreditControllerVerifyForm
            showModal={showCreditControllerVerifyForm}
            setshowModal={setShowCreditControllerVerifyForm}
            waiver={waiver}
          />
        )}
      </Suspense>
    </>
  )
}

export default WaiverDetail