import React from 'react';
import QuotationConclusion from "./QuotationConclusion";
import QuotationEntry from "./QuotationEntry";
import QuotationFunnel from "./QuotationFunnel";
import QuotationPipeline from "./QuotationPipeline";
import QuotationProcessing from "./QuotationProcessing";
import PropTypes from "prop-types";

const QuotationOverview = ({originalData}) => {
  const {summary, data} = originalData

  const quotationProcessing = Array.isArray(data) ? data.filter(item => item.seq === 1) : [];

  return (
    <>
      <QuotationFunnel data={summary} />
      <QuotationConclusion data={summary} />
      <QuotationPipeline data={data} />
      <QuotationEntry originalData={originalData} />
      <QuotationProcessing data={quotationProcessing} />
    </>
  )
}

QuotationOverview.propTypes = {
  originalData: PropTypes.object.isRequired
}

export default QuotationOverview;
