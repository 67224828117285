import LostJob from "components/crm/reports/lost/LostJob";
import LostQuotation from "components/crm/reports/lost/LostQuotation";
import LostSummary from "components/crm/reports/lost/LostSummary";
import React from 'react';
import PropTypes from 'prop-types';

const Lost = ({lostData={}}) => {
  const {quotation, job} = lostData;

  const quotationData = quotation && quotation.filter(item => item.seq === 5 ) //5: lost quotation, 6: lost job
  const jobData = job && job.filter(item => item.seq === 3) //project lost

  const quotations = {
    total_projects: quotation?.length || 0,
    quotationData: quotationData
  }

  const jobs = {
    total_projects: job?.length || 0,
    jobData: jobData
  }

  return (
    <>
      <LostSummary data={{quotations, jobs}} />
      <LostJob data={jobData} />
      <LostQuotation data={quotationData} />
    </>
  );
};

Lost.propTypes = {
  lostData: PropTypes.object.isRequired
}

export default Lost;