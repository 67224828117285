import React from 'react';
import { useSelector } from 'react-redux';
import { PermissionContext } from 'context/Context';

// Create a provider component
const PermissionProvider = ({ children }) => {
  const {permissions} = useSelector(state => state.profile_user.data || {});

  return (
    <PermissionContext.Provider value={{ permissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;