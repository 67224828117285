import React from 'react';
import Flex from "components/common/Flex";
import IconAlert from "components/common/IconAlert";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import { useCallback, useState } from "react";
import TransactionDetailAttachment from "./TransactionDetailAttachments";
import { removeDocument } from "redux/booking/car/carBookingSlice";
import { useDispatch } from "react-redux";
import ApprovalDriverList from "./ApprovalDriverList";
import TransactionDetailDriverTrans from "./TransactionDetailDriverTrans";
import TransactionDetailUploadAttachmentDirectly from "./TransactionDetailUploadAttachmentDirectly";
import TransactionDetailBookingInfo from "./TransactionDetailBookingInfo";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineAssessment } from "react-icons/md";
import AssignDriverFormSingle from "./AssignDriverFormSingle";
import PropTypes from 'prop-types';

const TransactionDetailInfo = ({transaction={}}) => {
  const [showAssignDriver, setShowAssignDriver] = useState(false);

  const dispatch = useDispatch();
  const statusId = transaction?.status_id;
  const documents = transaction?.documents || [];

  const {
    allow_upload_document,
    allow_remove_document,
    allow_assign_driver
  } = transaction.permissions || {};

  const bgStatusFormat = useCallback((statusId) => {
    let bg = "info";
  
    switch (statusId) {
      case 1:
        bg = "warning";
        break;
      case 2:
        bg = "success";
        break;
      case 3:
      case 4:
        bg = "danger";
        break;
      case 5:
        bg = "info";
        break;
      default:
        bg = "info"
        break;
    }
  
    return bg;
  }, [transaction]);

  const isWatingApproval = () => {
    return statusId === 1 || statusId === 5;
  }

  const isRejected = () => {
    return statusId === 3;
  }

  const isApproved = () => {
    return statusId === 2;
  }

  const handleRemoveAttachment = (documentId) => {
    dispatch(removeDocument({transactionId: transaction?.id, documentId}))
  }

  const handleAssignDriver = () => {
    setShowAssignDriver(true)
  }

  return (
    <>
      <IconAlert variant={bgStatusFormat(statusId)}>
        <p className="mb-0">{transaction?.action_message}</p>
      </IconAlert>

      <TransactionDetailBookingInfo transaction={transaction} bgStatusFormat={bgStatusFormat} />

      {!isWatingApproval() && (
        <>
          <Card className="mb-3 fs--1">
            <Card.Header>
              <Flex justifyContent="between">
                <h5 className="mb-0">Approval information</h5>

                {allow_assign_driver && (
                  <Button size="sm" variant="falcon-default" onClick={handleAssignDriver}>
                    <MdOutlineAssessment /> Assign Driver
                  </Button>
                )}
              </Flex>
          
            </Card.Header>
            <Card.Body className="bg-light border-top">
              {isRejected() ? (
                <Row className="mb-3">
                  <Col lg className="mt-4 mt-lg-0">
                    <h6 className="fw-semi-bold ls mb-3 text-danger">
                      Booking rejected with some reason below:
                    </h6>

                    <Row className="mb-3">
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">Reason</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-danger">{transaction.remark}</p>
                      </Col>
                    </Row>
                    <Row className="mt-6 mb-2">
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">CC emails</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">
                          {transaction?.cc_email && transaction?.cc_email.map((item) => (
                            <Badge pill variant="info" className="me-1" key={item.id}>{item}</Badge>
                          ))}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">Rejected by</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{transaction?.approved_by}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">Rejected date</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{transaction?.approved_date}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row className="mb-5">
                  <Col lg xxl={6}>
                    <Row>
                      <Col>
                        <div className="mb-2">Booking assigned to drivers below:</div>
                        <ApprovalDriverList transaction={transaction} />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg className="mt-4 mt-lg-0">
                    <Row className="mt-4 mb-2">
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">CC emails</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">
                          {transaction?.cc_email && transaction?.cc_email.map((item) => (
                            <Badge pill variant="info" className="me-1" key={item.id}>{item}</Badge>
                          ))}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">Notes</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-danger" dangerouslySetInnerHTML={{ __html: transaction?.remark }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">Approved by</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary"><FaUserCircle /> {transaction?.approved_by}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} sm={4}>
                        <p className="fw-semi-bold fs--1 mb-1">Approved date</p>
                      </Col>
                      <Col>
                        <p className="mb-1 fs--1 text-primary">{transaction?.approved_date}</p>
                      </Col>
                    </Row>
                    
                    <h6 className="fw-semi-bold ls mb-3 text-uppercase mt-4">
                      Attachments
                    </h6>
                    
                    {documents.length > 0 && (
                      <div className="bg-light py-2">
                        <Flex direction="row" wrap="wrap" justifyContent="arround">
                          {documents.map(attachment => (
                            <TransactionDetailAttachment
                              {...attachment}
                              key={attachment.id}
                              allow_remove={allow_remove_document}
                              handleRemoveAttachment={handleRemoveAttachment}
                            />
                          ))}
                        </Flex>
                      </div>
                    )}

                    {allow_upload_document && (
                      <Row>
                        <Col>
                          <TransactionDetailUploadAttachmentDirectly transactionId={transaction?.id} />
                        </Col>
                      </Row>
                    )}
                    
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>

          {isApproved() && (
            <Card className="mb-3 fs--1">
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5 className="mb-0">Car and Driver transactions</h5>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="bg-light border-top">
                <TransactionDetailDriverTrans transaction={transaction} />
              </Card.Body>
            </Card>
          )}
        </>
      )}

      {showAssignDriver && (
        <AssignDriverFormSingle
          showModal={showAssignDriver}
          setshowModal={setShowAssignDriver}
          transaction={transaction}
        />
      )}
      
    </>
  );
};

TransactionDetailInfo.propTypes = {
  transaction: PropTypes.object.isRequired
}

export default TransactionDetailInfo;