import QuotationWeekItem from "./QuotationWeekItem";
import React from 'react';
import PropTypes from "prop-types";

const QuoationThisWeek = ({data}) => {
  return (
    <QuotationWeekItem weekData={data} title="Quotations for this week" />
  );
};

QuoationThisWeek.propTypes = {
  data: PropTypes.object.isRequired
};

export default QuoationThisWeek;