// Check if the user has the required permissions
const hasPermission = (userPermissions, permissionNo) => {
  return userPermissions[permissionNo] === true;
};

export const filterMenusByPermissions = (menus, userPermissions) => {
  return menus.map(menu => {
    if (menu.children) {
      const allowedSubmenus = filterMenusByPermissions(menu.children, userPermissions);
      if (allowedSubmenus.length > 0) {
        return { ...menu, children: allowedSubmenus };
      }
    }
    return hasPermission(userPermissions, menu.permissionNo)
    ? menu
    : null;
  }).filter(menu => menu !== null);
};

export const truncateFilename = (name, maxLength = 20) => {
  if (name.length <= maxLength) return name;
  const extension = name.split('.').pop();
  const baseName = name.slice(0, maxLength - extension.length - 3);
  return `${baseName}...${extension}`;
};