import React, { useState, useEffect } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { BY_NUMBER_WEIGHT_FILTER } from 'app_config';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);


const getOption = (data) => ({
  color: [
    getColor('success'),
    rgbaColor(getColor('warning'), 0.7),
    getColor('danger')
  ],
  legend: {
    orient: 'vertical',
    left: 'right',
    top: 0,
    itemWidth: 12,
    itemHeight: 12,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    inactiveBorderColor: 'transparent',
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 12,
      fontFamily: 'Poppins',
      fontWeight: '500'
    },
    //itemGap: 16
  },
  series: [
    {
      type: 'pie',
      radius: '70%',
      // width: '80%',
      // height: '80%',
      center: ['50%', '45%'],
      label: {
        show: true,
        position: 'inside',
        formatter: '{d}%',
        //formatter: (params) => formatNumber(params, isWeight),
        color:'black', 
        fontSize: 14
      },
      percentPrecision: 0,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      data: data
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    //formatter: (params) => formatNumber(params, isWeight)
  }
});

getOption.propTypes = {
  data: PropTypes.array.isRequired
}

const TypeOfSalesChart = ({title, data}) => {
  const [numberWeightFilter, setNumberWeightFilter] = useState(BY_NUMBER_WEIGHT_FILTER[0])
  const [dataObject, setDataObject] = useState([])

  useEffect(() => {
    if (!data) return;

    const newData = data && data.map(item => {
      const name = Object.keys(item)[0];
      const values = item[name];
      const value = numberWeightFilter === BY_NUMBER_WEIGHT_FILTER[0] ? values.weight : values.number_of_projects;
    
      return {
        name,
        value
      };
    });

    setDataObject(newData)
  }, [data, numberWeightFilter])

  const handleChangeFilterNumberWeight = (event) => {
    const selectedStatus = event.target.value;
    setNumberWeightFilter(selectedStatus);
  };

  return (
    <div>
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select
            size="sm"
            onChange={handleChangeFilterNumberWeight} defaultValue={numberWeightFilter}
            className="mx-1 fs--1"
          >
            {BY_NUMBER_WEIGHT_FILTER.map(item => (
              <option key={item}>{item}</option>
            ))}
          </Form.Select>
        }
      />
      <div className="p-3 d-flex aligm-items-center">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(dataObject)}
          className="h-100 w-100"
        />
      </div>
    </div>
  )
}

TypeOfSalesChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default TypeOfSalesChart;