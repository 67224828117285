import React from 'react';
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { rejectBooking } from "redux/booking/travel/travelBookingSlice";
import IconAlert from 'components/common/IconAlert';

const BookingDetailRejectModal = ({showModal, setshowModal, booking}) => {
  const { register, handleSubmit, formState: {errors}, setValue } = useForm()

  const dispatch = useDispatch()

  const onSubmit = (data, e) => {
    e.preventDefault()

    const bookingId = booking.id

    try {
      data = { reason: data.remark }
      dispatch(rejectBooking({bookingId, data}))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Reject travel booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="warning">
              <p className="mb-0">Please provide a reason for rejecting the trip.</p>
            </IconAlert>
       
            <Form.Group as={Row} className="my-3" controlId="remark">
              <Form.Label column sm={3} className="text-lg-end">
                Reason
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="remark"
                  placeholder="Reason"
                  className="fs--1"
                  {...register("remark", {
                    required: "Reason must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.remark && errors.remark.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              variant="danger"
              type="submit"
            >
              Submit
            </Button>
            <Button size="sm" variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

BookingDetailRejectModal.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  booking: PropTypes.object
}

export default BookingDetailRejectModal