import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { FaRegListAlt } from 'react-icons/fa';
import { MdSummarize } from 'react-icons/md';
import HeaderReport from './HeaderReport';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ContractDetail from './contract/ContractDetail';
import ContractSummary from './contract/ContractSummary';
//import NumberWeightSummary from './contract/NumberWeightSummary';
import { getContractDashboard } from 'redux/crm/report/contractSlice';
import ContractWeekChart from './contract/ContractWeekChart';
import NumberWeightSummary from './NumberWeightSummary';

const Contract = () => {
  const { loading, dashboardData } = useSelector((state) => state.crm_report_contract)

  const {
    current_period,
    previous_period,
    type_of_sales,
    sales_regions,
    monthly,
    pj_countries,
    countries,
    this_week_data,
    last_week_data,
    data=[]
  } = dashboardData

  const currentDate = moment();
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(currentDate);

  const dispatch = useDispatch()

  const params = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD')
  };

  useEffect(() => {
    dispatch(getContractDashboard(params))
  }, [startDate, endDate])

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      <HeaderReport
        title="Contract"
        subtitle="Dashboard"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onDatesChange={handleDatesChange}
      />

      <Row className="g-3 mb-3">
        <Col sm={4} xxl={6}>
          <NumberWeightSummary
            title="Number of signed contracts"
            current_value={current_period?.number_of_projects}
            previous_value={previous_period?.number_of_projects}
          />
        </Col>
        <Col sm={4} xxl={6}>
          <NumberWeightSummary
            title="Weight of signed contract (M.T)"
            current_value={current_period?.weight}
            previous_value={previous_period?.weight}
          />
        </Col>
        <Col sm={4} xxl={6}>
          <NumberWeightSummary
            title="Signed contract Value (USD)"
            current_value={current_period?.revenue}
            previous_value={previous_period?.revenue}
            isRevenue={true}
          />
        </Col>
      </Row>

      <Row className="mb-3 gx-3">
        <Col>
          <ContractWeekChart title="Signed contracts this week" data={this_week_data} />
        </Col>
        <Col>
          <ContractWeekChart title="Signed contracts last week" data={last_week_data} />
        </Col>
      </Row>

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }
      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="contract-summary">
              <Card.Header className="p-0 border-bottom">
                <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="contract-summary"
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <MdSummarize className="text-600 tab-icon" />
                      <h6 className="text-600 mb-0 ms-1"> Summary</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="contract-detail"
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FaRegListAlt className="text-600 tab-icon" />
                      <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="contract-summary">
                    <ContractSummary originalData={{type_of_sales, sales_regions, monthly, pj_countries, countries, this_week_data, last_week_data, data}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="contract-detail">
                    <ContractDetail data={data} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Contract;