import React, { useEffect } from "react";
import AsyncSelect from 'react-select/async';
import { useDispatch } from "react-redux";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { toast } from "react-toastify";
import { releaseChangeLog } from "redux/documentation/changeLogSlice";
import { useForm, Controller } from "react-hook-form";
import TinymceEditor from "components/common/TinymceEditor";
import {departmentsDebounce} from "helpers/employeeOptionHelper";
import { debouncedFilterEmployees } from "helpers/debounce";

const ChangeLogPublishConfirmation = ({showModal, setshowModal, changeLog }) => {
  const {control, register, handleSubmit, formState: {errors}, setValue} = useForm();
  const dispatch = useDispatch();

  useEffect(() => { 
    if (changeLog) {
      setValue('subject', changeLog.title);
      setValue('content', changeLog.body);
    }
  }, [changeLog]);

  const closeModal = () => {
    setshowModal(false)
  }

  const departmentOptions = (inputValue, callback) => {
    departmentsDebounce (inputValue, callback);
  }

  const onSubmit = (data) => {
    console.log(data);

    try {
      dispatch(releaseChangeLog({ changeLogId: changeLog.id, data}))
      closeModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleChangeDepartment = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('department_ids', selectedOptionsValues, { shouldValidate: true })
  }

  const employeeOptions = (inputValue, callback) => {
    debouncedFilterEmployees(inputValue, callback)
  }

  const handleChangeCCNames = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('cc', selectedOptionsValues, { shouldValidate: true })
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Publish confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Are you sure to Publish version <strong>{changeLog.version} to users</strong>?
          </IconAlert>

          <Row className="g-0 align-items-center mb-2">
            <Col sm={2} className="mx-2 fs--0">To Departments</Col>
            <Col>
              <Controller
                control={control}
                name="department_ids"
                rules={{required: "To must be required"}}
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={departmentOptions}
                    closeMenuOnSelect={false}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100 border-0 outline-none px-x1 rounded-0"
                    isMulti
                    onChange={handleChangeDepartment}
                  />
                )}
              />
              {errors.to && (
                <span className="text-danger mx-4 mt-1">
                  {errors.to.message}
                </span>
              )}
            </Col>
          </Row>

          <Row className="g-0 align-items-center mb-2">
            <Col sm={2} className="mx-2 fs--0">CC</Col>
            <Col>
              <Controller
                control={control}
                name="cc"
                rules={{required: "CC must be required"}}
                render={() => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={employeeOptions}
                    closeMenuOnSelect={false}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    className="w-100 border-0 outline-none px-x1 rounded-0"
                    isMulti
                    onChange={handleChangeCCNames}
                  />
                )}
              />
              {errors.cc && (
                <span className="text-danger mx-4 mt-1">
                  {errors.cc.message}
                </span>
              )}
            </Col>
          </Row>

          <Row className="gx-0 align-items-center mb-2">
            <Col sm={2} className="mx-2 fs--0">Subject</Col>
            <Col>
              <div className="mx-3">
                <Form.Control
                  className="fs--1"
                  placeholder="Subject"
                  {...register('subject', {required: true})}
                />
              </div>
            </Col>
          </Row>

          <Form.Group as={Row} className="mb-2" controlId="list_of_changes">
            <Col>
              <Form.Label column className="text-lg-start">
                Content:
              </Form.Label>

              <Controller
                control={control}
                name="content"
                render={({ field }) => (
                  <TinymceEditor
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit">Publish</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

export default ChangeLogPublishConfirmation;