import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import apiAxios from 'helpers/apiAxios';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AssignContactModal from '../AssignContactModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import PropTypes from 'prop-types';

const ContactDetailHeader = ({contact}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showAssignContact, setShowAssignContact] = useState(false)

  const {
    allow_edit,
    allow_delete,
    allow_assign
  } = contact?.permissions || {};

  const navigate = useNavigate();
  const contactUrl = "/crm/contacts"

  const handleNewContact = () => {
    navigate(`${contactUrl}/new`)
  }

  const handleEditContact = () => {
    navigate(`${contactUrl}/${contact.id}/edit`, {state: contact})
  }

  const handleDeleteContact = () => {
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    try {
      await apiAxios.delete(`${contactUrl}/${contact.id}`)
      toast.success(`Contact has been deleted sucessful.`)

      navigate(contactUrl)
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleContactList = () => {
    navigate(`${contactUrl}/list`)
  }

  const handleAssignedContact = () => {
    setShowAssignContact(true)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            {allow_assign && (
              <IconButton
                variant="info"
                size="sm"
                icon="user"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleAssignedContact}
                >
                <span className="d-none d-xl-inline-block ms-1">Assign to</span>
              </IconButton>
            )}
  
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleContactList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List contacts
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  {allow_edit && (
                    <>
                      <Dropdown.Item className="text-primary" onClick={handleEditContact}>
                        <MdEdit /> Edit
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                  
                  {allow_delete && (
                    <Dropdown.Item className="text-danger" onClick={handleDeleteContact}>
                      <MdDelete /> Delete
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleActionConfirm}
          message="Are you sure to delete?"
      />
      )}

      {showAssignContact && (
        <AssignContactModal
          showModal={showAssignContact}
          setshowModal={setShowAssignContact}
          contactId={contact.id}
        />
      )}
      
    </>
  );
};

ContactDetailHeader.propTypes = {
  contact: PropTypes.object.isRequired
}

export default ContactDetailHeader;
