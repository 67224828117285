import BecameJob from "./BecameJob";
import JobEnteredTracking from "./JobEnteredTracking";
import JobEntryTypeOfSales from "./JobEntryTypeOfSales";
import React from 'react';
import PropTypes from 'prop-types';

const JobEntryOverview = ({originalData={}}) => {
  const {summary, monthly, sales_regions, data} = originalData;
  const summaryData = summary?.data;

  return (
    <>
      <JobEntryTypeOfSales data={summary} />
      <BecameJob data={summaryData} />
      <JobEnteredTracking originalData={{monthly, sales_regions, data}} />
    </>
  );
};

JobEntryOverview.propTypes = {
  originalData: PropTypes.shape({
    summary: PropTypes.object,
    monthly: PropTypes.object,
    sales_regions: PropTypes.object,
    data: PropTypes.array
  }).isRequired
}

export default JobEntryOverview;