import Flex from "components/common/Flex";
import { Button, Col, Form, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const AgPagination = ({totalRows, totalPagesCount, pageChange, pageSize, from, to, itemsPages}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(pageSize)
  const pageCount = totalPagesCount

  //const visibleRange = Math.min(pageCount, pageSize);
  const visibleRange = 10;
  const startPage = Math.max(1, currentPage - Math.floor(visibleRange / 2));
  const endPage = Math.min(pageCount, startPage + visibleRange - 1);

  const gotoPage = (page) => {
    setCurrentPage(page)
    pageChange(page, itemsPerPage)
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const canPrevPage = currentPage > 1;
  const canNextPage = currentPage < pageCount;

  return (
    <>
      <Row className={`flex-between-center my-3 align-items-center justify-content-${itemsPages ? 'between' : 'center'}`}>
        {itemsPages && (
          <Col
            sm="auto"
            as={Flex}
            alignItems="center"
            className="mb-2 mb-sm-0"
            style={{minWidth: "350px"}}
          >
            <Form.Select
              size="sm"
              value={itemsPerPage}
              onChange={({ target }) => {
                setItemsPerPage(parseInt(target.value));
                pageChange(currentPage, parseInt(target.value))
              }}
              style={{ maxWidth: '5.4rem' }}
              className="justify-content-center"
            >
              {itemsPages && itemsPages.map((page, index) => (
                <option key={index} value={page}>{page}</option>
              ))}
            </Form.Select>
            <h6 className="mb-0 ms-2 mx-2">
              Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{totalRows}</strong> records
            </h6>
          </Col>
        )}
        <Col sm="auto">
          <Row className="gx-2 align-items-center">
            <Col xs="auto">
              <Flex alignItems="center" justifyContent="center" wrap="wrap">
                <Button
                  size="sm"
                  variant="falcon-default"
                  onClick={() => gotoPage(1)}
                  className={classNames('me-1', { disabled: currentPage === 1 })}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  onClick={prevPage}
                  className={classNames({ disabled: !canPrevPage })}
                >
                  <FontAwesomeIcon icon="chevron-left" />
                </Button>
                <ul className="pagination mb-0 mx-1">
                  {Array.from(Array(pageCount).keys()).map((page, index) => {
                    // if (
                    //   pageCount > pageSize &&
                    //   ((index === 2 && currentPage > startPage + 2) || (index === pageCount - 3 && currentPage < endPage - 2))
                    // ) {
                    //   return (
                    //     <li key={page}>
                    //       <Button size="sm" variant="falcon-default" className="me-1">
                    //         ...
                    //       </Button>
                    //     </li>
                    //   );
                    // }

                    if (index >= startPage - 1 && index <= endPage - 1) {
                      return (
                        <li key={page} className={classNames({ active: currentPage === page + 1 })}>
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className={classNames('page', {
                              'me-1': index + 1 !== pageCount,
                              'bg-info': currentPage === page + 1 && currentPage === index + 1
                            })}
                            onClick={() => gotoPage(page + 1)}
                          >
                            {page + 1}
                          </Button>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
                <Button
                  size="sm"
                  variant="falcon-default"
                  onClick={nextPage}
                  className={classNames({ disabled: !canNextPage })}
                >
                  <FontAwesomeIcon icon="chevron-right" />
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  onClick={() => gotoPage(pageCount)}
                  className={classNames('ms-1', { disabled: currentPage === pageCount })}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </Button>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

AgPagination.propsType = {
  totalRows: PropTypes.number,
  totalPagesCount: PropTypes.number,
  pageChange: PropTypes.func,
  pageSize: PropTypes.number,
  from: PropTypes.number,
  to: PropTypes.number,
  itemsPages: PropTypes.array
}

export default AgPagination;