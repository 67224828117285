import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

const settingUrl = "/settings/1"

export const getSetting = createAsyncThunk(
  "Settings/getSetting",
  async () => {
    try {
      const response = await apiAxios.get(settingUrl)
      return response.data.attributes
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const updateSeting = createAsyncThunk(
  "Settings/updateSeting",
  async (data) => {
    try {
      const response = await apiAxios.put(settingUrl, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCountries = createAsyncThunk(
  "Settings/getCountries",
  async (params) => {
    try {
      const countryUrl = "/countries"
      const response = await apiAxios.get(`${countryUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getExchangeRate = createAsyncThunk(
  "Settings/getExchangeRate",
  async (params) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/external/bank/current-exchange-rate`
      const response = await apiAxios.get(url, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const settingSlice = createSlice({
  name: "settings",
  initialState: {
    data: {},
    confirmEmail: null,
    has403Error: false,
    countryData: [],
    usdExchangeRateData: []
  },
  reducers: {
    confirmEmail: (state, action) => {
      state.confirmEmail = action.payload
    },
    setForbiddenError: (state) => {
      state.has403Error = true
    },
    resetForbiddenError: (state) => {
      state.has403Error = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSetting.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(updateSeting.fulfilled, (state, action) => {
        const { error, data, message } = action.payload

        if (error !== undefined) {
          toast.error(error)
        }
        else {
          if (data) {
            state.data = data.attributes
            toast.success(message)
          }
          else {
            toast.error("Error when load data.")
          }
        }
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countryData = action.payload
      })
      .addCase(getExchangeRate.fulfilled, (state, action) => {
        const {data} = action.payload
        state.usdExchangeRateData = data.sell
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  confirmEmail,
  setForbiddenError,
  resetForbiddenError
} = settingSlice.actions

export default settingSlice.reducer