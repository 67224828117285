import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Dropdown, Button } from 'react-bootstrap';
import { FaPlus, FaUpload, FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import FolderForm from './FolderForm';
import DriveUpload from './DriveUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const DriveHeader = () => {
  const [showFolderForm, setShowFolderForm] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const {permissions} = useSelector(state => state.profile_user.data);
  const { v921, v922} = permissions || {};

  const navigate = useNavigate()
  const params = useParams();
  const assetId = params?.id;

  const handleNewFolder = () => {
    setShowFolderForm(true);
  }

  const handleUpload = () => {
    setShowUploadForm(true);
  }

  const handleTrashList = () => {
    navigate("/drives/trash/list")
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex justifyContent="end">
            {v922 && (
              <Button size="sm" variant="warning" className="ms-1" onClick={handleUpload}>
                <FaUpload className="me-1" /> Upload files
              </Button>
            )}
            
            {v921 && (
              <Button size="sm" variant="primary" onClick={handleNewFolder} className="mx-1">
                <FaPlus className="me-1" /> New folder
              </Button>
            )}
           
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>
      
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="mb-1" onClick={handleTrashList}>
                    <FaTrashAlt /> Trash List
                  </Dropdown.Item>

                  {/* <Dropdown.Divider />
                  <Dropdown.Item className="mb-1">
                    <MdSettings /> Whitelist IP
                  </Dropdown.Item> */}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showFolderForm && (
        <FolderForm
          showModal={showFolderForm}
          setshowModal={setShowFolderForm}
          asset={null}
          title="Create a New Folder"
        />
      )}

      {showUploadForm && (
        <DriveUpload
          showModal={showUploadForm}
          setshowModal={setShowUploadForm}
          assetId={assetId}
        />
      )}
    </>
  );
};

export default DriveHeader;