import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import AppContext from 'context/Context';
import { getColor } from 'helpers/utils';

const TinymceEditor = ({ value, handleChange, height = '50vh', readonly=false }) => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.dom.addStyle(
        `body{color: ${getColor('white')} !important;}`
      );
    }
  }, [isDark]);

  return (
    <Editor
      tinymceScriptSrc='/tinymce/tinymce.min.js'
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      onEditorChange={handleChange}
      licenseKey='gpl'
      init={{
        readonly,
        height,
        menubar: false,
        content_style: `body { color: ${getColor('black')}; font-family:Helvetica,Arial,sans-serif; font-size:14px }`,
        mobile: {
          theme: 'mobile',
          toolbar: ['undo', 'bold']
        },
        statusbar: false,
        plugins: 'link image lists table directionality help', //fontsize textcolor colorpicker
        toolbar:
          'styleselect | bold italic strikethrough link bullist numlist image blockquote table | forecolor backcolor | undo redo | help | fontsize',
        directionality: isRTL ? 'rtl' : 'ltr',
        convert_urls: false,           // Disables URL conversion
        relative_urls: false,          // Ensures URLs are absolute
        remove_script_host: false,     // Keeps the full URL including host
        document_base_url: process.env.REACT_APP_CLIENT_URL,
        theme_advanced_toolbar_align: 'center',
        //editimage_cors_hosts: ["http://localhost:3030/", "https://portal.pebsteel.com.vn/"]
      }}
    />
  );
};

TinymceEditor.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.string
};

export default TinymceEditor;
