import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import PropTypes from 'prop-types';
import { adjustToNoon } from "helpers/utils";

const RequestScheduleForm = ({
    estimateData,
    setEstimateData,
    abErectionData,
    setAbErectionData,
    apdData,
    setApdData,
    shopData,
    setShopData,
    otherData,
    setOtherData,
    isScheduled
  }) => {
  const {control, register, formState: {errors}, setValue } = useForm();
  const [activeKey, setActiveKey] = useState(Array.from({ length: 5 }, (_, index) => index.toString()))

  useEffect(() => {
    if (estimateData) {
      const requested_date = estimateData.est_requested_date || estimateData.requested_date
      setValue("est_requested_date", requested_date ? new Date(requested_date) : null)

      if (isScheduled) {
        const scheduled_date = estimateData.est_scheduled_date || estimateData.scheduled_date
        setValue("est_scheduled_date", scheduled_date ? new Date(scheduled_date) : null)
      }
    }

    if (abErectionData) {
      const requested_date = abErectionData.requested_date
      setValue("requested_date", requested_date ? new Date(requested_date) : null)

      if (isScheduled) {
        const scheduled_date = abErectionData.scheduled_date
        setValue("scheduled_date", scheduled_date ? new Date(scheduled_date) : null)
      }
    }

    if (apdData) {
      apdData.forEach(item => {
        setValue(`name_${item.id}`, item.name);
        setValue(`apd_requested_${item.id}`, item.apd_requested ? new Date(item.apd_requested) : null);
        setValue(`ab_erd_requested_${item.id}`, item.ab_erd_requested ? new Date(item.ab_erd_requested) : null);

        if (isScheduled) {
          setValue(`apd_scheduled_${item.id}`, item.apd_scheduled ? new Date(item.apd_scheduled) : null);
          setValue(`ab_erd_scheduled_${item.id}`, item.ab_erd_scheduled ? new Date(item.ab_erd_scheduled) : null);
        }
      });
    }

    if (shopData) {
      shopData.forEach(item => {
        setValue(`name_${item.id}`, item.name);
        setValue(`description_${item.id}`, item.description);
        setValue(`shop_requested_${item.id}`, item.shop_requested ? new Date(item.shop_requested) : null);
        setValue(`erc_requested_${item.id}`, item.erc_requested ? new Date(item.erc_requested) : null);

        if (isScheduled) {
          setValue(`shop_scheduled_${item.id}`, item.shop_scheduled ? new Date(item.shop_scheduled) : null);
          setValue(`erc_scheduled_${item.id}`, item.erc_scheduled ? new Date(item.erc_scheduled) : null);
        }
      });
    }

    if (otherData) {
      otherData.forEach(item => {
        setValue(`name_${item.id}`, item.name);
        setValue(`requested_date_${item.id}`, item.requested_date ? new Date(item.requested_date) : null);
        
        if (isScheduled) {
          setValue(`scheduled_date_${item.id}`, item.scheduled_date ? new Date(item.scheduled_date) : null);
        }
      });
    }

  }, [estimateData, abErectionData, apdData, shopData, otherData, isScheduled]);

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  }

  const handleAddAPD = () => {
    setApdData((prevState) => [
      ...prevState, {
        id: prevState.length + 1,
        apd_requested: new Date(),
        //ab_requested: new Date()
      }
    ])
  }

  const handleAddShop = () => {
    setShopData((prevState) => [
      ...prevState, {
        id: prevState.length + 1,
        shop_requested: new Date(),
        //erc_requested: new Date()
      }
    ])
  }

  const handleAddOther = () => {
    setOtherData((prevState) => [
      ...prevState, {
        id: prevState.length + 1,
        requested_date: new Date()
      }
    ])
  }

  const deleteItem = (data, setData, id) => {
    const newData = data.filter(item => item.id !== id)
    setData(newData)
  }

  const handleDeleteApd = (id) => {
    deleteItem(apdData, setApdData, id)
  }

  const handleDeleteShop = (id) => {
    deleteItem(shopData, setShopData, id)
  }

  const handleDeleteOther = (id) => {
    deleteItem(otherData, setOtherData, id)
  }

  const handleInputChange = (setData, name, id, value) => {
    setData((prevData) => {
      const updateData = prevData.map(item => (item.id === id ? {...item, [name]: value} : item));
      return updateData;
    });

    setValue(`${name}_${id}`, value)
  }

  const handleUpdateEstimate = (name, value) => {
    setEstimateData((prevData) => ({...prevData, [name]: value}))
  }

  const handleUpdateAbErection = (name, value) => {
    setAbErectionData((prevData) => ({...prevData, [name]: value}))
  }
 
  return (
    <Accordion
      id="request-schedule-form"
      className="border rounded overflow-hidden fs--1"
      alwaysOpen
      flush
      activeKey={activeKey}
      onSelect={handleAccordionSelect}
    >
      <Card className="rounded-0">
        <Accordion.Item eventKey="0" className="border-0">
          <Card.Header className="p-0">
            <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 mt-1"
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900 text-primary">
                  1. Estimates
                </span>
              </div>
            </Accordion.Button>
          </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2 gx-2">
                  <Col>
                    <Form.Group as={Row} className="mb-2" controlId="est_requested_date">
                      <Form.Label column sm={5} className="text-lg-start">
                        Request date
                      </Form.Label>
                      <Col>
                        <Controller
                          control={control}
                          name="est_requested_date"
                          rules={{required: "Request date must be required"}}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => { 
                                const adjustedDate = adjustToNoon(date);
                                field.onChange(adjustedDate);
                                handleUpdateEstimate("est_requested_date", adjustedDate)
                              }}
                              className="form-control fs--1"
                              placeholderText="dd-MMM-YYYY"
                              dateFormat="dd-MMM-yyyy"
                              fixedHeight
                              disabled={isScheduled}
                            />
                          )}
                        />
                        <span className="text-danger">
                          {errors.est_requested_date && errors.est_requested_date.message}
                        </span>  
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    {isScheduled && (
                      <Form.Group as={Row} className="mb-2" controlId="est_scheduled_date">
                        <Form.Label column sm={5} className="text-lg-start">
                          Schedule date
                        </Form.Label>
                        <Col>
                          <Controller
                            control={control}
                            name="est_scheduled_date"
                            rules={{required: "Schedule date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) => { 
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                  handleUpdateEstimate("est_scheduled_date", adjustedDate)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors.est_scheduled_date && errors.est_scheduled_date.message}
                          </span>  
                        </Col>
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" className="border-0">
          <Card.Header className="p-0">
            <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 mt-1"
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900 text-primary">
                  2. Approval Drawings (APD), A/B for Approval Drawings
                </span>
              </div>
            </Accordion.Button>
          </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Table striped hover>
                  <thead className="ng-light">
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td>APD request</td>
                      {isScheduled && (
                        <td>APD schedule</td>
                      )}
                      <td>A/B request</td>
                      {isScheduled && (
                        <td>A/B schedule</td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {apdData && apdData.map(item => (
                      <tr key={item.id}>
                        <td>
                          <MdDelete className="text-danger cursor-pointer" onClick={() => handleDeleteApd(item.id)} />
                        </td>
                        <td>
                          <Form.Control
                            name={`name_${item.id}`}
                            placeholder="Name"
                            className="fs--1"
                            {...register(`name_${item.id}`, {
                              required: "Name must be required."
                            })}
                            onChange={(e) => handleInputChange(setApdData, "name", item.id, e.target.value)}
                          />
                          <span className="text-danger">
                            {errors[`name_${item.id}`] && errors[`name_${item.id}`].message}
                          </span>  
                        </td>
                        <td>
                          <Controller
                            control={control}
                            name={`apd_requested_${item.id}`}
                            rules={{required: "Request date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) =>  {
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate)
                                  handleInputChange(setApdData, "apd_requested", item.id, adjustedDate)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                                disabled={isScheduled}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors[`apd_requested_${item.id}`] && errors[`apd_requested_${item.id}`].message}
                          </span>  

                        </td>
                        {isScheduled && (
                          <td>
                            <Controller
                              control={control}
                              name={`apd_scheduled_${item.id}`}
                              rules={{required: "Schedule date must be required"}}
                              render={({ field }) => (
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) =>  {
                                    const adjustedDate = adjustToNoon(date);
                                    field.onChange(adjustedDate)
                                    handleInputChange(setApdData, "apd_scheduled", item.id, adjustedDate)
                                  }}
                                  className="form-control fs--1"
                                  placeholderText="dd-MMM-YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  fixedHeight
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors[`apd_scheduled_${item.id}`] && errors[`apd_scheduled_${item.id}`].message}
                            </span> 
                          </td>
                        )}
                        <td>
                          <Controller
                            control={control}
                            name={`ab_erd_requested_${item.id}`}
                            rules={{required: "Request date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) =>  {
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                  handleInputChange(setApdData, "ab_erd_requested", item.id, adjustedDate)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                                disabled={isScheduled}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors[`ab_erd_requested_${item.id}`] && errors[`ab_erd_requested_${item.id}`].message}
                          </span>  

                        </td>
                        {isScheduled && (
                          <td>
                            <Controller
                              control={control}
                              name={`ab_erd_scheduled_${item.id}`}
                              rules={{required: "Schedule date must be required"}}
                              render={({ field }) => (
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) =>  {
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                    handleInputChange(setApdData, "ab_erd_scheduled", item.id, adjustedDate)
                                  }}
                                  className="form-control fs--1"
                                  placeholderText="dd-MMM-YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  fixedHeight
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors[`ab_erd_scheduled_${item.id}`] && errors[`ab_erd_scheduled_${item.id}`].message}
                            </span> 
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {!isScheduled && (
                  <Flex
                    justifyContent="end"
                    alignItems="center"
                    className="text-primary cursor-pointer fs--1"
                    onClick={handleAddAPD}
                  >
                    <span className="circle-dashed">
                      <FontAwesomeIcon icon="plus" />
                    </span>
                    <span className="ms-3">Add Record</span>
                  </Flex>
                )}
              </Card.Body>
            </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="border-0">
          <Card.Header className="p-0">
            <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 mt-1"
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900 text-primary">
                  3. Shop, Erection Drawings
                </span>
              </div>
            </Accordion.Button>
          </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <table>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td>Description</td>
                      <td>Shop request</td>
                      {isScheduled && (
                        <td>Shop schedule</td>
                      )}
                      <td>Erection request</td>
                      {isScheduled && (
                        <td>Erection schedule</td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {shopData && shopData.map(item => (
                      <tr key={item.id}>
                        <td>
                          <MdDelete className="text-danger cursor-pointer" onClick={() => handleDeleteShop(item.id)} />
                        </td>
                        <td>
                          <Form.Control
                            name={`name_${item.id}`}
                            placeholder="Name"
                            className="fs--1"
                            {...register(`name_${item.id}`, {
                              required: "Name must be required."
                            })}
                            onChange={(e) => handleInputChange(setShopData, "name", item.id, e.target.value)}
                          />
                          <span className="text-danger">
                            {errors[`name_${item.id}`] && errors[`name_${item.id}`].message}
                          </span>  
                        </td>
                        <td>
                          <Form.Control
                            name={`description_${item.id}`}
                            placeholder="Description"
                            className="fs--1"
                            {...register(`description_${item.id}`, {
                              required: "Description must be required."
                            })}
                            onChange={(e) => handleInputChange(setShopData, "description", item.id, e.target.value)}
                          />
                          <span className="text-danger">
                            {errors[`description_${item.id}`] && errors[`description_${item.id}`].message}
                          </span>  
                        </td>
                        <td>
                          <Controller
                            control={control}
                            name={`shop_requested_${item.id}`}
                            rules={{required: "Request date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) =>  {
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                  handleInputChange(setShopData, "shop_requested", item.id, date)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                                disabled={isScheduled}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors[`shop_requested_${item.id}`] && errors[`shop_requested_${item.id}`].message}
                          </span>  

                        </td>
                        {isScheduled && (
                          <td>
                            <Controller
                              control={control}
                              name={`shop_scheduled_${item.id}`}
                              rules={{required: "Schedule date must be required"}}
                              render={({ field }) => (
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) =>  {
                                    const adjustedDate = adjustToNoon(date);
                                    field.onChange(adjustedDate);
                                    handleInputChange(setShopData, "shop_scheduled", item.id, adjustedDate)
                                  }}
                                  className="form-control fs--1"
                                  placeholderText="dd-MMM-YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  fixedHeight
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors[`shop_scheduled_${item.id}`] && errors[`shop_scheduled_${item.id}`].message}
                            </span> 
                          </td>
                        )}
                        <td>
                          <Controller
                            control={control}
                            name={`erc_requested_${item.id}`}
                            rules={{required: "Request date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) =>  {
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                  handleInputChange(setShopData, "erc_requested", item.id, adjustedDate)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                                disabled={isScheduled}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors[`erc_requested_${item.id}`] && errors[`erc_requested_${item.id}`].message}
                          </span>  

                        </td>
                        {isScheduled && (
                          <td>
                            <Controller
                              control={control}
                              name={`erc_scheduled_${item.id}`}
                              rules={{required: "Schedule date must be required"}}
                              render={({ field }) => (
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) =>  {
                                    const adjustedDate = adjustToNoon(date);
                                    field.onChange(adjustedDate);
                                    handleInputChange(setShopData, "erc_scheduled", item.id, adjustedDate)
                                  }}
                                  className="form-control fs--1"
                                  placeholderText="dd-MMM-YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  fixedHeight
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors[`erc_scheduled_${item.id}`] && errors[`erc_scheduled_${item.id}`].message}
                            </span> 
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {!isScheduled && (
                  <Flex
                    justifyContent="end"
                    alignItems="center"
                    className="text-primary cursor-pointer fs--1 mt-3"
                    onClick={handleAddShop}
                  >
                    <span className="circle-dashed">
                      <FontAwesomeIcon icon="plus" />
                    </span>
                    <span className="ms-3">Add Record</span>
                  </Flex>
                  )}
              </Card.Body>
            </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="border-0">
          <Card.Header className="p-0">
            <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 mt-1"
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900 text-primary">
                  4. A/B for Erection Drawings
                </span>
              </div>
            </Accordion.Button>
          </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2 gx-2">
                  <Col>
                    <Form.Group as={Row} className="mb-2" controlId="requested_date">
                      <Form.Label column sm={5} className="text-lg-start">
                        Request date
                      </Form.Label>
                      <Col>
                        <Controller
                          control={control}
                          name="requested_date"
                          rules={{required: "Request date must be required"}}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => { 
                                const adjustedDate = adjustToNoon(date);
                                field.onChange(adjustedDate);
                                handleUpdateAbErection("requested_date", adjustedDate)
                              }}
                              className="form-control fs--1"
                              placeholderText="dd-MMM-YYYY"
                              dateFormat="dd-MMM-yyyy"
                              fixedHeight
                              disabled={isScheduled}
                            />
                          )}
                        />
                        <span className="text-danger">
                          {errors.requested_date && errors.requested_date.message}
                        </span>  
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    {isScheduled && (
                      <Form.Group as={Row} className="mb-2" controlId="scheduled_date">
                        <Form.Label column sm={5} className="text-lg-start">
                          Schedule date
                        </Form.Label>
                        <Col>
                          <Controller
                            control={control}
                            name="scheduled_date"
                            rules={{required: "Schedule date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) => { 
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                  handleUpdateAbErection("scheduled_date", adjustedDate)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors.scheduled_date && errors.scheduled_date.message}
                          </span>  
                        </Col>
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="border-0">
          <Card.Header className="p-0">
            <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 mt-1"
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900 text-primary">
                  5. Other Drawings
                </span>
              </div>
            </Accordion.Button>
          </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Table striped hover>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td>Request date</td>
                      {isScheduled && (
                        <td>Schedule date</td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {otherData && otherData.map(item => (
                      <tr key={item.id}>
                        <td>
                          <MdDelete className="text-danger cursor-pointer" onClick={() => handleDeleteOther(item.id)} />
                        </td>
                        <td>
                          <Form.Control
                            name={`name_${item.id}`}
                            placeholder="Name"
                            className="fs--1"
                            {...register(`name_${item.id}`, {
                              required: "Name must be required."
                            })}
                            onChange={(e) => handleInputChange(setOtherData, "name", item.id, e.target.value)}
                          />
                          <span className="text-danger">
                            {errors[`name_${item.id}`] && errors[`name_${item.id}`].message}
                          </span>  
                        </td>
                        <td>
                          <Controller
                            control={control}
                            name={`requested_date_${item.id}`}
                            rules={{required: "Request date must be required"}}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) =>  {
                                  const adjustedDate = adjustToNoon(date);
                                  field.onChange(adjustedDate);
                                  handleInputChange(setOtherData, "requested_date", item.id, adjustedDate)
                                }}
                                className="form-control fs--1"
                                placeholderText="dd-MMM-YYYY"
                                dateFormat="dd-MMM-yyyy"
                                fixedHeight
                                disabled={isScheduled}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {errors[`requested_date_${item.id}`] && errors[`requested_date_${item.id}`].message}
                          </span>  

                        </td>
                        {isScheduled && (
                          <td>
                            <Controller
                              control={control}
                              name={`scheduled_date_${item.id}`}
                              rules={{required: "Schedule date must be required"}}
                              render={({ field }) => (
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) =>  {
                                    const adjustedDate = adjustToNoon(date);
                                    field.onChange(adjustedDate);
                                    handleInputChange(setOtherData, "scheduled_date", item.id, adjustedDate)
                                  }}
                                  className="form-control fs--1"
                                  placeholderText="dd-MMM-YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  fixedHeight
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors[`scheduled_date_${item.id}`] && errors[`scheduled_date_${item.id}`].message}
                            </span> 
                          </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {!isScheduled && (
                  <Flex
                    justifyContent="end"
                    alignItems="center"
                    className="text-primary cursor-pointer fs--1"
                    onClick={handleAddOther}
                  >
                    <span className="circle-dashed">
                      <FontAwesomeIcon icon="plus" />
                    </span>
                    <span className="ms-3">Add Record</span>
                  </Flex>
                )}
                
              </Card.Body>
            </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
};

RequestScheduleForm.propTypes = {
  estimateData: PropTypes.object.isRequired,
  setEstimateData: PropTypes.func.isRequired,
  abErectionData: PropTypes.object.isRequired,
  setAbErectionData: PropTypes.func.isRequired,
  apdData: PropTypes.object.isRequired,
  setApdData: PropTypes.func.isRequired,
  shopData: PropTypes.object.isRequired,
  setShopData: PropTypes.func.isRequired,
  otherData: PropTypes.object.isRequired,
  setOtherData: PropTypes.func.isRequired,
  isScheduled: PropTypes.bool.isRequired
}

export default RequestScheduleForm;