import LeadEntry from "./LeadEntry";
import LeadFunnel from "./LeadFunnel";
import {Row, Col} from "react-bootstrap";
import LeadMQLStatus from "./LeadMQLStatus";
import LeadChannel from "./LeadChannel";
import LeadSource from "./LeadSource";
import LeadOppoturnity from "./LeadOppoturnity";
import LeadMQL from "./LeadMQL";
import LeadSQL from "./LeadSQL";
import React from 'react';
import PropTypes from 'prop-types';

const LeadOverview = ({data=[]}) => {
  const opportunityData = data.filter(item => item.status_id === 4); //converted to PCV
  const mqlData = data.filter(item => item.stage === "MQL") ;
  const sqlData = data.filter(item => item.stage === "SQL") ;

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <LeadFunnel data={data} />
        </Col>
        <Col>
          <LeadMQLStatus data={data} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col>
          <LeadChannel data={data} />
        </Col>
        <Col>
          <LeadSource data={data} />
        </Col>
      </Row>
    

      <LeadEntry data={data} />
      <LeadOppoturnity data={opportunityData} />
      <LeadMQL data={mqlData} />
      <LeadSQL data={sqlData} />
    </>
  )
};

LeadOverview.propTypes = {
  data: PropTypes.array.isRequired
};

export default LeadOverview;