import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { getColor, rgbaColor } from 'helpers/utils';
import { tooltipFormatter } from 'helpers/echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data, statusNames, salesUnitRegionNames) => ({
  color: [
    //getColor('primary'),
    getColor('success'),
    getColor('warning'),
    getColor('secondary'),
    getColor('danger'),
    rgbaColor(getColor('danger'), 0.2),
  ],
  legend: {
    data: statusNames,
    left: 5,
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    textStyle: { color: getColor('gray-700') },
    itemGap: 20
  },
  xAxis: {
    name: "Sales regions",
    type: 'category',
    data: salesUnitRegionNames,
    axisLine: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-600'),
      //formatter: value => dayjs(value).format('ddd')
    }
  },
  yAxis: {
    name: "Number of waivers",
    type: 'value',
    position: 'left',
    splitLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      color: getColor('gray-600'),
      margin: 15
    }
  },
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    axisPointer: {
      type: 'none'
    },
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-700') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  series: Object.keys(data).map((status_name) => ({
    name: status_name,
    type: 'bar',
    stack: 'stackbar',
    data: Object.values(data[status_name]),
  })),
  grid: {
    containLabel: true,
    right: '8%',
    left: '2%',
    bottom: '5%',
    top: '20%'
  }
});

const WaiverSalesRegionV2 = ({ data, statusNames, salesUnitRegionNames }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="By sales regions"
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select size="sm">
            <option>Last 7 days</option>
            <option>Last Month</option>
            <option>This Month</option>
            <option>This Year</option>
            <option>Last Year</option>
          </Form.Select>
        }
      />
      <Card.Body className="h-100 w-100">
        <ReactEChartsCore
           echarts={echarts}
           option={getOptions(data, statusNames, salesUnitRegionNames)}
           style={{ height: '21.875rem' }}
        />
      </Card.Body>
    </Card>
  );
};

WaiverSalesRegionV2.propTypes = {
  data: PropTypes.array.isRequired,
  statusNames: PropTypes.array.isRequired,
  salesUnitRegionNames: PropTypes.array.isRequired
};

export default WaiverSalesRegionV2;
