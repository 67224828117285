import { useParams } from "react-router-dom";
import RoleDetailHeader from "./RoleDetailHeader";
import RoleDetailInfo from "./RoleDetailInfo";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRole } from "redux/authorization/roleSlice";
import { Card, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import RoleActivity from "./RoleActivity";

const RoleDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {recordData: role} = useSelector((state) => state.authorization_role);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    dispatch(getRole(params.id));
    console.log(trigger)
  }, [params.id, trigger]);

  return (
    <>
      <RoleDetailHeader role={role} setTrigger={setTrigger} />
      <Card>
        <Tab.Container defaultActiveKey="Role-detail">
          <Card.Header className="p-0 border-bottom">
            <Nav className="nav-tabs border-0 flex-nowrap tab-Role-details">
              <Nav.Item>
                <Nav.Link
                  eventKey="Role-detail"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="stream"
                    className="text-600 tab-icon"
                  />
                  <h6 className="text-600 mb-0 ms-1">Detail</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Role-activity-log"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FontAwesomeIcon
                    icon="history"
                    className="text-600"
                  />
                  <h6 className="text-600 mb-0 ms-1">Activities history</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              <Tab.Pane eventKey="Role-detail">
                <RoleDetailInfo role={role}/>
              </Tab.Pane>
              <Tab.Pane eventKey="Role-activity-log">
               <RoleActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  )
};

export default RoleDetail;