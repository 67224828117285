import { Card } from "react-bootstrap";
import React from 'react';

const ReadMe = () => {
  return (
    <Card>
      <Card.Body className="p-4 fs--1">
        <div className="mb-2">Explanation to get value right of report:
          <div className="mt-2">
            <label className="font-weight-bold text-primary">1. Jobs (J):</label> must be activated job
            <ul style={{ listStyle: 'unset' }}>
              <li>ACTIVE PROJECT (Shop & Fabrication) =&gt; Current status</li>
              <li>ACTIVE PROJECTS (Waiting RAD, Under APD, Re-APD, Contract signed) =&gt; Current status</li>
              <li>FINISHED SHIPMENT (status of name)</li>
            </ul>
          </div>

          <div>
            <label className="font-weight-bold text-primary">2. Prospective projects (P)</label>
            <ul style={{ listStyle: 'unset' }}>
              <li>PROSPECTIVE PROJECTS</li>
            </ul>
          </div>
        
          <div>
            <label className="font-weight-bold text-primary">3. Lost jobs (L)</label>
            <ul style={{ listStyle: 'unset' }}>
              <li>CANCELLED (ID#1)</li>
              <li>LOST (ID#12)</li>
              <li>WAIT FOR A LONG TIME (ID#34): <span className="text-primary">if sum of final estimate weight &gt; 100 M.T then counting otherwise nothing. However, final estimate weight will be always sum of them</span>.</li>
            </ul>
          </div>

          <div>
            <label className="font-weight-bold text-danger">Notes that we don’t count and sum of weight with status of name:</label>
            <ul style={{ listStyle: 'unset' }}>
              <li>SITE WORK (ID#14) </li>
              <li>SOLD SCRAP (ID#15)</li>
              <li>HANDLE BY PEBY (ID#8): <span className="text-primary">just count and sum of final estimate weight when project has many CO# &gt; CO#00</span>.</li>
            </ul>
          </div>

          <div className="text-primary small text-end mt-5 font-italic">Updated by THV, Date: 06 December 2022.</div>

        </div>
      </Card.Body>
    </Card>
  )
}

export default ReadMe