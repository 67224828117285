import apiAxios from './apiAxios';

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

const getEmployeeOption = async (url, inputValue, justShowName = false, hasPage = true) => {
  try {
    const newUrl = hasPage ? `${url}?page=1&per_page=30&q=${inputValue}` : url
    const response = await apiAxios.get(newUrl);
    const { data } = response;

    const options = data && data.map((item) => {
      if (item.attributes) {
        return { value: justShowName ? item.attributes.name : item.attributes.id, label: item.attributes.name };
      } else {
        return { value: justShowName ? item.name : item.id, label: item.name };
      }
    });

    return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const filterOptions = async (url, inputValue, justShowName = false) => {
  try {
    const response = await apiAxios.get(`${url}?page=1&per_page=30&q=${inputValue}`);
    const { data } = response;

    const options = data && data.map((item) => {

      if (item.attributes) {
        return { value: justShowName ? item.attributes.name : item.attributes.id, label: item.attributes.name };
      } else {
        return { value: justShowName ? item.name : item.id, label: item.name };
      }
    });

    return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
  } catch (error) {
    console.error(error);
    return [];
  }
};

const employeUrl = '/organization/employees/active_list';

//Just only label and value is name
export const debouncedFilterEmployees = debounce((inputValue, callback) => {
  filterOptions(employeUrl, inputValue, true).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

//Just only label is id and value is name
export const debouncedFilterEmployeesOriginal = debounce((inputValue, callback) => {
  filterOptions(employeUrl, inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const debouncedEmployees = debounce((inputValue, callback, employeeType = 'all') => {
  let url;
  url = employeeType === 'all' ? `${employeUrl}?q=${inputValue}` : `${employeUrl}?employee_type=${employeeType}&q=${inputValue}`;
  const urlNew = `${url}&page=1&per_page=30`;

  getEmployeeOption(urlNew, inputValue, true, false).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);
