import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Collapse, Accordion, Card, Col, Row, Image, Form, Table } from "react-bootstrap";
import logo from "./../../../../assets/img/pebsteel.png";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatDate } from "helpers/utils";
import PropTypes from 'prop-types';

const DetailGDS = ({pif}) => {
  const {gds: initialKeys} = useSelector(state => state.crm_project_pif.initialKeys)
  const [activeKey, setActiveKey] = useState(initialKeys)

  const {project, building, adaptation} = pif || {};

  const gds = pif?.gds || {}
  const paintSystemData = gds?.paint_systems || []
  const secondaryMemberData = gds?.secondary_members || []

  useEffect(() => {
    setActiveKey(initialKeys);
  }, [initialKeys]);

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <Collapse in={true}>
      <Accordion
        id="detail-gds-pif"
        className="border rounded overflow-hidden fs--1"
        alwaysOpen
        flush
        activeKey={activeKey}
        onSelect={handleAccordionSelect}
      >
        <Card className="rounded-0">

          {/* 1. General Information */}
          <Accordion.Item eventKey="0" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    1. General Information
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="gx-0">
                  <Col sm={2}>
                    <Image
                      fluid
                      className="fit-cover rounded"
                      src={logo}
                      alt="Logo Pebsteel"
                      width="120"
                      height="120"
                    />
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>
                        Quote No:
                      </Col>
                      <Col sm={9} md={7}>
                        <span className="text-primary">{project?.q_number}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        P/Job No:
                      </Col>
                      <Col sm={9} md={7}>
                        <span className="text-primary">{building?.j_number}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>
                        CO#: 
                      </Col>
                      <Col sm={10} md={8}>
                        <span className="text-primary">{adaptation?.co || '00'}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        REV#:
                      </Col>
                      <Col sm={10} md={8}>
                        <span className="text-primary">{adaptation?.rev}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                  <Row className="mb-2">
                      <Col>
                        Date: 
                      </Col>
                      <Col sm={10} md={8}>
                        <span className="text-primary">{formatDate(adaptation?.entry_date)}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        Date:
                      </Col>
                      <Col sm={10} md={8}>
                        <span className="text-primary">{formatDate(adaptation?.entry_date)}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Flex justifyContent="between">
                      <Form.Check
                        type="checkbox"
                        label="For Order Entry"
                        name="for_order_entry"
                        checked={adaptation?.type === "change_order"}
                      />

                      <Form.Check
                        type="checkbox"
                        label="For Quotation"
                        name="for_quotation"
                        checked={adaptation?.type === "revision"}
                      />
                    </Flex>
                    <h6 className="text-center my-2">This sheet is applicable to entire project</h6>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Row className="mb-2">
                      <Col>
                        Project name:
                      </Col>
                      <Col sm={10} md={8}>
                        <span className="text-primary">{project?.name}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        Location:
                      </Col>
                      <Col sm={10} md={8}>
                        <span className="text-primary">{building?.pj_location}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>
                        No of all Bldgs in this Project:
                      </Col>
                      <Col sm={10} md={6}>
                        <span className="text-primary">{gds?.no_of_bldgs}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        No. of Non Identical Bldgs in this Project:
                      </Col>
                      <Col sm={10} md={6}>
                        <span className="text-primary">{gds?.no_of_non_identical_bldgs}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 2. Current Contract status */}
          <Accordion.Item eventKey="1" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    2. Current Contract status
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Letter of intent issued by customer on (Date)"
                      checked={gds?.contract_loi}
                    />
                  </Col>
                  <Col sm={10} md={6}>
                    <span className="text-primary">{formatDate(gds?.contract_loi_on_date)}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="PEB Standard Offer signed by customer on (Date)"
                      checked={gds?.peb_standard_offer_signed}
                    />
                  </Col>
                  <Col sm={10} md={6}>
                    <span className="text-primary">{formatDate(gds?.peb_standard_offer_signed_date)}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Customer contract signed by customer on (Date)"
                      checked={gds?.customer_contract_signed}
                    />
                  </Col>
                  <Col sm={10} md={6}>
                    <span className="text-primary">{formatDate(gds?.customer_contract_signed_date)}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Others (Explain)"
                      checked={gds?.contract_status_others}
                    />
                  </Col>
                  <Col sm={10} md={6}>
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.contract_status_others_explain }}></span>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 3. Penalty */}
          <Accordion.Item eventKey="2" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    3. Penalty
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2">
                  <Col>
                    Terms of penalty
                  </Col>
                  <Col md={8}>
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.term_of_penalty }}></span>
                  </Col>
                </Row>
                <Row className="mb-2 mt-3">
                  <Col>
                    Max. Penalty as % of Contract Price:
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="5%"
                      checked={gds?.penalty_of_contact_price_5_percent}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Check
                      type="checkbox"
                      label="Others (Specify)"
                      checked={gds?.penalty_of_contact_price_other}
                    />
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.penalty_of_contact_price_other_specify }}></span>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 4. Shipment */}
          <Accordion.Item eventKey="3" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    4. Shipment
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2">
                  <Col>
                    Delivery Commitment (weeks):
                  </Col>
                  <Col md={8}>
                    <span className="text-primary">{gds?.shipment_delivery_commitment}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    Delivery by:
                  </Col>
                  <Col md={8}>
                    <Flex justifyContent="between">
                      <Form.Check
                        type="checkbox"
                        label="by PEB Steel"
                        checked={gds?.shipment_delivery_by_pebsteel}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Customer pickup"
                        checked={gds?.shipment_customer_pickup}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Partial shipment allowed"
                        checked={gds.shipment_partial_allow}
                      />

                    </Flex>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <Row className="g-0">
                      <Col md={3}>
                        <Form.Check
                          type="checkbox"
                          label="Overland to"
                          checked={gds.overland_to}
                        />
                      </Col>
                      <Col>
                        <span className="text-primary">{gds.shipment_overland_to}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="g-0">
                      <Col md={3}>
                        <Form.Check
                          type="checkbox"
                          label="Seafreight to"
                          checked={gds.seafreight_to}
                        />
                      </Col>
                      <Col>
                        <span className="text-primary">{gds.shipment_seafreight_to}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Seafreight"
                          checked={gds.shipment_seafreight}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Container"
                          checked={gds.shipment_container}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Truck"
                          checked={gds.shipment_truck}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col>
                        Export document by:
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="PEB Steel"
                              checked={gds.shipment_export_document_by_pebsteel}
                            />
                          </Col>
                          <Col md={8}>
                            <Form.Check
                              type="checkbox"
                              label="by others"
                              checked={gds.shipment_export_document_by_others}
                            />

                            <span className="text-primary">{gds.shipment_export_document_by_others_specify}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 5. Required Initial Engineering Submittals */}
          <Accordion.Item eventKey="4" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    5. Required Initial Engineering Submittals
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Design calculation"
                      checked={gds.eng_design_calculation}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Approval drawings"
                      checked={gds.eng_approval_drawings}
                    />
                        
                    <Form.Check
                      type="checkbox"
                      label="Erection drawings"
                      checked={gds.eng_erection_drawings}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Preliminary Design Sheet"
                      checked={gds.end_preliminary_design_sheet}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Anchor Bolts Plan"
                      checked={gds.eng_anchor_bolts_plan}
                    />       
                  </Col>
                </Row>             
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 6. Anchor Bolts */}
          <Accordion.Item eventKey="5" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    6. Anchor Bolts
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2">
                  <Col>
                    Anchor Bolts by:
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="PEB Steel"
                          checked={gds.anchor_bolts_by_pebsteel}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="by others"
                          checked={gds.anchor_bolts_by_other}
                        />

                        <div className="text-primary" dangerouslySetInnerHTML={{ __html: gds.anchor_bolts_by_other_specify }}></div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Row>
                      <Col>Ship Anchor Bolts:</Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="with Job"
                          checked={gds.anchor_bolts_ship_with_job}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Priod to Job"
                          checked={gds.anchor_bolts_priod_to_job}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col>Ship by:</Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Overland"
                          checked={gds.anchor_bolts_ship_by_overland}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Seafreight"
                          checked={gds.anchor_bolts_ship_by_seafreight}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-primary" dangerouslySetInnerHTML={{ __html: gds.anchor_bolts_note }}></div>
                  </Col>
                </Row>

              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 7. Buyer (Invoice to) */}
          <Accordion.Item eventKey="6" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    7. Buyer (Invoice to)
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col>
                    <Row className="mb-2">
                      <Col>Name:</Col>
                      <Col md={9} className="text-primary">
                        {/* <Link to={`/crm/customers/${project?.customer?.id}`} target="_blank">{project?.customer?.name}</Link> */}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Phone:</Col>
                      <Col md={9} className="text-primary"></Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Address:</Col>
                      <Col md={9} className="text-primary"></Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Contact person:</Col>
                      <Col md={9} className="text-primary"></Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>Email:</Col>
                      <Col md={9} className="text-primary"></Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Fax:</Col>
                      <Col md={9} className="text-primary"></Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Title:</Col>
                      <Col md={9} className="text-primary"></Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 8. Consignee (Ship To)  */}
          <Accordion.Item eventKey="7" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    8. Consignee (Ship To) 
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col>
                    <Row className="mb-2">
                      <Col>Name:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Phone:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Address:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>Mobile:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Fax:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Email:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 9. Shipment (Advice To) */}
          <Accordion.Item eventKey="8" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    9. Shipment (Advice To)
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col>
                    <Row className="mb-2">
                      <Col>Name:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Fax:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>Mobile:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>Email:</Col>
                      <Col md={9}>
                        <span className="text-primary"></span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 10. Contractor */}
          <Accordion.Item eventKey="9" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    10. Contractor
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col md={{span: 6}}>
                    <Row className="mb-2">
                      <Col>Name:</Col>
                      <Col md={9} className="text-primary">
                        {/* <Link to={`/crm/customers/${project?.contractor?.id}`} target="_blank">{project?.contractor?.name}</Link> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    11. Consultant
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col md={{span: 6}}>
                    <Row className="mb-2">
                      <Col>Name:</Col>
                      <Col md={9} className="text-primary">
                        {/* <Link to={`/crm/customers/${project?.consultant?.id}`} target="_blank">{project?.consultant?.name}</Link> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="11" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    12. Enduser
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col md={{span: 6}}>
                    <Row className="mb-2">
                      <Col>Name:</Col>
                      <Col md={9} className="text-primary">
                        {/* <Link to={`/crm/customers/${project?.enduser?.id}`} target="_blank">{project?.enduser?.name}</Link> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 13. Erection */}
          <Accordion.Item eventKey="12" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    13. Erection
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row className="mb-2">
                  <Col>
                    Erection by:
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="PEB Steel"
                          checked={gds.erection_by_pebsteel}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="by others"
                          checked={gds.erection_by_others}
                        />
                      </Col>
                      <Col md={6} sm={8}>
                        <Row className="gx-2">
                          <Col md="auto">
                            Erector Name:
                          </Col>
                          <Col>
                            <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds.erector_name }}></span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Erection supervisior by PEB"
                      checked={gds.erection_supervisor_by_peb}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col md="auto">
                        Explain details:
                      </Col>
                      <Col>
                        <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds.erection_explain_detail }}></span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 14. Primary Members */}
          <Accordion.Item eventKey="13" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    14. Primary Members
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col md="auto">
                    Surface treatment:
                  </Col>
                  <Col>
                    <Flex justifyContent="between">
                      <Form.Check
                        type="checkbox"
                        label="Power clean ST3"
                        checked={gds.primary_power_clean_st3}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Shotblasting SA 2.0"
                        checked={gds.primary_shotblasting_sa_2_0}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Shotblasting SA 2.5"
                        checked={gds.primary_shotblasting_sa_2_5}
                      />

                      <Form.Check
                        type="checkbox"
                        label="H.D.Galv"
                        checked={gds.primary_hd_galv}
                      />

                      <Form.Check
                        type="checkbox"
                        label="DFT"
                        checked={gds.primary_dft}
                      />
                    </Flex>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Flex>
                      <div className="me-3">Purlin Clip to Column / Rafter:</div>
                        <Form.Check
                          type="checkbox"
                          checked={gds.primary_purlin_clip_to_column}
                        />
                      </Flex>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds.primary_memebers_note }}></span>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 15. Paint system */}
          <Accordion.Item eventKey="14" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    15. Paint system
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <div className="fs--1">
                  <Table striped>
                    <thead className="bg-200 text-900">
                      <tr>
                        <th className="border">Coat No.</th>
                        <th className="border">DFT (microns)</th>
                        <th className="border">Paint Type</th>
                        <th className="border">Manufacturer</th>
                        <th className="border">Color</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paintSystemData && paintSystemData.map((item, index) => (
                        <tr key={index}>
                          <td className="border">{item.cost_no}</td>
                          <td className="border">{item.dft}</td>
                          <td className="border">{item.paint_type}</td>
                          <td className="border">{item.manufacturer}</td>
                          <td className="border">{item.color}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <Row className="mt-4">
                  <Col>
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.paint_system_note }}></span>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 16. Secondary Members (Purlins, girts, eave strut) */}
          <Accordion.Item eventKey="15" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    16. Secondary Members (Purlins, girts, eave strut)
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col sm={3}>
                    <Form.Check
                      type="checkbox"
                      label="Galv."
                      checked={gds.second_galv}
                    />      
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="fw-semi-bold">
                        Surface treatment:
                      </Col>
                      <Col>
                        <Flex justifyContent="between">
                          <Form.Check
                            type="checkbox"
                            label="Power clean ST3"
                            checked={gds.second_power_clean_st3}
                          />

                          <Form.Check
                            type="checkbox"
                            label="Shotblasting SA 2.0"
                            checked={gds.second_shotblasting_sa_2_0}
                          />

                          <Form.Check
                            type="checkbox"
                            label="Shotblasting SA 2.5"
                            checked={gds.second_shotblasting_sa_2_5}
                          />
                        </Flex>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={3} className="fw-semi-bold">
                        Paint system:
                      </Col>
                      <Col>
                        <Flex justifyContent="between">
                          <Form.Check
                            type="checkbox"
                            label="Standard Alkyd red oxide primer"
                            checked={gds.second_paint_standard}
                          />

                          <Form.Check
                            type="checkbox"
                            label="Special (see below)"
                            checked={gds.second_paint_speical}
                          /> 
                        </Flex>    
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
                <div className="fs--1 mt-2">
                  <Table striped>
                    <thead className="bg-200 text-900">
                      <tr>
                        <th className="border">Coat No.</th>
                        <th className="border">DFT (microns)</th>
                        <th className="border">Paint Type</th>
                        <th className="border">Manufacturer</th>
                        <th className="border">Color</th>
                      </tr>
                    </thead>
                    <tbody>
                      {secondaryMemberData && secondaryMemberData.map((item, index) => (
                        <tr key={index}>
                          <td className="border">{item.cost_no}</td>
                          <td className="border">{item.dft}</td>
                          <td className="border">{item.paint_type}</td>
                          <td className="border">{item.manufacturer}</td>
                          <td className="border">{item.color}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <Row className="mt-4">
                  <Col>
                    <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.secondary_memeber_note }}></span>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 17. Attached */}
          <Accordion.Item eventKey="16" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    17. Attached
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Flex justifyContent="between" className="mx-4">
                  <Form.Check
                    type="checkbox"
                    label="GDS"
                    checked={gds.attached_gds}
                  />

                  <Form.Check
                    type="checkbox"
                    label="BDS1"
                    checked={gds.attached_bds1}
                  />

                  <Form.Check
                    type="checkbox"
                    label="BDS2"
                    checked={gds.attached_bds2}
                  />

                  <Form.Check
                    type="checkbox"
                    label="BDS3"
                    checked={gds.attached_bds3}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Sketchs"
                    checked={gds.attached_sketchs}
                  />
                </Flex>
            </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 18. Checking */}
          <Accordion.Item eventKey="17" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    18. Checking
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col>
                    <Row className="align-items-center">
                      <Col md="auto">Filled by:</Col>
                      <Col>
                        <span className="text-primary">{gds.filled_by}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="align-items-center">
                      <Col md="auto">Checked by:</Col>
                      <Col>
                        <span className="text-primary">{gds.checked_by}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="align-items-center">
                      <Col md="auto">Date:</Col>
                      <Col>
                        <span className="text-primary">{formatDate(gds.checked_date)}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>

          {/* 19. Double Side Welding - UT Test - Third Part Inspection */}
          <Accordion.Item eventKey="18" className="border-0">
            <Card.Header className="p-0">
              <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 mt-1"
                    transform="shrink-2"
                  />
                  <span className="fw-medium font-sans-serif text-900 text-primary">
                    19. Double Side Welding - UT Test - Third Part Inspection
                  </span>
                </div>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body className="m-2">
                <Row>
                  <Col md="auto">
                    <Form.Check
                      type="checkbox"
                      label="Double Side Welding"
                      checked={gds.double_side_welding}
                    />

                    <Form.Check
                      type="checkbox"
                      label="UT Test"
                      checked={gds.ut_test}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Third Party Inspection"
                      checked={gds.third_party_inspection}
                    />
                  </Col>
                  <Col>
                    <span className="text-primary">{gds.double_side_welding_notes}</span>
                  </Col>
                </Row>

                <Flex justifyContent="between" className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="Engineering"
                    checked={gds.engineering}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Sales Support Dept,"
                    checked={gds.sales_support_dept}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Sales Dept,"
                    checked={gds.sales_dept}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Customer Service Dept,"
                    checked={gds.customer_service_dept}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Production,"
                    checked={gds.production}
                  />
         
                  <Form.Check
                    type="checkbox"
                    label="Finance,"
                    checked={gds.finance}
                  />
                </Flex>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
        </Card>
      </Accordion>
    </Collapse>
)
}

DetailGDS.propTypes = {
  pif: PropTypes.object.isRequired
}

export default DetailGDS;

