import SoftBadge from "components/common/SoftBadge";
import PropTypes from 'prop-types';
import React from 'react';

const BadgeRenderer = (props) => {
  const value = props.value;

  return (
    value.map((name, index) => (
      <SoftBadge bg='info' key={index} className='me-1'>{name}</SoftBadge> 
    ))
  )
};

BadgeRenderer.propTypes = {
  value: PropTypes.number
}

export default BadgeRenderer;