import React, { useState } from 'react';
import { Card, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { formatNumber, formatNumberWord, rgbaColor } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicECharts from 'components/common/BasicEChart';

import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import DetailDataModal from '../DetailDataModal';
import PCVDetail from './PCVDetail';
import PropTypes from'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

getOptions.propTypes = {
  data: PropTypes.array.isRequired
};

const PCVThisMonth = ({data={}}) => {
  const [showDetailModal, setShowDetailModal] = useState(false)

  const {this_month, last_month} = data;
  const this_month_data = this_month?.data;
  const summaryData = this_month?.summary;

  const chartDataNumber = summaryData && Object.entries(summaryData).map(([date, { number_of_projects }]) => [date, number_of_projects]);
  const chartDataWeight = summaryData && Object.entries(summaryData).map(([date, { weight }]) => [date, weight]);

  const handleViewDetail = (e) => {
    e.preventDefault();
    setShowDetailModal(true);
  }

  return (
    <>
      <Card className="h-100">
        <FalconCardHeader
          title="PCV this month"
          titleTag="h6"
          className="py-2"
          light
        />
        <Card.Body className="pb-0">
          <Row>
            <Col md={6} sm={12}>
              <h6 className="text-700">Number of projects</h6>
              <Flex justifyContent="between" className="my-2">
                <div>
                  <span className="h3 text-primary">{formatNumberWord(this_month?.number_of_projects)}</span>
                  <span className="text-500 fs--1 ms-1 mb-3">vs {formatNumberWord(last_month?.number_of_projects)}</span>
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip style={{ position: 'fixed' }}>
                          Compare with the last month
                        </Tooltip>
                      }
                    >
                      <span>
                        <FontAwesomeIcon
                          icon="info-circle"
                          transform="shrink-1"
                          className="ms-1 text-400"
                        />
                      </span>
                    </OverlayTrigger>
                </div>
                <div>
                  <BasicECharts
                    echarts={echarts}
                    options={getOptions(chartDataNumber)}
                    style={{ width: '6.5rem', height: 50 }}
                  />
                </div>
              </Flex>
            </Col>
            <Col md={6} sm={12}>
              <h6 className="text-700">Weight</h6>
              <Flex justifyContent="between" className="my-2">
                <div>
                  <span className="h3 text-primary">{formatNumberWord(this_month?.weight)} M.T</span>
                  <span className="text-500 fs--1 ms-1 mb-3">vs {formatNumberWord(last_month?.weight)} M.T</span>
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip style={{ position: 'fixed' }}>
                          Compare with the last month
                        </Tooltip>
                      }
                    >
                      <span>
                        <FontAwesomeIcon
                          icon="info-circle"
                          transform="shrink-1"
                          className="ms-1 text-400"
                        />
                      </span>
                    </OverlayTrigger>
                </div>
                <div>
                  <BasicECharts
                    echarts={echarts}
                    options={getOptions(chartDataWeight)}
                    style={{ width: '6.5rem', height: 50 }}
                  />
                </div>
              </Flex>
            </Col>
          </Row>
          <div className="mx-ncard">
            <SimpleBarReact>
              <Table className="fs--1 mb-0 overflow-hidden">
                <thead className="bg-100 text-800">
                  <tr>
                    <th className="text-nowrap">PCV no.</th>
                    <th className="text-nowrap">Quote number</th>
                    <th className="text-nowrap text-end">Weight</th>
                    <th className="text-nowrap text-end">Sales region</th>
                  </tr>
                </thead>
                <tbody>
                  {this_month_data && this_month_data.length > 0 ? (
                    this_month_data.slice(0, 5).map((item, index) => (
                      <tr key={index}>
                        <td className="text-truncate">
                          <Link to={`/crm/projects/${item.project_id}`} target="_blank">{item.project_id}</Link>
                        </td>
                        <td className="text-truncate">
                          <Link to={`/crm/projects/${item.project_id}`} target="_blank">{item.q_number}</Link>
                        </td>
                        <td className="text-end">{formatNumber(item.est_final_weight)} M.T</td>
                        <td className="text-end">{item.sales_unit_region}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center text-primary">No data found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </SimpleBarReact>
          </div>
        </Card.Body>
        <FalconCardFooterLink title="View Details" size="sm" onClick={(e) => handleViewDetail(e)} />
      </Card>

      {showDetailModal && (
        <DetailDataModal
          showModal={showDetailModal}
          setshowModal={setShowDetailModal}
          title="PCV of this month"
          dataComponent={<PCVDetail data={this_month_data} />}
        />
      )}
    </>
  )
}

PCVThisMonth.propTypes = {
  data: PropTypes.object.isRequired
};

export default PCVThisMonth;