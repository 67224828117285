import { PERPAGE_DEFAULT_SM } from "app_config";
import { objectSerializer } from "./utils";
import { calPaginatorRecord } from "./paginator";
import { toast } from "react-toastify";

const createInitialState = () => ({
  data: [],
  totalRows: 0,
  totalPagesCount: 0,
  currentPage: 1,
  pageSize: PERPAGE_DEFAULT_SM,
  fromRecord: 0,
  toRecord: 1,
  currentParams: { page: 1, per_page: PERPAGE_DEFAULT_SM },
  filterTags: [],
  selectedRecord: []
});

const initialState = {
  allData: createInitialState(),
  activityLogData: createInitialState(),
  loading: false,
  exporting: false,
  sortParams: {attribute: 'id', direction: 'desc'},
  sortColumns: {},
  recordData: {}
}

const initialStateStatus = {
  allData: createInitialState(),
  submitedData: createInitialState(),
  waitingApprovalData: createInitialState(),
  approvedData: createInitialState(),
  rejectedData: createInitialState(),
  canceledData: createInitialState(),
  activityLogData: createInitialState(),
  loading: false,
  exporting: false,
  sortParams: {attribute: 'id', direction: 'desc'},
  sortColumns: {},
  recordData: {}
}

const handleFulfilled = (state, action, dataKey) => {
  state.loading = false;
  const results = action.payload;
  const data = state[dataKey];

  if (results.rows.length > 0) {
    data.data = objectSerializer(results.rows);
    data.totalRows = results.total_rows;
  }
  else
  {
    data.data = [];
    data.totalRows = 0;
  }
 
  const calRecords = calPaginatorRecord(data.currentPage, data.pageSize, data.totalRows);
  data.totalPagesCount = calRecords.totalPagesCount;
  data.fromRecord = calRecords.beginRecords || 1;
  data.toRecord = calRecords.lastRecords;
};

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const record = data.attributes
    
    if (record !== null) {
      switch (type) {
        case "create": 
          state.allData.data.unshift(record)
          break;
        case "delete":
          state.allData.data = state.allData.data.filter((item) => item.id !== record.id)
          break;
        default:
        {
          const updatedIndex = state.allData.data.findIndex((item) => item.id === record.id)
          if (updatedIndex !== -1) {
            state.allData.data[updatedIndex] = record
          }

          state.recordData = record;
          break;
        }
      }
    }

    if (message !== null) {
      toast.success(message);
    }
  }
};

const setNestedPageHelper = (state, action) => {
  const { key, page } = action.payload;
  state[key].currentPage = page;
};

const setNestedPageSizeHelper = (state, action) => {
  const { key, pageSize } = action.payload;
  state[key].pageSize = parseInt(pageSize);
};

const setNestedParamsHelper = (state, action) => {
  const { key, params } = action.payload;
  state[key].currentParams = params;
};

const resetNestedParamsHelper = (state, action) => {
  const { key } = action.payload;
  state[key].currentParams = { page: 1, per_page: PERPAGE_DEFAULT_SM };
  state[key].filterTags = [];
};

const setSortParamsHelper = (state, action) => {
  state.sortParams = action.payload;
};

const setFilterTagHelper = (state, action) => {
  const { key, tags } = action.payload;
  state[key].filterTags = tags;
};

const resetFilterTagHelper = (state, action) => {
  const { key } = action.payload;
  state[key].filterTags = [];
};

const setSelectedRecordHelper = (state, action) => {
  const { key, selectedRecord } = action.payload;
  state[key].selectedRecord = selectedRecord;
};

const resetSelectedRecordHelper = (state, action) => {
  const { key } = action.payload;
  state[key].selectedRecord = [];
};

export {
  createInitialState,
  initialState,
  initialStateStatus,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  setSelectedRecordHelper,
  resetSelectedRecordHelper,
  handleFulfilled,
  handlePayload
};
