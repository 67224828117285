import React from 'react';
import ActionComponent from "./ActionComponent";
import { getSize } from "helpers/utils"
import useAuth from "hooks/useAuth";
import { FaFolder, FaFile, FaUserCircle, FaUsers } from "react-icons/fa";
import { Link } from 'react-router-dom'
import PropTypes from'prop-types';

const DriveItem = ({item, handleAction}) => {
  const {userName} = useAuth();
  const isFolder = item.asset_type === "folder";

  const assetId = item?.id;
  const driveUrl = `/drives/${assetId}`;

  const folderComponent = () => isFolder ? <FaFolder size="20" /> : <FaFile size="20" /> ;
  
  return (
    <tr key={item?.id} className="hover-actions-trigger btn-reveal-trigger hover-bg-100">
      <td className="text-wrap w-50">
        <span className="me-3">{folderComponent(item)}</span>
        {isFolder ? (
          <Link to={driveUrl}>{item?.name}</Link>
        ) : item?.name}
        
        {item?.public ? <FaUsers className="ms-2" /> : null}
      </td>
      <td><FaUserCircle size="15" /> {item?.created_by === userName ? "me" : item?.created_by}</td>
      <td>{item?.updated_date}</td>
      <td>{item?.asset_type === "file" ? getSize(item?.file_size) : "-"}</td>
      <td>
        <ActionComponent
          item={item}
          handleAction={handleAction}
        />
      </td>
    </tr>
  );
};

DriveItem.propTypes = {
  item: PropTypes.shape({
    asset_type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    public: PropTypes.bool,
    created_by: PropTypes.string,
    updated_date: PropTypes.string,
    file_size: PropTypes.number
  }).isRequired,
  handleAction: PropTypes.func.isRequired
};

export default DriveItem;