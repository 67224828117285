import React from 'react';
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import TransactionDetailUploadAttachment from "./TransactionDetailUploadAttachment";
import IconButton from "components/common/IconButton";
import TransactionDetailAssignDriverForm from "./TransactionDetailAssignDriverForm";
import { useCallback, useState } from "react";
import { approveBooking } from "redux/booking/car/carBookingSlice";

const TransactionDetailApprovalModal = ({showModal, setshowModal, transaction}) => {
  const { register, handleSubmit, formState: {errors}} = useForm()

  const [assignDrivers, setAssignDrivers] = useState([])
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch()

  const validateDriverAssign = () => {
    let ret = true

    if (assignDrivers.length === 0) {
      ret = false
    } 
    else {
      assignDrivers.map((item) => {
        if (item.driver_id === "" || item.car_id === "") {
          ret = false
        }
      })
    }

    if (!ret) {
      toast.error("Driver and car must be assigned.")
    }

    return ret
  }

  const validateDuplicateCar = () => {
    const carIdSet = new Set()
    const driverIdSet = new Set()
    const duplicatesCar = []
    const duplicatesDriver = []

    let ret = true

    for(const driverCar of assignDrivers) {
      const {driver_id, car_id} = driverCar

      if (carIdSet.has(car_id)) {
        duplicatesCar.push(car_id)
      }
      else {
        carIdSet.add(car_id)
      }

      if (driverIdSet.has(driver_id)) {
        duplicatesDriver.push(driver_id)
      }
      else {
        driverIdSet.add(driver_id)
      }
    }

    if (duplicatesCar.length > 0) {
      toast.error("Duplicate cars found.", duplicatesCar)
      ret = false
    }

    if (duplicatesDriver.length > 0) {
      toast.error("Duplicate drivers found.", duplicatesDriver)
      ret = false
    }

    return ret
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
 
    const transactionId = transaction.id

    if (!validateDriverAssign()) return

    //validate duplicate driver or car
    if (!validateDuplicateCar()) return

    data = {
      driver_cars: assignDrivers,
      remark: data.remark,
      files: files
    }
    console.log(data)

    try {
      dispatch(approveBooking({transactionId, data}))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const handleAddDriver = useCallback(() => {
    const newDriver = { driver_id: "", car_id: "" };
    const updatedDrivers = [...assignDrivers, newDriver];

    setAssignDrivers(updatedDrivers)
  }, [assignDrivers])

  const handleRemoveDriver = (index) => {
    const drivers = assignDrivers.filter((item, i) => i !== index)
    setAssignDrivers(drivers)
  }

  const updateAssignDriver = (index, driver) => {
    const newData = { driver_id: driver.id, car_id: driver.car_id };
    
    const updatedAssignDrivers = [...assignDrivers];
    updatedAssignDrivers[index] = newData;
    setAssignDrivers(updatedAssignDrivers);
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-primary border-0">
            <Modal.Title className="fs-0 text-white" id="contained-modal-title-vcenter">Approve car booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Assigning drivers for car booking</h6>
            { assignDrivers.map((item, index) => (
              <TransactionDetailAssignDriverForm 
                index={index}
                key={item.driver_id}
                driver={item}
                handleRemoveDriver={() => handleRemoveDriver(index)}
                transaction={transaction}
                updateAssignDriver={updateAssignDriver}
              />
            ))}
            
            <Form.Group as={Row} className="mt-3 mb-5 text-end" controlId="addDriver">
              <Col sm={{span: 7, offset: 3}}>
                <IconButton
                  onClick={handleAddDriver}
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                >
                  Add Driver
                </IconButton>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="remark">
              <Form.Label column sm={3} className="text-lg-end">
                Notes
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="remark"
                  placeholder="Notes"
                  className="fs--1"
                  {...register("remark")}
                />
                <span className="text-danger">
                  {errors.remark && errors.remark.message}
                </span>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="attachment">    
              <Form.Label column sm={3} className="text-lg-end">
                Attachments
              </Form.Label>
              <Col sm={9} md={7}>
                <TransactionDetailUploadAttachment files={files} setFiles={setFiles} />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              type="submit"
            >
              Approve
            </Button>
            <Button size="sm" variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

TransactionDetailApprovalModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired
}

export default TransactionDetailApprovalModal;