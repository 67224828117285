import { Image, Form, Row, Col } from "react-bootstrap";
import logo from "./../../../../assets/img/pebsteel.png";
import { formatDate } from "helpers/utils";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';

const GDS = ({pif}) => {
  const {project, building, adaptation} = pif;
  const gds = pif?.gds || {};
  const paintSystemData = gds?.paint_systems || [];
  const secondaryMemberData = gds?.secondary_members || [];

  const projectUrl = `/crm/projects/${project?.id}`;

  return (
    <table className="fs--1">
      <tr>
        <td colSpan="2" className="text-start fw-bold">
          General Data Sheet (GDS) 1
        </td>
        <td colSpan="6" className="text-center">
          <div className="fs-2 fw-bold mb-2">PROJECT INFORMATION FORM</div>
        </td>
        <td colSpan="2" className="text-end fw-bold">
          GDS Page: 1 of 1
        </td>
      </tr>
      <tr>
        <td colSpan="2" rowSpan="2" className="text-center">
          <Image
            fluid
            className="fit-cover rounded"
            src={logo}
            alt="Logo Pebsteel"
            width="130"
            height="120"
          />
        </td>
        <td className="fw-bold">Quote No:</td>
        <td className="text-primary">
          <Link to={projectUrl}>{project?.q_number}</Link>
        </td>
        <td className="fw-bold">Rev No:</td>
        <td className="text-primary">{adaptation.rev}</td>
        <td className="fw-bold">Date:</td>
        <td className="text-primary">{formatDate(adaptation.entry_date)}</td>
        <td colSpan="2" className="text-end fw-bold">
          <Flex justifyContent="around">
            <Form.Check
              type="checkbox"
              label="For Order Entry"
              name="for_order_entry"
              checked={adaptation?.type === "change_order"}
            />

            <Form.Check
              type="checkbox"
              label="For Quotation"
              name="for_quotation"
              checked={adaptation?.type === "revision"}
            />
          </Flex>
        </td>
      </tr>
      <tr>
        <td className="fw-bold">P/Job No:</td>
        <td className="text-primary">
          <Link to={projectUrl}>{building?.j_number}</Link>
        </td>
        <td className="fw-bold">CO#:</td>
        <td className="text-primary">{adaptation.co}</td>
        <td className="fw-bold">Date:</td>
        <td className="text-primary">{formatDate(adaptation.entry_date)}</td>
        <td colSpan="2" className="text-end fw-bold">
          <h6 className="text-center my-2">This sheet is applicable to entire project</h6>
        </td>
      </tr>
      <tr>
        <td rowSpan="2" className="text-center">1</td>
        <td className="fw-bold text-center" rowSpan="2">GENERAL INFORMATION</td>
        <td>Project Name:</td>
        <td colSpan="5" className="fw-bold text-primary">{project?.name}</td>
        <td colSpan="2">
          <Flex justifyContent="between">
            <span>No of all Bldgs in this Project:</span> 
            <span className="text-primary fw-bold me-4">{gds?.no_of_bldgs}</span>
          </Flex>
        </td>
      </tr>
      <tr>
        <td>Location:</td>
        <td colSpan="5" className="fw-bold text-primary">{building?.pj_location}</td>
        <td colSpan="2">
          <Flex justifyContent="between">
            <span>No. of Non Identical Bldgs in this Project:</span> 
            <span className="text-primary fw-bold me-4">{gds?.no_of_non_identical_bldgs}</span>
          </Flex>
        </td>
      </tr>
      <tr>
        <td className="text-center">2</td>
        <td className="fw-bold text-center">CURRENT CONTRACT STATUS</td>
        <td colSpan="8">
          <div className="m-2">
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Letter of intent issued by customer on (Date)"
                  checked={gds?.contract_loi}
                />
              </Col>
              <Col sm={10} md={6}>
                <span className="text-primary">{formatDate(gds?.contract_loi_on_date)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="PEB Standard Offer signed by customer on (Date)"
                  checked={gds?.peb_standard_offer_signed}
                />
              </Col>
              <Col sm={10} md={6}>
                <span className="text-primary">{formatDate(gds?.peb_standard_offer_signed_date)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Customer contract signed by customer on (Date)"
                  checked={gds?.customer_contract_signed}
                />
              </Col>
              <Col sm={10} md={6}>
                <span className="text-primary">{formatDate(gds?.customer_contract_signed_date)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Others (Explain)"
                  checked={gds?.contract_status_others}
                />
              </Col>
              <Col sm={10} md={6}>
                <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.contract_status_others_explain }}></span>
              </Col>
            </Row>
          </div>
        </td>
      </tr>
      <tr>
        <td width="2%" className="text-center">3</td>
        <td className="fw-bold text-center" width="18%">PENALTY</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col>
              Terms of penalty
            </Col>
            <Col md={8}>
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.term_of_penalty }}></span>
            </Col>
          </Row>
          <Row className="mb-2 mt-3">
            <Col>
              Max. Penalty as % of Contract Price:
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="5%"
                checked={gds?.penalty_of_contact_price_5_percent}
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="checkbox"
                label="Others (Specify)"
                checked={gds?.penalty_of_contact_price_other}
              />
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.penalty_of_contact_price_other_specify }}></span>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">4</td>
        <td className="fw-bold text-center">SHIPMENT</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col>
              Delivery Commitment (weeks):
            </Col>
            <Col md={8}>
              <span className="text-primary">{gds?.shipment_delivery_commitment}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              Delivery by:
            </Col>
            <Col md={8}>
              <Flex justifyContent="between">
                <Form.Check
                  type="checkbox"
                  label="by PEB Steel"
                  checked={gds?.shipment_delivery_by_pebsteel}
                />

                <Form.Check
                  type="checkbox"
                  label="Customer pickup"
                  checked={gds?.shipment_customer_pickup}
                />

                <Form.Check
                  type="checkbox"
                  label="Partial shipment allowed"
                  checked={gds.shipment_partial_allow}
                />

              </Flex>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <Row className="g-0">
                <Col md={3}>
                  <Form.Check
                    type="checkbox"
                    label="Overland to"
                    checked={gds.overland_to}
                  />
                </Col>
                <Col>
                  <span className="text-primary">{gds.shipment_overland_to}</span>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="g-0">
                <Col md={3}>
                  <Form.Check
                    type="checkbox"
                    label="Seafreight to"
                    checked={gds.seafreight_to}
                  />
                </Col>
                <Col>
                  <span className="text-primary">{gds.shipment_seafreight_to}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Seafreight"
                    checked={gds.shipment_seafreight}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Container"
                    checked={gds.shipment_container}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Truck"
                    checked={gds.shipment_truck}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  Export document by:
                </Col>
                <Col md={8}>
                  <Row>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="PEB Steel"
                        checked={gds.shipment_export_document_by_pebsteel}
                      />
                    </Col>
                    <Col md={8}>
                      <Form.Check
                        type="checkbox"
                        label="by others"
                        checked={gds.shipment_export_document_by_others}
                      />

                      <span className="text-primary">{gds.shipment_export_document_by_others_specify}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">5</td>
        <td className="fw-bold text-center">REQUIRED INITIAL ENGINEERING SUBMITTALS</td>
        <td colSpan="8">
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                label="Design calculation"
                checked={gds.eng_design_calculation}
              />

              <Form.Check
                type="checkbox"
                label="Approval drawings"
                checked={gds.eng_approval_drawings}
              />
                  
              <Form.Check
                type="checkbox"
                label="Erection drawings"
                checked={gds.eng_erection_drawings}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="Preliminary Design Sheet"
                checked={gds.end_preliminary_design_sheet}
              />

              <Form.Check
                type="checkbox"
                label="Anchor Bolts Plan"
                checked={gds.eng_anchor_bolts_plan}
              />       
            </Col>
          </Row> 
        </td>
      </tr>
      <tr>
        <td className="text-center">6</td>
        <td className="fw-bold text-center">ANCHOR BOLTS</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col>
              Anchor Bolts by:
            </Col>
            <Col md={8}>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="PEB Steel"
                    checked={gds.anchor_bolts_by_pebsteel}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="by others"
                    checked={gds.anchor_bolts_by_other}
                  />

                  <div className="text-primary" dangerouslySetInnerHTML={{ __html: gds.anchor_bolts_by_other_specify }}></div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Row>
                <Col>Ship Anchor Bolts:</Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="with Job"
                    checked={gds.anchor_bolts_ship_with_job}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Priod to Job"
                    checked={gds.anchor_bolts_priod_to_job}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col>Ship by:</Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Overland"
                    checked={gds.anchor_bolts_ship_by_overland}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Seafreight"
                    checked={gds.anchor_bolts_ship_by_seafreight}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-primary" dangerouslySetInnerHTML={{ __html: gds.anchor_bolts_note }}></div>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">7</td>
        <td className="fw-bold text-center">BUYER (Invoice To)</td>
        <td colSpan="8">
          <Row>
            <Col>
              <Row className="mb-2">
                <Col>Name:</Col>
                <Col md={10} className="text-primary">
              
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Phone:</Col>
                <Col md={10} className="text-primary"></Col>
              </Row>
              <Row className="mb-2">
                <Col>Address:</Col>
                <Col md={9} className="text-primary"></Col>
              </Row>
              <Row className="mb-2">
                <Col>Contact person:</Col>
                <Col md={10} className="text-primary"></Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-2">
                <Col>Email:</Col>
                <Col md={10} className="text-primary"></Col>
              </Row>
              <Row className="mb-2">
                <Col>Fax:</Col>
                <Col md={10} className="text-primary"></Col>
              </Row>
              <Row className="mb-2">
                <Col>Title:</Col>
                <Col md={10} className="text-primary"></Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">8</td>
        <td className="fw-bold text-center">CONSIGNEE (Ship To)</td>
        <td colSpan="8">
          <Row>
            <Col>
              <Row className="mb-2">
                <Col>Name:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Phone:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Address:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-2">
                <Col>Mobile:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Fax:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Email:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">9</td>
        <td className="fw-bold text-center">SHIPMENT (Advice To)</td>
        <td colSpan="8">
          <Row>
            <Col>
              <Row className="mb-2">
                <Col>Name:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Fax:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-2">
                <Col>Mobile:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Email:</Col>
                <Col md={10}>
                  <span className="text-primary"></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">10</td>
        <td className="fw-bold text-center">CONSULTANT</td>
        <td colSpan="8">

        </td>
      </tr>
      <tr>
        <td className="text-center">11</td>
        <td className="fw-bold text-center">CONTRACTOR</td>
        <td colSpan="8">
        </td>
      </tr>
      <tr>
        <td className="text-center">12</td>
        <td className="fw-bold text-center">ENDUSER</td>
        <td colSpan="8">

        </td>
      </tr>
      <tr>
        <td className="text-center">13</td>
        <td className="fw-bold text-center">ERECTION</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col>
              Erection by:
            </Col>
            <Col md={8}>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="PEB Steel"
                    checked={gds.erection_by_pebsteel}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="by others"
                    checked={gds.erection_by_others}
                  />
                </Col>
                <Col md={6} sm={8}>
                  <Row className="gx-2">
                    <Col md="auto">
                      Erector Name:
                    </Col>
                    <Col>
                      <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds.erector_name }}></span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <Form.Check
                type="checkbox"
                label="Erection supervisior by PEB"
                checked={gds.erection_supervisor_by_peb}
              />
            </Col>
            <Col>
              <Row>
                <Col md="auto">
                  Explain details:
                </Col>
                <Col>
                  <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds.erection_explain_detail }}></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">14</td>
        <td className="fw-bold text-center">PRIMARY MEMBERS</td>
        <td colSpan="8">
          <Row>
            <Col md="auto">
              Surface treatment:
            </Col>
            <Col>
              <Flex justifyContent="between">
                <Form.Check
                  type="checkbox"
                  label="Power clean ST3"
                  checked={gds.primary_power_clean_st3}
                />

                <Form.Check
                  type="checkbox"
                  label="Shotblasting SA 2.0"
                  checked={gds.primary_shotblasting_sa_2_0}
                />

                <Form.Check
                  type="checkbox"
                  label="Shotblasting SA 2.5"
                  checked={gds.primary_shotblasting_sa_2_5}
                />

                <Form.Check
                  type="checkbox"
                  label="H.D.Galv"
                  checked={gds.primary_hd_galv}
                />

                <Form.Check
                  type="checkbox"
                  label="DFT"
                  checked={gds.primary_dft}
                />
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col>
              <Flex>
                <div className="me-3">Purlin Clip to Column / Rafter:</div>
                  <Form.Check
                    type="checkbox"
                    checked={gds.primary_purlin_clip_to_column}
                  />
                </Flex>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds.primary_memebers_note }}></span>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">15</td>
        <td className="fw-bold text-center">PAINT SYSTEM</td>
        <td colSpan="8">
          <div className="table-responsive fs--1 mt-2">
            <table>
              <thead className="bg-200 text-900">
                <tr>
                  <th className="border">Coat No.</th>
                  <th className="border">DFT (microns)</th>
                  <th className="border">Paint Type</th>
                  <th className="border">Manufacturer</th>
                  <th className="border">Color</th>
                </tr>
              </thead>
              <tbody>
                {paintSystemData && paintSystemData.map((item, index) => (
                  <tr key={index}>
                    <td className="border">{item.cost_no}</td>
                    <td className="border">{item.dft}</td>
                    <td className="border">{item.paint_type}</td>
                    <td className="border">{item.manufacturer}</td>
                    <td className="border">{item.color}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Row className="mt-2">
            <Col>
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.paint_system_note }}></span>
            </Col>
          </Row>

        </td>
      </tr>
      <tr>
        <td className="text-center">16</td>
        <td className="fw-bold text-center">SECONDARY MEMBERS <br/> (Purlins, girts, eave strut)</td>
        <td colSpan="8">
          <Row>
            <Col sm={3}>
              <Form.Check
                type="checkbox"
                label="Galv."
                checked={gds.second_galv}
              />      
            </Col>
            <Col>
              <Row>
                <Col md={3} className="fw-semi-bold">
                  Surface treatment:
                </Col>
                <Col>
                  <Flex justifyContent="between">
                    <Form.Check
                      type="checkbox"
                      label="Power clean ST3"
                      checked={gds.second_power_clean_st3}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Shotblasting SA 2.0"
                      checked={gds.second_shotblasting_sa_2_0}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Shotblasting SA 2.5"
                      checked={gds.second_shotblasting_sa_2_5}
                    />
                  </Flex>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} className="fw-semi-bold">
                  Paint system:
                </Col>
                <Col>
                  <Flex justifyContent="between">
                    <Form.Check
                      type="checkbox"
                      label="Standard Alkyd red oxide primer"
                      checked={gds.second_paint_standard}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Special (see below)"
                      checked={gds.second_paint_speical}
                    /> 
                  </Flex>    
                </Col>
              </Row>
            </Col>
          </Row>
                
          <div className="table-responsive fs--1 mt-2">
            <table>
              <thead className="bg-200 text-900">
                <tr>
                  <th className="border">Coat No.</th>
                  <th className="border">DFT (microns)</th>
                  <th className="border">Paint Type</th>
                  <th className="border">Manufacturer</th>
                  <th className="border">Color</th>
                </tr>
              </thead>
              <tbody>
                {secondaryMemberData && secondaryMemberData.map((item, index) => (
                  <tr key={index}>
                    <td className="border">{item.cost_no}</td>
                    <td className="border">{item.dft}</td>
                    <td className="border">{item.paint_type}</td>
                    <td className="border">{item.manufacturer}</td>
                    <td className="border">{item.color}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Row className="mt-2">
            <Col>
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: gds?.secondary_memeber_note }}></span>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">17</td>
        <td className="fw-bold text-center">ATTACHED</td>
        <td colSpan="8">
          <Flex justifyContent="between" className="mx-4">
            <Form.Check
              type="checkbox"
              label="GDS"
              checked={gds.attached_gds}
            />

            <Form.Check
              type="checkbox"
              label="BDS1"
              checked={gds.attached_bds1}
            />

            <Form.Check
              type="checkbox"
              label="BDS2"
              checked={gds.attached_bds2}
            />

            <Form.Check
              type="checkbox"
              label="BDS3"
              checked={gds.attached_bds3}
            />

            <Form.Check
              type="checkbox"
              label="Sketchs"
              checked={gds.attached_sketchs}
            />
          </Flex>
        </td>
      </tr>
      <tr>
        <td className="text-center">18</td>
        <td className="fw-bold text-center">CHECKING</td>
        <td colSpan="8">
          <Row>
            <Col>
              <Row className="align-items-center">
                <Col md="auto">Filled by:</Col>
                <Col>
                  <span className="text-primary">{gds.filled_by}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col md="auto">Checked by:</Col>
                <Col>
                  <span className="text-primary">{gds.checked_by}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col md="auto">Date:</Col>
                <Col>
                  <span className="text-primary">{formatDate(gds.checked_date)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">19</td>
        <td className="fw-bold text-center">DOUBLE SIDE WELDING - WELDING - UT TEST - THIRD PART INPSECTION</td>
        <td colSpan="8">
          <Row>
            <Col md="auto">
              <Form.Check
                type="checkbox"
                label="Double Side Welding"
                checked={gds.double_side_welding}
              />

              <Form.Check
                type="checkbox"
                label="UT Test"
                checked={gds.ut_test}
              />

              <Form.Check
                type="checkbox"
                label="Third Party Inspection"
                checked={gds.third_party_inspection}
              />
            </Col>
            <Col>
              <span className="text-primary">{gds.double_side_welding_notes}</span>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan="10">
        <Flex justifyContent="around" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Engineering"
              checked={gds.engineering}
            />

            <Form.Check
              type="checkbox"
              label="Sales Support Dept,"
              checked={gds.sales_support_dept}
            />

            <Form.Check
              type="checkbox"
              label="Sales Dept,"
              checked={gds.sales_dept}
            />

            <Form.Check
              type="checkbox"
              label="Customer Service Dept,"
              checked={gds.customer_service_dept}
            />

            <Form.Check
              type="checkbox"
              label="Production,"
              checked={gds.production}
            />
    
            <Form.Check
              type="checkbox"
              label="Finance,"
              checked={gds.finance}
            />
          </Flex>
        </td>
      </tr>
    </table>  
  )
};

GDS.propTypes = {
  pif: PropTypes.object.isRequired
}

export default GDS;