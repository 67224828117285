import { CRM_SALUTATION } from 'app_config';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select'
import { toast } from 'react-toastify';
import { createContact, updateContact} from 'redux/crm/contactSlice';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import AsyncSelect from 'react-select/async';
import {isValidPhoneNumber} from "libphonenumber-js"
import { Controller, useForm } from "react-hook-form";
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { salesEmployeesByNameDebounce } from 'helpers/employeeOptionHelper';
import { FaBan, FaSave } from 'react-icons/fa';

const ContactForm = ({ customer, contact, handleCancel }) => {
  const {control, register, reset, handleSubmit, formState: { errors}, setValue } = useForm();

  const contactUrl = useMemo(() => "/crm/contacts", []);
  const customerUrl = useMemo(() => "/crm/customers", []);
  const salutationOptions = useMemo(() => CRM_SALUTATION.map((option) => ({ value: option, label: option })), []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultSalutationOption = useMemo(() => (
    contact ? { value: contact.salutation, label: contact.salutation } : salutationOptions[1]
  ), [contact]);

  const defaultMainContactOption = useMemo(() => {
    if (contact && contact.main_contact) {
      return { value: contact.main_contact, label: contact.main_contact };
    }
    return null;
  }, [contact]);

  const defaultSecondaryContactOption = useMemo(() => {
    if (contact && contact.secondary_contact) {
      return contact.secondary_contact.map((option) => ({  value: option, label: option }));
    }
    return null;
  }, [contact]);

  useEffect(() => {
    setValue('salutation', salutationOptions[1].value);

    if(contact) {
      Object.entries(contact).forEach(([key, value]) => {
        setValue(key, value);
      })
    }
  }, [setValue]);

  const handleChangeSecondaryContact = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('secondary_contact', selectedOptionsValues, { shouldValidate: true });
  }

  const mainContactOptions = (inputValue, callback) => {
    salesEmployeesByNameDebounce(inputValue, callback);
  };

  const onSubmit = async (data) => {
    console.log(data)
    if (!validate(data)) return;

    try {
      let obj_params = {}
      const customerId = customer ? customer.id : null;
      let contactId = contact ? contact.id : null;

      if (!contact) {
        obj_params =  customer ? {customerId, data} : {data};
        const response = await dispatch(createContact({customerId, data}));
        contactId = response.payload.data.id;
        reset();
      }
      else {
        obj_params =  customer ? {customerId, contactId, data} : {contactId, data};
        await dispatch(updateContact(obj_params));
      }

      customer ? navigate(`${customerUrl}/${customerId}`) : navigate(`${contactUrl}/${contactId}`);
    }
    catch(error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const validatePhoneNumber = (number) => {
    if (number === undefined || number === "" || number === null) return undefined

    let countryCode = customer ? customer.country : 'VN'
    if (!isValidPhoneNumber(number.toString(), countryCode)) {
      return `Invalid phone number with country code ${countryCode}.`;
    }
    return true
  }

  const validate = (data) => {
    if ((data.email === '' || data.email === undefined) && (data.mobile === undefined || data.mobile === '')) {
      toast.error("Email or mobile must be required.")
      return false
    }
    return true
  }
  
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row} className="mb-3" controlId="salutation">
        <Form.Label column sm={3} className="text-lg-end">
          Salutation
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name="salutation"
            render={() => (
              <Select
                closeMenuOnSelect={true}
                options={salutationOptions}
                defaultValue={defaultSalutationOption}
                placeholder='Select...'
                classNamePrefix="react-select"
                onChange={selectedOption => setValue('salutation', selectedOption.value, {shouldValidate: true})}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Salutation is required'
              }
            }}
          />
          <span className="text-danger">
            {errors.salutation && errors.salutation.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="first_name">
        <Form.Label column sm={3} className="text-lg-end">
          Contact name
        </Form.Label>
        <Col sm={9} md={7}>
          <Row className="g-2">
            <Col>
              <Form.Control
                type="text"
                name="first_name"
                placeholder="First name"
                className="fs--1"
                {...register("first_name", {
                  required: "First name must be required."
                })}
              />
              <span className="text-danger">
                {errors.first_name && errors.first_name.message}
              </span>
            </Col>
            <Col>
              <Form.Control
                type="text"
                name="last_name"
                placeholder="Last name"
                className="fs--1"
                {...register("last_name")}
              />
            </Col>
          </Row>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="position">
        <Form.Label column sm={3} className="text-lg-end">
          Title
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="text"
            name="position"
            placeholder="Title"
            className="fs--1"
            {...register("position")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="department">
        <Form.Label column sm={3} className="text-lg-end">
          Department
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="text"
            name="department"
            placeholder="Department"
            className="fs--1"
            {...register("department")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label column sm={3} className="text-lg-end">
          Email
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="email"
            name="email"
            placeholder="example@pebsteel.com.vn"
            className="fs--1"
            {...register('email', {
              //required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address'
              }
            })}
          />
          <span className="text-danger">
            {errors.email && errors.email.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="mobile">
        <Form.Label column sm={3} className="text-lg-end">
          Phone number
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name="mobile"
            render={({field: {ref, ...field}}) => (
              <PhoneInput
                {...field}
                inputProps={{ref}}
                country={customer ? customer.country?.toLowerCase() : 'vn' }
                autoFormat={true}
                countryCodeEditable={contact ? true : false}
                enableSearch={true}
                disableSearchIcon={true}
                inputStyle={{width: '100%'}}
                searchStyle={{width: '95%'}}
              />
            )}
            rules={{ validate: validatePhoneNumber }}
          />
          <span className="text-danger">
            {errors.mobile && errors.mobile.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="main_contact">
        <Form.Label column sm={3} className="text-lg-end">
          Main contact
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name="main_contact"
            render={() => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                defaultValue={defaultMainContactOption}
                loadOptions={mainContactOptions}
                closeMenuOnSelect={true}
                placeholder='Select or input 3 characters of name...'
                classNamePrefix="react-select"
                className="w-100"
                onChange={selectedOption => setValue('main_contact', selectedOption.value)}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Main contact is required'
              }
            }}
          />
          <span className="text-danger">
            {errors.main_contact && errors.main_contact.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="secondary_contact">
        <Form.Label column sm={3} className="text-lg-end">
          Secondary contact
        </Form.Label>
        <Col sm={9} md={7}>
          <Controller
            control={control}
            name="secondary_contact"
            render={() => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                defaultValue={defaultSecondaryContactOption}
                loadOptions={mainContactOptions}
                closeMenuOnSelect={false}
                placeholder='Select...'
                classNamePrefix="react-select"
                className="w-100"
                isMulti
                onChange={handleChangeSecondaryContact}
              />
            )}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="other_contact">
        <Form.Label column sm={3} className="text-lg-end">
          Other contact
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="text"
            name="other_contact"
            placeholder="Other contact"
            className="fs--1"
            {...register("other_contact")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="personal_page">
        <Form.Label column sm={3} className="text-lg-end">
          Personal page
        </Form.Label>
        <Col sm={9} md={7}>
          <Form.Control
            type="text"
            name="personal_page"
            placeholder="Personal page"
            className="fs--1"
            {...register("personal_page")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col className="my-2">
          <Flex justifyContent="center">
            <Button size="sm" type="submit"><FaSave /> Save</Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={handleCancel}><FaBan /> Cancel</Button>
          </Flex>
        </Col>
      </Form.Group>
    </Form>
  )
}

ContactForm.propTypes = {
  customer: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default ContactForm;