import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { createPermission, updatePermission } from "redux/authorization/permissionSlice";
import { FaBan, FaSave } from "react-icons/fa";
import { formatString } from "helpers/utils";
import Select from 'react-select';
import { PERMISSION_PARENT_GROUPS } from "app_config";
import PropTypes from 'prop-types';

const PermissionForm = ({showModal, setshowModal, title, permission}) => {
  const {register, control, handleSubmit, formState: {errors}, setValue, reset } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (title === "New Permission") {
      reset()
    }
  }, [title]);

  useEffect(() => {
    if(permission) {
      Object.entries(permission).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue, permission]);

  const closeModal = () => {
    setshowModal(false)
  }

  const onSubmit = async (data) => {
    console.log(data)

    try {
      if (permission) {
        const permissionId = permission.id;
        dispatch(updatePermission({permissionId, data }));
      }
      else {
        dispatch(createPermission(data));
        reset();
      }

      if (permission) {
        closeModal()
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const parentGroupOptions = PERMISSION_PARENT_GROUPS.map((option) => ({ value: option, label: formatString(option) }));

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-2" controlId="subject_class">
              <Form.Label column sm={3} className="text-lg-end">
                Subject class
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="subject_class"
                  placeholder="Subject class"
                  className="fs--1"
                  {...register("subject_class", {
                    required: "Subject class must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.subject_class && errors.subject_class.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="action">
              <Form.Label column sm={3} className="text-lg-end">
                Action
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="action"
                  placeholder="Action"
                  className="fs--1"
                  {...register("action", {
                    required: "Action must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.action && errors.action.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="description">
              <Form.Label column sm={3} className="text-lg-end">
                Description
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  name="description"
                  placeholder="Description"
                  className="fs--1"
                  {...register("description", {
                    required: "Description must be required."
                  })}
                />
                <span className="text-danger">
                  {errors.description && errors.description.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="parent_group">
              <Form.Label column sm={3} className="text-lg-end">
                Parent group
              </Form.Label>
              <Col sm={9} md={7}>
                <Controller
                  control={control}
                  name='parent_group'
                  rules={{required: "Parent group is required"}}
                  render={({field}) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      options={parentGroupOptions}
                      value={parentGroupOptions.find(option => option.value === field.value) || null}
                      placeholder='Select...'
                      classNamePrefix='react-select'
                      onChange={selectedOption => setValue('parent_group', selectedOption.value)}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.parent_group && errors.parent_group.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit"><FaSave /> Save</Button>
            <Button size="sm" variant="danger" onClick={closeModal}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  </>
  ) 
};

PermissionForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
}

export default PermissionForm;