import React from 'react';
import {formatNumber} from 'helpers/utils'
import PropTypes from 'prop-types';

const NumberRenderer = (props) => {
  const value = formatNumber(props.value)

  return (
    <span className="text-end text-primary">{value}</span>
  );
};

NumberRenderer.propTypes = {
  value: PropTypes.string
};


export default NumberRenderer;
