import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import IconAlert from 'components/common/IconAlert';
import { FaClone, FaBan } from "react-icons/fa";
import { cloneExpense } from "redux/booking/travel/setting/expenseSlice";
import PropTypes from 'prop-types';

const CloneExpenseConfirm = ({showModal, setshowModal, record}) => {
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await dispatch(cloneExpense(record.id));
      const expenseId = response.payload.data.id;

      if (expenseId) {
        closeModal();
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><FaClone /> Clone new expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="warning">
              <p className="mb-0">Are you sure to clone new expense from current {record.name}-ID#{record.id}?</p>
            </IconAlert>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              variant="primary"
              type="submit"
            >
              <FaClone /> Clone
            </Button>
            <Button size="sm" variant="falcon-default" onClick={closeModal}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

CloneExpenseConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired
}

export default CloneExpenseConfirm;