import React from 'react';
import Flex from "components/common/Flex"
import { formatNumber, formatNumberCurrency } from "helpers/utils"
import { ListGroup, Row, Col } from "react-bootstrap"
import BookingAttachment from "../../BookingAttachment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCcVisa } from "@fortawesome/free-brands-svg-icons"
import PropTypes from 'prop-types';

const OtherItem = ({booking, record, editShuttleBooking, deleteShuttleBooking, handleRemoveAttachment}) => {
  const shuttleId = record.id

  const isUSDCurrency = () => {
    return booking.currency === "USD"
  }

  return (
    <ListGroup className="m-4 fs--1">
      <ListGroup.Item className='p-3 p-sm-4 light' action>
        <Flex justifyContent="between" className="mb-3">
          <div>
            <Flex justifyContent="start">
              <h5 className="mb-1 text-primary">{record && record.name === "Visa" ? <FontAwesomeIcon icon={faCcVisa} /> : null} {record.name}</h5>
              
              <div className="ms-3">
                <FontAwesomeIcon icon="edit" title="Edit" className="me-2" onClick={editShuttleBooking} />
                <FontAwesomeIcon icon="trash-alt" title="Delete" onClick={deleteShuttleBooking} />
              </div>

              {/* <Dropdown align="end" className="btn-reveal-trigger d-inline-block ms-3">
                <Dropdown.Toggle split variant="falcon-default" size="sm">
                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Dropdown.Item className="mb-1" onClick={editShuttleBooking}>
                      <FontAwesomeIcon icon="edit" /> Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-1" onClick={deleteShuttleBooking}>
                      <FontAwesomeIcon icon="trash-alt" /> Delete
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown> */}
            </Flex>
          </div>
          <small>Created date: <strong>{record.created_date}</strong> | By: <strong>{record.created_by}</strong></small>
        </Flex>

        <Row className="mb-2">
          <Col xs={2} sm={2}>
            <p className="fs--1 mb-1">Description</p>
          </Col>
          <Col>
            <p className="mb-1 fs--1 text-primary">{record.description}</p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2} sm={2}>
            <p className="fs--1 mb-1">Note</p>
          </Col>
          <Col>
            <p className="mb-1 fs--1 text-primary" dangerouslySetInnerHTML={{ __html: record.notes }}></p>
          </Col>
        </Row>
 
        <Row as={Flex} justifyContent="end" className="mt-4">
          <Col md={8}>
            <h6 className="mb-2">Attachments</h6>

            {record && record.documents.length > 0 && (
              <Flex direction="row" wrap="wrap" justifyContent="arround">
                {record.documents.map(attachment => (
                  <BookingAttachment
                    {...attachment}
                    key={attachment.id}
                    handleRemoveAttachment={(documentId) => handleRemoveAttachment(shuttleId, documentId)}
                  />
                ))}
              </Flex>
            )} 
          </Col>
          <Col md={4}>
            <Row className="mb-2">
              <Col><p className="fw-semi-bold fs--1 mb-1">Amount currency</p></Col>
              <Col className="text-end text-primary">{formatNumberCurrency(record.amount_currency, record.currency)}</Col>
            </Row>

            {isUSDCurrency() && (
              <Row className="mb-2">
                <Col><p className="fw-semi-bold fs--1 mb-1">Exchange rate</p></Col>
                <Col className="text-end text-primary">{formatNumber(record.exchange_rate)} VND</Col>
              </Row>
            )}

            <Row className="mb-2">
              <Col><p className="fw-semi-bold fs--1 mb-1">Amount</p></Col>
              <Col className="text-end text-primary">{formatNumberCurrency(record.amount, "VND")}</Col>
            </Row>
          </Col>
        </Row>

      </ListGroup.Item>
    </ListGroup>
  )
};

OtherItem.propTypes = {
  booking: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  editShuttleBooking: PropTypes.bool.isRequired,
  deleteShuttleBooking: PropTypes.func.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired
}

export default OtherItem;