import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { formatNumber, formatNumberWord } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContractWeekChart = ({title, data=[]}) => {
  const number_of_projects = data?.length || 0;

  const {weight, revenue} = data && data.reduce((acc, item) => {
    const { contract_weight, contract_value} = item
    acc.weight += parseInt(contract_weight) || 0;
    acc.revenue += parseInt(contract_value) || 0;

    return acc;
  }, {weight: 0, revenue: 0});

  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body className="pb-0">
        <Row>
          <Col md={4} sm={12}>
            <h6 className="text-700">Number of projects</h6>
            <h3 className="fw-normal text-500 text-primary">{formatNumberWord(number_of_projects)}</h3>
          </Col>
          <Col md={4} sm={12}>
            <h6 className="text-700">Weight</h6>
            <h3 className="fw-normal text-500 text-primary">{formatNumberWord(weight)} M.T</h3>
          </Col>
          <Col md={4} sm={12}>
            <h6 className="text-700">Revenue</h6>
            <h3 className="fw-normal text-success">${formatNumberWord(revenue)}</h3>
          </Col>
        </Row>
        <div className="mx-ncard">
          <SimpleBarReact>
            <Table className="fs--1 mb-0 overflow-hidden">
              <thead className="bg-100 text-800">
                <tr>
                  <th className="text-nowrap">Job number</th>
                  <th className="text-nowrap text-end">Weight</th>
                  <th className="text-nowrap text-end">Revenue</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map(item => (
                  <tr key={item.id}>
                    <td className="text-truncate">
                      <Link to={`/crm/projects/${item.id}`} target="_blank">{item.j_number}</Link>
                    </td>
                    <td className="text-truncate text-end">{formatNumber(item.contract_weight)} M.T</td>
                    <td className="text-truncate text-end">${formatNumber(item.contract_value)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>
      </Card.Body>
    </Card>
  )
};

ContractWeekChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default ContractWeekChart;