import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';

const FeedGallery = ({ images }) => {
  console.log(images);

  return (
    <FalconLightBoxGallery images={images}>
      {setImgIndex => (
        <Row className="g-2">
          {images.length === 1 ? (
            // Display single image full-width
            <Col lg={12} key={0}>
              <Image
                src={images[0]} // Access the first image
                fluid
                rounded
                onClick={() => setImgIndex(0)}
                loading="lazy"
              />
            </Col>
          ) : (
            // Display multiple images with responsive columns
            images.map((imageUrl, index) => (
              <Col xs={6} sm={4} md={3} lg={4} key={index}>
                <Image
                  src={imageUrl}
                  fluid
                  rounded
                  onClick={() => setImgIndex(index)}
                  loading="lazy"
                />
              </Col>
            ))
          )}
        </Row>
      )}
    </FalconLightBoxGallery>
  );
};

FeedGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

export default FeedGallery;
