import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import { deleteSalesUnit } from 'redux/crm/salesUnitSlice';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import PropTypes from 'prop-types';

const SalesUnitDetailHeader = ({sales_unit}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const salesUnitUrl = "/crm/sales-units";
  const salesUnitId = sales_unit?.id;

  const handleBackToList = () => navigate(`${salesUnitUrl}/list`);

  const handleNewSalesUnit = () => {
    navigate(`${salesUnitUrl}/new`)
  }

  const handleEditSalesUnit = () => {
    navigate(`${salesUnitUrl}/${salesUnitId}/edit`, {state: sales_unit});
  }

  const handleDeleteSalesUnit = () => {
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleOkConfirm = async () => {
    dispatch(deleteSalesUnit(salesUnitId));
    setShowConfirmationDelete(false);
    handleBackToList();
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-2">
                Sales Unit ID#: {sales_unit.id}
              </h5>
              <div>
                {
                  sales_unit.active ?
                  (
                    <SoftBadge pill bg="success" className="fs--2">
                      {' '}
                      Activated
                      <FontAwesomeIcon
                        icon="check"
                        className="ms-1"
                        transform="shrink-2"
                      />
                    </SoftBadge>
                  )
                  :
                  (
                    <SoftBadge pill bg="danger" className="fs--2">
                      {' '}
                      Not active
                      <FontAwesomeIcon
                        icon="exclamation"
                        className="ms-1"
                        transform="shrink-2"
                      />
                    </SoftBadge>
                  )
                }
              </div>
            </Col>
            <Col xs="auto">
              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="plus"
                className="m-1"
                onClick={handleNewSalesUnit}
              >
                New
              </IconButton>
              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="edit"
                onClick={handleEditSalesUnit}
              >
                Edit
              </IconButton>
              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="trash-alt"
                className="m-1"
                onClick={handleDeleteSalesUnit}
              >
                Delete
              </IconButton>

              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="list"
                onClick={handleBackToList}
              >
                Back to list
              </IconButton>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="border-top">
          <Row>
            <Col lg xxl={6}>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Description</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.description}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Parent</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.parent_id}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Region</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.region}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Type of Sales</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.type_of_sales}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Created by</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.created_by}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Created date</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{sales_unit.created_at}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleOkConfirm}
          message="Are you sure to delete?"
        />
      )}

    </>
  );
};

SalesUnitDetailHeader.propTypes = {
  sales_unit: PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    parent_id: PropTypes.string,
    region: PropTypes.string,
    type_of_sales: PropTypes.string,
    created_by: PropTypes.string,
    created_at: PropTypes.instanceOf(Date)
  }).isRequired
}

export default SalesUnitDetailHeader;