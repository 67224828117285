import { Image, Form, Row, Col } from "react-bootstrap";
import logo from "./../../../../assets/img/pebsteel.png";
import { formatDate } from "helpers/utils";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import React from 'react';
import PropTypes from 'prop-types';

const BDS1 = ({pif, area}) => {
  const {project, building, adaptation} = pif;
  const bds1 = area?.bds1 || {};
  const openWallData = bds1?.open_walls || [];

  const projectUrl = `/crm/projects/${project?.id}`;

  return (
    <table className="fs--1 mt-5">
      <tr>
        <td colSpan="2" className="text-start fw-bold">
          Building Data Sheet 1
        </td>
        <td colSpan="6" className="text-center">
          <div className="fs-2 fw-bold mb-2">PROJECT INFORMATION FORM</div>
        </td>
        <td colSpan="2" className="text-end fw-bold">
          This building only: 1 of 3
        </td>
      </tr>
      <tr>
        <td colSpan="2" rowSpan="2" className="text-center">
          <Image
            fluid
            className="fit-cover rounded"
            src={logo}
            alt="Logo Pebsteel"
            width="130"
            height="120"
          />
        </td>
        <td className="fw-bold">Quote No:</td>
        <td className="text-primary">
          <Link to={projectUrl}>{project?.q_number}</Link>
        </td>
        <td className="fw-bold">Rev No:</td>
        <td className="text-primary">{adaptation.rev}</td>
        <td className="fw-bold">Date:</td>
        <td className="text-primary">{formatDate(adaptation.entry_date)}</td>
        <td colSpan="2" className="text-end fw-bold">
          <Flex justifyContent="around">
            <Form.Check
              type="checkbox"
              label="For Order Entry"
              name="for_order_entry"
              checked={adaptation?.type === "change_order"}
            />

            <Form.Check
              type="checkbox"
              label="For Quotation"
              name="for_quotation"
              checked={adaptation?.type === "revision"}
            />
          </Flex>
        </td>
      </tr>
      <tr>
        <td className="fw-bold">P/Job No:</td>
        <td className="text-primary">
          <Link to={projectUrl}>{building?.j_number}</Link>
        </td>
        <td className="fw-bold">CO#:</td>
        <td className="text-primary">{adaptation.co}</td>
        <td className="fw-bold">Date:</td>
        <td className="text-primary">{formatDate(adaptation.entry_date)}</td>
        <td colSpan="2" className="text-end fw-bold">
          <Flex justifyContent="around">
            <div>
              Bldg No: <span className="text-primary">{building?.name}</span>
            </div>
            <div>
              Area No: <span className="text-primary">{area?.id}</span>
            </div>
          </Flex>
          <h6 className="text-center my-2">This area only : Page 1 of 3</h6>
        </td>
      </tr>
      <tr>
        <td className="text-center">1</td>
        <td className="fw-bold text-center">GENERAL INFORMATION</td>
        <td colSpan="8">
          <Row className="mb-2 mt-4 gx-1">
            <Col sm={6}>
              <Row className="gx-2 mb-2">
                <Col sm={3}>Customer name:</Col>
                <Col sm="auto" className="fw-semi-bold text-primary">{project.customer?.name}</Col>
              </Row>
              <Row className="gx-2 mb-2">
                <Col sm={3}>Project name:</Col>
                <Col sm="auto" className="fw-semi-bold text-primary">{project.name}</Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={3}>Building usage:</Col>
                <Col sm="auto" className="fw-semi-bold">
                  <span className="text-primary">{bds1.building_usage}</span>
                </Col>
              </Row>
            </Col>
            <Col sm={6}> 
              <Row className="gx-1 mb-2">
                <Col sm="auto">Location:</Col>
                <Col className="fw-semi-bold text-primary">{building.pj_location}</Col>
              </Row>
              <Row className="gx-1 mb-2 align-items-center">
                <Col sm="auto">Qty of Identical Bldgs:</Col>
                <Col className="fw-semi-bold">
                  <span className="text-primary">{bds1.qty_of_identical_bldgs}</span>
                </Col>
              </Row>
              <Row className="gx-1 mb-2 align-items-center">
                <Col sm="auto">Estimated No. of trailers/ containers for all identical bldgs:</Col>
                <Col className="fw-semi-bold">
                  <span className="text-primary">{bds1.estimated_no_trailed}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">2</td>
        <td className="fw-bold text-center">LOADS <br/> (kN/m2)</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col sm={5}>
              <Row className="gx-0 mb-2 align-items-center">
                <Col sm={5}>Live (On Purlins):</Col>
                <Col>
                  <Flex>
                    <span className="text-primary">{bds1.loads_live_on_purlins} (KN/m2)</span>
                  </Flex>
                </Col>
              </Row>
              <Row className="gx-0 mb-2 align-items-center">
                <Col sm={5}>On Frames:</Col>
                <Col>
                  <Flex>
                    <span className="text-primary">{bds1.loads_on_frame} (KN/m2)</span>
                  </Flex>
                </Col>
              </Row>
            </Col>
            <Col sm={5}>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={5}>Collateral on Frame:</Col>
                <Col>
                  <Flex>
                    <span className="text-primary">{bds1.loads_collateral_on_frame} (KN/m2)</span>
                  </Flex>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={5}>Collateral on Roof:</Col>
                <Col>
                  <Flex>
                    <span className="text-primary">{bds1.loads_collateral_on_roof} (KN/m2)</span>
                  </Flex>
                </Col>
              </Row>
            </Col>
            <Col sm={2}>
              <div className="mt-3">
                <Form.Check
                  type="checkbox"
                  label="Seismic"
                  checked={bds1.loads_seismic}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-2 align-items-center">
            <Col sm={5}>
              <Row className="gx-0 mb-2 align-items-center">
                <Col>Wind Speed (KMH):</Col>
                <Col sm={7}>
                  <span className="text-primary">{bds1.loads_wind_speed} (KMH)</span>
                </Col>
              </Row>
              <Row className="gx-0 mb-2 align-items-center">
                <Col>Recurrence time:</Col>
                <Col sm={7}>
                  <span className="text-primary">{bds1.loads_recurrence_time}</span>
                </Col>
              </Row>
            </Col>
            <Col sm={7}>
              <Row className="mb-2 align-items-center">
                <Col>Explosure:</Col>
                <Col sm={9}>
                  <span className="text-primary">{bds1.loads_explosure}</span>
                </Col>
              </Row>
              <Row className="mb-2 align-items-center">
                <Col>Rainfall:</Col>
                <Col sm={9}>
                  <Flex alignItems="center">
                    <span className="text-primary">{bds1.loads_rainfall} mm/hr</span>
                  </Flex>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="gx-2 mb-2 align-items-center">
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm="auto">Description of collateral load:</Col>
                <Col>
                  <span className="text-primary">{bds1.loads_description}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm="auto">Occupancy category:</Col>
                <Col>
                  <span className="text-primary">{bds1.loads_occupancy_category}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <Row className="gx-2">
                <Col sm="auto" className="fw-semi-bold">Building Code:</Col>
                <Col>
                  <Flex justifyContent="between">
                    <Form.Check
                      type="checkbox"
                      label="MBMA 2012"
                      checked={bds1.loads_building_code_mbma_2012}
                    />

                    <Form.Check
                      type="checkbox"
                      label="IBC 2018"
                      checked={bds1.loads_building_code_ibc_2018}
                    />
                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span dangerouslySetInnerHTML={{ __html: bds1.loads_building_code_others }}></span>    
                </Col>
              </Row>
            </Col>
            <Col sm={7}>
              <Row className="gx-2">
                <Col sm="auto" className="fw-semi-bold">Design Standard:</Col>
                <Col>
                  <Flex justifyContent="around">
                    <Form.Check
                      type="checkbox"
                      label="AISC-ASD89"
                      checked={bds1.loads_design_standard_aisc_asd89}
                    />

                    <Form.Check
                      type="checkbox"
                      label="AISC360-16"
                      checked={bds1.loads_design_standard_aisc_360_16}
                    />

                    <Form.Check
                      type="checkbox"
                      label="AISI 2001"
                      checked={bds1.loads_design_standard_aisi_2001}
                    />

                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span dangerouslySetInnerHTML={{ __html: bds1.loads_design_standard_others }}></span>   
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">3</td>
        <td className="fw-bold text-center">GRADE</td>
        <td colSpan="8">
          <Row className="gx-2 mb-2 align-items-center">
            <Col sm={3}>Primary member:</Col>
            <Col>
              <span className="text-primary">{bds1.grade_primary_member}</span>
            </Col>
          </Row>
          <Row className="gx-2 mb-2 align-items-center">
            <Col sm={3}>Secondary member:</Col>
            <Col>
              <span className="text-primary">{bds1.grade_secondary_member}</span>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <span className="text-primary" dangerouslySetInnerHTML={{ __html: bds1.grade_note }}></span>  
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">4</td>
        <td className="fw-bold text-center">BUILDING PARAMETERS</td>
        <td colSpan="8">
          <Row className="mb-2 gx-2">
            <Col sm={4}>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Frame Type:</Col>
                <Col>
                  <span className="text-primary">{bds1.building_parameters_frame_type}</span>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={3}>Slope:</Col>
                <Col>
                  <span className="text-primary">{bds1.building_parameters_slope}</span>
                </Col>
              </Row>
            </Col>
            <Col sm={5}>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={3}>Height (M):</Col>
                <Col>
                  <Row className="gx-1">
                    <Col sm={6}>
                      <span className="text-primary">{bds1.building_parameters_height_other}</span>
                    </Col>
                    <Col>
                      <span className="text-primary">{bds1.building_parameters_height}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Bldg Width (M):</Col>
                <Col>
                  <span className="text-primary">{bds1.building_parameters_bldgs_width}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Width modules:</Col>
                <Col>
                  <span className="text-primary">{bds1.building_parameters_width_module}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3 g-0 align-items-center">
            <Col sm={2}>Length (M):</Col>
            <Col>
              <span className="text-primary">{bds1.building_parameters_length}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Bay Spacing (M):</Col>
                <Col>
                  <span className="text-primary">{bds1.building_parameters_bay_spacing}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Columns support:</Col>
                <Col>
                  <span className="text-primary">{bds1.building_parameters_column_support}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span dangerouslySetInnerHTML={{ __html: bds1.building_parameters_note }}></span>    
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">5</td>
        <td className="fw-bold text-center">ENDWALL CONDITION</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Left End Wall Frame:</Col>
                <Col>
                  <span className="text-primary">{bds1.left_end_wall_frame}</span>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Right End Wall Frame:</Col>
                <Col>
                  <span className="text-primary">{bds1.right_end_wall_frame}</span>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Left End Girt type:</Col>
                <Col>
                  <span className="text-primary">{bds1.lef_end_girt_type}</span>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Near Side Girt type:</Col>
                <Col>
                  <span className="text-primary">{bds1.near_site_girt_type}</span>
                </Col>
              </Row>
            </Col>
            <Col>
            <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Wind Col. Spacing (M):</Col>
                <Col>
                  <span className="text-primary">{bds1.wind_col_spacing_left}</span>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Wind Col. Spacing (M):</Col>
                <Col>
                  <span className="text-primary">{bds1.wind_col_spacing_right}</span>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Right End Girt type:</Col>
                <Col>
                  <span className="text-primary">{bds1.right_end_girt_type}</span>
                </Col>
              </Row>
              <Row className="gx-2 mb-2 align-items-center">
                <Col sm={4}>Far Side Girt type:</Col>
                <Col>
                  <span className="text-primary">{bds1.far_side_girt_type}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span dangerouslySetInnerHTML={{ __html: bds1.endwall_condition_note }}></span>      
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">6</td>
        <td className="fw-bold text-center">OPEN WALLS <br/> Lengths are <br/> measured from <br/> corner of NSW & LEW </td>
        <td colSpan="8">
        <div className="fs--1 mt-2">
          <table>
            <thead className="bg-200 text-900">
              <tr className="text-center" style={{height: "10px"}}>
                <th className="border" rowSpan={2}>Location</th>
                <th className="border" colSpan={3}>Length (mm)</th>
                <th className="border" rowSpan={2}>Height (m)</th>
                <th className="border" rowSpan={2}>Open for</th>
                <th className="border" rowSpan={2}>Bracing Type</th>
              </tr>
              <tr className="text-center">
                <th className="border">Length</th>
                <th className="border">From</th>
                <th className="border">To</th>
              </tr>
            </thead>
            <tbody>
              {openWallData && openWallData.map((item, index) => (
                <tr key={index}>
                  <td className="border">{item.location}</td>
                  <td className="border">{item.length}</td>
                  <td className="border">{item.length_from}</td>
                  <td className="border">{item.length_to}</td>
                  <td className="border text-end">{item.height}</td>
                  <td className="border">{item.open_for}</td>
                  <td className="border">{item.bracing_type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Row className="mt-3 mb-2">
          <Col>
            <span dangerouslySetInnerHTML={{ __html: bds1.open_walls_note }}></span>  
          </Col>
        </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">7</td>
        <td className="fw-bold text-center">ROOF</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col sm={2}>
              <Form.Check
                type="checkbox"
                label="by PEB Steel"
                checked={bds1.roof_by_pebsteel}
              />
            </Col>
            <Col ms={10}>
              <Row className="gx-2 align-items-center">
                <Col sm="auto">Panel is:</Col>
                <Col>
                  <span className="text-primary">{bds1.roof_panel_is}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="gx-2 align-items-center">
                <Col sm="auto">Profile:</Col>
                <Col>
                  <span className="text-primary">{bds1.roof_profile}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={2}>
              <Form.Check
                type="checkbox"
                label="by Others"
                checked={bds1.roof_by_others}
              />
            </Col>
            <Col sm={10}>
              <Row className="gx-3 align-items-center">
                <Col sm="auto">Color:</Col>
                <Col>
                  <span className="text-primary">{bds1.roof_color}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="border py-2">
            <Col sm={2}>Insulation</Col>
            <Col sm={10}>
              <Row className="mb-3">
                <Col sm={8}>
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="by PEB Steel"
                      checked={bds1.roof_insulation_by_pebsteel}
                    />
                  </div>
                  <Row>
                    <Col>
                      <span className="text-primary">{bds1.roof_name}</span>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Thickness (mm):</Col>
                        <Col>
                          <span className="text-primary">{bds1.roof_insulation_thickness}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="by Others"
                      checked={bds1.roof_insulation_by_others}
                    />
                  </div>
                  <Row className="gx-2 align-items-center">
                    <Col sm="auto">Density:</Col>
                    <Col>
                      <span className="text-primary">{bds1.roof_insulation_density}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <span dangerouslySetInnerHTML={{ __html: bds1.roof_insulation_note }}></span>   
                </Col>
              </Row>
                
            </Col>
          </Row>
          <Row className="border py-2">
            <Col sm="2">Skylight</Col>
            <Col>
              <Row className="gx-2">
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="By Pebsteel"
                    checked={bds1.roof_skylight_by_pebsteel}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Single layer"
                    checked={bds1.roof_skylight_single_layer}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Double layers"
                    checked={bds1.roof_skylight_double_layer}
                  />
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    label="By Others"
                    checked={bds1.roof_skylight_by_others}
                  />
                </Col>
              </Row>
              <Row className="gx-2">
                <Col>
                  <div>Width (mm):</div>
                  <span className="text-primary">{bds1.roof_skylight_width}</span>
                </Col>
                <Col>
                  <div>Length (mm):</div>
                  <span className="text-primary">{bds1.roof_skylight_length}</span>
                </Col>
                <Col>
                  <div>Thickness (mm):</div>
                  <span className="text-primary">{bds1.roof_skylight_thickness}</span>
                </Col>
                <Col>
                  <div>Color:</div>
                  <span className="text-primary">{bds1.roof_skylight_color}</span>
                </Col>
                <Col sm={4}>
                  <div>Description:</div>
                  <span className="text-primary">{bds1.roof_skylight_desciption}</span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <span dangerouslySetInnerHTML={{ __html: bds1.roof_skylight_note }}></span>  
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">8</td>
        <td className="fw-bold text-center">WALL</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col sm={2}>
              <Form.Check
                type="checkbox"
                label="by PEB Steel"
                checked={bds1.wall_by_pebsteel}
              />
            </Col>
            <Col ms={10}>
              <Row className="gx-2 align-items-center">
                <Col sm="auto">Panel is:</Col>
                <Col>
                  <span className="text-primary">{bds1.wall_panel_is}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="gx-2 align-items-center">
                <Col sm="auto">Profile:</Col>
                <Col>
                  <span className="text-primary">{bds1.wall_profile}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={2}>
              <Form.Check
                type="checkbox"
                label="by Others"
                checked={bds1.wall_by_others}
              />
            </Col>
            <Col sm={10}>
              <Row className="gx-3 align-items-center">
                <Col sm="auto">Color:</Col>
                <Col>
                  <span className="text-primary">{bds1.wall_color}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="border py-2">
            <Col sm={2}>Insulation</Col>
            <Col sm={10}>
              <Row className="mb-3">
                <Col sm={8}>
                  <div>
                    <span className="text-primary">{bds1.wall_insulation_by_pebsteel}</span>
                  </div>
                  <Row>
                    <Col>
                      <span className="text-primary">{bds1.wall_name}</span>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Thickness (mm):</Col>
                        <Col>
                          <span className="text-primary">{bds1.wall_insulation_thickness}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="by Others"
                      checked={bds1.wall_insulation_by_others}
                    />
                  </div>
                  <Row className="gx-2 align-items-center">
                    <Col sm="auto">Density:</Col>
                    <Col>
                      <span className="text-primary">{bds1.wall_insulation_density}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <span dangerouslySetInnerHTML={{ __html: bds1.wall_insulation_note }}></span>    
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="border py-2">
            <Col sm="2">Wall light</Col>
            <Col>
              <Row className="gx-2">
                <Col sm={8}>
                  <Form.Check
                    type="checkbox"
                    label="By Pebsteel"
                    checked={bds1.wall_light_by_pebsteel}
                  />
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="checkbox"
                    label="By Others"
                    checked={bds1.wall_light_by_others}
                  />
                </Col>
              </Row>
              <Row className="gx-2">
                <Col>
                  <div>Width (mm):</div>
                  <span className="text-primary">{bds1.wall_light_width}</span>
                </Col>
                <Col>
                  <div>Length (mm):</div>
                  <span className="text-primary">{bds1.wall_light_length}</span>
                </Col>
                <Col>
                  <div>Thickness (mm):</div>
                  <span className="text-primary">{bds1.wall_light_thickness}</span>
                </Col>
                <Col>
                  <div>Color:</div>
                  <span className="text-primary">{bds1.wall_light_color}</span>
                </Col>
                <Col sm={4}>
                  <div>Description:</div>
                  <span className="text-primary">{bds1.wall_light_description}</span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <span dangerouslySetInnerHTML={{ __html: bds1.wall_light_note }}></span>  
                </Col>
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">9</td>
        <td className="fw-bold text-center">Sheeting Angle, <br/> Gable Angle, Eave Angle <br/>(If sheeting by
others)</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                label="By Pebsteel"
                checked={bds1.sheeting_angle_by_pebsteel}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                type="checkbox"
                label="By Others"
                checked={bds1.sheeting_angle_by_others}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <span dangerouslySetInnerHTML={{ __html: bds1.sheeting_angle_note }}></span>  
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">10</td>
        <td className="fw-bold text-center">EAVE CONDITION</td>
        <td colSpan="8">
          <Row className="mb-2">
            <Col>
              <Form.Check
                type="checkbox"
                label="Gutters & Downspouts,"
                checked={bds1.eave_condition_gutter_downspouts}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="Eave Trim,"
                checked={bds1.eave_condition_trim}
              />
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row className="mb-2 gx-2">
            <Col>
              <Form.Check
                type="checkbox"
                label="Curved Eave with projection,"
                checked={bds1.eave_condition_curved_projection}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="w/o Gutters & Downspouts,"
                checked={bds1.eave_condition_w_o_gutters_downspouts}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="w/Valley Gutters & Downspouts,"
                checked={bds1.eave_condition_w_valley_gutters_downspouts}
              />
            </Col>
            <Col>
              <Flex justifyContent="between">
                <div>Downspouts</div>
                <Form.Check
                  type="checkbox"
                  label="Metal"
                  checked={bds1.eave_condition_downspouts_meta}
                />

                <Form.Check
                  type="checkbox"
                  label="PVC"
                  checked={bds1.eave_condition_downspouts_pvc}
                />
              </Flex>
            </Col>
          </Row>
          <Row className="gx-2">
            <Col>
              <Form.Check
                type="checkbox"
                label="Curved Eave w/o projection,"
                checked={bds1.eave_condition_curved_projection2}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="w/o Gutters & Downspouts,"
                checked={bds1.eave_condition_w_o_gutters_downspouts2}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="w/Valley Gutters & Downspouts,"
                checked={bds1.eave_condition_w_valley_gutters_downspouts2}
              />
            </Col>
            <Col>
              <Flex justifyContent="between">
                <div>Downspouts</div>
                <Form.Check
                  type="checkbox"
                  label="Metal"
                  checked={bds1.eave_condition_downspouts_meta2}
                />

                <Form.Check
                  type="checkbox"
                  label="PVC"
                  checked={bds1.eave_condition_downspouts_pvc2}
                />
              </Flex>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span dangerouslySetInnerHTML={{ __html: bds1.eave_condition_note }}></span>  
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td className="text-center">11</td>
        <td className="fw-bold text-center">WALL TRIM COLORS</td>
        <td colSpan="8">
          <Row className="mb-3">
            <Col sm={4}>
              <div>Color of gutters, down spouts, Eave trim :</div>
              <span className="text-primary">{bds1.wall_trim_colors}</span>
            </Col>
            <Col sm={8}>
              <span dangerouslySetInnerHTML={{ __html: bds1.wall_trim_colors_gutters_note }}></span>  
            </Col>
            </Row>
            <Row>
            <Col sm={4}>
              <div>Color of other flashing, trim:</div>
              <span className="text-primary">{bds1.wall_trim_colors_others}</span> 
            </Col>
            <Col sm={8}>
              <span dangerouslySetInnerHTML={{ __html: bds1.wall_trim_colors_flashing_note }}></span>   
            </Col>
          </Row>
        </td>
      </tr>
    </table>
  )
};

BDS1.propTypes = {
  pif: PropTypes.object.isRequired,
  area: PropTypes.array.isRequired
}

export default BDS1;