import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import { FaAddressCard, FaGg, FaIndustry, FaInfo, FaLink, FaMap, FaPhone, FaRProject, FaRecordVinyl } from "react-icons/fa";
import { MdAccountBalance, MdBloodtype, MdEmail, MdMessage, MdTransferWithinAStation } from "react-icons/md";
import { Link } from "react-router-dom";
import { formatPhoneNumberInternational, formatPhoneNumberUrl } from 'helpers/phoneNumber';
import { formatNumber } from "helpers/utils";
import GoogleMapsLink from "helpers/googleMapsLink";
import React, { useState, Suspense, lazy } from "react";
import ContactItem from "../../ContactItem";
import RatingScore from "../../component/RatingScore";
import PropTypes from 'prop-types';

const LazyLeadTransferToSalesConfirm = lazy(() => import("../confirmation/LeadTransferToSalesConfirm"));
const LazyLeadRecordConfirm = lazy(() => import("../confirmation/LeadRecordConfirm"));

const Open = ({lead}) => {
  const status = lead?.status;
  const permissions = lead?.permissions;
  const contacts = lead?.contacts;

  const {
    allow_transfer,
    allow_record,
  } = permissions || {};

  const [showRecordConfirm, setShowRecordConfirm] = useState(false);
  const [showTransferToSalesConfirm, setShowTransferToSalesConfirm] = useState(false);
  //const [showContactForm, setShowContactForm] = useState(false);

  const handleRecordLead = () => {
    setShowRecordConfirm(true);
  }

  const handleTransferToSalesLead = () => {
    setShowTransferToSalesConfirm(true);
  }

  // const handleAddContact = () => {
  //   setShowContactForm(true);
  // }

  return (
    <>
      <div className="p-3">
        <div className="fs-2 fw-semi-bold">Open stage</div>
        <p>New lead has been collected.</p>

        <Row className="fs--1 gx-3 h-100 mb-3">
          <Col md={7}>
            <Card className="mb-3 h-100">
              <Card.Header className="fs-1 border-bottom"><FaInfo /> Information</Card.Header>
              <Card.Body>
                <Row className="gx-2">
                  <Col>
                    <Row className="mb-2">
                      <Col>Status:</Col>
                      <Col sm={9}>
                        <Badge pill bg="primary">{status?.name}</Badge> / Stage: <Badge pill bg="info">{lead.stage}</Badge>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>MQL status:</Col>
                      <Col sm={9}>
                        <Badge pill bg="primary">{lead.mql_status}</Badge>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col>Lead name:</Col>
                      <Col sm={9}>
                        <span className="text-primary">{lead.name}</span>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col>Rating:</Col>
                      <Col sm={9}>
                        <RatingScore rating={lead.rating} />
                      </Col>
                    </Row>

                    <Row className="mb-2 mt-5">
                      <Col>
                        <Row className="mb-2">
                          <Col>Lead source:</Col>
                          <Col>
                            <span className="text-primary">{lead.source}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="mb-2">
                          <Col>How to know Pebsteel:</Col>
                          <Col>
                            <span className="text-primary">{lead.contact_mode}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <Card.Header className="fs-1 border-bottom"><MdAccountBalance /> Account name</Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col>Name</Col>
                  <Col sm={10}>
                    <span className="text-primary fw-bold">{lead.account_name}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><FaIndustry /> Industry</Col>
                  <Col sm={7}>
                    <span className="text-primary">{lead.industry}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><MdBloodtype /> Type of company</Col>
                  <Col sm={7}>
                    <span className="text-primary">{lead.type_of_company}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><FaGg /> Legal status</Col>
                  <Col sm={7}>
                    <span className="text-primary">{lead.legal_status}</span>
                  </Col>
                </Row>

                <Row className="mb-2 mt-4">
                  <Col><MdEmail /> Work email</Col>
                  <Col sm={7}>
                    <Link to={`mailto:${lead.work_email}`}>{lead.work_email}</Link>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><FaPhone /> Work phone</Col>
                  <Col sm={7}>
                    <Link to={formatPhoneNumberUrl(lead.work_phone)}>{formatPhoneNumberInternational(lead.work_phone)}</Link>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col><FaLink /> Website</Col>
                  <Col sm={7}>
                    <Link to={`//${lead.website}`} target="_blank" rel="noopener noreferrer">{lead.website}</Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Header className="fs-1 border-bottom"><FaMap /> Address</Card.Header>
              <Card.Body>
                {lead.address ? (
                  <GoogleMapsLink address={lead?.address} />
                ) : "No address found."}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="fs--1 gx-3 mb-3">
          <Col md={7}>
            <Card className="mb-3">
              <Card.Header className="fs-1 border-bottom"><MdMessage /> Customer message</Card.Header>
              <Card.Body>
                <span className="text-primary">{lead.message ? lead.message : "No message found."}</span>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Header className="fs-1 border-bottom"><FaRProject /> Project information</Card.Header>
              <Card.Body>
                <Row className="mb-4">
                  <Col>Project name:</Col>
                  <Col sm={9}>
                    <span className="text-primary fw-bold">{lead.project_name}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Amount:</Col>
                  <Col sm={9}>
                    <span className="text-primary">${formatNumber(lead.amount)}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>Area:</Col>
                  <Col sm={9}>
                    <span className="text-primary">{formatNumber(lead.area)} m2</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Row className="mb-2">
                      <Col>Building type L1:</Col>
                      <Col sm={9}>
                        <span className="text-primary">{lead?.building_type_l1?.name}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Col>Building type L2:</Col>
                      <Col sm={9}>
                        <span className="text-primary">{lead?.building_type_l2?.name}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mb-2 mt-4">
                  <Col>Location:</Col>
                  <Col sm={9}>
                    <GoogleMapsLink address={lead.project_location} />
                  </Col>
                </Row>

              </Card.Body>
            </Card>

          </Col>
          <Col md={5}>
            <Card className="mb-3">
              <Card.Header className="fs-1 border-bottom"><FaAddressCard /> Contacts</Card.Header>
              <Card.Body>
                {contacts && contacts.map(contact => (
                  <ContactItem
                    key={contact?.id}
                    contact={contact}
                  />
                ))}
              </Card.Body>
              <Card.Footer>
                {/* <div className="text-end">
                  <Button size="sm" variant="primary" onClick={handleAddContact}><FaPlus /> Add contact</Button>
                </div> */}
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <div className="bg-light fixed-bottom border-0 text-center p-3">
          {allow_record && (
            <Button
              variant="warning"
              className="me-1"
              size="sm"
              onClick={handleRecordLead}
            >
              <FaRecordVinyl className="text-danger"/> <span className="d-none d-xl-inline-block ms-1">Record</span>
            </Button>
          )}

          {allow_transfer && (
            <Button
              variant="info"
              className="me-1"
              size="sm"
              onClick={handleTransferToSalesLead}
            >
              <MdTransferWithinAStation className="text-warning"/> <span className="d-none d-xl-inline-block ms-1">Transfer to Sales</span>
            </Button>
          )}
        </div>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        {showRecordConfirm && (
          <LazyLeadRecordConfirm
            showModal={showRecordConfirm}
            setshowModal={setShowRecordConfirm}
            lead={lead}
          />
        )}

        {showTransferToSalesConfirm && (
          <LazyLeadTransferToSalesConfirm
            showModal={showTransferToSalesConfirm}
            setshowModal={setShowTransferToSalesConfirm}
            lead={lead}
          />
        )}
      </Suspense>
    </>
  )
};

Open.propTypes = {
  lead: PropTypes.object.isRequired
}

export default Open;