import { Button } from "react-bootstrap";
import HotelItem from "./HotelItem";
import Flex from "components/common/Flex";
import { useDispatch } from "react-redux";
import { deleteBookingHotel, removeHotelDocument } from "redux/booking/travel/travelBookingSlice";
import React, { useState } from "react";
import HotelForm from "./HotelForm";
import ConfirmationModal from "components/confirmation/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const HotelList = ({booking}) => {
  const [showModalHotel, setShowModalHotel] = useState(false)
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [hotel, setHotel] = useState(null)
  const {hotels} = booking

  const bookingId = booking.id
  const dispatch = useDispatch()

  const handleEditHotelBooking = (hotel) => {
    setHotel(hotel)
    openModal()
  }

  const handleDeleteHotelBooking = (hotel) => {
    setHotel(hotel)
    setShowConfirmationDelete(true)
  }

  const handleRemoveAttachment = (hotelId, documentId) => {
    dispatch(removeHotelDocument({bookingId, hotelId, documentId}))
  }

  const handleCreateHotelBooking = () => {
    setHotel(null)
    openModal()
  }

  const openModal = () => {
    setShowModalHotel(true)
  }

  const closeConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleCloseConfirmation = () => {
    closeConfirmation()
  }

  const handleOkConfirm = async () => {
    const hotelId = hotel.id
    dispatch(deleteBookingHotel({bookingId, hotelId}))
    closeConfirmation()
  }

  return (
    <>
      <Flex justifyContent="end" className="mx-4">
        <Button
          variant="falcon-primary"
          onClick={handleCreateHotelBooking}
        >
          <FontAwesomeIcon icon="plus" />  New Hotel Booking
        </Button>
      </Flex>
      
      {hotels && hotels.map((hotel) => (
        <HotelItem
          key={hotel.id}
          booking={booking}
          hotel={hotel}
          editHotelBooking={() => handleEditHotelBooking(hotel)}
          deleteHotelBooking={() => handleDeleteHotelBooking(hotel)}
          handleRemoveAttachment={handleRemoveAttachment}
        />
      ))}

      <HotelForm 
        showModal={showModalHotel}
        setshowModal={setShowModalHotel}
        booking={booking}
        hotel={hotel}
      />

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleOkConfirm}
        message="Are you sure to delete?"
      />
    </>
  )
};

HotelList.propTypes = {
  booking: PropTypes.object.isRequired
}

export default HotelList;