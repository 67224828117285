import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import RoleForm from '../RoleForm';
import RoleDetailClone from './RoleDetailClone';
import { deleteRole } from 'redux/authorization/roleSlice';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaEllipsisV } from 'react-icons/fa';

const RoleDetailHeader = ({role, setTrigger}) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
  const [showRoleForm, setShowRoleForm] = useState(false)
  const [showCloneForm, setShowCloneForm] = useState(false)
  const [title, setTitle] = useState('')
  const [roleData, setRoleData] = useState(role);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const roleUrl = "/authorization/roles"

  const handleNewRole = () => {
    setTitle("New Role")
    showForm()
    setRoleData(null)
  }

  const handleEditRole = () => {
    setTitle("Edit Role")
    showForm()
    setRoleData(role)
  }

  const handleDeleteRole = () => {
    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmationDelete(false)
  }

  const handleActionConfirm = async () => {
    try {
      dispatch(deleteRole(role))
      navigate(roleUrl)
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  const handleRoleList = () => {
    navigate(roleUrl)
  }

  const handleCloneRole = () => {
    showCloneRoleForm()
  }

  const showForm = () => {
    setShowRoleForm(true)
  }

  const showCloneRoleForm = () => {
    setShowCloneForm(true)
  }

  const handleRefresh = () => {
    setTrigger(value => value + 1)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="sync-alt"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleRefresh}
            >
              <span className="d-none d-xl-inline-block ms-1"></span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="user-plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewRole}
            >
              <span className="d-none d-xl-inline-block ms-1">New</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="copy"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleCloneRole}
            >
              <span className="d-none d-xl-inline-block ms-1">Clone</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleRoleList}
            >
              <span className="d-none d-xl-inline-block ms-1">
                List
              </span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FaEllipsisV className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item onClick={handleEditRole}>
                    <MdEdit /> Edit
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="text-danger" onClick={handleDeleteRole}>
                    <MdDelete className="text-danger" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      <ConfirmationModal
        show={showConfirmationDelete}
        onHide={handleCloseConfirmation}
        onConfirm={handleActionConfirm}
        message="Are you sure to delete?"
      />

      <RoleForm
        showModal={showRoleForm}
        setshowModal={setShowRoleForm}
        title={title}
        role={roleData}
      />

      <RoleDetailClone
        showModal={showCloneForm}
        setshowModal={setShowCloneForm}
        role={role}
      />
    </>
  );
};

RoleDetailHeader.propTypes = {
  role: PropTypes.object
}

export default RoleDetailHeader;
