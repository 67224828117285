import React from "react"
import { useLocation } from "react-router-dom"
import ProjectForm from "./ProjectForm"

const EditPCV = () => {
  const location = useLocation();
  const project = location.state?.project;

  return (
    <ProjectForm title="Edit PCV" project={project} />
  )
}

export default EditPCV;