import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { resetNestedParams, setFilterTag, setNestedParams } from "redux/authorization/permissionSlice";
import IconAlert from "components/common/IconAlert";
import { formatString } from "helpers/utils";
import { MdSearch, MdOutlineRestartAlt, MdOutlineClose } from "react-icons/md";
import { PERMISSION_PARENT_GROUPS } from "app_config";
import React from 'react';
import PropTypes from 'prop-types';

const FilterAdvanceModal = ({showModal, setshowModal, data, entryKey}) => {
  const { control, register, handleSubmit, setValue, reset } = useForm();
  const dispatch = useDispatch();

  const {pageSize} = data || {};
  const key = entryKey;

  const onSubmit = (data) => {
    const newData = {
      subject_class_cont: data.subject_class,
      action_cont: data.action,
      description_cont: data.description,
      parent_group_in: data.parent_groups,
      page: 1,
      per_page: pageSize
    }

    console.log(newData)

    //store for filter
    const dataFilter = [
      { id: "subject_class_cont", name: "Subject class", value: data.subject_class },
      { id: "action_cont", name: "Action", value: data.action },
      { id: "description_cont", name: "Description", value: data.description },
    ]

    if (data.parent_groups) {
      dataFilter.push({ id: "parent_group_in", name: "Parent group", value: data.parent_groups })
    }

    dispatch(setFilterTag({key, tags: dataFilter.filter((data) => data.value !== undefined && data.value !== '')}))

    let obj = {}
    Object.entries(newData).forEach((item) => {
      const value = item[1]
      const key = item[0]

      if (value !== null && value !== "" && value !== undefined) {
        obj = {...obj, [key]: value}
      }
    })

    try {
      dispatch(setNestedParams({key, params: obj}));
      closeModal()
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleReset = () => {
    resetForm()
  }

  const resetForm = () => {
    reset()

    setValue('parent_groups', null)
    dispatch(resetNestedParams({key}));
  }

  const handleChangeParentGroup = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('parent_groups', selectedOptionsValues, { shouldValidate: true })
  }

  const parentGroupOptions = PERMISSION_PARENT_GROUPS.map((option) => ({ value: option, label: formatString(option) }));

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="bg-light border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Apply filters to advances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="info">
            <p className="mb-0">Establish the permission's filtering criteria</p>
          </IconAlert>

          <Form.Group as={Row} className="mb-2" controlId="subject_class">
            <Form.Label column sm={3} className="text-lg-end">
              Subject class
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                rows={6}
                name="subject_class"
                placeholder="Subject class"
                className="fs--1"
                {...register("subject_class")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="action">
            <Form.Label column sm={3} className="text-lg-end">
              Action
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                type="text"
                name="action"
                placeholder="Action"
                className="fs--1"
                {...register("action")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="description">
            <Form.Label column sm={3} className="text-lg-end">
              Description
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={6}
                name="description"
                placeholder="Description"
                className="fs--1"
                {...register("description")}
              />
            </Col>
          </Form.Group>
          
          <div className="border-dashed border-bottom my-3"></div>

          <Form.Group as={Row} className="mb-2" controlId="parent_groups">
            <Form.Label column sm={3} className="text-lg-end">
              Parent group
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name='parent_groups'
                render={({field}) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={false}
                    options={parentGroupOptions}
                    placeholder='Select...'
                    classNamePrefix='react-select'
                    isMulti
                    value={field.value ? parentGroupOptions.filter((item) => field.value.includes(item.value)) : []}
                    onChange={handleChangeParentGroup}
                  />
                )}
              />
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="bg-light border-0">
          <Button size="sm" type="submit"><MdSearch /> Search</Button>
          <Button size="sm" variant="falcon-default" onClick={handleReset}><MdOutlineRestartAlt /> Reset</Button>
          <Button size="sm" variant="falcon-default" onClick={closeModal}><MdOutlineClose /> Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

FilterAdvanceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  entryKey: PropTypes.string.isRequired
}

export default FilterAdvanceModal;