import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";
import {
  initialStateStatus,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  createInitialState,
  handlePayload
} from "helpers/reducerHelpers";

const bookingUrl = "/booking/car/transactions"

export const getAllBookings = createAsyncThunk(
  "CarBooking/getAllBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBooking = createAsyncThunk(
  "CarBooking/getBooking",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/${transactionId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createBooking = createAsyncThunk(
  "CarBooking/createBooking",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(bookingUrl, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error?.response.data || error.message)
    }
  }
)

export const updateBooking = createAsyncThunk(
  "CarBooking/updateBooking",
  async ({ transactionId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${bookingUrl}/${transactionId}`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteBooking = createAsyncThunk(
  "CarBooking/deleteBooking",
  async (transaction) => {
    try {
      const response = await apiAxios.delete(`${bookingUrl}/${transaction.id}`);
      return response.data

    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const exportBookings = createAsyncThunk(
  "CarBooking/exportBookings",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${bookingUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `car-bookings-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const getDriversBySite = createAsyncThunk(
  "CarBooking/getDriversBySite",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/drivers_available`, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCarsBySite = createAsyncThunk(
  "CarBooking/getCarsBySite",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/cars_available`, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const approveBooking = createAsyncThunk(
  "CarBooking/approveBooking",
  async ({ transactionId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/approve`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const rejectBooking = createAsyncThunk(
  "CarBooking/rejectBooking",
  async ( { transactionId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/reject`, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const uploadDocument = createAsyncThunk(
  "CarBooking/uploadDocument",
  async ({ transactionId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeDocument = createAsyncThunk(
  "CarBooking/removeDocument",
  async ( { transactionId, documentId }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/remove_document`, {file_id: documentId} )
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const sendBackEmail = createAsyncThunk(
  "CarBooking/sendBackEmail",
  async ( transactionId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/send_back_email`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const sendBackToApprove = createAsyncThunk(
  "CarBooking/sendBackToApprove",
  async ( transactionId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/send_back_to_approve`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const cloneBooking = createAsyncThunk(
  "CarBooking/cloneBooking",
  async ( transactionId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/clone`);
      return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data);
    }
  }
)

export const transferSiteOfficeBooking = createAsyncThunk(
  "CarBooking/transferSiteOfficeBooking",
  async ( {transactionId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/transfer_site_office`, data);
      return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data);
    }
  }
)

export const resetBooking = createAsyncThunk(
  "CarBooking/resetBooking",
  async ( transactionId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/reset`)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const cancelBooking = createAsyncThunk(
  "CarBooking/cancelBooking",
  async ( { transactionId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/cancel`, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const getActivityLog = createAsyncThunk(
  "CarBooking/getActivityLog",
  async ({ transactionId, params } ) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/${transactionId}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const driverConfirm = createAsyncThunk(
  "CarBooking/driverConfirm",
  async ({ transactionId, driverTranId } ) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/driver_transactions/${driverTranId}/confirm`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const assignDriverTran = createAsyncThunk(
  "CarBooking/assignDriverTran",
  async ({ transactionId, data } ) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/driver_transactions`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const updateDriverTran = createAsyncThunk(
  "CarBooking/updateDriverTran",
  async ({ transactionId, driverTranId, data } ) => {
    try {
      const response = await apiAxios.put(`${bookingUrl}/${transactionId}/driver_transactions/${driverTranId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const deleteDriverTran = createAsyncThunk(
  "CarBooking/deleteDriverTran",
  async ({ transactionId, driverTranId } ) => {
    try {
      const response = await apiAxios.delete(`${bookingUrl}/${transactionId}/driver_transactions/${driverTranId}`)
      return response.data;
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const removeImageDriverTran = createAsyncThunk(
  "CarBooking/removeImageDriverTran",
  async ({ transactionId, driverTranId, imageId } ) => {
    try {
      const response = await apiAxios.post(`${bookingUrl}/${transactionId}/driver_transactions/${driverTranId}/remove_image`, {image_id: imageId})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCarTransactions = createAsyncThunk(
  "CarBooking/getCarTransactions",
  async () => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/driver_transactions`)
      return response.data;
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCarTransactionsPending = createAsyncThunk(
  "CarBooking/getCarTransactionsPending",
  async () => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/driver_transactions/pending_list`)
      return response.data;
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportCarTransactions = createAsyncThunk(
  "CarBooking/exportCarTransactions",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${bookingUrl}/driver_transactions/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `car-transactions-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const getAllStatuses = createAsyncThunk(
  "CarBooking/getAllStatuses",
  async () => {
    try {
      const response = await apiAxios.get("/booking/car/statuses")
      return objectSerializer(response.data)
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCarSites = createAsyncThunk(
  "CarBooking/getCarSites",
  async () => {
    try {
      const response = await apiAxios.get("/organization/sites/car_booking")
      return objectSerializer(response.data.rows)
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCarCalendar = createAsyncThunk(
  "CarBooking/getCarCalendar",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/calendar`, {params})
      return objectSerializer(response.data)
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getWaitingApprovalBookings = createAsyncThunk(
  "CarBooking/getWaitingApprovalBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/waiting_approval_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getApprovedBookings = createAsyncThunk(
  "CarBooking/getApprovedBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/approved_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getRejectedBookings = createAsyncThunk(
  "CarBooking/getRejectedBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/rejected_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCanceledBookings = createAsyncThunk(
  "CarBooking/getCanceledBookings",
  async (params) => {
    try {
      const response = await apiAxios.get(`${bookingUrl}/canceled_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const handelPayloadDriver = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const record = data.attributes
    
    if (record !== null) {
      switch (type) {
        case "create": 
          state.recordData.driver_trans.unshift(record)
          break;
        case "delete":
          state.recordData.driver_trans = state.recordData.driver_trans.filter((item) => item.id !== record.id)
          break;
        default:
        {
          const updatedIndex = state.recordData.driver_trans.findIndex((item) => item.id === record.id)
          if (updatedIndex !== -1) {
            state.recordData.driver_trans[updatedIndex] = record
          }

          break;
        }
      }
    }

    if (message !== null) {
      toast.success(message);
    }
  }
};

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const carBookingSlice = createSlice({
  name: "booking_car",
  initialState: {
    ...initialStateStatus,
    carTransactionData: createInitialState(),
    carTransactionPendingData: createInitialState(),
    transactionStatuses: [],
    carSites: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookings.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getWaitingApprovalBookings.fulfilled, (state, action) => handleFulfilled(state, action, "waitingApprovalData"))
      .addCase(getApprovedBookings.fulfilled, (state, action) => handleFulfilled(state, action, "approvedData"))
      .addCase(getRejectedBookings.fulfilled, (state, action) => handleFulfilled(state, action, "rejectedData"))
      .addCase(getCanceledBookings.fulfilled, (state, action) => handleFulfilled(state, action, "canceledData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))
      
      .addCase(getCarTransactions.fulfilled, (state, action) => handleFulfilled(state, action, "carTransactionData"))
      .addCase(getCarTransactionsPending.fulfilled, (state, action) => handleFulfilled(state, action, "carTransactionPendingData"))

      .addCase(getBooking.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(createBooking.fulfilled, (state, action) => handlePayload(state, action, "create"))
      .addCase(updateBooking.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(deleteBooking.fulfilled, (state, action) => handlePayload(state, action, "delete"))
      .addCase(exportBookings.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportBookings.fulfilled, (state) => {
        state.exporting = false
        toast.success("Booking has been exported successfully.")
      })
      .addCase(approveBooking.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(rejectBooking.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(uploadDocument.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(removeDocument.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(sendBackEmail.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(sendBackToApprove.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(transferSiteOfficeBooking.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(resetBooking.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(cancelBooking.fulfilled, (state, action) => handlePayload(state, action))

      .addCase(assignDriverTran.fulfilled, (state, action) => handelPayloadDriver(state, action, "create"))
      .addCase(updateDriverTran.fulfilled, (state, action) => handelPayloadDriver(state, action))
      .addCase(deleteDriverTran.fulfilled, (state, action) => handelPayloadDriver(state, action, "delete"))
      .addCase(removeImageDriverTran.fulfilled, (state, action) => handelPayloadDriver(state, action))
      .addCase(driverConfirm.fulfilled, (state, action) => handelPayloadDriver(state, action))
      
      .addCase(getAllStatuses.fulfilled, (state, action) => {
        state.transactionStatuses = action.payload
      })
      .addCase(getCarSites.fulfilled, (state, action) => {
        state.carSites = action.payload
      })
      .addCase(exportCarTransactions.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportCarTransactions.fulfilled, (state) => {
        state.exporting = false
        toast.success("Car transactions have been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {

          console.log("Error action payload:", action.payload); // Debugging
          state.loading = false;

          const errorMessage =
            action.payload?.error?.response?.status ??
            action.payload?.error ??
            action.error?.message ??
            "An unexpected error occurred";
    
        toast.error(errorMessage);
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
} = carBookingSlice.actions

export default carBookingSlice.reducer