import { FaTrash, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import {ListGroup, Badge} from "react-bootstrap";
import Flex from "components/common/Flex";
import React, { useState } from "react";
import PIFVersionDeleteConfirmation from "components/crm/pif-version/PIFVersionDeleteConfirmation";
import { MdHistoryToggleOff } from "react-icons/md";
import PropTypes from 'prop-types';

const PIFVersionList = ({projectId, buildingId, changeOrderId, allow_delete_pif_version, data}) => {
  const pifUrl = `/crm/projects/${projectId}/buildings/${buildingId}/change-orders/${changeOrderId}/pifs-versions`;

  const [pif, setPif] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeletePIF = (item) => {
    setShowDeleteConfirm(true);
    setPif(item);
  }

  return (
    <>
      <ListGroup>
        {data && data.map((item, index) => (
          <ListGroup.Item key={index}>
            <Flex justifyContent="between">
              <div>
                <div>
                  <Link to={`${pifUrl}/${item?.id}`}>Version {item.version}</Link>
                  <span className="text-capitalize ms-2 small"><Badge pill>{item.co_status}</Badge></span>
                </div>
                <div className="small"><FaUser /> By: {item?.created_by} | <MdHistoryToggleOff /> {item?.created_date}</div>
              </div>
              <div>
                {allow_delete_pif_version && item?.allow_delete && (
                  <FaTrash className="ms-1 cursor-pointer text-danger" title="Delete" onClick={() => handleDeletePIF(item)} />
                )}
              </div>
            </Flex>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {showDeleteConfirm && (
        <PIFVersionDeleteConfirmation
          showModal={showDeleteConfirm}
          setshowModal={setShowDeleteConfirm}
          projectId={projectId}
          buildingId={buildingId}
          adaptationId={changeOrderId}
          pif={pif}
        />
      )}
    </>
  );
};

PIFVersionList.propTypes = {
  projectId: PropTypes.number.isRequired,
  buildingId: PropTypes.number.isRequired,
  changeOrderId: PropTypes.number.isRequired,
  allow_delete_pif_version: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

export default PIFVersionList;
