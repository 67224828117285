import React from "react"
import WaiverForm from "./WaiverForm"
import { useLocation } from "react-router-dom"

const NewWaiver = () => {
  const location = useLocation()
  const {project} = location.state

  return (
    <WaiverForm project={project} title="NEW"/>
  )
}

export default NewWaiver