import React from "react";

const TypeOfSalesTable = ({data= []}) => {

  const { number_of_projects, weight } = data && data?.reduce((acc, item) => {
    const [, { number_of_projects, weight }] = Object.entries(item)[0];
  
    acc.number_of_projects += parseInt(number_of_projects) || 0;
    acc.weight += parseFloat(weight) || 0;
  
    return acc;
  }, { number_of_projects: 0, weight: 0 });

  return (
    <div className="fs--1">
      <h6 className="mb-2">Detail:</h6>
      <table className="border">
        <thead className="bg-light text-center">
          <th className="px-3"></th>
          <th className="px-3">Number of projects</th>
          <th className="px-3">Weight</th>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const [name, values] = Object.entries(item)[0];
            return (
              <tr key={index}>
                <td className="px-3 border">{name}</td>
                <td className="text-end px-3 border">{values.number_of_projects}</td>
                <td className="text-end px-3 border">{values.weight} M.T</td>
              </tr>
            );
          })}
          <tr className="bg-light fw-bold border">
            <td className="px-3 border">Total</td>
            <td className="text-end px-3 border">{number_of_projects}</td>
            <td className="text-end px-3 border">{weight?.toLocaleString()} M.T</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
};

export default TypeOfSalesTable;