import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import IconAlert from 'components/common/IconAlert';
import { updateProjectBudget } from 'redux/booking/travel/travelBookingSlice';
import { FaHandHoldingUsd } from 'react-icons/fa';

const BookingUpdateBudgetModal = ({showModal, setShowModal, booking}) => {
  const { register, handleSubmit, formState: {errors}, setValue, getValues } = useForm();
  const {usdExchangeRateData} = useSelector((state) => state.settings);

  const dispatch = useDispatch();
  const bookingId = booking.id;

  useEffect(() => {
    if (booking?.project) {
      setValue('budget_amount_currency', booking.project.budget_amount_currency);
    }
    
    setValue('budget_exchange_rate', usdExchangeRateData);
  }, [setValue, booking])

  const onSubmit = (data) => {
    console.log(data)

    try {
      dispatch(updateProjectBudget({bookingId, data}))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="border-0 bg-light">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><FaHandHoldingUsd /> Update project budget for trip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="info">
              <p className="mb-0">In the case of a trip following the project's job, there must be a budget based on the price matrix. The information is confirmed by the accounting department.</p>
            </IconAlert>
            <Form.Group as={Row} className="mb-2" controlId="budget_amount_currency">
              <Form.Label column sm={3} className="text-lg-end">
                Budget currency
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  name="note"
                  placeholder="Budget currency"
                  className="fs--1"
                  onInput={(event) => {
                    const value = event.target.value
                    const exchangeRate = parseFloat(getValues("budget_exchange_rate")) || 1
                    const amount = parseFloat(value) * exchangeRate || 0
  
                    setValue("budget_amount", amount)
                  }}
                  {...register("budget_amount_currency", {
                    required: "Budget currency must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.budget_amount_currency && errors.budget_amount_currency.message}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="budget_exchange_rate">
              <Form.Label column sm={3} className="text-lg-end">
                Exchange rate
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  name="budget_exchange_rate"
                  placeholder="Exchange rate"
                  className="fs--1"
                  {...register("budget_exchange_rate", {
                    required: "Exchange rate must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.budget_exchange_rate && errors.budget_exchange_rate.message}
                </span>
                <IconAlert variant="info" className="mt-2">
                  <p className="mb-0">USD exchange rate to VND from <a href="https://www.vietcombank.com.vn/KHCN/Cong-cu-tien-ich/Ty-gia?devicechannel=default" target="_blank" rel="noreferrer">Vietcombank</a>.</p>
                </IconAlert>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="budget_amount">
              <Form.Label column sm={3} className="text-lg-end">
                Budget amount
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  name="note"
                  placeholder="Budget amount"
                  className="fs--1"
                  disabled
                  {...register("budget_amount", {
                    required: "Budget amount must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.budget_amount_currency && errors.budget_amount_currency.message}
                </span>
                <div className="mt-1 mb-2">Amount is converted from USD to VND.</div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" controlId="budget_notes">
              <Form.Label column sm={3} className="text-lg-end">
                Note
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="budget_notes"
                  placeholder="Note"
                  className="fs--1"
                  {...register("budget_notes")}
                />
                <span className="text-danger">
                  {errors.note && errors.note.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit" variant="primary">Update</Button>
            <Button size="sm" variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

BookingUpdateBudgetModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  booking: PropTypes.object
}


export default BookingUpdateBudgetModal;