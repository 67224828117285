import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SalesUnitDetailHeader from "./SalesUnitDetailHeader";
import SalesUnitDetailInfo from "./SalesUnitDetailInfo";
import { getSalesUnit } from "redux/crm/salesUnitSlice";
import { useDispatch, useSelector } from "react-redux";

const SalesUnitDetail = () => {
  const params = useParams();
  const {recordData} = useSelector((state) => state.crm_sales_unit);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesUnit(params.id));
  }, [params]);;

  return (
    <>
      <SalesUnitDetailHeader sales_unit={recordData} />
      <SalesUnitDetailInfo sales_unit={recordData} />
    </>
  )
}

export default SalesUnitDetail;