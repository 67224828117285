import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Accordion, Card, Row, Col, Form, Table, Dropdown } from "react-bootstrap";
import TinymceEditor from "components/common/TinymceEditor";
import BDSGeneralInformation from "./BDSGeneralInformation";
import Select from "react-select";
import PropTypes from 'prop-types';

import { 
  CRM_PIF_LOADS_RECURRENCE_TIME,
  CRM_PIF_LOADS_EXPLOSURE,
  CRM_PIF_LOADS_OCCUPANCYCATEGORY,
  CRM_PIF_GRADE_PRIMARY_MEMBER,
  CRM_PIF_GRADE_SECONDARY_MEMBER,
  CRM_PIF_BUILDING_FRAME_TYPE,
  CRM_PIF_BUILDING_HEIGHT,
  CRM_PIF_BUILDING_COLUMN_SUPPORT,
  CRM_PIF_WALL_FRAME,
  CRM_PIF_GIRT_TYPE,
  CRM_PIF_ROOF_PROFILE,
  CRM_PIF_COLOR,
  CRM_PIF_ROOF_NAME
} from "app_config";
import { useSelector } from "react-redux";

const BDS1 = ({
  data,
  openWallData,
  newOpenWall,
  editOpenWall,
  deleteOpenWall
}) => {
  const {bds1: initialKeys} = useSelector(state => state.crm_project_pif.initialKeys);
  const [activeKey, setActiveKey] = useState(initialKeys);

  const {project, building, area} = data;
  const {control, register, Controller, setValue} = data.useHookForm;

  const areaId = area.id
  const {bds1} = area

  useEffect(() => {
    setActiveKey(initialKeys);
  }, [initialKeys]);

  useEffect(() => {
    if (bds1) {
      Object.entries(bds1).forEach(([key, value]) => {
        setValue(`bds1_${key}_${area.id}`, value)
      })
    }
  }, [bds1, setValue]);

  const loadsRecurrenceTimeOptions = useMemo(() => (
    CRM_PIF_LOADS_RECURRENCE_TIME.map((option) => ({value: option, label: option}))
  ))
  const defaultLoadsRecurrenceTimeOption = bds1 ? { value: bds1.loads_recurrence_time, label: bds1.loads_recurrence_time } : null

  const loadsExplosureOptions = useMemo(() => (
    CRM_PIF_LOADS_EXPLOSURE.map((option) => ({value: option, label: option}))
  ))
  const defaultLoadsExplosureOption = bds1 ? { value: bds1.loads_explosure, label: bds1.loads_explosure } : null

  const loadsOccupancyCategoryOptions = useMemo(() => (
    CRM_PIF_LOADS_OCCUPANCYCATEGORY.map((option) => ({value: option, label: option}))
  ))
  const defaultLoadsOccupancyCategoryOption = bds1 ? { value: bds1.loads_occupancy_category, label: bds1.loads_occupancy_category } : null

  const gradePrimaryMemberOptions = useMemo(() => (
    CRM_PIF_GRADE_PRIMARY_MEMBER.map((option) => ({value: option, label: option}))
  ))
  const defaultGradePrimaryMemberOption = bds1 ? { value: bds1.grade_primary_member, label: bds1.grade_primary_member } : null

  const gradeSecondaryMemberOptions = useMemo(() => (
    CRM_PIF_GRADE_SECONDARY_MEMBER.map((option) => ({value: option, label: option}))
  ))
  const defaultGradeSecondaryMemberOption = bds1 ? { value: bds1.grade_secondary_member, label: bds1.grade_secondary_member } : null

  const frameTypeOptions = useMemo(() => (
    CRM_PIF_BUILDING_FRAME_TYPE.map((option) => ({value: option, label: option}))
  ))
  const defaultFrameTypeOption = bds1 ? { value: bds1.building_parameters_frame_type, label: bds1.building_parameters_frame_type } : null

  const buildingHeightOptions = useMemo(() => (
    CRM_PIF_BUILDING_HEIGHT.map((option) => ({value: option, label: option}))
  ))
  const defaultBuildingHeightOption = bds1 ? { value: bds1.building_parameters_height, label: bds1.building_parameters_height } : null
  
  const buildingColumnSupportOptions = useMemo(() => (
    CRM_PIF_BUILDING_COLUMN_SUPPORT.map((option) => ({value: option, label: option}))
  ))
  const defaultBuildingColumnSupportOption = bds1 ? { value: bds1.building_parameters_column_support, label: bds1.building_parameters_column_support } : null
  
  const wallFrameOptions = useMemo(() => (
    CRM_PIF_WALL_FRAME.map((option) => ({value: option, label: option}))
  ))
  const defaultLeftEndWallFrameOption = bds1 ? { value: bds1.left_end_wall_frame, label: bds1.left_end_wall_frame } : null
  const defaultRightWallFrameOption = bds1 ? { value: bds1.right_end_wall_frame, label: bds1.right_end_wall_frame } : null

  const girtTypeOptions = useMemo(() => (
    CRM_PIF_GIRT_TYPE.map((option) => ({value: option, label: option}))
  ))
  const defaultLeftGirtTypeOption = bds1 ? { value: bds1.lef_end_girt_type, label: bds1.lef_end_girt_type } : null
  const defaultNearGirtTypeOption = bds1 ? { value: bds1.near_site_girt_type, label: bds1.near_site_girt_type } : null
  const defaultRightGirtTypeOption = bds1 ? { value: bds1.right_end_girt_type, label: bds1.right_end_girt_type } : null
  const defaultFarGirtTypeOption = bds1 ? { value: bds1.far_side_girt_type, label: bds1.far_side_girt_type } : null
  
  const roofProfileOptions = useMemo(() => (
    CRM_PIF_ROOF_PROFILE.map((option) => ({value: option, label: option}))
  ))
  const defaultRoofProfileOption = bds1 ? { value: bds1.roof_profile, label: bds1.roof_profile } : null
  const defaultWallProfileOption = bds1 ? { value: bds1.wall_profile, label: bds1.wall_profile } : null
  
  const colorOptions = useMemo(() => (
    CRM_PIF_COLOR.map((option) => ({value: option, label: option}))
  ))
  const defaultRoofColorOption = bds1 ? { value: bds1.roof_color, label: bds1.roof_color } : null
  const defaultWallColorOption = bds1 ? { value: bds1.wall_color, label: bds1.wall_color } : null
  const defaultWallTrimColorGutterOption = bds1 ? { value: bds1.wall_trim_colors, label: bds1.wall_trim_colors } : null
  const defaultWallTrimColorOtherOption = bds1 ? { value: bds1.wall_trim_colors_others, label: bds1.wall_trim_colors_others } : null
  
  const roofInsulationOptions = useMemo(() => (
    CRM_PIF_ROOF_NAME.map((option) => ({value: option, label: option}))
  ))
  const defaultRoofNameOption = bds1 ? { value: bds1.roof_name, label: bds1.roof_name } : null
  const defaultWallNameOption = bds1 ? { value: bds1.wall_name, label: bds1.wall_name } : null

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <>
      <Collapse in={true}>
        <Accordion
          id="revision-table"
          className="border rounded overflow-hidden fs--1"
          alwaysOpen
          flush
          activeKey={activeKey}
          onSelect={handleAccordionSelect}
        >
          <Card className="rounded-0">
            <Accordion.Item eventKey="0" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      1. General Information
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <BDSGeneralInformation data={data} />
                  <h6 className="text-center my-2">This area only : Page 1 of 3</h6>
                  <Row className="mb-2 mt-4 gx-1">
                    <Col sm={5}>
                      <Row className="gx-2 mb-2">
                        <Col sm={4}>Customer name:</Col>
                        <Col sm="auto" className="fw-semi-bold text-primary">{project?.customer?.name}</Col>
                      </Row>
                      <Row className="gx-2 mb-2">
                        <Col sm={4}>Project name:</Col>
                        <Col sm="auto" className="fw-semi-bold text-primary">{project?.name}</Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Building usage:</Col>
                        <Col sm="auto" className="fw-semi-bold">
                          <Form.Control
                            name={`bds1_building_usage_${area.id}`}
                            placeholder="Building usage"
                            className="fs--1"
                            {...register(`bds1_building_usage_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={7}> 
                      <Row className="gx-1 mb-2">
                        <Col sm="auto">Location:</Col>
                        <Col className="fw-semi-bold text-primary">{building?.pj_location}</Col>
                      </Row>
                      <Row className="gx-1 mb-2 align-items-center">
                        <Col sm="auto">Qty of Identical Bldgs:</Col>
                        <Col className="fw-semi-bold">
                          <Form.Control
                            type="number"
                            name={`bds1_qty_of_identical_bldgs_${area.id}`}
                            placeholder="Qty of Identical Bldgs"
                            className="fs--1"
                            {...register(`bds1_qty_of_identical_bldgs_${area.id}`)}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-1 mb-2 align-items-center">
                        <Col sm="auto">Estimated No. of trailers/ containers for all identical bldgs:</Col>
                        <Col className="fw-semi-bold">
                          <Form.Control
                            type="number"
                            name={`bds1_estimated_no_trailed_${area.id}`}
                            placeholder="Estimated No. of trailers"
                            className="fs--1"
                            {...register(`bds1_estimated_no_trailed_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 2. Loads (kN/m2) */}
            <Accordion.Item eventKey="1" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      2. Loads (kN/m2)
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm={5}>
                      <Row className="gx-0 mb-2 align-items-center">
                        <Col sm={5}>Live (On Purlins):</Col>
                        <Col>
                          <Flex>
                            <Form.Control
                              name={`bds1_loads_live_on_purlins_${area.id}`}
                              placeholder="Live (On Purlins)"
                              className="fs--1"
                              {...register(`bds1_loads_live_on_purlins_${area.id}`)}
                            />
                            <div className="ms-1">(KN/m2)</div>
                          </Flex>
                        </Col>
                      </Row>
                      <Row className="gx-0 mb-2 align-items-center">
                        <Col sm={5}>On Frames:</Col>
                        <Col>
                          <Flex>
                            <Form.Control
                              name={`bds1_loads_on_frame_${area.id}`}
                              placeholder="On Frames"
                              className="fs--1"
                              {...register(`bds1_loads_on_frame_${area.id}`)}
                            />
                            <div className="ms-1">(KN/m2)</div>
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={5}>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={5}>Collateral on Frame:</Col>
                        <Col>
                          <Flex>
                            <Form.Control
                              name={`bds1_loads_collateral_on_frame_${area.id}`}
                              placeholder="Collateral on Frame"
                              className="fs--1"
                              {...register(`bds1_loads_collateral_on_frame_${area.id}`)}
                            />
                            <div className="ms-1">(KN/m2)</div>
                          </Flex>
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={5}>Collateral on Roof:</Col>
                        <Col>
                          <Flex>
                            <Form.Control
                              name={`bds1_loads_collateral_on_roof_${area.id}`}
                              placeholder="Collateral on Roof"
                              className="fs--1"
                              {...register(`bds1_loads_collateral_on_roof_${area.id}`)}
                            />
                            <div className="ms-1">(KN/m2)</div>
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={2}>
                      <div className="mt-3">
                        <Controller
                          control={control}
                          name={`bds1_loads_seismic_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Seismic"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2 align-items-center">
                    <Col>
                      <Row className="gx-0 mb-2 align-items-center">
                        <Col sm={5}>Wind Speed (KMH):</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_loads_wind_speed_${area.id}`}
                            placeholder="Wind Speed (KMH)"
                            className="fs--1"
                            {...register(`bds1_loads_wind_speed_${area.id}`)}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-0 mb-2 align-items-center">
                        <Col sm={5}>Recurrence time:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_loads_recurrence_time_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={loadsRecurrenceTimeOptions}
                                defaultValue={defaultLoadsRecurrenceTimeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue(`bds1_loads_recurrence_time_${area.id}`, selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                          />

                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-2 align-items-center">
                        <Col sm={3}>Explosure:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_loads_explosure_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={loadsExplosureOptions}
                                defaultValue={defaultLoadsExplosureOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_loads_explosure_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2 align-items-center">
                        <Col sm={3}>Rainfall:</Col>
                        <Col>
                          <Flex alignItems="center">
                            <Form.Control
                              name={`bds1_loads_rainfall_${area.id}`}
                              placeholder="Rainfall"
                              className="fs--1"
                              {...register(`bds1_loads_rainfall_${area.id}`)}
                            />
                            <div className="ms-1">mm/hr</div>
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="gx-2 mb-2 align-items-center">
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm="auto">Description of collateral load:</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_loads_description_${area.id}`}
                            placeholder="Description of collateral load"
                            className="fs--1"
                            {...register(`bds1_loads_description_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm="auto">Occupancy category:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_loads_occupancy_category_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={loadsOccupancyCategoryOptions}
                                defaultValue={defaultLoadsOccupancyCategoryOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_loads_occupancy_category_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5}>
                      <Row className="gx-2">
                        <Col sm="auto" className="fw-semi-bold">Building Code:</Col>
                        <Col>
                          <Flex justifyContent="between">
                            <Controller
                              control={control}
                              name={`bds1_loads_building_code_mbma_2012_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="MBMA 2012"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds1_loads_building_code_ibc_2018_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="IBC 2018"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={7}>
                      <Row className="gx-2">
                        <Col sm="auto" className="fw-semi-bold">Design Standard:</Col>
                        <Col>
                          <Flex justifyContent="between">
                            <Controller
                              control={control}
                              name={`bds1_loads_design_standard_aisc_asd89_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="AISC-ASD89"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds1_loads_design_standard_aisc_360_16_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="AISC360-16"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name={`bds1_loads_design_standard_aisi_2001_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="AISI 2001"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="gx-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_loads_building_code_others_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="40vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />     
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_loads_design_standard_others_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="40vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />     
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 3. Grade (kN/m2) */}
            <Accordion.Item eventKey="2" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      3. Grade (kN/m2)
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="gx-2 mb-2 align-items-center">
                    <Col sm={3}>Primary member:</Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_grade_primary_member_${area.id}`}
                        render={() => (
                          <Select
                            closeMenuOnSelect={true}
                            options={gradePrimaryMemberOptions}
                            defaultValue={defaultGradePrimaryMemberOption}
                            placeholder='Select...'
                            classNamePrefix="react-select"
                            onChange={selectedOption => setValue(`bds1_grade_primary_member_${area.id}`, selectedOption.value, {shouldValidate: true})}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="gx-2 mb-2 align-items-center">
                    <Col sm={3}>Secondary member:</Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_grade_secondary_member_${area.id}`}
                        render={() => (
                          <Select
                            closeMenuOnSelect={true}
                            options={gradeSecondaryMemberOptions}
                            defaultValue={defaultGradeSecondaryMemberOption}
                            placeholder='Select...'
                            classNamePrefix="react-select"
                            onChange={selectedOption => setValue(`bds1_grade_secondary_member_${area.id}`, selectedOption.value, {shouldValidate: true})}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name={`bds1_grade_note_${area.id}`}
                        placeholder="Specify note..."
                        className="fs--1"
                        {...register(`bds1_grade_note_${area.id}`)}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 4. Building Parameters */}
            <Accordion.Item eventKey="3" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      4. Building Parameters
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2 gx-2">
                    <Col sm={4}>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Frame Type:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_building_parameters_frame_type_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={frameTypeOptions}
                                defaultValue={defaultFrameTypeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_building_parameters_frame_type_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={3}>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={3}>Slope:</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_building_parameters_slope_${area.id}`}
                            placeholder="Slope"
                            className="fs--1"
                            {...register(`bds1_building_parameters_slope_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={5}>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={3}>Height (M):</Col>
                        <Col>
                          <Row className="gx-1">
                            <Col sm={6}>
                              <Form.Control
                                name={`bds1_building_parameters_height_other_${area.id}`}
                                placeholder="Height (M)"
                                className="fs--1"
                                {...register(`bds1_building_parameters_height_other_${area.id}`)}
                              />
                            </Col>
                            <Col>
                              <Controller
                                control={control}
                                name={`bds1_building_parameters_height_${area.id}`}
                                render={() => (
                                  <Select
                                    closeMenuOnSelect={true}
                                    options={buildingHeightOptions}
                                    defaultValue={defaultBuildingHeightOption}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    onChange={selectedOption => setValue(`bds1_building_parameters_height_${area.id}`, selectedOption.value, {shouldValidate: true})}
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Bldg Width (M):</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_building_parameters_bldgs_width_${area.id}`}
                            placeholder="Bldg Width (M)"
                            className="fs--1"
                            {...register(`bds1_building_parameters_bldgs_width_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Width modules:</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_building_parameters_width_module_${area.id}`}
                            placeholder="Width modules"
                            className="fs--1"
                            {...register(`bds1_building_parameters_width_module_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3 g-0 align-items-center">
                    <Col sm={2}>Length (M):</Col>
                    <Col>
                      <Form.Control
                        name={`bds1_building_parameters_length_${area.id}`}
                        placeholder="Length (M)"
                        className="fs--1"
                        {...register(`bds1_building_parameters_length_${area.id}`)}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Bay Spacing (M):</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_building_parameters_bay_spacing_${area.id}`}
                            placeholder="Bay Spacing (M)"
                            className="fs--1"
                            {...register(`bds1_building_parameters_bay_spacing_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Columns support:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_building_parameters_column_support_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={buildingColumnSupportOptions}
                                defaultValue={defaultBuildingColumnSupportOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                onChange={selectedOption => setValue(`bds1_building_parameters_column_support_${area.id}`, selectedOption.value, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_building_parameters_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />     
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 5. Endwall Condition */}
            <Accordion.Item eventKey="4" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      5. Endwall Condition
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Left End Wall Frame:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_left_end_wall_frame_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={wallFrameOptions}
                                defaultValue={defaultLeftEndWallFrameOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_left_end_wall_frame_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Right End Wall Frame:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_right_end_wall_frame_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={wallFrameOptions}
                                defaultValue={defaultRightWallFrameOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_right_end_wall_frame_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Left End Girt type:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_lef_end_girt_type_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={girtTypeOptions}
                                defaultValue={defaultLeftGirtTypeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_lef_end_girt_type_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Near Side Girt type:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_near_site_girt_type_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={girtTypeOptions}
                                defaultValue={defaultNearGirtTypeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_near_site_girt_type_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                    <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Wind Col. Spacing (M):</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_wind_col_spacing_left_${area.id}`}
                            placeholder="Wind Col. Spacing (M)"
                            className="fs--1"
                            {...register(`bds1_wind_col_spacing_left_${area.id}`)}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Wind Col. Spacing (M):</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_wind_col_spacing_right_${area.id}`}
                            placeholder="Wind Col. Spacing (M)"
                            className="fs--1"
                            {...register(`bds1_wind_col_spacing_right_${area.id}`)}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Right End Girt type:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_right_end_girt_type_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={girtTypeOptions}
                                defaultValue={defaultRightGirtTypeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_right_end_girt_type_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2 mb-2 align-items-center">
                        <Col sm={4}>Far Side Girt type:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_far_side_girt_type_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={girtTypeOptions}
                                defaultValue={defaultFarGirtTypeOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_far_side_girt_type_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_endwall_condition_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />     
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/*6. Open walls lengths are measured from corner of NSW & LEW  */}
            <Accordion.Item eventKey="5" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      6. Open walls lengths are measured from corner of NSW & LEW 
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Flex justifyContent="end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-2"
                      iconAlign="middle"
                      className="me-1"
                      onClick={() => newOpenWall(areaId)}
                    >
                      <span className="d-none d-xl-inline-block ms-1">New Open Wall</span>
                    </IconButton>
                  </Flex>

                  <div className="table-responsive fs--1 mt-2">
                    <Table striped>
                      <thead className="bg-200 text-900">
                        <tr className="text-center" style={{height: "10px"}}>
                          <th rowSpan={2}></th>
                          <th className="border" rowSpan={2}>Location</th>
                          <th className="border" colSpan={3}>Length (mm)</th>
                          <th className="border" rowSpan={2}>Height (m)</th>
                          <th className="border" rowSpan={2}>Open for</th>
                          <th className="border" rowSpan={2}>Bracing Type</th>
                        </tr>
                        <tr className="text-center">
                          <th className="border">Length</th>
                          <th className="border">From</th>
                          <th className="border">To</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openWallData && openWallData.filter(item => item.area_id === area.id).map((item, index) => (
                          <tr key={index}>
                            <td className="border">
                              <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border py-0">
                                  <div className="py-2">
                          
                                    <Dropdown.Item onClick={() => editOpenWall(areaId, item)} className="mb-1">
                                      <FontAwesomeIcon icon="edit" /> Edit
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item className="mb-1" onClick={() => deleteOpenWall(areaId, item)}>
                                      <FontAwesomeIcon icon="trash-alt" /> Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="border">{item.location}</td>
                            <td className="border">{item.length}</td>
                            <td className="border">{item.length_from}</td>
                            <td className="border">{item.length_to}</td>
                            <td className="border">{item.height}</td>
                            <td className="border">{item.open_for}</td>
                            <td className="border">{item.bracing_type}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="mt-3 mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_open_walls_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 7. Roof */}
            <Accordion.Item eventKey="6" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      7. Roof
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm={2}>
                      <Controller
                        control={control}
                        name={`bds1_roof_by_pebsteel_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="by PEB Steel"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col ms={10}>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Panel is:</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_roof_panel_is_${area.id}`}
                            placeholder="Panel is"
                            className="fs--1"
                            {...register(`bds1_roof_panel_is_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Profile:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_profile_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={roofProfileOptions}
                                defaultValue={defaultRoofProfileOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_roof_profile_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={2}>
                      <Controller
                        control={control}
                        name={`bds1_roof_by_others_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="by Others"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={10}>
                      <Row className="gx-3 align-items-center">
                        <Col sm="auto">Color:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_color_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={colorOptions}
                                defaultValue={defaultRoofColorOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_roof_color_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="border py-2">
                    <Col sm={2}>Insulation</Col>
                    <Col sm={10}>
                      <Row className="mb-3">
                        <Col sm={8}>
                          <div>
                            <Controller
                              control={control}
                              name={`bds1_roof_insulation_by_pebsteel_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="by PEB Steel"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                          <Row>
                            <Col>
                              <Controller
                                control={control}
                                name={`bds1_roof_name_${area.id}`}
                                render={() => (
                                  <Select
                                    closeMenuOnSelect={true}
                                    options={roofInsulationOptions}
                                    defaultValue={defaultRoofNameOption}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    isClearable
                                    onChange={selectedOption => setValue(`bds1_roof_name_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                                  />
                                )}
                              />
                            </Col>
                            <Col>
                              <Row className="gx-2 align-items-center">
                                <Col sm="auto">Thickness (mm):</Col>
                                <Col>
                                  <Form.Control
                                    name={`bds1_roof_insulation_thickness_${area.id}`}
                                    placeholder="Thickness (mm)"
                                    className="fs--1"
                                    {...register(`bds1_roof_insulation_thickness_${area.id}`)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4}>
                          <div>
                            <Controller
                              control={control}
                              name={`bds1_roof_insulation_by_others_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="by Others"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                          <Row className="gx-2 align-items-center">
                            <Col sm="auto">Density:</Col>
                            <Col>
                              <Form.Control
                                name={`bds1_roof_insulation_density_${area.id}`}
                                placeholder="Density"
                                className="fs--1"
                                {...register(`bds1_roof_insulation_density_${area.id}`)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_insulation_note_${area.id}`}
                            render={({ field }) => (
                              <TinymceEditor
                                height="30vh"
                                handleChange={field.onChange}
                                value={field.value}
                              />
                            )}
                          /> 
                        </Col>
                      </Row>
                        
                    </Col>
                  </Row>
                  <Row className="border py-2">
                    <Col sm="2">Skylight</Col>
                    <Col>
                      <Row className="gx-2">
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_skylight_by_pebsteel_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="By Pebsteel"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_skylight_single_layer_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Single layer"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_skylight_double_layer_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="Double layers"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          <Controller
                            control={control}
                            name={`bds1_roof_skylight_by_others_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="By Others"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2">
                        <Col>
                          <div>Width (mm):</div>
                          <Form.Control
                            name={`bds1_roof_skylight_width_${area.id}`}
                            placeholder="Width"
                            className="fs--1"
                            {...register(`bds1_roof_skylight_width_${area.id}`)}
                          />
                        </Col>
                        <Col>
                          <div>Length (mm):</div>
                          <Form.Control
                            name={`bds1_roof_skylight_length_${area.id}`}
                            placeholder="Length"
                            className="fs--1"
                            {...register(`bds1_roof_skylight_length_${area.id}`)}
                          />
                        </Col>
                        <Col>
                          <div>Thickness (mm):</div>
                          <Form.Control
                            name={`bds1_roof_skylight_thickness_${area.id}`}
                            placeholder="Thickness"
                            className="fs--1"
                            {...register(`bds1_roof_skylight_thickness_${area.id}`)}
                          />
                        </Col>
                        <Col>
                          <div>Color:</div>
                          <Form.Control
                            name={`bds1_roof_skylight_color_${area.id}`}
                            placeholder="Color"
                            className="fs--1"
                            {...register(`bds1_roof_skylight_color_${area.id}`)}
                          />
                        </Col>
                        <Col sm={4}>
                          <div>Description:</div>
                          <Form.Control
                            name={`bds1_roof_skylight_desciption_${area.id}`}
                            placeholder="Description"
                            className="fs--1"
                            {...register(`bds1_roof_skylight_desciption_${area.id}`)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_roof_skylight_note_${area.id}`}
                            render={({ field }) => (
                              <TinymceEditor
                                height="30vh"
                                handleChange={field.onChange}
                                value={field.value}
                              />
                            )}
                          /> 
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 8. Wall */}
            <Accordion.Item eventKey="7" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      8. Wall
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm={2}>
                      <Controller
                        control={control}
                        name={`bds1_wall_by_pebsteel_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="by PEB Steel"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col ms={10}>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Panel is:</Col>
                        <Col>
                          <Form.Control
                            name={`bds1_wall_panel_is_${area.id}`}
                            placeholder="Panel is"
                            className="fs--1"
                            {...register(`bds1_wall_panel_is_${area.id}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="gx-2 align-items-center">
                        <Col sm="auto">Profile:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_wall_profile_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={roofProfileOptions}
                                defaultValue={defaultWallProfileOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_wall_profile_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={2}>
                      <Controller
                        control={control}
                        name={`bds1_wall_by_others_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="by Others"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={10}>
                      <Row className="gx-3 align-items-center">
                        <Col sm="auto">Color:</Col>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_wall_color_${area.id}`}
                            render={() => (
                              <Select
                                closeMenuOnSelect={true}
                                options={colorOptions}
                                defaultValue={defaultWallColorOption}
                                placeholder='Select...'
                                classNamePrefix="react-select"
                                isClearable
                                onChange={selectedOption => setValue(`bds1_wall_color_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="border py-2">
                    <Col sm={2}>Insulation</Col>
                    <Col sm={10}>
                      <Row className="mb-3">
                        <Col sm={8}>
                          <div>
                            <Controller
                              control={control}
                              name={`bds1_wall_insulation_by_pebsteel_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="by PEB Steel"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                          <Row>
                            <Col>
                              <Controller
                                control={control}
                                name={`bds1_wall_name_${area.id}`}
                                render={() => (
                                  <Select
                                    closeMenuOnSelect={true}
                                    options={roofInsulationOptions}
                                    defaultValue={defaultWallNameOption}
                                    placeholder='Select...'
                                    classNamePrefix="react-select"
                                    isClearable
                                    onChange={selectedOption => setValue(`bds1_wall_name_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                                  />
                                )}
                              />
                            </Col>
                            <Col>
                              <Row className="gx-2 align-items-center">
                                <Col sm="auto">Thickness (mm):</Col>
                                <Col>
                                  <Form.Control
                                    name={`bds1_wall_insulation_thickness_${area.id}`}
                                    placeholder="Thickness (mm)"
                                    className="fs--1"
                                    {...register(`bds1_wall_insulation_thickness_${area.id}`)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4}>
                          <div>
                            <Controller
                              control={control}
                              name={`bds1_wall_insulation_by_others_${area.id}`}
                              render={({ field }) => (
                                <Form.Check
                                  type="checkbox"
                                  label="by Others"
                                  checked={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                          <Row className="gx-2 align-items-center">
                            <Col sm="auto">Density:</Col>
                            <Col>
                              <Form.Control
                                name={`bds1_wall_insulation_density_${area.id}`}
                                placeholder="Density"
                                className="fs--1"
                                {...register(`bds1_wall_insulation_density_${area.id}`)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_wall_insulation_note_${area.id}`}
                            render={({ field }) => (
                              <TinymceEditor
                                height="30vh"
                                handleChange={field.onChange}
                                value={field.value}
                              />
                            )}
                          /> 
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="border py-2">
                    <Col sm="2">Wall light</Col>
                    <Col>
                      <Row className="gx-2">
                        <Col sm={8}>
                          <Controller
                            control={control}
                            name={`bds1_wall_light_by_pebsteel_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="By Pebsteel"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          <Controller
                            control={control}
                            name={`bds1_wall_light_by_others_${area.id}`}
                            render={({ field }) => (
                              <Form.Check
                                type="checkbox"
                                label="By Others"
                                checked={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="gx-2">
                        <Col>
                          <div>Width (mm):</div>
                          <Form.Control
                            name={`bds1_wall_light_width_${area.id}`}
                            placeholder="Width"
                            className="fs--1"
                            {...register(`bds1_wall_light_width_${area.id}`)}
                          />
                        </Col>
                        <Col>
                          <div>Length (mm):</div>
                          <Form.Control
                            name={`bds1_wall_light_length_${area.id}`}
                            placeholder="Length"
                            className="fs--1"
                            {...register(`bds1_wall_light_length_${area.id}`)}
                          />
                        </Col>
                        <Col>
                          <div>Thickness (mm):</div>
                          <Form.Control
                            name={`bds1_wall_light_thickness_${area.id}`}
                            placeholder="Thickness"
                            className="fs--1"
                            {...register(`bds1_wall_light_thickness_${area.id}`)}
                          />
                        </Col>
                        <Col>
                          <div>Color:</div>
                          <Form.Control
                            name={`bds1_wall_light_color_${area.id}`}
                            placeholder="Color"
                            className="fs--1"
                            {...register(`bds1_wall_light_color_${area.id}`)}
                          />
                        </Col>
                        <Col sm={4}>
                          <div>Description:</div>
                          <Form.Control
                            name={`bds1_wall_light_description_${area.id}`}
                            placeholder="Description"
                            className="fs--1"
                            {...register(`bds1_wall_light_description_${area.id}`)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Controller
                            control={control}
                            name={`bds1_wall_light_note_${area.id}`}
                            render={({ field }) => (
                              <TinymceEditor
                                height="30vh"
                                handleChange={field.onChange}
                                value={field.value}
                              />
                            )}
                          /> 
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 9. Sheeting Angle, Gable Angle, Eave Angle (If sheeting by others) */}
            <Accordion.Item eventKey="8" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      9. Sheeting Angle, Gable Angle, Eave Angle (If sheeting by others)
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col sm={8}>
                      <Controller
                        control={control}
                        name={`bds1_sheeting_angle_by_pebsteel_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="By Pebsteel"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={4}>
                      <Controller
                        control={control}
                        name={`bds1_sheeting_angle_by_others_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="By Others"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_sheeting_angle_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      /> 
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 10. Eave Condition */}
            <Accordion.Item eventKey="9" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      10. Eave Condition
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                  <Row className="mb-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_gutter_downspouts_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Gutters & Downspouts,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_trim_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Eave Trim,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row className="mb-2 gx-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_curved_projection_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Curved Eave with projection,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_w_o_gutters_downspouts_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="w/o Gutters & Downspouts,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_w_valley_gutters_downspouts_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="w/Valley Gutters & Downspouts,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Flex justifyContent="between">
                        <div>Downspouts</div>
                        <Controller
                          control={control}
                          name={`bds1_eave_condition_downspouts_meta_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Metal"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bds1_eave_condition_downspouts_pvc_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="PVC"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Flex>
                    </Col>
                  </Row>
                  <Row className="gx-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_curved_projection2_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Curved Eave w/o projection,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_w_o_gutters_downspouts2_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="w/o Gutters & Downspouts,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_w_valley_gutters_downspouts2_${area.id}`}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="w/Valley Gutters & Downspouts,"
                            checked={field.value}
                            onChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Flex justifyContent="between">
                        <div>Downspouts</div>
                        <Controller
                          control={control}
                          name={`bds1_eave_condition_downspouts_meta2_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="Metal"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bds1_eave_condition_downspouts_pvc2_${area.id}`}
                          render={({ field }) => (
                            <Form.Check
                              type="checkbox"
                              label="PVC"
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Flex>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Controller
                        control={control}
                        name={`bds1_eave_condition_note_${area.id}`}
                        render={({ field }) => (
                          <TinymceEditor
                            height="30vh"
                            handleChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      /> 
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>

{/* 11. Wall Trim Colors */}
            <Accordion.Item eventKey="10" className="border-0">
              <Card.Header className="p-0">
                <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 shadow-none">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon="caret-right"
                      className="accordion-icon me-3 mt-1"
                      transform="shrink-2"
                    />
                    <span className="fw-medium font-sans-serif text-900 text-primary">
                      11. Wall Trim Colors
                    </span>
                  </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="m-2">
                 <Row className="mb-3">
                  <Col sm={4}>
                    <div>Color of gutters , down spouts , Eave trim :</div>
                    <Controller
                      control={control}
                      name={`bds1_wall_trim_colors_${area.id}`}
                      render={() => (
                        <Select
                          closeMenuOnSelect={true}
                          options={colorOptions}
                          defaultValue={defaultWallTrimColorGutterOption}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          isClearable
                          onChange={selectedOption => setValue(`bds1_wall_trim_colors_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                        />
                      )}
                    /> 
                  </Col>
                  <Col sm={8}>
                    <Controller
                      control={control}
                      name={`bds1_wall_trim_colors_gutters_note_${area.id}`}
                      render={({ field }) => (
                        <TinymceEditor
                          height="30vh"
                          handleChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    /> 
                  </Col>
                 </Row>
                 <Row>
                  <Col sm={4}>
                    <div>Color of other flashing, trim:</div>
                    <Controller
                      control={control}
                      name={`bds1_wall_trim_colors_others_${area.id}`}
                      render={() => (
                        <Select
                          closeMenuOnSelect={true}
                          options={colorOptions}
                          defaultValue={defaultWallTrimColorOtherOption}
                          placeholder='Select...'
                          classNamePrefix="react-select"
                          isClearable
                          onChange={selectedOption => setValue(`bds1_wall_trim_colors_others_${area.id}`, selectedOption ? selectedOption.value : null, {shouldValidate: true})}
                          menuPortalTarget={document.body}
                        />
                      )}
                    /> 
                  </Col>
                  <Col sm={8}>
                    <Controller
                      control={control}
                      name={`bds1_wall_trim_colors_flashing_note_${area.id}`}
                      render={({ field }) => (
                        <TinymceEditor
                          height="30vh"
                          handleChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    /> 
                  </Col>
                 </Row>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        </Accordion>
      </Collapse>
  </>
  )
};

BDS1.propTypes = {
  data: PropTypes.object.isRequired,
  openWallData: PropTypes.object.isRequired,
  newOpenWall: PropTypes.func.isRequired,
  editOpenWall: PropTypes.func.isRequired,
  deleteOpenWall: PropTypes.func.isRequired
}

export default BDS1;