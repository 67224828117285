import { useDispatch, useSelector } from "react-redux";
import React, {useEffect } from "react";
import { useParams } from "react-router-dom";
import LeadDetailHeader from "./detail/LeadDetailHeader";
import { Badge, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SimpleBar from "simplebar-react";
import { MdHistory} from "react-icons/md";
import { FaLeaf, FaRegListAlt, FaUser } from "react-icons/fa";
import { getLead } from "redux/crm/leadSlice";
import LeadStep from "./detail/LeadStep";
import LeadActivity from "./detail/LeadActivity";
import RatingScore from "./component/RatingScore";

const LeadDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {recordData: lead} = useSelector(state => state.crm_lead);

  const status = lead?.status

  useEffect(() => {
    dispatch(getLead(params.id))
  }, [params])

  return (
    <>
      <LeadDetailHeader lead={lead} />

      <Card className="mb-3 p-4 fs--1">
        <Row className="gx-2">
          <Col sm={6}>
            <div>
              <FaLeaf />
              <span className="fs-1 fw-semi-bold text-primary ms-1">{lead?.name}</span>
            </div>
            <div>Status: <Badge pill bg="primary">{status?.name}</Badge> / <Badge pill bg="info">{lead.stage}</Badge> / <span className="text-primary">{lead.mql_status}</span> / Rating: <RatingScore rating={lead.rating} /> </div>
            <div className="small">Created by: <strong>{lead.created_by}</strong>, Date: {lead.created_date}</div>
          </Col>
          <Col>
            Account name:
            <div className="text-primary mt-1 fs--1">{lead.account_name}</div>
          </Col>
          <Col sm={2}>
            <FaUser /> Owner:
            <div className="text-primary fw-bold mt-1 fs--1">{lead.owner_by}</div>
          </Col>
        </Row>
      </Card>

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="lead-detail">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-detail"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaRegListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Detail</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="lead-activities"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdHistory className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Activities</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="lead-detail">
                    <Row>
                      <Col className="mt-0 h-100">
                        <LeadStep lead={lead} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="lead-activities">
                    <LeadActivity lead={lead} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default LeadDetail;