import { useDispatch } from "react-redux"
import { Form, Modal, Button } from "react-bootstrap"
import IconAlert from "components/common/IconAlert"
import { toast } from "react-toastify"
import { recordLead } from "redux/crm/leadSlice"
import { FaRecordVinyl } from "react-icons/fa"
import React from 'react';
import PropTypes from 'prop-types';

const LeadRecordConfirm = ({showModal, setshowModal, lead }) => {
  const leadId = lead.id

  const dispatch = useDispatch()

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }

  const handleSubmit = (data) => {
    try {
      dispatch(recordLead(leadId))
      closeModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Record confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Are you sure record Lead ID#{lead.id} keep in database?
          </IconAlert>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit"><FaRecordVinyl className="text-danger"/> Record</Button>
          <Button size="sm" variant="danger" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

LeadRecordConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired
}

export default LeadRecordConfirm;