import { useLocation, useNavigate } from "react-router-dom";
import generatePDF, {Resolution} from 'react-to-pdf';
import { Card, Spinner } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import Flex from "components/common/Flex";
import GDS from "./print/GDS";
import "../../../assets/scss/print-form.scss";
import BDS1 from "./print/BDS1";
import BDS2 from "./print/BDS2";
import BDS3 from "./print/BDS3";
import { CRM_PIF_ISO } from "app_config";
import { useState } from "react";
import { toast } from "react-toastify";
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import {downloadPIF} from "redux/crm/project/pifSlice";

const HeaderPrint = ({pif, download}) => {
  const navigate = useNavigate();
  const {project} = pif;

  const handleBackToProject = () => {
    navigate(`/crm/projects/${project?.id}`)
  }
  
  const handleDownloadPDF = () => {
    download()
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="download"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleDownloadPDF}
              title="Download"
            >
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="list"
              iconAlign="middle"
              className="d-none d-sm-block me-1"
              onClick={handleBackToProject}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Back to Project
              </span>
            </IconButton>
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
}

HeaderPrint.propTypes = {
  pif: PropTypes.object.isRequired,
  download: PropTypes.func.isRequired
}

const PageBreak = ({ children }) => {
  return (
    <div
      style={{
        pageBreakBefore: "always",
        pageBreakAfter: "always",
        breakBefore: "always", // for newer browsers
        breakAfter: "always",  // for newer browsers
      }}
    >
      {children}
    </div>
  );
};

PageBreak.propTypes = {
  children: PropTypes.object.isRequired
}

const PIFPrint = () => {
  const location = useLocation();
  const { pif } = location.state;
  const {project, building, adaptation} = pif;
  const gds = pif?.gds || {};
  const areas = pif?.areas || [];

  const [showExporting, setExporting] = useState(false);
  const dispatch = useDispatch();
        
  // const handleDownload = () => {
  //   setExporting(true)
  //   generatePDF(getTargetElement, options)
  //     .then(() => {
  //       setExporting(false);
  //     })
  //     .catch(error => {
  //       console.error('Error generating PDF:', error);
  //       toast.error('Error generating PDF:', error)
  //       setExporting(false)
  //     })
  // }

  const handleDownload = async () => {
    try {
      setExporting(true)
      await dispatch(downloadPIF({
        projectId: project.id,
        buildingId: building.id,
        adaptationId: adaptation.id,
        pifId: pif.id
      })).unwrap();
    }
    catch(error) {
      toast.error(error.message);
    }
    finally {
      setExporting(false)
    }
  }

  const jNumberPrefix = adaptation?.type === 'change_order' ? `${building.j_number} ${project.q_number} CO#${adaptation.co}` : `${project.q_number} REV#${adaptation.rev}`;
  const pifName = `PIF ${jNumberPrefix} ${building.name}.pdf`;

  const options = {
    filename: pifName,
    // default is `save`
    //method: "open",
    resolution: Resolution.NORMAL,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: 20,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'landscape',
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'application/pdf',
      qualityRatio: 1
    },
    overrides: {
      pdf: {
        compress: true
      },
      canvas: {
        useCORS: true
      }
    },
  }

  const getTargetElement = () => document.getElementById('pif-form');

  return (
    <Card className="m-2">
      <HeaderPrint pif={pif} download={handleDownload} />

      {showExporting && (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" role="status" variant="primary" className="me-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner> Downloading...
        </div>
      )}

      <div id="pif-form" className="m-4 print-form responsive-table text-1000">
        <GDS pif={pif} />
        Execution stage
        {areas && areas.map(item => (
          <div key={item.id}>
            <PageBreak>
              <BDS1 pif={pif} area={item} />
            </PageBreak>
            <PageBreak>
              <BDS2 pif={pif} area={item} />
            </PageBreak>
            <PageBreak>
              <BDS3 pif={pif} area={item} />
            </PageBreak>
          </div>
        ))}

        <div className="my-2 fs--1">{CRM_PIF_ISO}</div>
      </div>
    </Card>
  )
}

export default PIFPrint;