import { Row, Col, Card } from "react-bootstrap";
import TypeOfSalesChart from "./type_of_sales/TypeOfSalesChart";
import TypeOfSalesTable from "./type_of_sales/TypeOfSalesTable";
import Flex from "components/common/Flex";
import IconAlert from "components/common/IconAlert";
import React from 'react';
import PropTypes from 'prop-types';

const JobEntryTypeOfSales = ({data}) => {
  const {general, domestic, oversea} = data || {};

  return (
    <>
      <Row className="mb-3 g-3">
        <Col>
          <Card className="h-100">
            <Card.Body>
              <h5>Summary jobs entered</h5>
              <TypeOfSalesChart data={general} />
              <Row className="gx-2">
                <Col md={6}>
                  <TypeOfSalesTable data={general} />
                </Col>
                <Col >
                  <IconAlert variant="warning" className="m-3">
                    <p className="mb-0 fs--1">Jobs (J) is including DP received, bank guarantee letter of credit, contract sign, contract with have Pending or PO.</p>
                  </IconAlert>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3 g-3">
        <Col>
          <Card className="mb-3 h-100">
            <Card.Body>
              <h5>Jobs entered by Domestic</h5>
              <TypeOfSalesChart data={domestic} />
              <Flex justifyContent="end">
                <TypeOfSalesTable data={domestic} />
              </Flex>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="h-100">
            <Card.Body>
              <h5>Jobs entered by Oversea</h5>
              <TypeOfSalesChart data={oversea} />
              <Flex justifyContent="end">
                <TypeOfSalesTable data={oversea} />
              </Flex>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
};

JobEntryTypeOfSales.propTypes = {
  data: PropTypes.shape({
    general: PropTypes.object,
    domestic: PropTypes.object,
    oversea: PropTypes.object
  }).isRequired
};

export default JobEntryTypeOfSales;