import React from 'react';
import { useLocation } from "react-router-dom"
import BookingForm from "./BookingForm"

const EditTravelBooking = () => {
  const location = useLocation()
  const booking = location.state

  return (
    <BookingForm title="Edit travel request" booking={booking} />
  )
}

export default EditTravelBooking;