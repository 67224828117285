import { Row, Col, Card } from "react-bootstrap";
import RoleDetailInfoPermissionList from "./RoleDetailInfoPermissionList";
import RoleDetailInfoPermissionAdd from "./RoleDetailInfoPermissionAdd";
import React from "react";

const RoleDetailInfoPermission = () => {
  return (
    <>
      <h6 className="fw-bold text-600">Assigning permissions to role</h6>
      <Row className="fs--1 gx-3">
        <Col sm={5}>
          <Card className="h-100">
            <Card.Body>
              <RoleDetailInfoPermissionAdd />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={7}>
          <Card>
            <Card.Body>
              <RoleDetailInfoPermissionList />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default RoleDetailInfoPermission