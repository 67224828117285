import React from "react";
import { useLocation } from "react-router-dom";
import LeadForm from "./LeadForm";

const EditLead = () => {
  const location = useLocation()
  const {lead} = location.state

  return (
    <LeadForm 
      lead={lead}
      title="Edit Lead"
    />
  )
}

export default EditLead;