import { useParams } from "react-router-dom";
import UserDetailHeader from "./UserDetailHeader";
import UserDetailInfo from "./UserDetailInfo";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, unlockUser } from "redux/authorization/userSlice";
import { Button, Card, Nav, Tab } from "react-bootstrap";
import UserActivity from "./UserActivity";
import UserDetailAuthorization from "./UserDetaillAuthorization";
import { FaHistory, FaInfoCircle, FaUnlock, FaUserShield } from "react-icons/fa";
import IconAlert from "components/common/IconAlert";

const UserDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {recordData: user} = useSelector((state) => state.authorization_user);
  const userId = params.id;

  //const confirmed = user?.confirmed;
  const activated = user?.active || false;
  const locked = user?.access_locked && activated;
  
  useEffect(() => {
    dispatch(getUser(userId))
  }, [dispatch, userId]);

  const handleUnlockUser = () => {
    dispatch(unlockUser(userId))
  }

  return (
    <>
      <UserDetailHeader user={user} />

      <div className="fs--1">
        {/* {!confirmed && (
          <IconAlert variant="warning" dismissible>
            <p className="mb-0">Waiting for user confirmation before signing in to the system!</p>
          </IconAlert>
        )} */}

        {!activated && (
          <IconAlert variant="warning" dismissible>
            <p className="mb-0">The user has been deactivated!</p>
          </IconAlert>
        )}

        {locked && (
          <IconAlert variant="warning" dismissible>
            <p className="mb-0">User has been locked due to incorrect password input 5 times! <Button size="sm" variant="warning" onClick={handleUnlockUser}><FaUnlock /> Unlock now!</Button></p>
          </IconAlert>
        )}
      </div>

      <Card>
        <Tab.Container defaultActiveKey="User-detail">
          <Card.Header className="p-0 border-bottom">
            <Nav className="nav-tabs border-0 flex-nowrap tab-User-details">
              <Nav.Item>
                <Nav.Link
                  eventKey="User-detail"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FaInfoCircle className="text-600 tab-icon" />
                  <h6 className="text-600 mb-0 ms-1">Detail</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="User-authorization"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FaUserShield  className="text-600" />
                  <h6 className="text-600 mb-0 ms-1">Authorization</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="User-activity-log"
                  className="d-flex align-items-center py-3 px-x1 mb-0"
                >
                  <FaHistory  className="text-600" />
                  <h6 className="text-600 mb-0 ms-1">Activities history</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="bg-light">
            <Tab.Content>
              <Tab.Pane eventKey="User-detail">
                <UserDetailInfo user={user} />
              </Tab.Pane>
              <Tab.Pane eventKey="User-authorization">
                <UserDetailAuthorization user={user} />
              </Tab.Pane>
              <Tab.Pane eventKey="User-activity-log">
                <UserActivity />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  )
};

export default UserDetail;