import PropTypes from 'prop-types';
import React from 'react';
import SocialAuthButtons from "./SocialAuthButtons";
import PrivacyPolicy from './PrivacyPolicy';

const LoginForm = () => {
  return (
    <div>
      <SocialAuthButtons />
      <PrivacyPolicy />
    </div>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
