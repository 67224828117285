import React from "react";
import logo from "./../../../../../assets/img/pebsteel.png"
import { Card, Image } from "react-bootstrap";
import BookingHeaderPrint from "../print/BookingHeaderPrint";
import { useLocation } from "react-router-dom";
import { formatBookingDate, formatNumber, formatNumberCurrency } from "helpers/utils";
import generatePDF from 'react-to-pdf';
import "./booking-print.scss";
import PropTypes from 'prop-types'

const EmployeeNameComponent = ({ employees }) => (
  <>
    {employees && employees.map((item, index) => (
      <span key={index}>
        {item.employee_name} ({item.title}), 
      </span>
    ))}
  </>
);

EmployeeNameComponent.propTypes = {
  employees: PropTypes.array.isRequired
}

const DepartmentComponent = ({ employees }) => (
  <>
    {employees && 
      Array.from(new Set(employees.map(item => item.department)))
        .map((department, index) => (
          <span key={index}>{department}</span>
      ))
    }
  </>
);

DepartmentComponent.propTypes = {
  employees: PropTypes.array.isRequired
}

const TitleComponent = ({ employees }) => (
  <>
    {employees && employees.map((item, index) => (
      <span key={index}>{item.title}, </span>
    ))}
  </>
);

TitleComponent.propTypes = {
  employees: PropTypes.array.isRequired
}

const PrintBooking = () => {
  const location = useLocation()
  const booking  = location.state
  const { currency } = booking

  const options = {
    filename: `travel-booking-${booking.id}.pdf`,
    // default is `save`
    //method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    //resolution: Resolution.HIGH,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: 20,
       // default is 'A4'
       format: 'A4',
       // default is 'portrait'
       orientation: 'landscape',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/png',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
  }

  const getTargetElement = () => document.getElementById('travel-booking-report');

  const handleDownload = () => {
    generatePDF(getTargetElement, options)
  }
  

  return (
    <Card className="m-2">
      <BookingHeaderPrint booking={booking} download={handleDownload}/>

      <div id="travel-booking-report" className="m-4 text-1000">
        <table className="fs--1">
          <tr>
            <td className="text-center py-4">
              <Image
                fluid
                className="fit-cover rounded"
                src={logo}
                alt="Logo Pebsteel"
                width="150"
                height="150"
              />
            </td>
            <td colSpan="6" className="text-center bg-primary text-white">
              <div className="fs-2 fw-bold mb-2">BUSINESS TRAVELLING APPLICATION</div>
              <div className="fs-0 fw-semi-bold fst-italic">KẾ HOẠCH CÔNG TÁC</div>
            </td>
          </tr>
          <tr>
            <td className="my-1 none-border">Name /<span className="fst-italic">Họ và tên:</span></td>
            <td colSpan="6" className="text-start fw-semi-bold none-border">
              <EmployeeNameComponent employees={booking.employees} />
            </td>
          </tr>
          <tr>
            <td className="my-1 none-border">Department/ <span className="fst-italic">Phòng ban</span>:</td>
            <td colSpan="4" className="text-start fw-semi-bold none-border">
              <DepartmentComponent employees={booking.employees} />
            </td>
            <td colSpan="2" className="my-1 none-border">
              Title/ <span className="fst-italic">Chức vụ</span>:
              <span className="fw-semi-bold">
                <TitleComponent employees={booking.employees} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="pb-6 none-border vertical-top">Objective of trip/ <span className="fst-italic">Mục đích công tác</span>:</td>
            <td colSpan="6" className="text-start none-border vertical-top">
              <div className="fw-bold mb-2">{booking.description_purpose_of_travel}</div>
              <div className="text-danger">
                {booking.follow_project_budget && booking.job_number ? (
                  `Follow the project budget with ${booking.job_number} ($${formatNumber(booking?.project_budget_amount_currency)})`
                ) : (
                  `${booking?.job_number} - charged by ${booking?.site_name}`
                )}
              </div>

              {booking?.project?.customer_name && (
                <div className="mt-1">Customer: <strong>{booking?.project?.customer_name}</strong></div>
              )}

              {booking?.customers_traveler && (
                <div className="mt-1">Customers traveler: <strong>{booking?.customers_traveler?.join(", ")}</strong></div>
              )}

              <div><strong>{booking?.booking_number}</strong></div>
            </td>
          </tr>
          <tr>
            <td className="none-border">Schedule/ <span className="fst-italic">Thời gian</span>: </td>
            <td colSpan="4" className="none-border">From/ <span className="fst-italic">Từ ngày</span>: <strong>{formatBookingDate(booking.actual_date_of_travel)}</strong></td>
            <td colSpan="2" className="text-start none-border">To/ <span className="fst-italic">đến ngày</span>: <strong>{formatBookingDate(booking.actual_date_of_return)}</strong></td>
          </tr>
          <tr>
            <td colSpan="7" className="py-3">{" "}</td>
          </tr>
          <tr className="text-center bg-primary text-white">
            <td className="py-2">
              <div>Destination</div>
              <div className="fst-italic">Nơi đến công tác</div>
            </td>
            <td>
              <div>No. of days</div>
              <div className="fst-italic">Số ngày</div>
            </td>
            <td>
              <div>Air Ticket</div>
              <div className="fst-italic">Vé máy bay</div>
            </td>
            <td>
              <div>Hotel</div>
              <div className="fst-italic">Khách sạn</div>
            </td>
            <td>
              <div>Meal, Transportation & miscellaneous expense</div>
              <div className="fst-italic">Trợ cấp công tác</div>
            </td>
            <td>
              <div>Total</div>
              <div className="fst-italic">Tổng cộng</div>
            </td>
            <td>
              <div>Advance</div>
              <div className="fst-italic">Tạm ứng</div>
            </td>
          </tr>
          <tr className="text-center">
            <td>{booking.destination}</td>
            <td>{booking.number_of_days}</td>
            <td>{formatNumberCurrency(booking.total_estimate_shuttle_cost_currency, currency)}</td>
            <td>{formatNumberCurrency(booking.hotel_cost_currency, currency)}</td>
            <td>{formatNumberCurrency(booking.other_cost_currency, currency)}</td>
            <td>{formatNumberCurrency(booking.total_cost_currency, currency)}</td>
            <td>{formatNumberCurrency(booking.advance_cost_currency, currency)}</td>
          </tr>
          <tr className="text-center fw-semi-bold">
            <td colSpan="5">Total / <span className="fst-italic">Cộng</span></td>
            <td>{formatNumberCurrency(booking.total_cost_currency, currency)}</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="7" className="py-3 text-danger"><strong>No. of days:</strong> actual travel days for business purposes, based on the time of the ticket (air, car, etc.)</td>
          </tr>
          <tr className="text-center py-2 bg-primary text-white">
            <td colSpan="2">Requested by</td>
            <td colSpan="3">
              <div>Reviewed by</div>
              <div>(Line Manager/ HR HO)</div>
            </td>
            <td colSpan="2">
              <div>Approved by</div> 
              <div>(HR HO/ GM/ BOD)</div>
            </td>
          </tr>
          <tr className="text-center">
            <td colSpan="2" className="py-4">
              <div className="mb-1">{booking.submited_by}</div>
              <div>{booking.submited_date}</div>
            </td>
            <td colSpan="3" className="py-4">
              {booking.signature_line_managers && booking.signature_line_managers.map((item) => (
                <>
                  <div className="mb-1">{item.submited_by}</div>
                  <div>{formatBookingDate(item.created_at)}</div>
                </>
              ))}
            </td>
            <td colSpan="2" className="py-4">
              {booking.signature_hr_bods && booking.signature_hr_bods.map((item) => (
                <>
                  <div className="mb-1">{item.submited_by}</div>
                  <div>{formatBookingDate(item.created_at)}</div>
                </>
              ))}
            </td>
          </tr>
        </table>
      </div>

    </Card>
  )
}

export default PrintBooking