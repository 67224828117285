import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllSalesUnits,
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setFilterTag,
  exportSalesUnits
} from "redux/crm/salesUnitSlice";

import AgGridTable from "components/ag-grid/AgGridTable";
import AgPagination from "components/ag-grid/AgPagination";
import { useDispatch } from "react-redux";
import GridFilter from "components/peb-grid/GridFilter";
import FilterAdvanceModal from "./FilterAdvanceModal";
import { PERPAGE_DEFAULT_SM } from "app_config";
import { columnDefs } from "./component/Columns";
import PropTypes from 'prop-types';


const SalesUnitList = ({setSelected}) => {
  const key = "allData";
  const { allData } = useSelector((state) => state.crm_sales_unit);

  const {
    data,
    totalRows,
    pageSize,
    totalPagesCount,
    fromRecord,
    toRecord,
    currentParams,
    filterTags
  } = allData || {};
  
  const dispatch = useDispatch();

  useEffect(() => {
    const params = currentParams ?? { page: 1, per_page: PERPAGE_DEFAULT_SM };
    dispatch(getAllSalesUnits(params));
  }, [currentParams]);

  const handlePageChange = ((page, pageSize) => {
    dispatch(setNestedPage({ key, page }));
    dispatch(setNestedPageSize({ key, pageSize }));

    const newCurrentParams = {...currentParams, page: page, per_page: pageSize};
    console.log(newCurrentParams);
    dispatch(setNestedParams({ key, params: newCurrentParams }));

    dispatch(getAllSalesUnits(newCurrentParams));
  })

  return (
    <>
      <GridFilter
        entryType="all"
        entryData={allData}
        loadApi={getAllSalesUnits}
        currentParams={currentParams}
        entryKey={"allData"}
        setNestedParams={setNestedParams}
        resetNestedParams={resetNestedParams}
        setFilterTag={setFilterTag}
        filterTags={filterTags}
        filterAttributes="id_or_name_or_description_cont"
        exportApi={exportSalesUnits}
        FilterAdvanceComponent={FilterAdvanceModal}
      />

      <AgGridTable
        columnDefs={columnDefs}
        rowData={data}
        gridHeight={'60vh'}
        refData={setSelected}
        selectedMultipe={false}
        serverSide={true}
      />

      <AgPagination
        totalRows={totalRows}
        totalPagesCount={totalPagesCount}
        pageChange={handlePageChange}
        pageSize={pageSize}
        from={fromRecord}
        to={toRecord}
        itemsPages={["50", "100", "200", "500"]}
      />
    </>
  )
};

SalesUnitList.propTypes = {
  setSelected: PropTypes.func.isRequired
}

export default SalesUnitList;