const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaInfoCircle, FaCalendar } from 'react-icons/fa';
import { MdPendingActions } from 'react-icons/md';
import JobEntryDeail from '../job_entry/JobEntryDeail';
import EntryItem from '../components/EntryItem';
import { monthNameMap } from 'helpers/utils';
import React from 'react';
import PropTypes from'prop-types';

const LostJob = ({data=[]}) => {
  const COLOR = "danger";

  const salesRegionData = data.reduce((summary, project) => {
    const { sales_unit_region, est_weight } = project;

    if (!summary[sales_unit_region]) {
        summary[sales_unit_region] = { number_of_projects: 0, weight: 0 };
    }

    summary[sales_unit_region].number_of_projects++;
    summary[sales_unit_region].weight += parseFloat(est_weight) || 0;

    return summary;
  }, {});

  const monthData = data.reduce((summary, project) => {
    const { job_entry_month, est_weight } = project;

    if (!summary[job_entry_month]) {
        summary[job_entry_month] = { number_of_projects: 0, weight: 0 };
    }

    summary[job_entry_month].number_of_projects++;
    summary[job_entry_month].weight += parseFloat(est_weight) || 0;

    return summary;
  }, {});

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMap[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="crm-customer">
        <Card.Header className="bg-light text-primary h5">
          <MdPendingActions /> Job
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Lost Job monthly and sales region for the period.</p>

          <Tabs defaultActiveKey="lost-sales-region" id="lost-quotation-job" transition={false}>
            <Tab eventKey="lost-sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <EntryItem data={salesRegionData} color={COLOR} />
            </Tab>
            <Tab eventKey="lost-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <EntryItem data={convertToMonthNames(monthData)} color={COLOR} />
            </Tab>
            <Tab eventKey="lost-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <JobEntryDeail data={data} />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

LostJob.propTypes = {
  data: PropTypes.object.isRequired
};

export default LostJob;