import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from 'helpers/reducerHelpers';

const departmentUrl = "/organization/departments"

export const getAllDepartments = createAsyncThunk(
  "department/getAllDepartments",
  async (params) => {
    try {
      const response = await apiAxios.get(`${departmentUrl}`, {params})
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getDepartment = createAsyncThunk(
  "department/getDepartment",
  async (departmentId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${departmentUrl}/${departmentId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createDepartment = createAsyncThunk(
  "department/CreateDepartment",
  async (data) => {
    try {
      const response = await apiAxios.post(departmentUrl, data)
      return response.data
    }
    catch(error){
      throw new Error(error.message)
    }
  }
)

export const updateDepartment = createAsyncThunk(
  "department/updateDepartment",
  async ({ departmentId, data }) => {
    try {
      const response = await apiAxios.put(`${departmentUrl}/${departmentId}`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const deleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (departmentId) => {
    try {
      const response = await apiAxios.delete(`${departmentUrl}/${departmentId}`);
      return response.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
);

export const exportDepartments = createAsyncThunk(
  "department/exportDepartments",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${departmentUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'departments.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const departmentSlice = createSlice({
  name: "organization_department",
  initialState: {
    ...initialState
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDepartments.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))

      .addCase(createDepartment.fulfilled, (state, action) => {
        handlePayload(state, action, "create");
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        handlePayload(state, action);
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        handlePayload(state, action, "delete");
      })
      .addCase(exportDepartments.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportDepartments.fulfilled, (state) => {
        state.exporting = false
        toast.success("Department has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
});

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = departmentSlice.actions;

export default departmentSlice.reducer
