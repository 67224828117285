import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog
} from "redux/crm/customerSlice";
import ActivityHistory from "components/peb-grid/ActivityHistory";
import React from 'react';

const CustomerActivity = () => {
  const {activityLogData} = useSelector(state => state.crm_customer);
  const params = useParams();

  return (
    <ActivityHistory
      activityLogData={activityLogData}
      setNestedPage={setNestedPage}
      setNestedPageSize={setNestedPageSize}
      setNestedParams={setNestedParams}
      getActivityLog={getActivityLog}
      customerId={params.id} //dynamic params to activity
    />
  )
}

export default CustomerActivity;