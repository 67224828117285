import React, { useCallback, useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card, Form, InputGroup, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AgPagination from 'components/ag-grid/AgPagination';
import FaqForm from './FaqForm';
import { useSelector } from 'react-redux';
import { getFaqs, setPageSize, setCurrentPage } from 'redux/documentation/faqSlice';
import { useDispatch } from 'react-redux';
import { debounce } from 'helpers/debounce';
import FaqDeleteConfirmation from './FaqDeleteConfirmation';
import FaqItem from './FaqItem';
import { setCurrentParams } from '../../../../../redux/documentation/faqSlice';

const Faq = () => {
  const [filterValue, setFilterValue] = useState('');
  const [showFaqForm, setShowFaqForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [title, setTitle] = useState(null);
  const [faq, setFaq] = useState(null);
  const {paginate, currentParams, data} = useSelector(state => state.documentation_faq);
  const {permissions} = useSelector(state => state.profile_user.data);
  
  const { v305 } = permissions || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaqs(currentParams));
  }, [dispatch, currentParams]);

  const handleMakeFaq = () => {
    setShowFaqForm(true)
    setTitle("New FAQ")
    setFaq(null)
  }

  const handleChangeDebounced = useCallback(
    debounce((value) => {
      const newParams = { ...currentParams, title_cont: value }; // Update the parameter as needed
      dispatch(getFaqs(newParams));
    }, 500), // Set the debounce time (in milliseconds)
    [dispatch, currentParams]
  );

  const handleChange = (e) => {
    const {value} = e.target
    setFilterValue(value)
    handleChangeDebounced(value) //call the debound function
  }

  const handlePageChange = ((page, pageSize) => {
    setPageSize(pageSize)
    setCurrentPage(page)

    const newParams = { ...currentParams, page, per_page: pageSize };
    dispatch(setCurrentParams(newParams));
  })

  const handleEditFaq = (faq) => {
    setShowFaqForm(true)
    setTitle("Edit FAQ")
    setFaq(faq)
  }

  const handleDeleteFaq = (faq) => {
    setShowDeleteConfirmation(true)
    setFaq(faq)
  }

  return (
    <>
      <PageHeader
        title="Frequently asked questions"
        description="Below you'll find answers to the questions we get asked the most about to join with Portal"
        className="mb-3"
      />
      <div className="my-2">
        <Row>
          <Col md={{span: 4, offset: 8}}>
            <InputGroup>
              <Form.Control placeholder="Search..." onChange={handleChange} />
              <Button size="sm" type="submit" className="me-1">
                <FontAwesomeIcon icon="search" className="me-1" />
              </Button>
              {v305 && (
                <Button size="sm" variant="warning" onClick={handleMakeFaq}>
                  <FontAwesomeIcon icon="plus" className="me-1" /> Make FAQ
                </Button>
              )}
            </InputGroup>
          </Col>
        </Row>
      </div>

      <Card>
        <Card.Body>
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            {data.map((faq, index) => (
              <FaqItem
                key={faq.id}
                faq={faq}
                editFaq={() => handleEditFaq(faq)}
                deleteFaq={() => handleDeleteFaq(faq)}
                isFirst={index === 0}
                isLast={index === data.length - 1}
              />
            ))}
          </Accordion>

          <Row className="m-3">
            <Col>
              <AgPagination
                totalRows={paginate.totalRows}
                totalPagesCount={paginate.totalPagesCount}
                pageChange={handlePageChange}
                pageSize={paginate.pageSize}
                from={paginate.fromRecord}
                to={paginate.toRecord}
                //itemsPages={["10", "20", "50"]}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showFaqForm && (
        <FaqForm
          showModal={showFaqForm}
          setshowModal={setShowFaqForm}
          title={title}
          faq={faq}
        />
      )}

      {showDeleteConfirmation && (
        <FaqDeleteConfirmation
          showModal={showDeleteConfirmation}
          setshowModal={setShowDeleteConfirmation}
          faq={faq}
        />
      )}
    </>
  );
};

export default Faq;
