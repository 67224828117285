import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

export const getClassifyCustomers = createAsyncThunk(
  "Customer/getClassifyCustomers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get("/crm/classify_customers", {params})
      return objectSerializer(response.data)
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const classifyCustomerSlice = createSlice({
  name: "classify_customer",
  initialState: {
    data: [],
    totalRows: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: 100,
    fromRecord: 0,
    toRecord: 1,
    loading: false,
    exporting: false,
    filterTags: [],
    currentParams: {},
    filterColumn: "",
    filterColumns: {},
    sortColumn: "",
    sortColumns: {},
    dateColumns: [],
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = parseInt(action.payload)
    },
    addFilterTag: (state, action) => {
      state.filterTags.push(action.payload)
    },
    removeFilterTag: (state, action) => {
      state.filterTags = state.filterTags.filter((tag, index) => index !== action.payload)
    },
    clearAllFilterTag: (state) => {
      state.filterTags = []
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    setFilterColumn: (state, action) => {
      state.filterColumn = action.payload
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClassifyCustomers.fulfilled, (state, action) => {
        if (state.data.length === 0) {
          state.loading = false
          const results = action.payload

          state.data = objectSerializer(results.rows.data)
          state.totalRows = results.total_rows

          const calRecords      = calPaginatorRecord(state.currentPage, state.pageSize, state.totalRows)
          state.totalPagesCount = calRecords.totalPagesCount
          state.fromRecord      = calRecords.beginRecords
          state.toRecord        = calRecords.lastRecords
        }
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  addFilterTag,
  removeFilterTag,
  clearAllFilterTag,
  setCurrentParams,
  setFilterColumn,
  setSortColumn
} = classifyCustomerSlice.actions;

export default classifyCustomerSlice.reducer;