import React, { useEffect } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { useDispatch } from 'react-redux';
import { loginWithGoogle } from 'redux/authSlice';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfileUser } from 'redux/profileUserSlice';
import Flex from 'components/common/Flex';

const SocialAuthButtons = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const from = location?.state?.from || "/";

  // const handleSignInAzure = () => {
  //   alert("Sign in with Microsoft Azure")
  // }

  const handleLogin = async (codeResponse) => {
    try {
      await dispatch(loginWithGoogle(codeResponse));

      //get profile user
      await dispatch(getProfileUser())
      navigate(from);

    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data);
      }
      else {
        toast.error('An error occurred while signing in.')
      }
      //throw error;
    }
  };

  const onErrorCallback = (error) => { 
    toast.error("Login failed.", error);
    throw new Error(error.message);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleLogin,
    onError: onErrorCallback
  });

  useEffect(() => {
    if (googleLogin.token) {
      handleLogin(googleLogin.codeResponse);
    }
  }, [googleLogin.token]);

  return (
    <Form.Group className="mb-0">
      {/* <Row>
        <Col sm={6} className="pe-sm-1">
          <Button
            variant=""
            size="sm"
            className="btn-outline-google-plus mt-2 w-100"
            onClick={handleSignInGoogle}
          >
            <FontAwesomeIcon
              icon={['fab', 'google-plus-g']}
              transform="grow-8"
              className="me-2"
            />{' '}
            Google
          </Button>
        </Col>
        <Col sm={6} className="ps-sm-1">
          <Button
            variant=""
            size="sm"
            className="btn-outline-twitter mt-2 w-100"
            onClick={handleSignInAzure}
          >
            <FontAwesomeIcon
              icon={faWindows}
              transform="grow-8"
              className="me-2"
            />{' '}
            Microsoft Azure
          </Button>
        </Col>
      </Row> */}
      <Row className="mt-2">
        <Col>
          <Button
            variant=""
            //size="sm"
            className="btn btn-outline-primary my-2 w-100"
            onClick={googleLogin}
          >
            <FcGoogle className="me-1" size="20" />
            Sign in with Google
          </Button>
        </Col>
      </Row>
    </Form.Group>
  )
};

export default SocialAuthButtons;
