import React from 'react';
import PropTypes from 'prop-types';

const CheckboxRenderer = (props) => {
  return (
    <div className="text-center">
      <input
        type="checkbox"
        readOnly
        checked={props.value === true}
      />
    </div>
  );
};

CheckboxRenderer.propTypes = {
  value: PropTypes.bool
};

export default CheckboxRenderer;
