import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
//import FalconCardHeader from 'components/common/FalconCardHeader';
import { formatNumberWord, rgbaColor } from 'helpers/utils';
import BasicECharts from 'components/common/BasicEChart';

import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getMonthName = (monthNumber) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber];
}

getMonthName.propTypes = {
  monthNumber: PropTypes.number.isRequired
}

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    formatter: function(params) {
      const date = new Date(params[0].axisValue);
      const formattedDate = `${date.getDate()}-${getMonthName(date.getMonth())}`;
      return formattedDate;
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 2
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('success'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('success'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

getOptions.propTypes = {
  data: PropTypes.array.isRequired
}

const ContractGeneral = ({contractData={}}) => {
  const {current_period, previous_period, data} = contractData;

  const sortedData = data.slice().sort((a, b) => {
    const dateA = new Date(a.contract_date);
    const dateB = new Date(b.contract_date);
    
    if (isNaN(dateA) || isNaN(dateB)) {
        console.error("Invalid contract date:", a.contract_date, b.contract_date);
        return 0;
    }
    
    return dateA - dateB;
  });

  const groupedData = sortedData.reduce((acc, item) => {
    const date = item.contract_date;
    //const projectId = item.project_id;
    const weight = parseFloat(item.contract_weight) || 0;
    const revenue = parseFloat(item.contract_value) || 0;
    
    acc[date] = acc[date] || { count: 0, weight: 0, revenue: 0 };

    acc[date].count++;
    acc[date].weight += weight;
    acc[date].revenue += revenue;

    return acc;
  }, {});

  const chartDataNumber = Object.entries(groupedData).map(([date, { count }]) => {
    return [date, count];
  });

  const chartDataWeight = Object.entries(groupedData).map(([date, { weight }]) => {
    return [date, weight];
  });

  const chartDataRevenue = Object.entries(groupedData).map(([date, { revenue }]) => {
    return [date, revenue];
  });

  return (
    <Card className="h-100 mb-3">
      <Card.Body className="pb-0">
        <Row>
          <Col md={4} sm={12} className="border-end">
            <h6 className="text-700">Number of projects</h6>
            <Flex justifyContent="between" className="my-3">
              <div>
                <span className="h3 text-success">{formatNumberWord(current_period?.number_of_projects)}</span>
                <span className="text-500 fs--1 ms-1">
                  vs {formatNumberWord(previous_period?.number_of_projects)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataNumber)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
          <Col md={4} sm={12} className="border-end">
            <h6 className="text-700">Weight (M.T)</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-success">{formatNumberWord(current_period?.weight)}</span>
                <span className="text-500 fs--1 ms-1">
                  vs {formatNumberWord(previous_period?.weight)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataWeight)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
          <Col md={4} sm={12}>
            <h6 className="text-700">Revenue</h6>
            <Flex justifyContent="between" className="my-2">
              <div>
                <span className="h3 text-success">${formatNumberWord(current_period?.revenue)}</span>
                <span className="text-500 fs--1 ms-1">
                  vs {formatNumberWord(previous_period?.revenue)}
                
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>
                        Compare with the same period in the previous year
                      </Tooltip>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        icon="info-circle"
                        transform="shrink-1"
                        className="ms-1 text-400"
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
              <div>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(chartDataRevenue)}
                  style={{ width: '6.5rem', height: 50 }}
                />
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ContractGeneral.propTypes = {
  contractData: PropTypes.object.isRequired
}

export default ContractGeneral;