import { useDispatch } from "react-redux";
import { Form, Modal, Button } from "react-bootstrap";
import IconAlert from "components/common/IconAlert";
import { toast } from "react-toastify";
import { deleteChangeLog } from "redux/documentation/changeLogSlice";
import React from 'react';
import PropTypes from 'prop-types';

const ChangeLogDeleteConfirmation = ({showModal, setshowModal, changeLog }) => {
  const dispatch = useDispatch()

  const closeModal = () => {
    setshowModal(false)
  }

  const handleSubmit = () => {
    try {
      dispatch(deleteChangeLog(changeLog.id))
      closeModal()
    }
    catch(error) {
      toast.error(error.message)
    } 
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Delete change log confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IconAlert variant="warning">
            Are you sure to delete version <strong>{changeLog.version}</strong>?
          </IconAlert>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit">Yes</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

ChangeLogDeleteConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  changeLog: PropTypes.object.isRequired
}

export default ChangeLogDeleteConfirmation;