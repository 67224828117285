import { faRProject, faIntercom, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';

export const homeRoutes = {
  label: 'Home',
  children: [
    {
      name: 'Starter',
      icon: 'flag',
      to: '/',
      active: true
    },
    {
      name: 'Profile',
      to: '/user/profile',
      active: true
    },
    {
      name: 'Privacy policy',
      to: '/privacy-policy',
      active: true
    },
    {
      name: 'Terms of service',
      to: '/terms-of-service',
      active: true
    }
  ]
};

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      permissionNo: 'v501',
      icon: 'chart-pie',
      children: [
        // {
        //   name: 'Dashboard',
        //   to: '/dashboard/home',
        //   exact: true,
        //   active: true
        // },
        // {
        //   name: 'Analytics',
        //   to: '/dashboard/analytics',
        //   active: true
        // },
        // {
        //   name: 'CRM',
        //   to: '/dashboard/crm',
        //   active: true
        // },
        {
          name: 'CRM',
          to: '/dashboard/crm',
          active: true
        }
        // {
        //   name: 'E Commerce',
        //   to: '/dashboard/e-commerce',
        //   active: true
        // },
        // {
        //   name: 'LMS',
        //   to: '/dashboard/lms',
        //   active: true,
        //   badge: {
        //     type: 'success',
        //     text: 'New'
        //   }
        // },
        // {
        //   name: 'Management',
        //   to: '/dashboard/project-management',
        //   active: true
        // },
        // {
        //   name: 'SaaS',
        //   to: '/dashboard/saas',
        //   active: true
        // },
        // {
        //   name: 'Support desk',
        //   to: '/dashboard/support-desk',
        //   active: true,
        //   badge: {
        //     type: 'success',
        //     text: 'New'
        //   }
        // }
      ]
    }
  ]
};

export const bookingRoutes = {
  label: 'Booking',
  children: [
    {
      name: 'Meeting room',
      icon: 'calendar-alt',
      to: '/booking/meeting-room',
      permissionNo: 'v401',
      active: true
    },
    {
      name: 'Car',
      icon: 'car',
      permissionNo: 'v402',
      active: true,
      children: [
        {
          name: 'Request car booking',
          to: '/booking/car/new-car-booking',
          permissionNo: 'v402',
          active: true
        },
        {
          name: 'Booking list',
          to: '/booking/car/list',
          permissionNo: 'v402',
          active: true
        },
        {
          name: 'Calendar',
          to: '/booking/car/calendar',
          permissionNo: 'v402',
          active: true
        },
        {
          name: 'Reports',
          permissionNo: 'v510',
          active: true,
          children: [
            {
              name: 'Car transactions',
              to: '/booking/car/reports/car-transactions',
              permissionNo: 'v510',
              active: true
            }
          ]
        },
        {
          name: 'Settings',
          permissionNo: 'v503',
          active: true,
          children: [
            {
              name: 'Car',
              to: '/booking/car/settings/cars',
              permissionNo: 'v503',
              active: true
            },
            {
              name: 'Driver',
              to: '/booking/car/settings/drivers',
              permissionNo: 'v503',
              active: true
            }
          ]
        }
      ]
    },
    {
      name: 'Travel',
      icon: 'plane',
      permissionNo: 'v503',
      active: true,
      children: [
        {
          name: 'New travel request',
          to: '/booking/travel/new-travel-request',
          permissionNo: 'v503',
          active: true
        },
        {
          name: 'Booking list',
          to: '/booking/travel/list',
          permissionNo: 'v503',
          active: true
        },
        {
          name: 'Settings',
          permissionNo: 'v503',
          active: true,
          children: [
            {
              name: 'Expense policy',
              to: '/booking/travel/settings/expense-policy',
              permissionNo: 'v503',
              active: true
            },
            {
              name: 'Approval-flow',
              to: '/booking/travel/settings/approval-flows',
              permissionNo: 'v503',
              active: true
            }
          ]
        }
      ]
    }
  ]
};

export const driveRoutes = {
  label: 'Drive',
  children: [
    {
      name: 'Drive',
      icon: faGoogleDrive,
      to: '/drives/list',
      permissionNo: 'v402--change-me',
      active: true
    }
  ]
};

export const socialRoutes = {
  label: 'Social',
  children: [
    {
      name: 'Social',
      icon: 'share-alt',
      to: '/social/feeds',
      active: true
    }
  ]
};

export const crmRoutes = {
  label: 'CRM',
  children: [
    {
      name: 'Lead',
      icon: 'leaf',
      active: true,
      permissionNo: 'v600',
      children: [
        {
          name: 'New Lead',
          to: '/crm/leads/new',
          permissionNo: 'v601',
          active: true
        },
        {
          name: 'Lead details',
          to: '/crm/leads/list',
          permissionNo: 'v600',
          active: true
        },
        {
          name: 'Dashboard',
          permissionNo: 'v602',
          to: '/crm/leads/dashboard',
          active: true
        }
      ]
    },
    {
      name: 'Contact',
      icon: 'address-book',
      to: '/crm/contacts/list',
      permissionNo: 'v701',
      active: true
    },
    {
      name: 'Customer',
      icon: faIntercom,
      to: '/crm/customers/list',
      permissionNo: 'v801',
      active: true
    },
    {
      name: 'Project',
      icon: faRProject,
      permissionNo: 'v900',
      active: true,
      children: [
        {
          name: 'Create new PCV',
          to: '/crm/projects/new-pcv',
          permissionNo: 'v900',
          active: true
        },
        {
          name: 'Project details',
          to: '/crm/projects/list',
          permissionNo: 'v900',
          active: true
        },
        {
          name: 'Quotation details',
          to: '/crm/projects/reports/quotations',
          permissionNo: 'v900',
          active: true
        },
        {
          name: 'Master schedule',
          to: '/crm/projects/reports/project-master-schedule',
          permissionNo: 'v900',
          active: true
        },
        {
          name: 'Warranty',
          to: '/crm/projects/warranties',
          permissionNo: 'v900',
          active: true
        },
        // {
        //   name: 'Individual productivity',
        //   to: '/crm/projects/reports/individual-productivity',
        // permissionNo: 'v503',
        // active: true
        // },
        // {
        //   name: 'Team productivity',
        //   to: '/crm/projects/reports/team-productivity',
        //   active: true
        // }
      ]
    },
    {
      name: 'Waiver',
      icon: 'money-bill-wave',
      to: '/crm/projects/waivers',
      permissionNo: 'v910',
      active: true
    },
    {
      name: 'Contract & Addendum',
      icon: 'file-signature',
      to: '/crm/contracts',
      permissionNo: 'v911',
      active: true
    },
    {
      name: 'Sales unit',
      icon: 'universal-access',
      to: 'crm/sales-units/list',
      permissionNo: 'v912',
      active: true
    },
    {
      name: 'Reports',
      icon: 'flag',
      permissionNo: 'v913',
      active: true,
      children: [
        {
          name: 'Quotation',
          to: '/crm/reports/quotation-dashboard',
          permissionNo: 'v9131',
          active: true
        },
        {
          name: 'PIF',
          to: '/crm/reports/pifs',
          permissionNo: 'v9132',
          active: true
        },
        {
          name: 'ICCC',
          to: '/crm/reports/iccc',
          permissionNo: 'v9133',
          active: true
        },
        {
          name: 'Contract',
          to: '/crm/reports/contract-dashboard',
          permissionNo: 'v9134',
          active: true
        },
        {
          name: 'Waiver',
          to: '/crm/reports/waiver-dashboard',
          permissionNo: 'v9135',
          active: true
        },
        {
          name: 'Master schedule',
          to: '/crm/reports/project-master-dashboard',
          permissionNo: 'v9136',
          active: true
        },
        {
          name: 'Change order',
          to: '/crm/reports/change-order',
          permissionNo: 'v9137',
          active: true
        },
        {
          name: 'Project schedule',
          to: '/crm/reports/project-schedule',
          permissionNo: 'v9138',
          active: true
        },
        {
          name: 'Estimate assginment schedule',
          to: '/crm/reports/estimate-assignment-schedule',
          permissionNo: 'v9139',
          active: true
        },
        {
          name: 'Engineering job progress',
          to: '/crm/reports/engineering-job-progress',
          permissionNo: 'v9140',
          active: true
        },
        {
          name: 'Project Bom & Shop',
          to: '/crm/reports/project-bom-shop',
          permissionNo: 'v9141',
          active: true
        },
        {
          name: 'Job entry',
          to: '/crm/reports/job-entry',
          permissionNo: 'v9142',
          active: true
        }
      ]
    },
    {
      name: 'Settings',
      icon: 'tools',
      permissionNo: 'v914',
      active: true,
      children: [
        {
          name: 'Configuration',
          to: '/crm/settings/configuration',
          active: true
        }
      ]
    }
  ]
};

export const organizationRoutes = {
  label: 'Organization',
  children: [
    {
      name: 'Employees',
      icon: 'users',
      to: '/organization/employees',
      permissionNo: 'v100',
      active: true
    },
    {
      name: 'Departments',
      icon: 'building',
      to: '/organization/departments',
      permissionNo: 'v101',
      active: true
    },
    {
      name: 'Sites',
      icon: 'building',
      to: '/organization/sites',
      permissionNo: 'v102',
      active: true
    },
    {
      name: 'Position group',
      icon: 'user-friends',
      to: '/organization/position-groups',
      permissionNo: 'v103',
      active: true
    }
  ]
};

export const authorizationRoutes = {
  label: 'Authorization',
  children: [
    {
      name: 'User',
      icon: 'user',
      to: '/authorization/users',
      permissionNo: 'v201',
      active: true
    },
    {
      name: 'Role',
      icon: 'user-shield',
      to: '/authorization/roles',
      permissionNo: 'v202',
      active: true
    },
    {
      name: 'Permission',
      icon: 'user-astronaut',
      to: '/authorization/permissions',
      permissionNo: 'v203',
      active: true
    }
  ]
};

export const jobRoutes = {
  label: 'Background job',
  children: [
    {
      name: 'Sidekiq',
      icon: 'bell',
      to: '/sidekiq',
      permissionNo: 'v301',
      active: true
    }
  ]
};

export const integrationRoutes = {
  label: 'Integration',
  children: [
    {
      name: 'Integration',
      icon: 'plug',
      to: '/integration/applications/list',
      permissionNo: 'v302',
      active: true
    }
  ]
};

export const settingsRoutes = {
  label: 'Settings',
  children: [
    {
      name: 'Settings',
      icon: 'tools',
      to: '/settings',
      permissionNo: 'v303',
      active: true
    }
  ]
};

// export const appRoutes = {
//   label: 'app',
//   children: [
//     {
//       name: 'Calendar',
//       icon: 'calendar-alt',
//       to: '/app/calendar',
//       active: true
//     },
//     {
//       name: 'Chat',
//       icon: 'comments',
//       to: '/app/chat',
//       active: true
//     },
//     {
//       name: 'Email',
//       icon: 'envelope-open',
//       active: true,
//       children: [
//         {
//           name: 'Inbox',
//           to: '/email/inbox',
//           active: true
//         },
//         {
//           name: 'Email detail',
//           to: '/email/email-detail',
//           active: true
//         },
//         {
//           name: 'Compose',
//           to: '/email/compose',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Events',
//       icon: 'calendar-day',
//       active: true,
//       children: [
//         {
//           name: 'Create an event',
//           to: '/events/create-an-event',
//           active: true
//         },
//         {
//           name: 'Event detail',
//           to: '/events/event-detail',
//           active: true
//         },
//         {
//           name: 'Event list',
//           to: '/events/event-list',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'E Commerce',
//       icon: 'shopping-cart',
//       active: true,
//       children: [
//         {
//           name: 'Product',
//           active: true,
//           children: [
//             {
//               name: 'Product list',
//               to: '/e-commerce/product/product-list',
//               active: true
//             },
//             {
//               name: 'Product grid',
//               to: '/e-commerce/product/product-grid',
//               active: true
//             },
//             {
//               name: 'Product details',
//               to: '/e-commerce/product/product-details',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Orders',
//           active: true,
//           children: [
//             {
//               name: 'Order list',
//               to: '/e-commerce/orders/order-list',
//               active: true
//             },
//             {
//               name: 'Order details',
//               to: '/e-commerce/orders/order-details',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Customers',
//           to: '/e-commerce/customers',
//           active: true
//         },
//         {
//           name: 'Customer details',
//           to: '/e-commerce/customer-details',
//           active: true
//         },
//         {
//           name: 'Shopping cart',
//           to: '/e-commerce/shopping-cart',
//           active: true
//         },
//         {
//           name: 'Checkout',
//           to: '/e-commerce/checkout',
//           active: true
//         },
//         {
//           name: 'Billing',
//           to: '/e-commerce/billing',
//           active: true
//         },
//         {
//           name: 'Invoice',
//           to: '/e-commerce/invoice',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'E Learning',
//       icon: 'graduation-cap',
//       active: true,
//       badge: {
//         type: 'success',
//         text: 'New'
//       },
//       children: [
//         {
//           name: 'Course',
//           active: true,
//           children: [
//             {
//               name: 'Course list',
//               to: '/e-learning/course/course-list',
//               active: true
//             },
//             {
//               name: 'Course grid',
//               to: '/e-learning/course/course-grid',
//               active: true
//             },
//             {
//               name: 'Course details',
//               to: '/e-learning/course/course-details',
//               active: true
//             },
//             {
//               name: 'Create a course',
//               to: '/e-learning/course/create-a-course',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Student overview',
//           to: '/e-learning/student-overview',
//           active: true
//         },
//         {
//           name: 'Trainer profile',
//           to: '/e-learning/trainer-profile',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Kanban',
//       icon: ['fab', 'trello'],
//       to: '/app/kanban',
//       active: true
//     },
//     {
//       name: 'Social',
//       icon: 'share-alt',
//       active: true,
//       children: [
//         {
//           name: 'Feed',
//           to: '/social/feed',
//           active: true
//         },
//         {
//           name: 'Activity log',
//           to: '/social/activity-log',
//           active: true
//         },
//         {
//           name: 'Notifications',
//           to: '/social/notifications',
//           active: true
//         },
//         {
//           name: 'Followers',
//           to: '/social/followers',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Support desk',
//       icon: 'ticket-alt',
//       active: true,
//       children: [
//         {
//           name: 'Table view',
//           to: '/support-desk/table-view',
//           active: true
//         },
//         {
//           name: 'Card view',
//           to: '/support-desk/card-view',
//           active: true
//         },
//         {
//           name: 'Contacts',
//           to: '/support-desk/contacts',
//           active: true
//         },
//         {
//           name: 'Contact details',
//           to: '/support-desk/contact-details',
//           active: true
//         },
//         {
//           name: 'Tickets preview',
//           to: '/support-desk/tickets-preview',
//           active: true
//         },
//         {
//           name: 'Quick links',
//           to: '/support-desk/quick-links',
//           active: true
//         },
//         {
//           name: 'Reports',
//           to: '/support-desk/reports',
//           active: true
//         }
//       ]
//     }
//   ]
// };

// export const pagesRoutes = {
//   label: 'pages',
//   children: [
//     {
//       name: 'Starter',
//       icon: 'flag',
//       to: '/pages/starter',
//       active: true
//     },
//     {
//       name: 'Landing',
//       icon: 'globe',
//       to: '/landing',
//       active: true
//     },
//     {
//       name: 'Pricing',
//       icon: 'tags',
//       active: true,
//       children: [
//         {
//           name: 'Pricing default',
//           to: '/pricing/pricing-default',
//           active: true
//         },
//         {
//           name: 'Pricing alt',
//           to: '/pricing/pricing-alt',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Faq',
//       icon: 'question-circle',
//       active: true,
//       children: [
//         {
//           name: 'Faq basic',
//           to: '/faq/faq-basic',
//           active: true
//         },
//         {
//           name: 'Faq alt',
//           to: '/faq/faq-alt',
//           active: true
//         },
//         {
//           name: 'Faq accordion',
//           to: '/faq/faq-accordion',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Errors',
//       active: true,
//       icon: 'exclamation-triangle',
//       children: [
//         {
//           name: '404',
//           to: '/errors/404',
//           active: true
//         },
//         {
//           name: '500',
//           to: '/errors/500',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Miscellaneous',
//       icon: 'thumbtack',
//       active: true,
//       children: [
//         {
//           name: 'Associations',
//           to: '/miscellaneous/associations',
//           active: true
//         },
//         {
//           name: 'Invite people',
//           to: '/miscellaneous/invite-people',
//           active: true
//         }
//       ]
//     }
//   ]
// };

// export const modulesRoutes = {
//   label: 'Modules',
//   children: [
//     {
//       name: 'Forms',
//       active: true,
//       icon: 'file-alt',
//       children: [
//         {
//           name: 'Basic',
//           active: true,
//           children: [
//             {
//               name: 'Form control',
//               to: '/forms/basic/form-control',
//               active: true
//             },
//             {
//               name: 'Input group',
//               to: '/forms/basic/input-group',
//               active: true
//             },
//             {
//               name: 'Select',
//               to: '/forms/basic/select',
//               active: true
//             },
//             {
//               name: 'Checks',
//               to: '/forms/basic/checks',
//               active: true
//             },
//             {
//               name: 'Range',
//               to: '/forms/basic/range',
//               active: true
//             },
//             {
//               name: 'Layout',
//               to: '/forms/basic/layout',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Advance',
//           active: true,
//           children: [
//             {
//               name: 'Advance select',
//               to: '/forms/advance/advance-select',
//               active: true
//             },
//             {
//               name: 'Date picker',
//               to: '/forms/advance/date-picker',
//               active: true
//             },
//             {
//               name: 'Editor',
//               to: '/forms/advance/editor',
//               active: true
//             },
//             {
//               name: 'Emoji button',
//               to: '/forms/advance/emoji-button',
//               active: true
//             },
//             {
//               name: 'File uploader',
//               to: '/forms/advance/file-uploader',
//               active: true
//             },
//             {
//               name: 'Input mask',
//               to: '/forms/advance/input-mask',
//               active: true
//             },
//             {
//               name: 'Range slider',
//               to: '/forms/advance/range-slider',
//               active: true
//             },
//             {
//               name: 'Rating',
//               to: '/forms/advance/rating',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Floating labels',
//           to: '/forms/floating-labels',
//           active: true
//         },
//         {
//           name: 'Wizard',
//           to: '/forms/wizard',
//           active: true
//         },
//         {
//           name: 'Validation',
//           to: '/forms/validation',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Tables',
//       icon: 'table',
//       active: true,
//       children: [
//         {
//           name: 'Basic tables',
//           to: '/tables/basic-tables',
//           active: true
//         },
//         {
//           name: 'Advance tables',
//           to: '/tables/advance-tables',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Charts',
//       icon: 'chart-line',
//       active: true,
//       children: [
//         {
//           name: 'Chartjs',
//           to: '/charts/chartjs',
//           active: true
//         },
//         {
//           name: 'D3js',
//           to: '/charts/d3js',
//           active: true,
//           badge: {
//             type: 'success',
//             text: 'New'
//           }
//         },
//         {
//           name: 'ECharts',
//           active: true,
//           children: [
//             {
//               name: 'How to use',
//               to: '/charts/echarts/how-to-use',
//               active: true
//             },
//             {
//               name: 'Line charts',
//               to: '/charts/echarts/line-charts',
//               active: true
//             },
//             {
//               name: 'Bar charts',
//               to: '/charts/echarts/bar-charts',
//               active: true
//             },
//             {
//               name: 'Candlestick charts',
//               to: '/charts/echarts/candlestick-charts',
//               active: true
//             },
//             {
//               name: 'Geo map',
//               to: '/charts/echarts/geo-map',
//               active: true
//             },
//             {
//               name: 'Scatter charts',
//               to: '/charts/echarts/scatter-charts',
//               active: true
//             },
//             {
//               name: 'Pie charts',
//               to: '/charts/echarts/pie-charts',
//               active: true
//             },
//             {
//               name: 'Radar charts',
//               to: '/charts/echarts/radar-charts',
//               active: true
//             },
//             {
//               name: 'Heatmap charts',
//               to: '/charts/echarts/heatmap-charts',
//               active: true
//             }
//           ]
//         }
//       ]
//     },
//     {
//       name: 'Icons',
//       active: true,
//       icon: 'shapes',
//       children: [
//         {
//           name: 'Font awesome',
//           to: '/icons/font-awesome',
//           active: true
//         },
//         {
//           name: 'React icons',
//           to: '/icons/react-icons',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Maps',
//       icon: 'map',
//       active: true,
//       children: [
//         {
//           name: 'Google map',
//           to: '/maps/google-map',
//           active: true
//         },
//         {
//           name: 'Leaflet map',
//           to: '/maps/leaflet-map',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Components',
//       active: true,
//       icon: 'puzzle-piece',
//       children: [
//         {
//           name: 'Alerts',
//           to: '/components/alerts',
//           active: true
//         },
//         {
//           name: 'Accordion',
//           to: '/components/accordion',
//           active: true
//         },
//         {
//           name: 'Animated icons',
//           to: '/components/animated-icons',
//           active: true
//         },
//         {
//           name: 'Background',
//           to: '/components/background',
//           active: true
//         },
//         {
//           name: 'Badges',
//           to: '/components/badges',
//           active: true
//         },
//         {
//           name: 'Breadcrumbs',
//           to: '/components/breadcrumb',
//           active: true
//         },
//         {
//           name: 'Buttons',
//           to: '/components/buttons',
//           active: true
//         },
//         {
//           name: 'Calendar',
//           to: '/components/calendar',
//           active: true
//         },
//         {
//           name: 'Cards',
//           to: '/components/cards',
//           active: true
//         },
//         {
//           name: 'Carousel',
//           active: true,
//           children: [
//             {
//               name: 'Bootstrap',
//               to: '/components/carousel/bootstrap',
//               label: 'bootstrap-carousel',
//               active: true
//             },
//             {
//               name: 'Slick',
//               to: '/components/carousel/slick',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Collapse',
//           to: '/components/collapse',
//           active: true
//         },
//         {
//           name: 'Cookie notice',
//           to: '/components/cookie-notice',
//           active: true
//         },
//         {
//           name: 'Countup',
//           to: '/components/countup',
//           active: true
//         },
//         {
//           name: 'Draggable',
//           to: '/components/draggable',
//           active: true
//         },
//         {
//           name: 'Dropdowns',
//           to: '/components/dropdowns',
//           active: true
//         },
//         {
//           name: 'List group',
//           to: '/components/list-group',
//           active: true
//         },
//         {
//           name: 'Modals',
//           to: '/components/modals',
//           active: true
//         },
//         {
//           name: 'Offcanvas',
//           to: '/components/offcanvas',
//           active: true
//         },
//         {
//           name: 'Navs & Tabs',
//           active: true,
//           children: [
//             {
//               name: 'Navs',
//               to: '/components/navs-and-tabs/navs',
//               active: true
//             },
//             {
//               name: 'Navbar',
//               to: '/components/navs-and-tabs/navbar',
//               active: true
//             },
//             {
//               name: 'Vertical navbar',
//               to: '/components/navs-and-tabs/vertical-navbar',
//               active: true
//             },
//             {
//               name: 'Top navbar',
//               to: '/components/navs-and-tabs/top-navbar',
//               active: true
//             },
//             {
//               name: 'Double Top',
//               to: '/components/navs-and-tabs/double-top-navbar',
//               active: true
//             },
//             {
//               name: 'Combo navbar',
//               to: '/components/navs-and-tabs/combo-navbar',
//               active: true
//             },
//             {
//               name: 'Tabs',
//               to: '/components/navs-and-tabs/tabs',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Pictures',
//           active: true,
//           children: [
//             {
//               name: 'Avatar',
//               to: '/components/pictures/avatar',
//               active: true
//             },
//             {
//               name: 'Images',
//               to: '/components/pictures/images',
//               active: true
//             },
//             {
//               name: 'Figures',
//               to: '/components/pictures/figures',
//               active: true
//             },
//             {
//               name: 'Hoverbox',
//               to: '/components/pictures/hoverbox',
//               active: true
//             },
//             {
//               name: 'Lightbox',
//               to: '/components/pictures/lightbox',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Progress Bar',
//           to: '/components/progress-bar',
//           active: true
//         },
//         {
//           name: 'Pagination',
//           to: '/components/pagination',
//           active: true
//         },
//         {
//           name: 'Placeholder',
//           to: '/components/placeholder',
//           active: true
//         },
//         {
//           name: 'Popovers',
//           to: '/components/popovers',
//           active: true
//         },
//         {
//           name: 'Scrollspy',
//           to: '/components/scrollspy',
//           active: true
//         },
//         {
//           name: 'Search',
//           to: '/components/search',
//           active: true
//         },
//         {
//           name: 'Spinners',
//           to: '/components/spinners',
//           active: true
//         },
//         {
//           name: 'Timeline',
//           to: '/components/timeline',
//           active: true
//         },
//         {
//           name: 'Toasts',
//           to: '/components/toasts',
//           active: true
//         },
//         {
//           name: 'Tooltips',
//           to: '/components/tooltips',
//           active: true
//         },
//         {
//           name: 'Treeview',
//           to: '/components/treeview',
//           active: true
//         },
//         {
//           name: 'Typed text',
//           to: '/components/typed-text',
//           active: true
//         },
//         {
//           name: 'Videos',
//           active: true,
//           children: [
//             {
//               name: 'Embed',
//               to: '/components/videos/embed',
//               active: true
//             },
//             {
//               name: 'React Player',
//               to: '/components/videos/react-player',
//               active: true
//             }
//           ]
//         }
//       ]
//     },
//     {
//       name: 'Utilities',
//       active: true,
//       icon: 'fire',
//       children: [
//         {
//           name: 'Borders',
//           to: '/utilities/borders',
//           active: true
//         },
//         {
//           name: 'Colors',
//           to: '/utilities/colors',
//           active: true
//         },
//         {
//           name: 'Colored links',
//           to: '/utilities/colored-links',
//           active: true
//         },
//         {
//           name: 'Display',
//           to: '/utilities/display',
//           active: true
//         },
//         {
//           name: 'Flex',
//           to: '/utilities/flex',
//           active: true
//         },
//         {
//           name: 'Float',
//           to: '/utilities/float',
//           active: true
//         },
//         {
//           name: 'Grid',
//           to: '/utilities/grid',
//           active: true
//         },
//         {
//           name: 'Scroll Bar',
//           to: '/utilities/scroll-bar',
//           active: true
//         },
//         {
//           name: 'Position',
//           to: '/utilities/position',
//           active: true
//         },
//         {
//           name: 'Spacing',
//           to: '/utilities/spacing',
//           active: true
//         },
//         {
//           name: 'Sizing',
//           to: '/utilities/sizing',
//           active: true
//         },
//         {
//           name: 'Stretched link',
//           to: '/utilities/stretched-link',
//           active: true
//         },
//         {
//           name: 'Text truncation',
//           to: '/utilities/text-truncation',
//           active: true
//         },
//         {
//           name: 'Typography',
//           to: '/utilities/typography',
//           active: true
//         },
//         {
//           name: 'Vertical align',
//           to: '/utilities/vertical-align',
//           active: true
//         },
//         {
//           name: 'Visibility',
//           to: '/utilities/visibility',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Widgets',
//       icon: 'poll',
//       to: '/widgets',
//       active: true
//     },
//     {
//       name: 'Multi level',
//       active: true,
//       icon: 'layer-group',
//       children: [
//         {
//           name: 'Level two',
//           active: true,
//           children: [
//             {
//               name: 'Item 1',
//               active: true,
//               to: '#!'
//             },
//             {
//               name: 'Item 2',
//               active: true,
//               to: '#!'
//             }
//           ]
//         },
//         {
//           name: 'Level three',
//           active: true,
//           children: [
//             {
//               name: 'Item 3',
//               active: true,
//               to: '#!'
//             },
//             {
//               name: 'Item 4',
//               active: true,
//               children: [
//                 {
//                   name: 'Item 5',
//                   active: true,
//                   to: '#!'
//                 },
//                 {
//                   name: 'Item 6',
//                   active: true,
//                   to: '#!'
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           name: 'Level four',
//           active: true,
//           children: [
//             {
//               name: 'Item 6',
//               active: true,
//               to: '#!'
//             },
//             {
//               name: 'Item 7',
//               active: true,
//               children: [
//                 {
//                   name: 'Item 8',
//                   active: true,
//                   to: '#!'
//                 },
//                 {
//                   name: 'Item 9',
//                   active: true,
//                   children: [
//                     {
//                       name: 'Item 10',
//                       active: true,
//                       to: '#!'
//                     },
//                     {
//                       name: 'Item 11',
//                       active: true,
//                       to: '#!'
//                     }
//                   ]
//                 }
//               ]
//             }
//           ]
//         }
//       ]
//     }
//   ]
// };

export const documentationRoutes = {
  label: 'documentation',
  children: [
    {
      name: 'Faq',
      icon: 'question-circle',
      to: '/documentation/faqs',
      permissionNo: 'v305',
      active: true
    },
    {
      name: 'Changelog',
      icon: 'code-branch',
      permissionNo: 'v306',
      to: '/documentation/change-logs',
      active: true
    }
  ]
};

export const publicRoutes = [
  { ...bookingRoutes, sequence: 1 },
  { ...driveRoutes, sequence: 2 },
  { ...socialRoutes, sequence: 3 },
  { ...documentationRoutes, sequence: 11 }
];

export const protectedRoutes = [
  { ...dashboardRoutes, sequence: 1 },
  { ...crmRoutes, sequence: 5 },
  { ...organizationRoutes, sequence: 6 },
  { ...authorizationRoutes, sequence: 7 },
  { ...jobRoutes, sequence: 8 },
  { ...integrationRoutes, sequence: 9 },
  { ...settingsRoutes, sequence: 10 }
];
