import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { assignUserTravelBookingSiteRecord } from "redux/authorization/userSlice";
import { useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import PropTypes from 'prop-types';

const RecordLevelTravelBookingSite = ({userId, data=[], sites=[]}) => {
  const { handleSubmit, setValue } = useForm();
  const [selectedSites, setSelectedSites] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const selected = data.length > 0 && data.map(item => ({value: item.id, label: item.name}));
    setSelectedSites(selected);
  }, [userId, data, sites])


  const onSubmit = (data) => {
    console.log(data)

    try {
      if (userId) {
        dispatch(assignUserTravelBookingSiteRecord({userId, data}))
      }
    }
    catch (error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }
  
  const siteOptions = sites && sites.map(item => ({value: item.id, label: item.name}));

  const defaultSiteOption = useMemo(() => {
    if (data) {
      return data.map((item) => ({value: item.id, label: item.name}));
    }
    return null;
  }, [data]);

  const handleChangeSalesUnit = (selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue('site_ids', selectedOptionsValues, { shouldValidate: true })
    setSelectedSites(selectedOptions)
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2 fs--1">
          <Select
            closeMenuOnSelect={false}
            options={siteOptions}
            defaultValue={defaultSiteOption}
            value={selectedSites}
            placeholder='Select...'
            classNamePrefix='react-select'
            isMulti
            onChange={handleChangeSalesUnit}
          />
        </div>

        <div className="d-flex justify-content-between">
          <div>Total {data.length} sites assigned.</div>
          <Button
            type="submit"
            size="sm"
            variant="falcon-default"
          >
            <span>{data.length > 0 ? "Update" : "Add"} Site</span>
          </Button>
        </div>
      </Form>

      <div className="mt-4">Assigning Site for limit record access to Travel booking.</div>
    </>
  )
};

RecordLevelTravelBookingSite.propTypes = {
  userId: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired
}

export default RecordLevelTravelBookingSite;