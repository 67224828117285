import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ApplicationHeader = () => {
  const navigate = useNavigate()

  const applicationUrl = "/integration/applications";

  const handleNewApplication = () => {
    navigate(`${applicationUrl}/new`)
  }

  return (
    <>
      <Card className="mb-3" bg="falcon-default">
        <Card.Header className="d-flex flex-between-center border-bottom">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            iconAlign="middle"
            className="d-none d-sm-block me-1"
            onClick={handleNewApplication}
          >
            <span className="d-none d-xl-inline-block ms-1">
              New Application
            </span>
          </IconButton>
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
};

export default ApplicationHeader;