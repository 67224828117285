import IconButton from "components/common/IconButton";
import React, { Suspense, useState, lazy, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import ShopDetailTable from "./shop/ShopDetailTable";
import Flex from "components/common/Flex";
import { MdRefresh } from "react-icons/md";
import { showOnMaster, updateFullReleaseBom } from "redux/crm/project/changeOrderSlice";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { formatNumber } from "helpers/utils";

const LazyShopForm = lazy(() => import("./shop/ShopForm"));
const LazyShopDeleteConfirm = lazy(() => import("./shop/ShopDeleteConfirm"));

const ShopDrawing = ({changeOrder}) => {
  const [showShopForm, setShowShopForm] = useState(false);
  const [showDeletePhase, setShowDeletePhase] = useState(false);
  const [title, setTitle] = useState(null);
  const [phase, setPhase] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedFullReleaseBom, setIsCheckedFullReleaseBom] = useState(false);

  const {
    allow_create_bom_shop,
    allow_show_master,
    allow_update_full_release_building
  } = changeOrder?.permissions || {};

  const {project, building} = changeOrder || {};
  const dispatch = useDispatch();

  useEffect(() => {
    setIsChecked(building?.shop_co_lastest_id === changeOrder?.id);
    setIsCheckedFullReleaseBom(building?.full_released);
  }, [building, dispatch]);

  console.log(isCheckedFullReleaseBom)

  const handleNewPhase = () => {
    setTitle("New Shop/Erection Drawing")
    setPhase(null)
    setShowShopForm(true)
  }

  const handleEditPhase = (phase) => {
    setTitle("Edit Shop/Erection Drawing")
    setPhase(phase)
    setShowShopForm(true)
  }

  const handleDeletePhase = (phase) => {
    setPhase(phase)
    setShowDeletePhase(true)
  }

  const handleRefreshPhase = () => {
    setRefreshCount((prevCount => prevCount + 1))
  }

  const handleShowShopToMasterSchedule = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);

    const data = {
      type: "shop",
      checked: checked,
      weight: null,
      latest_co_id: changeOrder.id
    };

    dispatch(showOnMaster({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data
    }));
  }

  const handleUpdateFullRelease = (e) => {
    const checked = e.target.checked;
    setIsCheckedFullReleaseBom(checked);

    dispatch(updateFullReleaseBom({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data: { checked }
    }));
  }

  return (
    <>
      <Row>
        <Col>
          <Row className="mb-2 gx-2">
            <Col sm={4}>Final Estimate weight</Col>
            <Col className="text-primary">{formatNumber(building?.est_final_weight)} M.T</Col>
          </Row>
          <Row className="mb-2 gx-2">
            <Col sm={4}>Final BOM weight</Col>
            <Col className="text-primary">{formatNumber(building?.bom_weight)} M.T</Col>
          </Row>
          <Row className="mb-2 mt-4">
            <Col className="text-primary">
              {allow_show_master && (
                <Form.Check
                  label="Show in Master Schedule report"
                  onChange={(e) => handleShowShopToMasterSchedule(e)}
                  checked={isChecked}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="mb-2 gx-2">
            <Col sm={4}>Variance BOM with Estimate</Col>
            <Col className="text-danger"> 100%</Col>
          </Row>
          <Row className="mb-2 gx-2">
            <Col sm={4}>Full Released BOM</Col>
            <Col className="text-primary">
              <Form.Check
                onChange={(e) => handleUpdateFullRelease(e)}
                checked={isCheckedFullReleaseBom}
                disable={!allow_update_full_release_building}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="border-dashed border-bottom my-3"></div>

      <Row className="mt-2">
        <Col>
          <Flex justifyContent="start">
            {allow_create_bom_shop && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1 fs--1"
                onClick={handleNewPhase}
              >
                <span className="d-none d-xl-inline-block ms-1">New Phase</span>
              </IconButton>
            )}
            
            <Button variant="falcon-default" className="d-none d-xl-inline-block ms-1 fs--1" onClick={handleRefreshPhase}>
              <MdRefresh /> Refresh
            </Button>
          </Flex>

          <ShopDetailTable
            changeOrder={changeOrder}
            editPhase={handleEditPhase}
            deletePhase={handleDeletePhase}
            refreshCount={refreshCount}
          />
        </Col>
      </Row>

      <Suspense fallback={<div>Loading....</div>}>
        {showShopForm && (
          <LazyShopForm
            showModal={showShopForm}
            setshowModal={setShowShopForm}
            changeOrder={changeOrder}
            phase={phase}
            title={title}
          />
        )}

        {showDeletePhase && (
          <LazyShopDeleteConfirm
            showModal={showDeletePhase}
            setshowModal={setShowDeletePhase}
            changeOrder={changeOrder}
            phase={phase}
          />
        )}
      </Suspense>
    </>
  )
};

ShopDrawing.propTypes = {
  changeOrder: PropTypes.object.isRequired
}

export default ShopDrawing;