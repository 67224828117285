import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </Flex>
);

const CalendarEventModal = ({
  setIsOpenEventModal,
  isOpenEventModal,
  modalEventContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };

  const { id, title, end, start } = isOpenEventModal && modalEventContent.event;
  const { description, site_name, submited_by, submited_date, pickup_location, drop_location, status_name,
      bg_name, drivers, driver_name, driver_phone, car_name, route
    } = isOpenEventModal && modalEventContent.event.extendedProps;

  return (
    <Modal
      show={isOpenEventModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
          <p className="mb-0 fs--1 mt-1 fw-normal">
          <small className="fst-italic">
            Submited by <span className="text-primary">{submited_by} | {submited_date}</span>
          </small>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-x1 pb-x1 pt-1 fs--1">

        <Flex justifyContent="between">
          <p className="mb-0 text-justify">
            Booking ID#: <strong>{id}</strong>
          </p>
          <p className="mb-0 text-justify">
            Status: <Badge pill bg={bg_name}>{status_name}</Badge>
          </p>
        </Flex>

        <EventModalMediaContent
          icon="map-marker-alt"
          heading="Pickup"
        >
          <div>Time: <span className="text-primary">{dayjs(start).format('hh:mm A')}</span></div>
          <div>Location: <span className="text-primary">{pickup_location}</span></div>
        </EventModalMediaContent>

        <EventModalMediaContent
          icon="location-arrow"
          heading="Destination"
        >
          <span className="text-primary h6">{drop_location}</span>
        </EventModalMediaContent>
        <EventModalMediaContent icon="calendar-check" heading="Departure Date and Time">
          <p className="mb-1">
            <div>Begin date: <span className="text-primary">{dayjs(start).format('dddd, MMMM DD, YYYY, hh:mm A')}</span></div>
            <div>End date: <span className="text-primary">{dayjs(end).format('dddd, MMMM DD, YYYY, hh:mm A')}</span></div>
          </p>
        </EventModalMediaContent>

        <EventModalMediaContent icon="location-arrow" heading="Destination">
          <div
            className="mb-1"
            style={{whiteSpace: "pre-line"}}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </EventModalMediaContent>

        {drivers && drivers.length > 0 && (
          <EventModalMediaContent
            icon="car"
            heading="Car information"
          >
            <ul>
              {drivers.map((item) => (
                <>
                  <li key={item.id}>
                    <div>Car name: <span className="text-primary">{item.car_name}</span></div>
                    <div>Driver: <span className="text-primary">{item.driver_name}</span></div>
                    <div>Phone: 
                      <span className="text-primary">
                        <a href={`tel:${item.driver_phone}`}>{item.driver_phone}</a>
                      </span>
                    </div>
                  </li>
                  <hr />
                </>
              ))}
            </ul>
          </EventModalMediaContent>
        )}

        {driver_name && (
          <EventModalMediaContent
            icon="car"
            heading="Car information"
          >
            <div>Car name: <span className="text-primary">{car_name}</span></div>
            <div>Driver: <span className="text-primary">{driver_name}</span></div>
            <div>Phone: <span className="text-primary">
              <a href={`tel:${driver_phone}`}>{driver_phone}</a>
            </span></div>
          </EventModalMediaContent>
        )}

        <EventModalMediaContent
          icon="route"
          heading="Route"
          content={route}
        />

        <EventModalMediaContent
          icon="building"
          heading="Site office"
          content={site_name}
        />
      </Modal.Body>
      <Modal.Footer className="bg-light px-x1 border-top-0">
        <Button
          as={Link}
          to={`/booking/car/${id}`}
          variant="falcon-primary"
          size="sm"
        >
          <span>See more details</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarEventModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  modalEventContent: PropTypes.object.isRequired
};

export default CalendarEventModal;
