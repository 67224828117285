import { formatDate } from 'helpers/utils';
import React from 'react';
import PropTypes from 'prop-types';

const formatDateInfo = (shipments, dateType) => (
  shipments.map((shipment) => (
    shipment[dateType] ? (
      <div key={shipment.name}><strong>{shipment.name}</strong>: {formatDate(shipment[dateType], "dd/MM/yy")}</div>
    ) : null
  ))
);

formatDateInfo.propTypes = {
  shipments: PropTypes.array.isRequired,
  dateType: PropTypes.string.isRequired
}

const ShipmentFormatRenderer = (props) => {
  const {shipments} = props.data

  switch (props.column.colId) {
    case "shipments.required_by_customer":
      return formatDateInfo(shipments, "required_by_customer");

    case "shipments.csd_committed":
      return formatDateInfo(shipments, "csd_committed");

    case "shipments.csd_actual":
      return formatDateInfo(shipments, "csd_actual");

    default:
      return null;
  }
}

ShipmentFormatRenderer.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  shipments: PropTypes.array.isRequired
}

export default ShipmentFormatRenderer;