import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/confirmation/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deleteExpense } from 'redux/booking/travel/setting/expenseSlice';
import ExpenseForm from './ExpenseForm';
import CloneExpenseConfirm from './CloneExpenseConfirm';

const ExpenseHeader = ({ selected }) => {
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showExpenseForm, setShowExpenseForm] = useState(false);
  const [showCloneExpenseConfirm, setShowCloneExpenseConfirm] = useState(false);
  const [title, setTitle] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expense = selected.at(-1);

  const validateExpenseSelected = () => {
    if (selected.length === 0)
    {
      toast.error('Record has not selected yet.')
      return false
    }
    return true
  }

  const validateExpenseSelectedMultiple = () => {
    if (selected.length > 1)
    {
      toast.error('You are selecting multiple records. Please select just one of expense.')
      return false
    }
    return true
  }

  const showForm = () => {
    setShowExpenseForm(true)
  }

  const handleNewExpense = () => {
    setTitle("New expense")
    showForm()
  }

  const handleEditExpense = () => {
    if (!validateExpenseSelected()) return
    if (!validateExpenseSelectedMultiple()) return

    setTitle("Edit expense")
    showForm()
  }

  const handleDeleteExpense = () => {
    if (!validateExpenseSelected()) return

    setShowConfirmationDelete(true)
  }

  const handleCloseConfirmation = () => {
    if (!validateExpenseSelected()) return
    setShowConfirmationDelete(true)
  }

  const handleOkConfirm = async () => {
    dispatch(deleteExpense(expense.id))
    setShowConfirmationDelete(false) 
  }

  const handleCloneExpense = () => {
    setShowCloneExpenseConfirm(true)
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
          />
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleNewExpense}
            >
              <span className="d-none d-xl-inline-block ms-1">New</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="edit"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleEditExpense}
            >
              <span className="d-none d-xl-inline-block ms-1">Edit</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="clone"
              transform="shrink-2"
              iconAlign="middle"
              className="me-1"
              onClick={handleCloneExpense}
            >
              <span className="d-none d-xl-inline-block ms-1">Clone</span>
            </IconButton>
            
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="text-danger mb-1" onClick={handleDeleteExpense}>
                    <FontAwesomeIcon icon="trash-alt" /> Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Card.Header>
      </Card>

      {showConfirmationDelete && (
        <ConfirmationModal
          show={showConfirmationDelete}
          onHide={handleCloseConfirmation}
          onConfirm={handleOkConfirm}
          message="Are you sure to delete?"
        />
      )}

      {showExpenseForm && (
        <ExpenseForm
          showModal={showExpenseForm}
          setshowModal={setShowExpenseForm}
          title={title}
          record={expense}
        />
      )}

      {showCloneExpenseConfirm && (
        <CloneExpenseConfirm
          showModal={showCloneExpenseConfirm}
          setshowModal={setShowCloneExpenseConfirm}
          record={expense}
        />
      )}
      
    </>
  );
};

ExpenseHeader.propTypes = {
  expenseSelected: PropTypes.array
};

export default ExpenseHeader;
