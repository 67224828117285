const { Tabs, Tab, Card } = require("react-bootstrap")
import { FaGlobe, FaCalendar, FaInfoCircle } from 'react-icons/fa';
import React, { lazy, Suspense } from "react";
import { monthNameMapDecimal } from 'helpers/utils';
import PropTypes from 'prop-types';

const LazyJobEnteredItem = lazy(() => import("./JobEnteredItem"));
const LazyJobEntryDeail = lazy(() => import("./JobEntryDeail"));

const JobEnteredTracking = ({originalData}) => {
  const {monthly, sales_regions, data} = originalData
  const COLOR = "primary";

  const convertToMonthNames = (data) => {
    const newData = {};
    for (const key in data) {
      const monthName = monthNameMapDecimal[key];
      newData[monthName] = data[key];
    }
    return newData;
  };

  return (
    <>
      <Card className="h-100 mb-3" id="became-job">
        <Card.Header className="bg-light text-primary h5">
          Entered Jobs tracking
        </Card.Header>
        <Card.Body>
          <p className="fs--1">Entered jobs monthly or sales regions as period.</p>

          <Tabs defaultActiveKey="sales-region" id="job-entry" transition={false}>
            <Tab eventKey="sales-region" title={<span><FaGlobe /> Sales regions</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyJobEnteredItem data={sales_regions} color={COLOR} isSalesRegion={true} />
              </Suspense>
            </Tab>
            <Tab eventKey="became-job-monthly" title={<span><FaCalendar /> Monthly</span>} className='border-bottom border-x p-3'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyJobEnteredItem data={convertToMonthNames(monthly)} color={COLOR} />
              </Suspense>
            </Tab>
            <Tab eventKey="became-job-detail" title={<span><FaInfoCircle /> Detail</span>} className='border-bottom border-x'>
              <Suspense fallback={<div>Loding...</div>}>
                <LazyJobEntryDeail data={data} />
              </Suspense>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

JobEnteredTracking.propTypes = {
  originalData: PropTypes.object.isRequired
}

export default JobEnteredTracking;