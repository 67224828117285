import React from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { cancelBooking } from 'redux/booking/car/carBookingSlice';
import IconAlert from 'components/common/IconAlert';
import PropTypes from 'prop-types';

const TransactionDetailCancelModal = ({showModal, setshowModal, transaction}) => {
  const { register, handleSubmit, formState: {errors}, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const transactionId = transaction?.id;

  const onSubmit = (data) => {
    console.log(data)

    try {
      dispatch(cancelBooking({transactionId, data}))
      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  } 

  const closeModal = () => {
    setshowModal(false)
  }

  return (
  <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="border-0 bg-light">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Cancel car booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="info">
              <p className="mb-0">You are only allowed to cancel a booking when it has not been approved. You must specify the reason for the cancellation.</p>
            </IconAlert>

            <Form.Group as={Row} className="my-4" controlId="reason">
              <Form.Label column sm={3} className="text-lg-end">
                Reason
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="reason"
                  placeholder="Reason"
                  className="fs--1"
                  {...register("reason", {
                    required: "Reason must be required"
                  })}
                />
                <span className="text-danger">
                  {errors.reason && errors.reason.message}
                </span>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button size="sm" type="submit" variant="primary">Yes</Button>
            <Button size="sm" variant="secondary" onClick={closeModal}>No</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

TransactionDetailCancelModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired
}

export default TransactionDetailCancelModal;