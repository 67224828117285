import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { toast } from "react-toastify";

import {
  initialState,
  createInitialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper,
  handlePayload
} from "helpers/reducerHelpers";

const projectUrl = "/crm/projects"

export const getAllProjects = createAsyncThunk(
  "Project/getAllProjects",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getProject = createAsyncThunk(
  "Project/getProject",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createProject = createAsyncThunk(
  "Project/createProject",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(projectUrl, {project: data})
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateProject = createAsyncThunk(
  "Project/updateProject",
  async ({ projectId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${projectUrl}/${projectId}`, {project: data})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteProject = createAsyncThunk(
  "Project/deleteProject",
  async (project) => {
    try {
      const response = await apiAxios.delete(`${projectUrl}/${project.id}`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const convertQuoteNumber = createAsyncThunk(
  "Project/convertQuoteNumber",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/convert_quotation`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const changeStatusProject = createAsyncThunk(
  "Project/changeStatusProject",
  async ({projectId, data}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/change_status`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const updateQuotation= createAsyncThunk(
  "Project/updateQuotation",
  async ({ projectId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.put(`${projectUrl}/${projectId}/update_quotation`, {project: data})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const exportProjects = createAsyncThunk(
  "Project/exportProjects",
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${projectUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `projects-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
)

export const getActivityLog = createAsyncThunk(
  "Project/getActivityLog",
  async ({ projectId, params } ) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getBuildings = createAsyncThunk(
  "Project/getBuildings",
  async ({ projectId, params } ) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/buildings`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const assignQuotationToSSE = createAsyncThunk(
  "Project/assignQuotationToSSE",
  async ({ projectId, data } ) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/assign_to_sse`, data)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getNewPCVProjects = createAsyncThunk(
  "Project/getNewPCVProjects",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/new_pcvs`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getQuotationProjects = createAsyncThunk(
  "Project/getQuotationProjects",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/quotations`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getNegotiationProjects = createAsyncThunk(
  "Project/getNegotiationProjects",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/negotiations`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getJobProjects = createAsyncThunk(
  "Project/getJobProjects",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/jobs`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getLostProjects = createAsyncThunk(
  "Project/getLostProjects",
  async (params) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/losts`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const requestEstimateBuildings = createAsyncThunk(
  "Quotation/requestEstimateBuildings",
  async ({projectId, params}) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/request_estimate_buildings`, { estimate: params } );
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleEstimateBuildings = createAsyncThunk(
  "Quotation/scheduleEstimateBuildings",
  async ({projectId, params}) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/schedule_estimate_buildings`, { estimate: params } );
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseEstimateBuildings = createAsyncThunk(
  "Quotation/releaseEstimateBuildings",
  async ({projectId, data}) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/release_estimate_buildings`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const changeSalesUnitProject = createAsyncThunk(
  "Project/changeSalesUnitProject",
  async ({ projectId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/change_sales_unit`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const createFolderProject = createAsyncThunk(
  "Project/createFolderProject",
  async ({ projectId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/create_folder`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const getDocuments = createAsyncThunk(
  "Project/getDocuments",
  async ({projectId, params}, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/list_documents`, {params})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const uploadDocument = createAsyncThunk(
  "Project/uploadDocument",
  async ({ projectId, formData }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/upload_document`, formData, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeDocument = createAsyncThunk(
  "Project/removeDocument",
  async ( { projectId, assetId }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/remove_document`, { asset_id: assetId });
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const getProjectContracts = createAsyncThunk(
  "Project/getProjectContracts",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/${projectId}/load_project_contracts`);
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const syncProjectToOplaCrm = createAsyncThunk(
  "Project/syncProjectToOplaCrm",
  async ({ projectId, data }, { rejectWithValue }) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/sync_sales_process_opla`, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const lostReasonProject = createAsyncThunk(
  "Project/lostReasonProject",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAxios.get(`${projectUrl}/lost_reasons`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const closeLostProject = createAsyncThunk(
  "EnterJob/closeLostProject",
  async ({projectId, data}) => {
    try {
      const response = await apiAxios.post(`${projectUrl}/${projectId}/close_lost`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)


const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const projectSlice = createSlice({
  name: "crm_project",
  initialState: {
    ...initialState,
    newPCVData: createInitialState(),
    quotationData: createInitialState(),
    negotiationData: createInitialState(),
    jobData: createInitialState(),
    lostData: createInitialState()
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getNewPCVProjects.fulfilled, (state, action) => handleFulfilled(state, action, "newPCVData"))
      .addCase(getQuotationProjects.fulfilled, (state, action) => handleFulfilled(state, action, "quotationData"))
      .addCase(getNegotiationProjects.fulfilled, (state, action) => handleFulfilled(state, action, "negotiationData"))
      .addCase(getJobProjects.fulfilled, (state, action) => handleFulfilled(state, action, "jobData"))
      .addCase(getLostProjects.fulfilled, (state, action) => handleFulfilled(state, action, "lostData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))
      
      .addCase(getProject.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(createProject.fulfilled, (state, action) => handlePayload(state, action, "create"))
      .addCase(updateProject.fulfilled, (state, action) =>  handlePayload(state, action, "delete"))
      .addCase(convertQuoteNumber.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(updateQuotation.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(changeStatusProject.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(exportProjects.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportProjects.fulfilled, (state) => {
        state.exporting = false
        toast.success("Project has been exported successfully.")
      })
      .addCase(assignQuotationToSSE.fulfilled, (state, action) => handlePayload(state, action))

      .addCase(requestEstimateBuildings.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(scheduleEstimateBuildings.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(releaseEstimateBuildings.fulfilled, (state, action) => handlePayload(state, action))
      .addCase(changeSalesUnitProject.fulfilled, (state, action) =>  handlePayload(state, action))
      .addCase(closeLostProject.fulfilled, (state, action) =>  handlePayload(state, action))
      
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)

          // if (action.payload.status === 403) {
          //   console.log("403")
          //   window.location.href = '/error/403'
          // }
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = projectSlice.actions

export default projectSlice.reducer