import React, { useState } from 'react';
import { Card, Col, Row, Form } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import noAvatar from 'assets/img/team/avatar.png';
import UserDetailEmployeeMap from './UserDetailEmployeeMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserDetailChangeAvatar from './UserDetailChangeAvatar';
import UserDetailUpdateEmployee from './UserDetailUpdateEmployee';
import { MdConfirmationNumber, MdCreate, MdLock, MdLogin, MdManageAccounts } from 'react-icons/md';
import { FaUser, FaUserTie } from 'react-icons/fa';
import PropTypes from 'prop-types';

const UserDetailInfo = ({user}) => {
  const {employee} = user || {};

  const [showModalAvatar, setshowModalAvatar] = useState(false);
  const [showModalEmployee, setshowModalEmployee] = useState(false);

  const handleChangeAvatar = () => {
    setshowModalAvatar(true);
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-2 d-flex justify-content-between">
                <span><FaUser /> Name: <span className="text-primary">{user.name}</span></span>
                <span>ID#: <span className="text-primary">{user.user_code}</span></span>
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="border-top fs--1">
          <Row>
            <Col sm={3} className="text-center">
              {user && user.avatar_url ? 
                <Avatar src={user.avatar_url} rounded="circle" size="4xl" /> : <Avatar src={noAvatar} rounded="circle" size="4xl" />
              }
              <FontAwesomeIcon
                icon="camera"
                size='lg'
                style={{cursor: "pointer"}}
                title="Change avatar"
                onClick={handleChangeAvatar}
              />
            </Col>
            <Col>
              <Row>
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Full of name</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.full_name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Email:</p>
                </Col>
                <Col>
                  <a href = {`mailto: ${user.email}`} className="mb-1 fs--1 text-primary">{user.email}</a>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Site</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.site?.name}</p>
                </Col>
              </Row>
              <Row >
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Sales unit</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.sales_unit?.name} - {user.sales_unit?.description}</p>
                </Col>
              </Row>
              
              <Row className="my-2 mt-4">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold">Active</p>
                </Col>
                <Col>
                  <Form.Check 
                    type='switch'
                    id='user-active'
                    checked={user.active}
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold">View all sales unit on dashboard</p>
                </Col>
                <Col>
                  <Form.Check 
                    type='switch'
                    id='user-all_sales_units'
                    checked={user.all_sales_units}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0"><FaUserTie /> Employee mapping</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <UserDetailEmployeeMap employee={employee} setshowModal={setshowModalEmployee} />
        </Card.Body>
      </Card>
      
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0"><MdManageAccounts /> Account</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Row className="mb-5">
            <Col lg xxl={6}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase text-primary">
                <MdLogin /> Signed in
              </h6>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Current sign in date</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.current_sign_in_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Current sign in IP</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.current_sign_in_ip}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Sign in count</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.sign_in_count}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Remember created date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.remember_created_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Last sign in date</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.last_sign_in_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Failed attempts</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.failed_attempts}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">Provider</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.provider}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold fs--1 mb-1">UID</p>
                </Col>
                <Col>
                  <p className="mb-1 fs--1 text-primary">{user.uid}</p>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col xs={4} sm={4}>
                  <p className="fw-semi-bold mb-1 fs--1">Time zone</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.time_zone}</p>
                </Col>
              </Row>

            </Col>
            <Col lg className="mt-4 mt-lg-0">
              <h6 className="fw-semi-bold ls mb-3 text-uppercase text-primary">
                <MdLock /> Locked account
              </h6>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Locked date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.locked_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Reset password sent date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.reset_password_sent_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Reset password token</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.reset_password_token}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Unlock token</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.unlock_token}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg xxl={6}>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase text-primary">
                <MdConfirmationNumber /> Confirmation
              </h6>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Confirmed date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.confirmed_at}</p>
                </Col>
              </Row>

              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Confirmed sent date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.confirmation_sent_at}</p>
                </Col>
              </Row>

              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Unconfirmed email</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.unconfirmed_email}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <h6 className="fw-semi-bold ls mb-3 text-uppercase text-primary">
                <MdCreate />Created
              </h6>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Created by</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.created_by}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Created date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.created_at}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={5}>
                  <p className="fw-semi-bold mb-1 fs--1">Updated date</p>
                </Col>
                <Col>
                  <p className="mb-1 text-primary fs--1">{user.updated_at}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Tracking logs</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <UserDetailTrackingLog />
        </Card.Body>
      </Card> */}

      {showModalAvatar && (
        <UserDetailChangeAvatar
          user={user}
          showModal={showModalAvatar}
          setshowModal={setshowModalAvatar}
        />
      )}
      
      {showModalEmployee && (
        <UserDetailUpdateEmployee
          user={user}
          showModal={showModalEmployee}
          setShowModal={setshowModalEmployee}
        />
      )}
    </>
  );
};

UserDetailInfo.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserDetailInfo;
