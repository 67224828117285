import React from "react";
import ApplicationForm from "./ApplicationForm";
import { useLocation } from "react-router-dom";

const EditApplication = () => {
  const location = useLocation();
  const {record} = location.state;

  return (
    <ApplicationForm 
    application={record}
      title="Edit Application"
    />
  );
};

export default EditApplication;