import React, { Suspense, lazy } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import BookingHeader from './booking/BookingHeader';
import {  } from 'react-icons/md';
import { FaBan, FaCheck } from 'react-icons/fa';
import { MdCancel, MdPending, MdFiberNew, MdListAlt } from 'react-icons/md';

const AllList = lazy(() => import('./booking/list/All'));
const SubmitedList = lazy(() => import('./booking/list/Submited'));
const WaitingApprovalList = lazy(() => import('./booking/list/WaitingApproval'));
const WaitingConfirmationList = lazy(() => import('./booking/list/WaitingConfirmation'));
const WaitingVerificationList = lazy(() => import('./booking/list/WaitingVerification'));
const ApprovedList = lazy(() => import('./booking/list/Approved'));
const RejectedList = lazy(() => import('./booking/list/Rejected'));
const CanceledList = lazy(() => import('./booking/list/Canceled'));

const TravelBookings = () => {
  const { loading } = useSelector((state) => state.booking_setting_car);

  return (
    <>
      <BookingHeader />

      {loading && 
        <div className="text-center my-2">
          <Spinner animation="border" />
        </div>
      }

      <Row className="g-3">
        <Col>
          <Card>
            <Tab.Container defaultActiveKey="travel-booking-all">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom fs--1">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-all"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdListAlt className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> All</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-submited"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdFiberNew className="text-600 tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> New Submission</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-waiting-for-confirmation"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdPending className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> W. for confirmation</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-waiting-for-verification"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdPending className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> W. for verification</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-waiting-for-approval"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdPending className="text-warning tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> W. for approval</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-approved"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaCheck className="text-success tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Approved</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-rejected"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FaBan className="text-danger tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Rejected</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="travel-booking-canceled"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <MdCancel className="text-danger tab-icon" />
                        <h6 className="text-600 mb-0 ms-1"> Canceled</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="bg-light">
                <Suspense fallback={
                  <div className="text-center my-2">
                    <Spinner animation="border" />
                  </div>
                }>
                  <Tab.Content>
                    <Tab.Pane eventKey="travel-booking-all">
                      <AllList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-submited">
                      <SubmitedList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-waiting-for-confirmation">
                      <WaitingConfirmationList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-waiting-for-verification">
                      <WaitingVerificationList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-waiting-for-approval">
                      <WaitingApprovalList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-approved">
                      <ApprovedList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-rejected">
                      <RejectedList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="travel-booking-canceled">
                      <CanceledList />
                    </Tab.Pane>
                  </Tab.Content>
                </Suspense>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default TravelBookings
