import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiAxios from 'helpers/apiAxios';
import { toast } from 'react-toastify';
import { 
  initialState,
  handleFulfilled,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper,
  resetNestedParamsHelper,
  setSortParamsHelper,
  setFilterTagHelper,
  resetFilterTagHelper, 
  createInitialState
} from 'helpers/reducerHelpers';

const contactUrl = '/crm/contacts';

export const getAllContacts = createAsyncThunk(
  'Contact/getAllContacts',
  async (params) => {
    try {
      const response = await apiAxios.get(`${contactUrl}`, { params } );
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const getContact = createAsyncThunk(
  'Contact/getContact',
  async (contactId) => {
    try {
      const response = await apiAxios.get(`${contactUrl}/${contactId}`);
      return response.data;
    }
    catch(error) {
      throw new Error(error.message);
    }
  }
)

export const createContact = createAsyncThunk(
  'Contact/CreateContact',
  async ({ customerId, data }, { rejectWithValue }) => {
    try {
      const url = generateContactUrl(customerId);
      const response = await apiAxios.post(url, data);
      return response.data;
    }
    catch(error){
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
)

export const updateContact = createAsyncThunk(
  'Contact/updateContact',
  async ({ customerId, contactId, data }, { rejectWithValue }) => {
    try {
      const url = generateContactUrl(customerId);
      const response = await apiAxios.put(`${url}/${contactId}`, data);
      return response.data;
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteContact = createAsyncThunk(
  'Contact/deleteContact',
  async ({customerId, contact}, { rejectWithValue }) => {
    try {
      const url = generateContactUrl(customerId)
      const response = await apiAxios.delete(`${url}/${contact.id}`);
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
);

export const exportContacts = createAsyncThunk(
  'Contact/exportContacts',
  async (params) => {
    //remove page and per_page in object
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${contactUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `contacts-${params?.export_status}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
);

export const assignContact = createAsyncThunk(
  'Contact/assignContact',
  async ({ contactId, formData }) => {
    try {
      const response = await apiAxios.post(`${contactUrl}/${contactId}/assign_to`, formData)
      return response.data;
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getActivityLog = createAsyncThunk(
  'Contact/getActivityLog',
  async ({ contactId, params } ) => {
    try {
      const response = await apiAxios.get(`${contactUrl}/${contactId}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const generateContactUrl = (customerId) => {
  return customerId ? `/crm/companies/${customerId}/contacts` : contactUrl;
}

export const getCreatedFromLeadContacts = createAsyncThunk(
  'Contact/getCreatedFromLeadContacts',
  async (params) => {
    try {
      const response = await apiAxios.get(`${contactUrl}/created_from_lead_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getCreatedThisMonthContacts = createAsyncThunk(
  'Contact/getCreatedThisMonthContacts',
  async (params) => {
    try {
      const response = await apiAxios.get(`${contactUrl}/created_this_month_list`, { params } )
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const record = data.attributes;
    
    if (record !== null) {
      switch (type) {
        case "create": 
          state.allData.data.unshift(record);
          state.customerContacts.unshift(record);
          break;
        case "delete":
          state.allData.data = state.allData.data.filter((item) => item.id !== record.id);
          break;
        default:
        {
          const updatedIndex = state.allData.data.findIndex((item) => item.id === record.id);
          if (updatedIndex !== -1) {
            state.allData.data[updatedIndex] = record;
          }

          state.recordData = record;
          break;
        }
      }
    }
    toast.success(message);
  }
};

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const contactSlice = createSlice({
  name: 'crm_contact',
  initialState: {
    ...initialState,
    createdFromLeadData: createInitialState(),
    createdThisMonthData: createInitialState(),
    customerContacts: []
  },
  reducers: {
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper,
    resetNestedParams: resetNestedParamsHelper,
    setSortParams: setSortParamsHelper,
    setFilterTag: setFilterTagHelper,
    resetFilterTag: resetFilterTagHelper,

    setCustomerContacts: (state, action) => {
      state.customerContacts = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContacts.fulfilled, (state, action) => handleFulfilled(state, action, "allData"))
      .addCase(getCreatedFromLeadContacts.fulfilled, (state, action) => handleFulfilled(state, action, "createdFromLeadData"))
      .addCase(getCreatedThisMonthContacts.fulfilled, (state, action) => handleFulfilled(state, action, "createdThisMonthData"))
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))

      .addCase(getContact.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(createContact.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        handlePayload(state, action, "delete")
      })
      .addCase(exportContacts.pending, (state) => {
        state.exporting = true
      })
      .addCase(assignContact.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(exportContacts.fulfilled, (state) => {
        state.exporting = false
        toast.success('Contacts has been exported successfully.')
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.error.message)
        }
      );
  }
})

export const {
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  resetNestedParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
  setCustomerContacts
} = contactSlice.actions

export default contactSlice.reducer
