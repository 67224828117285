import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

import {
  handleFulfilled
} from "helpers/reducerHelpers";

const changeOrderUrl = (projectId, buildingId, changeOrderId) => (
  changeOrderId ? `/crm/projects/${projectId}/buildings/${buildingId}/change_orders/${changeOrderId}` : `/crm/projects/${projectId}/buildings/${buildingId}/change_orders`
)

import {
  createInitialState,
  setNestedPageHelper,
  setNestedPageSizeHelper,
  setNestedParamsHelper
} from "helpers/reducerHelpers";

export const getBuildingChangeOrders = createAsyncThunk(
  "ChangeOrder/getBuildingChangeOrders",
  async ({ projectId, buildingId, params } ) => {
    try {
      const url = changeOrderUrl(projectId, buildingId)
      const response = await apiAxios.get(url, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getChangeOrder = createAsyncThunk(
  "ChangeOrder/getChangeOrder",
  async ({ projectId, buildingId, changeOrderId } ) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.get(url)
      return response.data.attributes
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const createChangeOrder = createAsyncThunk(
  "ChangeOrder/createChangeOrder",
  async ({projectId, buildingId, data}, { rejectWithValue }) => {
    try {
      const url = changeOrderUrl(projectId, buildingId)
      const response = await apiAxios.post(url, data)

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateChangeOrder = createAsyncThunk(
  "ChangeOrder/updateChangeOrder",
  async ({ projectId, buildingId, changeOrderId, data }, { rejectWithValue }) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const copyChangeOrder = createAsyncThunk(
  "ChangeOrder/copyChangeOrder",
  async ({ projectId, buildingId, data }, { rejectWithValue }) => {
    try {
      const url = changeOrderUrl(projectId, buildingId)
      const response = await apiAxios.post(`${url}/copy_co`, data)
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const deleteChangeOrder = createAsyncThunk(
  "ChangeOrder/deleteChangeOrder",
  async ({projectId, buildingId, changeOrderId}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.delete(url);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestConfirmChangerOrder = createAsyncThunk(
  "ChangeOrder/requestConfirmChangerOrder",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/request_confirm`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestReviseChangerOrder = createAsyncThunk(
  "ChangeOrder/requestReviseChangerOrder",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/request_revise`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const revokeChangerOrder = createAsyncThunk(
  "ChangeOrder/revokeChangerOrder",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/revoke`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const confirmChangeOrder = createAsyncThunk(
  "ChangeOrder/confirmChangeOrder",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/confirm`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const finalRequestScheduleToENG = createAsyncThunk(
  "ChangeOrder/finalRequestScheduleToENG",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/final_enter_job_request`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const finalScheduleToCSD = createAsyncThunk(
  "ChangeOrder/finalScheduleToCSD",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/final_enter_job_schedule`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const finalScheduleToSales = createAsyncThunk(
  "ChangeOrder/finalScheduleToSales",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/final_enter_job_release`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const closeChangeOrder = createAsyncThunk(
  "ChangeOrder/closeChangeOrder",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/close`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const clonePIFVersion = createAsyncThunk(
  "ChangeOrder/clonePIFVersion",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/clone_pif_version`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadCODocument = (params) => uploadDocument({ ...params, endpoint: 'upload_co_form_document' })
export const uploadPPDDocument = (params) => uploadDocument({ ...params, endpoint: 'upload_ppd_rad_document' })
export const uploadEstimateReleasedDocument = (params) => uploadDocument({ ...params, endpoint: 'upload_estimate_release_document' })
export const uploadABErectionDocument = (params) => uploadDocument({ ...params, endpoint: 'upload_ab_erection_document' })

export const removeCODocument = (params) => uploadDocument({ ...params, endpoint: 'remove_co_form_document' })
export const removePPDDocument = (params) => uploadDocument({ ...params, endpoint: 'remove_ppd_rad_document' })
export const removeEstimateReleasedDocument = (params) => uploadDocument({ ...params, endpoint: 'remove_estimate_release_document' })
export const removeABErectionDocument = (params) => uploadDocument({ ...params, endpoint: 'remove_ab_erection_document' })


const uploadDocument = createAsyncThunk(
  "ChangeOrder/uploadDocument",
  async ({ projectId, buildingId, changeOrderId, data, endpoint }, { rejectWithValue }) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId);
      const response = await apiAxios.post(`${url}/${endpoint}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getRequestScheduleMailer = createAsyncThunk(
  "ChangeOrder/getRequestScheduleMailer",
  async ({projectId, buildingId, changeOrderId, params}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.get(`${url}/request_schedule_release_mailer`, {params})
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestEstimateToENG = createAsyncThunk(
  "ChangeOrder/requestEstimateToENG",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/estimate_request`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleEstimateToCSD = createAsyncThunk(
  "ChangeOrder/scheduleEstimateToCSD",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/estimate_schedule`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseEstimateToCSD = createAsyncThunk(
  "ChangeOrder/releaseEstimateToCSD",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/estimate_release`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const confirmEstimateToSales = createAsyncThunk(
  "ChangeOrder/confirmEstimateToSales",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/estimate_confirm`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const rejectEstimateToENG = createAsyncThunk(
  "ChangeOrder/rejectEstimateToENG",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/estimate_reject`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestABErectionToENG = createAsyncThunk(
  "ChangeOrder/requestABErectionToENG",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/ab_erection_request`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleABErectionToCSD = createAsyncThunk(
  "ChangeOrder/scheduleABErectionToCSD",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/ab_erection_schedule`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseABErectionToCSD = createAsyncThunk(
  "ChangeOrder/releaseABErectionToCSD",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/ab_erection_release`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

//---------------------------------------APD----------------------------------------

const partUrl = (projectId, buildingId, changeOrderId, partId) => (
  partId ? `${changeOrderUrl(projectId, buildingId, changeOrderId)}/parts/${partId}` : `${changeOrderUrl(projectId, buildingId, changeOrderId)}/parts`
)

export const createAPD = createAsyncThunk(
  "ChangeOrder/createAPD",
  async ({projectId, buildingId, changeOrderId, data}, { rejectWithValue }) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(url, data)

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateAPD = createAsyncThunk(
  "ChangeOrder/updateAPD",
  async ({ projectId, buildingId, changeOrderId, partId, data }, { rejectWithValue }) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId, partId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const deleteAPD = createAsyncThunk(
  "ChangeOrder/deleteAPD",
  async ({projectId, buildingId, changeOrderId, partId}) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId, partId)
      const response = await apiAxios.delete(url);
      return { partId, message: response.data.message, error: response.data.error || undefined}
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestAPD = createAsyncThunk(
  "ChangeOrder/requestAPD",
  async ({projectId, buildingId, changeOrderId, partId, data}) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId, partId)
      const response = await apiAxios.post(`${url}/apd_request`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleAPD = createAsyncThunk(
  "ChangeOrder/scheduleAPD",
  async ({projectId, buildingId, changeOrderId, partId, data}) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId, partId)
      const response = await apiAxios.post(`${url}/apd_schedule`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseAPD = createAsyncThunk(
  "ChangeOrder/releaseAPD",
  async ({projectId, buildingId, changeOrderId, partId, data}) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId, partId)
      const response = await apiAxios.post(`${url}/apd_release`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const removeAPDDocument = createAsyncThunk(
  "ChangeOrder/removeAPDDocument",
  async ({ projectId, buildingId, changeOrderId, partId, data }, { rejectWithValue }) => {
    try {
      const url = partUrl(projectId, buildingId, changeOrderId, partId);
      const response = await apiAxios.post(`${url}/remove_document`, data)
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const confirmApdToSales = createAsyncThunk(
  "ChangeOrder/confirmApdToSales",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/apd_confirm`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const rejectApdToENG = createAsyncThunk(
  "ChangeOrder/rejectApdToENG",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/apd_reject`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

//---------------------------------------Shop-----------------------------------------

const phaseUrl = (projectId, buildingId, changeOrderId, phaseId) => (
  phaseId ? `${changeOrderUrl(projectId, buildingId, changeOrderId)}/phases/${phaseId}` : `${changeOrderUrl(projectId, buildingId, changeOrderId)}/phases`
)

export const createShop = createAsyncThunk(
  "ChangeOrder/createShop",
  async ({projectId, buildingId, changeOrderId, data}, { rejectWithValue }) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(url, data)

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateShop = createAsyncThunk(
  "ChangeOrder/updateShop",
  async ({ projectId, buildingId, changeOrderId, phaseId, data }, { rejectWithValue }) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId, phaseId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const deleteShop = createAsyncThunk(
  "ChangeOrder/deleteShop",
  async ({projectId, buildingId, changeOrderId, phaseId}) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId, phaseId)
      const response = await apiAxios.delete(url);
      return { phaseId, message: response.data.message, error: response.data.error || undefined}
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestShop = createAsyncThunk(
  "ChangeOrder/requestShop",
  async ({projectId, buildingId, changeOrderId, phaseId, data}) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId, phaseId)
      const response = await apiAxios.post(`${url}/shop_request`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleShop = createAsyncThunk(
  "ChangeOrder/scheduleShop",
  async ({projectId, buildingId, changeOrderId, phaseId, data}) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId, phaseId)
      const response = await apiAxios.post(`${url}/shop_schedule`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseShop = createAsyncThunk(
  "ChangeOrder/releaseShop",
  async ({projectId, buildingId, changeOrderId, phaseId, data}) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId, phaseId)
      const response = await apiAxios.post(`${url}/shop_release`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const removeShopDocument = createAsyncThunk(
  "ChangeOrder/removeShopDocument",
  async ({ projectId, buildingId, changeOrderId, phaseId, data }, { rejectWithValue }) => {
    try {
      const url = phaseUrl(projectId, buildingId, changeOrderId, phaseId);
      const response = await apiAxios.post(`${url}/remove_document`, data)
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

//---------------------------------------Shop Revision--------------------------------

const phaseRevisionUrl = (projectId, buildingId, changeOrderId, phaseId, detailId) => (
  detailId ? `${changeOrderUrl(projectId, buildingId, changeOrderId)}/phases/${phaseId}/phase_details/${detailId}` : `${changeOrderUrl(projectId, buildingId, changeOrderId)}/phases/${phaseId}/phase_details`
)

export const createShopRevision = createAsyncThunk(
  "ChangeOrder/createShopRevision",
  async ({projectId, buildingId, changeOrderId, phaseId, data}, { rejectWithValue }) => {
    try {
      const url = phaseRevisionUrl(projectId, buildingId, changeOrderId, phaseId)
      const response = await apiAxios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateShopRevision = createAsyncThunk(
  "ChangeOrder/updateShopRevision",
  async ({ projectId, buildingId, changeOrderId, phaseId, detailId, data }, { rejectWithValue }) => {
    try {
      const url = phaseRevisionUrl(projectId, buildingId, changeOrderId, phaseId, detailId)
      const response = await apiAxios.put(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const deleteShopRevision = createAsyncThunk(
  "ChangeOrder/deleteShopRevision",
  async ({projectId, buildingId, changeOrderId, phaseId, detailId}) => {
    try {
      const url = phaseRevisionUrl(projectId, buildingId, changeOrderId, phaseId, detailId)
      const response = await apiAxios.delete(url)
      //return { phase: response.data.phase, message: response.data.message, error: response.data.error || undefined}
      return { phase_id: phaseId, detailId, message: response.data.message, error: response.data.error || undefined}
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const removeShopRevisionDocument = createAsyncThunk(
  "ChangeOrder/removeShopRevisionDocument",
  async ({ projectId, buildingId, changeOrderId, phaseId, detailId, data }, { rejectWithValue }) => {
    try {
      const url = phaseRevisionUrl(projectId, buildingId, changeOrderId, phaseId, detailId)
      const response = await apiAxios.post(`${url}/remove_document`, data)
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

//---------------------------------------Other----------------------------------------

const otherUrl = (projectId, buildingId, changeOrderId, otherId) => (
  otherId ? `${changeOrderUrl(projectId, buildingId, changeOrderId)}/others/${otherId}` : `${changeOrderUrl(projectId, buildingId, changeOrderId)}/others`
)

export const createOther = createAsyncThunk(
  "ChangeOrder/createOther",
  async ({projectId, buildingId, changeOrderId, data}, { rejectWithValue }) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(url, data)

      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateOther = createAsyncThunk(
  "ChangeOrder/updateOther",
  async ({ projectId, buildingId, changeOrderId, otherId, data }, { rejectWithValue }) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: 'An unexpected error occurred.' });
      }
    }
  }
)

export const deleteOther = createAsyncThunk(
  "ChangeOrder/deleteOther",
  async ({projectId, buildingId, changeOrderId, otherId}) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId)
      const response = await apiAxios.delete(url);
      return { otherId, message: response.data.message, error: response.data.error || undefined}
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestOther = createAsyncThunk(
  "ChangeOrder/requestOther",
  async ({projectId, buildingId, changeOrderId, otherId, data}) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId)
      const response = await apiAxios.post(`${url}/other_request`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const scheduleOther = createAsyncThunk(
  "ChangeOrder/scheduleOther",
  async ({projectId, buildingId, changeOrderId, otherId, data}) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId)
      const response = await apiAxios.post(`${url}/other_schedule`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const releaseOther = createAsyncThunk(
  "ChangeOrder/releaseOther",
  async ({projectId, buildingId, changeOrderId, otherId, data}) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId)
      const response = await apiAxios.post(`${url}/other_release`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const removeOtherDocument = createAsyncThunk(
  "ChangeOrder/removeOtherDocument",
  async ({ projectId, buildingId, changeOrderId, otherId, data }, { rejectWithValue }) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId);
      const response = await apiAxios.post(`${url}/remove_document`, data)
      return response.data

    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getRequestScheduleMailerOther = createAsyncThunk(
  "ChangeOrder/getRequestScheduleMailerOther",
  async ({projectId, buildingId, changeOrderId, otherId, params}) => {
    try {
      const url = otherUrl(projectId, buildingId, changeOrderId, otherId)
      const response = await apiAxios.get(`${url}/request_schedule_release_mailer`, {params})
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const getActivityLog = createAsyncThunk(
  "ChangeOrder/getActivityLog",
  async ({ projectId, buildingId, changeOrderId, params } ) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.get(`${url}}/activity_log`, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const showOnMaster = createAsyncThunk(
  "ChangeOrder/showOnMaster",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/show_master`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const updateEstWithoutChange = createAsyncThunk(
  "ChangeOrder/updateEstWithoutChange",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/update_est_without_change`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const updateFullReleaseBom = createAsyncThunk(
  "ChangeOrder/updateFullReleaseBom",
  async ({projectId, buildingId, changeOrderId, data}) => {
    try {
      const url = changeOrderUrl(projectId, buildingId, changeOrderId)
      const response = await apiAxios.post(`${url}/update_full_release_bom`, data)
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)


export const downloadCOForm = createAsyncThunk(
  "PIF/downloadCOForm",
  async ({projectId, buildingId, changeOrderId}) => {
    const coUrl = changeOrderUrl(projectId, buildingId, changeOrderId)
    const response = await apiAxios.get(`${coUrl}/print`, {responseType: 'blob'})
    
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'pif.pdf'; // Fallback filename
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+?)"/);
      if (match && match[1]) {
        filename = decodeURIComponent(match[1]);
      }
    }

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handleListPayload = (buildingState, action, type="") => {
  const { error, data, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    let changeOrder = null

    switch (type) {
      case "create":
        changeOrder = data.attributes;
        buildingState.data.unshift(changeOrder)
        break

      case "delete":
        if (data?.id) {
          buildingState.data = buildingState.data.filter((item) => item.id !== data?.id)
        }
        break

      default:
      {
        changeOrder = data.attributes

        if (changeOrder) {
          const updatedIndex = buildingState.data.findIndex((item) => item.id === changeOrder.id)
          if (updatedIndex !== -1) {
            buildingState.data[updatedIndex] = changeOrder
          }
        }
        break;
      }
    }

    toast.success(message)
  }
}

const handlePayload = (state, action) => {
  const { error, data, message } = action.payload

  if (error !== undefined) {
    toast.error(error);
  } else {
    const change_order = data.attributes

    if (change_order && state.changeOrderData.id === change_order.id) {
      state.changeOrderData = change_order
    }
    toast.success(message);
  }
}

const handlePayloadAPD = (state, action, type="") => {
  const { error, data, partId, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    let part = null

    switch (type) {
      case "create":
        part = data.attributes;
        state.changeOrderData.parts.unshift(part)
        break

      case "delete":
        if (partId) {
          state.changeOrderData.parts = state.changeOrderData.parts.filter((item) => item.id !== partId)
        }
        break

      default:
      {
        part = data.attributes

        if (part) {
          const updatedIndex = state.changeOrderData.parts.findIndex((item) => item.id === part.id)
          if (updatedIndex !== -1) {
            state.changeOrderData.parts[updatedIndex] = part
          }
        }
        break;
      }
    }

    toast.success(message)
  }
}

const handlePayloadShop = (state, action, type="") => {
  const { error, data, phaseId, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    let shop = null

    switch (type) {
      case "create":
        shop = data.attributes;
        state.changeOrderData.phases.unshift(shop)
        break

      case "delete":
        if (phaseId) {
          state.changeOrderData.phases = state.changeOrderData.phases.filter((item) => item.id !== phaseId)
        }
        break

      default:
      {
        shop = data.attributes

        if (shop) {
          const updatedIndex = state.changeOrderData.phases.findIndex((item) => item.id === shop.id)
          if (updatedIndex !== -1) {
            state.changeOrderData.phases[updatedIndex] = shop
          }
        }
        break;
      }
    }

    toast.success(message)
  }
}

const handlePayloadShopRevision = (state, action, type="") => {
  const { error, phase_id, data, detailId, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    let detail = null

    if (phase_id) {
      const phase = state.changeOrderData.phases.find((item) => item.id === phase_id)

      if (phase) {
        switch (type) {
          case "create":
            detail = data.attributes
            phase.details.unshift(detail)
            
            break
    
          case "delete":
            if (detailId) {
              phase.details = phase.details.filter((item) => item.id !== detailId)
            }
            break
    
          default:
          {
            detail = data.attributes
    
            if (phase) {
              const updatedIndex = phase.details.findIndex((item) => item.id === detail.id)
              if (updatedIndex !== -1) {
                phase.details[updatedIndex] = detail
              }
            }
            break;
          }
        }
    
        toast.success(message)
      }
    }
    else {
      toast.error("No Phase return.")
    }
  }
}

const handlePayloadOther = (state, action, type="") => {
  const { error, data, otherId, message } = action.payload

  if (error !== undefined) {
    toast.error(error)
  } else {
    let other = null

    switch (type) {
      case "create":
        other = data.attributes;
        state.changeOrderData.others.unshift(other)
        break

      case "delete":
        if (otherId) {
          state.changeOrderData.others = state.changeOrderData.others.filter((item) => item.id !== otherId)
        }
        break

      default:
      {
        other = data.attributes

        if (other) {
          const updatedIndex = state.changeOrderData.others.findIndex((item) => item.id === other.id)
          if (updatedIndex !== -1) {
            state.changeOrderData.others[updatedIndex] = other
          }
        }
        break;
      }
    }

    toast.success(message)
  }
}

const changeOrderSlide = createSlice({
  name: "crm_change_order",
  initialState: {
    buildings: {
      buildingId: {
        id: 0,
        data: [],
        totalRows: 0,
        loading: false,
        exporting: false,
      }
    },
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    changeOrderData: {},
    mailer: {},
    enterJobActiveKey: ["0"],
    activityLogData: createInitialState()
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    },
    setEnterJobActiveKey: (state, action) => {
      state.enterJobActiveKey = action.payload
    },
    setNestedPage: setNestedPageHelper,
    setNestedPageSize: setNestedPageSizeHelper,
    setNestedParams: setNestedParamsHelper
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuildingChangeOrders.pending, (state) => {
        state.loading = true
      })
      .addCase(getBuildingChangeOrders.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload
        const buildingId = results.building_id

        const existingBuilding = state.buildings[buildingId]
        if (existingBuilding) {
          // Update the existing building
          existingBuilding.data = objectSerializer(results.rows)
          existingBuilding.totalRows = results.total_rows
        }
        else {
          // Add a new building
          state.buildings[buildingId] = {
            id: buildingId,
            data: objectSerializer(results.rows),
            totalRows: results.total_rows,
            loading: false,
            exporting: false,
          }
        }
      })
      .addCase(getChangeOrder.fulfilled, (state, action) => {
        state.changeOrderData = action.payload
      })
      .addCase(createChangeOrder.fulfilled, (state, action) => {
        handleListPayload(state.buildings.buildingId, action, "create")
      })
      .addCase(updateChangeOrder.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(deleteChangeOrder.fulfilled, (state, action) => {
        handleListPayload(state.buildings.buildingId, action, "delete")
      })
      .addCase(copyChangeOrder.fulfilled, (state, action) => {
        handleListPayload(state.buildings.buildingId, action, "create")
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      }) 
      .addCase(getRequestScheduleMailer.fulfilled, (state, action) => {
        state.mailer = action.payload
      })
      .addCase(requestABErectionToENG.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(scheduleABErectionToCSD.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(releaseABErectionToCSD.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(requestEstimateToENG.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(scheduleEstimateToCSD.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(releaseEstimateToCSD.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(confirmEstimateToSales.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(rejectEstimateToENG.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      //-------------------------APD------------------------
      .addCase(createAPD.fulfilled, (state, action) => {
        handlePayloadAPD(state, action, "create")
      })
      .addCase(updateAPD.fulfilled, (state, action) => {
        handlePayloadAPD(state, action)
      })
      .addCase(deleteAPD.fulfilled, (state, action) => {
        handlePayloadAPD(state, action, "delete")
      })
      .addCase(requestAPD.fulfilled, (state, action) => {
        handlePayloadAPD(state, action)
      })
      .addCase(scheduleAPD.fulfilled, (state, action) => {
        handlePayloadAPD(state, action)
      })
      .addCase(releaseAPD.fulfilled, (state, action) => {
        handlePayloadAPD(state, action)
      })
      .addCase(removeAPDDocument.fulfilled, (state, action) => {
        handlePayloadAPD(state, action)
      })
      .addCase(confirmApdToSales.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(rejectApdToENG.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      //-------------------------Shop------------------------
      .addCase(createShop.fulfilled, (state, action) => {
        handlePayloadShop(state, action, "create")
      })
      .addCase(updateShop.fulfilled, (state, action) => {
        handlePayloadShop(state, action)
      })
      .addCase(deleteShop.fulfilled, (state, action) => {
        handlePayloadShop(state, action, "delete")
      })
      .addCase(requestShop.fulfilled, (state, action) => {
        handlePayloadShop(state, action)
      })
      .addCase(scheduleShop.fulfilled, (state, action) => {
        handlePayloadShop(state, action)
      })
      .addCase(releaseShop.fulfilled, (state, action) => {
        handlePayloadShop(state, action)
      })
      .addCase(removeShopDocument.fulfilled, (state, action) => {
        handlePayloadShop(state, action)
      })
      //----------------------Phase revision---------------
      .addCase(createShopRevision.fulfilled, (state, action) => {
        handlePayloadShopRevision(state, action, "create")
      })
      .addCase(updateShopRevision.fulfilled, (state, action) => {
        handlePayloadShopRevision(state, action)
      })
      .addCase(deleteShopRevision.fulfilled, (state, action) => {
        handlePayloadShopRevision(state, action, "delete")
      })
      .addCase(removeShopRevisionDocument.fulfilled, (state, action) => {
        handlePayloadShopRevision(state, action)
      })
      //----------------------Other------------------------
      .addCase(createOther.fulfilled, (state, action) => {
        handlePayloadOther(state, action, "create")
      })
      .addCase(updateOther.fulfilled, (state, action) => {
        handlePayloadOther(state, action)
      })
      .addCase(deleteOther.fulfilled, (state, action) => {
        handlePayloadOther(state, action, "delete")
      })
      .addCase(requestOther.fulfilled, (state, action) => {
        handlePayloadOther(state, action)
      })
      .addCase(scheduleOther.fulfilled, (state, action) => {
        handlePayloadOther(state, action)
      })
      .addCase(releaseOther.fulfilled, (state, action) => {
        handlePayloadOther(state, action)
      })
      .addCase(removeOtherDocument.fulfilled, (state, action) => {
        handlePayloadOther(state, action)
      })
      .addCase(getRequestScheduleMailerOther.fulfilled, (state, action) => {
        state.mailer = action.payload
      })
      .addCase(requestConfirmChangerOrder.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(requestReviseChangerOrder.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(revokeChangerOrder.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(confirmChangeOrder.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(finalRequestScheduleToENG.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(finalScheduleToCSD.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(finalScheduleToSales.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(closeChangeOrder.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(showOnMaster.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateEstWithoutChange.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updateFullReleaseBom.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(clonePIFVersion.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(getActivityLog.fulfilled, (state, action) => handleFulfilled(state, action, "activityLogData"))
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams,
  resetParams,
  setEnterJobActiveKey,
  setNestedPage,
  setNestedPageSize,
  setNestedParams
} = changeOrderSlide.actions;

export default changeOrderSlide.reducer;