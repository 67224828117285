import { useSelector } from "react-redux";
import { getWarranties } from "redux/crm/project/warrantySlice";
import GridTable from "./component/GridTable";
import React from 'react';

const WarrantyList = () => {
  const { allData } = useSelector((state) => state.crm_warranty);
  const entryType = "all";

  return (
    <>
      <div className="fs--1">Summary of warranties.</div>

      <GridTable
        recordType={entryType}
        recordData={allData}
        loadApi={getWarranties}
        recordKey={`${entryType}Data`}
      />
    </>
  );
};

export default WarrantyList;