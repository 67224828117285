import useAuth from 'hooks/useAuth';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  return isAuthenticated
    ? <Outlet /> : (
    <Navigate
      to={`${process.env.REACT_APP_CLIENT_LOGIN_URL}`}
      state={{ from: location }} // <-- pass in route state
      replace
    />
  );
};

export default PrivateRoute;