import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { cloneBooking } from "redux/booking/car/carBookingSlice";
import IconAlert from 'components/common/IconAlert';
import { FaClone, FaBan } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

const CloneBookingConfirm = ({showModal, setshowModal, transaction}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const transactionId = transaction.id;

    try {
      const response = await dispatch(cloneBooking(transactionId));
      const {data, message} = response.payload;

      closeModal();
      toast.success(message);

      if (data.id) {
        navigate(`/booking/car/${data.id}`);
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setshowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" keyboard={false}
        className="fs--1"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="bg-light border-0">
            <Modal.Title className="fs-0" id="contained-modal-title-vcenter"><FaClone /> Clone new car booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IconAlert variant="warning">
              <p className="mb-0">Are you sure to clone new car booking from current booking number {transaction.booking_number}?</p>
            </IconAlert>
          </Modal.Body>
          <Modal.Footer className="bg-light border-0">
            <Button
              size="sm"
              variant="primary"
              type="submit"
            >
              <FaClone /> Clone
            </Button>
            <Button size="sm" variant="falcon-default" onClick={closeModal}><FaBan /> Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

CloneBookingConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired
}

export default CloneBookingConfirm;