import { Modal, Button, Card, Row, Col } from "react-bootstrap"
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import IconButton from 'components/common/IconButton';
import { ExportToExcel } from "helpers/utils";;
import { Link } from "react-router-dom";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import React from 'react';
import PropTypes from 'prop-types';

const DetailViewModal = ({showModal, setshowModal, data, title }) => {
  const columns = [
    {
      accessor: 'id',
      Header: 'ID#',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold'
      },
      Cell: rowData => {
        const { project_id } = rowData.row.original;
        return <Link to={`/crm/projects/${project_id}`}>{project_id}</Link>;
      }
    },
    {
      accessor: 'j_number',
      Header: 'P/J number',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { project_id } = rowData.row.original;
        return <Link to={`/crm/projects/${project_id}`}>{project_id}</Link>;
      }
    },
    {
      accessor: 'building_name',
      Header: 'Building name'
    },
    {
      accessor: 'co',
      Header: 'CO#'
    },
    {
      accessor: 'co_status',
      Header: 'Status'
    }
  ];

  const handleExport = (data) => {
    ExportToExcel(data, "change-order-status")
  }

  const closeModal = () => {
    setshowModal(false)
  }

  const handleCancel = () => {
    closeModal()
  }
  
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" keyboard={false}
      className="fs--1"
      size="lg"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fs-0" id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          selection
          sortable
          pagination
          perPage={10}
          rowCount={data?.length}
        >
          <Card className="p-2">
            <Card.Header>
              <Row>
                <Col md={{span: 9, offset: 6}}>
                  <Row className="gx-1">
                    <Col>
                      <AdvanceTableSearchBox table/>
                    </Col>
                    <Col>
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={() => handleExport(data)}
                      >
                        <span className="ms-1">Export</span>
                      </IconButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter rowCount={data?.length} table rowInfo navButtons />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button size="sm" variant="falcon-default" onClick={handleCancel}>Close</Button>
      </Modal.Footer>

    </Modal>
  )
};

DetailViewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default DetailViewModal;