import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const iccctUrl = `/crm/reports/icccc`;

export const getIcccs = createAsyncThunk(
  "ReportICCC/getIcccs",
  async (params) => {
    try {
      const response = await apiAxios.get(iccctUrl, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportData = createAsyncThunk(
  "ReportICCC/exportData",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${iccctUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'iccc.xlsx');
    document.body.appendChild(link);
    link.click();
  }
)

export const getColumns = createAsyncThunk(
  "ReportICCC/getColumns",
  async () => {
    try {
      const response = await apiAxios.get(`${iccctUrl}/attributes`)
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const icccSlice = createSlice({
  name: "report_iccc",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    currentParamsOriginal: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    filterTags: [],
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    },
    setFilterTag: (state, action) => {
      state.filterTags = action.payload
    },
    resetFilterTag: (state) => {
      state.filterTags = []
    },
    setCurrentParamsOriginal: (state, action) => {
      state.currentParamsOriginal = action.payload
    },
    resetCurrentParamsOriginal: (state) => {
      state.currentParamsOriginal = {page: 1, per_page: 100}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIcccs.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate           = state.paginate
        const calRecords         = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(getColumns.fulfilled, (state, action) => {
        state.sortColumns = action.payload.sort_columns
      })
      .addCase(exportData.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportData.fulfilled, (state) => {
        state.exporting = false
        toast.success("Data has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams,
  setFilterTag,
  resetFilterTag,
  setCurrentParamsOriginal,
  resetCurrentParamsOriginal
} = icccSlice.actions;

export default icccSlice.reducer;