import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';
import classNames from 'classnames';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { Card, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DetailViewModal from '../DetailViewModal';
import PropTypes from 'prop-types';


const Notification = ({ notification: { title, type, co_type, value }, isLast, viewDetail }) => {
  return (
    <li
      className={classNames(`alert mb-0 rounded-0 py-3 px-x1 alert-${type}`, {
        'border-top': !(type === 'warning'),
        'border-0': isLast,
        'border-x-0 border-top-0': !isLast
      })}
    >
      <Row className="flex-between-center">
        <Col>
          <Flex>
            <FontAwesomeIcon
              icon="circle"
              className={classNames('mt-1 fs--2', {
                'text-primary': !type
              })}
            />
            <p className="fs--1 ps-2 mb-0">{value} {title}</p>
          </Flex>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <Link to="#!" className="alert-link fs--1 fw-medium" onClick={() => viewDetail(title, co_type)}>
            View detail
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Link>
        </Col>
      </Row>
    </li>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    co_type: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  isLast: PropTypes.bool.isRequired,
  viewDetail: PropTypes.func.isRequired
}


const GreetingCard = ({data}) => {
  const {userName} = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState([])
  const [title, setTitle] = useState(null);

  const withoutCO00 = data && data.filter(item => item.co !== '00')
  const totalChangeOrders = withoutCO00?.length || 0
  
  const projectObject = data && data.reduce((acc, item) => {
    acc[item.project_id] = (acc[item.project_id] || 0) + 1;
    return acc
  }, {});

  const totalProjects = projectObject && Object.keys(projectObject)?.length || 0

  const WAITING_FOR_CONFIRMATION_BY_CSE = "Waiting for confirmation by CSE";
  const waitingByCSDCO = data && data.filter(item => item.co_status === WAITING_FOR_CONFIRMATION_BY_CSE)
  const rejectedCO = data && data.filter(item => item.co_status === "rejected")

  const notifications = [
    {
      id: 1,
      title: WAITING_FOR_CONFIRMATION_BY_CSE,
      type: "warning",
      co_type: "waiting",
      value: waitingByCSDCO?.length || 0
    },
    {
      id: 2,
      title: "Rejected",
      type: "danger",
      co_type: "rejected",
      value: rejectedCO?.length || 0
    }
  ]

  const handleViewDetail = (title, type) => {
    setShowModal(true);
    setTitle(title);

    switch(type) {
      case "waiting":
        setDetailData(waitingByCSDCO);
        break;
      case "rejected":
        setDetailData(rejectedCO);
        break;
      default:
        setDetailData([]);
        break;
    }
  };


  return (
    <>
      <Card className="bg-transparent-50 overflow-hidden h-100">
        <Card.Header className="position-relative">
          <Background
            image={ecomBg}
            className="d-none d-md-block bg-card z-index-1"
            style={{
              backgroundSize: '230px',
              backgroundPosition: 'right bottom',
              zIndex: '-1'
            }}
          />
          <div className="position-relative z-index-2">
            <div>
              <h3 className="text-primary mb-1">Good Afternoon, {userName}!</h3>
              <p className="text-700">
                Here’s what happening change order of this month
              </p>
            </div>
            <Flex className="py-3">
              <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">Change orders</p>
                <h3 className="text-800 mb-0">{totalChangeOrders}</h3>
              </div>
              <div className="ps-3">
                <p className="text-600 fs--1">Projects</p>
                <h3 className="mb-0 text-primary">{totalProjects}</h3>
              </div>
            </Flex>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <ul className="mb-0 list-unstyled">
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                viewDetail={handleViewDetail}
              />
            ))}
          </ul>
        </Card.Body>
      </Card>

      {showModal && (
        <DetailViewModal
          showModal={showModal}
          setshowModal={setShowModal}
          data={detailData}
          title={title}
        />
      )}
    </>
  );
};

GreetingCard.propTypes = {
  data: PropTypes.array.isRequired
}

export default GreetingCard;