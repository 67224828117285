import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { calPaginatorRecord } from "helpers/paginator";
import { toast } from "react-toastify";

const projectUrl = "/crm/projects";

const generalEnterJobUrl = (projectId, enterJobId) => (
  enterJobId ? `${projectUrl}/${projectId}/enter_jobs/${enterJobId}` : `${projectUrl}/${projectId}/enter_jobs`
)

export const getEnterJobs = createAsyncThunk(
  "EnterJob/getEnterJobs",
  async ({ projectId, params } ) => {
    try {
      const url = generalEnterJobUrl(projectId)
      const response = await apiAxios.get(url, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const getEnterJob = createAsyncThunk(
  "EnterJob/getEnterJob",
  async ({projectId, enterJobId}, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.get(url)
      return response.data.attributes
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getBuildingsEnterJob = createAsyncThunk(
  "EnterJob/getBuildingsEnterJob",
  async ({projectId, params}, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId)
      const response = await apiAxios.get(`${url}/load_buildings`, {params})
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getBuildingRevisionsEnterJob = createAsyncThunk(
  "EnterJob/getBuildingRevisionsEnterJob",
  async ({projectId, buildingId}, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId)
      const response = await apiAxios.get(`${url}/load_building_revisions?building_id=${buildingId}`)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createEnterJob = createAsyncThunk(
  "EnterJob/createEnterJob",
  async ({projectId, data}, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId)
      const response = await apiAxios.post(url, data)
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateEnterJob = createAsyncThunk(
  "EnterJob/updateEnterJob",
  async ({ projectId, enterJobId, data }, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.put(url, data)
      return response.data
    }
    catch(error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteEnterJob = createAsyncThunk(
  "EnterJob/deleteEnterJob",
  async ({projectId, enterJobId}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.delete(url);
      return { enterJobId, message: response.data.message }
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const requestPJnumberEnterJob = createAsyncThunk(
  "EnterJob/requestPJnumberEnterJob",
  async ({projectId, enterJobId}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/request_p_j_number`);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const convertPnumberEnterJob = createAsyncThunk(
  "EnterJob/convertPnumberEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/convert_p_number`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const convertJnumberEnterJob = createAsyncThunk(
  "EnterJob/convertJnumberEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/convert_j_number`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const assignToSSEEnterJob = createAsyncThunk(
  "EnterJob/assignToSSEEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/assign_to_sse`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const assignToCSEOEEnterJob = createAsyncThunk(
  "EnterJob/assignToCSEOEEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/assign_to_cse_oe`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const assignToCSEOMEnterJob = createAsyncThunk(
  "EnterJob/assignToCSEOMEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/assign_to_cse_om`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const updatePJNumberEnterJob = createAsyncThunk(
  "EnterJob/updatePJNumberEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.put(`${url}/update_p_j_number`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadDocument = createAsyncThunk(
  "EnterJob/uploadDocument",
  async ({ projectId, enterJobId, data }, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/upload_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeDocument = createAsyncThunk(
  "EnterJob/removeDocument",
  async ( { projectId, enterJobId, documentId }, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/remove_document`, { document_id: documentId } );
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const choiceRevisionEnterJob = createAsyncThunk(
  "EnterJob/choiceRevisionEnterJob",
  async ({projectId, enterJobId, data}) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/load_revision`, data);
      return response.data
      
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const uploadDocumentRevision = createAsyncThunk(
  "EnterJob/uploadDocumentRevision",
  async ({ projectId, enterJobId, data }, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/upload_revision_document`, data, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

export const removeDocumentRevision = createAsyncThunk(
  "EnterJob/removeDocumentRevision",
  async ( { projectId, enterJobId, data }, { rejectWithValue }) => {
    try {
      const url = generalEnterJobUrl(projectId, enterJobId)
      const response = await apiAxios.post(`${url}/remove_revision_document`, data );
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data)
    }
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const handlePayload = (state, action, type="none") => {
  const { error, data, message } = action.payload;

  if (error !== undefined) {
    toast.error(error);
  } else {
    const enterJob = data.attributes

    if (enterJob !== null) {
      switch (type) {
        case "create": 
          state.data.unshift(enterJob)
          break
        case "delete":
          state.data = state.data.filter((item) => item.id !== enterJob.id)
          break;
        default:
        {
          const updatedIndex = state.data.findIndex((item) => item.id === enterJob.id)
          if (updatedIndex !== -1) {
            state.data[updatedIndex] = enterJob
          }

          break;
        }
      }
    }
    toast.success(message);
  }
};

const enterJobSlice = createSlice({
  name: "enterJob",
  initialState: {
    data: [],
    totalRows: 0,
    paginate: {
      totalPagesCount: 0,
      currentPage: 1,
      pageSize: 100,
      fromRecord: 0,
      toRecord: 1,
    },
    currentParams: {page: 1, per_page: 100},
    sortParams: {attribute: 'id', direction: 'desc'},
    loading: false,
    exporting: false,
    sortColumns: {},
    enterJobData: {}
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.paginate.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.paginate.pageSize = parseInt(action.payload)
    },
    setCurrentParams: (state, action) => {
      state.currentParams = action.payload
    },
    resetCurrentParams: (state) => {
      state.currentParams = {page: 1, per_page: 100}
    },
    setSortParams: (state, action) => {
      state.sortParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnterJob.fulfilled, (state, action) => {
        state.enterJobData = action.payload
      })
      .addCase(getEnterJobs.fulfilled, (state, action) => {
        state.loading = false
        const results = action.payload

        state.data = objectSerializer(results.rows)
        state.totalRows = results.total_rows

        const paginate           = state.paginate
        const calRecords         = calPaginatorRecord(paginate.currentPage, paginate.pageSize, state.totalRows)
        paginate.totalPagesCount = calRecords.totalPagesCount
        paginate.fromRecord      = calRecords.beginRecords
        paginate.toRecord        = calRecords.lastRecords
      })
      .addCase(createEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action, "create")
      })
      .addCase(deleteEnterJob.fulfilled, (state, action) => {
        //handlePayload(state, action, "delete")
        const { error, enterJobId, message } = action.payload;

        if (error !== undefined) {
          toast.error(error);
        } 
        else
        {
          if (enterJobId) {
            state.data = state.data.filter((item) => item.id !== enterJobId)
            toast.success(message)
          }
        }
      })
      .addCase(updateEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(requestPJnumberEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(convertPnumberEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(convertJnumberEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(assignToSSEEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(assignToCSEOEEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(assignToCSEOMEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(updatePJNumberEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeDocument.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(uploadDocumentRevision.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(removeDocumentRevision.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addCase(choiceRevisionEnterJob.fulfilled, (state, action) => {
        handlePayload(state, action)
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams
} = enterJobSlice.actions;

export default enterJobSlice.reducer;