import { Form, Offcanvas, Row, Col, Button } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import IconAlert from "components/common/IconAlert";
import Select from 'react-select'
import { getExchangeRate } from "redux/settingsSlice";
import { useDispatch } from "react-redux";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {isAfter} from "date-fns"
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import BookingUploadAttachment from "../../BookingUploadAttachment";
import { createBookingShuttle, updateBookingShuttle } from "redux/booking/travel/travelBookingSlice";
import PropTypes from 'prop-types';
import TinymceEditor from "components/common/TinymceEditor";

const ShuttleForm = ({showModal, setshowModal, booking, shuttle, transit}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue, getValues, reset } = useForm()
  const {usdExchangeRateData} = useSelector((state) => state.settings)
  const [files, setFiles] = useState([]);
  
  const SHUTTLE_NAMES = ["Car rental" , "Plane", "Train", "Taxi", "Motocycle", "Bus", "Other"]
  const shuttleNameOptions = useMemo(() => (
    SHUTTLE_NAMES.map((option) => ({ value: option, label: option }))
  ), []);

  const defaultShutteNameOption = shuttle ? shuttleNameOptions.find((option) => option.value === shuttle.name) : null

  const dispatch = useDispatch()
  const bookingId = booking.id
  const showExchangeRate = booking.currency == "USD"
  const title = shuttle ? "Edit" : "Create"

  useEffect(() => {
    if (!shuttle) {
      reset()
    }
  }, [shuttle])

  useEffect(() => {
    //get exchange rate
    if (showExchangeRate) {
      dispatch(getExchangeRate())
    }
  }, [showExchangeRate])

  useEffect(() => {
    setValue('departure_date', new Date(booking.date_of_travel))
    setValue('return_date', new Date(booking.date_of_return))
    setValue('currency', booking.currency)

    if (transit) {
      setValue('departure_date', new Date(transit.from_date))
      setValue('return_date', new Date(transit.to_date))
    }

    if (showExchangeRate) {
      setValue('exchange_rate', usdExchangeRateData)
    }
    
    if(shuttle) {
      Object.entries(shuttle).forEach(([key, value]) => {
        if (key === 'departure_date' || key === 'return_date') {
          if (value) {
            const dateValue = new Date(value)
            setValue(key, dateValue);
          }
        }
        else {
          setValue(key, value)
        }
      })
    }

  }, [[setValue, booking, shuttle]])

  const closeModal = () => {
    setshowModal(false)
  }

  const handleClose = () => {
    closeModal()
  }

  const onSubmit = async (data) => {
    console.log(data)

    if (transit) {
      data = {...data, transit_id: transit.id}
    }

    if (files) {
      data = {...data, files}
    }

    try {
      let shuttleId = ""

      if (shuttle) {
        shuttleId = shuttle.id
        dispatch(updateBookingShuttle({bookingId, shuttleId, data }))
      }
      else {
        dispatch(createBookingShuttle({ bookingId, data }))
      }

      closeModal()
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)

      return;
    }
  }

  return (
    <Offcanvas
      show={showModal}
      onHide={handleClose}
      placement="end"
      backdrop="static"
      scroll={false}
      style={{width: '50%'}}
    >
      <Offcanvas.Header className="bg-light" closeButton>
        <Offcanvas.Title>
          {title} Shuttle booking <FontAwesomeIcon icon={faBusinessTime} className="text-primary" />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        
        <IconAlert variant="warning" className="mt-2">
          <p className="mb-0">Book shuttle shuttle for your trip, including company vehicles, car rentals, flights, trains, taxis, and more.</p>
        </IconAlert>

        {transit && (
          <h5 className="mb-3">Booking for: <span className="text-primary">{transit.name}</span></h5>
        )}

        <Form onSubmit={handleSubmit(onSubmit)} className="fs--1">
          <Form.Group as={Row} className="mb-2" controlId="name">
            <Form.Label column sm={3} className="text-lg-end">
              Name
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="name"
                render={() => (
                  <Select
                    closeMenuOnSelect={true}
                    options={shuttleNameOptions}
                    defaultValue={defaultShutteNameOption}
                    placeholder='Select...'
                    classNamePrefix="react-select"
                    onChange={selectedOption => { 
                      setValue('name', selectedOption.value, {shouldValidate: true})
                      setValue("primary", selectedOption.value === "Plane")
                    }}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required'
                  }
                }}
              />
              <span className="text-danger">
                {errors.name && errors.name.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="description">
            <Form.Label column sm={3} className="text-lg-end">
              Description
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                as="textarea"
                rows={5}
                name="description"
                placeholder="Description"
                className="fs--1"
                {...register("description", {
                  required: "Description must be required."
                })}
              />
              <span className="text-danger">
                {errors.description && errors.description.message}
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="departure_date">
            <Form.Label column sm={3} className="text-lg-end">
              Departure date
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="departure_date"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                )}
                rules={{
                  required: "Departure date must be required."
                }}
              />
              <span className="text-danger">
                {errors.departure_date && errors.departure_date.message}
              </span>    
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="return_date">
            <Form.Label column sm={3} className="text-lg-end">
              Return date
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="return_date"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="form-control fs--1"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                )}
                rules={{
                  required: "Return date must be required.",
                  validate: {
                    afterFromDate: value => {
                      const fromDate = getValues('departure_date');
                      return isAfter(value, fromDate) || 'Check out must be after check in time.';
                    },
                  },
                }}
              />
              <span className="text-danger">
                {errors.return_date && errors.return_date.message}
              </span>    
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="primary" className="mb-2 mt-4">
            <Form.Label column sm={3} className="text-lg-end"></Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="primary"
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    type="switch"
                    label="Set primary"
                    checked={field.value}
                    onChange={(e) => setValue("primary", e.target.checked)}
                  />
                )}
              />
              <span className="text-primary"><FontAwesomeIcon icon="info-circle" />  When <mark>Primary</mark> is enabled, business travel expenses will be calculated based on the actual travel time of transportation, such as flight, train, taxi ticket, etc.</span>
            </Col>
          </Form.Group>
          
          <hr />
          <Form.Group as={Row} className="mb-2 mt-4" controlId="amount_estimate_currency">
            <Form.Label column sm={3} className="text-lg-end">
              Amount estimate currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="amount_estimate_currency"
                placeholder="Amount estimate currency"
                className="fs--1"
                {...register("amount_estimate_currency", {
                  required: "Amount estimate currency must be required."
                })}
              />
              <span className="text-danger">
                {errors.amount_estimate_currency && errors.amount_estimate_currency.message}
              </span>
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Shuttle expense estimate.</div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="amount_currency">
            <Form.Label column sm={3} className="text-lg-end">
              Amount actual
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="amount_currency"
                placeholder="Expense currency"
                className="fs--1"
                {...register("amount_currency")}
              />
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Actual shuttle expense.</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-2" controlId="currency">
            <Form.Label column sm={3} className="text-lg-end">
              Currency
            </Form.Label>
            <Col sm={9} md={7}>
              <Form.Control
                name="currency"
                placeholder="Currency"
                className="fs--1"
                disabled
                {...register("currency", {
                  required: "Currency must be required."
                })}
              />
              <span className="text-danger">
                {errors.currency && errors.currency.message}
              </span>
              <div className="my-1"><FontAwesomeIcon icon="info-circle" /> Currency in accordance with the trip's currency.</div>
            </Col>
          </Form.Group>
          
          {showExchangeRate && (
            <Form.Group as={Row} className="mb-2" controlId="exchange_rate">
              <Form.Label column sm={3} className="text-lg-end">
                Exchange rate
              </Form.Label>
              <Col sm={9} md={7}>
                <Form.Control
                  type="text"
                  name="exchange_rate"
                  placeholder="Exchange rate"
                  className="fs--1"
                  {...register('exchange_rate', {
                    required: 'Exchange rate must be required'
                  })}
                />
                <span className="text-danger">
                  {errors.exchange_rate && errors.exchange_rate.message}
                </span>
                <IconAlert variant="info" className="mt-2">
                  <p className="mb-0">USD exchange rate to VND from <a href="https://www.vietcombank.com.vn/KHCN/Cong-cu-tien-ich/Ty-gia?devicechannel=default" target="_blank" rel="noreferrer">Vietcombank</a>.</p>
                </IconAlert>
              </Col>
            </Form.Group>
          )}

          <hr/>

          <Form.Group as={Row} className="mb-2 mt-4" controlId="notes">
            <Form.Label column sm={3} className="text-lg-end">
              Note
            </Form.Label>
            <Col sm={9} md={7}>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <TinymceEditor
                    height="30vh"
                    handleChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="attachment">    
              <Form.Label column sm={3} className="text-lg-end">
                Attachments
              </Form.Label>
              <Col sm={9} md={7}>
                <BookingUploadAttachment files={files} setFiles={setFiles} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col className="my-4">
                <Flex justifyContent="center">
                  <Button size="sm" type="submit">Save</Button>
                  <Button variant="danger" size="sm" className="ms-2" onClick={handleClose}>Cancel</Button>
                </Flex>
              </Col>
            </Form.Group>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

ShuttleForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  shuttle: PropTypes.object.isRequired,
  transit: PropTypes.object.isRequired
}

export default ShuttleForm;