import apiAxios from './apiAxios';
import { debounce } from './debounce';

const employeUrl = '/organization/employees/active_list?';

const salesEmployeesUrl = `${employeUrl}employee_type=sales`;
const csdEmployeesUrl = `${employeUrl}employee_type=csd`;
const engEnployeesUrl = `${employeUrl}employee_type=engineering`;


export const filterOptions = async (url, inputValue, justShowName = false) => {
  try {
    const response = await apiAxios.get(`${url}&page=1&per_page=30&q=${inputValue}`);
    const { data } = response;

    const options = data && data.map((item) => {
      if (item.attributes) {
        return { value: justShowName ? item.attributes.name : item.attributes.id, label: item.attributes.name };
      } else {
        return { value: justShowName ? item.name : item.id, label: item.name };
      }
    });

    return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const activeEmployeesDebounce = debounce((inputValue, callback) => {
  filterOptions(employeUrl, inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const activeEmployeesByNameDebounce = debounce((inputValue, callback) => {
  filterOptions(employeUrl, inputValue, true).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);


export const salesEmployeesDebounce = debounce((inputValue, callback) => {
  filterOptions(salesEmployeesUrl, inputValue, true).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const salesEmployeesByNameDebounce = debounce((inputValue, callback) => {
  filterOptions(salesEmployeesUrl, inputValue, true).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const csdEmployeesDebounce = debounce((inputValue, callback) => {
  filterOptions(csdEmployeesUrl, inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const csdEmployeesByNameDebounce = debounce((inputValue, callback) => {
  filterOptions(csdEmployeesUrl, inputValue, true).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const engEmployeesDebounce = debounce((inputValue, callback) => {
  filterOptions(engEnployeesUrl, inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

export const engEmployeesByNameDebounce = debounce((inputValue, callback) => {
  filterOptions(engEnployeesUrl, inputValue, true).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);


const filterDepartmentOptions = async (inputValue) => {
  try {
    const response = await apiAxios.get(`/organization/departments?page=1&per_page=50&name_cont=${inputValue}`);
    const { rows: data } = response.data;

    const options = data && data.map((item) => {
      return { value: item.attributes.id, label: item.attributes.name };
    });

    return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const departmentsDebounce = debounce((inputValue, callback) => {
  filterDepartmentOptions(inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

const filterSiteOptions = async (inputValue) => {
  try {
    const response = await apiAxios.get(`/organization/sites?page=1&per_page=50&name_cont=${inputValue}`);
    const { rows: data } = response.data;

    const options = data && data.map((item) => {
      return { value: item.attributes.id, label: item.attributes.name };
    });

    return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const sitesDebounce = debounce((inputValue, callback) => {
  filterSiteOptions(inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);

const filterEmpEmailOptions = async (inputValue) => {
  try {
    const response = await apiAxios.get(`${employeUrl}&page=1&per_page=30&q=${inputValue}`);
    const { data } = response;

    const options = data && data.map((item) => {
      return { value: item.attributes.email, label: item.attributes.email };
    });

    return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const employeesEmailDebounce = debounce((inputValue, callback) => {
  filterEmpEmailOptions(inputValue).then((filteredOptions) => {
    callback(filteredOptions);
  });
}, 300);