import parsePhoneNumber from 'libphonenumber-js'

const phoneNumber = (phone) => {
  if (phone !== undefined && phone && !phone.includes("+")) {
    phone = "+" + phone;
  }
  return phone ? parsePhoneNumber(phone) : null;
}

export const formatPhoneNumberInternational = (phone) => {
  const number = phoneNumber(phone)
  return number ? number.formatInternational() : null
}

export const formatPhoneNumberNational = (phone) => {
  const number = phoneNumber(phone)
  return number ? number.formatNational() : null
}

export const formatPhoneNumberUrl = (phone) => {
  const number = phoneNumber(phone)
  return number ? number.getURI() : null
}