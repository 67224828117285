import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiAxios from "helpers/apiAxios";
import { objectSerializer } from "helpers/utils";
import { toast } from "react-toastify";

const waiverUrl = `/crm/reports/waiver`;

export const getWaivers = createAsyncThunk(
  "ReportWaiver/getWaivers",
  async (params) => {
    try {
      const response = await apiAxios.get(waiverUrl, { params })
      return response.data
    }
    catch(error) {
      throw new Error(error.message)
    }
  }
)

export const exportWaivers = createAsyncThunk(
  "ReportWaiver/exportWaivers",
  async (params) => {
    const { page, per_page, ...rest } = params
    console.log(rest, page, per_page)

    const response = await apiAxios.get(`${waiverUrl}/export`, {params, responseType: 'blob'})
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'addendums.xlsx');
    document.body.appendChild(link);
    link.click();
  }
)

const isRejectedAction = (action) => {
  return action.type.endsWith('rejected')
}

const waiverSlice = createSlice({
  name: "report_waiver",
  initialState: {
    data: [],
    loading: false,
    exporting: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWaivers.fulfilled, (state, action) => {
        state.loading = false
        state.data = objectSerializer(action.payload)
      })
      .addCase(exportWaivers.pending, (state) => {
        state.exporting = true
      })
      .addCase(exportWaivers.fulfilled, (state) => {
        state.exporting = false
        toast.success("Data has been exported successfully.")
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.loading = false;
          toast.error(action.payload?.message || action.payload?.error || action?.error?.message)
        }
      );
  }
})

export const {
  setCurrentPage,
  setPageSize,
  setCurrentParams,
  resetCurrentParams,
  setSortParams,
  setFilterTag,
  resetFilterTag
} = waiverSlice.actions;

export default waiverSlice.reducer;