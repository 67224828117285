import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { formatDate, formatNumber } from "helpers/utils";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { Row, Col, Dropdown, Form } from "react-bootstrap";
import { FaShareSquare, FaCalendarCheck, FaVoteYea } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";
import { useDispatch } from "react-redux";
import { showOnMaster } from "redux/crm/project/changeOrderSlice";
import PropTypes from 'prop-types';
import { removeOtherDocument } from "../../../../../redux/crm/project/changeOrderSlice";

const LazyOtherDeleteConfirm = lazy(() => import("./other/OtherDeleteConfirm"));
const LazyOtherForm= lazy(() => import("./other/OtherForm"));
const LazyOtherRequestToENG = lazy(() => import("./other/OtherRequestToENG"));
const LazyOtherScheduleToCSD = lazy(() => import("./other/OtherScheduleToCSD"));
const LazyOtherReleaseToCSD = lazy(() => import("./other/OtherReleaseToCSD"));

const OtherDrawing = ({changeOrder}) => {
  const [showRequestOther, setShowRequestOther] = useState(false);
  const [showScheduleOther, setShowScheduleOther] = useState(false);
  const [showReleaseOther, setShowReleaseOther] = useState(false);
  const [showOtherForm, setShowOtherForm] = useState(false);
  const [showDeleteOther, setShowDeleteOther] = useState(false);
  const [title, setTitle] = useState(null);
  const [record, setRecord] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const {project, building} = changeOrder || {};
  const data = changeOrder?.others || [];
  const dispatch = useDispatch();

  const {
    allow_create_other,
    allow_show_master
  } = changeOrder?.permissions || {};

  //const isChecked = building?.est_co_lastest_id === changeOrder?.id;

  useEffect(() => {
    setIsChecked(building?.est_co_lastest_id === changeOrder?.id);
  }, [building, changeOrder, data]);

  const handleNewOther = () => {
    setTitle("New Other Drawing")
    setRecord(null)
    setShowOtherForm(true)
  }

  const handleEditOther = (record) => {
    setTitle("Edit Other Drawing")
    setRecord(record)
    setShowOtherForm(true)
  }

  const handleDeleteOther = (record) => {
    setRecord(record)
    setShowDeleteOther(true)
  }

  const handleRequestOther = (record) => {
    setRecord(record)
    setShowRequestOther(true)
  }

  const handleScheduleOther = (record) => {
    setRecord(record)
    setShowScheduleOther(true)
  }

  const handleReleaseOther = (record) => {
    setRecord(record)
    setShowReleaseOther(true)
  }

  const handleRemoveDocument = (otherId, documentId) => {
    try {
      const params = {
        projectId: project.id,
        buildingId: building.id,
        changeOrderId: changeOrder.id,
        otherId,
        data: { document_id: documentId }
      }
      dispatch(removeOtherDocument(params))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleShowForecastToMasterSchedule = (e, weight) => {
    const checked = e.target.checked
    setIsChecked(checked);

    const data = {
      type: "forecast",
      checked: checked,
      weight: weight,
      latest_co_id: changeOrder.id
    }

    dispatch(showOnMaster({
      projectId: project.id,
      buildingId: building.id,
      changeOrderId: changeOrder.id,
      data
    }));
  }

  const columns = useMemo(() => [
    {
      Header: ' ',
      Cell: rowData => {
        const other = rowData.row.original

        const {
          allow_edit,
          allow_delete,
          allow_request_to_eng,
          allow_schedule_to_csd,
          allow_release_to_csd,
        } = other?.permissions || {};

        return (
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
      
                {allow_request_to_eng && (
                  <>
                    <Dropdown.Item onClick={() => handleRequestOther(other)} className="mb-2">
                      <FaShareSquare /> Request to ENG
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}

                {allow_schedule_to_csd && (
                  <Dropdown.Item onClick={() => handleScheduleOther(other)} className="mb-2 text-primary">
                    <FaCalendarCheck/> Schedule to CSD
                  </Dropdown.Item>
                )}

                {allow_release_to_csd && (
                  <Dropdown.Item onClick={() => handleReleaseOther(other)} className="mb-2 text-primary">
                    <FaVoteYea /> Release to CSD
                  </Dropdown.Item>
                )}
                
                {allow_edit && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleEditOther(other)} className="mb-2">
                      <FontAwesomeIcon icon="edit" /> Edit
                    </Dropdown.Item>
                  </>
                )}

                {allow_delete && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-1 text-danger" onClick={() => handleDeleteOther(other)}>
                      <FontAwesomeIcon icon="trash-alt" /> Delete
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )
      },
      cellProps: {
        className: "fixed-column"
      }
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({row}) => {
        const {name, drawing_type, weight} = row.original;

        return drawing_type === "forecast_material" ? (
          <>
            <div className="text-primary pb-2">{name}</div>
            <div className="text-warning">
              {allow_show_master && (
                <Form.Check
                  label="Show in Master Schedule report"
                  onChange={(e) => handleShowForecastToMasterSchedule(e, weight)}
                  checked={isChecked}
                />
              )}
            </div>
          </>
        ) : <div className="text-primary">{name}</div>
      }
    },
    {
      Header: 'Request',
      accessor: 'requested_date',
      Cell: ({row}) => formatDate(row.original.requested_date)
    },
    {
      Header: 'Schedule',
      accessor: 'scheduled_date',
      Cell: ({row}) => formatDate(row.original.scheduled_date)
    },
    {
      Header: 'Release',
      accessor: 'released_date',
      Cell: ({row}) => formatDate(row.original.released_date)
    },
    {
      Header: 'Weight (M.T)',
      accessor: 'weight',
      Cell: ({row}) => formatNumber(row.original.weight),
      cellProps: {
        className: "text-end text-primary"
      }
    },
    {
      accessor: 'designer',
      Header: 'Designer',
      cellProps:{
        className:'text-center'
      }
    },
    {
      accessor: 'checker',
      Header: 'Checker',
      cellProps:{
        className:'text-center'
      }
    },
    {
      accessor: 'attachments',
      Header: (
        <><MdAttachFile /> Attachments</>
      ),
      Cell: rowData => {
        const other = rowData.row.original

        const {
          allow_view_document,
          allow_remove_document,
          allow_download_document,
        } = other?.permissions || {};

        return (
          <>
          {other.documents && (
            <div className="pb-2 mt-3">
              {other.documents.map(attachment => (
                <DocumentAttachment
                  {...attachment}
                  key={attachment.id}
                  isTable
                  allowView={allow_view_document}
                  allowDownload={allow_download_document}
                  allowRemove={allow_remove_document}
                  handleRemoveAttachment={(documentId) => handleRemoveDocument(other.id, documentId)}
                />
              ))}
            </div>
          )} 
          </>
        )
      }
    },
    {
      accessor: 'eng_notes',
      Header: 'ENG note',
      cellProps: {
        className: "text-danger"
      }
    },
    {
      accessor: 'created_by',
      Header: 'Created by',
      cellProps: {
        className: "text-center"
      }
    },
    {
      accessor: 'created_date',
      Header: 'Created date',
    }
  ], [isChecked]);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={15}
      >
        <Row className="flex-end-center mb-3">
          <Col>
            {allow_create_other && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-2"
                iconAlign="middle"
                className="me-1"
                onClick={handleNewOther}
              >
                <span className="d-none d-xl-inline-block ms-1">New Record</span>
              </IconButton>
            )}
          </Col>
          <Col sm="3">
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3 mb-2">
          <AdvanceTablePagination
            table
          />
        </div>
      </AdvanceTableWrapper>
     

      <Suspense fallback={<div>Loading....</div>}>
        {showOtherForm && (
          <LazyOtherForm
            showModal={showOtherForm}
            setshowModal={setShowOtherForm}
            changeOrder={changeOrder}
            record={record}
            title={title}
          />
        )}

        {showRequestOther && (
          <LazyOtherRequestToENG
            showModal={showRequestOther}
            setshowModal={setShowRequestOther}
            changeOrder={changeOrder}
            other={record}
          />
        )}

        {showScheduleOther && (
          <LazyOtherScheduleToCSD
            showModal={showScheduleOther}
            setshowModal={setShowScheduleOther}
            changeOrder={changeOrder}
            other={record}
          />
        )}

        {showReleaseOther && (
          <LazyOtherReleaseToCSD
            showModal={showReleaseOther}
            setshowModal={setShowReleaseOther}
            changeOrder={changeOrder}
            other={record}
          />
        )}

        {showDeleteOther && (
          <LazyOtherDeleteConfirm
            showModal={showDeleteOther}
            setshowModal={setShowDeleteOther}
            changeOrder={changeOrder}
            other={record}
          />
        )}
      </Suspense>
    </>
    
  )
};

OtherDrawing.propTypes = {
  changeOrder: PropTypes.object.isRequired
}

export default OtherDrawing;