import { useDispatch } from "react-redux"
import { Form, Modal, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { FaTrash, FaUpload } from "react-icons/fa"
import { uploadAsset } from "redux/drive/driveSlice"
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from "components/common/Flex"
import React, { useState } from "react"
import { getSize } from 'helpers/utils';
import PropTypes from'prop-types';

const DriveUpload = ({showModal, setshowModal, assetId }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const dispatch = useDispatch();

  const closeModal = () => {
    setshowModal(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      dispatch(uploadAsset({assetId, data: {documents: selectedFiles}}));
      closeModal();
    }
    catch(error) {
      toast.error(error.message);
    }  
  }

  const {getRootProps, getInputProps} = useDropzone({
    //accept: "image/*",
    multiple: true,
    onDrop: (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(file => !file.name.toLowerCase().endsWith('.exe'));
      setSelectedFiles(filteredFiles)
    }
  })

  const handleRemove = path => {
    setSelectedFiles(selectedFiles.filter(file => file.path !== path));
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="fs--1"
      centered
      size="lg"
    >
      <Form onSubmit={handleSubmit} className="fs--1">
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title className="fs-0" id="contained-modal-title-vcenter">Upload files!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <IconAlert variant="warning">
            Drop or select your files to upload on current folder.
          </IconAlert> */}

          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps()} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Drop your files here</p>
            </Flex>
          </div>
          <div className="text-warning my-2 text-end">The maximum file size allowed is 500MB.</div>

         <div className="mt-3">
            {selectedFiles && selectedFiles.length > 0 && (
              <>
                <h6 className="mb-3">Files will upload detail:</h6>
                {selectedFiles.map(file => (
                  <Flex
                    alignItems="center"
                    className="py-1 border-bottom btn-reveal-trigger fs--1 p-3"
                    key={file.path} // Use a unique identifier for the key
                  >
                    <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                      <div>
                        <h6>{file.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>{getSize(file.size)}</strong>
                          </p>
                        </Flex>
                      </div>
                    </Flex>
                    <FaTrash className="cursor-pointer" onClick={() => handleRemove(file.path)} />
                  </Flex>
                ))}
                <div className="m-3 text-primary fw-bold">Total {selectedFiles.length || 0} files.</div>
              </>
            )}
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button size="sm" type="submit" variant="primary"><FaUpload /> Upload</Button>
          <Button size="sm" variant="danger" onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

DriveUpload.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setshowModal: PropTypes.func.isRequired,
  assetId: PropTypes.number.isRequired,
};

export default DriveUpload;