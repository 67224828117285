import React from 'react';
import PropTypes from 'prop-types';

const FormatPercentRenderer = (props) => {
  const value = props.value;

  if (value !== null) {
    const numericValue = Number(value)
    return !isNaN(numericValue) ? `${numericValue}%` : <span className="text-danger">{value}</span>
  }

  return <span className="text-danger">N/A</span>
}

FormatPercentRenderer.propTypes = {
  value: PropTypes.number
};

export default FormatPercentRenderer;
