import { Card, Row, Col } from "react-bootstrap";
import { MdArrowForwardIos, MdOutlineRequestQuote, MdOutlineTravelExplore } from "react-icons/md";
import SimpleBar from "simplebar-react";
import { GiTrophyCup } from "react-icons/gi";
import { FaPeopleArrows, FaSadTear } from "react-icons/fa";
import { Link } from "react-router-dom";
import SoftBadge from "components/common/SoftBadge";
import React, { useState } from "react";
import DetailDataModal from "../../DetailDataModal";
import QuotationDetail from "./QuotationDetail";
import PropTypes from "prop-types";

const CardItem = ({title, componentIcon, data, color, handleViewDetail}) => {
  // const total = data && Array.isArray(data) ? data.reduce((total, item) => {
  //   return total + parseFloat(item.est_weight || 0); // Ensure to parse the value as float
  // }, 0) : 0;

  return (
    <Card className="h-100">
      <Card.Header className={`text-center bg-${color} text-white border-bottom`}>
        <div>{componentIcon} {title}</div>
        <SoftBadge pill className="fw-bold fs-0 mt-2">{data?.length}</SoftBadge>
      </Card.Header>
      <Card.Body>
        <SimpleBar>
          {data && data?.length > 0 ? (
            <>
              <div className="fs--1 text-capitalize">
                {data.slice(0,10).map((item, index) => (
                  <div key={index} className={`py-2 ${data?.length !== index ? 'border-bottom': ''}`}>
                    <Link to={`/crm/projects/${item?.project_id}`} target="_blank" className="text-decoration-none">{item?.project_name}</Link>
                  </div>
                ))}
              </div>
              <div className="mt-2 text-end">
               
              </div>
            </>
          ) : <span className="text-center text-primary">No data found.</span>}
        </SimpleBar>
      </Card.Body>
      <Card.Footer className="text-end border-top fs--1">
        <Link onClick={handleViewDetail}>View Details <MdArrowForwardIos /></Link>
        {/* <div className="text-600">{total?.toLocaleString() || 0} M.T</div> */}
      </Card.Footer>
    </Card>
  )
}

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  componentIcon: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  handleViewDetail: PropTypes.func.isRequired
}

const QuotationPipeline = ({data}) => {
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [titlePipe, setTitlePipe] = useState(false)
  const [dataPipe, setDataPipe] = useState(false)

  const filterData = (value) => {
    return data && Array.isArray(data) ? data.filter(item => item.seq === value) : [];
  }

  const quotationProcessing = filterData(1);
  const tendering = filterData(2);
  const negotiation = filterData(3);
  const job = filterData(4);

  const lostQuotation = filterData(5);
  const lostJob = filterData(6);
  const lostData = [...lostQuotation, ...lostJob];

  const handleViewDetail = (title, data) => {
    setShowDetailModal(true);
    setTitlePipe(title)
    setDataPipe(data)
  }

  const pipelineData = [
    {
      id: 1,
      title: "Quotation processing",
      componentIcon: <MdOutlineRequestQuote />,
      data: quotationProcessing,
      color: "primary"
    },
    {
      id: 2,
      title: "Tendering",
      componentIcon: <MdOutlineTravelExplore />,
      data: tendering,
      color: "info"
    },
    {
      id: 3,
      title: "Negotiation",
      componentIcon: <FaPeopleArrows />,
      data: negotiation,
      color: "warning"
    },
    {
      id: 3,
      title: "Job",
      componentIcon: <GiTrophyCup />,
      data: job,
      color: "success"
    },
    {
      id: 3,
      title: "Lost (Q + J)",
      componentIcon: <FaSadTear />,
      data: lostData,
      color: "danger"
    },
  ]

  return (
    <>
      <Card className="h-100 mb-3">
        <Card.Header className="bg-light h5">
          Quotation Pipeline
        </Card.Header>
        <Card.Body>
          <Row className="mb-3 gx-2 fs--1">
            {pipelineData.map(item => (
              <Col key={item.id}>
                <CardItem
                  title={item.title}
                  componentIcon={item.componentIcon}
                  data={item.data}
                  color={item.color}
                  handleViewDetail={() => handleViewDetail(item.title, item.data)}
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>

      {showDetailModal && (
        <DetailDataModal
          showModal={showDetailModal}
          setshowModal={setShowDetailModal}
          title={titlePipe}
          dataComponent={<QuotationDetail data={dataPipe} />}
        />
      )}
    </>
  )
};

QuotationPipeline.propTypes = {
  data: PropTypes.array.isRequired
}

export default QuotationPipeline;