import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { Form, Row, Col, Button, Card, Accordion } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { debounce, filterOptions } from 'helpers/debounce';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { createSalesUnit, updateSalesUnit } from 'redux/crm/salesUnitSlice';
import AsyncSelect from 'react-select/async';
import IconButton from 'components/common/IconButton';
import { useNavigate } from 'react-router-dom';
import { FaBan, FaSave } from 'react-icons/fa';
import { activeEmployeesByNameDebounce } from 'helpers/employeeOptionHelper';
import {CRM_SALES_UNIT_REGION, CRM_SALES_UNIT_TYPE_OF_SALES} from 'app_config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const SalesUnitForm = ({title, salesUnit}) => {
  const {control, register, handleSubmit, formState: {errors}, setValue } = useForm();
  const [activeKey, setActiveKey] = useState(["0", "1", "2", "3", "4"])
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    if(salesUnit) {
      Object.entries(salesUnit).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [setValue]);

  const onSubmit = async (data) => {
    console.log(data)

    try {
      let salesUnitId = salesUnit ? salesUnit.id : null;

      if (!salesUnit) {
        const response = await dispatch(createSalesUnit(data));
        salesUnitId = response.payload.data.id ;
      }
      else {
        await dispatch(updateSalesUnit({salesUnitId, data}));
      }

      if (salesUnitId) {
        navigate(`/crm/sales-units/${salesUnitId}`);
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleBack = () => {
    navigate(-1);
  }

  const defaultNameOption = useMemo(() => {
    return (key) => salesUnit ? { value: salesUnit[key], label: salesUnit[key] } : '';
  }, [salesUnit]);

  const defaultNameOptions = useMemo(() => {
    return (key) => {
      if (salesUnit && salesUnit[key]) {
        return salesUnit[key].map((option) => ({ value: option, label: option }));
      }
      return [];
    };
  }, [salesUnit]);

  const handleChangeName = (selectedOptions, key) => {
    const selectedOptionsValues = selectedOptions.map((option) => option.value);
    setValue(key, selectedOptionsValues, { shouldValidate: true });
  }

  const defaultSaleChiefRep = defaultNameOption("sales_manager");
  const defaultSalesLeader = defaultNameOption("sales_leader");
  const defaultSalesAdmin = defaultNameOption("sales_admin");
  const defaultSaleTender = defaultNameOption("sales_tender");
  const defaultParentOption = defaultNameOption("parent_id");

  const defaultEngInchargeOption = defaultNameOption("eng_incharge");
  const defaultEngDetailerOption = defaultNameOption("detailer_incharge");
  const defaultEngManagerOption = defaultNameOption("eng_manager");

  const defaultCreditControllerOption = defaultNameOption("credit_controller");
  const defaultFinanceControllerOption = defaultNameOption("finance_controller");

  const defaultCSDOrderEntryOption = defaultNameOptions("csd_order_entry");
  const defaultCSDOrderManagementOption = defaultNameOptions("csd_order_management");

  const defaultContarctManagementOption = defaultNameOptions("contract_management");
  const defaultContarctHandlerOption = defaultNameOptions("contract_handler_optional");
  const defaultWaiverFormOption = defaultNameOptions("waiver_form_handler");
  const defaultEstimateForecastOption = defaultNameOptions("estimate_forecast_handler");

  const defaultGeneralManagerOperation = defaultNameOption("general_manager_operation");

  const salesUnitsRegionOptions = CRM_SALES_UNIT_REGION.map((option) => ({ value: option, label: option }))
  const typeOfSalesOptions = CRM_SALES_UNIT_TYPE_OF_SALES.map((option) => ({ value: option, label: option }))

  const loadEmpName = (inputValue, callback) => {
    activeEmployeesByNameDebounce(inputValue, callback);
  };

  const handleChangeSalesTender = (selectedOptions) => handleChangeName(selectedOptions, "sales_tender");
  const handleChangeCSDOrderEntry = (selectedOptions) => handleChangeName(selectedOptions, "csd_order_entry");
  const handleChangeCSDOrderManagement = (selectedOptions) => handleChangeName(selectedOptions, "csd_order_management");
  
  const handleChangeContractManagement = (selectedOptions) => handleChangeName(selectedOptions, "contract_management");
  const handleChangeContractHandler = (selectedOptions) => handleChangeName(selectedOptions, "contract_handler_optional");
  const handleChangeWaiverForm = (selectedOptions) => handleChangeName(selectedOptions, "waiver_form_handler");
  const handleChangeEstimateForecast = (selectedOptions) => handleChangeName(selectedOptions, "estimate_forecast_handler");

  const parentOptions = debounce((inputValue, callback) => {
    filterOptions("/crm/sales_units/active_list", inputValue, true).then((filteredOptions) => {
      callback(filteredOptions);
    });
  }, 300);

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey)
  }

  return (
    <Card className='fs--1'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Header className='d-flex flex-between-center border-bottom'>
          <Card.Title>{title}</Card.Title>
          <IconButton
            onClick={handleBack}
            variant='falcon-default'
            size='sm'
            icon='arrow-left'
          />
        </Card.Header>
        <Card.Body className='mt-3'>
          <Accordion
            id="new-sales-unit"
            className="border rounded overflow-hidden fs--1 mt-3"
            alwaysOpen
            flush
            activeKey={activeKey}
            onSelect={handleAccordionSelect}
          >
            <Card className="rounded-0">
              <Accordion.Item eventKey="0" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        1. Information
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Form.Group as={Row} className='mb-2' controlId='name'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Name
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Form.Control
                          type='text'
                          name='name'
                          placeholder='Sales unit name'
                          className='fs--1'
                          {...register('name', {
                            required: 'Name must be required.'
                          })}
                        />
                        <span className='text-danger'>
                          {errors.name && errors.name.message}
                        </span>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='Description'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Description
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Form.Control
                          type='text'
                          name='description'
                          placeholder='Description'
                          className='fs--1'
                          {...register('description', {
                            required: 'Description must be required.'
                          })}
                        />
                        <span className='text-danger'>
                          {errors.description && errors.description.message}
                        </span>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='type_of_sales'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Type of sales
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='type_of_sales'
                          render={({field}) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={typeOfSalesOptions}
                              value={typeOfSalesOptions.find(option => option.value === field.value) || null}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              onChange={selectedOption => field.onChange(selectedOption.value)}
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: 'Type of sales must be required'
                            }
                          }}
                        />
                        <span className='text-danger'>
                          {errors.type_of_sales && errors.type_of_sales.message}
                        </span>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='region'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Region
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='region'
                          render={({field}) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={salesUnitsRegionOptions}
                              value={salesUnitsRegionOptions.find(option => option.value === field.value) || null}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              onChange={selectedOption => field.onChange(selectedOption.value)}
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: 'Region must be required'
                            }
                          }}
                        />
                        <span className='text-danger'>
                          {errors.region && errors.region.message}
                        </span>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2 mt-3' controlId='parent_id'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Parent
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='parent_id'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultParentOption}
                              loadOptions={parentOptions}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('parent_id', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-1 mt-3' controlId='active'>
                      <Col sm={{ span: 10, offset: 3 }}>
                        <Controller
                          control={control}
                          name='active'
                          defaultValue={true}
                          render={({ field }) => (
                            <Form.Check
                              type='switch'
                              label='Active'
                              checked={field.value}
                              onChange={(e) => setValue('active', e.target.checked)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        2. Sales handle
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Form.Group as={Row} className='mb-2 mt-3' controlId='sales_admin'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Sales admin
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='sales_admin'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultSalesAdmin}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('sales_admin', selectedOption?.value)}
                            />
                          )}
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: 'Sales admin must be required'
                          //   }
                          // }}
                        />
                        <span className='text-danger'>
                          {errors.sales_admin && errors.sales_admin.message}
                        </span>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='sales_leader'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Sales leader
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='sales_leader'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultSalesLeader}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('sales_leader', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='sales_tender'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Tender
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='sales_tender'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultSaleTender}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeSalesTender}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='sales_manager'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Chief Ref/GM
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='sales_manager'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultSaleChiefRep}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('sales_manager', selectedOption?.value)}
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: 'Chief Ref/GM must be required'
                            }
                          }}
                        />
                        <span className='text-danger'>
                          {errors.sales_manager && errors.sales_manager.message}
                        </span>
                      </Col>
                    </Form.Group>

                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        3. Engineering
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Form.Group as={Row} className='mb-2' controlId='eng_incharge'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        ENG incharge
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='eng_incharge'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultEngInchargeOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('eng_incharge', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='detailer_incharge'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Detailer incharge
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='detailer_incharge'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultEngDetailerOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('detailer_incharge', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='eng_manager'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        ENG manager
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='eng_manager'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultEngManagerOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('eng_manager', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        4. CSD
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Form.Group as={Row} className='mb-2' controlId='csd_order_entry'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Order entry
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='csd_order_entry'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultCSDOrderEntryOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeCSDOrderEntry}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='csd_order_management'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Order management
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='csd_order_management'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultCSDOrderManagementOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeCSDOrderManagement}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='contract_management'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Contract management
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='contract_management'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultContarctManagementOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeContractManagement}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='contract_handler_optional'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Contract handlers (optional)
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='contract_handler_optional'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultContarctHandlerOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeContractHandler}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='estimate_forecast_handler'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Estimate forecast handlers
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='estimate_forecast_handler'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultEstimateForecastOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeEstimateForecast}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='waiver_form_handler'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Waiver Form handlers
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='waiver_form_handler'
                          render={({field}) => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultWaiverFormOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={false}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              {...field}
                              isMulti
                              onChange={handleChangeWaiverForm}
                              value={(field.value || []).map(value => ({ value, label: value }))}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='general_manager_operation'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        General manager operation
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='general_manager_operation'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultGeneralManagerOperation}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('general_manager_operation', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4" className="border-0">
                <Card.Header className="p-0">
                  <Accordion.Button className="bg-light btn btn-link text-decoration-none fs--1 d-block w-100 py-2 px-3 border-bottom rounded-0 text-start shadow-none">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon="caret-right"
                        className="accordion-icon me-3 mt-1"
                        transform="shrink-2"
                      />
                      <span className="fw-medium font-sans-serif text-900">
                        5. Finance controller
                      </span>
                    </div>
                  </Accordion.Button>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="m-2">
                    <Form.Group as={Row} className='mb-2' controlId='credit_controller'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Credit controller
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='credit_controller'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultCreditControllerOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('credit_controller', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-2' controlId='finance_controller'>
                      <Form.Label column sm={3} className='text-lg-end'>
                        Finance controller
                      </Form.Label>
                      <Col sm={9} md={7}>
                        <Controller
                          control={control}
                          name='finance_controller'
                          render={() => (
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              defaultValue={defaultFinanceControllerOption}
                              loadOptions={loadEmpName}
                              closeMenuOnSelect={true}
                              placeholder='Select...'
                              classNamePrefix='react-select'
                              className='w-100'
                              onChange={selectedOption => setValue('finance_controller', selectedOption?.value)}
                            />
                          )}
                        />
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
          </Accordion>

          <Form.Group as={Row}>
            <Col className='my-2'>
              <Flex justifyContent='center'>
                <Button size='sm' type='submit'><FaSave /> Save</Button>
                <Button variant='danger' size='sm' className='ms-2' onClick={handleBack}><FaBan /> Cancel</Button>
              </Flex>
            </Col>
          </Form.Group>

        </Card.Body>
      </Form>
    </Card>
  )
};

SalesUnitForm.propTypes = {
  title: PropTypes.string.isRequired,
  salesUnit: PropTypes.shape({
    id: PropTypes.number
  })
};

export default SalesUnitForm;