import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { assignRolePermission, getPermissionsTreeView } from "redux/authorization/roleSlice";
import SimpleBarReact from 'simplebar-react';

const LazyLoadedTreeview = React.lazy(() => import("components/common/Treeview"));

const RoleDetailInfoPermissionAdd = () => {
  const {recordData, permissionTreeViewData} = useSelector((state) => state.authorization_role) ;
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const role = recordData || {};

  useEffect(() => {
    if (role) {
      dispatch(getPermissionsTreeView(role.id))
    }
  }, [role, dispatch]);

  const handleAssignPermission = () => {
    dispatch(assignRolePermission({role, data: {permission_ids: selectedItems}}))
  }

  return (
    <div className="mt-5">
      <SimpleBarReact style={{ maxHeight: '25rem' }}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyLoadedTreeview
            data={permissionTreeViewData}
            selection
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </React.Suspense>
      </SimpleBarReact>
      <Flex justifyContent="center" className="mt-3">
        <Button
          size="sm"
          variant="falcon-default"
          onClick={handleAssignPermission}
        >
          <FontAwesomeIcon icon="user-shield" /> Assign permission
        </Button>
      </Flex>
    </div>
  )
}

export default RoleDetailInfoPermissionAdd