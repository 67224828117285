import { Button, Card, Col, Row, Badge, Form, ListGroup } from 'react-bootstrap';
import TransactionDetailBookingInfo from './detail/TransactionDetailBookingInfo';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { driverConfirm, getBooking, updateDriverTran } from 'redux/booking/car/carBookingSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconAlert from "components/common/IconAlert";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import {format} from 'date-fns';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { FaBan, FaParking, FaUserCircle } from 'react-icons/fa';
import { MdOutlineClosedCaptionDisabled } from 'react-icons/md';
import UploadDocument from "components/upload/UploadDocument";
import DocumentAttachment from "components/upload/DocumentAttachment";
import { formatNumber } from 'helpers/utils';

const TransactionDriverUpdateDeparture = () => {
  const { control, register, handleSubmit, formState: {errors}, setValue, getValues } = useForm();
  const {recordData: transaction} = useSelector((state) => state.booking_car);

  const dispatch = useDispatch();
  const params = useParams();
  const transactionId = params.id;
  const driverTranId = params.driver_tran_id;
  const driverTrans = transaction.driver_trans || [];

  const driver_tran = driverTrans.filter((item) => item.id === parseInt(driverTranId))[0] || {};
  const [showFormUpdate, setShowFormUpdate] = useState(!driver_tran.departure_begin_kilometer);
  const [images, setImages] = useState([]);
  const fileTypes = "image/*";
  const fileNote = "Only image/* files type are allowed. The file size is maximum of 20Mb";


  const {
    allow_edit,
    allow_remove_image
  } = driver_tran?.permissions || {};

  useEffect(() => {
    dispatch(getBooking(transactionId))
  }, [params, dispatch]);

  useEffect(() => {
    if(driver_tran && showFormUpdate) {

      if (!driver_tran.departure_begin_time) {
        setValue("departure_begin_time", dateConvert(transaction.from_date));
      }
      else {
        setValue("departure_begin_time", dateConvert(driver_tran.departure_begin_time))
      }

      if (!driver_tran.departure_end_time) {
        setValue("departure_end_time", dateConvert(transaction.to_date));
      }
      else {
        setValue("departure_end_time", dateConvert(driver_tran.departure_end_time))
      }

      setValue("departure_begin_kilometer", driver_tran.departure_begin_kilometer)
      setValue("departure_end_kilometer", driver_tran.departure_end_kilometer)
      setValue("notes", driver_tran.notes)
      setValue("parking_fee", driver_tran.parking_fee)
    }
    else {
      setValue("departure_begin_time", dateConvert(transaction.from_date));
      setValue("departure_end_time", dateConvert(transaction.to_date));
    }
  }, [setValue, showFormUpdate, driver_tran])

  const bgStatusFormat = useCallback((statusId) => {
    let bg = "info";
  
    switch (statusId) {
      case 1:
        bg = "warning";
        break;
      case 2:
        bg = "success";
        break;
      case 3:
      case 4:
        bg = "danger";
        break;
      case 5:
        bg = "info";
        break;
      default:
        bg = "info"
        break;
    }
  
    return bg
  }, [transaction])

  const onSubmit = (data) => {
    console.log(data)

    data = {...data, images, closed: true}; //lock deny user update again!

    try {
      if (driver_tran) {
        dispatch(updateDriverTran({transactionId, driverTranId, data}))
        closeUpdateForm()
      }
      else {
        toast.error("Error update driver.")
      }
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }

  const handleCancel = () => {
    closeUpdateForm()
  }

  const validateDepartureKilometers = (bgeinKm, endKm) => {
    if (bgeinKm <= endKm) {
      return true //pass
    }
    //setError('departure_end_kilometer', 'Departure end (km) must be greater than Departure begin (km)')
    //return 'Departure end (km) must be greater than Departure begin (km)'
    toast.error('Departure end (km) must be greater than Departure begin (km)')
  }

  const handleConfirm = () => {
    dispatch(driverConfirm({transactionId, driverTranId}))
  }

  const closeUpdateForm = () => {
    setShowFormUpdate(false)
  }

  const handleEditDriver = () => {
    setShowFormUpdate(true)
  }

  const dateConvert = (date) => {
    const newDate = new Date(date)
    return newDate
  }

  const driverConfirmed = () => {
    return driver_tran && driver_tran.confirmed
  }

  const formatDate = (date) => {
    if (!date) return
    return format(new Date(date), "MMM, dd-yyyy h:mm aa")
  }

  const removeChangeOrderFormDocument = (imageId) => {
    try {
      dispatch(removeImageDriverTran({transactionId, driverTranId, imageId}))
    }
    catch(error) {
      console.log(error.message)
      toast.error(error.message)
    }
  }


  return (
    <>
      {!driverConfirmed() && (
        <IconAlert variant="warning">
          <p className="mb-0">Waiting for confirmation by driver!</p>
        </IconAlert>
      )}

      <div>
        {showFormUpdate ? (
          <Card className="mb-3 fs--1">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card.Body>
                <IconAlert variant="info" className="mb-3">
                  Specify the departure time and kilometers for the booking. After you update this information, it will be locked and sent to HR Admin.
                </IconAlert>

                <div className="border rounded p-3">
                  <Form.Group as={Row} className="mb-3" controlId="departure_begin_time">
                    <Form.Label column sm={3} className="text-lg-end">
                      Departure time
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Row className="g-2">
                        <Col>
                          <Controller
                            control={control}
                            name="departure_begin_time"
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control fs--1"
                                placeholderText="From"
                                timeIntervals={5}
                                //dateFormat="h:mm aa"
                                dateFormat="dd-MM-yyyy h:mm aa"
                                showTimeSelect
                                showTimeSelectOnly
                              />
                            )}
                            rules={{
                              required: 'Departure begin time must be required'
                            }}
                          />
                        </Col>
                        <Col>
                          <Controller
                            control={control}
                            name="departure_end_time"
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                className="form-control fs--1"
                                placeholderText="To"
                                timeIntervals={5}
                                //dateFormat="h:mm aa"
                                dateFormat="dd-MM-yyyy h:mm aa"
                                showTimeSelect
                                showTimeSelectOnly
                              />
                            )}
                            rules={{
                              required: 'Departure end time must be required'
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="departure_begin_kilometer">
                    <Form.Label column sm={3} className="text-lg-end">
                      Departure (km)
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Row className="g-2">
                        <Col>
                          <Form.Control
                            type="number"
                            name="departure_begin_kilometer"
                            min={0}
                            placeholder="From"
                            className="fs--1"
                            {...register('departure_begin_kilometer', {
                              required: 'Departure from (km) must be required'
                            })}
                          />
                          <span className="text-danger">
                            {errors.departure_begin_kilometer && errors.departure_begin_kilometer.message}
                          </span>
                        </Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="departure_end_kilometer"
                            min={0}
                            placeholder="To"
                            className="fs--1"
                            {...register('departure_end_kilometer', {
                              required: 'Departure to (km) must be required',
                              validate: (value) => {
                                validateDepartureKilometers(
                                  Number(getValues('departure_begin_kilometer')), 
                                  Number(value)
                                )
                              }
                            })}
                          />
                          <span className="text-danger">
                            {errors.departure_end_kilometer && errors.departure_end_kilometer.message}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="parking_fee">
                    <Form.Label column sm={3} className="text-lg-end">
                      Parking fee (VND)
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Form.Control
                        type="number"
                        name="parking_fee"
                        placeholder="Parking fee"
                        className="fs--1"
                        {...register("parking_fee")}
                      />
                      <span className="text-danger">
                        {errors.parking_fee && errors.parking_fee.message}
                      </span>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="notes">
                    <Form.Label column sm={3} className="text-lg-end">
                      Notes
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        name="notes"
                        placeholder="Notes"
                        className="fs--1"
                        {...register("notes", {
                          required: "Notes must be required"
                        })}
                      />
                      <span className="text-danger">
                        {errors.notes && errors.notes.message}
                      </span>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="attachment">    
                    <Form.Label column sm={3} className="text-lg-end">
                      Attachments
                    </Form.Label>
                    <Col sm={9} md={7}>
                      <UploadDocument
                        files={images}
                        setFiles={setImages}
                        fileTypes={fileTypes}
                        note={fileNote}
                      />
                    </Col>
                  </Form.Group>
                </div>

              </Card.Body>
              <Card.Footer className="text-center">
                <Button size="sm" type="submit" className="me-1"><MdOutlineClosedCaptionDisabled /> Update and send to Admin</Button>
                <Button size="sm" variant="danger" onClick={handleCancel}><FaBan /> Cancel</Button>
              </Card.Footer>
            </Form>
          </Card>
        ) : (
          <Card className="mb-3 fs--1">
           
            <Card.Header className="mb-3">
              <Flex justifyContent="between">
                <div className="mb-0 h5">Car and Driver information</div>

                {allow_edit && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="edit"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-1"
                    onClick={handleEditDriver}
                  >
                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                  </IconButton>
                )}
              </Flex>
            </Card.Header>
            <Card.Body className="bg-light border-top">
              <Row className="mb-2">
                <Col lg xxl={6}>
                  <ListGroup.Item>
                    <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                      Information
                    </h6>
                    <div className="mb-3">Booking assigned to driver:</div>
                    <Row className="mb-1">
                      <Col xs={3} sm={4}>Car number</Col>
                      <Col className="text-primary">{driver_tran.car?.name} / <strong>{driver_tran.car?.model}</strong></Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs={3} sm={4}>Driver name</Col>
                      <Col className="text-primary">{driver_tran.driver?.name}</Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs={3} sm={4}>Email</Col>
                      <Col className="text-primary">{driver_tran.driver?.email}</Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs={3} sm={4}>Phone number</Col>
                      <Col className="text-primary">{driver_tran.driver?.phone}</Col>
                    </Row>
                  </ListGroup.Item>
                </Col>
                <Col lg>
                  <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                    Confirmation
                  </h6>

                  {driverConfirmed() ? (
                    <>
                      <Row>
                        <Col xs={3} sm={4} className="text-danger">Confirmed?</Col>
                        <Col>
                          <FontAwesomeIcon icon="check-circle" className="text-success" size="2x" />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs={3} sm={4}>Confirmed date</Col>
                        <Col className="text-primary">{driver_tran.confirmed_date}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs={3} sm={4}>Confirmed by</Col>
                        <Col className="text-primary"><FaUserCircle /> {driver_tran.confirmed_by}</Col>
                      </Row>
                    </>
                  ) : (
                    <Row className="mt-3">
                      <Col xs={3} sm={4} className="text-danger">Confirmed?</Col>
                      <Col>
                        <Badge bg="warning" className="fs--2">Waiting for confirmation by driver!</Badge>
                        <div className="my-1 text-end">
                          Click here to <Link className="fw-semi-bold" onClick={handleConfirm}>Confirm!</Link>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row className="mb-2 mt-4">
                <Col lg xxl={6}>
                  <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                    Approval
                  </h6>

                  <Row className="mb-3">
                    <Col xs={3} sm={4}>
                      <p className="fw-semi-bold fs--1 mb-1">Notes</p>
                    </Col>
                    <Col>
                      <p className="mb-1 fs--1 text-danger" dangerouslySetInnerHTML={{ __html: transaction.remark }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} sm={4}>
                      <p className="fw-semi-bold fs--1 mb-1">Approved by</p>
                    </Col>
                    <Col>
                      <p className="mb-1 fs--1 text-primary"><FaUserCircle /> {transaction.approved_by}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} sm={4}>
                      <p className="fw-semi-bold fs--1 mb-1">Approved date</p>
                    </Col>
                    <Col>
                      <p className="mb-1 fs--1 text-primary">{transaction.approved_date}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg>
                  <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                    Departure
                  </h6>

                  <Row className="mb-2">
                    <Col xs={3} sm={4}>Begin time</Col>
                    <Col className="text-primary">{formatDate(driver_tran.departure_begin_time)}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3} sm={4}>End time</Col>
                    <Col className="text-primary">{formatDate(driver_tran.departure_end_time)}</Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs={3} sm={4} className="text-primary h6">Total</Col>
                    <Col className="text-primary h6">
                      <Badge pill bg="info">{driver_tran.total_hours} hours</Badge>
                    </Col>
                  </Row>
                  
                  <Row className="mb-2">
                    <Col xs={3} sm={4}>Begin (Km)</Col>
                    <Col className="text-primary">{driver_tran.departure_begin_kilometer}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3} sm={4}>End (Km)</Col>
                    <Col className="text-primary">{driver_tran.departure_end_kilometer}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3} sm={4} className="text-primary h6">Total</Col>
                    <Col className="text-primary h6">
                      <Badge pill bg="info">{driver_tran.total_kilometers} km</Badge>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={3} sm={4} className="text-primary h6"><FaParking /> Parking Fee</Col>
                    <Col className="text-primary h6">
                      <Badge pill bg="info">{formatNumber(driver_tran.parking_fee, "VND")} VND</Badge>
                    </Col>
                  </Row>

                  <h6 className="fw-semi-bold ls my-3 text-uppercase">
                    Attachments
                  </h6>
                  <div className="mx-2">
                    {driver_tran?.images && driver_tran?.images.map(attachment => (
                      <DocumentAttachment
                        {...attachment}
                        key={attachment.id}
                        isTable
                        allowView={true}
                        allowRemove={allow_remove_image}
                        allowDownload={true}
                        handleRemoveAttachment={(imageId) => removeChangeOrderFormDocument(imageId)}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}

        <TransactionDetailBookingInfo transaction={transaction} bgStatusFormat={bgStatusFormat} />
      </div>
    </>
  );
};

export default TransactionDriverUpdateDeparture;