import React from 'react';
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { 
  setNestedPage,
  setNestedPageSize,
  setNestedParams,
  getActivityLog
} from "redux/booking/travel/travelBookingSlice"
import ActivityHistory from "components/peb-grid/ActivityHistory"

const BookingActivity = () => {
  const {activityLogData} = useSelector(state => state.booking_travel);
  const params = useParams();

  return (
    <ActivityHistory
      activityLogData={activityLogData}
      setNestedPage={setNestedPage}
      setNestedPageSize={setNestedPageSize}
      setNestedParams={setNestedParams}
      getActivityLog={getActivityLog}
      bookingId={params.id} //dynamic params to activity
    />
  )
}

export default BookingActivity;