import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SalesUnitDetailInfo = ({ sales_unit = {} }) => {
  const {
    sales_admin,
    sales_leader,
    sales_manager,
    eng_incharge,
    detailer_incharge,
    eng_manager,
    credit_controller,
    finance_controller,
    csd_order_entry = [],
    csd_order_management = [],
    contract_management = [],
    general_manager_operation,
    contract_handler_optional = [],
    estimate_forecast_handler = [],
    waiver_form_handler = []
  } = sales_unit;

  const renderSoftBadges = (items) => (
    items.map((name, index) => (
      <SoftBadge bg='info' key={index} className='me-2'>{name}</SoftBadge>
    ))
  );

  const renderRow = (label, content) => (
    <Row>
      <Col xs={5} sm={7}>
        <p className="fw-semi-bold mb-1 fs--1">{label}</p>
      </Col>
      <Col>
        <p className="mb-2 text-primary fs--1">{content}</p>
      </Col>
    </Row>
  );

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Details</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light border-top">
        <Row className="mb-5">
          <Col lg xxl={5}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">Sales team</h6>
            {renderRow('Admin', sales_admin)}
            {renderRow('Team Leader', sales_leader)}
            {renderRow('Manager/Chief Rep', sales_manager)}
          </Col>
          <Col lg className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">Engineering</h6>
            {renderRow('Design incharge', eng_incharge)}
            {renderRow('Detailer incharge', detailer_incharge)}
            {renderRow('Manager', eng_manager)}
          </Col>
          <Col lg className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">Finance</h6>
            {renderRow('Credit controller', credit_controller)}
            {renderRow('Finance controller', finance_controller)}
          </Col>
        </Row>
        <Row>
          <Col lg xxl={5}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">CSD</h6>
            {renderRow('Order entry', renderSoftBadges(csd_order_entry))}
            {renderRow('Order management', renderSoftBadges(csd_order_management))}
            {renderRow('Contract management', renderSoftBadges(contract_management))}
            {renderRow('GM Operation', <SoftBadge bg='info' className='me-1'>{general_manager_operation}</SoftBadge>)}
          </Col>
          <Col lg className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">Factory</h6>
            {renderRow('Contract handler (optional)', renderSoftBadges(contract_handler_optional))}
            {renderRow('Estimate forecast handler', renderSoftBadges(estimate_forecast_handler))}
            {renderRow('Waiver form handler', renderSoftBadges(waiver_form_handler))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SalesUnitDetailInfo.propTypes = {
  sales_unit: PropTypes.object.isRequired
}

export default SalesUnitDetailInfo;
