import { formatDate } from 'helpers/utils';
import React from 'react';
import PropTypes from 'prop-types';

const formatDateInfo = (apds, dateType) => (
  apds.map((apd) => (
    apd[dateType] ? (
      <div key={apd.name}><strong>{apd.name}</strong>: {formatDate(apd[dateType], "dd/MM/yy")}</div>
    ) : null
  ))
);

formatDateInfo.propTypes = {
  apds: PropTypes.object.isRequired,
  dateType: PropTypes.instanceOf(Date).isRequired
}


const APDFormatRenderer = (props) => {
  const {apds} = props.data

  switch (props.column.colId) {
    case "apds.apd_request":
      return formatDateInfo(apds, "apd_schedule");

    case "apds.apd_schedule":
      return formatDateInfo(apds, "apd_schedule");

    case "apds.apd_release":
      return formatDateInfo(apds, "apd_release");

    case "apds.ab_erd_request":
      return formatDateInfo(apds, "ab_erd_request");

    case "apds.ab_erd_schedule":
      return formatDateInfo(apds, "ab_erd_schedule");

    case "apds.ab_erd_release":
      return formatDateInfo(apds, "ab_erd_release");

    default:
      return null;
  }
}

APDFormatRenderer.propTypes = {
  apds: PropTypes.object.isRequired
}

export default APDFormatRenderer;