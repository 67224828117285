import CustomerProcess from "components/crm/reports/customer/CustomerProcess";
import CustomerSignedContract from "components/crm/reports/customer/CustomerSignedContract";
import React from 'react';
import PropTypes from 'prop-types';

const Customer = ({customerData}) => {
  const {customer, contract} = customerData || {};
  
  return (
    <>
      <CustomerProcess customerData={customer} />
      <CustomerSignedContract data={contract} />
    </>
  )
};

Customer.propTypes = {
  customerData: PropTypes.object.isRequired
}

export default Customer;